var cdsRecentSearches$g = {
  ariaRecentSearches: "vypisování nejnovějších hledání"
};
var cdsTrendingSearches$g = {
  ariaTrendingSearches: "vypisování častých hledání"
};
var cds$g = {
  cdsRecentSearches: cdsRecentSearches$g,
  cdsTrendingSearches: cdsTrendingSearches$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  cds: cds$g
};
var cdsRecentSearches$f = {
  ariaRecentSearches: "Eingabe der vor kurzem verwendeten Suchen"
};
var cdsTrendingSearches$f = {
  ariaTrendingSearches: "Eingabe von Trendsuchen"
};
var cds$f = {
  cdsRecentSearches: cdsRecentSearches$f,
  cdsTrendingSearches: cdsTrendingSearches$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  cds: cds$f
};
var cdsRecentSearches$e = {
  ariaRecentSearches: "typing recent searches"
};
var cdsTrendingSearches$e = {
  ariaTrendingSearches: "typing trending searches"
};
var cds$e = {
  cdsRecentSearches: cdsRecentSearches$e,
  cdsTrendingSearches: cdsTrendingSearches$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  cds: cds$e
};
var cdsRecentSearches$d = {
  ariaRecentSearches: "escribiendo búsquedas recientes"
};
var cdsTrendingSearches$d = {
  ariaTrendingSearches: "escribiendo búsquedas populares"
};
var cds$d = {
  cdsRecentSearches: cdsRecentSearches$d,
  cdsTrendingSearches: cdsTrendingSearches$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  cds: cds$d
};
var cdsRecentSearches$c = {
  ariaRecentSearches: "escribiendo búsquedas recientes"
};
var cdsTrendingSearches$c = {
  ariaTrendingSearches: "escribiendo búsquedas más populares"
};
var cds$c = {
  cdsRecentSearches: cdsRecentSearches$c,
  cdsTrendingSearches: cdsTrendingSearches$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  cds: cds$c
};
var cdsRecentSearches$b = {
  ariaRecentSearches: "Saisie des recherches récentes"
};
var cdsTrendingSearches$b = {
  ariaTrendingSearches: "Saisie de recherches tendances"
};
var cds$b = {
  cdsRecentSearches: cdsRecentSearches$b,
  cdsTrendingSearches: cdsTrendingSearches$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  cds: cds$b
};
var cdsRecentSearches$a = {
  ariaRecentSearches: "तात्कालिक खोजें टाइप कर रहे हैं"
};
var cdsTrendingSearches$a = {
  ariaTrendingSearches: "ट्रेडिंग खोजें टाइप कर रहे हैं"
};
var cds$a = {
  cdsRecentSearches: cdsRecentSearches$a,
  cdsTrendingSearches: cdsTrendingSearches$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  cds: cds$a
};
var cdsRecentSearches$9 = {
  ariaRecentSearches: "legutóbbi keresések gépelése"
};
var cdsTrendingSearches$9 = {
  ariaTrendingSearches: "népszerű keresések begépelése"
};
var cds$9 = {
  cdsRecentSearches: cdsRecentSearches$9,
  cdsTrendingSearches: cdsTrendingSearches$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  cds: cds$9
};
var cdsRecentSearches$8 = {
  ariaRecentSearches: "mengetik pencarian terbaru"
};
var cdsTrendingSearches$8 = {
  ariaTrendingSearches: "mengetik pencarian populer"
};
var cds$8 = {
  cdsRecentSearches: cdsRecentSearches$8,
  cdsTrendingSearches: cdsTrendingSearches$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  cds: cds$8
};
var cdsRecentSearches$7 = {
  ariaRecentSearches: "inserimento di ricerche recenti in corso"
};
var cdsTrendingSearches$7 = {
  ariaTrendingSearches: "inserimento di ricerche di tendenza in corso"
};
var cds$7 = {
  cdsRecentSearches: cdsRecentSearches$7,
  cdsTrendingSearches: cdsTrendingSearches$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  cds: cds$7
};
var cdsRecentSearches$6 = {
  ariaRecentSearches: "最近の検索を入力"
};
var cdsTrendingSearches$6 = {
  ariaTrendingSearches: "検索の傾向を入力"
};
var cds$6 = {
  cdsRecentSearches: cdsRecentSearches$6,
  cdsTrendingSearches: cdsTrendingSearches$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  cds: cds$6
};
var cdsRecentSearches$5 = {
  ariaRecentSearches: "최근 검색 입력"
};
var cdsTrendingSearches$5 = {
  ariaTrendingSearches: "인기 검색어 입력"
};
var cds$5 = {
  cdsRecentSearches: cdsRecentSearches$5,
  cdsTrendingSearches: cdsTrendingSearches$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  cds: cds$5
};
var cdsRecentSearches$4 = {
  ariaRecentSearches: "wpisywanie ostatnich wyszukiwań"
};
var cdsTrendingSearches$4 = {
  ariaTrendingSearches: "wpisywanie popularnych wyszukiwań"
};
var cds$4 = {
  cdsRecentSearches: cdsRecentSearches$4,
  cdsTrendingSearches: cdsTrendingSearches$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  cds: cds$4
};
var cdsRecentSearches$3 = {
  ariaRecentSearches: "digitando pesquisas recentes"
};
var cdsTrendingSearches$3 = {
  ariaTrendingSearches: "digitando pesquisas de tendência"
};
var cds$3 = {
  cdsRecentSearches: cdsRecentSearches$3,
  cdsTrendingSearches: cdsTrendingSearches$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  cds: cds$3
};
var cdsRecentSearches$2 = {
  ariaRecentSearches: "ввод последних поисковых запросов"
};
var cdsTrendingSearches$2 = {
  ariaTrendingSearches: "ввод популярных поисковых запросов"
};
var cds$2 = {
  cdsRecentSearches: cdsRecentSearches$2,
  cdsTrendingSearches: cdsTrendingSearches$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  cds: cds$2
};
var cdsRecentSearches$1 = {
  ariaRecentSearches: "输入最近搜索"
};
var cdsTrendingSearches$1 = {
  ariaTrendingSearches: "键入趋势搜索"
};
var cds$1 = {
  cdsRecentSearches: cdsRecentSearches$1,
  cdsTrendingSearches: cdsTrendingSearches$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  cds: cds$1
};
var cdsRecentSearches = {
  ariaRecentSearches: "輸入最近搜尋"
};
var cdsTrendingSearches = {
  ariaTrendingSearches: "輸入趨勢搜尋"
};
var cds = {
  cdsRecentSearches: cdsRecentSearches,
  cdsTrendingSearches: cdsTrendingSearches
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  cds
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cdsTranslationChunksConfig = {
  cds: ['cdsRecentSearches', 'cdsTrendingSearches']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: cdsTranslations
 *             +   resources: { en: cdsTranslationsEn }
 *               }
 *             ```
 */
const cdsTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { cdsTranslationChunksConfig, cdsTranslations, cs as cdsTranslationsCs, de as cdsTranslationsDe, en as cdsTranslationsEn, es as cdsTranslationsEs, es_CO as cdsTranslationsEs_CO, fr as cdsTranslationsFr, hi as cdsTranslationsHi, hu as cdsTranslationsHu, id as cdsTranslationsId, it as cdsTranslationsIt, ja as cdsTranslationsJa, ko as cdsTranslationsKo, pl as cdsTranslationsPl, pt as cdsTranslationsPt, ru as cdsTranslationsRu, zh as cdsTranslationsZh, zh_TW as cdsTranslationsZh_TW };
