var requestedDeliveryDate$w = {
  datePickerLabel: "Vyžádat datum dodání",
  readOnlyTextLabel: "Požadované datum dodání",
  successMessage: "Datum dodání úspěšně aktualizováno.",
  errorMessage: "Něco se pokazilo. Nelze aktualizovat požadované datum."
};
var requestedDeliveryDate$x = {
  requestedDeliveryDate: requestedDeliveryDate$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  requestedDeliveryDate: requestedDeliveryDate$x
};
var requestedDeliveryDate$u = {
  datePickerLabel: "Lieferdatum anfragen",
  readOnlyTextLabel: "Wunschlieferdatum",
  successMessage: "Lieferdatum erfolgreich aktualisiert.",
  errorMessage: "Etwas ist schiefgegangen. Das gewünschte Datum konnte nicht aktualisiert werden"
};
var requestedDeliveryDate$v = {
  requestedDeliveryDate: requestedDeliveryDate$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  requestedDeliveryDate: requestedDeliveryDate$v
};
var requestedDeliveryDate$s = {
  datePickerLabel: "Request Delivery Date",
  readOnlyTextLabel: "Requested Delivery Date",
  successMessage: "Delivery Date updated successfully.",
  errorMessage: "Something went wrong. Unable to update the requested date."
};
var requestedDeliveryDate$t = {
  requestedDeliveryDate: requestedDeliveryDate$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  requestedDeliveryDate: requestedDeliveryDate$t
};
var requestedDeliveryDate$q = {
  datePickerLabel: "Solicitar fecha de entrega",
  readOnlyTextLabel: "Fecha de entrega solicitada",
  successMessage: "Fecha de entrega actualizada correctamente.",
  errorMessage: "Algo salió mal. No es posible actualizar la fecha solicitada."
};
var requestedDeliveryDate$r = {
  requestedDeliveryDate: requestedDeliveryDate$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  requestedDeliveryDate: requestedDeliveryDate$r
};
var requestedDeliveryDate$o = {
  datePickerLabel: "Solicitar fecha de entrega",
  readOnlyTextLabel: "Fecha de entrega solicitada",
  successMessage: "Fecha de entrega actualizada correctamente.",
  errorMessage: "Algo salió mal. No es posible actualizar la fecha solicitada."
};
var requestedDeliveryDate$p = {
  requestedDeliveryDate: requestedDeliveryDate$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  requestedDeliveryDate: requestedDeliveryDate$p
};
var requestedDeliveryDate$m = {
  datePickerLabel: "Demander la date de livraison",
  readOnlyTextLabel: "Date de livraison demandée",
  successMessage: "La date de livraison a été mise à jour avec succès.",
  errorMessage: "Une erreur s'est produite. Impossible de mettre à jour la date demandée."
};
var requestedDeliveryDate$n = {
  requestedDeliveryDate: requestedDeliveryDate$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  requestedDeliveryDate: requestedDeliveryDate$n
};
var requestedDeliveryDate$k = {
  datePickerLabel: "वितरण दिनांक का अनुरोध करें",
  readOnlyTextLabel: "अनुरोधित वितरण दिनांक",
  successMessage: "वितरण दिनांक सफलतापूर्वक अपडेट किया गया.",
  errorMessage: "कुछ गलत हुआ है. अनुरोधित दिनांक अपडेट करे में अक्षम."
};
var requestedDeliveryDate$l = {
  requestedDeliveryDate: requestedDeliveryDate$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  requestedDeliveryDate: requestedDeliveryDate$l
};
var requestedDeliveryDate$i = {
  datePickerLabel: "Kérés kézbesítési dátuma",
  readOnlyTextLabel: "Kért kézbesítési dátum",
  successMessage: "A kézbesítési dátum aktualizálása sikeres.",
  errorMessage: "Hiba történt. Nem sikerült aktualizálni a kért dátumot."
};
var requestedDeliveryDate$j = {
  requestedDeliveryDate: requestedDeliveryDate$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  requestedDeliveryDate: requestedDeliveryDate$j
};
var requestedDeliveryDate$g = {
  datePickerLabel: "Tanggal Permintaan Pengiriman",
  readOnlyTextLabel: "Tanggal Pengiriman yang Diminta",
  successMessage: "Tanggal pengiriman berhasil diperbarui.",
  errorMessage: "Terjadi kesalahan. Tidak dapat memperbarui tanggal yang diminta."
};
var requestedDeliveryDate$h = {
  requestedDeliveryDate: requestedDeliveryDate$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  requestedDeliveryDate: requestedDeliveryDate$h
};
var requestedDeliveryDate$e = {
  datePickerLabel: "Richiedi data di consegna",
  readOnlyTextLabel: "Data di consegna richiesta",
  successMessage: "Data di consegna aggiornata correttamente.",
  errorMessage: "Si è verificato un errore. Impossibile aggiornare la data richiesta."
};
var requestedDeliveryDate$f = {
  requestedDeliveryDate: requestedDeliveryDate$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  requestedDeliveryDate: requestedDeliveryDate$f
};
var requestedDeliveryDate$c = {
  datePickerLabel: "配送依頼日",
  readOnlyTextLabel: "依頼された配送日",
  successMessage: "配送日が正常に更新されました。",
  errorMessage: "問題が発生しました。依頼された日付を更新できません。"
};
var requestedDeliveryDate$d = {
  requestedDeliveryDate: requestedDeliveryDate$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  requestedDeliveryDate: requestedDeliveryDate$d
};
var requestedDeliveryDate$a = {
  datePickerLabel: "배송일  요청",
  readOnlyTextLabel: "요청된 배송일",
  successMessage: "배송일이 업데이트되었습니다.",
  errorMessage: "문제가 발생했습니다. 요청된 날짜를 업데이트할 수 없습니다."
};
var requestedDeliveryDate$b = {
  requestedDeliveryDate: requestedDeliveryDate$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  requestedDeliveryDate: requestedDeliveryDate$b
};
var requestedDeliveryDate$8 = {
  datePickerLabel: "Poproś o datę dostawy",
  readOnlyTextLabel: "Wymagana data dostawy",
  successMessage: "Data dostawy została zaktualizowana.",
  errorMessage: "Coś poszło nie tak. Nie można zaktualizować żądanej daty."
};
var requestedDeliveryDate$9 = {
  requestedDeliveryDate: requestedDeliveryDate$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  requestedDeliveryDate: requestedDeliveryDate$9
};
var requestedDeliveryDate$6 = {
  datePickerLabel: "Data de entrega de solicitação",
  readOnlyTextLabel: "Data de entrega solicitada",
  successMessage: "Data de entrega atualizada com êxito.",
  errorMessage: "Algo deu errado. Impossível atualizar a data solicitada."
};
var requestedDeliveryDate$7 = {
  requestedDeliveryDate: requestedDeliveryDate$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  requestedDeliveryDate: requestedDeliveryDate$7
};
var requestedDeliveryDate$4 = {
  datePickerLabel: "Запросить дату доставки",
  readOnlyTextLabel: "Запрошенная дата доставки",
  successMessage: "Дата доставки обновлена.",
  errorMessage: "Что-то пошло не так. Не удалось обновить запрошенную дату."
};
var requestedDeliveryDate$5 = {
  requestedDeliveryDate: requestedDeliveryDate$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  requestedDeliveryDate: requestedDeliveryDate$5
};
var requestedDeliveryDate$2 = {
  datePickerLabel: "要求交货日期",
  readOnlyTextLabel: "要求交货日期",
  successMessage: "交货日期已成功更新。",
  errorMessage: "出错了。无法更新要求日期。"
};
var requestedDeliveryDate$3 = {
  requestedDeliveryDate: requestedDeliveryDate$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  requestedDeliveryDate: requestedDeliveryDate$3
};
var requestedDeliveryDate = {
  datePickerLabel: "請求交貨日期",
  readOnlyTextLabel: "請求的交貨日期",
  successMessage: "已成功更新交貨日期。",
  errorMessage: "發生錯誤，無法更新請求的日期。"
};
var requestedDeliveryDate$1 = {
  requestedDeliveryDate: requestedDeliveryDate
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  requestedDeliveryDate: requestedDeliveryDate$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const requestedDeliveryDateTranslationChunksConfig = {
  requestedDeliveryDate: ['requestedDeliveryDate']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: requestedDeliveryDateTranslations
 *             +   resources: { en: requestedDeliveryDateTranslationsEn }
 *               }
 *             ```
 */
const requestedDeliveryDateTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { requestedDeliveryDateTranslationChunksConfig, requestedDeliveryDateTranslations, cs as requestedDeliveryDateTranslationsCs, de as requestedDeliveryDateTranslationsDe, en as requestedDeliveryDateTranslationsEn, es as requestedDeliveryDateTranslationsEs, es_CO as requestedDeliveryDateTranslationsEs_CO, fr as requestedDeliveryDateTranslationsFr, hi as requestedDeliveryDateTranslationsHi, hu as requestedDeliveryDateTranslationsHu, id as requestedDeliveryDateTranslationsId, it as requestedDeliveryDateTranslationsIt, ja as requestedDeliveryDateTranslationsJa, ko as requestedDeliveryDateTranslationsKo, pl as requestedDeliveryDateTranslationsPl, pt as requestedDeliveryDateTranslationsPt, ru as requestedDeliveryDateTranslationsRu, zh as requestedDeliveryDateTranslationsZh, zh_TW as requestedDeliveryDateTranslationsZh_TW };
