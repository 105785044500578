var myAccountV2OrderHistory$g = {
  heading: "Všechny objednávky ({{param}})",
  item: "{{param}} položka",
  items: "Počet položek: {{param}}",
  totalPrice: "Cena celkem: {{param}}",
  consignmentCode: "Konsignace {{param}}",
  statusDate: "Naposledy aktualizováno: {{param}}",
  returnProcessed: "Vrácení zpracováno: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "K vyzvednutí – "
  },
  checkoutMode: {
    deliveryEntries: "K expedici – "
  },
  checkoutPickupInStore: {
    heading: "K vyzvednutí – "
  },
  orderListResults: "Seznam objednávek",
  orderListPagination: "Stránkování seznamu objednávek",
  totalPriceLabel: "Celková cena",
  orderPlaced: "Datum objednávky",
  orderCodeLabel: "Kód objednávky",
  consignmentDetailLabel: "Informace o konsignaci",
  consignmentNumberLabel: "Číslo konsignace",
  consignmentStatusLabel: "Status konsignace",
  consignmentStatusDateLabel: "Naposledy aktualizováno",
  estimateDeliveryLabel: "Odhadované datum dodání"
};
var myAccountV2OrderDetails$g = {
  returnItems: "Vrátit položky",
  cancelItems: "Zrušit položky",
  downloadInvoices: "Stáhnout faktury",
  viewAllOrders: "Zobrazit všechny objednávky",
  noInvoices: "Faktury zatím nebyly vytvořeny. Vraťte se později."
};
var myAccountV2Orders$g = {
  item: "{{value}} položka",
  items: "{{value}} položky/položek",
  heading: "Objednávky a vratky",
  orderNumber: "Číslo objednávky ({{value}})",
  purchasedOn: "Zakoupeno dne: {{value}}",
  orderedItems: "Objednané položky: {{value}}",
  totalPrice: "Cena celkem: {{value}}",
  orderDetails: "Detaily objednávky",
  orderDetailsLabel: "Zobrazit detaily objednávky číslo {{value}}",
  orderStatusLabel: "Status objednávky",
  returnOrder: "Vrátit objednávku",
  showMore: "Zobrazit více",
  showMoreLabel: "Přejít na historii objednávek"
};
var myAccountV2Order$g = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$g,
  myAccountV2OrderDetails: myAccountV2OrderDetails$g,
  myAccountV2Orders: myAccountV2Orders$g
};
var orderDetails$g = {
  orderId: "Číslo objednávky",
  orderNumber: "Číslo objednávky",
  replenishmentId: "Č. doplňování",
  purchaseOrderId: "Č. objednávky",
  purchaseOrderNumber: "Číslo objednávky",
  emptyPurchaseOrderId: "Nic",
  none: "Žádné {{value}}",
  placed: "Podáno",
  placedBy: "Podal/a",
  unit: "Jednotka",
  costCenter: "Nákladové středisko",
  costCenterAndUnit: "Nákladové středisko / jednotka",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Způsob platby",
  payByAccount: "Platba z účtu",
  paidByCreditCard: "(platba platební kartou)",
  status: "Status",
  active: "Aktivní",
  shippedOn: "Odesláno dne",
  shippingMethod: "Způsob dodání",
  placedOn: "Podáno dne",
  startOn: "Zahájit",
  nextOrderDate: "Datum další objednávky",
  frequency: "Četnost",
  cancelled: " Zrušeno",
  deliveryStatus_IN_TRANSIT: "V přepravě",
  deliveryStatus_READY_FOR_PICKUP: "Připraveno k vyzvednutí",
  deliveryStatus_READY_FOR_SHIPPING: "Připraveno k expedici",
  deliveryStatus_WAITING: "Čekající",
  deliveryStatus_DELIVERING: "Dodává se",
  deliveryStatus_PICKPACK: "Připraveno k expedici",
  deliveryStatus_PICKUP_COMPLETE: "Vyzvednutí je dokončeno",
  deliveryStatus_DELIVERY_COMPLETED: "Dodání dokončeno",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Problém s platbou",
  deliveryStatus_IN_PROCESS: "Zpracování objednávky",
  deliveryStatus_READY: "Zpracování objednávky",
  deliveryStatus_DELIVERY_REJECTED: "Dodávka odmítnuta",
  deliveryStatus_SHIPPED: "Expedováno",
  deliveryStatus_TAX_NOT_COMMITTED: "Problém v oblasti daní",
  deliveryStatus_CANCELLED: "Zrušeno",
  statusDisplay_cancelled: "Zrušeno",
  statusDisplay_cancelling: "Probíhá zrušení",
  statusDisplay_completed: "Dokončeno",
  statusDisplay_created: "Vytvořeno",
  statusDisplay_error: "Čeká",
  statusDisplay_Error: "Čeká",
  statusDisplay_processing: "Čeká",
  statusDisplay_open: "Otevřeno",
  statusDisplay_pending: {
    approval: "Čeká na schválení",
    merchant: {
      approval: "Čeká na schválení obchodníka"
    }
  },
  statusDisplay_approved: "Schváleno",
  statusDisplay_rejected: "Zamítnuto",
  statusDisplay_merchant: {
    approved: "Obchodník byl schválen",
    rejected: "Obchodník byl zamítnut"
  },
  statusDisplay_assigned: {
    admin: "Přiřazeno správci"
  },
  consignmentTracking: {
    action: "Sledovat zásilku",
    dialog: {
      header: "Informace o sledování",
      shipped: "Expedováno",
      estimate: "Odhadované dodání",
      carrier: "Dodací služba",
      trackingId: "Číslo sledování",
      noTracking: "Balíček nebyl ze skladu odeslán. Informace o sledování budou k dispozici po odeslání balíčku.",
      loadingHeader: "Sledování konsignace"
    }
  },
  cancellationAndReturn: {
    returnAction: "Vyžádat vrácení",
    cancelAction: "Zrušit položky",
    item: "Položka",
    itemPrice: "Cena položky",
    quantity: "Maximální množství",
    returnQty: "Množství k vrácení",
    cancelQty: "Množství ke zrušení",
    setAll: "Nastavit všechna množství na maximum",
    totalPrice: "Celkem",
    submit: "Odeslat požadavek",
    submitDescription: "Odeslat požadavek. Položky na této stránce byly zahrnuty do požadavku na zrušení.",
    returnSuccess: "Váš požadavek na vrácení ({{rma}}) byl odeslán.",
    cancelSuccess: "Váš požadavek na zrušení byl odeslán (původní objednávka {{orderCode}} bude aktualizována)"
  },
  cancelReplenishment: {
    title: "Zrušit doplnění",
    description: "Zrušit veškeré budoucí objednávky na doplnění?",
    accept: "Ano",
    reject: "Ne",
    cancelSuccess: "Objednávka na doplnění č. {{replenishmentOrderCode}} byla úspěšně zrušena."
  },
  caption: "Obsah objednávky."
};
var orderHistory$g = {
  orderHistory: "Historie objednávky",
  orderId: "Číslo objednávky",
  emptyPurchaseOrderId: "Nic",
  date: "Datum",
  status: "Status",
  PONumber: "Číslo objednávky",
  total: "Celkem",
  noOrders: "Pro tento účet nemáme žádné záznamy objednávek.",
  noReplenishmentOrders: "Pro tento účet nemáme žádné záznamy objednávek na doplnění.",
  startShopping: "Začít nakupovat",
  sortBy: "Řadit podle",
  sortOrders: "Seřadit objednávky",
  replenishmentOrderHistory: "Historie objednávek na doplnění",
  replenishmentOrderId: "Č. doplňování",
  purchaseOrderNumber: "Č. objednávky",
  costCenter: "Nákladové středisko",
  startOn: "Zahájit dne",
  frequency: "Četnost",
  nextOrderDate: "Datum další objednávky",
  cancel: "Zrušit",
  cancelled: "Zrušeno",
  replenishmentHistory: "Historie doplňování",
  notFound: "Nebyly nalezeny žádné objednávky",
  actions: "Akce"
};
var AccountOrderHistoryTabContainer$g = {
  tabs: {
    AccountOrderHistoryComponent: "VŠECHNY OBJEDNÁVKY ({{param}})",
    OrderReturnRequestListComponent: "VRATKY ({{param}})"
  },
  tabPanelContainerRegion: "Skupina s detaily historie objednávky",
  tabPanelContainerRegionGroup: "Skupina s detaily historie objednávky"
};
var returnRequestList$g = {
  returnRequestId: "Číslo vratky",
  orderId: "Číslo objednávky",
  date: "Datum vytvoření",
  status: "Status",
  sortBy: "Řadit podle",
  sortReturns: "Seřadit vratky",
  statusDisplay_APPROVAL_PENDING: "Čeká na schválení",
  statusDisplay_CANCELED: "Zrušeno",
  statusDisplay_CANCELLING: "Ruší se",
  statusDisplay_WAIT: "Počkejte",
  statusDisplay_RECEIVED: "Přijato",
  statusDisplay_RECEIVING: "Přijímá se",
  statusDisplay_APPROVING: "Schvaluje se",
  statusDisplay_REVERSING_PAYMENT: "Probíhá stornování platby",
  statusDisplay_PAYMENT_REVERSED: "Platba byla stornována",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Nepodařilo se stornovat platbu",
  statusDisplay_REVERSING_TAX: "Probíhá stornování daně",
  statusDisplay_TAX_REVERSED: "Daň byla stornována",
  statusDisplay_TAX_REVERSAL_FAILED: "Nepodařilo se stornovat daň",
  statusDisplay_COMPLETED: "Dokončeno"
};
var returnRequest$g = {
  returnRequestId: "Číslo požadavku na vrácení",
  orderCode: "pro objednávku č.",
  status: "Status vratky",
  cancel: "Zrušit požadavek na vrácení",
  item: "Popis",
  itemPrice: "Cena položky",
  returnQty: "Vrácené množství",
  total: "Celkem",
  summary: "Vráceno celkem",
  subtotal: "Mezisoučet",
  deliveryCode: "Náklady na dodání",
  estimatedRefund: "Odhadovaná refundace",
  note: "Celkové sumy jsou odhadované a nemusí zahrnovat příslušné daně a další poplatky.",
  cancelSuccess: "Váš požadavek na vrácení ({{rma}}) byl zrušen.",
  caption: "Obsah objednávky."
};
var reorder$g = {
  button: "Znovu objednat",
  dialog: {
    reorderProducts: "Znovu objednat produkty",
    messages: {
      reviewConfiguration: "Došlo k chybě s konfigurací „{{ productCode}}“.",
      lowStock: "Množství pro {{ productName }}:{{ quantity }}) bylo sníženo na {{ quantityAdded }}.",
      noStock: "{{ productName }} není momentálně skladem.",
      pricingError: "Problém s cenou u „{{ productCode }}“.",
      unresolvableIssues: "Neznámý problém s „{{ productCode }}“.",
      success: "Produkty byly úspěšně přidány do košíku."
    },
    areYouSureToReplaceCart: "Stávající košík bude nahrazen novými položkami. Přejete si pokračovat?",
    cancel: "Zrušit",
    "continue": "Pokračovat"
  }
};
var order$g = {
  orderDetails: orderDetails$g,
  orderHistory: orderHistory$g,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$g,
  returnRequestList: returnRequestList$g,
  returnRequest: returnRequest$g,
  reorder: reorder$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  myAccountV2Order: myAccountV2Order$g,
  order: order$g
};
var myAccountV2OrderHistory$f = {
  heading: "Alle Bestellungen ({{param}})",
  item: "{{param}} Position",
  items: "{{param}} Positionen",
  totalPrice: "Gesamtpreis: {{param}}",
  consignmentCode: "Sendung {{param}}",
  statusDate: "Zuletzt aktualisiert: {{param}}",
  returnProcessed: "Rücksendung bearbeitet: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "Abzuholen - "
  },
  checkoutMode: {
    deliveryEntries: "Zu versenden - "
  },
  checkoutPickupInStore: {
    heading: "Abzuholen - "
  },
  orderListResults: "Liste der Bestellungen",
  orderListPagination: "Paginierung der Bestellliste",
  totalPriceLabel: "Gesamtpreis",
  orderPlaced: "Bestellung aufgegeben am",
  orderCodeLabel: "Bestellcode",
  consignmentDetailLabel: "Sendungsinformationen",
  consignmentNumberLabel: "Sendungsnummer",
  consignmentStatusLabel: "Sendungsstatus",
  consignmentStatusDateLabel: "Zuletzt aktualisiert am",
  estimateDeliveryLabel: "Voraussichtliches Lieferdatum"
};
var myAccountV2OrderDetails$f = {
  returnItems: "Positionen zurücksenden",
  cancelItems: "Positionen stornieren",
  downloadInvoices: "Rechnungen herunterladen",
  viewAllOrders: "Alle Bestellungen anzeigen",
  noInvoices: "Rechnungen noch nicht generiert. Bitte kommen Sie später zurück."
};
var myAccountV2Orders$f = {
  item: "{{value}} Position",
  items: "{{value}} Positionen",
  heading: "Bestellungen und Rücksendungen",
  orderNumber: "Bestellnummer ({{value}})",
  purchasedOn: "Gekauft am: {{value}}",
  orderedItems: "Bestellte Positionen: {{value}}",
  totalPrice: "Gesamtpreis: {{value}}",
  orderDetails: "Bestelldetails",
  orderDetailsLabel: "Bestelldetails für Bestellnummer {{value}} anzeigen",
  orderStatusLabel: "Bestellstatus",
  returnOrder: "Rücksendeauftrag",
  showMore: "Mehr anzeigen",
  showMoreLabel: "Zum Bestellverlauf navigieren"
};
var myAccountV2Order$f = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$f,
  myAccountV2OrderDetails: myAccountV2OrderDetails$f,
  myAccountV2Orders: myAccountV2Orders$f
};
var orderDetails$f = {
  orderId: "Bestellnr.",
  orderNumber: "Bestellnummer",
  replenishmentId: "Nachbestellnr.",
  purchaseOrderId: "Bestellnr.",
  purchaseOrderNumber: "Bestellnummer",
  emptyPurchaseOrderId: "Keine",
  none: "Keine - {{value}}",
  placed: "Aufgegeben",
  placedBy: "Aufgegeben von",
  unit: "Einheit",
  costCenter: "Kostenstelle",
  costCenterAndUnit: "Kostenstelle / Einheit",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Art der Zahlung",
  payByAccount: "Über Konto bezahlen",
  paidByCreditCard: "(mit Kreditkarte bezahlt)",
  status: "Status",
  active: "Aktiv",
  shippedOn: "Versendet am",
  shippingMethod: "Versandart",
  placedOn: "Aufgegeben am",
  startOn: "Start am",
  nextOrderDate: "Nächstes Bestelldatum",
  frequency: "Häufigkeit",
  cancelled: " Storniert",
  deliveryStatus_IN_TRANSIT: "Auf Transportweg",
  deliveryStatus_READY_FOR_PICKUP: "Bereit für Abholung",
  deliveryStatus_READY_FOR_SHIPPING: "Bereit für Versand",
  deliveryStatus_WAITING: "Wartet",
  deliveryStatus_DELIVERING: "Wird geliefert",
  deliveryStatus_PICKPACK: "Wird auf Versand vorbereitet",
  deliveryStatus_PICKUP_COMPLETE: "Abholung abgeschlossen",
  deliveryStatus_DELIVERY_COMPLETED: "Lieferung abgeschlossen",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Zahlungsproblem",
  deliveryStatus_IN_PROCESS: "Bestellungsabwicklung",
  deliveryStatus_READY: "Bestellungsabwicklung",
  deliveryStatus_DELIVERY_REJECTED: "Lieferung abgelehnt",
  deliveryStatus_SHIPPED: "Versendet",
  deliveryStatus_TAX_NOT_COMMITTED: "Steuerproblem",
  deliveryStatus_CANCELLED: "Storniert",
  statusDisplay_cancelled: "Storniert",
  statusDisplay_cancelling: "Stornierung ausstehend",
  statusDisplay_completed: "Abgeschlossen",
  statusDisplay_created: "Erstellt",
  statusDisplay_error: "Ausstehend",
  statusDisplay_Error: "Ausstehend",
  statusDisplay_processing: "Ausstehend",
  statusDisplay_open: "Offen",
  statusDisplay_pending: {
    approval: "Genehmigung ausstehend",
    merchant: {
      approval: "Genehmigung durch Händler ausstehend"
    }
  },
  statusDisplay_approved: "Genehmigt",
  statusDisplay_rejected: "Abgelehnt",
  statusDisplay_merchant: {
    approved: "Vom Händler genehmigt",
    rejected: "Vom Händler abgelehnt"
  },
  statusDisplay_assigned: {
    admin: "Dem Administrator zugeordnet"
  },
  consignmentTracking: {
    action: "Paket nachverfolgen",
    dialog: {
      header: "Nachverfolgungsinformationen",
      shipped: "Versendet",
      estimate: "Voraussichtliche Lieferung",
      carrier: "Lieferservice",
      trackingId: "Sendungsverfolgungsnummer",
      noTracking: "Das Paket wurde nicht aus dem Lager versendet. Die Nachverfolgungsinformationen sind nach dem Versand des Pakets verfügbar.",
      loadingHeader: "Sendungsverfolgung"
    }
  },
  cancellationAndReturn: {
    returnAction: "Rücksendung anfordern",
    cancelAction: "Positionen stornieren",
    item: "Position",
    itemPrice: "Positionspreis",
    quantity: "Maximale Menge",
    returnQty: "Rücksendungsmenge",
    cancelQty: "Stornierungsmenge",
    setAll: "Alle Mengen auf das Maximum festlegen",
    totalPrice: "Gesamtsumme",
    submit: "Anfrage senden",
    submitDescription: "Anfrage senden. Die Positionen auf dieser Seite werden in die Stornierungsanfrage aufgenommen.",
    returnSuccess: "Ihre Rücksendungsanfrage ({{rma}}) wurde abgeschickt",
    cancelSuccess: "Ihre Stornierungsanfrage wurde abgeschickt (ursprüngliche Bestellung {{orderCode}} wird aktualisiert)"
  },
  cancelReplenishment: {
    title: "Nachbestellung stornieren",
    description: "Künftige Nachbestellung stornieren?",
    accept: "Ja",
    reject: "Nein",
    cancelSuccess: "Nachbestellnr. {{replenishmentOrderCode}} wurde erfolgreich storniert"
  },
  caption: "Inhalte der Bestellung."
};
var orderHistory$f = {
  orderHistory: "Bestellverlauf",
  orderId: "Bestellnr.",
  emptyPurchaseOrderId: "Keine",
  date: "Datum",
  status: "Status",
  PONumber: "Bestellnummer",
  total: "Gesamtsumme",
  noOrders: "Für dieses Konto gibt es keine Bestellungsdatensätze.",
  noReplenishmentOrders: "Für dieses Konto gibt es keine Nachbestellungsdatensätze.",
  startShopping: "Einkauf beginnen",
  sortBy: "Sortieren nach",
  sortOrders: "Bestellungen sortieren",
  replenishmentOrderHistory: "Nachbestellverlauf",
  replenishmentOrderId: "Nachbestellnr.",
  purchaseOrderNumber: "Bestellnr.",
  costCenter: "Kostenstelle",
  startOn: "Start am",
  frequency: "Häufigkeit",
  nextOrderDate: "Nächstes Bestelldatum",
  cancel: "Abbrechen",
  cancelled: "Abgebrochen",
  replenishmentHistory: "Nachbestellverlauf",
  notFound: "Keine Bestellungen gefunden",
  actions: "Aktionen"
};
var AccountOrderHistoryTabContainer$f = {
  tabs: {
    AccountOrderHistoryComponent: "ALLE BESTELLUNGEN ({{param}})",
    OrderReturnRequestListComponent: "RÜCKSENDUNGEN ({{param}})"
  },
  tabPanelContainerRegion: "Mit Bestellverlaufdetails gruppieren",
  tabPanelContainerRegionGroup: "Mit Bestellverlaufdetails gruppieren"
};
var returnRequestList$f = {
  returnRequestId: "Rücksendungsnr.",
  orderId: "Bestellnr.",
  date: "Erstellungsdatum",
  status: "Status",
  sortBy: "Sortieren nach",
  sortReturns: "Rücksendungen sortieren",
  statusDisplay_APPROVAL_PENDING: "Genehmigung ausstehend",
  statusDisplay_CANCELED: "Storniert",
  statusDisplay_CANCELLING: "Wird storniert",
  statusDisplay_WAIT: "Warten",
  statusDisplay_RECEIVED: "Erhalten",
  statusDisplay_RECEIVING: "Wird empfangen",
  statusDisplay_APPROVING: "Wird genehmigt",
  statusDisplay_REVERSING_PAYMENT: "Zahlung wird storniert",
  statusDisplay_PAYMENT_REVERSED: "Zahlung storniert",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Zahlungsstornierung fehlgeschlagen",
  statusDisplay_REVERSING_TAX: "Steuer wird storniert",
  statusDisplay_TAX_REVERSED: "Steuer storniert",
  statusDisplay_TAX_REVERSAL_FAILED: "Steuerstornierung fehlgeschlagen",
  statusDisplay_COMPLETED: "Abgeschlossen"
};
var returnRequest$f = {
  returnRequestId: "Nr. der Rücksendungsanfrage",
  orderCode: "Für Bestellnr.",
  status: "Rücksendungstatus",
  cancel: "Rücksendeanfrage stornieren",
  item: "Beschreibung",
  itemPrice: "Positionspreis",
  returnQty: "Rücksendungsmenge",
  total: "Gesamtsumme",
  summary: "Gesamtsumme für Rücksendung",
  subtotal: "Zwischensumme",
  deliveryCode: "Versandkosten",
  estimatedRefund: "Geschätzte Rückerstattung",
  note: "Die Summen werden geschätzt und enthalten möglicherweise keine anwendbaren Steuern oder andere Gebühren.",
  cancelSuccess: "Ihre Rücksendungsanfrage ({{rma}}) wurde storniert",
  caption: "Inhalte der Bestellung."
};
var reorder$f = {
  button: "Erneut bestellen",
  dialog: {
    reorderProducts: "Produkte erneut bestellen",
    messages: {
      reviewConfiguration: "Bei der Konfiguration von \"{{ productCode}}\" ist ein Fehler aufgetreten.",
      lowStock: "Menge für {{ productName }}: {{ quantity }} wurde auf {{ quantityAdded }} verringert.",
      noStock: "{{ productName }} ist zurzeit nicht vorrätig.",
      pricingError: "Preisfindungsproblem mit \"{{ productCode }}\".",
      unresolvableIssues: "Nicht erkanntes Problem mit \"{{ productCode }}\".",
      success: "Produkte wurden dem Warenkorb erfolgreich hinzugefügt"
    },
    areYouSureToReplaceCart: "Der derzeitige Warenkorb wird durch neue Positionen ersetzt. Möchten Sie fortfahren?",
    cancel: "Abbrechen",
    "continue": "Weiter"
  }
};
var order$f = {
  orderDetails: orderDetails$f,
  orderHistory: orderHistory$f,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$f,
  returnRequestList: returnRequestList$f,
  returnRequest: returnRequest$f,
  reorder: reorder$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  myAccountV2Order: myAccountV2Order$f,
  order: order$f
};
var myAccountV2OrderHistory$e = {
  heading: "All Orders ({{param}})",
  item: "{{param}} Item",
  items: "{{param}} Items",
  totalPrice: "Total Price: {{param}}",
  consignmentCode: "Consignment {{param}}",
  statusDate: "Last Updated: {{param}}",
  returnProcessed: "Return Processed: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "To Be Picked Up - "
  },
  checkoutMode: {
    deliveryEntries: "To Be Shipped - "
  },
  checkoutPickupInStore: {
    heading: "To Be Picked Up - "
  },
  orderListResults: "Orders List",
  orderListPagination: "Orders List pagination",
  totalPriceLabel: "Total Price",
  orderPlaced: "Order Placed On",
  orderCodeLabel: "Order Code",
  consignmentDetailLabel: "Consignment Information",
  consignmentNumberLabel: "Consignment Number",
  consignmentStatusLabel: "Consignment Status",
  consignmentStatusDateLabel: "Last Updated On",
  estimateDeliveryLabel: "Estimated Delivery Date"
};
var myAccountV2OrderDetails$e = {
  returnItems: "Return Items",
  cancelItems: "Cancel Items",
  downloadInvoices: "Download Invoices",
  viewAllOrders: "View All Orders",
  noInvoices: "Invoices are not generated yet. Please come back later."
};
var myAccountV2Orders$e = {
  item: "{{value}} Item",
  items: "{{value}} Items",
  heading: "Orders And Returns",
  orderNumber: "Order Number ({{value}})",
  purchasedOn: "Purchased On: {{value}}",
  orderedItems: "Ordered Items: {{value}}",
  totalPrice: "Total Price: {{value}}",
  orderDetails: "Order Details",
  orderDetailsLabel: "Show order details for order number {{value}}",
  orderStatusLabel: "Order Status",
  returnOrder: "Return Order",
  showMore: "Show More",
  showMoreLabel: "Navigate to order history"
};
var myAccountV2Order$e = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$e,
  myAccountV2OrderDetails: myAccountV2OrderDetails$e,
  myAccountV2Orders: myAccountV2Orders$e
};
var orderDetails$e = {
  orderId: "Order #",
  orderNumber: "Order Number",
  replenishmentId: "Replenishment #",
  purchaseOrderId: "Purchase Order #",
  purchaseOrderNumber: "Purchase Order Number",
  emptyPurchaseOrderId: "None",
  none: "None {{value}}",
  placed: "Placed",
  placedBy: "Placed By",
  unit: "Unit",
  costCenter: "Cost Center",
  costCenterAndUnit: "Cost Center / Unit",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Method of Payment",
  payByAccount: "Pay by Account",
  paidByCreditCard: "(paid by credit card)",
  status: "Status",
  active: "Active",
  shippedOn: "Shipped on",
  shippingMethod: "Shipping Method",
  placedOn: "Placed on",
  startOn: "Start On",
  nextOrderDate: "Next Order Date",
  frequency: "Frequency",
  cancelled: " Cancelled",
  deliveryStatus_IN_TRANSIT: "In Transit",
  deliveryStatus_READY_FOR_PICKUP: "Ready for Pickup",
  deliveryStatus_READY_FOR_SHIPPING: "Ready for Shipping",
  deliveryStatus_WAITING: "Waiting",
  deliveryStatus_DELIVERING: "Delivering",
  deliveryStatus_PICKPACK: "Preparing for Shipment",
  deliveryStatus_PICKUP_COMPLETE: "Pickup Complete",
  deliveryStatus_DELIVERY_COMPLETED: "Delivery Complete",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Payment Issue",
  deliveryStatus_IN_PROCESS: "Order Processing",
  deliveryStatus_READY: "Order Processing",
  deliveryStatus_DELIVERY_REJECTED: "Delivery Rejected",
  deliveryStatus_SHIPPED: "Shipped",
  deliveryStatus_TAX_NOT_COMMITTED: "Tax Issue",
  deliveryStatus_CANCELLED: "Cancelled",
  statusDisplay_cancelled: "Cancelled",
  statusDisplay_cancelling: "Cancel Pending",
  statusDisplay_completed: "Completed",
  statusDisplay_created: "Created",
  statusDisplay_error: "Pending",
  statusDisplay_Error: "Pending",
  statusDisplay_processing: "Pending",
  statusDisplay_open: "Open",
  statusDisplay_pending: {
    approval: "Pending Approval",
    merchant: {
      approval: "Pending Merchant Approval"
    }
  },
  statusDisplay_approved: "Approved",
  statusDisplay_rejected: "Rejected",
  statusDisplay_merchant: {
    approved: "Merchant Approved",
    rejected: "Merchant Rejected"
  },
  statusDisplay_assigned: {
    admin: "Assigned To Administrator"
  },
  consignmentTracking: {
    action: "Track package",
    dialog: {
      header: "Tracking Information",
      shipped: "Shipped",
      estimate: "Estimated Delivery",
      carrier: "Delivery Service",
      trackingId: "Tracking Number",
      noTracking: "The package has not been dispatched from the warehouse. The tracking information will be available after the package is shipped.",
      loadingHeader: "Consignment Tracking"
    }
  },
  cancellationAndReturn: {
    returnAction: "Request a Return",
    cancelAction: "Cancel Items",
    item: "Item",
    itemPrice: "Item Price",
    quantity: "Max Quantity",
    returnQty: "Quantity to Return",
    cancelQty: "Quantity to Cancel",
    setAll: "Set all quantities to maximum",
    totalPrice: "Total",
    submit: "Submit Request",
    submitDescription: "Submit Request. Items on this page will be included in the cancellation request.",
    returnSuccess: "Your return request ({{rma}}) was submitted",
    cancelSuccess: "Your cancellation request was submitted (original order {{orderCode}} will be updated)"
  },
  cancelReplenishment: {
    title: "Cancel Replenishment",
    description: "Cancel any future replenishment order?",
    accept: "Yes",
    reject: "No",
    cancelSuccess: "Replenishment order #{{replenishmentOrderCode}} has been successfully cancelled"
  },
  caption: "Order contents."
};
var orderHistory$e = {
  orderHistory: "Order history",
  orderId: "Order #",
  emptyPurchaseOrderId: "None",
  date: "Date",
  status: "Status",
  PONumber: "P.O. Number",
  total: "Total",
  noOrders: "We have no order records for this account.",
  noReplenishmentOrders: "We have no replenishment order records for this account.",
  startShopping: "Start Shopping",
  sortBy: "Sort by",
  sortOrders: "Sort orders",
  replenishmentOrderHistory: "Replenishment Order History",
  replenishmentOrderId: "Replenishment #",
  purchaseOrderNumber: "PO #",
  costCenter: "Cost Center",
  startOn: "Start On",
  frequency: "Frequency",
  nextOrderDate: "Next Order Date",
  cancel: "Cancel",
  cancelled: "Cancelled",
  replenishmentHistory: "Replenishment History",
  notFound: "No Orders Found",
  actions: "Actions"
};
var AccountOrderHistoryTabContainer$e = {
  tabs: {
    AccountOrderHistoryComponent: "ALL ORDERS ({{param}})",
    OrderReturnRequestListComponent: "RETURNS ({{param}})"
  },
  tabPanelContainerRegion: "Group with order history details",
  tabPanelContainerRegionGroup: "Group with order history details"
};
var returnRequestList$e = {
  returnRequestId: "Return #",
  orderId: "Order #",
  date: "Date Created",
  status: "Status",
  sortBy: "Sort by",
  sortReturns: "Sort returns",
  statusDisplay_APPROVAL_PENDING: "Approval Pending",
  statusDisplay_CANCELED: "Cancelled",
  statusDisplay_CANCELLING: "Cancelling",
  statusDisplay_WAIT: "Wait",
  statusDisplay_RECEIVED: "Received",
  statusDisplay_RECEIVING: "Receiving",
  statusDisplay_APPROVING: "Approving",
  statusDisplay_REVERSING_PAYMENT: "Reversing Payment",
  statusDisplay_PAYMENT_REVERSED: "Payment Reversed",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Payment Reversal Failed",
  statusDisplay_REVERSING_TAX: "Reversing Tax",
  statusDisplay_TAX_REVERSED: "Tax Reversed",
  statusDisplay_TAX_REVERSAL_FAILED: "Tax Reversal Failed",
  statusDisplay_COMPLETED: "Completed"
};
var returnRequest$e = {
  returnRequestId: "Return Request #",
  orderCode: "For Order #",
  status: "Return status",
  cancel: "Cancel Return Request",
  item: "Description",
  itemPrice: "Item Price",
  returnQty: "Return Quantity",
  total: "Total",
  summary: "Return Totals",
  subtotal: "Subtotal",
  deliveryCode: "Delivery cost",
  estimatedRefund: "Estimated refund",
  note: "The totals are estimated and may not include applicable taxes or other charges.",
  cancelSuccess: "Your return request ({{rma}}) was cancelled",
  caption: "Order contents."
};
var reorder$e = {
  button: "Reorder",
  dialog: {
    reorderProducts: "Reorder products",
    messages: {
      reviewConfiguration: "An error occurred with \"{{ productCode}}\" configuration.",
      lowStock: "Quantity for {{ productName }}: {{ quantity }} has been reduced to {{ quantityAdded }}.",
      noStock: "{{ productName }} is currently out of stock.",
      pricingError: "Pricing problem with \"{{ productCode }}\".",
      unresolvableIssues: "Unrecognized problem with \"{{ productCode }}\".",
      success: "Products have been successfully added to the cart"
    },
    areYouSureToReplaceCart: "Current cart will be replaced with new items. Do you wish to continue?",
    cancel: "Cancel",
    "continue": "Continue"
  }
};
var order$e = {
  orderDetails: orderDetails$e,
  orderHistory: orderHistory$e,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$e,
  returnRequestList: returnRequestList$e,
  returnRequest: returnRequest$e,
  reorder: reorder$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  myAccountV2Order: myAccountV2Order$e,
  order: order$e
};
var myAccountV2OrderHistory$d = {
  heading: "Todos los pedidos ({{param}})",
  item: "{{param}} pedido",
  items: "{{param}} pedidos",
  totalPrice: "Precio total: {{param}}",
  consignmentCode: "Consignación {{param}}",
  statusDate: "Última actualización: {{param}}",
  returnProcessed: "Devolución procesada: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "Para recoger - "
  },
  checkoutMode: {
    deliveryEntries: "Para enviar - "
  },
  checkoutPickupInStore: {
    heading: "Para recoger - "
  },
  orderListResults: "Lista de pedidos",
  orderListPagination: "Paginación de la lista de pedidos",
  totalPriceLabel: "Precio total",
  orderPlaced: "Fecha en que se realizó el pedido",
  orderCodeLabel: "Código de pedido",
  consignmentDetailLabel: "Información de consignación",
  consignmentNumberLabel: "Número de consignación",
  consignmentStatusLabel: "Estado de consignación",
  consignmentStatusDateLabel: "Fecha de la última actualización",
  estimateDeliveryLabel: "Fecha de entrega estimada"
};
var myAccountV2OrderDetails$d = {
  returnItems: "Devolver artículos",
  cancelItems: "Cancelar artículos",
  downloadInvoices: "Descargar facturas",
  viewAllOrders: "Ver todos los pedidos",
  noInvoices: "Aún no se generaron facturas. Vuelva más tarde."
};
var myAccountV2Orders$d = {
  item: "{{value}} pedido",
  items: "{{value}} pedidos",
  heading: "Pedidos y devoluciones",
  orderNumber: "Número de pedido ({{value}})",
  purchasedOn: "Fecha de compra: {{value}}",
  orderedItems: "Artículos pedidos: {{value}}",
  totalPrice: "Precio total: {{value}}",
  orderDetails: "Detalles del pedido",
  orderDetailsLabel: "Mostrar detalles del pedido para  el número de pedido {{value}}",
  orderStatusLabel: "Estado del pedido",
  returnOrder: "Pedido de devolución",
  showMore: "Mostrar más",
  showMoreLabel: "Ir al historial de pedidos"
};
var myAccountV2Order$d = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$d,
  myAccountV2OrderDetails: myAccountV2OrderDetails$d,
  myAccountV2Orders: myAccountV2Orders$d
};
var orderDetails$d = {
  orderId: "Número de pedido",
  orderNumber: "Número de pedido",
  replenishmentId: "Número de reaprovisionamiento",
  purchaseOrderId: "Número de pedido de compra",
  purchaseOrderNumber: "Número de pedido de compra",
  emptyPurchaseOrderId: "Ninguno",
  none: "Ninguno {{value}}",
  placed: "Realizado",
  placedBy: "Realizado por",
  unit: "Unidad",
  costCenter: "Centro de costes",
  costCenterAndUnit: "Centro de costes / Unidad",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Método de pago",
  payByAccount: "Pagar por cuenta",
  paidByCreditCard: "(pagado con tarjeta de crédito)",
  status: "Estado",
  active: "Activo",
  shippedOn: "Enviado el",
  shippingMethod: "Método de envío",
  placedOn: "Realizado el",
  startOn: "Fecha de inicio",
  nextOrderDate: "Fecha del próximo pedido",
  frequency: "Frecuencia",
  cancelled: " Cancelado",
  deliveryStatus_IN_TRANSIT: "En tránsito",
  deliveryStatus_READY_FOR_PICKUP: "Listo para la recogida",
  deliveryStatus_READY_FOR_SHIPPING: "Listo para el envío",
  deliveryStatus_WAITING: "Esperando",
  deliveryStatus_DELIVERING: "Entrega",
  deliveryStatus_PICKPACK: "Preparar para el envío",
  deliveryStatus_PICKUP_COMPLETE: "Recogida completa",
  deliveryStatus_DELIVERY_COMPLETED: "Entrega completa",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Emisión de pago",
  deliveryStatus_IN_PROCESS: "Procesamiento del pedido",
  deliveryStatus_READY: "Procesamiento del pedido",
  deliveryStatus_DELIVERY_REJECTED: "Entrega rechazada",
  deliveryStatus_SHIPPED: "Enviado",
  deliveryStatus_TAX_NOT_COMMITTED: "Emisión de impuesto",
  deliveryStatus_CANCELLED: "Cancelado",
  statusDisplay_cancelled: "Cancelado",
  statusDisplay_cancelling: "Pendiente de cancelación",
  statusDisplay_completed: "Completado",
  statusDisplay_created: "Creado",
  statusDisplay_error: "Pendiente",
  statusDisplay_Error: "Pendiente",
  statusDisplay_processing: "Pendiente",
  statusDisplay_open: "Abierto",
  statusDisplay_pending: {
    approval: "Aprobación pendiente",
    merchant: {
      approval: "Aprobación de comerciante pendiente"
    }
  },
  statusDisplay_approved: "Aprobado",
  statusDisplay_rejected: "Rechazado",
  statusDisplay_merchant: {
    approved: "Aprobado por el comerciante",
    rejected: "Rechazado por el comerciante"
  },
  statusDisplay_assigned: {
    admin: "Asignado al administrador"
  },
  consignmentTracking: {
    action: "Seguimiento del paquete",
    dialog: {
      header: "Información de seguimiento",
      shipped: "Enviado",
      estimate: "Entrega estimada",
      carrier: "Servicio de entrega",
      trackingId: "Número de seguimiento",
      noTracking: "El paquete no se envió desde el almacén. La información de seguimiento estará disponible una vez que se envíe el paquete.",
      loadingHeader: "Seguimiento de consignación"
    }
  },
  cancellationAndReturn: {
    returnAction: "Solicitar una devolución",
    cancelAction: "Cancelar elementos",
    item: "Artículo",
    itemPrice: "Precio del artículo",
    quantity: "Cantidad máx.",
    returnQty: "Cantidad a devolver",
    cancelQty: "Cantidad a cancelar",
    setAll: "Definir todas las cantidades al máximo",
    totalPrice: "Total",
    submit: "Enviar solicitud",
    submitDescription: "Enviar solicitud. Los artículos de esta página se incluirán en la solicitud de cancelación.",
    returnSuccess: "Se envió su solicitud de devolución ({{rma}})",
    cancelSuccess: "Se envió su solicitud de cancelación (se actualizará el pedido original {{orderCode}})"
  },
  cancelReplenishment: {
    title: "Cancelar reaprovisionamiento",
    description: "¿Desea cancelar cualquier pedido de reaprovisionamiento?",
    accept: "Sí",
    reject: "No",
    cancelSuccess: "El pedido de reaprovisionamiento número {{replenishmentOrderCode}} se canceló correctamente"
  },
  caption: "Contenidos del pedido."
};
var orderHistory$d = {
  orderHistory: "Historial de pedidos",
  orderId: "Número de pedido",
  emptyPurchaseOrderId: "Ninguno",
  date: "Fecha",
  status: "Estado",
  PONumber: "Número de pedido de cliente",
  total: "Total",
  noOrders: "No tenemos registros de pedidos para esta cuenta.",
  noReplenishmentOrders: "No tenemos registros de pedido de reaprovisionamiento para esta cuenta.",
  startShopping: "Comenzar compra",
  sortBy: "Ordenar por",
  sortOrders: "Órdenes de clasificación",
  replenishmentOrderHistory: "Historial de pedidos de reaprovisionamiento",
  replenishmentOrderId: "Número de reaprovisionamiento",
  purchaseOrderNumber: "Número de pedido",
  costCenter: "Centro de costes",
  startOn: "Fecha de inicio",
  frequency: "Frecuencia",
  nextOrderDate: "Fecha del próximo pedido",
  cancel: "Cancelar",
  cancelled: "Cancelada",
  replenishmentHistory: "Historial de reaprovisionamiento",
  notFound: "No existen pedidos",
  actions: "Acciones"
};
var AccountOrderHistoryTabContainer$d = {
  tabs: {
    AccountOrderHistoryComponent: "TODOS LOS PEDIDOS ({{param}})",
    OrderReturnRequestListComponent: "DEVOLUCIONES ({{param}})"
  },
  tabPanelContainerRegion: "Grupo con información del historial de pedidos",
  tabPanelContainerRegionGroup: "Grupo con información del historial de pededidos"
};
var returnRequestList$d = {
  returnRequestId: "Número de devolución",
  orderId: "Número de pedido",
  date: "Fecha de creación",
  status: "Estado",
  sortBy: "Ordenar por",
  sortReturns: "Ordenar devoluciones",
  statusDisplay_APPROVAL_PENDING: "Aprobación pendiente",
  statusDisplay_CANCELED: "Cancelada",
  statusDisplay_CANCELLING: "Cancelando",
  statusDisplay_WAIT: "En espera",
  statusDisplay_RECEIVED: "Recibida",
  statusDisplay_RECEIVING: "Recibiendo",
  statusDisplay_APPROVING: "Aprobando",
  statusDisplay_REVERSING_PAYMENT: "Anulando pago",
  statusDisplay_PAYMENT_REVERSED: "Pago anulado",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Falló anulación de pago",
  statusDisplay_REVERSING_TAX: "Anulando impuesto",
  statusDisplay_TAX_REVERSED: "Impuesto anulado",
  statusDisplay_TAX_REVERSAL_FAILED: "Falló anulación de impuestos",
  statusDisplay_COMPLETED: "Completado"
};
var returnRequest$d = {
  returnRequestId: "Número de solicitud de devolución",
  orderCode: "Para el número de pedido",
  status: "Estado de la devolución",
  cancel: "Cancelar solicitud de devolución",
  item: "Descripción",
  itemPrice: "Precio del artículo",
  returnQty: "Cantidad de devolución",
  total: "Total",
  summary: "Total de devoluciones",
  subtotal: "Subtotal",
  deliveryCode: "Coste de entrega",
  estimatedRefund: "Reembolso estimado",
  note: "Los totales son estimados y pueden no incluir impuestos aplicables u otros cargos.",
  cancelSuccess: "Se canceló su solicitud de devolución ({{rma}})",
  caption: "Contenidos del pedido."
};
var reorder$d = {
  button: "Volver a realizar el pedido",
  dialog: {
    reorderProducts: "Volver a realizar el pedido de productos",
    messages: {
      reviewConfiguration: "Se produjo un error con la configuración \"{{ productCode}}\".",
      lowStock: "La cantidad de {{ productName }}: {{ quantity }} se ha reducido a {{ quantityAdded }}.",
      noStock: "{{ productName }} no está disponible actualmente en el stock.",
      pricingError: "Problemas de precios con \"{{ productCode }}\".",
      unresolvableIssues: "Problema no reconocido con \"{{ productCode }}\".",
      success: "Los productos se han añadido a la cesta correctamente"
    },
    areYouSureToReplaceCart: "La ces actual se reemplazará con artículos nuevos. ¿Desea continuar?ta",
    cancel: "Cancelar",
    "continue": "Continuar"
  }
};
var order$d = {
  orderDetails: orderDetails$d,
  orderHistory: orderHistory$d,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$d,
  returnRequestList: returnRequestList$d,
  returnRequest: returnRequest$d,
  reorder: reorder$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  myAccountV2Order: myAccountV2Order$d,
  order: order$d
};
var myAccountV2OrderHistory$c = {
  heading: "Todos los pedidos ({{param}})",
  item: "{{param}} pedido",
  items: "{{param}} pedidos",
  totalPrice: "Precio total: {{param}}",
  consignmentCode: "Consignación {{param}}",
  statusDate: "Última actualización: {{param}}",
  returnProcessed: "Devolución procesada: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "Para recoger - "
  },
  checkoutMode: {
    deliveryEntries: "Para enviar - "
  },
  checkoutPickupInStore: {
    heading: "Para recoger - "
  },
  orderListResults: "Lista de pedidos",
  orderListPagination: "Paginación de la lista de pedidos",
  totalPriceLabel: "Precio total",
  orderPlaced: "Fecha en que se realizó el pedido",
  orderCodeLabel: "Código de pedido",
  consignmentDetailLabel: "Información de consignación",
  consignmentNumberLabel: "Número de consignación",
  consignmentStatusLabel: "Estado de consignación",
  consignmentStatusDateLabel: "Fecha de la última actualización",
  estimateDeliveryLabel: "Fecha de entrega estimada"
};
var myAccountV2OrderDetails$c = {
  returnItems: "Devolver artículos",
  cancelItems: "Cancelar artículos",
  downloadInvoices: "Descargar facturas",
  viewAllOrders: "Ver todos los pedidos",
  noInvoices: "Aún no se generaron facturas. Vuelva más tarde."
};
var myAccountV2Orders$c = {
  item: "{{value}} pedido",
  items: "{{value}} pedidos",
  heading: "Pedidos y devoluciones",
  orderNumber: "Número de pedido ({{value}})",
  purchasedOn: "Fecha de compra: {{value}}",
  orderedItems: "Artículos pedidos: {{value}}",
  totalPrice: "Precio total: {{value}}",
  orderDetails: "Detalles del pedido",
  orderDetailsLabel: "Mostrar detalles del pedido para  el número de pedido {{value}}",
  orderStatusLabel: "Estado del pedido",
  returnOrder: "Pedido de devolución",
  showMore: "Mostrar más",
  showMoreLabel: "Ir al historial de pedidos"
};
var myAccountV2Order$c = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$c,
  myAccountV2OrderDetails: myAccountV2OrderDetails$c,
  myAccountV2Orders: myAccountV2Orders$c
};
var orderDetails$c = {
  orderId: "Número de pedido",
  orderNumber: "Número de pedido",
  replenishmentId: "Número de reaprovisionamiento",
  purchaseOrderId: "Número de pedido de compra",
  purchaseOrderNumber: "Número de pedido de compra",
  emptyPurchaseOrderId: "Ninguno",
  none: "Ninguno {{value}}",
  placed: "Realizado",
  placedBy: "Realizado por",
  unit: "Unidad",
  costCenter: "Centro de costos",
  costCenterAndUnit: "Centro de costos / Unidad",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Método de pago",
  payByAccount: "Pagar por cuenta",
  paidByCreditCard: "(pagada con tarjeta de crédito)",
  status: "Estado",
  active: "Activo",
  shippedOn: "Enviado el",
  shippingMethod: "Método de envío",
  placedOn: "Realizado el",
  startOn: "Fecha de inicio",
  nextOrderDate: "Próxima fecha de pedido",
  frequency: "Frecuencia",
  cancelled: " Cancelado",
  deliveryStatus_IN_TRANSIT: "En tránsito",
  deliveryStatus_READY_FOR_PICKUP: "Listo para el retiro",
  deliveryStatus_READY_FOR_SHIPPING: "Listo para el envío",
  deliveryStatus_WAITING: "Esperando",
  deliveryStatus_DELIVERING: "Entregando",
  deliveryStatus_PICKPACK: "En preparación para el envío",
  deliveryStatus_PICKUP_COMPLETE: "Recolección completa",
  deliveryStatus_DELIVERY_COMPLETED: "Entrega completa",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Pago emitido",
  deliveryStatus_IN_PROCESS: "Procesamiento del pedido",
  deliveryStatus_READY: "Procesamiento del pedido",
  deliveryStatus_DELIVERY_REJECTED: "Entrega rechazada",
  deliveryStatus_SHIPPED: "Enviado",
  deliveryStatus_TAX_NOT_COMMITTED: "Emisión de impuestos",
  deliveryStatus_CANCELLED: "Cancelado",
  statusDisplay_cancelled: "Cancelado",
  statusDisplay_cancelling: "Pendiente de cancelación",
  statusDisplay_completed: "Finalizado",
  statusDisplay_created: "Creado",
  statusDisplay_error: "Pendiente",
  statusDisplay_Error: "Pendiente",
  statusDisplay_processing: "Pendiente",
  statusDisplay_open: "Abierto",
  statusDisplay_pending: {
    approval: "Aprobación pendiente",
    merchant: {
      approval: "Aprobación del comerciante pendiente"
    }
  },
  statusDisplay_approved: "Aprobado",
  statusDisplay_rejected: "Rechazado",
  statusDisplay_merchant: {
    approved: "Aprobado por el comerciante",
    rejected: "Rechazado por el comerciante"
  },
  statusDisplay_assigned: {
    admin: "Asignado al administrador"
  },
  consignmentTracking: {
    action: "Rastrear el paquete",
    dialog: {
      header: "Información de seguimiento",
      shipped: "Enviado",
      estimate: "Entrega estimada",
      carrier: "Servicio de entrega",
      trackingId: "Número de seguimiento",
      noTracking: "El paquete no se envió desde el almacén. La información de seguimiento estará disponible una vez que se envíe el paquete.",
      loadingHeader: "Seguimiento de consignaciones"
    }
  },
  cancellationAndReturn: {
    returnAction: "Solicitar una devolución",
    cancelAction: "Cancelar elementos",
    item: "Elemento",
    itemPrice: "Precio del producto",
    quantity: "Cantidad máx.",
    returnQty: "Cantidad a devolver",
    cancelQty: "Cantidad a cancelar",
    setAll: "Configurar todas las cantidades al máximo",
    totalPrice: "Total",
    submit: "Enviar solicitud",
    submitDescription: "Enviar solicitud. Los productos de esta página se incluirán en la solicitud de cancelación.",
    returnSuccess: "Se envió su solicitud de devolución ({{rma}})",
    cancelSuccess: "Se envió su solicitud de cancelación (se actualizará la orden original {{orderCode}})"
  },
  cancelReplenishment: {
    title: "Cancelar reaprovisionamiento",
    description: "¿Desea cancelar cualquier orden de reaprovisionamiento?",
    accept: "Sí",
    reject: "No",
    cancelSuccess: "La orden de reaprovisionamiento número {{replenishmentOrderCode}} se canceló correctamente"
  },
  caption: "Contenidos de la orden."
};
var orderHistory$c = {
  orderHistory: "Historial de órdenes",
  orderId: "Número de pedido",
  emptyPurchaseOrderId: "Ninguno",
  date: "Fecha",
  status: "Estado",
  PONumber: "Número del pedido de compra",
  total: "Total",
  noOrders: "No tenemos registros de pedidos para esta cuenta.",
  noReplenishmentOrders: "No tenemos registros de órdenes de reaprovisionamiento para esta cuenta.",
  startShopping: "Comenzar compra",
  sortBy: "Clasificar por",
  sortOrders: "Órdenes de clasificación",
  replenishmentOrderHistory: "Historial de órdenes de reaprovisionamiento",
  replenishmentOrderId: "Número de reaprovisionamiento",
  purchaseOrderNumber: "Número de pedido de compra",
  costCenter: "Centro de costos",
  startOn: "Fecha de inicio",
  frequency: "Frecuencia",
  nextOrderDate: "Próxima fecha de pedido",
  cancel: "Cancelar",
  cancelled: "Cancelado",
  replenishmentHistory: "Historial de reaprovisionamiento",
  notFound: "No existen pedidos",
  actions: "Acciones"
};
var AccountOrderHistoryTabContainer$c = {
  tabs: {
    AccountOrderHistoryComponent: "TODOS LOS PEDIDOS ({{param}})",
    OrderReturnRequestListComponent: "DEVOLUCIONES ({{param}})"
  },
  tabPanelContainerRegion: "Grupo con datos del historial del pedido",
  tabPanelContainerRegionGroup: "Grupo con datos del historial del pedido"
};
var returnRequestList$c = {
  returnRequestId: "Número de devolución",
  orderId: "Número de pedido",
  date: "Fecha de creación",
  status: "Estado",
  sortBy: "Clasificar por",
  sortReturns: "Clasificar devoluciones",
  statusDisplay_APPROVAL_PENDING: "Aprobación pendiente",
  statusDisplay_CANCELED: "Cancelado",
  statusDisplay_CANCELLING: "Cancelando",
  statusDisplay_WAIT: "Espere",
  statusDisplay_RECEIVED: "Recibido",
  statusDisplay_RECEIVING: "Recibiendo",
  statusDisplay_APPROVING: "Aprobando",
  statusDisplay_REVERSING_PAYMENT: "Capturando pago",
  statusDisplay_PAYMENT_REVERSED: "Pago capturado",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Error de pago",
  statusDisplay_REVERSING_TAX: "Capturando impuesto",
  statusDisplay_TAX_REVERSED: "Impuesto capturado",
  statusDisplay_TAX_REVERSAL_FAILED: "Error de pago",
  statusDisplay_COMPLETED: "Finalizado"
};
var returnRequest$c = {
  returnRequestId: "Número de pedido de devolución",
  orderCode: "Para el número de orden",
  status: "Estado de la devolución",
  cancel: "Cancelar pedido de devolución",
  item: "Descripción",
  itemPrice: "Precio del producto",
  returnQty: "Cantidad de devolución",
  total: "Total",
  summary: "Totales de devolución",
  subtotal: "Subtotal",
  deliveryCode: "Costo de entrega",
  estimatedRefund: "Reembolso estimado",
  note: "Los totales son estimados y pueden no incluir impuestos aplicables u otros cargos.",
  cancelSuccess: "Se canceló su pedido de devolución ({{rma}}).",
  caption: "Contenidos del pedido."
};
var reorder$c = {
  button: "Volver a pedir",
  dialog: {
    reorderProducts: "Volver a pedir productos",
    messages: {
      reviewConfiguration: "Se produjo un error con la configuración \"{{ productCode}}\".",
      lowStock: "La cantidad de {{ productName }}: {{ quantity }} se redujo a {{ quantityAdded }}.",
      noStock: "{{ productName }} no está disponible actualmente en el stock.",
      pricingError: "Problemas de precios con \"{{ productCode }}\".",
      unresolvableIssues: "Problema no reconocido con \"{{ productCode }}\".",
      success: "Los productos se agregaron al carro correctamente"
    },
    areYouSureToReplaceCart: "El carro actual se reemplezará con nuevos artículos. ¿Desea continuar?",
    cancel: "Cancelar",
    "continue": "Continuar"
  }
};
var order$c = {
  orderDetails: orderDetails$c,
  orderHistory: orderHistory$c,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$c,
  returnRequestList: returnRequestList$c,
  returnRequest: returnRequest$c,
  reorder: reorder$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  myAccountV2Order: myAccountV2Order$c,
  order: order$c
};
var myAccountV2OrderHistory$b = {
  heading: "Toutes les commandes ({{param}})",
  item: "{{param}} article",
  items: "{{param}} articles",
  totalPrice: "Prix total : {{param}}",
  consignmentCode: "Expédition {{param}}",
  statusDate: "Dernière mise à jour : {{param}}",
  returnProcessed: "Retour traité : {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "À retirer - "
  },
  checkoutMode: {
    deliveryEntries: "À expédier - "
  },
  checkoutPickupInStore: {
    heading: "À retirer - "
  },
  orderListResults: "Liste des commandes",
  orderListPagination: "Pagination de la liste des commandes",
  totalPriceLabel: "Prix total",
  orderPlaced: "Commande passée le",
  orderCodeLabel: "Code de la commande",
  consignmentDetailLabel: "Informations d'expédition",
  consignmentNumberLabel: "Numéro d'expédition",
  consignmentStatusLabel: "Statut d'expédition",
  consignmentStatusDateLabel: "Dernière mise à jour le",
  estimateDeliveryLabel: "Date de livraison estimée"
};
var myAccountV2OrderDetails$b = {
  returnItems: "Retourner des articles",
  cancelItems: "Annuler des articles",
  downloadInvoices: "Télécharger les factures",
  viewAllOrders: "Afficher toutes les commandes",
  noInvoices: "Les factures ne sont pas encore générées. Veuillez repasser plus tard."
};
var myAccountV2Orders$b = {
  item: "{{value}} article",
  items: "{{value}} articles",
  heading: "Commandes et  retours",
  orderNumber: "Numéro de commande ({{value}})",
  purchasedOn: "Acheté le : {{value}}",
  orderedItems: "Articles commandés : {{value}}",
  totalPrice: "Prix total : {{value}}",
  orderDetails: "Détails de la commande",
  orderDetailsLabel: "Afficher les détails de la commande pour le numéro de commande {{value}}",
  orderStatusLabel: "Statut de la commande",
  returnOrder: "Retourner la commande",
  showMore: "Afficher plus",
  showMoreLabel: "Naviguer vers l'historique des commandes"
};
var myAccountV2Order$b = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$b,
  myAccountV2OrderDetails: myAccountV2OrderDetails$b,
  myAccountV2Orders: myAccountV2Orders$b
};
var orderDetails$b = {
  orderId: "N° de commande",
  orderNumber: "Numéro de commande",
  replenishmentId: "Nº de réapprovisionnement",
  purchaseOrderId: "Nº de commande d'achat",
  purchaseOrderNumber: "Numéro de commande d'achat",
  emptyPurchaseOrderId: "Aucune",
  none: "Aucune {{value}}",
  placed: "Passée",
  placedBy: "Passée par",
  unit: "Unité",
  costCenter: "Centre de coûts",
  costCenterAndUnit: "Centre de coûts / Unité",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Mode de paiement",
  payByAccount: "Payer par compte",
  paidByCreditCard: "(payée par carte de crédit)",
  status: "Statut",
  active: "Active",
  shippedOn: "Expédiée le",
  shippingMethod: "Mode de livraison",
  placedOn: "Passée le",
  startOn: "Début le",
  nextOrderDate: "Prochaine date de commande",
  frequency: "Fréquence",
  cancelled: " Annulée",
  deliveryStatus_IN_TRANSIT: "En transit",
  deliveryStatus_READY_FOR_PICKUP: "Prête pour le retrait",
  deliveryStatus_READY_FOR_SHIPPING: "Prête pour expédition",
  deliveryStatus_WAITING: "En attente",
  deliveryStatus_DELIVERING: "En cours de livraison",
  deliveryStatus_PICKPACK: "En cours de préparation pour expédition",
  deliveryStatus_PICKUP_COMPLETE: "Retrait terminé",
  deliveryStatus_DELIVERY_COMPLETED: "Livraison terminée",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Problème de paiement",
  deliveryStatus_IN_PROCESS: "Traitement de la commande",
  deliveryStatus_READY: "Traitement de la commande",
  deliveryStatus_DELIVERY_REJECTED: "Livraison refusée",
  deliveryStatus_SHIPPED: "Expédiée",
  deliveryStatus_TAX_NOT_COMMITTED: "Problème de taxes",
  deliveryStatus_CANCELLED: "Annulée",
  statusDisplay_cancelled: "Annulée",
  statusDisplay_cancelling: "Annulation en attente",
  statusDisplay_completed: "Terminée",
  statusDisplay_created: "Créée",
  statusDisplay_error: "En attente",
  statusDisplay_Error: "En attente",
  statusDisplay_processing: "En attente",
  statusDisplay_open: "En cours",
  statusDisplay_pending: {
    approval: "En attente d'approbation",
    merchant: {
      approval: "En attente de l'approbation du commerçant"
    }
  },
  statusDisplay_approved: "Approuvée",
  statusDisplay_rejected: "Rejetée",
  statusDisplay_merchant: {
    approved: "Approuvée par le commerçant",
    rejected: "Rejetée par le commerçant"
  },
  statusDisplay_assigned: {
    admin: "Affectée à l'administrateur"
  },
  consignmentTracking: {
    action: "Suivre le colis",
    dialog: {
      header: "Informations de suivi",
      shipped: "Expédiée",
      estimate: "Livraison estimée",
      carrier: "Service de livraison",
      trackingId: "Numéro de suivi",
      noTracking: "Le colis n'a pas été expédié de l'entrepôt. Les informations de suivi seront disponibles une fois le colis expédié.",
      loadingHeader: "Suivi de consignation"
    }
  },
  cancellationAndReturn: {
    returnAction: "Demander un retour",
    cancelAction: "Annuler des articles",
    item: "Article",
    itemPrice: "Prix de l'article",
    quantity: "Quantité max.",
    returnQty: "Quantité à retourner",
    cancelQty: "Quantité à annuler",
    setAll: "Définir toutes les quantités sur le maximum",
    totalPrice: "Total",
    submit: "Envoyer la demande",
    submitDescription: "Envoyer la demande. Les articles sur cette page seront inclus dans la demande d'annulation.",
    returnSuccess: "Votre demande de retour ({{rma}}) a été envoyée.",
    cancelSuccess: "Votre demande d'annulation a été envoyée (la commande d'origine {{orderCode}} sera mise à jour)."
  },
  cancelReplenishment: {
    title: "Annuler le réapprovisionnement",
    description: "Annuler toute commande de réapprovisionnement future ?",
    accept: "Oui",
    reject: "Non",
    cancelSuccess: "La commande de réapprovisionnement n°{{replenishmentOrderCode}} a été annulée avec succès."
  },
  caption: "Contenu de la commande"
};
var orderHistory$b = {
  orderHistory: "Historique des commandes",
  orderId: "N° commande",
  emptyPurchaseOrderId: "Aucun(e)",
  date: "Date",
  status: "Statut",
  PONumber: "Numéro de commande d'achat",
  total: "Total",
  noOrders: "Nous n'avons aucun enregistrement de commande pour ce compte.",
  noReplenishmentOrders: "Nous n'avons aucun enregistrement de commande de réapprovisionnement pour ce compte.",
  startShopping: "Commencer les achats",
  sortBy: "Trier par",
  sortOrders: "Trier les commandes",
  replenishmentOrderHistory: "Historique des commandes de réapprovisionnement",
  replenishmentOrderId: "Nº de réapprovisionnement",
  purchaseOrderNumber: "Nº de la commande d'achat",
  costCenter: "Centre de coûts",
  startOn: "Début le",
  frequency: "Fréquence",
  nextOrderDate: "Prochaine date de commande",
  cancel: "Annuler",
  cancelled: "Annulée",
  replenishmentHistory: "Historique des réapprovisionnements",
  notFound: "Aucune commande trouvée",
  actions: "Actions"
};
var AccountOrderHistoryTabContainer$b = {
  tabs: {
    AccountOrderHistoryComponent: "TOUTES LES COMMANDES ({{param}})",
    OrderReturnRequestListComponent: "RETOURS ({{param}})"
  },
  tabPanelContainerRegion: "Regrouper avec les détails d'historique de commandes",
  tabPanelContainerRegionGroup: "Regrouper avec les détails d'historique de commandes"
};
var returnRequestList$b = {
  returnRequestId: "Nº de retour",
  orderId: "N° commande",
  date: "Date de création",
  status: "Statut",
  sortBy: "Trier par",
  sortReturns: "Trier les retours",
  statusDisplay_APPROVAL_PENDING: "Approbation en attente",
  statusDisplay_CANCELED: "Annulé",
  statusDisplay_CANCELLING: "Annulation en cours",
  statusDisplay_WAIT: "En attente",
  statusDisplay_RECEIVED: "Reçu",
  statusDisplay_RECEIVING: "Réception en cours",
  statusDisplay_APPROVING: "Approbation en cours",
  statusDisplay_REVERSING_PAYMENT: "Annulation du paiement",
  statusDisplay_PAYMENT_REVERSED: "Paiement annulé",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Échec de l'annulation du paiement",
  statusDisplay_REVERSING_TAX: "Annulation de la TVA",
  statusDisplay_TAX_REVERSED: "TVA annulée",
  statusDisplay_TAX_REVERSAL_FAILED: "Échec de l'annulation de la TVA",
  statusDisplay_COMPLETED: "Terminé"
};
var returnRequest$b = {
  returnRequestId: "Nº de demande de retour",
  orderCode: "Pour la commande nº",
  status: "Statut de retour",
  cancel: "Annuler la demande de retour",
  item: "Description",
  itemPrice: "Prix de l'article",
  returnQty: "Quantité retournée",
  total: "Total",
  summary: "Totaux des retours",
  subtotal: "Sous-total",
  deliveryCode: "Frais de livraison",
  estimatedRefund: "Remboursement estimé",
  note: "Les totaux sont estimés et peuvent ne pas inclure les taxes applicables ou d'autres frais.",
  cancelSuccess: "Votre demande de retour ({{rma}}) a été annulée.",
  caption: "Contenu de la commande"
};
var reorder$b = {
  button: "Recommander",
  dialog: {
    reorderProducts: "Recommander les produits",
    messages: {
      reviewConfiguration: "Une erreur s'est produite avec la configuration \"{{ productCode}}\".",
      lowStock: "La quantité pour {{ productName }} : {{ quantity }} a été réduite à {{ quantityAdded }}.",
      noStock: "{{ productName }} est actuellement en rupture de stock.",
      pricingError: "Problème de prix avec \"{{ productCode }}\"",
      unresolvableIssues: "Problème non reconnu avec \"{{ productCode }}\"",
      success: "Les produits ont été ajoutés au panier avec succès."
    },
    areYouSureToReplaceCart: "Le panier actuel sera remplacé par de nouveaux articles. Voulez-vous poursuivre ?",
    cancel: "Annuler",
    "continue": "Poursuivre"
  }
};
var order$b = {
  orderDetails: orderDetails$b,
  orderHistory: orderHistory$b,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$b,
  returnRequestList: returnRequestList$b,
  returnRequest: returnRequest$b,
  reorder: reorder$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  myAccountV2Order: myAccountV2Order$b,
  order: order$b
};
var myAccountV2OrderHistory$a = {
  heading: "सभी ऑर्डर ({{param}})",
  item: "{{param}} आइटम",
  items: "{{param}} आइटम",
  totalPrice: "कुल मूल्यः {{param}}",
  consignmentCode: "कन्साइनमेंट {{param}}",
  statusDate: "अंतिम अपडेट किया गया: {{param}}",
  returnProcessed: "वापसी प्रक्रियाः {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "लेना बाकी है - "
  },
  checkoutMode: {
    deliveryEntries: "भेजना बाकी है - "
  },
  checkoutPickupInStore: {
    heading: "लेना बाकी है - "
  },
  orderListResults: "ऑर्डर सूची",
  orderListPagination: "ऑर्डर सूची पृष्ठांकन",
  totalPriceLabel: "कुल मूल्य",
  orderPlaced: "ऑर्डर करने का दिनांक",
  orderCodeLabel: "ऑर्डर कोड",
  consignmentDetailLabel: "कन्साइनमेंट जानकारी",
  consignmentNumberLabel: "कन्साइनमेंट संख्या",
  consignmentStatusLabel: "कन्साइनमेंट की स्थिति",
  consignmentStatusDateLabel: "अंतिम बार अपडेट करने की दिनांक",
  estimateDeliveryLabel: "अनुमानित वितरण दिनांकः"
};
var myAccountV2OrderDetails$a = {
  returnItems: "वापस किए गए आइटम",
  cancelItems: "आइटम रद्द करें",
  downloadInvoices: "चालान डाउनलोड करें",
  viewAllOrders: "सभी ऑर्डर देखें",
  noInvoices: "चालान अभी तक जनरेट नहीं किए गए हैं. कृपया बाद में आएं."
};
var myAccountV2Orders$a = {
  item: "{{value}} आइटम",
  items: "{{value}} आइटम",
  heading: "ऑर्डर और वापसी",
  orderNumber: "ऑर्डर संख्या ({{value}})",
  purchasedOn: "क्रय करने का दिनांकः {{value}}",
  orderedItems: "ऑर्डर किए गए आइटमः {{value}}",
  totalPrice: "कुल मूल्यः {{value}}",
  orderDetails: "ऑर्डर विवरण",
  orderDetailsLabel: "ऑर्डर संख्या {{value}} के लिए ऑर्डर विवरण दिखाएं",
  orderStatusLabel: "ऑर्डर की स्थिति",
  returnOrder: "वापसी ऑर्डर",
  showMore: "अधिक दिखाएं",
  showMoreLabel: "ऑर्डर इतिहास पर नेविगेट करें"
};
var myAccountV2Order$a = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$a,
  myAccountV2OrderDetails: myAccountV2OrderDetails$a,
  myAccountV2Orders: myAccountV2Orders$a
};
var orderDetails$a = {
  orderId: "ऑर्डर #",
  orderNumber: "ऑर्डर संख्या",
  replenishmentId: "पुनःपूर्ति #",
  purchaseOrderId: "क्रय ऑर्डर #",
  purchaseOrderNumber: "क्रय ऑर्डर संख्या",
  emptyPurchaseOrderId: "कोई नहीं",
  none: "कोई नहीं {{value}}",
  placed: "दिया गया",
  placedBy: "द्वारा स्थापित",
  unit: "इकाई",
  costCenter: "लागत केंद्र",
  costCenterAndUnit: "लागत केंद्र / इकाई ",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "भुगतान की विधि",
  payByAccount: "खाते द्वारा भुगतान करें",
  paidByCreditCard: "(क्रेडिट कार्ड द्वारा भुगतान किया गया)",
  status: "स्थिति",
  active: "सक्रिय",
  shippedOn: "शिप करे का दिनांक",
  shippingMethod: "शिपिंग विधि",
  placedOn: "दिनांक को किया गया",
  startOn: "प्रारंभ दिनांक",
  nextOrderDate: "अगले ऑर्डर का दिनांक",
  frequency: "आवृत्ति",
  cancelled: " रद्द किया गया",
  deliveryStatus_IN_TRANSIT: "ट्रांज़िट में",
  deliveryStatus_READY_FOR_PICKUP: "पिकअप के लिए तैयार है",
  deliveryStatus_READY_FOR_SHIPPING: "शिपिंग के लिए तैयार",
  deliveryStatus_WAITING: "प्रतीक्षा हो रही है",
  deliveryStatus_DELIVERING: "वितरण हो रहा है",
  deliveryStatus_PICKPACK: "शिपमेंट के लिए तैयार कर रहे हैं",
  deliveryStatus_PICKUP_COMPLETE: "पिकअप पूर्ण",
  deliveryStatus_DELIVERY_COMPLETED: "वितरण पूर्ण",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "भुगता समस्या",
  deliveryStatus_IN_PROCESS: "ऑर्डर प्रोसेसिंग",
  deliveryStatus_READY: "ऑर्डर प्रोसेसिंग",
  deliveryStatus_DELIVERY_REJECTED: "वितरण अस्वीकृत हुआ",
  deliveryStatus_SHIPPED: "भेज दिया गया है",
  deliveryStatus_TAX_NOT_COMMITTED: "कर समस्या",
  deliveryStatus_CANCELLED: "रद्द किया गया",
  statusDisplay_cancelled: "रद्द किया गया",
  statusDisplay_cancelling: "लंबित रद्द करें",
  statusDisplay_completed: "पूर्ण हुआ",
  statusDisplay_created: "बनाया गया",
  statusDisplay_error: "लंबित",
  statusDisplay_Error: "लंबित",
  statusDisplay_processing: "लंबित",
  statusDisplay_open: "खोलें",
  statusDisplay_pending: {
    approval: "लंबित अनुमोदन",
    merchant: {
      approval: "लंबित मर्चेंट अनुमोदन"
    }
  },
  statusDisplay_approved: "अनुमोदित",
  statusDisplay_rejected: "अस्वीकृत",
  statusDisplay_merchant: {
    approved: "मर्चेंट अनुमोदित",
    rejected: "मर्चेंट अस्वीकृत"
  },
  statusDisplay_assigned: {
    admin: "प्रशासक को असाइन किया गया"
  },
  consignmentTracking: {
    action: "पैकेज ट्रैक करें",
    dialog: {
      header: "ट्रैकिंग की जानकारी",
      shipped: "भेज दिया गया",
      estimate: "अनुमानित वितरण",
      carrier: "वितरण सेवा",
      trackingId: "ट्रैकिंग संख्या",
      noTracking: "वेयरहाउस से पैकेज भेज दिया गया है. पैकेज शिप होने के बाद ट्रैकिंग जानकारी उपलब्ध होगी.",
      loadingHeader: "कंसाइनमेंट ट्रैकिंग"
    }
  },
  cancellationAndReturn: {
    returnAction: "वापसी का अनुरोध करें",
    cancelAction: "आइटम रद्द करें",
    item: "आइटम",
    itemPrice: "आइटम मूल्य",
    quantity: "अधिकतम मात्रा",
    returnQty: "वापस की गई मात्रा",
    cancelQty: "रद्द की गई मात्रा",
    setAll: "सभी मात्राएं अधिकतम पर सेट करें",
    totalPrice: "कुल",
    submit: "अनुरोध सबमिट करें",
    submitDescription: "अनुरोध सबमिट करें. इस पृष्ठ के आइटम रद्दकरण अनुरोध में शामिल किए जाएंगे.",
    returnSuccess: "आपका वापसी अनुरोध ({{rma}}) सबमिट किया गया",
    cancelSuccess: "आपका रद्दकरण अनुरोध सबमिट किया गया (मूल ऑर्डर {{orderCode}} अपडेट किया जाएगा)"
  },
  cancelReplenishment: {
    title: "पुनःपूर्ति रद्द करें ",
    description: "कोई भावी पुनः पूर्ति ऑर्डर रद्द करें?",
    accept: "हां",
    reject: "नहीं",
    cancelSuccess: "पुनः पूर्ति ऑर्डर #{{replenishmentOrderCode}} सफलतापूर्वक रद्द किया गया"
  },
  caption: "ऑर्डर सामग्री."
};
var orderHistory$a = {
  orderHistory: "ऑर्डर का इतिहास",
  orderId: "ऑर्डर #",
  emptyPurchaseOrderId: "कोई नहीं",
  date: "दिनांक",
  status: "स्थिति",
  PONumber: "P.O. संख्या",
  total: "कुल",
  noOrders: "इस खाते के लिए हमारे पास कोई ऑर्डर रिकॉर्ड नहीं हैं.",
  noReplenishmentOrders: "इस खाते के लिए हमारे पास कोई पुनः पूर्ति ऑर्डर रिकॉर्ड नहीं हैं.",
  startShopping: "शॉपिंग प्रारंभ करें",
  sortBy: "इसके द्वारा क्रमित",
  sortOrders: "ऑर्डर क्रमित करें",
  replenishmentOrderHistory: "पुनःपूर्ति ऑर्डर इतिहास",
  replenishmentOrderId: "पुनःपूर्ति #",
  purchaseOrderNumber: "PO #",
  costCenter: "लागत केंद्र",
  startOn: "प्रारंभ दिनांक",
  frequency: "आवृत्ति",
  nextOrderDate: "अगले ऑर्डर का दिनांक",
  cancel: "रद्द करें",
  cancelled: "रद्द किया गया",
  replenishmentHistory: "पुनःपूर्ति इतिहास",
  notFound: "कोई ऑर्डर नहीं मिला ",
  actions: "क्रियाएं"
};
var AccountOrderHistoryTabContainer$a = {
  tabs: {
    AccountOrderHistoryComponent: "सभी ऑर्डर ({{param}})",
    OrderReturnRequestListComponent: "वापसियाँ ({{param}})"
  },
  tabPanelContainerRegion: "ऑर्डर इतिहास विवरण के साथ समूह",
  tabPanelContainerRegionGroup: "ऑर्डर इतिहास विवरण के साथ समूह"
};
var returnRequestList$a = {
  returnRequestId: "वापसी #",
  orderId: "ऑर्डर#",
  date: "निर्माण दिनांक",
  status: "स्थिति",
  sortBy: "इसके द्वारा क्रमित",
  sortReturns: "वापसी क्रमित करें",
  statusDisplay_APPROVAL_PENDING: "लंबित अनुमोदन",
  statusDisplay_CANCELED: "रद्द किया गया",
  statusDisplay_CANCELLING: "रद्द किया जा रहा है",
  statusDisplay_WAIT: "प्रतीक्षा करें",
  statusDisplay_RECEIVED: "प्राप्त",
  statusDisplay_RECEIVING: "प्राप्त कर रहा है",
  statusDisplay_APPROVING: "अनुमोदित कर रहा है",
  statusDisplay_REVERSING_PAYMENT: "भुगतान वापस किया जा रहा है",
  statusDisplay_PAYMENT_REVERSED: "भुगतान वापस गया",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "भुगतान वापसी विफल",
  statusDisplay_REVERSING_TAX: "कर वापस किया जा रहा है",
  statusDisplay_TAX_REVERSED: "कर वापस गया",
  statusDisplay_TAX_REVERSAL_FAILED: "कर वापसी विफल",
  statusDisplay_COMPLETED: "पूर्ण हुआ"
};
var returnRequest$a = {
  returnRequestId: "वापसी का अनुरोध #",
  orderCode: "ऑर्डर के लिए #",
  status: "वापसी स्थिति",
  cancel: "वापसी अनुरोध रद्द करें",
  item: "वर्णन",
  itemPrice: "आइटम मूल्य",
  returnQty: "मात्रा वापस करें",
  total: "कुल",
  summary: "कुल वापसी",
  subtotal: "उप योग",
  deliveryCode: "वितरण की लागत",
  estimatedRefund: "अनुमानित धन वापसी",
  note: "कुल योग अनुमानित है और लागू कर अन्य शुल्क शामिल नहीं हैं.",
  cancelSuccess: "आपका वापसी अनुरोध ({{rma}}) रद्द किया गया",
  caption: "ऑर्डर सामग्री."
};
var reorder$a = {
  button: "पुनःऑर्डर",
  dialog: {
    reorderProducts: "पुनः ऑर्डर उत्पाद",
    messages: {
      reviewConfiguration: "\"{{ productCode}}\" कॉन्फ़िगरेशन के साथ त्रुटि उत्पन्न हुई.",
      lowStock: "{{ productName }}: {{ quantity }} के लिए मात्रा {{ quantityAdded }} तक कम की गई.",
      noStock: "{{ productName }} वर्तमान में स्टॉक में नहीं है.",
      pricingError: "\"{{ productCode }}\" के साथ मूल्य-निर्धारण समस्या",
      unresolvableIssues: "\"{{ productCode }}\" के साथ अपरिचित समस्या.",
      success: "उत्पाद सफलतापूर्वक कार्ट में जोड़ा गया"
    },
    areYouSureToReplaceCart: "वर्तमान कार्ट नए आइटम से पुनर्स्थापित की जाएगी. क्या आप जारी रखना चाहते हैं?",
    cancel: "रद्द करें",
    "continue": "जारी रखें"
  }
};
var order$a = {
  orderDetails: orderDetails$a,
  orderHistory: orderHistory$a,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$a,
  returnRequestList: returnRequestList$a,
  returnRequest: returnRequest$a,
  reorder: reorder$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  myAccountV2Order: myAccountV2Order$a,
  order: order$a
};
var myAccountV2OrderHistory$9 = {
  heading: "Összes rendelés ({{param}})",
  item: "{{param}} tétel",
  items: "{{param}} tétel",
  totalPrice: "Teljes ár: {{param}}",
  consignmentCode: "Konszignáció: {{param}}",
  statusDate: "Legutolsó frissítés: {{param}}",
  returnProcessed: "Visszáru feldolgozva: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "Átveendő – "
  },
  checkoutMode: {
    deliveryEntries: "Szállítandó – "
  },
  checkoutPickupInStore: {
    heading: "Átveendő – "
  },
  orderListResults: "Rendelési lista",
  orderListPagination: "Rendelési lista lapszámozása",
  totalPriceLabel: "Teljes ár",
  orderPlaced: "Rendelésleadás dátuma",
  orderCodeLabel: "Rendelési kód",
  consignmentDetailLabel: "Konszignáció adatai",
  consignmentNumberLabel: "Konszignáció száma",
  consignmentStatusLabel: "Konszignáció státusa",
  consignmentStatusDateLabel: "Legutóbbi frissítés",
  estimateDeliveryLabel: "Becsült kézbesítési dátum"
};
var myAccountV2OrderDetails$9 = {
  returnItems: "Tételek visszaküldése",
  cancelItems: "Tételek törlése",
  downloadInvoices: "Számlák letöltése",
  viewAllOrders: "Összes rendelés megtekintése",
  noInvoices: "A számlák még nincsenek létrehozva. Nézzen vissza később."
};
var myAccountV2Orders$9 = {
  item: "{{value}} tétel",
  items: "{{value}} tétel",
  heading: "Rendelések és visszáruk",
  orderNumber: "Rendelés száma ({{value}})",
  purchasedOn: "Vásárlás dátuma: {{value}}",
  orderedItems: "Rendelt tételek: {{value}}",
  totalPrice: "Teljes ár: {{value}}",
  orderDetails: "Rendelés részletei",
  orderDetailsLabel: "Rendelés részleteinek megjelenítése a következő számú rendeléshez: {{value}}",
  orderStatusLabel: "Rendelés státusa",
  returnOrder: "Visszárurendelés",
  showMore: "Több megjelenítése",
  showMoreLabel: "Navigálás a rendeléstörténethez"
};
var myAccountV2Order$9 = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$9,
  myAccountV2OrderDetails: myAccountV2OrderDetails$9,
  myAccountV2Orders: myAccountV2Orders$9
};
var orderDetails$9 = {
  orderId: "Rendelés #",
  orderNumber: "Rendelés száma",
  replenishmentId: "Utánpótlás #",
  purchaseOrderId: "Megrendelés #",
  purchaseOrderNumber: "Megrendelés száma",
  emptyPurchaseOrderId: "Nincs",
  none: "Nincs {{value}}",
  placed: "Megrendelve",
  placedBy: "Megrendelő:",
  unit: "Egység",
  costCenter: "Költséghely",
  costCenterAndUnit: "Költséghely/egység",
  costCenterAndUnitValue: "{{costCenterName}}/{{unitName}}",
  methodOfPayment: "Fizetési mód",
  payByAccount: "Fizetés számlára",
  paidByCreditCard: "(hitelkártyával fizetve)",
  status: "Státus",
  active: "Aktív",
  shippedOn: "Szállítás dátuma",
  shippingMethod: "Szállítás módja",
  placedOn: "Megrendelés dátuma",
  startOn: "Indítás dátuma",
  nextOrderDate: "Következő rendelés dátuma",
  frequency: "Gyakoriság",
  cancelled: " Törölve",
  deliveryStatus_IN_TRANSIT: "Szállítás alatt",
  deliveryStatus_READY_FOR_PICKUP: "Átvehető",
  deliveryStatus_READY_FOR_SHIPPING: "Szállításra kész",
  deliveryStatus_WAITING: "Várakozik",
  deliveryStatus_DELIVERING: "Kézbesítés folyamatban",
  deliveryStatus_PICKPACK: "Előkészítés a szállításra",
  deliveryStatus_PICKUP_COMPLETE: "Átvétel befejezve",
  deliveryStatus_DELIVERY_COMPLETED: "Kézbesítve",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Fizetési probléma",
  deliveryStatus_IN_PROCESS: "Rendelés fedolgozás alatt",
  deliveryStatus_READY: "Rendelés fedolgozás alatt",
  deliveryStatus_DELIVERY_REJECTED: "Kézbesítés elutasítva",
  deliveryStatus_SHIPPED: "Kiszállítva",
  deliveryStatus_TAX_NOT_COMMITTED: "Adózási probléma",
  deliveryStatus_CANCELLED: "Törölve",
  statusDisplay_cancelled: "Törölve",
  statusDisplay_cancelling: "Törlés függőben",
  statusDisplay_completed: "Befejezve",
  statusDisplay_created: "Létrehozva",
  statusDisplay_error: "Függőben",
  statusDisplay_Error: "Függőben",
  statusDisplay_processing: "Függőben",
  statusDisplay_open: "Nyitott",
  statusDisplay_pending: {
    approval: "Jóváhagyásra vár",
    merchant: {
      approval: "Kereskedő jóváhagyására vár"
    }
  },
  statusDisplay_approved: "Jóváhagyva",
  statusDisplay_rejected: "Elutasítva",
  statusDisplay_merchant: {
    approved: "Kereskedő által jóváhagyva",
    rejected: "Kereskedő által elutasítva"
  },
  statusDisplay_assigned: {
    admin: "Rendszergazdához rendelve"
  },
  consignmentTracking: {
    action: "Csomagkövetés",
    dialog: {
      header: "Követési információ",
      shipped: "Kiszállítva",
      estimate: "Becsült kézbesítés",
      carrier: "Kézbesítési szolgáltatás",
      trackingId: "Követési szám",
      noTracking: "A csomagot még nem indították el a raktárból. A követési információ a csomag szállításának megkezdése után lesz elérhető.",
      loadingHeader: "Konszignációs követés"
    }
  },
  cancellationAndReturn: {
    returnAction: "Visszáru igénylése",
    cancelAction: "Tételek törlése",
    item: "Tétel",
    itemPrice: "Tétel ára",
    quantity: "Max. mennyiség",
    returnQty: "Visszaküldendő mennyiség",
    cancelQty: "Törlendő mennyiség",
    setAll: "Összes mennyiség maximumra állítása",
    totalPrice: "Összesen",
    submit: "Kérelem elküldése",
    submitDescription: "Kérelem elküldése. A törlési kérelem tartalmazni fogja az ezen az oldalon található tételeket.",
    returnSuccess: "Visszáruigénylés ({{rma}}) elküldve",
    cancelSuccess: "Törlési kérelem elküldve (az eredeti rendelés ({{orderCode}}) frissülni fog)"
  },
  cancelReplenishment: {
    title: "Utánpótlás törlése",
    description: "Törli a jövőbeli utánpótlásra vonatkozó rendeléseket?",
    accept: "Igen",
    reject: "Nem",
    cancelSuccess: "Az utánpótlási rendelés (#{{replenishmentOrderCode}}) törlése sikeres"
  },
  caption: "Rendelés tartalma."
};
var orderHistory$9 = {
  orderHistory: "Rendelési előzmények",
  orderId: "Rendelés #",
  emptyPurchaseOrderId: "Nincs",
  date: "Dátum",
  status: "Státus",
  PONumber: "Megrendelés száma",
  total: "Összesen",
  noOrders: "Nincsenek rendelési rekordok ehhez a fiókhoz.",
  noReplenishmentOrders: "Nincsenek utánpótlási rendelési rekordok ehhez a fiókhoz.",
  startShopping: "Vásárlás megkezdése",
  sortBy: "Rendezés alapja",
  sortOrders: "Rendelések rendezése",
  replenishmentOrderHistory: "Utánpótlási rendelési előzmények",
  replenishmentOrderId: "Utánpótlás #",
  purchaseOrderNumber: "Megrendelés #",
  costCenter: "Költséghely",
  startOn: "Indítás dátuma",
  frequency: "Gyakoriság",
  nextOrderDate: "Következő rendelés dátuma",
  cancel: "Mégse",
  cancelled: "Törölve",
  replenishmentHistory: "Utánpótlási előzmények",
  notFound: "Nem található rendelés",
  actions: "Műveletek"
};
var AccountOrderHistoryTabContainer$9 = {
  tabs: {
    AccountOrderHistoryComponent: "ÖSSZES RENDELÉS ({{param}})",
    OrderReturnRequestListComponent: "VISSZÁRUK ({{param}})"
  },
  tabPanelContainerRegion: "Csoportosítás a rendelési előzmények részleteivel",
  tabPanelContainerRegionGroup: "Csoportosítás a rendelési előzmények részleteivel"
};
var returnRequestList$9 = {
  returnRequestId: "Visszáru #",
  orderId: "Rendelés #",
  date: "Létrehozás dátuma",
  status: "Státus",
  sortBy: "Rendezés alapja",
  sortReturns: "Visszáruk rendezése",
  statusDisplay_APPROVAL_PENDING: "Jóváhagyásra vár",
  statusDisplay_CANCELED: "Törölve",
  statusDisplay_CANCELLING: "Törlés",
  statusDisplay_WAIT: "Várakozik",
  statusDisplay_RECEIVED: "Beérkezett",
  statusDisplay_RECEIVING: "Fogadás",
  statusDisplay_APPROVING: "Jóváhagyás",
  statusDisplay_REVERSING_PAYMENT: "Fizetés stornózása",
  statusDisplay_PAYMENT_REVERSED: "Fizetés stornózva",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "A fizetés stornózása sikertelen",
  statusDisplay_REVERSING_TAX: "Adó stornózása",
  statusDisplay_TAX_REVERSED: "Adó stornózva",
  statusDisplay_TAX_REVERSAL_FAILED: "Az adó stornózása sikertelen",
  statusDisplay_COMPLETED: "Befejezve"
};
var returnRequest$9 = {
  returnRequestId: "Visszáruigénylés #",
  orderCode: "A következő rendeléshez: #",
  status: "Visszáru státusa",
  cancel: "Visszáruigénylés törlése",
  item: "Leírás",
  itemPrice: "Tétel ára",
  returnQty: "Visszáru mennyisége",
  total: "Összesen",
  summary: "Visszáru összesen",
  subtotal: "Részösszeg",
  deliveryCode: "Szállítási költség",
  estimatedRefund: "Becsült visszatérítés",
  note: "Az összegek csak becsült értékek és előfordulhat, hogy nem tartalmazzák a vonatkozó adókat vagy egyéb díjakat.",
  cancelSuccess: "Visszáruigénylés ({{rma}}) törölve",
  caption: "Rendelés tartalma."
};
var reorder$9 = {
  button: "Ismételt rendelés",
  dialog: {
    reorderProducts: "Termékek ismételt rendelése",
    messages: {
      reviewConfiguration: "Hiba történt a(z) „{{ productCode}}” konfigurációval.",
      lowStock: "A(z) {{ productName }}:{{ quantity }} mennyisége a következőre csökkent: {{ quantityAdded }}.",
      noStock: "A(z) {{ productName }} jelenleg nincs készleten.",
      pricingError: "Árazási probléma a következővel: „{{ productCode }}”.",
      unresolvableIssues: "Ismeretlen probléma a következővel: „{{ productCode }}”.",
      success: "Termékek sikeresen hozzáadva a kosárhoz"
    },
    areYouSureToReplaceCart: "Az új tételek az aktuális kosár helyére lépnek. Szeretné folytatni?",
    cancel: "Mégse",
    "continue": "Folytatás"
  }
};
var order$9 = {
  orderDetails: orderDetails$9,
  orderHistory: orderHistory$9,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$9,
  returnRequestList: returnRequestList$9,
  returnRequest: returnRequest$9,
  reorder: reorder$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  myAccountV2Order: myAccountV2Order$9,
  order: order$9
};
var myAccountV2OrderHistory$8 = {
  heading: "Semua Pemesanan ({{param}})",
  item: "{{param}} Item",
  items: "{{param}} Item",
  totalPrice: "Total Harga: {{param}}",
  consignmentCode: "Konsinyasi {{param}}",
  statusDate: "Terakhir Diperbarui: {{param}}",
  returnProcessed: "Pengembalian Diproses: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "Siap Diambil - "
  },
  checkoutMode: {
    deliveryEntries: "Siap Dikirim - "
  },
  checkoutPickupInStore: {
    heading: "Siap Diambil - "
  },
  orderListResults: "Daftar Pemesanan",
  orderListPagination: "Paginasi Daftar Pemesanan",
  totalPriceLabel: "Total Harga",
  orderPlaced: "Pemesanan Dilakukan Pada",
  orderCodeLabel: "Kode Pemesanan",
  consignmentDetailLabel: "Informasi Konsinyasi",
  consignmentNumberLabel: "Nomor Konsinyasi",
  consignmentStatusLabel: "Status Konsinyasi",
  consignmentStatusDateLabel: "Terakhir Diperbarui Pada",
  estimateDeliveryLabel: "Estimasi Tanggal Pengiriman"
};
var myAccountV2OrderDetails$8 = {
  returnItems: "Kembalikan Item",
  cancelItems: "Batalkan Item",
  downloadInvoices: "Unduh Tagihan",
  viewAllOrders: "Lihat Semua Pemesanan",
  noInvoices: "Tagihan belum dibuat. Silakan kembali lagi nanti."
};
var myAccountV2Orders$8 = {
  item: "{{value}} Item",
  items: "{{value}} Item",
  heading: "Pemesanan dan Pengembalian",
  orderNumber: "Nomor Pesanan ({{value}})",
  purchasedOn: "Dibeli Pada: {{value}}",
  orderedItems: "Item yang Dipesan: {{value}}",
  totalPrice: "Total Harga: {{value}}",
  orderDetails: "Rincian Pemesanan",
  orderDetailsLabel: "Tampilkan rincian pemesanan untuk nomor pesanan {{value}}",
  orderStatusLabel: "Status Pesanan",
  returnOrder: "Kembalikan Pesanan",
  showMore: "Tampilkan Lebih Banyak",
  showMoreLabel: "Arahkan ke riwayat pemesanan"
};
var myAccountV2Order$8 = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$8,
  myAccountV2OrderDetails: myAccountV2OrderDetails$8,
  myAccountV2Orders: myAccountV2Orders$8
};
var orderDetails$8 = {
  orderId: "Pemesanan #",
  orderNumber: "Nomor Pesanan",
  replenishmentId: "Pengadaan Ulang #",
  purchaseOrderId: "Pesanan Pembelian #",
  purchaseOrderNumber: "Nomor Pesanan Pembelian",
  emptyPurchaseOrderId: "Tidak ada",
  none: "Tidak ada {{value}}",
  placed: "Dipesan",
  placedBy: "Dipesan Oleh",
  unit: "Unit",
  costCenter: "Pusat Biaya",
  costCenterAndUnit: "Pusat Biaya / Unit",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Metode Pembayaran",
  payByAccount: "Bayar dengan Kartu",
  paidByCreditCard: "(dibayar dengan kartu kredit)",
  status: "Status",
  active: "Aktif",
  shippedOn: "Dikirim pada",
  shippingMethod: "Metode Pengiriman",
  placedOn: "Dipesan pada",
  startOn: "Dimulai Pada",
  nextOrderDate: "Tanggal Pemesanan Berikutnya",
  frequency: "Frekuensi",
  cancelled: " Dibatalkan",
  deliveryStatus_IN_TRANSIT: "Sedang Transit",
  deliveryStatus_READY_FOR_PICKUP: "Siap Untuk Pengambilan",
  deliveryStatus_READY_FOR_SHIPPING: "Siap untuk Pengiriman",
  deliveryStatus_WAITING: "Sedang Menunggu",
  deliveryStatus_DELIVERING: "Sedang Dikirim",
  deliveryStatus_PICKPACK: "Sedang Disiapkan untuk Pengiriman",
  deliveryStatus_PICKUP_COMPLETE: "Pengambilan Selesai",
  deliveryStatus_DELIVERY_COMPLETED: "Pengiriman Selesai",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Masalah Pembayaran",
  deliveryStatus_IN_PROCESS: "Proses Penyiapan Pesanan",
  deliveryStatus_READY: "Proses Penyiapan Pesanan",
  deliveryStatus_DELIVERY_REJECTED: "Pengiriman Ditolak",
  deliveryStatus_SHIPPED: "Dikirim",
  deliveryStatus_TAX_NOT_COMMITTED: "Masalah Pajak",
  deliveryStatus_CANCELLED: "Dibatalkan",
  statusDisplay_cancelled: "Dibatalkan",
  statusDisplay_cancelling: "Batalkan Penundaan",
  statusDisplay_completed: "Selesai",
  statusDisplay_created: "Dibuat",
  statusDisplay_error: "Ditunda",
  statusDisplay_Error: "Ditunda",
  statusDisplay_processing: "Ditunda",
  statusDisplay_open: "Terbuka",
  statusDisplay_pending: {
    approval: "Menunggu Persetujuan",
    merchant: {
      approval: "Menunggu Persetujuan Pedagang"
    }
  },
  statusDisplay_approved: "Disetujui",
  statusDisplay_rejected: "Ditolak",
  statusDisplay_merchant: {
    approved: "Disetujui Oleh Pedagang",
    rejected: "Ditolak Oleh Pedagang"
  },
  statusDisplay_assigned: {
    admin: "Ditetapkan Ke Administrator"
  },
  consignmentTracking: {
    action: "Lacak paket",
    dialog: {
      header: "Informasi Pelacakan",
      shipped: "Dikirim",
      estimate: "Estimasi Pengiriman",
      carrier: "Layanan Pengiriman",
      trackingId: "Nomor Pelacakan",
      noTracking: "Paket belum dikirim dari gudang. Informasi pelacakan akan tersedia setelah paket dikirim.",
      loadingHeader: "Pelacakan Konsinyasi"
    }
  },
  cancellationAndReturn: {
    returnAction: "Minta Pengembalian",
    cancelAction: "Batalkan Item",
    item: "Item",
    itemPrice: "Harga Item",
    quantity: "Jumlah Maksimum",
    returnQty: "Jumlah yang Akan Dikembalikan",
    cancelQty: "Jumlah yang Akan Dibatalkan",
    setAll: "Tetapkan semua jumlah menjadi maksimum",
    totalPrice: "Total",
    submit: "Kirimkan Permintaan",
    submitDescription: "Kirimkan Permintaan. Item pada halaman ini akan dimasukkan dalam permintaan pembatalan.",
    returnSuccess: "Permintaan pengembalian Anda ({{rma}}) telah dikirim",
    cancelSuccess: "Permintaan pembatalan Anda telah dikirim (pesanan awal {{orderCode}} akan diperbarui)"
  },
  cancelReplenishment: {
    title: "Batalkan Pengadaan Ulang",
    description: "Batalkan setiap pemesanan pengadaan ulang di masa mendatang?",
    accept: "Ya",
    reject: "Tidak",
    cancelSuccess: "Pemesanan pengadaan ulang #{{replenishmentOrderCode}} berhasil dibatalkan"
  },
  caption: "Isi pesanan."
};
var orderHistory$8 = {
  orderHistory: "Riwayat pemesanan",
  orderId: "Pemesanan #",
  emptyPurchaseOrderId: "Tidak ada",
  date: "Tanggal",
  status: "Status",
  PONumber: "Nomor P.O.",
  total: "Total",
  noOrders: "Kami tidak memiliki catatan pemesanan untuk akun ini.",
  noReplenishmentOrders: "Kami tidak memiliki catatan pemesanan pengadaan ulang untuk akun ini.",
  startShopping: "Mulai Berbelanja",
  sortBy: "Urutkan berdasarkan",
  sortOrders: "Pengaturan urutan",
  replenishmentOrderHistory: "Riwayat Pemesanan Pengadaan Ulang",
  replenishmentOrderId: "Pengadaan Ulang #",
  purchaseOrderNumber: "PO #",
  costCenter: "Pusat Biaya",
  startOn: "Dimulai Pada",
  frequency: "Frekuensi",
  nextOrderDate: "Tanggal Pemesanan Berikutnya",
  cancel: "Batalkan",
  cancelled: "Dibatalkan",
  replenishmentHistory: "Riwayat Pengadaan Ulang",
  notFound: "Pemesanan Tidak Ditemukan",
  actions: "Tindakan"
};
var AccountOrderHistoryTabContainer$8 = {
  tabs: {
    AccountOrderHistoryComponent: "SEMUA PEMESANAN ({{param}})",
    OrderReturnRequestListComponent: "PENGEMBALIAN ({{param}})"
  },
  tabPanelContainerRegion: "Grup dengan rincian riwayat pemesanan",
  tabPanelContainerRegionGroup: "Grup dengan rincian riwayat pemesanan"
};
var returnRequestList$8 = {
  returnRequestId: "Pengembalian #",
  orderId: "Pemesanan #",
  date: "Tanggal Dibuat",
  status: "Status",
  sortBy: "Urutkan berdasarkan",
  sortReturns: "Urutkan pengembalian",
  statusDisplay_APPROVAL_PENDING: "Menunggu Persetujuan",
  statusDisplay_CANCELED: "Dibatalkan",
  statusDisplay_CANCELLING: "Proses Pembatalan",
  statusDisplay_WAIT: "Tunggu",
  statusDisplay_RECEIVED: "Diterima",
  statusDisplay_RECEIVING: "Proses Penerimaan",
  statusDisplay_APPROVING: "Proses Penyetujuan",
  statusDisplay_REVERSING_PAYMENT: "Proses Pembatalan Pembayaran",
  statusDisplay_PAYMENT_REVERSED: "Pembayaran Dibatalkan",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Pembatalan Pembayaran Gagal",
  statusDisplay_REVERSING_TAX: "Proses Pembatalan Pajak",
  statusDisplay_TAX_REVERSED: "Pajak Dibatalkan",
  statusDisplay_TAX_REVERSAL_FAILED: "Pembatalan Pajak Gagal",
  statusDisplay_COMPLETED: "Selesai"
};
var returnRequest$8 = {
  returnRequestId: "Permintaan Pengembalian #",
  orderCode: "Untuk Pemesanan #",
  status: "Status pengembalian",
  cancel: "Batalkan Permintaan Pengembalian",
  item: "Deskripsi",
  itemPrice: "Harga Item",
  returnQty: "Jumlah Pengembalian",
  total: "Total",
  summary: "Total Pengembalian",
  subtotal: "Subtotal",
  deliveryCode: "Ongkos kirim",
  estimatedRefund: "Estimasi pengembalian dana",
  note: "Jumlah total yang tercantum merupakan estimasi dan mungkin tidak termasuk pajak atau biaya lain yang berlaku.",
  cancelSuccess: "Permintaan pengembalian Anda ({{rma}}) dibatalkan",
  caption: "Isi pesanan."
};
var reorder$8 = {
  button: "Pesan Ulang",
  dialog: {
    reorderProducts: "Pesan ulang produk",
    messages: {
      reviewConfiguration: "Terjadi kesalahan dengan konfigurasi \"{{ productCode}}\".",
      lowStock: "Jumlah untuk {{ productName }}: {{ quantity }} telah dikurangi menjadi {{ quantityAdded }}.",
      noStock: "Stok {{ productName }} saat ini telah habis.",
      pricingError: "Terdapat masalah penetapan harga pada \"{{ productCode }}\"",
      unresolvableIssues: "Terdapat masalah yang tidak dikenal pada \"{{ productCode }}\".",
      success: "Produk telah berhasil ditambahkan ke keranjang"
    },
    areYouSureToReplaceCart: "Keranjang saat ini akan diganti dengan item yang baru. Apakah Anda ingin melanjutkan?",
    cancel: "Batalkan",
    "continue": "Lanjutkan"
  }
};
var order$8 = {
  orderDetails: orderDetails$8,
  orderHistory: orderHistory$8,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$8,
  returnRequestList: returnRequestList$8,
  returnRequest: returnRequest$8,
  reorder: reorder$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  myAccountV2Order: myAccountV2Order$8,
  order: order$8
};
var myAccountV2OrderHistory$7 = {
  heading: "Tutti gli ordini ({{param}})",
  item: "Articolo {{param}}",
  items: "Articoli {{param}}",
  totalPrice: "Prezzo totale: {{param}}",
  consignmentCode: "Spedizione {{param}}",
  statusDate: "Ultimo aggiornamento: {{param}}",
  returnProcessed: "Reso elaborato: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "Da prelevare - "
  },
  checkoutMode: {
    deliveryEntries: "Da spedire - "
  },
  checkoutPickupInStore: {
    heading: "Da prelevare - "
  },
  orderListResults: "Lista di ordini",
  orderListPagination: "Impaginazione lista di ordini",
  totalPriceLabel: "Prezzo totale",
  orderPlaced: "Data inserimento ordine",
  orderCodeLabel: "Codice ordine",
  consignmentDetailLabel: "Informazioni spedizione",
  consignmentNumberLabel: "Numero spedizione",
  consignmentStatusLabel: "Stato spedizione",
  consignmentStatusDateLabel: "Data ultimo aggiornamento",
  estimateDeliveryLabel: "Data di consegna stimata"
};
var myAccountV2OrderDetails$7 = {
  returnItems: "Restituisci articoli",
  cancelItems: "Annulla articoli",
  downloadInvoices: "Scarica fatture",
  viewAllOrders: "Visualizza tutti gli ordini",
  noInvoices: "Fatture non ancora generate. Tornare in seguito."
};
var myAccountV2Orders$7 = {
  item: "Articolo {{value}}",
  items: "Articoli {{value}}",
  heading: "Ordini e resi",
  orderNumber: "Numero dell'ordine ({{value}})",
  purchasedOn: "Acquistato il: {{value}}",
  orderedItems: "Articoli ordinati: {{value}}",
  totalPrice: "Prezzo totale: {{value}}",
  orderDetails: "Dettagli ordine",
  orderDetailsLabel: "Mostra i dettagli dell'ordine per l'ordine numero {{value}}",
  orderStatusLabel: "Stato ordine",
  returnOrder: "Restituisci ordine",
  showMore: "Mostra di più",
  showMoreLabel: "Passa alla cronologia degli ordini"
};
var myAccountV2Order$7 = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$7,
  myAccountV2OrderDetails: myAccountV2OrderDetails$7,
  myAccountV2Orders: myAccountV2Orders$7
};
var orderDetails$7 = {
  orderId: "N. ordine",
  orderNumber: "Numero dell'ordine",
  replenishmentId: "N. riapprovvigionamento",
  purchaseOrderId: "N. ordine d'acquisto",
  purchaseOrderNumber: "Numero ordine acquisto",
  emptyPurchaseOrderId: "Nessuno",
  none: "Nessuno {{value}}",
  placed: "Effettuato",
  placedBy: "Effettuato da",
  unit: "Unità",
  costCenter: "Centro di costo",
  costCenterAndUnit: "Centro di costo/unità",
  costCenterAndUnitValue: "{{costCenterName}}/{{unitName}}",
  methodOfPayment: "Metodo di pagamento",
  payByAccount: "Paga con conto",
  paidByCreditCard: "(pagato tramite carta di credito)",
  status: "Stato",
  active: "Attivo",
  shippedOn: "Data di spedizione",
  shippingMethod: "Metodo di spedizione",
  placedOn: "Effettuato il",
  startOn: "Inizia il",
  nextOrderDate: "Data ordine successivo",
  frequency: "Frequenza",
  cancelled: " Annullato",
  deliveryStatus_IN_TRANSIT: "In transito",
  deliveryStatus_READY_FOR_PICKUP: "Pronto per prelievo",
  deliveryStatus_READY_FOR_SHIPPING: "Pronto per la spedizione",
  deliveryStatus_WAITING: "In attesa",
  deliveryStatus_DELIVERING: "In consegna",
  deliveryStatus_PICKPACK: "Preparazione alla spedizione in corso",
  deliveryStatus_PICKUP_COMPLETE: "Prelievo completato",
  deliveryStatus_DELIVERY_COMPLETED: "Consegna completata",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Problema di pagamento",
  deliveryStatus_IN_PROCESS: "Elaborazione dell'ordine",
  deliveryStatus_READY: "Elaborazione dell'ordine",
  deliveryStatus_DELIVERY_REJECTED: "Consegna rifiutata",
  deliveryStatus_SHIPPED: "Spedito",
  deliveryStatus_TAX_NOT_COMMITTED: "Problema fiscale",
  deliveryStatus_CANCELLED: "Annullato",
  statusDisplay_cancelled: "Annullato",
  statusDisplay_cancelling: "Annullamento in sospeso",
  statusDisplay_completed: "Completato",
  statusDisplay_created: "Creato",
  statusDisplay_error: "In sospeso",
  statusDisplay_Error: "In sospeso",
  statusDisplay_processing: "In sospeso",
  statusDisplay_open: "Aperto",
  statusDisplay_pending: {
    approval: "In attesa di approvazione",
    merchant: {
      approval: "In attesa di approvazione da esercente"
    }
  },
  statusDisplay_approved: "Approvato",
  statusDisplay_rejected: "Rifiutato",
  statusDisplay_merchant: {
    approved: "Approvato dall'esercente",
    rejected: "Rifiutato dall'esercente"
  },
  statusDisplay_assigned: {
    admin: "Assegnato ad amministratore"
  },
  consignmentTracking: {
    action: "Traccia il pacco",
    dialog: {
      header: "Informazioni di tracciamento",
      shipped: "Spedito",
      estimate: "Consegna stimata",
      carrier: "Servizio di consegna",
      trackingId: "Numero tracciamento",
      noTracking: "Il pacco non è stato spedito dal magazzino. Le informazioni di tracciamento saranno disponibili dopo la spedizione del pacco.",
      loadingHeader: "Tracciamento spedizione"
    }
  },
  cancellationAndReturn: {
    returnAction: "Richiedi un reso",
    cancelAction: "Annulla articoli",
    item: "Articolo",
    itemPrice: "Prezzo articolo",
    quantity: "Quantità max",
    returnQty: "Quantità da restituire",
    cancelQty: "Quantità da annullare",
    setAll: "Imposta tutte le quantità sul massimo",
    totalPrice: "Totale",
    submit: "Invia richiesta",
    submitDescription: "Invia richiesta. Gli articoli in questa pagina verranno inclusi nella richiesta di annullamento.",
    returnSuccess: "La richiesta di reso ({{rma}}) è stata inviata",
    cancelSuccess: "La richiesta di annullamento è stata inviata (l'ordine originale {{orderCode}} verrà aggiornato)"
  },
  cancelReplenishment: {
    title: "Annulla riapprovvigionamento",
    description: "Annullare qualsiasi ordine di riapprovvigionamento futuro?",
    accept: "Sì",
    reject: "No",
    cancelSuccess: "Odine di riapprovvigionamento n. {{replenishmentOrderCode}} annullato correttamente"
  },
  caption: "Contenuto dell'ordine."
};
var orderHistory$7 = {
  orderHistory: "Cronologia ordini",
  orderId: "N. ordine",
  emptyPurchaseOrderId: "Nessuno",
  date: "Data",
  status: "Stato",
  PONumber: "Numero ordine di acquisto",
  total: "Totale",
  noOrders: "Nessun record di ordini per questo account.",
  noReplenishmentOrders: "Nessun record di ordini di riapprovvigionamento per questo account.",
  startShopping: "Inizia gli acquisti",
  sortBy: "Ordina per",
  sortOrders: "Ordina ordini",
  replenishmentOrderHistory: "Cronologia degli ordini di riapprovvigionamento",
  replenishmentOrderId: "N. riapprovvigionamento",
  purchaseOrderNumber: "N. ordine d'acquisto",
  costCenter: "Centro di costo",
  startOn: "Inizia il",
  frequency: "Frequenza",
  nextOrderDate: "Data ordine successivo",
  cancel: "Annulla",
  cancelled: "Annullato",
  replenishmentHistory: "Cronologia di riapprovvigionamento",
  notFound: "Nessun ordine trovato",
  actions: "Azioni"
};
var AccountOrderHistoryTabContainer$7 = {
  tabs: {
    AccountOrderHistoryComponent: "TUTTI GLI ORDINI ({{param}})",
    OrderReturnRequestListComponent: "RESI ({{param}})"
  },
  tabPanelContainerRegion: "Gruppo con dettagli cronologia ordini",
  tabPanelContainerRegionGroup: "Gruppo con dettagli cronologia ordini"
};
var returnRequestList$7 = {
  returnRequestId: "N. reso",
  orderId: "N. ordine",
  date: "Data di creazione",
  status: "Stato",
  sortBy: "Ordina per",
  sortReturns: "Ordina resi",
  statusDisplay_APPROVAL_PENDING: "Approvazione in sospeso",
  statusDisplay_CANCELED: "Annullato",
  statusDisplay_CANCELLING: "Annullamento in corso",
  statusDisplay_WAIT: "Attendi",
  statusDisplay_RECEIVED: "Ricevuto",
  statusDisplay_RECEIVING: "Ricezione in corso",
  statusDisplay_APPROVING: "Approvazione in corso",
  statusDisplay_REVERSING_PAYMENT: "Storno pagamento in corso",
  statusDisplay_PAYMENT_REVERSED: "Pagamento stornato",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Storno pagamento non riuscito",
  statusDisplay_REVERSING_TAX: "Storno imposta in corso",
  statusDisplay_TAX_REVERSED: "Imposta stornata",
  statusDisplay_TAX_REVERSAL_FAILED: "Storno imposta non riuscito",
  statusDisplay_COMPLETED: "Completato"
};
var returnRequest$7 = {
  returnRequestId: "N. richiesta di reso",
  orderCode: "Per n. ordine",
  status: "Stato del reso",
  cancel: "Annulla richiesta di reso",
  item: "Descrizione",
  itemPrice: "Prezzo articolo",
  returnQty: "Quantità del reso",
  total: "Totale",
  summary: "Totali reso",
  subtotal: "Totale parziale",
  deliveryCode: "Costi di consegna",
  estimatedRefund: "Rimborso stimato",
  note: "I totali sono stime e potrebbero non includere imposte o altre spese applicabili.",
  cancelSuccess: "La richiesta di reso ({{rma}}) è stata annullata",
  caption: "Contenuto dell'ordine."
};
var reorder$7 = {
  button: "Ordina nuovamente",
  dialog: {
    reorderProducts: "Ordina nuovamente i prodotti",
    messages: {
      reviewConfiguration: "Si è verificato un errore nella configurazione di \"{{ productCode}}\".",
      lowStock: "La quantità per {{ productName }}: {{ quantity }} è stata ridotta a {{ quantityAdded }}.",
      noStock: "{{ productName }} attualmente non disponibile.",
      pricingError: "Problema di determinazione del prezzo con \"{{ productCode }}\".",
      unresolvableIssues: "Problema non identificato con \"{{ productCode }}\".",
      success: "Prodotti aggiunti correttamente al carrello acquisti"
    },
    areYouSureToReplaceCart: "Il carrello acquisti attuale verrà sostituito da altri articoli. Continuare?",
    cancel: "Annulla",
    "continue": "Continua"
  }
};
var order$7 = {
  orderDetails: orderDetails$7,
  orderHistory: orderHistory$7,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$7,
  returnRequestList: returnRequestList$7,
  returnRequest: returnRequest$7,
  reorder: reorder$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  myAccountV2Order: myAccountV2Order$7,
  order: order$7
};
var myAccountV2OrderHistory$6 = {
  heading: "すべての注文 ({{param}})",
  item: "{{param}} アイテム",
  items: "{{param}} アイテム",
  totalPrice: "合計価格: {{param}}",
  consignmentCode: "出荷/配送指示 {{param}}",
  statusDate: "最終更新: {{param}}",
  returnProcessed: "返品処理: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "受け取り待ち - "
  },
  checkoutMode: {
    deliveryEntries: "配送待ち - "
  },
  checkoutPickupInStore: {
    heading: "受け取り待ち - "
  },
  orderListResults: "注文リスト",
  orderListPagination: "注文リストのページネーション",
  totalPriceLabel: "合計価格",
  orderPlaced: "注文実行日",
  orderCodeLabel: "注文コード",
  consignmentDetailLabel: "出荷/配送指示情報",
  consignmentNumberLabel: "出荷/配送指示番号",
  consignmentStatusLabel: "出荷/配送指示ステータス",
  consignmentStatusDateLabel: "最終更新日",
  estimateDeliveryLabel: "配送予定日"
};
var myAccountV2OrderDetails$6 = {
  returnItems: "返品アイテム",
  cancelItems: "アイテムをキャンセル",
  downloadInvoices: "請求書のダウンロード",
  viewAllOrders: "すべての注文を表示",
  noInvoices: "請求書はまだ生成されていません。後でもう一度アクセスしてください。"
};
var myAccountV2Orders$6 = {
  item: "{{value}} アイテム",
  items: "{{value}} アイテム",
  heading: "注文および返品",
  orderNumber: "注文番号 ({{value}})",
  purchasedOn: "購入日: {{value}}",
  orderedItems: "注文アイテム: {{value}}",
  totalPrice: "合計価格: {{value}}",
  orderDetails: "注文詳細",
  orderDetailsLabel: "注文番号 {{value}} の注文詳細を表示",
  orderStatusLabel: "注文ステータス",
  returnOrder: "返品注文",
  showMore: "さらに表示",
  showMoreLabel: "注文履歴にナビゲート"
};
var myAccountV2Order$6 = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$6,
  myAccountV2OrderDetails: myAccountV2OrderDetails$6,
  myAccountV2Orders: myAccountV2Orders$6
};
var orderDetails$6 = {
  orderId: "注文番号",
  orderNumber: "注文番号",
  replenishmentId: "補充番号",
  purchaseOrderId: "発注書番号",
  purchaseOrderNumber: "発注書番号",
  emptyPurchaseOrderId: "なし",
  none: "なし {{value}}",
  placed: "注文済み",
  placedBy: "注文者",
  unit: "ユニット",
  costCenter: "原価センタ",
  costCenterAndUnit: "原価センタ/ユニット",
  costCenterAndUnitValue: "{{costCenterName}}/{{unitName}}",
  methodOfPayment: "支払方法",
  payByAccount: "アカウントで支払",
  paidByCreditCard: "(クレジットカードで支払)",
  status: "ステータス",
  active: "有効",
  shippedOn: "出荷日",
  shippingMethod: "配送方法",
  placedOn: "注文日",
  startOn: "開始日",
  nextOrderDate: "次回注文日",
  frequency: "頻度",
  cancelled: " キャンセル済み",
  deliveryStatus_IN_TRANSIT: "積送中",
  deliveryStatus_READY_FOR_PICKUP: "受け取り準備完了",
  deliveryStatus_READY_FOR_SHIPPING: "出荷準備完了",
  deliveryStatus_WAITING: "待機中",
  deliveryStatus_DELIVERING: "納入",
  deliveryStatus_PICKPACK: "配送準備中",
  deliveryStatus_PICKUP_COMPLETE: "受け取り完了",
  deliveryStatus_DELIVERY_COMPLETED: "納入完了",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "支払に関する問題",
  deliveryStatus_IN_PROCESS: "注文処理中",
  deliveryStatus_READY: "注文処理中",
  deliveryStatus_DELIVERY_REJECTED: "納入拒否",
  deliveryStatus_SHIPPED: "出荷済み",
  deliveryStatus_TAX_NOT_COMMITTED: "税に関する問題",
  deliveryStatus_CANCELLED: "キャンセル済み",
  statusDisplay_cancelled: "キャンセル済み",
  statusDisplay_cancelling: "保留をキャンセル",
  statusDisplay_completed: "完了",
  statusDisplay_created: "作成済み",
  statusDisplay_error: "保留中",
  statusDisplay_Error: "保留中",
  statusDisplay_processing: "保留中",
  statusDisplay_open: "開く",
  statusDisplay_pending: {
    approval: "承認を保留",
    merchant: {
      approval: "業者承認を保留"
    }
  },
  statusDisplay_approved: "承認済み",
  statusDisplay_rejected: "却下済み",
  statusDisplay_merchant: {
    approved: "業者が承認しました",
    rejected: "業者が拒否しました"
  },
  statusDisplay_assigned: {
    admin: "管理者に割り当て済み"
  },
  consignmentTracking: {
    action: "パッケージの追跡",
    dialog: {
      header: "追跡情報",
      shipped: "出荷済み",
      estimate: "納品予定",
      carrier: "配送サービス",
      trackingId: "追跡番号",
      noTracking: "パッケージは倉庫から発送されていません。追跡情報はパッケージの出荷後に利用できるようになります。",
      loadingHeader: "出荷/配送指示追跡"
    }
  },
  cancellationAndReturn: {
    returnAction: "返品の依頼",
    cancelAction: "アイテムをキャンセル",
    item: "アイテム",
    itemPrice: "商品価格",
    quantity: "最大数量",
    returnQty: "返品数量",
    cancelQty: "キャンセル数量",
    setAll: "すべての数量を最大に設定",
    totalPrice: "合計",
    submit: "依頼の送信",
    submitDescription: "依頼を送信します。このページにあるアイテムは、キャンセル依頼に含まれます。",
    returnSuccess: "返品依頼 ({{rma}}) が送信されました",
    cancelSuccess: "キャンセル依頼が送信されました (オリジナル注文 {{orderCode}} が更新されます)"
  },
  cancelReplenishment: {
    title: "補充をキャンセル",
    description: "将来の補充注文をすべてキャンセルしますか?",
    accept: "はい",
    reject: "いいえ",
    cancelSuccess: "補充注文 #{{replenishmentOrderCode}} は正常にキャンセルされました"
  },
  caption: "注文の内容です。"
};
var orderHistory$6 = {
  orderHistory: "注文履歴",
  orderId: "注文番号",
  emptyPurchaseOrderId: "なし",
  date: "日付",
  status: "ステータス",
  PONumber: "発注書番号",
  total: "合計",
  noOrders: "このアカウントには注文レコードがありません。",
  noReplenishmentOrders: "このアカウントには補充注文レコードがありません。",
  startShopping: "ショッピング開始",
  sortBy: "ソート基準",
  sortOrders: "ソート順序",
  replenishmentOrderHistory: "補充注文履歴",
  replenishmentOrderId: "補充番号",
  purchaseOrderNumber: "発注書番号",
  costCenter: "原価センタ",
  startOn: "開始日",
  frequency: "頻度",
  nextOrderDate: "次回注文日",
  cancel: "キャンセル",
  cancelled: "キャンセル済み",
  replenishmentHistory: "補充履歴",
  notFound: "注文はありません",
  actions: "アクション"
};
var AccountOrderHistoryTabContainer$6 = {
  tabs: {
    AccountOrderHistoryComponent: "すべての注文 ({{param}})",
    OrderReturnRequestListComponent: "返品 ({{param}})"
  },
  tabPanelContainerRegion: "注文履歴詳細のあるグループ",
  tabPanelContainerRegionGroup: "注文履歴詳細のあるグループ"
};
var returnRequestList$6 = {
  returnRequestId: "返品番号",
  orderId: "注文番号",
  date: "作成日",
  status: "ステータス",
  sortBy: "ソート基準",
  sortReturns: "返品のソート",
  statusDisplay_APPROVAL_PENDING: "承認保留中",
  statusDisplay_CANCELED: "キャンセル済み",
  statusDisplay_CANCELLING: "キャンセル中",
  statusDisplay_WAIT: "待機",
  statusDisplay_RECEIVED: "受け取り済み",
  statusDisplay_RECEIVING: "受信中",
  statusDisplay_APPROVING: "承認中",
  statusDisplay_REVERSING_PAYMENT: "支払の取消中",
  statusDisplay_PAYMENT_REVERSED: "取り消された支払",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "支払の取消が失敗しました",
  statusDisplay_REVERSING_TAX: "税の取消中",
  statusDisplay_TAX_REVERSED: "税が取り消されました",
  statusDisplay_TAX_REVERSAL_FAILED: "税の取消が失敗しました",
  statusDisplay_COMPLETED: "完了"
};
var returnRequest$6 = {
  returnRequestId: "返品依頼番号",
  orderCode: "注文番号",
  status: "返品ステータス",
  cancel: "返品依頼のキャンセル",
  item: "説明",
  itemPrice: "商品価格",
  returnQty: "返品数量",
  total: "合計",
  summary: "返品合計",
  subtotal: "小計",
  deliveryCode: "配送費用",
  estimatedRefund: "見積られた払戻",
  note: "合計は見積であり、適用される税やその他の手数料が含まれていません。",
  cancelSuccess: "返品依頼 ({{rma}}) がキャンセルされました",
  caption: "注文の内容です。"
};
var reorder$6 = {
  button: "再注文",
  dialog: {
    reorderProducts: "商品の再注文",
    messages: {
      reviewConfiguration: "\"{{ productCode}}\" の設定でエラーが発生しました。",
      lowStock: "{{ productName }} の数量: {{ quantity }} は {{ quantityAdded }} に削減されました。",
      noStock: "{{ productName }} は現在在庫がありません。",
      pricingError: "\"{{ productCode }}\" の価格設定の問題です。",
      unresolvableIssues: "\"{{ productCode }}\" の不明な問題です。",
      success: "商品は正常にカートに追加されました"
    },
    areYouSureToReplaceCart: "現在のカートは新しいアイテムで置換されます。続行しますか?",
    cancel: "キャンセル",
    "continue": "続行"
  }
};
var order$6 = {
  orderDetails: orderDetails$6,
  orderHistory: orderHistory$6,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$6,
  returnRequestList: returnRequestList$6,
  returnRequest: returnRequest$6,
  reorder: reorder$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  myAccountV2Order: myAccountV2Order$6,
  order: order$6
};
var myAccountV2OrderHistory$5 = {
  heading: "모든 주문({{param}})",
  item: "{{param}}개 품목",
  items: "{{param}}개 품목",
  totalPrice: "총 가격: {{param}}",
  consignmentCode: "위탁품 {{param}}",
  statusDate: "마지막 업데이트: {{param}}",
  returnProcessed: "반품 처리: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "픽업 예정 - "
  },
  checkoutMode: {
    deliveryEntries: "배송 예정 - "
  },
  checkoutPickupInStore: {
    heading: "픽업 예정 - "
  },
  orderListResults: "주문 목록",
  orderListPagination: "주문 목록 페이지 매김",
  totalPriceLabel: "총 가격",
  orderPlaced: "주문일",
  orderCodeLabel: "주문 코드",
  consignmentDetailLabel: "위탁 정보",
  consignmentNumberLabel: "위탁 번호",
  consignmentStatusLabel: "위탁 상태",
  consignmentStatusDateLabel: "마지막 업데이트 날짜",
  estimateDeliveryLabel: "예상 배송일"
};
var myAccountV2OrderDetails$5 = {
  returnItems: "반품 품목",
  cancelItems: "품목 취소",
  downloadInvoices: "송장 다운로드",
  viewAllOrders: "모든 주문 보기",
  noInvoices: "송장이 아직 생성되지 않았습니다. 나중에 확인하십시오."
};
var myAccountV2Orders$5 = {
  item: "{{value}}개 품목",
  items: "{{value}}개 품목",
  heading: "주문 및 반품",
  orderNumber: "주문 번호({{value}})",
  purchasedOn: "구매일: {{value}}",
  orderedItems: "주문 품목: {{value}}",
  totalPrice: "총 가격: {{value}}",
  orderDetails: "주문 세부사항",
  orderDetailsLabel: "주문 번호 {{value}}에 대한 주문 세부사항 표시",
  orderStatusLabel: "주문 상태",
  returnOrder: "반품 주문",
  showMore: "자세히 보기",
  showMoreLabel: "주문 내역으로 이동"
};
var myAccountV2Order$5 = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$5,
  myAccountV2OrderDetails: myAccountV2OrderDetails$5,
  myAccountV2Orders: myAccountV2Orders$5
};
var orderDetails$5 = {
  orderId: "주문 번호",
  orderNumber: "주문 번호",
  replenishmentId: "보충 번호",
  purchaseOrderId: "구매 주문 번호",
  purchaseOrderNumber: "구매 주문 번호",
  emptyPurchaseOrderId: "없음",
  none: "없음 {{value}}",
  placed: "주문함",
  placedBy: "주문자",
  unit: "단위",
  costCenter: "코스트 센터",
  costCenterAndUnit: "코스트 센터/단위",
  costCenterAndUnitValue: "{{costCenterName}}/{{unitName}}",
  methodOfPayment: "지불 방법",
  payByAccount: "계정으로 지불",
  paidByCreditCard: "(신용 카드로 지불함)",
  status: "상태",
  active: "사용 중",
  shippedOn: "배송일",
  shippingMethod: "배송 방법",
  placedOn: "주문일",
  startOn: "시작일",
  nextOrderDate: "다음 주문일",
  frequency: "빈도",
  cancelled: " 취소됨",
  deliveryStatus_IN_TRANSIT: "배송 중",
  deliveryStatus_READY_FOR_PICKUP: "픽업 준비 완료",
  deliveryStatus_READY_FOR_SHIPPING: "배송 준비 완료",
  deliveryStatus_WAITING: "대기 중",
  deliveryStatus_DELIVERING: "배송 중",
  deliveryStatus_PICKPACK: "배송 준비 중",
  deliveryStatus_PICKUP_COMPLETE: "픽업 완료",
  deliveryStatus_DELIVERY_COMPLETED: "배송 완료",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "지불 문제",
  deliveryStatus_IN_PROCESS: "주문 처리 중",
  deliveryStatus_READY: "주문 처리 중",
  deliveryStatus_DELIVERY_REJECTED: "배송 거부됨",
  deliveryStatus_SHIPPED: "배송됨",
  deliveryStatus_TAX_NOT_COMMITTED: "세금 문제",
  deliveryStatus_CANCELLED: "취소됨",
  statusDisplay_cancelled: "취소됨",
  statusDisplay_cancelling: "취소 보류 중",
  statusDisplay_completed: "완료됨",
  statusDisplay_created: "작성됨",
  statusDisplay_error: "보류 중",
  statusDisplay_Error: "보류 중",
  statusDisplay_processing: "보류 중",
  statusDisplay_open: "미결",
  statusDisplay_pending: {
    approval: "승인 보류 중",
    merchant: {
      approval: "판매업체 승인 보류 중"
    }
  },
  statusDisplay_approved: "승인됨",
  statusDisplay_rejected: "거부됨",
  statusDisplay_merchant: {
    approved: "판매업체 승인됨",
    rejected: "판매업체 거부됨"
  },
  statusDisplay_assigned: {
    admin: "관리자에게 할당됨"
  },
  consignmentTracking: {
    action: "패키지 추적",
    dialog: {
      header: "추적 정보",
      shipped: "배송됨",
      estimate: "예상 배송일",
      carrier: "배송 서비스",
      trackingId: "추적 번호",
      noTracking: "패키지가 창고에서 발송되지 않았습니다. 패키지가 배송된 후에 추적 정보를 사용할 수 있습니다.",
      loadingHeader: "위탁 추적"
    }
  },
  cancellationAndReturn: {
    returnAction: "반품 요청",
    cancelAction: "품목 취소",
    item: "품목",
    itemPrice: "품목 가격",
    quantity: "최대 수량",
    returnQty: "반품할 수량",
    cancelQty: "취소할 수량",
    setAll: "모든 수량을 최대 수량으로 설정",
    totalPrice: "합계",
    submit: "요청 제출",
    submitDescription: "요청 제출. 이 페이지의 품목이 취소 요청에 포함됩니다.",
    returnSuccess: "반품 요청({{rma}})이 제출되었습니다.",
    cancelSuccess: "취소 요청이 제출되었습니다(최초 주문 {{orderCode}}이(가) 업데이트됨)."
  },
  cancelReplenishment: {
    title: "보충 취소",
    description: "나중에 추가한 보충 주문을 취소하시겠습니까?",
    accept: "예",
    reject: "아니요",
    cancelSuccess: "보충 주문 번호 {{replenishmentOrderCode}}이(가) 취소되었습니다."
  },
  caption: "주문 내용"
};
var orderHistory$5 = {
  orderHistory: "주문 내역",
  orderId: "주문 번호",
  emptyPurchaseOrderId: "없음",
  date: "날짜",
  status: "상태",
  PONumber: "구매 주문 번호",
  total: "합계",
  noOrders: "이 계정의 주문 기록이 없습니다.",
  noReplenishmentOrders: "이 계정의 보충 주문 기록이 없습니다.",
  startShopping: "배송 시작",
  sortBy: "정렬 기준",
  sortOrders: "정렬 순서",
  replenishmentOrderHistory: "보충 주문 내역",
  replenishmentOrderId: "보충 번호",
  purchaseOrderNumber: "구매 주문 번호",
  costCenter: "코스트 센터",
  startOn: "시작일",
  frequency: "빈도",
  nextOrderDate: "다음 주문일",
  cancel: "취소",
  cancelled: "취소됨",
  replenishmentHistory: "보충 내역",
  notFound: "주문이 없습니다.",
  actions: "작업"
};
var AccountOrderHistoryTabContainer$5 = {
  tabs: {
    AccountOrderHistoryComponent: "모든 주문({{param}})",
    OrderReturnRequestListComponent: "반품({{param}})"
  },
  tabPanelContainerRegion: "주문 내역 세부사항 그룹",
  tabPanelContainerRegionGroup: "주문 내역 세부사항 그룹"
};
var returnRequestList$5 = {
  returnRequestId: "반품 번호",
  orderId: "주문 번호",
  date: "작성 날짜",
  status: "상태",
  sortBy: "정렬 기준",
  sortReturns: "반품 정렬",
  statusDisplay_APPROVAL_PENDING: "승인 보류 중",
  statusDisplay_CANCELED: "취소됨",
  statusDisplay_CANCELLING: "취소 중",
  statusDisplay_WAIT: "대기",
  statusDisplay_RECEIVED: "수신함",
  statusDisplay_RECEIVING: "수신 중",
  statusDisplay_APPROVING: "승인 중",
  statusDisplay_REVERSING_PAYMENT: "지불 취소 중",
  statusDisplay_PAYMENT_REVERSED: "지불 취소됨",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "지불 취소 실패",
  statusDisplay_REVERSING_TAX: "세금 취소 중",
  statusDisplay_TAX_REVERSED: "세금 취소됨",
  statusDisplay_TAX_REVERSAL_FAILED: "세금 취소 실패",
  statusDisplay_COMPLETED: "완료됨"
};
var returnRequest$5 = {
  returnRequestId: "반품 요청 번호",
  orderCode: "주문 번호",
  status: "반품 상태",
  cancel: "반품 요청 취소",
  item: "설명",
  itemPrice: "품목 가격",
  returnQty: "반품 수량",
  total: "합계",
  summary: "반품 합계",
  subtotal: "소계",
  deliveryCode: "배송비",
  estimatedRefund: "예상 환불 금액",
  note: "합계는 예상 금액이며 세금이나 기타 수수료를 포함하지 않을 수 있습니다.",
  cancelSuccess: "반품 요청({{rma}})이 취소되었습니다.",
  caption: "주문 내용"
};
var reorder$5 = {
  button: "재주문",
  dialog: {
    reorderProducts: "제품 재주문",
    messages: {
      reviewConfiguration: "\"{{ productCode}}\" 구성에서 오류가 발생했습니다.",
      lowStock: "{{ productName }} 수량: {{ quantity }}에서 {{ quantityAdded }}(으)로 감소되었습니다.",
      noStock: "{{ productName }}은(는) 현재 품절입니다.",
      pricingError: "\"{{ productCode }}\"에 가격 문제가 있습니다.",
      unresolvableIssues: "\"{{ productCode }}\"에 알 수 없는 문제가 있습니다.",
      success: "제품이 장바구니에 추가되었습니다."
    },
    areYouSureToReplaceCart: "현재 장바구니가 새 품목으로 바뀌게 됩니다. 계속하시겠습니까?",
    cancel: "취소",
    "continue": "계속"
  }
};
var order$5 = {
  orderDetails: orderDetails$5,
  orderHistory: orderHistory$5,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$5,
  returnRequestList: returnRequestList$5,
  returnRequest: returnRequest$5,
  reorder: reorder$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  myAccountV2Order: myAccountV2Order$5,
  order: order$5
};
var myAccountV2OrderHistory$4 = {
  heading: "Wszystkie zamówienia ({{param}})",
  item: "{{param}} pozycja",
  items: "{{param}} pozycje(-i)",
  totalPrice: "Cena łączna: {{param}}",
  consignmentCode: "Konsygnacja {{param}}",
  statusDate: "Ostatnia aktualizacja: {{param}}",
  returnProcessed: "Zwrot przetworzony: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "Do pobrania - "
  },
  checkoutMode: {
    deliveryEntries: "Do wysłania - "
  },
  checkoutPickupInStore: {
    heading: "Do pobrania - "
  },
  orderListResults: "Lista zamówień",
  orderListPagination: "Stronicowanie listy zamówień",
  totalPriceLabel: "Cena łączna",
  orderPlaced: "Data złożenia zamówienia",
  orderCodeLabel: "Kod zamówienia",
  consignmentDetailLabel: "Informacja o konsygnacji",
  consignmentNumberLabel: "Numer konsygnacji",
  consignmentStatusLabel: "Status konsygnacji",
  consignmentStatusDateLabel: "Data ostatniej aktualizacji",
  estimateDeliveryLabel: "Szacowana data dostawy"
};
var myAccountV2OrderDetails$4 = {
  returnItems: "Zwróć pozycje",
  cancelItems: "Anuluj pozycje",
  downloadInvoices: "Pobierz faktury",
  viewAllOrders: "Wyświetl wszystkie zamówienia",
  noInvoices: "Faktury nie zostały jeszcze wygenerowane. Wróć później."
};
var myAccountV2Orders$4 = {
  item: "{{value}} pozycja",
  items: "{{value}} pozycje(-i)",
  heading: "Zamówienia i zwroty",
  orderNumber: "Numer zamówienia ({{value}})",
  purchasedOn: "Data zakupu: {{value}}",
  orderedItems: "Pozycje zamówienia: {{value}}",
  totalPrice: "Cena łączna: {{value}}",
  orderDetails: "Szczegóły zamówienia",
  orderDetailsLabel: "Pokaż szczegóły zamówienia dla numeru zamówienia {{value}}",
  orderStatusLabel: "Status zamówienia",
  returnOrder: "Zlecenie zwrotu",
  showMore: "Pokaż więcej",
  showMoreLabel: "Przejdź do historii zamówienia"
};
var myAccountV2Order$4 = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$4,
  myAccountV2OrderDetails: myAccountV2OrderDetails$4,
  myAccountV2Orders: myAccountV2Orders$4
};
var orderDetails$4 = {
  orderId: "Zamówienie nr",
  orderNumber: "Numer zamówienia",
  replenishmentId: "Uzupełnianie nr",
  purchaseOrderId: "Zamówienie nr",
  purchaseOrderNumber: "Numer zamówienia",
  emptyPurchaseOrderId: "Brak",
  none: "Brak {{value}}",
  placed: "Złożone",
  placedBy: "Złożone przez",
  unit: "Jednostka",
  costCenter: "Miejsce powstawania kosztów",
  costCenterAndUnit: "Miejsce powstawania kosztów/jednostka",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Metoda płatności",
  payByAccount: "Płatność przez konto",
  paidByCreditCard: "(zapłacono kartą kredytową)",
  status: "Status",
  active: "Aktywne",
  shippedOn: "Data wysłania",
  shippingMethod: "Metoda wysyłki",
  placedOn: "Data złożenia",
  startOn: "Data rozpoczęcia",
  nextOrderDate: "Data następnego zamówienia",
  frequency: "Częstotliwość",
  cancelled: " Anulowane",
  deliveryStatus_IN_TRANSIT: "W drodze",
  deliveryStatus_READY_FOR_PICKUP: "Gotowe do pobrania",
  deliveryStatus_READY_FOR_SHIPPING: "Gotowe do wysyłki",
  deliveryStatus_WAITING: "Oczekujące",
  deliveryStatus_DELIVERING: "Dostarczanie",
  deliveryStatus_PICKPACK: "Przygotowanie do wysyłki",
  deliveryStatus_PICKUP_COMPLETE: "Odbiór zakończony",
  deliveryStatus_DELIVERY_COMPLETED: "Dostawa zakończona",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Problem z płatnością",
  deliveryStatus_IN_PROCESS: "Przetwarzanie zamówienia",
  deliveryStatus_READY: "Przetwarzanie zamówienia",
  deliveryStatus_DELIVERY_REJECTED: "Dostawa odrzucona",
  deliveryStatus_SHIPPED: "Wysłane",
  deliveryStatus_TAX_NOT_COMMITTED: "Problem podatkowy",
  deliveryStatus_CANCELLED: "Anulowane",
  statusDisplay_cancelled: "Anulowane",
  statusDisplay_cancelling: "Anuluj oczekujące",
  statusDisplay_completed: "Zakończone",
  statusDisplay_created: "Utworzone",
  statusDisplay_error: "Oczekujące",
  statusDisplay_Error: "Oczekujące",
  statusDisplay_processing: "Oczekujące",
  statusDisplay_open: "Otwarte",
  statusDisplay_pending: {
    approval: "Oczekuje na zatwierdzenie",
    merchant: {
      approval: "Oczekuje na zatwierdzenie przez sprzedawcę"
    }
  },
  statusDisplay_approved: "Zatwierdzone",
  statusDisplay_rejected: "Odrzucone",
  statusDisplay_merchant: {
    approved: "Zatwierdzone przez sprzedawcę",
    rejected: "Odrzucone przez sprzedawcę"
  },
  statusDisplay_assigned: {
    admin: "Przypisane do administratora"
  },
  consignmentTracking: {
    action: "Śledź pakiet",
    dialog: {
      header: "Informacje śledzenia",
      shipped: "Wysłane",
      estimate: "Szacowana dostawa",
      carrier: "Usługa dostawy",
      trackingId: "Numer śledzenia",
      noTracking: "Pakiet nie został wysłany z magazynu. Informacje o śledzeniu przesyłki będą dostępne po jej wysłaniu.",
      loadingHeader: "Śledzenie konsygnacji"
    }
  },
  cancellationAndReturn: {
    returnAction: "Żądaj zwrotu",
    cancelAction: "Anuluj pozycje",
    item: "Pozycja",
    itemPrice: "Cena pozycji",
    quantity: "Maks. ilość",
    returnQty: "Ilość do zwrotu",
    cancelQty: "Ilość do anulowania",
    setAll: "Ustaw wszystkie ilości na maksimum",
    totalPrice: "Suma",
    submit: "Prześlij zapytanie",
    submitDescription: "Prześlij żądanie. Elementy na tej stronie zostaną uwzględnione w żądaniu anulowania.",
    returnSuccess: "Żądanie zwrotu ({{rma}}) zostało przesłane",
    cancelSuccess: "Żądanie anulowania zostało przesłane (oryginalne zamówienie {{orderCode}} zostanie zaktualizowane)"
  },
  cancelReplenishment: {
    title: "Anuluj uzupełnienie",
    description: "Czy anulować przyszłe zlecenie uzupełnienia?",
    accept: "Tak",
    reject: "Nie",
    cancelSuccess: "Zlecenie uzupełnienia zapasów nr {{replenishmentOrderCode}} zostało pomyślnie anulowane"
  },
  caption: "Treść zamówienia."
};
var orderHistory$4 = {
  orderHistory: "Historia zamówienia",
  orderId: "Zamówienie nr",
  emptyPurchaseOrderId: "Brak",
  date: "Data",
  status: "Status",
  PONumber: "Nr zamówienia",
  total: "Suma",
  noOrders: "Nie mamy rekordów zamówień dla tego konta.",
  noReplenishmentOrders: "Nie mamy rekordów zamówień uzupełnienia dla tego konta.",
  startShopping: "Rozpocznij zakupy",
  sortBy: "Sortuj według",
  sortOrders: "Sortuj zamówienia",
  replenishmentOrderHistory: "Historia zleceń uzupełnienia",
  replenishmentOrderId: "Uzupełnienie nr",
  purchaseOrderNumber: "Zamówienie nr",
  costCenter: "Miejsce powstawania kosztów",
  startOn: "Data rozpoczęcia",
  frequency: "Częstotliwość",
  nextOrderDate: "Data następnego zamówienia",
  cancel: "Anuluj",
  cancelled: "Anulowane",
  replenishmentHistory: "Historia uzupełniania",
  notFound: "Nie znaleziono zamówień",
  actions: "Czynności"
};
var AccountOrderHistoryTabContainer$4 = {
  tabs: {
    AccountOrderHistoryComponent: "WSZYSTKIE ZAMÓWIENIA ({{param}})",
    OrderReturnRequestListComponent: "ZWROTY ({{param}})"
  },
  tabPanelContainerRegion: "Grupa ze szczegółami historii zamówień",
  tabPanelContainerRegionGroup: "Grupa ze szczegółami historii zamówień"
};
var returnRequestList$4 = {
  returnRequestId: "Zwrot nr",
  orderId: "Zamówienie nr",
  date: "Data utworzenia",
  status: "Status",
  sortBy: "Sortuj według",
  sortReturns: "Sortuj zwroty",
  statusDisplay_APPROVAL_PENDING: "Oczekuje na zatwierdzenie",
  statusDisplay_CANCELED: "Anulowane",
  statusDisplay_CANCELLING: "Anulowanie",
  statusDisplay_WAIT: "Oczekuje",
  statusDisplay_RECEIVED: "Otrzymane",
  statusDisplay_RECEIVING: "Odbieranie",
  statusDisplay_APPROVING: "Zatwierdzanie",
  statusDisplay_REVERSING_PAYMENT: "Stornowanie płatności",
  statusDisplay_PAYMENT_REVERSED: "Płatność wystornowana",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Stornowanie płatności nie powiodło się",
  statusDisplay_REVERSING_TAX: "Stornowanie podatku",
  statusDisplay_TAX_REVERSED: "Podatek wystornowany",
  statusDisplay_TAX_REVERSAL_FAILED: "Stornowanie podatku nie powiodło się",
  statusDisplay_COMPLETED: "Zakończone"
};
var returnRequest$4 = {
  returnRequestId: "Żądanie zwrotu nr",
  orderCode: "Dla zamówienia nr",
  status: "Status zwrotu",
  cancel: "Anuluj żądanie zwrotu",
  item: "Opis",
  itemPrice: "Cena pozycji",
  returnQty: "Ilość zwrotów",
  total: "Suma",
  summary: "Sumy zwrotów",
  subtotal: "Suma częściowa",
  deliveryCode: "Koszt dostawy",
  estimatedRefund: "Szacowany zwrot",
  note: "Łączne kwoty są szacunkowe i mogą nie obejmować obowiązujących podatków lub innych opłat.",
  cancelSuccess: "Żądanie zwrotu ({{rma}}) zostało anulowane",
  caption: "Treść zamówienia."
};
var reorder$4 = {
  button: "Zamów ponownie",
  dialog: {
    reorderProducts: "Zamów produkty ponownie",
    messages: {
      reviewConfiguration: "Wystąpił błąd w konfiguracji \"{{ productCode}}\".",
      lowStock: "Ilość dla {{ productName }}: {{ quantity }} została zmniejszona do {{ quantityAdded }}.",
      noStock: "{{ productName }} jest obecnie niedostępny w magazynie.",
      pricingError: "Problem z wyceną z \"{{ productCode }}\".",
      unresolvableIssues: "Niezidentyfikowany problem z \"{{ productCode }}\".",
      success: "Produkty zostały dodane do koszyka"
    },
    areYouSureToReplaceCart: "Bieżący koszyk zostanie zastąpiony nowymi pozycjami. Czy chcesz kontynuować?",
    cancel: "Anuluj",
    "continue": "Kontynuuj"
  }
};
var order$4 = {
  orderDetails: orderDetails$4,
  orderHistory: orderHistory$4,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$4,
  returnRequestList: returnRequestList$4,
  returnRequest: returnRequest$4,
  reorder: reorder$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  myAccountV2Order: myAccountV2Order$4,
  order: order$4
};
var myAccountV2OrderHistory$3 = {
  heading: "Todos os pedidos ({{param}})",
  item: "{{param}} item",
  items: "{{param}} itens",
  totalPrice: "Preço total: {{param}}",
  consignmentCode: "Consignação {{param}}",
  statusDate: "Última atualização: {{param}}",
  returnProcessed: "Devolução processada: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "A ser retirado - "
  },
  checkoutMode: {
    deliveryEntries: "A ser enviado - "
  },
  checkoutPickupInStore: {
    heading: "A ser retirado - "
  },
  orderListResults: "Lista de pedidos",
  orderListPagination: "Paginação de lista de pedidos",
  totalPriceLabel: "Preço total",
  orderPlaced: "Pedido feito em",
  orderCodeLabel: "Código do pedido",
  consignmentDetailLabel: "Informação da consignação",
  consignmentNumberLabel: "Número da consignação",
  consignmentStatusLabel: "Status da consignação",
  consignmentStatusDateLabel: "Última atualização em",
  estimateDeliveryLabel: "Data estimada de entrega"
};
var myAccountV2OrderDetails$3 = {
  returnItems: "Devolver itens",
  cancelItems: "Cancelar itens",
  downloadInvoices: "Baixar faturas",
  viewAllOrders: "Visualizar todos os pedidos",
  noInvoices: "As faturas ainda não foram geradas. Volte mais tarde."
};
var myAccountV2Orders$3 = {
  item: "{{value}} item",
  items: "{{value}} itens",
  heading: "Pedidos e devoluções",
  orderNumber: "Número do pedido ({{value}})",
  purchasedOn: "Comprado em: {{value}}",
  orderedItems: "Itens pedidos: {{value}}",
  totalPrice: "Preço total: {{value}}",
  orderDetails: "Detalhes do pedido",
  orderDetailsLabel: "Mostrar detalhes do pedido para número do pedido {{value}}",
  orderStatusLabel: "Status do pedido",
  returnOrder: "Pedido de devolução",
  showMore: "Mostrar mais",
  showMoreLabel: "Navegar para histórico do pedido"
};
var myAccountV2Order$3 = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$3,
  myAccountV2OrderDetails: myAccountV2OrderDetails$3,
  myAccountV2Orders: myAccountV2Orders$3
};
var orderDetails$3 = {
  orderId: "Nº do pedido",
  orderNumber: "Número do pedido",
  replenishmentId: "Nº de reabastecimento",
  purchaseOrderId: "N° do pedido",
  purchaseOrderNumber: "Número do pedido",
  emptyPurchaseOrderId: "Nenhum",
  none: "Nenhum {{value}}",
  placed: "Feito",
  placedBy: "Feito por",
  unit: "Unidade",
  costCenter: "Centro de custo",
  costCenterAndUnit: "Centro de custo/unidade",
  costCenterAndUnitValue: "{{costCenterName}}/{{unitName}}",
  methodOfPayment: "Forma de pagamento",
  payByAccount: "Pagar por conta",
  paidByCreditCard: "(pago por cartão de crédito)",
  status: "Status",
  active: "Ativo",
  shippedOn: "Enviado em",
  shippingMethod: "Método de entrega",
  placedOn: "Feito em",
  startOn: "Iniciar em",
  nextOrderDate: "Data do próximo pedido",
  frequency: "Frequência",
  cancelled: " Cancelado",
  deliveryStatus_IN_TRANSIT: "Em trânsito",
  deliveryStatus_READY_FOR_PICKUP: "Pronto para retirada",
  deliveryStatus_READY_FOR_SHIPPING: "Pronto para entrega",
  deliveryStatus_WAITING: "Aguardando",
  deliveryStatus_DELIVERING: "Entregando",
  deliveryStatus_PICKPACK: "Preparando para embarque",
  deliveryStatus_PICKUP_COMPLETE: "Retirada concluída",
  deliveryStatus_DELIVERY_COMPLETED: "Entrega concluída",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Problema no pagamento",
  deliveryStatus_IN_PROCESS: "Processamento do pedido",
  deliveryStatus_READY: "Processamento do pedido",
  deliveryStatus_DELIVERY_REJECTED: "Entrega rejeitada",
  deliveryStatus_SHIPPED: "Enviado",
  deliveryStatus_TAX_NOT_COMMITTED: "Problema fiscal",
  deliveryStatus_CANCELLED: "Cancelado",
  statusDisplay_cancelled: "Cancelado",
  statusDisplay_cancelling: "Cancelamento pendente",
  statusDisplay_completed: "Concluído",
  statusDisplay_created: "Criado",
  statusDisplay_error: "Pendente",
  statusDisplay_Error: "Pendente",
  statusDisplay_processing: "Pendente",
  statusDisplay_open: "Aberto",
  statusDisplay_pending: {
    approval: "Aprovação pendente",
    merchant: {
      approval: "Aprovação do comerciante pendente"
    }
  },
  statusDisplay_approved: "Aprovado",
  statusDisplay_rejected: "Rejeitado",
  statusDisplay_merchant: {
    approved: "Comerciante aprovado",
    rejected: "Comerciante rejeitado"
  },
  statusDisplay_assigned: {
    admin: "Atribuído ao administrador"
  },
  consignmentTracking: {
    action: "Rastrear pacote",
    dialog: {
      header: "Informações de rastreamento",
      shipped: "Enviado",
      estimate: "Entrega estimada",
      carrier: "Serviço de entrega",
      trackingId: "Número de rastreamento",
      noTracking: "O pacote ainda não foi expedido do depósito. As informações de rastreamento estarão disponíveis após o envio do pacote.",
      loadingHeader: "Rastreamento de consignação"
    }
  },
  cancellationAndReturn: {
    returnAction: "Solicitar uma devolução",
    cancelAction: "Cancelar itens",
    item: "Item",
    itemPrice: "Preço do item",
    quantity: "Quantidade máxima",
    returnQty: "Quantidade a devolver",
    cancelQty: "Quantidade a cancelar",
    setAll: "Definir todas as quantidades como máximas",
    totalPrice: "Total",
    submit: "Enviar solicitação",
    submitDescription: "Enviar solicitação. Os itens nessa página serão incluídos na solicitação de cancelamento.",
    returnSuccess: "Sua solicitação de devolução ({{rma}}) foi enviada",
    cancelSuccess: "Sua solicitação de cancelamento foi enviada (o pedido original {{orderCode}} será atualizado)"
  },
  cancelReplenishment: {
    title: "Cancelar reabastecimento",
    description: "Cancelar qualquer pedido de reabastecimento futuro?",
    accept: "Sim",
    reject: "Não",
    cancelSuccess: "O pedido de reabastecimento nº{{replenishmentOrderCode}} foi cancelado com êxito"
  },
  caption: "Conteúdo do pedido."
};
var orderHistory$3 = {
  orderHistory: "Histórico do pedido",
  orderId: "Nº do pedido",
  emptyPurchaseOrderId: "Nenhum",
  date: "Data",
  status: "Status",
  PONumber: "Número da caixa postal",
  total: "Total",
  noOrders: "Não temos registros de pedidos para essa conta.",
  noReplenishmentOrders: "Não temos registros de pedidos de reabastecimento para essa conta.",
  startShopping: "Iniciar compras",
  sortBy: "Ordenar por",
  sortOrders: "Ordenar pedidos",
  replenishmentOrderHistory: "Histórico de reabastecimento",
  replenishmentOrderId: "Nº de reabastecimento",
  purchaseOrderNumber: "Nº do pedido",
  costCenter: "Centro de custo",
  startOn: "Iniciar em",
  frequency: "Frequência",
  nextOrderDate: "Data do próximo pedido",
  cancel: "Cancelar",
  cancelled: "Cancelado",
  replenishmentHistory: "Histórico de reabastecimento",
  notFound: "Nenhum pedido encontrado",
  actions: "Ações"
};
var AccountOrderHistoryTabContainer$3 = {
  tabs: {
    AccountOrderHistoryComponent: "TODOS OS PEDIDOS ({{param}})",
    OrderReturnRequestListComponent: "DEVOLUÇÕES ({{param}})"
  },
  tabPanelContainerRegion: "Agrupar com detalhes do histórico de pedidos",
  tabPanelContainerRegionGroup: "Agrupar com detalhes do histórico de pedidos"
};
var returnRequestList$3 = {
  returnRequestId: "Nº da devolução",
  orderId: "Nº do pedido",
  date: "Data da criação",
  status: "Status",
  sortBy: "Ordenar por",
  sortReturns: "Ordenar devoluções",
  statusDisplay_APPROVAL_PENDING: "Aprovação pendente",
  statusDisplay_CANCELED: "Cancelado",
  statusDisplay_CANCELLING: "Cancelando",
  statusDisplay_WAIT: "Aguarde",
  statusDisplay_RECEIVED: "Recebido",
  statusDisplay_RECEIVING: "Recebendo",
  statusDisplay_APPROVING: "Aprovando",
  statusDisplay_REVERSING_PAYMENT: "Revertendo pagamento",
  statusDisplay_PAYMENT_REVERSED: "Pagamento revertido",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Falha na reversão de pagamento",
  statusDisplay_REVERSING_TAX: "Revertendo imposto",
  statusDisplay_TAX_REVERSED: "Imposto revertido",
  statusDisplay_TAX_REVERSAL_FAILED: "Falha na reversão de imposto",
  statusDisplay_COMPLETED: "Concluído"
};
var returnRequest$3 = {
  returnRequestId: "Nº da solicitação de devolução",
  orderCode: "Para nº do pedido",
  status: "Status da devolução",
  cancel: "Cancelar solicitação de devolução",
  item: "Descrição",
  itemPrice: "Preço do item",
  returnQty: "Quantidade de devolução",
  total: "Total",
  summary: "Totais de devolução",
  subtotal: "Subtotal",
  deliveryCode: "Custo de entrega",
  estimatedRefund: "Devolução estimada",
  note: "Os totais são estimados e podem não incluir impostos aplicáveis ou outras taxas.",
  cancelSuccess: "Sua solicitação de devolução ({{rma}}) foi cancelada",
  caption: "Conteúdo do pedido."
};
var reorder$3 = {
  button: "Pedir novamente",
  dialog: {
    reorderProducts: "Pedir produtos novamente",
    messages: {
      reviewConfiguration: "Ocorreu um erro com a configuração \"{{ productCode}}\".",
      lowStock: "A quantidade para {{ productName }}: {{ quantity }} foi reduzida para {{ quantityAdded }}.",
      noStock: "{{ productName }} está atualmente sem estoque.",
      pricingError: "Problema de determinação do preço com \"{{ productCode }}\".",
      unresolvableIssues: "Problema não reconhecido com \"{{ productCode }}\".",
      success: "Os produtos foram adicionados com êxito ao carrinho"
    },
    areYouSureToReplaceCart: "O carrinho atual será substituído por novos itens. Continuar?",
    cancel: "Cancelar",
    "continue": "Continuar"
  }
};
var order$3 = {
  orderDetails: orderDetails$3,
  orderHistory: orderHistory$3,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$3,
  returnRequestList: returnRequestList$3,
  returnRequest: returnRequest$3,
  reorder: reorder$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  myAccountV2Order: myAccountV2Order$3,
  order: order$3
};
var myAccountV2OrderHistory$2 = {
  heading: "Все заказы ({{param}})",
  item: "{{param}} позиция",
  items: "{{param}} поз.",
  totalPrice: "Общая цена: {{param}}",
  consignmentCode: "Поставка {{param}}",
  statusDate: "Последнее обновление: {{param}}",
  returnProcessed: "Процесс возврата: {{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "Для самовывоза - "
  },
  checkoutMode: {
    deliveryEntries: "Для отправки - "
  },
  checkoutPickupInStore: {
    heading: "Для самовывоза - "
  },
  orderListResults: "Список заказов",
  orderListPagination: "Разбиение списка заказов на страницы",
  totalPriceLabel: "Общая цена",
  orderPlaced: "Заказ размещен",
  orderCodeLabel: "Код заказа",
  consignmentDetailLabel: "Информация о поставке",
  consignmentNumberLabel: "Номер поставки",
  consignmentStatusLabel: "Статус поставки",
  consignmentStatusDateLabel: "Дата последнего обновления",
  estimateDeliveryLabel: "Ориентировочная дата доставки"
};
var myAccountV2OrderDetails$2 = {
  returnItems: "Вернуть позиции",
  cancelItems: "Отменить позиции",
  downloadInvoices: "Выгрузить счета",
  viewAllOrders: "Просмотреть все заказы",
  noInvoices: "Счета пока не сгенерированы. Вернитесь позже."
};
var myAccountV2Orders$2 = {
  item: "{{value}} позиция",
  items: "{{value}} поз.",
  heading: "Заказы и возвраты",
  orderNumber: "Номер заказа ({{value}})",
  purchasedOn: "Дата покупки: {{value}}",
  orderedItems: "Заказанные позиции: {{value}} ",
  totalPrice: "Общая цена: {{value}}",
  orderDetails: "Сведения о заказе",
  orderDetailsLabel: "Показать сведения о заказе для номера заказа {{value}}",
  orderStatusLabel: "Статус заказа",
  returnOrder: "Заказ на возврат",
  showMore: "Показать больше",
  showMoreLabel: "Перейти в историю заказов"
};
var myAccountV2Order$2 = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$2,
  myAccountV2OrderDetails: myAccountV2OrderDetails$2,
  myAccountV2Orders: myAccountV2Orders$2
};
var orderDetails$2 = {
  orderId: "Заказ №",
  orderNumber: "Номер заказа",
  replenishmentId: "Пополнение №",
  purchaseOrderId: "Заказ на покупку №",
  purchaseOrderNumber: "Номер заказа на покупку",
  emptyPurchaseOrderId: "Нет",
  none: "Нет {{value}}",
  placed: "Размещено",
  placedBy: "Кем размещено",
  unit: "Единица",
  costCenter: "МВЗ",
  costCenterAndUnit: "МВЗ / единица",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Метод оплаты",
  payByAccount: "Оплатить со счета",
  paidByCreditCard: "(оплачено картой)",
  status: "Статус",
  active: "Активно",
  shippedOn: "Дата отправки",
  shippingMethod: "Метод доставки",
  placedOn: "Дата размещения",
  startOn: "Дата начала",
  nextOrderDate: "Дата следующего заказа",
  frequency: "Периодичность",
  cancelled: " Отменено",
  deliveryStatus_IN_TRANSIT: "В пути",
  deliveryStatus_READY_FOR_PICKUP: "Готово к вывозу",
  deliveryStatus_READY_FOR_SHIPPING: "Готово к отправке",
  deliveryStatus_WAITING: "В ожидании",
  deliveryStatus_DELIVERING: "Доставка выполняется",
  deliveryStatus_PICKPACK: "Подготовка к отправке",
  deliveryStatus_PICKUP_COMPLETE: "Вывоз завершен",
  deliveryStatus_DELIVERY_COMPLETED: "Доставка завершена",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Проблема при оплате",
  deliveryStatus_IN_PROCESS: "Обработка заказа",
  deliveryStatus_READY: "Обработка заказа",
  deliveryStatus_DELIVERY_REJECTED: "Доставка отклонена",
  deliveryStatus_SHIPPED: "Отправлено",
  deliveryStatus_TAX_NOT_COMMITTED: "Проблема с налогами",
  deliveryStatus_CANCELLED: "Отменено",
  statusDisplay_cancelled: "Отменено",
  statusDisplay_cancelling: "Ожидает отмены",
  statusDisplay_completed: "Завершено",
  statusDisplay_created: "Создано",
  statusDisplay_error: "Ожидает",
  statusDisplay_Error: "Ожидает",
  statusDisplay_processing: "Ожидает",
  statusDisplay_open: "Открыто",
  statusDisplay_pending: {
    approval: "Ожидает утверждения",
    merchant: {
      approval: "Ожидает утверждения продавца"
    }
  },
  statusDisplay_approved: "Утверждено",
  statusDisplay_rejected: "Отклонено",
  statusDisplay_merchant: {
    approved: "Продавец утвердил",
    rejected: "Продавец отклонил"
  },
  statusDisplay_assigned: {
    admin: "Направлено администратору"
  },
  consignmentTracking: {
    action: "Отслеживать пакет",
    dialog: {
      header: "Информация для отслеживания",
      shipped: "Отправлено",
      estimate: "Предполагаемая доставка",
      carrier: "Служба доставки",
      trackingId: "Номер для отслеживания",
      noTracking: "Пакет не отправлен со склада. Информация для отслеживания будет доступна после его отправки.",
      loadingHeader: "Отслеживание поставки"
    }
  },
  cancellationAndReturn: {
    returnAction: "Запросить возврат",
    cancelAction: "Отменить позиции",
    item: "Позиция",
    itemPrice: "Цена позиции",
    quantity: "Максимальное количество",
    returnQty: "Количество для возврата",
    cancelQty: "Количество для отмены",
    setAll: "Установить все количества на максимальные",
    totalPrice: "Итого",
    submit: "Отправить запрос",
    submitDescription: "Отправить запрос. Позиции на этой странице будут включены в запрос на отмену.",
    returnSuccess: "Запрос на возврат ({{rma}}) отправлен",
    cancelSuccess: "Запрос на отмену отправлен (исходный заказ {{orderCode}} будет обновлен) "
  },
  cancelReplenishment: {
    title: "Отменить пополнение",
    description: "Отменить все будущие заказы на пополнение?",
    accept: "Да",
    reject: "Нет",
    cancelSuccess: "Заказ на пополнение №{{replenishmentOrderCode}} отменен"
  },
  caption: "Содержимое заказа."
};
var orderHistory$2 = {
  orderHistory: "История заказов",
  orderId: "Заказ №",
  emptyPurchaseOrderId: "Нет",
  date: "Дата",
  status: "Статус",
  PONumber: "Номер заказа на покупку",
  total: "Итого",
  noOrders: "Нет записей заказов для этой учетной записи.",
  noReplenishmentOrders: "Нет записей заказов на пополнение для этой учетной записи.",
  startShopping: "Начать покупки",
  sortBy: "Сортировать по",
  sortOrders: "Сортировать заказы",
  replenishmentOrderHistory: "История заказов на пополнение",
  replenishmentOrderId: "Пополнение №",
  purchaseOrderNumber: "ЗП №",
  costCenter: "МВЗ",
  startOn: "Дата начала",
  frequency: "Периодичность",
  nextOrderDate: "Дата следующего заказа",
  cancel: "Отменить",
  cancelled: "Отменено",
  replenishmentHistory: "История пополнения",
  notFound: "Заказы не найдены",
  actions: "Операции"
};
var AccountOrderHistoryTabContainer$2 = {
  tabs: {
    AccountOrderHistoryComponent: "ВСЕ ЗАКАЗЫ ({{param}})",
    OrderReturnRequestListComponent: "ВОЗВРАТЫ ({{param}})"
  },
  tabPanelContainerRegion: "Группа со сведениями истории заказов",
  tabPanelContainerRegionGroup: "Группа со сведениями истории заказов"
};
var returnRequestList$2 = {
  returnRequestId: "Возврат №",
  orderId: "Заказ №",
  date: "Дата создания",
  status: "Статус",
  sortBy: "Сортировать по",
  sortReturns: "Сортировать возвраты",
  statusDisplay_APPROVAL_PENDING: "Ожидает утверждения",
  statusDisplay_CANCELED: "Отменено",
  statusDisplay_CANCELLING: "Отмена",
  statusDisplay_WAIT: "Ожидание",
  statusDisplay_RECEIVED: "Получено",
  statusDisplay_RECEIVING: "Получение",
  statusDisplay_APPROVING: "Утверждение",
  statusDisplay_REVERSING_PAYMENT: "Сторнирование платежа",
  statusDisplay_PAYMENT_REVERSED: "Платеж сторнирован",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "Ошибка сторнирования платежа",
  statusDisplay_REVERSING_TAX: "Сторнирование налога",
  statusDisplay_TAX_REVERSED: "Налог сторнирован",
  statusDisplay_TAX_REVERSAL_FAILED: "Ошибка сторнирования налога",
  statusDisplay_COMPLETED: "Завершено"
};
var returnRequest$2 = {
  returnRequestId: "Запрос на возврат №",
  orderCode: "По заказу №",
  status: "Статус возврата",
  cancel: "Отменить запрос на возврат",
  item: "Описание",
  itemPrice: "Цена позиции",
  returnQty: "Количество возврата",
  total: "Итого",
  summary: "Итого к возврату",
  subtotal: "Промежуточная сумма",
  deliveryCode: "Стоимость доставки",
  estimatedRefund: "Предполагаемое возмещение",
  note: "Итоговые суммы являются предполагаемыми и не включают применимые налоги и другие сборы.",
  cancelSuccess: "Запрос на возврат ({{rma}}) отменен",
  caption: "Содержимое заказа."
};
var reorder$2 = {
  button: "Заказать повторно",
  dialog: {
    reorderProducts: "Заказать продукты повторно",
    messages: {
      reviewConfiguration: "Ошибка с конфигурацией \"{{ productCode}}\".",
      lowStock: "Количество {{ productName }}: {{ quantity }}) сокращено до {{ quantityAdded }}.",
      noStock: "{{ productName }} сейчас не в наличии.",
      pricingError: "Проблема при расчете цены для: \"{{ productCode }}\".",
      unresolvableIssues: "Неизвестная проблема с: {{ productCode }}.",
      success: "Продукты добавлены в корзину"
    },
    areYouSureToReplaceCart: "Текущая корзина будет заменена новыми позициями. Продолжить?",
    cancel: "Отменить",
    "continue": "Продолжить"
  }
};
var order$2 = {
  orderDetails: orderDetails$2,
  orderHistory: orderHistory$2,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$2,
  returnRequestList: returnRequestList$2,
  returnRequest: returnRequest$2,
  reorder: reorder$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  myAccountV2Order: myAccountV2Order$2,
  order: order$2
};
var myAccountV2OrderHistory$1 = {
  heading: "所有订单 ({{param}})",
  item: "{{param}} 个项目",
  items: "{{param}} 个项目",
  totalPrice: "总价：{{param}}",
  consignmentCode: "寄售 {{param}}",
  statusDate: "上次更新：{{param}}",
  returnProcessed: "已处理退货：{{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "待提货 - "
  },
  checkoutMode: {
    deliveryEntries: "待发货 - "
  },
  checkoutPickupInStore: {
    heading: "待提货 - "
  },
  orderListResults: "订单列表",
  orderListPagination: "订单列表分页",
  totalPriceLabel: "总价",
  orderPlaced: "订单下达日期",
  orderCodeLabel: "订单代码",
  consignmentDetailLabel: "寄售信息",
  consignmentNumberLabel: "寄售编号",
  consignmentStatusLabel: "寄售状态",
  consignmentStatusDateLabel: "上次更新日期",
  estimateDeliveryLabel: "预计交付日期"
};
var myAccountV2OrderDetails$1 = {
  returnItems: "退货项目",
  cancelItems: "取消项目",
  downloadInvoices: "下载发票",
  viewAllOrders: "查看所有订单",
  noInvoices: "尚未生成发票。请稍后返回。"
};
var myAccountV2Orders$1 = {
  item: "{{value}} 个项目",
  items: "{{value}} 个项目",
  heading: "订单和退货",
  orderNumber: "订单编号 ({{value}})",
  purchasedOn: "购买日期：{{value}}",
  orderedItems: "订购项目：{{value}}",
  totalPrice: "总价：{{value}}",
  orderDetails: "订单详细信息",
  orderDetailsLabel: "显示订单编号 {{value}} 的订单详细信息",
  orderStatusLabel: "订单状态",
  returnOrder: "退货订单",
  showMore: "显示更多",
  showMoreLabel: "导航到订单历史记录"
};
var myAccountV2Order$1 = {
  myAccountV2OrderHistory: myAccountV2OrderHistory$1,
  myAccountV2OrderDetails: myAccountV2OrderDetails$1,
  myAccountV2Orders: myAccountV2Orders$1
};
var orderDetails$1 = {
  orderId: "订单编号",
  orderNumber: "订单编号",
  replenishmentId: "补货编号",
  purchaseOrderId: "采购订单编号",
  purchaseOrderNumber: "采购订单号",
  emptyPurchaseOrderId: "无",
  none: "无 {{value}}",
  placed: "已下单",
  placedBy: "订货人",
  unit: "单位",
  costCenter: "成本中心",
  costCenterAndUnit: "成本中心/单位",
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "付款方式",
  payByAccount: "通过账户支付",
  paidByCreditCard: "（通过信用卡支付）",
  status: "状态",
  active: "活动",
  shippedOn: "发货日期",
  shippingMethod: "发货方式",
  placedOn: "下单日期",
  startOn: "开始日期",
  nextOrderDate: "下一个订单日期",
  frequency: "频率",
  cancelled: " 已取消",
  deliveryStatus_IN_TRANSIT: "正在途中",
  deliveryStatus_READY_FOR_PICKUP: "可提货",
  deliveryStatus_READY_FOR_SHIPPING: "准备发货",
  deliveryStatus_WAITING: "正在等待",
  deliveryStatus_DELIVERING: "正在送货",
  deliveryStatus_PICKPACK: "准备装运",
  deliveryStatus_PICKUP_COMPLETE: "提货完成",
  deliveryStatus_DELIVERY_COMPLETED: "送货完成",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "付款问题",
  deliveryStatus_IN_PROCESS: "订单处理",
  deliveryStatus_READY: "订单处理",
  deliveryStatus_DELIVERY_REJECTED: "拒绝送货",
  deliveryStatus_SHIPPED: "已发货",
  deliveryStatus_TAX_NOT_COMMITTED: "税收问题",
  deliveryStatus_CANCELLED: "已取消",
  statusDisplay_cancelled: "已取消",
  statusDisplay_cancelling: "取消待处理状态",
  statusDisplay_completed: "已完成",
  statusDisplay_created: "已创建",
  statusDisplay_error: "待处理",
  statusDisplay_Error: "待处理",
  statusDisplay_processing: "待处理",
  statusDisplay_open: "未完成",
  statusDisplay_pending: {
    approval: "等待批准",
    merchant: {
      approval: "等待商家批准"
    }
  },
  statusDisplay_approved: "已批准",
  statusDisplay_rejected: "已拒绝",
  statusDisplay_merchant: {
    approved: "商家已批准",
    rejected: "商家已拒绝"
  },
  statusDisplay_assigned: {
    admin: "已分配至管理员"
  },
  consignmentTracking: {
    action: "跟踪包",
    dialog: {
      header: "跟踪信息",
      shipped: "已发货",
      estimate: "预计交付",
      carrier: "送货服务",
      trackingId: "跟踪编号",
      noTracking: "包尚未从仓库分派。包发货后，将提供跟踪信息。",
      loadingHeader: "发货跟踪"
    }
  },
  cancellationAndReturn: {
    returnAction: "请求退货",
    cancelAction: "取消项目",
    item: "商品",
    itemPrice: "商品价格",
    quantity: "最大数量",
    returnQty: "退货数量",
    cancelQty: "取消数量",
    setAll: "将所有数量设置为最大值",
    totalPrice: "总计",
    submit: "提交请求",
    submitDescription: "提交请求。此页面上的内容将包含在取消请求中。",
    returnSuccess: "已提交您的退货请求 ({{rma}})",
    cancelSuccess: "您的取消请求已提交（原始订单 {{orderCode}} 将更新）"
  },
  cancelReplenishment: {
    title: "取消补货",
    description: "取消任何未来补货单？",
    accept: "是",
    reject: "否",
    cancelSuccess: "已成功取消补货单编号 {{replenishmentOrderCode}}"
  },
  caption: "订单内容。"
};
var orderHistory$1 = {
  orderHistory: "订单历史记录",
  orderId: "订单号",
  emptyPurchaseOrderId: "无",
  date: "日期",
  status: "状态",
  PONumber: "采购订单号",
  total: "总计",
  noOrders: "我们没有此账户的订单记录。",
  noReplenishmentOrders: "我们没有此账户的补货单记录。",
  startShopping: "开始购物",
  sortBy: "排序依据",
  sortOrders: "排序顺序",
  replenishmentOrderHistory: "补货单历史记录",
  replenishmentOrderId: "补货编号",
  purchaseOrderNumber: "采购订单编号",
  costCenter: "成本中心",
  startOn: "开始日期",
  frequency: "频率",
  nextOrderDate: "下一个订单日期",
  cancel: "取消",
  cancelled: "已取消",
  replenishmentHistory: "补货历史记录",
  notFound: "未找到订单",
  actions: "操作"
};
var AccountOrderHistoryTabContainer$1 = {
  tabs: {
    AccountOrderHistoryComponent: "所有订单 ({{param}})",
    OrderReturnRequestListComponent: "退货 ({{param}})"
  },
  tabPanelContainerRegion: "包含订单历史记录详细信息的组",
  tabPanelContainerRegionGroup: "包含订单历史记录详细信息的组"
};
var returnRequestList$1 = {
  returnRequestId: "退货编号",
  orderId: "订单号",
  date: "创建日期",
  status: "状态",
  sortBy: "排序依据",
  sortReturns: "排序退货",
  statusDisplay_APPROVAL_PENDING: "待审批",
  statusDisplay_CANCELED: "已取消",
  statusDisplay_CANCELLING: "正在取消",
  statusDisplay_WAIT: "等待",
  statusDisplay_RECEIVED: "所收到的商品",
  statusDisplay_RECEIVING: "正在接收",
  statusDisplay_APPROVING: "正在批准",
  statusDisplay_REVERSING_PAYMENT: "正在冲销付款",
  statusDisplay_PAYMENT_REVERSED: "付款冲销",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "付款冲销失败",
  statusDisplay_REVERSING_TAX: "正在冲销税费",
  statusDisplay_TAX_REVERSED: "税费冲销",
  statusDisplay_TAX_REVERSAL_FAILED: "税费冲销失败",
  statusDisplay_COMPLETED: "已完成"
};
var returnRequest$1 = {
  returnRequestId: "退货请求编号",
  orderCode: "针对订单编号",
  status: "退货状态",
  cancel: "取消退货请求",
  item: "描述",
  itemPrice: "商品价格",
  returnQty: "退货数量",
  total: "总计",
  summary: "退货总计",
  subtotal: "小计",
  deliveryCode: "送货成本",
  estimatedRefund: "预计退货",
  note: "总额为估计值，可能不包括适用的税费或其他费用。",
  cancelSuccess: "已取消您的退货请求 ({{rma}})",
  caption: "订单内容。"
};
var reorder$1 = {
  button: "重订货",
  dialog: {
    reorderProducts: "重新订购产品",
    messages: {
      reviewConfiguration: "“{{ productCode}}”配置发生错误。",
      lowStock: "{{ productName }} 的数量：{{ quantity }} 已减少至 {{ quantityAdded }}。",
      noStock: "{{ productName }} 目前缺货。",
      pricingError: "“{{ productCode }}”存在定价问题。",
      unresolvableIssues: "“{{ productCode }}”存在无法识别的问题。",
      success: "产品已成功添加到购物车"
    },
    areYouSureToReplaceCart: "当前购物车将由新项目替换。是否要继续？",
    cancel: "取消",
    "continue": "继续"
  }
};
var order$1 = {
  orderDetails: orderDetails$1,
  orderHistory: orderHistory$1,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer$1,
  returnRequestList: returnRequestList$1,
  returnRequest: returnRequest$1,
  reorder: reorder$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  myAccountV2Order: myAccountV2Order$1,
  order: order$1
};
var myAccountV2OrderHistory = {
  heading: "所有訂單 ({{param}})",
  item: "{{param}} 個項目",
  items: "{{param}} 個項目",
  totalPrice: "總價：{{param}}",
  consignmentCode: "託售 {{param}}",
  statusDate: "上次更新：{{param}}",
  returnProcessed: "已處理退貨：{{param}}",
  deliveryPointOfServiceDetails: {
    itemsToBePickUp: "待撿貨 "
  },
  checkoutMode: {
    deliveryEntries: "待出貨 "
  },
  checkoutPickupInStore: {
    heading: "待撿貨 "
  },
  orderListResults: "訂單清單",
  orderListPagination: "訂單清單分頁",
  totalPriceLabel: "總價",
  orderPlaced: "下訂日期",
  orderCodeLabel: "訂單代碼",
  consignmentDetailLabel: "託售資訊",
  consignmentNumberLabel: "託售號碼",
  consignmentStatusLabel: "託售狀態",
  consignmentStatusDateLabel: "上次更新日期",
  estimateDeliveryLabel: "預計交貨日期"
};
var myAccountV2OrderDetails = {
  returnItems: "退貨項目",
  cancelItems: "取消項目",
  downloadInvoices: "下載發票",
  viewAllOrders: "檢視全部訂單",
  noInvoices: "發票尚未產生，請稍後再查看。"
};
var myAccountV2Orders = {
  item: "{{value}} 個項目",
  items: "{{value}} 個項目",
  heading: "訂單和退貨",
  orderNumber: "訂單號碼 ({{value}})",
  purchasedOn: "採購日期：{{value}}",
  orderedItems: "訂購項目：{{value}}",
  totalPrice: "總價：{{value}}",
  orderDetails: "訂單明細",
  orderDetailsLabel: "顯示訂單號碼 {{value}} 的訂單明細",
  orderStatusLabel: "訂單狀態",
  returnOrder: "退貨單",
  showMore: "顯示更多",
  showMoreLabel: "瀏覽至訂單歷史記錄"
};
var myAccountV2Order = {
  myAccountV2OrderHistory: myAccountV2OrderHistory,
  myAccountV2OrderDetails: myAccountV2OrderDetails,
  myAccountV2Orders: myAccountV2Orders
};
var orderDetails = {
  orderId: "訂單號碼",
  orderNumber: "訂單號碼",
  replenishmentId: "補貨號碼",
  purchaseOrderId: "採購單號碼",
  purchaseOrderNumber: "採購單號碼",
  emptyPurchaseOrderId: "無",
  none: "無 {{value}}",
  placed: "已下單",
  placedBy: "下單者",
  unit: "單位",
  costCenter: "成本中心",
  costCenterAndUnit: "成本中心/單位",
  costCenterAndUnitValue: "{{costCenterName}}/{{unitName}}",
  methodOfPayment: "付款方法",
  payByAccount: "由帳戶付款",
  paidByCreditCard: "(由信用卡付款)",
  status: "狀態",
  active: "啟用中",
  shippedOn: "出貨日期",
  shippingMethod: "出貨方法",
  placedOn: "下單日期",
  startOn: "開始日期",
  nextOrderDate: "下一個訂單日期",
  frequency: "頻率",
  cancelled: " 已取消",
  deliveryStatus_IN_TRANSIT: "在途",
  deliveryStatus_READY_FOR_PICKUP: "就緒可取貨",
  deliveryStatus_READY_FOR_SHIPPING: "就緒可出貨",
  deliveryStatus_WAITING: "等待中",
  deliveryStatus_DELIVERING: "正在交貨",
  deliveryStatus_PICKPACK: "正在準備出貨",
  deliveryStatus_PICKUP_COMPLETE: "取貨完成",
  deliveryStatus_DELIVERY_COMPLETED: "交貨完成",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "付款問題",
  deliveryStatus_IN_PROCESS: "訂單處理中",
  deliveryStatus_READY: "訂單處理中",
  deliveryStatus_DELIVERY_REJECTED: "已拒絕交貨",
  deliveryStatus_SHIPPED: "已出貨",
  deliveryStatus_TAX_NOT_COMMITTED: "稅務問題",
  deliveryStatus_CANCELLED: "已取消",
  statusDisplay_cancelled: "已取消",
  statusDisplay_cancelling: "取消待處理",
  statusDisplay_completed: "已完成",
  statusDisplay_created: "已建立",
  statusDisplay_error: "待處理",
  statusDisplay_Error: "待處理",
  statusDisplay_processing: "待處理",
  statusDisplay_open: "未結",
  statusDisplay_pending: {
    approval: "待核准",
    merchant: {
      approval: "待商家核准"
    }
  },
  statusDisplay_approved: "已核准",
  statusDisplay_rejected: "已拒絕",
  statusDisplay_merchant: {
    approved: "商家已核准",
    rejected: "商家已拒絕"
  },
  statusDisplay_assigned: {
    admin: "已指派給管理員"
  },
  consignmentTracking: {
    action: "追蹤包裹",
    dialog: {
      header: "追蹤資訊",
      shipped: "已出貨",
      estimate: "預計交貨",
      carrier: "交貨服務",
      trackingId: "追蹤號碼",
      noTracking: "包裹未從倉庫發貨。包裹出貨後，才會提供追蹤資訊。",
      loadingHeader: "託售追蹤"
    }
  },
  cancellationAndReturn: {
    returnAction: "請求退貨",
    cancelAction: "取消項目",
    item: "項目",
    itemPrice: "項目價格",
    quantity: "最大數量",
    returnQty: "待退貨數量",
    cancelQty: "待取消數量",
    setAll: "將所有數量設定為最大值",
    totalPrice: "總計",
    submit: "提交請求",
    submitDescription: "提交請求。此頁面上的項目將包含在取消請求中。",
    returnSuccess: "已提交您的退貨請求 ({{rma}})",
    cancelSuccess: "已提交您的取消請求 (原始訂單 {{orderCode}} 將更新)"
  },
  cancelReplenishment: {
    title: "取消補貨",
    description: "是否取消未來補貨訂單？",
    accept: "是",
    reject: "否",
    cancelSuccess: "已成功取消補貨訂單號碼 {{replenishmentOrderCode}}"
  },
  caption: "訂單內容。"
};
var orderHistory = {
  orderHistory: "訂單歷史記錄",
  orderId: "訂單號碼",
  emptyPurchaseOrderId: "無",
  date: "日期",
  status: "狀態",
  PONumber: "採購單號碼",
  total: "總計",
  noOrders: "此帳戶沒有訂單記錄。",
  noReplenishmentOrders: "此帳戶沒有補貨訂單記錄",
  startShopping: "開始購物",
  sortBy: "排序依據",
  sortOrders: "排序順序",
  replenishmentOrderHistory: "補貨訂單歷史記錄",
  replenishmentOrderId: "補貨號碼",
  purchaseOrderNumber: "訂購單號碼",
  costCenter: "成本中心",
  startOn: "開始日期",
  frequency: "頻率",
  nextOrderDate: "下一個訂單日期",
  cancel: "取消",
  cancelled: "已取消",
  replenishmentHistory: "補貨歷史記錄",
  notFound: "找不到訂單",
  actions: "動作"
};
var AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: "所有訂單 ({{param}})",
    OrderReturnRequestListComponent: "退貨 ({{param}})"
  },
  tabPanelContainerRegion: "以訂單歷史記錄明細分組",
  tabPanelContainerRegionGroup: "以訂單歷史記錄明細分組"
};
var returnRequestList = {
  returnRequestId: "退貨號碼",
  orderId: "訂單號碼",
  date: "建立日期",
  status: "狀態",
  sortBy: "排序依據",
  sortReturns: "排序退貨",
  statusDisplay_APPROVAL_PENDING: "核准待處理",
  statusDisplay_CANCELED: "已取消",
  statusDisplay_CANCELLING: "正在取消",
  statusDisplay_WAIT: "等待",
  statusDisplay_RECEIVED: "已接收",
  statusDisplay_RECEIVING: "正在接收",
  statusDisplay_APPROVING: "正在核准",
  statusDisplay_REVERSING_PAYMENT: "正在迴轉付款",
  statusDisplay_PAYMENT_REVERSED: "已迴轉付款",
  statusDisplay_PAYMENT_REVERSAL_FAILED: "付款迴轉失敗",
  statusDisplay_REVERSING_TAX: "正在迴轉稅款",
  statusDisplay_TAX_REVERSED: "已迴轉稅款",
  statusDisplay_TAX_REVERSAL_FAILED: "稅款迴轉失敗",
  statusDisplay_COMPLETED: "已完成"
};
var returnRequest = {
  returnRequestId: "退貨請求號碼",
  orderCode: "針對訂單號碼",
  status: "退貨狀態",
  cancel: "取消退貨請求",
  item: "說明",
  itemPrice: "項目價格",
  returnQty: "退貨數量",
  total: "總計",
  summary: "退貨總計",
  subtotal: "小計",
  deliveryCode: "交貨成本",
  estimatedRefund: "預計退款",
  note: "已預計總計，但可能未包含適用稅款貨其他費用。",
  cancelSuccess: "已取消您的退貨請求 ({{rma}})",
  caption: "訂單內容。"
};
var reorder = {
  button: "重新訂購",
  dialog: {
    reorderProducts: "重新訂購產品",
    messages: {
      reviewConfiguration: "\"{{ productCode}}\" 組態發生錯誤。",
      lowStock: "{{ productName }} 的數量：{{ quantity }} 已減少為 {{ quantityAdded }}。",
      noStock: "{{ productName }} 目前缺貨。",
      pricingError: "\"{{ productCode }}\" 發生定價問題。",
      unresolvableIssues: "\"{{ productCode }}\" 發生無法識別的問題。",
      success: "已成功將產品新增至購物車"
    },
    areYouSureToReplaceCart: "目前購物車將使用新項目取代。您要繼續嗎？",
    cancel: "取消",
    "continue": "繼續"
  }
};
var order = {
  orderDetails: orderDetails,
  orderHistory: orderHistory,
  AccountOrderHistoryTabContainer: AccountOrderHistoryTabContainer,
  returnRequestList: returnRequestList,
  returnRequest: returnRequest,
  reorder: reorder
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  myAccountV2Order,
  order
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const orderTranslationChunksConfig = {
  myAccountV2Order: ['myAccountV2OrderHistory', 'myAccountV2OrderDetails', 'myAccountV2Orders'],
  order: ['orderDetails', 'orderHistory', 'AccountOrderHistoryTabContainer', 'returnRequestList', 'returnRequest', 'reorder']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: orderTranslations
 *             +   resources: { en: orderTranslationsEn }
 *               }
 *             ```
 */
const orderTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { orderTranslationChunksConfig, orderTranslations, cs as orderTranslationsCs, de as orderTranslationsDe, en as orderTranslationsEn, es as orderTranslationsEs, es_CO as orderTranslationsEs_CO, fr as orderTranslationsFr, hi as orderTranslationsHi, hu as orderTranslationsHu, id as orderTranslationsId, it as orderTranslationsIt, ja as orderTranslationsJa, ko as orderTranslationsKo, pl as orderTranslationsPl, pt as orderTranslationsPt, ru as orderTranslationsRu, zh as orderTranslationsZh, zh_TW as orderTranslationsZh_TW };
