var dpPaymentForm$w = {
  redirect: "Za chvíli vás přesměrujeme na stránku třetí strany k registraci karty",
  callback: "Chvíli počkejte… načítáme detaily platby…",
  cancelledOrFailed: "Registrace karty selhala nebo byla zrušena",
  error: {
    redirect: "Nepodařilo se spustit operaci. Zkuste to znovu.",
    paymentFetch: "Nedaří se načíst detaily platby. Zkuste to znovu.",
    unknown: "Došlo k neznámé chybě při načítání platby. Obraťte se na podporu."
  },
  confirmation: {
    heading: "Máte neuložené změny.",
    text: "Pokud nyní zavřete dialogové okno, ztratíte všechny provedené změny. Opravdu chcete okno zavřít?"
  }
};
var dpPaymentForm$x = {
  dpPaymentForm: dpPaymentForm$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  dpPaymentForm: dpPaymentForm$x
};
var dpPaymentForm$u = {
  redirect: "Sie werden in Kürze zur Seite eines Drittanbieters für die Kartenregistrierung weitergeleitet",
  callback: "Bitte warten... Zahlungsdetails werden abgerufen...",
  cancelledOrFailed: "Kartenregistrierung ist fehlgeschlagen oder wurde abgebrochen",
  error: {
    redirect: "Vorgang konnte nicht initiiert werden. Versuchen Sie es erneut.",
    paymentFetch: "Die Zahlungsdetails konnten nicht abgerufen werden. Versuchen Sie es erneut.",
    unknown: "Beim Abrufen der Zahlung ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an den Support."
  },
  confirmation: {
    heading: "Ihre Änderungen wurden noch nicht gespeichert.",
    text: "Wenn Sie diesen Dialog schließen, gehen alle Ihre Änderungen verloren. Möchten Sie den Dialog wirklich schließen?"
  }
};
var dpPaymentForm$v = {
  dpPaymentForm: dpPaymentForm$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  dpPaymentForm: dpPaymentForm$v
};
var dpPaymentForm$s = {
  redirect: "You will soon be redirected to third party page for card registration",
  callback: "Please Wait... Fetching payment details...",
  cancelledOrFailed: "Card registration failed or cancelled",
  error: {
    redirect: "Unable to initiate operation. Please try again",
    paymentFetch: "Unable to fetch payment details. Please try again.",
    unknown: "Unknown error occurred while fetching payment. Please contact support"
  },
  confirmation: {
    heading: "You Have Unsaved Changes.",
    text: "If you close this dialog, you wil lose all the changes you have made. Are you sure you wish to close?"
  }
};
var dpPaymentForm$t = {
  dpPaymentForm: dpPaymentForm$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  dpPaymentForm: dpPaymentForm$t
};
var dpPaymentForm$q = {
  redirect: "En breve se lo redirigirá a la página del tercero para realizar el registro de tarjeta",
  callback: "Espere… Obteniendo detalles de pago…",
  cancelledOrFailed: "Registro de tarjeta fallido o cancelado",
  error: {
    redirect: "No es posible iniciar la operación. Inténtelo de nuevo",
    paymentFetch: "No es posible obtener los detalles de pago. Inténtelo de nuevo.",
    unknown: "Ocurrió un error desconocido al obtener el pago. Póngase en contacto con el servicio de soporte"
  },
  confirmation: {
    heading: "Tiene cambios sin grabar.",
    text: "Si cierra este diálogo, perderá todos los cambios realizados. ¿Confirma que desea cerrar?"
  }
};
var dpPaymentForm$r = {
  dpPaymentForm: dpPaymentForm$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  dpPaymentForm: dpPaymentForm$r
};
var dpPaymentForm$o = {
  redirect: "En breve se lo redirigirá a la página del tercero para realizar el registro de tarjeta",
  callback: "Espere… Obteniendo detalles de pago…",
  cancelledOrFailed: "Registro de tarjeta fallido o cancelado",
  error: {
    redirect: "No es posible iniciar la operación. Inténtelo de nuevo",
    paymentFetch: "No es posible obtener los detalles de pago. Inténtelo de nuevo.",
    unknown: "Ocurrió un error desconocido al obtener el pago. Póngase en contacto con el servicio de soporte"
  },
  confirmation: {
    heading: "Tiene cambios sin guardar.",
    text: "Si cierra este diálogo, perderá todos los cambios realizados. ¿Confirma que desea cerrar?"
  }
};
var dpPaymentForm$p = {
  dpPaymentForm: dpPaymentForm$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  dpPaymentForm: dpPaymentForm$p
};
var dpPaymentForm$m = {
  redirect: "Vous serez bientôt redirigé vers la page d'un tiers pour l'enregistrement de la carte.",
  callback: "Veuillez patienter... Nous récupérons les détails du paiement...",
  cancelledOrFailed: "L'enregistrement de la carte a échoué ou a été annulé.",
  error: {
    redirect: "Impossible de lancer l'opération. Veuillez réessayer.",
    paymentFetch: "Impossible d'obtenir les détails du paiement. Veuillez réessayer.",
    unknown: "Une erreur inconnue s'est produite lors de la récupération du paiement. Veuillez contacter le service d'assistance."
  },
  confirmation: {
    heading: "Vous avez des modifications non sauvegardées.",
    text: "Si vous fermez cette boîte de dialogue, vous perdrez toutes les modifications que vous avez apportées. Êtes-vous sûr de vouloir fermer cette boîte de dialogue ?"
  }
};
var dpPaymentForm$n = {
  dpPaymentForm: dpPaymentForm$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  dpPaymentForm: dpPaymentForm$n
};
var dpPaymentForm$k = {
  redirect: "कार्ड पंजीकरण के लिए हम जल्दी ही तृतीय पक्ष पृष्ठ पर फिर से जाएंगे",
  callback: "कृपया प्रतीक्षा करें... भुगतान विवरण प्राप्त कर रहा है...",
  cancelledOrFailed: "कार्ड पंजीकरण विफल या रद्द हुआ",
  error: {
    redirect: "संचालन प्रारंभ करने में असमर्थ. कृपया फिर से कोशिश करें.",
    paymentFetch: "भुगतान विवरण प्राप्त करने में असमर्थ. कृपया फिर से कोशिश करें.",
    unknown: "भुगतान प्राप्त करते समय अज्ञात त्रुटि उत्पन्न हुई. कृपया सहायता से संपर्क करें"
  },
  confirmation: {
    heading: "आपके न सहेजे गए परिवर्तन हैं.",
    text: "यदि आप यह डायलॉग चुनते हैं, आपके द्वारा किए गए सभी परिवर्तन गुम हो जाएंगे. क्या आप वाकई में बंद करना चाहते हैं?"
  }
};
var dpPaymentForm$l = {
  dpPaymentForm: dpPaymentForm$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  dpPaymentForm: dpPaymentForm$l
};
var dpPaymentForm$i = {
  redirect: "Hamarosan átirányítjuk egy külső oldalra, ahol regisztrálhatja a kártyáját",
  callback: "Várjon... Folyamatban a fizetési adatok lekérése...",
  cancelledOrFailed: "A kártyaregisztráció sikertelen volt vagy megszakították",
  error: {
    redirect: "Nem sikerült elindítani a műveletet. Próbálja újra.",
    paymentFetch: "Nem sikerült lekérni a fizetési adatokat. Próbálja újra.",
    unknown: "Ismeretlen hiba történt a fizetés lekérésekor. Forduljon a támogatáshoz."
  },
  confirmation: {
    heading: "Nem mentette a módosításait.",
    text: "Ha bezárja ezt a párbeszédablakot, elveszíti az összes végrehajtott módosítást. Biztosan szeretné bezárni?"
  }
};
var dpPaymentForm$j = {
  dpPaymentForm: dpPaymentForm$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  dpPaymentForm: dpPaymentForm$j
};
var dpPaymentForm$g = {
  redirect: "Anda akan segera diarahkan ke halaman pihak ketiga untuk pendaftaran kartu",
  callback: "Silakan Tunggu... Sedang mengambil rincian pembayaran...",
  cancelledOrFailed: "Pendaftaran kartu tidak berhasil atau telah dibatalkan",
  error: {
    redirect: "Tidak dapat memulai operasi. Silakan coba lagi",
    paymentFetch: "Tidak dapat mengambil rincian pembayaran. Silakan coba lagi.",
    unknown: "Terjadi kesalahan yang tidak diketahui saat mengambil rincian pembayaran. Silakan hubungi bagian dukungan"
  },
  confirmation: {
    heading: "Ada Perubahan yang Belum Anda Simpan.",
    text: "Jika Anda menutup dialog ini, semua perubahan yang telah dibuat akan hilang. Apakah Anda yakin ingin menutupnya?"
  }
};
var dpPaymentForm$h = {
  dpPaymentForm: dpPaymentForm$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  dpPaymentForm: dpPaymentForm$h
};
var dpPaymentForm$e = {
  redirect: "A breve si verrà reindirizzati a una pagina di terzi per la registrazione della carta",
  callback: "Attendere... recupero dei dettagli di pagamento in corso...",
  cancelledOrFailed: "Registrazione della carta non riuscita o annullata",
  error: {
    redirect: "Impossibile avviare l'operazione. Riprovare.",
    paymentFetch: "Impossibile recuperare i dettagli di pagamento. Riprovare.",
    unknown: "Errore sconosciuto durante il recupero del pagamento. Contattare il supporto."
  },
  confirmation: {
    heading: "Sono presenti modifiche non salvate.",
    text: "Se si chiude questa finestra di dialogo, le modifiche apportate qui andranno perse. Confermare di volerla chiudere?"
  }
};
var dpPaymentForm$f = {
  dpPaymentForm: dpPaymentForm$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  dpPaymentForm: dpPaymentForm$f
};
var dpPaymentForm$c = {
  redirect: "まもなくカード登録用のサードパーティページにリダイレクトされます",
  callback: "お待ちください... 支払詳細をフェッチしています...",
  cancelledOrFailed: "カード登録が失敗したかキャンセルされました",
  error: {
    redirect: "操作を開始できません。もう一度実行してください",
    paymentFetch: "支払詳細をフェッチできません。もう一度実行してください",
    unknown: "支払のフェッチ中に不明なエラーが発生しました。サポートに連絡してください"
  },
  confirmation: {
    heading: "未保存の変更があります。",
    text: "このダイアログを閉じると、行った変更がすべて失われます。閉じてもよろしいですか?"
  }
};
var dpPaymentForm$d = {
  dpPaymentForm: dpPaymentForm$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  dpPaymentForm: dpPaymentForm$d
};
var dpPaymentForm$a = {
  redirect: "카드를 등록할 수 있는 서드 파티 페이지로 곧 이동합니다.",
  callback: "잠시 기다리십시오... 지불 세부사항을 가져오는 중...",
  cancelledOrFailed: "카드를 등록하지 못했거나 취소되었습니다.",
  error: {
    redirect: "작업을 시작할 수 없습니다. 다시 시도하십시오.",
    paymentFetch: "지불 세부사항을 가져올 수 없습니다. 다시 시도하십시오.",
    unknown: "지불 기능을 불러오는 동안 알 수 없는 오류가 발생했습니다. 지원 팀에 문의하십시오."
  },
  confirmation: {
    heading: "저장하지 않은 변경사항이 있습니다.",
    text: "이 대화 상자를 닫으면 변경사항이 모두 손실됩니다. 닫으시겠습니까?"
  }
};
var dpPaymentForm$b = {
  dpPaymentForm: dpPaymentForm$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  dpPaymentForm: dpPaymentForm$b
};
var dpPaymentForm$8 = {
  redirect: "Wkrótce zostaniesz przekierowany na zewnętrzną stronę w celu rejestracji karty",
  callback: "Proszę czekać... Pobieranie szczegółów płatności...",
  cancelledOrFailed: "Rejestracja karty zakończona niepowodzeniem lub anulowana",
  error: {
    redirect: "Nie można rozpocząć operacji. Spróbuj ponownie",
    paymentFetch: "Nie można pobrać szczegółów płatności. Spróbuj ponownie.",
    unknown: "Podczas pobierania płatności wystąpił nieznany błąd. Prosimy o kontakt z obsługą klienta."
  },
  confirmation: {
    heading: "Masz niezapisane zmiany.",
    text: "Zamknięcie tego okna dialogowego spowoduje utratę wszystkich wprowadzonych zmian. Czy na pewno chcesz zamknąć okno?"
  }
};
var dpPaymentForm$9 = {
  dpPaymentForm: dpPaymentForm$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  dpPaymentForm: dpPaymentForm$9
};
var dpPaymentForm$6 = {
  redirect: "Em breve, você será redirecionado para uma página de terceiros para registro do cartão",
  callback: "Aguarde... Obtendo detalhes de pagamento...",
  cancelledOrFailed: "Registro de cartão falhado ou cancelado",
  error: {
    redirect: "Impossível iniciar operação. Tente novamente",
    paymentFetch: "Impossível obter detalhes de pagamento. Tente novamente.",
    unknown: "Ocorreu um erro desconhecido ao obter o pagamento. Contate o suporte"
  },
  confirmation: {
    heading: "Você tem modificações não gravadas.",
    text: "Se você fechar este diálogo, irá perder todas as modificações que efetuou. Fechar?"
  }
};
var dpPaymentForm$7 = {
  dpPaymentForm: dpPaymentForm$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  dpPaymentForm: dpPaymentForm$7
};
var dpPaymentForm$4 = {
  redirect: "Скоро вы будете перенаправлены на стороннюю страницу для регистрации карты",
  callback: "Пожалуйста, подождите... Получение платежных реквизитов...",
  cancelledOrFailed: "Регистрация карты не удалась или отменена",
  error: {
    redirect: "Невозможно инициировать операцию. Попробуйте еще раз.",
    paymentFetch: "Невозможно получить платежные реквизиты. Попробуйте еще раз.",
    unknown: "Неизвестная ошибка при получении оплаты. Обратитесь в службу поддержки."
  },
  confirmation: {
    heading: "У вас есть несохраненные изменения.",
    text: "Если вы закроете этот диалог, вы потеряете все сделанные вами изменения. Вы уверены, что хотите закрыть?"
  }
};
var dpPaymentForm$5 = {
  dpPaymentForm: dpPaymentForm$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  dpPaymentForm: dpPaymentForm$5
};
var dpPaymentForm$2 = {
  redirect: "您即将被重定向到第三方页面进行卡注册",
  callback: "请等待...正在获取付款详细信息...",
  cancelledOrFailed: "卡注册失败或已取消",
  error: {
    redirect: "无法发起操作。请重试",
    paymentFetch: "无法获取付款详细信息。请重试。",
    unknown: "获取付款时出现未知错误。请联系支持部门"
  },
  confirmation: {
    heading: "您有未保存的更改。",
    text: "如果关闭此对话框，您将丢失所有已进行的更改。是否确定要关闭？"
  }
};
var dpPaymentForm$3 = {
  dpPaymentForm: dpPaymentForm$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  dpPaymentForm: dpPaymentForm$3
};
var dpPaymentForm = {
  redirect: "系統即將重新導向至第三方頁面，以進行卡片註冊",
  callback: "請稍候...正在取得付款明細...",
  cancelledOrFailed: "卡片註冊失敗或已取消",
  error: {
    redirect: "無法初始作業，請再試一次",
    paymentFetch: "無法取得付款明細，請再試一次。",
    unknown: "取得付款時發生未知錯誤。請聯絡支援團隊"
  },
  confirmation: {
    heading: "您有未儲存更改內容。",
    text: "若您關閉此對話，則會遺失已做的所有更改內容。您確定要關閉嗎？"
  }
};
var dpPaymentForm$1 = {
  dpPaymentForm: dpPaymentForm
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  dpPaymentForm: dpPaymentForm$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const dpTranslationChunksConfig = {
  dpPaymentForm: ['dpPaymentForm']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: dpTranslations
 *             +   resources: { en: dpTranslationsEn }
 *               }
 *             ```
 */
const dpTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { dpTranslationChunksConfig, dpTranslations, cs as dpTranslationsCs, de as dpTranslationsDe, en as dpTranslationsEn, es as dpTranslationsEs, es_CO as dpTranslationsEs_CO, fr as dpTranslationsFr, hi as dpTranslationsHi, hu as dpTranslationsHu, id as dpTranslationsId, it as dpTranslationsIt, ja as dpTranslationsJa, ko as dpTranslationsKo, pl as dpTranslationsPl, pt as dpTranslationsPt, ru as dpTranslationsRu, zh as dpTranslationsZh, zh_TW as dpTranslationsZh_TW };
