.quote-list {
  width: 90%;
  margin: auto;
  .tile-content {
    .top-heading {
      text-align: center;
      p {
        display: inline-block;
        font-family: var(--font-base-font-family-body);
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-base-font-size-12);
        line-height: 16px;
      }
      .accNo {
        margin-right: 20px;
      }
    }
  }
  .before-table {
    clear: both;
    border: 1px solid #eeeeee;
    display: inline-block;
    width: 100%;
    padding: 0px 10px;
    height: 62px;
    .leftcontent {
      float: left;
      display: flex;
      margin-top: 15px;
      height: 30px;
      a {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 600;
        font-size: var(--font-base-font-size-12);
        line-height: 18px;
        color: #415853;
      }
      .custDetail {
        width: 141px;
        .ng-option {
          display: flex;
          padding-left: 24px;
          input {
            left: 39px;
            width: 16px;
            height: 16px;
            margin-top: 3px;
          }
          .custName {
            position: relative;
            left: 14px;
            top: 2px;
            height: 38px;
          }
          .custNameid {
            position: relative;
            top: 26px;
          }
          small {
            display: contents;
          }
        }
        .ng-select-container {
          border-radius: 19px;
          width: 144px;
        }
        .ng-dropdown-panel-items {
          width: 258px;
          height: 106px;
        }
        .ng-dropdown-panel {
          margin-top: 6px;
        }
      }
    }
    .prodLine {
      width: 141px;
      .ng-option input {
        left: 39px;
        width: 16px;
        height: 16px;
      }
      .prodName {
        position: relative;
        left: 14px;
        top: 2px;
      }
      .ng-select-container {
        border-radius: 19px;
      }
      .ng-dropdown-panel-items {
        min-width: 354px;
        width: auto;
        height: 144px;
      }
      .ng-dropdown-panel {
        margin-top: 6px;
      }
    }
  }
  .rightContent {
    display: flex;
    float: right;
    .dateRange {
      width: 141px;
      margin-left: -8px;
      .pl {
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-base-font-size-12);
        line-height: 16px;
        letter-spacing: 0.25px;
        padding-left: 15px;
        color: #1a2321;
      }
      vs-date-range-picker {
        .date-range-picker {
          width: 37%;
          .calendar-container .mat-calendar {
            width: 100%;
          }
          .action-container {
            margin: 0 10px;
            p.selected-dates {
              margin: 2px 0 4px 0;
            }
            .float-right {
              margin: 5px 0 10px;
            }
          }
        }
      }
    }
    .search {
      text-align: right;
      display: inline-block;
      margin-top: 15px;
      input {
        width: 260px;
        height: 32px;
        font-family: var(--font-base-font-family-body);
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-base-font-size-14);
        line-height: 21px;
        color: #506c65;
        border: 1px solid #9e9e9e;
      }
      input:focus {
        border: 1px solid #d3d6db;
        border: 1px solid #9e9e9e;
      }
      .search-icon {
        right: 39px;
        position: relative;
        top: -30px;
        left: -10px;
        cursor: pointer;
        color: #506c65;
      }
      .search-btn {
        border-radius: 19px;
      }
    }
    .material-icons {
      font-size: var(--font-base-font-size-18);
      color: #bdbdbd;
      cursor: pointer;
    }
    .ng-select-container {
      width: 207px;
      height: 32px;
      margin-top: 15px;
      margin-right: 10px;
      border-radius: 19px;
    }
    .icon {
      display: grid;
      height: 18px;
      width: 18px;
      margin-top: 17px;
      margin-right: 4px;
      .keyarrow {
        width: 9px;
        height: 9px;
      }
    }
  }
  .mat-elevation-z8 {
    box-shadow: none;
    width: 100%;
    margin: auto;
  }
  .example-contaner {
    font-size: var(--font-base-font-size-14);
    line-height: 20px;
    clear: both;
    .left-content {
      width: fit-content;
      padding-left: 1%;
      padding-right: 1%;
      height: 32px;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-base-font-size-14);
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.1px;
      float: left;
      margin: 0.4em 0.4em;
      background: #f5f5f5;
      border-radius: 20px;
      .material-icons {
        background: #bdbdbd;
        border-radius: 16px;
        flex: none;
        order: 1;
        align-self: center;
        margin: 8px 0px;
        font-size: var(--font-base-font-size-14);
        position: relative;
        top: 1px;
        cursor: pointer;
        color: #ffffff;
      }
    }
    .left-content.prodDetail {
      display: flex;
      padding-top: 5px;
      .material-icons {
        margin-top: 0px;
        margin-left: 5px;
      }
    }
    .clear-all {
      color: var(--color-base-teal-500);
      cursor: pointer;
      line-height: 63px;
      margin-left: 2em;
      font-size: var(--font-base-font-size-16);
      font-family: var(--font-base-font-family-body);
      bottom: 0.5em;
      position: relative;
    }
    .mat-table,
    .clearfix {
      clear: both;
      margin-bottom: 10em;
    }
    .mat-table.mt {
      clear: both;
    }
    .mat-header-cell {
      flex: none;
    }
    .mat-cell {
      flex: none;
      font-family: var(--font-base-font-family-body);
    }
    .tb-heading {
      font-weight: 600;
      color: var(--color-base-teal-950);
      font-size: var(--font-base-font-size-14);
    }
    .mat-header-row {
      border-bottom: 1px solid #ced7d4;
      .mat-column-Order {
        color: #334541;
      }
    }
    .mat-column-Order {
      width: 15%;
      font-family: var(--font-base-font-family-body);
      color: var(--color-base-teal-500);
    }
    .mat-column-Order-Status {
      width: 15%;
    }
    .mat-column-PO-Number {
      width: 15%;
    }
    .mat-column-Payment-Type {
      width: 15%;
    }
    .mat-column-PO-Date {
      width: 15%;
      .material-icons {
        position: relative;
        font-size: var(--font-base-font-size-18);
        color: #bdbdbd;
        cursor: pointer;
      }
      .material-icons:first-child {
        bottom: 3px;
      }
      .material-icons:last-child {
        top: 7px;
        right: 18px;
      }
    }
    .mat-column-Net-Price {
      width: 15%;
    }
    .mat-column-Order-Created {
      width: 15%;
      .material-icons {
        position: relative;
        font-size: var(--font-base-font-size-18);
        color: #bdbdbd;
        cursor: pointer;
      }
      .material-icons:first-child {
        bottom: 3px;
      }
      .material-icons:last-child {
        top: 7px;
        right: 18px;
      }
    }
    .mat-column-Last-Updated {
      width: 15%;
      .material-icons {
        position: relative;
        font-size: var(--font-base-font-size-18);
        color: #bdbdbd;
        cursor: pointer;
      }
      .material-icons:first-child {
        bottom: 3px;
      }
      .material-icons:last-child {
        top: 7px;
        right: 18px;
      }
    }
  }
  .noData {
    text-align: center;
    margin-bottom: 10em;
    .img {
      width: 152px;
      height: 122px;
      margin: auto;
    }
    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #1a2321;
    }
  }
  .ng-select {
    .ng-value {
      display: none;
    }
    .ng-clear-wrapper {
      display: none;
    }
  }
  .ng-select .ng-has-value .ng-placeholder {
    display: block;
  }
}

.order-content {
  float: right;
  margin-top: 27px;
}

.refresh {
  margin-right: 20px;
  display: inline-block;
  font-weight: 600;
  font-size: var(--font-base-font-size-12);
  line-height: 18px;
  align-items: center;
  text-align: right;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #415853;
  cursor: pointer;
  .material-icons {
    position: relative;
    top: 4px;
    margin-left: 24px;
  }
}

.icons {
  display: inline-block;
  background-repeat: no-repeat;
  &.download {
    background: url('../../../../assets/img/save_alt.svg');
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    cursor: pointer;
    margin-right: 7px;
    position: relative;
    top: 3px;
  }
}

.refreshIcon {
  vertical-align: middle;
  margin-left: 10px;
}

.currently-loading-spinner {
  margin-bottom: 10em;
}

.btn-txt {
  font-family: var(--font-base-font-family-decorative);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-base-font-size-12);
  line-height: 18px;
  align-items: center;
  text-align: right;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #415853;
  margin-left: 0.1em;
}

.icon-size {
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-base-font-size-18);
  line-height: 18px;
  align-items: center;
  text-align: right;
  text-transform: lowercase;
  color: #415853;
}

.date-ranger-picker-input {
  border-radius: 19px !important;
  width: 202px !important;
  padding: 6px 8px !important;
}

.date-ranger-picker-input {
  .date-range-text {
    position: absolute !important;
  }
}

:host ::ng-deep .ng-select.ng-select-opened .ng-select-container .ng-arrow {
  top: 0px;
}

::ng-deep .ng-dropdown-panel.ng-select-bottom {
  top: 405.667px !important;
  width: 207px !important;
  min-width: 207px !important;
}

.rightContent .ng-select.ng-select-opened.ng-select-top > .ng-select-container,
.rightContent
  .ng-select.ng-select-opened.ng-select-bottom
  > .ng-select-container {
  border-bottom-right-radius: 19px !important;
  border-bottom-left-radius: 19px !important;
  border-top-right-radius: 19px !important;
  border-top-left-radius: 19px !important;
}

:host
  ::ng-deep
  .rightContent
  .ng-select-container
  .ng-dropdown-panel.ng-select-bottom {
  top: inherit;
}

.rightContent {
  .ng-select {
    height: 54px;
  }
}

.orderrecieved,
.Received {
  color: #757575;
}

.orderinprogress,
.Processing {
  color: #d68e17;
}

.blocked,
.Blocked {
  color: #cf2333;
}

.shipped,
.Shipped {
  color: #4faab0;
}

.shippedinvoiced {
  color: var(--color-base-teal-900);
}

.code-hyperlink {
  text-decoration: underline;
  cursor: pointer;
}
