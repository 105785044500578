<div class="waygate-order-summary">
  <ng-container *ngIf="cartData?.appliedVouchers?.length > 0">
    <div class="order-summary-coupon-text">
      {{ 'buyCart.couponCode' | cxTranslate }}
    </div>
    <div class="applied-coupon-holder">
      <span class="applied-coupon-icon mr-2"></span>
      <div class="coupons-holder">
        <span class="applied-coupon">{{
          cartData.appliedVouchers[0].code
        }}</span>
        <span class="applied-coupon-text">{{
          'buyCart.couponApplied' | cxTranslate
        }}</span>
      </div>
    </div>
    <hr class="horizontal-line" />
  </ng-container>

  <!-- <div class="available-coupon">View available coupons</div> -->

  <div class="order-summary-heading">
    {{ 'titles.orderSummary' | cxTranslate }}
  </div>
  <div class="order-summary-content">
    <div class="order-summary-sub-content">
      <div class="order-summary-label">
        {{ 'titles.subTotal' | cxTranslate }}
      </div>
      <div class="order-summary-value">
        {{ cartData?.totalListPrice?.formattedValue }}
      </div>
    </div>
    <!-- <div class="order-summary-sub-content">
      <div class="surcharge-label">
        Surcharge <i class="material-icons">info</i>
      </div>
      <div class="order-summary-value">$0.00</div>
    </div> -->
    <div class="order-summary-sub-content">
      <div class="order-summary-label">
        {{ 'titles.discount' | cxTranslate }}
      </div>
      <div class="order-summary-value">
        {{ cartData?.yourPriceDiscount?.formattedValue }}
      </div>
    </div>
    <!-- <div class="order-summary-sub-content">
      <div class="order-summary-label">NEWPROD2023</div>
      <div class="order-summary-value">- $550.00</div>
    </div> -->
    <div
      class="order-summary-sub-content"
      *ngIf="cartData?.totalDiscounts?.value > 0"
    >
      <div class="order-summary-label">
        {{ cartData?.appliedCouponCodes[0] }}
      </div>
      <div class="order-summary-value">
        {{ cartData?.totalDiscounts?.formattedValue }}
      </div>
    </div>
  </div>

  <hr class="horizontal-line" />
  <div class="order-summary-total">
    <div class="total-label">{{ 'titles.total' | cxTranslate }}</div>
    <div class="total-value">{{ cartData?.totalPrice?.formattedValue }}</div>
  </div>
  <hr class="horizontal-line" />
  <div class="order-summary-terms mt-2">
    <bh-checkbox
      class="float-left terms-checkbox"
      label=""
      [value]="agreeTerms"
      [attr.error]="error.agreeTerms ? error.agreeTerms : null"
      (change)="onChange(agreeTerms, 'agreeTerms')"
    >
    </bh-checkbox>
    <div>
      <label class="terms-checkbox-label"
        >{{ 'titles.iagreeto' | cxTranslate }}
        <a
          href="https://www.bakerhughesds.com/sales-terms-conditions"
          target="_blank"
        >
          {{ 'titles.termsConditions' | cxTranslate }}
        </a></label
      >
    </div>
  </div>

  <div class="order-summary-actions">
    <bh-button
      *ngIf="!makePaymrnt"
      [attr.disabled]="!agreeTermschecked"
      class="float-left place-order-btn"
      medium
      type="primary"
      label="Place order"
      (click)="placeOrder()"
    ></bh-button>
    <bh-button
      *ngIf="makePaymrnt"
      [attr.disabled]="!agreeTermschecked"
      class="float-left place-order-btn"
      medium
      type="primary"
      label="{{ 'titles.makePayment' | cxTranslate }}"
      (click)="placeOrder()"
    ></bh-button>
    <bh-button
      class="float-right"
      medium
      type="secondary"
      label="Return to cart"
      (click)="goToCart()"
    >
    </bh-button>
  </div>
  <div class="order-summary-actions">
    <div *ngIf="noCartEntriesData" class="availibility-error">
      <i class="material-icons">report</i>
      <span class="error-text">
        A technical error has occurred. Kindly contact the support team at
        'ogmchybris.support&#64;bakerhughes.com' for assistance.
      </span>
    </div>
  </div>
</div>
