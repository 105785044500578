var epdVisualization$w = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Domů"
      },
      zoomButton: {
        label: "Lupa"
      },
      panButton: {
        label: "Posunout"
      },
      rotateButton: {
        label: "Otočit"
      },
      isolateButton: {
        label: "Izolovat"
      },
      playButton: {
        label: "Přehrát"
      },
      pauseButton: {
        label: "Pozastavit"
      },
      hotspotsButton: {
        label: "Hotspoty",
        show: "Zobrazit",
        hide: "Skrýt"
      },
      visualViewerAnimationSlider: {
        label: "Slider s animací",
        role: "Slider"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "Pro tento produkt nejsou k dispozici žádné náhradní díly."
    },
    visualPickingProductList: {
      description: "Popis",
      itemPrice: "Cena",
      outOfStock: "Není skladem",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Filtrovat podle názvu nebo ID"
      },
      searchButton: {
        label: "hledat"
      },
      resetButton: {
        label: "reset"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "Nebyly nalezeny žádné vyhovující vizualizace",
      multipleMatchingVisualsFound: "Bylo nalezeno několik vyhovujících vizualizací",
      unexpectedLoadError: "Došlo k chybě při načítání vizualizací"
    }
  }
};
var epdVisualization$x = {
  epdVisualization: epdVisualization$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  epdVisualization: epdVisualization$x
};
var epdVisualization$u = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Startseite"
      },
      zoomButton: {
        label: "Zoom"
      },
      panButton: {
        label: "Verschieben"
      },
      rotateButton: {
        label: "Drehen"
      },
      isolateButton: {
        label: "Isolieren"
      },
      playButton: {
        label: "Wiedergabe"
      },
      pauseButton: {
        label: "Pause"
      },
      hotspotsButton: {
        label: "Hotspots",
        show: "Anzeigen",
        hide: "Ausblenden"
      },
      visualViewerAnimationSlider: {
        label: "Animierungsschieberegler",
        role: "Schieberegler"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "Für dieses Produkt sind keine Ersatzteile verfügbar."
    },
    visualPickingProductList: {
      description: "Beschreibung",
      itemPrice: "Preis",
      outOfStock: "Nicht vorrätig",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Nach Name oder ID filtern"
      },
      searchButton: {
        label: "suchen"
      },
      resetButton: {
        label: "zurücksetzen"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "Keine passende Visualisierung gefunden",
      multipleMatchingVisualsFound: "Mehrere passende Visualisierungen gefunden",
      unexpectedLoadError: "Beim Laden der Visualisierung ist ein Fehler aufgetreten"
    }
  }
};
var epdVisualization$v = {
  epdVisualization: epdVisualization$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  epdVisualization: epdVisualization$v
};
var epdVisualization$s = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Home"
      },
      zoomButton: {
        label: "Zoom"
      },
      panButton: {
        label: "Pan"
      },
      rotateButton: {
        label: "Rotate"
      },
      isolateButton: {
        label: "Isolate"
      },
      playButton: {
        label: "Play"
      },
      pauseButton: {
        label: "Pause"
      },
      hotspotsButton: {
        label: "Hotspots",
        show: "Show",
        hide: "Hide"
      },
      visualViewerAnimationSlider: {
        label: "Animation Slider",
        role: "Slider"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "No spare parts are available for this product."
    },
    visualPickingProductList: {
      description: "Description",
      itemPrice: "Price",
      outOfStock: "Out of stock",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Filter by name or id"
      },
      searchButton: {
        label: "search"
      },
      resetButton: {
        label: "reset"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "No matching visualization found",
      multipleMatchingVisualsFound: "Multiple matching visualizations found",
      unexpectedLoadError: "Error occurred while loading visualization"
    }
  }
};
var epdVisualization$t = {
  epdVisualization: epdVisualization$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  epdVisualization: epdVisualization$t
};
var epdVisualization$q = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Inicio"
      },
      zoomButton: {
        label: "Zoom"
      },
      panButton: {
        label: "Efecto panorámico"
      },
      rotateButton: {
        label: "Rotar"
      },
      isolateButton: {
        label: "Aislar"
      },
      playButton: {
        label: "Reproducir"
      },
      pauseButton: {
        label: "Pausa"
      },
      hotspotsButton: {
        label: "Zonas reactivas",
        show: "Mostrar",
        hide: "Ocultar"
      },
      visualViewerAnimationSlider: {
        label: "Barra deslizante de animación",
        role: "Barra deslizante"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "No hay piezas de repuesto disponibles para este producto."
    },
    visualPickingProductList: {
      description: "Descripción",
      itemPrice: "Precio",
      outOfStock: "Fuera de stock",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Filtrar por nombre o ID"
      },
      searchButton: {
        label: "buscar"
      },
      resetButton: {
        label: "restablecer"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "No existe visualización coincidente",
      multipleMatchingVisualsFound: "Existen varias visualizaciones coincidentes",
      unexpectedLoadError: "Se produjo un error al cargar la visualización"
    }
  }
};
var epdVisualization$r = {
  epdVisualization: epdVisualization$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  epdVisualization: epdVisualization$r
};
var epdVisualization$o = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Inicio"
      },
      zoomButton: {
        label: "Zoom"
      },
      panButton: {
        label: "Efecto panorámico"
      },
      rotateButton: {
        label: "Rotar"
      },
      isolateButton: {
        label: "Aislar"
      },
      playButton: {
        label: "Reproducir"
      },
      pauseButton: {
        label: "Pausa"
      },
      hotspotsButton: {
        label: "Zonas reactivas",
        show: "Mostrar",
        hide: "Ocultar"
      },
      visualViewerAnimationSlider: {
        label: "Barra deslizante de animación",
        role: "Barra deslizante"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "No hay piezas de repuesto disponibles para este producto."
    },
    visualPickingProductList: {
      description: "Descripción",
      itemPrice: "Precio",
      outOfStock: "Fuera de stock",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Filtrar por nombre o ID"
      },
      searchButton: {
        label: "buscar"
      },
      resetButton: {
        label: "restablecer"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "No existe visualización coincidente",
      multipleMatchingVisualsFound: "Existen varias visualizaciones coincidentes",
      unexpectedLoadError: "Se produjo un error al cargar la visualización"
    }
  }
};
var epdVisualization$p = {
  epdVisualization: epdVisualization$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  epdVisualization: epdVisualization$p
};
var epdVisualization$m = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Accueil"
      },
      zoomButton: {
        label: "Zoom"
      },
      panButton: {
        label: "Panoramique"
      },
      rotateButton: {
        label: "Pivoter"
      },
      isolateButton: {
        label: "Isoler"
      },
      playButton: {
        label: "Lecture"
      },
      pauseButton: {
        label: "Pause"
      },
      hotspotsButton: {
        label: "Zones réactives",
        show: "Afficher",
        hide: "Masquer"
      },
      visualViewerAnimationSlider: {
        label: "Diaporama d'animation",
        role: "Diaporama"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "Aucune pièce de rechange n'est disponible pour ce produit."
    },
    visualPickingProductList: {
      description: "Description",
      itemPrice: "Prix",
      outOfStock: "Épuisé",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Filtrer par nom ou par ID"
      },
      searchButton: {
        label: "Rechercher"
      },
      resetButton: {
        label: "Réinitialiser"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "Aucun visualisation correspondante trouvée",
      multipleMatchingVisualsFound: "Plusieurs visualisations correspondantes trouvées",
      unexpectedLoadError: "Erreur lors du chargement de la visualisation"
    }
  }
};
var epdVisualization$n = {
  epdVisualization: epdVisualization$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  epdVisualization: epdVisualization$n
};
var epdVisualization$k = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "होम"
      },
      zoomButton: {
        label: "ज़ूम करें"
      },
      panButton: {
        label: "पैन"
      },
      rotateButton: {
        label: "घुमाएं"
      },
      isolateButton: {
        label: "आइसोलेट"
      },
      playButton: {
        label: "प्ले करें"
      },
      pauseButton: {
        label: "विराम"
      },
      hotspotsButton: {
        label: "हॉटस्पॉट",
        show: "दिखाएं",
        hide: "छिपाएं"
      },
      visualViewerAnimationSlider: {
        label: "एनिमेशन स्लाइडर",
        role: "स्लाइडर"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "इस उत्पाद के लिए कोई स्पेयर पार्ट उपलब्ध नहीं हैं."
    },
    visualPickingProductList: {
      description: "वर्णन",
      itemPrice: "मूल्य",
      outOfStock: "स्टॉक समाप्त",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "नाम या  id के अनुसार फ़िल्टर करें"
      },
      searchButton: {
        label: "खोजें"
      },
      resetButton: {
        label: "रीसेट करें"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "कोई मेलखाते दृश्य नहीं मिले",
      multipleMatchingVisualsFound: "एक से अधिक मेल खाते दृश्य मिले",
      unexpectedLoadError: "दृश्य लोड करते समय त्रुटि उत्पन्न हुई"
    }
  }
};
var epdVisualization$l = {
  epdVisualization: epdVisualization$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  epdVisualization: epdVisualization$l
};
var epdVisualization$i = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Kezdőlap"
      },
      zoomButton: {
        label: "Nagyítás"
      },
      panButton: {
        label: "Pásztázás"
      },
      rotateButton: {
        label: "Forgatás"
      },
      isolateButton: {
        label: "Izolálás"
      },
      playButton: {
        label: "Lejátszás"
      },
      pauseButton: {
        label: "Szünet"
      },
      hotspotsButton: {
        label: "Gócpontok",
        show: "Megjelenítés",
        hide: "Elrejtés"
      },
      visualViewerAnimationSlider: {
        label: "Animációs csúszka",
        role: "Csúszka"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "Nem találhatók pótalkatrészek ehhez a termékhez."
    },
    visualPickingProductList: {
      description: "Leírás",
      itemPrice: "Ár",
      outOfStock: "Nincs készleten",
      id: "Azonosító"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Szűrés név vagy azonosító szerint"
      },
      searchButton: {
        label: "keresés"
      },
      resetButton: {
        label: "alaphelyzet"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "Nem található egyező vizualizáció",
      multipleMatchingVisualsFound: "Több egyező vizualizáció található",
      unexpectedLoadError: "Hiba történt a vizualizáció betöltésekor"
    }
  }
};
var epdVisualization$j = {
  epdVisualization: epdVisualization$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  epdVisualization: epdVisualization$j
};
var epdVisualization$g = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Beranda"
      },
      zoomButton: {
        label: "Zum"
      },
      panButton: {
        label: "Geser"
      },
      rotateButton: {
        label: "Putar"
      },
      isolateButton: {
        label: "Sorot"
      },
      playButton: {
        label: "Putar"
      },
      pauseButton: {
        label: "Jeda"
      },
      hotspotsButton: {
        label: "Titik Akses",
        show: "Tampilkan",
        hide: "Sembunyikan"
      },
      visualViewerAnimationSlider: {
        label: "Penggeser Animasi",
        role: "Penggeser"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "Tidak ada suku cadang tersedia untuk produk ini."
    },
    visualPickingProductList: {
      description: "Deskripsi",
      itemPrice: "Harga",
      outOfStock: "Stok habis",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Filter berdasarkan nama atau id"
      },
      searchButton: {
        label: "cari"
      },
      resetButton: {
        label: "atur ulang"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "Tidak ditemukan visualisasi yang cocok",
      multipleMatchingVisualsFound: "Ditemukan beberapa visualisasi yang cocok",
      unexpectedLoadError: "Terjadi kesalahan saat memuat visualisasi"
    }
  }
};
var epdVisualization$h = {
  epdVisualization: epdVisualization$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  epdVisualization: epdVisualization$h
};
var epdVisualization$e = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Pagina iniziale"
      },
      zoomButton: {
        label: "Zoom"
      },
      panButton: {
        label: "Panoramica"
      },
      rotateButton: {
        label: "Ruota"
      },
      isolateButton: {
        label: "Isola"
      },
      playButton: {
        label: "Riproduci"
      },
      pauseButton: {
        label: "Pausa"
      },
      hotspotsButton: {
        label: "Hotspots",
        show: "Mostra",
        hide: "Nascondi"
      },
      visualViewerAnimationSlider: {
        label: "Cursore di animazione",
        role: "Cursore"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "Nessuna parte di ricambio disponibile per questo prodotto."
    },
    visualPickingProductList: {
      description: "Descrizione",
      itemPrice: "Prezzo",
      outOfStock: "Non disponibile",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Filtra per nome o ID"
      },
      searchButton: {
        label: "cerca"
      },
      resetButton: {
        label: "reimposta"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "Nessuna visualizzazione corrispondente trovata",
      multipleMatchingVisualsFound: "Trovate più visualizzazioni corrispondenti",
      unexpectedLoadError: "Errore durante il caricamento della visualizzazione"
    }
  }
};
var epdVisualization$f = {
  epdVisualization: epdVisualization$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  epdVisualization: epdVisualization$f
};
var epdVisualization$c = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "ホーム"
      },
      zoomButton: {
        label: "ズーム"
      },
      panButton: {
        label: "パン"
      },
      rotateButton: {
        label: "回転"
      },
      isolateButton: {
        label: "分離"
      },
      playButton: {
        label: "再生"
      },
      pauseButton: {
        label: "一時停止"
      },
      hotspotsButton: {
        label: "ホットスポット",
        show: "表示",
        hide: "非表示"
      },
      visualViewerAnimationSlider: {
        label: "アニメーションスライダ",
        role: "スライダ"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "この商品にはスペアパーツがありません。"
    },
    visualPickingProductList: {
      description: "説明",
      itemPrice: "価格",
      outOfStock: "在庫なし",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "名前または ID でフィルタ"
      },
      searchButton: {
        label: "検索"
      },
      resetButton: {
        label: "リセット"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "一致するビジュアライゼーションが見つかりません",
      multipleMatchingVisualsFound: "一致するビジュアライゼーションが複数見つかりました",
      unexpectedLoadError: "ビジュアライゼーションのロード中にエラーが発生しました"
    }
  }
};
var epdVisualization$d = {
  epdVisualization: epdVisualization$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  epdVisualization: epdVisualization$d
};
var epdVisualization$a = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "홈"
      },
      zoomButton: {
        label: "확대/축소"
      },
      panButton: {
        label: "이동"
      },
      rotateButton: {
        label: "회전"
      },
      isolateButton: {
        label: "분리"
      },
      playButton: {
        label: "재생"
      },
      pauseButton: {
        label: "일시 중지"
      },
      hotspotsButton: {
        label: "핫스폿",
        show: "표시",
        hide: "숨기기"
      },
      visualViewerAnimationSlider: {
        label: "애니메이션 슬라이더",
        role: "슬라이더"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "이 제품의 예비 부품이 없습니다."
    },
    visualPickingProductList: {
      description: "설명",
      itemPrice: "가격",
      outOfStock: "재고 없음",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "이름 또는 ID별 필터"
      },
      searchButton: {
        label: "검색"
      },
      resetButton: {
        label: "재설정"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "일치하는 시각화가 없습니다.",
      multipleMatchingVisualsFound: "일치하는 시각화가 여러 개 있습니다.",
      unexpectedLoadError: "시각화를 로드하는 동안 오류가 발생했습니다."
    }
  }
};
var epdVisualization$b = {
  epdVisualization: epdVisualization$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  epdVisualization: epdVisualization$b
};
var epdVisualization$8 = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Strona główna"
      },
      zoomButton: {
        label: "Skaluj"
      },
      panButton: {
        label: "Przesuń"
      },
      rotateButton: {
        label: "Obróć"
      },
      isolateButton: {
        label: "Wyizoluj"
      },
      playButton: {
        label: "Odtwórz"
      },
      pauseButton: {
        label: "Wstrzymaj"
      },
      hotspotsButton: {
        label: "Obszary aktywne",
        show: "Pokaż",
        hide: "Ukryj"
      },
      visualViewerAnimationSlider: {
        label: "Suwak animacji",
        role: "Suwak"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "Dla tego produktu nie są dostępne części zamienne."
    },
    visualPickingProductList: {
      description: "Opis",
      itemPrice: "Cena",
      outOfStock: "Zapas wyczerpany",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Filtruj według nazwy lub ID"
      },
      searchButton: {
        label: "szukaj"
      },
      resetButton: {
        label: "resetuj"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "Nie znaleziono pasującej wizualizacji",
      multipleMatchingVisualsFound: "Znaleziono wiele pasujących wizualizacji",
      unexpectedLoadError: "Wystąpił błąd podczas ładowania wizualizacji"
    }
  }
};
var epdVisualization$9 = {
  epdVisualization: epdVisualization$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  epdVisualization: epdVisualization$9
};
var epdVisualization$6 = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Página inicial"
      },
      zoomButton: {
        label: "Zoom"
      },
      panButton: {
        label: "Deslocar"
      },
      rotateButton: {
        label: "Girar"
      },
      isolateButton: {
        label: "Isolar"
      },
      playButton: {
        label: "Reproduzir"
      },
      pauseButton: {
        label: "Pausar"
      },
      hotspotsButton: {
        label: "Pontos ativos",
        show: "Mostrar",
        hide: "Ocultar"
      },
      visualViewerAnimationSlider: {
        label: "Controle deslizante de animação",
        role: "Controle deslizante"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "Nenhuma peça de reposição disponível para este produto."
    },
    visualPickingProductList: {
      description: "Descrição",
      itemPrice: "Preço",
      outOfStock: "Sem estoque",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Filtrar por nome ou ID"
      },
      searchButton: {
        label: "procurar"
      },
      resetButton: {
        label: "reinicializar"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "Nenhuma visualização correspondente encontrada",
      multipleMatchingVisualsFound: "Várias visualizações correspondentes encontradas",
      unexpectedLoadError: "Erro ocorrido ao carregar visualização"
    }
  }
};
var epdVisualization$7 = {
  epdVisualization: epdVisualization$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  epdVisualization: epdVisualization$7
};
var epdVisualization$4 = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "Домашняя страница"
      },
      zoomButton: {
        label: "Масштаб"
      },
      panButton: {
        label: "Панорама"
      },
      rotateButton: {
        label: "Повернуть"
      },
      isolateButton: {
        label: "Изолировать"
      },
      playButton: {
        label: "Воспроизвести"
      },
      pauseButton: {
        label: "Пауза"
      },
      hotspotsButton: {
        label: "Горячие точки",
        show: "Показать",
        hide: "Скрыть"
      },
      visualViewerAnimationSlider: {
        label: "Анимационный слайдер",
        role: "Слайдер"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "Для этого продукта запасные части отсутствуют."
    },
    visualPickingProductList: {
      description: "Описание",
      itemPrice: "Цена",
      outOfStock: "Нет в наличии",
      id: "Ид."
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "Фильтр по имени или ид."
      },
      searchButton: {
        label: "Поиск"
      },
      resetButton: {
        label: "Сбросить"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "Соответствующая визуализация не найдена",
      multipleMatchingVisualsFound: "Найдено несколько соответствующих визуализаций",
      unexpectedLoadError: "При загрузке визуализации произошла ошибка"
    }
  }
};
var epdVisualization$5 = {
  epdVisualization: epdVisualization$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  epdVisualization: epdVisualization$5
};
var epdVisualization$2 = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "主页"
      },
      zoomButton: {
        label: "缩放"
      },
      panButton: {
        label: "平移"
      },
      rotateButton: {
        label: "旋转"
      },
      isolateButton: {
        label: "隔离"
      },
      playButton: {
        label: "播放"
      },
      pauseButton: {
        label: "暂停"
      },
      hotspotsButton: {
        label: "热点",
        show: "显示",
        hide: "隐藏"
      },
      visualViewerAnimationSlider: {
        label: "动画滑动条",
        role: "滑动条"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "没有可用于此产品的备件。"
    },
    visualPickingProductList: {
      description: "描述",
      itemPrice: "价格",
      outOfStock: "缺货",
      id: "标识"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "按名称或标识过滤"
      },
      searchButton: {
        label: "搜索"
      },
      resetButton: {
        label: "重置"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "找不到匹配的可视化",
      multipleMatchingVisualsFound: "找到多个匹配的可视化",
      unexpectedLoadError: "加载可视化时出错"
    }
  }
};
var epdVisualization$3 = {
  epdVisualization: epdVisualization$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  epdVisualization: epdVisualization$3
};
var epdVisualization = {
  visualViewer: {
    contentType: {
      drawing2D: "2D",
      model3D: "3D"
    },
    toolbar: {
      homeButton: {
        label: "首頁"
      },
      zoomButton: {
        label: "縮放"
      },
      panButton: {
        label: "平移"
      },
      rotateButton: {
        label: "旋轉"
      },
      isolateButton: {
        label: "隔離"
      },
      playButton: {
        label: "播放"
      },
      pauseButton: {
        label: "暫停"
      },
      hotspotsButton: {
        label: "熱點",
        show: "顯示",
        hide: "隱藏"
      },
      visualViewerAnimationSlider: {
        label: "動畫幻燈片",
        role: "幻燈片"
      }
    }
  },
  visualPicking: {
    visualPickingTab: {
      noProductReferences: "此產品沒有備用零件。"
    },
    visualPickingProductList: {
      description: "說明",
      itemPrice: "價格",
      outOfStock: "缺貨",
      id: "ID"
    },
    visualPickingProductFilter: {
      input: {
        placeholder: "按名稱或 ID 篩選"
      },
      searchButton: {
        label: "搜尋"
      },
      resetButton: {
        label: "重設"
      }
    }
  },
  errors: {
    visualLoad: {
      noMatchingVisualFound: "找不到符合的視覺化",
      multipleMatchingVisualsFound: "找不到多個符合的視覺化",
      unexpectedLoadError: "載入視覺化時發生錯誤"
    }
  }
};
var epdVisualization$1 = {
  epdVisualization: epdVisualization
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  epdVisualization: epdVisualization$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const epdVisualizationTranslationChunksConfig = {
  epdVisualization: ['epdVisualization']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: epdVisualizationTranslations
 *             +   resources: { en: epdVisualizationTranslationsEn }
 *               }
 *             ```
 */
const epdVisualizationTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { epdVisualizationTranslationChunksConfig, epdVisualizationTranslations, cs as epdVisualizationTranslationsCs, de as epdVisualizationTranslationsDe, en as epdVisualizationTranslationsEn, es as epdVisualizationTranslationsEs, es_CO as epdVisualizationTranslationsEs_CO, fr as epdVisualizationTranslationsFr, hi as epdVisualizationTranslationsHi, hu as epdVisualizationTranslationsHu, id as epdVisualizationTranslationsId, it as epdVisualizationTranslationsIt, ja as epdVisualizationTranslationsJa, ko as epdVisualizationTranslationsKo, pl as epdVisualizationTranslationsPl, pt as epdVisualizationTranslationsPt, ru as epdVisualizationTranslationsRu, zh as epdVisualizationTranslationsZh, zh_TW as epdVisualizationTranslationsZh_TW };
