<ng-container *ngIf="iscontentAvailableFromSlot">
  <div class="container">
    <div class="slideshow-container" *ngIf="slidesArr?.length > 0">
      <ng-container *ngFor="let item of slidesArr; let i = index">
        <div
          class="slider-wrapper fadeeffect"
          [ngStyle]="{ display: slideIndex === i ? 'block' : 'none' }"
        >
          <div class="each-slide">
            <div class="text-wrapper">
              <div class="title" [innerHTML]="item?.headline"></div>
              <div class="desc" [innerHTML]="item?.content"></div>
              <div class="more-details-btn">
                <button
                  class="save-btn action-btn"
                  type="button"
                  *ngIf="isInternalLink(item?.urlLink); else externalLinkButton"
                  [routerLink]="item?.urlLink"
                >
                  {{ 'waygate.moreDetails' | cxTranslate }}
                </button>
                <ng-template #externalLinkButton>
                  <button
                    class="save-btn action-btn"
                    type="button"
                    (click)="handleExternalUrl(item?.urlLink)"
                  >
                    {{ 'waygate.moreDetails' | cxTranslate }}
                  </button>
                </ng-template>
              </div>
            </div>
            <div class="img-wrapper">
              <img src="{{ baseSiteURL }}{{ item.media?.url }}" alt="imgage" />
            </div>
          </div>
        </div>
      </ng-container>
      <div
        class="carousel-left material-icons"
        (click)="slideIndex === 0 ? '' : plusSlides(-1)"
        [ngClass]="{ disabled: slideIndex === 0 }"
      >
        navigate_before
      </div>
      <div
        class="carousel-right material-icons"
        (click)="slideIndex === slidesArr.length - 1 ? '' : plusSlides(1)"
        [ngClass]="{ disabled: slideIndex === slidesArr.length - 1 }"
      >
        navigate_next
      </div>
    </div>
  </div>
</ng-container>
