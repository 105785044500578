var unitLevelOrderHistory$g = {
  orderHistory: "Historie objednávek na úrovni jednotky",
  orderId: "Číslo objednávky",
  emptyPurchaseOrderId: "Nic",
  date: "Datum",
  status: "Status",
  buyer: "Nákupčí",
  unit: "Jednotka",
  total: "Celkem",
  noOrders: "Pro vaši jednotku nemáme žádné odpovídající záznamy objednávek.",
  startShopping: "Začít nakupovat",
  sortBy: "Řadit podle",
  sortOrders: "Seřadit objednávky",
  purchaseOrderNumber: "Č. objednávky",
  startOn: "Zahájit dne",
  frequency: "Četnost",
  nextOrderDate: "Datum další objednávky",
  notFound: "Nebyly nalezeny žádné objednávky",
  applyFilter: "Použít",
  filterBy: "Filtrovat podle",
  filterByBuyer: " Filtrovat podle nákupčího",
  filterByBuyerLabel: "Jméno nákupčího",
  filterByBuyerPlaceholder: "Zadejte jméno nákupčího",
  filterByBuyerAriaLabel: " Filtrovat seznam podle nákupčího",
  filterByUnit: "Filtrovat podle jednotky",
  filterByUnitLabel: "Název jednotky",
  filterByUnitPlaceholder: "Zadejte název jednotky",
  filterByUnitAriaLabel: "Filtrovat seznam podle jednotky",
  search: "Hledat",
  clearAll: "Vymazat vše",
  removeAppliedFilters: "Odebrat filtry",
  yourFilters: "Vaše filtry:",
  and: " a "
};
var unitLevelOrderHistorySorting$g = {
  date: "Datum",
  orderNumber: "Číslo objednávky",
  buyer: "Nákupčí (vzestupně)",
  buyerDesc: "Nákupčí (sestupně)",
  orgUnit: "Jednotka (vzestupně)",
  orgUnitDesc: "Jednotka (sestupně)"
};
var unitLevelOrderDetails$g = {
  buyer: "Nákupčí"
};
var unitOrder$g = {
  unitLevelOrderHistory: unitLevelOrderHistory$g,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$g,
  unitLevelOrderDetails: unitLevelOrderDetails$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  unitOrder: unitOrder$g
};
var unitLevelOrderHistory$f = {
  orderHistory: "Bestellverlauf auf Einheitsebene",
  orderId: "Bestellnr.",
  emptyPurchaseOrderId: "Keine",
  date: "Datum",
  status: "Status",
  buyer: "Käufer",
  unit: "Einheit",
  total: "Gesamtsumme",
  noOrders: "Für Ihre Einheit gibt es keine passenden Bestellungsdatensätze.",
  startShopping: "Einkauf beginnen",
  sortBy: "Sortieren nach",
  sortOrders: "Bestellungen sortieren",
  purchaseOrderNumber: "Bestellnr.",
  startOn: "Start am",
  frequency: "Häufigkeit",
  nextOrderDate: "Nächstes Bestelldatum",
  notFound: "Keine Bestellungen gefunden",
  applyFilter: "Anwenden",
  filterBy: "Filtern nach",
  filterByBuyer: "Nach Käufer filtern",
  filterByBuyerLabel: "Käufername",
  filterByBuyerPlaceholder: "Käufername eingeben",
  filterByBuyerAriaLabel: "Liste nach Käufer filtern",
  filterByUnit: "Nach Einheit filtern",
  filterByUnitLabel: "Name der Einheit",
  filterByUnitPlaceholder: "Name der Einheit eingeben",
  filterByUnitAriaLabel: "Liste nach Einheit filtern",
  search: "Suchen",
  clearAll: "Alle zurücksetzen",
  removeAppliedFilters: "Filter entfernen",
  yourFilters: "Ihre Filter:",
  and: " und "
};
var unitLevelOrderHistorySorting$f = {
  date: "Datum",
  orderNumber: "Bestellnummer",
  buyer: "Käufer (aufsteigend)",
  buyerDesc: "Käufer (absteigend)",
  orgUnit: "Einheit (aufsteigend)",
  orgUnitDesc: "Einheit (absteigend)"
};
var unitLevelOrderDetails$f = {
  buyer: "Käufer"
};
var unitOrder$f = {
  unitLevelOrderHistory: unitLevelOrderHistory$f,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$f,
  unitLevelOrderDetails: unitLevelOrderDetails$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  unitOrder: unitOrder$f
};
var unitLevelOrderHistory$e = {
  orderHistory: "Unit-level order history",
  orderId: "Order #",
  emptyPurchaseOrderId: "None",
  date: "Date",
  status: "Status",
  buyer: "Buyer",
  unit: "Unit",
  total: "Total",
  noOrders: "We have no matching order records for your unit.",
  startShopping: "Start Shopping",
  sortBy: "Sort by",
  sortOrders: "Sort orders",
  purchaseOrderNumber: "PO #",
  startOn: "Start On",
  frequency: "Frequency",
  nextOrderDate: "Next Order Date",
  notFound: "No Orders Found",
  applyFilter: "Apply",
  filterBy: "Filter by",
  filterByBuyer: "Filter by Buyer",
  filterByBuyerLabel: "Buyer name",
  filterByBuyerPlaceholder: "Enter buyer name",
  filterByBuyerAriaLabel: "Filter list by buyer",
  filterByUnit: "Filter by Unit",
  filterByUnitLabel: "Unit name",
  filterByUnitPlaceholder: "Enter unit name",
  filterByUnitAriaLabel: "Filter list by unit",
  search: "Search",
  clearAll: "Clear All",
  removeAppliedFilters: "Remove filters",
  yourFilters: "Your Filters:",
  and: " and "
};
var unitLevelOrderHistorySorting$e = {
  date: "Date",
  orderNumber: "Order Number",
  buyer: "Buyer (Ascending)",
  buyerDesc: "Buyer (Descending)",
  orgUnit: "Unit (Ascending)",
  orgUnitDesc: "Unit (Descending)"
};
var unitLevelOrderDetails$e = {
  buyer: "Buyer"
};
var unitOrder$e = {
  unitLevelOrderHistory: unitLevelOrderHistory$e,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$e,
  unitLevelOrderDetails: unitLevelOrderDetails$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  unitOrder: unitOrder$e
};
var unitLevelOrderHistory$d = {
  orderHistory: "Historial de pedidos a nivel de unidad",
  orderId: "Número de pedido",
  emptyPurchaseOrderId: "Ninguno",
  date: "Fecha",
  status: "Estado",
  buyer: "Comprador",
  unit: "Unidad",
  total: "Total",
  noOrders: "No tenemos registros de pedidos coincidentes para su unidad.",
  startShopping: "Comenzar compra",
  sortBy: "Ordenar por",
  sortOrders: "Órdenes de clasificación",
  purchaseOrderNumber: "Número de pedido",
  startOn: "Fecha de inicio",
  frequency: "Frecuencia",
  nextOrderDate: "Fecha del próximo pedido",
  notFound: "No existen pedidos",
  applyFilter: "Aplicar",
  filterBy: "Filtrar por",
  filterByBuyer: "Filtrar por comprador",
  filterByBuyerLabel: "Nombre del comprador",
  filterByBuyerPlaceholder: "Introduzca nombre del comprador",
  filterByBuyerAriaLabel: "Filtrar lista por comprador",
  filterByUnit: "Filtrar por unidad",
  filterByUnitLabel: "Nombre de unidad",
  filterByUnitPlaceholder: "Introduzca nombre de unidad",
  filterByUnitAriaLabel: "Filtrar lista por unidad",
  search: "Buscar",
  clearAll: "Borrar todo",
  removeAppliedFilters: "Eliminar filtros",
  yourFilters: "Sus filtros:",
  and: " y "
};
var unitLevelOrderHistorySorting$d = {
  date: "Fecha",
  orderNumber: "Número de pedido",
  buyer: "Comprador (ascendente)",
  buyerDesc: "Comprador (descendente)",
  orgUnit: "Unidad (ascendente)",
  orgUnitDesc: "Unidad (descendente)"
};
var unitLevelOrderDetails$d = {
  buyer: "Comprador"
};
var unitOrder$d = {
  unitLevelOrderHistory: unitLevelOrderHistory$d,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$d,
  unitLevelOrderDetails: unitLevelOrderDetails$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  unitOrder: unitOrder$d
};
var unitLevelOrderHistory$c = {
  orderHistory: "Historial de pedidos a nivel de unidad",
  orderId: "Número de pedido",
  emptyPurchaseOrderId: "Ninguno",
  date: "Fecha",
  status: "Estado",
  buyer: "Comprador",
  unit: "Unidad",
  total: "Total",
  noOrders: "No tenemos registros de pedidos coincidentes para su unidad.",
  startShopping: "Comenzar compra",
  sortBy: "Clasificar por",
  sortOrders: "Órdenes de clasificación",
  purchaseOrderNumber: "Número de pedido",
  startOn: "Fecha de inicio",
  frequency: "Frecuencia",
  nextOrderDate: "Próxima fecha de pedido",
  notFound: "No existen pedidos",
  applyFilter: "Aplicar",
  filterBy: "Filtrar por",
  filterByBuyer: "Filtrar por comprador",
  filterByBuyerLabel: "Nombre del comprador",
  filterByBuyerPlaceholder: "Ingrese nombre del comprador",
  filterByBuyerAriaLabel: "Filtrar lista por comprador",
  filterByUnit: "Filtrar por unidad",
  filterByUnitLabel: "Nombre de unidad",
  filterByUnitPlaceholder: "Ingrese nombre de unidad",
  filterByUnitAriaLabel: "Filtrar lista por unidad",
  search: "Buscar",
  clearAll: "Borrar todo",
  removeAppliedFilters: "Eliminar filtros",
  yourFilters: "Sus filtros:",
  and: " y "
};
var unitLevelOrderHistorySorting$c = {
  date: "Fecha",
  orderNumber: "Número de pedido",
  buyer: "Comprador (ascendente)",
  buyerDesc: "Comprador (descendente)",
  orgUnit: "Unidad (ascendente)",
  orgUnitDesc: "Unidad (descendente)"
};
var unitLevelOrderDetails$c = {
  buyer: "Comprador"
};
var unitOrder$c = {
  unitLevelOrderHistory: unitLevelOrderHistory$c,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$c,
  unitLevelOrderDetails: unitLevelOrderDetails$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  unitOrder: unitOrder$c
};
var unitLevelOrderHistory$b = {
  orderHistory: "Historique des commandes au niveau de l'unité",
  orderId: "N° commande",
  emptyPurchaseOrderId: "Aucun(e)",
  date: "Date",
  status: "Statut",
  buyer: "Acheteur",
  unit: "Unité",
  total: "Total",
  noOrders: "Nous n'avons aucune commande correspondante pour votre unité.",
  startShopping: "Commencer les achats",
  sortBy: "Trier par",
  sortOrders: "Trier les commandes",
  purchaseOrderNumber: "Nº de la commande d'achat",
  startOn: "Début le",
  frequency: "Fréquence",
  nextOrderDate: "Prochaine date de commande",
  notFound: "Aucune commande trouvée",
  applyFilter: "Appliquer",
  filterBy: "Filtrer par",
  filterByBuyer: "Filtrer par acheteur",
  filterByBuyerLabel: "Nom de l'acheteur",
  filterByBuyerPlaceholder: "Saisissez le nom de l'acheteur",
  filterByBuyerAriaLabel: "Filtrer la liste par acheteur",
  filterByUnit: "Filtrer par unité",
  filterByUnitLabel: "Nom de l'unité",
  filterByUnitPlaceholder: "Saisissez un nom d'unité",
  filterByUnitAriaLabel: "Filtrer la liste par unité",
  search: "Rechercher",
  clearAll: "Effacer tout",
  removeAppliedFilters: "Supprimer les filtres",
  yourFilters: "Vos filtres :",
  and: " et "
};
var unitLevelOrderHistorySorting$b = {
  date: "Date",
  orderNumber: "Numéro de commande",
  buyer: "Acheteur (croissant)",
  buyerDesc: "Acheteur (décroissant)",
  orgUnit: "Unité (croissant)",
  orgUnitDesc: "Unité (décroissant)"
};
var unitLevelOrderDetails$b = {
  buyer: "Acheteur"
};
var unitOrder$b = {
  unitLevelOrderHistory: unitLevelOrderHistory$b,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$b,
  unitLevelOrderDetails: unitLevelOrderDetails$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  unitOrder: unitOrder$b
};
var unitLevelOrderHistory$a = {
  orderHistory: "इकाई-स्तर ऑर्डर इतिहास",
  orderId: "ऑर्डर #",
  emptyPurchaseOrderId: "कोई नहीं",
  date: "दिनांक",
  status: "स्थिति",
  buyer: "क्रेता",
  unit: "इकाई",
  total: "कुल",
  noOrders: "हमारे पास आपकी इकाई के लिए कोई मेल खाते ऑर्डर रिकॉर्ड नहीं है.",
  startShopping: "शॉपिंग प्रारंभ करें",
  sortBy: "इसके द्वारा क्रमित",
  sortOrders: "ऑर्डर क्रमित करें",
  purchaseOrderNumber: "PO #",
  startOn: "प्रारंभ दिनांक",
  frequency: "आवृत्ति",
  nextOrderDate: "अगले ऑर्डर का दिनांक",
  notFound: "कोई ऑर्डर नहीं मिला ",
  applyFilter: "लागू करें",
  filterBy: "फ़िल्टरकर्ता",
  filterByBuyer: "क्रेता द्वारा फ़िल्टर किया गया",
  filterByBuyerLabel: "क्रेता का नाम",
  filterByBuyerPlaceholder: "क्रेता का नाम दर्ज करें",
  filterByBuyerAriaLabel: "क्रेता के अनुसार फ़िल्टर सूची",
  filterByUnit: "इकाई के अनुसार फ़िल्टर",
  filterByUnitLabel: "इकाई का नाम",
  filterByUnitPlaceholder: "इकाई का नाम दर्ज करें",
  filterByUnitAriaLabel: "इकाई के अनुसार फ़िल्टर सूची",
  search: "खोजें",
  clearAll: "सब साफ़ करें",
  removeAppliedFilters: "फ़िल्टर निकालें",
  yourFilters: "आपके फ़िल्टरः",
  and: " और "
};
var unitLevelOrderHistorySorting$a = {
  date: "दिनांक",
  orderNumber: "ऑर्डर संख्या",
  buyer: "क्रेता (आरोही)",
  buyerDesc: "क्रेता (अवरोही)",
  orgUnit: "इकाई (आरोही)",
  orgUnitDesc: "इकाई (अवरोही)"
};
var unitLevelOrderDetails$a = {
  buyer: "क्रेता"
};
var unitOrder$a = {
  unitLevelOrderHistory: unitLevelOrderHistory$a,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$a,
  unitLevelOrderDetails: unitLevelOrderDetails$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  unitOrder: unitOrder$a
};
var unitLevelOrderHistory$9 = {
  orderHistory: "Egységszintű rendeléstörténet",
  orderId: "Rendelés #",
  emptyPurchaseOrderId: "Nincs",
  date: "Dátum",
  status: "Státus",
  buyer: "Beszerző",
  unit: "Egység",
  total: "Összesen",
  noOrders: "Nincsenek egyező rendelési rekordok az egységéhez.",
  startShopping: "Vásárlás megkezdése",
  sortBy: "Rendezés alapja",
  sortOrders: "Rendelések rendezése",
  purchaseOrderNumber: "Megrendelés #",
  startOn: "Indítás dátuma",
  frequency: "Gyakoriság",
  nextOrderDate: "Következő rendelés dátuma",
  notFound: "Nem található rendelés",
  applyFilter: "Alkalmaz",
  filterBy: "Szűrés alapja",
  filterByBuyer: "Szűrés beszerző szerint",
  filterByBuyerLabel: "Beszerző neve",
  filterByBuyerPlaceholder: "Adja meg a beszerző nevét",
  filterByBuyerAriaLabel: "Lista szűrése beszerző szerint",
  filterByUnit: "Szűrés egység szerint",
  filterByUnitLabel: "Egység neve",
  filterByUnitPlaceholder: "Adja meg az egység nevét",
  filterByUnitAriaLabel: "Lista szűrése egység szerint",
  search: "Keresés",
  clearAll: "Összes törlése",
  removeAppliedFilters: "Szűrők eltávolítása",
  yourFilters: "Saját szűrők:",
  and: " és "
};
var unitLevelOrderHistorySorting$9 = {
  date: "Dátum",
  orderNumber: "Rendelésszám",
  buyer: "Beszerző (növekvő)",
  buyerDesc: "Beszerző (csökkenő)",
  orgUnit: "Egység (növekvő)",
  orgUnitDesc: "Egység (csökkenő)"
};
var unitLevelOrderDetails$9 = {
  buyer: "Beszerző"
};
var unitOrder$9 = {
  unitLevelOrderHistory: unitLevelOrderHistory$9,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$9,
  unitLevelOrderDetails: unitLevelOrderDetails$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  unitOrder: unitOrder$9
};
var unitLevelOrderHistory$8 = {
  orderHistory: "Riwayat pemesanan tingkat unit",
  orderId: "Pemesanan #",
  emptyPurchaseOrderId: "Tidak ada",
  date: "Tanggal",
  status: "Status",
  buyer: "Pembeli",
  unit: "Unit",
  total: "Total",
  noOrders: "Tidak ada catatan pemesanan yang cocok untuk unit Anda.",
  startShopping: "Mulai Berbelanja",
  sortBy: "Urutkan berdasarkan",
  sortOrders: "Urutkan pemesanan",
  purchaseOrderNumber: "PO #",
  startOn: "Dimulai Pada",
  frequency: "Frekuensi",
  nextOrderDate: "Tanggal Pemesanan Berikutnya",
  notFound: "Pemesanan Tidak Ditemukan",
  applyFilter: "Terapkan",
  filterBy: "Filter berdasarkan",
  filterByBuyer: "Filter berdasarkan Pembeli",
  filterByBuyerLabel: "Nama pembeli",
  filterByBuyerPlaceholder: "Masukkan nama pembeli",
  filterByBuyerAriaLabel: "Filter daftar berdasarkan pembeli",
  filterByUnit: "Filter berdasarkan Unit",
  filterByUnitLabel: "Nama unit",
  filterByUnitPlaceholder: "Masukkan nama unit",
  filterByUnitAriaLabel: "Filter daftar berdasarkan unit",
  search: "Cari",
  clearAll: "Hapus Semua",
  removeAppliedFilters: "Hapus filter",
  yourFilters: "Filter Anda:",
  and: " dan "
};
var unitLevelOrderHistorySorting$8 = {
  date: "Tanggal",
  orderNumber: "Nomor Pemesanan",
  buyer: "Pembeli (Urutan Naik)",
  buyerDesc: "Pembeli (Urutan Turun)",
  orgUnit: "Unit (Urutan Naik)",
  orgUnitDesc: "Unit (Urutan Turun)"
};
var unitLevelOrderDetails$8 = {
  buyer: "Pembeli"
};
var unitOrder$8 = {
  unitLevelOrderHistory: unitLevelOrderHistory$8,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$8,
  unitLevelOrderDetails: unitLevelOrderDetails$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  unitOrder: unitOrder$8
};
var unitLevelOrderHistory$7 = {
  orderHistory: "Cronologia ordini a livello di unità",
  orderId: "N. ordine",
  emptyPurchaseOrderId: "Nessuno",
  date: "Data",
  status: "Stato",
  buyer: "Acquirente",
  unit: "Unità",
  total: "Totale",
  noOrders: "Nessun record di ordini corrispondente per l'unità.",
  startShopping: "Inizia gli acquisti",
  sortBy: "Ordina per",
  sortOrders: "Ordina ordini",
  purchaseOrderNumber: "N. ordine d'acquisto",
  startOn: "Inizia il",
  frequency: "Frequenza",
  nextOrderDate: "Data ordine successivo",
  notFound: "Nessun ordine trovato",
  applyFilter: "Applica",
  filterBy: "Filtra per",
  filterByBuyer: "Filtra per acquirente",
  filterByBuyerLabel: "Nome acquirente",
  filterByBuyerPlaceholder: "Inserisci nome acquirente",
  filterByBuyerAriaLabel: "Filtra lista per acquirente",
  filterByUnit: "Filtra per unità",
  filterByUnitLabel: "Nome unità",
  filterByUnitPlaceholder: "Inserisci nome unità",
  filterByUnitAriaLabel: "Filtra lista per unità",
  search: "Cerca",
  clearAll: "Cancella tutto",
  removeAppliedFilters: "Rimuovi filtri",
  yourFilters: "Filtri personali:",
  and: " e "
};
var unitLevelOrderHistorySorting$7 = {
  date: "Data",
  orderNumber: "Numero dell'ordine",
  buyer: "Acquirente (in ordine crescente)",
  buyerDesc: "Acquirente (in ordine decrescente)",
  orgUnit: "Unità (in ordine crescente)",
  orgUnitDesc: "Unità (in ordine decrescente)"
};
var unitLevelOrderDetails$7 = {
  buyer: "Acquirente"
};
var unitOrder$7 = {
  unitLevelOrderHistory: unitLevelOrderHistory$7,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$7,
  unitLevelOrderDetails: unitLevelOrderDetails$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  unitOrder: unitOrder$7
};
var unitLevelOrderHistory$6 = {
  orderHistory: "ユニットレベルの注文履歴",
  orderId: "注文番号",
  emptyPurchaseOrderId: "なし",
  date: "日付",
  status: "ステータス",
  buyer: "購入担当者",
  unit: "ユニット",
  total: "合計",
  noOrders: "ユニットに一致する注文レコードがありません。",
  startShopping: "ショッピング開始",
  sortBy: "ソート基準",
  sortOrders: "ソート順序",
  purchaseOrderNumber: "発注書番号",
  startOn: "開始日",
  frequency: "頻度",
  nextOrderDate: "次回注文日",
  notFound: "注文はありません",
  applyFilter: "適用",
  filterBy: "フィルタ基準",
  filterByBuyer: "購入担当者によるフィルタ",
  filterByBuyerLabel: "購入担当者名",
  filterByBuyerPlaceholder: "購入担当者名を入力",
  filterByBuyerAriaLabel: "購入担当者によるフィルタ一覧",
  filterByUnit: "ユニットによるフィルタ",
  filterByUnitLabel: "ユニット名",
  filterByUnitPlaceholder: "ユニット名を入力",
  filterByUnitAriaLabel: "ユニットによるフィルタ一覧",
  search: "検索",
  clearAll: "すべてクリア",
  removeAppliedFilters: "フィルタの削除",
  yourFilters: "ユーザのフィルタ:",
  and: " および "
};
var unitLevelOrderHistorySorting$6 = {
  date: "日付",
  orderNumber: "注文番号",
  buyer: "購入担当者 (昇順)",
  buyerDesc: "購入担当者 (降順)",
  orgUnit: "ユニット (昇順)",
  orgUnitDesc: "ユニット (降順)"
};
var unitLevelOrderDetails$6 = {
  buyer: "購入担当者"
};
var unitOrder$6 = {
  unitLevelOrderHistory: unitLevelOrderHistory$6,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$6,
  unitLevelOrderDetails: unitLevelOrderDetails$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  unitOrder: unitOrder$6
};
var unitLevelOrderHistory$5 = {
  orderHistory: "단위 레벨 주문 내역",
  orderId: "주문 번호",
  emptyPurchaseOrderId: "없음",
  date: "날짜",
  status: "상태",
  buyer: "구매자",
  unit: "단위",
  total: "합계",
  noOrders: "단위에 일치하는 주문 기록이 없습니다.",
  startShopping: "배송 시작",
  sortBy: "정렬 기준",
  sortOrders: "정렬 순서",
  purchaseOrderNumber: "구매 주문 번호",
  startOn: "시작일",
  frequency: "빈도",
  nextOrderDate: "다음 주문일",
  notFound: "주문이 없습니다.",
  applyFilter: "적용",
  filterBy: "필터 기준",
  filterByBuyer: "구매자별 필터",
  filterByBuyerLabel: "구매자 이름",
  filterByBuyerPlaceholder: "구매자 이름 입력",
  filterByBuyerAriaLabel: "구매자별 목록 필터",
  filterByUnit: "단위별 필터",
  filterByUnitLabel: "단위 이름",
  filterByUnitPlaceholder: "단위 이름 입력",
  filterByUnitAriaLabel: "단위별 목록 필터",
  search: "검색",
  clearAll: "모두 지우기",
  removeAppliedFilters: "필터 제거",
  yourFilters: "필터:",
  and: " 및 "
};
var unitLevelOrderHistorySorting$5 = {
  date: "날짜",
  orderNumber: "주문 번호",
  buyer: "구매자(오름차순)",
  buyerDesc: "구매자(내림차순)",
  orgUnit: "단위(오름차순)",
  orgUnitDesc: "단위(내림차순)"
};
var unitLevelOrderDetails$5 = {
  buyer: "구매자"
};
var unitOrder$5 = {
  unitLevelOrderHistory: unitLevelOrderHistory$5,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$5,
  unitLevelOrderDetails: unitLevelOrderDetails$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  unitOrder: unitOrder$5
};
var unitLevelOrderHistory$4 = {
  orderHistory: "Historia zamówienia na poziomie jednostki",
  orderId: "Zamówienie nr",
  emptyPurchaseOrderId: "Brak",
  date: "Data",
  status: "Status",
  buyer: "Nabywca",
  unit: "Jednostka",
  total: "Suma",
  noOrders: "Brak pasujących rekordów zamówień dla Twojej jednostki.",
  startShopping: "Rozpocznij zakupy",
  sortBy: "Sortuj według",
  sortOrders: "Sortuj zamówienia",
  purchaseOrderNumber: "Zamówienie nr",
  startOn: "Data rozpoczęcia",
  frequency: "Częstotliwość",
  nextOrderDate: "Data następnego zamówienia",
  notFound: "Nie znaleziono zamówień",
  applyFilter: "Zastosuj",
  filterBy: "Filtruj wg",
  filterByBuyer: "Filtruj wg nabywcy",
  filterByBuyerLabel: "Nazwa nabywcy",
  filterByBuyerPlaceholder: "Wprowadź nazwę nabywcy",
  filterByBuyerAriaLabel: "Lista filtrów wg nabywcy",
  filterByUnit: "Filtruj wg jednostki",
  filterByUnitLabel: "Nazwa jednostki",
  filterByUnitPlaceholder: "Wprowadź nazwę jednostki",
  filterByUnitAriaLabel: "Filtruj listę wg jednostki",
  search: "Szukaj",
  clearAll: "Wyczyść wszystkie",
  removeAppliedFilters: "Usuń filtry",
  yourFilters: "Twoje filtry:",
  and: " i "
};
var unitLevelOrderHistorySorting$4 = {
  date: "Data",
  orderNumber: "Numer zamówienia",
  buyer: "Nabywca (rosnąco)",
  buyerDesc: "Nabywca (malejąco)",
  orgUnit: "Jednostka (rosnąco)",
  orgUnitDesc: "Jednostka (malejąco)"
};
var unitLevelOrderDetails$4 = {
  buyer: "Nabywca"
};
var unitOrder$4 = {
  unitLevelOrderHistory: unitLevelOrderHistory$4,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$4,
  unitLevelOrderDetails: unitLevelOrderDetails$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  unitOrder: unitOrder$4
};
var unitLevelOrderHistory$3 = {
  orderHistory: "Histórico do pedido no nível da unidade",
  orderId: "Nº do pedido",
  emptyPurchaseOrderId: "Nenhum",
  date: "Data",
  status: "Status",
  buyer: "Comprador",
  unit: "Unidade",
  total: "Total",
  noOrders: "Nenhum registro de pedido correspondente para sua unidade.",
  startShopping: "Iniciar compras",
  sortBy: "Ordenar por",
  sortOrders: "Ordens de classificação",
  purchaseOrderNumber: "Nº do pedido",
  startOn: "Início em",
  frequency: "Frequência",
  nextOrderDate: "Data do próximo pedido",
  notFound: "Nenhum pedido encontrado",
  applyFilter: "Aplicar",
  filterBy: "Filtrar por",
  filterByBuyer: "Filtrar por comprador",
  filterByBuyerLabel: "Nome do comprador",
  filterByBuyerPlaceholder: "Inserir nome do comprador",
  filterByBuyerAriaLabel: "Filtrar lista por comprador",
  filterByUnit: "Filtrar por unidade",
  filterByUnitLabel: "Nome de unidade",
  filterByUnitPlaceholder: "Inserir nome de unidade",
  filterByUnitAriaLabel: "Filtrar lista por unidade",
  search: "Pesquisa",
  clearAll: "Limpar tudo",
  removeAppliedFilters: "Remover filtros",
  yourFilters: "Seus filtros:",
  and: " e "
};
var unitLevelOrderHistorySorting$3 = {
  date: "Data",
  orderNumber: "Número do pedido",
  buyer: "Comprador (ascendente)",
  buyerDesc: "Comprador (descendente)",
  orgUnit: "Unidade (ascendente)",
  orgUnitDesc: "Unidade (descendente)"
};
var unitLevelOrderDetails$3 = {
  buyer: "Comprador"
};
var unitOrder$3 = {
  unitLevelOrderHistory: unitLevelOrderHistory$3,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$3,
  unitLevelOrderDetails: unitLevelOrderDetails$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  unitOrder: unitOrder$3
};
var unitLevelOrderHistory$2 = {
  orderHistory: "История заказов на уровне единицы",
  orderId: "Заказ №",
  emptyPurchaseOrderId: "Нет",
  date: "Дата",
  status: "Статус",
  buyer: "Покупатель",
  unit: "Единица",
  total: "Итого",
  noOrders: "Нет записей заказов для вашей единицы.",
  startShopping: "Начать покупки",
  sortBy: "Сортировать по",
  sortOrders: "Сортировать заказы",
  purchaseOrderNumber: "ЗП №",
  startOn: "Дата начала",
  frequency: "Периодичность",
  nextOrderDate: "Дата следующего заказа",
  notFound: "Заказы не найдены",
  applyFilter: "Применить",
  filterBy: "Фильтровать по",
  filterByBuyer: "Фильтровать по покупателю",
  filterByBuyerLabel: "Имя покупателя",
  filterByBuyerPlaceholder: "Введите имя покупателя",
  filterByBuyerAriaLabel: "Фильтровать список по покупателю",
  filterByUnit: "Фильтровать по единице",
  filterByUnitLabel: "Имя единицы",
  filterByUnitPlaceholder: "Введите имя единицы",
  filterByUnitAriaLabel: "Фильтровать список по единице",
  search: "Поиск",
  clearAll: "Очистить все",
  removeAppliedFilters: "Удалить фильтры",
  yourFilters: "Ваши фильтры:",
  and: " и "
};
var unitLevelOrderHistorySorting$2 = {
  date: "Дата",
  orderNumber: "Номер заказа",
  buyer: "Покупатель (по восходящей)",
  buyerDesc: "Покупатель (по нисходящей)",
  orgUnit: "Единица (по восходящей)",
  orgUnitDesc: "Единица (по нисходящей)"
};
var unitLevelOrderDetails$2 = {
  buyer: "Покупатель"
};
var unitOrder$2 = {
  unitLevelOrderHistory: unitLevelOrderHistory$2,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$2,
  unitLevelOrderDetails: unitLevelOrderDetails$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  unitOrder: unitOrder$2
};
var unitLevelOrderHistory$1 = {
  orderHistory: "单位级别订单历史记录",
  orderId: "订单号",
  emptyPurchaseOrderId: "无",
  date: "日期",
  status: "状态",
  buyer: "买方",
  unit: "单位",
  total: "总计",
  noOrders: "您的单位没有匹配订单记录。",
  startShopping: "开始购物",
  sortBy: "排序依据",
  sortOrders: "对订单进行排序",
  purchaseOrderNumber: "采购订单编号",
  startOn: "开始日期",
  frequency: "频率",
  nextOrderDate: "下一个订单日期",
  notFound: "找不到订单",
  applyFilter: "应用",
  filterBy: "过滤条件",
  filterByBuyer: "按买方过滤",
  filterByBuyerLabel: "买方名称",
  filterByBuyerPlaceholder: "输入买方名称",
  filterByBuyerAriaLabel: "按买方过滤列表",
  filterByUnit: "按单位过滤",
  filterByUnitLabel: "单位名称",
  filterByUnitPlaceholder: "输入单位名称",
  filterByUnitAriaLabel: "按单位过滤列表",
  search: "搜索",
  clearAll: "全部清除",
  removeAppliedFilters: "移除过滤器",
  yourFilters: "您的过滤器：",
  and: " 和 "
};
var unitLevelOrderHistorySorting$1 = {
  date: "日期",
  orderNumber: "订单编号",
  buyer: "买方（升序）",
  buyerDesc: "买方（降序）",
  orgUnit: "单位（升序）",
  orgUnitDesc: "单位（降序）"
};
var unitLevelOrderDetails$1 = {
  buyer: "买方"
};
var unitOrder$1 = {
  unitLevelOrderHistory: unitLevelOrderHistory$1,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting$1,
  unitLevelOrderDetails: unitLevelOrderDetails$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  unitOrder: unitOrder$1
};
var unitLevelOrderHistory = {
  orderHistory: "單位層級訂單歷史記錄",
  orderId: "訂單號碼",
  emptyPurchaseOrderId: "無",
  date: "日期",
  status: "狀態",
  buyer: "買方",
  unit: "單位",
  total: "總計",
  noOrders: "沒有甫和您單位的訂單記錄。",
  startShopping: "開始購物",
  sortBy: "排序依據",
  sortOrders: "排序順序",
  purchaseOrderNumber: "採購單號碼",
  startOn: "開始日期",
  frequency: "頻率",
  nextOrderDate: "下一個訂單日期",
  notFound: "找不到訂單",
  applyFilter: "套用",
  filterBy: "篩選依據",
  filterByBuyer: "按買方篩選",
  filterByBuyerLabel: "買方名稱",
  filterByBuyerPlaceholder: "輸入買方名稱",
  filterByBuyerAriaLabel: "按買方篩選清單",
  filterByUnit: "按單位篩選",
  filterByUnitLabel: "單位名稱",
  filterByUnitPlaceholder: "輸入單位名稱",
  filterByUnitAriaLabel: "按單位篩選清單",
  search: "搜尋",
  clearAll: "全部清除",
  removeAppliedFilters: "移除篩選",
  yourFilters: "您的篩選：",
  and: " 和 "
};
var unitLevelOrderHistorySorting = {
  date: "日期",
  orderNumber: "訂單號碼",
  buyer: "買方 (升冪)",
  buyerDesc: "買方 (降冪)",
  orgUnit: "單位 (升冪)",
  orgUnitDesc: "單位 (降冪)"
};
var unitLevelOrderDetails = {
  buyer: "買方"
};
var unitOrder = {
  unitLevelOrderHistory: unitLevelOrderHistory,
  unitLevelOrderHistorySorting: unitLevelOrderHistorySorting,
  unitLevelOrderDetails: unitLevelOrderDetails
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  unitOrder
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const unitOrderTranslationChunksConfig = {
  unitOrder: ['unitLevelOrderHistory', 'unitLevelOrderHistorySorting', 'unitLevelOrderDetails']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: unitOrderTranslations
 *             +   resources: { en: unitOrderTranslationsEn }
 *               }
 *             ```
 */
const unitOrderTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { unitOrderTranslationChunksConfig, unitOrderTranslations, cs as unitOrderTranslationsCs, de as unitOrderTranslationsDe, en as unitOrderTranslationsEn, es as unitOrderTranslationsEs, es_CO as unitOrderTranslationsEs_CO, fr as unitOrderTranslationsFr, hi as unitOrderTranslationsHi, hu as unitOrderTranslationsHu, id as unitOrderTranslationsId, it as unitOrderTranslationsIt, ja as unitOrderTranslationsJa, ko as unitOrderTranslationsKo, pl as unitOrderTranslationsPl, pt as unitOrderTranslationsPt, ru as unitOrderTranslationsRu, zh as unitOrderTranslationsZh, zh_TW as unitOrderTranslationsZh_TW };
