import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_CURRENT,
  Product,
  ProductScope,
  ProductService,
  RoutingService,
  WindowRef,
} from '@spartacus/core';
import { Order, OrderHistoryFacade } from '@spartacus/order/root';
import {
  CommonConfigurator,
  CommonConfiguratorUtilsService,
  ConfiguratorModelUtils,
  ConfiguratorRouter,
  ConfiguratorRouterExtractorService,
} from '@spartacus/product-configurator/common';
import {
  Configurator,
  ConfiguratorCartService,
  ConfiguratorCommonsService,
  ConfiguratorGroupsService,
  ConfiguratorQuantityService,
  ConfiguratorStorefrontUtilsService,
} from '@spartacus/product-configurator/rulebased';
import {
  CurrentProductService,
  ICON_TYPE,
  IntersectionOptions,
  IntersectionService,
  LaunchDialogService,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';

import {
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import {
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ProductCatelogService } from '../../../../core/product-catalog/services/product-catelog.service';
import { CustomerAccountService } from '../../../../core/customer-account/customer-account.service';
import { GoogleTagManagerService } from '../../../../shared/services/gtm.service';
import { MyFavoritesService } from '../../../my-favorites/my-favorites.service';
import { DS_DIALOG } from '../../../../core/dialog/dialog.config';
import { CommerceTypes } from '../../../../shared/models/commerceTypes.model';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { ApiService } from '../../../../core/http/api.service';
import { HttpParams } from '@angular/common/http';

@Component({
  standalone: false,
  selector: 'cx-configurator-price-summary',
  templateUrl: './configurator-price-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./configurator-price-summary.component.scss'],
})
export class ConfiguratorPriceSummaryComponent {
  currentUserType;
  userLoggedIn$: Observable<boolean>;
  isChecked = false;
  productLine: string;
  totalPriceCheck: boolean = false;
  bentlyConfiguredPrice$ = new ReplaySubject<any>(1);
  private bentlyPriceSubject = new Subject<any>();
  bentlyConfiguredPriceValue: any;
  ngOnInit() {
    this.customerAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
    });
    this.userLoggedIn$ = this.authService.isUserLoggedIn();

    this.userLoggedIn$.subscribe((res) => {
      if (res) this.currentUserType = OCC_USER_ID_CURRENT;
      else this.currentUserType = OCC_USER_ID_ANONYMOUS;
    });
  }

  ngOnChanges() {
    this.userLoggedIn$ = this.authService.isUserLoggedIn();

    this.userLoggedIn$.subscribe((res) => {
      if (res) this.currentUserType = OCC_USER_ID_CURRENT;
      else this.currentUserType = OCC_USER_ID_ANONYMOUS;
    });
  }
  onImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.style.display = 'none';
  }
  product$: Observable<Product> = this.configRouterExtractorService
    .extractRouterData()
    .pipe(
      switchMap((routerData) =>
        this.configuratorCommonsService.getConfiguration(routerData.owner)
      ),
      map((configuration) => {
        switch (configuration.owner.type) {
          case CommonConfigurator.OwnerType.PRODUCT:
          case CommonConfigurator.OwnerType.CART_ENTRY:
            return configuration.productCode;
          case CommonConfigurator.OwnerType.ORDER_ENTRY:
            return configuration.overview.productCode;
        }
      }),
      switchMap((productCode) => this.productService.get(productCode))
    );
  configuration$: Observable<Configurator.Configuration> =
    this.configRouterExtractorService.extractRouterData().pipe(
      switchMap((routerData) => {
        return this.configuratorCommonsService.getConfiguration(
          routerData.owner
        );
      })
    );
  showMore = false;
  iconTypes = ICON_TYPE;

  constructor(
    protected configuratorCommonsService: ConfiguratorCommonsService,
    protected configRouterExtractorService: ConfiguratorRouterExtractorService,
    private authService: AuthService,
    private productService: ProductService,
    private auth: AuthService,
    private userAccountFacade: UserAccountFacade,
    private route: ActivatedRoute,
    private productCatelogService: ProductCatelogService,
    private currentProductService: CurrentProductService,
    private myFavouritesService: MyFavoritesService,
    private globalMessageService: GlobalMessageService,
    private cd: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService,
    protected routingService: RoutingService,
    protected configuratorCartService: ConfiguratorCartService,
    protected configuratorGroupsService: ConfiguratorGroupsService,
    protected orderHistoryFacade: OrderHistoryFacade,
    protected commonConfiguratorUtilsService: CommonConfiguratorUtilsService,
    protected configUtils: ConfiguratorStorefrontUtilsService,
    protected intersectionService: IntersectionService,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    protected configuratorQuantityService: ConfiguratorQuantityService,
    private customerAccService: CustomerAccountService,
    protected router: Router,
    protected winRef: WindowRef,
    private launchDialogService: LaunchDialogService,
    protected activeCartFacade: ActiveCartFacade,
    private productCatService: ProductCatelogService,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) {}

  getProductImageAlt(product: Product): string {
    return product.images?.['PRIMARY']?.['thumbnail']?.altText;
  }

  async updatePrice(configId: any, productCode: any) {
    this.totalPriceCheck = true;

    if (this.productLine == 'bently-nevada') {
      let reqbody = {
        productCode: productCode,
        configId: configId,
        productLine: this.productLine,
      };

      try {
        this.getUpdatedPriceForBentley(reqbody).subscribe((price) => {
          this.bentlyConfiguredPrice$.next(price);
        });
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
  }
  configurationCompleteChecker(configuration: Configurator.Configuration) {
    let count = 0,
      checker = 0;
    for (let i = 0; i < configuration.groups.length; i++) {
      if (configuration.groups[i].configurable == true) {
        count++;
      }
    }
    for (let i = 0; i < configuration.groups.length; i++) {
      if (
        configuration.groups[i].configurable == true &&
        configuration.groups[i].complete == true &&
        configuration.groups[i].consistent == true
      ) {
        checker++;
      }
    }
    if (checker == count) {
      return true;
    } else {
      return false;
    }
  }
  getUpdatedPriceForBentley(reqBody: any): Observable<any> {
    const params1 = ['users', 'current', 'products', 'configPrice'];
    const url = this.apiService.constructUrl(params1);
    // let queryParams = new HttpParams().set('productLine', 'bently-nevada');
    // queryParams = queryParams.append('productLine', 'Bently');
    return this.apiService.postData(url, reqBody);
  }

  protected subscription = new Subscription();
  quantityControl = new UntypedFormControl(1);
  iconType = ICON_TYPE;

  container$: Observable<{
    routerData: ConfiguratorRouter.Data;
    configuration: Configurator.Configuration;
    hasPendingChanges: boolean;
  }> = this.configRouterExtractorService.extractRouterData().pipe(
    switchMap((routerData) =>
      this.configuratorCommonsService
        .getConfiguration(routerData.owner)
        .pipe(map((configuration) => ({ routerData, configuration })))
        .pipe(
          switchMap((cont) =>
            this.configuratorCommonsService
              .hasPendingChanges(cont.configuration.owner)
              .pipe(
                map((hasPendingChanges) => ({
                  routerData: cont.routerData,
                  configuration: cont.configuration,
                  hasPendingChanges,
                }))
              )
          )
        )
    )
  );

  protected navigateToCart(): void {
    // this.router.navigate([this.productLine,'cart']);
    /* this.routingService.go('cart'); */
    this.winRef.location.href = `/${this.productLine}/cart`;
  }

  protected navigateToOverview(
    configuratorType: string,
    owner: CommonConfigurator.Owner
  ): void {
    this.routingService.go({
      cxRoute: 'configureOverview' + configuratorType,
      params: { ownerType: 'cartEntry', entityKey: owner.id },
    });
  }

  protected displayConfirmationMessage(key: string): void {
    this.globalMessageService.add(
      { key: key },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
  }

  /**
   * Performs the navigation to the corresponding location (cart or overview pages).
   *
   * @param {string} configuratorType - Configurator type
   * @param {CommonConfigurator.Owner} owner - Owner
   * @param {boolean} isAdd - Is add to cart
   * @param {boolean} isOverview - Is overview page
   * @param {boolean} showMessage - Show message
   */
  performNavigation(
    configuratorType: string,
    owner: CommonConfigurator.Owner,
    isAdd: boolean,
    isOverview: boolean,
    showMessage: boolean
  ): void {
    const messageKey = isAdd
      ? 'configurator.addToCart.confirmation'
      : 'configurator.addToCart.confirmationUpdate';
    /*     if (isOverview) {
      this.navigateToCart();
    } else {
      this.navigateToOverview(configuratorType, owner);
    } */
    this.navigateToCart();
    if (showMessage) {
      this.displayConfirmationMessage(messageKey);
    }
  }

  /**
   * Decides on the resource key for the button. Depending on the business process (owner of the configuration) and the
   * need for a cart update, the text will differ
   * @param {ConfiguratorRouter.Data} routerData - Reflects the current router state
   * @param {Configurator.Configuration} configuration - Configuration
   * @returns {string} The resource key that controls the button description
   */
  getButtonResourceKey(
    routerData: ConfiguratorRouter.Data,
    configuration: Configurator.Configuration
  ): string {
    if (
      routerData.isOwnerCartEntry &&
      configuration.isCartEntryUpdateRequired
    ) {
      return 'configurator.addToCart.buttonUpdateCart';
    } else if (
      routerData.isOwnerCartEntry &&
      !configuration.isCartEntryUpdateRequired
    ) {
      return 'configurator.addToCart.buttonAfterAddToCart';
    } else {
      return 'configurator.addToCart.button';
    }
  }
  private openSwitchCartModal(
    currentCartType,
    switchToCartType,
    cartId,
    configuration: Configurator.Configuration,
    routerData: ConfiguratorRouter.Data,
    switchSame?: boolean
  ) {
    const componentdata = {
      currentCartType: currentCartType,
      switchToCartType: switchToCartType,
      currentCartCode: cartId,
      switchSame: switchSame,
    };
    const switchCartModel = this.launchDialogService.openDialog(
      DS_DIALOG.SWITCH_CART_DIALOG,
      undefined,
      undefined,
      componentdata
    );
    if (switchCartModel) {
      switchCartModel.pipe(take(1)).subscribe((value) => {
        if (value == true || value?.instance?.reason == true) {
          this.onAddToCart(configuration, routerData);
        }
      });
    }
  }
  addToCart(
    configuration: Configurator.Configuration,
    routerData: ConfiguratorRouter.Data,
    isAddToQuote?: boolean
  ) {
    let currentCartType: CommerceTypes;
    let userType;
    let lastSalesArea;
    this.userLoggedIn$.subscribe((res) => {
      if (res) {
        currentCartType = CommerceTypes.BUY;
        userType = OCC_USER_ID_CURRENT;
      } else if (isAddToQuote) {
        currentCartType = CommerceTypes.GUESTQUOTE;
        userType = OCC_USER_ID_ANONYMOUS;
        lastSalesArea = JSON.parse(sessionStorage.getItem('lastSalesArea'));
      } else {
        currentCartType = CommerceTypes.GUESTBUY;
        userType = OCC_USER_ID_ANONYMOUS;
        lastSalesArea = JSON.parse(sessionStorage.getItem('lastSalesArea'));
      }
    });
    let currentSalesArea =
      this.customerAccService.getGuestActiveSalesAreaFromStorage();
    this.activeCartFacade
      .getActive()
      .pipe(
        take(1),
        concatMap((activeCart: any) => {
          let cartId;
          if (userType === OCC_USER_ID_CURRENT) {
            cartId = activeCart.code;
          } else if (userType === OCC_USER_ID_ANONYMOUS) {
            cartId = activeCart.guid;
          }
          if (activeCart.entries?.length > 0) {
            if (activeCart.commerceType != currentCartType) {
              this.openSwitchCartModal(
                activeCart.commerceType,
                currentCartType,
                cartId,
                configuration,
                routerData
              );
            } else if (
              lastSalesArea &&
              lastSalesArea.salesAreaId != currentSalesArea.salesAreaId
            ) {
              this.openSwitchCartModal(
                activeCart.commerceType,
                currentCartType,
                cartId,
                configuration,
                routerData,
                true
              );
            } else {
              this.onAddToCart(configuration, routerData);
            }
            return of({ modal: true });
          } else {
            return this.productCatService.saveCartType(
              cartId,
              currentCartType,
              userType
            );
          }
        })
      )
      .subscribe(
        (val) => {
          if (val === null) {
            this.onAddToCart(configuration, routerData);
          }
        },
        (error) => {}
      );
  }
  /**
   * Triggers action and navigation, both depending on the context. Might result in an addToCart, updateCartEntry,
   * just a cart navigation or a browser back navigation
   * @param {Configurator.Configuration} configuration - Configuration
   * @param {ConfiguratorRouter.Data} routerData - Reflects the current router state
   */
  onAddToCart(
    configuration: Configurator.Configuration,
    routerData: ConfiguratorRouter.Data
  ): void {
    const pageType = routerData.pageType;
    const configuratorType = configuration.owner.configuratorType;
    const isOverview = pageType === ConfiguratorRouter.PageType.OVERVIEW;
    const isOwnerCartEntry =
      routerData.owner.type === CommonConfigurator.OwnerType.CART_ENTRY;
    const owner = configuration.owner;

    const currentGroup = configuration.interactionState.currentGroup;
    if (currentGroup) {
      this.configuratorGroupsService.setGroupStatusVisited(
        configuration.owner,
        currentGroup
      );
    }
    this.container$
      .pipe(
        filter((cont) => !cont.hasPendingChanges),
        take(1)
      )
      .subscribe(() => {
        if (isOwnerCartEntry) {
          if (configuration.isCartEntryUpdateRequired) {
            this.configuratorCartService.updateCartEntry(configuration);
          }

          this.performNavigation(
            configuratorType,
            owner,
            false,
            isOverview,
            configuration.isCartEntryUpdateRequired ?? false
          );
          if (configuration.isCartEntryUpdateRequired) {
            this.configuratorCommonsService.removeConfiguration(owner);
          }
        } else {
          this.configuratorCartService.addToCart(
            owner.id,
            configuration.configId,
            owner
          );

          this.configuratorCommonsService
            .getConfiguration(owner)
            .pipe(
              filter(
                (configWithNextOwner) =>
                  configWithNextOwner.nextOwner !== undefined
              ),
              take(1)
            )
            .subscribe((configWithNextOwner) => {
              //See preceeding filter operator: configWithNextOwner.nextOwner is always defined here
              const nextOwner =
                configWithNextOwner.nextOwner ??
                ConfiguratorModelUtils.createInitialOwner();

              this.performNavigation(
                configuratorType,
                nextOwner,
                true,
                isOverview,
                true
              );

              // we clean up the cart entry related configuration, as we might have a
              // configuration for the same cart entry number stored already.
              // (Cart entries might have been deleted)

              // we do not clean up the product bound configuration yet, as existing
              // observables would instantly trigger a re-create.
              // Cleaning up this obsolete product bound configuration will only happen
              // when a new config form requests a new observable for a product bound
              // configuration

              this.configuratorCommonsService.removeConfiguration(nextOwner);
            });
        }
      });
  }
}
