var productVariants$w = {
  style: "Styl",
  selectedStyle: "Vybraný styl",
  size: "Velikost",
  color: "Barva",
  sizeGuideLabel: "Průvodce styly"
};
var productVariants$x = {
  productVariants: productVariants$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  productVariants: productVariants$x
};
var productVariants$u = {
  style: "Stil",
  selectedStyle: "Ausgewählter Stil",
  size: "Größe",
  color: "Farbe",
  sizeGuideLabel: "Stilleitfaden"
};
var productVariants$v = {
  productVariants: productVariants$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  productVariants: productVariants$v
};
var productVariants$s = {
  style: "Style",
  selectedStyle: "Selected style",
  size: "Size",
  color: "Color",
  sizeGuideLabel: "Style guide"
};
var productVariants$t = {
  productVariants: productVariants$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  productVariants: productVariants$t
};
var productVariants$q = {
  style: "Estilo",
  selectedStyle: "Estilo seleccionado",
  size: "Tamaño",
  color: "Color",
  sizeGuideLabel: "Guía de estilo"
};
var productVariants$r = {
  productVariants: productVariants$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  productVariants: productVariants$r
};
var productVariants$o = {
  style: "Estilo",
  selectedStyle: "Estilo seleccionado",
  size: "Tamaño",
  color: "Color",
  sizeGuideLabel: "Guía de estilo"
};
var productVariants$p = {
  productVariants: productVariants$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  productVariants: productVariants$p
};
var productVariants$m = {
  style: "Style",
  selectedStyle: "Style sélectionné",
  size: "Taille",
  color: "Couleur",
  sizeGuideLabel: "Guide des styles"
};
var productVariants$n = {
  productVariants: productVariants$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  productVariants: productVariants$n
};
var productVariants$k = {
  style: "स्टाइल",
  selectedStyle: "चयनित स्टाइल",
  size: "साइज़",
  color: "रंग",
  sizeGuideLabel: "स्टाइल गाइड"
};
var productVariants$l = {
  productVariants: productVariants$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  productVariants: productVariants$l
};
var productVariants$i = {
  style: "Stílus",
  selectedStyle: "Kiválasztott stílus",
  size: "Méret",
  color: "Szín",
  sizeGuideLabel: "Stílusútmutató"
};
var productVariants$j = {
  productVariants: productVariants$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  productVariants: productVariants$j
};
var productVariants$g = {
  style: "Model",
  selectedStyle: "Model yang dipilih",
  size: "Ukuran",
  color: "Warna",
  sizeGuideLabel: "Panduan model"
};
var productVariants$h = {
  productVariants: productVariants$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  productVariants: productVariants$h
};
var productVariants$e = {
  style: "Stile",
  selectedStyle: "Stile selezionato",
  size: "Taglia",
  color: "Colore",
  sizeGuideLabel: "Guida agli stili"
};
var productVariants$f = {
  productVariants: productVariants$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  productVariants: productVariants$f
};
var productVariants$c = {
  style: "スタイル",
  selectedStyle: "選択したスタイル",
  size: "サイズ",
  color: "色",
  sizeGuideLabel: "スタイルガイド"
};
var productVariants$d = {
  productVariants: productVariants$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  productVariants: productVariants$d
};
var productVariants$a = {
  style: "스타일",
  selectedStyle: "선택한 스타일",
  size: "크기",
  color: "색상",
  sizeGuideLabel: "스타일 가이드"
};
var productVariants$b = {
  productVariants: productVariants$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  productVariants: productVariants$b
};
var productVariants$8 = {
  style: "Styl",
  selectedStyle: "Wybrany styl",
  size: "Rozmiar",
  color: "Kolor",
  sizeGuideLabel: "Przewodnik po stylu"
};
var productVariants$9 = {
  productVariants: productVariants$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  productVariants: productVariants$9
};
var productVariants$6 = {
  style: "Estilo",
  selectedStyle: "Estilo selecionado",
  size: "Tamanho",
  color: "Cor",
  sizeGuideLabel: "Guia de estilo"
};
var productVariants$7 = {
  productVariants: productVariants$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  productVariants: productVariants$7
};
var productVariants$4 = {
  style: "Стиль",
  selectedStyle: "Выбранный стиль",
  size: "Размер",
  color: "Цвет",
  sizeGuideLabel: "Руководство по стилю"
};
var productVariants$5 = {
  productVariants: productVariants$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  productVariants: productVariants$5
};
var productVariants$2 = {
  style: "样式",
  selectedStyle: "所选样式",
  size: "大小",
  color: "颜色",
  sizeGuideLabel: "样式指南"
};
var productVariants$3 = {
  productVariants: productVariants$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  productVariants: productVariants$3
};
var productVariants = {
  style: "樣式",
  selectedStyle: "所選樣式",
  size: "大小",
  color: "顏色",
  sizeGuideLabel: "樣式指南"
};
var productVariants$1 = {
  productVariants: productVariants
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  productVariants: productVariants$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const productVariantsTranslationChunksConfig = {
  productVariants: ['productVariants']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: productVariantsTranslations
 *             +   resources: { en: productVariantsTranslationsEn }
 *               }
 *             ```
 */
const productVariantsTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { productVariantsTranslationChunksConfig, productVariantsTranslations, cs as productVariantsTranslationsCs, de as productVariantsTranslationsDe, en as productVariantsTranslationsEn, es as productVariantsTranslationsEs, es_CO as productVariantsTranslationsEs_CO, fr as productVariantsTranslationsFr, hi as productVariantsTranslationsHi, hu as productVariantsTranslationsHu, id as productVariantsTranslationsId, it as productVariantsTranslationsIt, ja as productVariantsTranslationsJa, ko as productVariantsTranslationsKo, pl as productVariantsTranslationsPl, pt as productVariantsTranslationsPt, ru as productVariantsTranslationsRu, zh as productVariantsTranslationsZh, zh_TW as productVariantsTranslationsZh_TW };
