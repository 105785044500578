var exportEntries$g = {
  exportToCsv: "Exportovat do CSV",
  exportMessage: "Soubor CSV se do vašeho zařízení stáhne automaticky. Pokud se tak nestane, zkontrolujte nastavení prohlížeče.",
  columnNames: {
    code: "Kód",
    quantity: "Množství",
    name: "Název",
    price: "Cena"
  },
  exportProductToCsv: "Exportovat do CSV"
};
var importEntries$g = {
  importProducts: "Import produktů"
};
var importEntriesDialog$g = {
  importProducts: "Import produktů",
  importProductsSubtitle: "Přidejte produkty prostřednictvím importu souboru .CSV",
  importProductsNewSavedCartSubtitle: "Přidejte produkty prostřednictvím importu souboru .CSV a vytvořením nového uloženého košíku.",
  importProductFileDetails: "Textový soubor musí obsahovat seznam produktů s požadovanými sloupci oddělenými čárkou: SKU a množství.",
  selectFile: "Vyberte soubor",
  selectedSuccessfully: "Soubor CSV byl úspěšně vybrán.",
  savedCartName: "Název uloženého košíku",
  savedCartDescription: "Popis uloženého košiku",
  optional: "volitelné",
  charactersLeft: "zbývá znaků: {{count}}",
  cancel: "Zrušit",
  upload: "Upload",
  close: "Zavřít",
  summary: {
    info: "Během importu produktů nezavírejte ani neaktualizujte toto okno.",
    loadedToCart: "Produkty byly nahrány do košíku {{ cartName }}",
    loaded: "Produkty byly nahrány",
    loading: "Probíhá zpracování produktů… ({{ count }}/{{ total }})",
    successes: "{{ successesCount }} z {{ total }} produktů byl úspěšně importován.",
    warning: "{{ count }} nebyl zcela importován.",
    warning_other: "Počet produktů, které nebyly zcela importovány: {{ count }}",
    error: "{{ count }} nebyl importován.",
    error_other: "Počet produktů, které nebyly importovány: {{ count }}",
    messages: {
      unknownIdentifier: "SKU produktu „{{ productCode}}“ neexistuje.",
      lowStock: "Množství pro {{ productName }}:{{ quantity }}) bylo sníženo na {{ quantityAdded }}.",
      noStock: "{{ productName }} není momentálně skladem.",
      unknownError: "Neznámý problém s „{{ productCode }}“.",
      limitExceeded: "Nelze přidat „{{ productCode }}“. Překročen limit."
    },
    show: "Zobrazit",
    hide: "Skrýt"
  }
};
var importExport$g = {
  exportEntries: exportEntries$g,
  importEntries: importEntries$g,
  importEntriesDialog: importEntriesDialog$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  importExport: importExport$g
};
var exportEntries$f = {
  exportToCsv: "In CSV-Datei exportieren",
  exportMessage: "Die CSV-Datei wird automatisch auf Ihr Gerät heruntergeladen, andernfalls überprüfen Sie bitte Ihre Browsereinstellungen",
  columnNames: {
    code: "Code",
    quantity: "Menge",
    name: "Name",
    price: "Preis"
  },
  exportProductToCsv: "Produkt in CSV-Datei eportieren"
};
var importEntries$f = {
  importProducts: "Produkte importieren"
};
var importEntriesDialog$f = {
  importProducts: "Produkte importieren",
  importProductsSubtitle: "Fügen Sie Produkte hinzu, indem Sie eine .CSV-Datei importieren.",
  importProductsNewSavedCartSubtitle: "Fügen Sie Produkte hinzu, indem Sie eine .CSV-Datei importieren und einen neuen gespeicherten Warenkorb erstellen.",
  importProductFileDetails: "Die Textdatei sollte eine Liste der Produkte mit erforderlichen, durch ein Komma getrennte Spalten enthalten: SKU und Menge.",
  selectFile: "Datei auswählen",
  selectedSuccessfully: "CSV-Datei wurde erfolgreich ausgewählt.",
  savedCartName: "Name des gespeicherten Warenkorbs",
  savedCartDescription: "Beschreibung des gespeicherten Warenkorbs",
  optional: "optional",
  charactersLeft: "Zeichen übrig: {{count}}",
  cancel: "Abbrechen",
  upload: "Hochladen",
  close: "Schließen",
  summary: {
    info: "Schließen oder aktualisieren Sie dieses Fenster nicht, während die Produkte importiert werden.",
    loadedToCart: "Produkte wurden in den Warenkorb {{ cartName }} geladen",
    loaded: "Produkte wurden geladen",
    loading: "Produkte werden verarbeitet... ({{ count }}/{{ total }})",
    successes: "{{ successesCount }} von {{ total }} Produkten wurden erfolgreich importiert.",
    warning: "{{ count }} Produkt wurde nicht vollständig importiert.",
    warning_other: "{{ count }} Produkte wurden nicht vollständig importiert.",
    error: "{{ count }} Produkt wurde nicht importiert.",
    error_other: "{{ count }} Produkte wurden nicht importiert.",
    messages: {
      unknownIdentifier: "Produkt-SKU \"{{ productCode}}\" ist nicht vorhanden.",
      lowStock: "Menge für {{ productName }}: {{ quantity }} wurde auf {{ quantityAdded }} verringert.",
      noStock: "{{ productName }} ist zurzeit nicht vorrätig.",
      unknownError: "Nicht erkanntes Problem mit \"{{ productCode }}\".",
      limitExceeded: "\"{{ productCode }}\" kann nicht hinzugefügt werden. Limit überschritten."
    },
    show: "Anzeigen",
    hide: "Ausblenden"
  }
};
var importExport$f = {
  exportEntries: exportEntries$f,
  importEntries: importEntries$f,
  importEntriesDialog: importEntriesDialog$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  importExport: importExport$f
};
var exportEntries$e = {
  exportToCsv: "Export to CSV",
  exportMessage: "CSV file will download automatically to your device, if it does not please check your browser settings",
  columnNames: {
    code: "Code",
    quantity: "Quantity",
    name: "Name",
    price: "Price"
  },
  exportProductToCsv: "Export Product to CSV"
};
var importEntries$e = {
  importProducts: "Import Products"
};
var importEntriesDialog$e = {
  importProducts: "Import Products",
  importProductsSubtitle: "Add products by importing a .CSV file.",
  importProductsNewSavedCartSubtitle: "Add products by importing a .CSV file and creating a new saved cart.",
  importProductFileDetails: "Text file should contain list of products with required columns separated by comma: SKU and quantity.",
  selectFile: "Select File",
  selectedSuccessfully: "CSV file has been successfully selected.",
  savedCartName: "Saved Cart Name",
  savedCartDescription: "Saved Cart Description",
  optional: "optional",
  charactersLeft: "characters left: {{count}}",
  cancel: "Cancel",
  upload: "Upload",
  close: "Close",
  summary: {
    info: "Do not close or refresh this window while products are being imported.",
    loadedToCart: "Products has been loaded to cart {{ cartName }}",
    loaded: "Products has been loaded",
    loading: "Products are being processed... ({{ count }}/{{ total }})",
    successes: "{{ successesCount }} out of {{ total }} products have been imported successfully.",
    warning: "{{ count }} product was not imported totally.",
    warning_other: "{{ count }} products were not imported totally.",
    error: "{{ count }} product was not imported.",
    error_other: "{{ count }} products were not imported.",
    messages: {
      unknownIdentifier: "Product SKU \"{{ productCode}}\" does not exist.",
      lowStock: "Quantity for {{ productName }}: {{ quantity }} has been reduced to {{ quantityAdded }}.",
      noStock: "{{ productName }} is currently out of stock.",
      unknownError: "Unrecognized problem with \"{{ productCode }}\".",
      limitExceeded: "Can not add \"{{ productCode }}\". Limit exceeded."
    },
    show: "Show",
    hide: "Hide"
  }
};
var importExport$e = {
  exportEntries: exportEntries$e,
  importEntries: importEntries$e,
  importEntriesDialog: importEntriesDialog$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  importExport: importExport$e
};
var exportEntries$d = {
  exportToCsv: "Exportar a CSV",
  exportMessage: "El fichero CSV se descargará automáticamente en su dispositivo. Si no lo hace, verifique la configuración de su navegador.",
  columnNames: {
    code: "Código",
    quantity: "Cantidad",
    name: "Nombre",
    price: "Precio"
  },
  exportProductToCsv: "Exportar producto a CSV"
};
var importEntries$d = {
  importProducts: "Importar productos"
};
var importEntriesDialog$d = {
  importProducts: "Importar productos",
  importProductsSubtitle: "Añada productos importando un fichero .CSV.",
  importProductsNewSavedCartSubtitle: "Añada productos importando un archivo .CSV y creando una nueva cesta guardada.",
  importProductFileDetails: "El fichero de texto debe contener una lista de productos con las columnas obligatorias separadas por comas: unidad de almacenamiento y cantidad.",
  selectFile: "Seleccionar fichero",
  selectedSuccessfully: "El fichero CSV se ha seleccionado correctamente.",
  savedCartName: "Nombre de cesta guardada",
  savedCartDescription: "Descripción de cesta guardada",
  optional: "opcional",
  charactersLeft: "caracteres restantes: {{count}}",
  cancel: "Cancelar",
  upload: "Cargar",
  close: "Cerrar",
  summary: {
    info: "No cierre ni actualice esta ventana mientras se importan los productos.",
    loadedToCart: "Los productos se han cargado en el carrito {{ cartName }}",
    loaded: "Se han cargado los productos",
    loading: "Los productos se están procesando... ({{ count }}/{{ total }})",
    successes: "{{ successesCount }} de {{ total }} productos se han importado con éxito.",
    warning: "{{ count }} producto no se importó en su totalidad.",
    warning_other: "{{ count }} productos no se importaron en su totalidad.",
    error: "{{ count }} producto no se importó.",
    error_other: "{{ count }} productos no se importaron.",
    messages: {
      unknownIdentifier: "La unidad de almacenamiento \"{{ productCode}}\" del producto no existe.",
      lowStock: "La cantidad de {{ productName }}: {{ quantity }} se ha reducido a {{ quantityAdded }}.",
      noStock: "{{ productName }} no está disponible actualmente en el stock.",
      unknownError: "Problema no reconocido con \"{{ productCode }}\".",
      limitExceeded: "No se puede añadir \"{{ productCode }}\". Se excedió el límite."
    },
    show: "Visualizar",
    hide: "Ocultar"
  }
};
var importExport$d = {
  exportEntries: exportEntries$d,
  importEntries: importEntries$d,
  importEntriesDialog: importEntriesDialog$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  importExport: importExport$d
};
var exportEntries$c = {
  exportToCsv: "Exportar a CSV",
  exportMessage: "El archivo CSV se descargará automáticamente en su dispositivo. Si no lo hace, verifique la configuración de su navegador.",
  columnNames: {
    code: "Código",
    quantity: "Cantidad",
    name: "Nombre",
    price: "Precio"
  },
  exportProductToCsv: "Exportar producto a CSV"
};
var importEntries$c = {
  importProducts: "Importar productos"
};
var importEntriesDialog$c = {
  importProducts: "Importar productos",
  importProductsSubtitle: "Agregar productos importando un archivo .CSV.",
  importProductsNewSavedCartSubtitle: "Agregar productos importando un archivo .CSV y creando un nuevo carro guardado.",
  importProductFileDetails: "El archivo de texto debe contener una lista de productos con las columnas obligatorias separadas por comas: unidad de almacenamiento y cantidad.",
  selectFile: "Seleccionar archivo",
  selectedSuccessfully: "El archivo CSV se ha seleccionado correctamente.",
  savedCartName: "Nombre de carro guardado",
  savedCartDescription: "Descripción de carro guardado",
  optional: "opcional",
  charactersLeft: "caracteres restantes: {{count}}",
  cancel: "Cancelar",
  upload: "Cargar",
  close: "Cerrar",
  summary: {
    info: "No cierre ni actualice esta ventana mientras se importan los productos.",
    loadedToCart: "Los productos se han cargado en el carro {{ cartName }}",
    loaded: "Se han cargado los productos",
    loading: "Los productos se están procesando... ({{ count }}/{{ total }})",
    successes: "{{ successesCount }} de {{ total }} productos se han importado correctamente.",
    warning: "{{ count }} producto no se importó en su totalidad.",
    warning_other: "{{ count }} productos no se importaron en su totalidad.",
    error: "{{ count }} producto no se importó.",
    error_other: "{{ count }} productos no se importaron.",
    messages: {
      unknownIdentifier: "La unidad de almacenamiento \"{{ productCode}}\" del producto no existe.",
      lowStock: "La cantidad de {{ productName }}: {{ quantity }} se ha reducido a {{ quantityAdded }}.",
      noStock: "{{ productName }} no está disponible actualmente en el stock.",
      unknownError: "Problema no reconocido con \"{{ productCode }}\".",
      limitExceeded: "No se puede agregar \"{{ productCode }}\". Se excedió el límite."
    },
    show: "Mostrar",
    hide: "Ocultar"
  }
};
var importExport$c = {
  exportEntries: exportEntries$c,
  importEntries: importEntries$c,
  importEntriesDialog: importEntriesDialog$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  importExport: importExport$c
};
var exportEntries$b = {
  exportToCsv: "Exporter au format CSV",
  exportMessage: "Le fichier CSV sera téléchargé automatiquement sur votre appareil, si ce n'est pas le cas, veuillez vérifier les paramètres de votre navigateur.",
  columnNames: {
    code: "Code",
    quantity: "Quantité",
    name: "Nom",
    price: "Prix"
  },
  exportProductToCsv: "Exporter le produit au format CSV"
};
var importEntries$b = {
  importProducts: "Importer des produits"
};
var importEntriesDialog$b = {
  importProducts: "Importer des produits",
  importProductsSubtitle: "Ajoutez des produits en important un fichier .CSV.",
  importProductsNewSavedCartSubtitle: "Ajoutez des produits en important un fichier .CSV et en créant un nouveau panier sauvegardé.",
  importProductFileDetails: "Le fichier texte doit contenir une liste de produits avec les colonnes requises séparées par une virgule : UGS et quantité.",
  selectFile: "Sélectionner un fichier",
  selectedSuccessfully: "Le fichier CSV a été sélectionné avec succès.",
  savedCartName: "Nom du panier sauvegardé",
  savedCartDescription: "Description du panier sauvegardé",
  optional: "facultatif",
  charactersLeft: "Caractères restants : {{count}}",
  cancel: "Annuler",
  upload: "Charger",
  close: "Fermer",
  summary: {
    info: "Ne fermez pas ou n'actualisez pas cette fenêtre pendant l'importation des produits.",
    loadedToCart: "Les produits ont été chargés dans le panier {{ cartName }}",
    loaded: "Les produits ont été chargés",
    loading: "Les produits sont en cours de traitement... ({{ count }}/{{ total }})",
    successes: "{{ successesCount }} produits sur {{ total }} ont été importés avec succès.",
    warning: "{{ count }} produit n'a pas été importé en totalité.",
    warning_other: "{{ count }} produits n'ont pas été importés en totalité.",
    error: "{{ count }} produit n'a pas été importé.",
    error_other: "{{ count }} produits n'ont pas été importés.",
    messages: {
      unknownIdentifier: "L'UGS produit \"{{ productCode}}\" n'existe pas.",
      lowStock: "La quantité pour {{ productName }} : {{ quantity }} a été réduite à {{ quantityAdded }}.",
      noStock: "{{ productName }} est actuellement en rupture de stock.",
      unknownError: "Problème non reconnu avec \"{{ productCode }}\"",
      limitExceeded: "Impossible d'ajouter \"{{ productCode }}\". Limite dépassée."
    },
    show: "Afficher",
    hide: "Masquer"
  }
};
var importExport$b = {
  exportEntries: exportEntries$b,
  importEntries: importEntries$b,
  importEntriesDialog: importEntriesDialog$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  importExport: importExport$b
};
var exportEntries$a = {
  exportToCsv: "CSV में निर्यात करें",
  exportMessage: "CSV फ़ाइल स्वचालित रूप से आपके डिवाइस में डाउनलोड होगी, यदि नहीं होती तो कृपया अपनी ब्राउज़र सेटिंग जांचें",
  columnNames: {
    code: "कोड",
    quantity: "मात्रा",
    name: "नाम",
    price: "मूल्य"
  },
  exportProductToCsv: "CSV में उत्पाद निर्यात करें"
};
var importEntries$a = {
  importProducts: "उत्पाद आयात करें"
};
var importEntriesDialog$a = {
  importProducts: "उत्पाद आयात करें",
  importProductsSubtitle: ".CSV फ़ाइल आयात करके उत्पाद जोड़ें.",
  importProductsNewSavedCartSubtitle: ".CSV फ़ाइल आयात करके उत्पाद जोड़ें और नया सहेजा गया कार्ट बना रहे हैं.",
  importProductFileDetails: "टेक्स्ट फ़ाइल में अल्पविराम द्वारा अलग किए गए आवश्यक स्तंभों के साथ उत्पाद सूची होनी चाहिएः SKU और मात्रा",
  selectFile: "फाइल चुनें",
  selectedSuccessfully: "CSV फ़ाइल सफलतापूर्वक चुनी गई.",
  savedCartName: "सहेजी गई कार्ट का नाम",
  savedCartDescription: "सहेजी गई कार्ट का वर्णन",
  optional: "वैकल्पिक",
  charactersLeft: "वर्ण बचे हैंः  {{count}}",
  cancel: "रद्द करें",
  upload: "अपलोड करें",
  close: "बंद करें",
  summary: {
    info: "उत्पाद आयात हो रहा है इसलिए इस विंडो को बंद या रिफ़्रेश न करें.",
    loadedToCart: "उत्पाद कार्ट {{ cartName }} में लोड किया गया",
    loaded: "उत्पाद लोड किया गया",
    loading: "उत्पाद प्रोसेस हो रहा है...  ({{ count }}/{{ total }})",
    successes: "{{ total }} में से {{ successesCount }} उत्पाद सफलतापूर्वक आयात किए गए.",
    warning: "{{ count }} उत्पाद कभी भी आयात नहीं किए गए.",
    warning_other: "{{ count }} उत्पाद कभी भी आयात नहीं किए गए.",
    error: "{{ count }} उत्पाद आयात नहीं किए गए.",
    error_other: "{{ count }} उत्पाद आयात नहीं किए गए.",
    messages: {
      unknownIdentifier: "उत्पाद SKU \"{{ productCode}}\" मौजूद नहीं है.",
      lowStock: "{{ productName }}: {{ quantity }} के लिए मात्रा {{ quantityAdded }} तक कम की गई.",
      noStock: "{{ productName }} वर्तमान में स्टॉक में नहीं है.",
      unknownError: "\"{{ productCode }}\" के साथ अपरिचित समस्या.",
      limitExceeded: "\"{{ productCode }}\" जोड़ा नहीं जा सकता. सीमा पार हुई."
    },
    show: "दिखाएं",
    hide: "छिपाएं"
  }
};
var importExport$a = {
  exportEntries: exportEntries$a,
  importEntries: importEntries$a,
  importEntriesDialog: importEntriesDialog$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  importExport: importExport$a
};
var exportEntries$9 = {
  exportToCsv: "Exportálás CSV-formátumba",
  exportMessage: "A rendszer automatikusan letölti a CSV-fájlt az eszközére; ha ez nem történik meg, ellenőrizze a böngészője beállításait",
  columnNames: {
    code: "Kód",
    quantity: "Mennyiség",
    name: "Név",
    price: "Ár"
  },
  exportProductToCsv: "Termék exportálása CSV-be"
};
var importEntries$9 = {
  importProducts: "Termékek importálása"
};
var importEntriesDialog$9 = {
  importProducts: "Termékek importálása",
  importProductsSubtitle: "Termékek hozzáadása .CSV-fájl importálásával",
  importProductsNewSavedCartSubtitle: "Termékek hozzáadása .CSV-fájl importálásával és új mentett kosár létrehozásával",
  importProductFileDetails: "A szöveges fájlnak tartalmaznia kell a termékek listáját a vesszővel elválasztott kötelező oszlopokkal: cikkszám és mennyiség.",
  selectFile: "Fájl kiválasztása",
  selectedSuccessfully: "CSV-fájl sikeresen kiválasztva.",
  savedCartName: "Mentett kosár neve",
  savedCartDescription: "Mentett kosár leírása",
  optional: "opcionális",
  charactersLeft: "hátralévő karakterek: {{count}}",
  cancel: "Mégse",
  upload: "Feltöltés",
  close: "Bezárás",
  summary: {
    info: "Ne zárja be vagy frissítse ezt az ablakot, amíg folyamatban van a termékek importálása.",
    loadedToCart: "Termékek betöltve a következő kosárhoz: {{ cartName }}",
    loaded: "Termékek betöltve",
    loading: "Termékek feldolgozása folyamatban... ({{ count }}/{{ total }})",
    successes: "{{ successesCount }}/{{ total }} termék importálása sikeres.",
    warning: "{{ count }} terméket nem sikerült teljesen importálni.",
    warning_other: "{{ count }} terméket nem sikerült teljesen importálni.",
    error: "{{ count }} terméket nem sikerült importálni.",
    error_other: "{{ count }} terméket nem sikerült importálni.",
    messages: {
      unknownIdentifier: "A(z) „{{ productCode}}” cikkszám nem létezik.",
      lowStock: "A(z) {{ productName }}:{{ quantity }} mennyisége a következőre csökkent: {{ quantityAdded }}.",
      noStock: "A(z) {{ productName }} jelenleg nincs készleten.",
      unknownError: "Ismeretlen hiba a következővel: „{{ productCode }}”.",
      limitExceeded: "Nem lehet hozzáadni a következőt: „{{ productCode }}”. Korlát túllépve."
    },
    show: "Megjelenítés",
    hide: "Elrejtés"
  }
};
var importExport$9 = {
  exportEntries: exportEntries$9,
  importEntries: importEntries$9,
  importEntriesDialog: importEntriesDialog$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  importExport: importExport$9
};
var exportEntries$8 = {
  exportToCsv: "Ekspor ke CSV",
  exportMessage: "File CSV akan diunduh secara otomatis ke perangkat Anda. Jika tidak, silakan periksa pengaturan browser Anda",
  columnNames: {
    code: "Kode",
    quantity: "Jumlah",
    name: "Nama",
    price: "Harga"
  },
  exportProductToCsv: "Ekspor Produk ke CSV"
};
var importEntries$8 = {
  importProducts: "Impor Produk"
};
var importEntriesDialog$8 = {
  importProducts: "Impor Produk",
  importProductsSubtitle: "Tambahkan produk dengan mengimpor file .CSV.",
  importProductsNewSavedCartSubtitle: "Tambahkan produk dengan mengimpor file .CSV dan membuat keranjang tersimpan baru.",
  importProductFileDetails: "File teks harus memuat daftar produk dengan kolom yang diperlukan dan dipisahkan dengan koma: SKU dan jumlah.",
  selectFile: "Pilih File",
  selectedSuccessfully: "File CSV berhasil dipilih.",
  savedCartName: "Nama Keranjang Tersimpan",
  savedCartDescription: "Deskripsi Keranjang Tersimpan",
  optional: "opsional",
  charactersLeft: "karakter tersisa: {{count}}",
  cancel: "Batalkan",
  upload: "Unggah",
  close: "Tutup",
  summary: {
    info: "Mohon untuk tidak menutup atau menyegarkan jendela ini saat produk sedang diimpor.",
    loadedToCart: "Produk telah dimuat ke keranjang {{ cartName }}",
    loaded: "Produk telah dimuat",
    loading: "Produk sedang diproses... ({{ count }}/{{ total }})",
    successes: "{{ successesCount }} dari {{ total }} produk berhasil diimpor.",
    warning: "{{ count }} produk tidak terimpor sepenuhnya.",
    warning_other: "{{ count }} produk tidak terimpor sepenuhnya.",
    error: "{{ count }} produk tidak terimpor.",
    error_other: "{{ count }} produk tidak terimpor.",
    messages: {
      unknownIdentifier: "Produk SKU \"{{ productCode}}\" tidak ada.",
      lowStock: "Jumlah untuk {{ productName }}: {{ quantity }} telah dikurangi menjadi {{ quantityAdded }}.",
      noStock: "{{ productName }} saat ini telah habis.",
      unknownError: "Terdapat masalah pada \"{{ productCode }}\" yang tidak dikenali.",
      limitExceeded: "Tidak dapat menambahkan \"{{ productCode }}\". Batas maksimum terlampaui."
    },
    show: "Tampilkan",
    hide: "Sembunyikan"
  }
};
var importExport$8 = {
  exportEntries: exportEntries$8,
  importEntries: importEntries$8,
  importEntriesDialog: importEntriesDialog$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  importExport: importExport$8
};
var exportEntries$7 = {
  exportToCsv: "Esporta in formato CSV",
  exportMessage: "Il file CSV verrà scaricato automaticamente nel dispositivo; in caso contrario, verificare le impostazioni del browser.",
  columnNames: {
    code: "Codice",
    quantity: "Quantità",
    name: "Nome",
    price: "Prezzo"
  },
  exportProductToCsv: "Esporta prodotto in CSV"
};
var importEntries$7 = {
  importProducts: "Importa prodotti"
};
var importEntriesDialog$7 = {
  importProducts: "Importa prodotti",
  importProductsSubtitle: "Aggiungere prodotti tramite l'importazione di un file .CSV.",
  importProductsNewSavedCartSubtitle: "Aggiungere prodotti tramite l'importazione di un file .CSV e la creazione di un nuovo carrello acquisti salvato.",
  importProductFileDetails: "Il file di testo deve contenere una lista di prodotto con le colonne richieste separate da virgola: SKU e Quantità.",
  selectFile: "Seleziona file",
  selectedSuccessfully: "Il file CSV è stato selezionato correttamente.",
  savedCartName: "Nome carrello acquisti salvato",
  savedCartDescription: "Descrizione carrello acquisti salvata",
  optional: "facoltativa",
  charactersLeft: "caratteri rimanenti: {{count}}",
  cancel: "Annulla",
  upload: "Carica",
  close: "Chiudi",
  summary: {
    info: "Non chiudere o aggiornare questa finestra durante l'importazione dei prodotti.",
    loadedToCart: "Prodotti caricati nel carrello acquisti {{ cartName }}",
    loaded: "Prodotti caricati",
    loading: "Prodotti in fase di elaborazione... ({{ count }}/{{ total }})",
    successes: "{{ successesCount }} di {{ total }} prodotti importati correttamente.",
    warning: "{{ count }} prodotto non importato totalmente.",
    warning_other: "{{ count }} prodotti non importati totalmente.",
    error: "{{ count }} prodotto non importato.",
    error_other: "{{ count }} prodotti non importati.",
    messages: {
      unknownIdentifier: "SKU prodotto \"{{ productCode}}\" inesistente.",
      lowStock: "Quantità per {{ productName }}: {{ quantity }} ridotta a {{ quantityAdded }}.",
      noStock: "{{ productName }} attualmente non in stock.",
      unknownError: "Problema non identificato con \"{{ productCode }}\".",
      limitExceeded: "Impossibile aggiungere \"{{ productCode }}\". Limite superato."
    },
    show: "Mostra",
    hide: "Nascondi"
  }
};
var importExport$7 = {
  exportEntries: exportEntries$7,
  importEntries: importEntries$7,
  importEntriesDialog: importEntriesDialog$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  importExport: importExport$7
};
var exportEntries$6 = {
  exportToCsv: "CSV にエクスポート",
  exportMessage: "CSV ファイルはお使いのデバイスに自動的にダウンロードされます。ダウンロードされない場合は、ブラウザの設定を確認してください。",
  columnNames: {
    code: "コード",
    quantity: "数量",
    name: "名前",
    price: "価格"
  },
  exportProductToCsv: "商品を CSV にエクスポート"
};
var importEntries$6 = {
  importProducts: "商品のインポート"
};
var importEntriesDialog$6 = {
  importProducts: "商品のインポート",
  importProductsSubtitle: ".CSV ファイルをインポートして商品を追加",
  importProductsNewSavedCartSubtitle: ".CSV ファイルをインポートして商品を追加し、保存済みのカートを新しく作成します。",
  importProductFileDetails: "テキストファイルには必要な列 (SKU および数量) がカンマで区切られた商品のリストが含まれている必要があります。",
  selectFile: "ファイルの選択",
  selectedSuccessfully: "CSV ファイルが正常に選択されました。",
  savedCartName: "保存されたカート名",
  savedCartDescription: "保存されたカートの説明",
  optional: "任意",
  charactersLeft: "残り文字数: {{count}}",
  cancel: "キャンセル",
  upload: "アップロード",
  close: "閉じる",
  summary: {
    info: "商品のインポート中は、このウィンドウを閉じたりリフレッシュしたりしないでください。",
    loadedToCart: "商品がカート {{ cartName }} に読み込まれました",
    loaded: "商品が読み込まれました",
    loading: "商品の処理中です... ({{ count }}/{{ total }})",
    successes: "{{ total }} 個中 {{ successesCount }} 個の商品が正常にインポートされました。",
    warning: "合計 {{ count }} 個の商品がインポートされませんでした。",
    warning_other: "合計 {{ count }} 個の商品がインポートされませんでした。",
    error: "{{ count }} 個の商品がインポートされませんでした。",
    error_other: "{{ count }} 個の商品がインポートされませんでした。",
    messages: {
      unknownIdentifier: "商品 SKU \"{{ productCode}}\" が存在しません。",
      lowStock: "{{ productName }} の数量: {{ quantity }} は {{ quantityAdded }} に削減されました。",
      noStock: "{{ productName }} は現在在庫がありません。",
      unknownError: "\"{{ productCode }}\" の不明な問題です。",
      limitExceeded: "\"{{ productCode }}\" を追加できません。制限を超過しました。"
    },
    show: "表示",
    hide: "非表示"
  }
};
var importExport$6 = {
  exportEntries: exportEntries$6,
  importEntries: importEntries$6,
  importEntriesDialog: importEntriesDialog$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  importExport: importExport$6
};
var exportEntries$5 = {
  exportToCsv: "CSV로 내보내기",
  exportMessage: "CSV 파일이 장치로 자동으로 다운로드됩니다. 다운로드되지 않으면 브라우저 설정을 확인하십시오.",
  columnNames: {
    code: "코드",
    quantity: "수량",
    name: "이름",
    price: "가격"
  },
  exportProductToCsv: "제품을 CSV로 내보내기"
};
var importEntries$5 = {
  importProducts: "제품 가져오기"
};
var importEntriesDialog$5 = {
  importProducts: "제품 가져오기",
  importProductsSubtitle: ".CSV 파일을 가져와서 제품을 추가하십시오.",
  importProductsNewSavedCartSubtitle: ".CSV 파일을 가져오고 새로 저장된 장바구니를 만들어서 제품을 추가하십시오.",
  importProductFileDetails: "텍스트 파일에는 제품 목록이 포함되어야 합니다(SKU, 수량 등의 필수 열은 쉼표로 구분).",
  selectFile: "파일 선택",
  selectedSuccessfully: "CSV 파일이 선택되었습니다.",
  savedCartName: "저장된 장바구니 이름",
  savedCartDescription: "저장된 장바구니 설명",
  optional: "옵션",
  charactersLeft: "{{count}}자 남음",
  cancel: "취소",
  upload: "업로드",
  close: "닫기",
  summary: {
    info: "제품을 가져오는 중에는 이 창을 닫거나 새로 고치지 마십시오.",
    loadedToCart: "제품이 장바구니 {{ cartName }}(으)로 로드되었습니다.",
    loaded: "제품이 로드되었습니다.",
    loading: "제품 처리 중... ({{ count }}/{{ total }})",
    successes: "{{ total }}개 제품 중 {{ successesCount }}개를 가져왔습니다.",
    warning: "{{ count }}개 제품을 완전히 가져오지는 못했습니다.",
    warning_other: "{{ count }}개 제품을 완전히 가져오지는 못했습니다.",
    error: "{{ count }}개 제품을 가져오지 못했습니다.",
    error_other: "{{ count }}개 제품을 가져오지 못했습니다.",
    messages: {
      unknownIdentifier: "제품 SKU \"{{ productCode}}\"이(가) 없습니다.",
      lowStock: "{{ productName }} 수량: {{ quantity }}개에서 {{ quantityAdded }}개로 줄었습니다.",
      noStock: "{{ productName }}의 재고가 없습니다.",
      unknownError: "\"{{ productCode }}\"에 알 수 없는 문제가 있습니다.",
      limitExceeded: "\"{{ productCode }}\"을(를) 추가할 수 없습니다. 한도를 초과했습니다."
    },
    show: "표시",
    hide: "숨기기"
  }
};
var importExport$5 = {
  exportEntries: exportEntries$5,
  importEntries: importEntries$5,
  importEntriesDialog: importEntriesDialog$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  importExport: importExport$5
};
var exportEntries$4 = {
  exportToCsv: "Eksportuj do CSV",
  exportMessage: "Plik CSV zostanie automatycznie pobrany na urządzenie. Jeśli tak się nie stanie, sprawdź ustawienia przeglądarki.",
  columnNames: {
    code: "Kod",
    quantity: "Ilość",
    name: "Nazwa",
    price: "Cena"
  },
  exportProductToCsv: "Eksportuj produkt do pliku CSV"
};
var importEntries$4 = {
  importProducts: "Importuj produkty"
};
var importEntriesDialog$4 = {
  importProducts: "Importuj produkty",
  importProductsSubtitle: "Dodaj produkty, importując plik .CSV.",
  importProductsNewSavedCartSubtitle: "Dodaj produkty, importując plik .CSV i tworząc nowy zapisany koszyk.",
  importProductFileDetails: "Plik tekstowy powinien zawierać listę produktów z wymaganymi kolumnami oddzielonymi przecinkami: SKU i ilość.",
  selectFile: "Wybierz plik",
  selectedSuccessfully: "Plik CSV został wybrany.",
  savedCartName: "Zapisana nazwa koszyka",
  savedCartDescription: "Zapisany opis koszyka",
  optional: "opcjonalne",
  charactersLeft: "pozostało znaków: {{count}}",
  cancel: "Anuluj",
  upload: "Przekaż",
  close: "Zamknij",
  summary: {
    info: "Nie zamykaj ani nie odświeżaj tego okna podczas importowania produktów.",
    loadedToCart: "Produkty zostały wczytane do koszyka {{ cartName }}",
    loaded: "Produkty zostały wczytane",
    loading: "Trwa przetwarzanie produktów... ({{ count }}/{{ total }})",
    successes: "Zaimportowano {{ successesCount }} z {{ total }} produktów.",
    warning: "Nie zaimportowano całkowicie {{ count }} produktu.",
    warning_other: "Nie zaimportowano całkowicie {{ count }} produktów.",
    error: "Nie zaimportowano {{ count }} produktu.",
    error_other: "Nie zaimportowano {{ count }} produktów.",
    messages: {
      unknownIdentifier: "SKU produktu \"{{ productCode}}\" nie istnieje.",
      lowStock: "Ilość dla {{ productName }}: {{ quantity }} została zmniejszona do {{ quantityAdded }}.",
      noStock: "{{ productName }} jest obecnie niedostępny w magazynie.",
      unknownError: "Niezidentyfikowany problem z \"{{ productCode }}\".",
      limitExceeded: "Nie można dodać \"{{ productCode }}\". Przekroczono limit."
    },
    show: "Pokaż",
    hide: "Ukryj"
  }
};
var importExport$4 = {
  exportEntries: exportEntries$4,
  importEntries: importEntries$4,
  importEntriesDialog: importEntriesDialog$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  importExport: importExport$4
};
var exportEntries$3 = {
  exportToCsv: "Exportar para CSV",
  exportMessage: "O arquivo CSV será baixado automaticamente em seu dispositivo, caso contrário, verifique as configurações do seu navegador",
  columnNames: {
    code: "Código",
    quantity: "Quantidade",
    name: "Nome",
    price: "Preço"
  },
  exportProductToCsv: "Exportar produto para CSV"
};
var importEntries$3 = {
  importProducts: "Importar produtos"
};
var importEntriesDialog$3 = {
  importProducts: "Importar produtos",
  importProductsSubtitle: "Adicionar produtos importando um arquivo .CSV.",
  importProductsNewSavedCartSubtitle: "Adicionar produtos importando um arquivo .CSV e criando um novo carrinho gravado.",
  importProductFileDetails: "O arquivo de texto deve conter uma lista de produtos com colunas obrigatórias separadas por vírgula: SKU e quantidade.",
  selectFile: "Selecionar arquivo",
  selectedSuccessfully: "O arquivo CSV foi selecionado com êxito.",
  savedCartName: "Nome do carrinho gravado",
  savedCartDescription: "Descrição do carrinho gravado",
  optional: "opcional",
  charactersLeft: "caracteres restantes: {{count}}",
  cancel: "Cancelar",
  upload: "Carregar",
  close: "Fechar",
  summary: {
    info: "Não feche ou atualize essa janela enquanto os produtos estiverem sendo importados.",
    loadedToCart: "Os produtos foram carregados no carrinho {{ cartName }}",
    loaded: "Os produtos foram carregados",
    loading: "Os produtos estão sendo processados... ({{ count }}/{{ total }})",
    successes: "{{ successesCount }} de {{ total }} produtos foram importados com êxito.",
    warning: "{{ count }} produto não foi importado totalmente.",
    warning_other: "{{ count }} produtos não foram importados totalmente.",
    error: "{{ count }} produto não foi importado.",
    error_other: "{{ count }} produtos não foram importados.",
    messages: {
      unknownIdentifier: "A SKU do produto \"{{ productCode}}\" não existe.",
      lowStock: "A quantidade para {{ productName }}: {{ quantity }} foi reduzida para {{ quantityAdded }}.",
      noStock: "{{ productName }} está atualmente sem estoque.",
      unknownError: "Problema não reconhecido com \"{{ productCode }}\".",
      limitExceeded: "Não é possível adicionar \"{{ productCode }}\". Limite excedido."
    },
    show: "Mostrar",
    hide: "Ocultar"
  }
};
var importExport$3 = {
  exportEntries: exportEntries$3,
  importEntries: importEntries$3,
  importEntriesDialog: importEntriesDialog$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  importExport: importExport$3
};
var exportEntries$2 = {
  exportToCsv: "Экспорт в CSV",
  exportMessage: "Файл CSV автоматически загрузится на ваше устройство. Если этого не произошло, проверьте настройки браузера.",
  columnNames: {
    code: "Код",
    quantity: "Количество",
    name: "Имя",
    price: "Цена"
  },
  exportProductToCsv: "Экспорт продукта в CSV"
};
var importEntries$2 = {
  importProducts: "Импорт продуктов"
};
var importEntriesDialog$2 = {
  importProducts: "Импорт продуктов",
  importProductsSubtitle: "Добавьте продукты через импорт файла .CSV",
  importProductsNewSavedCartSubtitle: "Добавьте продукты через импорт файла .CSV и создайте новую сохраненную корзину.",
  importProductFileDetails: "Текстовый файл должен содержать список продуктов с обязательными столбцами, разделенными запятой: SKU и количество.",
  selectFile: "Выбрать файл",
  selectedSuccessfully: "Файл CSV выбран.",
  savedCartName: "Имя сохраненной корзины",
  savedCartDescription: "Описание сохраненной корзины",
  optional: "необязательно",
  charactersLeft: "осталось символов: {{count}}",
  cancel: "Отменить",
  upload: "Загрузить",
  close: "Закрыть",
  summary: {
    info: "Не закрывайте и не обновляйте это окно во время импорта продуктов.",
    loadedToCart: "Продукты загружены в корзину {{ cartName }}",
    loaded: "Продукты загружены",
    loading: "Продукты обрабатываются... ({{ count }}/{{ total }})",
    successes: "Импортировано продуктов: {{ successesCount }} из {{ total }}.",
    warning: "{{ count }} продукт не импортирован полностью.",
    warning_other: "{{ count }} прод. не импортировано полностью.",
    error: "{{ count }} продукт не импортирован.",
    error_other: "Не импортировано продуктов: {{ count }}.",
    messages: {
      unknownIdentifier: "SKU продукта \"{{ productCode}}\" не существует.",
      lowStock: "Количество для {{ productName }}: {{ quantity }} сокращено до {{ quantityAdded }}. ",
      noStock: "{{ productName }} сейчас не в наличии.",
      unknownError: "Неизвестная проблема с \"{{ productCode }}\".",
      limitExceeded: "Невозможно добавить \"{{ productCode }}\". Превышен лимит."
    },
    show: "Показать",
    hide: "Скрыть"
  }
};
var importExport$2 = {
  exportEntries: exportEntries$2,
  importEntries: importEntries$2,
  importEntriesDialog: importEntriesDialog$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  importExport: importExport$2
};
var exportEntries$1 = {
  exportToCsv: "导出为 CSV",
  exportMessage: "CSV 文件将自动下载到您的设备，如果没有，请检查您的浏览器设置",
  columnNames: {
    code: "代码",
    quantity: "数量",
    name: "名称",
    price: "价格"
  },
  exportProductToCsv: "将产品导出至 CSV"
};
var importEntries$1 = {
  importProducts: "导入产品"
};
var importEntriesDialog$1 = {
  importProducts: "导入产品",
  importProductsSubtitle: "通过导入 .CSV 文件添加产品。",
  importProductsNewSavedCartSubtitle: "通过导入 .CSV 文件并创建新的已保存购物车来添加产品。",
  importProductFileDetails: "文本文件应包含产品列表，其中所需列以逗号分隔：SKU 和数量。",
  selectFile: "选择文件",
  selectedSuccessfully: "已成功选择 CSV 文件。",
  savedCartName: "已保存购物车名称",
  savedCartDescription: "已保存购物车描述",
  optional: "可选",
  charactersLeft: "剩余字符：{{count}}",
  cancel: "取消",
  upload: "上载",
  close: "关闭",
  summary: {
    info: "在导入产品时请勿关闭或刷新此窗口。",
    loadedToCart: "产品已加载到购物车 {{ cartName }}",
    loaded: "产品已加载",
    loading: "产品正在处理... ({{ count }}/{{ total }})",
    successes: "已成功导入 {{ total }} 个产品中的 {{ successesCount }} 个。",
    warning: "{{ count }} 个产品未完全导入。",
    warning_other: "{{ count }} 个产品未完全导入。",
    error: "{{ count }} 个产品未导入。",
    error_other: "{{ count }} 个产品未导入。",
    messages: {
      unknownIdentifier: "产品 SKU“{{ productCode}}”不存在。",
      lowStock: "{{ productName }} 的数量：{{ quantity }} 已减少至 {{ quantityAdded }}。",
      noStock: "{{ productName }} 目前缺货。",
      unknownError: "“{{ productCode }}”存在无法识别的问题。",
      limitExceeded: "无法添加“{{ productCode }}”。超出限制。"
    },
    show: "显示",
    hide: "隐藏"
  }
};
var importExport$1 = {
  exportEntries: exportEntries$1,
  importEntries: importEntries$1,
  importEntriesDialog: importEntriesDialog$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  importExport: importExport$1
};
var exportEntries = {
  exportToCsv: "匯出至 CSV",
  exportMessage: "CSV 檔案將自動下載至您的裝置，若未下載，請檢查您的瀏覽器設定",
  columnNames: {
    code: "代碼",
    quantity: "數量",
    name: "名稱",
    price: "價格"
  },
  exportProductToCsv: "將產品匯出至 CSV"
};
var importEntries = {
  importProducts: "匯入產品"
};
var importEntriesDialog = {
  importProducts: "匯入產品",
  importProductsSubtitle: "匯入 .CSV 檔案以新增產品。",
  importProductsNewSavedCartSubtitle: "透過匯入 .CSV 檔案並建立新儲存的購物車，來新增產品。",
  importProductFileDetails: "文字檔案應包含產品清單，並以逗號分隔必要欄：SKU 和數量。",
  selectFile: "選擇檔案",
  selectedSuccessfully: "已成功選擇 CSV 檔案。",
  savedCartName: "儲存的購物車名稱",
  savedCartDescription: "儲存的購物車說明",
  optional: "選擇性",
  charactersLeft: "剩餘字元：{{count}}",
  cancel: "取消",
  upload: "上傳",
  close: "關閉",
  summary: {
    info: "匯入產品時，請不要關閉或重新整理此視窗。",
    loadedToCart: "已將產品載入至購物車 {{ cartName }}",
    loaded: "已載入產品",
    loading: "正在處理產品... ({{ count }}/{{ total }})",
    successes: "已成功匯入 {{ successesCount }} 個產品，總計 {{ total }} 個。",
    warning: "未完全匯入 {{ count }} 個產品。",
    warning_other: "未完全匯入 {{ count }} 個產品。",
    error: "未匯入 {{ count }} 個產品。",
    error_other: "未匯入 {{ count }} 個產品。",
    messages: {
      unknownIdentifier: "產品 SKU「{{ productCode}}」不存在。",
      lowStock: "{{ productName }} 的數量：{{ quantity }} 已減少為 {{ quantityAdded }}。",
      noStock: "{{ productName }} 目前缺貨。",
      unknownError: "「{{ productCode }}」發生無法識別的問題。",
      limitExceeded: "無法新增「{{ productCode }}」。已超過限制。"
    },
    show: "顯示",
    hide: "隱藏"
  }
};
var importExport = {
  exportEntries: exportEntries,
  importEntries: importEntries,
  importEntriesDialog: importEntriesDialog
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  importExport
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const importExportTranslationChunksConfig = {
  importExport: ['exportEntries', 'importEntries', 'importEntriesDialog']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: importExportTranslations
 *             +   resources: { en: importExportTranslationsEn }
 *               }
 *             ```
 */
const importExportTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { importExportTranslationChunksConfig, importExportTranslations, cs as importExportTranslationsCs, de as importExportTranslationsDe, en as importExportTranslationsEn, es as importExportTranslationsEs, es_CO as importExportTranslationsEs_CO, fr as importExportTranslationsFr, hi as importExportTranslationsHi, hu as importExportTranslationsHu, id as importExportTranslationsId, it as importExportTranslationsIt, ja as importExportTranslationsJa, ko as importExportTranslationsKo, pl as importExportTranslationsPl, pt as importExportTranslationsPt, ru as importExportTranslationsRu, zh as importExportTranslationsZh, zh_TW as importExportTranslationsZh_TW };
