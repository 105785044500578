var productImageZoomTrigger$g = {
  expand: "Rozšířit obrázek"
};
var productImageZoomDialog$g = {
  zoomedInImage: "Zvětšený obrázek",
  previousSlide: "Předchozí snímek",
  nextSlide: "Další snímek",
  close: "Zavřít"
};
var productImageZoom$g = {
  productImageZoomTrigger: productImageZoomTrigger$g,
  productImageZoomDialog: productImageZoomDialog$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  productImageZoom: productImageZoom$g
};
var productImageZoomTrigger$f = {
  expand: "Bild erweitern"
};
var productImageZoomDialog$f = {
  zoomedInImage: "Vergrößertes Bild",
  previousSlide: "Vorherige Folie",
  nextSlide: "Nächste Folie",
  close: "Schließen"
};
var productImageZoom$f = {
  productImageZoomTrigger: productImageZoomTrigger$f,
  productImageZoomDialog: productImageZoomDialog$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  productImageZoom: productImageZoom$f
};
var productImageZoomTrigger$e = {
  expand: "Expand image"
};
var productImageZoomDialog$e = {
  zoomedInImage: "Zoomed in image",
  previousSlide: "Previous slide",
  nextSlide: "Next slide",
  close: "Close"
};
var productImageZoom$e = {
  productImageZoomTrigger: productImageZoomTrigger$e,
  productImageZoomDialog: productImageZoomDialog$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  productImageZoom: productImageZoom$e
};
var productImageZoomTrigger$d = {
  expand: "Ampliar imagen"
};
var productImageZoomDialog$d = {
  zoomedInImage: "Imagen con acercamiento",
  previousSlide: "Diapositiva anterior",
  nextSlide: "Diapositiva siguiente",
  close: "Cerrar"
};
var productImageZoom$d = {
  productImageZoomTrigger: productImageZoomTrigger$d,
  productImageZoomDialog: productImageZoomDialog$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  productImageZoom: productImageZoom$d
};
var productImageZoomTrigger$c = {
  expand: "Ampliar imagen"
};
var productImageZoomDialog$c = {
  zoomedInImage: "Acercar imagen",
  previousSlide: "Diapositiva anterior",
  nextSlide: "Diapositiva siguiente",
  close: "Cerrar"
};
var productImageZoom$c = {
  productImageZoomTrigger: productImageZoomTrigger$c,
  productImageZoomDialog: productImageZoomDialog$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  productImageZoom: productImageZoom$c
};
var productImageZoomTrigger$b = {
  expand: "Agrandir l'image"
};
var productImageZoomDialog$b = {
  zoomedInImage: "Zoomé dans l'image",
  previousSlide: "Diapositive précédente",
  nextSlide: "Diapositive suivante",
  close: "Fermer"
};
var productImageZoom$b = {
  productImageZoomTrigger: productImageZoomTrigger$b,
  productImageZoomDialog: productImageZoomDialog$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  productImageZoom: productImageZoom$b
};
var productImageZoomTrigger$a = {
  expand: "छवि विस्तृत करें"
};
var productImageZoomDialog$a = {
  zoomedInImage: "छवि ज़ूम इन करें",
  previousSlide: "पिछली स्लाइड",
  nextSlide: "अगली स्लाइड",
  close: "बंद करें"
};
var productImageZoom$a = {
  productImageZoomTrigger: productImageZoomTrigger$a,
  productImageZoomDialog: productImageZoomDialog$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  productImageZoom: productImageZoom$a
};
var productImageZoomTrigger$9 = {
  expand: "Kép kibontása"
};
var productImageZoomDialog$9 = {
  zoomedInImage: "Felnagyított kép",
  previousSlide: "Előző dia",
  nextSlide: "Következő dia",
  close: "Bezárás"
};
var productImageZoom$9 = {
  productImageZoomTrigger: productImageZoomTrigger$9,
  productImageZoomDialog: productImageZoomDialog$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  productImageZoom: productImageZoom$9
};
var productImageZoomTrigger$8 = {
  expand: "Perluas gambar"
};
var productImageZoomDialog$8 = {
  zoomedInImage: "Gambar yang diperbesar",
  previousSlide: "Slide sebelumnya",
  nextSlide: "Slide berikutnya",
  close: "Tutup"
};
var productImageZoom$8 = {
  productImageZoomTrigger: productImageZoomTrigger$8,
  productImageZoomDialog: productImageZoomDialog$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  productImageZoom: productImageZoom$8
};
var productImageZoomTrigger$7 = {
  expand: "Espandi immagine"
};
var productImageZoomDialog$7 = {
  zoomedInImage: "Immagine ingrandita",
  previousSlide: "Diapositiva precedente",
  nextSlide: "Diapositiva successiva",
  close: "Chiudi"
};
var productImageZoom$7 = {
  productImageZoomTrigger: productImageZoomTrigger$7,
  productImageZoomDialog: productImageZoomDialog$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  productImageZoom: productImageZoom$7
};
var productImageZoomTrigger$6 = {
  expand: "画像を拡大"
};
var productImageZoomDialog$6 = {
  zoomedInImage: "画像にズームイン",
  previousSlide: "前のスライド",
  nextSlide: "次のスライド",
  close: "閉じる"
};
var productImageZoom$6 = {
  productImageZoomTrigger: productImageZoomTrigger$6,
  productImageZoomDialog: productImageZoomDialog$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  productImageZoom: productImageZoom$6
};
var productImageZoomTrigger$5 = {
  expand: "이미지 확대"
};
var productImageZoomDialog$5 = {
  zoomedInImage: "확대된 이미지",
  previousSlide: "이전 슬라이드",
  nextSlide: "다음 슬라이드",
  close: "닫기"
};
var productImageZoom$5 = {
  productImageZoomTrigger: productImageZoomTrigger$5,
  productImageZoomDialog: productImageZoomDialog$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  productImageZoom: productImageZoom$5
};
var productImageZoomTrigger$4 = {
  expand: "Rozwiń zdjęcie"
};
var productImageZoomDialog$4 = {
  zoomedInImage: "Powiększone zdjęcie",
  previousSlide: "Poprzedni slajd",
  nextSlide: "Następny slajd",
  close: "Zamknij"
};
var productImageZoom$4 = {
  productImageZoomTrigger: productImageZoomTrigger$4,
  productImageZoomDialog: productImageZoomDialog$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  productImageZoom: productImageZoom$4
};
var productImageZoomTrigger$3 = {
  expand: "Expandir imagem"
};
var productImageZoomDialog$3 = {
  zoomedInImage: "Imagem ampliada",
  previousSlide: "Slide anterior",
  nextSlide: "Slide seguinte",
  close: "Fechar"
};
var productImageZoom$3 = {
  productImageZoomTrigger: productImageZoomTrigger$3,
  productImageZoomDialog: productImageZoomDialog$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  productImageZoom: productImageZoom$3
};
var productImageZoomTrigger$2 = {
  expand: "Развернуть изображение"
};
var productImageZoomDialog$2 = {
  zoomedInImage: "Увеличенное изображение",
  previousSlide: "Предыдущий слайд",
  nextSlide: "Следующий слайд",
  close: "Закрыть"
};
var productImageZoom$2 = {
  productImageZoomTrigger: productImageZoomTrigger$2,
  productImageZoomDialog: productImageZoomDialog$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  productImageZoom: productImageZoom$2
};
var productImageZoomTrigger$1 = {
  expand: "展开图像"
};
var productImageZoomDialog$1 = {
  zoomedInImage: "已放大图像",
  previousSlide: "上一张幻灯片",
  nextSlide: "下一张幻灯片",
  close: "关闭"
};
var productImageZoom$1 = {
  productImageZoomTrigger: productImageZoomTrigger$1,
  productImageZoomDialog: productImageZoomDialog$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  productImageZoom: productImageZoom$1
};
var productImageZoomTrigger = {
  expand: "展開圖像"
};
var productImageZoomDialog = {
  zoomedInImage: "放大圖像",
  previousSlide: "上一張投影片",
  nextSlide: "下一張投影片",
  close: "關閉"
};
var productImageZoom = {
  productImageZoomTrigger: productImageZoomTrigger,
  productImageZoomDialog: productImageZoomDialog
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  productImageZoom
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const productImageZoomTranslationChunksConfig = {
  productImageZoom: ['productImageZoomTrigger', 'productImageZoomDialog']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: productImageZoomTranslations
 *             +   resources: { en: productImageZoomTranslationsEn }
 *               }
 *             ```
 */
const productImageZoomTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { productImageZoomTranslationChunksConfig, productImageZoomTranslations, cs as productImageZoomTranslationsCs, de as productImageZoomTranslationsDe, en as productImageZoomTranslationsEn, es as productImageZoomTranslationsEs, es_CO as productImageZoomTranslationsEs_CO, fr as productImageZoomTranslationsFr, hi as productImageZoomTranslationsHi, hu as productImageZoomTranslationsHu, id as productImageZoomTranslationsId, it as productImageZoomTranslationsIt, ja as productImageZoomTranslationsJa, ko as productImageZoomTranslationsKo, pl as productImageZoomTranslationsPl, pt as productImageZoomTranslationsPt, ru as productImageZoomTranslationsRu, zh as productImageZoomTranslationsZh, zh_TW as productImageZoomTranslationsZh_TW };
