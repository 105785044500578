var pickupInfo$g = {
  inStorePickup: "Vyzvednutí v prodejně",
  pickupBy: "Vyzvedne",
  pickupFrom: "Místo vyzvednutí:"
};
var pickupOptionDialog$g = {
  close: "Zavřít",
  modalHeader: "Vyzvednutí v prodejně"
};
var pickupOptions$g = {
  legend: "Způsob dodání",
  changeStore: "Změnit prodejnu",
  delivery: "Doručit (vrácení zdarma)",
  pickup: "Bezplatné vyzvednutí v prodejně",
  shipIt: "Doručit",
  selectStore: "Vybrat prodejnu",
  freeReturn: "Vrácení zdarma",
  storeIsNotSelected: "Nebylo vybráno vyzvednutí v prodejně."
};
var setPreferredStore$g = {
  makeThisMyStore: "Nastavit jako Moji prodejnu",
  myStore: "Moje prodejna"
};
var store$g = {
  pickupFromHere: "Vyzvednout zde",
  stockLevel_inStock: "{{ count }} skladem",
  stockLevel_outOfStock: "Není skladem",
  viewHours: "Zobrazit hodiny"
};
var storeList$g = {
  noStoresMessage: "V databázi nebyly nalezeny žádné prodejny…"
};
var storeSchedule$g = {
  closed: "Zavřeno",
  storeHours: "Otevírací doba prodejny"
};
var storeSearch$g = {
  findAStore: "Najít prodejnu",
  findStores: "Najít prodejnu",
  hideOutOfStockOptions: "Skrýt možnosti, které nejsou skladem.",
  searchPlaceholder: "Zadejte PSČ nebo město nebo adresu",
  useMyLocation: "Použít mou polohu"
};
var addressBookPickupInStore$g = {
  heading: "Prodejna pro vyzvednutí"
};
var storeFinderPickupInStore$g = {
  heading: "Moje prodejna"
};
var checkoutPickupInStore$g = {
  heading: "Položky, které mají být vyzvednuty",
  storeItemHeading: "Adresa prodejny pro vyzvednutí"
};
var cardActions$g = {
  getDirections: "Získat popis cesty – tento odkaz otevře nové okno"
};
var deliveryPointOfServiceDetails$g = {
  itemsToBePickUp: "Položky, které mají být vyzvednuty",
  pickUpInStoreAddress: "Adresa prodejny pro vyzvednutí",
  itemsToBeShipped: "Položky k odeslání",
  shippingAddress: "Dodací adresa",
  deliveryMethod: "Způsob dodání",
  deliveryOptions: "Možnosti dodání",
  statusDisplay_cancelled: "Zrušeno",
  statusDisplay_cancelling: "Probíhá zrušení",
  statusDisplay_completed: "Dokončeno",
  statusDisplay_created: "Vytvořeno",
  statusDisplay_error: "Čeká",
  statusDisplay_Error: "Čeká",
  statusDisplay_processing: "Čeká",
  statusDisplay_open: "Otevřeno",
  statusDisplay_pending: {
    approval: "Čeká na schválení",
    merchant: {
      approval: "Čeká na schválení obchodníka"
    }
  },
  statusDisplay_approved: "Schváleno",
  statusDisplay_rejected: "Zamítnuto",
  statusDisplay_merchant: {
    approved: "Obchodník byl schválen",
    rejected: "Obchodník byl zamítnut"
  },
  statusDisplay_assigned: {
    admin: "Přiřazeno správci"
  }
};
var pickupInStore$g = {
  pickupInfo: pickupInfo$g,
  pickupOptionDialog: pickupOptionDialog$g,
  pickupOptions: pickupOptions$g,
  setPreferredStore: setPreferredStore$g,
  store: store$g,
  storeList: storeList$g,
  storeSchedule: storeSchedule$g,
  storeSearch: storeSearch$g,
  addressBookPickupInStore: addressBookPickupInStore$g,
  storeFinderPickupInStore: storeFinderPickupInStore$g,
  checkoutPickupInStore: checkoutPickupInStore$g,
  cardActions: cardActions$g,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  pickupInStore: pickupInStore$g
};
var pickupInfo$f = {
  inStorePickup: "In Filiale abholen",
  pickupBy: "Abholen durch",
  pickupFrom: "Abholen von"
};
var pickupOptionDialog$f = {
  close: "Schließen",
  modalHeader: "In Filiale abholen"
};
var pickupOptions$f = {
  legend: "Lieferart",
  changeStore: "Filiale ändern",
  delivery: "Liefern (kostenlose Rücksendung)",
  pickup: "Kostenlos in Filiale abholen",
  shipIt: "Liefern",
  selectStore: "Filiale auswählen",
  freeReturn: "Kostenlose Rücksendung",
  storeIsNotSelected: "Keine Filiale für Abholung ausgewählt."
};
var setPreferredStore$f = {
  makeThisMyStore: "Als meine Filiale festlegen",
  myStore: "Meine Filiale"
};
var store$f = {
  pickupFromHere: "Hier abholen",
  stockLevel_inStock: "{{ count }} vorrätig",
  stockLevel_outOfStock: "Nicht vorrätig",
  viewHours: "Öffnungszeiten anzeigen"
};
var storeList$f = {
  noStoresMessage: "Keine Filialen in Datenbank gefunden..."
};
var storeSchedule$f = {
  closed: "Geschlossen",
  storeHours: "Öffnungszeiten"
};
var storeSearch$f = {
  findAStore: "Filiale suchen",
  findStores: "Filialen suchen",
  hideOutOfStockOptions: "Optionen für nicht vorrätig ausblenden",
  searchPlaceholder: "Postleitzahl oder Stadt oder Adresse eingeben",
  useMyLocation: "Meinen Standort verwenden"
};
var addressBookPickupInStore$f = {
  heading: "Filiale für Abholung"
};
var storeFinderPickupInStore$f = {
  heading: "Meine Filiale"
};
var checkoutPickupInStore$f = {
  heading: "Abzuholende Positionen",
  storeItemHeading: "Adresse der Filiale für Abholung"
};
var cardActions$f = {
  getDirections: "Wegbeschreibung abrufen, dieser Link öffnet sich in einem neuen Fenster"
};
var deliveryPointOfServiceDetails$f = {
  itemsToBePickUp: "Abzuholende Positionen",
  pickUpInStoreAddress: "Adresse der Filiale für Abholung",
  itemsToBeShipped: "Zu versendende Positionen",
  shippingAddress: "Lieferadresse",
  deliveryMethod: "Lieferart",
  deliveryOptions: "Lieferoptionen",
  statusDisplay_cancelled: "Storniert",
  statusDisplay_cancelling: "Stornierung ausstehend",
  statusDisplay_completed: "Abgeschlossen",
  statusDisplay_created: "Erstellt",
  statusDisplay_error: "Ausstehend",
  statusDisplay_Error: "Ausstehend",
  statusDisplay_processing: "Ausstehend",
  statusDisplay_open: "Offen",
  statusDisplay_pending: {
    approval: "Genehmigung ausstehend",
    merchant: {
      approval: "Genehmigung durch Händler ausstehend"
    }
  },
  statusDisplay_approved: "Genehmigt",
  statusDisplay_rejected: "Abgelehnt",
  statusDisplay_merchant: {
    approved: "Vom Händler genehmigt",
    rejected: "Vom Händler abgelehnt"
  },
  statusDisplay_assigned: {
    admin: "Dem Administrator zugeordnet"
  }
};
var pickupInStore$f = {
  pickupInfo: pickupInfo$f,
  pickupOptionDialog: pickupOptionDialog$f,
  pickupOptions: pickupOptions$f,
  setPreferredStore: setPreferredStore$f,
  store: store$f,
  storeList: storeList$f,
  storeSchedule: storeSchedule$f,
  storeSearch: storeSearch$f,
  addressBookPickupInStore: addressBookPickupInStore$f,
  storeFinderPickupInStore: storeFinderPickupInStore$f,
  checkoutPickupInStore: checkoutPickupInStore$f,
  cardActions: cardActions$f,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  pickupInStore: pickupInStore$f
};
var pickupInfo$e = {
  inStorePickup: "In Store Pickup",
  pickupBy: "Pick up by",
  pickupFrom: "Pick up from"
};
var pickupOptionDialog$e = {
  close: "Close",
  modalHeader: "Pickup in Store"
};
var pickupOptions$e = {
  legend: "Delivery Method",
  changeStore: "Change Store",
  delivery: "Ship It (Free Return)",
  pickup: "Free Pickup In Store",
  shipIt: "Ship It",
  selectStore: "Select Store",
  freeReturn: "Free Return",
  storeIsNotSelected: "Store for pickup is not selected."
};
var setPreferredStore$e = {
  makeThisMyStore: "Make This My Store",
  myStore: "My Store"
};
var store$e = {
  pickupFromHere: "Pick Up from here",
  stockLevel_inStock: "{{ count }} in Stock",
  stockLevel_outOfStock: "Out of Stock",
  viewHours: "View Hours"
};
var storeList$e = {
  noStoresMessage: "No stores found in database..."
};
var storeSchedule$e = {
  closed: "Closed",
  storeHours: "Store hours"
};
var storeSearch$e = {
  findAStore: "Find a Store",
  findStores: "Find Stores",
  hideOutOfStockOptions: "Hide out of stock options",
  searchPlaceholder: "Enter Zip Code or Town or Address",
  useMyLocation: "Use my location"
};
var addressBookPickupInStore$e = {
  heading: "Pick-Up Store"
};
var storeFinderPickupInStore$e = {
  heading: "My Store"
};
var checkoutPickupInStore$e = {
  heading: "Items to be Picked Up",
  storeItemHeading: "Pick Up Store Address"
};
var cardActions$e = {
  getDirections: "Get Directions, this link opens in a new window"
};
var deliveryPointOfServiceDetails$e = {
  itemsToBePickUp: "Items To Be Pick-Up",
  pickUpInStoreAddress: "Pick Up Store Address",
  itemsToBeShipped: "Items To Be Shipped",
  shippingAddress: "Shipping Address",
  deliveryMethod: "Delivery Method",
  deliveryOptions: "Delivery Options",
  statusDisplay_cancelled: "Cancelled",
  statusDisplay_cancelling: "Cancel Pending",
  statusDisplay_completed: "Completed",
  statusDisplay_created: "Created",
  statusDisplay_error: "Pending",
  statusDisplay_Error: "Pending",
  statusDisplay_processing: "Pending",
  statusDisplay_open: "Open",
  statusDisplay_pending: {
    approval: "Pending Approval",
    merchant: {
      approval: "Pending Merchant Approval"
    }
  },
  statusDisplay_approved: "Approved",
  statusDisplay_rejected: "Rejected",
  statusDisplay_merchant: {
    approved: "Merchant Approved",
    rejected: "Merchant Rejected"
  },
  statusDisplay_assigned: {
    admin: "Assigned To Administrator"
  }
};
var pickupInStore$e = {
  pickupInfo: pickupInfo$e,
  pickupOptionDialog: pickupOptionDialog$e,
  pickupOptions: pickupOptions$e,
  setPreferredStore: setPreferredStore$e,
  store: store$e,
  storeList: storeList$e,
  storeSchedule: storeSchedule$e,
  storeSearch: storeSearch$e,
  addressBookPickupInStore: addressBookPickupInStore$e,
  storeFinderPickupInStore: storeFinderPickupInStore$e,
  checkoutPickupInStore: checkoutPickupInStore$e,
  cardActions: cardActions$e,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  pickupInStore: pickupInStore$e
};
var pickupInfo$d = {
  inStorePickup: "Recogida en la tienda",
  pickupBy: "Recogida a cargo de",
  pickupFrom: "Recoger de"
};
var pickupOptionDialog$d = {
  close: "Cerrar",
  modalHeader: "Recogida en la tienda"
};
var pickupOptions$d = {
  legend: "Método de entrega",
  changeStore: "Cambiar tienda",
  delivery: "Enviar (devolución gratuita)",
  pickup: "Recogida en la tienda gratuita",
  shipIt: "Enviar",
  selectStore: "Seleccionar tienda",
  freeReturn: "Devolución gratuita",
  storeIsNotSelected: "No se seleccionó una tienda para la recogida."
};
var setPreferredStore$d = {
  makeThisMyStore: "Marcar como mi tienda",
  myStore: "Mi tienda"
};
var store$d = {
  pickupFromHere: "Recoger aquí",
  stockLevel_inStock: "{{ count }} en stock",
  stockLevel_outOfStock: "No disponible en stock",
  viewHours: "Ver horas"
};
var storeList$d = {
  noStoresMessage: "No existen tiendas en la base de datos..."
};
var storeSchedule$d = {
  closed: "Cerrada",
  storeHours: "Horarios de la tienda"
};
var storeSearch$d = {
  findAStore: "Buscar una tienda",
  findStores: "Buscar tiendas",
  hideOutOfStockOptions: "Ocultar opciones no disponibles en stock",
  searchPlaceholder: "Introducir código postal, ciudad o dirección",
  useMyLocation: "Utilizar mi ubicación"
};
var addressBookPickupInStore$d = {
  heading: "Tienda de recogida"
};
var storeFinderPickupInStore$d = {
  heading: "Mi tienda"
};
var checkoutPickupInStore$d = {
  heading: "Artículos para recoger",
  storeItemHeading: "Dirección de la tienda de recogida"
};
var cardActions$d = {
  getDirections: "Obtener direcciones, este enlace se abre en una ventana nueva"
};
var deliveryPointOfServiceDetails$d = {
  itemsToBePickUp: "Artículos para recoger",
  pickUpInStoreAddress: "Dirección de la tienda de recogida",
  itemsToBeShipped: "Artículos para enviar",
  shippingAddress: "Dirección de envío",
  deliveryMethod: "Método de entrega",
  deliveryOptions: "Opciones de entrega",
  statusDisplay_cancelled: "Cancelado",
  statusDisplay_cancelling: "Pendiente de cancelación",
  statusDisplay_completed: "Completado",
  statusDisplay_created: "Creado",
  statusDisplay_error: "Pendiente",
  statusDisplay_Error: "Pendiente",
  statusDisplay_processing: "Pendiente",
  statusDisplay_open: "Abierto",
  statusDisplay_pending: {
    approval: "Pendiente de aprobación",
    merchant: {
      approval: "Pendiente de la aprobación del comerciante"
    }
  },
  statusDisplay_approved: "Aprobado",
  statusDisplay_rejected: "Rechazado",
  statusDisplay_merchant: {
    approved: "Aprobado por el comerciante",
    rejected: "Rechazado por el comerciante"
  },
  statusDisplay_assigned: {
    admin: "Asignado al administrador"
  }
};
var pickupInStore$d = {
  pickupInfo: pickupInfo$d,
  pickupOptionDialog: pickupOptionDialog$d,
  pickupOptions: pickupOptions$d,
  setPreferredStore: setPreferredStore$d,
  store: store$d,
  storeList: storeList$d,
  storeSchedule: storeSchedule$d,
  storeSearch: storeSearch$d,
  addressBookPickupInStore: addressBookPickupInStore$d,
  storeFinderPickupInStore: storeFinderPickupInStore$d,
  checkoutPickupInStore: checkoutPickupInStore$d,
  cardActions: cardActions$d,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  pickupInStore: pickupInStore$d
};
var pickupInfo$c = {
  inStorePickup: "Recogida en la tienda",
  pickupBy: "Recogida a cargo de",
  pickupFrom: "Recoger de"
};
var pickupOptionDialog$c = {
  close: "Cerrar",
  modalHeader: "Recogida en la tienda"
};
var pickupOptions$c = {
  legend: "Método de entrega",
  changeStore: "Cambiar tienda",
  delivery: "Enviar (devolución gratuita)",
  pickup: "Recogida en la tienda gratuita",
  shipIt: "Enviar",
  selectStore: "Seleccionar tienda",
  freeReturn: "Devolución gratuita",
  storeIsNotSelected: "No se seleccionó una tienda para la recogida."
};
var setPreferredStore$c = {
  makeThisMyStore: "Marcar como mi tienda",
  myStore: "Mi tienda"
};
var store$c = {
  pickupFromHere: "Recoger aquí",
  stockLevel_inStock: "{{ count }} en stock",
  stockLevel_outOfStock: "No disponibles en stock",
  viewHours: "Ver horas"
};
var storeList$c = {
  noStoresMessage: "No existen tiendas en la base de datos..."
};
var storeSchedule$c = {
  closed: "Cerrada",
  storeHours: "Horarios de la tienda"
};
var storeSearch$c = {
  findAStore: "Buscar una tienda",
  findStores: "Buscar tiendas",
  hideOutOfStockOptions: "Ocultar opciones no disponibles en stock",
  searchPlaceholder: "Ingresar código postal, ciudad o dirección",
  useMyLocation: "Utilizar mi ubicación"
};
var addressBookPickupInStore$c = {
  heading: "Tienda de recogida"
};
var storeFinderPickupInStore$c = {
  heading: "Mi tienda"
};
var checkoutPickupInStore$c = {
  heading: "Artículos para recoger",
  storeItemHeading: "Dirección de la tienda de recogida"
};
var cardActions$c = {
  getDirections: "Obtener direcciones, este enlace se abre en una ventana nueva"
};
var deliveryPointOfServiceDetails$c = {
  itemsToBePickUp: "Artículos para recoger",
  pickUpInStoreAddress: "Dirección de la tienda de recogida",
  itemsToBeShipped: "Artículos para enviar",
  shippingAddress: "Dirección de envío",
  deliveryMethod: "Método de entrega",
  deliveryOptions: "Opciones de entrega",
  statusDisplay_cancelled: "Cancelado",
  statusDisplay_cancelling: "Pendiente de cancelación",
  statusDisplay_completed: "Completado",
  statusDisplay_created: "Creado",
  statusDisplay_error: "Pendiente",
  statusDisplay_Error: "Pendiente",
  statusDisplay_processing: "Pendiente",
  statusDisplay_open: "Abierto",
  statusDisplay_pending: {
    approval: "Pendiente de aprobación",
    merchant: {
      approval: "Pendiente de la aprobación del comerciante"
    }
  },
  statusDisplay_approved: "Aprobado",
  statusDisplay_rejected: "Rechazado",
  statusDisplay_merchant: {
    approved: "Aprobado por el comerciante",
    rejected: "Rechazado por el comerciante"
  },
  statusDisplay_assigned: {
    admin: "Asignado al administrador"
  }
};
var pickupInStore$c = {
  pickupInfo: pickupInfo$c,
  pickupOptionDialog: pickupOptionDialog$c,
  pickupOptions: pickupOptions$c,
  setPreferredStore: setPreferredStore$c,
  store: store$c,
  storeList: storeList$c,
  storeSchedule: storeSchedule$c,
  storeSearch: storeSearch$c,
  addressBookPickupInStore: addressBookPickupInStore$c,
  storeFinderPickupInStore: storeFinderPickupInStore$c,
  checkoutPickupInStore: checkoutPickupInStore$c,
  cardActions: cardActions$c,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  pickupInStore: pickupInStore$c
};
var pickupInfo$b = {
  inStorePickup: "Retrait au point de vente",
  pickupBy: "Retrait par",
  pickupFrom: "Retrait de"
};
var pickupOptionDialog$b = {
  close: "Fermer",
  modalHeader: "Retrait au point de vente"
};
var pickupOptions$b = {
  legend: "Mode de livraison",
  changeStore: "Modifier le point de vente",
  delivery: "Expédition (retour gratuit)",
  pickup: "Retrait au point de vente gratuit",
  shipIt: "Expédition",
  selectStore: "Sélectionner le point de vente",
  freeReturn: "Retour gratuit",
  storeIsNotSelected: "Le point de vente pour le retrait n'est pas sélectionné."
};
var setPreferredStore$b = {
  makeThisMyStore: "Le définir comme Mon point de vente",
  myStore: "Mon point de vente"
};
var store$b = {
  pickupFromHere: "Retirer ici",
  stockLevel_inStock: "{{ count }} en stock",
  stockLevel_outOfStock: "Épuisé",
  viewHours: "Afficher les horaires"
};
var storeList$b = {
  noStoresMessage: "Aucun point de vente trouvé dans la base de données..."
};
var storeSchedule$b = {
  closed: "Fermé",
  storeHours: "Horaires du point de vente"
};
var storeSearch$b = {
  findAStore: "Rechercher un point de vente",
  findStores: "Rechercher des points de vente",
  hideOutOfStockOptions: "Masquer les options avec rupture de stock",
  searchPlaceholder: "Saisissez le code postal, la localité ou l'adresse",
  useMyLocation: "Utiliser ma localisation"
};
var addressBookPickupInStore$b = {
  heading: "Point de vente pour le retrait"
};
var storeFinderPickupInStore$b = {
  heading: "Mon point de vente"
};
var checkoutPickupInStore$b = {
  heading: "Articles à retirer",
  storeItemHeading: "Adresse du point de vente pour le retrait"
};
var cardActions$b = {
  getDirections: "Obtenir un itinéraire, ce lien s'ouvre dans une nouvelle fenêtre"
};
var deliveryPointOfServiceDetails$b = {
  itemsToBePickUp: "Articles à retirer",
  pickUpInStoreAddress: "Adresse du point de vente pour le retrait",
  itemsToBeShipped: "Articles à expédier",
  shippingAddress: "Adresse d'expédition",
  deliveryMethod: "Mode de livraison",
  deliveryOptions: "Options de livraison",
  statusDisplay_cancelled: "Annulé",
  statusDisplay_cancelling: "Annulation en attente",
  statusDisplay_completed: "Terminé",
  statusDisplay_created: "Créé",
  statusDisplay_error: "En attente",
  statusDisplay_Error: "En attente",
  statusDisplay_processing: "En attente",
  statusDisplay_open: "En cours",
  statusDisplay_pending: {
    approval: "En attente d'approbation",
    merchant: {
      approval: "En attente de l'approbation du commerçant"
    }
  },
  statusDisplay_approved: "Approuvé",
  statusDisplay_rejected: "Rejeté",
  statusDisplay_merchant: {
    approved: "Approuvé par le commerçant",
    rejected: "Rejeté par le commerçant"
  },
  statusDisplay_assigned: {
    admin: "Affecté à l'administrateur"
  }
};
var pickupInStore$b = {
  pickupInfo: pickupInfo$b,
  pickupOptionDialog: pickupOptionDialog$b,
  pickupOptions: pickupOptions$b,
  setPreferredStore: setPreferredStore$b,
  store: store$b,
  storeList: storeList$b,
  storeSchedule: storeSchedule$b,
  storeSearch: storeSearch$b,
  addressBookPickupInStore: addressBookPickupInStore$b,
  storeFinderPickupInStore: storeFinderPickupInStore$b,
  checkoutPickupInStore: checkoutPickupInStore$b,
  cardActions: cardActions$b,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  pickupInStore: pickupInStore$b
};
var pickupInfo$a = {
  inStorePickup: "स्टोर पिकअप",
  pickupBy: "पिकअप करने वाला",
  pickupFrom: "इससे पिकअप करें"
};
var pickupOptionDialog$a = {
  close: "बंद करें",
  modalHeader: "स्टोर से प्राप्त करें"
};
var pickupOptions$a = {
  legend: "वितरण विधि",
  changeStore: "स्टोर बदलें",
  delivery: "इसे शिप करें (मुफ़्त वापसी)",
  pickup: "स्टोर से मुफ़्त प्राप्त करें",
  shipIt: "इसे शिप करें",
  selectStore: "स्टोर चुनें",
  freeReturn: "मुफ़्त वापसी",
  storeIsNotSelected: "पिकअप के लिए स्टोर नहीं चुना गया."
};
var setPreferredStore$a = {
  makeThisMyStore: "इसे मेरा स्टोर बनाएं",
  myStore: "मेरा स्टोर"
};
var store$a = {
  pickupFromHere: "यहां से पिक अप करें",
  stockLevel_inStock: "{{ count }} स्टॉक में है",
  stockLevel_outOfStock: "स्टॉक में नहीं है",
  viewHours: "घंटे देखें"
};
var storeList$a = {
  noStoresMessage: "डेटाबेस में कोई स्टोर नहीं मिला..."
};
var storeSchedule$a = {
  closed: "बंद है",
  storeHours: "स्टोर का समय"
};
var storeSearch$a = {
  findAStore: "स्टोर खोजें ",
  findStores: "स्टोर खोजें",
  hideOutOfStockOptions: "समाप्त स्टॉक के विकल्प छुपाएं",
  searchPlaceholder: "ज़िप कोड या शहर या पता दर्ज करें",
  useMyLocation: "मेरे स्थान का उपयोग करें"
};
var addressBookPickupInStore$a = {
  heading: "पिक-अप स्टोर"
};
var storeFinderPickupInStore$a = {
  heading: "मेरा स्टोर"
};
var checkoutPickupInStore$a = {
  heading: "पिकअप किए जाने वाले आइटम",
  storeItemHeading: "पिकअप स्टोर का पता"
};
var cardActions$a = {
  getDirections: "निर्देश प्राप्त करें, यह लिंक नई विंडो में खुलती है"
};
var deliveryPointOfServiceDetails$a = {
  itemsToBePickUp: "पिकअप किए जाने वाले आइटम",
  pickUpInStoreAddress: "पिकअप स्टोर का पता",
  itemsToBeShipped: "शिप किए जाने वाले आइटम",
  shippingAddress: "शिपिंग पता",
  deliveryMethod: "वितरण विधि",
  deliveryOptions: "वितरण विकल्प",
  statusDisplay_cancelled: "रद्द किया गया",
  statusDisplay_cancelling: "लंबित रद्द करें",
  statusDisplay_completed: "पूर्ण हुआ",
  statusDisplay_created: "बनाया गया",
  statusDisplay_error: "लंबित",
  statusDisplay_Error: "लंबित",
  statusDisplay_processing: "लंबित",
  statusDisplay_open: "खोलें",
  statusDisplay_pending: {
    approval: "लंबित अनुमोदन",
    merchant: {
      approval: "मर्चेंट के अनुमोदन के लिए लंबित"
    }
  },
  statusDisplay_approved: "अनुमोदित",
  statusDisplay_rejected: "अस्वीकृत",
  statusDisplay_merchant: {
    approved: "मर्चेंट द्वारा अनुमोदित",
    rejected: "मर्चेंट द्वारा अस्वीकृत"
  },
  statusDisplay_assigned: {
    admin: "व्यवस्थापक को असाइन किया गया"
  }
};
var pickupInStore$a = {
  pickupInfo: pickupInfo$a,
  pickupOptionDialog: pickupOptionDialog$a,
  pickupOptions: pickupOptions$a,
  setPreferredStore: setPreferredStore$a,
  store: store$a,
  storeList: storeList$a,
  storeSchedule: storeSchedule$a,
  storeSearch: storeSearch$a,
  addressBookPickupInStore: addressBookPickupInStore$a,
  storeFinderPickupInStore: storeFinderPickupInStore$a,
  checkoutPickupInStore: checkoutPickupInStore$a,
  cardActions: cardActions$a,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  pickupInStore: pickupInStore$a
};
var pickupInfo$9 = {
  inStorePickup: "Átvétel üzletben",
  pickupBy: "Átvétel eddig:",
  pickupFrom: "Átvétel innen:"
};
var pickupOptionDialog$9 = {
  close: "Bezárás",
  modalHeader: "Átvétel az üzletben"
};
var pickupOptions$9 = {
  legend: "Kézbesítési mód",
  changeStore: "Üzlet módosítása",
  delivery: "Szállítás (ingyenes visszáru)",
  pickup: "Ingyenes átvétel az üzletben",
  shipIt: "Szállítás",
  selectStore: "Válasszon üzletet",
  freeReturn: "Ingyenes visszáru",
  storeIsNotSelected: "Nincs kiválasztva üzlet az átvételhez."
};
var setPreferredStore$9 = {
  makeThisMyStore: "Legyen ez a saját üzletem",
  myStore: "Saját üzlet"
};
var store$9 = {
  pickupFromHere: "Átvétel innen",
  stockLevel_inStock: "{{ count }} készleten",
  stockLevel_outOfStock: "Nincs készleten",
  viewHours: "Nyitvatartási idő megtekintése"
};
var storeList$9 = {
  noStoresMessage: "Nem találhatók üzletek az adatbázisban..."
};
var storeSchedule$9 = {
  closed: "Zárva",
  storeHours: "Üzlet nyitvatartási ideje"
};
var storeSearch$9 = {
  findAStore: "Üzlet keresése",
  findStores: "Üzletek keresése",
  hideOutOfStockOptions: "Készleten nem elérhető lehetőségek elrejtése",
  searchPlaceholder: "Adja meg az irányítószámot, a települést vagy a címet",
  useMyLocation: "Saját hely használata"
};
var addressBookPickupInStore$9 = {
  heading: "Átvételi üzlet"
};
var storeFinderPickupInStore$9 = {
  heading: "Saját üzlet"
};
var checkoutPickupInStore$9 = {
  heading: "Átveendő tételek",
  storeItemHeading: "Átvételi üzlet címe"
};
var cardActions$9 = {
  getDirections: "Útvonal tervezése; ez a hivatkozás új ablakban nyílik meg"
};
var deliveryPointOfServiceDetails$9 = {
  itemsToBePickUp: "Átveendő tételek",
  pickUpInStoreAddress: "Átvételi üzlet címe",
  itemsToBeShipped: "Szállítandó tételek",
  shippingAddress: "Szállítási cím",
  deliveryMethod: "Kézbesítési mód",
  deliveryOptions: "Kézbesítési lehetőségek",
  statusDisplay_cancelled: "Törölve",
  statusDisplay_cancelling: "Törlés függőben",
  statusDisplay_completed: "Befejezve",
  statusDisplay_created: "Létrehozva",
  statusDisplay_error: "Függőben",
  statusDisplay_Error: "Függőben",
  statusDisplay_processing: "Függőben",
  statusDisplay_open: "Nyitott",
  statusDisplay_pending: {
    approval: "Jóváhagyásra vár",
    merchant: {
      approval: "Kereskedő jóváhagyására vár"
    }
  },
  statusDisplay_approved: "Jóváhagyva",
  statusDisplay_rejected: "Elutasítva",
  statusDisplay_merchant: {
    approved: "Kereskedő által jóváhagyva",
    rejected: "Kereskedő által elutasítva"
  },
  statusDisplay_assigned: {
    admin: "Rendszergazdához rendelve"
  }
};
var pickupInStore$9 = {
  pickupInfo: pickupInfo$9,
  pickupOptionDialog: pickupOptionDialog$9,
  pickupOptions: pickupOptions$9,
  setPreferredStore: setPreferredStore$9,
  store: store$9,
  storeList: storeList$9,
  storeSchedule: storeSchedule$9,
  storeSearch: storeSearch$9,
  addressBookPickupInStore: addressBookPickupInStore$9,
  storeFinderPickupInStore: storeFinderPickupInStore$9,
  checkoutPickupInStore: checkoutPickupInStore$9,
  cardActions: cardActions$9,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  pickupInStore: pickupInStore$9
};
var pickupInfo$8 = {
  inStorePickup: "Pengambilan di Toko",
  pickupBy: "Diambil oleh",
  pickupFrom: "Diambil dari"
};
var pickupOptionDialog$8 = {
  close: "Tutup",
  modalHeader: "Diambil di Toko"
};
var pickupOptions$8 = {
  legend: "Metode Pengiriman",
  changeStore: "Ganti Toko",
  delivery: "Kirim (Tanpa Biaya Pengembalian)",
  pickup: "Tanpa Biaya Pengambilan di Toko",
  shipIt: "Kirim",
  selectStore: "Pilih Toko",
  freeReturn: "Tanpa Biaya Pengembalian",
  storeIsNotSelected: "Toko tempat pengambilan tidak dipilih."
};
var setPreferredStore$8 = {
  makeThisMyStore: "Jadikan Sebagai Toko Saya",
  myStore: "Toko Saya"
};
var store$8 = {
  pickupFromHere: "Diambil di sini",
  stockLevel_inStock: "{{ count }} Stok Tersedia",
  stockLevel_outOfStock: "Stok Habis",
  viewHours: "Lihat Jam Buka Toko"
};
var storeList$8 = {
  noStoresMessage: "Tidak ada toko di basis data..."
};
var storeSchedule$8 = {
  closed: "Tutup",
  storeHours: "Jam buka toko"
};
var storeSearch$8 = {
  findAStore: "Cari Toko",
  findStores: "Cari Toko",
  hideOutOfStockOptions: "Sembunyikan opsi stok habis",
  searchPlaceholder: "Masukkan Kode Pos, Kota, atau Alamat",
  useMyLocation: "Gunakan lokasi saya"
};
var addressBookPickupInStore$8 = {
  heading: "Toko Pengambilan"
};
var storeFinderPickupInStore$8 = {
  heading: "Toko Saya"
};
var checkoutPickupInStore$8 = {
  heading: "Item yang Akan Diambil",
  storeItemHeading: "Alamat Toko Pengambilan"
};
var cardActions$8 = {
  getDirections: "Dapatkan Petunjuk Arah, tautan ini akan terbuka di jendela baru."
};
var deliveryPointOfServiceDetails$8 = {
  itemsToBePickUp: "Item Yang Akan Diambil",
  pickUpInStoreAddress: "Alamat Toko Pengambilan",
  itemsToBeShipped: "Item Yang Akan Dikirim",
  shippingAddress: "Alamat Pengiriman",
  deliveryMethod: "Metode Pengiriman",
  deliveryOptions: "Opsi Pengiriman",
  statusDisplay_cancelled: "Dibatalkan",
  statusDisplay_cancelling: "Batalkan Penundaan",
  statusDisplay_completed: "Selesai",
  statusDisplay_created: "Dibuat",
  statusDisplay_error: "Ditunda",
  statusDisplay_Error: "Ditunda",
  statusDisplay_processing: "Ditunda",
  statusDisplay_open: "Aktif",
  statusDisplay_pending: {
    approval: "Menunggu Persetujuan",
    merchant: {
      approval: "Menunggu Persetujuan Pedagang"
    }
  },
  statusDisplay_approved: "Disetujui",
  statusDisplay_rejected: "Ditolak",
  statusDisplay_merchant: {
    approved: "Disetujui Oleh Pedagang",
    rejected: "Ditolak Oleh Pedagang"
  },
  statusDisplay_assigned: {
    admin: "Ditetapkan Ke Administrator"
  }
};
var pickupInStore$8 = {
  pickupInfo: pickupInfo$8,
  pickupOptionDialog: pickupOptionDialog$8,
  pickupOptions: pickupOptions$8,
  setPreferredStore: setPreferredStore$8,
  store: store$8,
  storeList: storeList$8,
  storeSchedule: storeSchedule$8,
  storeSearch: storeSearch$8,
  addressBookPickupInStore: addressBookPickupInStore$8,
  storeFinderPickupInStore: storeFinderPickupInStore$8,
  checkoutPickupInStore: checkoutPickupInStore$8,
  cardActions: cardActions$8,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  pickupInStore: pickupInStore$8
};
var pickupInfo$7 = {
  inStorePickup: "Ritiro al punto vendita",
  pickupBy: "Ritiro da parte di",
  pickupFrom: "Ritiro da"
};
var pickupOptionDialog$7 = {
  close: "Chiudi",
  modalHeader: "Ritiro al punto vendita"
};
var pickupOptions$7 = {
  legend: "Metodo di consegna",
  changeStore: "Cambia punto vendita",
  delivery: "Spedisci (reso gratuito)",
  pickup: "Ritiro gratuito al punto vendita",
  shipIt: "Spedisci",
  selectStore: "Seleziona punto vendita",
  freeReturn: "Reso gratuito",
  storeIsNotSelected: "Punto vendita per ritiro non selezionato."
};
var setPreferredStore$7 = {
  makeThisMyStore: "Impostalo come punto vendita personale",
  myStore: "Punto vendita personale"
};
var store$7 = {
  pickupFromHere: "Ritira qui",
  stockLevel_inStock: "{{ count }} in stock",
  stockLevel_outOfStock: "Non disponibile",
  viewHours: "Visualizza orari"
};
var storeList$7 = {
  noStoresMessage: "Nessun punto vendita trovato nel database..."
};
var storeSchedule$7 = {
  closed: "Chiuso",
  storeHours: "Orari del punto vendita"
};
var storeSearch$7 = {
  findAStore: "Trova un punto vendita",
  findStores: "Trova punti vendita",
  hideOutOfStockOptions: "Nascondi le opzioni non disponibili",
  searchPlaceholder: "Inserisci CAP o città o indirizzo",
  useMyLocation: "Utilizza la mia posizione"
};
var addressBookPickupInStore$7 = {
  heading: "Punto vendita del ritiro"
};
var storeFinderPickupInStore$7 = {
  heading: "Punto vendita personale"
};
var checkoutPickupInStore$7 = {
  heading: "Articoli da ritirare",
  storeItemHeading: "Indirizzo del punto vendita di ritiro"
};
var cardActions$7 = {
  getDirections: "Ottieni indicazioni stradali; questo collegamento aprirà una nuova finestra"
};
var deliveryPointOfServiceDetails$7 = {
  itemsToBePickUp: "Articoli da ritirare",
  pickUpInStoreAddress: "Indirizzo del punto vendita di ritiro",
  itemsToBeShipped: "Articoli da spedire",
  shippingAddress: "Indirizzo di spedizione",
  deliveryMethod: "Metodo di consegna",
  deliveryOptions: "Opzioni di consegna",
  statusDisplay_cancelled: "Annullato",
  statusDisplay_cancelling: "Annullamento in sospeso",
  statusDisplay_completed: "Completato",
  statusDisplay_created: "Creato",
  statusDisplay_error: "In sospeso",
  statusDisplay_Error: "In sospeso",
  statusDisplay_processing: "In sospeso",
  statusDisplay_open: "Aperto",
  statusDisplay_pending: {
    approval: "In attesa di approvazione",
    merchant: {
      approval: "In attesa di approvazione da esercente"
    }
  },
  statusDisplay_approved: "Approvato",
  statusDisplay_rejected: "Rifiutato",
  statusDisplay_merchant: {
    approved: "Approvato dall'esercente",
    rejected: "Rifiutato dall'esercente"
  },
  statusDisplay_assigned: {
    admin: "Assegnato ad amministratore"
  }
};
var pickupInStore$7 = {
  pickupInfo: pickupInfo$7,
  pickupOptionDialog: pickupOptionDialog$7,
  pickupOptions: pickupOptions$7,
  setPreferredStore: setPreferredStore$7,
  store: store$7,
  storeList: storeList$7,
  storeSchedule: storeSchedule$7,
  storeSearch: storeSearch$7,
  addressBookPickupInStore: addressBookPickupInStore$7,
  storeFinderPickupInStore: storeFinderPickupInStore$7,
  checkoutPickupInStore: checkoutPickupInStore$7,
  cardActions: cardActions$7,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  pickupInStore: pickupInStore$7
};
var pickupInfo$6 = {
  inStorePickup: "店頭受け取り",
  pickupBy: "受け取り者",
  pickupFrom: "受け取り場所"
};
var pickupOptionDialog$6 = {
  close: "閉じる",
  modalHeader: "店頭受け取り"
};
var pickupOptions$6 = {
  legend: "配送方法",
  changeStore: "店舗を変更",
  delivery: "配送する (返品無料)",
  pickup: "無料店頭受け取り",
  shipIt: "配送する",
  selectStore: "店舗の選択",
  freeReturn: "無料返品",
  storeIsNotSelected: "受け取る店舗が選択されていません。"
};
var setPreferredStore$6 = {
  makeThisMyStore: "この店舗をマイストアにする",
  myStore: "マイストア"
};
var store$6 = {
  pickupFromHere: "ここで受け取り",
  stockLevel_inStock: "{{ count }} は在庫あり",
  stockLevel_outOfStock: "在庫なし",
  viewHours: "営業時間を表示"
};
var storeList$6 = {
  noStoresMessage: "データベースに店舗がありません..."
};
var storeSchedule$6 = {
  closed: "定休日",
  storeHours: "店舗の営業時間"
};
var storeSearch$6 = {
  findAStore: "店舗検索",
  findStores: "店舗を探す",
  hideOutOfStockOptions: "在庫オプションを非表示にする",
  searchPlaceholder: "郵便番号、市区町村名、または住所を入力します",
  useMyLocation: "自分の位置を使用"
};
var addressBookPickupInStore$6 = {
  heading: "受け取り店舗"
};
var storeFinderPickupInStore$6 = {
  heading: "マイストア"
};
var checkoutPickupInStore$6 = {
  heading: "受け取るアイテム",
  storeItemHeading: "受け取り店舗の住所"
};
var cardActions$6 = {
  getDirections: "ルート案内取得 (このリンクをクリックすると新しいウィンドウが開きます)"
};
var deliveryPointOfServiceDetails$6 = {
  itemsToBePickUp: "受け取るアイテム",
  pickUpInStoreAddress: "受け取り店舗の住所",
  itemsToBeShipped: "配送対象のアイテム",
  shippingAddress: "配送先住所",
  deliveryMethod: "配送方法",
  deliveryOptions: "配送オプション",
  statusDisplay_cancelled: "キャンセル済み",
  statusDisplay_cancelling: "保留をキャンセル",
  statusDisplay_completed: "完了",
  statusDisplay_created: "作成済み",
  statusDisplay_error: "保留中",
  statusDisplay_Error: "保留中",
  statusDisplay_processing: "保留中",
  statusDisplay_open: "未処理",
  statusDisplay_pending: {
    approval: "承認を保留",
    merchant: {
      approval: "業者承認を保留"
    }
  },
  statusDisplay_approved: "承認済み",
  statusDisplay_rejected: "却下済み",
  statusDisplay_merchant: {
    approved: "業者が承認しました",
    rejected: "業者が拒否しました"
  },
  statusDisplay_assigned: {
    admin: "管理者に割り当て済み"
  }
};
var pickupInStore$6 = {
  pickupInfo: pickupInfo$6,
  pickupOptionDialog: pickupOptionDialog$6,
  pickupOptions: pickupOptions$6,
  setPreferredStore: setPreferredStore$6,
  store: store$6,
  storeList: storeList$6,
  storeSchedule: storeSchedule$6,
  storeSearch: storeSearch$6,
  addressBookPickupInStore: addressBookPickupInStore$6,
  storeFinderPickupInStore: storeFinderPickupInStore$6,
  checkoutPickupInStore: checkoutPickupInStore$6,
  cardActions: cardActions$6,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  pickupInStore: pickupInStore$6
};
var pickupInfo$5 = {
  inStorePickup: "매장 내 픽업",
  pickupBy: "픽업자",
  pickupFrom: "픽업 위치"
};
var pickupOptionDialog$5 = {
  close: "닫기",
  modalHeader: "매장 내 픽업"
};
var pickupOptions$5 = {
  legend: "배송 방법",
  changeStore: "매장 변경",
  delivery: "배송(무료 반품)",
  pickup: "매장 내 무료 픽업",
  shipIt: "배송",
  selectStore: "매장 선택",
  freeReturn: "무료 반품",
  storeIsNotSelected: "픽업할 매장이 선택되지 않았습니다."
};
var setPreferredStore$5 = {
  makeThisMyStore: "내 매장으로 설정",
  myStore: "내 매장"
};
var store$5 = {
  pickupFromHere: "여기서 픽업",
  stockLevel_inStock: "{{ count }} 재고 있음",
  stockLevel_outOfStock: "재고 없음",
  viewHours: "시간 보기"
};
var storeList$5 = {
  noStoresMessage: "데이터베이스에 매장이 없음..."
};
var storeSchedule$5 = {
  closed: "영업 종료",
  storeHours: "매장 시간"
};
var storeSearch$5 = {
  findAStore: "매장 찾기",
  findStores: "매장 찾기",
  hideOutOfStockOptions: "재고 없음 옵션 숨기기",
  searchPlaceholder: "우편 번호나 시/구/군, 주소 입력",
  useMyLocation: "내 위치 사용"
};
var addressBookPickupInStore$5 = {
  heading: "픽업 매장"
};
var storeFinderPickupInStore$5 = {
  heading: "내 매장"
};
var checkoutPickupInStore$5 = {
  heading: "픽업할 품목",
  storeItemHeading: "픽업 매장 주소"
};
var cardActions$5 = {
  getDirections: "오시는 길, 이 링크를 클릭하면 새 창이 열립니다."
};
var deliveryPointOfServiceDetails$5 = {
  itemsToBePickUp: "픽업할 품목",
  pickUpInStoreAddress: "픽업 매장 주소",
  itemsToBeShipped: "배송할 품목",
  shippingAddress: "배송 주소",
  deliveryMethod: "배송 방법",
  deliveryOptions: "배송 옵션",
  statusDisplay_cancelled: "취소됨",
  statusDisplay_cancelling: "취소 보류 중",
  statusDisplay_completed: "완료됨",
  statusDisplay_created: "작성됨",
  statusDisplay_error: "보류 중",
  statusDisplay_Error: "보류 중",
  statusDisplay_processing: "보류 중",
  statusDisplay_open: "미결",
  statusDisplay_pending: {
    approval: "승인 보류 중",
    merchant: {
      approval: "판매업체 승인 보류 중"
    }
  },
  statusDisplay_approved: "승인됨",
  statusDisplay_rejected: "거부됨",
  statusDisplay_merchant: {
    approved: "판매업체 승인됨",
    rejected: "판매업체 거부됨"
  },
  statusDisplay_assigned: {
    admin: "관리자에게 지정됨"
  }
};
var pickupInStore$5 = {
  pickupInfo: pickupInfo$5,
  pickupOptionDialog: pickupOptionDialog$5,
  pickupOptions: pickupOptions$5,
  setPreferredStore: setPreferredStore$5,
  store: store$5,
  storeList: storeList$5,
  storeSchedule: storeSchedule$5,
  storeSearch: storeSearch$5,
  addressBookPickupInStore: addressBookPickupInStore$5,
  storeFinderPickupInStore: storeFinderPickupInStore$5,
  checkoutPickupInStore: checkoutPickupInStore$5,
  cardActions: cardActions$5,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  pickupInStore: pickupInStore$5
};
var pickupInfo$4 = {
  inStorePickup: "Odbiór w sklepie",
  pickupBy: "Odbiór przez",
  pickupFrom: "Odbiór z"
};
var pickupOptionDialog$4 = {
  close: "Zamknij",
  modalHeader: "Odbiór w sklepie"
};
var pickupOptions$4 = {
  legend: "Metoda dostawy",
  changeStore: "Zmień sklep",
  delivery: "Wysyłka (bezpłatny zwrot)",
  pickup: "Darmowy odbiór w sklepie",
  shipIt: "Wysyłka",
  selectStore: "Wybierz sklep",
  freeReturn: "Darmowy zwrot",
  storeIsNotSelected: "Sklep do odbioru nie został wybrany"
};
var setPreferredStore$4 = {
  makeThisMyStore: "Zapisz jako Mój Sklep",
  myStore: "Mój Sklep"
};
var store$4 = {
  pickupFromHere: "Odbiór tutaj",
  stockLevel_inStock: "{{ count }} dostępny",
  stockLevel_outOfStock: "Zapas wyczerpany",
  viewHours: "Zobacz godziny"
};
var storeList$4 = {
  noStoresMessage: "Nie znaleziono sklepów w bazie danych..."
};
var storeSchedule$4 = {
  closed: "Zamknięte",
  storeHours: "Godziny otwarcia sklepu"
};
var storeSearch$4 = {
  findAStore: "Znajdź sklep",
  findStores: "Znajdź sklepy",
  hideOutOfStockOptions: "Ukryj się opcje magazynowe",
  searchPlaceholder: "Wpisz kod pocztowy lub miasto lub adres",
  useMyLocation: "Użyj mojej lokalizacji"
};
var addressBookPickupInStore$4 = {
  heading: "Punkt odbioru"
};
var storeFinderPickupInStore$4 = {
  heading: "Mój Sklep"
};
var checkoutPickupInStore$4 = {
  heading: "Pozycje do odbioru",
  storeItemHeading: "Adres punktu odbioru"
};
var cardActions$4 = {
  getDirections: "Jak dojechać. Ten link otwiera się w nowym oknie."
};
var deliveryPointOfServiceDetails$4 = {
  itemsToBePickUp: "Pozycje do odbioru",
  pickUpInStoreAddress: "Adres punktu odbioru",
  itemsToBeShipped: "Pozycje do wysłania",
  shippingAddress: "Adres wysyłki",
  deliveryMethod: "Metoda dostawy",
  deliveryOptions: "Opcje dostawy",
  statusDisplay_cancelled: "Anulowane",
  statusDisplay_cancelling: "Anuluj oczekujące",
  statusDisplay_completed: "Zakończone",
  statusDisplay_created: "Utworzone",
  statusDisplay_error: "Oczekujące",
  statusDisplay_Error: "Oczekujące",
  statusDisplay_processing: "Oczekujące",
  statusDisplay_open: "Otwarte",
  statusDisplay_pending: {
    approval: "Oczekuje na zatwierdzenie",
    merchant: {
      approval: "Oczekuje na zatwierdzenie przez sprzedawcę"
    }
  },
  statusDisplay_approved: "Zatwierdzone",
  statusDisplay_rejected: "Odrzucone",
  statusDisplay_merchant: {
    approved: "Zatwierdzone przez sprzedawcę",
    rejected: "Odrzucone przez sprzedawcę"
  },
  statusDisplay_assigned: {
    admin: "Przypisane do administratora"
  }
};
var pickupInStore$4 = {
  pickupInfo: pickupInfo$4,
  pickupOptionDialog: pickupOptionDialog$4,
  pickupOptions: pickupOptions$4,
  setPreferredStore: setPreferredStore$4,
  store: store$4,
  storeList: storeList$4,
  storeSchedule: storeSchedule$4,
  storeSearch: storeSearch$4,
  addressBookPickupInStore: addressBookPickupInStore$4,
  storeFinderPickupInStore: storeFinderPickupInStore$4,
  checkoutPickupInStore: checkoutPickupInStore$4,
  cardActions: cardActions$4,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  pickupInStore: pickupInStore$4
};
var pickupInfo$3 = {
  inStorePickup: "Retirada na loja",
  pickupBy: "Retirar até",
  pickupFrom: "Retirar de"
};
var pickupOptionDialog$3 = {
  close: "Fechar",
  modalHeader: "Retirar na loja"
};
var pickupOptions$3 = {
  legend: "Método de entrega",
  changeStore: "Modificar loja",
  delivery: "Envio (devolução gratuita)",
  pickup: "Retirada gratuita na loja",
  shipIt: "Envio",
  selectStore: "Selecionar loja",
  freeReturn: "Devolução gratuita",
  storeIsNotSelected: "Loja para retirada não selecionada."
};
var setPreferredStore$3 = {
  makeThisMyStore: "Tornar esta minha loja",
  myStore: "Minha loja"
};
var store$3 = {
  pickupFromHere: "Retirada aqui",
  stockLevel_inStock: "{{ count }} em estoque",
  stockLevel_outOfStock: "Sem estoque",
  viewHours: "Visualizar horário"
};
var storeList$3 = {
  noStoresMessage: "Nenhuma loja encontrada no banco de dados..."
};
var storeSchedule$3 = {
  closed: "Fechado",
  storeHours: "Horário da loja"
};
var storeSearch$3 = {
  findAStore: "Encontrar uma loja",
  findStores: "Encontrar lojas",
  hideOutOfStockOptions: "Ocultar opções sem estoque",
  searchPlaceholder: "Inserir código postal ou cidade ou endereço",
  useMyLocation: "Utilizar a minha localização"
};
var addressBookPickupInStore$3 = {
  heading: "Loja de retirada"
};
var storeFinderPickupInStore$3 = {
  heading: "Minha loja"
};
var checkoutPickupInStore$3 = {
  heading: "Itens a serem retirados",
  storeItemHeading: "Endereço de loja de retirada"
};
var cardActions$3 = {
  getDirections: "Obter indicações, este link se abre em uma nova janela"
};
var deliveryPointOfServiceDetails$3 = {
  itemsToBePickUp: "Itens a serem retirados",
  pickUpInStoreAddress: "Endereço de loja de retirada",
  itemsToBeShipped: "Itens a serem enviados",
  shippingAddress: "Endereço de entrega",
  deliveryMethod: "Método de entrega",
  deliveryOptions: "Opções de entrega",
  statusDisplay_cancelled: "Cancelado",
  statusDisplay_cancelling: "Cancelamento pendente",
  statusDisplay_completed: "Concluído",
  statusDisplay_created: "Criado",
  statusDisplay_error: "Pendente",
  statusDisplay_Error: "Pendente",
  statusDisplay_processing: "Pendente",
  statusDisplay_open: "Aberto",
  statusDisplay_pending: {
    approval: "Aprovação pendente",
    merchant: {
      approval: "Aprovação do comerciante pendente"
    }
  },
  statusDisplay_approved: "Aprovado",
  statusDisplay_rejected: "Rejeitado",
  statusDisplay_merchant: {
    approved: "Comerciante aprovado",
    rejected: "Comerciante rejeitado"
  },
  statusDisplay_assigned: {
    admin: "Atribuído ao administrador"
  }
};
var pickupInStore$3 = {
  pickupInfo: pickupInfo$3,
  pickupOptionDialog: pickupOptionDialog$3,
  pickupOptions: pickupOptions$3,
  setPreferredStore: setPreferredStore$3,
  store: store$3,
  storeList: storeList$3,
  storeSchedule: storeSchedule$3,
  storeSearch: storeSearch$3,
  addressBookPickupInStore: addressBookPickupInStore$3,
  storeFinderPickupInStore: storeFinderPickupInStore$3,
  checkoutPickupInStore: checkoutPickupInStore$3,
  cardActions: cardActions$3,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  pickupInStore: pickupInStore$3
};
var pickupInfo$2 = {
  inStorePickup: "Самовывоз из магазина",
  pickupBy: "Самовывоз (кем)",
  pickupFrom: "Самовывоз из"
};
var pickupOptionDialog$2 = {
  close: "Закрыть",
  modalHeader: "Самовывоз из магазина"
};
var pickupOptions$2 = {
  legend: "Метод доставки",
  changeStore: "Изменить магазин",
  delivery: "Доставка (бесплатный возврат)",
  pickup: "Бесплатный самовывоз из магазина",
  shipIt: "Доставка",
  selectStore: "Выбрать магазин",
  freeReturn: "Бесплатный возврат",
  storeIsNotSelected: "Не выбран магазин для самовывоза."
};
var setPreferredStore$2 = {
  makeThisMyStore: "Сделать моим магазином",
  myStore: "Мой магазин"
};
var store$2 = {
  pickupFromHere: "Забрать здесь",
  stockLevel_inStock: "{{ count }} в наличии",
  stockLevel_outOfStock: "Нет в наличии",
  viewHours: "Смотреть часы работы"
};
var storeList$2 = {
  noStoresMessage: "Магазины не найдены в базе данных..."
};
var storeSchedule$2 = {
  closed: "Закрыто",
  storeHours: "Часы работы магазина"
};
var storeSearch$2 = {
  findAStore: "Найти магазин",
  findStores: "Найти магазины",
  hideOutOfStockOptions: "Скрыть опции наличия",
  searchPlaceholder: "Введите почтовый индекс, город или адрес",
  useMyLocation: "Использовать мое местоположение"
};
var addressBookPickupInStore$2 = {
  heading: "Магазин самовывоза"
};
var storeFinderPickupInStore$2 = {
  heading: "Мой магазин"
};
var checkoutPickupInStore$2 = {
  heading: "Позиции для самовывоза",
  storeItemHeading: "Адрес магазина самовывоза"
};
var cardActions$2 = {
  getDirections: "Получить маршрут, эта ссылка откроется в новом окне"
};
var deliveryPointOfServiceDetails$2 = {
  itemsToBePickUp: "Позиции для самовывоза",
  pickUpInStoreAddress: "Адрес магазина самовывоза",
  itemsToBeShipped: "Позиции для доставки",
  shippingAddress: "Адрес доставки",
  deliveryMethod: "Метод доставки",
  deliveryOptions: "Опции доставки",
  statusDisplay_cancelled: "Отменено",
  statusDisplay_cancelling: "Ожидает отмены",
  statusDisplay_completed: "Завершено",
  statusDisplay_created: "Создано",
  statusDisplay_error: "Ожидает",
  statusDisplay_Error: "Ожидает",
  statusDisplay_processing: "Ожидает",
  statusDisplay_open: "Открыто",
  statusDisplay_pending: {
    approval: "Ожидает утверждения",
    merchant: {
      approval: "Ожидает утверждения продавца"
    }
  },
  statusDisplay_approved: "Утверждено",
  statusDisplay_rejected: "Отклонено",
  statusDisplay_merchant: {
    approved: "Продавец утвердил",
    rejected: "Продавец отклонил"
  },
  statusDisplay_assigned: {
    admin: "Присвоено администратору"
  }
};
var pickupInStore$2 = {
  pickupInfo: pickupInfo$2,
  pickupOptionDialog: pickupOptionDialog$2,
  pickupOptions: pickupOptions$2,
  setPreferredStore: setPreferredStore$2,
  store: store$2,
  storeList: storeList$2,
  storeSchedule: storeSchedule$2,
  storeSearch: storeSearch$2,
  addressBookPickupInStore: addressBookPickupInStore$2,
  storeFinderPickupInStore: storeFinderPickupInStore$2,
  checkoutPickupInStore: checkoutPickupInStore$2,
  cardActions: cardActions$2,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  pickupInStore: pickupInStore$2
};
var pickupInfo$1 = {
  inStorePickup: "店内提货",
  pickupBy: "提货人",
  pickupFrom: "提货地点"
};
var pickupOptionDialog$1 = {
  close: "关闭",
  modalHeader: "店内提货"
};
var pickupOptions$1 = {
  legend: "送货方式",
  changeStore: "更改店铺",
  delivery: "发货（免费退货）",
  pickup: "免费店内提货",
  shipIt: "发货",
  selectStore: "选择店铺",
  freeReturn: "免费退货",
  storeIsNotSelected: "未选择提货店铺。"
};
var setPreferredStore$1 = {
  makeThisMyStore: "将此设置为我的店铺",
  myStore: "我的店铺"
};
var store$1 = {
  pickupFromHere: "在此提货",
  stockLevel_inStock: "有 {{ count }} 个现货 ",
  stockLevel_outOfStock: "缺货",
  viewHours: "查看营业时间"
};
var storeList$1 = {
  noStoresMessage: "未在数据库中找到店铺..."
};
var storeSchedule$1 = {
  closed: "已关闭",
  storeHours: "店铺营业时间"
};
var storeSearch$1 = {
  findAStore: "查找店铺",
  findStores: "查找店铺",
  hideOutOfStockOptions: "隐藏缺货选项",
  searchPlaceholder: "输入邮政编码、城镇或地址",
  useMyLocation: "使用我的位置"
};
var addressBookPickupInStore$1 = {
  heading: "提货店铺"
};
var storeFinderPickupInStore$1 = {
  heading: "我的店铺"
};
var checkoutPickupInStore$1 = {
  heading: "待提货商品",
  storeItemHeading: "提货店铺地址"
};
var cardActions$1 = {
  getDirections: "获取方位，此链接将在新窗口中打开"
};
var deliveryPointOfServiceDetails$1 = {
  itemsToBePickUp: "待提货商品",
  pickUpInStoreAddress: "提货店铺地址",
  itemsToBeShipped: "待发货商品",
  shippingAddress: "发货地址",
  deliveryMethod: "送货方式",
  deliveryOptions: "送货选项",
  statusDisplay_cancelled: "已取消",
  statusDisplay_cancelling: "取消待处理状态",
  statusDisplay_completed: "已完成",
  statusDisplay_created: "已创建",
  statusDisplay_error: "待处理",
  statusDisplay_Error: "待处理",
  statusDisplay_processing: "待处理",
  statusDisplay_open: "未完成",
  statusDisplay_pending: {
    approval: "等待批准",
    merchant: {
      approval: "等待商家批准"
    }
  },
  statusDisplay_approved: "已批准",
  statusDisplay_rejected: "已拒绝",
  statusDisplay_merchant: {
    approved: "商家已批准",
    rejected: "商家已拒绝"
  },
  statusDisplay_assigned: {
    admin: "已分配至管理员"
  }
};
var pickupInStore$1 = {
  pickupInfo: pickupInfo$1,
  pickupOptionDialog: pickupOptionDialog$1,
  pickupOptions: pickupOptions$1,
  setPreferredStore: setPreferredStore$1,
  store: store$1,
  storeList: storeList$1,
  storeSchedule: storeSchedule$1,
  storeSearch: storeSearch$1,
  addressBookPickupInStore: addressBookPickupInStore$1,
  storeFinderPickupInStore: storeFinderPickupInStore$1,
  checkoutPickupInStore: checkoutPickupInStore$1,
  cardActions: cardActions$1,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  pickupInStore: pickupInStore$1
};
var pickupInfo = {
  inStorePickup: "商店取貨",
  pickupBy: "取貨於",
  pickupFrom: "取貨自"
};
var pickupOptionDialog = {
  close: "關閉",
  modalHeader: "商店取貨"
};
var pickupOptions = {
  legend: "交貨方法",
  changeStore: "更改商店",
  delivery: "運送 (免費退貨)",
  pickup: "免費商店取貨",
  shipIt: "運送",
  selectStore: "選擇商店",
  freeReturn: "免費退貨",
  storeIsNotSelected: "未選擇要取貨的商店。"
};
var setPreferredStore = {
  makeThisMyStore: "將此設定為我的商店",
  myStore: "我的商店"
};
var store = {
  pickupFromHere: "從這裡取貨",
  stockLevel_inStock: "庫存有 {{ count }} 個",
  stockLevel_outOfStock: "缺貨",
  viewHours: "檢視時數"
};
var storeList = {
  noStoresMessage: "資料庫中找不到商店..."
};
var storeSchedule = {
  closed: "關閉",
  storeHours: "商店營業時間"
};
var storeSearch = {
  findAStore: "搜尋商店",
  findStores: "搜尋商店",
  hideOutOfStockOptions: "從庫存選項隱藏",
  searchPlaceholder: "輸入郵遞區號或城鎮或地址",
  useMyLocation: "使用我的位置"
};
var addressBookPickupInStore = {
  heading: "取貨商店"
};
var storeFinderPickupInStore = {
  heading: "我的商店"
};
var checkoutPickupInStore = {
  heading: "要取貨的項目",
  storeItemHeading: "取貨商店地址"
};
var cardActions = {
  getDirections: "規劃路線，此連結會在新視窗中開啟"
};
var deliveryPointOfServiceDetails = {
  itemsToBePickUp: "要取貨的項目",
  pickUpInStoreAddress: "取貨商店地址",
  itemsToBeShipped: "待運送項目",
  shippingAddress: "出貨地址",
  deliveryMethod: "交貨方法",
  deliveryOptions: "交貨選項",
  statusDisplay_cancelled: "已取消",
  statusDisplay_cancelling: "取消待處理",
  statusDisplay_completed: "已完成",
  statusDisplay_created: "已建立",
  statusDisplay_error: "待處理",
  statusDisplay_Error: "待處理",
  statusDisplay_processing: "待處理",
  statusDisplay_open: "未結",
  statusDisplay_pending: {
    approval: "待核准",
    merchant: {
      approval: "待商家核准"
    }
  },
  statusDisplay_approved: "已核准",
  statusDisplay_rejected: "已拒絕",
  statusDisplay_merchant: {
    approved: "商家已核准",
    rejected: "商家已拒絕"
  },
  statusDisplay_assigned: {
    admin: "已指派給管理員"
  }
};
var pickupInStore = {
  pickupInfo: pickupInfo,
  pickupOptionDialog: pickupOptionDialog,
  pickupOptions: pickupOptions,
  setPreferredStore: setPreferredStore,
  store: store,
  storeList: storeList,
  storeSchedule: storeSchedule,
  storeSearch: storeSearch,
  addressBookPickupInStore: addressBookPickupInStore,
  storeFinderPickupInStore: storeFinderPickupInStore,
  checkoutPickupInStore: checkoutPickupInStore,
  cardActions: cardActions,
  deliveryPointOfServiceDetails: deliveryPointOfServiceDetails
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  pickupInStore
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pickupInStoreTranslationChunksConfig = {
  pickupInStore: ['pickupInfo', 'pickupOptionDialog', 'pickupOptions', 'setPreferredStore', 'store', 'storeList', 'storeSchedule', 'storeSearch', 'addressBookPickupInStore', 'storeFinderPickupInStore', 'checkoutPickupInStore', 'cardActions', 'deliveryPointOfServiceDetails']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: pickupInStoreTranslations
 *             +   resources: { en: pickupInStoreTranslationsEn }
 *               }
 *             ```
 */
const pickupInStoreTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { pickupInStoreTranslationChunksConfig, pickupInStoreTranslations, cs as pickupInStoreTranslationsCs, de as pickupInStoreTranslationsDe, en as pickupInStoreTranslationsEn, es as pickupInStoreTranslationsEs, es_CO as pickupInStoreTranslationsEs_CO, fr as pickupInStoreTranslationsFr, hi as pickupInStoreTranslationsHi, hu as pickupInStoreTranslationsHu, id as pickupInStoreTranslationsId, it as pickupInStoreTranslationsIt, ja as pickupInStoreTranslationsJa, ko as pickupInStoreTranslationsKo, pl as pickupInStoreTranslationsPl, pt as pickupInStoreTranslationsPt, ru as pickupInStoreTranslationsRu, zh as pickupInStoreTranslationsZh, zh_TW as pickupInStoreTranslationsZh_TW };
