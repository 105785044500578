import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from '@spartacus/assets';
import {
  FeaturesConfig,
  I18nConfig,
  OccConfig,
  provideConfig,
  SiteContextConfig,
} from '@spartacus/core';
import { defaultB2bOccConfig } from '@spartacus/setup';

import { defaultCmsContentProviders, mediaConfig } from '@spartacus/storefront';
import { CustomLayoutModule } from '../core/layout/layout.module';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [],
  imports: [CustomLayoutModule],
  providers: [
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig(defaultB2bOccConfig),
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          baseUrl: environment.occBaseUrl,
          prefix: '/occ/v2/',
        },
      },
    }),
    provideConfig(<SiteContextConfig>{
      context: {
        baseSite: [environment.occBaseSite],
        currency: ['USD'],
        language: ['en', 'de', 'es', 'fr', 'pt', 'ru', 'ja', 'zh'],
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en',
      },
    }),
    provideConfig(<FeaturesConfig>{
      features: {
        level: '2211.36',
      },
    }),
  ],
})
export class SpartacusConfigurationModule {}
