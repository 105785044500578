import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LaunchDialogService } from '@spartacus/storefront';
import { AccountStatus } from '../dashboard.model';

@Component({
  standalone: false,
  selector: 'app-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.scss'],
})
export class RejectModalComponent implements OnInit {
  form!: FormGroup;
  status!: string;
  submit: boolean = false;
  actionTitle = {
    [AccountStatus.approved]: `Approve`,
    [AccountStatus.rejected]: `Reject`,
    [AccountStatus.onHold]: `Hold`,
  };
  constructor(protected launchDialogService: LaunchDialogService) {
    this.form = new FormGroup({
      comment: new FormControl(null, []),
    });
  }

  ngOnInit(): void {
    this.launchDialogService.data$.subscribe((data) => {
      this.status = data?.status;
      if (AccountStatus.rejected == this.status) {
        this.form.controls['comment'].setValidators([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(250),
        ]);
      } else {
        this.form.controls['comment'].clearValidators();
      }
      this.form.controls['comment'].updateValueAndValidity();
    });
  }

  closeModal(): void {
    this.launchDialogService.closeDialog({ action: 'close' });
  }

  onSubmit() {
    this.submit = true;
    if (this.form.valid) {
      const data = {
        action: 'submit',
        comment: this.form.value.comment,
      };
      this.launchDialogService.closeDialog(data);
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }
}
