import { AllProductLine } from '../../../shared/enums/availableProductList.enum';

const commonLinks = [
  {
    name: 'Browse Products',
    iconImg: '../../../../assets/img/browseProductsIcon.svg',
  },
  { name: 'My Orders', iconImg: '../../../../assets/img/myOrdersIcon.svg' },
  { name: 'My Returns', iconImg: '../../../../assets/img/myReturnsIcon.svg' },
  { name: 'Create RMA', iconImg: '../../../../assets/img/CreateRMA.svg' },
  {
    name: 'Calibration Certificate',
    iconImg: '../../../../assets/img/calibrationIcon.svg',
  },
  {
    name: 'Track Order',
    iconImg: '../../../../assets/img/trackOrderIcon.svg',
  },
  {
    name: 'Customer Support',
    iconImg: '../../../../assets/img/customerSupportIcon.svg',
  },
  {
    name: 'Supportive Links',
    iconImg: '../../../../assets/img/supportiveLinksIcon.svg',
  },
];

const specificLinks = {
  [AllProductLine.panametrics]: [
    '/panametrics/categories/ECOM_LVL1_00000002/Panametrics',
    '/panametrics/my-orders',
    '/rma-tracking',
    '/rma-form',
    '/panametrics/calibration-data',
    '/panametrics/track-order',
    '/panametrics/contactus',
    '/list-of-portals',
  ],
  [AllProductLine.waygate]: [
    '/waygate/categories/ECOM_LVL1_00000001/Waygate-Technologies',
    '/waygate/my-orders',
    '/rma-tracking',
    '/rma-form',
    '/waygate/calibration-data',
    '/waygate/track-order',
    '/waygate/contactus',
    '/list-of-portals',
  ],
  [AllProductLine.druck]: [
    '/druck/categories/ECOM_LVL1_00000008/Druck',
    '/druck/my-orders',
    '/rma-tracking',
    '/rma-form',
    '/druck/calibration-data',
    '/druck/track-order',
    '/druck/contactus',
    '/list-of-portals',
  ],
  [AllProductLine.bently]: [
    '/bently-nevada/categories/ECOM_LVL1_00000006/Bently-Nevada',
    '/bently-nevada/my-orders',
    '/rma-tracking',
    '/rma-form',
    '/bently-nevada/calibration-data',
    '/bently-nevada/track-order',
    '/bently-nevada/contactus',
    '/list-of-portals',
  ],
  [AllProductLine.reuterStokes]: [
    '/reuter-stokes/categories/ECOM_LVL1_00000009/Reuter-Stokes',
    '/reuter-stokes/my-orders',
    '/rma-tracking',
    '/rma-form',
    '/reuter-stokes/calibration-data',
    '/reuter-stokes/track-order',
    '/reuter-stokes/contactus',
    '/list-of-portals',
  ],
};

export const quickLinks = Object.fromEntries(
  Object.entries(specificLinks).map(([key, links]) => [
    key,
    commonLinks.map((link, index) => ({ ...link, link: links[index] })),
  ])
);
