import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  productLineCode,
  servicesList,
  industriesList,
  usefulLinks,
} from './../../../shared/products-constants';
import { AuthService } from '@spartacus/core';
import { environment } from '../../../../environments/environment';
import { CustomerAccountService } from '../../../core/customer-account/customer-account.service';
import { ProductCategoriesService } from '../../../core/product-catalog/services/product-categories.service';
import { AllProductLine } from '../../../shared/enums/availableProductList.enum';

declare const Optanon: any;
@Component({
  standalone: false,
  selector: 'app-waygate-footer',
  templateUrl: './waygate-footer.component.html',
  styleUrls: ['./waygate-footer.component.scss'],
})
export class WaygateFooterComponent implements OnInit {
  productLine: string;
  productCategories$: Observable<any>;
  // salesAreas = ['ECOM_LVL1_00000001'];
  productLineCode: string[];
  servicesList: any[];
  industriesList: any[];
  supportList: any[];
  supportListGuest: any[];
  usefulLinksList: any[];
  @Input() isChooseBrandPage: boolean = false;
  contactUsUrl: string;
  isIndustriesVisible: boolean = true;
  productNotFoundList: any;
  isServicesVisible: boolean = true;
  mobProductListToggle = false;
  mobServiceToggle = false;
  mobIndustriesToggle = false;
  mobUSefulLinksToggle = false;
  constructor(
    private productCategoriesService: ProductCategoriesService,
    private custAccService: CustomerAccountService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      this.isIndustriesVisible =
        this.productLine === AllProductLine.reuterStokes ? false : true;
      this.isServicesVisible =
        this.productLine === AllProductLine.druck ? false : true;
      this.productLineCode = productLineCode[this.productLine]?.split(' ');
      this.servicesList = servicesList[this.productLine];
      this.industriesList = industriesList[this.productLine];
      this.usefulLinksList = usefulLinks[this.productLine];
    });

    this.contactUsUrl = `/${this.productLine}/contactus`;

    this.productCategories$ =
      this.productCategoriesService.fetchDefaultProductCategories();
  }
  openCookies() {
    Optanon.ToggleInfoDisplay();
  }
  openTerms() {
    const termsDocUrl =
      environment.occBaseUrl +
      `/_ui/responsive/common/images/DSe-CommercePortalTermsofUse.pdf`;
    (window as any).open(termsDocUrl, '_blank');
  }

  constructCategoryUrl(category) {
    return `/${this.productLine}/categories/${category.code}/${category.name}`;
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
