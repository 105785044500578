var asmCustomer360$w = {
  alertErrorMessage: "Informace nelze načíst. Opakujte později nebo se obraťte na správce systému.",
  errorMessageHeader: "Jejda! Něco se pokazilo.",
  applyActionAlter: "Akci nelze dokončit. Zkuste to později znovu.",
  header: {
    title: "Profil zákazníka",
    newTitle: "Zákazník 360°",
    subTitle: "{{name}} Zobrazení Zákazník 360°",
    newSubTitle: "{{name}} Zobrazení 360",
    activeCartLabel: "{{cartSize}} položka přidána do košíku",
    recentOrderLabel: "Poslední objednávka {{price}}",
    recentTicketLabel: "Poslední tiket",
    signedUpAt: "Účet by vytvořen dne {{date}}"
  },
  productListing: {
    totalNoItems: "Celkový počet položek {{count}}",
    totalPrice: "Cena celkem {{price}}",
    showMore: "Zobrazit více",
    showLess: "Zobrazit méně"
  },
  productItem: {
    outOfStock: "Není skladem",
    quantity: "Množství: {{count}}",
    itemPrice: "Cena položky: {{price}}"
  },
  activeCart: {
    header: "Aktivní košík",
    emptyDescription: "Momentálně neexistují žádné položky aktivního košíku",
    aria: {
      linkLabel: "Aktivní košík {{code}}"
    }
  },
  savedCart: {
    header: "Poslední uložený košík",
    emptyDescription: "Momentálně neexistují žádné položky uloženého košíku",
    aria: {
      linkLabel: "Poslední uložený košík {{code}}"
    }
  },
  productInterests: {
    header: "Zájmové oblasti",
    emptyDescription: "Momentálně neexistují žádné položky zájmu"
  },
  profile: {
    address: "Adresa",
    billingAddress: "Fakturační adresa",
    deliveryAddress: "Dodací adresa",
    phone1: "Telefon 1",
    phone2: "Telefon 2",
    paymentMethodHeader: "Uložené způsoby platby"
  },
  activity: {
    type: "Typ",
    id: "ID",
    description: "Popis",
    status: "Status",
    created: "Vytvořeno",
    updated: "Aktualizováno",
    numberOfCartItems: "Košík s {{count}} položkami",
    cart: "Košík",
    order: "Objednávka",
    savedCart: "Uložený košík",
    emptyStateText: "Momentálně neexistuje žádná zaznamenaná činnost zákazníka",
    headerText: "Všeobecné"
  },
  productReviews: {
    columnHeaders: {
      item: "Položka",
      dateAndStatus: "Datum / status",
      rating: "Hodnocení",
      review: "Recenze"
    },
    header: "Recenze produktů",
    emptyDescription: "Momentálně neexistují žádné položky recenze produktů",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Nadpis",
      category: "Kategorie"
    },
    header: "Tikety podpory",
    emptyDescription: "Momentálně neexistují žádné tikety podpory"
  },
  coupons: {
    headerText: "Kupóny",
    emptyDescription: "Momentálně neexistují žádné kupóny",
    applyButtonText: "Použít v košíku",
    applied: "Kupón je použitý",
    removeButtonText: "Odebrat"
  },
  promotions: {
    headerText: "Akce",
    emptyDescription: "Momentálně neexistují žádné akce",
    applied: "Akce je využitá",
    headerName: "Název",
    headerAction: "Akce"
  },
  customerCoupons: {
    headerText: "Kupóny zákazníka",
    emptyDescription: "Momentálně neexistují žádné kupóny zákazníka.",
    applyButtonText: "Přiřadit zákazníkovi",
    removeButtonText: "Odebrat",
    availableTab: "K dispozici",
    sentTab: "Odeslané",
    searchBox: "Kupón"
  },
  maps: {
    storeClosed: "Zavřít",
    storesFound: "Nalezeno {{ initial }} – {{ end }} z {{ total }} prodejen"
  },
  overviewTab: "Přehled",
  profileTab: "Profil",
  activityTab: "Činnost",
  feedbackTab: "Zpětná vazba",
  promotionsTab: "Akce",
  mapsTab: "Mapy",
  storeLocationsTab: "Místa obchodů",
  aria: {
    activeCartCode: "Aktivní košík {{code}}",
    recentOrderCode: "Poslední objednávka {{code}}"
  },
  pagination: "Stránkování",
  page: "Strana {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$x = {
  asmCustomer360: asmCustomer360$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  asmCustomer360: asmCustomer360$x
};
var asmCustomer360$u = {
  alertErrorMessage: "Die Informationen können nicht geladen werden. Versuchen Sie es später erneut, oder wenden Sie sich an Ihren Systemadministrator.",
  errorMessageHeader: "Hoppla, etwas ist schiefgegangen",
  applyActionAlter: "Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es später erneut.",
  header: {
    title: "Kundenprofil",
    newTitle: "Kunde 360°",
    subTitle: "{{name}} − 360°-Kundensicht",
    newSubTitle: "{{name}} − 360°-Sicht",
    activeCartLabel: "{{cartSize}} Position wurde dem Einkaufswagen hinzugefügt",
    recentOrderLabel: "Letzte Bestellung {{price}}",
    recentTicketLabel: "Letztes Ticket",
    signedUpAt: "Konto am {{date}} erstellt"
  },
  productListing: {
    totalNoItems: "Gesamtanzahl der Positionen {{count}}",
    totalPrice: "Gesamtpreis {{price}}",
    showMore: "Mehr anzeigen",
    showLess: "Weniger anzeigen"
  },
  productItem: {
    outOfStock: "Nicht vorrätig",
    quantity: "Menge: {{count}}",
    itemPrice: "Positionspreis: {{price}}"
  },
  activeCart: {
    header: "Aktiver Warenkorb",
    emptyDescription: "Zurzeit sind keine Positionen eines aktiven Warenkorbs vorhanden",
    aria: {
      linkLabel: "Aktiver Warenkorb {{code}}"
    }
  },
  savedCart: {
    header: "Zuletzt gespeicherter Warenkorb",
    emptyDescription: "Zurzeit sind keine Positionen eines gespeicherten Warenkorbs vorhanden",
    aria: {
      linkLabel: "Zuletzt gespeicherter Warenkorb {{code}}"
    }
  },
  productInterests: {
    header: "Interessen",
    emptyDescription: "Zurzeit sind keine Positionen von Interesse vorhanden"
  },
  profile: {
    address: "Adresse",
    billingAddress: "Rechnungsadresse",
    deliveryAddress: "Lieferadresse",
    phone1: "Telefon 1",
    phone2: "Telefon 2",
    paymentMethodHeader: "Gespeicherte Zahlungsarten"
  },
  activity: {
    type: "Art",
    id: "ID",
    description: "Beschreibung",
    status: "Status",
    created: "Erstellt",
    updated: "Aktualisiert",
    numberOfCartItems: "Warenkorb mit {{count}} Positionen",
    cart: "Warenkorb",
    order: "Bestellung",
    savedCart: "Gespeicherter Warenkorb",
    emptyStateText: "Zurzeit ist keine erfasste Kundenaktivität vorhanden",
    headerText: "Allgemein"
  },
  productReviews: {
    columnHeaders: {
      item: "Position",
      dateAndStatus: "Datum/Status",
      rating: "Bewertung",
      review: "Rezension"
    },
    header: "Produktrezensionen",
    emptyDescription: "Zurzeit sind keine Positionen mit Produktrezensionen vorhanden",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Überschrift",
      category: "Kategorie"
    },
    header: "Supportanfragen",
    emptyDescription: "Zurzeit sind keine Supportanfragen vorhanden"
  },
  coupons: {
    headerText: "Coupons",
    emptyDescription: "Zurzeit sind keine Coupons vorhanden",
    applyButtonText: "Auf Warenkorb anwenden",
    applied: "Coupon angewendet",
    removeButtonText: "Entfernen"
  },
  promotions: {
    headerText: "Werbeaktionen",
    emptyDescription: "Zurzeit gibt es keine Werbeaktionen",
    applied: "Werbeaktion angewendet",
    headerName: "Name",
    headerAction: "Aktion"
  },
  customerCoupons: {
    headerText: "Kundencoupons",
    emptyDescription: "Zurzeit sind keine Kundencoupons vorhanden",
    applyButtonText: "Dem Kunden zuordnen",
    removeButtonText: "Entfernen",
    availableTab: "Verfügbar",
    sentTab: "Gesendet",
    searchBox: "Coupon"
  },
  maps: {
    storeClosed: "Schließen",
    storesFound: "{{ initial }} - {{ end }} von {{ total }} Filialen gefunden"
  },
  overviewTab: "Übersicht",
  profileTab: "Profil",
  activityTab: "Aktivität",
  feedbackTab: "Feedback",
  promotionsTab: "Werbeaktionen",
  mapsTab: "Karten",
  storeLocationsTab: "Filialstandorte",
  aria: {
    activeCartCode: "Aktiver Warenkorb {{code}}",
    recentOrderCode: "Letzte Bestellung {{code}}"
  },
  pagination: "Paginierung",
  page: "Seite {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$v = {
  asmCustomer360: asmCustomer360$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  asmCustomer360: asmCustomer360$v
};
var asmCustomer360$s = {
  alertErrorMessage: "The information cannot be be loaded. Please try again later or contact your system administrator.",
  errorMessageHeader: "Oops! Something went wrong",
  applyActionAlter: "The action couldn't be completed. Please try again later.",
  header: {
    title: "Customer Profile",
    newTitle: "Customer 360°",
    subTitle: "{{name}} Customer 360° View",
    newSubTitle: "{{name}} 360 View",
    activeCartLabel: "{{cartSize}} item added to cart",
    recentOrderLabel: "Last order {{price}}",
    recentTicketLabel: "Last ticket",
    signedUpAt: "Account created on {{date}}"
  },
  productListing: {
    totalNoItems: "Total No. Items {{count}}",
    totalPrice: "Total Price {{price}}",
    showMore: "Show More",
    showLess: "Show Less"
  },
  productItem: {
    outOfStock: "Out of Stock",
    quantity: "Qty: {{count}}",
    itemPrice: "Item Price: {{price}}"
  },
  activeCart: {
    header: "Active Cart",
    emptyDescription: "There are currently no active cart Items",
    aria: {
      linkLabel: "Active Cart {{code}}"
    }
  },
  savedCart: {
    header: "Last Saved Cart",
    emptyDescription: "There are currently no saved cart items",
    aria: {
      linkLabel: "Last Saved Cart {{code}}"
    }
  },
  productInterests: {
    header: "Interests",
    emptyDescription: "There are currently no interest items"
  },
  profile: {
    address: "Address",
    billingAddress: "Billing Address",
    deliveryAddress: "Delivery Address",
    phone1: "Phone 1",
    phone2: "Phone 2",
    paymentMethodHeader: "Saved Payment Methods"
  },
  activity: {
    type: "Type",
    id: "Id",
    description: "Description",
    status: "Status",
    created: "Created",
    updated: "Updated",
    numberOfCartItems: "Cart with {{count}} items",
    cart: "Cart",
    order: "Order",
    savedCart: "Saved Cart",
    emptyStateText: "There is currently no recorded customer activity",
    headerText: "General"
  },
  productReviews: {
    columnHeaders: {
      item: "Item",
      dateAndStatus: "Date / Status",
      rating: "Rating",
      review: "Review"
    },
    header: "Product Reviews",
    emptyDescription: "There are currently no product review items",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Headline",
      category: "Category"
    },
    header: "Support Tickets",
    emptyDescription: "There are currently no support tickets"
  },
  coupons: {
    headerText: "Coupons",
    emptyDescription: "There are currently no coupons",
    applyButtonText: "Apply to Cart",
    applied: "Coupon Applied",
    removeButtonText: "Remove"
  },
  promotions: {
    headerText: "Promotions",
    emptyDescription: "There are currently no promotions",
    applied: "Promotion Applied",
    headerName: "Name",
    headerAction: "Action"
  },
  customerCoupons: {
    headerText: "Customer Coupons",
    emptyDescription: "There are currently no customer coupons",
    applyButtonText: "Assign to Customer",
    removeButtonText: "Remove",
    availableTab: "Available",
    sentTab: "Sent",
    searchBox: "Coupon"
  },
  maps: {
    storeClosed: "Close",
    storesFound: "{{ initial }} - {{ end }} from {{ total }} stores found"
  },
  overviewTab: "Overview",
  profileTab: "Profile",
  activityTab: "Activity",
  feedbackTab: "Feedback",
  promotionsTab: "Promotions",
  mapsTab: "Maps",
  storeLocationsTab: "Store Locations",
  aria: {
    activeCartCode: "Active Cart {{code}}",
    recentOrderCode: "Last Order {{code}}"
  },
  pagination: "Pagination",
  page: "Page {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$t = {
  asmCustomer360: asmCustomer360$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  asmCustomer360: asmCustomer360$t
};
var asmCustomer360$q = {
  alertErrorMessage: "La información no se puede cargar. Inténtelo de nuevo más tarde o póngase en contacto con el administrador del sistema.",
  errorMessageHeader: "Se produjo un error",
  applyActionAlter: "No se pudo completar la acción. Inténtelo de nuevo más tarde.",
  header: {
    title: "Perfil del cliente",
    newTitle: "Customer 360°",
    subTitle: "{{name}} Customer 360° View",
    newSubTitle: "{{name}} 360 View",
    activeCartLabel: "{{cartSize}} artículo añadido a la cesta",
    recentOrderLabel: "Último pedido {{price}}",
    recentTicketLabel: "Último ticket",
    signedUpAt: "Cuenta creada el {{date}}"
  },
  productListing: {
    totalNoItems: "Cantidad total de artículos {{count}}",
    totalPrice: "Precio total {{price}}",
    showMore: "Mostrar más",
    showLess: "Mostrar menos"
  },
  productItem: {
    outOfStock: "No disponible en stock",
    quantity: "Ctd.: {{count}}",
    itemPrice: "Precio del artículo: {{price}}"
  },
  activeCart: {
    header: "Cesta activa",
    emptyDescription: "Actualmente no hay artículos en la cesta activa",
    aria: {
      linkLabel: "Cesta activa {{code}}"
    }
  },
  savedCart: {
    header: "Última cesta grabada",
    emptyDescription: "Actualmente no hay artículos guardados en la cesta",
    aria: {
      linkLabel: "Última cesta grabada {{code}}"
    }
  },
  productInterests: {
    header: "Intereses",
    emptyDescription: "Actualmente no hay artículos de intereses"
  },
  profile: {
    address: "Dirección",
    billingAddress: "Dirección de facturación",
    deliveryAddress: "Dirección de entrega",
    phone1: "Teléfono 1",
    phone2: "Teléfono 2",
    paymentMethodHeader: "Métodos de pago grabados"
  },
  activity: {
    type: "Tipo",
    id: "ID",
    description: "Descripción",
    status: "Estado",
    created: "Creado",
    updated: "Actualizado",
    numberOfCartItems: "Cesta con {{count}} artículos",
    cart: "Cesta",
    order: "Pedido",
    savedCart: "Cesta guardada",
    emptyStateText: "Actualmente no hay actividad de cliente registrada",
    headerText: "General"
  },
  productReviews: {
    columnHeaders: {
      item: "Artículo",
      dateAndStatus: "Fecha/Estado",
      rating: "Clasificación",
      review: "Reseñas"
    },
    header: "Reseñas del producto",
    emptyDescription: "Actualmente no hay artículos con reseñas del producto",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Título",
      category: "Categoría"
    },
    header: "Tickets de soporte",
    emptyDescription: "Actualmente no hay tickets de soporte"
  },
  coupons: {
    headerText: "Cupones",
    emptyDescription: "Actualmente no hay cupones",
    applyButtonText: "Aplicar a la cesta",
    applied: "Cupón aplicado",
    removeButtonText: "Eliminar"
  },
  promotions: {
    headerText: "Promociones",
    emptyDescription: "Actualmente no hay promociones",
    applied: "Promoción aplicada",
    headerName: "Nombre",
    headerAction: "Acción"
  },
  customerCoupons: {
    headerText: "Cupones del cliente",
    emptyDescription: "Actualmente no hay cupones del cliente",
    applyButtonText: "Asignar a cliente",
    removeButtonText: "Eliminar",
    availableTab: "Disponible",
    sentTab: "Enviado",
    searchBox: "Cupón"
  },
  maps: {
    storeClosed: "Cerrar",
    storesFound: "Existen {{ initial }} - {{ end }} de {{ total }} tiendas"
  },
  overviewTab: "Resumen",
  profileTab: "Perfil",
  activityTab: "Actividad",
  feedbackTab: "Comentarios",
  promotionsTab: "Promociones",
  mapsTab: "Mapas",
  storeLocationsTab: "Ubicaciones de almacén",
  aria: {
    activeCartCode: "Cesta activa {{code}}",
    recentOrderCode: "Último pedido {{code}}"
  },
  pagination: "Paginación",
  page: "Página {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$r = {
  asmCustomer360: asmCustomer360$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  asmCustomer360: asmCustomer360$r
};
var asmCustomer360$o = {
  alertErrorMessage: "La información no se puede cargar. Inténtelo de nuevo más tarde o póngase en contacto con el administrador del sistema.",
  errorMessageHeader: "Se produjo un error",
  applyActionAlter: "No se pudo completar la acción. Inténtelo de nuevo más tarde.",
  header: {
    title: "Perfil del cliente",
    newTitle: "Customer 360°",
    subTitle: "{{name}} Customer 360° View",
    newSubTitle: "{{name}} 360 View",
    activeCartLabel: "{{cartSize}} artículo agregado al carro",
    recentOrderLabel: "Último pedido {{price}}",
    recentTicketLabel: "Último ticket",
    signedUpAt: "Cuenta creada el {{date}}"
  },
  productListing: {
    totalNoItems: "Cantidad total de artículos {{count}}",
    totalPrice: "Precio total {{price}}",
    showMore: "Mostrar más",
    showLess: "Mostrar menos"
  },
  productItem: {
    outOfStock: "No disponible en stock",
    quantity: "Ctd.: {{count}}",
    itemPrice: "Precio del artículo: {{price}}"
  },
  activeCart: {
    header: "Cesta activa",
    emptyDescription: "Actualmente no hay artículos en el carro activo",
    aria: {
      linkLabel: "Carro activo {{code}}"
    }
  },
  savedCart: {
    header: "Último carro guardado",
    emptyDescription: "Actualmente no hay artículos guardados en el carro",
    aria: {
      linkLabel: "Último carro guardado {{code}}"
    }
  },
  productInterests: {
    header: "Intereses",
    emptyDescription: "Actualmente no hay artículos de intereses"
  },
  profile: {
    address: "Dirección",
    billingAddress: "Dirección de facturación",
    deliveryAddress: "Dirección de entrega",
    phone1: "Teléfono 1",
    phone2: "Teléfono 2",
    paymentMethodHeader: "Métodos de pago guardados"
  },
  activity: {
    type: "Tipo",
    id: "ID",
    description: "Descripción",
    status: "Estado",
    created: "Creado",
    updated: "Actualizado",
    numberOfCartItems: "Carro con {{count}} artículos",
    cart: "Carro",
    order: "Pedido",
    savedCart: "Carro guardado",
    emptyStateText: "Actualmente no hay actividad de cliente registrada",
    headerText: "General"
  },
  productReviews: {
    columnHeaders: {
      item: "Artículo",
      dateAndStatus: "Fecha/Estado",
      rating: "Clasificación",
      review: "Reseñas"
    },
    header: "Reseñas del producto",
    emptyDescription: "Actualmente no hay artículos con reseñas del producto",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Título",
      category: "Categoría"
    },
    header: "Tickets de soporte",
    emptyDescription: "Actualmente no hay tickets de soporte"
  },
  coupons: {
    headerText: "Cupones",
    emptyDescription: "Actualmente no hay cupones",
    applyButtonText: "Aplicar al carro",
    applied: "Cupón aplicado",
    removeButtonText: "Eliminar"
  },
  promotions: {
    headerText: "Promociones",
    emptyDescription: "Actualmente no hay promociones",
    applied: "Promoción aplicada",
    headerName: "Nombre",
    headerAction: "Acción"
  },
  customerCoupons: {
    headerText: "Cupones del cliente",
    emptyDescription: "Actualmente no hay cupones del cliente",
    applyButtonText: "Asignar a cliente",
    removeButtonText: "Eliminar",
    availableTab: "Disponible",
    sentTab: "Enviado",
    searchBox: "Cupón"
  },
  maps: {
    storeClosed: "Cerrar",
    storesFound: "Existen {{ initial }} - {{ end }} de {{ total }} tiendas"
  },
  overviewTab: "Resumen",
  profileTab: "Perfil",
  activityTab: "Actividad",
  feedbackTab: "Comentarios",
  promotionsTab: "Promociones",
  mapsTab: "Mapas",
  storeLocationsTab: "Ubicaciones de almacén",
  aria: {
    activeCartCode: "Carro activo {{code}}",
    recentOrderCode: "Último pedido {{code}}"
  },
  pagination: "Paginación",
  page: "Página {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$p = {
  asmCustomer360: asmCustomer360$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  asmCustomer360: asmCustomer360$p
};
var asmCustomer360$m = {
  alertErrorMessage: "Impossible de charger les informations. Veuillez réessayer ultérieurement ou contacter votre administrateur système.",
  errorMessageHeader: "Oups ! une erreur s'est produite",
  applyActionAlter: "Impossible de terminer l'action. Veuillez réessayer ultérieurement.",
  header: {
    title: "Profil client",
    newTitle: "Client 360°",
    subTitle: "Vue client à 360° {{name}}",
    newSubTitle: "Vue à 360° {{name}} ",
    activeCartLabel: "{{cartSize}} article ajouté au panier",
    recentOrderLabel: "Dernière commande {{price}}",
    recentTicketLabel: "Dernier ticket",
    signedUpAt: "Compte créé le {{date}}"
  },
  productListing: {
    totalNoItems: "Nombre total d'articles {{count}}",
    totalPrice: "Prix total {{price}}",
    showMore: "Afficher plus",
    showLess: "Afficher moins"
  },
  productItem: {
    outOfStock: "Épuisé",
    quantity: "Qté : {{count}}",
    itemPrice: "Prix de l'article : {{price}}"
  },
  activeCart: {
    header: "Panier actif",
    emptyDescription: "Il n'y a actuellement aucun article dans le panier actif.",
    aria: {
      linkLabel: "Panier actif {{code}}"
    }
  },
  savedCart: {
    header: "Dernier panier enregistré",
    emptyDescription: "Il n'y a actuellement aucun article dans le panier enregistré.",
    aria: {
      linkLabel: "Dernier panier enregistré {{code}}"
    }
  },
  productInterests: {
    header: "Intérêts",
    emptyDescription: "Il n'y a actuellement aucun élément d'intérêt."
  },
  profile: {
    address: "Adresse",
    billingAddress: "Adresse de facturation",
    deliveryAddress: "Adresse de livraison",
    phone1: "Téléphone 1",
    phone2: "Téléphone 2",
    paymentMethodHeader: "Modes de paiement enregistrés"
  },
  activity: {
    type: "Type",
    id: "ID",
    description: "Description",
    status: "Statut",
    created: "Créé",
    updated: "Mis à jour",
    numberOfCartItems: "Panier avec {{count}} articles",
    cart: "Panier",
    order: "Commande",
    savedCart: "Panier enregistré",
    emptyStateText: "Il n'y a actuellement aucune activité client enregistrée.",
    headerText: "Général"
  },
  productReviews: {
    columnHeaders: {
      item: "Article",
      dateAndStatus: "Date / Statut",
      rating: "Notation",
      review: "Évaluation"
    },
    header: "Évaluations de produits",
    emptyDescription: "Il n'y a actuellement aucun élément d'évaluation de produit.",
    sku: "UGS"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Titre",
      category: "Catégorie"
    },
    header: "Tickets de support",
    emptyDescription: "Il n'y a actuellement aucun ticket de support."
  },
  coupons: {
    headerText: "Bons de réduction",
    emptyDescription: "Il n'y a actuellement aucun bon de réduction.",
    applyButtonText: "Appliquer au panier",
    applied: "Bon de réduction appliqué",
    removeButtonText: "Supprimer"
  },
  promotions: {
    headerText: "Promotions",
    emptyDescription: "Il n'y a actuellement aucune promotion.",
    applied: "Promotion appliquée",
    headerName: "Nom",
    headerAction: "Action"
  },
  customerCoupons: {
    headerText: "Bons de réduction client",
    emptyDescription: "Il n’y a actuellement aucun bon de réduction client.",
    applyButtonText: "Affecter au client",
    removeButtonText: "Supprimer",
    availableTab: "Disponible",
    sentTab: "Envoyé",
    searchBox: "Bon de réduction"
  },
  maps: {
    storeClosed: "Fermer",
    storesFound: "{{ initial }} - {{ end }} de {{ total }} points de vente trouvés"
  },
  overviewTab: "Synthèse",
  profileTab: "Profil",
  activityTab: "Activité",
  feedbackTab: "Feedback",
  promotionsTab: "Promotions",
  mapsTab: "Cartes",
  storeLocationsTab: "Lieux des points de vente",
  aria: {
    activeCartCode: "Panier actif {{code}}",
    recentOrderCode: "Dernière commande {{code}}"
  },
  pagination: "Pagination",
  page: "Page {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$n = {
  asmCustomer360: asmCustomer360$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  asmCustomer360: asmCustomer360$n
};
var asmCustomer360$k = {
  alertErrorMessage: "यह जानकारी लोड नहीं की जा सकती. कृपया बाद में कोशिश करें या अपने सिस्टम व्यवस्थापक से संपर्क करें.",
  errorMessageHeader: "ओह! कुछ गलत हुआ है.",
  applyActionAlter: "कार्रवाई पूर्ण नहीं की जा सकी. कृपया फिर से कोशिश करें.",
  header: {
    title: "ग्राहक प्रोफाइल",
    newTitle: "ग्राहक 360°",
    subTitle: "{{name}} ग्राहक 360° दृश्य",
    newSubTitle: "{{name}} 360 दृश्य",
    activeCartLabel: "{{cartSize}} आइटम कार्ट में जोड़ा गया",
    recentOrderLabel: "अंतिम ऑर्डर {{price}}",
    recentTicketLabel: "अंतिम टिकट",
    signedUpAt: "{{date}} पर खाता बनाया गया"
  },
  productListing: {
    totalNoItems: "आइटम की कुल संख्या {{count}}",
    totalPrice: "कुल मूल्यः {{price}}",
    showMore: "अधिक दिखाएं",
    showLess: "कम दिखाएं"
  },
  productItem: {
    outOfStock: "स्टॉक में नहीं है",
    quantity: "मात्राः {{count}}",
    itemPrice: "आइटम मूल्यः {{price}}"
  },
  activeCart: {
    header: "सक्रिय कार्ट",
    emptyDescription: "वर्तमान में कोई सक्रिय कार्ट आइटम नहीं है",
    aria: {
      linkLabel: "सक्रिय कार्ट {{code}}"
    }
  },
  savedCart: {
    header: "अंतिम सहेजा हुआ कार्ट",
    emptyDescription: "वर्तमान में कोई सहेजा हुआ कार्ट आइटम नहीं है",
    aria: {
      linkLabel: "अंतिम सहेजा हुआ कार्ट {{code}}"
    }
  },
  productInterests: {
    header: "ब्याज",
    emptyDescription: "वर्तमान में कोई ब्याज आइटम नहीं हैं"
  },
  profile: {
    address: "पता",
    billingAddress: "बिलिंग पता",
    deliveryAddress: "वितरण का पता",
    phone1: "फ़ोन 1",
    phone2: "फ़ोन 2",
    paymentMethodHeader: "सहेजी गई भुगतान विधियां"
  },
  activity: {
    type: "प्रकार",
    id: "Id",
    description: "वर्णन",
    status: "स्थिति",
    created: "बनाया गया",
    updated: "अपडेट किया गया",
    numberOfCartItems: "{{count}} आइटम सहित कार्ट",
    cart: "कार्ट",
    order: "ऑर्डर",
    savedCart: "सहेजे गए कार्ट",
    emptyStateText: "वर्तमान में कोई रिकॉर्ड की गई ग्राहक गतिविधि नहीं है",
    headerText: "सामान्य"
  },
  productReviews: {
    columnHeaders: {
      item: "आइटम",
      dateAndStatus: "दिनांक / स्थिति",
      rating: "रेटिंग",
      review: "समीक्षा"
    },
    header: "उत्पाद समीक्षाएं",
    emptyDescription: "वर्तमान में कोई उत्पाद समीक्षा आइटम नहीं है",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "शीर्षक",
      category: "श्रेणी"
    },
    header: "सहायता टिकट",
    emptyDescription: "वर्तमान में कोई समर्थन टिकट नहीं है"
  },
  coupons: {
    headerText: "कूपन",
    emptyDescription: "वर्तमान में कोई कूपन नहीं हैं",
    applyButtonText: "कार्ट पर लागू करें",
    applied: "कूपन लागू किया गया",
    removeButtonText: "निकालें"
  },
  promotions: {
    headerText: "प्रचार",
    emptyDescription: "वर्तमान में कोई प्रचार नहीं है",
    applied: "प्रचार लागू किया गया",
    headerName: "नाम",
    headerAction: "कार्रवाई"
  },
  customerCoupons: {
    headerText: "ग्राहक कूपन",
    emptyDescription: "वर्तमान में कोई ग्राहक कूपन नहीं हैं",
    applyButtonText: "ग्राहक को असाइन करें",
    removeButtonText: "निकालें",
    availableTab: "उपलब्ध",
    sentTab: "प्रेषित",
    searchBox: "कूपन"
  },
  maps: {
    storeClosed: "बंद करें",
    storesFound: "{{ total }} से {{ initial }} - {{ end }} स्टोर मिले"
  },
  overviewTab: "ओवरव्यू",
  profileTab: "प्रोफाइल",
  activityTab: "गतिविधि",
  feedbackTab: "फ़ीडबैक",
  promotionsTab: "प्रचार",
  mapsTab: "मैप",
  storeLocationsTab: "स्टोर स्थान",
  aria: {
    activeCartCode: "सक्रिय कार्ट {{code}}",
    recentOrderCode: "अंतिम ऑर्डर {{code}}"
  },
  pagination: "पृष्ठांकन",
  page: "पृष्ठ {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$l = {
  asmCustomer360: asmCustomer360$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  asmCustomer360: asmCustomer360$l
};
var asmCustomer360$i = {
  alertErrorMessage: "Nem lehet betölteni az információkat. Próbálja újra később, vagy forduljon a rendszergazdájához.",
  errorMessageHeader: "Hoppá! Hiba történt.",
  applyActionAlter: "A műveletet nem lehetett végrehajtani. Próbálja újra később.",
  header: {
    title: "Ügyfélprofil",
    newTitle: "Ügyfél 360°",
    subTitle: "{{name}} Ügyfél 360°-os nézete",
    newSubTitle: "{{name}} 360 fokos nézet",
    activeCartLabel: "{{cartSize}} tétel hozzáadva a kosárhoz",
    recentOrderLabel: "Legutóbbi rendelés: {{price}}",
    recentTicketLabel: "Utolsó jegy",
    signedUpAt: "A fiók létrehozásának dátuma: {{date}}"
  },
  productListing: {
    totalNoItems: "Összes tétel száma: {{count}}",
    totalPrice: "Teljes ár: {{price}}",
    showMore: "Több megjelenítése",
    showLess: "Kevesebb megjelenítése"
  },
  productItem: {
    outOfStock: "Nincs készleten",
    quantity: "Menny.: {{count}}",
    itemPrice: "Tétel ára: {{price}}"
  },
  activeCart: {
    header: "Aktív kosár",
    emptyDescription: "Jelenleg nincsenek aktív kosártételek",
    aria: {
      linkLabel: "Aktív kosár: {{code}}"
    }
  },
  savedCart: {
    header: "Legutóbb mentett kosár",
    emptyDescription: "Jelenleg nincsenek mentett kosártételek",
    aria: {
      linkLabel: "Legutóbb mentett kosár: {{code}}"
    }
  },
  productInterests: {
    header: "Érdeklődési körök",
    emptyDescription: "Jelenleg nincsenek az érdeklődési körnek megfelelő tételek"
  },
  profile: {
    address: "Cím",
    billingAddress: "Számlázási cím",
    deliveryAddress: "Kézbesítési cím",
    phone1: "Telefonszám 1",
    phone2: "Telefonszám 2",
    paymentMethodHeader: "Mentett fizetési módok"
  },
  activity: {
    type: "Típus",
    id: "Azonosító",
    description: "Leírás",
    status: "Státus",
    created: "Létrehozva",
    updated: "Aktualizálva",
    numberOfCartItems: "Kosár {{count}} tétellel",
    cart: "Kosár",
    order: "Rendelés",
    savedCart: "Mentett bevásárlókosár",
    emptyStateText: "Jelenleg nincs rögzített ügyféltevékenység",
    headerText: "Általános"
  },
  productReviews: {
    columnHeaders: {
      item: "Tétel",
      dateAndStatus: "Dátum / Státus",
      rating: "Értékelés",
      review: "Vélemény"
    },
    header: "Termékvélemények",
    emptyDescription: "Jelenleg nincsenek termékvélemény-tételek",
    sku: "Cikkszám"
  },
  supportTickets: {
    columnHeaders: {
      id: "Azonosító",
      headline: "Címsor",
      category: "Kategória"
    },
    header: "Támogatási jegyek",
    emptyDescription: "Jelenleg nincsenek támogatási jegyek"
  },
  coupons: {
    headerText: "Kuponok",
    emptyDescription: "Jelenleg nincsenek kuponok",
    applyButtonText: "Alkalmazás a kosárra",
    applied: "Kupon alkalmazva",
    removeButtonText: "Eltávolítás"
  },
  promotions: {
    headerText: "Promóciók",
    emptyDescription: "Jelenleg nincsenek promóciók",
    applied: "A promóció alkalmazva",
    headerName: "Név",
    headerAction: "Művelet"
  },
  customerCoupons: {
    headerText: "Ügyfélkuponok",
    emptyDescription: "Jelenleg nincsenek ügyfélkuponok",
    applyButtonText: "Hozzárendelés ügyfélhez",
    removeButtonText: "Eltávolítás",
    availableTab: "Elérhető",
    sentTab: "Elküldve",
    searchBox: "Kupon"
  },
  maps: {
    storeClosed: "Bezárás",
    storesFound: "{{ initial }}–{{ end }} a talált {{ total }} üzletből"
  },
  overviewTab: "Áttekintés",
  profileTab: "Profil",
  activityTab: "Tevékenység",
  feedbackTab: "Visszajelzés",
  promotionsTab: "Promóciók",
  mapsTab: "Térképek",
  storeLocationsTab: "Üzletek helye",
  aria: {
    activeCartCode: "Aktív kosár: {{code}}",
    recentOrderCode: "Legutóbbi rendelés: {{code}}"
  },
  pagination: "Lapszámozás",
  page: "{{number}}. oldal",
  emptyCellValue: "---"
};
var asmCustomer360$j = {
  asmCustomer360: asmCustomer360$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  asmCustomer360: asmCustomer360$j
};
var asmCustomer360$g = {
  alertErrorMessage: "Informasi tidak dapat dimuat. Silakan coba lagi nanti atau hubungi administrator sistem Anda.",
  errorMessageHeader: "Ups, terjadi kesalahan",
  applyActionAlter: "Tindakan tidak dapat diselesaikan. Silakan coba lagi nanti.",
  header: {
    title: "Profil Pelanggan",
    newTitle: "Tampilan 360° - Pelanggan",
    subTitle: "Tampilan 360° Pelanggan {{name}}",
    newSubTitle: "Tampilan 360° {{name}}",
    activeCartLabel: "{{cartSize}} item ditambahkan ke troli",
    recentOrderLabel: "Pesanan terakhir {{price}}",
    recentTicketLabel: "Tiket terakhir",
    signedUpAt: "Akun dibuat pada {{date}}"
  },
  productListing: {
    totalNoItems: "Total Jumlah Item {{count}}",
    totalPrice: "Total Harga {{price}}",
    showMore: "Tampilkan Lebih Banyak",
    showLess: "Tampilkan Lebih Sedikit"
  },
  productItem: {
    outOfStock: "Stok Habis",
    quantity: "Jml: {{count}}",
    itemPrice: "Harga Item: {{price}}"
  },
  activeCart: {
    header: "Keranjang Aktif",
    emptyDescription: "Saat ini tidak ada Item dalam keranjang aktif",
    aria: {
      linkLabel: "Keranjang Aktif {{code}}"
    }
  },
  savedCart: {
    header: "Keranjang Tersimpan Terakhir",
    emptyDescription: "Saat ini tidak ada item dalam keranjang tersimpan",
    aria: {
      linkLabel: "Keranjang Tersimpan Terakhir {{code}}"
    }
  },
  productInterests: {
    header: "Produk Minat",
    emptyDescription: "Saat ini tidak ada item untuk produk minat"
  },
  profile: {
    address: "Alamat",
    billingAddress: "Alamat Penagihan",
    deliveryAddress: "Alamat Pengiriman",
    phone1: "Telepon 1",
    phone2: "Telepon 2",
    paymentMethodHeader: "Metode Pembayaran Tersimpan"
  },
  activity: {
    type: "Tipe",
    id: "Id",
    description: "Deskripsi",
    status: "Status",
    created: "Dibuat",
    updated: "Diperbarui",
    numberOfCartItems: "Keranjang dengan {{count}} item",
    cart: "Keranjang",
    order: "Pesanan",
    savedCart: "Keranjang Tersimpan",
    emptyStateText: "Saat ini tidak ada aktivitas pelanggan yang tercatat",
    headerText: "Umum"
  },
  productReviews: {
    columnHeaders: {
      item: "Item",
      dateAndStatus: "Tanggal / Status",
      rating: "Penilaian",
      review: "Ulasan"
    },
    header: "Ulasan Produk",
    emptyDescription: "Saat ini tidak ada item dalam ulasan produk",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Judul",
      category: "Kategori"
    },
    header: "Tiket Dukungan",
    emptyDescription: "Saat ini tidak ada tiket dukungan"
  },
  coupons: {
    headerText: "Kupon",
    emptyDescription: "Saat ini kupon tidak tersedia",
    applyButtonText: "Terapkan ke Troli",
    applied: "Kupon Diterapkan",
    removeButtonText: "Hapus"
  },
  promotions: {
    headerText: "Promosi",
    emptyDescription: "Saat ini promosi tidak tersedia",
    applied: "Promosi Diterapkan",
    headerName: "Nama",
    headerAction: "Tindakan"
  },
  customerCoupons: {
    headerText: "Kupon Pelanggan",
    emptyDescription: "Saat ini kupon pelanggan tidak tersedia",
    applyButtonText: "Tetapkan ke Pelanggan",
    removeButtonText: "Hapus",
    availableTab: "Tersedia",
    sentTab: "Terkirim",
    searchBox: "Kupon"
  },
  maps: {
    storeClosed: "Tutup",
    storesFound: "{{ initial }} - {{ end }} dari {{ total }} toko ditemukan"
  },
  overviewTab: "Gambaran Umum",
  profileTab: "Profil",
  activityTab: "Aktivitas",
  feedbackTab: "Umpan Balik",
  promotionsTab: "Promosi",
  mapsTab: "Peta",
  storeLocationsTab: "Lokasi Toko",
  aria: {
    activeCartCode: "Keranjang Aktif {{code}}",
    recentOrderCode: "Pesanan Terakhir {{code}}"
  },
  pagination: "Penomoran Halaman",
  page: "Halaman {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$h = {
  asmCustomer360: asmCustomer360$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  asmCustomer360: asmCustomer360$h
};
var asmCustomer360$e = {
  alertErrorMessage: "Impossibile caricare le informazioni. Riprovare più tardi o contattare l'amministratore di sistema.",
  errorMessageHeader: "Si è verificato un errore.",
  applyActionAlter: "Impossibile completare l'azione. Riprovare più tardi.",
  header: {
    title: "Profilo cliente",
    newTitle: "Cliente 360°",
    subTitle: "Vista cliente 360° {{name}}",
    newSubTitle: "Vista 360° {{name}}",
    activeCartLabel: "{{cartSize}} articoli aggiunti al carrello acquisti",
    recentOrderLabel: "{{price}} ultimo ordine ",
    recentTicketLabel: "Ultimo ticket",
    signedUpAt: "Account creato il {{date}}"
  },
  productListing: {
    totalNoItems: "Numero totale articoli {{count}}",
    totalPrice: "Prezzo totale {{price}}",
    showMore: "Mostra di più",
    showLess: "Mostra di meno"
  },
  productItem: {
    outOfStock: "Non disponibile",
    quantity: "Qtà: {{count}}",
    itemPrice: "Prezzo articolo: {{price}}"
  },
  activeCart: {
    header: "Carrello acquisti attivo",
    emptyDescription: "Al momento, non ci sono articoli nel carrello acquisti attivo",
    aria: {
      linkLabel: "Carrello acquisti attivo {{code}}"
    }
  },
  savedCart: {
    header: "Ultimo carrello acquisti salvato",
    emptyDescription: "Al momento, non ci sono articoli nel carrello acquisti salvato",
    aria: {
      linkLabel: "Ultimo carrello acquisti salvato {{code}}"
    }
  },
  productInterests: {
    header: "Interessi",
    emptyDescription: "Al momento non ci sono articoli di interesse"
  },
  profile: {
    address: "Indirizzo",
    billingAddress: "Indirizzo di fatturazione",
    deliveryAddress: "Indirizzo di consegna",
    phone1: "Telefono 1",
    phone2: "Telefono 2",
    paymentMethodHeader: "Metodi di pagamento salvati"
  },
  activity: {
    type: "Tipo",
    id: "ID",
    description: "Descrizione",
    status: "Stato",
    created: "Creato",
    updated: "Aggiornato",
    numberOfCartItems: "Carrello acquisti con {{count}} articoli",
    cart: "Carrello acquisti",
    order: "Ordine",
    savedCart: "Carrello acquisti salvato",
    emptyStateText: "Al momento non ci sono attività del cliente registrato",
    headerText: "Generale"
  },
  productReviews: {
    columnHeaders: {
      item: "Articolo",
      dateAndStatus: "Data/stato",
      rating: "Valutazione",
      review: "Recensione"
    },
    header: "Recensioni prodotto",
    emptyDescription: "Al momento non ci sono articoli con recensione prodotto",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Intestazione",
      category: "Categoria"
    },
    header: "Tickets supporto",
    emptyDescription: "Al momento non ci sono ticket di supporto"
  },
  coupons: {
    headerText: "Coupons",
    emptyDescription: "Al momento non ci sono coupon",
    applyButtonText: "Applica al carrello acquisti",
    applied: "Coupon applicato",
    removeButtonText: "Rimuovi"
  },
  promotions: {
    headerText: "Promozioni",
    emptyDescription: "Al momento non ci sono promozioni",
    applied: "Promozione applicata",
    headerName: "Nome",
    headerAction: "Azione"
  },
  customerCoupons: {
    headerText: "Coupons cliente",
    emptyDescription: "Al momento non ci sono coupon cliente",
    applyButtonText: "Assegna al cliente",
    removeButtonText: "Rimuovi",
    availableTab: "Disponibile",
    sentTab: "Inviato",
    searchBox: "Coupon"
  },
  maps: {
    storeClosed: "Chiudi",
    storesFound: "Trovati {{ initial }} - {{ end }} di {{ total }} punti vendita"
  },
  overviewTab: "Riepilogo",
  profileTab: "Profilo",
  activityTab: "Attività",
  feedbackTab: "Feedback",
  promotionsTab: "Promozioni",
  mapsTab: "Mappe",
  storeLocationsTab: "Ubicazioni dei punti vendita",
  aria: {
    activeCartCode: "Carrello acquisti attivo {{code}}",
    recentOrderCode: "{{code}} ultimo ordine"
  },
  pagination: "Impaginazione",
  page: "Pagina {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$f = {
  asmCustomer360: asmCustomer360$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  asmCustomer360: asmCustomer360$f
};
var asmCustomer360$c = {
  alertErrorMessage: "情報を読み込むことができません。もう一度実行するか、またはシステム管理者に連絡してください。",
  errorMessageHeader: "何らかの問題が発生しました。",
  applyActionAlter: "アクションを完了できませんでした。後でもう一度実行してください。",
  header: {
    title: "顧客プロフィール",
    newTitle: "顧客 360 度",
    subTitle: "{{name}} 顧客 360 度ビュー",
    newSubTitle: "{{name}} 360 ビュー",
    activeCartLabel: "{{cartSize}} アイテムがカートに追加されました",
    recentOrderLabel: "最後の注文 {{price}}",
    recentTicketLabel: "最後のチケット",
    signedUpAt: "アカウントが {{date}} に作成されました"
  },
  productListing: {
    totalNoItems: "アイテム合計数 {{count}}",
    totalPrice: "合計価格 {{price}}",
    showMore: "表示を増やす",
    showLess: "表示数を減らす"
  },
  productItem: {
    outOfStock: "在庫なし",
    quantity: "数量: {{count}}",
    itemPrice: "商品価格: {{price}}"
  },
  activeCart: {
    header: "有効なカート",
    emptyDescription: "現在、有効なカートアイテムはありません",
    aria: {
      linkLabel: "有効なカート {{code}}"
    }
  },
  savedCart: {
    header: "最後に保存したカート",
    emptyDescription: "現在、保存済のカートアイテムはありません。",
    aria: {
      linkLabel: "最後に保存したカート {{code}}"
    }
  },
  productInterests: {
    header: "関心",
    emptyDescription: "現在、関心のあるアイテムはありません"
  },
  profile: {
    address: "住所",
    billingAddress: "請求先住所",
    deliveryAddress: "配送先住所",
    phone1: "電話 1",
    phone2: "電話 2",
    paymentMethodHeader: "保存された支払方法"
  },
  activity: {
    type: "タイプ",
    id: "ID",
    description: "説明",
    status: "ステータス",
    created: "作成済み",
    updated: "更新済み",
    numberOfCartItems: "{{count}} アイテムがあるカート",
    cart: "カート",
    order: "注文",
    savedCart: "保存されたカート",
    emptyStateText: "現在、記録された顧客活動はありません",
    headerText: "一般"
  },
  productReviews: {
    columnHeaders: {
      item: "アイテム",
      dateAndStatus: "日付/ステータス",
      rating: "評価",
      review: "レビュー"
    },
    header: "商品レビュー",
    emptyDescription: "現在、商品レビューアイテムはありません",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "見出し",
      category: "カテゴリ"
    },
    header: "サポートチケット",
    emptyDescription: "現在、サポートチケットはありません"
  },
  coupons: {
    headerText: "クーポン",
    emptyDescription: "現在、クーポンはありません",
    applyButtonText: "カートに適用",
    applied: "クーポンが適用されました",
    removeButtonText: "削除"
  },
  promotions: {
    headerText: "プロモーション",
    emptyDescription: "現在、プロモーションはありません",
    applied: "プロモーションが適用されました",
    headerName: "名前",
    headerAction: "アクション"
  },
  customerCoupons: {
    headerText: "顧客クーポン",
    emptyDescription: "現在、顧客クーポンはありません。",
    applyButtonText: "顧客に割り当て",
    removeButtonText: "削除",
    availableTab: "利用可能",
    sentTab: "送信済み",
    searchBox: "クーポン"
  },
  maps: {
    storeClosed: "閉じる",
    storesFound: "{{ total }} 件の店舗から、{{ initial }} から {{ end }} が見つかりました"
  },
  overviewTab: "概要",
  profileTab: "プロフィール",
  activityTab: "活動",
  feedbackTab: "フィードバック",
  promotionsTab: "プロモーション",
  mapsTab: "マップ",
  storeLocationsTab: "店舗所在地",
  aria: {
    activeCartCode: "有効なカート {{code}}",
    recentOrderCode: "最後の注文 {{code}}"
  },
  pagination: "ページネーション",
  page: "{{number}} ページ",
  emptyCellValue: "---"
};
var asmCustomer360$d = {
  asmCustomer360: asmCustomer360$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  asmCustomer360: asmCustomer360$d
};
var asmCustomer360$a = {
  alertErrorMessage: "정보를 로드할 수 없습니다. 나중에 다시 시도하거나 시스템 관리자에게 문의하십시오.",
  errorMessageHeader: "오류가 발생했습니다.",
  applyActionAlter: "액션을 완료할 수 없습니다. 나중에 다시 시도하십시오.",
  header: {
    title: "고객 프로필",
    newTitle: "고객 360도",
    subTitle: "{{name}} 고객 360도 뷰",
    newSubTitle: "{{name}} 360도 뷰",
    activeCartLabel: "장바구니에 {{cartSize}} 품목이 추가되었습니다.",
    recentOrderLabel: "마지막 주문 {{price}}",
    recentTicketLabel: "마지막 티켓",
    signedUpAt: "계정 생성일 {{date}}"
  },
  productListing: {
    totalNoItems: "총 품목 수 {{count}}",
    totalPrice: "총액 {{price}}",
    showMore: "더 보기",
    showLess: "간단히 보기"
  },
  productItem: {
    outOfStock: "재고 없음",
    quantity: "수량: {{count}}",
    itemPrice: "품목 가격: {{price}}"
  },
  activeCart: {
    header: "사용 중인 장바구니",
    emptyDescription: "현재 사용 중인 장바구니 품목이 없습니다.",
    aria: {
      linkLabel: "사용 중인 장바구니 {{code}}"
    }
  },
  savedCart: {
    header: "마지막으로 저장된 장바구니",
    emptyDescription: "현재 저장된 장바구니 품목이 없습니다.",
    aria: {
      linkLabel: "마지막으로 저장된 장바구니 {{code}}"
    }
  },
  productInterests: {
    header: "관심",
    emptyDescription: "현재 관심 품목이 없습니다."
  },
  profile: {
    address: "주소",
    billingAddress: "청구 주소",
    deliveryAddress: "배송 주소",
    phone1: "전화 1",
    phone2: "전화 2",
    paymentMethodHeader: "저장된 지불 방법"
  },
  activity: {
    type: "유형",
    id: "ID",
    description: "설명",
    status: "상태",
    created: "생성됨",
    updated: "업데이트됨",
    numberOfCartItems: "장바구니에 {{count}}개 품목이 있음",
    cart: "장바구니",
    order: "주문",
    savedCart: "저장된 장바구니",
    emptyStateText: "현재 기록된 고객 활동이 없습니다.",
    headerText: "일반"
  },
  productReviews: {
    columnHeaders: {
      item: "품목",
      dateAndStatus: "날짜/상태",
      rating: "등급",
      review: "검토"
    },
    header: "제품 검토",
    emptyDescription: "현재 제품 검토 품목이 없습니다.",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "헤드라인",
      category: "범주"
    },
    header: "지원 티켓",
    emptyDescription: "현재 지원 티켓이 없습니다."
  },
  coupons: {
    headerText: "쿠폰",
    emptyDescription: "현재 쿠폰이 없습니다.",
    applyButtonText: "장바구니에 적용",
    applied: "쿠폰 적용됨",
    removeButtonText: "제거"
  },
  promotions: {
    headerText: "프로모션",
    emptyDescription: "현재 프로모션이 없습니다.",
    applied: "프로모션 적용됨",
    headerName: "이름",
    headerAction: "작업"
  },
  customerCoupons: {
    headerText: "고객 쿠폰",
    emptyDescription: "현재 고객 쿠폰이 없습니다.",
    applyButtonText: "고객에게 할당",
    removeButtonText: "제거",
    availableTab: "사용 가능",
    sentTab: "전송됨",
    searchBox: "쿠폰"
  },
  maps: {
    storeClosed: "닫기",
    storesFound: "{{ total }}개 매장에서 {{ initial }} - {{ end }} 찾음"
  },
  overviewTab: "개요",
  profileTab: "프로필",
  activityTab: "활동",
  feedbackTab: "피드백",
  promotionsTab: "프로모션",
  mapsTab: "지도",
  storeLocationsTab: "매장 위치",
  aria: {
    activeCartCode: "사용 중인 장바구니 {{code}}",
    recentOrderCode: "마지막 주문 {{code}}"
  },
  pagination: "페이지 매김",
  page: "{{number}} 페이지",
  emptyCellValue: "---"
};
var asmCustomer360$b = {
  asmCustomer360: asmCustomer360$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  asmCustomer360: asmCustomer360$b
};
var asmCustomer360$8 = {
  alertErrorMessage: "Nie można załadować informacji. Spróbuj ponownie później lub skontaktuj się z administratorem systemu.",
  errorMessageHeader: "Ups... Coś poszło nie tak",
  applyActionAlter: "Nie można było ukończyć czynności. Spróbuj ponownie później.",
  header: {
    title: "Profil klienta",
    newTitle: "Klient 360°",
    subTitle: "Widok 360° klienta {{name}}",
    newSubTitle: "Widok 360 {{name}}",
    activeCartLabel: "Dodano {{cartSize}} pozycję do koszyka",
    recentOrderLabel: "Ostatnie zamówienie {{price}}",
    recentTicketLabel: "Ostatnie zgłoszenie",
    signedUpAt: "Data utworzenia konta: {{date}}"
  },
  productListing: {
    totalNoItems: "Łączna liczba pozycji: {{count}}",
    totalPrice: "Cena łączna: {{price}}",
    showMore: "Pokaż więcej",
    showLess: "Pokaż mniej"
  },
  productItem: {
    outOfStock: "Zapas wyczerpany",
    quantity: "Ilość: {{count}}",
    itemPrice: "Cena pozycji: {{price}}"
  },
  activeCart: {
    header: "Aktywny koszyk",
    emptyDescription: "Obecnie nie ma pozycji aktywnego koszyka",
    aria: {
      linkLabel: "Aktywny koszyk: {{code}}"
    }
  },
  savedCart: {
    header: "Ostatnio zapisany koszyk",
    emptyDescription: "Obecnie nie ma pozycji zapisanego koszyka",
    aria: {
      linkLabel: "Ostatnio zapisany koszyk: {{code}}"
    }
  },
  productInterests: {
    header: "Odsetki",
    emptyDescription: "Obecnie nie ma pozycji odsetek"
  },
  profile: {
    address: "Adres",
    billingAddress: "Adres faktury",
    deliveryAddress: "Adres dostawy",
    phone1: "Telefon 1",
    phone2: "Telefon 2",
    paymentMethodHeader: "Zapisane metody płatności"
  },
  activity: {
    type: "Typ",
    id: "ID",
    description: "Opis",
    status: "Status",
    created: "Utworzone",
    updated: "Zaktualizowane",
    numberOfCartItems: "Koszyk z {{count}} pozycjami",
    cart: "Koszyk",
    order: "Zamówienie",
    savedCart: "Zapisany koszyk",
    emptyStateText: "Obecnie nie zarejestrowano żadnego działania klienta",
    headerText: "Ogólne"
  },
  productReviews: {
    columnHeaders: {
      item: "Pozycja",
      dateAndStatus: "Data / Status",
      rating: "Ocena",
      review: "Recenzja"
    },
    header: "Oceny produktów",
    emptyDescription: "Obecnie nie ma pozycji recenzji produktów",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Nagłówek",
      category: "Kategoria"
    },
    header: "Zgłoszenia klienta",
    emptyDescription: "Obecnie nie ma pozycji zgłoszeń klienta"
  },
  coupons: {
    headerText: "Kupony",
    emptyDescription: "Obecnie nie ma żadnych kuponów",
    applyButtonText: "Zastosuj do koszyka",
    applied: "Zastosowany kupon",
    removeButtonText: "Usuń"
  },
  promotions: {
    headerText: "Promocje",
    emptyDescription: "Obecnie nie ma żadnych promocji",
    applied: "Zastosowana promocja",
    headerName: "Nazwa",
    headerAction: "Czynność"
  },
  customerCoupons: {
    headerText: "Kupony klienta",
    emptyDescription: "Obecnie brak wysłanych kuponów klienta",
    applyButtonText: "Przypisz do klienta",
    removeButtonText: "Usuń",
    availableTab: "Dostępne",
    sentTab: "Wysłane",
    searchBox: "Kupon"
  },
  maps: {
    storeClosed: "Zamknij",
    storesFound: "{{ initial }} - znaleziono {{ end }} z {{ total }} sklepów"
  },
  overviewTab: "Przegląd",
  profileTab: "Profil",
  activityTab: "Działanie",
  feedbackTab: "Informacja zwrotna",
  promotionsTab: "Promocje",
  mapsTab: "Mapy",
  storeLocationsTab: "Lokalizacje sklepów",
  aria: {
    activeCartCode: "Aktywny koszyk: {{code}}",
    recentOrderCode: "Ostatnie zamówienie: {{code}}"
  },
  pagination: "Stronicowanie",
  page: "Strona {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$9 = {
  asmCustomer360: asmCustomer360$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  asmCustomer360: asmCustomer360$9
};
var asmCustomer360$6 = {
  alertErrorMessage: "A informação não pode ser carregada. Tente novamente mais tarde ou contate seu administrador do sistema.",
  errorMessageHeader: "Algo deu errado",
  applyActionAlter: "Não foi possível concluir a ação. Tente novamente mais tarde.",
  header: {
    title: "Perfil do cliente",
    newTitle: "Cliente 360º",
    subTitle: "Visão 360º do cliente {{name}}",
    newSubTitle: "Visão 360º {{name}}",
    activeCartLabel: "{{cartSize}} item adicionado ao carrinho",
    recentOrderLabel: "Último pedido {{price}}",
    recentTicketLabel: "Último ticket",
    signedUpAt: "Conta criada em {{date}}"
  },
  productListing: {
    totalNoItems: "Nº total de itens {{count}}",
    totalPrice: "Preço total {{price}}",
    showMore: "Mostrar mais",
    showLess: "Mostrar menos"
  },
  productItem: {
    outOfStock: "Sem estoque",
    quantity: "Quantidade: {{count}}",
    itemPrice: "Preço do item: {{price}}"
  },
  activeCart: {
    header: "Carrinho ativo",
    emptyDescription: "Atualmente não existem itens do carrinho ativos",
    aria: {
      linkLabel: "Carrinho ativo {{code}}"
    }
  },
  savedCart: {
    header: "Último carrinho gravado",
    emptyDescription: "Atualmente não existem itens do carrinho gravados",
    aria: {
      linkLabel: "Último carrinho gravado {{code}}"
    }
  },
  productInterests: {
    header: "Juros",
    emptyDescription: "Atualmente não existem itens de juros"
  },
  profile: {
    address: "Endereço",
    billingAddress: "Endereço de faturamento",
    deliveryAddress: "Endereço de entrega",
    phone1: "Telefone 1",
    phone2: "Telefone 2",
    paymentMethodHeader: "Formas de pagamento gravadas"
  },
  activity: {
    type: "Tipo",
    id: "ID",
    description: "Descrição",
    status: "Status",
    created: "Criado",
    updated: "Atualizado",
    numberOfCartItems: "Carrinho com {{count}} itens",
    cart: "Carrinho",
    order: "Pedido",
    savedCart: "Carrinho gravado",
    emptyStateText: "Atualmente não existe nenhuma atividade de cliente registrada",
    headerText: "Geral"
  },
  productReviews: {
    columnHeaders: {
      item: "Item",
      dateAndStatus: "Data/status",
      rating: "Classificação",
      review: "Revisão"
    },
    header: "Revisões de produto",
    emptyDescription: "Atualmente não existem itens da revisão do produto",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "Cabeçalho",
      category: "Categoria"
    },
    header: "Tickets de suporte",
    emptyDescription: "Atualmente não existem tickets de suporte"
  },
  coupons: {
    headerText: "Cupons",
    emptyDescription: "Atualmente não existem cupons",
    applyButtonText: "Aplicar ao carrinho",
    applied: "Cupom aplicado",
    removeButtonText: "Remover"
  },
  promotions: {
    headerText: "Promoções",
    emptyDescription: "Atualmente não existem promoções",
    applied: "Promoção aplicada",
    headerName: "Nome",
    headerAction: "Ação"
  },
  customerCoupons: {
    headerText: "Cupons do cliente",
    emptyDescription: "Atualmente não existem cupons de cliente",
    applyButtonText: "Atribuir ao cliente",
    removeButtonText: "Remover",
    availableTab: "Disponível",
    sentTab: "Enviado",
    searchBox: "Cupom"
  },
  maps: {
    storeClosed: "Fechar",
    storesFound: "{{ initial }} - {{ end }} de {{ total }} lojas encontradas"
  },
  overviewTab: "Síntese",
  profileTab: "Perfil",
  activityTab: "Atividade",
  feedbackTab: "Feedback",
  promotionsTab: "Promoções",
  mapsTab: "Mapas",
  storeLocationsTab: "Localizações de lojas",
  aria: {
    activeCartCode: "Carrinho ativo {{code}}",
    recentOrderCode: "Último pedido {{code}}"
  },
  pagination: "Paginação",
  page: "Página {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$7 = {
  asmCustomer360: asmCustomer360$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  asmCustomer360: asmCustomer360$7
};
var asmCustomer360$4 = {
  alertErrorMessage: "Невозможно загрузить информацию. Повторите попытку позже или обратитесь к системному администратору.",
  errorMessageHeader: "Ой, что-то пошло не так",
  applyActionAlter: "Не удалось выполнить это действие. Повторите попытку позже.",
  header: {
    title: "Профиль клиента",
    newTitle: "Клиент 360°",
    subTitle: "Ракурс 360° для клиента {{name}}",
    newSubTitle: "Ракурс 360° {{name}}",
    activeCartLabel: "{{cartSize}}: позиция добавлена в корзину",
    recentOrderLabel: "Последний заказ {{price}}",
    recentTicketLabel: "Последний сервисный запрос",
    signedUpAt: "Учетная запись создана {{date}}"
  },
  productListing: {
    totalNoItems: "Всего позиций: {{count}}",
    totalPrice: "Общая цена: {{price}}",
    showMore: "Показать больше",
    showLess: "Показать меньше"
  },
  productItem: {
    outOfStock: "Нет в наличии",
    quantity: "Кол.: {{count}}",
    itemPrice: "Цена позиции: {{price}}"
  },
  activeCart: {
    header: "Активная корзина",
    emptyDescription: "Сейчас нет позиций активной корзины",
    aria: {
      linkLabel: "Активная корзина {{code}}"
    }
  },
  savedCart: {
    header: "Последняя сохраненная корзина",
    emptyDescription: "Сейчас нет позиций сохраненной корзины",
    aria: {
      linkLabel: "Последняя сохраненная корзина {{code}}"
    }
  },
  productInterests: {
    header: "Интересы",
    emptyDescription: "Сейчас нет позиций интересов"
  },
  profile: {
    address: "Адрес",
    billingAddress: "Адрес выставления счета",
    deliveryAddress: "Адрес доставки",
    phone1: "Телефон 1",
    phone2: "Телефон 2",
    paymentMethodHeader: "Сохраненные методы оплаты"
  },
  activity: {
    type: "Тип",
    id: "Ид.",
    description: "Описание",
    status: "Статус",
    created: "Создано",
    updated: "Обновлено",
    numberOfCartItems: "Корзина с {{count}} поз.",
    cart: "Корзина",
    order: "Заказ",
    savedCart: "Сохраненная корзина",
    emptyStateText: "Сейчас нет записанных действий клиента",
    headerText: "Общее"
  },
  productReviews: {
    columnHeaders: {
      item: "Позиция",
      dateAndStatus: "Дата/статус",
      rating: "Рейтинг",
      review: "Отзыв"
    },
    header: "Отзывы о продуктах",
    emptyDescription: "Сейчас нет позиций отзывов о продуктах",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "Ид.",
      headline: "Заголовок",
      category: "Категория"
    },
    header: "Запросы в службу поддержки",
    emptyDescription: "Сейчас нет запросов в службу поддержки"
  },
  coupons: {
    headerText: "Купоны",
    emptyDescription: "Сейчас нет купонов",
    applyButtonText: "Применить к корзине",
    applied: "Купон применен",
    removeButtonText: "Удалить"
  },
  promotions: {
    headerText: "Рекламные акции",
    emptyDescription: "Сейчас нет рекламных акций",
    applied: "Рекламная акция применена",
    headerName: "Имя",
    headerAction: "Операция"
  },
  customerCoupons: {
    headerText: "Купоны клиента",
    emptyDescription: "Сейчас нет купонов клиента",
    applyButtonText: "Присвоить клиенту",
    removeButtonText: "Удалить",
    availableTab: "Доступно",
    sentTab: "Отправлено",
    searchBox: "Купон"
  },
  maps: {
    storeClosed: "Закрыть",
    storesFound: "{{ initial }} - {{ end }} из {{ total }} магазинов найдено"
  },
  overviewTab: "Обзор",
  profileTab: "Профиль",
  activityTab: "Действие",
  feedbackTab: "Отзыв",
  promotionsTab: "Рекламные акции",
  mapsTab: "Карты",
  storeLocationsTab: "Местоположения магазинов",
  aria: {
    activeCartCode: "Активная корзина {{code}}",
    recentOrderCode: "Последний заказ {{code}}"
  },
  pagination: "Разбивка на страницы",
  page: "Страница {{number}}",
  emptyCellValue: "---"
};
var asmCustomer360$5 = {
  asmCustomer360: asmCustomer360$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  asmCustomer360: asmCustomer360$5
};
var asmCustomer360$2 = {
  alertErrorMessage: "无法加载此信息。请稍后重试或联系系统管理员。",
  errorMessageHeader: "糟糕！发生了一些错误",
  applyActionAlter: "操作无法完成。请稍后再试。",
  header: {
    title: "客户个人资料",
    newTitle: "客户 360°",
    subTitle: "{{name}} 客户 360° 视图",
    newSubTitle: "{{name}} 360 视图",
    activeCartLabel: "{{cartSize}} 个项目已添加到购物车",
    recentOrderLabel: "最近订单 {{price}}",
    recentTicketLabel: "最近票证",
    signedUpAt: "{{date}} 上创建的账户"
  },
  productListing: {
    totalNoItems: "项目总计 {{count}}",
    totalPrice: "总价 {{price}}",
    showMore: "显示更多",
    showLess: "显示更少"
  },
  productItem: {
    outOfStock: "缺货",
    quantity: "数量：{{count}}",
    itemPrice: "项目价格：{{price}}"
  },
  activeCart: {
    header: "活动购物车",
    emptyDescription: "目前没有活动购物车项目",
    aria: {
      linkLabel: "活动购物车 {{code}}"
    }
  },
  savedCart: {
    header: "最近保存的购物车",
    emptyDescription: "目前没有保存的购物车项目",
    aria: {
      linkLabel: "最近保存的购物车 {{code}}"
    }
  },
  productInterests: {
    header: "关注对象",
    emptyDescription: "目前没有关注的项目"
  },
  profile: {
    address: "地址",
    billingAddress: "账单地址",
    deliveryAddress: "送货地址",
    phone1: "电话 1",
    phone2: "电话 2",
    paymentMethodHeader: "保存的付款方式"
  },
  activity: {
    type: "类型",
    id: "标识",
    description: "描述",
    status: "状态",
    created: "已创建",
    updated: "已更新",
    numberOfCartItems: "包含 {{count}} 个项目的购物车",
    cart: "购物车",
    order: "顺序",
    savedCart: "已保存购物车",
    emptyStateText: "目前没有记录的客户活动",
    headerText: "常规"
  },
  productReviews: {
    columnHeaders: {
      item: "商品",
      dateAndStatus: "日期/状态",
      rating: "评级",
      review: "评价"
    },
    header: "产品评价",
    emptyDescription: "目前没有产品评价项目",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "标识",
      headline: "标题",
      category: "类别"
    },
    header: "支持票证",
    emptyDescription: "目前没有支持票证"
  },
  coupons: {
    headerText: "优惠券",
    emptyDescription: "目前没有优惠券",
    applyButtonText: "适用于购物车",
    applied: "已应用优惠券",
    removeButtonText: "移除"
  },
  promotions: {
    headerText: "促销",
    emptyDescription: "目前没有促销",
    applied: "已应用促销",
    headerName: "名称",
    headerAction: "操作"
  },
  customerCoupons: {
    headerText: "客户优惠券",
    emptyDescription: "目前没有客户优惠券",
    applyButtonText: "分配给客户",
    removeButtonText: "移除",
    availableTab: "可用",
    sentTab: "已发送",
    searchBox: "优惠券"
  },
  maps: {
    storeClosed: "关闭",
    storesFound: " {{ initial }} - {{ end }}（共找到 {{ total }} 个店铺）"
  },
  overviewTab: "概览",
  profileTab: "个人资料",
  activityTab: "活动",
  feedbackTab: "反馈",
  promotionsTab: "促销",
  mapsTab: "地图",
  storeLocationsTab: "店铺位置",
  aria: {
    activeCartCode: "活动购物车 {{code}}",
    recentOrderCode: "最近订单 {{code}}"
  },
  pagination: "分页",
  page: "第 {{number}} 页",
  emptyCellValue: "---"
};
var asmCustomer360$3 = {
  asmCustomer360: asmCustomer360$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  asmCustomer360: asmCustomer360$3
};
var asmCustomer360 = {
  alertErrorMessage: "無法載入資訊。請稍後再試一次，或聯絡系統管理員。",
  errorMessageHeader: "糟糕！發生錯誤",
  applyActionAlter: "無法完成動作，請稍後再試一次。",
  header: {
    title: "客戶設定檔",
    newTitle: "Customer 360°",
    subTitle: "{{name}} Customer 360° View",
    newSubTitle: "{{name}} 360 View",
    activeCartLabel: "{{cartSize}} 個項目已新增至購物車",
    recentOrderLabel: "最後一個訂單 {{price}}",
    recentTicketLabel: "最後一個記錄單",
    signedUpAt: "帳戶建立日期 {{date}}"
  },
  productListing: {
    totalNoItems: "總計項目數量 {{count}}",
    totalPrice: "總價 {{price}}",
    showMore: "顯示更多",
    showLess: "顯示較少"
  },
  productItem: {
    outOfStock: "缺貨",
    quantity: "數量：{{count}}",
    itemPrice: "項目價格：{{price}}"
  },
  activeCart: {
    header: "啟用中購物車",
    emptyDescription: "目前沒有啟用中購物車項目",
    aria: {
      linkLabel: "啟用中購物車 {{code}}"
    }
  },
  savedCart: {
    header: "上次儲存的購物車",
    emptyDescription: "目前沒有儲存的購物車項目",
    aria: {
      linkLabel: "上次儲存的購物車 {{code}}"
    }
  },
  productInterests: {
    header: "興趣",
    emptyDescription: "目其沒有興趣項目"
  },
  profile: {
    address: "地址",
    billingAddress: "帳單寄送地址",
    deliveryAddress: "交貨地址",
    phone1: "電話 1",
    phone2: "電話 2",
    paymentMethodHeader: "儲存的付款方法"
  },
  activity: {
    type: "類型",
    id: "ID",
    description: "說明",
    status: "狀態",
    created: "已建立",
    updated: "已更新",
    numberOfCartItems: "購物車包含 {{count}} 個項目",
    cart: "購物車",
    order: "訂單",
    savedCart: "儲存的購物車",
    emptyStateText: "目前沒有記錄的客戶活動",
    headerText: "一般"
  },
  productReviews: {
    columnHeaders: {
      item: "項目",
      dateAndStatus: "日期/狀態",
      rating: "評比",
      review: "評論"
    },
    header: "產品評論",
    emptyDescription: "目前沒有產品評論項目",
    sku: "SKU"
  },
  supportTickets: {
    columnHeaders: {
      id: "ID",
      headline: "標題",
      category: "種類"
    },
    header: "支援記錄單",
    emptyDescription: "目前沒有支援記錄單"
  },
  coupons: {
    headerText: "優惠券",
    emptyDescription: "目前沒有優惠券",
    applyButtonText: "套用至購物車",
    applied: "已套用優惠券",
    removeButtonText: "移除"
  },
  promotions: {
    headerText: "促銷",
    emptyDescription: "目前沒有促銷",
    applied: "已套用促銷",
    headerName: "名稱",
    headerAction: "動作"
  },
  customerCoupons: {
    headerText: "客戶優惠券",
    emptyDescription: "目前沒有客戶優惠券",
    applyButtonText: "指派給客戶",
    removeButtonText: "移除",
    availableTab: "可用",
    sentTab: "已傳送",
    searchBox: "優惠券"
  },
  maps: {
    storeClosed: "關閉",
    storesFound: "已從 {{ total }} 個商店中找到 {{ initial }} - {{ end }} 個"
  },
  overviewTab: "概觀",
  profileTab: "設定檔",
  activityTab: "活動",
  feedbackTab: "反饋",
  promotionsTab: "促銷",
  mapsTab: "地圖",
  storeLocationsTab: "儲存地點",
  aria: {
    activeCartCode: "啟用中購物車 {{code}}",
    recentOrderCode: "最後一個訂單 {{code}}"
  },
  pagination: "分頁",
  page: "第 {{number}} 頁",
  emptyCellValue: "---"
};
var asmCustomer360$1 = {
  asmCustomer360: asmCustomer360
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  asmCustomer360: asmCustomer360$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const asmCustomer360TranslationChunksConfig = {
  asmCustomer360: ['asmCustomer360']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: asmCustomer360Translations
 *             +   resources: { en: asmCustomer360TranslationsEn }
 *               }
 *             ```
 */
const asmCustomer360Translations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { asmCustomer360TranslationChunksConfig, asmCustomer360Translations, cs as asmCustomer360TranslationsCs, de as asmCustomer360TranslationsDe, en as asmCustomer360TranslationsEn, es as asmCustomer360TranslationsEs, es_CO as asmCustomer360TranslationsEs_CO, fr as asmCustomer360TranslationsFr, hi as asmCustomer360TranslationsHi, hu as asmCustomer360TranslationsHu, id as asmCustomer360TranslationsId, it as asmCustomer360TranslationsIt, ja as asmCustomer360TranslationsJa, ko as asmCustomer360TranslationsKo, pl as asmCustomer360TranslationsPl, pt as asmCustomer360TranslationsPt, ru as asmCustomer360TranslationsRu, zh as asmCustomer360TranslationsZh, zh_TW as asmCustomer360TranslationsZh_TW };
