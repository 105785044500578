var asm$w = {
  mainLogoLabel: "SAP",
  mainTitle: "Režim asistenční služby",
  logout: "Odhlásit se",
  hideUi: "Zavřít ASM",
  helpPortalUrl: "Nápověda",
  customers: "Zákazníci",
  asmCustomer360Button: "Zobrazení Zákazník 360",
  asmCustomer360ViewButton: "Zákazník 360°",
  createCustomerSuccessfullyAlert: "Byl vytvořen zákaznický účet a zahájena relace zákazníka.",
  saveInactiveCartAlertInfo: "Identifikovaný košík není aktivní. Abyste mohli s košíkem dál pracovat, je třeba jej nejdřív uložit.",
  activeCartAlertInfo: "Identifikovaný košík není aktivní.",
  startCustomerEmulationAlertInfo: "Byla zahájena emulace zákazníka. Veškeré provedené činnosti budou odrážet účinky na účtu zákazníka.",
  toggleUi: {
    collapse: "Skrýt ASM",
    expand: "Zobrazit ASM"
  },
  loginForm: {
    submit: "Přihlásit se",
    userId: {
      label: "ID nebo e-mail agenta"
    },
    password: {
      label: "Heslo"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Jméno / e-mailová adresa zákazníka"
    },
    searchCustomer: {
      label: "Hledat podle jména nebo e-mailové adresy"
    },
    searchOrder: {
      label: "Hledat podle ID celé objednávky."
    },
    submit: "Zahájit relaci",
    startEmulation: "Zahájit emulaci",
    noMatch: "Nebyl nalezen žádný zákazník",
    noMatchResult: "Tento účet nelze najít.",
    noCustomerMatchResult: "Nebyl nalezen žádný odpovídající zákazník.",
    noOrderMatchResult: "Vyhledat podle celého ID objednávky.",
    createCustomer: "Vytvořit nového zákazníka",
    customer: "Zákazník",
    orderID: "ID objednávky"
  },
  createCustomerForm: {
    title: "Vytvořit nového zákazníka",
    createAccount: "Vytvořit",
    cancel: "Zrušit",
    firstName: {
      label: "Křestní jméno",
      placeholder: "Křestní jméno"
    },
    lastName: {
      label: "Příjmení",
      placeholder: "Příjmení"
    },
    emailAddress: {
      label: "E-mailová adresa",
      placeholder: "E-mailová adresa"
    },
    createAccountAlert: "Relace zákazníka začíná po vytvoření účtu zákazníka.",
    validationErrors: {
      firstName: "Zadejte platné křestní jméno.",
      lastName: "Zadejte platné příjmení.",
      emailAddress: "Zadejte platnou e-mailovou adresu."
    },
    badRequestDuplicatedEmail: "Zadejte jinou e-mailovou adresu, protože {{ emailAddress }} již existuje."
  },
  customerList: {
    title: "Seznam zákazníků",
    description: "Vyberte zákazníka z některého z poskytnutých seznamů.",
    createCustomer: "Vytvořit nového zákazníka",
    cancel: "Zrušit",
    tableHeader: {
      customer: "Zákazník",
      customerName: "Jméno/název zákazníka",
      name: "Jméno/název",
      email: "E-mail",
      emailId: "Email/ID",
      phone: "Telefon",
      account: "Účet",
      cart: "Košík",
      order: "Objednávka",
      view: "360 pohled",
      activeCart: "Aktivní košík",
      viewActiveCart: "Zobrazit aktivní košík",
      orders: "Objednávky",
      viewOrders: "Zobrazit objednávky",
      customer360: "Zákazník 360°",
      viewCustomer360: "Zobrazit Zákazník 360°"
    },
    tableSort: {
      sortBy: "Řadit podle",
      byName: "Jméno (vzestupně)",
      byNameAsc: "Jméno (vzestupně)",
      byNameDesc: "Jméno (sestupně)",
      byDateAsc: "Datum (vzestupně)",
      byDateDesc: "Datum (sestupně)",
      byOrderDateAsc: "Datum objednávky (vzestupně)",
      byOrderDateDesc: "Datum objednávky (sestupně)",
      byUnit: "Účet (vzestupně)",
      byUnitDesc: "Účet (sestupně)"
    },
    page: {
      page: "Strana {{count}}",
      previous: "Předchozí",
      next: "Další"
    },
    noOfCustomers: "Počet zákazníků: {{count}} ",
    oneCustomer: "1 zákazník",
    noCustomers: "V tomto seznamu zákazníků momentálně nejsou žádní zákazníci.",
    noLists: "Momentálně není k dispozici žádný seznam zákazníků. Obraťte se na správce systému.",
    listsError: "Seznam zákazníků nelze načíst. Zkuste to znovu později.",
    searchBox: "Hledat",
    enterSearchBox: "Zadejte jméno nebo e-mail zákazníka"
  },
  switchCustomer: {
    dialog: {
      title: "Upozornění",
      body: "Kliknutím na „Přepnout zákazníka“ ukončíte emulaci pro „{{ customerA }}“ a zahájíte emulaci pro „{{ customerB }}“. Veškeré neuložené změny pro „{{ customerA }}“ budou ztraceny.",
      actions: {
        "switch": "Přepnout zákazníka"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Uložit na později",
    dialog: {
      saveInfo: "Před další činností uložte košík.",
      disableInfo: "Košík nelze uložit, protože je prázdný.",
      title: "Uložit košík",
      row: {
        id: "ID",
        qty: "Množství",
        qtyFull: "Množství",
        total: "Celkem"
      },
      actions: {
        save: "Uložit na později"
      }
    }
  },
  bindCart: {
    cartNumber: "Číslo košíku",
    bindCartToCustomer: "Přiřadit košík zákazníkovi",
    success: "Košík byl úspěšně přiřazen",
    assignCartId: "Přiřadit ID košíku zákazníkovi",
    enterCartId: "Zadejte ID košíku",
    resetCartId: "Resetovat",
    dialog: {
      title: "Přiřadit anonymní košík",
      body: "Chcete nahradit aktuální aktivní košík anonymním košíkem? Pokud nahradíte aktuální aktivní košík, uloží se jako uložený košík.",
      actions: {
        replace: "Nahradit košík"
      }
    }
  },
  csagentTokenExpired: "Časový limit relace agenta zákaznické podpory vypršel.",
  endSession: "Ukončit relaci",
  endEmulation: "Ukončit emulaci",
  agentSessionTimer: {
    label: "Vypršel časový limit relace",
    minutes: "min",
    reset: "Resetovat"
  },
  auth: {
    agentLoggedInError: "Nemůžete se přihlásit jako uživatel, pokud existuje aktivní relace agenta zák. podpory. Buď emulujte uživatele, nebo odhlaste agenta zák. podpory."
  },
  error: {
    noCustomerId: "Pro vybraného uživatele nebylo nalezeno ID zákazníka. Relace nemůže být zahájena."
  }
};
var asm$x = {
  asm: asm$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  asm: asm$x
};
var asm$u = {
  mainLogoLabel: "SAP",
  mainTitle: "Modus für unterstützen Service",
  logout: "Abmelden",
  hideUi: "ASM schließen",
  helpPortalUrl: "Hilfe",
  customers: "Kunden",
  asmCustomer360Button: "360°-Kundensicht",
  asmCustomer360ViewButton: "Kunde 360°",
  createCustomerSuccessfullyAlert: "Das Kundekonto wurde erstellt, und die Kundensitzung wurde gestartet.",
  saveInactiveCartAlertInfo: "Der identifizierte Warenkorb ist ein inaktiver Warenkorb. Um weitere Aktionen für diesen Warenkorb durchzuführen, müssen Sie ihn vorher speichern. ",
  activeCartAlertInfo: "Der identifizierte Warenkorb ist ein aktiver Warenkorb.",
  startCustomerEmulationAlertInfo: "Kundenemulation wurde gestartet. Alle Aktionen, die Sie vornehmen, spiegeln die Auswirkungen auf das Kundenkonto wider.",
  toggleUi: {
    collapse: "ASM ausblenden",
    expand: "ASM anzeigen"
  },
  loginForm: {
    submit: "Anmelden",
    userId: {
      label: "ID oder E-Mail des Kundenbetreuers"
    },
    password: {
      label: "Kennwort"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Kundenname/E-Mail-Adresse"
    },
    searchCustomer: {
      label: "Nach Name oder E-Mail-Adresse suchen"
    },
    searchOrder: {
      label: "Nach vollständiger Bestellungs-ID suchen"
    },
    submit: "Sitzung starten",
    startEmulation: "Emulation starten",
    noMatch: "Keinen Kunden gefunden.",
    noMatchResult: "Dieses Konto wurde nicht gefunden.",
    noCustomerMatchResult: "Es wurde kein passender Kunde gefunden.",
    noOrderMatchResult: "Suchen Sie nach der vollständigen Bestellungs-ID.",
    createCustomer: "Neuen Kunden erstellen",
    customer: "Kunde",
    orderID: "Bestell-ID"
  },
  createCustomerForm: {
    title: "Neuen Kunden erstellen",
    createAccount: "Erstellen",
    cancel: "Abbrechen",
    firstName: {
      label: "Vorname",
      placeholder: "Vorname"
    },
    lastName: {
      label: "Nachname",
      placeholder: "Nachname"
    },
    emailAddress: {
      label: "E-Mail-Adresse",
      placeholder: "E-Mail-Adresse"
    },
    createAccountAlert: "Die Kundensitzung startet, nachdem Sie das Kundenkonto erstellt haben.",
    validationErrors: {
      firstName: "Geben Sie einen gültigen Vornamen ein.",
      lastName: "Geben Sie einen gültigen Nachnamen ein.",
      emailAddress: "Geben Sie eine gültige E-Mail-Adresse ein."
    },
    badRequestDuplicatedEmail: "Geben Sie eine andere E-Mail-Adresse ein, weil {{ emailAddress }} bereits vorhanden ist."
  },
  customerList: {
    title: "Kundenliste",
    description: "Wählen Sie einen Kunden aus einer der angegebenen Listen aus.",
    createCustomer: "Neuen Kunden erstellen",
    cancel: "Abbrechen",
    tableHeader: {
      customer: "Kunde",
      customerName: "Kundenname",
      name: "Name",
      email: "E-Mail",
      emailId: "E-Mail/ID",
      phone: "Telefon",
      account: "Konto",
      cart: "Warenkorb",
      order: "Bestellung",
      view: " 360°-Sicht",
      activeCart: "Aktiver Warenkorb",
      viewActiveCart: "Aktiven Warenkorb anzeigen",
      orders: "Bestellungen",
      viewOrders: "Bestellungen anzeigen",
      customer360: "Kunde 360°",
      viewCustomer360: "360°-Kundensicht"
    },
    tableSort: {
      sortBy: "Sortieren nach",
      byName: "Name (aufsteigend)",
      byNameAsc: "Name (aufsteigend)",
      byNameDesc: "Name (absteigend)",
      byDateAsc: "Datum (aufsteigend)",
      byDateDesc: "Datum (absteigend)",
      byOrderDateAsc: "Bestelldatum (aufsteigend)",
      byOrderDateDesc: "Bestelldatum (absteigend)",
      byUnit: "Konto (aufsteigend)",
      byUnitDesc: "Konto (absteigend)"
    },
    page: {
      page: "Seite {{count}}",
      previous: "Zurück",
      next: "Weiter"
    },
    noOfCustomers: "{{count}} Kunden",
    oneCustomer: "1 Kunde",
    noCustomers: "Diese Kundenliste enthält zurzeit keine Kunden.",
    noLists: "Zurzeit sind keine Kundenlisten verfügbar. Wenden Sie sich an Ihren Systemadministrator.",
    listsError: "Die Kundenlisten konnten nicht abgerufen werden. Versuchen Sie es später erneut.",
    searchBox: "Suchen",
    enterSearchBox: "Name oder E-Mail des Kunden eingeben"
  },
  switchCustomer: {
    dialog: {
      title: "Warnung",
      body: "Wenn Sie auf \"Kunde wechseln\" klicken, wird die Emulation für \"{{ customerA }}\" beendet und für \"{{ customerB }}\" gestartet. Alle ungespeicherten Änderungen für \"{{ customerA }}\" gehen verloren.",
      actions: {
        "switch": "Kunde wechseln"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Für später speichern",
    dialog: {
      saveInfo: "Speichern Sie den Warenkorb, bevor Sie mit weiteren Aktionen fortfahren.",
      disableInfo: "Der Warenkorb kann nicht gespeichert werden, weil er leer ist.",
      title: "Warenkorb speichern",
      row: {
        id: "ID",
        qty: "Menge",
        qtyFull: "Menge",
        total: "Gesamtsumme"
      },
      actions: {
        save: "Für später speichern"
      }
    }
  },
  bindCart: {
    cartNumber: "Warenkorbnummer",
    bindCartToCustomer: "Warenkorb dem Kunden zuordnen",
    success: "Warenkorb wurde erfolgreich zugeordnet",
    assignCartId: "Warenkorb-ID dem Kunden zuordnen",
    enterCartId: "Warenkorb-ID eingeben",
    resetCartId: "Zurücksetzen",
    dialog: {
      title: "Anonymen Warenkorb zuordnen",
      body: "Möchten Sie den aktuellen aktiven Warenkorb durch den anonymen Warenkorb ersetzen? Wenn Sie den aktuellen aktiven Warenkorb ersetzen, wird er als gespeicherter Warenkorb gespeichert.",
      actions: {
        replace: "Warenkorb ersetzen"
      }
    }
  },
  csagentTokenExpired: "Ihre Kundenbetreuersitzung ist abgelaufen.",
  endSession: "Sitzung beenden",
  endEmulation: "Emulation beenden",
  agentSessionTimer: {
    label: "Sitzungs-Timeout",
    minutes: "Min.",
    reset: "Zurücksetzen"
  },
  auth: {
    agentLoggedInError: "Anmeldung als Benutzer nicht möglich, wenn es eine aktive Kundenbetreuersitzung gibt. Emulieren Sie entweder den Benutzer, oder melden Sie den Kundenbetreuer ab."
  },
  error: {
    noCustomerId: "Keine Kunden-ID für den ausgewählten Benutzer gefunden. Sitzung kann nicht gestartet werden."
  }
};
var asm$v = {
  asm: asm$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  asm: asm$v
};
var asm$s = {
  mainLogoLabel: "SAP",
  mainTitle: "Assisted Service Mode",
  logout: "Sign Out",
  hideUi: "Close ASM",
  helpPortalUrl: "Help",
  customers: "Customers",
  asmCustomer360Button: "Customer 360 View",
  asmCustomer360ViewButton: "Customer 360°",
  createCustomerSuccessfullyAlert: "The customer account has been created and the customer session has started.",
  saveInactiveCartAlertInfo: "The identified cart is an inactive cart. To take further actions on this cart, save it first.",
  activeCartAlertInfo: "The identified cart is an active cart.",
  startCustomerEmulationAlertInfo: "Customer emulation has started. Any actions you do will reflect the effects on the customer account.",
  toggleUi: {
    collapse: "Hide ASM",
    expand: "Show ASM"
  },
  loginForm: {
    submit: "Sign In",
    userId: {
      label: "Agent ID or Email"
    },
    password: {
      label: "Password"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Customer Name/Email Address"
    },
    searchCustomer: {
      label: "Search by name or email address"
    },
    searchOrder: {
      label: "Search by full order ID"
    },
    submit: "Start Session",
    startEmulation: "Start Emulation",
    noMatch: "No customer found.",
    noMatchResult: "This account cannot be found.",
    noCustomerMatchResult: "No matching customer found.",
    noOrderMatchResult: "Search by full order ID.",
    createCustomer: "Create New Customer",
    customer: "Customer",
    orderID: "Order ID"
  },
  createCustomerForm: {
    title: "Create New Customer",
    createAccount: "Create",
    cancel: "Cancel",
    firstName: {
      label: "First Name",
      placeholder: "First name"
    },
    lastName: {
      label: "Last Name",
      placeholder: "Last name"
    },
    emailAddress: {
      label: "Email Address",
      placeholder: "Email address"
    },
    createAccountAlert: "The customer session starts once you create the customer account.",
    validationErrors: {
      firstName: "Enter a valid first name.",
      lastName: "Enter a valid last name.",
      emailAddress: "Enter a valid email address."
    },
    badRequestDuplicatedEmail: "Enter a different email address as {{ emailAddress }} already exists."
  },
  customerList: {
    title: "Customer List",
    description: "Select a customer from one of several provided lists.",
    createCustomer: "Create New Customer",
    cancel: "Cancel",
    tableHeader: {
      customer: "Customer",
      customerName: "Customer Name",
      name: "Name",
      email: "Email",
      emailId: "Email/ID",
      phone: "Phone",
      account: "Account",
      cart: "Cart",
      order: "Order",
      view: "360 View",
      activeCart: "Active Cart",
      viewActiveCart: "View Active Cart",
      orders: "Orders",
      viewOrders: "View Orders",
      customer360: "Customer 360°",
      viewCustomer360: "View Customer 360°"
    },
    tableSort: {
      sortBy: "Sort by",
      byName: "Name (Asc)",
      byNameAsc: "Name (Asc)",
      byNameDesc: "Name (Desc)",
      byDateAsc: "Date (Asc)",
      byDateDesc: "Date (Desc)",
      byOrderDateAsc: "Order date (Asc)",
      byOrderDateDesc: "Order date (Desc)",
      byUnit: "Account (Asc)",
      byUnitDesc: "Account (Desc)"
    },
    page: {
      page: "Page {{count}}",
      previous: "Previous",
      next: "Next"
    },
    noOfCustomers: "{{count}} Customers",
    oneCustomer: "1 Customer",
    noCustomers: "There are currently no customers in this customer list.",
    noLists: "There are currently no customer lists available. Contact your system administrator.",
    listsError: "The customer lists could not be retrieved. Please try again later.",
    searchBox: "Search",
    enterSearchBox: "Enter customer name or email"
  },
  switchCustomer: {
    dialog: {
      title: "Warning",
      body: "Clicking \"Switch Customer\" will end the emulation for \"{{ customerA }}\" and start for \"{{ customerB }}\". Any unsaved changes for \"{{ customerA }}\" will be lost.",
      actions: {
        "switch": "Switch Customer"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Save for Later",
    dialog: {
      saveInfo: "Save the cart before you can take further actions.",
      disableInfo: "Cannot save the cart as it is empty.",
      title: "Save Cart",
      row: {
        id: "ID",
        qty: "Qty",
        qtyFull: "Quantity",
        total: "Total"
      },
      actions: {
        save: "Save for Later"
      }
    }
  },
  bindCart: {
    cartNumber: "Cart Number",
    bindCartToCustomer: "Assign Cart to Customer",
    success: "Cart has been successfully assigned",
    assignCartId: "Assign a cart id to customer",
    enterCartId: "Enter cart id",
    resetCartId: "Reset",
    dialog: {
      title: "Assign Anonymous Cart",
      body: "Do you want to replace the current active cart with the anonymous cart? If you replace the current active cart, it is saved as a saved cart.",
      actions: {
        replace: "Replace Cart"
      }
    }
  },
  csagentTokenExpired: "Your customer support agent session is expired.",
  endSession: "End Session",
  endEmulation: "End Emulation",
  agentSessionTimer: {
    label: "Session Timeout",
    minutes: "min",
    reset: "Reset"
  },
  auth: {
    agentLoggedInError: "Cannot login as user when there is an active CS agent session. Please either emulate user or logout CS agent."
  },
  error: {
    noCustomerId: "No customerId found for selected user. Session cannot be started."
  }
};
var asm$t = {
  asm: asm$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  asm: asm$t
};
var asm$q = {
  mainLogoLabel: "SAP",
  mainTitle: "Modo de servicio asistido",
  logout: "Cerrar sesión",
  hideUi: "Cerrar MSA",
  helpPortalUrl: "Ayuda",
  customers: "Clientes",
  asmCustomer360Button: "Cliente Vista 360º",
  asmCustomer360ViewButton: "Cliente 360°",
  createCustomerSuccessfullyAlert: "Se ha creado la cuenta del cliente e iniciado la sesión del cliente.",
  saveInactiveCartAlertInfo: "La cesta identificada está inactiva. Para realizar otras acciones con esta cesta, primero guárdela.",
  activeCartAlertInfo: "La cesta identificada está activa.",
  startCustomerEmulationAlertInfo: "Ha comenzado la emulación del cliente. Cualquier acción que realice replicará los efectos en la cuenta del cliente.",
  toggleUi: {
    collapse: "Ocultar MSA",
    expand: "Mostrar MSA"
  },
  loginForm: {
    submit: "Iniciar sesión",
    userId: {
      label: "ID o correo electrónico de agente"
    },
    password: {
      label: "Contraseña"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Nombre/dirección de correo electrónico del cliente"
    },
    searchCustomer: {
      label: "Buscar por nombre o dirección de correo electrónico"
    },
    searchOrder: {
      label: "Buscar por ID completo de pedido"
    },
    submit: "Comenzar sesión",
    startEmulation: "Comenzar emulación",
    noMatch: "No se ha encontrado ningún cliente.",
    noMatchResult: "No se ha podido encontrar esta cuenta.",
    noCustomerMatchResult: "No se ha encontrado ningún cliente que coincida.",
    noOrderMatchResult: "Buscar por ID completo de pedido.",
    createCustomer: "Crear nuevo cliente",
    customer: "Cliente",
    orderID: "ID de pedido"
  },
  createCustomerForm: {
    title: "Crear nuevo cliente",
    createAccount: "Crear",
    cancel: "Cancelar",
    firstName: {
      label: "Nombre",
      placeholder: "Nombre"
    },
    lastName: {
      label: "Apellido",
      placeholder: "Apellido"
    },
    emailAddress: {
      label: "Dirección de correo electrónico",
      placeholder: "Dirección de correo electrónico"
    },
    createAccountAlert: "La sesión de cliente comienza una vez creada la cuenta de cliente.",
    validationErrors: {
      firstName: "Indique un nombre válido.",
      lastName: "Indique un apellido válido.",
      emailAddress: "Indique una dirección de correo electrónico válida."
    },
    badRequestDuplicatedEmail: "Ingrese una dirección de correo electrónico diferente porque {{ emailAddress }} ya existe."
  },
  customerList: {
    title: "Lista de clientes",
    description: "Seleccione un cliente de una de las distintas listas disponibles.",
    createCustomer: "Crear nuevo cliente",
    cancel: "Cancelar",
    tableHeader: {
      customer: "Cliente",
      customerName: "Nombre del cliente",
      name: "Nombre",
      email: "Correo electrónico",
      emailId: "Correo electrónico/ID",
      phone: "Teléfono",
      account: "Cuenta",
      cart: "Cesta",
      order: "Pedido",
      view: "Vista 360°",
      activeCart: "Cesta activa",
      viewActiveCart: "Ver cesta activa",
      orders: "Pedidos",
      viewOrders: "Ver pedidos",
      customer360: "Cliente 360°",
      viewCustomer360: "Ver Cliente 360°"
    },
    tableSort: {
      sortBy: "Ordenar por",
      byName: "Nombre (ascendente)",
      byNameAsc: "Nombre (ascendente)",
      byNameDesc: "Nombre (descendente)",
      byDateAsc: "Fecha (ascendente)",
      byDateDesc: "Fecha (descendente)",
      byOrderDateAsc: "Fecha de pedido (ascendente)",
      byOrderDateDesc: "Fecha de pedido (descendente)",
      byUnit: "Cuenta (ascendente)",
      byUnitDesc: "Cuente (descendente)"
    },
    page: {
      page: "Página {{count}}",
      previous: "Anterior",
      next: "Siguiente"
    },
    noOfCustomers: "{{count}} Clientes",
    oneCustomer: "1 Cliente",
    noCustomers: "En este momento no hay clientes en esta lista de clientes.",
    noLists: "En este momento no hay listas de clientes disponibles. Póngase en contacto con el administrador.",
    listsError: "Las listas de clientes no se pueden retirar. Intente nuevamente más tarde.",
    searchBox: "Buscar",
    enterSearchBox: "Indique nombre o correo electrónico del cliente"
  },
  switchCustomer: {
    dialog: {
      title: "Advertencia",
      body: "Hacer clic en \"Cambiar cliente\" finaliza la emulación de \"{{ customerA }}\" e inicia la de \"{{ customerB }}\". Se perderá cualquier cambio de \"{{ customerA }}\" que no haya sido grabado.",
      actions: {
        "switch": "Cambiar cliente"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Grabar para más tarde",
    dialog: {
      saveInfo: "Grabe la cesta antes de realizar otras acciones.",
      disableInfo: "No se puede grabar la cesta porque está vacía.",
      title: "Grabar cesta",
      row: {
        id: "ID",
        qty: "Cantidad",
        qtyFull: "Cantidad",
        total: "Total"
      },
      actions: {
        save: "Grabar para más tarde"
      }
    }
  },
  bindCart: {
    cartNumber: "Número de cesta",
    bindCartToCustomer: "Asignar cesta a cliente",
    success: "Se ha asignado la cesta correctamente",
    assignCartId: "Asignar ID de cesta a cliente",
    enterCartId: "Indique ID de cesta",
    resetCartId: "Restablecer",
    dialog: {
      title: "Asignar cesta anónima",
      body: "¿Quiere reemplazar la cesta activa en este momento por la cesta anónima? Si reemplaza la cesta activa en este momento se grabará como cesta grabada.",
      actions: {
        replace: "Reemplazar cesta"
      }
    }
  },
  csagentTokenExpired: "Su sesión de agente de soporte al cliente ha expirado.",
  endSession: "Finalizar sesión",
  endEmulation: "Finalizar emulación",
  agentSessionTimer: {
    label: "Expiró la sesión",
    minutes: "min",
    reset: "Restablecer"
  },
  auth: {
    agentLoggedInError: "No puede iniciar sesión como usuario cuando se encuentra activa una sesión de agente CS. Emule el usuario o cierre la sesión de agente CS."
  },
  error: {
    noCustomerId: "No se encontró ningún ID de cliente para el usuario seleccionado. No se puede iniciar sesión."
  }
};
var asm$r = {
  asm: asm$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  asm: asm$r
};
var asm$o = {
  mainLogoLabel: "SAP",
  mainTitle: "Modo de servicio asistido",
  logout: "Cerrar sesión",
  hideUi: "Cerrar MSA",
  helpPortalUrl: "Ayuda",
  customers: "Clientes",
  asmCustomer360Button: "Cliente Vista 360º",
  asmCustomer360ViewButton: "Cliente 360°",
  createCustomerSuccessfullyAlert: "Se ha creado la cuenta del cliente e iniciado la sesión del cliente.",
  saveInactiveCartAlertInfo: "La cesta identificada está inactiva. Para realizar otras acciones con esta cesta, primero guárdela.",
  activeCartAlertInfo: "La cesta identificada está activa.",
  startCustomerEmulationAlertInfo: "Ha comenzado la emulación del cliente. Cualquier acción que realice replicará los efectos en la cuenta del cliente.",
  toggleUi: {
    collapse: "Ocultar MSA",
    expand: "Mostrar MSA"
  },
  loginForm: {
    submit: "Iniciar sesión",
    userId: {
      label: "ID o correo electrónico de agente"
    },
    password: {
      label: "Contraseña"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Nombre/dirección de correo electrónico del cliente"
    },
    searchCustomer: {
      label: "Buscar por nombre o dirección de correo electrónico"
    },
    searchOrder: {
      label: "Buscar por ID completo de pedido"
    },
    submit: "Comenzar sesión",
    startEmulation: "Comenzar emulación",
    noMatch: "No se ha encontrado ningún cliente.",
    noMatchResult: "No se ha podido encontrar esta cuenta.",
    noCustomerMatchResult: "No se ha encontrado ningún cliente que coincida.",
    noOrderMatchResult: "Buscar por ID completo de pedido.",
    createCustomer: "Crear nuevo cliente",
    customer: "Cliente",
    orderID: "ID de pedido"
  },
  createCustomerForm: {
    title: "Crear nuevo cliente",
    createAccount: "Crear",
    cancel: "Cancelar",
    firstName: {
      label: "Nombre",
      placeholder: "Nombre"
    },
    lastName: {
      label: "Apellido",
      placeholder: "Apellido"
    },
    emailAddress: {
      label: "Dirección de correo electrónico",
      placeholder: "Dirección de correo electrónico"
    },
    createAccountAlert: "La sesión de cliente comienza una vez creada la cuenta de cliente.",
    validationErrors: {
      firstName: "Ingrese un nombre válido.",
      lastName: "Indique un apellido válido.",
      emailAddress: "Ingrese una dirección de correo electrónico válida."
    },
    badRequestDuplicatedEmail: "Ingrese una dirección de correo electrónico diferente porque {{ emailAddress }} ya existe."
  },
  customerList: {
    title: "Lista de clientes",
    description: "Seleccione un cliente de una de las distintas listas disponibles.",
    createCustomer: "Crear nuevo cliente",
    cancel: "Cancelar",
    tableHeader: {
      customer: "Cliente",
      customerName: "Nombre del cliente",
      name: "Nombre",
      email: "Correo electrónico",
      emailId: "Correo electrónico/ID",
      phone: "Teléfono",
      account: "Cuenta",
      cart: "Carro",
      order: "Pedido",
      view: "Vista 360°",
      activeCart: "Carro activo",
      viewActiveCart: "Ver carro activo",
      orders: "Pedidos",
      viewOrders: "Ver pedidos",
      customer360: "Cliente 360°",
      viewCustomer360: "Ver Cliente 360°"
    },
    tableSort: {
      sortBy: "Ordenar por",
      byName: "Nombre (ascendente)",
      byNameAsc: "Nombre (ascendente)",
      byNameDesc: "Nombre (descendente)",
      byDateAsc: "Fecha (ascendente)",
      byDateDesc: "Fecha (descendente)",
      byOrderDateAsc: "Fecha de pedido (ascendente)",
      byOrderDateDesc: "Fecha de pedido (descendente)",
      byUnit: "Cuenta (ascendente)",
      byUnitDesc: "Cuenta (descendente)"
    },
    page: {
      page: "Página {{count}}",
      previous: "Anterior",
      next: "Siguiente"
    },
    noOfCustomers: "{{count}} Clientes",
    oneCustomer: "1 Cliente",
    noCustomers: "En este momento no hay clientes en esta lista de clientes.",
    noLists: "En este momento no hay listas de clientes disponibles. Póngase en contacto con el administrador.",
    listsError: "Las listas de clientes no se pueden retirar. Intente nuevamente más tarde.",
    searchBox: "Buscar",
    enterSearchBox: "Indique nombre o correo electrónico del cliente"
  },
  switchCustomer: {
    dialog: {
      title: "Advertencia",
      body: "Hacer clic en \"Cambiar cliente\" finaliza la emulación de \"{{ customerA }}\" e inicia la de \"{{ customerB }}\". Se perderá cualquier cambio de \"{{ customerA }}\" que no haya sido grabado.",
      actions: {
        "switch": "Cambiar cliente"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Guardar para más tarde",
    dialog: {
      saveInfo: "Guarde el carro antes de realizar otras acciones.",
      disableInfo: "No se puede guardar el carro porque está vacío.",
      title: "Guardar carro",
      row: {
        id: "ID",
        qty: "Cantidad",
        qtyFull: "Cantidad",
        total: "Total"
      },
      actions: {
        save: "Guardar para más tarde"
      }
    }
  },
  bindCart: {
    cartNumber: "Número de carro",
    bindCartToCustomer: "Asignar carro a cliente",
    success: "Se ha asignado el carro correctamente",
    assignCartId: "Asignar carro a cliente",
    enterCartId: "Indique ID de carro",
    resetCartId: "Restablecer",
    dialog: {
      title: "Asignar carro anónimo",
      body: "¿Quiere reemplazar el carro activo en este momento por el carro anónimo? Si reemplaza el carro activo en este momento se guardará como carro guardado.",
      actions: {
        replace: "Reemplazar carro"
      }
    }
  },
  csagentTokenExpired: "Su sesión de agente de soporte al cliente ha expirado.",
  endSession: "Finalizar sesión",
  endEmulation: "Finalizar emulación",
  agentSessionTimer: {
    label: "Expiró la sesión",
    minutes: "min",
    reset: "Restablecer"
  },
  auth: {
    agentLoggedInError: "No puede iniciar sesión como usuario cuando se encuentra activa una sesión de agente CS. Emule el usuario o cierre la sesión de agente CS."
  },
  error: {
    noCustomerId: "No se encontró ningún ID de cliente para el usuario seleccionado. No se puede iniciar sesión."
  }
};
var asm$p = {
  asm: asm$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  asm: asm$p
};
var asm$m = {
  mainLogoLabel: "SAP",
  mainTitle: "Mode de service assisté",
  logout: "Se déconnecter",
  hideUi: "Fermer le mode de service assisté",
  helpPortalUrl: "Aide",
  customers: "Clients",
  asmCustomer360Button: "Vue client à 360°",
  asmCustomer360ViewButton: "Client 360°",
  createCustomerSuccessfullyAlert: "Le compte client a été créé et la session client a démarré.",
  saveInactiveCartAlertInfo: "Le panier identifié est inactif. Pour effectuer d'autres actions sur ce panier, commencez par le sauvegarder.",
  activeCartAlertInfo: "Le panier identifié est inactif.",
  startCustomerEmulationAlertInfo: "L'émulation du client a démarré. Toutes les actions que vous entreprendrez se répercuteront sur le compte client.",
  toggleUi: {
    collapse: "Masquer le mode de service assisté",
    expand: "Afficher le mode de service assisté"
  },
  loginForm: {
    submit: "Se connecter",
    userId: {
      label: "ID ou e-mail de l'agent"
    },
    password: {
      label: "Mot de passe"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Nom/adresse e-mail du client"
    },
    searchCustomer: {
      label: "Rechercher par nom ou par adresse e-mail"
    },
    searchOrder: {
      label: "Rechercher par ID complet de commande"
    },
    submit: "Démarrer la session",
    startEmulation: "Démarrer l'émulation",
    noMatch: "Aucun client trouvé",
    noMatchResult: "Ce compte est introuvable.",
    noCustomerMatchResult: "Aucun client correspondant trouvé",
    noOrderMatchResult: "Rechercher par ID complet de commande",
    createCustomer: "Créer un nouveau client",
    customer: "Client",
    orderID: "ID de commande"
  },
  createCustomerForm: {
    title: "Créer un nouveau client",
    createAccount: "Créer",
    cancel: "Annuler",
    firstName: {
      label: "Prénom",
      placeholder: "Prénom"
    },
    lastName: {
      label: "Nom de famille",
      placeholder: "Nom de famille"
    },
    emailAddress: {
      label: "Adresse e-mail",
      placeholder: "Adresse e-mail"
    },
    createAccountAlert: "La session client démarre lorsque vous créez le compte client.",
    validationErrors: {
      firstName: "Saisissez un prénom valide.",
      lastName: "Saisissez un nom de famille valide.",
      emailAddress: "Saisissez une adresse e-mail valide."
    },
    badRequestDuplicatedEmail: "Saisissez une autre adresse e-mail car {{ emailAddress }} existe déjà."
  },
  customerList: {
    title: "Liste de clients",
    description: "Sélectionnez un client parmi l'une des listes fournies.",
    createCustomer: "Créer un nouveau client",
    cancel: "Annuler",
    tableHeader: {
      customer: "Client",
      customerName: "Nom du client",
      name: "Nom",
      email: "E-mail",
      emailId: "E-mail/ID",
      phone: "Téléphone",
      account: "Compte",
      cart: "Panier",
      order: "Commande",
      view: "Vue 360°",
      activeCart: "Panier actif",
      viewActiveCart: "Afficher le panier actif",
      orders: "Commandes",
      viewOrders: "Afficher les commandes",
      customer360: "Client 360°",
      viewCustomer360: "Vue client à 360°"
    },
    tableSort: {
      sortBy: "Trier par",
      byName: "Nom (croissant)",
      byNameAsc: "Nom (croissant)",
      byNameDesc: "Nom (décroissant)",
      byDateAsc: "Date (croissant)",
      byDateDesc: "Date (décroissant)",
      byOrderDateAsc: "Date de commande (croissant)",
      byOrderDateDesc: "Date de commande (décroissant)",
      byUnit: "Compte (croissant)",
      byUnitDesc: "Compte (décroissant)"
    },
    page: {
      page: "Page {{count}}",
      previous: "Précédent",
      next: "Suivant"
    },
    noOfCustomers: "{{count}} clients",
    oneCustomer: "1 client",
    noCustomers: "Aucun client ne figure actuellement dans cette liste de clients.",
    noLists: "Aucune liste de clients n'est disponible actuellement. Contactez votre administrateur système.",
    listsError: "Impossible de récupérer les listes de clients. Veuillez réessayer ultérieurement.",
    searchBox: "Rechercher",
    enterSearchBox: "Saisissez le nom ou l'e-mail du client."
  },
  switchCustomer: {
    dialog: {
      title: "Avertissement",
      body: "Si vous cliquez sur \"Changer de client\", l'émulation se termine pour \"{{ customerA }}\" et commence pour \"{{ customerB }}\". Toutes les modifications non sauvegardées pour \"{{ customerA }}\" seront perdues.",
      actions: {
        "switch": "Changer de client"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Sauvegarder pour plus tard",
    dialog: {
      saveInfo: "Sauvegarder le panier avant d'effectuer d'autres actions.",
      disableInfo: "Impossible de sauvegarder le panier car il est vide.",
      title: "Sauvegarder le panier",
      row: {
        id: "ID",
        qty: "Qté",
        qtyFull: "Quantité",
        total: "Total"
      },
      actions: {
        save: "Sauvegarder pour plus tard"
      }
    }
  },
  bindCart: {
    cartNumber: "Numéro du panier",
    bindCartToCustomer: "Affecter le panier au client",
    success: "Le panier a été affecté avec succès.",
    assignCartId: "Affecter un ID de panier à un client",
    enterCartId: "Saisissez l'ID du panier.",
    resetCartId: "Réinitialiser",
    dialog: {
      title: "Affecter un panier anonyme",
      body: "Voulez-vous remplacer le panier actif actuel par le panier anonyme ? Si vous remplacez le panier actif actuel, il est sauvegardé en tant que panier sauvegardé.",
      actions: {
        replace: "Remplacer le panier"
      }
    }
  },
  csagentTokenExpired: "Votre session d'agent de support client a expiré.",
  endSession: "Terminer la session",
  endEmulation: "Terminer l'émulation",
  agentSessionTimer: {
    label: "Expiration de la session",
    minutes: "min",
    reset: "Réinitialiser"
  },
  auth: {
    agentLoggedInError: "Impossible de se connecter en tant qu'utilisateur lorsqu'une session de l'agent de support client est active. Veuillez soit émuler l'utilisateur, soit déconnecter l'agent de support client."
  },
  error: {
    noCustomerId: "Aucun ID client trouvé pour l'utilisateur sélectionné. Impossible de démarrer la session."
  }
};
var asm$n = {
  asm: asm$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  asm: asm$n
};
var asm$k = {
  mainLogoLabel: "SAP",
  mainTitle: "सहायक सेवा मोड",
  logout: "साइन आउट करें",
  hideUi: "ASM बंद करें",
  helpPortalUrl: "सहायता",
  customers: "ग्राहक",
  asmCustomer360Button: "ग्राहक 360 दृश्य",
  asmCustomer360ViewButton: "ग्राहक 360°",
  createCustomerSuccessfullyAlert: "ग्राहक खाता बनाया गया और ग्राहक सत्र शुरू किया गया.",
  saveInactiveCartAlertInfo: "पहचानी गई कार्ट निष्क्रिय कार्ट है. इस कार्ट पर अन्य कार्रवाईयां करें, इसे पहले सहेजें.",
  activeCartAlertInfo: "पहचानी गई कार्ट सक्रिय कार्ट है.",
  startCustomerEmulationAlertInfo: "ग्राहक अनुकरण प्रारंभ हुआ. आपके द्वारा की गई कोई भी कार्रवाई ग्राहक खाते पर प्रभाव दर्शाएगी.",
  toggleUi: {
    collapse: "ASM छिपाएं",
    expand: "ASM दिखाएं"
  },
  loginForm: {
    submit: "साइन इन करें",
    userId: {
      label: "एजेंट ID या ईमेल"
    },
    password: {
      label: "पासवर्ड"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "ग्राहक का नाम/ ईमेल पता"
    },
    searchCustomer: {
      label: "नाम या ईमेल पते द्वारा खोजें"
    },
    searchOrder: {
      label: "पूर्ण ऑर्डर ID द्वारा खोजें"
    },
    submit: "सत्र आरंभ करें",
    startEmulation: "अनुकरण प्रारंभ करें",
    noMatch: "कोई ग्राहक नहीं मिला",
    noMatchResult: "यह खाता नहीं मिल सकता.",
    noCustomerMatchResult: "कोई मेल खाता ग्राहक नहीं मिला.",
    noOrderMatchResult: "पूर्ण ऑर्डर ID द्वारा खोजें.",
    createCustomer: "नया ग्राहक बनाएं",
    customer: "ग्राहक",
    orderID: "ऑर्डर ID"
  },
  createCustomerForm: {
    title: "नया ग्राहक बनाएं",
    createAccount: "बनाएं",
    cancel: "रद्द करें",
    firstName: {
      label: "पहला नाम",
      placeholder: "प्रथम नाम"
    },
    lastName: {
      label: "अंतिम नाम",
      placeholder: "अंतिम नाम"
    },
    emailAddress: {
      label: "ईमेल पता",
      placeholder: "ईमेल पता"
    },
    createAccountAlert: "आपके द्वारा ग्राहक खाता बनाने पर ग्राहक सत्र प्रारंभ होता है.",
    validationErrors: {
      firstName: "मान्य प्रथम नाम दर्ज करें.",
      lastName: "मान्य अंतिम नाम दर्ज करें.",
      emailAddress: "कोई मान्य ईमेल पता दर्ज करें."
    },
    badRequestDuplicatedEmail: "{{ emailAddress }} पहले से मौजूद होने के कारण भिन्न ईमेल पता दर्ज करें."
  },
  customerList: {
    title: "ग्राहक सूची",
    description: "प्रदत्त अनेक सूचियों में से एक से ग्राहक चुनें.",
    createCustomer: "नया ग्राहक बनाएं",
    cancel: "रद्द करें",
    tableHeader: {
      customer: "ग्राहक",
      customerName: "ग्राहक का नाम",
      name: "नाम",
      email: "ईमेल",
      emailId: "ईमेल/ID",
      phone: "फोन",
      account: "खाता",
      cart: "कार्ट",
      order: "ऑर्डर",
      view: "360 दृश्य",
      activeCart: "सक्रिय कार्ट",
      viewActiveCart: "सक्रिय कार्ट देखें",
      orders: "ऑर्डर",
      viewOrders: "ऑर्डर देखें",
      customer360: "ग्राहक 360°",
      viewCustomer360: "ग्राहक 360° देखें"
    },
    tableSort: {
      sortBy: "इसके द्वारा क्रमित",
      byName: "नाम (आरोही क्रम में)",
      byNameAsc: "नाम (आरोही क्रम में)",
      byNameDesc: "नाम (अवरोही क्रम में)",
      byDateAsc: "दिनांक (आरोही क्रम में)",
      byDateDesc: "दिनांक (अवरोही क्रम में)",
      byOrderDateAsc: "ऑर्डर दिनांक (आरोही क्रम में)",
      byOrderDateDesc: "ऑर्डर दिनांक (अवरोही क्रम में)",
      byUnit: "खाता(आरोही क्रम में)",
      byUnitDesc: "खाता(अवरोही क्रम में)"
    },
    page: {
      page: "पृष्ठ {{count}}",
      previous: "पिछला",
      next: "अगला"
    },
    noOfCustomers: "{{count}} ग्राहक",
    oneCustomer: "1 ग्राहक",
    noCustomers: "वर्तमान में इस ग्राहक सूची में कोई ग्राहक नहीं हैं.",
    noLists: "वर्तमान में कोई ग्राहक सूचियां उपलब्ध नहीं हैं. अपने सिस्टम व्यवस्थापक से संपर्क करें.",
    listsError: "ग्राहक सूचियां पुनर्प्राप्त नहीं की जा सकीं. कृपया बाद में फिर से कोशिश करें.",
    searchBox: "खोजें",
    enterSearchBox: "ग्राहक का नाम या ईमेल दर्ज करें"
  },
  switchCustomer: {
    dialog: {
      title: "चेतावनी",
      body: "\"ग्राहक स्विच करें\" पर क्लिक करने से \"{{ customerA }}\" के लिए अनुकरण समाप्त होगा और \"{{ customerB }}\" प्रारंभ होगा. \"{{ customerA }}\" हेतु कोई भी सहेजे न गए परिवर्तन खो जाएंगे.",
      actions: {
        "switch": "ग्राहक स्विच करें"
      }
    }
  },
  saveCart: {
    saveCartBtn: "बाद के लिए सहेजे",
    dialog: {
      saveInfo: "अगली कार्रवाई करने से पहले कार्ट सहेजें.",
      disableInfo: "कार्ट रिक्त होने से सहेजी नहीं जा सकती.",
      title: "कार्ट सहेजें",
      row: {
        id: "ID",
        qty: "मात्रा",
        qtyFull: "मात्रा",
        total: "कुल"
      },
      actions: {
        save: "बाद के लिए सहेजे"
      }
    }
  },
  bindCart: {
    cartNumber: "कार्ट संख्या",
    bindCartToCustomer: "ग्राहक को कार्ट असाइन करें",
    success: "कार्ट सफलतापूर्वक असाइ किया गया",
    assignCartId: "ग्राहक को कार्ट id असाइन करें",
    enterCartId: "कार्ट  id दर्ज करें",
    resetCartId: "रीसेट करें",
    dialog: {
      title: "अज्ञात कार्ट असाइन करें",
      body: "क्या आप वर्तमान सक्रिय कार्ट को अज्ञात कार्ट से बदलना चाहते हैं? यदि आपने वर्तमान सक्रिय कार्ट बदली, यह सहेजी गई कार्ट के रूप में सहेजी जाएगी.",
      actions: {
        replace: "कार्ट प्रतिस्थापित करें"
      }
    }
  },
  csagentTokenExpired: "आपका ग्राहक सहायता एजेंट सत्र समाप्त हुआ.",
  endSession: "समाप्ति सत्र",
  endEmulation: "अनुकरण समाप्ति",
  agentSessionTimer: {
    label: "सत्र समय समाप्ति",
    minutes: "मिनट",
    reset: "रीसेट करें"
  },
  auth: {
    agentLoggedInError: "सक्रिय CS एजेंट सत्र होने पर उपयोगकर्ता के रूप में लॉगिन नहीं कर सकते. कृपया या तो उपयोगकर्ता का अनुकरण करें या CS एजेंट से लॉगआउट करें."
  },
  error: {
    noCustomerId: "चयनित उपयोगकर्ता के लिए कोई ग्राहक नहीं मिला. सत्र प्रारंभ नहीं किया जा सकता."
  }
};
var asm$l = {
  asm: asm$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  asm: asm$l
};
var asm$i = {
  mainLogoLabel: "SAP",
  mainTitle: "Támogatott szolgáltatási mód",
  logout: "Kijelentkezés",
  hideUi: "ASM bezárása",
  helpPortalUrl: "Súgó",
  customers: "Ügyfelek",
  asmCustomer360Button: "Ügyfél 360 fokos nézete",
  asmCustomer360ViewButton: "Ügyfél 360°",
  createCustomerSuccessfullyAlert: "Az ügyfélfiók létrejött, és megkezdődött az ügyfélmunkamenet.",
  saveInactiveCartAlertInfo: "Az azonosított kosár inaktív. Ha további műveleteket szeretne végezni a kosárral, először mentse.",
  activeCartAlertInfo: "Az azonosított kosár aktív.",
  startCustomerEmulationAlertInfo: "Ügyfélemulálás megkezdve. A végrehajtott műveletek hatásai megjelennek az ügyfélfiókon is.",
  toggleUi: {
    collapse: "ASM elrejtése",
    expand: "ASM megjelenítése"
  },
  loginForm: {
    submit: "Bejelentkezés",
    userId: {
      label: "Ügynök azonosítója vagy e-mail címe"
    },
    password: {
      label: "Jelszó"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Ügyfél neve/e-mail-címe"
    },
    searchCustomer: {
      label: "Keresés név vagy e-mail-cím szerint"
    },
    searchOrder: {
      label: "Keresés teljes rendelésazonosító szerint"
    },
    submit: "Munkamenet indítása",
    startEmulation: "Emulálás indítása",
    noMatch: "Nem található ügyfél.",
    noMatchResult: "Ez a fiók nem található.",
    noCustomerMatchResult: "Nem található egyező ügyfél.",
    noOrderMatchResult: "Keresés teljes rendelési azonosító szerint.",
    createCustomer: "Új ügyfél létrehozása",
    customer: "Ügyfél",
    orderID: "Rendelésazonosító"
  },
  createCustomerForm: {
    title: "Új ügyfél létrehozása",
    createAccount: "Létrehozás",
    cancel: "Mégse",
    firstName: {
      label: "Utónév",
      placeholder: "Utónév"
    },
    lastName: {
      label: "Vezetéknév",
      placeholder: "Vezetéknév"
    },
    emailAddress: {
      label: "E-mail-cím",
      placeholder: "E-mail cím"
    },
    createAccountAlert: "Az ügyfélmunkamenet az ügyfélfiók létrehozása után indul.",
    validationErrors: {
      firstName: "Érvényes utónevet adjon meg.",
      lastName: "Érvényes vezetéknevet adjon meg.",
      emailAddress: "Érvényes e-mail-címet adjon meg."
    },
    badRequestDuplicatedEmail: "Adjon meg egy másik e-mail-címet; {{ emailAddress }} már létezik."
  },
  customerList: {
    title: "Ügyféllista",
    description: "Válasszon ki egy ügyfelet a megadott listák egyikéről.",
    createCustomer: "Új ügyfél létrehozása",
    cancel: "Mégse",
    tableHeader: {
      customer: "Ügyfél",
      customerName: "Ügyfél neve",
      name: "Név",
      email: "E-mail",
      emailId: "E-mail/azonosító",
      phone: "Telefon",
      account: "Fiók",
      cart: "Kosár",
      order: "Rendelés",
      view: "360 fokos nézet",
      activeCart: "Aktív kosár",
      viewActiveCart: "Aktív kosár megtekintése",
      orders: "Rendelések",
      viewOrders: "Rendelések megtekintése",
      customer360: "Ügyfél 360°",
      viewCustomer360: "Ügyfél 360° megtekintése"
    },
    tableSort: {
      sortBy: "Rendezés alapja",
      byName: "Név (növ.)",
      byNameAsc: "Név (növ.)",
      byNameDesc: "Név (csökk.)",
      byDateAsc: "Dátum (növ.)",
      byDateDesc: "Dátum (csökk.)",
      byOrderDateAsc: "Rendelés dátuma (növ.)",
      byOrderDateDesc: "Rendelés dátuma (csökk.)",
      byUnit: "Fiók (növ.)",
      byUnitDesc: "Fiók (csökk.)"
    },
    page: {
      page: "{{count}}. oldal",
      previous: "Előző",
      next: "Következő"
    },
    noOfCustomers: "{{count}} ügyfél",
    oneCustomer: "1 ügyfél",
    noCustomers: "Jelenleg nincsenek ügyfelek ebben az ügyféllistában.",
    noLists: "Jelenleg nincsenek elérhető ügyféllisták. Forduljon a rendszergazdájához.",
    listsError: "Nem sikerült lekérni az ügyféllistát. Próbálja újra később.",
    searchBox: "Keresés",
    enterSearchBox: "Adja meg az ügyfél nevét vagy e-mail-címét"
  },
  switchCustomer: {
    dialog: {
      title: "Figyelmeztetés",
      body: "Az „Ügyfél váltása” gombra kattintva befejezheti a(z) „{{ customerA }}”, és megkezdheti a(z) „{{ customerB }}” emulálását. A(z) „{{ customerA }}” nem mentett módosításai elvesznek.",
      actions: {
        "switch": "Ügyfél váltása"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Mentés későbbre",
    dialog: {
      saveInfo: "A további műveletek végrehajtása előtt mentse a kosarat.",
      disableInfo: "A kosár nem menthető, mert üres.",
      title: "Kosár mentése",
      row: {
        id: "Azonosító",
        qty: "Menny.",
        qtyFull: "Mennyiség",
        total: "Összesen"
      },
      actions: {
        save: "Mentés későbbre"
      }
    }
  },
  bindCart: {
    cartNumber: "Kosár száma",
    bindCartToCustomer: "Kosár hozzárendelése ügyfélhez",
    success: "Kosár sikeresen hozzárendelve",
    assignCartId: "Kosárazonosító hozzárendelése ügyfélhez",
    enterCartId: "Kosárazonosító megadása",
    resetCartId: "Alaphelyzetbe állítás",
    dialog: {
      title: "Névtelen kosár hozzárendelése",
      body: "Szeretné lecserélni a jelenlegi aktív kosarat a névtelen kosárra? Ha lecseréli a jelenlegi aktív kosarat, a rendszer mentett kosárként menti.",
      actions: {
        replace: "Kosár lecserélése"
      }
    }
  },
  csagentTokenExpired: "Az ügyféltámogatási ügynökmunkamenete lejárt.",
  endSession: "Munkamenet befejezése",
  endEmulation: "Emulálás befejezése",
  agentSessionTimer: {
    label: "Munkamenet időtúllépése",
    minutes: "perc",
    reset: "Alaphelyzetbe állítás"
  },
  auth: {
    agentLoggedInError: "Nem lehet bejelentkezni felhasználóként, ha aktív az ügyfélszolgálati ügynök munkamenete. Emulálja a felhasználót, vagy jelentkezzen ki az ügyfélszolgálati ügynökből."
  },
  error: {
    noCustomerId: "Nem található customerId a kiválasztott felhasználóhoz. Nem lehet elindítani a munkamenetet."
  }
};
var asm$j = {
  asm: asm$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  asm: asm$j
};
var asm$g = {
  mainLogoLabel: "SAP",
  mainTitle: "Assisted Service Mode",
  logout: "Keluar",
  hideUi: "Tutup ASM",
  helpPortalUrl: "Bantuan",
  customers: "Pelanggan",
  asmCustomer360Button: "Tampilan Pelanggan 360 Derajat",
  asmCustomer360ViewButton: "Tampilan 360° - Pelanggan",
  createCustomerSuccessfullyAlert: "Akun pelanggan telah dibuat, dan sesi pelanggan telah dimulai.",
  saveInactiveCartAlertInfo: "Keranjang ini tidak aktif. Untuk mengambil tindakan lebih lanjut, simpan keranjang terlebih dahulu.",
  activeCartAlertInfo: "Keranjang ini aktif.",
  startCustomerEmulationAlertInfo: "Emulasi pelanggan telah dimulai. Setiap tindakan yang Anda lakukan akan tercermin pada akun pelanggan.",
  toggleUi: {
    collapse: "Sembunyikan ASM",
    expand: "Tampilkan ASM"
  },
  loginForm: {
    submit: "Masuk",
    userId: {
      label: "ID atau Email Agen"
    },
    password: {
      label: "Kata Sandi"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Nama Pelanggan/Alamat Email"
    },
    searchCustomer: {
      label: "Cari berdasarkan nama atau alamat email"
    },
    searchOrder: {
      label: "Cari berdasarkan ID pesanan yang lengkap"
    },
    submit: "Mulai Sesi",
    startEmulation: "Mulai Emulasi",
    noMatch: "Tidak ditemukan pelanggan.",
    noMatchResult: "Akun ini tidak dapat ditemukan.",
    noCustomerMatchResult: "Tidak ditemukan pelanggan yang cocok.",
    noOrderMatchResult: "Cari berdasarkan ID pesanan yang lengkap.",
    createCustomer: "Buat Pelanggan Baru",
    customer: "Pelanggan",
    orderID: "ID Pesanan"
  },
  createCustomerForm: {
    title: "Buat Pelanggan Baru",
    createAccount: "Buat",
    cancel: "Batalkan",
    firstName: {
      label: "Nama Depan",
      placeholder: "Nama depan"
    },
    lastName: {
      label: "Nama Belakang",
      placeholder: "Nama belakang"
    },
    emailAddress: {
      label: "Alamat Email",
      placeholder: "Alamat email"
    },
    createAccountAlert: "Sesi pelanggan akan dimulai setelah Anda membuat akun pelanggan.",
    validationErrors: {
      firstName: "Masukkan nama depan yang valid.",
      lastName: "Masukkan nama belakang yang valid.",
      emailAddress: "Masukkan alamat email yang valid."
    },
    badRequestDuplicatedEmail: "Masukkan alamat email yang lain karena {{ emailAddress }} sudah ada."
  },
  customerList: {
    title: "Daftar Pelanggan",
    description: "Pilih pelanggan dari salah satu daftar yang disediakan.",
    createCustomer: "Buat Pelanggan Baru",
    cancel: "Batalkan",
    tableHeader: {
      customer: "Pelanggan",
      customerName: "Nama Pelanggan",
      name: "Nama",
      email: "Email",
      emailId: "Email/ID",
      phone: "Telepon",
      account: "Akun",
      cart: "Keranjang",
      order: "Pesanan",
      view: "Tampilan 360",
      activeCart: "Keranjang Aktif",
      viewActiveCart: "Lihat Keranjang Aktif",
      orders: "Pesanan",
      viewOrders: "Lihat Pesanan",
      customer360: "Tampilan 360° - Pelanggan",
      viewCustomer360: "Lihat Tampilan 360° - Pelanggan"
    },
    tableSort: {
      sortBy: "Urutkan berdasarkan",
      byName: "Nama (Urutkan Naik)",
      byNameAsc: "Nama (Urutkan Naik)",
      byNameDesc: "Nama (Urutkan Turun)",
      byDateAsc: "Tanggal (Urutkan Naik)",
      byDateDesc: "Tanggal (Urutkan Turun)",
      byOrderDateAsc: "Tanggal Pemesanan (Urutkan Naik)",
      byOrderDateDesc: "Tanggal Pemesanan (Urutkan Turun)",
      byUnit: "Akun (Urutkan Naik)",
      byUnitDesc: "Akun (Urutkan Turun)"
    },
    page: {
      page: "Halaman {{count}}",
      previous: "Sebelumnya",
      next: "Selanjutnya"
    },
    noOfCustomers: "{{count}} Pelanggan",
    oneCustomer: "1 Pelanggan",
    noCustomers: "Saat ini tidak ada pelanggan dalam daftar pelanggan ini.",
    noLists: "Saat ini daftar pelanggan tidak tersedia. Hubungi administrator sistem Anda.",
    listsError: "Tidak dapat mengakses daftar pelanggan. Silakan coba lagi nanti.",
    searchBox: "Cari",
    enterSearchBox: "Masukkan nama atau email pelanggan"
  },
  switchCustomer: {
    dialog: {
      title: "Peringatan",
      body: "Mengeklik \"Ganti Pelanggan\" akan mengakhiri emulasi untuk \"{{ customerA }}\" dan memulai untuk \"{{ customerB }}\". Perubahan apa pun yang telah dibuat pada \"{{ customerA }}\" akan hilang.",
      actions: {
        "switch": "Ganti Pelanggan"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Simpan untuk Nanti",
    dialog: {
      saveInfo: "Simpan keranjang sebelum Anda mengambil tindakan lebih lanjut.",
      disableInfo: "Tidak dapat menyimpan keranjang karena kosong.",
      title: "Simpan Keranjang",
      row: {
        id: "ID",
        qty: "Jml",
        qtyFull: "Jumlah",
        total: "Total"
      },
      actions: {
        save: "Simpan untuk Nanti"
      }
    }
  },
  bindCart: {
    cartNumber: "Nomor Keranjang",
    bindCartToCustomer: "Tetapkan Keranjang ke Pelanggan",
    success: "Keranjang telah berhasil ditetapkan",
    assignCartId: "Tetapkan id keranjang ke pelanggan",
    enterCartId: "Masukkan id keranjang",
    resetCartId: "Atur Ulang",
    dialog: {
      title: "Tetapkan Keranjang Anonim",
      body: "Apakah Anda ingin mengganti keranjang aktif saat ini dengan keranjang anonim? Jika Anda mengganti keranjang aktif saat ini, keranjang tersebut akan disimpan sebagai keranjang tersimpan.",
      actions: {
        replace: "Ganti Keranjang"
      }
    }
  },
  csagentTokenExpired: "Sesi agen dukungan pelanggan Anda telah habis.",
  endSession: "Akhiri Sesi",
  endEmulation: "Akhiri Emulasi",
  agentSessionTimer: {
    label: "Waktu Sesi Habis",
    minutes: "mnt",
    reset: "Atur Ulang"
  },
  auth: {
    agentLoggedInError: "Tidak dapat masuk sebagai pengguna saat sesi agen CS sedang berlangsung. Lakukan emulasi pengguna atau keluar dari agen CS."
  },
  error: {
    noCustomerId: "Tidak ada Id pelanggan yang ditemukan untuk pengguna yang dipilih. Sesi tidak dapat dimulai."
  }
};
var asm$h = {
  asm: asm$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  asm: asm$h
};
var asm$e = {
  mainLogoLabel: "SAP",
  mainTitle: "Modalità di servizio assistito",
  logout: "Esci",
  hideUi: "Chiudi ASM",
  helpPortalUrl: "Guida",
  customers: "Clienti",
  asmCustomer360Button: "Vista cliente 360°",
  asmCustomer360ViewButton: "Cliente 360°",
  createCustomerSuccessfullyAlert: "L'account cliente è stato creato e la sessione cliente è stata avviata.",
  saveInactiveCartAlertInfo: "Il carrello acquisti identificato è un carrello acquisti inattivo. Per intervenire ulteriormente su questo carrello acquisti, innanzitutto salvarlo.",
  activeCartAlertInfo: "Il carrello acquisti identificato è un carrello acquisti attivo.",
  startCustomerEmulationAlertInfo: "L'emulazione cliente è stata avviata. Qualsiasi azione intrapresa rispecchierà gli effetti sull'account cliente.",
  toggleUi: {
    collapse: "Nascondi ASM",
    expand: "Mostra ASM"
  },
  loginForm: {
    submit: "Accedi",
    userId: {
      label: "ID o e-mail utente"
    },
    password: {
      label: "Password"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Nome/indirizzo e-mail cliente"
    },
    searchCustomer: {
      label: "Cerca in base a nome o indirizzo e-mail"
    },
    searchOrder: {
      label: "Cerca in base a ID ordine completo"
    },
    submit: "Avvia sessione",
    startEmulation: "Avvia emulazione",
    noMatch: "Nessun cliente trovato.",
    noMatchResult: "Impossibile trovare questo account.",
    noCustomerMatchResult: "Nessun cliente corrispondente trovato.",
    noOrderMatchResult: "Cerca in base a ID ordine completo",
    createCustomer: "Crea nuovo cliente",
    customer: "Cliente",
    orderID: "ID ordine"
  },
  createCustomerForm: {
    title: "Crea nuovo cliente",
    createAccount: "Crea",
    cancel: "Annulla",
    firstName: {
      label: "Nome",
      placeholder: "Nome"
    },
    lastName: {
      label: "Cognome",
      placeholder: "Cognome"
    },
    emailAddress: {
      label: "Indirizzo e-mail",
      placeholder: "Indirizzo e-mail"
    },
    createAccountAlert: "La sessione cliente inizia dopo aver creato l'account cliente.",
    validationErrors: {
      firstName: "Inserire un nome valido.",
      lastName: "Inserire un cognome valido.",
      emailAddress: "Inserire un indirizzo e-mail valido."
    },
    badRequestDuplicatedEmail: "Inserire un diverso indirizzo e-mail, poiché {{ emailAddress }} esiste già."
  },
  customerList: {
    title: "Lista clienti",
    description: "Selezionare un cliente da una delle numerose liste fornite.",
    createCustomer: "Crea nuovo cliente",
    cancel: "Annulla",
    tableHeader: {
      customer: "Cliente",
      customerName: "Nome cliente",
      name: "Nome",
      email: "E-mail",
      emailId: "E-mail/ID",
      phone: "Telefono",
      account: "Account",
      cart: "Carrello acquisti",
      order: "Ordine",
      view: "Vista 360°",
      activeCart: "Carrello acquisti attivo",
      viewActiveCart: "Visualizza carrello acquisti attivo",
      orders: "Ordini",
      viewOrders: "Visualizza ordini",
      customer360: "Cliente 360°",
      viewCustomer360: "Visualizza cliente 360°"
    },
    tableSort: {
      sortBy: "Ordina per",
      byName: "Nome (in ordine crescente)",
      byNameAsc: "Nome (in ordine crescente)",
      byNameDesc: "Nome (in ordine decrescente)",
      byDateAsc: "Data (in ordine crescente)",
      byDateDesc: "Data (in ordine decrescente)",
      byOrderDateAsc: "Data ordine (in ordine crescente)",
      byOrderDateDesc: "Data ordine (in ordine decrescente)",
      byUnit: "Account (in ordine crescente)",
      byUnitDesc: "Account (in ordine decrescente)"
    },
    page: {
      page: "Pagina {{count}}",
      previous: "Indietro",
      next: "Avanti"
    },
    noOfCustomers: "{{count}} clienti",
    oneCustomer: "1 cliente",
    noCustomers: "Al momento, non ci sono clienti in questa lista clienti.",
    noLists: "Al momento non sono disponibili liste clienti. Rivolgersi all'amministratore di sistema.",
    listsError: "Non è stato possibile recuperare liste clienti. Riprovare più tardi.",
    searchBox: "Cerca",
    enterSearchBox: "Inserisci nome o e-mail del cliente"
  },
  switchCustomer: {
    dialog: {
      title: "Messaggio di avvertimento",
      body: "La selezione di \"Cambia cliente\" terminerà l'emulazione per \"{{ customerA }}\" e la avvierà per \"{{ customerB }}\". Le modifiche a \"{{ customerA }}\" non salvate andranno perse.",
      actions: {
        "switch": "Cambia cliente"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Salva per uso successivo",
    dialog: {
      saveInfo: "Salvare il carrello acquisti prima di poter intervenire ulteriormente.",
      disableInfo: "Non è possibile salvare il carrello acquisti, poiché è vuoto.",
      title: "Salva carrello acquisti",
      row: {
        id: "ID",
        qty: "Qtà",
        qtyFull: "Quantità",
        total: "Totale"
      },
      actions: {
        save: "Salva per uso successivo"
      }
    }
  },
  bindCart: {
    cartNumber: "Numero carrello acquisti",
    bindCartToCustomer: "Assegna carrello acquisti al cliente",
    success: "Il carrello acquisti è stato assegnato correttamente",
    assignCartId: "Assegna un ID carrello acquisti al cliente",
    enterCartId: "Inserisci ID carrello acquisti",
    resetCartId: "Reimposta",
    dialog: {
      title: "Assegna carrello acquisti anonimo",
      body: "Sostituire il carrello attivo attuale con il carrello anonimo? Se si sostituisce, il carrello attivo attuale verrà salvato come carrello acquisti salvato. ",
      actions: {
        replace: "Sostituisci carrello acquisti"
      }
    }
  },
  csagentTokenExpired: "La sessione dell'agente di supporto cliente è scaduta.",
  endSession: "Termina sessione",
  endEmulation: "Termina emulazione",
  agentSessionTimer: {
    label: "Timeout sessione",
    minutes: "min",
    reset: "Reimposta"
  },
  auth: {
    agentLoggedInError: "Impossibile accedere come utente quando è attiva una sessione utente CS. Emulare l'utente o disconnettere l'utente CS."
  },
  error: {
    noCustomerId: "Nessun ID cliente trovato per l'utente selezionato. Impossibile avviare la sessione."
  }
};
var asm$f = {
  asm: asm$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  asm: asm$f
};
var asm$c = {
  mainLogoLabel: "SAP",
  mainTitle: "支援されたサービスモード",
  logout: "サインアウト",
  hideUi: "ASM を閉じる",
  helpPortalUrl: "ヘルプ",
  customers: "顧客",
  asmCustomer360Button: "顧客 360 ビュー",
  asmCustomer360ViewButton: "顧客 360 度",
  createCustomerSuccessfullyAlert: "顧客アカウントが作成され顧客セッションが開始されました。",
  saveInactiveCartAlertInfo: "識別されたカードは無効なカートです。このカートでアクションをさらに行うには、まず保存してください。",
  activeCartAlertInfo: "識別されたカートは有効なカートです。",
  startCustomerEmulationAlertInfo: "顧客エミュレーションが開始されました。実行するすべてのアクションが顧客アカウントの効果に反映されます。",
  toggleUi: {
    collapse: "ASM を表示",
    expand: "ASM を非表示"
  },
  loginForm: {
    submit: "サインイン",
    userId: {
      label: "エージェント ID または電子メール"
    },
    password: {
      label: "パスワード"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "顧客名/電子メールアドレス"
    },
    searchCustomer: {
      label: "名前または電子メールアドレスで検索"
    },
    searchOrder: {
      label: "完全な注文 ID で検索"
    },
    submit: "セッションの開始",
    startEmulation: "エミュレーションの開始",
    noMatch: "顧客が見つかりません。",
    noMatchResult: "このアカウントは見つかりません。",
    noCustomerMatchResult: "一致する顧客が見つかりません。",
    noOrderMatchResult: "完全な注文 ID で検索します。",
    createCustomer: "新しい顧客の作成",
    customer: "顧客",
    orderID: "注文 ID"
  },
  createCustomerForm: {
    title: "新しい顧客の作成",
    createAccount: "作成",
    cancel: "キャンセル",
    firstName: {
      label: "名",
      placeholder: "名"
    },
    lastName: {
      label: "姓",
      placeholder: "姓"
    },
    emailAddress: {
      label: "電子メールアドレス",
      placeholder: "電子メールアドレス"
    },
    createAccountAlert: "顧客セッションは顧客アカウントを作成すると開始されます。",
    validationErrors: {
      firstName: "有効な名を入力してください。",
      lastName: "有効な姓を入力してください。",
      emailAddress: "有効な電子メールアドレスを入力してください。"
    },
    badRequestDuplicatedEmail: "{{ emailAddress }} はすでに存在しているため、別の電子メールアドレスを入力してください。"
  },
  customerList: {
    title: "顧客リスト",
    description: "提供されているいくつかのリストから顧客を選択します。",
    createCustomer: "新しい顧客の作成",
    cancel: "キャンセル",
    tableHeader: {
      customer: "顧客",
      customerName: "顧客名",
      name: "名前",
      email: "電子メール",
      emailId: "電子メール/ID",
      phone: "電話番号",
      account: "アカウント",
      cart: "カート",
      order: "注文",
      view: "360 ビュー",
      activeCart: "有効なカート",
      viewActiveCart: "有効なカートを表示",
      orders: "注文",
      viewOrders: "注文を表示",
      customer360: "顧客 360 度",
      viewCustomer360: "顧客 360 度で表示"
    },
    tableSort: {
      sortBy: "ソート基準",
      byName: "名前 (昇順)",
      byNameAsc: "名前 (昇順)",
      byNameDesc: "名前 (降順)",
      byDateAsc: "日付 (昇順)",
      byDateDesc: "日付 (降順)",
      byOrderDateAsc: "注文日 (昇順)",
      byOrderDateDesc: "注文日 (降順)",
      byUnit: "アカウント (昇順)",
      byUnitDesc: "アカウント (降順)"
    },
    page: {
      page: "{{count}} ページ",
      previous: "前",
      next: "次"
    },
    noOfCustomers: "{{count}} 人の顧客",
    oneCustomer: "1 人の顧客",
    noCustomers: "現在、この顧客リストには顧客がいません。",
    noLists: "現在、使用可能な顧客リストがありません。システム管理者に連絡してください。",
    listsError: "顧客リストを取得できませんでした。後でもう一度実行してください。",
    searchBox: "検索",
    enterSearchBox: "顧客名または電子メールを入力"
  },
  switchCustomer: {
    dialog: {
      title: "警告",
      body: "\"顧客の切り替え\" をクリックすると、\"{{ customerA }}\" のエミュレーションが終了し、\"{{ customerB }}\" のエミュレーションが開始されます。\"{{ customerA }}\" で保存されていない変更は失われます。",
      actions: {
        "switch": "顧客の切り替え"
      }
    }
  },
  saveCart: {
    saveCartBtn: "保存して後で使用",
    dialog: {
      saveInfo: "アクションを続ける前にカートを保存してください。",
      disableInfo: "カートは空であるため保存できません。",
      title: "カートを保存",
      row: {
        id: "ID",
        qty: "数量",
        qtyFull: "数量",
        total: "合計"
      },
      actions: {
        save: "保存して後で使用"
      }
    }
  },
  bindCart: {
    cartNumber: "カート番号",
    bindCartToCustomer: "顧客にカートを割り当て",
    success: "カートが正常に割り当てられました",
    assignCartId: "顧客にカート ID を割り当て",
    enterCartId: "カート ID の入力",
    resetCartId: "リセット",
    dialog: {
      title: "匿名カートの割り当て",
      body: "現在の有効なカートを匿名カートで置換しますか? 現在の有効なコートを置換すると、保存されたカートとして保存されます。",
      actions: {
        replace: "カートの置換"
      }
    }
  },
  csagentTokenExpired: "カスタマサポートエージェントセッションの期限が切れました。",
  endSession: "セッション終了",
  endEmulation: "エミュレーション終了",
  agentSessionTimer: {
    label: "セッションタイムアウト",
    minutes: "分",
    reset: "リセット"
  },
  auth: {
    agentLoggedInError: "有効な CS エージェントセッションが存在する場合、ユーザとしてログインできません。ユーザをエミュレートするか、または CS エージェントからログアウトしてください。"
  },
  error: {
    noCustomerId: "選択したユーザの顧客 ID が見つかりませんでした。セッションを開始できません。"
  }
};
var asm$d = {
  asm: asm$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  asm: asm$d
};
var asm$a = {
  mainLogoLabel: "SAP",
  mainTitle: "지원 서비스 모드",
  logout: "로그아웃",
  hideUi: "ASM 닫기",
  helpPortalUrl: "도움말",
  customers: "고객",
  asmCustomer360Button: "고객 360도 뷰",
  asmCustomer360ViewButton: "고객 360도",
  createCustomerSuccessfullyAlert: "고객 계정이 생성되었으며 고객 세션이 시작되었습니다.",
  saveInactiveCartAlertInfo: "식별된 장바구니가 사용 중인 장바구니가 아닙니다. 이 장바구니로 계속하려면 먼저 저장하십시오.",
  activeCartAlertInfo: "식별된 장바구니가 사용 중인 장바구니입니다.",
  startCustomerEmulationAlertInfo: "고객 에뮬레이션이 시작되었습니다. 계속하면 고객 계정에 영향을 줍니다.",
  toggleUi: {
    collapse: "ASM 숨기기",
    expand: "ASM 표시"
  },
  loginForm: {
    submit: "로그인",
    userId: {
      label: "에이전트 ID 또는 이메일"
    },
    password: {
      label: "암호"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "고객 이름/이메일 주소"
    },
    searchCustomer: {
      label: "이름 또는 이메일 주소로 검색"
    },
    searchOrder: {
      label: "전체 주문 ID로 검색"
    },
    submit: "세션 시작",
    startEmulation: "에뮬레이션 시작",
    noMatch: "고객이 없습니다.",
    noMatchResult: "이 계정을 찾을 수 없습니다.",
    noCustomerMatchResult: "일치하는 고객이 없습니다.",
    noOrderMatchResult: "전체 주문 ID로 검색",
    createCustomer: "새 고객 만들기",
    customer: "고객",
    orderID: "주문 ID"
  },
  createCustomerForm: {
    title: "새 고객 만들기",
    createAccount: "만들기",
    cancel: "취소",
    firstName: {
      label: "이름",
      placeholder: "이름"
    },
    lastName: {
      label: "성",
      placeholder: "성"
    },
    emailAddress: {
      label: "이메일 주소",
      placeholder: "이메일 주소"
    },
    createAccountAlert: "고객 계정을 만들면 고객 새션이 시작됩니다.",
    validationErrors: {
      firstName: "유효한 이름을 입력하십시오.",
      lastName: "유효한 성을 입력하십시오.",
      emailAddress: "유효한 이메일 주소를 입력하십시오."
    },
    badRequestDuplicatedEmail: "{{ emailAddress }}이(가) 이미 있으므로 다른 이메일 주소를 입력하십시오."
  },
  customerList: {
    title: "고객 목록",
    description: "제공된 여러 리스트 중 하나에서 고객을 선택하십시오.",
    createCustomer: "새 고객 만들기",
    cancel: "취소",
    tableHeader: {
      customer: "고객",
      customerName: "고객 이름",
      name: "이름",
      email: "이메일",
      emailId: "이메일/ID",
      phone: "전화",
      account: "계정",
      cart: "장바구니",
      order: "주문",
      view: "360도 뷰",
      activeCart: "사용 중인 장바구니",
      viewActiveCart: "사용 중인 장바구니 보기",
      orders: "주문",
      viewOrders: "주문 보기",
      customer360: "고객 360도",
      viewCustomer360: "고객 360도 보기"
    },
    tableSort: {
      sortBy: "정렬 기준",
      byName: "이름(오름차순)",
      byNameAsc: "이름(오름차순)",
      byNameDesc: "이름(내림차순)",
      byDateAsc: "날짜(오름차순)",
      byDateDesc: "날짜(내림차순)",
      byOrderDateAsc: "주문일(오름차순)",
      byOrderDateDesc: "주문일(내림차순)",
      byUnit: "계정(오름차순)",
      byUnitDesc: "계정(내림차순)"
    },
    page: {
      page: "{{count}} 페이지",
      previous: "이전",
      next: "다음"
    },
    noOfCustomers: "{{count}}명의 고객",
    oneCustomer: "1명의 고객",
    noCustomers: "현재 이 고객 목록에는 고객이 없습니다.",
    noLists: "현재 사용할 수 있는 고객 목록이 없습니다. 시스템 관리자에게 문의하십시오.",
    listsError: "고객 목록을 가져올 수 없습니다. 나중에 다시 시도하십시오.",
    searchBox: "검색",
    enterSearchBox: "고객 이름이나 이메일을 입력하십시오."
  },
  switchCustomer: {
    dialog: {
      title: "경고",
      body: "\"고객 전환\"을 클릭하면 \"{{ customerA }}\"에 대한 에뮬레이션이 종료되고 \"{{ customerB }}\"에 대한 에뮬레이션이 시작됩니다. \"{{ customerA }}\"에 대한 저장되지 않은 변경사항이 손실됩니다.",
      actions: {
        "switch": "고객 전환"
      }
    }
  },
  saveCart: {
    saveCartBtn: "저장 후 나중에 사용",
    dialog: {
      saveInfo: "나중에 계속하려면 장바구니를 저장하십시오.",
      disableInfo: "장바구니가 비어 있어서 저장할 수 없습니다.",
      title: "장바구니 저장",
      row: {
        id: "ID",
        qty: "수량",
        qtyFull: "수량",
        total: "합계"
      },
      actions: {
        save: "저장 후 나중에 사용"
      }
    }
  },
  bindCart: {
    cartNumber: "장바구니 번호",
    bindCartToCustomer: "고객에게 장바구니 할당",
    success: "장바구니가 할당되었습니다.",
    assignCartId: "고객에게 장바구니 ID 할당",
    enterCartId: "장바구니 ID 입력",
    resetCartId: "재설정",
    dialog: {
      title: "익명의 장바구니 할당",
      body: "현재 사용 중인 장바구니를 익명의 장바구니로 바꾸시겠습니까? 현재 사용 중인 장바구니를 바꾸면 저장된 장바구니로 저장됩니다.",
      actions: {
        replace: "장바구니 바꾸기"
      }
    }
  },
  csagentTokenExpired: "고객 지원 에이전트 세션이 만료되었습니다.",
  endSession: "세션 종료",
  endEmulation: "에뮬레이션 종료",
  agentSessionTimer: {
    label: "세션 시간 제한",
    minutes: "분",
    reset: "재설정"
  },
  auth: {
    agentLoggedInError: "사용 중인 CS 에이전트 세션이 있으면 사용자로 로그인할 수 없습니다. 사용자를 에뮬레이션하거나 CS 에이전트를 로그아웃하십시오."
  },
  error: {
    noCustomerId: "선택한 사용자의 고객 ID가 없습니다. 세션을 시작할 수 없습니다."
  }
};
var asm$b = {
  asm: asm$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  asm: asm$b
};
var asm$8 = {
  mainLogoLabel: "SAP",
  mainTitle: "Tryb usługi wspomaganej",
  logout: "Wyloguj się",
  hideUi: "Zamknij ASM",
  helpPortalUrl: "Pomoc",
  customers: "Klienci",
  asmCustomer360Button: "Widok klienta 360",
  asmCustomer360ViewButton: "Klient 360°",
  createCustomerSuccessfullyAlert: "Konto klienta zostało utworzone i sesja klienta rozpoczęła się.",
  saveInactiveCartAlertInfo: "Zidentyfikowany koszyk jest nieaktywny. Aby wykonać dalsze czynności na tym koszyku, najpierw go zapisz.",
  activeCartAlertInfo: "Zidentyfikowany koszyk jest aktywny.",
  startCustomerEmulationAlertInfo: "Rozpoczęła się emulacja klienta. Wszelkie wykonane czynności będą miały wpływ na konto klienta.",
  toggleUi: {
    collapse: "Ukryj ASM",
    expand: "Pokaż ASM"
  },
  loginForm: {
    submit: "Zarejestruj się",
    userId: {
      label: "ID lub e-mail agenta"
    },
    password: {
      label: "Hasło"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Nazwa klienta/adres e-mail"
    },
    searchCustomer: {
      label: "Szukaj po nazwie lub adresie e-mail"
    },
    searchOrder: {
      label: "Szukaj według pełnego ID zamówienia"
    },
    submit: "Rozpocznij sesję",
    startEmulation: "Rozpocznij emulację",
    noMatch: "Nie znaleziono klienta.",
    noMatchResult: "Nie znaleziono tego konta.",
    noCustomerMatchResult: "Nie znaleziono pasującego klienta.",
    noOrderMatchResult: "Szukaj według pełnego ID zamówienia.",
    createCustomer: "Utwórz nowego klienta",
    customer: "Klient",
    orderID: "ID zamówienia"
  },
  createCustomerForm: {
    title: "Utwórz nowego klienta",
    createAccount: "Utwórz",
    cancel: "Anuluj",
    firstName: {
      label: "Imię",
      placeholder: "Imię"
    },
    lastName: {
      label: "Nazwisko",
      placeholder: "Nazwisko"
    },
    emailAddress: {
      label: "Adres e-mail",
      placeholder: "Adres e-mail"
    },
    createAccountAlert: "Sesja klienta rozpoczyna się po utworzeniu konta klienta.",
    validationErrors: {
      firstName: "Wprowadź prawidłowe imię.",
      lastName: "Wprowadź prawidłowe nazwisko.",
      emailAddress: "Wprowadź prawidłowy adres e-mail."
    },
    badRequestDuplicatedEmail: "Wprowadź inny adres e-mail, ponieważ {{ emailAddress }} już istnieje."
  },
  customerList: {
    title: "Lista klientów",
    description: "Wybierz klienta z jednej z kilku dostępnych list.",
    createCustomer: "Utwórz nowego klienta",
    cancel: "Anuluj",
    tableHeader: {
      customer: "Klient",
      customerName: "Nazwa odbiorcy",
      name: "Nazwa",
      email: "E-mail",
      emailId: "E-mail/ID",
      phone: "Telefon",
      account: "Konto",
      cart: "Koszyk zakupów",
      order: "Zamówienie",
      view: "Widok 360",
      activeCart: "Aktywny koszyk zakupów",
      viewActiveCart: "Wyświetl aktywny koszyk",
      orders: "Zamówienia",
      viewOrders: "Wyświetl zamówienia",
      customer360: "Klient 360°",
      viewCustomer360: "Wyświetl klienta 360°"
    },
    tableSort: {
      sortBy: "Sortuj według",
      byName: "Nazwa (rosn.)",
      byNameAsc: "Nazwa (rosn.)",
      byNameDesc: "Nazwa (malej.)",
      byDateAsc: "Data (rosn.)",
      byDateDesc: "Data (malej.)",
      byOrderDateAsc: "Data zamówienia (rosn.)",
      byOrderDateDesc: "Data zamówienia (malej.)",
      byUnit: "Konto (rosn.)",
      byUnitDesc: "Konto (malej.)"
    },
    page: {
      page: "Strona {{count}}",
      previous: "Poprzednie",
      next: "Następne"
    },
    noOfCustomers: "{{count}} klientów",
    oneCustomer: "1 klient",
    noCustomers: "Obecnie nie ma klientów na tej liście klientów.",
    noLists: "Obecnie nie są dostępne żadne listy klientów. Skontaktuj się z administratorem systemu.",
    listsError: "Nie można pobrać listy klientów. Spróbuj ponownie później.",
    searchBox: "Szukaj",
    enterSearchBox: "Wprowadź nazwę klienta lub adres e-mail"
  },
  switchCustomer: {
    dialog: {
      title: "Ostrzeżenie",
      body: "Kliknięcie \"Przełącz klienta\" zakończy emulację dla \"{{ customerA }}\" i rozpocznie dla \"{{ customerB }}\". Niezapisane zmiany dla \"{{ customerA }}\" zostaną utracone.",
      actions: {
        "switch": "Przełącz klienta"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Zapisz w celu późniejszego użycia",
    dialog: {
      saveInfo: "Zapisz koszyk przed wykonaniem kolejnych czynności.",
      disableInfo: "Nie można zapisać koszyka, ponieważ jest pusty.",
      title: "Zapisz koszyk",
      row: {
        id: "ID",
        qty: "Ilość",
        qtyFull: "Ilość",
        total: "Suma"
      },
      actions: {
        save: "Zapisz w celu późniejszego użycia"
      }
    }
  },
  bindCart: {
    cartNumber: "Numer koszyka",
    bindCartToCustomer: "Przypisz koszyk do klienta",
    success: "Koszyk został przypisany",
    assignCartId: "Przypisz ID koszyka do klienta",
    enterCartId: "Wprowadź ID koszyka",
    resetCartId: "Resetuj",
    dialog: {
      title: "Przypisz koszyk anonimowy",
      body: "Czy chcesz zastąpić bieżący aktywny koszyk koszykiem anonimowym? Jeśli zastąpisz bieżący aktywny koszyk, zostanie on zapisany jako zapisany koszyk.",
      actions: {
        replace: "Zastąp koszyk"
      }
    }
  },
  csagentTokenExpired: "Sesja agenta obsługi klienta wygasła.",
  endSession: "Zakończ sesję",
  endEmulation: "Zakończ emulację",
  agentSessionTimer: {
    label: "Przekroczenie czasu sesji",
    minutes: "min",
    reset: "Resetuj"
  },
  auth: {
    agentLoggedInError: "Nie można zalogować się jako użytkownik, gdy aktywna jest sesja agenta CS. Emuluj użytkownika lub wyloguj agenta CS."
  },
  error: {
    noCustomerId: "Nie znaleziono customerId dla wybranego użytkownika. Nie można rozpocząć sesji."
  }
};
var asm$9 = {
  asm: asm$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  asm: asm$9
};
var asm$6 = {
  mainLogoLabel: "SAP",
  mainTitle: "Modo de serviço assistido",
  logout: "Encerrar sessão",
  hideUi: "Fechar ASM",
  helpPortalUrl: "Ajuda",
  customers: "Clientes",
  asmCustomer360Button: "Visão 360º do cliente",
  asmCustomer360ViewButton: "Cliente 360º",
  createCustomerSuccessfullyAlert: "O conta do cliente foi criada e a sessão do cliente foi iniciada.",
  saveInactiveCartAlertInfo: "O carrinho identificado é um carrinho inativo. Para efetuar outras ações nesse carrinho, grave-o primeiro.",
  activeCartAlertInfo: "O carrinho identificado é um carrinho ativo.",
  startCustomerEmulationAlertInfo: "A emulação do cliente foi iniciada. Todas as ações que você efetuar refletirão os efeitos na conta do cliente.",
  toggleUi: {
    collapse: "Ocultar ASM",
    expand: "Mostrar ASM"
  },
  loginForm: {
    submit: "Iniciar sessão",
    userId: {
      label: "ID ou e-mail do agente"
    },
    password: {
      label: "Senha"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Nome/endereço de e-mail do cliente"
    },
    searchCustomer: {
      label: "Pesquisar por nome ou endereço de e-mail"
    },
    searchOrder: {
      label: "Pesquisar por ID do pedido completo"
    },
    submit: "Iniciar sessão",
    startEmulation: "Iniciar emulação",
    noMatch: "Nenhum cliente encontrado.",
    noMatchResult: "Não é possível encontrar essa conta.",
    noCustomerMatchResult: "Nenhum cliente correspondente encontrado.",
    noOrderMatchResult: "Pesquisar por ID do pedido completo.",
    createCustomer: "Criar novo cliente",
    customer: "Cliente",
    orderID: "ID do pedido"
  },
  createCustomerForm: {
    title: "Criar novo cliente",
    createAccount: "Criar",
    cancel: "Cancelar",
    firstName: {
      label: "Nome",
      placeholder: "Nome"
    },
    lastName: {
      label: "Sobrenome",
      placeholder: "Sobrenome"
    },
    emailAddress: {
      label: "Endereço de e-mail",
      placeholder: "Endereço de e-mail"
    },
    createAccountAlert: "A sessão do cliente é iniciada assim que você criar a conta do cliente.",
    validationErrors: {
      firstName: "Insira um nome válido.",
      lastName: "Insira um sobrenome válido.",
      emailAddress: "Insira um endereço de e-mail válido."
    },
    badRequestDuplicatedEmail: "Insira um endereço de e-mail diferente, uma vez que {{ emailAddress }} já existe."
  },
  customerList: {
    title: "Lista de clientes",
    description: "Selecione um cliente a partir de uma das várias listas fornecidas.",
    createCustomer: "Criar novo cliente",
    cancel: "Cancelar",
    tableHeader: {
      customer: "Cliente",
      customerName: "Nome do cliente",
      name: "Nome",
      email: "E-mail",
      emailId: "E-mail/ID",
      phone: "Telefone",
      account: "Conta",
      cart: "Carrinho",
      order: "Pedido",
      view: "Visão 360º",
      activeCart: "Carrinho ativo",
      viewActiveCart: "Visualizar carrinho ativo",
      orders: "Pedidos",
      viewOrders: "Visualizar pedidos",
      customer360: "Cliente 360º",
      viewCustomer360: "Visualizar cliente 360º"
    },
    tableSort: {
      sortBy: "Ordenar por",
      byName: "Nome (crescente)",
      byNameAsc: "Nome (crescente)",
      byNameDesc: "Nome (decrescente)",
      byDateAsc: "Data (crescente)",
      byDateDesc: "Data (decrescente)",
      byOrderDateAsc: "Data do pedido (crescente)",
      byOrderDateDesc: "Data do pedido (decrescente)",
      byUnit: "Conta (crescente)",
      byUnitDesc: "Conta (decrescente)"
    },
    page: {
      page: "Página {{count}}",
      previous: "Anterior",
      next: "Seguinte"
    },
    noOfCustomers: "{{count}} clientes",
    oneCustomer: "1 cliente",
    noCustomers: "Atualmente não existem clientes nesta lista de clientes.",
    noLists: "Atualmente não existem listas de clientes disponíveis. Contate seu administrador do sistema.",
    listsError: "Não foi possível recuperar as listas de clientes. Tente novamente mais tarde.",
    searchBox: "Pesquisar",
    enterSearchBox: "Insira o nome ou e-mail do cliente"
  },
  switchCustomer: {
    dialog: {
      title: "Advertência",
      body: "Clicar em \"Mudar cliente\" terminará a emulação de \"{{ customerA }}\" e iniciará a de \"{{ customerB }}\". Todas as modificações não gravadas para \"{{ customerA }}\" serão perdidas.",
      actions: {
        "switch": "Mudar cliente"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Gravar para mais tarde",
    dialog: {
      saveInfo: "Grave o carrinho antes de efetuar outras ações.",
      disableInfo: "Não é possível gravar o carrinho, uma vez que está vazio.",
      title: "Gravar carrinho",
      row: {
        id: "ID",
        qty: "Qtd",
        qtyFull: "Quantidade",
        total: "Total"
      },
      actions: {
        save: "Gravar para mais tarde"
      }
    }
  },
  bindCart: {
    cartNumber: "Número do carrinho",
    bindCartToCustomer: "Atribuir carrinho ao cliente",
    success: "O carrinho foi atribuído com êxito",
    assignCartId: "Atribuir um ID de carrinho ao cliente",
    enterCartId: "Inserir ID de carrinho",
    resetCartId: "Redefinir",
    dialog: {
      title: "Atribuir carrinho anônimo",
      body: "Pretende substituir o carrinho ativo atual pelo carrinho anônimo? Se você substituir o carrinho ativo atual, ele será gravado como um carrinho gravado.",
      actions: {
        replace: "Substituir carrinho"
      }
    }
  },
  csagentTokenExpired: "Sua sessão de agente de suporte ao cliente expirou.",
  endSession: "Terminar sessão",
  endEmulation: "Terminar emulação",
  agentSessionTimer: {
    label: "Timeout da sessão",
    minutes: "min",
    reset: "Redefinir"
  },
  auth: {
    agentLoggedInError: "Não é possível fazer login como usuário quando existe uma sessão ativa do agente de suporte ao cliente. Emule o usuário ou faça logout do agente de suporte ao cliente."
  },
  error: {
    noCustomerId: "Nenhum customerId encontrado para o usuário selecionado. A sessão não pode ser iniciada."
  }
};
var asm$7 = {
  asm: asm$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  asm: asm$7
};
var asm$4 = {
  mainLogoLabel: "SAP",
  mainTitle: "Режим вспомогательной службы",
  logout: "Выйти",
  hideUi: "Закрыть ASM",
  helpPortalUrl: "Справка",
  customers: "Клиенты",
  asmCustomer360Button: "Ракурс 360° для клиента",
  asmCustomer360ViewButton: "Клиент 360°",
  createCustomerSuccessfullyAlert: "Учетная запись клиента создана, сеанс клиента начался.",
  saveInactiveCartAlertInfo: "Обнаруженная корзина неактивна. Сохраните ее, чтобы продолжить.",
  activeCartAlertInfo: "Обнаруженная корзина активна.",
  startCustomerEmulationAlertInfo: "Эмуляция клиента начата. Любые ваши действия отразятся на учетной записи клиента.",
  toggleUi: {
    collapse: "Скрыть ASM",
    expand: "Показать ASM"
  },
  loginForm: {
    submit: "Вход",
    userId: {
      label: "Ид. агента или эл. почта"
    },
    password: {
      label: "Пароль"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "Имя клиента/адрес эл. почты"
    },
    searchCustomer: {
      label: "Поиск по имени или адресу эл. почты"
    },
    searchOrder: {
      label: "Поиск по полному ид. заказа"
    },
    submit: "Начать сеанс",
    startEmulation: "Начать эмуляцию",
    noMatch: "Клиент не найден.",
    noMatchResult: "Эта учетная запись не найдена.",
    noCustomerMatchResult: "Соответствующий клиент не найден.",
    noOrderMatchResult: "Выполните поиск по полному ид. заказа.",
    createCustomer: "Создать нового клиента",
    customer: "Клиент",
    orderID: "Ид. заказа"
  },
  createCustomerForm: {
    title: "Создать нового клиента",
    createAccount: "Создать",
    cancel: "Отменить",
    firstName: {
      label: "Имя",
      placeholder: "Имя"
    },
    lastName: {
      label: "Фамилия",
      placeholder: "Фамилия"
    },
    emailAddress: {
      label: "Адрес электронной почты",
      placeholder: "Адрес электронной почты"
    },
    createAccountAlert: "Сеанс клиента начнется после создания учетной записи клиента.",
    validationErrors: {
      firstName: "Введите действительное имя.",
      lastName: "Введите действительное имя.",
      emailAddress: "Введите действительный адрес электронной почты."
    },
    badRequestDuplicatedEmail: "Введите другой адрес электронной почты, так как {{ emailAddress }} уже существует."
  },
  customerList: {
    title: "Список клиентов",
    description: "Выберите клиента из одного или нескольких предоставленных списков.",
    createCustomer: "Создать нового клиента",
    cancel: "Отменить",
    tableHeader: {
      customer: "Клиент",
      customerName: "Имя клиента",
      name: "Имя",
      email: "Электронная почта",
      emailId: "Электронная почта / ид.",
      phone: "Телефон",
      account: "Учетная запись",
      cart: "Корзина",
      order: "Заказ",
      view: "Ракурс 360°",
      activeCart: "Активная корзина",
      viewActiveCart: "Просмотреть активную корзину",
      orders: "Заказы",
      viewOrders: "Просмотреть заказы",
      customer360: "Клиент 360°",
      viewCustomer360: "Ракурс 360° для клиента"
    },
    tableSort: {
      sortBy: "Сортировать по",
      byName: "Имя (по восходящей)",
      byNameAsc: "Имя (по восходящей)",
      byNameDesc: "Имя (по нисходящей)",
      byDateAsc: "Дата (по восходящей)",
      byDateDesc: "Дата (по нисходящей)",
      byOrderDateAsc: "Дата заказа (по восходящей)",
      byOrderDateDesc: "Дата заказа (по нисходящей)",
      byUnit: "Учетная запись (по восходящей)",
      byUnitDesc: "Учетная запись (по нисходящей)"
    },
    page: {
      page: "Страница {{count}}",
      previous: "Назад",
      next: "Дальше"
    },
    noOfCustomers: "Число клиентов: {{count}}",
    oneCustomer: "1 клиент",
    noCustomers: "На текущий момент в этом списке клиентов нет клиентов.",
    noLists: "Сейчас нет доступных списков клиентов. Обратитесь к системному администратору.",
    listsError: "Не удалось вызвать списки клиентов. Повторите попытку позже.",
    searchBox: "Поиск",
    enterSearchBox: "Введите имя или электронную почту клиента"
  },
  switchCustomer: {
    dialog: {
      title: "Предупреждение",
      body: "При выборе \"Сменить клиента\" будет завершена эмуляция для \"{{ customerA }}\" и начата для \"{{ customerB }}\". Несохраненные изменения для \"{{ customerA }}\" будут потеряны.",
      actions: {
        "switch": "Сменить клиента"
      }
    }
  },
  saveCart: {
    saveCartBtn: "Сохранить на будущее",
    dialog: {
      saveInfo: "Сохраните корзину перед выполнением дальнейших действий.",
      disableInfo: "Корзину нельзя сохранить, так как она пуста.",
      title: "Сохранить корзину",
      row: {
        id: "Ид.",
        qty: "Кол.",
        qtyFull: "Количество",
        total: "Итого"
      },
      actions: {
        save: "Сохранить на будущее"
      }
    }
  },
  bindCart: {
    cartNumber: "№ корзины",
    bindCartToCustomer: "Присвоить корзину клиенту",
    success: "Корзина присвоена",
    assignCartId: "Присвоить ид. корзины клиенту",
    enterCartId: "Введите ид. корзины",
    resetCartId: "Сбросить",
    dialog: {
      title: "Присвоить анонимную корзину",
      body: "Заменить текущую активную корзину на анонимную корзину? Если вы замените текущую активную корзину, она будет сохранена как сохраненная корзина.",
      actions: {
        replace: "Заменить корзину"
      }
    }
  },
  csagentTokenExpired: "Срок сеанса агента службы поддержки клиентов истек.",
  endSession: "Завершить сеанс",
  endEmulation: "Завершить эмуляцию",
  agentSessionTimer: {
    label: "Тайм-аут сеанса",
    minutes: "мин",
    reset: "Сбросить"
  },
  auth: {
    agentLoggedInError: "Невозможно войти как пользователь, когда есть активный сеанс агента службы поддержки. Либо эмулируйте пользователя, либо выйдите из агента службы поддержки."
  },
  error: {
    noCustomerId: "Для выбранного пользователя не найден ид. клиента. Невозможно запустить сеанс."
  }
};
var asm$5 = {
  asm: asm$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  asm: asm$5
};
var asm$2 = {
  mainLogoLabel: "SAP",
  mainTitle: "辅助服务模式",
  logout: "注销",
  hideUi: "关闭 ASM",
  helpPortalUrl: "帮助",
  customers: "客户",
  asmCustomer360Button: "客户 360 视图",
  asmCustomer360ViewButton: "客户 360°",
  createCustomerSuccessfullyAlert: "客户账户已创建且客户会话已开始。",
  saveInactiveCartAlertInfo: "标识的购物车为非活动购物车。要对此购物车采取进一步操作，请先保存它。",
  activeCartAlertInfo: "标识的购物车为活动购物车。",
  startCustomerEmulationAlertInfo: "客户模拟已开始。您执行的任何操作都将对客户账户产生影响。",
  toggleUi: {
    collapse: "隐藏 ASM",
    expand: "显示 ASM"
  },
  loginForm: {
    submit: "登录",
    userId: {
      label: "代理标识或电子邮件"
    },
    password: {
      label: "密码"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "客户姓名/电子邮件地址"
    },
    searchCustomer: {
      label: "按名称或电子邮件地址搜索"
    },
    searchOrder: {
      label: "按完整订单标识搜索"
    },
    submit: "开始会话",
    startEmulation: "开始模拟",
    noMatch: "未找到客户。",
    noMatchResult: "无法找到此账户。",
    noCustomerMatchResult: "未找到匹配的客户。",
    noOrderMatchResult: "按完整订单标识搜索。",
    createCustomer: "创建新客户",
    customer: "客户",
    orderID: "订单标识"
  },
  createCustomerForm: {
    title: "创建新客户",
    createAccount: "创建",
    cancel: "取消",
    firstName: {
      label: "名字",
      placeholder: "名字"
    },
    lastName: {
      label: "姓氏",
      placeholder: "姓氏"
    },
    emailAddress: {
      label: "电子邮件地址",
      placeholder: "电子邮件地址"
    },
    createAccountAlert: "您创建客户账户后，客户会话将开始。",
    validationErrors: {
      firstName: "输入有效的名字。",
      lastName: "输入有效的姓氏。",
      emailAddress: "输入有效的电子邮件地址。"
    },
    badRequestDuplicatedEmail: "输入不同的电子邮件地址，因为 {{ emailAddress }} 已存在。"
  },
  customerList: {
    title: "客户列表",
    description: "从提供的几个列表中选择客户。",
    createCustomer: "创建新客户",
    cancel: "取消",
    tableHeader: {
      customer: "客户",
      customerName: "客户名称",
      name: "名称",
      email: "电子邮件",
      emailId: "电子邮件/标识",
      phone: "电话",
      account: "会计科目",
      cart: "购物车",
      order: "顺序",
      view: "360 视图",
      activeCart: "活动购物车",
      viewActiveCart: "查看活动购物车",
      orders: "订单",
      viewOrders: "查看订单",
      customer360: "客户 360°",
      viewCustomer360: "查看客户 360°"
    },
    tableSort: {
      sortBy: "排序依据",
      byName: "名称 (升序)",
      byNameAsc: "名称 (升序)",
      byNameDesc: "名称(降序)",
      byDateAsc: "日期(升序)",
      byDateDesc: "日期(降序)",
      byOrderDateAsc: "订单日期（升序）",
      byOrderDateDesc: "订单日期（降序）",
      byUnit: "账户（升序）",
      byUnitDesc: "账户（降序）"
    },
    page: {
      page: "第 {{count}} 页",
      previous: "上一步",
      next: "下一步"
    },
    noOfCustomers: "{{count}} 个客户",
    oneCustomer: "1 个客户",
    noCustomers: "当前客户列表中没有客户。",
    noLists: "目前没有可用的客户列表。请联系您的系统管理员。",
    listsError: "无法检索客户列表。请稍后重试。",
    searchBox: "搜索",
    enterSearchBox: "输入客户名称或电子邮件"
  },
  switchCustomer: {
    dialog: {
      title: "警告",
      body: "单击“切换客户”将结束“{{ customerA }}”的模拟并开始“{{ customerB }}”。“{{ customerA }}”的任何未保存的更改都将丢失。",
      actions: {
        "switch": "切换客户"
      }
    }
  },
  saveCart: {
    saveCartBtn: "保存备用",
    dialog: {
      saveInfo: "请先保存购物车，然后才能采取进一步操作。",
      disableInfo: "无法保存购物车，因为它为空。",
      title: "保存购物车",
      row: {
        id: "标识",
        qty: "数量",
        qtyFull: "数量",
        total: "总计"
      },
      actions: {
        save: "保存备用"
      }
    }
  },
  bindCart: {
    cartNumber: "购物车编号",
    bindCartToCustomer: "将购物车分配给客户",
    success: "已成功分配购物车",
    assignCartId: "将购物车标识分配给客户",
    enterCartId: "输入购物车标识",
    resetCartId: "重置",
    dialog: {
      title: "分配匿名购物车",
      body: "是否要用匿名购物车替换当前活动购物车？如果替换当前活动购物车，它将另存为已保存购物车。",
      actions: {
        replace: "替换购物车"
      }
    }
  },
  csagentTokenExpired: "您的客户支持代理已过期。",
  endSession: "结束会话",
  endEmulation: "结束模拟",
  agentSessionTimer: {
    label: "会话超时",
    minutes: "分钟",
    reset: "重置"
  },
  auth: {
    agentLoggedInError: "当存在活动的 CS 代理会话时，无法以用户身份登录。请模拟用户或注销 CS 代理。"
  },
  error: {
    noCustomerId: "未找到所选用户的 customerId。无法启动会话。"
  }
};
var asm$3 = {
  asm: asm$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  asm: asm$3
};
var asm = {
  mainLogoLabel: "SAP",
  mainTitle: "輔助服務模式",
  logout: "登出",
  hideUi: "關閉 ASM",
  helpPortalUrl: "輔助說明",
  customers: "客戶",
  asmCustomer360Button: "Customer 360 檢視",
  asmCustomer360ViewButton: "Customer 360°",
  createCustomerSuccessfullyAlert: "已建立客戶帳戶並已開始客戶工作階段。",
  saveInactiveCartAlertInfo: "識別的購物車是未啟用的購物車。若要對此購物車進行進一步動作，請先儲存。",
  activeCartAlertInfo: "識別的購物車是啟用中購物車。",
  startCustomerEmulationAlertInfo: "客戶模擬已開始。您所做的動作將對客戶帳戶產生影響。",
  toggleUi: {
    collapse: "隱藏 ASM",
    expand: "顯示 ASM"
  },
  loginForm: {
    submit: "登入",
    userId: {
      label: "代理人 ID 或電子郵件"
    },
    password: {
      label: "密碼"
    }
  },
  customerSearch: {
    searchTerm: {
      label: "客戶名稱/電子郵件地址"
    },
    searchCustomer: {
      label: "按名稱或電子郵件地址搜尋"
    },
    searchOrder: {
      label: "按完整訂單 ID 搜尋"
    },
    submit: "開始工作階段",
    startEmulation: "開始模擬",
    noMatch: "找不到客戶。",
    noMatchResult: "找不到此帳戶。",
    noCustomerMatchResult: "找不到相符的客戶。",
    noOrderMatchResult: "按完整訂單 ID 搜尋。",
    createCustomer: "建立新客戶",
    customer: "客戶",
    orderID: "訂單 ID"
  },
  createCustomerForm: {
    title: "建立新客戶",
    createAccount: "建立",
    cancel: "取消",
    firstName: {
      label: "名字",
      placeholder: "名字"
    },
    lastName: {
      label: "姓氏",
      placeholder: "姓氏"
    },
    emailAddress: {
      label: "電子郵件地址",
      placeholder: "電子郵件地址"
    },
    createAccountAlert: "建立客戶帳戶後，便會開始客戶工作階段。",
    validationErrors: {
      firstName: "輸入有效的名字。",
      lastName: "輸入有效的姓氏。",
      emailAddress: "輸入有效的電子郵件地址"
    },
    badRequestDuplicatedEmail: "由於 {{ emailAddress }} 已存在，請輸入不同的電子郵件地址。"
  },
  customerList: {
    title: "客戶清單",
    description: "從多個所提供清單之一選擇客戶。",
    createCustomer: "建立新客戶",
    cancel: "取消",
    tableHeader: {
      customer: "客戶",
      customerName: "客戶名稱",
      name: "名稱",
      email: "電子郵件",
      emailId: "電子郵件/ID",
      phone: "電話",
      account: "帳戶",
      cart: "購物車",
      order: "訂單",
      view: "360 檢視",
      activeCart: "啟用中購物車",
      viewActiveCart: "檢視啟用中購物車",
      orders: "訂單",
      viewOrders: "檢視訂單",
      customer360: "Customer 360°",
      viewCustomer360: "檢視 Customer 360°"
    },
    tableSort: {
      sortBy: "排序依據",
      byName: "名稱 (升冪)",
      byNameAsc: "名稱 (升冪)",
      byNameDesc: "名稱 (降冪)",
      byDateAsc: "日期 (升冪)",
      byDateDesc: "日期 (降冪)",
      byOrderDateAsc: "訂單日期 (升冪)",
      byOrderDateDesc: "訂單日期 (降冪)",
      byUnit: "帳戶 (升冪)",
      byUnitDesc: "帳戶 (降冪)"
    },
    page: {
      page: "第 {{count}} 頁",
      previous: "上一頁",
      next: "下一頁"
    },
    noOfCustomers: "{{count}} 個客戶",
    oneCustomer: "1 個客戶",
    noCustomers: "客戶清單中目前沒有客戶。",
    noLists: "目前沒有可用的客戶清單。請聯絡系統管理員。",
    listsError: "無法檢索客戶清單。請稍後再試一次。",
    searchBox: "搜尋",
    enterSearchBox: "輸入客戶名稱或電子郵件"
  },
  switchCustomer: {
    dialog: {
      title: "警告",
      body: "按一下「切換客戶」 將結束「{{ customerA }}」的模擬，並開始「{{ customerB }}」的模擬。「{{ customerA }}」的任何未儲存更改將遺失。",
      actions: {
        "switch": "切換客戶"
      }
    }
  },
  saveCart: {
    saveCartBtn: "儲存以供稍後使用",
    dialog: {
      saveInfo: "進行進一步動作前，先儲存購物車。",
      disableInfo: "由於購物車為空，因此無法儲存。",
      title: "儲存購物車",
      row: {
        id: "ID",
        qty: "數量",
        qtyFull: "數量",
        total: "總計"
      },
      actions: {
        save: "儲存以供稍後使用"
      }
    }
  },
  bindCart: {
    cartNumber: "購物車號碼",
    bindCartToCustomer: "將購物車指派給客戶",
    success: "已成功指派購物車",
    assignCartId: "將購物車 ID 指派給客戶",
    enterCartId: "輸入購物車 ID",
    resetCartId: "重設",
    dialog: {
      title: "指派匿名購物車",
      body: "您要將目前啟用中購物車取代為匿名購物車嗎？如果您取代目前啟用中購物車，則會儲存為儲存的購物車。",
      actions: {
        replace: "取代購物車"
      }
    }
  },
  csagentTokenExpired: "您的客戶支援代理人工作階段已到期。",
  endSession: "結束工作階段",
  endEmulation: "結束模擬",
  agentSessionTimer: {
    label: "工作階段逾時",
    minutes: "分",
    reset: "重設"
  },
  auth: {
    agentLoggedInError: "當有啟用中 CS 代理人工作階段時，無法以使用者身分登入。請模擬使用者或登出 CS 代理人。"
  },
  error: {
    noCustomerId: "找不到所選使用者的 customerId。無法開始工作階段。"
  }
};
var asm$1 = {
  asm: asm
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  asm: asm$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const asmTranslationChunksConfig = {
  asm: ['asm']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: asmTranslations
 *             +   resources: { en: asmTranslationsEn }
 *               }
 *             ```
 */
const asmTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { asmTranslationChunksConfig, asmTranslations, cs as asmTranslationsCs, de as asmTranslationsDe, en as asmTranslationsEn, es as asmTranslationsEs, es_CO as asmTranslationsEs_CO, fr as asmTranslationsFr, hi as asmTranslationsHi, hu as asmTranslationsHu, id as asmTranslationsId, it as asmTranslationsIt, ja as asmTranslationsJa, ko as asmTranslationsKo, pl as asmTranslationsPl, pt as asmTranslationsPt, ru as asmTranslationsRu, zh as asmTranslationsZh, zh_TW as asmTranslationsZh_TW };
