var reconsent$g = {
  dialog: {
    title: "Dokončení profilu",
    description: "Potřebujeme váš souhlas pro následující"
  }
};
var cdcProfile$g = {
  profileUpdateSuccess: "Údaje profilu byly úspěšně aktualizovány",
  profileUpdateFailure: "Nepodařilo se aktualizovat údaje profilu"
};
var cdc$g = {
  reconsent: reconsent$g,
  cdcProfile: cdcProfile$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  cdc: cdc$g
};
var reconsent$f = {
  dialog: {
    title: "Vervollständigung des Profils",
    description: "Wir benötigen noch Ihre Einwilligung für Folgendes"
  }
};
var cdcProfile$f = {
  profileUpdateSuccess: "Profildetails erfolgreich aktualisiert",
  profileUpdateFailure: "Profildetails konnten nicht aktualisiert werden"
};
var cdc$f = {
  reconsent: reconsent$f,
  cdcProfile: cdcProfile$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  cdc: cdc$f
};
var reconsent$e = {
  dialog: {
    title: "Profile Completion",
    description: "We still need your consent for the following"
  }
};
var cdcProfile$e = {
  profileUpdateSuccess: "Profile details successfully updated",
  profileUpdateFailure: "Failed to update profile details"
};
var cdc$e = {
  reconsent: reconsent$e,
  cdcProfile: cdcProfile$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  cdc: cdc$e
};
var reconsent$d = {
  dialog: {
    title: "Acciones para completar el perfil",
    description: "Aún necesitamos su consentimiento para lo siguiente"
  }
};
var cdcProfile$d = {
  profileUpdateSuccess: "Detalles del perfil actualizados correctamente",
  profileUpdateFailure: "Se produjo un error al actualizar los detalles del perfil"
};
var cdc$d = {
  reconsent: reconsent$d,
  cdcProfile: cdcProfile$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  cdc: cdc$d
};
var reconsent$c = {
  dialog: {
    title: "Acciones para completar el perfil",
    description: "Aún necesitamos su consentimiento para lo siguiente"
  }
};
var cdcProfile$c = {
  profileUpdateSuccess: "Detalles del perfil actualizados correctamente",
  profileUpdateFailure: "Se produjo un error al actualizar los detalles del perfil"
};
var cdc$c = {
  reconsent: reconsent$c,
  cdcProfile: cdcProfile$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  cdc: cdc$c
};
var reconsent$b = {
  dialog: {
    title: "Finalisation du profil",
    description: "Nous avons encore besoin de votre consentement pour ce qui suit"
  }
};
var cdcProfile$b = {
  profileUpdateSuccess: "Les détails du profil ont bien été mis à jour",
  profileUpdateFailure: "Échec de la mise à jour des détails du profil"
};
var cdc$b = {
  reconsent: reconsent$b,
  cdcProfile: cdcProfile$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  cdc: cdc$b
};
var reconsent$a = {
  dialog: {
    title: "प्रोफ़ाइल पूर्णता",
    description: "हमें निम्न के लिए अभी भी आपकी सहमति की आवश्यकता है"
  }
};
var cdcProfile$a = {
  profileUpdateSuccess: "प्रोफ़इल विवरण सफलतापूर्वक अपडेट किया गया",
  profileUpdateFailure: "प्रोफ़ाइल विवरण अपडेट करने में विफल"
};
var cdc$a = {
  reconsent: reconsent$a,
  cdcProfile: cdcProfile$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  cdc: cdc$a
};
var reconsent$9 = {
  dialog: {
    title: "Profil kitöltöttsége",
    description: "Még el kell fogadnia a következőt:"
  }
};
var cdcProfile$9 = {
  profileUpdateSuccess: "Profiladatok sikeresen frissítve",
  profileUpdateFailure: "Nem sikerült frissíteni a profiladatokat"
};
var cdc$9 = {
  reconsent: reconsent$9,
  cdcProfile: cdcProfile$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  cdc: cdc$9
};
var reconsent$8 = {
  dialog: {
    title: "Penyelesaian Profil",
    description: "Kami masih memerlukan persetujuan Anda untuk hal berikut"
  }
};
var cdcProfile$8 = {
  profileUpdateSuccess: "Rincian profil berhasil diperbarui",
  profileUpdateFailure: "Gagal memperbarui rincian profil"
};
var cdc$8 = {
  reconsent: reconsent$8,
  cdcProfile: cdcProfile$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  cdc: cdc$8
};
var reconsent$7 = {
  dialog: {
    title: "Completamento del profilo",
    description: "Abbiamo ancora bisogno del tuo consenso per quanto segue"
  }
};
var cdcProfile$7 = {
  profileUpdateSuccess: "Dettagli del profilo aggiornati correttamente",
  profileUpdateFailure: "Impossibile aggiornare i dettagli del profilo"
};
var cdc$7 = {
  reconsent: reconsent$7,
  cdcProfile: cdcProfile$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  cdc: cdc$7
};
var reconsent$6 = {
  dialog: {
    title: "プロファイル作成完了",
    description: "次に対する同意が必要です"
  }
};
var cdcProfile$6 = {
  profileUpdateSuccess: "プロファイル詳細が正常に更新されました",
  profileUpdateFailure: "プロファイル詳細を更新できませんでした"
};
var cdc$6 = {
  reconsent: reconsent$6,
  cdcProfile: cdcProfile$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  cdc: cdc$6
};
var reconsent$5 = {
  dialog: {
    title: "프로파일 완료",
    description: "다음에 대한 동의가 필요합니다."
  }
};
var cdcProfile$5 = {
  profileUpdateSuccess: "프로파일 세부사항이 업데이트되었습니다.",
  profileUpdateFailure: "프로파일 세부사항을 업데이트하지 못했습니다."
};
var cdc$5 = {
  reconsent: reconsent$5,
  cdcProfile: cdcProfile$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  cdc: cdc$5
};
var reconsent$4 = {
  dialog: {
    title: "Ukończenie opracowania profilu",
    description: "Nadal potrzebujemy twojej zgody na następujące czynności"
  }
};
var cdcProfile$4 = {
  profileUpdateSuccess: "Dane profilu zostały zaktualizowane",
  profileUpdateFailure: "Nie udało się zaktualizować danych profilu"
};
var cdc$4 = {
  reconsent: reconsent$4,
  cdcProfile: cdcProfile$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  cdc: cdc$4
};
var reconsent$3 = {
  dialog: {
    title: "Conclusão do perfil",
    description: "Ainda precisamos do seu consentimento para o seguinte"
  }
};
var cdcProfile$3 = {
  profileUpdateSuccess: "Detalhes do perfil atualizados com êxito",
  profileUpdateFailure: "Falha ao atualizar detalhes do perfil"
};
var cdc$3 = {
  reconsent: reconsent$3,
  cdcProfile: cdcProfile$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  cdc: cdc$3
};
var reconsent$2 = {
  dialog: {
    title: "Заполнение профиля",
    description: "Нам необходимо ваше согласие на следующее:"
  }
};
var cdcProfile$2 = {
  profileUpdateSuccess: "Сведения профиля обновлены",
  profileUpdateFailure: "Не удалось обновить с"
};
var cdc$2 = {
  reconsent: reconsent$2,
  cdcProfile: cdcProfile$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  cdc: cdc$2
};
var reconsent$1 = {
  dialog: {
    title: "参数文件完成",
    description: "我们仍需要以下项的许可"
  }
};
var cdcProfile$1 = {
  profileUpdateSuccess: "参数文件详细信息已成功更新",
  profileUpdateFailure: "无法更新参数文件详细信息"
};
var cdc$1 = {
  reconsent: reconsent$1,
  cdcProfile: cdcProfile$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  cdc: cdc$1
};
var reconsent = {
  dialog: {
    title: "設定檔完成",
    description: "我們仍需要您的同意，進行下列"
  }
};
var cdcProfile = {
  profileUpdateSuccess: "已成功更新設定檔明細",
  profileUpdateFailure: "無法更新設定檔明細"
};
var cdc = {
  reconsent: reconsent,
  cdcProfile: cdcProfile
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  cdc
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cdcTranslationChunksConfig = {
  cdc: ['reconsent', 'cdcProfile']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: cdcTranslations
 *             +   resources: { en: cdcTranslationsEn }
 *               }
 *             ```
 */
const cdcTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { cdcTranslationChunksConfig, cdcTranslations, cs as cdcTranslationsCs, de as cdcTranslationsDe, en as cdcTranslationsEn, es as cdcTranslationsEs, es_CO as cdcTranslationsEs_CO, fr as cdcTranslationsFr, hi as cdcTranslationsHi, hu as cdcTranslationsHu, id as cdcTranslationsId, it as cdcTranslationsIt, ja as cdcTranslationsJa, ko as cdcTranslationsKo, pl as cdcTranslationsPl, pt as cdcTranslationsPt, ru as cdcTranslationsRu, zh as cdcTranslationsZh, zh_TW as cdcTranslationsZh_TW };
