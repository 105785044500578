var wishlist$w = {
  empty: "V seznamu přání zatím nemáte žádné produkty",
  itemRemoved: "Vybraná položka byla odebrána.",
  caption: "Obsah seznamu přání."
};
var wishlist$x = {
  wishlist: wishlist$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  wishlist: wishlist$x
};
var wishlist$u = {
  empty: "Ihre Wunschliste enthält noch keine Produkte",
  itemRemoved: "Ausgewählte Position wurde entfernt.",
  caption: "Inhalte der Wunschliste."
};
var wishlist$v = {
  wishlist: wishlist$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  wishlist: wishlist$v
};
var wishlist$s = {
  empty: "No products in your wish list yet",
  itemRemoved: "Selected item has been removed.",
  caption: "Wish list contents."
};
var wishlist$t = {
  wishlist: wishlist$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  wishlist: wishlist$t
};
var wishlist$q = {
  empty: "No hay productos en su lista de deseos aún",
  itemRemoved: "Se eliminó el artículo seleccionado.",
  caption: "Contenidos de la lista de deseos."
};
var wishlist$r = {
  wishlist: wishlist$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  wishlist: wishlist$r
};
var wishlist$o = {
  empty: "No hay productos en su lista de deseos aún",
  itemRemoved: "Se eliminó el artículo seleccionado.",
  caption: "Contenidos de la lista de deseos."
};
var wishlist$p = {
  wishlist: wishlist$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  wishlist: wishlist$p
};
var wishlist$m = {
  empty: "Il n'y a aucun produit dans votre liste de souhaits pour le moment.",
  itemRemoved: "L'article sélectionné a été supprimé.",
  caption: "Contenu de la liste de souhaits"
};
var wishlist$n = {
  wishlist: wishlist$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  wishlist: wishlist$n
};
var wishlist$k = {
  empty: "आपकी विश लिस्ट में अभी तक कोई उत्पाद नहीं है",
  itemRemoved: "चयनित आइटम निकाले गए.",
  caption: "विश लिस्ट की सामग्री."
};
var wishlist$l = {
  wishlist: wishlist$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  wishlist: wishlist$l
};
var wishlist$i = {
  empty: "Még nincsenek termékek a kívánságlistáján",
  itemRemoved: "A kiválasztott tétel el lett távolítva.",
  caption: "A kívánságlista tartalma."
};
var wishlist$j = {
  wishlist: wishlist$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  wishlist: wishlist$j
};
var wishlist$g = {
  empty: "Belum tersedia produk dalam daftar keinginan Anda",
  itemRemoved: "Item yang dipilih telah dihapus.",
  caption: "Isi daftar keinginan."
};
var wishlist$h = {
  wishlist: wishlist$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  wishlist: wishlist$h
};
var wishlist$e = {
  empty: "Ancora nessun prodotto nel promemoria",
  itemRemoved: "L'articolo selezionato è stato rimosso.",
  caption: "Contenuto del promemoria."
};
var wishlist$f = {
  wishlist: wishlist$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  wishlist: wishlist$f
};
var wishlist$c = {
  empty: "ほしい物リストにはまだ商品がありません",
  itemRemoved: "選択したアイテムが削除されました。",
  caption: "ほしい物リストの内容です。"
};
var wishlist$d = {
  wishlist: wishlist$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  wishlist: wishlist$d
};
var wishlist$a = {
  empty: "관심 품목 목록에 아직 제품이 없음",
  itemRemoved: "선택한 품목이 제거되었습니다.",
  caption: "관심 품목 목록 내용"
};
var wishlist$b = {
  wishlist: wishlist$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  wishlist: wishlist$b
};
var wishlist$8 = {
  empty: "Brak produktów na liście życzeń",
  itemRemoved: "Wybrana pozycja została usunięta.",
  caption: "Zawartość listy życzeń."
};
var wishlist$9 = {
  wishlist: wishlist$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  wishlist: wishlist$9
};
var wishlist$6 = {
  empty: "Nenhum produto ainda na sua lista de desejos",
  itemRemoved: "O item selecionado foi removido.",
  caption: "Conteúdo da lista de desejos."
};
var wishlist$7 = {
  wishlist: wishlist$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  wishlist: wishlist$7
};
var wishlist$4 = {
  empty: "В вашем списке желаний пока нет продуктов",
  itemRemoved: "Выбранная позиция удалена.",
  caption: "Содержимое списка желаний."
};
var wishlist$5 = {
  wishlist: wishlist$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  wishlist: wishlist$5
};
var wishlist$2 = {
  empty: "您的愿望清单中尚无产品",
  itemRemoved: "所选项目已移除。",
  caption: "愿望清单内容。"
};
var wishlist$3 = {
  wishlist: wishlist$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  wishlist: wishlist$3
};
var wishlist = {
  empty: "您的願望清單中尚無產品",
  itemRemoved: "已移除所選項目。",
  caption: "願望清單內容。"
};
var wishlist$1 = {
  wishlist: wishlist
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  wishlist: wishlist$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const wishListTranslationChunksConfig = {
  wishlist: ['wishlist']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: wishListTranslations
 *             +   resources: { en: wishListTranslationsEn }
 *               }
 *             ```
 */
const wishListTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { wishListTranslationChunksConfig, wishListTranslations, cs as wishListTranslationsCs, de as wishListTranslationsDe, en as wishListTranslationsEn, es as wishListTranslationsEs, es_CO as wishListTranslationsEs_CO, fr as wishListTranslationsFr, hi as wishListTranslationsHi, hu as wishListTranslationsHu, id as wishListTranslationsId, it as wishListTranslationsIt, ja as wishListTranslationsJa, ko as wishListTranslationsKo, pl as wishListTranslationsPl, pt as wishListTranslationsPt, ru as wishListTranslationsRu, zh as wishListTranslationsZh, zh_TW as wishListTranslationsZh_TW };
