<ng-container *ngIf="changedPrices$ | async as changedPrices">
  <div
    class="form-group"
    *ngIf="attribute.values && attribute.values.length !== 0"
  >
    <label
      for="{{ createAttributeIdForConfigurator(attribute) }}"
      class="cx-visually-hidden"
    >
      {{
        'configurator.a11y.listbox'
          | cxTranslate
            : {
                count: attribute.values.length,
              }
      }}
    </label>
    <ng-container *ngIf="!config.features?.productConfiguratorAttributeTypesV2">
      <select
        id="{{ createAttributeIdForConfigurator(attribute) }}"
        class="form-control"
        [ngClass]="{
          'cx-required-error-msg ': (showRequiredErrorMessage$ | async),
        }"
        [formControl]="attributeDropDownForm"
        [cxFocus]="{ key: attribute.name }"
        (change)="onSelect(attributeDropDownForm.value)"
        [attr.aria-describedby]="createAttributeUiKey('label', attribute.name)"
      >
        <option
          *ngFor="let item of attribute.values"
          [selected]="item.selected"
          [label]="getLabel(expMode, item.valueDisplay, item.valueCode, item)"
          [attr.aria-label]="getAriaLabel(item, attribute)"
          [value]="item.valueCode"
        >
          {{ getLabel(expMode, item.valueDisplay, item.valueCode, item) }}
        </option>
      </select>
    </ng-container>

    <ng-container *ngIf="config.features?.productConfiguratorAttributeTypesV2">
      <div class="cx-value-label-pair">
        <select
          id="{{ createAttributeIdForConfigurator(attribute) }}"
          class="form-control"
          [ngClass]="{
            'cx-required-error-msg ': (showRequiredErrorMessage$ | async),
          }"
          [formControl]="attributeDropDownForm"
          [cxFocus]="{ key: attribute.name }"
          (change)="onSelect(attributeDropDownForm.value)"
          [attr.aria-describedby]="
            createAttributeUiKey('label', attribute.name)
          "
        >
          <option
            *ngFor="let item of attribute.values"
            [selected]="item.selected"
            [label]="
              '[' +
              item.valueCode +
              ']' +
              '&nbsp;:&nbsp;&nbsp;' +
              item.valueDisplay
            "
            [attr.aria-label]="getAriaLabel(item, attribute)"
            [value]="item.valueCode"
          >
            {{
              getLabel(
                expMode,
                item.valueDisplay,
                item.valueCode,
                enrichValueWithPrice(item, changedPrices)
              )
            }}
          </option>
        </select>
        <!-- <cx-configurator-show-more
          *ngIf="getSelectedValueDescription()"
          [text]="getSelectedValueDescription()"
          [textSize]="getValueDescriptionLength()"
          [productName]="
            getLabel(
              expMode,
              getSelectedValue()?.valueDisplay,
              getSelectedValue()?.valueCode,
              getSelectedValue()
            )
          "
          [tabIndex]="0"
        ></cx-configurator-show-more> -->
      </div>
    </ng-container>

    <div *ngIf="!withQuantity && getSelectedValue()" class="cx-value-price">
      <cx-configurator-price
        [formula]="
          extractValuePriceFormulaParameters(
            enrichValueWithPrice(getSelectedValue(), changedPrices)
          )
        "
      ></cx-configurator-price>
    </div>
  </div>
  <div *ngIf="withQuantity" class="cx-attribute-level-quantity-price">
    <cx-configurator-attribute-quantity
      (changeQuantity)="onChangeQuantity($event, attributeDropDownForm)"
      [quantityOptions]="extractQuantityParameters(attributeDropDownForm)"
    ></cx-configurator-attribute-quantity>
    <cx-configurator-price
      *ngIf="getSelectedValuePrice()"
      [formula]="extractPriceFormulaParameters()"
    ></cx-configurator-price>
  </div>
</ng-container>

<cx-configurator-attribute-numeric-input-field
  *ngIf="isAdditionalValueNumeric"
  class="cx-configurator-attribute-additional-value"
  [attr.aria-label]="'configurator.a11y.additionalValue' | cxTranslate"
></cx-configurator-attribute-numeric-input-field>

<cx-configurator-attribute-input-field
  *ngIf="isAdditionalValueAlphaNumeric"
  class="cx-configurator-attribute-additional-value"
  [attr.aria-label]="'configurator.a11y.additionalValue' | cxTranslate"
>
</cx-configurator-attribute-input-field>
