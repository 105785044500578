import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ActiveCartFacade, PaymentType } from '@spartacus/cart/base/root';
import {
  AuthService,
  GlobalMessageService,
  TranslationService,
  GlobalMessageType,
  OCC_USER_ID_CURRENT,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CustomerAccountService } from '../../../../core/customer-account/customer-account.service';
import { ProductCatelogService } from '../../../../core/product-catalog/services/product-catelog.service';
import { AddressModelService } from '../../../../shared/components/address-model/address-model.service';
import {
  PaymentTypeEnum,
  GtmEvents,
  ItemListTypeEnum,
} from '../../../../shared/enums/gtm.enum';
import {
  SoldToAddress,
  EndUserAddress,
  DeliveryAddress,
  PayerDeliveryAddress,
} from '../../../../shared/models/address-models';
import { CommerceTypes } from '../../../../shared/models/commerceTypes.model';
import {
  GTMDataLayer,
  EcommerceItem,
  Ecommerce,
} from '../../../../shared/models/googleTagManager.model';
import { GoogleTagManagerService } from '../../../../shared/services/gtm.service';
import {
  CreditCardPaymentInfoModel,
  SnapPayPaymentInfo,
} from '../../../checkout/buy-checkout/buy-checkout.model';
import { BuyCheckoutService } from '../../../checkout/buy-checkout/service/buy-checkout.service';
import { CustomerType } from '../../../../shared/models/customerType.model';

@Component({
  standalone: false,
  selector: 'app-waygate-cart-checkout',
  templateUrl: './waygate-cart-checkout.component.html',
  styleUrls: ['./waygate-cart-checkout.component.scss'],
})
export class WaygateCartCheckoutComponent {
  cart$: Observable<any>;
  stepIndex = 0;
  user$: Observable<unknown>;
  userType = '';
  public isAccountCollapsed = false;
  public isShippingCollapsed = false;
  public isNotificationCollapsed = false;
  public isPayerCollapsed = false;
  public isbilltoaddressCollapsed = false;
  public isComplianceCollapsed = false;
  public isPaymentCollapsed = false;
  userAddressList$: Observable<any>;
  public isEndUserDetailsCollapsed = false;
  @ViewChild('stickyMenu') menuElement: ElementRef;
  loading: boolean = false;
  loadingFlag: boolean = false;
  cardPayLoader: boolean = false;
  sticky: boolean = false;
  elementPosition: any;
  soldToAddress: SoldToAddress;
  endUserAddress: EndUserAddress;
  shippingAddress: DeliveryAddress;
  payerAddress: PayerDeliveryAddress;
  getPayerAddressList: any;
  paymentTerm: string;
  inkoTerm: string;
  largestFilmLeadtime: any;
  largestNonFilmLeadtime: any;
  collectList = [];
  prePayAddList = [];
  complianceModel: any;
  shippingmodel: any;
  payermodel: any;
  notificationModel: any;
  accountModel: any;
  cartId: any;
  cartType: any;
  orderCode;
  newCartSubscription: Subscription;
  currentCartSubscription: Subscription;
  currentFlag: boolean = true;
  invoiceMail: any;
  orderAckMail: any;
  checkoutData: any;
  payerCheckoutData: any;
  filmDate;
  nonFilmDate;
  customerAccount: any;
  payerCustomerAccount: any;
  agreeTerm: boolean = false;
  isEnduserAddress: boolean = false;
  isPaymentVal: boolean = false;
  isShippingAddressVal: boolean = false;
  isPayerAddressVal: boolean = false;
  isComplianceVal: boolean = false;
  isNotificationVal: boolean = false;
  isAddessDisable: boolean = false;
  isPayerDisabled: boolean = false;
  invoiceName: any;
  invoicePhone: any;
  orderAckName: any;
  orderAckPhone: any;
  userAddressList: any;
  showCreditCard: boolean = false;
  hidePurchaseOrder: boolean = false;
  ccPaymentInfo: CreditCardPaymentInfoModel;
  snapPayPaymentInfo = new SnapPayPaymentInfo();
  payInfo: any = {};
  merchatId: string;
  @Input() ccPaymentInfoForSnapPay;
  savedCards: any;
  binLokfailur: string = 'Credit Card is not valid !!';
  savedCardError: string = 'SavedCard is not Valid to use for placing an Order';
  showBinLookupErr: boolean = false;
  showUnexpectedErr: boolean = false;
  showSavedCardError: boolean = false;
  showCardNotAllowedErr: boolean = false;
  userFirstName: string;
  userLastName: string;
  currentCurrancyCode: string;
  currentSalesArea: string;
  breadcrumbs: any[] = [];
  cart: any;
  productLine: string;
  data: boolean = false;
  userId: any;
  userEmail: string = '';
  purchaseDataLayer: GTMDataLayer;
  customerUserType: string;
  isEndUserType: boolean;
  sameAsShipping: boolean;
  constructor(
    private translationService: TranslationService,
    protected authService: AuthService,
    private userAccountFacade: UserAccountFacade,
    private globalMessageService: GlobalMessageService,
    private customerAccService: CustomerAccountService,
    private buyCheckoutService: BuyCheckoutService,
    private activeCartFacade: ActiveCartFacade,
    private addressModelService: AddressModelService,
    private router: Router,
    private productCatService: ProductCatelogService,
    private translate: TranslationService,
    private gtmService: GoogleTagManagerService
  ) {
    sessionStorage.setItem('count', 'first');
    this.ccPaymentInfo = new CreditCardPaymentInfoModel();
  }
  ngOnInit(): void {
    this.customerAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      this.breadcrumbs = [
        {
          url: `/${this.productLine}/cart`,
          name: 'Cart',
        },
        {
          name: 'Checkout',
        },
      ];
    });
    this.customerAccService.disableChangeAccount.next(false);
    this.customerAccService.getCustomerUserType().subscribe((userType) => {
      this.customerUserType = userType;
      this.isEndUserType = this.customerUserType !== CustomerType.Type1;
      if (this.customerUserType === CustomerType.Type1) {
        this.isEnduserAddress = true;
      }
    });
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          this.userType = 'current';
          return this.userAccountFacade.get();
        } else {
          this.userType = 'anonymous';
          return of(undefined);
        }
      })
    );

    this.user$.subscribe(
      (res: any) => {
        if (res) {
          this.userType = 'current';
          this.userEmail = res?.email;
        } else {
          this.userType = 'anonymous';
        }
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          10000
        );
        window.scrollTo(0, 0);
      }
    );
    this.currentCartSubscription = this.activeCartFacade
      .getActiveCartId()
      .subscribe((cartId) => {
        this.cartId = cartId;
        if (
          this.cartId &&
          this.currentFlag &&
          sessionStorage.getItem('count') == 'first'
        )
          this.getDefaultAddress();
        //this.getUserAddressList();
      });
    this.cart$ = this.activeCartFacade.getActive();
    this.cart$.subscribe((res) => {
      this.cart = res;
    });
    this.userFirstName = this.buyCheckoutService.userFirstName;
    this.userLastName = this.buyCheckoutService.userLastName;
    if (sessionStorage.getItem('ccOrderPlaced') === 'true') {
      window.location.reload();
      sessionStorage.removeItem('ccOrderPlaced');
    }
    if (sessionStorage.getItem('binLokkupFailed') === 'true') {
      this.showBinLookupErr = true;
      this.cardPayLoader = false;
      sessionStorage.removeItem('binLokkupFailed');
    } else if (sessionStorage.getItem('savedCardException') === 'true') {
      this.showSavedCardError = true;
      this.cardPayLoader = false;
      sessionStorage.removeItem('savedCardException');
    } else if (sessionStorage.getItem('userClosedHppForm')) {
      this.cardPayLoader = false;
      sessionStorage.removeItem('userClosedHppForm');
    } else if (sessionStorage.getItem('unexpectedError')) {
      this.showUnexpectedErr = true;
      this.cardPayLoader = false;
      sessionStorage.removeItem('unexpectedError');
    } else if (sessionStorage.getItem('cardNotAllowedErr')) {
      this.showCardNotAllowedErr = true;
      this.cardPayLoader = false;
      sessionStorage.removeItem('cardNotAllowedErr');
    }
    window.addEventListener('message', this.handlePostMessage.bind(this));
  }
  printPage() {
    window.print();
  }
  getDefaultAddress() {
    this.buyCheckoutService.bindDefaultAddress(this.cartId).subscribe(
      (res: any) => {
        this.loadingFlag = true;
        this.soldToAddress = res?.soldToAddress;
        this.endUserAddress = res?.defaultEndUserAddress;
        this.shippingAddress = res?.defaultShiptToAddress;
        this.payerAddress = res?.payerAddress;
        this.paymentTerm = res?.paymentTrms?.name
          ? res?.paymentTrms?.name
          : res?.paymentTrms?.code;
        this.inkoTerm =
          this.productLine === 'panametrics'
            ? res?.shipToIncoterm1 + ' - ' + res?.shipToIncotrmName
            : res?.shipToIncotrmName + '-' + res?.shipToIncoterm2;

        this.collectList = res?.collectTypes;
        this.prePayAddList = res?.prepayAddTypes;
        this.cartType = res?.cartData?.cartType;
        this.invoiceName = res?.invoiceContact;
        this.invoicePhone = res?.invoicePhone;
        this.invoiceMail = res?.cartData?.invoiceEmail;
        this.orderAckName = res?.soaContact;
        this.orderAckPhone = res?.soaPhone;
        this.orderAckMail = res?.cartData?.orderConfirmation;
        this.checkoutData = res;
        this.payerCheckoutData = res;
        this.customerAccount = res?.defaultSoldTo?.uid;
        this.payerCustomerAccount = res.payerAddress?.sapCustomerID;
        this.largestFilmLeadtime = res?.cartData?.largestFilmLeadtime;
        this.largestNonFilmLeadtime = res?.cartData?.largestNonFilmLeadtime;
        this.buyCheckoutService.sendCartData({
          totalItems: res?.totalItems,
          totalEstimate: res?.totalPrice?.formattedValue,
          totalValue: res?.totalPrice?.value,
          totalDiscount: res?.cartData?.yourPriceDiscount
            ? res.cartData.yourPriceDiscount
            : '',
          totalSilverClausePrice: res?.cartData?.silverClauseTotal
            ? res.cartData.silverClauseTotal
            : '',
          cartData: res.cartData,
        });
        if (this.cartType) sessionStorage.setItem('cartType', this.cartType);
        this.showCreditCard = res?.showCreditCard ? res?.showCreditCard : false;
        this.hidePurchaseOrder = res?.hidePurchaseOrder
          ? res?.hidePurchaseOrder
          : false;
        this.savedCards = res?.savedCards;
        this.currentCurrancyCode = res.cartData.currencyIso;
        this.currentSalesArea = res.cartData.saleaAreaID.split('_')[1];
        if (this.showCreditCard) {
          this.setHppPaymentModel(res.billToAddress);
          this.merchatId = res.fiservMerchantId;
        }
      },
      (error) => {
        this.loadingFlag = true;
        if (error) {
          this.getTranslatedText('errors.errorMessage');
        }
      }
    );
  }
  setHppPaymentModel(data) {
    this.ccPaymentInfo.address = data?.line1 ?? '';
    this.ccPaymentInfo.companyCode = this.currentSalesArea;
    this.ccPaymentInfo.city = data?.town ?? '';
    this.ccPaymentInfo.state = data?.region?.isocodeShort ?? '';
    this.ccPaymentInfo.zip = data?.postalCode ?? '';
    if (this.ccPaymentInfo?.zip?.includes('-')) {
      this.ccPaymentInfo.zip = this.ccPaymentInfo.zip.split('-')[0];
    }
    this.ccPaymentInfo.country = data?.country?.isocode ?? '';
    this.ccPaymentInfo.email = data?.email ?? '';
    this.ccPaymentInfo.phoneNumber = data?.phone ?? '';
    this.ccPaymentInfo.firstName = this.userFirstName;
    this.ccPaymentInfo.lastName = this.userLastName;
  }
  checkEnduserAddress(value) {
    this.isEnduserAddress = value;
  }
  isComplianceValid(val) {
    this.isComplianceVal = val;
  }

  getComplianceInput(val) {
    this.complianceModel = val;
    if (
      this.notificationModel &&
      this.accountModel &&
      this.complianceModel &&
      this.shippingmodel
    ) {
      //this.createReqDateForHybridCart();
      if (
        this.agreeTerm &&
        this.isEnduserAddress &&
        this.isShippingAddressVal &&
        this.isNotificationVal &&
        this.isComplianceVal
      ) {
        this.loadingFlag = false;
        let param = {
          checkoutDetails: { ...this.shippingmodel, ...this.notificationModel },
          paymentType: this.accountModel,
          placeOrder: this.complianceModel,
        };
        if (
          this.accountModel.paymentType === 'card' &&
          this.snapPayPaymentInfo.isNewCard
        ) {
          this.openSnapPayForm();
          this.loadingFlag = true;
          this.cardPayLoader = true;
        } else if (
          this.accountModel.paymentType === 'card' &&
          this.snapPayPaymentInfo.isNewCard === false
        ) {
          let param = {
            checkoutDetails: {
              ...this.shippingmodel,
              ...this.notificationModel,
            },
            paymentType: this.accountModel,
            placeOrder: this.complianceModel,
            paymentInfo: this.snapPayPaymentInfo,
          };
          this.callPlaceOrder(param);
        } else {
          this.callPlaceOrder(param);
        }
      }
    }
  }
  isChecked(value) {
    this.agreeTerm = value;
  }
  getNoitificationInput(val) {
    this.notificationModel = val;
  }
  checkNotificationVal(value) {
    this.isNotificationVal = value;
  }
  getShippingInput(val) {
    this.shippingmodel = val;
  }
  getPaymentInput(val) {
    this.accountModel = val;
  }
  getPaymentInfo(val) {
    this.snapPayPaymentInfo = val;
  }
  isPaymentValid(val) {
    this.isPaymentVal = val;
  }
  getReqDate(val) {
    this.filmDate = val.filmDt;
    this.nonFilmDate = val.nonFilmDt;
  }
  checkShippingAddressVal(value) {
    this.isShippingAddressVal = value;
  }
  getTranslatedText(key) {
    let message;
    this.translationService.translate(key).subscribe((res) => {
      message = res;
    });
    return message;
  }
  goToStep(step) {
    switch (step) {
      case 0:
        this.stepIndex = step;
        return;
      case 1:
        this.stepIndex = step;
        return;
      case 2:
        // if (!this.checkEquipmentValidation()) {
        this.stepIndex = step;
        // }
        return;
      case 3:
        // if (this.checkServiceOfferingValidation()) {
        this.stepIndex = step;
        // }
        return;
    }
  }
  // separating below fn for US465610 US465611 US465613
  callPlaceOrder(paramVal: any) {
    this.buyCheckoutService
      .placeOrder(this.cartId, paramVal)
      .pipe(take(1))
      .subscribe(
        (code) => {
          this.loadingFlag = true;
          if (code.toString() === this.binLokfailur) {
            if (this.snapPayPaymentInfo.isNewCard === true) {
              sessionStorage.setItem('binLokkupFailed', 'true');
              window.location.reload();
            }
          } else if (code.toString() === this.savedCardError) {
            sessionStorage.setItem('savedCardException', 'true');
            window.location.reload();
          } else if (code) {
            this.orderCode = code;
            if (this.snapPayPaymentInfo.isNewCard === true) {
              sessionStorage.setItem('ccOrderPlaced', 'true');
            }
            this.router.navigate([
              `/${this.productLine}/order-summary`,
              this.orderCode,
            ]);
            this.buyCheckoutService.sendData({
              code: code,
              cartId: this.cartId,
              cardPayInfo: this.snapPayPaymentInfo,
            });
            sessionStorage.setItem('count', 'second');
            this.gtmPurchaseEvent(paramVal, this.orderCode);
            this.gtmAddPaymentInfoEvent(paramVal);

            this.newCartSubscription = this.productCatService
              .createCartWithType(OCC_USER_ID_CURRENT, CommerceTypes.BUY)
              .subscribe((res) => {
                if (res) {
                  this.currentFlag = false;
                  sessionStorage.setItem('count', 'second');
                  // this.router.navigate(['/waygate/order-summary', this.orderCode])
                } else {
                  this.loadingFlag = true;
                }
              });
          }
        },
        (error) => {
          this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
          this.data = true;
          this.loadingFlag = true;
        }
      );
  }
  createReqDateForHybridCart() {
    if (this.cartType == 'HYBRID') {
      if (this.filmDate) {
        this.buyCheckoutService
          .requsetDateForFilm(this.cartId, this.filmDate)
          .subscribe(
            (res) => {},
            (error) => {
              this.getTranslatedText('errors.errorMessage');
            }
          );
      }
      if (this.nonFilmDate) {
        this.buyCheckoutService
          .requsetDateForNonFilm(this.cartId, this.nonFilmDate)
          .subscribe(
            (res) => {},
            (error) => {
              this.getTranslatedText('errors.errorMessage');
            }
          );
      }
    }
  }
  public openSnapPayForm() {
    let hppform = document.getElementById('snappay_hppform');
    if (hppform) {
      hppform.remove();
    }
    let hpp = document.createElement('script');
    hpp.id = 'snappay_hppform';
    hpp.src = environment.snapPayApi.hppFormSrc;
    hpp.setAttribute('data-target', '#snappayhppform_response');
    hpp.setAttribute('data-callback', 'ecommerce_callback');
    hpp.setAttribute('data-accountid', environment.snapPayApi.accountId);
    hpp.setAttribute('data-customerid', this.currentSalesArea);
    hpp.setAttribute('data-currencycode', this.currentCurrancyCode);
    hpp.setAttribute(
      'data-transactionamount',
      environment.snapPayApi.transactionAmount
    );
    hpp.setAttribute('data-merchantid', this.merchatId);
    hpp.setAttribute('data-paymentmode', environment.snapPayApi.paymentMode);
    hpp.setAttribute('data-cvvrequired', environment.snapPayApi.yes);
    hpp.setAttribute('data-enableemailreceipt', environment.snapPayApi.no);
    hpp.setAttribute(
      'data-redirectionurl',
      environment.snapPayApi.redirectionUrl
    );
    hpp.setAttribute(
      'data-transactionType',
      environment.snapPayApi.transactionType
    );
    hpp.setAttribute('data-companycode', this.currentSalesArea);
    hpp.setAttribute('data-userid', environment.snapPayApi.userId);
    hpp.setAttribute('data-openiniframe', environment.snapPayApi.no);
    hpp.setAttribute('data-snappayurl', environment.snapPayApi.snapPayUrl);
    hpp.setAttribute('data-firstname', this.userFirstName);
    hpp.setAttribute('data-lastname', this.userLastName);
    hpp.setAttribute('data-addressline1', this.ccPaymentInfo.address);
    hpp.setAttribute('data-city', this.ccPaymentInfo.city);
    hpp.setAttribute('data-state', this.ccPaymentInfo.state);
    hpp.setAttribute('data-zip', this.ccPaymentInfo.zip);
    hpp.setAttribute('data-country', this.ccPaymentInfo.country);
    hpp.setAttribute(
      'data-signature',
      this.buyCheckoutService.generategetRequestIDHmac(
        this.ccPaymentInfo,
        this.currentSalesArea,
        this.currentCurrancyCode
      )
    );
    hpp.type = 'text/javascript';
    hpp.async = true;
    hpp.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(hpp);
  }
  // for capturing snap pay form data
  handlePostMessage(event: MessageEvent) {
    if (event.data.type === 'resval') {
      let snapResData = event.data.data;
      if (
        this.currentSalesArea === '1720' &&
        snapResData.paymentmethodtype === 'AMEX'
      ) {
        this.cardPayLoader = false;
        sessionStorage.setItem('cardNotAllowedErr', 'true');
        window.location.reload();
      } else if (snapResData.transactionstatus === 'Y') {
        this.snapPayPaymentInfo.isNewCard = this.snapPayPaymentInfo.isNewCard;
        this.snapPayPaymentInfo.ccType = snapResData.paymentmethodtype;
        this.snapPayPaymentInfo.ccNumber = snapResData.paymentmethodlast4;
        this.snapPayPaymentInfo.ccName =
          snapResData.paymentmethodfirstname +
          ' ' +
          snapResData.paymentmethodlastname;
        this.snapPayPaymentInfo.ccValidTru =
          snapResData.paymentmethodexpirationyear +
          '' +
          snapResData.paymentmethodexpirationmonth.toString().padStart(2, '0');
        this.snapPayPaymentInfo.merchantid = snapResData.merchantid;
        this.snapPayPaymentInfo.token = snapResData.paymentmethodtoken;
        let param = {
          checkoutDetails: { ...this.shippingmodel, ...this.notificationModel },
          paymentType: this.accountModel,
          placeOrder: this.complianceModel,
          paymentInfo: this.snapPayPaymentInfo,
        };
        if (
          this.accountModel !== undefined &&
          this.snapPayPaymentInfo.isNewCard === true
        ) {
          this.callPlaceOrder(param);
        } else {
          this.getTranslatedText('errors.errorMessage');
        }
      } else if (
        snapResData.transactionstatus === 'N' &&
        snapResData.returnmessage === 'Transaction Failed. '
      ) {
        this.cardPayLoader = false;
        sessionStorage.setItem('unexpectedError', 'true');
        window.location.reload();
      } else if (snapResData.transactionstatus === 'N') {
        this.cardPayLoader = false;
        sessionStorage.setItem('userClosedHppForm', 'true');
        window.location.reload();
      } else {
        sessionStorage.setItem('unexpectedError', 'true');
        window.location.reload();
      }
    }
  }
  ngOnDestroy(): void {
    this.newCartSubscription?.unsubscribe();
    this.currentCartSubscription?.unsubscribe();
    window.removeEventListener('message', this.handlePostMessage);
  }

  gtmAddPaymentInfoEvent(paramVal) {
    let producitem: EcommerceItem[] = [];
    if (this.cart) {
      this.cart?.entries.forEach((entry, index) => {
        producitem.push(this.populateProduct(entry, index));
      });
      let orderType: PaymentTypeEnum;
      if (paramVal?.paymentType?.paymentType == 'ACCOUNT') {
        orderType = PaymentTypeEnum.PO;
      } else if (paramVal?.paymentType?.paymentType == 'card') {
        orderType = PaymentTypeEnum.CreditCard;
      }

      let addPaymentInfoEcommerce: Ecommerce = {
        currency: this.cart.currencyIso,
        value: this.cart.totalPrice.value,
        payment_type: orderType,
        items: producitem,
      };

      let addPaymentInfoDataLayer: GTMDataLayer = {
        store: this.gtmService.getItemBrand(),
        ecommerce: addPaymentInfoEcommerce,
        event: GtmEvents.AddPaymentInfo,
      };
      this.gtmService.sendEvent(addPaymentInfoDataLayer);
    }
  }

  gtmPurchaseEvent(paramVal, orderCode) {
    let producitem: EcommerceItem[] = [];
    if (this.cart) {
      this.cart?.entries.forEach((entry, index) => {
        producitem.push(this.populateProduct(entry, index));
      });
      let orderType: PaymentTypeEnum;
      if (paramVal.paymentType.paymentType == 'ACCOUNT') {
        orderType = PaymentTypeEnum.PO;
      } else if (paramVal.paymentType.paymentType == 'card') {
        orderType = PaymentTypeEnum.CreditCard;
      }
      let purchaseEcommerceEcommerce: Ecommerce = {
        transaction_id: orderCode,
        value: this.cart.totalPrice.value,
        tax: this.cart.totalTax.value,
        shipping: this.cart.shipmentCost.value,
        currency: this.cart.currencyIso,
        payment_type: orderType,
        items: producitem,
      };
      let purchaseDataLayer = {
        store: this.gtmService.getItemBrand(),
        ecommerce: purchaseEcommerceEcommerce,
        event: GtmEvents.Purchase,
      };
      this.gtmService.sendEvent(purchaseDataLayer);
    }
  }

  populateProduct(entry, index): EcommerceItem {
    let product: EcommerceItem;
    product = {
      item_id: entry?.product?.code,
      item_name: entry?.product?.name,
      discount: entry?.discountPercentage ? +entry?.discountPercentage : '',
      index: index,
      item_brand: this.gtmService.getItemBrand(),
      item_list_id: ItemListTypeEnum.Checkoutpage,
      item_list_name: ItemListTypeEnum.Checkoutpage,
      price: entry.discountPrice,
      quantity: entry?.quantity || 1,
      item_category: '',
      item_category2: '',
      item_category3: '',
      item_category4: '',
      item_category5: '',
      item_variant: '',
    };
    return product;
  }
  onShippingAddressUpdated(updatedAddress) {
    this.shippingAddress = updatedAddress;
    if (this.sameAsShipping) {
      this.endUserAddress = { ...this.shippingAddress };
    }
  }
  onAddressUpdated(address: any) {
    this.endUserAddress = address;
  }
}
export function ecommerce_callback(err, data) {
  let snapPayResponse = JSON.parse(data.response);
  let resObj = { type: 'resval', data: snapPayResponse };
  this.window.postMessage(resObj, '*');
}
declare global {
  interface Window {
    ecommerce_callback: any;
  }
}
window.ecommerce_callback = ecommerce_callback;
