var storeFinder$w = {
  openUntil: "Otevřeno do",
  closed: "Zavřeno",
  back: "Zpět",
  call: "Volat",
  getDirections: "Získat popis cesty",
  ariaLabelGetDirections: "Získat popis cesty – tento odkaz otevře nové okno",
  ariaLabelCountriesCount: "Počet zemí s nalezenými prodejnami: {{ count }}",
  listView: "Zobrazení seznamu",
  mapView: "Mapové zobrazení",
  noStoresFound: "Nebyly nalezeny žádné prodejny.",
  storeHours: "Otevírací doba prodejny",
  storeFeatures: "Vlastnosti prodejny",
  storeFinder: "Vyhledávač prodejen",
  storesFoundTitle: "Nalezené prodejny",
  storesFound: "{{ count }} nalezená prodejna",
  storesFound_other: "Počet nalezených prodejen: {{ count }}",
  fromStoresFound: "z {{ count }} nalezené prodejny",
  fromStoresFound_other: "z {{ count }} nalezených prodejen",
  findStore: "Vyhledat prodejnu",
  findStoreBy: "Find store by",
  useMyLocation: "Použít mou polohu",
  viewAllStores: "Zobrazit všechny prodejny",
  contactUs: "Kontaktujte nás",
  searchBox: "PSČ, město nebo adresa",
  backToList: "Zpět na seznam",
  noStoresMessage: "V databázi nebyly nalezeny žádné prodejny…",
  geolocationNotEnabled: "Nepodařilo se najít žádné prodejny v okolí. Nebylo uděleno svolení se sdílením polohy.",
  searchNearestStores: "Vyhledat nejbližší prodejny.",
  searchBoxLabel: "Zadejte PSČ, město nebo adresu"
};
var storeFinder$x = {
  storeFinder: storeFinder$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  storeFinder: storeFinder$x
};
var storeFinder$u = {
  openUntil: "Offen bis",
  closed: "Geschlossen",
  back: "Zurück",
  call: "Anrufen",
  getDirections: "Wegbeschreibung abrufen",
  ariaLabelGetDirections: "Wegbeschreibung abrufen, dieser Link öffnet sich in einem neuen Fenster",
  ariaLabelCountriesCount: "Filialen in {{ count }} Ländern gefunden",
  listView: "Listenansicht",
  mapView: "Kartenansicht",
  noStoresFound: "Keine Filialen gefunden",
  storeHours: "Öffnungszeiten",
  storeFeatures: "Filialmerkmale",
  storeFinder: "Filialsuche",
  storesFoundTitle: "Filialen gefunden",
  storesFound: "{{ count }} Filiale gefunden",
  storesFound_other: "{{ count }} Filialen gefunden",
  fromStoresFound: "von {{ count }} Filiale gefunden",
  fromStoresFound_other: "von {{ count }} Filialen gefunden",
  findStore: "Filiale suchen",
  findStoreBy: "Find store by",
  useMyLocation: "Meinen Standort verwenden",
  viewAllStores: "Alle Filialen anzeigen",
  contactUs: "Kontakt",
  searchBox: "Postleitzahl oder Stadt oder Adresse eingeben",
  backToList: "Zurück zur Liste",
  noStoresMessage: "Keine Filialen in Datenbank gefunden...",
  geolocationNotEnabled: "Filialen in der Nähe konnten nicht gesucht werden. Geolokationseinwilligung wurde nicht aktiviert",
  searchNearestStores: "Filialen in der Nähe suchen",
  searchBoxLabel: "Postleitzahl oder Stadt oder Adresse eingeben"
};
var storeFinder$v = {
  storeFinder: storeFinder$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  storeFinder: storeFinder$v
};
var storeFinder$s = {
  openUntil: "Open until",
  closed: "Closed",
  back: "Back",
  call: "Call",
  getDirections: "Get Directions",
  ariaLabelGetDirections: "Get Directions, this link opens in a new window",
  ariaLabelCountriesCount: "Stores in {{ count }} countries found",
  listView: "List View",
  mapView: "Map View",
  noStoresFound: "No Stores Found.",
  storeHours: "Store hours",
  storeFeatures: "Store features",
  storeFinder: "Store Finder",
  storesFoundTitle: "Stores Found",
  storesFound: "{{ count }} store found",
  storesFound_other: "{{ count }} stores found",
  fromStoresFound: "from {{ count }} store found",
  fromStoresFound_other: "from {{ count }} stores found",
  findStore: "Find store",
  findStoreBy: "Find store by",
  useMyLocation: "Use my location",
  viewAllStores: "View all stores",
  contactUs: "Contact us",
  searchBox: "Postal code or town or address",
  backToList: "Back to list",
  noStoresMessage: "No stores found in database...",
  geolocationNotEnabled: "Cannot locate nearby stores. Geolocation consent has not been enabled",
  searchNearestStores: "Search nearest stores",
  searchBoxLabel: "Enter postal code, town or address"
};
var storeFinder$t = {
  storeFinder: storeFinder$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  storeFinder: storeFinder$t
};
var storeFinder$q = {
  openUntil: "Abierta hasta",
  closed: "Cerrada",
  back: "Atrás",
  call: "Llamar",
  getDirections: "Obtener direcciones",
  ariaLabelGetDirections: "Obtener direcciones, este enlace se abre en una ventana nueva",
  ariaLabelCountriesCount: "Existen tiendas en {{ count }} países",
  listView: "Vista de lista",
  mapView: "Vista de mapa",
  noStoresFound: "No existen tiendas.",
  storeHours: "Horarios de la tienda",
  storeFeatures: "Funciones de la tienda",
  storeFinder: "Buscador de tiendas",
  storesFoundTitle: "Existen tiendas",
  storesFound: "{{ count }} tienda encontrada",
  storesFound_other: "{{ count }} tiendas encontradas",
  fromStoresFound: "de {{ count }} tienda encontrada",
  fromStoresFound_other: "de {{ count }} tiendas encontradas",
  findStore: "Buscar tienda",
  findStoreBy: "Find store by",
  useMyLocation: "Utilizar mi ubicación",
  viewAllStores: "Ver todas las tiendas",
  contactUs: "Contactarnos",
  searchBox: "Código postal, ciudad o dirección",
  backToList: "Volver a la lista",
  noStoresMessage: "No existen tiendas en la base de datos...",
  geolocationNotEnabled: "No se pueden ubicar tiendas cercanas; no se ha otorgado consentimiento para la geolocalización",
  searchNearestStores: "Buscar tiendas más cercanas",
  searchBoxLabel: "Introducir código postal, ciudad o dirección"
};
var storeFinder$r = {
  storeFinder: storeFinder$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  storeFinder: storeFinder$r
};
var storeFinder$o = {
  openUntil: "Abierta hasta",
  closed: "Cerrada",
  back: "Atrás",
  call: "Llamar",
  getDirections: "Obtener direcciones",
  ariaLabelGetDirections: "Obtener direcciones, este enlace se abre en una ventana nueva",
  ariaLabelCountriesCount: "Existen tiendas en {{ count }} países",
  listView: "Vista de lista",
  mapView: "Vista de mapa",
  noStoresFound: "No existen tiendas.",
  storeHours: "Horarios de la tienda",
  storeFeatures: "Funciones de la tienda",
  storeFinder: "Buscador de tiendas",
  storesFoundTitle: "Existen tiendas",
  storesFound: "{{ count }} tienda encontrada",
  storesFound_other: "{{ count }} tiendas encontradas",
  fromStoresFound: "de {{ count }} tienda encontrada",
  fromStoresFound_other: "de {{ count }} tiendas encontradas",
  findStore: "Buscar tienda",
  findStoreBy: "Find store by",
  useMyLocation: "Utilizar mi ubicación",
  viewAllStores: "Ver todas las tiendas",
  contactUs: "Contactarnos",
  searchBox: "Código postal, ciudad o dirección",
  backToList: "Volver a la lista",
  noStoresMessage: "No existen tiendas en la base de datos...",
  geolocationNotEnabled: "No se pueden ubicar tiendas cercanas; no se ha otorgado consentimiento para la geolocalización",
  searchNearestStores: "Buscar tiendas más cercanas",
  searchBoxLabel: "Introducir código postal, ciudad o dirección"
};
var storeFinder$p = {
  storeFinder: storeFinder$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  storeFinder: storeFinder$p
};
var storeFinder$m = {
  openUntil: "Ouvert jusqu'à",
  closed: "Fermé",
  back: "Retour",
  call: "Appeler",
  getDirections: "Obtenir des itinéraires",
  ariaLabelGetDirections: "Obtenir des itinéraires, ce lien s'ouvre dans une nouvelle fenêtre",
  ariaLabelCountriesCount: "Points de vente trouvés dans {{ count }} pays",
  listView: "Vue de liste",
  mapView: "Vue de carte",
  noStoresFound: "Aucun point de vente trouvé",
  storeHours: "Horaires du point de vente",
  storeFeatures: "Caractéristiques du point de vente",
  storeFinder: "Recherche de points de vente",
  storesFoundTitle: "Points de vente trouvés",
  storesFound: "{{ count }} point de vente trouvé",
  storesFound_other: "{{ count }} points de vente trouvés",
  fromStoresFound: "de {{ count }} point de vente trouvé",
  fromStoresFound_other: "de {{ count }} points de vente trouvés",
  findStore: "Rechercher un point de vente",
  findStoreBy: "Find store by",
  useMyLocation: "Utiliser ma localisation",
  viewAllStores: "Afficher tous les points de vente",
  contactUs: "Nous contacter",
  searchBox: "Code postal, localité ou adresse",
  backToList: "Retour à la liste",
  noStoresMessage: "Aucun point de vente trouvé dans la base de données...",
  geolocationNotEnabled: "Impossible de localiser des points de vente à proximité. Le consentement à la géolocalisation n'a pas été activé.",
  searchNearestStores: "Rechercher les points de vente les plus proches",
  searchBoxLabel: "Saisir le code postal, la localité ou l'adresse"
};
var storeFinder$n = {
  storeFinder: storeFinder$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  storeFinder: storeFinder$n
};
var storeFinder$k = {
  openUntil: "इस समय तक खुला है",
  closed: "बंद है",
  back: "वापस जाएं",
  call: "कॉल करें",
  getDirections: "मार्गदर्शन प्राप्त करें",
  ariaLabelGetDirections: "निर्देश प्राप्त करें, यह लिंक नई विंडो में खुलती है",
  ariaLabelCountriesCount: "{{ count }} देशों में स्टोर मिले",
  listView: "सूची दृश्य",
  mapView: "मैप दृश्य",
  noStoresFound: "कोई स्टोर नहीं मिले.",
  storeHours: "स्टोर का समय",
  storeFeatures: "स्टोर की विशेषताएं",
  storeFinder: "स्टोर खोजकर्ता",
  storesFoundTitle: "स्टोर मिले",
  storesFound: "{{ count }} स्टोर मिले",
  storesFound_other: "{{ count }} स्टोर मिले",
  fromStoresFound: "{{ count }} से स्टोर मिला",
  fromStoresFound_other: "{{ count }} से स्टोर मिले",
  findStore: "स्टोर खोजें",
  findStoreBy: "Find store by",
  useMyLocation: "मेरे स्थान का उपयोग करें",
  viewAllStores: "सभी स्टोर देखें",
  contactUs: "हमसे संपर्क करें",
  searchBox: "पोस्टल कोड या शहर या पता",
  backToList: "सूची में वापस जाएं",
  noStoresMessage: "डेटाबेस में कोई स्टोर नहीं मिला...",
  geolocationNotEnabled: "नज़दीकी स्टोर का पता नहीं चल सकता. जियोलोकेशन सहमति सक्षम नहीं है",
  searchNearestStores: "सबसे नज़दीकी स्टोर खोजें",
  searchBoxLabel: "पोस्टल कोड या शहर या पता दर्ज करें"
};
var storeFinder$l = {
  storeFinder: storeFinder$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  storeFinder: storeFinder$l
};
var storeFinder$i = {
  openUntil: "Nyitva eddig:",
  closed: "Zárva",
  back: "Vissza",
  call: "Hívás",
  getDirections: "Útbaigazítás kérése",
  ariaLabelGetDirections: "Útvonal tervezése; ez a hivatkozás új ablakban nyílik meg",
  ariaLabelCountriesCount: "Üzletek találhatók {{ count }} országban",
  listView: "Listanézet",
  mapView: "Térképnézet",
  noStoresFound: "Nem találhatók üzletek.",
  storeHours: "Üzlet nyitvatartási ideje",
  storeFeatures: "Üzlet szolgáltatásai",
  storeFinder: "Üzletkereső",
  storesFoundTitle: "Üzletek találhatók",
  storesFound: "{{ count }} üzlet található",
  storesFound_other: "{{ count }} üzlet található",
  fromStoresFound: "a talált {{ count }} üzletből",
  fromStoresFound_other: "a talált {{ count }} üzletből",
  findStore: "Üzlet keresése",
  findStoreBy: "Find store by",
  useMyLocation: "Saját hely használata",
  viewAllStores: "Összes üzlet megtekintése",
  contactUs: "Kapcsolatfelvétel",
  searchBox: "Irányítószám, település vagy cím",
  backToList: "Vissza a listához",
  noStoresMessage: "Nem találhatók üzletek az adatbázisban...",
  geolocationNotEnabled: "Nem sikerült megjeleníteni a közeli üzleteket. Nem engedélyezték a geolokáció használatát.",
  searchNearestStores: "Legközelebbi üzletek keresése",
  searchBoxLabel: "Adja meg az irányítószámot, települést vagy címet"
};
var storeFinder$j = {
  storeFinder: storeFinder$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  storeFinder: storeFinder$j
};
var storeFinder$g = {
  openUntil: "Buka hingga",
  closed: "Tutup",
  back: "Kembali",
  call: "Hubungi",
  getDirections: "Dapatkan Petunjuk Arah",
  ariaLabelGetDirections: "Dapatkan Petunjuk Arah, tautan ini akan terbuka di jendela baru",
  ariaLabelCountriesCount: "Toko ditemukan di {{ count }} negara",
  listView: "Tampilan Daftar",
  mapView: "Tampilan Peta",
  noStoresFound: "Toko Tidak Ditemukan.",
  storeHours: "Jam buka toko",
  storeFeatures: "Fitur-fitur toko",
  storeFinder: "Pencari Toko",
  storesFoundTitle: "Toko Ditemukan",
  storesFound: "{{ count }} toko ditemukan",
  storesFound_other: "{{ count }} toko ditemukan",
  fromStoresFound: "dari {{ count }} toko ditemukan",
  fromStoresFound_other: "dari {{ count }} toko ditemukan",
  findStore: "Cari toko",
  findStoreBy: "Find store by",
  useMyLocation: "Gunakan lokasi saya",
  viewAllStores: "Lihat semua toko",
  contactUs: "Hubungi kami",
  searchBox: "Kode pos, kota, atau alamat",
  backToList: "Kembali ke daftar",
  noStoresMessage: "Tidak ada toko di basis data...",
  geolocationNotEnabled: "Tidak dapat menemukan toko terdekat. Izin geolokasi belum diaktifkan",
  searchNearestStores: "Cari toko terdekat",
  searchBoxLabel: "Masukkan kode pos, kota, atau alamat"
};
var storeFinder$h = {
  storeFinder: storeFinder$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  storeFinder: storeFinder$h
};
var storeFinder$e = {
  openUntil: "Aperto fino a",
  closed: "Chiuso",
  back: "Indietro",
  call: "Chiama",
  getDirections: "Ottieni indicazioni stradali",
  ariaLabelGetDirections: "Ottieni indicazioni stradali; questo collegamento si apre in una nuova finestra",
  ariaLabelCountriesCount: "Trovati punti vendita in {{ count }} paesi",
  listView: "Vista elenco",
  mapView: "Vista mappa",
  noStoresFound: "Nessun punto vendita trovato.",
  storeHours: "Orari del punto vendita",
  storeFeatures: "Caratteristiche del punto vendita",
  storeFinder: "Strumento di ricerca di punti vendita",
  storesFoundTitle: "Punti vendita trovati",
  storesFound: "{{ count }} punto vendita trovato",
  storesFound_other: "{{ count }} punti vendita trovati",
  fromStoresFound: "da {{ count }} punto vendita trovato",
  fromStoresFound_other: "da {{ count }} punti vendita trovati",
  findStore: "Trova punto vendita",
  findStoreBy: "Find store by",
  useMyLocation: "Utilizza la mia posizione",
  viewAllStores: "Visualizza tutti i punti vendita",
  contactUs: "Contatti",
  searchBox: "CAP o città o indirizzo",
  backToList: "Torna alla lista",
  noStoresMessage: "Nessun punto vendita trovato nel database...",
  geolocationNotEnabled: "Impossibile individuare punti vendita vicini. Non è stato attivato il consenso alla geolocalizzazione",
  searchNearestStores: "Cerca i punti vendita più vicini",
  searchBoxLabel: "Inserisci CAP, città o indirizzo"
};
var storeFinder$f = {
  storeFinder: storeFinder$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  storeFinder: storeFinder$f
};
var storeFinder$c = {
  openUntil: "閉店時間",
  closed: "定休日",
  back: "戻る",
  call: "電話",
  getDirections: "ルート案内取得",
  ariaLabelGetDirections: "ルート案内取得 (このリンクをクリックすると新しいウィンドウが開きます)",
  ariaLabelCountriesCount: "店舗が {{ count }} カ国で見つかりました",
  listView: "リストビュー",
  mapView: "地図ビュー",
  noStoresFound: "店舗が見つかりませんでした",
  storeHours: "店舗の営業時間",
  storeFeatures: "店舗の機能",
  storeFinder: "店舗検索",
  storesFoundTitle: "店舗が見つかりました",
  storesFound: "{{ count }} 件の店舗が見つかりました",
  storesFound_other: "{{ count }} 件の店舗が見つかりました",
  fromStoresFound: "{{ count }} 件の店舗が見つかりました",
  fromStoresFound_other: "{{ count }} 件の店舗が見つかりました",
  findStore: "店舗検索",
  findStoreBy: "Find store by",
  useMyLocation: "自分の位置を使用",
  viewAllStores: "すべての店舗を表示",
  contactUs: "連絡先",
  searchBox: "郵便番号、市区町村名、または住所を入力します",
  backToList: "リストに戻る",
  noStoresMessage: "データベースに店舗がありません...",
  geolocationNotEnabled: "近くにある店舗を見つけられません。位置情報の同意が有効になっていません",
  searchNearestStores: "近くにある店舗を検索",
  searchBoxLabel: "郵便番号、市区町村名、または住所を入力します"
};
var storeFinder$d = {
  storeFinder: storeFinder$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  storeFinder: storeFinder$d
};
var storeFinder$a = {
  openUntil: "영업 시간",
  closed: "영업 종료",
  back: "뒤로",
  call: "전화",
  getDirections: "오시는 길",
  ariaLabelGetDirections: "오시는 길, 이 링크를 클릭하면 새 창이 열립니다.",
  ariaLabelCountriesCount: "{{ count }}개 국가에서 매장을 찾음",
  listView: "목록 보기",
  mapView: "지도 보기",
  noStoresFound: "매장 없음",
  storeHours: "매장 시간",
  storeFeatures: "매장 특징",
  storeFinder: "매장 찾기",
  storesFoundTitle: "매장 찾음",
  storesFound: "{{ count }}개 매장을 찾음",
  storesFound_other: "{{ count }}개 매장을 찾음",
  fromStoresFound: "{{ count }}개 매장에서 찾음",
  fromStoresFound_other: "{{ count }}개 매장에서 찾음",
  findStore: "매장 찾기",
  findStoreBy: "Find store by",
  useMyLocation: "내 위치 사용",
  viewAllStores: "모든 매장 보기",
  contactUs: "사용자 문의",
  searchBox: "우편 번호나 시/구/군, 주소 입력",
  backToList: "목록으로 돌아가기",
  noStoresMessage: "데이터베이스에 매장이 없음...",
  geolocationNotEnabled: "가까운 매장을 알 수 없습니다. 지리적 동의가 활성화되지 않았습니다.",
  searchNearestStores: "가까운 매장 검색",
  searchBoxLabel: "우편 번호나 시/구/군, 주소 입력"
};
var storeFinder$b = {
  storeFinder: storeFinder$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  storeFinder: storeFinder$b
};
var storeFinder$8 = {
  openUntil: "Otwarte do",
  closed: "Zamknięte",
  back: "Wstecz",
  call: "Zadzwoń",
  getDirections: "Jak dojechać",
  ariaLabelGetDirections: "Jak dojechać. Ten link otwiera się w nowym oknie.",
  ariaLabelCountriesCount: "Znaleziono sklepy w {{ count }} krajach",
  listView: "Widok listy",
  mapView: "Widok mapy",
  noStoresFound: "Nie znaleziono sklepów.",
  storeHours: "Godziny otwarcia sklepu",
  storeFeatures: "Funkcje sklepu",
  storeFinder: "Wyszukiwarka sklepów",
  storesFoundTitle: "Znalezione sklepy",
  storesFound: "{{ count }} znaleziony sklep",
  storesFound_other: "{{ count }} znalezione sklepy",
  fromStoresFound: "z {{ count }} znalezionego sklepu",
  fromStoresFound_other: "z {{ count }} znalezionych sklepów",
  findStore: "Znajdź sklep",
  findStoreBy: "Find store by",
  useMyLocation: "Użyj mojej lokalizacji",
  viewAllStores: "Pokaż wszystkie sklepy",
  contactUs: "Skontaktuj się z nami",
  searchBox: "Kod pocztowy lub miasto lub adres",
  backToList: "Powrót do listy",
  noStoresMessage: "Nie znaleziono sklepów w bazie danych...",
  geolocationNotEnabled: "Nie można znaleźć sklepów w pobliżu. Zgoda na geolokalizację nie została włączona",
  searchNearestStores: "Szukaj najbliższych sklepów",
  searchBoxLabel: "Wpisz kod pocztowy, miasto lub adres"
};
var storeFinder$9 = {
  storeFinder: storeFinder$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  storeFinder: storeFinder$9
};
var storeFinder$6 = {
  openUntil: "Aberto até",
  closed: "Fechado",
  back: "Voltar",
  call: "Chamar",
  getDirections: "Obter indicações",
  ariaLabelGetDirections: "Obter indicações, este link se abre em uma nova janela",
  ariaLabelCountriesCount: "Encontradas lojas em {{ count }} países",
  listView: "Visão de lista",
  mapView: "Visão do mapa",
  noStoresFound: "Nenhuma loja encontrada.",
  storeHours: "Horas da loja",
  storeFeatures: "Recursos da loja",
  storeFinder: "Localizador de lojas",
  storesFoundTitle: "Lojas encontradas",
  storesFound: "{{ count }} loja encontrada",
  storesFound_other: "{{ count }} lojas encontradas",
  fromStoresFound: "de {{ count }} loja encontrada",
  fromStoresFound_other: "de {{ count }} lojas encontradas",
  findStore: "Encontrar loja",
  findStoreBy: "Find store by",
  useMyLocation: "Utilizar a minha localização",
  viewAllStores: "Visualizar todas as lojas",
  contactUs: "Entre em contato conosco",
  searchBox: "Código postal ou cidade ou endereço",
  backToList: "Voltar à lista",
  noStoresMessage: "Nenhuma loja encontrada no banco de dados...",
  geolocationNotEnabled: "Impossível localizar lojas próximas. O consentimento de geolocalização não foi ativado",
  searchNearestStores: "Procurar lojas próximas",
  searchBoxLabel: "Inserir código postal, cidade ou endereço"
};
var storeFinder$7 = {
  storeFinder: storeFinder$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  storeFinder: storeFinder$7
};
var storeFinder$4 = {
  openUntil: "Открыто до",
  closed: "Закрыто",
  back: "Назад",
  call: "Позвонить",
  getDirections: "Получить маршрут",
  ariaLabelGetDirections: "Получить маршрут, эта ссылка откроется в новом окне",
  ariaLabelCountriesCount: "Найдены магазины в {{ count }} странах",
  listView: "Список",
  mapView: "Карта",
  noStoresFound: "Магазины не найдены.",
  storeHours: "Часы работы магазина",
  storeFeatures: "Характеристики магазина",
  storeFinder: "Средство поиска магазинов",
  storesFoundTitle: "Магазины найдены",
  storesFound: "Найден {{ count }} магазин",
  storesFound_other: "Найдено магазинов: {{ count }}",
  fromStoresFound: "из {{ count }} магазина найдено",
  fromStoresFound_other: "из {{ count }} магазинов найдено",
  findStore: "Найти магазин",
  findStoreBy: "Find store by",
  useMyLocation: "Использовать мое местоположение",
  viewAllStores: "Смотреть все магазины",
  contactUs: "Свяжитесь с нами",
  searchBox: "Почтовый индекс, город или адрес",
  backToList: "Назад к списку",
  noStoresMessage: "Магазины не найдены в базе данных...",
  geolocationNotEnabled: "Невозможно найти ближайшие магазины. Не активировано согласие на геолокацию.",
  searchNearestStores: "Поиск ближайших магазинов",
  searchBoxLabel: "Введите почтовый индекс, город или адрес"
};
var storeFinder$5 = {
  storeFinder: storeFinder$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  storeFinder: storeFinder$5
};
var storeFinder$2 = {
  openUntil: "打开持续时间",
  closed: "已关闭",
  back: "返回",
  call: "致电",
  getDirections: "获取方位",
  ariaLabelGetDirections: "获取方位，此链接将在新窗口中打开",
  ariaLabelCountriesCount: "找到 {{ count }} 个国家/地区的店铺",
  listView: "列表视图",
  mapView: "地图视图",
  noStoresFound: "找不到店铺。",
  storeHours: "店铺营业时间",
  storeFeatures: "店铺特征",
  storeFinder: "店铺查找器",
  storesFoundTitle: "找到店铺",
  storesFound: "找到 {{ count }} 个店铺",
  storesFound_other: "找到 {{ count }} 个店铺",
  fromStoresFound: "（共找到 {{ count }} 个店铺）",
  fromStoresFound_other: "（共找到 {{ count }} 个店铺）",
  findStore: "查找店铺",
  findStoreBy: "Find store by",
  useMyLocation: "使用我的位置",
  viewAllStores: "查看所有店铺",
  contactUs: "联系我们",
  searchBox: "邮政编码、城镇或地址",
  backToList: "返回列表",
  noStoresMessage: "未在数据库中找到店铺...",
  geolocationNotEnabled: "无法找到附近店铺。地理定位许可尚未启用",
  searchNearestStores: "搜索最近的店铺",
  searchBoxLabel: "输入邮政编码、城镇或地址"
};
var storeFinder$3 = {
  storeFinder: storeFinder$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  storeFinder: storeFinder$3
};
var storeFinder = {
  openUntil: "營業至",
  closed: "已關閉",
  back: "返回",
  call: "呼叫",
  getDirections: "規劃路線",
  ariaLabelGetDirections: "規劃路線，此連結會在新視窗中開啟",
  ariaLabelCountriesCount: "已在 {{ count }} 個國家/地區中找到商店",
  listView: "清單檢視",
  mapView: "地圖檢視",
  noStoresFound: "找不到商店。",
  storeHours: "商店營業時間",
  storeFeatures: "商店特性",
  storeFinder: "商店搜尋器",
  storesFoundTitle: "已找到商店",
  storesFound: "已找到 {{ count }} 個商店",
  storesFound_other: "已找到 {{ count }} 個商店",
  fromStoresFound: "已從 {{ count }} 個商店找到",
  fromStoresFound_other: "已從 {{ count }} 個商店中找到",
  findStore: "搜尋商店",
  findStoreBy: "Find store by",
  useMyLocation: "使用我的位置",
  viewAllStores: "檢視所有商店",
  contactUs: "聯絡我們",
  searchBox: "郵遞區號、城鎮或地址",
  backToList: "返回清單",
  noStoresMessage: "資料庫中找不到商店...",
  geolocationNotEnabled: "找不到附近商店，地理位置同意尚未啟用",
  searchNearestStores: "搜尋附近商店",
  searchBoxLabel: "輸入郵遞區號、城鎮或地址"
};
var storeFinder$1 = {
  storeFinder: storeFinder
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  storeFinder: storeFinder$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const storeFinderTranslationChunksConfig = {
  storeFinder: ['storeFinder']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: storeFinderTranslations
 *             +   resources: { en: storeFinderTranslationsEn }
 *               }
 *             ```
 */
const storeFinderTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { storeFinderTranslationChunksConfig, storeFinderTranslations, cs as storeFinderTranslationsCs, de as storeFinderTranslationsDe, en as storeFinderTranslationsEn, es as storeFinderTranslationsEs, es_CO as storeFinderTranslationsEs_CO, fr as storeFinderTranslationsFr, hi as storeFinderTranslationsHi, hu as storeFinderTranslationsHu, id as storeFinderTranslationsId, it as storeFinderTranslationsIt, ja as storeFinderTranslationsJa, ko as storeFinderTranslationsKo, pl as storeFinderTranslationsPl, pt as storeFinderTranslationsPt, ru as storeFinderTranslationsRu, zh as storeFinderTranslationsZh, zh_TW as storeFinderTranslationsZh_TW };
