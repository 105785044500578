var estimatedDeliveryDate$w = {
  quantity: "Množství-",
  quantityFull: "Množství-",
  ETA: "Odhadované datum dodání",
  estimatedDeliveryDateEntryInfo: "{{ quantity }} množství bude dodáno do {{ date }}"
};
var estimatedDeliveryDate$x = {
  estimatedDeliveryDate: estimatedDeliveryDate$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  estimatedDeliveryDate: estimatedDeliveryDate$x
};
var estimatedDeliveryDate$u = {
  quantity: "Menge-",
  quantityFull: "Menge-",
  ETA: "Voraussichtliches Lieferdatum",
  estimatedDeliveryDateEntryInfo: "Menge {{ quantity }} wird bis {{ date }} geliefert"
};
var estimatedDeliveryDate$v = {
  estimatedDeliveryDate: estimatedDeliveryDate$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  estimatedDeliveryDate: estimatedDeliveryDate$v
};
var estimatedDeliveryDate$s = {
  quantity: "Qty-",
  quantityFull: "Quantity-",
  ETA: "Estimated delivery date:",
  estimatedDeliveryDateEntryInfo: "{{ quantity }} quantity will be delivered by {{ date }}"
};
var estimatedDeliveryDate$t = {
  estimatedDeliveryDate: estimatedDeliveryDate$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  estimatedDeliveryDate: estimatedDeliveryDate$t
};
var estimatedDeliveryDate$q = {
  quantity: "Ctd. -",
  quantityFull: "Cantidad -",
  ETA: "Fecha de entrega estimada:",
  estimatedDeliveryDateEntryInfo: "La cantidad {{ quantity }} será suministrada por {{ date }}"
};
var estimatedDeliveryDate$r = {
  estimatedDeliveryDate: estimatedDeliveryDate$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  estimatedDeliveryDate: estimatedDeliveryDate$r
};
var estimatedDeliveryDate$o = {
  quantity: "Ctd. -",
  quantityFull: "Cantidad -",
  ETA: "Fecha de entrega estimada:",
  estimatedDeliveryDateEntryInfo: "La cantidad {{ quantity }} será suministrada por {{ date }}"
};
var estimatedDeliveryDate$p = {
  estimatedDeliveryDate: estimatedDeliveryDate$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  estimatedDeliveryDate: estimatedDeliveryDate$p
};
var estimatedDeliveryDate$m = {
  quantity: "Qté-",
  quantityFull: "Quantité-",
  ETA: "Date de livraison estimée :",
  estimatedDeliveryDateEntryInfo: "La quantité {{ quantity }} sera livrée par {{ date }}"
};
var estimatedDeliveryDate$n = {
  estimatedDeliveryDate: estimatedDeliveryDate$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  estimatedDeliveryDate: estimatedDeliveryDate$n
};
var estimatedDeliveryDate$k = {
  quantity: "मात्रा-",
  quantityFull: "मात्रा-",
  ETA: "अनुमानित वितरण दिनांकः",
  estimatedDeliveryDateEntryInfo: "{{ quantity }} मात्रा {{ date }} द्वारा वितरित की जाएगी"
};
var estimatedDeliveryDate$l = {
  estimatedDeliveryDate: estimatedDeliveryDate$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  estimatedDeliveryDate: estimatedDeliveryDate$l
};
var estimatedDeliveryDate$i = {
  quantity: "Menny.-",
  quantityFull: "Mennyiség-",
  ETA: "Becsült kézbesítési dátum:",
  estimatedDeliveryDateEntryInfo: "A mennyiség ({{ quantity }}) {{ date }}-ig lesz kiszállítva"
};
var estimatedDeliveryDate$j = {
  estimatedDeliveryDate: estimatedDeliveryDate$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  estimatedDeliveryDate: estimatedDeliveryDate$j
};
var estimatedDeliveryDate$g = {
  quantity: "Jml-",
  quantityFull: "Jumlah-",
  ETA: "Estimasi tanggal pengiriman:",
  estimatedDeliveryDateEntryInfo: "Sejumlah {{ quantity }} item akan dikirim sebelum {{ date }}"
};
var estimatedDeliveryDate$h = {
  estimatedDeliveryDate: estimatedDeliveryDate$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  estimatedDeliveryDate: estimatedDeliveryDate$h
};
var estimatedDeliveryDate$e = {
  quantity: "Qtà -",
  quantityFull: "Quantità -",
  ETA: "Data di consegna stimata:",
  estimatedDeliveryDateEntryInfo: "La quantità {{ quantity }} verrà consegnata da {{ date }}"
};
var estimatedDeliveryDate$f = {
  estimatedDeliveryDate: estimatedDeliveryDate$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  estimatedDeliveryDate: estimatedDeliveryDate$f
};
var estimatedDeliveryDate$c = {
  quantity: "数量-",
  quantityFull: "数量-",
  ETA: "配送予定日:",
  estimatedDeliveryDateEntryInfo: "数量 {{ quantity }} が {{ date }} までに配送されます "
};
var estimatedDeliveryDate$d = {
  estimatedDeliveryDate: estimatedDeliveryDate$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  estimatedDeliveryDate: estimatedDeliveryDate$d
};
var estimatedDeliveryDate$a = {
  quantity: "수량 -",
  quantityFull: "수량 -",
  ETA: "예상 배송일:",
  estimatedDeliveryDateEntryInfo: "{{ quantity }} 수량이 {{ date }}에 의해 배송될 예정입니다."
};
var estimatedDeliveryDate$b = {
  estimatedDeliveryDate: estimatedDeliveryDate$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  estimatedDeliveryDate: estimatedDeliveryDate$b
};
var estimatedDeliveryDate$8 = {
  quantity: "Ilość-",
  quantityFull: "Ilość-",
  ETA: "Szacowana data dostawy:",
  estimatedDeliveryDateEntryInfo: "ilość {{ quantity }} zostanie dostarczona do {{ date }}"
};
var estimatedDeliveryDate$9 = {
  estimatedDeliveryDate: estimatedDeliveryDate$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  estimatedDeliveryDate: estimatedDeliveryDate$9
};
var estimatedDeliveryDate$6 = {
  quantity: "Qtd-",
  quantityFull: "Quantidade-",
  ETA: "Data estimada de entrega:",
  estimatedDeliveryDateEntryInfo: "A quantidade {{ quantity }} será entregue até {{ date }}"
};
var estimatedDeliveryDate$7 = {
  estimatedDeliveryDate: estimatedDeliveryDate$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  estimatedDeliveryDate: estimatedDeliveryDate$7
};
var estimatedDeliveryDate$4 = {
  quantity: "Кол.-",
  quantityFull: "Количество-",
  ETA: "Ориентировочная дата доставки:",
  estimatedDeliveryDateEntryInfo: "Количество {{ quantity }} будет доставлено {{ date }}"
};
var estimatedDeliveryDate$5 = {
  estimatedDeliveryDate: estimatedDeliveryDate$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  estimatedDeliveryDate: estimatedDeliveryDate$5
};
var estimatedDeliveryDate$2 = {
  quantity: "数量-",
  quantityFull: "数量-",
  ETA: "预计交付日期：",
  estimatedDeliveryDateEntryInfo: "{{ quantity }} 数量将由 {{ date }} 交付"
};
var estimatedDeliveryDate$3 = {
  estimatedDeliveryDate: estimatedDeliveryDate$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  estimatedDeliveryDate: estimatedDeliveryDate$3
};
var estimatedDeliveryDate = {
  quantity: "數量",
  quantityFull: "數量",
  ETA: "預計交貨日期：",
  estimatedDeliveryDateEntryInfo: "{{ quantity }} 數量將於 {{ date }} 交貨"
};
var estimatedDeliveryDate$1 = {
  estimatedDeliveryDate: estimatedDeliveryDate
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  estimatedDeliveryDate: estimatedDeliveryDate$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const estimatedDeliveryDateTranslationChunksConfig = {
  estimatedDeliveryDate: ['estimatedDeliveryDate']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: estimatedDeliveryDateTranslations
 *             +   resources: { en: estimatedDeliveryDateTranslationsEn }
 *               }
 *             ```
 */
const estimatedDeliveryDateTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { estimatedDeliveryDateTranslationChunksConfig, estimatedDeliveryDateTranslations, cs as estimatedDeliveryDateTranslationsCs, de as estimatedDeliveryDateTranslationsDe, en as estimatedDeliveryDateTranslationsEn, es as estimatedDeliveryDateTranslationsEs, es_CO as estimatedDeliveryDateTranslationsEs_CO, fr as estimatedDeliveryDateTranslationsFr, hi as estimatedDeliveryDateTranslationsHi, hu as estimatedDeliveryDateTranslationsHu, id as estimatedDeliveryDateTranslationsId, it as estimatedDeliveryDateTranslationsIt, ja as estimatedDeliveryDateTranslationsJa, ko as estimatedDeliveryDateTranslationsKo, pl as estimatedDeliveryDateTranslationsPl, pt as estimatedDeliveryDateTranslationsPt, ru as estimatedDeliveryDateTranslationsRu, zh as estimatedDeliveryDateTranslationsZh, zh_TW as estimatedDeliveryDateTranslationsZh_TW };
