<!-- <ng-container *ngIf="cart$ | async as cart"> -->
<div class="waygate-cart-container my-3">
  <div class="waygate-cart-action-content">
    <div class="waygate-cart-shipment-preference">
      <div class="waygate-cart-radio-btn mt-2">
        <bh-radio-button
          [attr.checked]="!isPartialShipment"
          label="Standard"
          value="'Complete'"
          name="group"
          checked="checked"
          (change)="handleChange($event)"
          [attr.disabled]="isShipmentSelectionDisabled"
        ></bh-radio-button>
        <bh-radio-button
          [attr.checked]="isPartialShipment"
          label="Express quote +250$"
          value="'Partial'"
          name="group"
          (change)="handleChange($event)"
          [attr.disabled]="isShipmentSelectionDisabled"
        ></bh-radio-button>
      </div>
    </div>
    <div class="waygate-cart-action-options">
      <div class="waygate-cart-action-option ds-datepicker">
        <label for="requestShipDate">Requested ship date</label>
        <vs-date-picker
          class="product-date-picker"
          id="waygateProductShipDate"
          [filterWeekend]="true"
          [defaultDate]="defaultDate"
          [min]="minDate"
          (selectedDate)="dateChange($event)"
        >
        </vs-date-picker>
      </div>
      <div class="waygate-cart-action-option">
        <a class="action-links mr-4" (click)="applyToAll()">
          <bh-button
            type="secondary"
            class="waygate-cart-action-option-text"
            label="Apply to all"
            [leftIcon]="'save'"
          ></bh-button>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- </ng-container> -->
