var futureStockDropdown$g = {
  header: "Budoucí dostupnost",
  quantity: "Množství",
  noFutureStocks: "Pro tento produkt nejsou informace o budoucí dostupnosti."
};
var futureStock$g = {
  futureStockDropdown: futureStockDropdown$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  futureStock: futureStock$g
};
var futureStockDropdown$f = {
  header: "Zukünftige Verfügbarkeit",
  quantity: "Menge",
  noFutureStocks: "Für dieses Produkt gibt es keine Informationen zur zukünftigen Verfügbarkeit."
};
var futureStock$f = {
  futureStockDropdown: futureStockDropdown$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  futureStock: futureStock$f
};
var futureStockDropdown$e = {
  header: "Future Availability",
  quantity: "Qty",
  noFutureStocks: "This product has no future availability information."
};
var futureStock$e = {
  futureStockDropdown: futureStockDropdown$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  futureStock: futureStock$e
};
var futureStockDropdown$d = {
  header: "Disponibilidad futura",
  quantity: "Cantidad",
  noFutureStocks: "Este producto no tiene información de disponibilidad futura."
};
var futureStock$d = {
  futureStockDropdown: futureStockDropdown$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  futureStock: futureStock$d
};
var futureStockDropdown$c = {
  header: "Disponibilidad futura",
  quantity: "Cantidad",
  noFutureStocks: "Este producto no tiene información de disponibilidad futura."
};
var futureStock$c = {
  futureStockDropdown: futureStockDropdown$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  futureStock: futureStock$c
};
var futureStockDropdown$b = {
  header: "Disponibilité future",
  quantity: "Qté",
  noFutureStocks: "Il n'y a aucune information sur la disponibilité future pour ce produit."
};
var futureStock$b = {
  futureStockDropdown: futureStockDropdown$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  futureStock: futureStock$b
};
var futureStockDropdown$a = {
  header: "भावी उपलब्धता",
  quantity: "मात्रा",
  noFutureStocks: "इस उत्पाद की भविष्य में उपलब्धता की कोई जानकारी नहीं है."
};
var futureStock$a = {
  futureStockDropdown: futureStockDropdown$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  futureStock: futureStock$a
};
var futureStockDropdown$9 = {
  header: "Jövőbeli rendelkezésre állás",
  quantity: "Menny.",
  noFutureStocks: "Nincs információ a termék jövőbeli rendelkezésre állásáról."
};
var futureStock$9 = {
  futureStockDropdown: futureStockDropdown$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  futureStock: futureStock$9
};
var futureStockDropdown$8 = {
  header: "Ketersediaan di Masa Mendatang",
  quantity: "Jml",
  noFutureStocks: "Produk ini tidak memiliki informasi ketersediaan di masa mendatang."
};
var futureStock$8 = {
  futureStockDropdown: futureStockDropdown$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  futureStock: futureStock$8
};
var futureStockDropdown$7 = {
  header: "Disponibilità futura",
  quantity: "Qtà",
  noFutureStocks: "Nessuna informazione sulla disponibilità futura del prodotto."
};
var futureStock$7 = {
  futureStockDropdown: futureStockDropdown$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  futureStock: futureStock$7
};
var futureStockDropdown$6 = {
  header: "入荷予定",
  quantity: "数量",
  noFutureStocks: "申し訳ありませんが、この商品の入荷予定はありません."
};
var futureStock$6 = {
  futureStockDropdown: futureStockDropdown$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  futureStock: futureStock$6
};
var futureStockDropdown$5 = {
  header: "미래 가용성",
  quantity: "수량",
  noFutureStocks: "이 제품에는 미래 가용성 정보가 없습니다."
};
var futureStock$5 = {
  futureStockDropdown: futureStockDropdown$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  futureStock: futureStock$5
};
var futureStockDropdown$4 = {
  header: "Dostępność w przyszłości",
  quantity: "Ilość",
  noFutureStocks: "Ten produkt nie ma informacji o dostępności w przyszłości."
};
var futureStock$4 = {
  futureStockDropdown: futureStockDropdown$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  futureStock: futureStock$4
};
var futureStockDropdown$3 = {
  header: "Disponibilidade futura",
  quantity: "Quantidade",
  noFutureStocks: "Este produto não tem informação de disponibilidade futura."
};
var futureStock$3 = {
  futureStockDropdown: futureStockDropdown$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  futureStock: futureStock$3
};
var futureStockDropdown$2 = {
  header: "Доступность в будущем",
  quantity: "Кол.",
  noFutureStocks: "По этому продукту нет информации о его доступности в будущем."
};
var futureStock$2 = {
  futureStockDropdown: futureStockDropdown$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  futureStock: futureStock$2
};
var futureStockDropdown$1 = {
  header: "未来可用性",
  quantity: "数量",
  noFutureStocks: "此产品没有未来可用性信息。"
};
var futureStock$1 = {
  futureStockDropdown: futureStockDropdown$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  futureStock: futureStock$1
};
var futureStockDropdown = {
  header: "未來可用度",
  quantity: "數量",
  noFutureStocks: "此產品沒有未來可用度資訊。"
};
var futureStock = {
  futureStockDropdown: futureStockDropdown
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  futureStock
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const futureStockTranslationChunksConfig = {
  futureStock: ['futureStockDropdown']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: futureStockTranslations
 *             +   resources: { en: futureStockTranslationsEn }
 *               }
 *             ```
 */
const futureStockTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { futureStockTranslationChunksConfig, futureStockTranslations, cs as futureStockTranslationsCs, de as futureStockTranslationsDe, en as futureStockTranslationsEn, es as futureStockTranslationsEs, es_CO as futureStockTranslationsEs_CO, fr as futureStockTranslationsFr, hi as futureStockTranslationsHi, hu as futureStockTranslationsHu, id as futureStockTranslationsId, it as futureStockTranslationsIt, ja as futureStockTranslationsJa, ko as futureStockTranslationsKo, pl as futureStockTranslationsPl, pt as futureStockTranslationsPt, ru as futureStockTranslationsRu, zh as futureStockTranslationsZh, zh_TW as futureStockTranslationsZh_TW };
