var multiDimensionalSelector$w = {
  variantThumbnailTitle: "Vyberte {{value}} {{category}}",
  selectedVariant: "Vybráno"
};
var multiDimensionalSelector$x = {
  multiDimensionalSelector: multiDimensionalSelector$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  multiDimensionalSelector: multiDimensionalSelector$x
};
var multiDimensionalSelector$u = {
  variantThumbnailTitle: " {{category}} {{value}} auswählen",
  selectedVariant: "Ausgewählt"
};
var multiDimensionalSelector$v = {
  multiDimensionalSelector: multiDimensionalSelector$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  multiDimensionalSelector: multiDimensionalSelector$v
};
var multiDimensionalSelector$s = {
  variantThumbnailTitle: "Select {{value}} {{category}}",
  selectedVariant: "Selected"
};
var multiDimensionalSelector$t = {
  multiDimensionalSelector: multiDimensionalSelector$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  multiDimensionalSelector: multiDimensionalSelector$t
};
var multiDimensionalSelector$q = {
  variantThumbnailTitle: "Seleccionar {{value}} {{category}}",
  selectedVariant: "Seleccionado"
};
var multiDimensionalSelector$r = {
  multiDimensionalSelector: multiDimensionalSelector$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  multiDimensionalSelector: multiDimensionalSelector$r
};
var multiDimensionalSelector$o = {
  variantThumbnailTitle: "Seleccionar {{value}} {{category}}",
  selectedVariant: "Seleccionado"
};
var multiDimensionalSelector$p = {
  multiDimensionalSelector: multiDimensionalSelector$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  multiDimensionalSelector: multiDimensionalSelector$p
};
var multiDimensionalSelector$m = {
  variantThumbnailTitle: "Sélectionner {{value}} {{category}}",
  selectedVariant: "Sélectionné"
};
var multiDimensionalSelector$n = {
  multiDimensionalSelector: multiDimensionalSelector$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  multiDimensionalSelector: multiDimensionalSelector$n
};
var multiDimensionalSelector$k = {
  variantThumbnailTitle: "{{value}} {{category}} चुनें",
  selectedVariant: "चयनित"
};
var multiDimensionalSelector$l = {
  multiDimensionalSelector: multiDimensionalSelector$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  multiDimensionalSelector: multiDimensionalSelector$l
};
var multiDimensionalSelector$i = {
  variantThumbnailTitle: "{{value}} {{category}} kiválasztása",
  selectedVariant: "Kiválasztva"
};
var multiDimensionalSelector$j = {
  multiDimensionalSelector: multiDimensionalSelector$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  multiDimensionalSelector: multiDimensionalSelector$j
};
var multiDimensionalSelector$g = {
  variantThumbnailTitle: "Pilih {{value}} {{category}}",
  selectedVariant: "Dipilih"
};
var multiDimensionalSelector$h = {
  multiDimensionalSelector: multiDimensionalSelector$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  multiDimensionalSelector: multiDimensionalSelector$h
};
var multiDimensionalSelector$e = {
  variantThumbnailTitle: "Seleziona {{value}} {{category}}",
  selectedVariant: "Selezionata"
};
var multiDimensionalSelector$f = {
  multiDimensionalSelector: multiDimensionalSelector$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  multiDimensionalSelector: multiDimensionalSelector$f
};
var multiDimensionalSelector$c = {
  variantThumbnailTitle: "{{value}} {{category}} を選択",
  selectedVariant: "選択済み"
};
var multiDimensionalSelector$d = {
  multiDimensionalSelector: multiDimensionalSelector$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  multiDimensionalSelector: multiDimensionalSelector$d
};
var multiDimensionalSelector$a = {
  variantThumbnailTitle: "{{value}} {{category}} 선택",
  selectedVariant: "선택됨"
};
var multiDimensionalSelector$b = {
  multiDimensionalSelector: multiDimensionalSelector$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  multiDimensionalSelector: multiDimensionalSelector$b
};
var multiDimensionalSelector$8 = {
  variantThumbnailTitle: "Wybierz {{value}} {{category}}",
  selectedVariant: "Wybrane"
};
var multiDimensionalSelector$9 = {
  multiDimensionalSelector: multiDimensionalSelector$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  multiDimensionalSelector: multiDimensionalSelector$9
};
var multiDimensionalSelector$6 = {
  variantThumbnailTitle: "Selecionar {{value}} {{category}}",
  selectedVariant: "Selecionado"
};
var multiDimensionalSelector$7 = {
  multiDimensionalSelector: multiDimensionalSelector$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  multiDimensionalSelector: multiDimensionalSelector$7
};
var multiDimensionalSelector$4 = {
  variantThumbnailTitle: "Выбрать {{value}} {{category}}",
  selectedVariant: "Выбрано"
};
var multiDimensionalSelector$5 = {
  multiDimensionalSelector: multiDimensionalSelector$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  multiDimensionalSelector: multiDimensionalSelector$5
};
var multiDimensionalSelector$2 = {
  variantThumbnailTitle: "选择 {{value}} {{category}}",
  selectedVariant: "已选择"
};
var multiDimensionalSelector$3 = {
  multiDimensionalSelector: multiDimensionalSelector$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  multiDimensionalSelector: multiDimensionalSelector$3
};
var multiDimensionalSelector = {
  variantThumbnailTitle: "選擇 {{value}} {{category}}",
  selectedVariant: "已選擇"
};
var multiDimensionalSelector$1 = {
  multiDimensionalSelector: multiDimensionalSelector
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  multiDimensionalSelector: multiDimensionalSelector$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const multiDimensionalSelectorTranslationChunksConfig = {
  multiDimensionalSelector: ['multiDimensionalSelector']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: multiDimensionalSelectorTranslations
 *             +   resources: { en: multiDimensionalSelectorTranslationsEn }
 *               }
 *             ```
 */
const multiDimensionalSelectorTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { multiDimensionalSelectorTranslationChunksConfig, multiDimensionalSelectorTranslations, cs as multiDimensionalSelectorTranslationsCs, de as multiDimensionalSelectorTranslationsDe, en as multiDimensionalSelectorTranslationsEn, es as multiDimensionalSelectorTranslationsEs, es_CO as multiDimensionalSelectorTranslationsEs_CO, fr as multiDimensionalSelectorTranslationsFr, hi as multiDimensionalSelectorTranslationsHi, hu as multiDimensionalSelectorTranslationsHu, id as multiDimensionalSelectorTranslationsId, it as multiDimensionalSelectorTranslationsIt, ja as multiDimensionalSelectorTranslationsJa, ko as multiDimensionalSelectorTranslationsKo, pl as multiDimensionalSelectorTranslationsPl, pt as multiDimensionalSelectorTranslationsPt, ru as multiDimensionalSelectorTranslationsRu, zh as multiDimensionalSelectorTranslationsZh, zh_TW as multiDimensionalSelectorTranslationsZh_TW };
