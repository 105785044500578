import { Component, OnInit, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'vs-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss'],
})
export class SpinnerOverlayComponent implements OnInit {
  @Input() public message: string;
  constructor() {}

  ngOnInit(): void {}
}
