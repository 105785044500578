var checkout$w = {
  backToCart: "Zpět do košíku"
};
var checkoutProgress$g = {
  label: "Průběh kontroly a potvrzení",
  deliveryAddress: "Dodací adresa",
  deliveryMode: "Způsob dodání",
  paymentDetails: "Platba",
  reviewOrder: "Recenze",
  state: {
    completed: "{{step}}, dokončeno",
    selected: "{{step}}, vybráno",
    disabled: "{{step}}, deaktivováno"
  }
};
var checkoutAddress$g = {
  shippingAddress: "Dodací adresa",
  selectYourDeliveryAddress: "Vyberte dodací adresu",
  defaultDeliveryAddress: "Standardní dodací adresa",
  addNewAddress: "Přidat novou adresu",
  shipToThisAddress: "Doručit na tuto adresu",
  deliveryAddressSelected: "Dodací adresa vybrána"
};
var checkoutMode$g = {
  deliveryMethod: "Způsob dodání",
  deliveryOptions: "Možnosti dodání",
  standardDelivery: "Standardní dodání",
  premiumDelivery: "Prémiové dodání",
  deliveryEntries: "Položky k odeslání"
};
var checkoutReview$g = {
  review: "Recenze",
  reviewOrder: "Zkontrolovat objednávku",
  orderItems: "Položky objednávky",
  confirmThatRead: "Potvrzuji, že po přečtení souhlasím s",
  placeOrder: "Objednat",
  termsAndConditions: "Obchodní podmínky",
  editDeliveryAddressDetails: "Upravit podrobnosti dodací adresy, otevře stránku s dodací adresou",
  editBillingDetails: "Upravit fakturační adresu, otevře stránku Platební údaje",
  editPaymentDetails: "Upravit platební údaje, otevře stránku Platební údaje",
  editPaymentType: "Upravit způsob platby, otevře stránku Způsob platby",
  editDeliveryMode: "Upravit režim dodání, otevře stránku Režim dodání",
  orderInProcess: "Objednávka se zpracovává, vyčkejte."
};
var checkoutOrderConfirmation$g = {
  confirmationOfOrder: "Potvrzení objednávky:",
  thankYou: "Děkujeme vám za objednávku!",
  invoiceHasBeenSentByEmail: "Fakturu jsme odeslali e-mailem. Brzy byste ji měli obdržet.",
  orderItems: "Položky objednávky",
  orderPlacedSuccessfully: "Objednávka úspěšně podána",
  createAccount: "Vytvořit účet?",
  createAccountForNext: "Vytvořte si účet pro <{{email}}> za  účelem rychlejší kontroly a potvrzení při příští návštěvě."
};
var checkout$x = {
  checkout: checkout$w,
  checkoutProgress: checkoutProgress$g,
  checkoutAddress: checkoutAddress$g,
  checkoutMode: checkoutMode$g,
  checkoutReview: checkoutReview$g,
  checkoutOrderConfirmation: checkoutOrderConfirmation$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  checkout: checkout$x
};
var checkout$u = {
  backToCart: "Zurück zum Warenkorb"
};
var checkoutProgress$f = {
  label: "Fortschritt beim Bezahlen",
  deliveryAddress: "Lieferadresse",
  deliveryMode: "Lieferart",
  paymentDetails: "Zahlung",
  reviewOrder: "Prüfen",
  state: {
    completed: "{{step}}, abgeschlossen",
    selected: "{{step}}, ausgewählt",
    disabled: "{{step}}, deaktiviert"
  }
};
var checkoutAddress$f = {
  shippingAddress: "Lieferadresse",
  selectYourDeliveryAddress: "Wählen Sie Ihre Lieferadresse aus",
  defaultDeliveryAddress: "Standardlieferadresse",
  addNewAddress: "Neue Adresse hinzufügen",
  shipToThisAddress: "An diese Adresse senden",
  deliveryAddressSelected: "Lieferadresse ausgewählt"
};
var checkoutMode$f = {
  deliveryMethod: "Lieferart",
  deliveryOptions: "Lieferoptionen",
  standardDelivery: "Standard-Lieferung",
  premiumDelivery: "Premium-Lieferung",
  deliveryEntries: "Zu versendende Positionen"
};
var checkoutReview$f = {
  review: "Prüfen",
  reviewOrder: "Bestellung prüfen",
  orderItems: "Bestellpositionen",
  confirmThatRead: "Ich bestätige, dass ich Folgendes gelesen habe und damit einverstanden bin:",
  placeOrder: "Bestellung aufgeben",
  termsAndConditions: "Allgemeine Geschäftsbedingungen",
  editDeliveryAddressDetails: "Details der Lieferadresse bearbeiten, öffnet die Seite \"Lieferadresse\"",
  editBillingDetails: "Rechnungsadresse bearbeiten, öffnet die Seite \"Zahlungsdetails\"",
  editPaymentDetails: "Zahlungsdetails bearbeiten, öffnet die Seite \"Zahlungsdetails\"",
  editPaymentType: "Zahlungsart bearbeiten, öffnet die Seite \"Zahlungsart\"",
  editDeliveryMode: "Lieferart bearbeiten, öffnet die Seite \"Lieferart\"",
  orderInProcess: "Bestellung ist in Bearbeitung. Bitte warten."
};
var checkoutOrderConfirmation$f = {
  confirmationOfOrder: "Bestätigung der Bestellung",
  thankYou: "Vielen Dank für Ihre Bestellung!",
  invoiceHasBeenSentByEmail: "Eine Rechnung wurde per E-Mail an Sie gesendet, die Sie in Kürze erhalten müssten.",
  orderItems: "Bestellpositionen",
  orderPlacedSuccessfully: "Bestellung erfolgreich aufgegeben",
  createAccount: "Ein Konto erstellen?",
  createAccountForNext: "Erstellen Sie ein Konto für <{{email}}>, um bei Ihrem nächsten Besuch schneller bezahlen zu können."
};
var checkout$v = {
  checkout: checkout$u,
  checkoutProgress: checkoutProgress$f,
  checkoutAddress: checkoutAddress$f,
  checkoutMode: checkoutMode$f,
  checkoutReview: checkoutReview$f,
  checkoutOrderConfirmation: checkoutOrderConfirmation$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  checkout: checkout$v
};
var checkout$s = {
  backToCart: "Back to cart"
};
var checkoutProgress$e = {
  label: "Checkout Progress",
  deliveryAddress: "Shipping Address",
  deliveryMode: "Delivery Mode",
  paymentDetails: "Payment",
  reviewOrder: "Review",
  state: {
    completed: "{{step}}, Completed",
    selected: "{{step}}, Selected",
    disabled: "{{step}}, Disabled"
  }
};
var checkoutAddress$e = {
  shippingAddress: "Shipping Address",
  selectYourDeliveryAddress: "Select your Delivery Address",
  defaultDeliveryAddress: "Default Delivery Address",
  addNewAddress: "Add New Address",
  shipToThisAddress: "Ship to this address",
  deliveryAddressSelected: "Delivery address selected"
};
var checkoutMode$e = {
  deliveryMethod: "Delivery Method",
  deliveryOptions: "Delivery Options",
  standardDelivery: "Standard Delivery",
  premiumDelivery: "Premium Delivery",
  deliveryEntries: "Items to be Shipped"
};
var checkoutReview$e = {
  review: "Review",
  reviewOrder: "Review Order",
  orderItems: "Order Items",
  confirmThatRead: "I am confirming that I have read and agreed with the",
  placeOrder: "Place Order",
  termsAndConditions: "Terms & Conditions",
  editDeliveryAddressDetails: "Edit delivery address details, opens Delivery Address page",
  editBillingDetails: "Edit billing address, opens Payment Details page",
  editPaymentDetails: "Edit payment details, opens Payment Details page",
  editPaymentType: "Edit payment method, opens Method of Payment page",
  editDeliveryMode: "Edit delivery mode, opens Delivery Mode page",
  orderInProcess: "Order is in process. Please wait."
};
var checkoutOrderConfirmation$e = {
  confirmationOfOrder: "Confirmation of Order:",
  thankYou: "Thank you for your order!",
  invoiceHasBeenSentByEmail: "An invoice has been sent by email. You should receive it soon.",
  orderItems: "Order Items",
  orderPlacedSuccessfully: "Order placed successfully",
  createAccount: "Create an account?",
  createAccountForNext: "Create an account for <{{email}}> for a faster checkout on your next visit."
};
var checkout$t = {
  checkout: checkout$s,
  checkoutProgress: checkoutProgress$e,
  checkoutAddress: checkoutAddress$e,
  checkoutMode: checkoutMode$e,
  checkoutReview: checkoutReview$e,
  checkoutOrderConfirmation: checkoutOrderConfirmation$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  checkout: checkout$t
};
var checkout$q = {
  backToCart: "Volver a la cesta"
};
var checkoutProgress$d = {
  label: "Ver progreso",
  deliveryAddress: "Dirección de envío",
  deliveryMode: "Modo de entrega",
  paymentDetails: "Pago",
  reviewOrder: "Revisión",
  state: {
    completed: "{{step}}, Finalizado",
    selected: "{{step}}, Seleccionado",
    disabled: "{{step}}, Desactivado"
  }
};
var checkoutAddress$d = {
  shippingAddress: "Dirección de envío",
  selectYourDeliveryAddress: "Seleccione su dirección de entrega",
  defaultDeliveryAddress: "Dirección de entrega predeterminada",
  addNewAddress: "Añadir dirección nueva",
  shipToThisAddress: "Enviar a esta dirección",
  deliveryAddressSelected: "Dirección de entrega seleccionada"
};
var checkoutMode$d = {
  deliveryMethod: "Método de entrega",
  deliveryOptions: "Opciones de entrega",
  standardDelivery: "Entrega estándar",
  premiumDelivery: "Entrega premium",
  deliveryEntries: "Artículos para enviar"
};
var checkoutReview$d = {
  review: "Revisión",
  reviewOrder: "Revisar pedido",
  orderItems: "Artículos del pedido",
  confirmThatRead: "Confirmo que he leído y estoy de acuerdo con los",
  placeOrder: "Realizar pedido",
  termsAndConditions: "Términos y condiciones",
  editDeliveryAddressDetails: "Editar detalles de la dirección de entrega abre la página Dirección de entrega",
  editBillingDetails: "Editar dirección de facturación abre la página Detalles de pago",
  editPaymentDetails: "Editar detalles de pago abre la página Detalles de pago",
  editPaymentType: "Editar método de pago abre la página Método de pago",
  editDeliveryMode: "Editar modo de entrega abre la página Modo de entrega",
  orderInProcess: "El pedido está en proceso. Por favor, espere."
};
var checkoutOrderConfirmation$d = {
  confirmationOfOrder: "Confirmación de pedido:",
  thankYou: "Gracias por su pedido.",
  invoiceHasBeenSentByEmail: "Le hemos enviado una factura por correo electrónico. Debería recibirla pronto.",
  orderItems: "Artículos del pedido",
  orderPlacedSuccessfully: "Pedido realizado correctamente",
  createAccount: "Crear una cuenta",
  createAccountForNext: "Cree una cuenta <{{email}}> para gestionar compras más rápido la próxima vez."
};
var checkout$r = {
  checkout: checkout$q,
  checkoutProgress: checkoutProgress$d,
  checkoutAddress: checkoutAddress$d,
  checkoutMode: checkoutMode$d,
  checkoutReview: checkoutReview$d,
  checkoutOrderConfirmation: checkoutOrderConfirmation$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  checkout: checkout$r
};
var checkout$o = {
  backToCart: "Volver al carro"
};
var checkoutProgress$c = {
  label: "Ver progreso",
  deliveryAddress: "Dirección de envío",
  deliveryMode: "Modo de entrega",
  paymentDetails: "Pago",
  reviewOrder: "Revisión",
  state: {
    completed: "{{step}}Finalizado",
    selected: "{{step}}, Seleccionado",
    disabled: "{{step}}, Desactivado"
  }
};
var checkoutAddress$c = {
  shippingAddress: "Dirección de envío",
  selectYourDeliveryAddress: "Seleccione su dirección de entrega",
  defaultDeliveryAddress: "Dirección de entrega predeterminada",
  addNewAddress: "Agregar dirección nueva",
  shipToThisAddress: "Enviar a esta dirección",
  deliveryAddressSelected: "Dirección de entrega seleccionada"
};
var checkoutMode$c = {
  deliveryMethod: "Método de entrega",
  deliveryOptions: "Opciones de entrega",
  standardDelivery: "Entrega estándar",
  premiumDelivery: "Entrega premium",
  deliveryEntries: "Artículos para enviar"
};
var checkoutReview$c = {
  review: "Revisión",
  reviewOrder: "Revisar pedido",
  orderItems: "Artículos del pedido",
  confirmThatRead: "Confirmo que he leído y estoy de acuerdo con los",
  placeOrder: "Realizar pedido",
  termsAndConditions: "Términos y condiciones",
  editDeliveryAddressDetails: "Editar detalles de la dirección de entrega abre la página Dirección de entrega",
  editBillingDetails: "Editar dirección de facturación abre la página Detalles de pago",
  editPaymentDetails: "Editar detalles de pago abre la página Detalles de pago",
  editPaymentType: "Editar método de pago abre la página Método de pago",
  editDeliveryMode: "Editar modo de entrega abre la página Modo de entrega",
  orderInProcess: "El pedido está en proceso. Por favor, espere."
};
var checkoutOrderConfirmation$c = {
  confirmationOfOrder: "Confirmación de pedido:",
  thankYou: "Gracias por su pedido.",
  invoiceHasBeenSentByEmail: "Le enviamos una factura por correo electrónico. Debería recibirla pronto.",
  orderItems: "Artículos del pedido",
  orderPlacedSuccessfully: "Pedido realizado correctamente",
  createAccount: "Crear una cuenta",
  createAccountForNext: "Cree una cuenta <{{email}}> para gestionar compras más rápido la próxima vez."
};
var checkout$p = {
  checkout: checkout$o,
  checkoutProgress: checkoutProgress$c,
  checkoutAddress: checkoutAddress$c,
  checkoutMode: checkoutMode$c,
  checkoutReview: checkoutReview$c,
  checkoutOrderConfirmation: checkoutOrderConfirmation$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  checkout: checkout$p
};
var checkout$m = {
  backToCart: "Retour au panier"
};
var checkoutProgress$b = {
  label: "Progression de la validation de la commande",
  deliveryAddress: "Adresse d'expédition",
  deliveryMode: "Mode de livraison",
  paymentDetails: "Paiement",
  reviewOrder: "Vérification",
  state: {
    completed: "{{step}}, terminé",
    selected: "{{step}}, sélectionné",
    disabled: "{{step}}, désactivé"
  }
};
var checkoutAddress$b = {
  shippingAddress: "Adresse d'expédition",
  selectYourDeliveryAddress: "Sélectionnez votre adresse de livraison",
  defaultDeliveryAddress: "Adresse de livraison par défaut",
  addNewAddress: "Ajouter une nouvelle adresse",
  shipToThisAddress: "Expédier à cette adresse",
  deliveryAddressSelected: "Adresse de livraison sélectionnée"
};
var checkoutMode$b = {
  deliveryMethod: "Mode de livraison",
  deliveryOptions: "Options de livraison",
  standardDelivery: "Livraison standard",
  premiumDelivery: "Livraison premium",
  deliveryEntries: "Articles à expédier"
};
var checkoutReview$b = {
  review: "Vérification",
  reviewOrder: "Vérifier la commande",
  orderItems: "Articles de la commande",
  confirmThatRead: "Je confirme avoir lu et accepté les",
  placeOrder: "Passer la commande",
  termsAndConditions: "Conditions générales",
  editDeliveryAddressDetails: "Modifier les détails de l'adresse de livraison, ouverture de la page Adresse de livraison",
  editBillingDetails: "Modifier l'adresse de facturation, ouverture de la page Détails du paiement",
  editPaymentDetails: "Modifier les détails du paiement, ouverture de la page Détails du paiement",
  editPaymentType: "Modifier le mode de paiement, ouverture de la page Mode de paiement",
  editDeliveryMode: "Modifier le mode de livraison, ouverture de la page Mode de livraison",
  orderInProcess: "La commande est en cours. Veuillez patienter."
};
var checkoutOrderConfirmation$b = {
  confirmationOfOrder: "Confirmation de la commande :",
  thankYou: "Merci pour votre commande !",
  invoiceHasBeenSentByEmail: "Une facture vous a été envoyée par e-mail. Vous devriez la recevoir bientôt.",
  orderItems: "Articles de la commande",
  orderPlacedSuccessfully: "La commande a été passée avec succès",
  createAccount: "Créer un compte ?",
  createAccountForNext: "Créez un compte pour <{{email}}> pour valider plus rapidement votre commande lors de votre prochaine visite."
};
var checkout$n = {
  checkout: checkout$m,
  checkoutProgress: checkoutProgress$b,
  checkoutAddress: checkoutAddress$b,
  checkoutMode: checkoutMode$b,
  checkoutReview: checkoutReview$b,
  checkoutOrderConfirmation: checkoutOrderConfirmation$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  checkout: checkout$n
};
var checkout$k = {
  backToCart: "कार्ट पर वापस जाएं"
};
var checkoutProgress$a = {
  label: "चेआउट प्रगति",
  deliveryAddress: "शिपिंग पता",
  deliveryMode: "वितरण मोड",
  paymentDetails: "भुगतान",
  reviewOrder: "समीक्षा",
  state: {
    completed: "{{step}}, पूर्ण किया गया",
    selected: "{{step}}, चयनित",
    disabled: "{{step}}, अक्षम किया गया"
  }
};
var checkoutAddress$a = {
  shippingAddress: "शिपिंग पता",
  selectYourDeliveryAddress: "अपना वितरण पता चुनें",
  defaultDeliveryAddress: "डिफ़ॉल्ट वितरण पता",
  addNewAddress: "नया पता जोड़ें",
  shipToThisAddress: "इस पते पर शिप करें",
  deliveryAddressSelected: "वितरण पता चयनित"
};
var checkoutMode$a = {
  deliveryMethod: "वितरण विधि",
  deliveryOptions: "वितरण विकल्प",
  standardDelivery: "मानक वितरण",
  premiumDelivery: "प्रीमियम वितरण",
  deliveryEntries: "शिप किए जाने वाले आइटम"
};
var checkoutReview$a = {
  review: "समीक्षा",
  reviewOrder: "ऑर्डर की समीक्षा करें",
  orderItems: "आइटम ऑर्डर करें",
  confirmThatRead: "मैं पुष्टि कर रहा हूं कि मैंने पढ़ा और सहमत हूं",
  placeOrder: "ऑर्डर दें",
  termsAndConditions: "नियम & शर्तें",
  editDeliveryAddressDetails: "वितरण पता संपादित करें, वितरण पता पृष्ठ खुलता है",
  editBillingDetails: "बिलिंग पता संपादित करें, भुगतान विवरण पृष्ठ खुलता है",
  editPaymentDetails: "भुगतान विवरण संपादित करें, भुगतान विवरण पृष्ठ खुलता है",
  editPaymentType: "भुगतान विधि संपादित करें, भुगतान विधि का पृष्ठ खुलता है",
  editDeliveryMode: "वितरण मोड संपादित करें, वितरण मोड पृष्ठ खुलता है",
  orderInProcess: "ऑर्डर प्रक्रिया में है. कृपया प्रतीक्षा करें."
};
var checkoutOrderConfirmation$a = {
  confirmationOfOrder: "ऑर्डर की पुष्टीः",
  thankYou: "आपके ऑर्डर के लिए धन्यवाद!",
  invoiceHasBeenSentByEmail: "ईमेल द्वारा चालान भेजा गया है. आप इसे जल्द ही प्राप्त करेंगे.",
  orderItems: "आइटम ऑर्डर करें",
  orderPlacedSuccessfully: "ऑर्डर सफलतापूर्वक दिया गया",
  createAccount: "एक खाता बनाएं?",
  createAccountForNext: "अपनी अगली विजिट पर तेज चेकआउट के लिए  <{{email}}> हेतु खाता बनाएं."
};
var checkout$l = {
  checkout: checkout$k,
  checkoutProgress: checkoutProgress$a,
  checkoutAddress: checkoutAddress$a,
  checkoutMode: checkoutMode$a,
  checkoutReview: checkoutReview$a,
  checkoutOrderConfirmation: checkoutOrderConfirmation$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  checkout: checkout$l
};
var checkout$i = {
  backToCart: "Vissza a kosárba"
};
var checkoutProgress$9 = {
  label: "Fizetési folyamat",
  deliveryAddress: "Szállítási cím",
  deliveryMode: "Kézbesítési mód",
  paymentDetails: "Fizetés",
  reviewOrder: "Vélemény",
  state: {
    completed: "{{step}}, befejezve",
    selected: "{{step}}, kiválasztva",
    disabled: "{{step}}, letiltva"
  }
};
var checkoutAddress$9 = {
  shippingAddress: "Szállítási cím",
  selectYourDeliveryAddress: "Válassza ki a kézbesítési címét",
  defaultDeliveryAddress: "Alapértelmezett kézbesítési cím",
  addNewAddress: "Új cím hozzáadása",
  shipToThisAddress: "Szállítás erre a címre",
  deliveryAddressSelected: "Kézbesítési cím kiválasztva"
};
var checkoutMode$9 = {
  deliveryMethod: "Kézbesítési mód",
  deliveryOptions: "Kézbesítési lehetőségek",
  standardDelivery: "Normál kiszállítás",
  premiumDelivery: "Prémium kiszállítás",
  deliveryEntries: "Szállítandó tételek"
};
var checkoutReview$9 = {
  review: "Áttekintés",
  reviewOrder: "Rendelés áttekintése",
  orderItems: "Rendelésben szereplő tételek",
  confirmThatRead: "Igazolom, hogy elolvastam és elfogadom a következőt:",
  placeOrder: "Rendelés leadása",
  termsAndConditions: "Használati feltételek",
  editDeliveryAddressDetails: "A kézbesítési cím adatainak szerkesztése, megnyitja a Kézbesítési cím oldalt",
  editBillingDetails: "A számlázási cím szerkesztése, megnyitja a Fizetési adatok oldalt",
  editPaymentDetails: "A fizetési adatok szerkesztése, megnyitja a Fizetési adatok oldalt",
  editPaymentType: "A fizetési mód szerkesztése, megnyitja a Fizetési mód oldalt",
  editDeliveryMode: "A kézbesítési mód szerkesztése, megnyitja a Kézbesítési mód oldalt",
  orderInProcess: "A rendelés folyamatban van. Kérjük, várjon."
};
var checkoutOrderConfirmation$9 = {
  confirmationOfOrder: "Rendelés megerősítése:",
  thankYou: "Köszönjük a rendelést!",
  invoiceHasBeenSentByEmail: "A számlát elküldtük e-mailben. Hamarosan megkapja.",
  orderItems: "Rendelésben szereplő tételek",
  orderPlacedSuccessfully: "Rendelés sikeresen leadva",
  createAccount: "Szeretne létrehozni egy fiókot?",
  createAccountForNext: "Egy <{{email}}>-fiók létrehozásával gyorsabban leadhatja rendelését a következő látogatáskor."
};
var checkout$j = {
  checkout: checkout$i,
  checkoutProgress: checkoutProgress$9,
  checkoutAddress: checkoutAddress$9,
  checkoutMode: checkoutMode$9,
  checkoutReview: checkoutReview$9,
  checkoutOrderConfirmation: checkoutOrderConfirmation$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  checkout: checkout$j
};
var checkout$g = {
  backToCart: "Kembali ke keranjang"
};
var checkoutProgress$8 = {
  label: "Kemajuan Proses Pembayaran",
  deliveryAddress: "Alamat Pengiriman",
  deliveryMode: "Mode Pengiriman",
  paymentDetails: "Pembayaran",
  reviewOrder: "Peninjauan",
  state: {
    completed: "{{step}}, Selesai",
    selected: "{{step}}, Dipilih",
    disabled: "{{step}}, Nonaktif"
  }
};
var checkoutAddress$8 = {
  shippingAddress: "Alamat Pengiriman",
  selectYourDeliveryAddress: "Pilih Alamat Pengiriman Anda",
  defaultDeliveryAddress: "Alamat Pengiriman Default",
  addNewAddress: "Tambah Alamat Baru",
  shipToThisAddress: "Kirim ke alamat ini",
  deliveryAddressSelected: "Alamat pengiriman dipilih"
};
var checkoutMode$8 = {
  deliveryMethod: "Metode Pengiriman",
  deliveryOptions: "Opsi Pengiriman",
  standardDelivery: "Pengiriman Standar",
  premiumDelivery: "Pengiriman Premium",
  deliveryEntries: "Item yang akan Dikirim"
};
var checkoutReview$8 = {
  review: "Peninjauan",
  reviewOrder: "Tinjau Pesanan",
  orderItems: "Item yang Dipesan",
  confirmThatRead: "Saya mengonfirmasikan bahwa saya telah membaca dan menyetujui",
  placeOrder: "Lakukan Pemesanan",
  termsAndConditions: "Syarat & Ketentuan",
  editDeliveryAddressDetails: "Mengedit rincian alamat pengiriman akan membuka halaman Alamat Pengiriman",
  editBillingDetails: "Mengedit alamat penagihan akan membuka halaman Rincian Pembayaran",
  editPaymentDetails: "Mengedit rincian pembayaran akan membuka halaman Rincian Pembayaran",
  editPaymentType: "Mengedit metode pembayaran akan membuka halaman Metode Pembayaran",
  editDeliveryMode: "Mengedit mode pengiriman akan membuka halaman Mode Pengiriman",
  orderInProcess: "Pesanan sedang diproses. Harap tunggu."
};
var checkoutOrderConfirmation$8 = {
  confirmationOfOrder: "Konfirmasi Pesanan:",
  thankYou: "Terima kasih atas pesanan Anda!",
  invoiceHasBeenSentByEmail: "Tagihan telah dikirim melalui email. Anda akan segera menerimanya.",
  orderItems: "Item yang Dipesan",
  orderPlacedSuccessfully: "Pemesanan berhasil dilakukan",
  createAccount: "Buat akun?",
  createAccountForNext: "Buat akun untuk <{{email}}> agar mempercepat proses pembayaran dalam kunjungan Anda yang berikutnya."
};
var checkout$h = {
  checkout: checkout$g,
  checkoutProgress: checkoutProgress$8,
  checkoutAddress: checkoutAddress$8,
  checkoutMode: checkoutMode$8,
  checkoutReview: checkoutReview$8,
  checkoutOrderConfirmation: checkoutOrderConfirmation$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  checkout: checkout$h
};
var checkout$e = {
  backToCart: "Torna al carrello acquisti"
};
var checkoutProgress$7 = {
  label: "Avanzamento del check-out",
  deliveryAddress: "Indirizzo di spedizione",
  deliveryMode: "Modalità di consegna",
  paymentDetails: "Pagamento",
  reviewOrder: "Recensione",
  state: {
    completed: "{{step}}, completati",
    selected: "{{step}}, selezionati",
    disabled: "{{step}}, disattivati"
  }
};
var checkoutAddress$7 = {
  shippingAddress: "Indirizzo di spedizione",
  selectYourDeliveryAddress: "Seleziona l'indirizzo di consegna",
  defaultDeliveryAddress: "Indirizzo di consegna predefinito",
  addNewAddress: "Aggiungi nuovo indirizzo",
  shipToThisAddress: "Spedisci a questo indirizzo",
  deliveryAddressSelected: "Indirizzo di consegna selezionato"
};
var checkoutMode$7 = {
  deliveryMethod: "Metodo di consegna",
  deliveryOptions: "Opzioni di consegna",
  standardDelivery: "Consegna standard",
  premiumDelivery: "Consegna premium",
  deliveryEntries: "Articoli da spedire"
};
var checkoutReview$7 = {
  review: "Recensione",
  reviewOrder: "Rivedi l'ordine",
  orderItems: "Articoli dell'ordine",
  confirmThatRead: "Confermo di aver letto e accettato",
  placeOrder: "Effettua l'ordine",
  termsAndConditions: "Termini e condizioni",
  editDeliveryAddressDetails: "Modifica i dettagli dell'indirizzo di consegna, apre la pagina Indirizzo di consegna",
  editBillingDetails: "Modifica l'indirizzo di fatturazione, apre la pagina Dettagli pagamento",
  editPaymentDetails: "Modifica i dettagli del pagamento, apre la pagina Dettagli pagamento",
  editPaymentType: "Modifica il metodo di pagamento, apre la pagina Metodo di pagamento",
  editDeliveryMode: "Modifica la modalità di consegna, apre la pagina Modalità di consegna",
  orderInProcess: "L'ordine è in corso, attendere."
};
var checkoutOrderConfirmation$7 = {
  confirmationOfOrder: "Conferma dell'ordine:",
  thankYou: "Grazie dell'ordine!",
  invoiceHasBeenSentByEmail: "La fattura è stata inviata per e-mail. Verrà ricevuta a breve.",
  orderItems: "Articoli dell'ordine",
  orderPlacedSuccessfully: "Ordine effettuato correttamente",
  createAccount: "Creare un account?",
  createAccountForNext: "Creare un account per <{{email}}> per un check-out più rapido alla prossima visita."
};
var checkout$f = {
  checkout: checkout$e,
  checkoutProgress: checkoutProgress$7,
  checkoutAddress: checkoutAddress$7,
  checkoutMode: checkoutMode$7,
  checkoutReview: checkoutReview$7,
  checkoutOrderConfirmation: checkoutOrderConfirmation$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  checkout: checkout$f
};
var checkout$c = {
  backToCart: "カートに戻る"
};
var checkoutProgress$6 = {
  label: "チェックアウトの進捗",
  deliveryAddress: "配送先住所",
  deliveryMode: "配送モード",
  paymentDetails: "支払",
  reviewOrder: "レビュー",
  state: {
    completed: "完了済み、{{step}}",
    selected: "選択済み、{{step}}",
    disabled: "無効化済み、{{step}}"
  }
};
var checkoutAddress$6 = {
  shippingAddress: "配送先住所",
  selectYourDeliveryAddress: "配送先住所の選択",
  defaultDeliveryAddress: "既定の配送先住所",
  addNewAddress: "新しい住所の追加",
  shipToThisAddress: "この住所に配送",
  deliveryAddressSelected: "配送先住所が選択されました"
};
var checkoutMode$6 = {
  deliveryMethod: "配送方法",
  deliveryOptions: "配送オプション",
  standardDelivery: "標準配送",
  premiumDelivery: "特別配送",
  deliveryEntries: "配送対象アイテム"
};
var checkoutReview$6 = {
  review: "レビュー",
  reviewOrder: "注文のレビュー",
  orderItems: "注文アイテム",
  confirmThatRead: "読んで同意したことを確認します",
  placeOrder: "注文を行う",
  termsAndConditions: "利用条件",
  editDeliveryAddressDetails: "配送先住所の詳細を編集するには、配送先住所ページを開きます",
  editBillingDetails: "請求先住所を編集するには、支払詳細ページを開きます",
  editPaymentDetails: "支払詳細を編集するには、支払詳細ページを開きます",
  editPaymentType: "支払方法を編集するには、支払方法ページを開きます",
  editDeliveryMode: "配送モードを編集するには、配送方法ページを開きます",
  orderInProcess: "注文は処理中です。お待ちください。"
};
var checkoutOrderConfirmation$6 = {
  confirmationOfOrder: "注文の確認:",
  thankYou: "ご注文、ありがとうございます。",
  invoiceHasBeenSentByEmail: "請求書は電子メールで送信されます。間もなく受信される予定です。",
  orderItems: "注文アイテム",
  orderPlacedSuccessfully: "注文が正常に行われました",
  createAccount: "アカウントを作成しますか?",
  createAccountForNext: "次回の訪問時にチェックアウト時間が短縮されるように <{{email}}> のアカウントを作成します。"
};
var checkout$d = {
  checkout: checkout$c,
  checkoutProgress: checkoutProgress$6,
  checkoutAddress: checkoutAddress$6,
  checkoutMode: checkoutMode$6,
  checkoutReview: checkoutReview$6,
  checkoutOrderConfirmation: checkoutOrderConfirmation$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  checkout: checkout$d
};
var checkout$a = {
  backToCart: "장바구니로 돌아가기"
};
var checkoutProgress$5 = {
  label: "체크아웃 프로세스",
  deliveryAddress: "배송 주소",
  deliveryMode: "배송 모드",
  paymentDetails: "지불",
  reviewOrder: "검토",
  state: {
    completed: "{{step}}, 완료됨",
    selected: "{{step}}, 선택됨",
    disabled: "{{step}}, 비활성화됨"
  }
};
var checkoutAddress$5 = {
  shippingAddress: "배송 주소",
  selectYourDeliveryAddress: "배송 주소 선택",
  defaultDeliveryAddress: "기본 배송 주소",
  addNewAddress: "새 주소 추가",
  shipToThisAddress: "이 주소로 배송",
  deliveryAddressSelected: "배송 주소 선택됨"
};
var checkoutMode$5 = {
  deliveryMethod: "배송 방법",
  deliveryOptions: "배송 옵션",
  standardDelivery: "표준 배송",
  premiumDelivery: "프리미엄 배송",
  deliveryEntries: "배송할 품목"
};
var checkoutReview$5 = {
  review: "검토",
  reviewOrder: "주문 검토",
  orderItems: "주문 품목",
  confirmThatRead: "읽었으며 이에 동의함을 확인함 -",
  placeOrder: "주문",
  termsAndConditions: "사용 약관",
  editDeliveryAddressDetails: "배송 주소 세부사항 편집을 누르면 배송 주소 페이지가 열립니다.",
  editBillingDetails: "청구 주소 편집을 누르면 지불 세부사항 페이지가 열립니다.",
  editPaymentDetails: "지불 세부사항 편집을 누르면 지불 세부사항 페이지가 열립니다.",
  editPaymentType: "지불 방법 편집을 누르면 지불 방법 페이지가 열립니다.",
  editDeliveryMode: "배송 모드 편집을 누르면 배송 모드 페이지가 열립니다.",
  orderInProcess: "주문을 처리 중입니다. 기다리십시오."
};
var checkoutOrderConfirmation$5 = {
  confirmationOfOrder: "주문 확인:",
  thankYou: "주문해주셔서 감사합니다!",
  invoiceHasBeenSentByEmail: "송장을 이메일로 보냈습니다. 곧 받으실 것입니다.",
  orderItems: "주문 품목",
  orderPlacedSuccessfully: "주문이 완료되었습니다.",
  createAccount: "계정을 만드시겠습니까?",
  createAccountForNext: "다음에 방문할 때 빠른 체크아웃을 위해 <{{email}}>에 대한 계정을 만드십시오."
};
var checkout$b = {
  checkout: checkout$a,
  checkoutProgress: checkoutProgress$5,
  checkoutAddress: checkoutAddress$5,
  checkoutMode: checkoutMode$5,
  checkoutReview: checkoutReview$5,
  checkoutOrderConfirmation: checkoutOrderConfirmation$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  checkout: checkout$b
};
var checkout$8 = {
  backToCart: "Powrót do koszyka"
};
var checkoutProgress$4 = {
  label: "Postęp realizacji płatności",
  deliveryAddress: "Adres wysyłki",
  deliveryMode: "Tryb dostawy",
  paymentDetails: "Płatność",
  reviewOrder: "Recenzja",
  state: {
    completed: "{{step}}, ukończone",
    selected: "{{step}}, wybrane",
    disabled: "{{step}}, wyłączone"
  }
};
var checkoutAddress$4 = {
  shippingAddress: "Adres wysyłki",
  selectYourDeliveryAddress: "Wybierz adres dostawy",
  defaultDeliveryAddress: "Domyślny adres dostawy",
  addNewAddress: "Dodaj nowy adres",
  shipToThisAddress: "Wyślij na ten adres",
  deliveryAddressSelected: "Wybrany adres dostawy"
};
var checkoutMode$4 = {
  deliveryMethod: "Metoda dostawy",
  deliveryOptions: "Opcje dostawy",
  standardDelivery: "Dostawa standardowa",
  premiumDelivery: "Dostawa premium",
  deliveryEntries: "Pozycje do wysłania"
};
var checkoutReview$4 = {
  review: "Przejrzyj",
  reviewOrder: "Przejrzyj zamówienie",
  orderItems: "Pozycje zamówienia",
  confirmThatRead: "Potwierdzam, że zapoznałem(-am) się i akceptuję",
  placeOrder: "Złóż zamówienie",
  termsAndConditions: "Warunki",
  editDeliveryAddressDetails: "Edytuj szczegóły adresu dostawy, otwiera stronę Adres dostawy",
  editBillingDetails: "Edytuj adres rozliczeniowy, otwiera stronę Szczegóły płatności",
  editPaymentDetails: "Edytuj szczegóły płatności, otwiera stronę Szczegóły płatności",
  editPaymentType: "Edytuj metodę płatności, otwiera stronę Metoda płatności",
  editDeliveryMode: "Edytuj tryb dostawy, otwiera stronę Tryb dostawy",
  orderInProcess: "Trwa przetwarzanie zamówienia. Proszę czekać."
};
var checkoutOrderConfirmation$4 = {
  confirmationOfOrder: "Potwierdzenie zamówienia:",
  thankYou: "Dziękujemy za zamówienie!",
  invoiceHasBeenSentByEmail: "Faktura została wysłana pocztą elektroniczną. Wkrótce ją otrzymasz.",
  orderItems: "Pozycje zamówienia",
  orderPlacedSuccessfully: "Zamówienie zostało złożone",
  createAccount: "Czy utworzyć konto?",
  createAccountForNext: "Utwórz konto dla <{{email}}> w celu szybszej realizacji płatności przy następnej wizycie."
};
var checkout$9 = {
  checkout: checkout$8,
  checkoutProgress: checkoutProgress$4,
  checkoutAddress: checkoutAddress$4,
  checkoutMode: checkoutMode$4,
  checkoutReview: checkoutReview$4,
  checkoutOrderConfirmation: checkoutOrderConfirmation$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  checkout: checkout$9
};
var checkout$6 = {
  backToCart: "Voltar para o carrinho"
};
var checkoutProgress$3 = {
  label: "Progresso de check-out",
  deliveryAddress: "Endereço de entrega",
  deliveryMode: "Modo de entrega",
  paymentDetails: "Pagamento",
  reviewOrder: "Revisão",
  state: {
    completed: "{{step}}, concluído",
    selected: "{{step}}, selecionado",
    disabled: "{{step}}, desativado"
  }
};
var checkoutAddress$3 = {
  shippingAddress: "Endereço de entrega",
  selectYourDeliveryAddress: "Selecione seu endereço de entrega",
  defaultDeliveryAddress: "Endereço de entrega padrão",
  addNewAddress: "Adicionar novo endereço",
  shipToThisAddress: "Entregar para esse endereço",
  deliveryAddressSelected: "Endereço de entrega selecionado"
};
var checkoutMode$3 = {
  deliveryMethod: "Método de entrega",
  deliveryOptions: "Opções de entrega",
  standardDelivery: "Entrega padrão",
  premiumDelivery: "Entrega premium",
  deliveryEntries: "Itens a serem enviados"
};
var checkoutReview$3 = {
  review: "Revisão",
  reviewOrder: "Revisar pedido",
  orderItems: "Itens do pedido",
  confirmThatRead: "Confirmo que li e concordo com os",
  placeOrder: "Fazer pedido",
  termsAndConditions: "Termos e Condições",
  editDeliveryAddressDetails: "A edição de detalhes do endereço de entrega abre a página Endereço de entrega",
  editBillingDetails: "A edição do endereço de faturamento abre a página Detalhes de pagamento",
  editPaymentDetails: "A edição de detalhes de pagamento abre a página Detalhes de pagamento",
  editPaymentType: "A edição da forma de pagamento abre a página Forma de pagamento",
  editDeliveryMode: "A edição do modo de entrega abre a página Modo de entrega",
  orderInProcess: "O pedido está em processamento. Aguarde."
};
var checkoutOrderConfirmation$3 = {
  confirmationOfOrder: "Confirmação do pedido:",
  thankYou: "Obrigado pelo seu pedido!",
  invoiceHasBeenSentByEmail: "Uma fatura foi enviada por e-mail. Você deverá recebê-la em breve.",
  orderItems: "Itens do pedido",
  orderPlacedSuccessfully: "Pedido feito com êxito",
  createAccount: "Criar uma conta?",
  createAccountForNext: "Crie uma conta para <{{email}}> para um check-out mais rápido em sua próxima visita."
};
var checkout$7 = {
  checkout: checkout$6,
  checkoutProgress: checkoutProgress$3,
  checkoutAddress: checkoutAddress$3,
  checkoutMode: checkoutMode$3,
  checkoutReview: checkoutReview$3,
  checkoutOrderConfirmation: checkoutOrderConfirmation$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  checkout: checkout$7
};
var checkout$4 = {
  backToCart: "Назад в корзину"
};
var checkoutProgress$2 = {
  label: "Ход оформления",
  deliveryAddress: "Адрес доставки",
  deliveryMode: "Способ доставки",
  paymentDetails: "Оплата",
  reviewOrder: "Проверка",
  state: {
    completed: "{{step}}, завершено",
    selected: "{{step}}, выбрано",
    disabled: "{{step}}, деактивировано"
  }
};
var checkoutAddress$2 = {
  shippingAddress: "Адрес доставки",
  selectYourDeliveryAddress: "Выберите адрес доставки",
  defaultDeliveryAddress: "Адрес доставки по умолчанию",
  addNewAddress: "Добавить новый адрес",
  shipToThisAddress: "Доставить на этот адрес",
  deliveryAddressSelected: "Адрес доставки выбран"
};
var checkoutMode$2 = {
  deliveryMethod: "Метод доставки",
  deliveryOptions: "Опции доставки",
  standardDelivery: "Стандартная доставка",
  premiumDelivery: "Премиальная доставка",
  deliveryEntries: "Позиции для отгрузки"
};
var checkoutReview$2 = {
  review: "Проверка",
  reviewOrder: "Проверить заказ",
  orderItems: "Позиции заказа",
  confirmThatRead: "Я подтверждаю, что прочитал(а) и принимаю",
  placeOrder: "Разместить заказ",
  termsAndConditions: "Условия",
  editDeliveryAddressDetails: "Редактировать сведения об адресе доставки, откроется страница \"Адрес доставки\"",
  editBillingDetails: "Редактировать адрес выставления счета, откроется страница \"Платежные реквизиты\"",
  editPaymentDetails: "Редактировать платежные реквизиты, откроется страница \"Платежные реквизиты\"",
  editPaymentType: "Редактировать метод оплаты, откроется страница \"Метод оплаты\"",
  editDeliveryMode: "Редактировать способ доставки, открывается страница \"Способ доставки\"",
  orderInProcess: "Заказ обрабатывается. Подождите."
};
var checkoutOrderConfirmation$2 = {
  confirmationOfOrder: "Подтверждение заказа:",
  thankYou: "Спасибо за заказ!",
  invoiceHasBeenSentByEmail: "Счет отправлен по электронной почте. Скоро вы его получите.",
  orderItems: "Позиции заказа",
  orderPlacedSuccessfully: "Заказ успешно размещен",
  createAccount: "Создать учетную запись?",
  createAccountForNext: "Создайте учетную запись для <{{email}}>, чтобы быстро оформить заказ в следующий раз."
};
var checkout$5 = {
  checkout: checkout$4,
  checkoutProgress: checkoutProgress$2,
  checkoutAddress: checkoutAddress$2,
  checkoutMode: checkoutMode$2,
  checkoutReview: checkoutReview$2,
  checkoutOrderConfirmation: checkoutOrderConfirmation$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  checkout: checkout$5
};
var checkout$2 = {
  backToCart: "返回到购物车"
};
var checkoutProgress$1 = {
  label: "结账进度",
  deliveryAddress: "发货地址",
  deliveryMode: "送货方式",
  paymentDetails: "付款",
  reviewOrder: "评价",
  state: {
    completed: "{{step}}，已完成",
    selected: "{{step}}，已选择",
    disabled: "{{step}}，已禁用"
  }
};
var checkoutAddress$1 = {
  shippingAddress: "发货地址",
  selectYourDeliveryAddress: "选择送货地址",
  defaultDeliveryAddress: "默认送货地址",
  addNewAddress: "添加新地址",
  shipToThisAddress: "送货地址",
  deliveryAddressSelected: "已选择送货地址"
};
var checkoutMode$1 = {
  deliveryMethod: "送货方式",
  deliveryOptions: "送货选项",
  standardDelivery: "标准送货",
  premiumDelivery: "高级送货",
  deliveryEntries: "待发货项目"
};
var checkoutReview$1 = {
  review: "评价",
  reviewOrder: "评价订单",
  orderItems: "订单项目",
  confirmThatRead: "我确认我已阅读并同意",
  placeOrder: "下订单",
  termsAndConditions: "条款和条件",
  editDeliveryAddressDetails: "编辑送货地址详细信息，打开“送货地址”页面",
  editBillingDetails: "编辑账单地址，打开“付款详细信息”页面",
  editPaymentDetails: "编辑付款详细信息，打开“付款详细信息”页面",
  editPaymentType: "编辑付款方式，打开“付款方式”页面",
  editDeliveryMode: "编辑送货方式，打开“送货方式”页面",
  orderInProcess: "订单正在处理中。请稍候。"
};
var checkoutOrderConfirmation$1 = {
  confirmationOfOrder: "确认订单。",
  thankYou: "感谢您的订购！",
  invoiceHasBeenSentByEmail: "发票已通过电子邮件发送。您很快就会收到。",
  orderItems: "订单项目",
  orderPlacedSuccessfully: "已成功下达订单",
  createAccount: "创建账户？",
  createAccountForNext: "为<{{email}}>创建账户，以便您下次访问时更快地结账。"
};
var checkout$3 = {
  checkout: checkout$2,
  checkoutProgress: checkoutProgress$1,
  checkoutAddress: checkoutAddress$1,
  checkoutMode: checkoutMode$1,
  checkoutReview: checkoutReview$1,
  checkoutOrderConfirmation: checkoutOrderConfirmation$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  checkout: checkout$3
};
var checkout = {
  backToCart: "返回購物車"
};
var checkoutProgress = {
  label: "結帳進度",
  deliveryAddress: "出貨地址",
  deliveryMode: "交貨模式",
  paymentDetails: "付款",
  reviewOrder: "評論",
  state: {
    completed: "{{step}} 已完成",
    selected: "{{step}} 已選擇",
    disabled: "{{step}} 已停用"
  }
};
var checkoutAddress = {
  shippingAddress: "出貨地址",
  selectYourDeliveryAddress: "選擇交貨地址",
  defaultDeliveryAddress: "預設交貨地址",
  addNewAddress: "新增地址",
  shipToThisAddress: "出貨至此地址",
  deliveryAddressSelected: "已選擇交貨地址"
};
var checkoutMode = {
  deliveryMethod: "交貨方法",
  deliveryOptions: "交貨選項",
  standardDelivery: "標準交貨",
  premiumDelivery: "頂級交貨",
  deliveryEntries: "待運送項目"
};
var checkoutReview = {
  review: "評論",
  reviewOrder: "評論訂單",
  orderItems: "訂單項目",
  confirmThatRead: "我確認我已閱讀並同意",
  placeOrder: "下單",
  termsAndConditions: "條款和條件",
  editDeliveryAddressDetails: "編輯交貨地址明細，開啟交貨地址頁面",
  editBillingDetails: "編輯帳單寄送地址，開啟付款明細頁面",
  editPaymentDetails: "編輯付款明細，開啟付款明細頁面",
  editPaymentType: "編輯付款方法開啟付款方法頁面",
  editDeliveryMode: "編輯交貨模式，開啟交貨模式頁面",
  orderInProcess: "訂單處理中，請稍候。"
};
var checkoutOrderConfirmation = {
  confirmationOfOrder: "訂單確認：",
  thankYou: "感謝您的訂購！",
  invoiceHasBeenSentByEmail: "發票已透過電子郵件傳送，您將於稍後收到。",
  orderItems: "訂單項目",
  orderPlacedSuccessfully: "已成功下單",
  createAccount: "是否建立帳戶？",
  createAccountForNext: "為 <{{email}}> 建立帳戶，下次造訪時即可更快速結帳。"
};
var checkout$1 = {
  checkout: checkout,
  checkoutProgress: checkoutProgress,
  checkoutAddress: checkoutAddress,
  checkoutMode: checkoutMode,
  checkoutReview: checkoutReview,
  checkoutOrderConfirmation: checkoutOrderConfirmation
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  checkout: checkout$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const checkoutTranslationChunksConfig = {
  checkout: ['checkout', 'checkoutProgress', 'checkoutAddress', 'checkoutMode', 'checkoutReview', 'checkoutOrderConfirmation']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: checkoutTranslations
 *             +   resources: { en: checkoutTranslationsEn }
 *               }
 *             ```
 */
const checkoutTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { checkoutTranslationChunksConfig, checkoutTranslations, cs as checkoutTranslationsCs, de as checkoutTranslationsDe, en as checkoutTranslationsEn, es as checkoutTranslationsEs, es_CO as checkoutTranslationsEs_CO, fr as checkoutTranslationsFr, hi as checkoutTranslationsHi, hu as checkoutTranslationsHu, id as checkoutTranslationsId, it as checkoutTranslationsIt, ja as checkoutTranslationsJa, ko as checkoutTranslationsKo, pl as checkoutTranslationsPl, pt as checkoutTranslationsPt, ru as checkoutTranslationsRu, zh as checkoutTranslationsZh, zh_TW as checkoutTranslationsZh_TW };
