import { Component, Input, OnInit } from '@angular/core';
import { CustomerAccountService } from '../../../../../core/customer-account/customer-account.service';
import { FormGroup, FormControl } from '@angular/forms';
import { LaunchDialogService } from '@spartacus/storefront';
import { DS_DIALOG } from '../../../../../core/dialog/dialog.config';
import { take } from 'rxjs';

@Component({
  standalone: false,
  selector: 'ds-quote-cart-item',
  templateUrl: './quote-cart-item.component.html',
  styleUrls: ['./quote-cart-item.component.scss'],
})
export class QuoteCartItemComponent implements OnInit {
  @Input() checkAll;
  @Input() entryNum;
  @Input() item;
  breakUpMenu: boolean;
  breakUp: boolean;
  productLine: string;
  showAvailabilityLoader: boolean = false;
  productOpen: boolean;
  showCheckAvalabilityAction: boolean;
  quantityForm = new FormGroup({
    quantity: new FormControl(1),
  });
  maxQuantity: number = 9999;
  quantity: number = 1;
  disableCheckAvailablity: boolean = true;
  minDate;
  defaultDate;
  additionalDetail: boolean = true;
  constructor(
    private customerAccService: CustomerAccountService,
    private launchDialogService: LaunchDialogService
  ) {}

  ngOnInit(): void {
    this.customerAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      // this.contactUsUrl = `/${productLine}/contactus`;
      // if (this.productLine) {
      //   this.leadTimeTooltipVisibilityFlag = this.showLeadtTimeTooltip();
      // }
    });
  }
  // setDefaultDate() {
  //   if (this.cart) {
  //     if (
  //       this.item?.productType === this.productType.Typ3 &&
  //       this.cart.longestLeadTimeFilm !== undefined
  //     ) {
  //       this.defaultDate = new Date(this.cart.longestLeadTimeFilm);
  //       this.minDate = new Date(this.cart.longestLeadTimeFilm);
  //     } else if (this.cart.longestLeadTime !== undefined) {
  //       this.defaultDate = new Date(this.cart.longestLeadTime);
  //       this.minDate = new Date(this.cart.longestLeadTime);
  //     }
  //   }
  // }
  checkEntry(ev) {}

  gtmSelectItemEvent(item) {}

  togglePriceBreakup() {
    this.breakUpMenu = !this.breakUpMenu;
  }
  closeMenu() {}
  checkAvailability() {}
  quantityAdded(ev) {}
  increaseQuantity(ev) {}
  decreaseQuantity(ev) {}
  checkStockAvailablity(it) {
    return true;
  }
  getInt(t) {
    return true;
  }
  getShipDate(it) {}
  onDateChange(ev) {}
  additionalDetailToggle() {
    this.additionalDetail = !this.additionalDetail;
  }
  openDeleteDialog(entryNum) {
    const componentData = {
      cart: '',
      userType: '',
      cartId: 0,
    };
    const deleteCartModal = this.launchDialogService.openDialog(
      DS_DIALOG.CART_DELETE_DIALOG,
      undefined,
      undefined,
      componentData
    );

    if (deleteCartModal) {
      deleteCartModal.pipe(take(1)).subscribe((value) => {
        if (value == 'delete') {
          // this.deleteCartItem(entryNum, this.cart);
        }
      });

      this.launchDialogService.dialogClose.pipe(take(2)).subscribe((value) => {
        if (value == 'delete') {
          // this.deleteCartItem(entryNum, this.cart);
        }
      });
    }
  }
}
