<div class="container">
  <div class="reg-conf-template">
    <ng-container *ngIf="response$ | async as accountMsg; else loading">
      <ng-container *ngIf="pageStatus == 'confirm-email'">
        <ng-container
          *ngIf="
            accountMsg == registerStatus.SUCCESS;
            else activationEmailFailure
          "
        >
          <div class="email-title-header">
            <span class="material-icons-outlined success"> check_circle </span>
            <h2>Request confirmed</h2>
          </div>
          <div class="email-body-content">
            Your user name has been accepted and is set up. We will now proceed
            to setup your application account. <br />
            You will be notified by email for further updates.
          </div>
        </ng-container>
        <ng-template #activationEmailFailure>
          <ng-container
            *ngIf="
              accountMsg == registerStatus.FAILURE;
              else activationEmailActive
            "
          >
            <div class="email-title-header">
              <span class="material-icons-outlined failure"> cancel </span>
              <h2>User activation failed</h2>
            </div>
            <div class="email-body-content">
              User Name was not successfully activated. <br />
              Please <a routerLink="/resend-email/{{ email }}">click here</a> to
              generate new Activation Code
            </div>
          </ng-container>
        </ng-template>
        <ng-template #activationEmailActive>
          <ng-container
            *ngIf="
              accountMsg == registerStatus.ACTIVE;
              else activationEmailError
            "
          >
            <div class="email-title-header">
              <span class="material-icons-outlined info"> info </span>
              <h2>User is active</h2>
            </div>
            <div class="email-body-content">
              User Name is already active. We will now proceed to set up your
              Store Account. <br />
              You will be notified by email for further updates.
            </div>
          </ng-container>
        </ng-template>
        <ng-template #activationEmailError>
          <ng-container
            *ngIf="
              accountMsg == registerStatus.ACTIVE;
              else activationEmailError
            "
          >
            <div class="email-title-header">
              <span class="material-icons-outlined warning">
                help_outline
              </span>
              <h2>Something went wrong</h2>
            </div>
            <div class="email-body-content">
              Something went wrong while performing this action. <br />
              Please reach out to our
              <a href="mailTo:ogmchybris.support@bakerhughes.com"
                >support team</a
              >
              for further assistance or try again later.
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="pageStatus == 'resend-email'">
        <ng-container
          *ngIf="accountMsg == registerStatus.SUCCESS; else resendEmailFailure"
        >
          <div class="email-title-header">
            <span class="material-icons-outlined success"> check_circle </span>
            <h2>Email sent</h2>
          </div>
          <div class="email-body-content">
            A new confirmation email is sent to you. <br />
            Please check your email and follow the instructions to activate your
            account.
          </div>
        </ng-container>
        <ng-template #resendEmailFailure>
          <div class="email-title-header">
            <span class="material-icons-outlined warning"> help_outline </span>
            <h2>Something went wrong</h2>
          </div>
          <div class="email-body-content">
            Something went wrong while performing this action. <br />
            Please reach out to our
            <a href="mailTo:ogmchybris.support@bakerhughes.com">support team</a>
            for further assistance or try again later.
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="pageStatus == 'cancel-activation'">
        <ng-container
          *ngIf="accountMsg == registerStatus.SUCCESS; else cancellationFailure"
        >
          <div class="email-title-header">
            <span class="material-icons-outlined info"> check_circle </span>
            <h2>Request cancelled</h2>
          </div>
          <div class="email-body-content">
            Your registration request is cancelled. <br />
          </div>
        </ng-container>
        <ng-template #cancellationFailure>
          <ng-container
            *ngIf="
              accountMsg == registerStatus.FAILURE;
              else cancellationActive
            "
          >
            <div class="email-title-header">
              <span class="material-icons-outlined failure"> cancel </span>
              <h2>Registration cancellation failed</h2>
            </div>
            <div class="email-body-content">
              Something went wrong while performing this action. <br />
              Please reach out to our
              <a href="mailTo:ogmchybris.support@bakerhughes.com"
                >support team</a
              >
              for further assistance or try again later.
            </div>
          </ng-container>
        </ng-template>
        <ng-template #cancellationActive>
          <ng-container
            *ngIf="accountMsg == registerStatus.ACTIVE; else cancellationError"
          >
            <div class="email-title-header">
              <span class="material-icons-outlined info"> info </span>
              <h2>User is active</h2>
            </div>
            <div class="email-body-content">
              User Name is already active. <br />
              We will now proceed to set up your Store Account.
            </div>
          </ng-container>
        </ng-template>
        <ng-template #cancellationError> Error </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
