import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'ds-quote-cart-actions',
  templateUrl: './quote-cart-actions.component.html',
  styleUrl: './quote-cart-actions.component.scss',
})
export class QuoteCartActionsComponent {
  isPartialShipment: boolean = false;
  isShipmentSelectionDisabled: boolean = false;
  minDate;
  defaultDate;
  constructor() {}

  handleChange(ev) {}

  applyToAll() {}

  dateChange(ev) {
    console.log(ev);
  }
}
