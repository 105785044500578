var pdfInvoices$w = {
  featureNotEnabled: "Nelze získat faktury. Zkontrolujte, zda jsou povoleny funkce SAP Invoice a API pro faktury.",
  invoicesLoadingError: "Něco se pokazilo. Nelze zobrazit faktury k objednávce.",
  invoicesTable: {
    header: "Faktury",
    label: "Tabulka faktur",
    invoiceId: "Číslo faktury",
    createdAt: "Datum",
    netAmount: "Částka netto",
    totalAmount: "Celková částka",
    attachment: "Stáhnout fakturu",
    download: "Stáhnout",
    attachmentDescription: "Stáhnout PDF pro fakturu {{id}}.",
    noneFound: "Nebyly nalezeny žádné faktury."
  },
  downloadPDFError: "Něco se pokazilo. Nelze získat fakturu v PDF.",
  sortBy: "Řadit podle",
  sortInvoices: "Řadit faktury",
  sorts: {
    byCreatedAtAsc: "Datum vzestupně",
    byCreatedAtDesc: "Datum sestupně",
    byInvoiceIdAsc: "Číslo faktury vzestupně",
    byInvoiceIdDesc: "Číslo faktury sestupně",
    byNetAmountAsc: "Částka netto vzestupně",
    byNetAmountDesc: "Částka netto sestupně",
    byTotalAmountAsc: "Celková částka vzestupně",
    byTotalAmountDesc: "Celková částka sestupně"
  }
};
var pdfInvoices$x = {
  pdfInvoices: pdfInvoices$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  pdfInvoices: pdfInvoices$x
};
var pdfInvoices$u = {
  featureNotEnabled: "Rechnungen konnten nicht abgerufen werden. Stellen Sie sicher, dass SAP Invoice und das API für Rechnungen aktiviert sind.",
  invoicesLoadingError: "Es ist ein Fehler aufgetreten. Für die Bestellung können keine Rechnungen angezeigt werden",
  invoicesTable: {
    header: "Rechnungen",
    label: "Rechnungstabelle",
    invoiceId: "Rechnungsnummer",
    createdAt: "Datum",
    netAmount: "Nettobetrag",
    totalAmount: "Gesamtbetrag",
    attachment: "Rechnung herunterladen.",
    download: "Herunterladen",
    attachmentDescription: "Laden Sie eine PDF-Datei für die Rechnung {{id}} herunter.",
    noneFound: "Keine Rechnungen gefunden"
  },
  downloadPDFError: "Es ist ein Fehler aufgetreten. Die PDF-Datei für die Rechnung kann nicht abgerufen werden",
  sortBy: "Sortieren nach",
  sortInvoices: "Rechnungen sortieren",
  sorts: {
    byCreatedAtAsc: "Datum aufsteigend",
    byCreatedAtDesc: "Datum absteigend",
    byInvoiceIdAsc: "Rechnungsnummer aufsteigend",
    byInvoiceIdDesc: "Rechnungsnummer absteigend",
    byNetAmountAsc: "Nettobetrag aufsteigend",
    byNetAmountDesc: "Nettobetrag absteigend",
    byTotalAmountAsc: "Gesamtbetrag aufsteigend",
    byTotalAmountDesc: "Nettobetrag absteigend"
  }
};
var pdfInvoices$v = {
  pdfInvoices: pdfInvoices$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  pdfInvoices: pdfInvoices$v
};
var pdfInvoices$s = {
  featureNotEnabled: "Unable to obtain invoices, ensure that SAP Invoice and API for Invoices are enabled.",
  invoicesLoadingError: "Something went wrong. Unable to display invoices for the order",
  invoicesTable: {
    header: "Invoices",
    label: "Invoices Table",
    invoiceId: "Invoice #",
    createdAt: "Date",
    netAmount: "Net Amount",
    totalAmount: "Total Amount",
    attachment: "Download Invoice",
    download: "Download",
    attachmentDescription: "Download PDF for invoice {{id}}.",
    noneFound: "No Invoices Found"
  },
  downloadPDFError: "Something went wrong. Unable to obtain the invoice PDF.",
  sortBy: "Sort by",
  sortInvoices: "Sort invoices",
  sorts: {
    byCreatedAtAsc: "Date Ascending",
    byCreatedAtDesc: "Date Descending",
    byInvoiceIdAsc: "Invoice Number Ascending",
    byInvoiceIdDesc: "Invoice Number Descending",
    byNetAmountAsc: "Net Amount Ascending",
    byNetAmountDesc: "Net Amount Descending",
    byTotalAmountAsc: "Total Amount Ascending",
    byTotalAmountDesc: "Total Amount Descending"
  }
};
var pdfInvoices$t = {
  pdfInvoices: pdfInvoices$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  pdfInvoices: pdfInvoices$t
};
var pdfInvoices$q = {
  featureNotEnabled: "No se puede obtener facturas. Asegúrese de que SAP Invoice y la API para facturas estén habilitadas.",
  invoicesLoadingError: "Se produjo un problema. No se puede mostrar las facturas para el pedido",
  invoicesTable: {
    header: "Facturas",
    label: "Tabla de facturas",
    invoiceId: "N.º de factura",
    createdAt: "Fecha",
    netAmount: "Importe neto",
    totalAmount: "Importe total",
    attachment: "Descargar factura",
    download: "Descargar",
    attachmentDescription: "Descargar PDF para factura {{id}}.",
    noneFound: "No se han encontrado facturas"
  },
  downloadPDFError: "Se produjo un problema. No se puede obtener el PDF de la factura.",
  sortBy: "Ordenar por",
  sortInvoices: "Ordenar facturas",
  sorts: {
    byCreatedAtAsc: "Fecha ascendente",
    byCreatedAtDesc: "Fecha descendente",
    byInvoiceIdAsc: "Número de factura ascendente",
    byInvoiceIdDesc: "Número de factura descendente",
    byNetAmountAsc: "Importe neto ascendente",
    byNetAmountDesc: "Importe neto descendente",
    byTotalAmountAsc: "Importe total ascendente",
    byTotalAmountDesc: "Importe total descendente"
  }
};
var pdfInvoices$r = {
  pdfInvoices: pdfInvoices$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  pdfInvoices: pdfInvoices$r
};
var pdfInvoices$o = {
  featureNotEnabled: "No se puede obtener facturas. Asegúrese de que SAP Invoice y la API para facturas estén activadas.",
  invoicesLoadingError: "Se produjo un problema. No se puede mostrar las facturas para el pedido",
  invoicesTable: {
    header: "Facturas",
    label: "Tabla de facturas",
    invoiceId: "N.º de factura",
    createdAt: "Fecha",
    netAmount: "Importe neto",
    totalAmount: "Importe total",
    attachment: "Descargar factura",
    download: "Descargar",
    attachmentDescription: "Descargar PDF para factura {{id}}.",
    noneFound: "No se encontró ninguna factura"
  },
  downloadPDFError: "Se produjo un problema. No se puede obtener el PDF de la factura.",
  sortBy: "Clasificar por",
  sortInvoices: "Clasificar facturas",
  sorts: {
    byCreatedAtAsc: "Fecha ascendente",
    byCreatedAtDesc: "Fecha descendente",
    byInvoiceIdAsc: "Número de factura ascendente",
    byInvoiceIdDesc: "Número de factura descendente",
    byNetAmountAsc: "Importe neto ascendente",
    byNetAmountDesc: "Importe neto descendente",
    byTotalAmountAsc: "Importe total ascendente",
    byTotalAmountDesc: "Importe total descendente"
  }
};
var pdfInvoices$p = {
  pdfInvoices: pdfInvoices$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  pdfInvoices: pdfInvoices$p
};
var pdfInvoices$m = {
  featureNotEnabled: "Impossible d'obtenir des factures, assurez-vous que SAP Invoice et API for Invoices sont activés.",
  invoicesLoadingError: "Une erreur s'est produite. Impossible d'afficher les factures pour la commande.",
  invoicesTable: {
    header: "Factures",
    label: "Tableau des factures",
    invoiceId: "Numéro de facture",
    createdAt: "Date",
    netAmount: "Montant net",
    totalAmount: "Montant total",
    attachment: "Télécharger la facture",
    download: "Télécharger",
    attachmentDescription: "Téléchargez le PDF pour la facture {{id}}.",
    noneFound: "Aucune facture trouvée"
  },
  downloadPDFError: "Une erreur s'est produite. Impossible d'obtenir le PDF de la facture.",
  sortBy: "Trier par",
  sortInvoices: "Trier les factures",
  sorts: {
    byCreatedAtAsc: "Date (croissant)",
    byCreatedAtDesc: "Date (décroissant)",
    byInvoiceIdAsc: "Numéro de facture (croissant)",
    byInvoiceIdDesc: "Numéro de facture (décroissant)",
    byNetAmountAsc: "Montant net (croissant)",
    byNetAmountDesc: "Montant net (décroissant)",
    byTotalAmountAsc: "Montant total (croissant)",
    byTotalAmountDesc: "Montant total (décroissant)"
  }
};
var pdfInvoices$n = {
  pdfInvoices: pdfInvoices$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  pdfInvoices: pdfInvoices$n
};
var pdfInvoices$k = {
  featureNotEnabled: "चालान प्राप्त करने में असमर्थ, सुनिश्चित करें कि चालानों के लिए SAP चालान और API सक्षम हैं.",
  invoicesLoadingError: "कुछ गलत हुआ है. ऑर्डर के लिे चालान प्रदर्शित करने में असमर्थ",
  invoicesTable: {
    header: "चालान",
    label: "चालान तालिका",
    invoiceId: "चालान #",
    createdAt: "दिनांक",
    netAmount: "शुद्ध राशि",
    totalAmount: "कुल राशि",
    attachment: "चालान डाउनलोड करें",
    download: "डाउनलोड करें",
    attachmentDescription: "चालान {{id}} के लिए PDF डाउनलोड करें.",
    noneFound: "कोई चालान नहीं मिला"
  },
  downloadPDFError: "कुछ गलत हुआ है. चालान PDF प्राप्त करने में असमर्थ.",
  sortBy: "इसके द्वारा क्रमित",
  sortInvoices: "चालान क्रमित करें",
  sorts: {
    byCreatedAtAsc: "दिनांक आरोही",
    byCreatedAtDesc: "दिनांक अवरोही",
    byInvoiceIdAsc: "चालान संख्या आरोही",
    byInvoiceIdDesc: "चालान संख्या अवरोही",
    byNetAmountAsc: "शुद्ध राशि आरोही",
    byNetAmountDesc: "शुद्ध राशि अवरोही",
    byTotalAmountAsc: "कुल राशि आरोही",
    byTotalAmountDesc: "कुल राशि अवरोही"
  }
};
var pdfInvoices$l = {
  pdfInvoices: pdfInvoices$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  pdfInvoices: pdfInvoices$l
};
var pdfInvoices$i = {
  featureNotEnabled: "Nem lehet lekérni a számlákat. Győződjön meg róla, hogy engedélyezve van az SAP Invoice és a számlázási API.",
  invoicesLoadingError: "Hiba történt. Nem lehet megjeleníteni a rendelés számláit.",
  invoicesTable: {
    header: "Számlák",
    label: "Számlák tábla",
    invoiceId: "Számla #",
    createdAt: "Dátum",
    netAmount: "Nettó összeg",
    totalAmount: "Teljes összeg",
    attachment: "Számla letöltése",
    download: "Letöltés",
    attachmentDescription: "Számla ({{id}}) PDF-jének letöltése.",
    noneFound: "Nem találhatók számlák"
  },
  downloadPDFError: "Hiba történt. Nem lehet lekérni a számla PDF-jét.",
  sortBy: "Rendezés alapja",
  sortInvoices: "Számlák rendezése",
  sorts: {
    byCreatedAtAsc: "Dátum szerint növekvő",
    byCreatedAtDesc: "Dátum szerint csökkenő",
    byInvoiceIdAsc: "Számlaszám szerint növekvő",
    byInvoiceIdDesc: "Számlaszám szerint csökkenő",
    byNetAmountAsc: "Nettó összeg szerint növekvő",
    byNetAmountDesc: "Nettó összeg szerint csökkenő",
    byTotalAmountAsc: "Teljes összeg szerint növekvő",
    byTotalAmountDesc: "Teljes összeg szerint csökkenő"
  }
};
var pdfInvoices$j = {
  pdfInvoices: pdfInvoices$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  pdfInvoices: pdfInvoices$j
};
var pdfInvoices$g = {
  featureNotEnabled: "Tidak dapat mengakses tagihan, pastikan SAP Invoice dan API untuk Tagihan telah diaktifkan.",
  invoicesLoadingError: "Terjadi kesalahan. Tidak dapat menampilkan tagihan pesanan.",
  invoicesTable: {
    header: "Tagihan",
    label: "Tabel Tagihan",
    invoiceId: "Tagihan #",
    createdAt: "Tanggal",
    netAmount: "Jumlah Bersih",
    totalAmount: "Jumlah Total",
    attachment: "Unduh Tagihan",
    download: "Unduh",
    attachmentDescription: "Unduh tagihan {{id}} dalam format PDF.",
    noneFound: "Tagihan Tidak Ditemukan"
  },
  downloadPDFError: "Terjadi kesalahan. Tidak dapat mengakses dokumen tagihan dalam format PDF.",
  sortBy: "Urutkan berdasarkan",
  sortInvoices: "Urutkan tagihan",
  sorts: {
    byCreatedAtAsc: "Tanggal Dengan Urutan Naik",
    byCreatedAtDesc: "Tanggal Dengan Urutan Turun",
    byInvoiceIdAsc: "Nomor Tagihan Dengan Urutan Naik",
    byInvoiceIdDesc: "Nomor Tagihan Dengan Urutan Turun",
    byNetAmountAsc: "Jumlah Bersih Dengan Urutan Naik",
    byNetAmountDesc: "Jumlah Bersih Dengan Urutan Turun",
    byTotalAmountAsc: "Jumlah Total Dengan Urutan Naik",
    byTotalAmountDesc: "Jumlah Total Dengan Urutan Turun"
  }
};
var pdfInvoices$h = {
  pdfInvoices: pdfInvoices$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  pdfInvoices: pdfInvoices$h
};
var pdfInvoices$e = {
  featureNotEnabled: "Impossibile ottenere le fatture; assicurarsi che SAP Invoice e le API per Invoices siano attivati.",
  invoicesLoadingError: "Si è verificato un errore. Impossibile visualizzare le fatture per l'ordine.",
  invoicesTable: {
    header: "Fatture",
    label: "Tabella fatture",
    invoiceId: "N. fattura",
    createdAt: "Data",
    netAmount: "Importo netto",
    totalAmount: "Importo totale",
    attachment: "Scarica fattura",
    download: "Scarica",
    attachmentDescription: "Scarica PDF per la fattura {{id}}.",
    noneFound: "Nessuna fattura trovata"
  },
  downloadPDFError: "Si è verificato un errore. Impossibile ottenere il PDF della fattura.",
  sortBy: "Ordina per",
  sortInvoices: "Ordina fatture",
  sorts: {
    byCreatedAtAsc: "Data (in ordine crescente)",
    byCreatedAtDesc: "Data (in ordine decrescente)",
    byInvoiceIdAsc: "Numero fattura (in ordine crescente)",
    byInvoiceIdDesc: "Numero fattura (in ordine decrescente)",
    byNetAmountAsc: "Importo netto (in ordine crescente)",
    byNetAmountDesc: "Importo netto (in ordine decrescente)",
    byTotalAmountAsc: "Importo totale (in ordine crescente)",
    byTotalAmountDesc: "Importo totale (in ordine decrescente)"
  }
};
var pdfInvoices$f = {
  pdfInvoices: pdfInvoices$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  pdfInvoices: pdfInvoices$f
};
var pdfInvoices$c = {
  featureNotEnabled: "請求書を取得できません。SAP Invoice および請求書用の API が有効化されていることを確認してください。",
  invoicesLoadingError: "問題が発生しました。注文の請求書を表示できません",
  invoicesTable: {
    header: "請求書",
    label: "請求書テーブル",
    invoiceId: "請求書番号",
    createdAt: "日付",
    netAmount: "正味額",
    totalAmount: "合計金額",
    attachment: "請求書のダウンロード",
    download: "ダウンロード",
    attachmentDescription: "請求書 {{id}} をダウンロードします。",
    noneFound: "請求書が見つかりません。"
  },
  downloadPDFError: "問題が発生しました。請求書の PDF を取得できません。",
  sortBy: "ソート基準",
  sortInvoices: "請求書のソート",
  sorts: {
    byCreatedAtAsc: "日付 (昇順)",
    byCreatedAtDesc: "日付 (降順)",
    byInvoiceIdAsc: "請求書番号 (昇順)",
    byInvoiceIdDesc: "請求書番号 (降順)",
    byNetAmountAsc: "正味額 (昇順)",
    byNetAmountDesc: "正味額 (降順)",
    byTotalAmountAsc: "合計金額 (昇順)",
    byTotalAmountDesc: "合計金額 (降順)"
  }
};
var pdfInvoices$d = {
  pdfInvoices: pdfInvoices$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  pdfInvoices: pdfInvoices$d
};
var pdfInvoices$a = {
  featureNotEnabled: "송장을 가져올 수 없습니다. SAP 송장과 송장을 위한 API가 활성화되었는지 확인하십시오.",
  invoicesLoadingError: "문제가 발생했습니다. 주문의 송장을 표시할 수 없습니다.",
  invoicesTable: {
    header: "송장",
    label: "송장 테이블",
    invoiceId: "송장 번호",
    createdAt: "날짜",
    netAmount: "순액",
    totalAmount: "총액",
    attachment: "송장 다운로드",
    download: "다운로드",
    attachmentDescription: "송장 {{id}}의 PDF를 다운로드합니다.",
    noneFound: "송장 없음"
  },
  downloadPDFError: "문제가 발생했습니다. 송장 PDF를 가져올 수 없습니다.",
  sortBy: "정렬 기준",
  sortInvoices: "송장 정렬",
  sorts: {
    byCreatedAtAsc: "날짜(오름차순)",
    byCreatedAtDesc: "날짜(내림차순)",
    byInvoiceIdAsc: "송장 번호(오름차순)",
    byInvoiceIdDesc: "송장 번호(내림차순)",
    byNetAmountAsc: "순액(오름차순)",
    byNetAmountDesc: "순액(내림차순)",
    byTotalAmountAsc: "총액(오름차순)",
    byTotalAmountDesc: "총액(내림차순)"
  }
};
var pdfInvoices$b = {
  pdfInvoices: pdfInvoices$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  pdfInvoices: pdfInvoices$b
};
var pdfInvoices$8 = {
  featureNotEnabled: "Nie można uzyskać faktur, upewnij się, że SAP Invoice i API for Invoices są włączone.",
  invoicesLoadingError: "Coś poszło nie tak. Nie można wyświetlić faktur dla zamówienia",
  invoicesTable: {
    header: "Faktury",
    label: "Tabela faktur",
    invoiceId: "Faktura #",
    createdAt: "Data",
    netAmount: "Kwota netto",
    totalAmount: "Kwota łączna",
    attachment: "Pobierz fakturę",
    download: "Pobierz",
    attachmentDescription: "Pobierz PDF z fakturą {{id}}.",
    noneFound: "Nie znaleziono żadnej faktury."
  },
  downloadPDFError: "Coś poszło nie tak. Nie można uzyskać pliku PDF z fakturą.",
  sortBy: "Sortuj według",
  sortInvoices: "Sortuj faktury",
  sorts: {
    byCreatedAtAsc: "Daty rosnąco",
    byCreatedAtDesc: "Daty malejąco",
    byInvoiceIdAsc: "Numery faktur rosnąco",
    byInvoiceIdDesc: "Numery faktur malejąco",
    byNetAmountAsc: "Kwoty netto rosnąco",
    byNetAmountDesc: "Kwoty netto malejąco",
    byTotalAmountAsc: "Kwoty łączne rosnąco",
    byTotalAmountDesc: "Kwoty łączne malejąco"
  }
};
var pdfInvoices$9 = {
  pdfInvoices: pdfInvoices$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  pdfInvoices: pdfInvoices$9
};
var pdfInvoices$6 = {
  featureNotEnabled: "Impossível obter faturas, assegure que a SAP Invoice e a API para faturas estão ativadas.",
  invoicesLoadingError: "Algo deu errado. Impossível exibir faturas para o pedido",
  invoicesTable: {
    header: "Faturas",
    label: "Tabela de faturas",
    invoiceId: "Nº da fatura",
    createdAt: "Data",
    netAmount: "Montante líquido",
    totalAmount: "Montante total",
    attachment: "Baixar fatura",
    download: "Baixar",
    attachmentDescription: "Baixar PDF para fatura {{id}}.",
    noneFound: "Nenhuma fatura encontrada"
  },
  downloadPDFError: "Algo deu errado. Impossível obter PDF de fatura.",
  sortBy: "Ordenar por",
  sortInvoices: "Ordenar faturas",
  sorts: {
    byCreatedAtAsc: "Data crescente",
    byCreatedAtDesc: "Data decrescente",
    byInvoiceIdAsc: "Número da fatura crescente",
    byInvoiceIdDesc: "Número da fatura decrescente",
    byNetAmountAsc: "Montante líquido crescente",
    byNetAmountDesc: "Montante líquido decrescente",
    byTotalAmountAsc: "Montante total crescente",
    byTotalAmountDesc: "Montante total decrescente"
  }
};
var pdfInvoices$7 = {
  pdfInvoices: pdfInvoices$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  pdfInvoices: pdfInvoices$7
};
var pdfInvoices$4 = {
  featureNotEnabled: "Невозможно получить счета, убедитесь, что активирован счет SAP и API для счетов.",
  invoicesLoadingError: "Что-то пошло не так. Невозможно просмотреть счета для заказа",
  invoicesTable: {
    header: "Счета",
    label: "Таблица счетов",
    invoiceId: "Счет №",
    createdAt: "Дата",
    netAmount: "Сумма нетто",
    totalAmount: "Общая сумма",
    attachment: "Выгрузить счет",
    download: "Выгрузить",
    attachmentDescription: "Выгрузить PDF для счета {{id}}.",
    noneFound: "Счета не найдены"
  },
  downloadPDFError: "Что-то пошло не так. Невозможно получить PDF счета.",
  sortBy: "Сортировать по",
  sortInvoices: "Сортировать счета",
  sorts: {
    byCreatedAtAsc: "Дата по возрастанию",
    byCreatedAtDesc: "Дата по убыванию",
    byInvoiceIdAsc: "Номер счета по возрастанию",
    byInvoiceIdDesc: "Номер счета по убыванию",
    byNetAmountAsc: "Сумма нетто по возрастанию",
    byNetAmountDesc: "Сумма нетто по убыванию",
    byTotalAmountAsc: "Общая сумма по возрастанию",
    byTotalAmountDesc: "Общая сумма по убыванию"
  }
};
var pdfInvoices$5 = {
  pdfInvoices: pdfInvoices$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  pdfInvoices: pdfInvoices$5
};
var pdfInvoices$2 = {
  featureNotEnabled: "无法获取发票，请确保已启用 SAP 发票和发票 API。",
  invoicesLoadingError: "出错了。无法显示订单的发票",
  invoicesTable: {
    header: "发票",
    label: "发票表",
    invoiceId: "发票编号",
    createdAt: "日期",
    netAmount: "净金额",
    totalAmount: "总金额",
    attachment: "下载发票",
    download: "下载",
    attachmentDescription: "下载发票 {{id}} 的 PDF。",
    noneFound: "找不到发票"
  },
  downloadPDFError: "出错了。无法获取发票 PDF。",
  sortBy: "排序依据",
  sortInvoices: "对发票进行排序",
  sorts: {
    byCreatedAtAsc: "日期升序",
    byCreatedAtDesc: "日期降序",
    byInvoiceIdAsc: "发票编号升序",
    byInvoiceIdDesc: "发票编号降序",
    byNetAmountAsc: "净金额升序",
    byNetAmountDesc: "净金额降序",
    byTotalAmountAsc: "总金额升序",
    byTotalAmountDesc: "总金额降序"
  }
};
var pdfInvoices$3 = {
  pdfInvoices: pdfInvoices$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  pdfInvoices: pdfInvoices$3
};
var pdfInvoices = {
  featureNotEnabled: "無法取得發票，請確保已啟用 SAP 發票和發票 API。",
  invoicesLoadingError: "發生錯誤，無法顯示訂單的發票",
  invoicesTable: {
    header: "發票",
    label: "發票表格",
    invoiceId: "發票號碼",
    createdAt: "日期",
    netAmount: "淨額",
    totalAmount: "總金額",
    attachment: "下載發票",
    download: "下載",
    attachmentDescription: "下載發票 {{id}} 的 PDF。",
    noneFound: "找不到發票"
  },
  downloadPDFError: "發生錯誤。無法取得發票 PDF。",
  sortBy: "排序依據",
  sortInvoices: "排序發票",
  sorts: {
    byCreatedAtAsc: "日期 (升冪)",
    byCreatedAtDesc: "日期 (降冪)",
    byInvoiceIdAsc: "發票號碼 (升冪)",
    byInvoiceIdDesc: "發票號碼 (降冪)",
    byNetAmountAsc: "淨額 (升冪)",
    byNetAmountDesc: "淨額 (降冪)",
    byTotalAmountAsc: "總金額 (升冪)",
    byTotalAmountDesc: "總金額 (降冪)"
  }
};
var pdfInvoices$1 = {
  pdfInvoices: pdfInvoices
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  pdfInvoices: pdfInvoices$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pdfInvoicesTranslationChunksConfig = {
  pdfInvoices: ['pdfInvoices']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: pdfInvoicesTranslations
 *             +   resources: { en: pdfInvoicesTranslationsEn }
 *               }
 *             ```
 */
const pdfInvoicesTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { pdfInvoicesTranslationChunksConfig, pdfInvoicesTranslations, cs as pdfInvoicesTranslationsCs, de as pdfInvoicesTranslationsDe, en as pdfInvoicesTranslationsEn, es as pdfInvoicesTranslationsEs, es_CO as pdfInvoicesTranslationsEs_CO, fr as pdfInvoicesTranslationsFr, hi as pdfInvoicesTranslationsHi, hu as pdfInvoicesTranslationsHu, id as pdfInvoicesTranslationsId, it as pdfInvoicesTranslationsIt, ja as pdfInvoicesTranslationsJa, ko as pdfInvoicesTranslationsKo, pl as pdfInvoicesTranslationsPl, pt as pdfInvoicesTranslationsPt, ru as pdfInvoicesTranslationsRu, zh as pdfInvoicesTranslationsZh, zh_TW as pdfInvoicesTranslationsZh_TW };
