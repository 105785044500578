var orderApprovalDetails$g = {
  back: "Zpět na seznam",
  showForm_APPROVE: "Schválit objednávku…",
  showForm_REJECT: "Zamítnout objednávku…",
  form: {
    title_APPROVE: "Schválit objednávku {{orderCode}} v celkové výši {{orderTotal}}",
    title_REJECT: "Zamítnout objednávku {{orderCode}} v celkové výši {{orderTotal}}",
    submit_APPROVE: "Schválit",
    submit_REJECT: "Odmítnout",
    cancel: "Zrušit",
    comment_APPROVE: {
      label: "Komentář (volitelný, max. 255 znaků)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Komentář (max. 255 znaků)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Podrobnosti o schvalování zákazníka",
    permission: "Oprávnění",
    approver: "Schvalovatel",
    status: "Status",
    approverComments: "Komentáře schvalovatele",
    noApprovalComments: "Nic",
    permissionType_B2BBudgetExceededPermission: "Rozpočet přiřazený vybranému nákladovému středisku byl překročen.",
    permissionType_B2BOrderThresholdPermission: "Celková výše objednávky překročila limit na objednávku.",
    permissionType_B2BOrderThresholdTimespanPermission: "Celková výše objednávky překročila limit na časové období."
  }
};
var orderApprovalList$g = {
  orderCode: "Číslo objednávky",
  POCode: "Č. objednávky",
  placedBy: "Podal",
  date: "Datum",
  status: "Status",
  total: "Celkem",
  none: "Nic",
  emptyList: "Momentálně zde nejsou žádné objednávky ke schválení.",
  orderApprovalList: "Seznam schválení objednávek"
};
var orderApprovalGlobal$g = {
  notification: {
    noSufficientPermissions: "Pro přístup na tuto stránku nemáte dostatečná oprávnění."
  }
};
var orderApproval$g = {
  orderApprovalDetails: orderApprovalDetails$g,
  orderApprovalList: orderApprovalList$g,
  orderApprovalGlobal: orderApprovalGlobal$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  orderApproval: orderApproval$g
};
var orderApprovalDetails$f = {
  back: "Zurück zur Liste",
  showForm_APPROVE: "Bestellung genehmigen...",
  showForm_REJECT: "Bestellung ablehnen...",
  form: {
    title_APPROVE: "Bestellung {{orderCode}} mit Summe {{orderTotal}} genehmigen",
    title_REJECT: "Bestellung {{orderCode}} mit Summe {{orderTotal}} ablehnen",
    submit_APPROVE: "Genehmigen",
    submit_REJECT: "Ablehnen",
    cancel: "Abbrechen",
    comment_APPROVE: {
      label: "Kommentar (optional, maximal 255 Zeichen)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Kommentar (maximal 255 Zeichen)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Kundengenehmigungsdetails",
    permission: "Berechtigung",
    approver: "Genehmigende Person",
    status: "Status",
    approverComments: "Kommentare der genehmigenden Person",
    noApprovalComments: "Keine",
    permissionType_B2BBudgetExceededPermission: "Das der ausgewählten Kostenstelle zugeordnete Budget ist überschritten",
    permissionType_B2BOrderThresholdPermission: "Bestellgesamtsumme überschreitet das Limit pro Bestellung",
    permissionType_B2BOrderThresholdTimespanPermission: "Bestellgesamtsumme überschreitet das Limit pro Zeitraum"
  }
};
var orderApprovalList$f = {
  orderCode: "Bestellnummer",
  POCode: "Bestellnr.",
  placedBy: "Aufgegeben von",
  date: "Datum",
  status: "Status",
  total: "Gesamtsumme",
  none: "Keine",
  emptyList: "Zurzeit sind keine zu genehmigenden Bestellungen vorhanden.",
  orderApprovalList: "Bestellgenehmigungsliste"
};
var orderApprovalGlobal$f = {
  notification: {
    noSufficientPermissions: "Keine ausreichenden Berechtigungen für den Zugriff auf diese Seite"
  }
};
var orderApproval$f = {
  orderApprovalDetails: orderApprovalDetails$f,
  orderApprovalList: orderApprovalList$f,
  orderApprovalGlobal: orderApprovalGlobal$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  orderApproval: orderApproval$f
};
var orderApprovalDetails$e = {
  back: "Back To List",
  showForm_APPROVE: "Approve Order...",
  showForm_REJECT: "Reject Order...",
  form: {
    title_APPROVE: "Approve order {{orderCode}} totalling {{orderTotal}}",
    title_REJECT: "Reject order {{orderCode}} totalling {{orderTotal}}",
    submit_APPROVE: "Approve",
    submit_REJECT: "Reject",
    cancel: "Cancel",
    comment_APPROVE: {
      label: "Comment (optional, maximum 255 characters)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Comment (maximum 255 characters)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Customer Approval Details",
    permission: "Permission",
    approver: "Approver",
    status: "Status",
    approverComments: "Approver Comments",
    noApprovalComments: "None",
    permissionType_B2BBudgetExceededPermission: "The budget associated with the chosen cost center has been exceeded",
    permissionType_B2BOrderThresholdPermission: "Order total exceeded the per-order limit",
    permissionType_B2BOrderThresholdTimespanPermission: "Order total exceeded the per-timespan limit"
  }
};
var orderApprovalList$e = {
  orderCode: "Order #",
  POCode: "PO #",
  placedBy: "Placed by",
  date: "Date",
  status: "Status",
  total: "Total",
  none: "None",
  emptyList: "There are no orders to approve at this time.",
  orderApprovalList: "Order Approval List"
};
var orderApprovalGlobal$e = {
  notification: {
    noSufficientPermissions: "No sufficient permissions to access this page."
  }
};
var orderApproval$e = {
  orderApprovalDetails: orderApprovalDetails$e,
  orderApprovalList: orderApprovalList$e,
  orderApprovalGlobal: orderApprovalGlobal$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  orderApproval: orderApproval$e
};
var orderApprovalDetails$d = {
  back: "Volver a la lista",
  showForm_APPROVE: "Aprobar pedido...",
  showForm_REJECT: "Rechazar pedido...",
  form: {
    title_APPROVE: "Aprobar pedido {{orderCode}} con un total de {{orderTotal}}",
    title_REJECT: "Rechazar pedido {{orderCode}} con un total de {{orderTotal}}",
    submit_APPROVE: "Aprobar",
    submit_REJECT: "Rechazar",
    cancel: "Cancelar",
    comment_APPROVE: {
      label: "Comentario (opcional, 255 caracteres como máximo)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Comentario (255 caracteres como máximo)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Detalles de aprobación del cliente",
    permission: "Permiso",
    approver: "Aprobador",
    status: "Estado",
    approverComments: "Comentarios del aprobador",
    noApprovalComments: "Ninguno",
    permissionType_B2BBudgetExceededPermission: "Se superó el presupuesto asociado al centro de coste seleccionado",
    permissionType_B2BOrderThresholdPermission: "El total del pedido superó el límite por pedido",
    permissionType_B2BOrderThresholdTimespanPermission: "El total del pedido superó el límite por intervalo de tiempo"
  }
};
var orderApprovalList$d = {
  orderCode: "Número de pedido",
  POCode: "Número de pedido",
  placedBy: "Realizado por",
  date: "Fecha",
  status: "Estado",
  total: "Total",
  none: "Ninguno",
  emptyList: "No hay pedidos para aprobar en este momento.",
  orderApprovalList: "Lista de aprobación de pedidos"
};
var orderApprovalGlobal$d = {
  notification: {
    noSufficientPermissions: "No tiene permisos suficientes para acceder a esta página."
  }
};
var orderApproval$d = {
  orderApprovalDetails: orderApprovalDetails$d,
  orderApprovalList: orderApprovalList$d,
  orderApprovalGlobal: orderApprovalGlobal$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  orderApproval: orderApproval$d
};
var orderApprovalDetails$c = {
  back: "Volver a la lista",
  showForm_APPROVE: "Aprobar pedido...",
  showForm_REJECT: "Rechazar pedido...",
  form: {
    title_APPROVE: "Aprobar pedido {{orderCode}} con un total de {{orderTotal}}",
    title_REJECT: "Rechazar pedido {{orderCode}} con un total de {{orderTotal}}",
    submit_APPROVE: "Aprobar",
    submit_REJECT: "Rechazar",
    cancel: "Cancelar",
    comment_APPROVE: {
      label: "Comentario (opcional, 255 caracteres como máximo)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Comentario (255 caracteres como máximo)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Detalles de la aprobación del cliente",
    permission: "Permiso",
    approver: "Aprobador",
    status: "Estado",
    approverComments: "Comentarios del aprobador",
    noApprovalComments: "Ninguno",
    permissionType_B2BBudgetExceededPermission: "Se superó el presupuesto asociado al centro de costos seleccionado",
    permissionType_B2BOrderThresholdPermission: "El total del pedido superó el límite por pedido",
    permissionType_B2BOrderThresholdTimespanPermission: "El total del pedido superó el límite por intervalo de tiempo"
  }
};
var orderApprovalList$c = {
  orderCode: "Número de pedido",
  POCode: "Número de pedido",
  placedBy: "Realizado por",
  date: "Fecha",
  status: "Estado",
  total: "Total",
  none: "Ninguno",
  emptyList: "No hay pedidos para aprobar en este momento.",
  orderApprovalList: "Lista de aprobación de pedidos"
};
var orderApprovalGlobal$c = {
  notification: {
    noSufficientPermissions: "No tiene permisos suficientes para acceder a esta página."
  }
};
var orderApproval$c = {
  orderApprovalDetails: orderApprovalDetails$c,
  orderApprovalList: orderApprovalList$c,
  orderApprovalGlobal: orderApprovalGlobal$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  orderApproval: orderApproval$c
};
var orderApprovalDetails$b = {
  back: "Retour à la liste",
  showForm_APPROVE: "Approuver la commande...",
  showForm_REJECT: "Rejeter la commande...",
  form: {
    title_APPROVE: "Approuver la commande {{orderCode}} totalisant {{orderTotal}}",
    title_REJECT: "Rejeter la commande {{orderCode}} totalisant {{orderTotal}}",
    submit_APPROVE: "Approuver",
    submit_REJECT: "Rejeter",
    cancel: "Annuler",
    comment_APPROVE: {
      label: "Commentaire (facultatif, 255 caractères maximum)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Commentaire (255 caractères maximum)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Détails de l'approbation du client",
    permission: "Autorisation",
    approver: "Approbateur",
    status: "Statut",
    approverComments: "Commentaires de l'approbateur",
    noApprovalComments: "Aucun(e)",
    permissionType_B2BBudgetExceededPermission: "Le budget associé au centre de coûts sélectionné a été dépassé.",
    permissionType_B2BOrderThresholdPermission: "Le total de la commande dépasse la limite par commande.",
    permissionType_B2BOrderThresholdTimespanPermission: "Le total de la commande dépasse la limite par période."
  }
};
var orderApprovalList$b = {
  orderCode: "N° commande",
  POCode: "Nº de la commande d'achat",
  placedBy: "Passée par",
  date: "Date",
  status: "Statut",
  total: "Total",
  none: "Aucun(e)",
  emptyList: "Il n'y a aucune commande à approuver pour le moment.",
  orderApprovalList: "Liste des approbations de commandes"
};
var orderApprovalGlobal$b = {
  notification: {
    noSufficientPermissions: "Vous n'avez pas les autorisations requises pour accéder à cette page."
  }
};
var orderApproval$b = {
  orderApprovalDetails: orderApprovalDetails$b,
  orderApprovalList: orderApprovalList$b,
  orderApprovalGlobal: orderApprovalGlobal$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  orderApproval: orderApproval$b
};
var orderApprovalDetails$a = {
  back: "सूची में वापस जाएं",
  showForm_APPROVE: "ऑर्डर अनुमोदित करें...",
  showForm_REJECT: "अस्वीकृत ऑर्डर...",
  form: {
    title_APPROVE: "अनुमोदित ऑर्डर {{orderCode}} कुल {{orderTotal}}",
    title_REJECT: "ऑर्डर अस्वीकृत {{orderCode}} कुल {{orderTotal}}",
    submit_APPROVE: "अनुमोदन करें",
    submit_REJECT: "अस्वीकृत करें",
    cancel: "रद्द करें",
    comment_APPROVE: {
      label: "टिप्पणी (वैकल्पिक, अधिकतम 255 वर्ण)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "टिप्पणी (अधिकतम 255 वर्ण)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "ग्राहक के अनुमोदन का विवरण",
    permission: "अनुमति",
    approver: "अनुमोदक",
    status: "स्थिति",
    approverComments: "अनुमोदक की टिप्पणियां",
    noApprovalComments: "कोई नहीं",
    permissionType_B2BBudgetExceededPermission: "चुने हुए लागत केंद्र से संबंधित बजट पार हो गया है",
    permissionType_B2BOrderThresholdPermission: "ऑर्डर का कुल योग प्रति ऑर्डर सीमा पार कर गया",
    permissionType_B2BOrderThresholdTimespanPermission: "ऑर्डर का कुल योग प्रति समयस्पान सीमा पार कर गया"
  }
};
var orderApprovalList$a = {
  orderCode: "ऑर्डर #",
  POCode: "PO #",
  placedBy: "ऑर्डर प्रदाता",
  date: "दिनांक",
  status: "स्थिति",
  total: "कुल",
  none: "कोई नहीं",
  emptyList: "इस समय अनुमोदन के लिए कोई ऑर्डर नहीं है.",
  orderApprovalList: "ऑर्डर अनुमोदन सूची"
};
var orderApprovalGlobal$a = {
  notification: {
    noSufficientPermissions: "इस पृष्ठ पर पहुंच के लिए पर्याप्त अनुमति नहीं है."
  }
};
var orderApproval$a = {
  orderApprovalDetails: orderApprovalDetails$a,
  orderApprovalList: orderApprovalList$a,
  orderApprovalGlobal: orderApprovalGlobal$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  orderApproval: orderApproval$a
};
var orderApprovalDetails$9 = {
  back: "Vissza a listához",
  showForm_APPROVE: "Rendelés jóváhagyása...",
  showForm_REJECT: "Rendelés elutasítása...",
  form: {
    title_APPROVE: "Rendelés ({{orderCode}}) jóváhagyása, összesen: {{orderTotal}}",
    title_REJECT: "Rendelés ({{orderCode}}) elutasítása, összesen: {{orderTotal}}",
    submit_APPROVE: "Jóváhagyás",
    submit_REJECT: "Elutasítás",
    cancel: "Mégse",
    comment_APPROVE: {
      label: "Megjegyzés (opcionális, legfeljebb 255 karakter)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Megjegyzés (legfeljebb 255 karakter)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Ügyfél általi jóváhagyás részletei",
    permission: "Jogosultság",
    approver: "Jóváhagyó",
    status: "Státus",
    approverComments: "Jóváhagyó megjegyzései",
    noApprovalComments: "Nincs",
    permissionType_B2BBudgetExceededPermission: "A kiválasztott költséghelyhez társított költségkeret meghaladva",
    permissionType_B2BOrderThresholdPermission: "A rendelés összege meghaladta a rendelésenkénti korlátot",
    permissionType_B2BOrderThresholdTimespanPermission: "A rendelés összege meghaladta az időszakonkénti korlátot"
  }
};
var orderApprovalList$9 = {
  orderCode: "Rendelés #",
  POCode: "Megrendelés #",
  placedBy: "Megrendelő",
  date: "Dátum",
  status: "Státus",
  total: "Összesen",
  none: "Nincs",
  emptyList: "Jelenleg nincsenek jóváhagyandó rendelések.",
  orderApprovalList: "Rendelések jóváhagyási listája"
};
var orderApprovalGlobal$9 = {
  notification: {
    noSufficientPermissions: "Nincs megfelelő jogosultsága az oldal eléréséhez."
  }
};
var orderApproval$9 = {
  orderApprovalDetails: orderApprovalDetails$9,
  orderApprovalList: orderApprovalList$9,
  orderApprovalGlobal: orderApprovalGlobal$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  orderApproval: orderApproval$9
};
var orderApprovalDetails$8 = {
  back: "Kembali Ke Daftar",
  showForm_APPROVE: "Setujui Pemesanan...",
  showForm_REJECT: "Tolak Pemesanan...",
  form: {
    title_APPROVE: "Setujui pemesanan {{orderCode}} dengan total {{orderTotal}}",
    title_REJECT: "Tolak pemesanan {{orderCode}} dengan total {{orderTotal}}",
    submit_APPROVE: "Setujui",
    submit_REJECT: "Tolak",
    cancel: "Batalkan",
    comment_APPROVE: {
      label: "Komentar (opsional, maksimal 255 karakter)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Komentar (maksimal 255 karakter)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Rincian Persetujuan Pelanggan",
    permission: "Izin",
    approver: "Penyetuju",
    status: "Status",
    approverComments: "Komentar Penyetuju",
    noApprovalComments: "Tidak ada",
    permissionType_B2BBudgetExceededPermission: "Anggaran yang terkait dengan pusat biaya yang dipilih telah terlampaui",
    permissionType_B2BOrderThresholdPermission: "Total pesanan melampaui batas untuk setiap pemesanannya",
    permissionType_B2BOrderThresholdTimespanPermission: "Total pesanan melampaui batas untuk setiap rentang waktunya"
  }
};
var orderApprovalList$8 = {
  orderCode: "Pemesanan #",
  POCode: "PO #",
  placedBy: "Dipesan oleh",
  date: "Tanggal",
  status: "Status",
  total: "Total",
  none: "Tidak ada",
  emptyList: "Saat ini tidak ada pemesanan yang perlu disetujui.",
  orderApprovalList: "Daftar Persetujuan Pemesanan"
};
var orderApprovalGlobal$8 = {
  notification: {
    noSufficientPermissions: "Tidak memiliki izin yang memadai untuk mengakses halaman ini."
  }
};
var orderApproval$8 = {
  orderApprovalDetails: orderApprovalDetails$8,
  orderApprovalList: orderApprovalList$8,
  orderApprovalGlobal: orderApprovalGlobal$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  orderApproval: orderApproval$8
};
var orderApprovalDetails$7 = {
  back: "Torna alla lista",
  showForm_APPROVE: "Approva ordine...",
  showForm_REJECT: "Rifiuta ordine...",
  form: {
    title_APPROVE: "Approva ordine {{orderCode}} per un totale di {{orderTotal}}",
    title_REJECT: "Rifiuta ordine {{orderCode}} per un totale di {{orderTotal}}",
    submit_APPROVE: "Approva",
    submit_REJECT: "Rifiuta",
    cancel: "Annulla",
    comment_APPROVE: {
      label: "Commento (facoltativo, massimo 255 caratteri)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Commento (massimo 255 caratteri)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Dettagli approvazione cliente",
    permission: "Autorizzazione",
    approver: "Approvatore",
    status: "Stato",
    approverComments: "Commenti dell'approvatore",
    noApprovalComments: "Nessuno",
    permissionType_B2BBudgetExceededPermission: "Il budget associato al centro di costo selezionato è stato superato",
    permissionType_B2BOrderThresholdPermission: "Il totale dell'ordine ha superato il limite per ordine",
    permissionType_B2BOrderThresholdTimespanPermission: "Il totale dell'ordine ha superato il limite per periodo"
  }
};
var orderApprovalList$7 = {
  orderCode: "N. ordine",
  POCode: "N. ordine d'acquisto",
  placedBy: "Effettuato da",
  date: "Data",
  status: "Stato",
  total: "Totale",
  none: "Nessuno",
  emptyList: "Al momento non ci sono ordini da approvare.",
  orderApprovalList: "Lista di approvazione ordini"
};
var orderApprovalGlobal$7 = {
  notification: {
    noSufficientPermissions: "Autorizzazioni insufficienti per accedere a questa pagina."
  }
};
var orderApproval$7 = {
  orderApprovalDetails: orderApprovalDetails$7,
  orderApprovalList: orderApprovalList$7,
  orderApprovalGlobal: orderApprovalGlobal$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  orderApproval: orderApproval$7
};
var orderApprovalDetails$6 = {
  back: "リストに戻る",
  showForm_APPROVE: "注文の承認...",
  showForm_REJECT: "注文の拒否...",
  form: {
    title_APPROVE: "注文の承認 {{orderCode}} 合計 {{orderTotal}}",
    title_REJECT: "注文の拒否 {{orderCode}} 合計 {{orderTotal}}",
    submit_APPROVE: "承認",
    submit_REJECT: "拒否",
    cancel: "キャンセル",
    comment_APPROVE: {
      label: "コメント (任意、最大 255 文字)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "コメント (最大 255 文字)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "顧客承認詳細",
    permission: "権限",
    approver: "承認者",
    status: "ステータス",
    approverComments: "承認者コメント",
    noApprovalComments: "なし",
    permissionType_B2BBudgetExceededPermission: "選択した原価センタに関連付けられた予算が超過しました",
    permissionType_B2BOrderThresholdPermission: "注文の合計が、注文ごとの制限を超過しました",
    permissionType_B2BOrderThresholdTimespanPermission: "注文の合計が、時間範囲ごとの制限を超過しました"
  }
};
var orderApprovalList$6 = {
  orderCode: "注文番号",
  POCode: "発注書番号",
  placedBy: "注文実行者",
  date: "日付",
  status: "ステータス",
  total: "合計",
  none: "なし",
  emptyList: "現時点では承認する注文はありません。",
  orderApprovalList: "注文承認リスト"
};
var orderApprovalGlobal$6 = {
  notification: {
    noSufficientPermissions: "このページにアクセスするのに十分な権限がありません。"
  }
};
var orderApproval$6 = {
  orderApprovalDetails: orderApprovalDetails$6,
  orderApprovalList: orderApprovalList$6,
  orderApprovalGlobal: orderApprovalGlobal$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  orderApproval: orderApproval$6
};
var orderApprovalDetails$5 = {
  back: "목록으로 돌아가기",
  showForm_APPROVE: "주문 승인...",
  showForm_REJECT: "주문 거부...",
  form: {
    title_APPROVE: "합계가 {{orderTotal}}인 주문 {{orderCode}} 승인",
    title_REJECT: "합계가 {{orderTotal}}인 주문 {{orderCode}} 거부",
    submit_APPROVE: "승인",
    submit_REJECT: "거부",
    cancel: "취소",
    comment_APPROVE: {
      label: "의견(선택사항, 최대 255자)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "의견(최대 255자)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "고객 승인 세부사항",
    permission: "권한",
    approver: "승인자",
    status: "상태",
    approverComments: "승인자 의견",
    noApprovalComments: "없음",
    permissionType_B2BBudgetExceededPermission: "선택된 코스트 센터와 연계된 예산을 초과했습니다.",
    permissionType_B2BOrderThresholdPermission: "주문 합계가 주문당 한도를 초과했습니다.",
    permissionType_B2BOrderThresholdTimespanPermission: "주문 합계가 기간당 한도를 초과했습니다."
  }
};
var orderApprovalList$5 = {
  orderCode: "주문 번호",
  POCode: "구매 주문 번호",
  placedBy: "주문자",
  date: "날짜",
  status: "상태",
  total: "합계",
  none: "없음",
  emptyList: "현재 승인할 주문이 없습니다.",
  orderApprovalList: "주문 승인 목록"
};
var orderApprovalGlobal$5 = {
  notification: {
    noSufficientPermissions: "이 페이지를 볼 수 있는 충분한 권한이 없습니다."
  }
};
var orderApproval$5 = {
  orderApprovalDetails: orderApprovalDetails$5,
  orderApprovalList: orderApprovalList$5,
  orderApprovalGlobal: orderApprovalGlobal$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  orderApproval: orderApproval$5
};
var orderApprovalDetails$4 = {
  back: "Powrót do listy",
  showForm_APPROVE: "Zatwierdź zamówienie...",
  showForm_REJECT: "Odrzuć zamówienie...",
  form: {
    title_APPROVE: "Zatwierdź zamówienie {{orderCode}} na łączną kwotę {{orderTotal}}",
    title_REJECT: "Odrzuć zamówienie {{orderCode}} na łączną kwotę {{orderTotal}}",
    submit_APPROVE: "Zatwierdź",
    submit_REJECT: "Odrzuć",
    cancel: "Anuluj",
    comment_APPROVE: {
      label: "Komentarz (opcjonalny, maksymalnie 255 znaków)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Komentarz (maksymalnie 255 znaków)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Szczegóły zatwierdzenia klienta",
    permission: "Uprawnienie",
    approver: "Osoba zatwierdzająca",
    status: "Status",
    approverComments: "Komentarz osoby zatwierdzającej",
    noApprovalComments: "Brak",
    permissionType_B2BBudgetExceededPermission: "Budżet powiązany z wybranym miejscem powstawania kosztów został przekroczony",
    permissionType_B2BOrderThresholdPermission: "Suma zamówienia przekroczyła limit przypadający na zamówienie",
    permissionType_B2BOrderThresholdTimespanPermission: "Suma zamówienia przekroczyła limit przypadający na okres"
  }
};
var orderApprovalList$4 = {
  orderCode: "Zamówienie nr",
  POCode: "Zamówienie nr",
  placedBy: "Złożone przez",
  date: "Data",
  status: "Status",
  total: "Suma",
  none: "Brak",
  emptyList: "Obecnie nie ma żadnych zamówień do zatwierdzenia.",
  orderApprovalList: "Lista zatwierdzeń zamówień"
};
var orderApprovalGlobal$4 = {
  notification: {
    noSufficientPermissions: "Niewystarczające uprawnienia dostępu do tej strony."
  }
};
var orderApproval$4 = {
  orderApprovalDetails: orderApprovalDetails$4,
  orderApprovalList: orderApprovalList$4,
  orderApprovalGlobal: orderApprovalGlobal$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  orderApproval: orderApproval$4
};
var orderApprovalDetails$3 = {
  back: "Voltar à lista",
  showForm_APPROVE: "Aprovar pedido...",
  showForm_REJECT: "Rejeitar pedido...",
  form: {
    title_APPROVE: "Aprovar pedido {{orderCode}} com o total de {{orderTotal}}",
    title_REJECT: "Rejeitar pedido {{orderCode}} com o total de {{orderTotal}}",
    submit_APPROVE: "Aprovar",
    submit_REJECT: "Rejeitar",
    cancel: "Cancelar",
    comment_APPROVE: {
      label: "Comentário (opcional, máximo de 255 caracteres)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Comentário (máximo de 255 caracteres)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Detalhes da aprovação do cliente",
    permission: "Permissão",
    approver: "Aprovador",
    status: "Status",
    approverComments: "Comentários do aprovador",
    noApprovalComments: "Nenhum",
    permissionType_B2BBudgetExceededPermission: "O orçamento associado ao centro de custo selecionado foi excedido",
    permissionType_B2BOrderThresholdPermission: "O total do pedido excedeu o limite por pedido",
    permissionType_B2BOrderThresholdTimespanPermission: "O total do pedido excedeu o limite por faixa de tempo"
  }
};
var orderApprovalList$3 = {
  orderCode: "Nº do pedido",
  POCode: "Nº do pedido",
  placedBy: "Feito por",
  date: "Data",
  status: "Status",
  total: "Total",
  none: "Nenhum",
  emptyList: "Não existem pedidos para aprovar no momento.",
  orderApprovalList: "Lista de aprovação de pedidos"
};
var orderApprovalGlobal$3 = {
  notification: {
    noSufficientPermissions: "Sem permissões suficientes para acessar esta página."
  }
};
var orderApproval$3 = {
  orderApprovalDetails: orderApprovalDetails$3,
  orderApprovalList: orderApprovalList$3,
  orderApprovalGlobal: orderApprovalGlobal$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  orderApproval: orderApproval$3
};
var orderApprovalDetails$2 = {
  back: "Назад к списку",
  showForm_APPROVE: "Утвердить заказ...",
  showForm_REJECT: "Отклонить заказ...",
  form: {
    title_APPROVE: "Утвердить заказ {{orderCode}} на общую сумму {{orderTotal}}",
    title_REJECT: "Отклонить заказ {{orderCode}} на общую сумму {{orderTotal}}",
    submit_APPROVE: "Утвердить",
    submit_REJECT: "Отклонить",
    cancel: "Отменить",
    comment_APPROVE: {
      label: "Комментарий (необязательно, максимум 255 символов)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "Комментарий (максимум 255 символов)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "Сведения об утверждении клиентом",
    permission: "Разрешение",
    approver: "Утверждающий",
    status: "Статус",
    approverComments: "Комментарии утверждающего",
    noApprovalComments: "Нет",
    permissionType_B2BBudgetExceededPermission: "Превышен бюджет, связанный с выбранным МВЗ",
    permissionType_B2BOrderThresholdPermission: "Общая сумма заказа превышает лимит на заказ",
    permissionType_B2BOrderThresholdTimespanPermission: "Общая сумма заказа превышает лимит на временной интервал"
  }
};
var orderApprovalList$2 = {
  orderCode: "Заказ №",
  POCode: "ЗП №",
  placedBy: "Размещено",
  date: "Дата",
  status: "Статус",
  total: "Итого",
  none: "Нет",
  emptyList: "На данный момент нет заказов на утверждение.",
  orderApprovalList: "Список утверждения заказов"
};
var orderApprovalGlobal$2 = {
  notification: {
    noSufficientPermissions: "Нет полномочий на доступ к странице."
  }
};
var orderApproval$2 = {
  orderApprovalDetails: orderApprovalDetails$2,
  orderApprovalList: orderApprovalList$2,
  orderApprovalGlobal: orderApprovalGlobal$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  orderApproval: orderApproval$2
};
var orderApprovalDetails$1 = {
  back: "返回到列表",
  showForm_APPROVE: "批准订单...",
  showForm_REJECT: "拒绝订单...",
  form: {
    title_APPROVE: "批准订单 {{orderCode}}，总计 {{orderTotal}}",
    title_REJECT: "拒绝订单 {{orderCode}}，总计 {{orderTotal}}",
    submit_APPROVE: "批准",
    submit_REJECT: "拒绝",
    cancel: "取消",
    comment_APPROVE: {
      label: "备注（可选，最多 255 个字符）",
      placeholder: ""
    },
    comment_REJECT: {
      label: "备注（最多 255 个字符）",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "客户批准详细信息",
    permission: "权限",
    approver: "批准人",
    status: "状态",
    approverComments: "批准人注释",
    noApprovalComments: "无",
    permissionType_B2BBudgetExceededPermission: "已超出与所选成本中心相关联的预算",
    permissionType_B2BOrderThresholdPermission: "订单总计超出每笔订单限制",
    permissionType_B2BOrderThresholdTimespanPermission: "订单总计超出每个时间跨度限制"
  }
};
var orderApprovalList$1 = {
  orderCode: "订单编号",
  POCode: "采购订单编号",
  placedBy: "订购人",
  date: "日期",
  status: "状态",
  total: "总计",
  none: "无",
  emptyList: "目前没有需要批准的订单。",
  orderApprovalList: "订单审批列表"
};
var orderApprovalGlobal$1 = {
  notification: {
    noSufficientPermissions: "权限不足，无法访问此页面。"
  }
};
var orderApproval$1 = {
  orderApprovalDetails: orderApprovalDetails$1,
  orderApprovalList: orderApprovalList$1,
  orderApprovalGlobal: orderApprovalGlobal$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  orderApproval: orderApproval$1
};
var orderApprovalDetails = {
  back: "返回清單",
  showForm_APPROVE: "核准訂單...",
  showForm_REJECT: "拒絕訂單...",
  form: {
    title_APPROVE: "核准訂單 {{orderCode}} 總計 {{orderTotal}}",
    title_REJECT: "拒絕訂單 {{orderCode}} 總計 {{orderTotal}}",
    submit_APPROVE: "核准",
    submit_REJECT: "拒絕",
    cancel: "取消",
    comment_APPROVE: {
      label: "備註 (選擇性，最多 255 個字元)",
      placeholder: ""
    },
    comment_REJECT: {
      label: "備註 (最多 255 個字元)",
      placeholder: ""
    }
  },
  permissionResults: {
    header: "客戶核准明細",
    permission: "權限",
    approver: "核准者",
    status: "狀態",
    approverComments: "核准者備註",
    noApprovalComments: "無",
    permissionType_B2BBudgetExceededPermission: "已超過與所選成本中心相關的預算",
    permissionType_B2BOrderThresholdPermission: "訂單總計超過各訂單限制",
    permissionType_B2BOrderThresholdTimespanPermission: "訂單總計超過各時間間隔限制"
  }
};
var orderApprovalList = {
  orderCode: "訂單號碼",
  POCode: "採購單號碼",
  placedBy: "下單者",
  date: "日期",
  status: "狀態",
  total: "總計",
  none: "無",
  emptyList: "目前沒有要核准的訂單。",
  orderApprovalList: "訂單核准清單"
};
var orderApprovalGlobal = {
  notification: {
    noSufficientPermissions: "沒有足夠權限無法存取此頁面。"
  }
};
var orderApproval = {
  orderApprovalDetails: orderApprovalDetails,
  orderApprovalList: orderApprovalList,
  orderApprovalGlobal: orderApprovalGlobal
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  orderApproval
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const orderApprovalTranslationChunksConfig = {
  orderApproval: ['orderApprovalDetails', 'orderApprovalList', 'orderApprovalGlobal']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: orderApprovalTranslations
 *             +   resources: { en: orderApprovalTranslationsEn }
 *               }
 *             ```
 */
const orderApprovalTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { orderApprovalTranslationChunksConfig, orderApprovalTranslations, cs as orderApprovalTranslationsCs, de as orderApprovalTranslationsDe, en as orderApprovalTranslationsEn, es as orderApprovalTranslationsEs, es_CO as orderApprovalTranslationsEs_CO, fr as orderApprovalTranslationsFr, hi as orderApprovalTranslationsHi, hu as orderApprovalTranslationsHu, id as orderApprovalTranslationsId, it as orderApprovalTranslationsIt, ja as orderApprovalTranslationsJa, ko as orderApprovalTranslationsKo, pl as orderApprovalTranslationsPl, pt as orderApprovalTranslationsPt, ru as orderApprovalTranslationsRu, zh as orderApprovalTranslationsZh, zh_TW as orderApprovalTranslationsZh_TW };
