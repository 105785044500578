var s4omScheduleLines$w = {
  quantity: "- Množství",
  a11y: {
    scheduleLineEntryInfo: "{{ quantity }} množství bude dodáno do {{ date }}"
  }
};
var s4omScheduleLines$x = {
  s4omScheduleLines: s4omScheduleLines$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  s4omScheduleLines: s4omScheduleLines$x
};
var s4omScheduleLines$u = {
  quantity: "-  Menge",
  a11y: {
    scheduleLineEntryInfo: "Menge {{ quantity }} wird bis {{ date }} geliefert"
  }
};
var s4omScheduleLines$v = {
  s4omScheduleLines: s4omScheduleLines$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  s4omScheduleLines: s4omScheduleLines$v
};
var s4omScheduleLines$s = {
  quantity: "-  Qty",
  a11y: {
    scheduleLineEntryInfo: "{{ quantity }} quantity will be delivered by {{ date }}"
  }
};
var s4omScheduleLines$t = {
  s4omScheduleLines: s4omScheduleLines$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  s4omScheduleLines: s4omScheduleLines$t
};
var s4omScheduleLines$q = {
  quantity: "-  ctd.",
  a11y: {
    scheduleLineEntryInfo: "La cantidad {{ quantity }} será suministrada por {{ date }}"
  }
};
var s4omScheduleLines$r = {
  s4omScheduleLines: s4omScheduleLines$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  s4omScheduleLines: s4omScheduleLines$r
};
var s4omScheduleLines$o = {
  quantity: "-  ctd.",
  a11y: {
    scheduleLineEntryInfo: "La cantidad {{ quantity }} será suministrada por {{ date }}"
  }
};
var s4omScheduleLines$p = {
  s4omScheduleLines: s4omScheduleLines$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  s4omScheduleLines: s4omScheduleLines$p
};
var s4omScheduleLines$m = {
  quantity: "-  Qté",
  a11y: {
    scheduleLineEntryInfo: "La quantité {{ quantity }} sera livrée par {{ date }}"
  }
};
var s4omScheduleLines$n = {
  s4omScheduleLines: s4omScheduleLines$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  s4omScheduleLines: s4omScheduleLines$n
};
var s4omScheduleLines$k = {
  quantity: "मात्रा",
  a11y: {
    scheduleLineEntryInfo: "{{ quantity }} मात्रा {{ date }} द्वारा वितरित की जाएगी"
  }
};
var s4omScheduleLines$l = {
  s4omScheduleLines: s4omScheduleLines$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  s4omScheduleLines: s4omScheduleLines$l
};
var s4omScheduleLines$i = {
  quantity: "– Menny.",
  a11y: {
    scheduleLineEntryInfo: "A mennyiség ({{ quantity }}) {{ date }}-ig lesz kiszállítva"
  }
};
var s4omScheduleLines$j = {
  s4omScheduleLines: s4omScheduleLines$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  s4omScheduleLines: s4omScheduleLines$j
};
var s4omScheduleLines$g = {
  quantity: "- Jml",
  a11y: {
    scheduleLineEntryInfo: "Sejumlah {{ quantity }} item akan dikirim sebelum {{ date }}"
  }
};
var s4omScheduleLines$h = {
  s4omScheduleLines: s4omScheduleLines$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  s4omScheduleLines: s4omScheduleLines$h
};
var s4omScheduleLines$e = {
  quantity: "- Qtà",
  a11y: {
    scheduleLineEntryInfo: "La quantità {{ quantity }} verrà consegnata da {{ date }}"
  }
};
var s4omScheduleLines$f = {
  s4omScheduleLines: s4omScheduleLines$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  s4omScheduleLines: s4omScheduleLines$f
};
var s4omScheduleLines$c = {
  quantity: "-  数量",
  a11y: {
    scheduleLineEntryInfo: "{{ quantity }} 数量が {{ date }} までに配送されます "
  }
};
var s4omScheduleLines$d = {
  s4omScheduleLines: s4omScheduleLines$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  s4omScheduleLines: s4omScheduleLines$d
};
var s4omScheduleLines$a = {
  quantity: "- 수량",
  a11y: {
    scheduleLineEntryInfo: "{{ quantity }} 수량이 {{ date }}에 의해 배송될 예정입니다."
  }
};
var s4omScheduleLines$b = {
  s4omScheduleLines: s4omScheduleLines$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  s4omScheduleLines: s4omScheduleLines$b
};
var s4omScheduleLines$8 = {
  quantity: "Ilość",
  a11y: {
    scheduleLineEntryInfo: "{{ quantity }} ilość zostanie dostarczona przez {{ date }}"
  }
};
var s4omScheduleLines$9 = {
  s4omScheduleLines: s4omScheduleLines$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  s4omScheduleLines: s4omScheduleLines$9
};
var s4omScheduleLines$6 = {
  quantity: "-  Qtd",
  a11y: {
    scheduleLineEntryInfo: "A quantidade {{ quantity }} será entregue até {{ date }}"
  }
};
var s4omScheduleLines$7 = {
  s4omScheduleLines: s4omScheduleLines$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  s4omScheduleLines: s4omScheduleLines$7
};
var s4omScheduleLines$4 = {
  quantity: "-  Кол.",
  a11y: {
    scheduleLineEntryInfo: "Количество {{ quantity }} будет доставлено {{ date }}"
  }
};
var s4omScheduleLines$5 = {
  s4omScheduleLines: s4omScheduleLines$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  s4omScheduleLines: s4omScheduleLines$5
};
var s4omScheduleLines$2 = {
  quantity: "- 数量",
  a11y: {
    scheduleLineEntryInfo: "数量 {{ quantity }} 将于 {{ date }} 前交付"
  }
};
var s4omScheduleLines$3 = {
  s4omScheduleLines: s4omScheduleLines$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  s4omScheduleLines: s4omScheduleLines$3
};
var s4omScheduleLines = {
  quantity: "-  數量",
  a11y: {
    scheduleLineEntryInfo: "{{ quantity }} 數量將於 {{ date }} 交貨"
  }
};
var s4omScheduleLines$1 = {
  s4omScheduleLines: s4omScheduleLines
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  s4omScheduleLines: s4omScheduleLines$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const s4omTranslationChunksConfig = {
  s4omScheduleLines: ['s4omScheduleLines']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: s4omTranslations
 *             +   resources: { en: s4omTranslationsEn }
 *               }
 *             ```
 */
const s4omTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { s4omTranslationChunksConfig, s4omTranslations, cs as s4omTranslationsCs, de as s4omTranslationsDe, en as s4omTranslationsEn, es as s4omTranslationsEs, es_CO as s4omTranslationsEs_CO, fr as s4omTranslationsFr, hi as s4omTranslationsHi, hu as s4omTranslationsHu, id as s4omTranslationsId, it as s4omTranslationsIt, ja as s4omTranslationsJa, ko as s4omTranslationsKo, pl as s4omTranslationsPl, pt as s4omTranslationsPt, ru as s4omTranslationsRu, zh as s4omTranslationsZh, zh_TW as s4omTranslationsZh_TW };
