import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RegistrationService } from '../registration.service';
import { HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { CustomerAccountService } from '../../../../core/customer-account/customer-account.service';
import { environment } from '../../../../../environments/environment';
declare const Optanon: any;

@Component({
  standalone: false,
  selector: 'app-registration-email-confirmation',
  templateUrl: './registration-email-confirmation.component.html',
  styleUrls: ['./registration-email-confirmation.component.scss'],
})
export class RegistrationEmailConfirmationComponent implements OnInit, AfterViewInit {
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    location.reload();
  }

  currentLanguage = '';
  props: any = {};
  ddWidth = '140px';
  productLine: string;
  title: string;
  subtitle: string;
  showloader: boolean = true;

  constructor(
    private custAccService: CustomerAccountService,
    private registrationService: RegistrationService,
    private activateRoute: ActivatedRoute
  ) {
    this.registrationService.hideCommonHeaderFooter();
  }

  ngOnInit(): void {
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
    });

    const currentRoute = this.activateRoute.snapshot.routeConfig?.path;

    switch (currentRoute) {
      case 'register/emailConfirmation':
        this.handleEmailConfirmation();
        break;
      case 'register/cancel':
        this.handleCancel();
        break;
      case 'register/resendEmail':
        this.handleResendEmail();
        break;
      default:
        this.title = 'Invalid Route';
        this.subtitle = 'Please try again.';
        this.showloader = false;
        break;
    }
  }

  handleEmailConfirmation() {
    this.activateRoute.queryParams
      .pipe(
        switchMap((params) =>
          this.registrationService.verifyEmailConfirmation(
            this.productLine,
            params['email'],
            params['token']
          )
        )
      )
      .subscribe(this.handleApiResponse());
  }

  handleCancel() {
    this.activateRoute.queryParams
      .pipe(
        switchMap((params) =>
          this.registrationService.cancelRegistration(
            this.productLine,
            params['email'],
            params['token']
          )
        )
      )
      .subscribe();
  }

  handleResendEmail() {
    this.activateRoute.queryParams
      .pipe(
        switchMap((params) =>
          this.registrationService.resendEmailConfirmation(
            this.productLine,
            params['email'],
            params['token']
          )
        )
      )
      .subscribe();
  }

  handleApiResponse() {
    return {
      next: (res: { statusCode: string }) => {
        if (res.statusCode === 'SUCCESS') {
          this.title = 'Email is verified';
          this.subtitle = 'Please proceed to login.';
        } else if (res.statusCode === 'ACTIVE') {
          this.title = 'Email is already validated';
          this.subtitle = 'Please proceed to login.';
        } else {
          this.title = 'Link expired';
          this.subtitle = 'Please register again.';
        }
        this.showloader = false;
      },
      error: () => {
        this.title = 'Error';
        this.subtitle = 'There is some error.';
        this.showloader = false;
      },
    };
  }

  logoClick() {
    window.location.href = '/';
  }

  ngAfterViewInit(): void {
    this.registrationService.hideCommonHeaderFooter();
  }

  onClickCookie(event: Event) {
    Optanon.ToggleInfoDisplay();
  }

  openTerms() {
    const termsDocUrl =
      environment.occBaseUrl +
      `/_ui/responsive/common/images/DSe-CommercePortalTermsofUse.pdf`;
    (window as any).open(termsDocUrl, '_blank');
  }
}