import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
} from '@spartacus/core';
import { switchMap } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomerAccountService } from '../../../../../core/customer-account/customer-account.service';
import { CommerceTypes } from '../../../../../shared/models/commerceTypes.model';

@Component({
  standalone: false,
  selector: 'ds-mini-cart-details',
  templateUrl: './mini-cart-details.component.html',
  styleUrls: ['./mini-cart-details.component.scss'],
})
export class MiniCartDetailsComponent implements OnInit {
  @Input()
  cart;

  @Output()
  closeMenu = new EventEmitter<boolean>();

  commerceTypes = CommerceTypes;

  readonly MAX_SHOW_LENGTH = 5;
  //checking whether the user is logged in or not
  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  user$: Observable<unknown>;
  userType = '';
  loadingData: any;
  productLine: string;
  @Input() showWaygate = null;

  constructor(
    private authService: AuthService,
    private userAccountFacade: UserAccountFacade,
    private globalMessageService: GlobalMessageService,
    public cref: ChangeDetectorRef,
    private customerAccService: CustomerAccountService
  ) {}
  ngOnInit(): void {
    this.customerAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
    });

    this.loadingData = true;
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          this.userType = 'current';
          return this.userAccountFacade.get();
        } else {
          this.userType = 'anonymous';
          return of(undefined);
        }
      })
    );
    this.user$.subscribe(
      (res) => {
        if (res) {
          this.userType = 'current';
        } else {
          this.userType = 'anonymous';
        }
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          10000
        );
        window.scrollTo(0, 0);
      }
    );
    if (this.userType == 'current') {
      this.loadingData = false;
    } else {
      this.loadingData = false;
    }
    this.cref.detectChanges();
  }

  closeBox() {
    if (this.cart?.commerceType == this.commerceTypes.RETURNS) {
      window.location.href = '/rma/cart';
    } else {
      window.location.href = `${this.productLine}/cart`;
    }
    this.customerAccService.disableChangeAccount.next(false);
    this.closeMenu.emit(true);
  }
}
