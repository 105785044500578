<app-waygate-breadcrumbs [breadcrumbs]="breadcrumbs"></app-waygate-breadcrumbs>

<div class="container">
  <div class="waygate-my-orders-container">
    <div class="waygate-my-orders-header">
      <div class="header-green-border"></div>
      <div class="waygate-my-order-row">
        <h1 class="waygate-my-order-title">
          {{ 'quoteCart.pageTitle' | cxTranslate }}
        </h1>
      </div>
    </div>
    <div class="background-shade">
      <div class="order-actions-container"></div>
    </div>
    <div class="cart-details-wrapper">
      <ds-quote-cart-item-list [items]="items"></ds-quote-cart-item-list>
    </div>
  </div>
</div>
