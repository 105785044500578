var checkoutScheduledReplenishment$w = {
  autoReplenishOrder: "Automatická objednávka na doplnění",
  orderType_PLACE_ORDER: "Žádná (pouze jednorázová objednávka)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Objednávka na doplnění",
  every: "Každé",
  recurrencePeriodType_DAILY: "Den/dny",
  recurrencePeriodType_WEEKLY: "Týden/týdny",
  recurrencePeriodType_MONTHLY: "Měsíc",
  dayOfMonth: "Dne",
  startOn: "Začít dne",
  repeatOnDays: "Opakovat v následujících dnech",
  duration: "Doba trvání"
};
var checkoutScheduledReplenishment$x = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$x
};
var checkoutScheduledReplenishment$u = {
  autoReplenishOrder: "Automatische Nachbestellung",
  orderType_PLACE_ORDER: "Keine (nur einmalige Bestellung)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Nachbestellung",
  every: "Alle/Jede(n)",
  recurrencePeriodType_DAILY: "Tag(e)",
  recurrencePeriodType_WEEKLY: "Woche(n)",
  recurrencePeriodType_MONTHLY: "Monat",
  dayOfMonth: "Am Tag",
  startOn: "Start am",
  repeatOnDays: "An den folgenden Tagen wiederholen",
  duration: "Dauer"
};
var checkoutScheduledReplenishment$v = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$v
};
var checkoutScheduledReplenishment$s = {
  autoReplenishOrder: "Auto Replenish Order",
  orderType_PLACE_ORDER: "None (one time order only)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Replenish Order",
  every: "Every",
  recurrencePeriodType_DAILY: "Day(s)",
  recurrencePeriodType_WEEKLY: "Week(s)",
  recurrencePeriodType_MONTHLY: "Month",
  dayOfMonth: "On day",
  startOn: "Start on",
  repeatOnDays: "Repeat on the following days",
  duration: "Duration"
};
var checkoutScheduledReplenishment$t = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$t
};
var checkoutScheduledReplenishment$q = {
  autoReplenishOrder: "Reaprovisionar pedido automáticamente",
  orderType_PLACE_ORDER: "No (solo pedido único)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Reaprovisionar pedido",
  every: "Cada",
  recurrencePeriodType_DAILY: "Días(s)",
  recurrencePeriodType_WEEKLY: "Semana(s)",
  recurrencePeriodType_MONTHLY: "Mes",
  dayOfMonth: "El día",
  startOn: "Fecha de inicio",
  repeatOnDays: "Repetir en los siguientes días",
  duration: "Duración"
};
var checkoutScheduledReplenishment$r = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$r
};
var checkoutScheduledReplenishment$o = {
  autoReplenishOrder: "Reaprovisionar pedido automáticamente",
  orderType_PLACE_ORDER: "No (solo pedido único)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Reaprovisionar pedido",
  every: "Cada",
  recurrencePeriodType_DAILY: "Días(s)",
  recurrencePeriodType_WEEKLY: "Semana(s)",
  recurrencePeriodType_MONTHLY: "Mes",
  dayOfMonth: "El día",
  startOn: "Fecha de inicio",
  repeatOnDays: "Repetir en los siguientes días",
  duration: "Duración"
};
var checkoutScheduledReplenishment$p = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$p
};
var checkoutScheduledReplenishment$m = {
  autoReplenishOrder: "Commande de réapprovisionnement automatique",
  orderType_PLACE_ORDER: "Aucun (commande ponctuelle uniquement)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Commande de réapprovisionnement",
  every: "Chaque",
  recurrencePeriodType_DAILY: "jour",
  recurrencePeriodType_WEEKLY: "semaine",
  recurrencePeriodType_MONTHLY: "mois",
  dayOfMonth: "À la date",
  startOn: "Début le",
  repeatOnDays: "Répéter les jours suivants",
  duration: "Durée"
};
var checkoutScheduledReplenishment$n = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$n
};
var checkoutScheduledReplenishment$k = {
  autoReplenishOrder: "स्वयं पुनःपूर्ति ऑर्डर",
  orderType_PLACE_ORDER: "कोई नहीं (केवल एक बार का ऑर्डर)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "पुनःपूर्ति ऑर्डर",
  every: "प्रत्येक",
  recurrencePeriodType_DAILY: "दिन",
  recurrencePeriodType_WEEKLY: "सप्ताह",
  recurrencePeriodType_MONTHLY: "माह",
  dayOfMonth: "दिन पर",
  startOn: "प्रारंभ दिनांक",
  repeatOnDays: "निम्न दिनों पर फिर से करें",
  duration: "अवधि"
};
var checkoutScheduledReplenishment$l = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$l
};
var checkoutScheduledReplenishment$i = {
  autoReplenishOrder: "Rendelés automatikus utánpótlása",
  orderType_PLACE_ORDER: "Nincs (csak egyszeri rendelés)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Rendelés utánpótlása",
  every: "Minden",
  recurrencePeriodType_DAILY: "Nap",
  recurrencePeriodType_WEEKLY: "Hét",
  recurrencePeriodType_MONTHLY: "Hónap",
  dayOfMonth: "Adott napon",
  startOn: "Kezdés dátuma",
  repeatOnDays: "Ismétlés a következő napokon:",
  duration: "Időtartam"
};
var checkoutScheduledReplenishment$j = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$j
};
var checkoutScheduledReplenishment$g = {
  autoReplenishOrder: "Pemesanan Pengadaan Ulang Otomatis",
  orderType_PLACE_ORDER: "Tidak ada (hanya sekali pemesanan)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Pemesanan Pengadaan Ulang",
  every: "Setiap",
  recurrencePeriodType_DAILY: "Hari",
  recurrencePeriodType_WEEKLY: "Minggu",
  recurrencePeriodType_MONTHLY: "Bulan",
  dayOfMonth: "Pada hari",
  startOn: "Dimulai pada",
  repeatOnDays: "Ulangi di hari berikutnya",
  duration: "Durasi"
};
var checkoutScheduledReplenishment$h = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$h
};
var checkoutScheduledReplenishment$e = {
  autoReplenishOrder: "Riapprovvigionamento automatico dell'ordine",
  orderType_PLACE_ORDER: "Nessuno (solo ordine una tantum)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Riapprovvigionamento dell'ordine",
  every: "Ogni",
  recurrencePeriodType_DAILY: "Giorni",
  recurrencePeriodType_WEEKLY: "Settimane",
  recurrencePeriodType_MONTHLY: "Mese",
  dayOfMonth: "Il giorno",
  startOn: "Inizia il",
  repeatOnDays: "Ripeti nei seguenti giorni",
  duration: "Durata"
};
var checkoutScheduledReplenishment$f = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$f
};
var checkoutScheduledReplenishment$c = {
  autoReplenishOrder: "自動補充注文",
  orderType_PLACE_ORDER: "なし (ワンタイム注文のみ)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "補充注文",
  every: "次の期間ごと",
  recurrencePeriodType_DAILY: "日",
  recurrencePeriodType_WEEKLY: "週",
  recurrencePeriodType_MONTHLY: "月",
  dayOfMonth: "特定の日にち",
  startOn: "開始日",
  repeatOnDays: "以下の曜日に繰り返し",
  duration: "期間"
};
var checkoutScheduledReplenishment$d = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$d
};
var checkoutScheduledReplenishment$a = {
  autoReplenishOrder: "주문 자동 보충",
  orderType_PLACE_ORDER: "없음(한 번만 주문)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "주문 보충",
  every: "주기",
  recurrencePeriodType_DAILY: "일",
  recurrencePeriodType_WEEKLY: "주",
  recurrencePeriodType_MONTHLY: "개월",
  dayOfMonth: "당일",
  startOn: "시작 날짜",
  repeatOnDays: "다음 날짜에 반복",
  duration: "기간"
};
var checkoutScheduledReplenishment$b = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$b
};
var checkoutScheduledReplenishment$8 = {
  autoReplenishOrder: "Automatyczne zlecenie uzupełnienia",
  orderType_PLACE_ORDER: "Brak (tylko zlecenie jednorazowe)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Zlecenie uzupełniania",
  every: "Co",
  recurrencePeriodType_DAILY: "Dni",
  recurrencePeriodType_WEEKLY: "Tygodnie",
  recurrencePeriodType_MONTHLY: "Miesiąc",
  dayOfMonth: "Dnia",
  startOn: "Data rozpoczęcia",
  repeatOnDays: "Powtarzaj w następne dni",
  duration: "Czas trwania"
};
var checkoutScheduledReplenishment$9 = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$9
};
var checkoutScheduledReplenishment$6 = {
  autoReplenishOrder: "Pedido de reabastecimento automático",
  orderType_PLACE_ORDER: "Nenhum (só pedido único)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Pedido de reabastecimento",
  every: "Cada",
  recurrencePeriodType_DAILY: "Dia(s)",
  recurrencePeriodType_WEEKLY: "Semana(s)",
  recurrencePeriodType_MONTHLY: "Mês",
  dayOfMonth: "No dia",
  startOn: "Iniciar em",
  repeatOnDays: "Repetir nos dias seguintes",
  duration: "Duração"
};
var checkoutScheduledReplenishment$7 = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$7
};
var checkoutScheduledReplenishment$4 = {
  autoReplenishOrder: "Заказ с автоматическим пополнением",
  orderType_PLACE_ORDER: "Нет (разовый заказ)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Пополнение заказа",
  every: "Кажд.",
  recurrencePeriodType_DAILY: "дн.",
  recurrencePeriodType_WEEKLY: "нед.",
  recurrencePeriodType_MONTHLY: "месяц",
  dayOfMonth: "день месяца",
  startOn: "Начало",
  repeatOnDays: "Повторять по следующим дням",
  duration: "Продолжительность"
};
var checkoutScheduledReplenishment$5 = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$5
};
var checkoutScheduledReplenishment$2 = {
  autoReplenishOrder: "自动补货订单",
  orderType_PLACE_ORDER: "无（仅限一次订单）",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "补货订单",
  every: "每",
  recurrencePeriodType_DAILY: "天",
  recurrencePeriodType_WEEKLY: "周",
  recurrencePeriodType_MONTHLY: "月",
  dayOfMonth: "日期",
  startOn: "开始日期",
  repeatOnDays: "以下日期重复",
  duration: "持续时间"
};
var checkoutScheduledReplenishment$3 = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$3
};
var checkoutScheduledReplenishment = {
  autoReplenishOrder: "自動補貨訂單",
  orderType_PLACE_ORDER: "無 (僅一次性訂單)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "補貨訂單",
  every: "每",
  recurrencePeriodType_DAILY: "天",
  recurrencePeriodType_WEEKLY: "週",
  recurrencePeriodType_MONTHLY: "月",
  dayOfMonth: "於日期",
  startOn: "開始日期",
  repeatOnDays: "於下列日期重複",
  duration: "持續期"
};
var checkoutScheduledReplenishment$1 = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  checkoutScheduledReplenishment: checkoutScheduledReplenishment$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const checkoutScheduledReplenishmentTranslationChunksConfig = {
  checkoutScheduledReplenishment: ['checkoutScheduledReplenishment']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: checkoutScheduledReplenishmentTranslations
 *             +   resources: { en: checkoutScheduledReplenishmentTranslationsEn }
 *               }
 *             ```
 */
const checkoutScheduledReplenishmentTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { checkoutScheduledReplenishmentTranslationChunksConfig, checkoutScheduledReplenishmentTranslations, cs as checkoutScheduledReplenishmentTranslationsCs, de as checkoutScheduledReplenishmentTranslationsDe, en as checkoutScheduledReplenishmentTranslationsEn, es as checkoutScheduledReplenishmentTranslationsEs, es_CO as checkoutScheduledReplenishmentTranslationsEs_CO, fr as checkoutScheduledReplenishmentTranslationsFr, hi as checkoutScheduledReplenishmentTranslationsHi, hu as checkoutScheduledReplenishmentTranslationsHu, id as checkoutScheduledReplenishmentTranslationsId, it as checkoutScheduledReplenishmentTranslationsIt, ja as checkoutScheduledReplenishmentTranslationsJa, ko as checkoutScheduledReplenishmentTranslationsKo, pl as checkoutScheduledReplenishmentTranslationsPl, pt as checkoutScheduledReplenishmentTranslationsPt, ru as checkoutScheduledReplenishmentTranslationsRu, zh as checkoutScheduledReplenishmentTranslationsZh, zh_TW as checkoutScheduledReplenishmentTranslationsZh_TW };
