var bulkPricingTable$g = {
  quantity: "Množství",
  price: "Cena",
  discount: "Sleva"
};
var bulkPricing$g = {
  bulkPricingTable: bulkPricingTable$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  bulkPricing: bulkPricing$g
};
var bulkPricingTable$f = {
  quantity: "Menge",
  price: "Preis",
  discount: "Rabatt"
};
var bulkPricing$f = {
  bulkPricingTable: bulkPricingTable$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  bulkPricing: bulkPricing$f
};
var bulkPricingTable$e = {
  quantity: "Quantity",
  price: "Price",
  discount: "Discount"
};
var bulkPricing$e = {
  bulkPricingTable: bulkPricingTable$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  bulkPricing: bulkPricing$e
};
var bulkPricingTable$d = {
  quantity: "Cantidad",
  price: "Precio",
  discount: "Descuento"
};
var bulkPricing$d = {
  bulkPricingTable: bulkPricingTable$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  bulkPricing: bulkPricing$d
};
var bulkPricingTable$c = {
  quantity: "Cantidad",
  price: "Precio",
  discount: "Descuento"
};
var bulkPricing$c = {
  bulkPricingTable: bulkPricingTable$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  bulkPricing: bulkPricing$c
};
var bulkPricingTable$b = {
  quantity: "Quantité",
  price: "Prix",
  discount: "Remise"
};
var bulkPricing$b = {
  bulkPricingTable: bulkPricingTable$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  bulkPricing: bulkPricing$b
};
var bulkPricingTable$a = {
  quantity: "मात्रा",
  price: "मूल्य",
  discount: "छूट"
};
var bulkPricing$a = {
  bulkPricingTable: bulkPricingTable$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  bulkPricing: bulkPricing$a
};
var bulkPricingTable$9 = {
  quantity: "Mennyiség",
  price: "Ár",
  discount: "Engedmény"
};
var bulkPricing$9 = {
  bulkPricingTable: bulkPricingTable$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  bulkPricing: bulkPricing$9
};
var bulkPricingTable$8 = {
  quantity: "Jumlah",
  price: "Harga",
  discount: "Diskon"
};
var bulkPricing$8 = {
  bulkPricingTable: bulkPricingTable$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  bulkPricing: bulkPricing$8
};
var bulkPricingTable$7 = {
  quantity: "Quantità",
  price: "Prezzo",
  discount: "Sconto"
};
var bulkPricing$7 = {
  bulkPricingTable: bulkPricingTable$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  bulkPricing: bulkPricing$7
};
var bulkPricingTable$6 = {
  quantity: "数量",
  price: "価格",
  discount: "値引"
};
var bulkPricing$6 = {
  bulkPricingTable: bulkPricingTable$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  bulkPricing: bulkPricing$6
};
var bulkPricingTable$5 = {
  quantity: "수량",
  price: "가격",
  discount: "할인"
};
var bulkPricing$5 = {
  bulkPricingTable: bulkPricingTable$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  bulkPricing: bulkPricing$5
};
var bulkPricingTable$4 = {
  quantity: "Ilość",
  price: "Cena",
  discount: "Rabat"
};
var bulkPricing$4 = {
  bulkPricingTable: bulkPricingTable$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  bulkPricing: bulkPricing$4
};
var bulkPricingTable$3 = {
  quantity: "Quantidade",
  price: "Preço",
  discount: "Desconto"
};
var bulkPricing$3 = {
  bulkPricingTable: bulkPricingTable$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  bulkPricing: bulkPricing$3
};
var bulkPricingTable$2 = {
  quantity: "Количество",
  price: "Цена",
  discount: "Скидка"
};
var bulkPricing$2 = {
  bulkPricingTable: bulkPricingTable$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  bulkPricing: bulkPricing$2
};
var bulkPricingTable$1 = {
  quantity: "数量",
  price: "价格",
  discount: "折扣"
};
var bulkPricing$1 = {
  bulkPricingTable: bulkPricingTable$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  bulkPricing: bulkPricing$1
};
var bulkPricingTable = {
  quantity: "數量",
  price: "價格",
  discount: "折扣"
};
var bulkPricing = {
  bulkPricingTable: bulkPricingTable
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  bulkPricing
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const bulkPricingTranslationChunksConfig = {
  bulkPricing: ['bulkPricingTable']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: bulkPricingTranslations
 *             +   resources: { en: bulkPricingTranslationsEn }
 *               }
 *             ```
 */
const bulkPricingTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { bulkPricingTranslationChunksConfig, bulkPricingTranslations, cs as bulkPricingTranslationsCs, de as bulkPricingTranslationsDe, en as bulkPricingTranslationsEn, es as bulkPricingTranslationsEs, es_CO as bulkPricingTranslationsEs_CO, fr as bulkPricingTranslationsFr, hi as bulkPricingTranslationsHi, hu as bulkPricingTranslationsHu, id as bulkPricingTranslationsId, it as bulkPricingTranslationsIt, ja as bulkPricingTranslationsJa, ko as bulkPricingTranslationsKo, pl as bulkPricingTranslationsPl, pt as bulkPricingTranslationsPt, ru as bulkPricingTranslationsRu, zh as bulkPricingTranslationsZh, zh_TW as bulkPricingTranslationsZh_TW };
