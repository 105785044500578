var loginForm$g = {
  forgotPassword: "Zapomněli jste heslo?",
  signIn: "Přihlásit se",
  register: "Registrovat",
  dontHaveAccount: "Nemáte účet?",
  guestCheckout: "Kontrola a potvrzení pro hosty",
  emailAddress: {
    label: "E-mailová adresa",
    placeholder: "Zadejte e-mailovou adresu"
  },
  password: {
    label: "Heslo",
    placeholder: "Zadejte své heslo"
  },
  wrongEmailFormat: "Toto není platný formát e-mailu."
};
var verificationTokenForm$g = {
  createVerificationToken: "Ověřovací kód zaslán na {{target}}. Zkontrolujte a zadejte kód.",
  needInputCredentials: "Chcete-li pokračovat, zadejte své přihlašovací údaje.",
  sendRateLime: "{{waitTime}} s",
  resend: "Odeslat znovu",
  verificationCode: {
    label: "Ověřovací kód",
    placeholder: "Zadejte ověřovací kód"
  },
  noReceiveCode: "Neobdrželi jste kód?",
  verify: "Ověřit",
  back: "Zpět",
  tokenInputHint: "Můžete si vyžádat nový kód. Spustí se časovač, který bude ukazovat, kolik sekund zbývá, než budete moci požadavek opakovat."
};
var verificationTokenDialog$g = {
  title: "Neobdrželi jste kód?",
  noReceiveCode: "Pokud jste neobdrželi kód, může to být z následujících důvodů:",
  contentLine1: "1. E-mail ještě nedorazil.",
  contentLine2: "2. Přijatý e-mail byl zařazen do spamu.",
  contentLine3: "3. Zadali jste nesprávný e-mail nebo heslo.",
  close: "Zavřít"
};
var miniLogin$g = {
  userGreeting: "Dobrý den, {{name}}",
  signInRegister: "Přihlásit se / zaregistrovat se"
};
var myAccountV2User$g = {
  signOut: "Odhlásit se"
};
var userAccount$g = {
  loginForm: loginForm$g,
  verificationTokenForm: verificationTokenForm$g,
  verificationTokenDialog: verificationTokenDialog$g,
  miniLogin: miniLogin$g,
  myAccountV2User: myAccountV2User$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  userAccount: userAccount$g
};
var loginForm$f = {
  forgotPassword: "Kennwort vergessen?",
  signIn: "Anmelden",
  register: "Registrieren",
  dontHaveAccount: "Kein Konto?",
  guestCheckout: "Als Gast bezahlen",
  emailAddress: {
    label: "E-Mail-Adresse",
    placeholder: "E-Mail-Adresse eingeben"
  },
  password: {
    label: "Kennwort",
    placeholder: "Kennwort eingeben"
  },
  wrongEmailFormat: "Dies ist kein gültiges E-Mail-Format."
};
var verificationTokenForm$f = {
  createVerificationToken: "Verifizierungscode wurde an {{target}} gesendet. Überprüfen Sie den Code, und geben Sie ihn ein.",
  needInputCredentials: "Geben Sie Ihre Anmeldedaten ein, um fortzufahren.",
  sendRateLime: "in {{waitTime}} Sekunden",
  resend: "Erneut senden",
  verificationCode: {
    label: "Verifizierungscode",
    placeholder: "Verifizierungscode eingeben"
  },
  noReceiveCode: "Haben Sie den Code nicht erhalten?",
  verify: "Verifizieren",
  back: "Zurück",
  tokenInputHint: "Sie können jetzt einen neuen Code anfordern. Ein Timer startet und zeigt die verbleibenden Sekunden an, bis Sie Ihre Anforderung erneut senden können. "
};
var verificationTokenDialog$f = {
  title: "Code nicht erhalten",
  noReceiveCode: "Wenn Sie den Code nicht erhalten haben, kann dies folgende Gründe haben:",
  contentLine1: "1. Die E-Mail ist noch nicht gekommen.",
  contentLine2: "2. Die eingegangene E-Mail wurde als Spam behandelt.",
  contentLine3: "3. Die E-Mail-Adresse oder das eingegebene Kennwort ist nicht korrekt.",
  close: "Schließen"
};
var miniLogin$f = {
  userGreeting: "Hallo {{name}}",
  signInRegister: "Anmelden/Registrieren"
};
var myAccountV2User$f = {
  signOut: "Abmelden"
};
var userAccount$f = {
  loginForm: loginForm$f,
  verificationTokenForm: verificationTokenForm$f,
  verificationTokenDialog: verificationTokenDialog$f,
  miniLogin: miniLogin$f,
  myAccountV2User: myAccountV2User$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  userAccount: userAccount$f
};
var loginForm$e = {
  forgotPassword: "Forgot password?",
  signIn: "Sign In",
  register: "Register",
  dontHaveAccount: "Don’t have an account?",
  guestCheckout: "Guest Checkout",
  emailAddress: {
    label: "Email address",
    placeholder: "Enter Email Address"
  },
  password: {
    label: "Password",
    placeholder: "Enter Your Password"
  },
  wrongEmailFormat: "This is not a valid email format."
};
var verificationTokenForm$e = {
  createVerificationToken: "Verification code has been sent to {{target}}. Please enter the code.",
  needInputCredentials: "Please enter your credentials to continue.",
  sendRateLime: "in {{waitTime}} seconds",
  resend: "Resend",
  verificationCode: {
    label: "Verification Code",
    placeholder: "Enter Verification Code"
  },
  noReceiveCode: "Didn't receive the code?",
  verify: "Verify",
  registerVerify: "Register",
  back: "Back",
  tokenInputHint: "You can request a new code. A timer starts indicating the seconds left until you can resend your request."
};
var verificationTokenDialog$e = {
  title: "Didn’t receive the code",
  noReceiveCode: "If you didn't receive the code, the reasons might be:",
  contentLine1: "1. The email hasn't come yet.",
  contentLine2: "2. The received email has been treated as junk email.",
  contentLine3: "3. Either the email address or the password you entered is incorrect.",
  close: "Close"
};
var miniLogin$e = {
  userGreeting: "Hi, {{name}}",
  signInRegister: "Sign In / Register"
};
var myAccountV2User$e = {
  signOut: "Sign Out"
};
var userAccount$e = {
  loginForm: loginForm$e,
  verificationTokenForm: verificationTokenForm$e,
  verificationTokenDialog: verificationTokenDialog$e,
  miniLogin: miniLogin$e,
  myAccountV2User: myAccountV2User$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  userAccount: userAccount$e
};
var loginForm$d = {
  forgotPassword: "¿Olvidó la contraseña?",
  signIn: "Iniciar sesión",
  register: "Registrarse",
  dontHaveAccount: "¿No tiene una cuenta?",
  guestCheckout: "Registro de invitado",
  emailAddress: {
    label: "Dirección de correo electrónico",
    placeholder: "Introduzca dirección de correo electrónico"
  },
  password: {
    label: "Contraseña",
    placeholder: "Introduzca su contraseña"
  },
  wrongEmailFormat: "Este no es un formato de correo electrónico válido."
};
var verificationTokenForm$d = {
  createVerificationToken: "Código de verificación enviado a {{target}}. Verifique e introduzca el código.",
  needInputCredentials: "Introduzca sus credenciales para continuar.",
  sendRateLime: "En {{waitTime}} segundos",
  resend: "Reenviar",
  verificationCode: {
    label: "Código de verificación",
    placeholder: "Introduzca código de verificación"
  },
  noReceiveCode: "¿No recibió el código?",
  verify: "Verificar",
  back: "Atrás",
  tokenInputHint: "Puede solicitar un nuevo código. Se inicia un temporizador que indica los segundos que faltan para que pueda volver a enviar su solicitud."
};
var verificationTokenDialog$d = {
  title: "No recibí un código",
  noReceiveCode: "Si no recibió un código, estas podrían ser las causas:",
  contentLine1: "1. El correo electrónico no ha llegado.",
  contentLine2: "2. El correo electrónico recibido ha sido tratado como correo no deseado.",
  contentLine3: "3. La dirección de correo electrónico o la contraseña introducidas son incorrectas.",
  close: "Cerrar"
};
var miniLogin$d = {
  userGreeting: "Hola, {{name}}",
  signInRegister: "Iniciar sesión / Registrarse"
};
var myAccountV2User$d = {
  signOut: "Cerrar sesión"
};
var userAccount$d = {
  loginForm: loginForm$d,
  verificationTokenForm: verificationTokenForm$d,
  verificationTokenDialog: verificationTokenDialog$d,
  miniLogin: miniLogin$d,
  myAccountV2User: myAccountV2User$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  userAccount: userAccount$d
};
var loginForm$c = {
  forgotPassword: "¿Olvidó la contraseña?",
  signIn: "Iniciar sesión",
  register: "Registrarse",
  dontHaveAccount: "¿No tiene una cuenta?",
  guestCheckout: "Registro de invitado",
  emailAddress: {
    label: "Dirección de correo electrónico",
    placeholder: "Ingrese dirección de correo electrónico"
  },
  password: {
    label: "Contraseña",
    placeholder: "Ingrese su contraseña"
  },
  wrongEmailFormat: "Este no es un formato de correo electrónico válido."
};
var verificationTokenForm$c = {
  createVerificationToken: "El código de verificación se envió a {{target}}. Verifique e ingrese el código.",
  needInputCredentials: "Ingrese sus credenciales para continuar.",
  sendRateLime: "En {{waitTime}} segundos",
  resend: "Reenviar",
  verificationCode: {
    label: "Código de verificación",
    placeholder: "Ingrese el código de verificación"
  },
  noReceiveCode: "¿No recibió el código?",
  verify: "Verificar",
  back: "Atrás",
  tokenInputHint: "Puede solicitar un nuevo código. Se inicia un temporizador que indica los segundos que faltan para que pueda volver a enviar su solicitud."
};
var verificationTokenDialog$c = {
  title: "No recibí un código",
  noReceiveCode: "Si no recibió un código, estas podrían ser las causas:",
  contentLine1: "1. El correo electrónico no ha llegado.",
  contentLine2: "2. El correo electrónico recibido ha sido tratado como correo no deseado.",
  contentLine3: "3. La dirección de correo electrónico o la contraseña ingresadas son incorrectas.",
  close: "Cerrar"
};
var miniLogin$c = {
  userGreeting: "Hola, {{name}}",
  signInRegister: "Iniciar sesión / Registrarse"
};
var myAccountV2User$c = {
  signOut: "Cerrar sesión"
};
var userAccount$c = {
  loginForm: loginForm$c,
  verificationTokenForm: verificationTokenForm$c,
  verificationTokenDialog: verificationTokenDialog$c,
  miniLogin: miniLogin$c,
  myAccountV2User: myAccountV2User$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  userAccount: userAccount$c
};
var loginForm$b = {
  forgotPassword: "Mot de passe oublié ?",
  signIn: "Se connecter",
  register: "S'inscrire",
  dontHaveAccount: "Vous n'avez pas de compte ?",
  guestCheckout: "Validation de commande en tant qu'invité",
  emailAddress: {
    label: "Adresse e-mail",
    placeholder: "Saisir l'adresse e-mail"
  },
  password: {
    label: "Mot de passe",
    placeholder: "Saisissez votre mot de passe"
  },
  wrongEmailFormat: "Le format d'e-mail n'est pas valide."
};
var verificationTokenForm$b = {
  createVerificationToken: "Un code de vérification a été envoyé à {{target}}. Veuillez vérifier et saisir le code.",
  needInputCredentials: "Veuillez saisir vos identifiants pour continuer.",
  sendRateLime: "dans {{waitTime}} secondes",
  resend: "Renvoyer",
  verificationCode: {
    label: "Code de vérification",
    placeholder: "Saisir le code de vérification"
  },
  noReceiveCode: "Vous n'avez pas reçu le code ?",
  verify: "Vérifier",
  back: "Retour",
  tokenInputHint: "Vous pouvez demander un nouveau code. Un chronomètre démarre pour indiquer le nombre de secondes restantes avant que vous ne puissiez renvoyer votre demande."
};
var verificationTokenDialog$b = {
  title: "Vous n'avez pas reçu le code ?",
  noReceiveCode: "Si vous n'avez pas reçu le code, les raisons peuvent être les suivantes :",
  contentLine1: "1. L'e-mail n'est pas encore arrivé.",
  contentLine2: "2. L'e-mail reçu a été considéré comme du spam.",
  contentLine3: "3. Vous avez saisi une adresse e-mail ou un mot de passe incorrect(e).",
  close: "Fermer"
};
var miniLogin$b = {
  userGreeting: "Bonjour, {{name}}",
  signInRegister: "Se connecter / S'inscrire"
};
var myAccountV2User$b = {
  signOut: "Se déconnecter"
};
var userAccount$b = {
  loginForm: loginForm$b,
  verificationTokenForm: verificationTokenForm$b,
  verificationTokenDialog: verificationTokenDialog$b,
  miniLogin: miniLogin$b,
  myAccountV2User: myAccountV2User$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  userAccount: userAccount$b
};
var loginForm$a = {
  forgotPassword: "पासवर्ड भूल गए हैं?",
  signIn: "साइन इन करें",
  register: "पंजीकृत करें",
  dontHaveAccount: "अभी तक खाता नहीं है?",
  guestCheckout: "गेस्ट चेकआउट",
  emailAddress: {
    label: "ईमेल पता",
    placeholder: "ईमेल पता दर्ज करें"
  },
  password: {
    label: "पासवर्ड",
    placeholder: "अपना पासवर्ड दर्ज करें"
  },
  wrongEmailFormat: "यह मान्य ई-मेल स्वरूप नहीं है."
};
var verificationTokenForm$a = {
  createVerificationToken: "सत्यापन कोड {{target}} को भेजा गया. कृपया जांचें और कोड दर्ज करें.",
  needInputCredentials: "जारी रखने के लिए कृपया अपना परिचय दर्ज करें.",
  sendRateLime: "{{waitTime}} सेकंड में",
  resend: "पुनः भेजें",
  verificationCode: {
    label: "सत्यापन कोड",
    placeholder: "सत्यापन कोड दाखिल करें"
  },
  noReceiveCode: "कोड प्राप्त नहीं हुआ?",
  verify: "सत्यापित करें",
  back: "वापस जाएं",
  tokenInputHint: "आप नए कोड का अनुरोध कर सकते हैं.  एक टाइमर आरंभ होता है जो ये बताता है कि आपके पास अपना अनुरोध पुनः भेजने के लिए कितने सेकंड शेष हैं."
};
var verificationTokenDialog$a = {
  title: "कोड प्राप्त नहीं हुआ",
  noReceiveCode: "यदि आपको कोड प्राप्त नहीं हुआ, ये कारण हो सकते हैंः",
  contentLine1: "1. ई-मेल अभी तक नहीं आया है.",
  contentLine2: "2. प्राप्त ई-मेल को जंक ई-मेल माना गया है.",
  contentLine3: "3. आपके द्वारा दर्ज ई-मेल पता या पासवर्ड गलत हो.",
  close: "बंद करें"
};
var miniLogin$a = {
  userGreeting: "हाय, {{name}}",
  signInRegister: "साइन-इन / रजिस्टर करें"
};
var myAccountV2User$a = {
  signOut: "साइन आउट करें"
};
var userAccount$a = {
  loginForm: loginForm$a,
  verificationTokenForm: verificationTokenForm$a,
  verificationTokenDialog: verificationTokenDialog$a,
  miniLogin: miniLogin$a,
  myAccountV2User: myAccountV2User$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  userAccount: userAccount$a
};
var loginForm$9 = {
  forgotPassword: "Elfelejtette a jelszavát?",
  signIn: "Bejelentkezés",
  register: "Regisztráció",
  dontHaveAccount: "Nincs fiókja?",
  guestCheckout: "Fizetés vendégként",
  emailAddress: {
    label: "E-mail-cím",
    placeholder: "Adja meg az e-mail-címet"
  },
  password: {
    label: "Jelszó",
    placeholder: "Adja meg jelszavát"
  },
  wrongEmailFormat: "Ez nem egy érvényes e-mail-formátum."
};
var verificationTokenForm$9 = {
  createVerificationToken: "Ellenőrző kód elküldve a következő címre: {{target}}. Ellenőrizze, és adja meg a kódot.",
  needInputCredentials: "A folytatáshoz adja meg a hitelesítő adatait.",
  sendRateLime: "{{waitTime}} másodpercen belül",
  resend: "Újraküldés",
  verificationCode: {
    label: "Ellenőrző kód",
    placeholder: "Adja meg az ellenőrző kódot"
  },
  noReceiveCode: "Nem kapta meg a kódot?",
  verify: "Ellenőrzés",
  back: "Vissza",
  tokenInputHint: "Igényelhet egy új kódot. Egy időzítő jelzi, hogy mikor kérheti a kód ismételt elküldését."
};
var verificationTokenDialog$9 = {
  title: "Nem kaptam meg a kódot",
  noReceiveCode: "Lehetséges okok, amiért nem kapta meg a kódot:",
  contentLine1: "1. Az e-mail még nem érkezett meg.",
  contentLine2: "2. A kapott e-mail a levélszemét mappába került.",
  contentLine3: "3. A megadott e-mail-cím vagy jelszó helytelen.",
  close: "Bezárás"
};
var miniLogin$9 = {
  userGreeting: "Üdv, {{name}}!",
  signInRegister: "Bejelentkezés / Regisztráció"
};
var myAccountV2User$9 = {
  signOut: "Kijelentkezés"
};
var userAccount$9 = {
  loginForm: loginForm$9,
  verificationTokenForm: verificationTokenForm$9,
  verificationTokenDialog: verificationTokenDialog$9,
  miniLogin: miniLogin$9,
  myAccountV2User: myAccountV2User$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  userAccount: userAccount$9
};
var loginForm$8 = {
  forgotPassword: "Lupa kata sandi?",
  signIn: "Masuk",
  register: "Daftar",
  dontHaveAccount: "Tidak memiliki akun?",
  guestCheckout: "Pembayaran Tanpa Akun",
  emailAddress: {
    label: "Alamat email",
    placeholder: "Masukkan Alamat Email"
  },
  password: {
    label: "Kata Sandi",
    placeholder: "Masukkan Kata Sandi Anda"
  },
  wrongEmailFormat: "Format email ini tidak valid."
};
var verificationTokenForm$8 = {
  createVerificationToken: "Kode verifikasi telah dikirim ke {{target}}. Silakan periksa dan masukkan kode tersebut.",
  needInputCredentials: "Silakan masukkan kredensial Anda untuk melanjutkan.",
  sendRateLime: "dalam {{waitTime}} detik ",
  resend: "Kirimkan Ulang",
  verificationCode: {
    label: "Kode Verifikasi",
    placeholder: "Masukkan Kode Verifikasi"
  },
  noReceiveCode: "Tidak menerima kode?",
  verify: "Verifikasi",
  back: "Kembali",
  tokenInputHint: "Anda dapat meminta kode baru. Penghitung waktu akan mulai menampilkan berapa lama Anda perlu menunggu sebelum dapat meminta kode baru."
};
var verificationTokenDialog$8 = {
  title: "Tidak menerima kode",
  noReceiveCode: "Jika Anda tidak menerima kode, kemungkinan alasannya adalah:",
  contentLine1: "1. Emailnya mungkin belum diterima.",
  contentLine2: "2. Email yang diterima mungkin masuk ke folder sampah.",
  contentLine3: "3. Alamat email atau kata sandi yang Anda masukkan mungkin salah.",
  close: "Tutup"
};
var miniLogin$8 = {
  userGreeting: "Hai, {{name}}",
  signInRegister: "Masuk / Daftar"
};
var myAccountV2User$8 = {
  signOut: "Keluar"
};
var userAccount$8 = {
  loginForm: loginForm$8,
  verificationTokenForm: verificationTokenForm$8,
  verificationTokenDialog: verificationTokenDialog$8,
  miniLogin: miniLogin$8,
  myAccountV2User: myAccountV2User$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  userAccount: userAccount$8
};
var loginForm$7 = {
  forgotPassword: "Password dimenticata?",
  signIn: "Accedi",
  register: "Registrati",
  dontHaveAccount: "Non si dispone di un account?",
  guestCheckout: "Check-out ospite",
  emailAddress: {
    label: "Indirizzo e-mail",
    placeholder: "Inserisci l'indirizzo e-mail"
  },
  password: {
    label: "Password",
    placeholder: "Inserisci la password"
  },
  wrongEmailFormat: "Formato e-mail non valido."
};
var verificationTokenForm$7 = {
  createVerificationToken: "Codice di verifica inviato a {{target}}. Controllare e inserire il codice.",
  needInputCredentials: "Per continuare, inserire le credenziali.",
  sendRateLime: "tra {{waitTime}} secondi",
  resend: "Invia nuovamente",
  verificationCode: {
    label: "Codice di verifica",
    placeholder: "Inserisci codice di verifica"
  },
  noReceiveCode: "Codice non ricevuto?",
  verify: "Verifica",
  back: "Indietro",
  tokenInputHint: "È possibile richiedere un nuovo codice. Parte un timer che indica tra quanti secondi è possibile inviare nuovamente la richiesta."
};
var verificationTokenDialog$7 = {
  title: "Codice non ricevuto?",
  noReceiveCode: "Se il codice non è stato ricevuto, i motivi potrebbero essere i seguenti:",
  contentLine1: "1. L'e-mail non è ancora stata ricevuta.",
  contentLine2: "2. L'e-mail ricevuta è stata ritenuta posta indesiderata.",
  contentLine3: "3. L'indirizzo e-mail o la password inseriti non sono corretti.",
  close: "Chiudi"
};
var miniLogin$7 = {
  userGreeting: "Ciao, {{name}}",
  signInRegister: "Accedi/Registrati"
};
var myAccountV2User$7 = {
  signOut: "Esci"
};
var userAccount$7 = {
  loginForm: loginForm$7,
  verificationTokenForm: verificationTokenForm$7,
  verificationTokenDialog: verificationTokenDialog$7,
  miniLogin: miniLogin$7,
  myAccountV2User: myAccountV2User$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  userAccount: userAccount$7
};
var loginForm$6 = {
  forgotPassword: "パスワードを忘れた場合",
  signIn: "サインイン",
  register: "登録",
  dontHaveAccount: "アカウントをお持ちでない場合",
  guestCheckout: "ゲストでチェックアウト",
  emailAddress: {
    label: "電子メールアドレス",
    placeholder: "電子メールアドレスを入力"
  },
  password: {
    label: "パスワード",
    placeholder: "パスワードを入力"
  },
  wrongEmailFormat: "これは有効な電子メール形式ではありません。"
};
var verificationTokenForm$6 = {
  createVerificationToken: "認証コードが {{target}} に送信されました。確認してコードを入力してください。",
  needInputCredentials: "続行するには、認証情報を入力してください。",
  sendRateLime: "{{waitTime}} 秒以内",
  resend: "再送信",
  verificationCode: {
    label: "認証コード",
    placeholder: "認証コードを入力"
  },
  noReceiveCode: "コードが受信されない場合",
  verify: "認証",
  back: "戻る",
  tokenInputHint: "新しいコードを依頼することができます。依頼を再送信できる残りの秒数を示すタイマーが開始されます。"
};
var verificationTokenDialog$6 = {
  title: "コードが受信されなかった場合",
  noReceiveCode: "コードが受信されなかった場合、以下の理由が考えられます。",
  contentLine1: "1. 電子メールがまだ届いていない。",
  contentLine2: "2. 受信した電子メールが迷惑メールとして処理された。",
  contentLine3: "3. 入力した電子メールアドレス、またはパスワードのどちらかが間違っていた。",
  close: "閉じる"
};
var miniLogin$6 = {
  userGreeting: "{{name}} さん、こんにちは",
  signInRegister: "サインイン/登録"
};
var myAccountV2User$6 = {
  signOut: "サインアウト"
};
var userAccount$6 = {
  loginForm: loginForm$6,
  verificationTokenForm: verificationTokenForm$6,
  verificationTokenDialog: verificationTokenDialog$6,
  miniLogin: miniLogin$6,
  myAccountV2User: myAccountV2User$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  userAccount: userAccount$6
};
var loginForm$5 = {
  forgotPassword: "암호 분실",
  signIn: "로그인",
  register: "등록",
  dontHaveAccount: "계정이 없습니까?",
  guestCheckout: "게스트 체크아웃",
  emailAddress: {
    label: "이메일 주소",
    placeholder: "이메일 주소 입력"
  },
  password: {
    label: "암호",
    placeholder: "암호 입력"
  },
  wrongEmailFormat: "올바른 이메일 형식이 아닙니다."
};
var verificationTokenForm$5 = {
  createVerificationToken: "{{target}}에 인증 코드를 보냈습니다. 확인하고 코드를 입력하십시오.",
  needInputCredentials: "계속하려면 자격 증명을 입력하십시오.",
  sendRateLime: "{{waitTime}}초 후",
  resend: "다시 보내기",
  verificationCode: {
    label: "인증 코드",
    placeholder: "인증 코드 입력"
  },
  noReceiveCode: "코드를 받지 못하셨습니까?",
  verify: "인증",
  back: "뒤로",
  tokenInputHint: "새 코드를 요청할 수 있습니다. 요청을 다시 보낼 수 있을 때까지 남아 있는 시간(초)를 나타내는 타이머가 시작됩니다."
};
var verificationTokenDialog$5 = {
  title: "코드를 받지 못했습니다",
  noReceiveCode: "코드를 받지 못했으면 다음과 같은 사유가 있을 수 있습니다.",
  contentLine1: "1. 이메일을 아직 받지 못했습니다.",
  contentLine2: "2. 받은 이메일이 스팸함에 있습니다.",
  contentLine3: "3. 입력한 이메일 주소나 암호가 잘못되었습니다.",
  close: "닫기"
};
var miniLogin$5 = {
  userGreeting: "안녕하세요, {{name}}님",
  signInRegister: "로그인/등록"
};
var myAccountV2User$5 = {
  signOut: "로그아웃"
};
var userAccount$5 = {
  loginForm: loginForm$5,
  verificationTokenForm: verificationTokenForm$5,
  verificationTokenDialog: verificationTokenDialog$5,
  miniLogin: miniLogin$5,
  myAccountV2User: myAccountV2User$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  userAccount: userAccount$5
};
var loginForm$4 = {
  forgotPassword: "Zapomniałeś(-aś) hasła?",
  signIn: "Zaloguj się",
  register: "Zarejestruj się",
  dontHaveAccount: "Nie masz konta?",
  guestCheckout: "Kup jako gość",
  emailAddress: {
    label: "Adres e-mail",
    placeholder: "Wprowadź adres e-mail."
  },
  password: {
    label: "Hasło",
    placeholder: "Wprowadź swoje hasło"
  },
  wrongEmailFormat: "To nie jest prawidłowy format adresu e-mail."
};
var verificationTokenForm$4 = {
  createVerificationToken: "Kod weryfikacyjny wysłany do {{target}}. Proszę sprawdź i wprowadź kod.",
  needInputCredentials: "Proszę wprowadź swoje dane uwierzytelniające, by kontynuować.",
  sendRateLime: "Za {{waitTime}} sekund(y)",
  resend: "Wyślij ponownie",
  verificationCode: {
    label: "Kod weryfikacyjny",
    placeholder: "Wprowadź kod weryfikacji"
  },
  noReceiveCode: "Kod nie dotarł?",
  verify: "Zweryfikuj",
  back: "Wstecz",
  tokenInputHint: "Możesz poprosić o nowy kod. Uruchomi się licznik czasu wskazujący sekundy pozostałe do ponownego wysłania żądania."
};
var verificationTokenDialog$4 = {
  title: "Kod nie dotarł?",
  noReceiveCode: "Jeśli nie otrzymałeś kodu, powodem tego może być:",
  contentLine1: "1. E-mail jeszcze nie dotarł.",
  contentLine2: "2. Otrzymany e-mail został potraktowany jako spam.",
  contentLine3: "3. Wprowadzony adres e-mail lub hasło są nieprawidłowe.",
  close: "Zamknij"
};
var miniLogin$4 = {
  userGreeting: "Witaj, {{name}}",
  signInRegister: "Zaloguj się / Zarejestruj"
};
var myAccountV2User$4 = {
  signOut: "Wyloguj się"
};
var userAccount$4 = {
  loginForm: loginForm$4,
  verificationTokenForm: verificationTokenForm$4,
  verificationTokenDialog: verificationTokenDialog$4,
  miniLogin: miniLogin$4,
  myAccountV2User: myAccountV2User$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  userAccount: userAccount$4
};
var loginForm$3 = {
  forgotPassword: "Esqueceu a senha?",
  signIn: "Iniciar sessão",
  register: "Registrar",
  dontHaveAccount: "Não tem uma conta?",
  guestCheckout: "Check-out de convidado",
  emailAddress: {
    label: "Endereço de e-mail",
    placeholder: "Inserir endereço de e-mail"
  },
  password: {
    label: "Senha",
    placeholder: "Insira sua senha"
  },
  wrongEmailFormat: "Este não é um formato de e-mail válido."
};
var verificationTokenForm$3 = {
  createVerificationToken: "Código de verificação enviado para {{target}}. Verifique e insira o código.",
  needInputCredentials: "Insira suas credenciais para continuar.",
  sendRateLime: "em {{waitTime}} segundos",
  resend: "Reenviar",
  verificationCode: {
    label: "Código de verificação",
    placeholder: "Inserir código de verificação"
  },
  noReceiveCode: "Não recebeu o código?",
  verify: "Verificar",
  back: "Voltar",
  tokenInputHint: "Você pode solicitar um novo código. Um temporizador começa a indicar os segundos restantes até que você possa reenviar sua solicitação."
};
var verificationTokenDialog$3 = {
  title: "Não recebi o código",
  noReceiveCode: "Se você não tiver recebido o código, os motivos podem ser os seguintes:",
  contentLine1: "1. O e-mail ainda não chegou.",
  contentLine2: "2. O e-mail recebido foi tratado como e-mail de lixo eletrônico.",
  contentLine3: "3. O endereço de e-mail ou a senha que você inseriu está incorreto.",
  close: "Fechar"
};
var miniLogin$3 = {
  userGreeting: "Olá, {{name}}",
  signInRegister: "Iniciar sessão/Registrar-se"
};
var myAccountV2User$3 = {
  signOut: "Encerrar sessão"
};
var userAccount$3 = {
  loginForm: loginForm$3,
  verificationTokenForm: verificationTokenForm$3,
  verificationTokenDialog: verificationTokenDialog$3,
  miniLogin: miniLogin$3,
  myAccountV2User: myAccountV2User$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  userAccount: userAccount$3
};
var loginForm$2 = {
  forgotPassword: "Забыли пароль?",
  signIn: "Вход",
  register: "Зарегистрироваться",
  dontHaveAccount: "У вас нет учетной записи?",
  guestCheckout: "Оформление заказа для гостя",
  emailAddress: {
    label: "Адрес электронной почты",
    placeholder: "Введите адрес электронной почты"
  },
  password: {
    label: "Пароль",
    placeholder: "Введите ваш пароль"
  },
  wrongEmailFormat: "Недействительный формат электронной почты."
};
var verificationTokenForm$2 = {
  createVerificationToken: "Код верификации отправлен на {{target}}. Проверьте и введите код.",
  needInputCredentials: "Чтобы продолжить, введите ваши учетные данные.",
  sendRateLime: "через {{waitTime}} сек.",
  resend: "Отправить повторно",
  verificationCode: {
    label: "Верификационный код",
    placeholder: "Введите верификационный код"
  },
  noReceiveCode: "Не получили код?",
  verify: "Проверить",
  back: "Назад",
  tokenInputHint: "Вы можете запросить новый код. Таймер начинает отсчитывать секунды, оставшиеся до повторной отправки запроса."
};
var verificationTokenDialog$2 = {
  title: "Код не получен",
  noReceiveCode: "Возможные причины того, что вы не получили код:",
  contentLine1: "1. Еще не пришло письмо на электронную почту.",
  contentLine2: "2. Полученное письмо попало в спам.",
  contentLine3: "3. Неправильно введен адрес электронной почты или пароль.",
  close: "Закрыть"
};
var miniLogin$2 = {
  userGreeting: "Здравствуйте, {{name}}",
  signInRegister: "Войти / зарегистрироваться"
};
var myAccountV2User$2 = {
  signOut: "Выйти"
};
var userAccount$2 = {
  loginForm: loginForm$2,
  verificationTokenForm: verificationTokenForm$2,
  verificationTokenDialog: verificationTokenDialog$2,
  miniLogin: miniLogin$2,
  myAccountV2User: myAccountV2User$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  userAccount: userAccount$2
};
var loginForm$1 = {
  forgotPassword: "忘记密码？",
  signIn: "登录",
  register: "注册",
  dontHaveAccount: "没有帐户？",
  guestCheckout: "客人结账",
  emailAddress: {
    label: "电子邮件地址",
    placeholder: "输入电子邮件地址"
  },
  password: {
    label: "密码",
    placeholder: "输入您的密码"
  },
  wrongEmailFormat: "电子邮件格式无效。"
};
var verificationTokenForm$1 = {
  createVerificationToken: "验证码已发送至 {{target}}。请查看并输入验证码。",
  needInputCredentials: "请输入您的凭据以继续。",
  sendRateLime: "{{waitTime}} 秒后",
  resend: "重新发送",
  verificationCode: {
    label: "验证码",
    placeholder: "输入验证码"
  },
  noReceiveCode: "没有收到验证码？",
  verify: "验证",
  back: "返回",
  tokenInputHint: "您可以请求新代码。计时器启动，指示可重新发送请求的剩余秒数。"
};
var verificationTokenDialog$1 = {
  title: "未收到验证码",
  noReceiveCode: "如果未收到验证码，原因可能如下：",
  contentLine1: "1. 电子邮件还未到。",
  contentLine2: "2. 收到的电子邮件已被视为垃圾电子邮件。",
  contentLine3: "3. 输入的电子邮件地址或密码不正确。",
  close: "关闭"
};
var miniLogin$1 = {
  userGreeting: "{{name}}，你好",
  signInRegister: "登录/注册"
};
var myAccountV2User$1 = {
  signOut: "退出"
};
var userAccount$1 = {
  loginForm: loginForm$1,
  verificationTokenForm: verificationTokenForm$1,
  verificationTokenDialog: verificationTokenDialog$1,
  miniLogin: miniLogin$1,
  myAccountV2User: myAccountV2User$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  userAccount: userAccount$1
};
var loginForm = {
  forgotPassword: "忘記密碼？",
  signIn: "登入",
  register: "註冊",
  dontHaveAccount: "沒有帳戶？",
  guestCheckout: "訪客結帳",
  emailAddress: {
    label: "電子郵件地址",
    placeholder: "輸入電子郵件地址"
  },
  password: {
    label: "密碼",
    placeholder: "輸入密碼"
  },
  wrongEmailFormat: "這不是有效的電子郵件格式。"
};
var verificationTokenForm = {
  createVerificationToken: "驗證代碼已傳送至 {{target}}，請查看並輸入代碼。",
  needInputCredentials: "請輸入憑證以繼續。",
  sendRateLime: "{{waitTime}} 秒內",
  resend: "重新傳送",
  verificationCode: {
    label: "驗證代碼",
    placeholder: "輸入驗證代碼"
  },
  noReceiveCode: "沒有收到代碼？",
  verify: "驗證",
  back: "返回",
  tokenInputHint: "您可請求新代碼。計時器將開始，表示您可重新傳送請求前的剩餘秒數。"
};
var verificationTokenDialog = {
  title: "沒有收到代碼",
  noReceiveCode: "若沒有收到代碼，原因可能是：",
  contentLine1: "1. 電子郵件尚未抵達。",
  contentLine2: "2. 收到的電子郵件已分類為垃圾電子郵件。",
  contentLine3: "3. 您輸入的電子郵件地址或密碼不正確。",
  close: "關閉"
};
var miniLogin = {
  userGreeting: "{{name}}，您好",
  signInRegister: "登入/註冊"
};
var myAccountV2User = {
  signOut: "登出"
};
var userAccount = {
  loginForm: loginForm,
  verificationTokenForm: verificationTokenForm,
  verificationTokenDialog: verificationTokenDialog,
  miniLogin: miniLogin,
  myAccountV2User: myAccountV2User
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  userAccount
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const userAccountTranslationChunksConfig = {
  userAccount: ['loginForm', 'verificationTokenForm', 'verificationTokenDialog', 'miniLogin', 'myAccountV2User']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: userAccountTranslations
 *             +   resources: { en: userAccountTranslationsEn }
 *               }
 *             ```
 */
const userAccountTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { userAccountTranslationChunksConfig, userAccountTranslations, cs as userAccountTranslationsCs, de as userAccountTranslationsDe, en as userAccountTranslationsEn, es as userAccountTranslationsEs, es_CO as userAccountTranslationsEs_CO, fr as userAccountTranslationsFr, hi as userAccountTranslationsHi, hu as userAccountTranslationsHu, id as userAccountTranslationsId, it as userAccountTranslationsIt, ja as userAccountTranslationsJa, ko as userAccountTranslationsKo, pl as userAccountTranslationsPl, pt as userAccountTranslationsPt, ru as userAccountTranslationsRu, zh as userAccountTranslationsZh, zh_TW as userAccountTranslationsZh_TW };
