import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';

@Component({
  standalone: false,
  selector: 'ds-quote-cart-item-list',
  templateUrl: './quote-cart-item-list.component.html',
  styleUrls: ['./quote-cart-item-list.component.scss'],
})
export class QuoteCartItemListComponent {
  @Input('items')
  // TODO: currently we're getting a new array of items if the cart changes.
  // pretty annoying as it forces a repaint on the screen,
  // which is noticable in the UI.
  set items(items: any[]) {
    console.log(items);
    this.resolveItems(items);
    this.createForm();
  }
  get items(): any[] {
    return this._items;
  }
  private _items: any[] = [];

  selectedItems: any[] = [];
  checkAll: boolean = false;
  form: FormGroup;

  constructor() {}

  private resolveItems(items: any[]): void {
    // if (items.every((item) => item.hasOwnProperty('orderEntry'))) {
    //   this._items = items.map((consignmentEntry) => {
    //     const entry = Object.assign(
    //       {},
    //       (consignmentEntry as ConsignmentEntry).orderEntry
    //     );
    //     entry.quantity = consignmentEntry.quantity;
    //     return entry;
    //   });
    // } else {
    this._items = items;
    // }
  }

  private createForm(): void {
    this.form = new FormGroup({});
    this._items.forEach((item) => {
      const { code } = item.product;
      const group = new FormGroup({
        entryNumber: new FormControl((<any>item).entryNumber),
        quantity: new FormControl(item.quantity, { updateOn: 'blur' }),
      });
      // if (!item.updateable || this.readonly) {
      //   group.disable();
      // }
      this.form.addControl(code, group);
    });
  }

  getControl(item: any): Observable<FormGroup> {
    console.log(item);
    return this.form.get([item.product.code])?.valueChanges.pipe(
      // tslint:disable-next-line:deprecation
      startWith(null),
      map((value) => {
        // if (value && this.activeCartFacade && this.options.isSaveForLater) {
        //   this.activeCartFacade.updateEntry(value.entryNumber, value.quantity);
        // } else if (value) {
        //   this.activeCartFacade.updateEntry(value.entryNumber, value.quantity);
        // }
      }),
      map(() => <FormGroup>this.form.get([item.product.code]))
    );
  }

  checkAllEntries(ev) {}
}
