import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  ProductScope,
  ProductService,
  GlobalMessageType,
  GlobalMessageService,
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MyFavoritesService } from '../../my-favorites/my-favorites.service';
import { CustomerAccountService } from '../../../core/customer-account/customer-account.service';
import {
  BuyActions,
  ReturnActions,
} from '../../../core/product-catalog/model/product-catelog.model';
import { GtmEvents, ItemListTypeEnum } from '../../../shared/enums/gtm.enum';
import { GTMDataLayer } from '../../../shared/models/googleTagManager.model';
import { GoogleTagManagerService } from '../../../shared/services/gtm.service';
import { ProductCatelogService } from '../../../core/product-catalog/services/product-catelog.service';
import { ProductReturnService } from '../../../core/product-catalog/services/product-return.service';
import { ApiService } from '../../../core/http/api.service';

@Component({
  standalone: false,
  selector: 'app-waygate-product-details',
  templateUrl: './waygate-product-details.component.html',
  styleUrls: ['./waygate-product-details.component.scss'],
})
export class WaygateProductDetailsComponent implements OnInit, AfterViewInit {
  user$: Observable<any>;
  params$: Observable<any>;
  queryParams$: Observable<any>;
  product$: Observable<any>;
  breadcrumbs = [];
  product: any;
  isLoggedIn: boolean;
  userType: string;
  returnActions = ReturnActions;
  isPriceAvailable: boolean;
  currentBuyAction: any;
  buyActions = BuyActions;
  currentReturnAction: any;
  productCode: string;
  leadTime: string;
  inStock: boolean;
  currentGuestAction: any;
  favStatus: boolean = false;
  breakUp = false;
  breakUpMenu = false;
  productImages: any;
  selectedImg: any;
  selectedGalleryImgIdx: number;
  productLine: string;
  isProductNotAdded = false;
  relProducts: any[] = [];

  constructor(
    private productService: ProductService,
    private auth: AuthService,
    private userAccountFacade: UserAccountFacade,
    private route: ActivatedRoute,
    private productCatelogService: ProductCatelogService,
    private currentProductService: CurrentProductService,
    private myFavouritesService: MyFavoritesService,
    private globalMessageService: GlobalMessageService,
    private cd: ChangeDetectorRef,
    private custAccService: CustomerAccountService,
    private gtmService: GoogleTagManagerService,
    private returnProdService: ProductReturnService,
    private router: Router,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
    });
    this.user$ = this.auth.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );

    this.auth.isUserLoggedIn().subscribe((success) => {
      if (success) {
        this.isLoggedIn = true;
        this.userType = 'current';
      } else {
        this.isLoggedIn = false;
        this.userType = 'anonymous';
      }
    });

    this.product$ = this.currentProductService.getProduct(ProductScope.DETAILS);

    this.product$.subscribe((response) => {
      if (response !== null && response !== undefined) {
        this.product = response;
      }
    });

    this.params$ = this.route.params;
    this.queryParams$ = this.route.queryParams;

    combineLatest(this.user$, this.queryParams$, this.params$).subscribe(
      (data) => {
        this.product = {};
        this.productService
          .get(data[2]?.pCode, ProductScope.DETAILS)
          .subscribe((product: any) => {
            this.breadcrumbs = [];
            this.product = product;
            // For calling related products
            if (this.product?.code)
              this.getRelatedProducts(this.userType, this.product?.code);

            if (
              product !== null &&
              product !== undefined &&
              !product?.errorCode
            ) {
              const viewItemDataLayer: GTMDataLayer = {
                event: GtmEvents.ViewItem,
                store: this.gtmService.getItemBrand(),
                ecommerce: {
                  currency: this.product?.listPrice?.currencyIso || '',
                  value: this.product?.yourPrice?.value || '',
                  items: [
                    {
                      item_id: this.product?.code,
                      item_name: this.product?.name,
                      index: '',
                      item_brand: this.gtmService.getItemBrand(),
                      item_category: this.product?.breadCrumbs[0]?.name || '',
                      item_category2: this.product?.breadCrumbs[1]?.name || '',
                      item_category3: this.product?.breadCrumbs[2]?.name || '',
                      item_category4: this.product?.breadCrumbs[3]?.name || '',
                      item_category5: this.product?.breadCrumbs[4]?.name || '',
                      item_list_id: ItemListTypeEnum.ProductDetail,
                      item_list_name: ItemListTypeEnum.ProductDetail,
                      price: this.product?.yourPrice?.value || '',
                    },
                  ],
                },
              };
              if (
                this.product &&
                this.product?.estShipData &&
                this.product?.estShipData[0]?.stockQty
              ) {
                viewItemDataLayer.ecommerce.items[0].quantity = Number(
                  this.product?.estShipData[0]?.stockQty
                );
              }
              this.productImages = product.images;
              this.selectedImg = product?.images?.GALLERY
                ? product?.images?.GALLERY[0]
                : null;
              this.selectedGalleryImgIdx = 0;
              if (product?.price?.value == 0) {
                this.isPriceAvailable = false;
              }
              this.currentBuyAction =
                this.productCatelogService.getBuyAction(product);
              this.currentReturnAction =
                this.productCatelogService.getReturnsAction(product);
              this.currentGuestAction =
                this.productCatelogService.getAnonymousActions(this.product);
              this.productCode = product?.code;
              this.leadTime = product?.leadTime;
              const defaultPlant = this.product?.plantAvailableAt?.find(
                (plant) => plant.defaultPlant
              );

              this.gtmService.sendEvent(viewItemDataLayer);
              if (defaultPlant) {
                this.inStock = defaultPlant.stockAvailable >= 1;
              }
            }
            this.breadcrumbs = product?.breadCrumbs?.map(
              (crumb, index, array) => {
                const params = crumb.url.split('/');
                let url;
                if (index === array.length - 1) {
                  url = `/${this.productLine}/product/${
                    this.productCode
                  }/${encodeURIComponent(this.product?.name)}`;
                } else {
                  url = `/${this.productLine}/categories/${params[3]}/${params[1]}`;
                }
                return {
                  name: crumb.name,
                  url: url,
                };
              }
            );
          });
      }
    );
  }

  getLeadTime(lead: string): number {
    return Number(lead);
  }

  onFavoriteClick(product) {
    if (this.favStatus == false) {
      this.myFavouritesService
        .addtowhishlist({ productCodes: product.code })
        .subscribe((success) => {
          this.globalMessageService.add(
            'Product added to favorites',
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
          this.favStatus = true;
          this.cd.detectChanges();
        });
    } else {
      this.myFavouritesService
        .removeFromWishlist([product.code])
        .subscribe((success) => {
          this.globalMessageService.add(
            'Product removed from favorites',
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
          this.favStatus = false;
          this.cd.detectChanges();
        });
    }
  }

  closeMenu(event) {
    this.breakUp = false;
  }

  togglePriceBreakup() {
    this.breakUpMenu = !this.breakUpMenu;
  }

  thumbnailImgClicked(gallaryImg, selectedGalleryImgIdx) {
    this.selectedImg = gallaryImg;
    this.selectedGalleryImgIdx = selectedGalleryImgIdx;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  isProductNotAddedToCart(isNotAdded: boolean) {
    this.isProductNotAdded = isNotAdded;
  }

  onCloseClick() {
    this.isProductNotAdded = false;
  }
  returnProduct() {
    const updatedProduct = {
      ...this.product,
      similar: false,
    };
    this.returnProdService.selectRmaProduct(updatedProduct);
    this.router.navigate(['/rma-form']);
  }

  /*
    getRelatedProducts will return related products,
    fields and referenceType will be static with 'Default' and 'SIMILAR' respectively
   */
  getRelatedProducts(user: string, productCode: string): void {
    const apiParams: {
      fields: string;
      productCode: string;
      referenceType: string;
    } = {
      fields: 'FULL',
      productCode: productCode,
      referenceType: 'SIMILAR',
    };
    let urlParams = ['users', user, 'products', 'productReferences'];
    let url = this.apiService.constructUrl(urlParams);

    this.apiService.getData(url, apiParams).subscribe({
      next: (data: any) => {
        this.relProducts = data;
      },
      error: (error) => {
        this.relProducts = [];
      },
    });
  }
}
