import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { ApiService } from '../../../core/http/api.service';
import { observeOn, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedCartService {
  maxSavedCartErrorNotification = new BehaviorSubject(false);
  private readonly isCheckAvailabilitySource = new Subject<any>();
  isCheckAvailability$ = this.isCheckAvailabilitySource.asObservable();
  private validateMessage = new BehaviorSubject('');
  private guestEmailAddress = new BehaviorSubject('');
  private readonly isCheckAvailabilityCheck = new BehaviorSubject<[]>([]);
  isCheckAvailabilityCheck$ = this.isCheckAvailabilityCheck.asObservable();

  public readonly isShipmentPrefernceSource = new BehaviorSubject(false);
  isShipmentPrefernce$ = this.isShipmentPrefernceSource.asObservable();

  public proceedButtonClicked = new BehaviorSubject(false);
  proceedButtonClicked$ = this.proceedButtonClicked.asObservable();

  private $validationvalue = new BehaviorSubject(false);
  currentMessage = this.validateMessage.asObservable();
  getGuestEmailAddress = this.guestEmailAddress.asObservable();

  private disableEarlyShipmentCheckbox = new BehaviorSubject(false);
  earlyShipmentCheckboxState = this.disableEarlyShipmentCheckbox.asObservable();

  user$: Observable<unknown>;
  userType = '';
  validationvalue: any;
  constructor(
    private apiService: ApiService,
    protected authService: AuthService,
    private userAccountFacade: UserAccountFacade,
    private globalMessageService: GlobalMessageService
  ) {
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          this.userType = 'current';
          return this.userAccountFacade.get();
        } else {
          this.userType = 'anonymous';
          return of(undefined);
        }
      })
    );

    this.user$.subscribe(
      (res) => {
        if (res) {
          this.userType = 'current';
        } else {
          this.userType = 'anonymous';
        }
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          10000
        );
        window.scrollTo(0, 0);
      }
    );
  }

  setGuestEmailAddress(email: string) {
    this.guestEmailAddress.next(email);
  }
  validateForm(validateMsg: string) {
    this.validateMessage.next(validateMsg);
  }

  saveGuestEmail(cartId, param) {
    const params = ['users', this.userType, 'dscheckout', cartId, 'guest'];
    const apiUrl = this.apiService.constructUrl(params);
    return this.apiService.postData(apiUrl, param);
  }

  public addCartItemComments(cartId, entryNumber, comment) {
    const params = [
      'users',
      this.userType,
      'carts',
      cartId,
      'entries',
      entryNumber,
      'updateCartEntryNotes',
    ];
    const apiUrl = this.apiService.constructUrl(params);
    const Obj = {
      entryNotes: comment.entryNotes,
    };
    return this.apiService.putData_options(apiUrl, Obj);
  }

  public updateShipmentType(cartId, Obj) {
    const params = [
      'users',
      this.userType,
      'carts',
      cartId,
      'changeshipmentmethod',
    ];
    const apiUrl = this.apiService.constructUrl(params);
    return this.apiService.putData_options(apiUrl, Obj);
  }

  public checkAvailability(cartId, entryNumber, Obj) {
    const params = [
      'users',
      this.userType,
      'carts',
      cartId,
      'entries',
      entryNumber,
    ];
    const apiUrl = this.apiService.constructUrl(params);
    return this.apiService.putData_options(apiUrl, Obj);
  }

  public downloadCart(cartId) {
    const params = ['users', this.userType, 'carts', cartId, 'export'];
    const apiUrl = this.apiService.constructUrl(params);
    return this.apiService.getData_Excel(apiUrl);
  }

  emitCheckAvailability(flag) {
    this.isCheckAvailabilitySource.next(flag);
  }
  setvalidation(params: boolean) {
    this.$validationvalue.next(params);
  }

  getvalidation(): Observable<any> {
    return this.$validationvalue.asObservable();
  }

  //set validation for DS

  setCheckAvailabilityFlag(item, addItemFLag) {
    this.isCheckAvailabilityCheck.pipe(take(1)).subscribe((res: any) => {
      if (item.length <= 0) {
        return this.isCheckAvailabilityCheck.next([]);
      }
      if (addItemFLag) {
        res.push(item);
        res = res.filter(
          (element, index, array) =>
            index ===
            array.findIndex(
              (el) =>
                el.product.code === element.product.code &&
                el.entryNumber == element.entryNumber
            )
        );
        this.isCheckAvailabilityCheck.next(res);
      } else {
        res = res.filter((element, index) => {
          return element.product.code != item.product.code;
        });
        this.isCheckAvailabilityCheck.next(res);
      }
    });
  }

  updateProceedButtonFlag(flag) {
    this.proceedButtonClicked.next(flag);
  }

  updateisShipmentPrefernce(itemFlag) {
    this.isShipmentPrefernceSource.next(itemFlag);
  }

  updateEalryShipmentCheckboxState(flag) {
    this.disableEarlyShipmentCheckbox.next(flag);
  }

  // Early shipment service for checkbox
  onEarlyShipmentService(checked: boolean, cartId) {
    const urlParams = [
      'users',
      this.userType,
      'carts',
      cartId,
      checked.toString(),
    ];
    const apiUrl = this.apiService.constructUrl(urlParams);
    return this.apiService
      .patchData(apiUrl, null)
      .subscribe({ next: () => {}, error: () => {} });
  }
}
