var addressForm$g = {
  title: "Oslovení",
  titleOptional: "Titul (volitelný)",
  defaultTitle: "Oslovení",
  firstName: {
    label: "Křestní jméno",
    placeholder: "Křestní jméno"
  },
  lastName: {
    label: "Příjmení",
    placeholder: "Příjmení"
  },
  address1: "Adresa 1",
  address2: "Adresa 2 (volitelná)",
  country: "Země/region",
  city: {
    label: "Město",
    placeholder: "Město"
  },
  state: "Stát",
  zipCode: {
    label: "PSČ",
    placeholder: "PSČ"
  },
  phoneNumber: {
    label: "Telefonní číslo (volitelné)",
    placeholder: "např. (###) - ### - ####"
  },
  cellphone: {
    label: "Číslo mobilního telefonu (volitelné)",
    placeholder: "např. (###) - ### - ####"
  },
  saveAsDefault: "Uložit jako standardní",
  chooseAddress: "Zvolte adresu",
  streetAddress: "Ulice",
  aptSuite: "Číslo bytu",
  selectOne: "Vyberte jedno…",
  setAsDefault: "Nastavit standardní dodací adresu",
  titleRequired: "Oslovení je povinné.",
  userAddressAddSuccess: "Nová adresa byla úspěšně přidána!",
  userAddressUpdateSuccess: "Adresa byla úspěšně aktualizována!",
  userAddressDeleteSuccess: "Adresa úspěšně odstraněna!",
  invalidAddress: "Neplatná adresa"
};
var addressBook$g = {
  addNewDeliveryAddress: "Přidat novou dodací adresu",
  editDeliveryAddress: "Upravit dodací adresu",
  areYouSureToDeleteAddress: "Chcete tuto adresu opravdu odstranit?",
  addNewAddress: "Přidat novou adresu",
  addAddress: "Přidat adresu",
  updateAddress: "Aktualizovat adresu",
  backToAddressList: "Zpět na seznam adres",
  defaultDeliveryAddress: "Standardní dodací adresa",
  additionalDeliveryAddress: "Další dodací adresa {{number}}"
};
var addressCard$g = {
  "default": "STANDARDNÍ",
  selected: "Vybráno",
  selectedAddress: "Vybraná adresa",
  setAsDefault: "Nastavit jako standardní",
  shipTo: "Příjemce",
  billTo: "Plátce",
  phoneNumber: "P",
  mobileNumber: "M"
};
var addressSuggestion$g = {
  verifyYourAddress: "Ověřte svou adresu",
  ensureAccuracySuggestChange: "V zájmu přesného doručení doporučujeme změnit následující vybrané.",
  chooseAddressToUse: "Vyberte, kterou adresu chcete používat:",
  suggestedAddress: "Navržená adresa",
  enteredAddress: "Zadaná adresa",
  editAddress: "Upravit adresu",
  saveAddress: "Uložit adresu"
};
var addressMessages$g = {
  setAsDefaultSuccessfully: "Adresa {{ streetAddress }} byla úspěšně nastavena jako standardní."
};
var address$g = {
  addressForm: addressForm$g,
  addressBook: addressBook$g,
  addressCard: addressCard$g,
  addressSuggestion: addressSuggestion$g,
  addressMessages: addressMessages$g
};
var myAccountV2Email$w = {
  myEmailAddress: "Moje e-mailová adresa",
  email: "E-mail",
  emailAddress: "E-mailová adresa",
  newEmailAddress: "Nová e-mailová adresa",
  confirmNewEmailAddress: "Potvrďte novou e-mailovou adresu",
  emailPlaceHolder: "Zadejte e-mail",
  passwordPlaceHolder: "Zadejte heslo",
  password: "Heslo",
  reloginIndicator: "Po nastavení nové e-mailové adresy je třeba znovu se přihlásit."
};
var myAccountV2Email$x = {
  myAccountV2Email: myAccountV2Email$w
};
var myAccountV2PasswordForm$g = {
  oldPassword: {
    label: "Staré heslo",
    placeholder: "Zadejte staré heslo"
  },
  oldPasswordIsRequired: "Je třeba zadat staré heslo.",
  newPassword: {
    label: "Nové heslo",
    placeholder: "Zadejte nové heslo"
  },
  passwordMinRequirements: "Heslo musí být dlouhé minimálně šest znaků a obsahovat alespoň jedno velké písmeno, jednu číslici a jeden symbol.",
  confirmPassword: {
    label: "Potvrďte nové heslo",
    placeholder: "Potvrďte nové heslo"
  },
  reloginIndicator: "Po nastavení nového hesla je třeba se znovu přihlásit.",
  bothPasswordMustMatch: "Obě hesla se musí shodovat",
  passwordUpdateSuccess: "Heslo bylo úspěšně aktualizováno",
  accessDeniedError: "Přístup byl odepřen",
  newPasswordTitle: "Moje heslo"
};
var myAccountV2Password$g = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$g
};
var myAccountV2UserProfile$w = {
  myEmailAddress: "Moje e-mailová adresa",
  myInformation: "Moje informace",
  name: "Jméno",
  customerId: "CustomerId",
  title: "Oslovení",
  firstName: "Křestní jméno",
  lastName: "Příjmení"
};
var myAccountV2UserProfile$x = {
  myAccountV2UserProfile: myAccountV2UserProfile$w
};
var updateEmailForm$g = {
  newEmailAddress: {
    label: "Nová e-mailová adresa",
    placeholder: "Zadejte e-mail"
  },
  confirmNewEmailAddress: {
    label: "Potvrďte novou e-mailovou adresu",
    placeholder: "Zadejte e-mail"
  },
  enterValidEmail: "Zadejte platný e-mail.",
  bothEmailMustMatch: "Oba e-maily se musí shodovat",
  password: {
    label: "Heslo",
    placeholder: "Zadejte heslo"
  },
  pleaseInputPassword: "Zadejte heslo",
  emailUpdateSuccess: "Úspěch. Přihlaste se s {{ newUid }}"
};
var register$g = {
  confirmPassword: {
    action: "Potvrďte heslo",
    label: "Potvrďte heslo",
    placeholder: "Potvrďte heslo"
  },
  managementInMyAccount: "Správa v Mém účtu.",
  termsAndConditions: "Obchodní podmínky",
  signIn: "Mám již účet. Přihlásit se",
  register: "Registrovat",
  confirmNewPassword: "Potvrďte nové heslo",
  resetPassword: "Resetovat heslo",
  createAccount: "Vytvoření účtu",
  title: "Oslovení",
  titleOptional: "Titul (volitelný)",
  firstName: {
    label: "Křestní jméno",
    placeholder: "Křestní jméno"
  },
  lastName: {
    label: "Příjmení",
    placeholder: "Příjmení"
  },
  emailAddress: {
    label: "E-mailová adresa",
    placeholder: "E-mailová adresa"
  },
  password: {
    label: "Heslo",
    placeholder: "Heslo"
  },
  newPassword: "Nové heslo",
  emailMarketing: "Použít mé osobní údaje k zasílání newsletterů marketingových kampaní e-mailem. Nastavení můžete změnit ve Správě souhlasů v Můj účet.",
  confirmThatRead: "Potvrzuji, že po přečtení souhlasím s",
  selectTitle: "Vyberte oslovení",
  passwordMinRequirements: "Zde je stručný návod na bezpečné heslo: Použijte minimálně 8 znaků a kombinujte malá a velká písmena, číslice a symboly.",
  passwordStrengthRequirements: "Zde je stručný návod na bezpečné heslo: Použijte minimálně 8 znaků a kombinujte malá a velká písmena, číslice a symboly. Nepoužívejte stejné znaky za sebou.",
  bothPasswordMustMatch: "Obě hesla se musí shodovat",
  titleRequired: "Oslovení je povinné.",
  postRegisterMessage: "Přihlaste se s poskytnutými přihlašovacími údaji.",
  postRegisterSuccessMessage: "Registrace byla úspěšná. Přihlaste se s poskytnutými přihlašovacími údaji."
};
var forgottenPassword$g = {
  resetPassword: "Resetovat heslo",
  enterEmailAddressAssociatedWithYourAccount: "Zadejte e-mailovou adresu přidruženou k vašemu účtu.",
  emailAddress: {
    label: "E-mailová adresa",
    placeholder: "Zadejte e-mail"
  },
  enterValidEmail: "Zadejte platný e-mail.",
  passwordResetEmailSent: "Poslali jsme vám e-mail s informacemi, jak resetovat heslo.",
  passwordResetSuccess: "Úspěch! Nyní se může přihlásit novým heslem."
};
var userProfile$g = {
  updateEmailForm: updateEmailForm$g,
  register: register$g,
  forgottenPassword: forgottenPassword$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  address: address$g,
  myAccountV2Email: myAccountV2Email$x,
  myAccountV2Password: myAccountV2Password$g,
  myAccountV2UserProfile: myAccountV2UserProfile$x,
  userProfile: userProfile$g
};
var addressForm$f = {
  title: "Anrede",
  titleOptional: "Anrede (optional)",
  defaultTitle: "Anrede",
  firstName: {
    label: "Vorname",
    placeholder: "Vorname"
  },
  lastName: {
    label: "Nachname",
    placeholder: "Nachname"
  },
  address1: "Adresse 1",
  address2: "Adresse 2 (optional)",
  country: "Land/Region",
  city: {
    label: "Ort",
    placeholder: "Ort"
  },
  state: "Bundesland",
  zipCode: {
    label: "Postleitzahl",
    placeholder: "Postleitzahl"
  },
  phoneNumber: {
    label: "Telefonnummer (optional)",
    placeholder: "z.B. (###) - ### - ####"
  },
  cellphone: {
    label: "Mobiltelefonnummer (optional)",
    placeholder: "z.B. (###) - ### - ####"
  },
  saveAsDefault: "Als Standard speichern",
  chooseAddress: "Adresse auswählen",
  streetAddress: "Hausanschrift",
  aptSuite: "Wohnungsnummer",
  selectOne: "Treffen Sie eine Auswahl...",
  setAsDefault: "Als Standardlieferadresse festlegen",
  titleRequired: "Anrede ist erforderlich.",
  userAddressAddSuccess: "Neue Adresse wurde erfolgreich hinzugefügt.",
  userAddressUpdateSuccess: "Adresse erfolgreich aktualisiert.",
  userAddressDeleteSuccess: "Adresse erfolgreich gelöscht.",
  invalidAddress: "Ungültige Adresse"
};
var addressBook$f = {
  addNewDeliveryAddress: "Neue Lieferadresse hinzufügen",
  editDeliveryAddress: "Lieferadresse bearbeiten",
  areYouSureToDeleteAddress: "Soll diese Adresse wirklich gelöscht werden?",
  addNewAddress: "Neue Adresse hinzufügen",
  addAddress: "Adresse hinzufügen",
  updateAddress: "Adresse aktualisieren",
  backToAddressList: "Zurück zur Adressliste",
  defaultDeliveryAddress: "Standardlieferadresse",
  additionalDeliveryAddress: "Zusätzliche Lieferadresse {{number}}"
};
var addressCard$f = {
  "default": "STANDARD",
  selected: "Ausgewählt",
  selectedAddress: "Ausgewählte Adresse",
  setAsDefault: "Als Standard festlegen",
  shipTo: "Liefern an",
  billTo: "Rechnung an",
  phoneNumber: "T",
  mobileNumber: "M"
};
var addressSuggestion$f = {
  verifyYourAddress: "Überprüfen Sie Ihre Adresse",
  ensureAccuracySuggestChange: "Um eine korrekte Lieferung sicherzustellen, schlagen wir die unten ausgewählte Änderung vor.",
  chooseAddressToUse: "Wählen Sie die Adresse aus, die Sie verwenden möchten:",
  suggestedAddress: "Vorgeschlagene Adresse",
  enteredAddress: "Eingegebene Adresse",
  editAddress: "Adresse bearbeiten",
  saveAddress: "Adresse speichern"
};
var addressMessages$f = {
  setAsDefaultSuccessfully: "Adresse {{ streetAddress }} wurde erfolgreich als Standard festgelegt"
};
var address$f = {
  addressForm: addressForm$f,
  addressBook: addressBook$f,
  addressCard: addressCard$f,
  addressSuggestion: addressSuggestion$f,
  addressMessages: addressMessages$f
};
var myAccountV2Email$u = {
  myEmailAddress: "Meine E-Mail-Adresse",
  email: "E-Mail",
  emailAddress: "E-Mail-Adresse",
  newEmailAddress: "Neue E-Mail-Adresse",
  confirmNewEmailAddress: "Neue E-Mail-Adresse bestätigen",
  emailPlaceHolder: "E-Mail eingeben",
  passwordPlaceHolder: "Kennwort eingeben",
  password: "Kennwort",
  reloginIndicator: "Sie müssen sich erneut anmelden, nachdem Sie eine neue E-Mail-Adresse festgelegt haben."
};
var myAccountV2Email$v = {
  myAccountV2Email: myAccountV2Email$u
};
var myAccountV2PasswordForm$f = {
  oldPassword: {
    label: "Altes Kennwort",
    placeholder: "Altes Kennwort eingeben"
  },
  oldPasswordIsRequired: "Altes Kennwort ist erforderlich.",
  newPassword: {
    label: "Neues Kennwort",
    placeholder: "Neues Kennwort eingeben"
  },
  passwordMinRequirements: "Das Kennwort muss mindestens sechs Zeichen lang sein und einen Großbuchstaben, eine Ziffer und ein Sonderzeichen enthalten",
  confirmPassword: {
    label: "Neues Kennwort bestätigen",
    placeholder: "Neues Kennwort bestätigen"
  },
  reloginIndicator: "Sie müssen sich erneut anmelden, nachdem Sie das Kennwort festgelegt haben.",
  bothPasswordMustMatch: "Beide Kennwörter müssen übereinstimmen",
  passwordUpdateSuccess: "Kennwort wurde erfolgreich aktualisiert",
  accessDeniedError: "Zugriff verweigert",
  newPasswordTitle: "Mein Kennwort"
};
var myAccountV2Password$f = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$f
};
var myAccountV2UserProfile$u = {
  myEmailAddress: "Meine E-Mail-Adresse",
  myInformation: "Meine Informationen",
  name: "Name",
  customerId: "Kunden-ID",
  title: "Anrede",
  firstName: "Vorname",
  lastName: "Nachname"
};
var myAccountV2UserProfile$v = {
  myAccountV2UserProfile: myAccountV2UserProfile$u
};
var updateEmailForm$f = {
  newEmailAddress: {
    label: "Neue E-Mail-Adresse",
    placeholder: "E-Mail eingeben"
  },
  confirmNewEmailAddress: {
    label: "Neue E-Mail-Adresse bestätigen",
    placeholder: "E-Mail eingeben"
  },
  enterValidEmail: "Geben Sie eine gültige E-Mail-Adresse ein.",
  bothEmailMustMatch: "Beide E-Mail-Adressen müssen übereinstimmen.",
  password: {
    label: "Kennwort",
    placeholder: "Kennwort eingeben"
  },
  pleaseInputPassword: "Geben Sie ein Kennwort ein",
  emailUpdateSuccess: "Erfolgreich. Melden Sie sich mit {{ newUid }} an"
};
var register$f = {
  confirmPassword: {
    action: "Kennwort bestätigen",
    label: "Kennwort bestätigen",
    placeholder: "Kennwort bestätigen"
  },
  managementInMyAccount: "Verwaltung in meinem Konto",
  termsAndConditions: "Allgemeine Geschäftsbedingungen",
  signIn: "Ich habe bereits ein Konto. Anmelden",
  register: "Registrieren",
  confirmNewPassword: "Neues Kennwort bestätigen",
  resetPassword: "Kennwort zurücksetzen",
  createAccount: "Konto erstellen",
  title: "Anrede",
  titleOptional: "Anrede (optional)",
  firstName: {
    label: "Vorname",
    placeholder: "Vorname"
  },
  lastName: {
    label: "Nachname",
    placeholder: "Nachname"
  },
  emailAddress: {
    label: "E-Mail-Adresse",
    placeholder: "E-Mail-Adresse"
  },
  password: {
    label: "Kennwort",
    placeholder: "Kennwort"
  },
  newPassword: "Neues Kennwort",
  emailMarketing: "Verwendung meiner personenbezogenen Daten für den Erhalt von E-Mail-Newslettern zu Marketingkampagnen. Um Ihre Einstellungen zu ändern, wechseln Sie zu \"Einwilligungsmanagement\" in \"Mein Konto\".",
  confirmThatRead: "Ich bestätige, dass ich Folgendes gelesen habe und damit einverstanden bin:",
  selectTitle: "Anrede auswählen",
  passwordMinRequirements: "Hier eine schnelle Anleitung für ein sicheres Kennwort: Verwenden Sie mindestens 8 Zeichen mit einer Mischung aus Groß- und Kleinbuchstaben, Ziffern und Sonderzeichen.",
  passwordStrengthRequirements: "Hier eine schnelle Anleitung für ein sicheres Kennwort: Verwenden Sie mindestens 8 Zeichen mit einer Mischung aus Groß- und Kleinbuchstaben, Ziffern und Sonderzeichen. Stellen Sie sicher, dass Sie ein Zeichen nicht mehrmals hintereinander verwenden.",
  bothPasswordMustMatch: "Beide Kennwörter müssen übereinstimmen",
  titleRequired: "Anrede ist erforderlich.",
  postRegisterMessage: "Melden Sie sich mit den angegebenen Anmeldedaten an.",
  postRegisterSuccessMessage: "Registrierung erfolgreich. Melden Sie sich mit den angegebenen Anmeldedaten an."
};
var forgottenPassword$f = {
  resetPassword: "Kennwort zurücksetzen",
  enterEmailAddressAssociatedWithYourAccount: "Geben Sie die E-Mail-Adresse ein, die mit Ihrem Konto verknüpft ist",
  emailAddress: {
    label: "E-Mail-Adresse",
    placeholder: "E-Mail eingeben"
  },
  enterValidEmail: "Geben Sie eine gültige E-Mail-Adresse ein.",
  passwordResetEmailSent: "Es wurde eine E-Mail an Sie geschickt mit Informationen, wie Sie Ihr Kennwort zurücksetzen können.",
  passwordResetSuccess: "Erfolgreich! Sie können sich jetzt mit Ihrem neuen Kennwort anmelden."
};
var userProfile$f = {
  updateEmailForm: updateEmailForm$f,
  register: register$f,
  forgottenPassword: forgottenPassword$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  address: address$f,
  myAccountV2Email: myAccountV2Email$v,
  myAccountV2Password: myAccountV2Password$f,
  myAccountV2UserProfile: myAccountV2UserProfile$v,
  userProfile: userProfile$f
};
var addressForm$e = {
  title: "Title",
  titleOptional: "Title (optional)",
  defaultTitle: "Title",
  firstName: {
    label: "First name",
    placeholder: "First Name"
  },
  lastName: {
    label: "Last name",
    placeholder: "Last Name"
  },
  address1: "Address 1",
  address2: "Address 2 (optional)",
  country: "Country/Region",
  city: {
    label: "City",
    placeholder: "City"
  },
  state: "State",
  zipCode: {
    label: "Zip code",
    placeholder: "Postal Code/Zip"
  },
  phoneNumber: {
    label: "Phone number (optional)",
    placeholder: "e.g. (###) - ### - ####"
  },
  cellphone: {
    label: "Mobile number (optional)",
    placeholder: "e.g. (###) - ### - ####"
  },
  saveAsDefault: "Save as default",
  chooseAddress: "Choose address",
  streetAddress: "Street Address",
  aptSuite: "Apartment Number",
  selectOne: "Select One...",
  setAsDefault: "Set as default delivery address",
  titleRequired: "Title is required.",
  userAddressAddSuccess: "New address was added successfully!",
  userAddressUpdateSuccess: "Address updated successfully!",
  userAddressDeleteSuccess: "Address deleted successfully!",
  invalidAddress: "Invalid Address"
};
var addressBook$e = {
  addNewDeliveryAddress: "Add a new delivery address",
  editDeliveryAddress: "Edit delivery address",
  areYouSureToDeleteAddress: "Are you sure you want to delete this address?",
  addNewAddress: "Add new address",
  addAddress: "Add address",
  updateAddress: "Update address",
  backToAddressList: "Back to address list",
  defaultDeliveryAddress: "Default Delivery Address",
  additionalDeliveryAddress: "Additional Delivery Address {{number}}"
};
var addressCard$e = {
  "default": "DEFAULT",
  selected: "Selected",
  selectedAddress: "Selected Address",
  setAsDefault: "Set as default",
  shipTo: "Ship To",
  billTo: "Bill To",
  phoneNumber: "P",
  mobileNumber: "M"
};
var addressSuggestion$e = {
  verifyYourAddress: "Verify your address",
  ensureAccuracySuggestChange: "To ensure delivery accuracy, we suggest the change selected below.",
  chooseAddressToUse: "Please choose which address you would like to use:",
  suggestedAddress: "Suggested address",
  enteredAddress: "Entered address",
  editAddress: "Edit address",
  saveAddress: "Save address"
};
var addressMessages$e = {
  setAsDefaultSuccessfully: "Address {{ streetAddress }} was successfully set as default"
};
var address$e = {
  addressForm: addressForm$e,
  addressBook: addressBook$e,
  addressCard: addressCard$e,
  addressSuggestion: addressSuggestion$e,
  addressMessages: addressMessages$e
};
var myAccountV2Email$s = {
  myEmailAddress: "My Email Address",
  email: "Email",
  emailAddress: "Email Address",
  newEmailAddress: "New Email Address",
  confirmNewEmailAddress: "Confirm New Email Address",
  emailPlaceHolder: "Enter email",
  passwordPlaceHolder: "Enter password",
  password: "Password",
  reloginIndicator: "You need to log in again after setting a new email address."
};
var myAccountV2Email$t = {
  myAccountV2Email: myAccountV2Email$s
};
var myAccountV2PasswordForm$e = {
  oldPassword: {
    label: "Old Password",
    placeholder: "Enter Old Password"
  },
  oldPasswordIsRequired: "Old password is required.",
  newPassword: {
    label: "New Password",
    placeholder: "Enter New Password"
  },
  passwordMinRequirements: "Password must be six characters minimum, with one uppercase letter, one number, one symbol",
  confirmPassword: {
    label: "Confirm New Password",
    placeholder: "Confirm New Password"
  },
  reloginIndicator: "You need to log in again after setting password.",
  bothPasswordMustMatch: "Both password must match",
  passwordUpdateSuccess: "Password updated with success",
  accessDeniedError: "Access is denied",
  newPasswordTitle: "My Password"
};
var myAccountV2Password$e = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$e
};
var myAccountV2UserProfile$s = {
  myEmailAddress: "My Email Address",
  myInformation: "My Information",
  name: "Name",
  customerId: "CustomerId",
  title: "Title",
  firstName: "First name",
  lastName: "Last name"
};
var myAccountV2UserProfile$t = {
  myAccountV2UserProfile: myAccountV2UserProfile$s
};
var updateEmailForm$e = {
  newEmailAddress: {
    label: "New email address",
    placeholder: "Enter email"
  },
  confirmNewEmailAddress: {
    label: "Confirm new email address",
    placeholder: "Enter email"
  },
  enterValidEmail: "Please enter a valid email.",
  bothEmailMustMatch: "Both emails must match",
  password: {
    label: "Password",
    placeholder: "Enter password"
  },
  pleaseInputPassword: "Please input password",
  emailUpdateSuccess: "Success. Please sign in with {{ newUid }}"
};
var register$e = {
  confirmPassword: {
    action: "Confirm password",
    label: "Confirm password",
    placeholder: "Confirm Password"
  },
  managementInMyAccount: "Management in My Account.",
  termsAndConditions: "Terms & Conditions",
  signIn: "I already have an account. Sign In",
  register: "Register",
  furtherRegistration: "Continue",
  confirmNewPassword: "Confirm New Password",
  resetPassword: "Reset Password",
  createAccount: "Create an account",
  title: "Title",
  titleOptional: "Title (optional)",
  firstName: {
    label: "First name",
    placeholder: "First name"
  },
  lastName: {
    label: "Last name",
    placeholder: "Last name"
  },
  emailAddress: {
    label: "Email address",
    placeholder: "Email address"
  },
  password: {
    label: "Password",
    placeholder: "Password"
  },
  newPassword: "New Password",
  emailMarketing: "Use my personal data to receive e-mail newsletters for marketing campaigns. To change your settings, go to Consent Management in My Account.",
  confirmThatRead: "I am confirming that I have read and agreed with the",
  selectTitle: "Select Title",
  passwordMinRequirements: "Here's a quick guide for a secure password: Use at least 8 characters with a mix of upper and lowercase letters, numbers, and symbols.",
  passwordStrengthRequirements: "Here's a quick guide for a secure password: Use at least 8 characters with a mix of upper and lowercase letters, numbers, and symbols. Make sure not to use the same character consecutively.",
  bothPasswordMustMatch: "Both password must match",
  titleRequired: "Title is required.",
  postRegisterMessage: "Please log in with provided credentials.",
  postRegisterSuccessMessage: "Your account has been successfully created! Please log in with provided credentials",
  rateLimitForRegistrationMessage: "You have reached the limit of sending verification code. Please wait {{seconds}} seconds before retrying or use a different email address.",
  verificationTokenForm: {
    createVerificationToken: "Verification code has been sent to {{target}}. Please enter the code.",
    sendRateLime: "in {{waitTime}} seconds",
    resend: "Resend",
    verificationCode: {
      label: "Verification Code",
      placeholder: "Enter Verification Code"
    },
    back: "Back"
  }
};
var forgottenPassword$e = {
  resetPassword: "Reset password",
  enterEmailAddressAssociatedWithYourAccount: "Enter the email address associated with your account",
  emailAddress: {
    label: "Email address",
    placeholder: "Enter email"
  },
  enterValidEmail: "Please enter a valid email.",
  passwordResetEmailSent: "An email has been sent to you with information on how to reset your password.",
  passwordResetSuccess: "Success! You can now login using your new password."
};
var userProfile$e = {
  updateEmailForm: updateEmailForm$e,
  register: register$e,
  forgottenPassword: forgottenPassword$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  address: address$e,
  myAccountV2Email: myAccountV2Email$t,
  myAccountV2Password: myAccountV2Password$e,
  myAccountV2UserProfile: myAccountV2UserProfile$t,
  userProfile: userProfile$e
};
var addressForm$d = {
  title: "Tratamiento",
  titleOptional: "Tratamiento (opcional)",
  defaultTitle: "Tratamiento",
  firstName: {
    label: "Nombre",
    placeholder: "Nombre"
  },
  lastName: {
    label: "Apellido",
    placeholder: "Apellido"
  },
  address1: "Dirección 1",
  address2: "Dirección 2 (opcional)",
  country: "País/Región",
  city: {
    label: "Ciudad",
    placeholder: "Ciudad"
  },
  state: "Estado",
  zipCode: {
    label: "Código postal",
    placeholder: "Código postal"
  },
  phoneNumber: {
    label: "Número de teléfono (opcional)",
    placeholder: "p. ej., (###) - ### - ####"
  },
  cellphone: {
    label: "Número de teléfono móvil (opcional)",
    placeholder: "p. ej., (###) - ### - ####"
  },
  saveAsDefault: "Grabar como por defecto",
  chooseAddress: "Elegir dirección",
  streetAddress: "Dirección postal",
  aptSuite: "Número de apartamento",
  selectOne: "Seleccionar uno...",
  setAsDefault: "Establecer como dirección de entrega por defecto",
  titleRequired: "Se requiere un tratamiento.",
  userAddressAddSuccess: "Nueva dirección añadida correctamente",
  userAddressUpdateSuccess: "Dirección actualizada correctamente",
  userAddressDeleteSuccess: "Dirección borrada correctamente",
  invalidAddress: "Dirección no válida"
};
var addressBook$d = {
  addNewDeliveryAddress: "Añadir una nueva dirección de entrega",
  editDeliveryAddress: "Editar dirección de entrega",
  areYouSureToDeleteAddress: "¿Confirma que desea borrar esta dirección?",
  addNewAddress: "Añadir dirección nueva",
  addAddress: "Añadir dirección",
  updateAddress: "Actualizar dirección",
  backToAddressList: "Volver a la lista de direcciones",
  defaultDeliveryAddress: "Dirección de entrega por defecto",
  additionalDeliveryAddress: "Dirección de entrega adicional {{number}}"
};
var addressCard$d = {
  "default": "POR DEFECTO",
  selected: "Seleccionada",
  selectedAddress: "Dirección seleccionada",
  setAsDefault: "Definir como por defecto",
  shipTo: "Enviar a",
  billTo: "Facturar a",
  phoneNumber: "T",
  mobileNumber: "M"
};
var addressSuggestion$d = {
  verifyYourAddress: "Verifique su dirección",
  ensureAccuracySuggestChange: "Para garantizar la precisión de la entrega, sugerimos que modifique los siguientes datos seleccionados.",
  chooseAddressToUse: "Elija qué dirección quisiera utilizar:",
  suggestedAddress: "Dirección sugerida",
  enteredAddress: "Dirección introducida",
  editAddress: "Editar dirección",
  saveAddress: "Grabar dirección"
};
var addressMessages$d = {
  setAsDefaultSuccessfully: "La dirección {{ streetAddress }} se definió correctamente como por defecto"
};
var address$d = {
  addressForm: addressForm$d,
  addressBook: addressBook$d,
  addressCard: addressCard$d,
  addressSuggestion: addressSuggestion$d,
  addressMessages: addressMessages$d
};
var myAccountV2Email$q = {
  myEmailAddress: "Mi dirección de correo electrónico",
  email: "Correo electrónico",
  emailAddress: "Dirección de correo electrónico",
  newEmailAddress: "Nueva dirección de correo electrónico",
  confirmNewEmailAddress: "Confirmar nueva dirección de correo electrónico",
  emailPlaceHolder: "Introducir correo electrónico",
  passwordPlaceHolder: "Introducir contraseña",
  password: "Contraseña",
  reloginIndicator: "Después de establecer una nueva dirección de correo electrónico, debe volver a iniciar sesión."
};
var myAccountV2Email$r = {
  myAccountV2Email: myAccountV2Email$q
};
var myAccountV2PasswordForm$d = {
  oldPassword: {
    label: "Contraseña anterior",
    placeholder: "Introducir contraseña anterior"
  },
  oldPasswordIsRequired: "Se requiere la contraseña anterior.",
  newPassword: {
    label: "Contraseña nueva",
    placeholder: "Introducir contraseña nueva"
  },
  passwordMinRequirements: "La contraseña debe tener seis caracteres como mínimo, con una letra mayúscula, un número y un símbolo",
  confirmPassword: {
    label: "Confirmar nueva contraseña",
    placeholder: "Confirmar nueva contraseña"
  },
  reloginIndicator: "Después de establecer la contraseña, debe volver a iniciar sesión.",
  bothPasswordMustMatch: "Ambas contraseñas deben coincidir",
  passwordUpdateSuccess: "Contraseña actualizada correctamente",
  accessDeniedError: "Se denegó el acceso",
  newPasswordTitle: "Mi contraseña"
};
var myAccountV2Password$d = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$d
};
var myAccountV2UserProfile$q = {
  myEmailAddress: "Mi dirección de correo electrónico",
  myInformation: "Mi información",
  name: "Nombre",
  customerId: "ID de cliente",
  title: "Tratamiento",
  firstName: "Nombre",
  lastName: "Apellido"
};
var myAccountV2UserProfile$r = {
  myAccountV2UserProfile: myAccountV2UserProfile$q
};
var updateEmailForm$d = {
  newEmailAddress: {
    label: "Nueva dirección de correo electrónico",
    placeholder: "Introducir correo electrónico"
  },
  confirmNewEmailAddress: {
    label: "Confirmar nueva dirección de correo electrónico",
    placeholder: "Introducir correo electrónico"
  },
  enterValidEmail: "Introduzca un correo electrónico válido.",
  bothEmailMustMatch: "Ambos correos electrónicos deben coincidir",
  password: {
    label: "Contraseña",
    placeholder: "Introduzca contraseña"
  },
  pleaseInputPassword: "Introduzca la contraseña",
  emailUpdateSuccess: "Hecho. Inicie sesión con {{ newUid }}"
};
var register$d = {
  confirmPassword: {
    action: "Confirmar contraseña",
    label: "Confirmar contraseña",
    placeholder: "Confirmar contraseña"
  },
  managementInMyAccount: "Gestión en Mi cuenta",
  termsAndConditions: "Términos y condiciones",
  signIn: "Ya tengo cuenta. Iniciar sesión.",
  register: "Registrarme",
  confirmNewPassword: "Confirmar nueva contraseña",
  resetPassword: "Restablecer contraseña",
  createAccount: "Crear una cuenta",
  title: "Tratamiento",
  titleOptional: "Tratamiento (opcional)",
  firstName: {
    label: "Nombre",
    placeholder: "Nombre"
  },
  lastName: {
    label: "Apellido",
    placeholder: "Apellido"
  },
  emailAddress: {
    label: "Dirección de correo electrónico",
    placeholder: "Dirección de correo electrónico"
  },
  password: {
    label: "Contraseña",
    placeholder: "Contraseña"
  },
  newPassword: "Contraseña nueva",
  emailMarketing: "Utilizar mis datos personales para recibir newsletters publicitarios por correo electrónico. Para cambiar la configuración, diríjase a Gestión de consentimiento en Mi cuenta.",
  confirmThatRead: "Confirmo que he leído y estoy de acuerdo con los",
  selectTitle: "Seleccionar título",
  passwordMinRequirements: "Esta es una guía rápida para generar una contraseña segura: utilice al menos 8 caracteres e incluya letras en mayúscula y minúscula, números y símbolos.",
  passwordStrengthRequirements: "Esta es una guía rápida para generar una contraseña segura: utilice al menos 8 caracteres e incluya letras en mayúscula y minúscula, números y símbolos. Asegúrese de no utilizar el mismo carácter de forma consecutiva.",
  bothPasswordMustMatch: "Ambas contraseñas deben coincidir",
  titleRequired: "Se requiere un tratamiento.",
  postRegisterMessage: "Inicie sesión con las credenciales provistas.",
  postRegisterSuccessMessage: "Registro exitoso: inicie sesión con las credenciales provistas"
};
var forgottenPassword$d = {
  resetPassword: "Restablecer contraseña",
  enterEmailAddressAssociatedWithYourAccount: "Introduzca la dirección de correo electrónico asociada con su cuenta",
  emailAddress: {
    label: "Dirección de correo electrónico",
    placeholder: "Introducir correo electrónico"
  },
  enterValidEmail: "Introduzca un correo electrónico válido.",
  passwordResetEmailSent: "Le hemos enviado un correo electrónico que contiene la información sobre cómo restablecer su contraseña.",
  passwordResetSuccess: "¡Hecho! Ahora puede iniciar sesión con su contraseña nueva."
};
var userProfile$d = {
  updateEmailForm: updateEmailForm$d,
  register: register$d,
  forgottenPassword: forgottenPassword$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  address: address$d,
  myAccountV2Email: myAccountV2Email$r,
  myAccountV2Password: myAccountV2Password$d,
  myAccountV2UserProfile: myAccountV2UserProfile$r,
  userProfile: userProfile$d
};
var addressForm$c = {
  title: "Tratamiento",
  titleOptional: "Tratamiento (opcional)",
  defaultTitle: "Tratamiento",
  firstName: {
    label: "Nombre",
    placeholder: "Nombre"
  },
  lastName: {
    label: "Apellido",
    placeholder: "Apellido"
  },
  address1: "Dirección 1",
  address2: "Dirección 2 (opcional)",
  country: "País/Región",
  city: {
    label: "Ciudad",
    placeholder: "Ciudad"
  },
  state: "Estado",
  zipCode: {
    label: "Código postal",
    placeholder: "Código postal"
  },
  phoneNumber: {
    label: "Número de teléfono (opcional)",
    placeholder: "Por ejemplo: (123) - 456 - 7890"
  },
  cellphone: {
    label: "Número de teléfono móvil (opcional)",
    placeholder: "Por ejemplo: (123) - 456 - 7890"
  },
  saveAsDefault: "Guardar como predeterminada",
  chooseAddress: "Elegir dirección",
  streetAddress: "Dirección postal",
  aptSuite: "Número de apartamento",
  selectOne: "Seleccionar uno...",
  setAsDefault: "Establecer como dirección de entrega predeterminada",
  titleRequired: "El tratamiento es obligatorio",
  userAddressAddSuccess: "La nueva dirección se agregó correctamente",
  userAddressUpdateSuccess: "Dirección actualizada correctamente.",
  userAddressDeleteSuccess: "Dirección borrada correctamente.",
  invalidAddress: "Dirección no válida"
};
var addressBook$c = {
  addNewDeliveryAddress: "Agregar una dirección de entrega nueva",
  editDeliveryAddress: "Editar dirección de entrega",
  areYouSureToDeleteAddress: "¿Confirma que desea borrar esta dirección?",
  addNewAddress: "Agregar dirección nueva",
  addAddress: "Agregar dirección",
  updateAddress: "Actualizar dirección",
  backToAddressList: "Volver a la lista de direcciones",
  defaultDeliveryAddress: "Dirección de entrega predeterminada",
  additionalDeliveryAddress: "Dirección de entrega adicional {{number}}"
};
var addressCard$c = {
  "default": "PREDETERMINADA",
  selected: "Seleccionada",
  selectedAddress: "Dirección seleccionada",
  setAsDefault: "Definir como predeterminada",
  shipTo: "Entrega",
  billTo: "Facturación",
  phoneNumber: "T",
  mobileNumber: "M"
};
var addressSuggestion$c = {
  verifyYourAddress: "Verifique su dirección",
  ensureAccuracySuggestChange: "Para garantizar la fiabilidad de entrega, sugerimos el cambio seleccionado a continuación.",
  chooseAddressToUse: "Elija la dirección que desea usar:",
  suggestedAddress: "Dirección sugerida",
  enteredAddress: "Dirección ingresada",
  editAddress: "Editar dirección",
  saveAddress: "Guardar dirección"
};
var addressMessages$c = {
  setAsDefaultSuccessfully: "La dirección {{ streetAddress }} se estableció correctamente como predeterminada"
};
var address$c = {
  addressForm: addressForm$c,
  addressBook: addressBook$c,
  addressCard: addressCard$c,
  addressSuggestion: addressSuggestion$c,
  addressMessages: addressMessages$c
};
var myAccountV2Email$o = {
  myEmailAddress: "Mi dirección de correo electrónico",
  email: "Correo electrónico",
  emailAddress: "Dirección de correo electrónico",
  newEmailAddress: "Nueva dirección de correo electrónico",
  confirmNewEmailAddress: "Confirmar nueva dirección de correo electrónico",
  emailPlaceHolder: "Ingresar correo electrónico",
  passwordPlaceHolder: "Ingresar contraseña",
  password: "Contraseña",
  reloginIndicator: "Después de establecer una nueva dirección de correo electrónico, debe volver a iniciar sesión."
};
var myAccountV2Email$p = {
  myAccountV2Email: myAccountV2Email$o
};
var myAccountV2PasswordForm$c = {
  oldPassword: {
    label: "Contraseña anterior",
    placeholder: "Ingresar contraseña anterior"
  },
  oldPasswordIsRequired: "Se requiere la contraseña anterior.",
  newPassword: {
    label: "Nueva contraseña",
    placeholder: "Ingresar nueva contraseña"
  },
  passwordMinRequirements: "La contraseña debe tener seis caracteres como mínimo, con una letra mayúscula, un número y un símbolo",
  confirmPassword: {
    label: "Confirmar contraseña nueva",
    placeholder: "Confirmar contraseña nueva"
  },
  reloginIndicator: "Después de establecer la contraseña, debe volver a iniciar sesión.",
  bothPasswordMustMatch: "Ambas contraseñas deben coincidir",
  passwordUpdateSuccess: "Contraseña actualizada correctamente",
  accessDeniedError: "Se denegó el acceso",
  newPasswordTitle: "Mi contraseña"
};
var myAccountV2Password$c = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$c
};
var myAccountV2UserProfile$o = {
  myEmailAddress: "Mi dirección de correo electrónico",
  myInformation: "Mi información",
  name: "Nombre",
  customerId: "ID de cliente",
  title: "Tratamiento",
  firstName: "Nombre",
  lastName: "Apellido"
};
var myAccountV2UserProfile$p = {
  myAccountV2UserProfile: myAccountV2UserProfile$o
};
var updateEmailForm$c = {
  newEmailAddress: {
    label: "Nueva dirección de correo electrónico",
    placeholder: "Ingresar correo electrónico"
  },
  confirmNewEmailAddress: {
    label: "Confirmar nueva dirección de correo electrónico",
    placeholder: "Ingresar correo electrónico"
  },
  enterValidEmail: "Ingrese un correo electrónico válido.",
  bothEmailMustMatch: "Ambos correos electrónicos deben coincidir",
  password: {
    label: "Contraseña",
    placeholder: "Ingrese contraseña"
  },
  pleaseInputPassword: "Ingrese la contraseña",
  emailUpdateSuccess: "Hecho. Inicie sesión con {{ newUid }}"
};
var register$c = {
  confirmPassword: {
    action: "Confirmar contraseña",
    label: "Confirmar contraseña",
    placeholder: "Confirmar contraseña"
  },
  managementInMyAccount: "Gestión en Mi cuenta",
  termsAndConditions: "Términos y condiciones",
  signIn: "Ya tengo cuenta. Iniciar sesión.",
  register: "Registrarme",
  confirmNewPassword: "Confirmar nueva contraseña",
  resetPassword: "Restablecer contraseña",
  createAccount: "Crear una cuenta",
  title: "Título",
  titleOptional: "Título (opcional)",
  firstName: {
    label: "Nombre",
    placeholder: "Nombre"
  },
  lastName: {
    label: "Apellido",
    placeholder: "Apellido"
  },
  emailAddress: {
    label: "Dirección de correo electrónico",
    placeholder: "Dirección de correo electrónico"
  },
  password: {
    label: "Contraseña",
    placeholder: "Contraseña"
  },
  newPassword: "Nueva contraseña",
  emailMarketing: "Utilizar mis datos personales para recibir newsletters publicitarios por correo electrónico. Para cambiar la configuración, diríjase a Gestión de consentimiento en Mi cuenta.",
  confirmThatRead: "Confirmo que he leído y estoy de acuerdo con los",
  selectTitle: "Seleccionar título",
  passwordMinRequirements: "Esta es una guía rápida para generar una contraseña segura: utilice al menos 8 caracteres e incluya letras en mayúscula y minúscula, números y símbolos.",
  passwordStrengthRequirements: "Esta es una guía rápida para generar una contraseña segura: utilice al menos 8 caracteres e incluya letras en mayúscula y minúscula, números y símbolos. Asegúrese de no utilizar el mismo carácter de forma consecutiva.",
  bothPasswordMustMatch: "Ambas contraseñas deben coincidir",
  titleRequired: "Se requiere un título.",
  postRegisterMessage: "Inicie sesión con las credenciales provistas.",
  postRegisterSuccessMessage: "Registro exitoso: inicie sesión con las credenciales provistas"
};
var forgottenPassword$c = {
  resetPassword: "Restablecer contraseña",
  enterEmailAddressAssociatedWithYourAccount: "Introduzca la dirección de correo electrónico asociada con su cuenta",
  emailAddress: {
    label: "Dirección de correo electrónico",
    placeholder: "Ingresar correo electrónico"
  },
  enterValidEmail: "Ingrese un correo electrónico válido.",
  passwordResetEmailSent: "Le hemos enviado un correo electrónico que contiene la información sobre cómo restablecer su contraseña.",
  passwordResetSuccess: "¡Hecho! Ahora puede iniciar sesión con su contraseña nueva."
};
var userProfile$c = {
  updateEmailForm: updateEmailForm$c,
  register: register$c,
  forgottenPassword: forgottenPassword$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  address: address$c,
  myAccountV2Email: myAccountV2Email$p,
  myAccountV2Password: myAccountV2Password$c,
  myAccountV2UserProfile: myAccountV2UserProfile$p,
  userProfile: userProfile$c
};
var addressForm$b = {
  title: "Titre",
  titleOptional: "Titre (facultatif)",
  defaultTitle: "Titre",
  firstName: {
    label: "Prénom",
    placeholder: "Prénom"
  },
  lastName: {
    label: "Nom de famille",
    placeholder: "Nom de famille"
  },
  address1: "Adresse 1",
  address2: "Adresse 2 (facultatif)",
  country: "Pays/Région",
  city: {
    label: "Ville",
    placeholder: "Ville"
  },
  state: "État",
  zipCode: {
    label: "Code postal",
    placeholder: "Code postal"
  },
  phoneNumber: {
    label: "Numéro de téléphone (facultatif)",
    placeholder: "par exemple (###) - ### - ####"
  },
  cellphone: {
    label: "Numéro de mobile (facultatif)",
    placeholder: "par exemple (###) - ### - ####"
  },
  saveAsDefault: "Sauvegarder par défaut",
  chooseAddress: "Sélectionner l'adresse",
  streetAddress: "Adresse postale",
  aptSuite: "Numéro d’appartement",
  selectOne: "Sélectionner un…",
  setAsDefault: "Définir comme adresse de livraison par défaut",
  titleRequired: "Le titre est obligatoire.",
  userAddressAddSuccess: "La nouvelle adresse a été ajoutée avec succès !",
  userAddressUpdateSuccess: "Adresse mise à jour avec succès !",
  userAddressDeleteSuccess: "Adresse supprimée avec succès !",
  invalidAddress: "Adresse non valide"
};
var addressBook$b = {
  addNewDeliveryAddress: "Ajouter une nouvelle adresse de livraison",
  editDeliveryAddress: "Modifier l'adresse de livraison",
  areYouSureToDeleteAddress: "Voulez-vous vraiment supprimer cette adresse ?",
  addNewAddress: "Ajouter une nouvelle adresse",
  addAddress: "Ajouter une adresse",
  updateAddress: "Mettre à jour l'adresse",
  backToAddressList: "Retour à la liste des adresses",
  defaultDeliveryAddress: "Adresse de livraison par défaut",
  additionalDeliveryAddress: "Adresse de livraison supplémentaire {{number}}"
};
var addressCard$b = {
  "default": "PAR DÉFAUT",
  selected: "Sélectionnée",
  selectedAddress: "Adresse sélectionnée",
  setAsDefault: "Définir par défaut",
  shipTo: "Adresse d'expédition",
  billTo: "Adresse de facturation",
  phoneNumber: "T",
  mobileNumber: "M"
};
var addressSuggestion$b = {
  verifyYourAddress: "Vérifiez votre adresse",
  ensureAccuracySuggestChange: "Pour garantir l'exactitude de la livraison, nous suggérons la modification sélectionnée ci-dessous.",
  chooseAddressToUse: "Veuillez sélectionner l'adresse que vous souhaitez utiliser :",
  suggestedAddress: "Adresse suggérée",
  enteredAddress: "Adresse saisie",
  editAddress: "Modifier l'adresse",
  saveAddress: "Enregistrer l'adresse"
};
var addressMessages$b = {
  setAsDefaultSuccessfully: "L'adresse {{ streetAddress }} a bien été définie par défaut."
};
var address$b = {
  addressForm: addressForm$b,
  addressBook: addressBook$b,
  addressCard: addressCard$b,
  addressSuggestion: addressSuggestion$b,
  addressMessages: addressMessages$b
};
var myAccountV2Email$m = {
  myEmailAddress: "Mon adresse e-mail",
  email: "E-mail",
  emailAddress: "Adresse e-mail",
  newEmailAddress: "Nouvelle adresse e-mail",
  confirmNewEmailAddress: "Confirmer la nouvelle adresse e-mail",
  emailPlaceHolder: "Saisir l'e-mail",
  passwordPlaceHolder: "Saisir le mot de passe",
  password: "Mot de passe",
  reloginIndicator: "Vous devez vous reconnecter après avoir défini une nouvelle adresse e-mail."
};
var myAccountV2Email$n = {
  myAccountV2Email: myAccountV2Email$m
};
var myAccountV2PasswordForm$b = {
  oldPassword: {
    label: "Ancien mot de passe",
    placeholder: "Saisir l'ancien mot de passe"
  },
  oldPasswordIsRequired: "L'ancien mot de passe est obligatoire.",
  newPassword: {
    label: "Nouveau mot de passe",
    placeholder: "Saisir le nouveau mot de passe"
  },
  passwordMinRequirements: "Le mot de passe doit contenir six caractères minimum, dont une majuscule, un chiffre et un symbole.",
  confirmPassword: {
    label: "Confirmer le nouveau mot de passe",
    placeholder: "Confirmer le nouveau mot de passe"
  },
  reloginIndicator: "Vous devez vous reconnecter après avoir défini le mot de passe.",
  bothPasswordMustMatch: "Les deux mots de passe doivent correspondre",
  passwordUpdateSuccess: "Le mot de passe a été mis à jour avec succès",
  accessDeniedError: "Accès refusé",
  newPasswordTitle: "Mon mot de passe"
};
var myAccountV2Password$b = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$b
};
var myAccountV2UserProfile$m = {
  myEmailAddress: "Mon adresse e-mail",
  myInformation: "Mes informations",
  name: "Nom",
  customerId: "ID client",
  title: "Civilité",
  firstName: "Prénom",
  lastName: "Nom de famille"
};
var myAccountV2UserProfile$n = {
  myAccountV2UserProfile: myAccountV2UserProfile$m
};
var updateEmailForm$b = {
  newEmailAddress: {
    label: "Nouvelle adresse e-mail",
    placeholder: "Saisissez l'adresse e-mail"
  },
  confirmNewEmailAddress: {
    label: "Confirmez la nouvelle adresse e-mail",
    placeholder: "Saisissez l'adresse e-mail"
  },
  enterValidEmail: "Veuillez saisir une adresse e-mail valide.",
  bothEmailMustMatch: "Les deux adresses e-mail doivent correspondre",
  password: {
    label: "Mot de passe",
    placeholder: "Saisissez le mot de passe"
  },
  pleaseInputPassword: "Veuillez saisir le mot de passe",
  emailUpdateSuccess: "Parfait ! Veuillez vous connecter avec {{ newUid }}"
};
var register$b = {
  confirmPassword: {
    action: "Confirmer le mot de passe",
    label: "Confirmer le mot de passe",
    placeholder: "Confirmer le mot de passe"
  },
  managementInMyAccount: "Gestion dans Mon compte",
  termsAndConditions: "Conditions générales",
  signIn: "J'ai déjà un compte. Connexion",
  register: "S'inscrire",
  confirmNewPassword: "Confirmer le nouveau mot de passe",
  resetPassword: "Réinitialiser le mot de passe",
  createAccount: "Créer un compte",
  title: "Titre",
  titleOptional: "Titre (facultatif)",
  firstName: {
    label: "Prénom",
    placeholder: "Prénom"
  },
  lastName: {
    label: "Nom de famille",
    placeholder: "Nom de famille"
  },
  emailAddress: {
    label: "Adresse e-mail",
    placeholder: "Adresse e-mail"
  },
  password: {
    label: "Mot de passe",
    placeholder: "Mot de passe"
  },
  newPassword: "Nouveau mot de passe",
  emailMarketing: "Utiliser mes données personnelles pour recevoir des lettres d'information par e-mail dans le cadre de campagnes de marketing. Pour modifier vos paramètres, allez à Gestion des consentements dans Mon compte.",
  confirmThatRead: "Je confirme que j'ai lu et accepté les",
  selectTitle: "Sélectionner un titre",
  passwordMinRequirements: "Voici un guide rapide pour un mot de passe sûr : Utilisez au moins 8 caractères avec un mélange de lettres majuscules et minuscules, de chiffres et de symboles.",
  passwordStrengthRequirements: "Voici un guide rapide pour un mot de passe sûr : utilisez au moins 8 caractères avec un mélange de lettres majuscules et minuscules, de chiffres et de symboles. Veillez à ne pas utiliser le même caractère consécutivement.",
  bothPasswordMustMatch: "Les deux mots de passe doivent correspondre",
  titleRequired: "Le titre est obligatoire.",
  postRegisterMessage: "Veuillez vous connecter avec les identifiants fournis.",
  postRegisterSuccessMessage: "Enregistrement réussi : Veuillez vous connecter avec les identifiants fournis"
};
var forgottenPassword$b = {
  resetPassword: "Réinitialiser le mot de passe",
  enterEmailAddressAssociatedWithYourAccount: "Saisissez l'adresse e-mail associée à votre compte",
  emailAddress: {
    label: "Adresse e-mail",
    placeholder: "Saisissez l'adresse e-mail"
  },
  enterValidEmail: "Saisissez une adresse e-mail valide.",
  passwordResetEmailSent: "Un e-mail vous a été envoyé avec des informations pour réinitialiser votre mot de passe.",
  passwordResetSuccess: "Parfait ! Vous pouvez désormais vous connecter en utilisant votre nouveau mot de passe."
};
var userProfile$b = {
  updateEmailForm: updateEmailForm$b,
  register: register$b,
  forgottenPassword: forgottenPassword$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  address: address$b,
  myAccountV2Email: myAccountV2Email$n,
  myAccountV2Password: myAccountV2Password$b,
  myAccountV2UserProfile: myAccountV2UserProfile$n,
  userProfile: userProfile$b
};
var addressForm$a = {
  title: "शीर्षक",
  titleOptional: "शीर्षक (वैकल्पिक)",
  defaultTitle: "शीर्षक",
  firstName: {
    label: "प्रथम नाम",
    placeholder: "पहला नाम"
  },
  lastName: {
    label: "अंतिम नाम",
    placeholder: "अंतिम नाम"
  },
  address1: "पता 1",
  address2: "पता 2 (वैकल्पिक)",
  country: "देश/क्षेत्र",
  city: {
    label: "शहर",
    placeholder: "शहर"
  },
  state: "राज्य",
  zipCode: {
    label: "जिप कोड",
    placeholder: "पोस्टल कोड/जिप"
  },
  phoneNumber: {
    label: "फोन नंबर (वैकल्पिक)",
    placeholder: "उदाहरण (###) - ### - ####"
  },
  cellphone: {
    label: "मोबाइल नंबर (वैकल्पिक)",
    placeholder: "उदाहरण (###) - ### - ####"
  },
  saveAsDefault: "डिफ़ॉल्ट के रूप में सहेजें",
  chooseAddress: "पता चुनें",
  streetAddress: "सड़क का पता",
  aptSuite: "अपार्टमेंट संख्या",
  selectOne: "एक चुनें...",
  setAsDefault: "डिफ़ॉल्ट डिलीवरी पते के रूप में सेट करें",
  titleRequired: "शीर्षक आवश्यक है.",
  userAddressAddSuccess: "नया पता सफलतापूर्वक जोड़ा गया!",
  userAddressUpdateSuccess: "पता सफलतापूर्वक अपडेट किया गया!",
  userAddressDeleteSuccess: "पता सफलतापूर्वक हटाया गया!",
  invalidAddress: "अमान्य पता"
};
var addressBook$a = {
  addNewDeliveryAddress: "डिलीवरी का नया पता जोड़ें",
  editDeliveryAddress: "वितरण का पता संपादित करें",
  areYouSureToDeleteAddress: "क्या आप वाकई इस पते को हटाना चाहते हैं?",
  addNewAddress: "नया पता जोड़ें",
  addAddress: "पता जोड़ें",
  updateAddress: "पता अपडेट करें",
  backToAddressList: "पता सूची में वापस जाएं",
  defaultDeliveryAddress: "डिफ़ॉल्ट वितरण पता",
  additionalDeliveryAddress: "अतिरिक्त वितरण पता{{number}}"
};
var addressCard$a = {
  "default": "डिफ़ॉल्ट",
  selected: "चयनित",
  selectedAddress: "पता चुनें",
  setAsDefault: "डिफ़ॉल्ट के रूप में सेट करें",
  shipTo: "यहां भेजें",
  billTo: "पर बिल करें",
  phoneNumber: "P",
  mobileNumber: "M"
};
var addressSuggestion$a = {
  verifyYourAddress: "अपना पता सत्यापित करें",
  ensureAccuracySuggestChange: "वितरण शुद्धता सुनिश्चित करने के लिए, हम निम्न चयित परिवर्तन की अनुशंसा करते हैं.",
  chooseAddressToUse: "आप कौनसे पते का उपयोग करना चाहते हैं वो कृपया चुनेंः",
  suggestedAddress: "सूचित पता",
  enteredAddress: "दर्ज किया गया पता",
  editAddress: "पता संपादित करें",
  saveAddress: "पता सहेजें "
};
var addressMessages$a = {
  setAsDefaultSuccessfully: "पता {{ streetAddress }} सफलतापूर्वक डिफ़ॉल्ट के रूप में सेट किया गया"
};
var address$a = {
  addressForm: addressForm$a,
  addressBook: addressBook$a,
  addressCard: addressCard$a,
  addressSuggestion: addressSuggestion$a,
  addressMessages: addressMessages$a
};
var myAccountV2Email$k = {
  myEmailAddress: "मेरा ईमेल पता",
  email: "ईमेल",
  emailAddress: "ईमेल पता",
  newEmailAddress: "नया ईमेल पता",
  confirmNewEmailAddress: "नए ईमेल पते की पुष्टि करे",
  emailPlaceHolder: "ईमेल दर्ज करें",
  passwordPlaceHolder: "पासवर्ड दर्ज करें",
  password: "पासवर्ड",
  reloginIndicator: "नया ईमेल पता सेट करने के बाद आपको फिर से लॉगिन करने की आवश्यकता है."
};
var myAccountV2Email$l = {
  myAccountV2Email: myAccountV2Email$k
};
var myAccountV2PasswordForm$a = {
  oldPassword: {
    label: "पुराना पासवर्ड",
    placeholder: "पुराना पासवर्ड दर्ज करें"
  },
  oldPasswordIsRequired: "पुराना पासवर्ड आवश्यक है.",
  newPassword: {
    label: "नया पासवर्ड",
    placeholder: "नया पासवर्ड दर्ज करें"
  },
  passwordMinRequirements: "पासवर्ड में एक अपरकेस वर्ण, एक संख्या, एक प्रतीक सहित कम से कम छः वर्ण होना चाहिए",
  confirmPassword: {
    label: "नए पासवर्ड की पुष्टि करें",
    placeholder: "नए पासवर्ड की पुष्टि करें"
  },
  reloginIndicator: "पासवर्ड सेट करने के बाद आपको फिर से लॉगिन करने की आवश्यकता है.",
  bothPasswordMustMatch: "दोनों पासवर्ड मेल खाना चाहिए",
  passwordUpdateSuccess: "पासवर्ड सफलतापूर्वक अपडेट किया गया",
  accessDeniedError: "पहुंच अस्वीकृत किया गया",
  newPasswordTitle: "मेरा पासवर्ड"
};
var myAccountV2Password$a = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$a
};
var myAccountV2UserProfile$k = {
  myEmailAddress: "मेरा ईमेल पता",
  myInformation: "मेरी जानकारी",
  name: "नाम",
  customerId: "ग्राहक Id",
  title: "शीर्षक",
  firstName: "प्रथम नाम",
  lastName: "अंतिम नाम"
};
var myAccountV2UserProfile$l = {
  myAccountV2UserProfile: myAccountV2UserProfile$k
};
var updateEmailForm$a = {
  newEmailAddress: {
    label: "नया ईमेल पता",
    placeholder: "ईमेल दर्ज करें"
  },
  confirmNewEmailAddress: {
    label: "नए ईमेल पते की पुष्टि करे",
    placeholder: "ईमेल दर्ज करें"
  },
  enterValidEmail: "कृपया कोई मान्य ईमेल दर्ज करें. ",
  bothEmailMustMatch: "दोनों ईमेल मेल खाने चाहिए",
  password: {
    label: "पासवर्ड",
    placeholder: "पासवर्ड दर्ज करें"
  },
  pleaseInputPassword: "कृपया पासवर्ड इनपुट करें",
  emailUpdateSuccess: "सफलता. कृपया {{ newUid }} के साथ साइन इन करें"
};
var register$a = {
  confirmPassword: {
    action: "पासवर्ड की पुष्टि करें",
    label: "पासवर्ड की पुष्टि करें",
    placeholder: "पासवर्ड की पुष्टि करें"
  },
  managementInMyAccount: "मेरे खाते में प्रबंधन.",
  termsAndConditions: "नियम & शर्तें",
  signIn: "मेरा पहले से खाता है. साइन इन",
  register: "पंजीकृत करें",
  confirmNewPassword: "नए पासवर्ड की पुष्टि करें",
  resetPassword: "पासवर्ड रीसेट करें",
  createAccount: "एक खाता बनाएं",
  title: "शीर्षक",
  titleOptional: "शीर्षक (वैकल्पिक)",
  firstName: {
    label: "प्रथम नाम",
    placeholder: "प्रथम नाम"
  },
  lastName: {
    label: "अंतिम नाम",
    placeholder: "अंतिम नाम"
  },
  emailAddress: {
    label: "ईमेल पता",
    placeholder: "ईमेल पता"
  },
  password: {
    label: "पासवर्ड",
    placeholder: "पासवर्ड"
  },
  newPassword: "नया पासवर्ड",
  emailMarketing: "मार्केटिंग कंपनियों के ईमेल समाचार पत्र प्राप्त करने के लिए मेरे निजी डेटा का प्रयोग करें. अपने सेटिंग बदले के लिए, मेरा खाता में सहमति प्रबंधन पर जाएं.",
  confirmThatRead: "मैं पुष्टि कर रहा हूं कि मैंने पढ़ कर स्वीकृत करता हूं",
  selectTitle: "शीर्षक चुनें",
  passwordMinRequirements: "सुरक्षित पासवर्ड के लिए त्वरित मार्गदर्शिकाः अपर और लोअर केस के वर्ण, संख्या और प्रतीक चिह्नों के कम से कम 8 वर्णों का उपयोग करें.",
  passwordStrengthRequirements: "सुरक्षित पासवर्ड के लिए त्वरित मार्गदर्शिकाः अपर और लोअर केस के वर्ण, संख्या और प्रतीक चिह्नों के कम से कम 8 वर्णों का अपयोग करे. ध्यान रखें कि एक ही वर्ण का लगातार उपयोग न करें.",
  bothPasswordMustMatch: "दोनों पासवर्ड मेल खाना चाहिए",
  titleRequired: "शीर्षक आवश्यक है.",
  postRegisterMessage: "कृपया प्रदत्त परिचय द्वारा लॉगिन करें.",
  postRegisterSuccessMessage: "सफल पंजीकरणः कृपया प्रदत्त परिचय द्वारा लॉगिन करें."
};
var forgottenPassword$a = {
  resetPassword: "पासवर्ड रीसेट करें",
  enterEmailAddressAssociatedWithYourAccount: "अपने खाते से संबंधित ईमेल पता दर्ज करें",
  emailAddress: {
    label: "ईमेल पता",
    placeholder: "ईमेल दर्ज करें"
  },
  enterValidEmail: "कृपया कोई मान्य ईमेल दर्ज करें. ",
  passwordResetEmailSent: "आपको पासवर्ड रीसेट करने की जानकारी वाला ईमेल भेजा जाएगा.",
  passwordResetSuccess: "सफल रहा! अब आप अपने नए पासवर्ड का उपयोग करके लॉगिन कर सकते हैं."
};
var userProfile$a = {
  updateEmailForm: updateEmailForm$a,
  register: register$a,
  forgottenPassword: forgottenPassword$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  address: address$a,
  myAccountV2Email: myAccountV2Email$l,
  myAccountV2Password: myAccountV2Password$a,
  myAccountV2UserProfile: myAccountV2UserProfile$l,
  userProfile: userProfile$a
};
var addressForm$9 = {
  title: "Megszólítás",
  titleOptional: "Megszólítás (opcionális)",
  defaultTitle: "Megszólítás",
  firstName: {
    label: "Utónév",
    placeholder: "Utónév"
  },
  lastName: {
    label: "Vezetéknév",
    placeholder: "Vezetéknév"
  },
  address1: "Cím 1",
  address2: "Cím 2 (opcionális)",
  country: "Ország/régió",
  city: {
    label: "Város",
    placeholder: "Város"
  },
  state: "Állam",
  zipCode: {
    label: "Irányítószám",
    placeholder: "Irányítószám"
  },
  phoneNumber: {
    label: "Telefonszám (opcionális)",
    placeholder: "pl. (###) - ### - ####"
  },
  cellphone: {
    label: "Mobilszám (opcionális)",
    placeholder: "pl. (###) - ### - ####"
  },
  saveAsDefault: "Mentés alapértelmezettként",
  chooseAddress: "Cím kiválasztása",
  streetAddress: "Utcanév",
  aptSuite: "Lakásszám",
  selectOne: "Válasszon egyet…",
  setAsDefault: "Beállítás alapértelmezett kézbesítési címként",
  titleRequired: "A megszólítás kitöltése kötelező.",
  userAddressAddSuccess: "Új cím sikeresen hozzáadva!",
  userAddressUpdateSuccess: "Cím sikeresen frissítve!",
  userAddressDeleteSuccess: "Cím sikeresen törölve!",
  invalidAddress: "Érvénytelen cím"
};
var addressBook$9 = {
  addNewDeliveryAddress: "Új kézbesítési cím hozzáadása",
  editDeliveryAddress: "Kézbesítési cím szerkesztése",
  areYouSureToDeleteAddress: "Biztosan törli ezt a címet?",
  addNewAddress: "Új cím hozzáadása",
  addAddress: "Cím hozzáadása",
  updateAddress: "Cím frissítése",
  backToAddressList: "Vissza a címlistához",
  defaultDeliveryAddress: "Alapértelmezett kézbesítési cím",
  additionalDeliveryAddress: "További kézbesítési cím ({{number}})"
};
var addressCard$9 = {
  "default": "ALAPÉRTELMEZETT",
  selected: "Kiválasztva",
  selectedAddress: "Kiválasztott cím",
  setAsDefault: "Beállítás alapértelmezettként",
  shipTo: "Szállítási cím",
  billTo: "Számlázási cím",
  phoneNumber: "P",
  mobileNumber: "M"
};
var addressSuggestion$9 = {
  verifyYourAddress: "Ellenőrizze a címét",
  ensureAccuracySuggestChange: "A kézbesítés pontossága érdekében azt javasoljuk, hogy módosítsa az alábbiakat.",
  chooseAddressToUse: "Válassza ki, hogy mely címet szeretné használni:",
  suggestedAddress: "Javasolt cím",
  enteredAddress: "Megadott cím",
  editAddress: "Cím szerkesztése",
  saveAddress: "Cím mentése"
};
var addressMessages$9 = {
  setAsDefaultSuccessfully: "A(z) {{ streetAddress }} cím sikeresen beállítva alapértelmezettként"
};
var address$9 = {
  addressForm: addressForm$9,
  addressBook: addressBook$9,
  addressCard: addressCard$9,
  addressSuggestion: addressSuggestion$9,
  addressMessages: addressMessages$9
};
var myAccountV2Email$i = {
  myEmailAddress: "Saját e-mail-cím",
  email: "E-mail",
  emailAddress: "E-mail-cím",
  newEmailAddress: "Új e-mail-cím",
  confirmNewEmailAddress: "Új e-mail-cím megerősítése",
  emailPlaceHolder: "E-mail-cím megadása",
  passwordPlaceHolder: "Jelszó megadása",
  password: "Jelszó",
  reloginIndicator: "Új e-mail-cím beállítása esetén újra be kell jelentkeznie."
};
var myAccountV2Email$j = {
  myAccountV2Email: myAccountV2Email$i
};
var myAccountV2PasswordForm$9 = {
  oldPassword: {
    label: "Régi jelszó",
    placeholder: "Adja meg a régi jelszavát"
  },
  oldPasswordIsRequired: "A régi jelszó megadása kötelező.",
  newPassword: {
    label: "Új jelszó",
    placeholder: "Adja meg az új jelszót"
  },
  passwordMinRequirements: "A jelszónak legalább hat karakterből kell állnia, legalább egy nagybetűvel, egy számmal és egy szimbólummal",
  confirmPassword: {
    label: "Új jelszó megerősítése",
    placeholder: "Új jelszó megerősítése"
  },
  reloginIndicator: "A jelszó beállítása után újra be kell jelentkeznie.",
  bothPasswordMustMatch: "A két jelszónak egyeznie kell",
  passwordUpdateSuccess: "Jelszó frissítése sikeres",
  accessDeniedError: "Hozzáférés megtagadva",
  newPasswordTitle: "Saját jelszó"
};
var myAccountV2Password$9 = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$9
};
var myAccountV2UserProfile$i = {
  myEmailAddress: "Saját e-mail-cím",
  myInformation: "Saját információk",
  name: "Név",
  customerId: "CustomerId",
  title: "Megszólítás",
  firstName: "Utónév",
  lastName: "Vezetéknév"
};
var myAccountV2UserProfile$j = {
  myAccountV2UserProfile: myAccountV2UserProfile$i
};
var updateEmailForm$9 = {
  newEmailAddress: {
    label: "Új e-mail-cím",
    placeholder: "E-mail-cím megadása"
  },
  confirmNewEmailAddress: {
    label: "Új e-mail-cím megerősítése",
    placeholder: "E-mail-cím megadása"
  },
  enterValidEmail: "Érvényes e-mail-címet adjon meg.",
  bothEmailMustMatch: "A két e-mail-címnek egyeznie kell",
  password: {
    label: "Jelszó",
    placeholder: "Jelszó megadása"
  },
  pleaseInputPassword: "Adja meg a jelszót",
  emailUpdateSuccess: "Siker. Jelentkezzen be a következővel: {{ newUid }}"
};
var register$9 = {
  confirmPassword: {
    action: "Jelszó megerősítése",
    label: "Jelszó megerősítése",
    placeholder: "Jelszó megerősítése"
  },
  managementInMyAccount: "Kezelés a Saját fiók menüben.",
  termsAndConditions: "Használati feltételek",
  signIn: "Már van fiókom. Bejelentkezés",
  register: "Regisztráció",
  confirmNewPassword: "Új jelszó megerősítése",
  resetPassword: "Jelszó visszaállítása",
  createAccount: "Fiók létrehozása",
  title: "Megszólítás",
  titleOptional: "Megszólítás (opcionális)",
  firstName: {
    label: "Utónév",
    placeholder: "Utónév"
  },
  lastName: {
    label: "Vezetéknév",
    placeholder: "Vezetéknév"
  },
  emailAddress: {
    label: "E-mail-cím",
    placeholder: "E-mail-cím"
  },
  password: {
    label: "Jelszó",
    placeholder: "Jelszó"
  },
  newPassword: "Új jelszó",
  emailMarketing: "A személyes adatai felhasználhatók, hogy e-mailes hírlevelet küldjük a marketingkampányok részeként. A beállításait a Saját fiók Hozzájárulás-kezelés menüjében módosíthatja.",
  confirmThatRead: "Megerősítem, hogy elolvastam és elfogadom a következőt:",
  selectTitle: "Megszólítás kiválasztása",
  passwordMinRequirements: "Íme egy gyors útmutató a biztonságos jelszó létrehozásához: Használjon legalább 8 karaktert, kis- és nagybetűket, számokat és szimbólumokat.",
  passwordStrengthRequirements: "Íme egy gyors útmutató a biztonságos jelszó létrehozásához: Használjon legalább 8 karaktert, kis- és nagybetűket, számokat és szimbólumokat. Ne használja többször egymás után ugyanazt a karaktert.",
  bothPasswordMustMatch: "A két jelszónak egyeznie kell.",
  titleRequired: "A megszólítás kitöltése kötelező.",
  postRegisterMessage: "Jelentkezzen be a megadott azonosító adatokkal.",
  postRegisterSuccessMessage: "Sikeres regisztráció: Jelentkezzen be a megadott azonosító adatokkal."
};
var forgottenPassword$9 = {
  resetPassword: "Jelszó visszaállítása",
  enterEmailAddressAssociatedWithYourAccount: "Adja meg a fiókjához társított e-mail-címet",
  emailAddress: {
    label: "E-mail-cím",
    placeholder: "E-mail-cím megadása"
  },
  enterValidEmail: "Érvényes e-mail-címet adjon meg.",
  passwordResetEmailSent: "Elküldtünk Önnek egy e-mailt a jelszó visszaállítására vonatkozó útmutatóval.",
  passwordResetSuccess: "Siker! Most már bejelentkezhet az új jelszavával."
};
var userProfile$9 = {
  updateEmailForm: updateEmailForm$9,
  register: register$9,
  forgottenPassword: forgottenPassword$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  address: address$9,
  myAccountV2Email: myAccountV2Email$j,
  myAccountV2Password: myAccountV2Password$9,
  myAccountV2UserProfile: myAccountV2UserProfile$j,
  userProfile: userProfile$9
};
var addressForm$8 = {
  title: "Gelar/Sapaan",
  titleOptional: "Gelar/Sapaan (opsional)",
  defaultTitle: "Gelar/Sapaan",
  firstName: {
    label: "Nama depan",
    placeholder: "Nama Depan"
  },
  lastName: {
    label: "Nama belakang",
    placeholder: "Nama Belakang"
  },
  address1: "Alamat 1",
  address2: "Alamat 2 (opsional)",
  country: "Negara/Wilayah",
  city: {
    label: "Kota",
    placeholder: "Kota"
  },
  state: "Provinsi",
  zipCode: {
    label: "Kode pos",
    placeholder: "Kode Pos"
  },
  phoneNumber: {
    label: "Nomor telepon (opsional)",
    placeholder: "misalnya (+62) - ### - #### - ####"
  },
  cellphone: {
    label: "Nomor ponsel (opsional)",
    placeholder: "misalnya (+62) - ### - #### - ####"
  },
  saveAsDefault: "Simpan sebagai default",
  chooseAddress: "Pilih alamat",
  streetAddress: "Alamat Jalan",
  aptSuite: "Nomor Apartemen",
  selectOne: "Pilih Satu...",
  setAsDefault: "Tetapkan sebagai alamat pengiriman utama",
  titleRequired: "Gelar/sapaan wajib diisi.",
  userAddressAddSuccess: "Alamat baru berhasil ditambahkan!",
  userAddressUpdateSuccess: "Alamat berhasil diperbarui!",
  userAddressDeleteSuccess: "Alamat berhasil dihapus!",
  invalidAddress: "Alamat Tidak Valid"
};
var addressBook$8 = {
  addNewDeliveryAddress: "Tambahkan alamat pengiriman baru",
  editDeliveryAddress: "Edit alamat pengiriman",
  areYouSureToDeleteAddress: "Apakah Anda yakin ingin menghapus permanen alamat ini?",
  addNewAddress: "Tambah alamat baru",
  addAddress: "Tambahkan alamat",
  updateAddress: "Perbarui alamat",
  backToAddressList: "Kembali ke daftar alamat",
  defaultDeliveryAddress: "Alamat Pengiriman Utama",
  additionalDeliveryAddress: "Alamat Pengiriman Tambahan {{number}}"
};
var addressCard$8 = {
  "default": "UTAMA",
  selected: "Dipilih",
  selectedAddress: "Alamat yang Dipilih",
  setAsDefault: "Tetapkan sebagai utama",
  shipTo: "Kirim Ke",
  billTo: "Tagih ke",
  phoneNumber: "T",
  mobileNumber: "P"
};
var addressSuggestion$8 = {
  verifyYourAddress: "Verifikasi alamat Anda",
  ensureAccuracySuggestChange: "Untuk memastikan ketepatan pengiriman, kami menyarankan perubahan yang dipilih di bawah ini.",
  chooseAddressToUse: "Silakan pilih alamat yang ingin Anda gunakan:",
  suggestedAddress: "Alamat yang disarankan",
  enteredAddress: "Alamat yang dimasukkan",
  editAddress: "Edit alamat",
  saveAddress: "Simpan alamat"
};
var addressMessages$8 = {
  setAsDefaultSuccessfully: "Alamat {{ streetAddress }} berhasil ditetapkan sebagai alamat utama"
};
var address$8 = {
  addressForm: addressForm$8,
  addressBook: addressBook$8,
  addressCard: addressCard$8,
  addressSuggestion: addressSuggestion$8,
  addressMessages: addressMessages$8
};
var myAccountV2Email$g = {
  myEmailAddress: "Alamat Email Saya",
  email: "Email",
  emailAddress: "Alamat Email",
  newEmailAddress: "Alamat Email Baru",
  confirmNewEmailAddress: "Konfirmasi Alamat Email Baru",
  emailPlaceHolder: "Masukkan email",
  passwordPlaceHolder: "Masukkan kata sandi",
  password: "Kata sandi",
  reloginIndicator: "Anda perlu masuk kembali setelah mengatur alamat email baru."
};
var myAccountV2Email$h = {
  myAccountV2Email: myAccountV2Email$g
};
var myAccountV2PasswordForm$8 = {
  oldPassword: {
    label: "Kata Sandi Lama",
    placeholder: "Masukkan Kata Sandi Lama"
  },
  oldPasswordIsRequired: "Kata sandi lama wajib diisi.",
  newPassword: {
    label: "Kata Sandi Baru",
    placeholder: "Masukkan Kata Sandi Baru"
  },
  passwordMinRequirements: "Kata sandi harus berisi setidaknya enam karakter, dengan satu huruf besar, satu angka, dan satu simbol",
  confirmPassword: {
    label: "Konfirmasi Kata Sandi Baru",
    placeholder: "Konfirmasi Kata Sandi Baru"
  },
  reloginIndicator: "Anda perlu masuk kembali setelah mengatur kata sandi.",
  bothPasswordMustMatch: "Kedua kata sandi harus sama",
  passwordUpdateSuccess: "Kata sandi berhasil diperbarui",
  accessDeniedError: "Akses ditolak",
  newPasswordTitle: "Kata Sandi Saya"
};
var myAccountV2Password$8 = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$8
};
var myAccountV2UserProfile$g = {
  myEmailAddress: "Alamat Email Saya",
  myInformation: "Informasi Saya",
  name: "Nama",
  customerId: "Id Pelanggan",
  title: "Gelar/Sapaan",
  firstName: "Nama depan",
  lastName: "Nama belakang"
};
var myAccountV2UserProfile$h = {
  myAccountV2UserProfile: myAccountV2UserProfile$g
};
var updateEmailForm$8 = {
  newEmailAddress: {
    label: "Alamat email baru",
    placeholder: "Masukkan email"
  },
  confirmNewEmailAddress: {
    label: "Konfirmasikan alamat email baru",
    placeholder: "Masukkan email"
  },
  enterValidEmail: "Silakan masukkan email yang valid.",
  bothEmailMustMatch: "Kedua email harus sama",
  password: {
    label: "Kata sandi",
    placeholder: "Masukkan kata sandi"
  },
  pleaseInputPassword: "Silakan masukkan kata sandi",
  emailUpdateSuccess: "Berhasil. Silakan masuk dengan {{ newUid }}"
};
var register$8 = {
  confirmPassword: {
    action: "Konfirmasikan kata sandi",
    label: "Konfirmasikan kata sandi",
    placeholder: "Konfirmasikan Kata Sandi"
  },
  managementInMyAccount: "Pengelolaan di Akun Saya.",
  termsAndConditions: "Syarat & Ketentuan",
  signIn: "Saya sudah memiliki akun. Masuk",
  register: "Daftar",
  confirmNewPassword: "Konfirmasi Kata Sandi Baru",
  resetPassword: "Atur Ulang Kata Sandi",
  createAccount: "Buat akun",
  title: "Gelar/Sapaan",
  titleOptional: "Gelar/Sapaan (opsional)",
  firstName: {
    label: "Nama depan",
    placeholder: "Nama depan"
  },
  lastName: {
    label: "Nama belakang",
    placeholder: "Nama belakang"
  },
  emailAddress: {
    label: "Alamat email",
    placeholder: "Alamat email"
  },
  password: {
    label: "Kata sandi",
    placeholder: "Kata sandi"
  },
  newPassword: "Kata Sandi Baru",
  emailMarketing: "Gunakan data pribadi saya untuk menerima buletin email terkait kampanye pemasaran. Untuk mengubah pengaturan Anda, buka Pengelolaan Persetujuan di Akun Saya.",
  confirmThatRead: "Saya mengonfirmasi bahwa saya telah membaca dan menyetujui",
  selectTitle: "Pilih Gelar/Sapaan",
  passwordMinRequirements: "Berikut panduan singkat untuk membuat kata sandi yang aman: Gunakan setidaknya 8 karakter dengan kombinasi huruf besar dan kecil, angka, serta simbol.",
  passwordStrengthRequirements: "Berikut panduan singkat untuk membuat kata sandi yang aman: Gunakan setidaknya 8 karakter dengan kombinasi huruf besar dan kecil, angka, serta simbol. Pastikan untuk tidak menggunakan karakter yang sama secara berurutan.",
  bothPasswordMustMatch: "Kedua kata sandi harus sama",
  titleRequired: "Gelar/sapaan wajib diisi.",
  postRegisterMessage: "Silakan masuk dengan kredensial yang diberikan.",
  postRegisterSuccessMessage: "Pendaftaran Berhasil: Silakan masuk dengan kredensial yang diberikan"
};
var forgottenPassword$8 = {
  resetPassword: "Atur ulang kata sandi",
  enterEmailAddressAssociatedWithYourAccount: "Masukkan alamat email yang terkait dengan akun Anda",
  emailAddress: {
    label: "Alamat email",
    placeholder: "Masukkan email"
  },
  enterValidEmail: "Silakan masukkan email yang valid.",
  passwordResetEmailSent: "Email telah dikirimkan kepada Anda yang berisi informasi tentang cara mengatur ulang kata sandi Anda.",
  passwordResetSuccess: "Berhasil! Anda sekarang dapat masuk menggunakan kata sandi Anda yang baru."
};
var userProfile$8 = {
  updateEmailForm: updateEmailForm$8,
  register: register$8,
  forgottenPassword: forgottenPassword$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  address: address$8,
  myAccountV2Email: myAccountV2Email$h,
  myAccountV2Password: myAccountV2Password$8,
  myAccountV2UserProfile: myAccountV2UserProfile$h,
  userProfile: userProfile$8
};
var addressForm$7 = {
  title: "Titolo",
  titleOptional: "Titolo (facoltativo)",
  defaultTitle: "Titolo",
  firstName: {
    label: "Nome",
    placeholder: "Nome"
  },
  lastName: {
    label: "Cognome",
    placeholder: "Cognome"
  },
  address1: "Indirizzo 1",
  address2: "Indirizzo 2 (facoltativo)",
  country: "Paese/regione",
  city: {
    label: "Città",
    placeholder: "Città"
  },
  state: "Stato",
  zipCode: {
    label: "CAP",
    placeholder: "Codice postale/CAP"
  },
  phoneNumber: {
    label: "Numero di telefono (facoltativo)",
    placeholder: "ad es. (###) - ### - ####"
  },
  cellphone: {
    label: "Numero di cellulare (facoltativo)",
    placeholder: "ad es. (###) - ### - ####"
  },
  saveAsDefault: "Salva come predefinito",
  chooseAddress: "Scegli indirizzo",
  streetAddress: "Via",
  aptSuite: "Numero di appartamento",
  selectOne: "Seleziona uno...",
  setAsDefault: "Salva come indirizzo di consegna predefinito",
  titleRequired: "Il titolo è obbligatorio.",
  userAddressAddSuccess: "Nuovo indirizzo aggiunto correttamente.",
  userAddressUpdateSuccess: "Indirizzo aggiornato correttamente.",
  userAddressDeleteSuccess: "Indirizzo eliminato correttamente.",
  invalidAddress: "Indirizzo non valido"
};
var addressBook$7 = {
  addNewDeliveryAddress: "Aggiungi un nuovo indirizzo di consegna",
  editDeliveryAddress: "Modifica indirizzo di consegna",
  areYouSureToDeleteAddress: "Confermare di voler eliminare questo indirizzo?",
  addNewAddress: "Aggiungi nuovo indirizzo",
  addAddress: "Aggiungi indirizzo",
  updateAddress: "Aggiorna indirizzo",
  backToAddressList: "Torna alla lista di indirizzi",
  defaultDeliveryAddress: "Indirizzo di consegna predefinito",
  additionalDeliveryAddress: "Indirizzo di consegna aggiuntivo {{number}}"
};
var addressCard$7 = {
  "default": "PREDEFINITO",
  selected: "Selezionato",
  selectedAddress: "Indirizzo selezionato",
  setAsDefault: "Imposta come predefinito",
  shipTo: "Destinatario spedizione",
  billTo: "Destinatario fattura",
  phoneNumber: "P",
  mobileNumber: "M"
};
var addressSuggestion$7 = {
  verifyYourAddress: "Verifica l'indirizzo",
  ensureAccuracySuggestChange: "Per garantire l'accuratezza della consegna, si consiglia la modifica selezionata sotto.",
  chooseAddressToUse: "Scegliere l'indirizzo da utilizzare:",
  suggestedAddress: "Indirizzo consigliato",
  enteredAddress: "Indirizzo inserito",
  editAddress: "Modifica indirizzo",
  saveAddress: "Salva indirizzo"
};
var addressMessages$7 = {
  setAsDefaultSuccessfully: "Indirizzo {{ streetAddress }} impostato correttamente come predefinito"
};
var address$7 = {
  addressForm: addressForm$7,
  addressBook: addressBook$7,
  addressCard: addressCard$7,
  addressSuggestion: addressSuggestion$7,
  addressMessages: addressMessages$7
};
var myAccountV2Email$e = {
  myEmailAddress: "Indirizzo e-mail personale",
  email: "E-mail",
  emailAddress: "Indirizzo e-mail",
  newEmailAddress: "Nuovo indirizzo e-mail",
  confirmNewEmailAddress: "Conferma nuovo indirizzo e-mail",
  emailPlaceHolder: "Inserisci e-mail",
  passwordPlaceHolder: "Inserisci password",
  password: "Password",
  reloginIndicator: "Dopo aver impostato un nuovo indirizzo e-mail è necessario accedere di nuovo."
};
var myAccountV2Email$f = {
  myAccountV2Email: myAccountV2Email$e
};
var myAccountV2PasswordForm$7 = {
  oldPassword: {
    label: "Password precedente",
    placeholder: "Inserisci password precedente"
  },
  oldPasswordIsRequired: "La password precedente è obbligatoria.",
  newPassword: {
    label: "Nuova password",
    placeholder: "Inserisci nuova password"
  },
  passwordMinRequirements: "La password deve avere un minimo di sei caratteri, con una lettera maiuscola, un numero e un simbolo",
  confirmPassword: {
    label: "Conferma nuova password",
    placeholder: "Conferma nuova password"
  },
  reloginIndicator: "Dopo aver impostato la password è necessario accedere di nuovo.",
  bothPasswordMustMatch: "Le password devono coincidere",
  passwordUpdateSuccess: "Password aggiornata correttamente",
  accessDeniedError: "Accesso negato",
  newPasswordTitle: "Password personale"
};
var myAccountV2Password$7 = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$7
};
var myAccountV2UserProfile$e = {
  myEmailAddress: "Indirizzo e-mail personale",
  myInformation: "Informazioni personali",
  name: "Nome",
  customerId: "ID cliente",
  title: "Titolo",
  firstName: "Nome",
  lastName: "Cognome"
};
var myAccountV2UserProfile$f = {
  myAccountV2UserProfile: myAccountV2UserProfile$e
};
var updateEmailForm$7 = {
  newEmailAddress: {
    label: "Nuovo indirizzo e-mail",
    placeholder: "Inserisci e-mail"
  },
  confirmNewEmailAddress: {
    label: "Conferma nuovo indirizzo e-mail",
    placeholder: "Inserisci e-mail"
  },
  enterValidEmail: "Inserire un indirizzo e-mail valido.",
  bothEmailMustMatch: "Le e-mail devono coincidere",
  password: {
    label: "Password",
    placeholder: "Inserisci password"
  },
  pleaseInputPassword: "Inserisci password",
  emailUpdateSuccess: "Operazione eseguita correttamente. Accedere con {{ newUid }}."
};
var register$7 = {
  confirmPassword: {
    action: "Conferma password",
    label: "Conferma password",
    placeholder: "Conferma password"
  },
  managementInMyAccount: "Gestione in Account personale.",
  termsAndConditions: "Termini e condizioni",
  signIn: "Ho già un account. Accedi",
  register: "Registrati",
  confirmNewPassword: "Conferma nuova password",
  resetPassword: "Reimposta password",
  createAccount: "Crea un account",
  title: "Titolo",
  titleOptional: "Titolo (facoltativo)",
  firstName: {
    label: "Nome",
    placeholder: "Nome"
  },
  lastName: {
    label: "Cognome",
    placeholder: "Cognome"
  },
  emailAddress: {
    label: "Indirizzo e-mail",
    placeholder: "Indirizzo e-mail"
  },
  password: {
    label: "Password",
    placeholder: "Password"
  },
  newPassword: "Nuova password",
  emailMarketing: "Utilizzare i dati personali per ricevere newslettere e-mail per campagne di marketing. Per modificare le impostazioni, passare a Gestione consensi in Account personale.",
  confirmThatRead: "Confermo di aver letto e accettato",
  selectTitle: "Seleziona titolo",
  passwordMinRequirements: "Questa è una guida rapida per una password sicura: utilizzare almeno otto caratteri con una combinazione di lettere maiuscole e minuscole, numeri e simboli.",
  passwordStrengthRequirements: "Questa è una guida rapida per una password sicura: utilizzare almeno otto caratteri con una combinazione di lettere maiuscole e minuscole, numeri e simboli. Assicurarsi di non utilizzare lo stesso carattere due volte consecutive.",
  bothPasswordMustMatch: "Le password devono coincidere",
  titleRequired: "Il titolo è obbligatorio.",
  postRegisterMessage: "Accedere con le credenziali fornite.",
  postRegisterSuccessMessage: "Registrazione corretta. Accedere con le credenziali fornite."
};
var forgottenPassword$7 = {
  resetPassword: "Reimposta password",
  enterEmailAddressAssociatedWithYourAccount: "Inserisci l'indirizzo e-mail associato all'account",
  emailAddress: {
    label: "Indirizzo e-mail",
    placeholder: "Inserisci e-mail"
  },
  enterValidEmail: "Inserire un indirizzo e-mail valido.",
  passwordResetEmailSent: "È stata inviata un'e-mail contenente informazioni su come reimpostare la password.",
  passwordResetSuccess: "Operazione riuscita. Ora è possibile accedere utilizzando la nuova password."
};
var userProfile$7 = {
  updateEmailForm: updateEmailForm$7,
  register: register$7,
  forgottenPassword: forgottenPassword$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  address: address$7,
  myAccountV2Email: myAccountV2Email$f,
  myAccountV2Password: myAccountV2Password$7,
  myAccountV2UserProfile: myAccountV2UserProfile$f,
  userProfile: userProfile$7
};
var addressForm$6 = {
  title: "タイトル",
  titleOptional: "タイトル (オプション)",
  defaultTitle: "タイトル",
  firstName: {
    label: "名",
    placeholder: "名"
  },
  lastName: {
    label: "姓",
    placeholder: "姓"
  },
  address1: "住所 1",
  address2: "住所 2 (オプション)",
  country: "国/地域",
  city: {
    label: "市区町村",
    placeholder: "市区町村"
  },
  state: "都道府県/州",
  zipCode: {
    label: "郵便番号",
    placeholder: "郵便番号"
  },
  phoneNumber: {
    label: "電話番号 (オプション)",
    placeholder: "例 (###) - ### - ####"
  },
  cellphone: {
    label: "携帯電話番号 (オプション)",
    placeholder: "例 (###) - ### - ####"
  },
  saveAsDefault: "デフォルトとして保存",
  chooseAddress: "住所の選択",
  streetAddress: "番地",
  aptSuite: "部屋番号",
  selectOne: "1 つ選択...",
  setAsDefault: "既定の配送先住所として設定",
  titleRequired: "タイトルが必要です。",
  userAddressAddSuccess: "新しい住所が正常に追加されました。",
  userAddressUpdateSuccess: "住所が正常に更新されました。",
  userAddressDeleteSuccess: "住所が正常に削除されました。",
  invalidAddress: "無効な住所です"
};
var addressBook$6 = {
  addNewDeliveryAddress: "新しい配送先住所の追加",
  editDeliveryAddress: "配送先住所の編集",
  areYouSureToDeleteAddress: "この住所を削除してもよろしいですか?",
  addNewAddress: "新しい住所の追加",
  addAddress: "住所の追加",
  updateAddress: "住所の更新",
  backToAddressList: "住所一覧に戻る",
  defaultDeliveryAddress: "既定の配送先住所",
  additionalDeliveryAddress: "追加の配送先住所 {{number}}"
};
var addressCard$6 = {
  "default": "既定の設定",
  selected: "選択済み",
  selectedAddress: "選択された住所",
  setAsDefault: "既定に設定",
  shipTo: "配送先",
  billTo: "請求先",
  phoneNumber: "P",
  mobileNumber: "M"
};
var addressSuggestion$6 = {
  verifyYourAddress: "住所の確認",
  ensureAccuracySuggestChange: "配送を正確に行うために、以下の選択を変更することをお奨めします。",
  chooseAddressToUse: "どの住所を使用するのかを選択してください。",
  suggestedAddress: "推奨される住所",
  enteredAddress: "入力された住所",
  editAddress: "住所を編集",
  saveAddress: "住所を保存"
};
var addressMessages$6 = {
  setAsDefaultSuccessfully: "住所 {{ streetAddress }} が既定の住所として正常に設定されました"
};
var address$6 = {
  addressForm: addressForm$6,
  addressBook: addressBook$6,
  addressCard: addressCard$6,
  addressSuggestion: addressSuggestion$6,
  addressMessages: addressMessages$6
};
var myAccountV2Email$c = {
  myEmailAddress: "自分の電子メールアドレス",
  email: "電子メール",
  emailAddress: "電子メールアドレス",
  newEmailAddress: "新しい電子メールアドレス",
  confirmNewEmailAddress: "新しい電子メールアドレスの確認",
  emailPlaceHolder: "電子メールアドレスを入力",
  passwordPlaceHolder: "パスワードを入力",
  password: "パスワード",
  reloginIndicator: "新しい電子メールアドレスの設定後、再度ログインする必要があります。"
};
var myAccountV2Email$d = {
  myAccountV2Email: myAccountV2Email$c
};
var myAccountV2PasswordForm$6 = {
  oldPassword: {
    label: "旧パスワード",
    placeholder: "旧パスワードを入力"
  },
  oldPasswordIsRequired: "旧パスワードが必要です。",
  newPassword: {
    label: "新しいパスワード",
    placeholder: "新しいパスワードを入力"
  },
  passwordMinRequirements: "パスワードは、大文字 1 文字、数字 1 文字、記号 1 文字を含む、6 文字以上である必要があります",
  confirmPassword: {
    label: "新しいパスワードを再入力",
    placeholder: "新しいパスワードを再入力"
  },
  reloginIndicator: "パスワードの設定後、もう一度ログインする必要があります。",
  bothPasswordMustMatch: "両方のパスワードが一致する必要があります",
  passwordUpdateSuccess: "パスワードが正常に更新されました",
  accessDeniedError: "アクセスが拒否されました",
  newPasswordTitle: "パスワード"
};
var myAccountV2Password$6 = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$6
};
var myAccountV2UserProfile$c = {
  myEmailAddress: "自分のメールアドレス",
  myInformation: "My 情報",
  name: "名前",
  customerId: "顧客 ID",
  title: "タイトル",
  firstName: "名",
  lastName: "姓"
};
var myAccountV2UserProfile$d = {
  myAccountV2UserProfile: myAccountV2UserProfile$c
};
var updateEmailForm$6 = {
  newEmailAddress: {
    label: "新しいメールアドレス",
    placeholder: "メールアドレスを入力"
  },
  confirmNewEmailAddress: {
    label: "新しいメールアドレスを確認",
    placeholder: "メールアドレスを入力"
  },
  enterValidEmail: "有効なメールアドレスを入力してください。",
  bothEmailMustMatch: "両方のメールアドレスが一致する必要があります",
  password: {
    label: "パスワード",
    placeholder: "パスワードを入力"
  },
  pleaseInputPassword: "パスワードを入力してください",
  emailUpdateSuccess: "成功しました。{{ newUid }} でサインインしてください"
};
var register$6 = {
  confirmPassword: {
    action: "パスワードを再入力",
    label: "パスワードを再入力",
    placeholder: "パスワードを再入力"
  },
  managementInMyAccount: "マイアカウントでの管理",
  termsAndConditions: "利用条件",
  signIn: "既にアカウントをお持ちの場合: サインイン",
  register: "登録",
  confirmNewPassword: "新しいパスワードを再入力",
  resetPassword: "パスワードをリセット",
  createAccount: "アカウントを作成",
  title: "タイトル",
  titleOptional: "タイトル (オプション)",
  firstName: {
    label: "名",
    placeholder: "名"
  },
  lastName: {
    label: "姓",
    placeholder: "姓"
  },
  emailAddress: {
    label: "メールアドレス",
    placeholder: "メールアドレス"
  },
  password: {
    label: "パスワード",
    placeholder: "パスワード"
  },
  newPassword: "新しいパスワード",
  emailMarketing: "個人データを使用して、マーケティングキャンペーンのニュースレターを電子メールで受け取ります。設定を変更するには、マイアカウントの同意管理に移動してください。",
  confirmThatRead: "読んで同意したことを確認します",
  selectTitle: "タイトルを選択",
  passwordMinRequirements: "安全なパスワードについて簡単に説明します。大文字と小文字、数字、および記号を組み合わせた 8 文字以上を使用してださい。",
  passwordStrengthRequirements: "安全なパスワードについて簡単に説明します。大文字と小文字、数字、および記号を組み合わせた 8 文字以上を使用してださい。同じ文字を連続して使用しないようにしてください。",
  bothPasswordMustMatch: "両方のパスワードが一致する必要があります",
  titleRequired: "タイトルは必須です。",
  postRegisterMessage: "指定された認証情報を使用してログインしてください。",
  postRegisterSuccessMessage: "正常に登録: 指定された認証情報を使用してログインしてください。"
};
var forgottenPassword$6 = {
  resetPassword: "パスワードをリセット",
  enterEmailAddressAssociatedWithYourAccount: "アカウントに関連付けられたメールアドレスを入力してください。",
  emailAddress: {
    label: "メールアドレス",
    placeholder: "メールアドレスを入力"
  },
  enterValidEmail: "有効なメールアドレスを入力してください。",
  passwordResetEmailSent: "パスワードのリセット方法が記載された電子メールが送信されました。",
  passwordResetSuccess: "パスワードの再設定に成功しました。新しいパスワードを使用してログインできます。"
};
var userProfile$6 = {
  updateEmailForm: updateEmailForm$6,
  register: register$6,
  forgottenPassword: forgottenPassword$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  address: address$6,
  myAccountV2Email: myAccountV2Email$d,
  myAccountV2Password: myAccountV2Password$6,
  myAccountV2UserProfile: myAccountV2UserProfile$d,
  userProfile: userProfile$6
};
var addressForm$5 = {
  title: "호칭",
  titleOptional: "호칭(선택사항)",
  defaultTitle: "호칭",
  firstName: {
    label: "이름",
    placeholder: "이름"
  },
  lastName: {
    label: "성",
    placeholder: "성"
  },
  address1: "주소 1",
  address2: "주소 2(선택사항)",
  country: "국가/지역",
  city: {
    label: "시/구/군",
    placeholder: "시/구/군"
  },
  state: "시/도",
  zipCode: {
    label: "우편 번호",
    placeholder: "우편 번호"
  },
  phoneNumber: {
    label: "전화 번호(옵션)",
    placeholder: "예: (###) - ### - ####"
  },
  cellphone: {
    label: "휴대폰 번호(옵션)",
    placeholder: "예: (###) - ### - ####"
  },
  saveAsDefault: "기본값으로 저장",
  chooseAddress: "주소 선택",
  streetAddress: "도로 주소",
  aptSuite: "아파트 호수",
  selectOne: "하나를 선택하십시오...",
  setAsDefault: "기본 배송 주소로 설정",
  titleRequired: "호칭은 필수 항목입니다.",
  userAddressAddSuccess: "새 주소가 추가되었습니다.",
  userAddressUpdateSuccess: "주소가 업데이트되었습니다.",
  userAddressDeleteSuccess: "주소가 삭제되었습니다.",
  invalidAddress: "주소가 잘못되었습니다."
};
var addressBook$5 = {
  addNewDeliveryAddress: "새 배송 주소 추가",
  editDeliveryAddress: "배송 주소 편집",
  areYouSureToDeleteAddress: "이 주소를 삭제하시겠습니까?",
  addNewAddress: "새 주소 추가",
  addAddress: "주소 추가",
  updateAddress: "주소 업데이트",
  backToAddressList: "주소록으로 돌아가기",
  defaultDeliveryAddress: "기본 배송 주소",
  additionalDeliveryAddress: "추가 배송 주소 {{number}}"
};
var addressCard$5 = {
  "default": "기본값",
  selected: "선택됨",
  selectedAddress: "선택된 주소",
  setAsDefault: "기본값으로 설정",
  shipTo: "배송지",
  billTo: "청구지",
  phoneNumber: "P",
  mobileNumber: "M"
};
var addressSuggestion$5 = {
  verifyYourAddress: "주소 확인",
  ensureAccuracySuggestChange: "정확한 배송을 위해 아래에 선택된 변경사항을 제안합니다.",
  chooseAddressToUse: "사용할 주소 선택:",
  suggestedAddress: "제안된 주소",
  enteredAddress: "입력한 주소",
  editAddress: "주소 편집",
  saveAddress: "주소 저장"
};
var addressMessages$5 = {
  setAsDefaultSuccessfully: "주소 {{ streetAddress }}이(가) 기본값으로 설정되었습니다."
};
var address$5 = {
  addressForm: addressForm$5,
  addressBook: addressBook$5,
  addressCard: addressCard$5,
  addressSuggestion: addressSuggestion$5,
  addressMessages: addressMessages$5
};
var myAccountV2Email$a = {
  myEmailAddress: "내 이메일 주소",
  email: "이메일",
  emailAddress: "이메일 주소",
  newEmailAddress: "새 이메일 주소",
  confirmNewEmailAddress: "새 이메일 주소 확인",
  emailPlaceHolder: "이메일 입력",
  passwordPlaceHolder: "암호 입력",
  password: "암호",
  reloginIndicator: "새 이메일 주소를 설정한 후에 다시 로그인해야 합니다."
};
var myAccountV2Email$b = {
  myAccountV2Email: myAccountV2Email$a
};
var myAccountV2PasswordForm$5 = {
  oldPassword: {
    label: "기존 암호",
    placeholder: "기존 암호 입력"
  },
  oldPasswordIsRequired: "기존 암호는 필수 항목입니다.",
  newPassword: {
    label: "새 암호",
    placeholder: "새 암호 입력"
  },
  passwordMinRequirements: "암호는 6자 이상이어야 하며, 하나의 대문자, 하나의 숫자, 하나의 기호를 포함해야 합니다.",
  confirmPassword: {
    label: "새 암호 확인",
    placeholder: "새 암호 확인"
  },
  reloginIndicator: "암호를 설정한 후에 다시 로그인해야 합니다.",
  bothPasswordMustMatch: "두 개의 암호가 일치해야 합니다.",
  passwordUpdateSuccess: "암호가 업데이트되었습니다.",
  accessDeniedError: "액세스가 거부되었습니다.",
  newPasswordTitle: "내 암호"
};
var myAccountV2Password$5 = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$5
};
var myAccountV2UserProfile$a = {
  myEmailAddress: "내 전자메일 주소",
  myInformation: "내 정보",
  name: "이름",
  customerId: "고객 ID",
  title: "호칭",
  firstName: "이름",
  lastName: "성"
};
var myAccountV2UserProfile$b = {
  myAccountV2UserProfile: myAccountV2UserProfile$a
};
var updateEmailForm$5 = {
  newEmailAddress: {
    label: "새 이메일 주소",
    placeholder: "이메일 입력"
  },
  confirmNewEmailAddress: {
    label: "새 이메일 주소 확인",
    placeholder: "이메일 입력"
  },
  enterValidEmail: "유효한 이메일을 입력하십시오.",
  bothEmailMustMatch: "두 개의 이메일이 일치해야 합니다.",
  password: {
    label: "암호",
    placeholder: "암호 입력"
  },
  pleaseInputPassword: "암호를 입력하십시오.",
  emailUpdateSuccess: "완료되었습니다. {{ newUid }}(으)로 로그인하십시오."
};
var register$5 = {
  confirmPassword: {
    action: "암호 확인",
    label: "암호 확인",
    placeholder: "암호 확인"
  },
  managementInMyAccount: "내 계정에서 관리",
  termsAndConditions: "사용 약관",
  signIn: "이미 계정이 있습니다. 로그인하십시오.",
  register: "등록",
  confirmNewPassword: "새 암호 확인",
  resetPassword: "암호 재설정",
  createAccount: "계정 만들기",
  title: "호칭",
  titleOptional: "호칭(선택사항)",
  firstName: {
    label: "이름",
    placeholder: "이름"
  },
  lastName: {
    label: "성",
    placeholder: "성"
  },
  emailAddress: {
    label: "이메일 주소",
    placeholder: "이메일 주소"
  },
  password: {
    label: "암호",
    placeholder: "암호"
  },
  newPassword: "새 암호",
  emailMarketing: "마케팅 캠페인에 대한 이메일 뉴스레터를 받으려면 내 개인 데이터를 사용하십시오. 설정을 변경하려면 내 계정에 있는 '동의 관리'로 가십시오.",
  confirmThatRead: "읽었으며 이에 동의함을 확인함 -",
  selectTitle: "호칭 선택",
  passwordMinRequirements: "보안 암호에 대한 퀵 가이드입니다. 8자 이상이어야 하며, 대문자, 소문자, 숫자, 기호가 포함되어야 합니다.",
  passwordStrengthRequirements: "보안 암호에 대한 퀵 가이드입니다. 8자 이상이어야 하며, 대문자, 소문자, 숫자, 기호가 포함되어야 합니다. 같은 문자를 연속해서 사용하지 마십시오.",
  bothPasswordMustMatch: "두 개의 암호가 일치해야 합니다.",
  titleRequired: "칭호는 필수 항목입니다.",
  postRegisterMessage: "제공된 자격 증명으로 로그인하십시오.",
  postRegisterSuccessMessage: "등록 완료: 제공된 자격 증명으로 로그인하십시오."
};
var forgottenPassword$5 = {
  resetPassword: "암호 재설정",
  enterEmailAddressAssociatedWithYourAccount: "계정과 연결된 이메일 주소를 입력하십시오.",
  emailAddress: {
    label: "이메일 주소",
    placeholder: "이메일 입력"
  },
  enterValidEmail: "유효한 이메일을 입력하십시오.",
  passwordResetEmailSent: "암호를 재설정하는 방법에 대한 정보가 포함된 이메일을 보냈습니다.",
  passwordResetSuccess: "완료되었습니다! 이제 새 암호를 사용하여 로그인할 수 있습니다."
};
var userProfile$5 = {
  updateEmailForm: updateEmailForm$5,
  register: register$5,
  forgottenPassword: forgottenPassword$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  address: address$5,
  myAccountV2Email: myAccountV2Email$b,
  myAccountV2Password: myAccountV2Password$5,
  myAccountV2UserProfile: myAccountV2UserProfile$b,
  userProfile: userProfile$5
};
var addressForm$4 = {
  title: "Tytuł",
  titleOptional: "Tytuł (opcjonalny)",
  defaultTitle: "Tytuł",
  firstName: {
    label: "Imię",
    placeholder: "Imię"
  },
  lastName: {
    label: "Nazwisko",
    placeholder: "Nazwisko"
  },
  address1: "Adres 1",
  address2: "Adres 2 (opcjonalny)",
  country: "Kraj/region",
  city: {
    label: "Miasto",
    placeholder: "Miasto"
  },
  state: "Województwo",
  zipCode: {
    label: "Kod pocztowy",
    placeholder: "Kod pocztowy"
  },
  phoneNumber: {
    label: "Numer telefonu (opcjonalny)",
    placeholder: "np. (###) - ### - ####"
  },
  cellphone: {
    label: "Numer telefonu komórkowego (opcjonalny)",
    placeholder: "np. (###) - ### - ###"
  },
  saveAsDefault: "Zapisz jako domyślny",
  chooseAddress: "Wybierz adres",
  streetAddress: "Ulica",
  aptSuite: "Numer mieszkania",
  selectOne: "Wybierz jeden...",
  setAsDefault: "Ustaw jako domyślny adres dostawy",
  titleRequired: "Tytuł jest wymagany.",
  userAddressAddSuccess: "Nowy adres został pomyślnie dodany!",
  userAddressUpdateSuccess: "Aktualizacja adresu powiodła się!",
  userAddressDeleteSuccess: "Adres został usunięty!",
  invalidAddress: "Nieprawidłowy adres"
};
var addressBook$4 = {
  addNewDeliveryAddress: "Dodaj nowy adres dostawy",
  editDeliveryAddress: "Edytuj adres dostawy",
  areYouSureToDeleteAddress: "Czy na pewno chcesz usunąć ten adres?",
  addNewAddress: "Dodaj nowy adres",
  addAddress: "Dodaj adres",
  updateAddress: "Aktualizuj adres",
  backToAddressList: "Powrót do listy adresowej",
  defaultDeliveryAddress: "Domyślny adres dostawy",
  additionalDeliveryAddress: "Dodatkowy adres dostawy {{number}}"
};
var addressCard$4 = {
  "default": "DOMYŚLNY",
  selected: "Wybrany",
  selectedAddress: "Wybrany adres",
  setAsDefault: "Ustaw jako domyślny",
  shipTo: "Wyślij do:",
  billTo: "Odbiorca faktury",
  phoneNumber: "T",
  mobileNumber: "T"
};
var addressSuggestion$4 = {
  verifyYourAddress: "Zweryfikuj adres",
  ensureAccuracySuggestChange: "Aby zapewnić prawidłowość dostawy, sugerujemy zmianę wskazaną poniżej.",
  chooseAddressToUse: "Wybierz adres, którego chcesz użyć:",
  suggestedAddress: "Sugerowany adres",
  enteredAddress: "Wprowadzony adres",
  editAddress: "Edytuj adres",
  saveAddress: "Zapisz adres"
};
var addressMessages$4 = {
  setAsDefaultSuccessfully: "Adres {{ streetAddress }} został ustawiony jako domyślny."
};
var address$4 = {
  addressForm: addressForm$4,
  addressBook: addressBook$4,
  addressCard: addressCard$4,
  addressSuggestion: addressSuggestion$4,
  addressMessages: addressMessages$4
};
var myAccountV2Email$8 = {
  myEmailAddress: "Mój adres e-mail",
  email: "E-mail",
  emailAddress: "Adres e-mail",
  newEmailAddress: "Nowy adres e-mail",
  confirmNewEmailAddress: "Potwierdź nowy adres e-mail",
  emailPlaceHolder: "Wprowadź adres e-mail",
  passwordPlaceHolder: "Wprowadź hasło",
  password: "Hasło",
  reloginIndicator: "Po ustawieniu nowego adresu e-mail należy zalogować się ponownie."
};
var myAccountV2Email$9 = {
  myAccountV2Email: myAccountV2Email$8
};
var myAccountV2PasswordForm$4 = {
  oldPassword: {
    label: "Stare hasło",
    placeholder: "Wprowadź stare hasło"
  },
  oldPasswordIsRequired: "Podanie starego hasła jest wymagane.",
  newPassword: {
    label: "Nowe hasło",
    placeholder: "Wprowadź nowe hasło"
  },
  passwordMinRequirements: "Hasło musi składać się z co najmniej sześciu znaków, w tym jednej wielkiej litery, jednej cyfry i jednego symbolu",
  confirmPassword: {
    label: "Potwierdź nowe hasło",
    placeholder: "Potwierdź nowe hasło"
  },
  reloginIndicator: "Po ustawieniu hasła należy zalogować się ponownie.",
  bothPasswordMustMatch: "Oba hasła muszą być zgodne",
  passwordUpdateSuccess: "Hasło pomyślnie zaktualizowane",
  accessDeniedError: "Odmowa dostępu",
  newPasswordTitle: "Moje hasło"
};
var myAccountV2Password$4 = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$4
};
var myAccountV2UserProfile$8 = {
  myEmailAddress: "Mój adres e-mail",
  myInformation: "Moje informacje",
  name: "Nazwa",
  customerId: "CustomerId",
  title: "Tytuł",
  firstName: "Imię",
  lastName: "Nazwisko"
};
var myAccountV2UserProfile$9 = {
  myAccountV2UserProfile: myAccountV2UserProfile$8
};
var updateEmailForm$4 = {
  newEmailAddress: {
    label: "Nowy adres e-mail",
    placeholder: "Wpisz adres e-mail"
  },
  confirmNewEmailAddress: {
    label: "Potwierdź nowy adres e-mail",
    placeholder: "Wpisz adres e-mail"
  },
  enterValidEmail: "Wprowadź prawidłowy adres e-mail.",
  bothEmailMustMatch: "Oba adresy e-mail muszą być zgodne",
  password: {
    label: "Hasło",
    placeholder: "Wprowadź hasło"
  },
  pleaseInputPassword: "Wprowadź hasło",
  emailUpdateSuccess: "Sukces. Zaloguj się za pomocą {{ newUid }}"
};
var register$4 = {
  confirmPassword: {
    action: "Potwierdź hasło",
    label: "Potwierdź hasło",
    placeholder: "Potwierdź hasło"
  },
  managementInMyAccount: "Zarządzanie Moim kontem.",
  termsAndConditions: "Warunki",
  signIn: "Masz już konto? Zaloguj się",
  register: "Zarejestruj się",
  confirmNewPassword: "Potwierdź nowe hasło",
  resetPassword: "Zresetuj hasło",
  createAccount: "Utwórz konto",
  title: "Tytuł",
  titleOptional: "Tytuł (opcjonalny)",
  firstName: {
    label: "Imię",
    placeholder: "Imię"
  },
  lastName: {
    label: "Nazwisko",
    placeholder: "Nazwisko"
  },
  emailAddress: {
    label: "Adres e-mail",
    placeholder: "Adres e-mail"
  },
  password: {
    label: "Hasło",
    placeholder: "Hasło"
  },
  newPassword: "Nowe hasło",
  emailMarketing: "Użyj moich danych osobowych do otrzymywania newsletterów e-mail w kampaniach marketingowych. Aby zmienić ustawienia, przejdź do sekcji Zarządzanie zgodami w sekcji Moje konto.",
  confirmThatRead: "Potwierdzam, że zapoznałem(-am) się i akceptuję",
  selectTitle: "Wybierz tytuł",
  passwordMinRequirements: "Oto krótki przewodnik po bezpiecznym haśle: Wpisz co najmniej 8 znaków, używając wielkich i małych liter, cyfr i symboli.",
  passwordStrengthRequirements: "Oto krótki przewodnik po bezpiecznym haśle: Wpisz co najmniej 8 znaków, używając wielkich i małych liter, cyfr i symboli. Nie używaj tych samych znaków jeden po drugim.",
  bothPasswordMustMatch: "Oba hasła muszą być zgodne",
  titleRequired: "Tytuł jest wymagany.",
  postRegisterMessage: "Zaloguj się przy użyciu podanych danych.",
  postRegisterSuccessMessage: "Rejestracja udana: zaloguj się przy użyciu podanych danych."
};
var forgottenPassword$4 = {
  resetPassword: "Zresetuj hasło",
  enterEmailAddressAssociatedWithYourAccount: "Wpisz adres e-mail powiązany z Twoim kontem",
  emailAddress: {
    label: "Adres e-mail",
    placeholder: "Wpisz adres e-mail"
  },
  enterValidEmail: "Wprowadź prawidłowy adres e-mail.",
  passwordResetEmailSent: "Wysłano do Ciebie wiadomość e-mail z informacjami o tym, jak zresetować hasło.",
  passwordResetSuccess: "Sukces! Możesz teraz zalogować się przy użyciu nowego hasła."
};
var userProfile$4 = {
  updateEmailForm: updateEmailForm$4,
  register: register$4,
  forgottenPassword: forgottenPassword$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  address: address$4,
  myAccountV2Email: myAccountV2Email$9,
  myAccountV2Password: myAccountV2Password$4,
  myAccountV2UserProfile: myAccountV2UserProfile$9,
  userProfile: userProfile$4
};
var addressForm$3 = {
  title: "Título",
  titleOptional: "Título (opcional)",
  defaultTitle: "Título",
  firstName: {
    label: "Nome",
    placeholder: "Nome"
  },
  lastName: {
    label: "Sobrenome",
    placeholder: "Sobrenome"
  },
  address1: "Endereço 1",
  address2: "Endereço 2 (opcional)",
  country: "País/região",
  city: {
    label: "Cidade",
    placeholder: "Cidade"
  },
  state: "Estado",
  zipCode: {
    label: "CEP",
    placeholder: "Código postal/CEP"
  },
  phoneNumber: {
    label: "Número de telefone (opcional)",
    placeholder: "por exemplo, (###) - ### - ####"
  },
  cellphone: {
    label: "Número de celular (opcional)",
    placeholder: "por exemplo, (###) - ### - ####"
  },
  saveAsDefault: "Gravar como padrão",
  chooseAddress: "Escolher endereço",
  streetAddress: "Endereço",
  aptSuite: "Número do apartamento",
  selectOne: "Selecionar um...",
  setAsDefault: "Definir como endereço de entrega padrão",
  titleRequired: "O título é obrigatório.",
  userAddressAddSuccess: "O novo endereço foi adicionado com êxito.",
  userAddressUpdateSuccess: "Endereço atualizado com êxito.",
  userAddressDeleteSuccess: "Endereço eliminado com êxito.",
  invalidAddress: "Endereço inválido"
};
var addressBook$3 = {
  addNewDeliveryAddress: "Adicionar um novo endereço de entrega",
  editDeliveryAddress: "Editar endereço de entrega",
  areYouSureToDeleteAddress: "Tem certeza de que quer eliminar este endereço?",
  addNewAddress: "Adicionar novo endereço",
  addAddress: "Adicionar endereço",
  updateAddress: "Atualizar endereço",
  backToAddressList: "Voltar à lista de endereços",
  defaultDeliveryAddress: "Endereço de entrega padrão",
  additionalDeliveryAddress: "Endereço de entrega adicional {{number}}"
};
var addressCard$3 = {
  "default": "PADRÃO",
  selected: "Selecionado",
  selectedAddress: "Endereço selecionado",
  setAsDefault: "Definir como padrão",
  shipTo: "Entregar a",
  billTo: "Faturar para",
  phoneNumber: "T",
  mobileNumber: "C"
};
var addressSuggestion$3 = {
  verifyYourAddress: "Verifique seu endereço",
  ensureAccuracySuggestChange: "Para assegurar a exatidão da entrega, sugerimos a modificação selecionada embaixo.",
  chooseAddressToUse: "Selecione o endereço que você gostaria de utilizar:",
  suggestedAddress: "Endereço sugerido",
  enteredAddress: "Endereço inserido",
  editAddress: "Editar endereço",
  saveAddress: "Gravar endereço"
};
var addressMessages$3 = {
  setAsDefaultSuccessfully: "O endereço {{ streetAddress }} foi definido como padrão com êxito"
};
var address$3 = {
  addressForm: addressForm$3,
  addressBook: addressBook$3,
  addressCard: addressCard$3,
  addressSuggestion: addressSuggestion$3,
  addressMessages: addressMessages$3
};
var myAccountV2Email$6 = {
  myEmailAddress: "Meu endereço de e-mail",
  email: "E-mail",
  emailAddress: "Endereço de e-mail",
  newEmailAddress: "Novo endereço de e-mail",
  confirmNewEmailAddress: "Confirmar novo endereço de e-mail",
  emailPlaceHolder: "Inserir e-mail",
  passwordPlaceHolder: "Inserir senha",
  password: "Senha",
  reloginIndicator: "Você precisa iniciar sessão novamente depois de definir um novo endereço de e-mail."
};
var myAccountV2Email$7 = {
  myAccountV2Email: myAccountV2Email$6
};
var myAccountV2PasswordForm$3 = {
  oldPassword: {
    label: "Senha antiga",
    placeholder: "Inserir senha antiga"
  },
  oldPasswordIsRequired: "A senha antiga é obrigatória.",
  newPassword: {
    label: "Nova senha",
    placeholder: "Inserir nova senha"
  },
  passwordMinRequirements: "A senha deve ter um mínimo de seis caracteres, com uma letra maiúscula, um número e um símbolo",
  confirmPassword: {
    label: "Confirmar nova senha",
    placeholder: "Confirmar nova senha"
  },
  reloginIndicator: "Você precisa iniciar sessão novamente depois de definir a senha.",
  bothPasswordMustMatch: "As duas senhas devem corresponder",
  passwordUpdateSuccess: "Senha atualizada com sucesso",
  accessDeniedError: "O acesso foi negado",
  newPasswordTitle: "Minha senha"
};
var myAccountV2Password$3 = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$3
};
var myAccountV2UserProfile$6 = {
  myEmailAddress: "Meu endereço de e-mail",
  myInformation: "Minhas informações",
  name: "Nome",
  customerId: "CustomerId",
  title: "Título",
  firstName: "Nome",
  lastName: "Sobrenome"
};
var myAccountV2UserProfile$7 = {
  myAccountV2UserProfile: myAccountV2UserProfile$6
};
var updateEmailForm$3 = {
  newEmailAddress: {
    label: "Novo endereço de e-mail",
    placeholder: "Inserir e-mail"
  },
  confirmNewEmailAddress: {
    label: "Confirmar novo endereço de e-mail",
    placeholder: "Inserir e-mail"
  },
  enterValidEmail: "Insira um e-mail válido.",
  bothEmailMustMatch: "Os dois e-mails devem corresponder",
  password: {
    label: "Senha",
    placeholder: "Inserir senha"
  },
  pleaseInputPassword: "Insira a senha",
  emailUpdateSuccess: "Êxito. Inicie sessão com {{ newUid }}"
};
var register$3 = {
  confirmPassword: {
    action: "Confirmar senha",
    label: "Confirmar senha",
    placeholder: "Confirmar senha"
  },
  managementInMyAccount: "Gerenciamento em Minha conta.",
  termsAndConditions: "Termos e Condições",
  signIn: "Já tenho uma conta. Iniciar sessão",
  register: "Registrar",
  confirmNewPassword: "Confirmar nova senha",
  resetPassword: "Redefinir senha",
  createAccount: "Criar uma conta",
  title: "Título",
  titleOptional: "Título (opcional)",
  firstName: {
    label: "Nome",
    placeholder: "Nome"
  },
  lastName: {
    label: "Sobrenome",
    placeholder: "Sobrenome"
  },
  emailAddress: {
    label: "Endereço de e-mail",
    placeholder: "Endereço de e-mail"
  },
  password: {
    label: "Senha",
    placeholder: "Senha"
  },
  newPassword: "Nova senha",
  emailMarketing: "Utilizar meus dados pessoais para receber newsletters por e-mail para campanhas de marketing. Para modificar suas configurações, vá para Gerenciamento de consentimentos em Minha conta.",
  confirmThatRead: "Confirmo que li e concordo com os",
  selectTitle: "Selecionar título",
  passwordMinRequirements: "Aqui está um guia rápido para uma senha segura: utilize pelo menos 8 caracteres com uma mistura de letras maiúsculas e minúsculas, números e símbolos.",
  passwordStrengthRequirements: "Aqui está um guia rápido para uma senha segura: Use pelo menos 8 caracteres com uma combinação de letras maiúsculas e minúsculas, números e símbolos.",
  bothPasswordMustMatch: "As duas senhas devem corresponder",
  titleRequired: "O título é obrigatório.",
  postRegisterMessage: "Inicie sessão com as credenciais fornecidas.",
  postRegisterSuccessMessage: "Registro com êxito: inicie sessão com as credenciais fornecidas"
};
var forgottenPassword$3 = {
  resetPassword: "Redefinir senha",
  enterEmailAddressAssociatedWithYourAccount: "Insira o endereço de e-mail associado à sua conta",
  emailAddress: {
    label: "Endereço de e-mail",
    placeholder: "Inserir e-mail"
  },
  enterValidEmail: "Insira um e-mail válido.",
  passwordResetEmailSent: "Um e-mail foi enviado para você com informações sobre como redefinir sua senha.",
  passwordResetSuccess: "Êxito! Você pode agora efetuar o login usando sua nova senha."
};
var userProfile$3 = {
  updateEmailForm: updateEmailForm$3,
  register: register$3,
  forgottenPassword: forgottenPassword$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  address: address$3,
  myAccountV2Email: myAccountV2Email$7,
  myAccountV2Password: myAccountV2Password$3,
  myAccountV2UserProfile: myAccountV2UserProfile$7,
  userProfile: userProfile$3
};
var addressForm$2 = {
  title: "Обращение",
  titleOptional: "Обращение (необязательно)",
  defaultTitle: "Обращение",
  firstName: {
    label: "Имя",
    placeholder: "Имя"
  },
  lastName: {
    label: "Фамилия",
    placeholder: "Фамилия"
  },
  address1: "Адрес 1",
  address2: "Адрес 2 (необязательно)",
  country: "Страна/регион",
  city: {
    label: "Город",
    placeholder: "Город"
  },
  state: "Статус",
  zipCode: {
    label: "Почтовый индекс",
    placeholder: "Почтовый индекс"
  },
  phoneNumber: {
    label: "Телефон (необязательно)",
    placeholder: "например: (###) - ### - ####"
  },
  cellphone: {
    label: "Мобильный телефон (необязательно)",
    placeholder: "например: (###) - ### - ####"
  },
  saveAsDefault: "Сохранить как значение по умолчанию",
  chooseAddress: "Выбрать адрес",
  streetAddress: "Адрес: улица",
  aptSuite: "Номер квартиры",
  selectOne: "Выберите...",
  setAsDefault: "Установить как адрес доставки по умолчанию",
  titleRequired: "Обращение обязательно.",
  userAddressAddSuccess: "Новый адрес добавлен.",
  userAddressUpdateSuccess: "Адрес обновлен.",
  userAddressDeleteSuccess: "Адрес удален.",
  invalidAddress: "Неправильный адрес"
};
var addressBook$2 = {
  addNewDeliveryAddress: "Добавьте новый адрес доставки",
  editDeliveryAddress: "Редактировать адрес доставки",
  areYouSureToDeleteAddress: "Действительно удалить этот адрес?",
  addNewAddress: "Добавить новый адрес",
  addAddress: "Добавить адрес",
  updateAddress: "Обновить адрес",
  backToAddressList: "Назад к списку адресов",
  defaultDeliveryAddress: "Адрес доставки по умолчанию",
  additionalDeliveryAddress: "Дополнительный адрес доставки {{number}}"
};
var addressCard$2 = {
  "default": "ПО УМОЛЧАНИЮ",
  selected: "Выбрано",
  selectedAddress: "Выбранный адрес",
  setAsDefault: "Установить по умолчанию",
  shipTo: "Адрес доставки",
  billTo: "Адрес для счета",
  phoneNumber: "Т",
  mobileNumber: "М"
};
var addressSuggestion$2 = {
  verifyYourAddress: "Проверьте адрес",
  ensureAccuracySuggestChange: "Для обеспечения точности доставки мы предлагаем выбранное ниже изменение.",
  chooseAddressToUse: "Выберите адрес, который вы хотите использовать.",
  suggestedAddress: "Предлагаемый адрес",
  enteredAddress: "Введенный адрес",
  editAddress: "Редактировать адрес",
  saveAddress: "Сохранить адрес"
};
var addressMessages$2 = {
  setAsDefaultSuccessfully: "Адрес {{ streetAddress }} установлен по умолчанию"
};
var address$2 = {
  addressForm: addressForm$2,
  addressBook: addressBook$2,
  addressCard: addressCard$2,
  addressSuggestion: addressSuggestion$2,
  addressMessages: addressMessages$2
};
var myAccountV2Email$4 = {
  myEmailAddress: "Мой адрес электронной почты",
  email: "Электронная почта",
  emailAddress: "Адрес электронной почты",
  newEmailAddress: "Новый адрес электронной почты",
  confirmNewEmailAddress: "Подтвердить новый адрес электронной почты",
  emailPlaceHolder: "Введите электронную почту",
  passwordPlaceHolder: "Введите пароль",
  password: "Пароль",
  reloginIndicator: "После настройки нового адреса электронной почты вам необходимо будет войти в систему еще раз."
};
var myAccountV2Email$5 = {
  myAccountV2Email: myAccountV2Email$4
};
var myAccountV2PasswordForm$2 = {
  oldPassword: {
    label: "Старый пароль",
    placeholder: "Введите старый пароль"
  },
  oldPasswordIsRequired: "Требуется старый пароль.",
  newPassword: {
    label: "Новый пароль",
    placeholder: "Введите новый пароль"
  },
  passwordMinRequirements: "Пароль должен содержать минимум шесть символов, включая одну букву в верхнем регистре, одну цифру и один спецсимвол.",
  confirmPassword: {
    label: "Подтвердить новый пароль",
    placeholder: "Подтвердить новый пароль"
  },
  reloginIndicator: "После установки пароля вам необходимо будет войти в систему еще раз.",
  bothPasswordMustMatch: "Пароли должны совпадать",
  passwordUpdateSuccess: "Пароль успешно обновлен",
  accessDeniedError: "В доступе отказано",
  newPasswordTitle: "Мой пароль"
};
var myAccountV2Password$2 = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$2
};
var myAccountV2UserProfile$4 = {
  myEmailAddress: "Мой адрес электронной почты",
  myInformation: "Мои данные",
  name: "Имя",
  customerId: "Ид. клиента",
  title: "Обращение",
  firstName: "Имя",
  lastName: "Фамилия"
};
var myAccountV2UserProfile$5 = {
  myAccountV2UserProfile: myAccountV2UserProfile$4
};
var updateEmailForm$2 = {
  newEmailAddress: {
    label: "Новый адрес электронной почты",
    placeholder: "Введите электронную почту"
  },
  confirmNewEmailAddress: {
    label: "Подтвердить новый адрес электронной почты",
    placeholder: "Введите электронную почту"
  },
  enterValidEmail: "Введите действительный адрес электронной почты.",
  bothEmailMustMatch: "Адреса электронной почты должны совпадать",
  password: {
    label: "Пароль",
    placeholder: "Введите пароль"
  },
  pleaseInputPassword: "Введите пароль",
  emailUpdateSuccess: "Отлично! Войдите в систему с {{ newUid }}"
};
var register$2 = {
  confirmPassword: {
    action: "Подтвердить пароль",
    label: "Подтвердить пароль",
    placeholder: "Подтвердить пароль"
  },
  managementInMyAccount: "Управление в моей учетной записи.",
  termsAndConditions: "Условия",
  signIn: "У меня уже есть учетная запись. Войти в систему.",
  register: "Зарегистрироваться",
  confirmNewPassword: "Подтвердить новый пароль",
  resetPassword: "Сбросить пароль",
  createAccount: "Создать учетную запись",
  title: "Обращение",
  titleOptional: "Обращение (необязательно)",
  firstName: {
    label: "Имя",
    placeholder: "Имя"
  },
  lastName: {
    label: "Фамилия",
    placeholder: "Фамилия"
  },
  emailAddress: {
    label: "Адрес электронной почты",
    placeholder: "Адрес электронной почты"
  },
  password: {
    label: "Пароль",
    placeholder: "Пароль"
  },
  newPassword: "Новый пароль",
  emailMarketing: "Использовать мои персональные данные для получения новостных рассылок по электронной почте по маркетинговым кампаниям. Чтобы изменить настройки, перейдите в \"Управление согласиями\" в \"Моя учетная запись\".",
  confirmThatRead: "Я подтверждаю, что прочитал(а) и принимаю",
  selectTitle: "Выбрать обращение",
  passwordMinRequirements: "Вот краткие правила создания надежного пароля: используйте не менее 8 символов, в числе которых должны быть буквы в верхнем и нижнем регистре, цифры и спецсимволы.",
  passwordStrengthRequirements: "Вот краткие правила создания надежного пароля: используйте не менее 8 символов, в числе которых должны быть буквы в верхнем и нижнем регистре, цифры и спецсимволы. Не используйте один и тот же символ несколько раз подряд.",
  bothPasswordMustMatch: "Пароли должны совпадать",
  titleRequired: "Обращение обязательно.",
  postRegisterMessage: "Войдите в систему с предоставленными учетными данными.",
  postRegisterSuccessMessage: "Успешная регистрация: войдите в систему с предоставленными учетными данными"
};
var forgottenPassword$2 = {
  resetPassword: "Сбросить пароль",
  enterEmailAddressAssociatedWithYourAccount: "Введите адрес электронной почты, связанный с вашей учетной записью",
  emailAddress: {
    label: "Адрес электронной почты",
    placeholder: "Введите электронную почту"
  },
  enterValidEmail: "Введите действительный адрес электронной почты.",
  passwordResetEmailSent: "Вам отправлено письмо с информацией о том, как сбросить пароль.",
  passwordResetSuccess: "Отлично! Теперь вы можете войти в систему с новым паролем."
};
var userProfile$2 = {
  updateEmailForm: updateEmailForm$2,
  register: register$2,
  forgottenPassword: forgottenPassword$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  address: address$2,
  myAccountV2Email: myAccountV2Email$5,
  myAccountV2Password: myAccountV2Password$2,
  myAccountV2UserProfile: myAccountV2UserProfile$5,
  userProfile: userProfile$2
};
var addressForm$1 = {
  title: "标题",
  titleOptional: "标题（可选）",
  defaultTitle: "标题",
  firstName: {
    label: "名字",
    placeholder: "名字"
  },
  lastName: {
    label: "姓氏",
    placeholder: "姓氏"
  },
  address1: "地址 1",
  address2: "地址 2（可选）",
  country: "国家/地区",
  city: {
    label: "城市",
    placeholder: "城市"
  },
  state: "州/省",
  zipCode: {
    label: "邮政编码",
    placeholder: "邮政编码"
  },
  phoneNumber: {
    label: "电话号码（可选）",
    placeholder: "例如 (###) - ### - ####"
  },
  cellphone: {
    label: "手机号码",
    placeholder: "例如 (###) - ### - ####"
  },
  saveAsDefault: "另存为默认值",
  chooseAddress: "选择地址",
  streetAddress: "街道地址",
  aptSuite: "公寓号",
  selectOne: "选择一个...",
  setAsDefault: "设置默认送货地址",
  titleRequired: "标题为必填项。",
  userAddressAddSuccess: "新地址添加成功！",
  userAddressUpdateSuccess: "地址更新成功！",
  userAddressDeleteSuccess: "地址删除成功！",
  invalidAddress: "无效地址"
};
var addressBook$1 = {
  addNewDeliveryAddress: "添加新送货地址",
  editDeliveryAddress: "编辑送货地址",
  areYouSureToDeleteAddress: "是否确定要删除此地址？",
  addNewAddress: "添加新地址",
  addAddress: "添加地址",
  updateAddress: "更新地址",
  backToAddressList: "返回地址列表",
  defaultDeliveryAddress: "默认送货地址",
  additionalDeliveryAddress: "附加送货地址 {{number}}"
};
var addressCard$1 = {
  "default": "默认值",
  selected: "已选择",
  selectedAddress: "所选地址",
  setAsDefault: "设置为默认值",
  shipTo: "送货地址",
  billTo: "帐单寄送地址",
  phoneNumber: "P",
  mobileNumber: "M"
};
var addressSuggestion$1 = {
  verifyYourAddress: "验证您的地址",
  ensureAccuracySuggestChange: "为确保交货准确性，我们建议进行以下更改。",
  chooseAddressToUse: "请选择您要使用的地址：",
  suggestedAddress: "建议地址",
  enteredAddress: "输入的地址",
  editAddress: "编辑地址",
  saveAddress: "保存地址"
};
var addressMessages$1 = {
  setAsDefaultSuccessfully: "地址 {{ streetAddress }} 已成功设置为默认地址"
};
var address$1 = {
  addressForm: addressForm$1,
  addressBook: addressBook$1,
  addressCard: addressCard$1,
  addressSuggestion: addressSuggestion$1,
  addressMessages: addressMessages$1
};
var myAccountV2Email$2 = {
  myEmailAddress: "我的电子邮件地址",
  email: "电子邮件",
  emailAddress: "电子邮件地址",
  newEmailAddress: "新电子邮件地址",
  confirmNewEmailAddress: "确认新电子邮件地址",
  emailPlaceHolder: "输入电子邮件",
  passwordPlaceHolder: "输入密码",
  password: "密码",
  reloginIndicator: "设置新电子邮件地址后，需要重新登录。"
};
var myAccountV2Email$3 = {
  myAccountV2Email: myAccountV2Email$2
};
var myAccountV2PasswordForm$1 = {
  oldPassword: {
    label: "旧密码",
    placeholder: "输入旧密码"
  },
  oldPasswordIsRequired: "需要提供旧密码。",
  newPassword: {
    label: "新密码",
    placeholder: "输入新密码"
  },
  passwordMinRequirements: "密码必须至少包含六个字符，包括一个大写字母、一个数字、一个符号",
  confirmPassword: {
    label: "确认新密码",
    placeholder: "确认新密码"
  },
  reloginIndicator: "设置密码后需要重新登录。",
  bothPasswordMustMatch: "两个密码必须一致",
  passwordUpdateSuccess: "密码已成功更新",
  accessDeniedError: "访问遭到拒绝",
  newPasswordTitle: "我的密码"
};
var myAccountV2Password$1 = {
  myAccountV2PasswordForm: myAccountV2PasswordForm$1
};
var myAccountV2UserProfile$2 = {
  myEmailAddress: "我的电子邮件地址",
  myInformation: "我的信息",
  name: "名称",
  customerId: "客户标识",
  title: "标题",
  firstName: "名字",
  lastName: "姓氏"
};
var myAccountV2UserProfile$3 = {
  myAccountV2UserProfile: myAccountV2UserProfile$2
};
var updateEmailForm$1 = {
  newEmailAddress: {
    label: "新电子邮件地址",
    placeholder: "输入电子邮件"
  },
  confirmNewEmailAddress: {
    label: "确认新电子邮件地址",
    placeholder: "输入电子邮件"
  },
  enterValidEmail: "请输入有效的电子邮件。",
  bothEmailMustMatch: "两封电子邮件必须匹配",
  password: {
    label: "密码",
    placeholder: "输入密码"
  },
  pleaseInputPassword: "请输入密码",
  emailUpdateSuccess: "成功。请使用 {{ newUid }} 进行登录。"
};
var register$1 = {
  confirmPassword: {
    action: "确认密码",
    label: "确认密码",
    placeholder: "确认密码"
  },
  managementInMyAccount: "“我的帐户”中的“管理”。",
  termsAndConditions: "条款和条件",
  signIn: "我已有帐户。登录",
  register: "注册",
  confirmNewPassword: "确认新密码",
  resetPassword: "重设密码",
  createAccount: "创建帐户",
  title: "标题",
  titleOptional: "标题（可选）",
  firstName: {
    label: "名字",
    placeholder: "名字"
  },
  lastName: {
    label: "姓氏",
    placeholder: "姓氏"
  },
  emailAddress: {
    label: "电子邮件地址",
    placeholder: "电子邮件地址"
  },
  password: {
    label: "密码",
    placeholder: "密码"
  },
  newPassword: "新密码",
  emailMarketing: "使用我的个人数据接收市场营销活动的电子邮件简报。要更改设置，请转到“我的帐户”中的 Consent Management。",
  confirmThatRead: "我确认我已阅读并同意",
  selectTitle: "选择标题",
  passwordMinRequirements: "以下是确保密码安全的快速指南：使用至少 8 个字符，且大写和小写字母、数字和符号混合。",
  passwordStrengthRequirements: "以下是确保密码安全的快速指南：使用至少 8 个字符，且大写和小写字母、数字和符号混合。确保不要连续使用相同字符。",
  bothPasswordMustMatch: "两个密码必须一致",
  titleRequired: "标题为必填项。",
  postRegisterMessage: "请使用提供的凭据进行登录。",
  postRegisterSuccessMessage: "注册成功：请使用提供的凭据进行登录"
};
var forgottenPassword$1 = {
  resetPassword: "重置密码",
  enterEmailAddressAssociatedWithYourAccount: "输入与您的帐户相关联的电子邮件地址。",
  emailAddress: {
    label: "电子邮件地址",
    placeholder: "输入电子邮件"
  },
  enterValidEmail: "请输入有效的电子邮件。",
  passwordResetEmailSent: "已向您发送电子邮件，其中包含有关如何重置密码的信息。",
  passwordResetSuccess: "成功！现在可以使用新密码登录了。"
};
var userProfile$1 = {
  updateEmailForm: updateEmailForm$1,
  register: register$1,
  forgottenPassword: forgottenPassword$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  address: address$1,
  myAccountV2Email: myAccountV2Email$3,
  myAccountV2Password: myAccountV2Password$1,
  myAccountV2UserProfile: myAccountV2UserProfile$3,
  userProfile: userProfile$1
};
var addressForm = {
  title: "稱謂",
  titleOptional: "稱謂 (選擇性)",
  defaultTitle: "稱謂",
  firstName: {
    label: "名字",
    placeholder: "名字"
  },
  lastName: {
    label: "姓氏",
    placeholder: "姓氏"
  },
  address1: "地址 1",
  address2: "地址行 2 (選擇性)",
  country: "國家/地區",
  city: {
    label: "城市",
    placeholder: "城市"
  },
  state: "州",
  zipCode: {
    label: "郵遞區號",
    placeholder: "郵遞區號/ZIP"
  },
  phoneNumber: {
    label: "電話號碼 (選擇性)",
    placeholder: "範例：(###) - ### - ####"
  },
  cellphone: {
    label: "行動電話號碼 (選擇性)",
    placeholder: "範例：(###) - ### - ####"
  },
  saveAsDefault: "儲存為預設",
  chooseAddress: "選擇地址",
  streetAddress: "街道地址",
  aptSuite: "公寓號碼",
  selectOne: "選擇一個...",
  setAsDefault: "設定為預設交貨地址",
  titleRequired: "稱謂為必要。",
  userAddressAddSuccess: "已成功新增地址！",
  userAddressUpdateSuccess: "已成功更新地址！",
  userAddressDeleteSuccess: "已成功刪除地址！",
  invalidAddress: "地址無效"
};
var addressBook = {
  addNewDeliveryAddress: "新增交貨地址",
  editDeliveryAddress: "編輯交貨地址",
  areYouSureToDeleteAddress: "是否確定要刪除此地址？",
  addNewAddress: "新增地址",
  addAddress: "新增地址",
  updateAddress: "更新地址",
  backToAddressList: "返回地址清單",
  defaultDeliveryAddress: "預設交貨地址",
  additionalDeliveryAddress: "附加交貨地址 {{number}}"
};
var addressCard = {
  "default": "預設",
  selected: "已選擇",
  selectedAddress: "所選地址",
  setAsDefault: "設定為預設",
  shipTo: "運送地址",
  billTo: "帳單地址",
  phoneNumber: "電話號碼",
  mobileNumber: "行動電話號碼"
};
var addressSuggestion = {
  verifyYourAddress: "驗證地址",
  ensureAccuracySuggestChange: "為了確保交貨準確性，建議您更改下方所選項目。",
  chooseAddressToUse: "請選擇要使用的地址：",
  suggestedAddress: "建議的地址",
  enteredAddress: "輸入的地址",
  editAddress: "編輯地址",
  saveAddress: "儲存地址"
};
var addressMessages = {
  setAsDefaultSuccessfully: "已成功將地址 {{ streetAddress }} 設定為預設"
};
var address = {
  addressForm: addressForm,
  addressBook: addressBook,
  addressCard: addressCard,
  addressSuggestion: addressSuggestion,
  addressMessages: addressMessages
};
var myAccountV2Email = {
  myEmailAddress: "我的電子郵件地址",
  email: "電子郵件",
  emailAddress: "電子郵件地址",
  newEmailAddress: "新電子郵件地址",
  confirmNewEmailAddress: "確認新電子郵件地址",
  emailPlaceHolder: "輸入電子郵件",
  passwordPlaceHolder: "輸入密碼",
  password: "密碼",
  reloginIndicator: "設定新電子郵件地址後，您必須再次登入。"
};
var myAccountV2Email$1 = {
  myAccountV2Email: myAccountV2Email
};
var myAccountV2PasswordForm = {
  oldPassword: {
    label: "舊密碼",
    placeholder: "輸入舊密碼"
  },
  oldPasswordIsRequired: "舊密碼為必要。",
  newPassword: {
    label: "新密碼",
    placeholder: "輸入新密碼"
  },
  passwordMinRequirements: "密碼最少必須是六個字元，包含大寫字母、一個數字、一個符號",
  confirmPassword: {
    label: "確認新密碼",
    placeholder: "確認新密碼"
  },
  reloginIndicator: "設定密碼後，您必須再次登入。",
  bothPasswordMustMatch: "兩者密碼必須相符",
  passwordUpdateSuccess: "密碼更新成功",
  accessDeniedError: "已拒絕存取",
  newPasswordTitle: "我的密碼"
};
var myAccountV2Password = {
  myAccountV2PasswordForm: myAccountV2PasswordForm
};
var myAccountV2UserProfile = {
  myEmailAddress: "我的電子郵件地址",
  myInformation: "我的資訊",
  name: "名稱",
  customerId: "客戶 ID",
  title: "稱謂",
  firstName: "名字",
  lastName: "姓氏"
};
var myAccountV2UserProfile$1 = {
  myAccountV2UserProfile: myAccountV2UserProfile
};
var updateEmailForm = {
  newEmailAddress: {
    label: "新電子郵件地址",
    placeholder: "輸入電子郵件"
  },
  confirmNewEmailAddress: {
    label: "確認新電子郵件地址",
    placeholder: "輸入電子郵件"
  },
  enterValidEmail: "請輸入有效電子郵件。",
  bothEmailMustMatch: "兩者電子郵件必須相符",
  password: {
    label: "密碼",
    placeholder: "輸入密碼"
  },
  pleaseInputPassword: "請輸入密碼",
  emailUpdateSuccess: "成功。請使用 {{ newUid }} 登入"
};
var register = {
  confirmPassword: {
    action: "確認密碼",
    label: "確認密碼",
    placeholder: "確認密碼"
  },
  managementInMyAccount: "在我的帳戶中管理",
  termsAndConditions: "條款和條件",
  signIn: "我已有帳號。登入",
  register: "註冊",
  confirmNewPassword: "確認新密碼",
  resetPassword: "重設密碼",
  createAccount: "建立帳戶",
  title: "稱謂",
  titleOptional: "稱謂 (選擇性)",
  firstName: {
    label: "名字",
    placeholder: "名字"
  },
  lastName: {
    label: "姓氏",
    placeholder: "姓氏"
  },
  emailAddress: {
    label: "電子郵件地址",
    placeholder: "電子郵件地址"
  },
  password: {
    label: "密碼",
    placeholder: "密碼"
  },
  newPassword: "新密碼",
  emailMarketing: "使用我的個人資料接收行銷活動的電子郵件電子報。若要更改設定，請移至我的帳戶中的「同意管理」。",
  confirmThatRead: "我確認我已閱讀並同意",
  selectTitle: "選擇稱謂",
  passwordMinRequirements: "此為安全密碼的快速指南：請使用至少 8 個字元，其中混合大小寫字母、數字和符號。",
  passwordStrengthRequirements: "此為安全密碼的快速指南：請使用至少 8 個字元，其中混合大小寫字母、數字和符號。請確保不要連續使用相同字元。",
  bothPasswordMustMatch: "兩者密碼必須相符",
  titleRequired: "稱謂為必要。",
  postRegisterMessage: "請使用提供的憑證登入。",
  postRegisterSuccessMessage: "註冊成功：請使用提供的憑證登入"
};
var forgottenPassword = {
  resetPassword: "重設密碼",
  enterEmailAddressAssociatedWithYourAccount: "輸入與您帳戶相關的電子郵件地址",
  emailAddress: {
    label: "電子郵件地址",
    placeholder: "輸入電子郵件"
  },
  enterValidEmail: "請輸入有效電子郵件。",
  passwordResetEmailSent: "電子郵件已傳送給你，其中包含如何重設密碼的資訊。",
  passwordResetSuccess: "成功！您現可使用新密碼登入。"
};
var userProfile = {
  updateEmailForm: updateEmailForm,
  register: register,
  forgottenPassword: forgottenPassword
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  address,
  myAccountV2Email: myAccountV2Email$1,
  myAccountV2Password,
  myAccountV2UserProfile: myAccountV2UserProfile$1,
  userProfile
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const userProfileTranslationChunksConfig = {
  address: ['addressForm', 'addressBook', 'addressCard', 'addressSuggestion', 'addressMessages'],
  myAccountV2Email: ['myAccountV2Email'],
  myAccountV2Password: ['myAccountV2PasswordForm'],
  myAccountV2UserProfile: ['myAccountV2UserProfile'],
  userProfile: ['updateEmailForm', 'register', 'forgottenPassword']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: userProfileTranslations
 *             +   resources: { en: userProfileTranslationsEn }
 *               }
 *             ```
 */
const userProfileTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { userProfileTranslationChunksConfig, userProfileTranslations, cs as userProfileTranslationsCs, de as userProfileTranslationsDe, en as userProfileTranslationsEn, es as userProfileTranslationsEs, es_CO as userProfileTranslationsEs_CO, fr as userProfileTranslationsFr, hi as userProfileTranslationsHi, hu as userProfileTranslationsHu, id as userProfileTranslationsId, it as userProfileTranslationsIt, ja as userProfileTranslationsJa, ko as userProfileTranslationsKo, pl as userProfileTranslationsPl, pt as userProfileTranslationsPt, ru as userProfileTranslationsRu, zh as userProfileTranslationsZh, zh_TW as userProfileTranslationsZh_TW };
