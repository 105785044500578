var checkoutB2B$w = {
  noPoNumber: "Nic",
  poNumber: "Číslo objednávky (volitelné)",
  costCenter: "Nákladové středisko",
  placeholder: "Zadejte č. objednávky",
  availableLabel: "Doručovací adresy, které máte k dispozici, závisejí na jednotce nákladového střediska",
  invalid: {
    accountType: "Váš účet neumožňuje, abyste zde provedli kontrolu a potvrzení."
  },
  methodOfPayment: {
    paymentType: "Typ platby"
  },
  review: {
    poNumber: "Číslo objednávky"
  },
  progress: {
    methodOfPayment: "Způsob platby"
  },
  invalidCostCenter: "Neplatné nákladové středisko."
};
var checkoutB2B$x = {
  checkoutB2B: checkoutB2B$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  checkoutB2B: checkoutB2B$x
};
var checkoutB2B$u = {
  noPoNumber: "Keine",
  poNumber: "Bestellnummer (optional)",
  costCenter: "Kostenstelle",
  placeholder: "Bestellung eingeben",
  availableLabel: "Welche Lieferadressen für Sie verfügbar sind, hängt von der Einheit der Kostenstelle ab.",
  invalid: {
    accountType: "Ihr Konto berechtigt Sie nicht dazu, hier zu bezahlen."
  },
  methodOfPayment: {
    paymentType: "Zahlungstyp"
  },
  review: {
    poNumber: "Bestellnummer"
  },
  progress: {
    methodOfPayment: "Art der Zahlung"
  },
  invalidCostCenter: "Ungültige Kostenstelle."
};
var checkoutB2B$v = {
  checkoutB2B: checkoutB2B$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  checkoutB2B: checkoutB2B$v
};
var checkoutB2B$s = {
  noPoNumber: "None",
  poNumber: "Purchase order number (optional)",
  costCenter: "Cost Center",
  placeholder: "Enter P.O",
  availableLabel: "Delivery addresses available to you depend on the cost center's unit",
  invalid: {
    accountType: "Your account does not allow you to checkout here."
  },
  methodOfPayment: {
    paymentType: "Payment Type"
  },
  review: {
    poNumber: "Purchase Order Number"
  },
  progress: {
    methodOfPayment: "Method of Payment"
  },
  invalidCostCenter: "Invalid cost center."
};
var checkoutB2B$t = {
  checkoutB2B: checkoutB2B$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  checkoutB2B: checkoutB2B$t
};
var checkoutB2B$q = {
  noPoNumber: "Ninguno",
  poNumber: "Número de pedido de compra (opcional)",
  costCenter: "Centro de costes",
  placeholder: "Introduzca pedido de compra",
  availableLabel: "Las direcciones de entrega disponibles dependen de la unidad del centro de costes",
  invalid: {
    accountType: "Su cuenta no le permite finalizar la compra aquí."
  },
  methodOfPayment: {
    paymentType: "Tipo de pago"
  },
  review: {
    poNumber: "Número de pedido de compra"
  },
  progress: {
    methodOfPayment: "Método de pago"
  },
  invalidCostCenter: "Centro de costes no válido."
};
var checkoutB2B$r = {
  checkoutB2B: checkoutB2B$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  checkoutB2B: checkoutB2B$r
};
var checkoutB2B$o = {
  noPoNumber: "Ninguno",
  poNumber: "Número de orden de compra (opcional)",
  costCenter: "Centro de costos",
  placeholder: "Ingrese orden de compra",
  availableLabel: "Las direcciones de entrega disponibles dependen de la unidad del centro de costos",
  invalid: {
    accountType: "Su cuenta no le permite finalizar la compra aquí."
  },
  methodOfPayment: {
    paymentType: "Tipo de pago"
  },
  review: {
    poNumber: "Número de pedido de compra"
  },
  progress: {
    methodOfPayment: "Método de pago"
  },
  invalidCostCenter: "Centro de costos no válido"
};
var checkoutB2B$p = {
  checkoutB2B: checkoutB2B$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  checkoutB2B: checkoutB2B$p
};
var checkoutB2B$m = {
  noPoNumber: "Aucun",
  poNumber: "Numéro de commande d'achat (facultatif)",
  costCenter: "Centre de coûts",
  placeholder: "Saisir la commande d'achat",
  availableLabel: "Les adresses de livraison disponibles dépendent de l'unité du centre de coûts.",
  invalid: {
    accountType: "Votre compte ne vous permet pas d'effectuer le règlement ici."
  },
  methodOfPayment: {
    paymentType: "Type de paiement"
  },
  review: {
    poNumber: "Numéro de commande de l'achat"
  },
  progress: {
    methodOfPayment: "Mode de paiement"
  },
  invalidCostCenter: "Centre de coûts non valide"
};
var checkoutB2B$n = {
  checkoutB2B: checkoutB2B$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  checkoutB2B: checkoutB2B$n
};
var checkoutB2B$k = {
  noPoNumber: "कोई नहीं",
  poNumber: "क्रय ऑर्डर संख्या (वैकल्पिक)",
  costCenter: "लागत केंद्र",
  placeholder: "P.O दर्ज करें",
  availableLabel: "लागत केंद्र की इकाई के आधार पर आपके लिए वितरण पता उपलब्ध होगा",
  invalid: {
    accountType: "आपका खाता आपको यहां चेकआउट करने की अनुमति नहीं देता है."
  },
  methodOfPayment: {
    paymentType: "भुगतान प्रकार"
  },
  review: {
    poNumber: "क्रय ऑर्डर संख्या"
  },
  progress: {
    methodOfPayment: "भुगतान की विधि"
  },
  invalidCostCenter: "अमान्य लागत केंद्र."
};
var checkoutB2B$l = {
  checkoutB2B: checkoutB2B$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  checkoutB2B: checkoutB2B$l
};
var checkoutB2B$i = {
  noPoNumber: "Nincs",
  poNumber: "Megrendelés száma (opcionális)",
  costCenter: "Költséghely",
  placeholder: "Megrendelés megadása",
  availableLabel: "A rendelkezésre álló kézbesítési címek a költséghely egységétől függnek",
  invalid: {
    accountType: "A fiókja nem teszi lehetővé a fizetést ezen a helyen."
  },
  methodOfPayment: {
    paymentType: "Fizetési mód"
  },
  review: {
    poNumber: "Megrendelés száma"
  },
  progress: {
    methodOfPayment: "Fizetési mód"
  },
  invalidCostCenter: "Érvénytelen költséghely."
};
var checkoutB2B$j = {
  checkoutB2B: checkoutB2B$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  checkoutB2B: checkoutB2B$j
};
var checkoutB2B$g = {
  noPoNumber: "Tidak Ada",
  poNumber: "Nomor pesanan pembelian (opsional)",
  costCenter: "Pusat Biaya",
  placeholder: "Masukkan Pesanan Pembelian (PO)",
  availableLabel: "Alamat pengiriman yang tersedia untuk Anda bergantung pada unit pusat biaya",
  invalid: {
    accountType: "Akun Anda tidak mengizinkan Anda untuk melakukan pembayaran di sini."
  },
  methodOfPayment: {
    paymentType: "Tipe Pembayaran"
  },
  review: {
    poNumber: "Nomor Pesanan Pembelian"
  },
  progress: {
    methodOfPayment: "Metode Pembayaran"
  },
  invalidCostCenter: "Pusat biaya tidak valid."
};
var checkoutB2B$h = {
  checkoutB2B: checkoutB2B$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  checkoutB2B: checkoutB2B$h
};
var checkoutB2B$e = {
  noPoNumber: "Nessuno",
  poNumber: "Numero ordine acquisto (facoltativo)",
  costCenter: "Centro di costo",
  placeholder: "Inserisci ordine d'acquisto",
  availableLabel: "Gli indirizzi di consegna disponibili dipendono dall'unità del centro di costo",
  invalid: {
    accountType: "L'account non consente di effettuare il check-out qui."
  },
  methodOfPayment: {
    paymentType: "Tipo di pagamento"
  },
  review: {
    poNumber: "Numero ordine d'acquisto"
  },
  progress: {
    methodOfPayment: "Metodo di pagamento"
  },
  invalidCostCenter: "Centro di costo non valido."
};
var checkoutB2B$f = {
  checkoutB2B: checkoutB2B$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  checkoutB2B: checkoutB2B$f
};
var checkoutB2B$c = {
  noPoNumber: "なし",
  poNumber: "発注書番号 (オプション)",
  costCenter: "原価センタ",
  placeholder: "発注書を入力",
  availableLabel: "使用可能な発注先住所は原価センタのユニットに依存します",
  invalid: {
    accountType: "このアカウントではここでチェックアウトできません。"
  },
  methodOfPayment: {
    paymentType: "支払タイプ"
  },
  review: {
    poNumber: "発注書番号"
  },
  progress: {
    methodOfPayment: "支払方法"
  },
  invalidCostCenter: "無効な原価センタです。"
};
var checkoutB2B$d = {
  checkoutB2B: checkoutB2B$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  checkoutB2B: checkoutB2B$d
};
var checkoutB2B$a = {
  noPoNumber: "없음",
  poNumber: "구매 주문 번호(선택사항)",
  costCenter: "코스트 센터",
  placeholder: "구매 주문 입력",
  availableLabel: "사용할 수 있는 배송지 주소는 코스트 센터 단위에 따라 달라집니다.",
  invalid: {
    accountType: "여기서 체크아웃할 수 없는 계정입니다."
  },
  methodOfPayment: {
    paymentType: "지불 유형"
  },
  review: {
    poNumber: "구매 주문 번호"
  },
  progress: {
    methodOfPayment: "지불 방법"
  },
  invalidCostCenter: "잘못된 코스트 센터입니다."
};
var checkoutB2B$b = {
  checkoutB2B: checkoutB2B$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  checkoutB2B: checkoutB2B$b
};
var checkoutB2B$8 = {
  noPoNumber: "Brak",
  poNumber: "Numer zamówienia (opcjonalny)",
  costCenter: "Miejsce powstawania kosztów",
  placeholder: "Wprowadź zamówienie",
  availableLabel: "Dostępne adresy dostawy zależą od jednostki miejsca powstawania kosztów",
  invalid: {
    accountType: "Twoje konto nie pozwala na dokonanie płatności w tym miejscu."
  },
  methodOfPayment: {
    paymentType: "Rodzaj płatności"
  },
  review: {
    poNumber: "Numer zamówienia"
  },
  progress: {
    methodOfPayment: "Metoda płatności"
  },
  invalidCostCenter: "Nieprawidłowe miejsce powstawania kosztów"
};
var checkoutB2B$9 = {
  checkoutB2B: checkoutB2B$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  checkoutB2B: checkoutB2B$9
};
var checkoutB2B$6 = {
  noPoNumber: "Nenhum",
  poNumber: "Número do pedido (opcional)",
  costCenter: "Centro de custo",
  placeholder: "Inserir pedido",
  availableLabel: "Os endereços de entrega disponíveis para você dependem da unidade do centro de custo",
  invalid: {
    accountType: "Sua conta não permite que você faça o check-out aqui."
  },
  methodOfPayment: {
    paymentType: "Tipo de pagamento"
  },
  review: {
    poNumber: "Número do pedido"
  },
  progress: {
    methodOfPayment: "Forma de pagamento"
  },
  invalidCostCenter: "Centro de custo inválido."
};
var checkoutB2B$7 = {
  checkoutB2B: checkoutB2B$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  checkoutB2B: checkoutB2B$7
};
var checkoutB2B$4 = {
  noPoNumber: "Нет",
  poNumber: "Номер заказа на покупку (необязательно)",
  costCenter: "МВЗ",
  placeholder: "Введите ЗП",
  availableLabel: "Доступность адресов доставки зависит от единицы МВЗ",
  invalid: {
    accountType: "Ваша учетная запись не позволяет перейти здесь к оформлению заказа."
  },
  methodOfPayment: {
    paymentType: "Тип оплаты"
  },
  review: {
    poNumber: "Номер заказа на покупку"
  },
  progress: {
    methodOfPayment: "Метод оплаты"
  },
  invalidCostCenter: "Недействительное МВЗ."
};
var checkoutB2B$5 = {
  checkoutB2B: checkoutB2B$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  checkoutB2B: checkoutB2B$5
};
var checkoutB2B$2 = {
  noPoNumber: "无",
  poNumber: "采购订单编号（可选）",
  costCenter: "成本中心",
  placeholder: "输入采购订单",
  availableLabel: "您可以使用的送货地址取决于成本中心的单位",
  invalid: {
    accountType: "您的账户无法在此结账。"
  },
  methodOfPayment: {
    paymentType: "付款类型"
  },
  review: {
    poNumber: "采购订单编号"
  },
  progress: {
    methodOfPayment: "付款方式"
  },
  invalidCostCenter: "成本中心无效。"
};
var checkoutB2B$3 = {
  checkoutB2B: checkoutB2B$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  checkoutB2B: checkoutB2B$3
};
var checkoutB2B = {
  noPoNumber: "無",
  poNumber: "採購單號碼 (選擇性)",
  costCenter: "成本中心",
  placeholder: "輸入採購單",
  availableLabel: "您可用的交貨地址取決於成本中心的單位",
  invalid: {
    accountType: "您的帳戶不允許您在此結帳。"
  },
  methodOfPayment: {
    paymentType: "付款類型"
  },
  review: {
    poNumber: "採購單號碼"
  },
  progress: {
    methodOfPayment: "付款方法"
  },
  invalidCostCenter: "成本中心無效。"
};
var checkoutB2B$1 = {
  checkoutB2B: checkoutB2B
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  checkoutB2B: checkoutB2B$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const checkoutB2BTranslationChunksConfig = {
  checkoutB2B: ['checkoutB2B']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: checkoutB2BTranslations
 *             +   resources: { en: checkoutB2BTranslationsEn }
 *               }
 *             ```
 */
const checkoutB2BTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { checkoutB2BTranslationChunksConfig, checkoutB2BTranslations, cs as checkoutB2BTranslationsCs, de as checkoutB2BTranslationsDe, en as checkoutB2BTranslationsEn, es as checkoutB2BTranslationsEs, es_CO as checkoutB2BTranslationsEs_CO, fr as checkoutB2BTranslationsFr, hi as checkoutB2BTranslationsHi, hu as checkoutB2BTranslationsHu, id as checkoutB2BTranslationsId, it as checkoutB2BTranslationsIt, ja as checkoutB2BTranslationsJa, ko as checkoutB2BTranslationsKo, pl as checkoutB2BTranslationsPl, pt as checkoutB2BTranslationsPt, ru as checkoutB2BTranslationsRu, zh as checkoutB2BTranslationsZh, zh_TW as checkoutB2BTranslationsZh_TW };
