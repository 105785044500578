var savedCartDetails$g = {
  cartName: "Název",
  cartDescription: "Popis",
  cartId: "ID",
  dateSaved: "Datum uložení",
  items: "Položky",
  quantity: "Množství",
  total: "Celkem",
  editSavedCart: "Upravit uložený košík",
  deleteSavedCart: "Odstranit uložený košík",
  addSelectedItems: "Přidat vybrané položky",
  emptyCartItems: "Vyjmout položky z košíku"
};
var savedCartList$g = {
  savedCarts: "Uložené košíky ({{count}})",
  cartName: "Název",
  cartId: "ID",
  dateSaved: "Datum uložení",
  cartDescription: "Popis",
  quantity: "Množství",
  quantityFull: "Množství",
  total: "Celkem",
  actions: "Akce",
  makeCartActive: "Aktivovat košík",
  notFound: "Nebyly nalezeny žádné uložené košíky",
  swapCartNoActiveCart: "Stávající  košík byl úspěšně aktivován uživatelem {{cartName}}.",
  swapCartWithActiveCart: "Stávající košík byl úspěšně aktivován uživatelem {{cartName}}. Vaše dřívější položky byly uloženy do košíku {{previousCartName}}."
};
var savedCartCartPage$g = {
  messages: {
    cartSaved: "Položky z vašeho košíku byly úspěšně uloženy na později do košíku „{{cartName}}“"
  }
};
var savedCartDialog$g = {
  saveForLater: "Uložit na později",
  itemsSavedForLater: "Všechny položky z vašeho košíku byly uloženy na později.",
  savedCartName: "Název uloženého košíku",
  savedCartDescription: "Popis uloženého košiku",
  optional: "volitelné",
  charactersLeft: "zbývá znaků: {{count}}",
  cancel: "Zrušit",
  save: "Uložit",
  restore: "Obnovit",
  followingCartDelete: "Následující uložený košík bude odstraněn",
  followingCartRestore: "Následující uložený košík bude obnoven jako aktivní košík",
  "delete": "Vymazat",
  deleteCartSuccess: "Košík byl úspěšně odstraněn",
  editCartSuccess: "Košík byl úspěšně upraven",
  editSavedCart: "Upravit uložený košík",
  deleteSavedCart: "Odstranit uložený košík",
  restoreSavedCart: "Obnovit uložený košík",
  name: "Název",
  id: "ID",
  description: "Popis",
  quantity: "Množství",
  quantityFull: "Množství",
  total: "Celkem",
  keepCopySavedCart: "Zachovat kopii tohoto košíku v seznamu uložených košíků",
  defaultCloneCartName: "Kopie {{name}}",
  nameOfCloneCart: "Název zkopírovaného košíku"
};
var addToSavedCart$g = {
  savedCarts: "Uložené košíky",
  saveCartForLater: "Uložit košík na později"
};
var savedCart$g = {
  savedCartDetails: savedCartDetails$g,
  savedCartList: savedCartList$g,
  savedCartCartPage: savedCartCartPage$g,
  savedCartDialog: savedCartDialog$g,
  addToSavedCart: addToSavedCart$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  savedCart: savedCart$g
};
var savedCartDetails$f = {
  cartName: "Name",
  cartDescription: "Beschreibung",
  cartId: "ID",
  dateSaved: "Speicherdatum",
  items: "Positionen",
  quantity: "Menge",
  total: "Gesamtsumme",
  editSavedCart: "Gespeicherten Warenkorb bearbeiten",
  deleteSavedCart: "Gespeicherten Warenkorb löschen",
  addSelectedItems: "Ausgewählte Positionen hinzufügen",
  emptyCartItems: "Warenkorbpositionen leeren"
};
var savedCartList$f = {
  savedCarts: "Gespeicherte Warenkörbe ({{count}})",
  cartName: "Name",
  cartId: "ID",
  dateSaved: "Speicherdatum",
  cartDescription: "Beschreibung",
  quantity: "Menge",
  quantityFull: "Menge",
  total: "Gesamtsumme",
  actions: "Aktionen",
  makeCartActive: "Warenkorb auf \"Aktiv\" setzen",
  notFound: "Keine gespeicherten Warenkörbe gefunden",
  swapCartNoActiveCart: "Vorhandener Warenkorb wurde durch {{cartName}} erfolgreich aktiviert.",
  swapCartWithActiveCart: "Vorhandener Warenkorb wurde durch {{cartName}} erfolgreich aktiviert. Ihre vorherigen Positionen wurden im Warenkorb {{previousCartName}} gespeichert."
};
var savedCartCartPage$f = {
  messages: {
    cartSaved: "Ihre Warenkorbpositionen wurden erfolgreich für später im Warenkorb \"{{cartName}}\" gespeichert"
  }
};
var savedCartDialog$f = {
  saveForLater: "Für später speichern",
  itemsSavedForLater: "Alle Positionen in Ihrem Warenkorb werden für später gespeichert",
  savedCartName: "Name des gespeicherten Warenkorbs",
  savedCartDescription: "Beschreibung des gespeicherten Warenkorbs",
  optional: "optional",
  charactersLeft: "Zeichen übrig: {{count}}",
  cancel: "Abbrechen",
  save: "Speichern",
  restore: "Wiederherstellen",
  followingCartDelete: "Der folgende gespeicherte Warenkorb wird gelöscht",
  followingCartRestore: "Der folgende gespeicherte Warenkorb wird als aktiver Warenkorb wiederhergestellt",
  "delete": "Löschen",
  deleteCartSuccess: "Warenkorb erfolgreich gelöscht",
  editCartSuccess: "Warenkorb erfolgreich bearbeitet",
  editSavedCart: "Gespeicherten Warenkorb bearbeiten",
  deleteSavedCart: "Gespeicherten Warenkorb löschen",
  restoreSavedCart: "Gespeicherten Warenkorb wiederherstellen",
  name: "Name",
  id: "ID",
  description: "Beschreibung",
  quantity: "MENGE",
  quantityFull: "Menge",
  total: "Gesamtsumme",
  keepCopySavedCart: "Kopie des Warenkorbs in der Liste der gespeicherten Warenkörbe beibehalten",
  defaultCloneCartName: "Kopie von {{name}}",
  nameOfCloneCart: "Name des kopierten Warenkorbs"
};
var addToSavedCart$f = {
  savedCarts: "Gespeicherte Warenkörbe",
  saveCartForLater: "Warenkorb für später speichern"
};
var savedCart$f = {
  savedCartDetails: savedCartDetails$f,
  savedCartList: savedCartList$f,
  savedCartCartPage: savedCartCartPage$f,
  savedCartDialog: savedCartDialog$f,
  addToSavedCart: addToSavedCart$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  savedCart: savedCart$f
};
var savedCartDetails$e = {
  cartName: "Name",
  cartDescription: "Description",
  cartId: "ID",
  dateSaved: "Date Saved",
  items: "Items",
  quantity: "Quantity",
  total: "Total",
  editSavedCart: "Edit Saved Cart",
  deleteSavedCart: "Delete Saved Cart",
  addSelectedItems: "Add selected items",
  emptyCartItems: "Empty Cart Items"
};
var savedCartList$e = {
  savedCarts: "Saved Carts ({{count}})",
  cartName: "Name",
  cartId: "Id",
  dateSaved: "Date Saved",
  cartDescription: "Description",
  quantity: "Qty",
  quantityFull: "Quantity",
  total: "Total",
  actions: "Actions",
  makeCartActive: "Make cart active",
  notFound: "No Saved Carts Found",
  swapCartNoActiveCart: "Existing cart is activated by {{cartName}} successfully.",
  swapCartWithActiveCart: "Existing cart is activated by {{cartName}} successfully. Your previous items were saved in a cart {{previousCartName}}."
};
var savedCartCartPage$e = {
  messages: {
    cartSaved: "Your cart items have been successfully saved for later in the \"{{cartName}}\" cart"
  }
};
var savedCartDialog$e = {
  saveForLater: "Save For Later",
  itemsSavedForLater: "All of the items in your cart will be saved for later",
  savedCartName: "Saved Cart Name",
  savedCartDescription: "Saved Cart Description",
  optional: "optional",
  charactersLeft: "characters left: {{count}}",
  cancel: "Cancel",
  save: "Save",
  restore: "Restore",
  followingCartDelete: "The following saved cart will be deleted",
  followingCartRestore: "The following saved cart will be restored as the active cart",
  "delete": "Delete",
  deleteCartSuccess: "Cart Deleted Successfully",
  editCartSuccess: "Cart Edited Successfully",
  editSavedCart: "Edit Saved Cart",
  deleteSavedCart: "Delete Saved Cart",
  restoreSavedCart: "Restore Saved Cart",
  name: "Name",
  id: "ID",
  description: "Description",
  quantity: "QTY",
  quantityFull: "Quantity",
  total: "Total",
  keepCopySavedCart: "Keep a copy of this cart in the saved carts list",
  defaultCloneCartName: "Copy of {{name}}",
  nameOfCloneCart: "Name of copied cart"
};
var addToSavedCart$e = {
  savedCarts: "Saved carts",
  saveCartForLater: "Save cart for later"
};
var savedCart$e = {
  savedCartDetails: savedCartDetails$e,
  savedCartList: savedCartList$e,
  savedCartCartPage: savedCartCartPage$e,
  savedCartDialog: savedCartDialog$e,
  addToSavedCart: addToSavedCart$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  savedCart: savedCart$e
};
var savedCartDetails$d = {
  cartName: "Nombre",
  cartDescription: "Descripción",
  cartId: "ID",
  dateSaved: "Fecha grabada",
  items: "Artículos",
  quantity: "Cantidad",
  total: "Total",
  editSavedCart: "Editar cesta grabada",
  deleteSavedCart: "Borrar cesta grabada",
  addSelectedItems: "Agregar elementos seleccionados",
  emptyCartItems: "Vaciar cesta"
};
var savedCartList$d = {
  savedCarts: "Cestas grabadas ({{count}})",
  cartName: "Nombre",
  cartId: "ID",
  dateSaved: "Fecha grabada",
  cartDescription: "Descripción",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  total: "Total",
  actions: "Acciones",
  makeCartActive: "Habilitar cesta",
  notFound: "No se encontró ninguna cesta guardada",
  swapCartNoActiveCart: "La cesta existente ha sido correctamente activada por {{cartName}}.",
  swapCartWithActiveCart: "La cesta existente ha sido correctamente activada por {{cartName}}. Los artículos anteriores han sido grabados en una cesta{{previousCartName}}."
};
var savedCartCartPage$d = {
  messages: {
    cartSaved: "Los artículos de la cesta han sido correctamente grabados en la cesta \"{{cartName}}\""
  }
};
var savedCartDialog$d = {
  saveForLater: "Grabar para después",
  itemsSavedForLater: "Todos los artículos de su cesta quedarán grabados para después",
  savedCartName: "Nombre de la cesta grabada",
  savedCartDescription: "Descripción de la cesta grabada",
  optional: "opcional",
  charactersLeft: "caracteres restantes: {{count}}",
  cancel: "Cancelar",
  save: "Grabar",
  restore: "Restablecer",
  followingCartDelete: "Se eliminará la siguiente cesta grabada",
  followingCartRestore: "La siguiente cesta grabada se restablecerá como una cesta activa",
  "delete": "Eliminar",
  deleteCartSuccess: "La cesta se ha eliminado correctamente",
  editCartSuccess: "La cesta se ha editado correctamente",
  editSavedCart: "Editar cesta grabada",
  deleteSavedCart: "Borrar cesta grabada",
  restoreSavedCart: "Restablecer cesta grabada",
  name: "Nombre",
  id: "ID",
  description: "Descripción",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  total: "Total",
  keepCopySavedCart: "Conservar una copia de esta cesta en la lista de cestas grabadas",
  defaultCloneCartName: "Copia de {{name}}",
  nameOfCloneCart: "Nombre de la cesta copiada"
};
var addToSavedCart$d = {
  savedCarts: "Cestas grabadas",
  saveCartForLater: "Grabar cesta para después"
};
var savedCart$d = {
  savedCartDetails: savedCartDetails$d,
  savedCartList: savedCartList$d,
  savedCartCartPage: savedCartCartPage$d,
  savedCartDialog: savedCartDialog$d,
  addToSavedCart: addToSavedCart$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  savedCart: savedCart$d
};
var savedCartDetails$c = {
  cartName: "Nombre",
  cartDescription: "Descripción",
  cartId: "ID",
  dateSaved: "Fecha guardada",
  items: "Artículos",
  quantity: "Cantidad",
  total: "Total",
  editSavedCart: "Editar carro guardado",
  deleteSavedCart: "Borrar carro guardado",
  addSelectedItems: "Agregar elementos seleccionados",
  emptyCartItems: "Vaciar carro"
};
var savedCartList$c = {
  savedCarts: "Carros guardados ({{count}})",
  cartName: "Nombre",
  cartId: "ID",
  dateSaved: "Fecha guardada",
  cartDescription: "Descripción",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  total: "Total",
  actions: "Acciones",
  makeCartActive: "Habilitar carro",
  notFound: "No se encontró ningún carro guardado",
  swapCartNoActiveCart: "El carro existente ha sido correctamente activado por {{cartName}}.",
  swapCartWithActiveCart: "El carro existente ha sido correctamente activado por {{cartName}}. Los artículos anteriores han sido guardados en un carro {{previousCartName}}."
};
var savedCartCartPage$c = {
  messages: {
    cartSaved: "Los artículos del carro han sido correctamente guardados en el carro \"{{cartName}}\""
  }
};
var savedCartDialog$c = {
  saveForLater: "Guardar para después",
  itemsSavedForLater: "Todos los artículos de su carro quedarán grabados para después",
  savedCartName: "Nombre del carro guardado",
  savedCartDescription: "Descripción del carro guardado",
  optional: "opcional",
  charactersLeft: "caracteres restantes: {{count}}",
  cancel: "Cancelar",
  save: "Guardar",
  restore: "Restablecer",
  followingCartDelete: "Se borrará el siguiente carro guardado",
  followingCartRestore: "El siguiente carro grabado se restablecerá como un carro activo",
  "delete": "Eliminar",
  deleteCartSuccess: "El carro se ha eliminado correctamente",
  editCartSuccess: "El carro se ha editado correctamente",
  editSavedCart: "Editar carro guardado",
  deleteSavedCart: "Borrar carro guardado",
  restoreSavedCart: "Restablecer carro guardado",
  name: "Nombre",
  id: "ID",
  description: "Descripción",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  total: "Total",
  keepCopySavedCart: "Conservar una copia de este carro en la lista de carros guardados",
  defaultCloneCartName: "Copia de {{name}}",
  nameOfCloneCart: "Nombre del carro copiado"
};
var addToSavedCart$c = {
  savedCarts: "Carros guardados",
  saveCartForLater: "Guardar carro para después"
};
var savedCart$c = {
  savedCartDetails: savedCartDetails$c,
  savedCartList: savedCartList$c,
  savedCartCartPage: savedCartCartPage$c,
  savedCartDialog: savedCartDialog$c,
  addToSavedCart: addToSavedCart$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  savedCart: savedCart$c
};
var savedCartDetails$b = {
  cartName: "Nom",
  cartDescription: "Description",
  cartId: "ID",
  dateSaved: "Date d'enregistrement",
  items: "Articles",
  quantity: "Quantité",
  total: "Total",
  editSavedCart: "Modifier le panier enregistré",
  deleteSavedCart: "Supprimer le panier enregistré",
  addSelectedItems: "Ajouter les articles sélectionnés",
  emptyCartItems: "Vider les articles du panier"
};
var savedCartList$b = {
  savedCarts: "Paniers enregistrés ({{count}})",
  cartName: "Nom",
  cartId: "ID",
  dateSaved: "Date d'enregistrement",
  cartDescription: "Description",
  quantity: "Qté",
  quantityFull: "Quantité",
  total: "Total",
  actions: "Actions",
  makeCartActive: "Rendre le panier actif",
  notFound: "Aucun panier enregistré trouvé",
  swapCartNoActiveCart: "Le panier existant a été activé avec succès par {{cartName}}.",
  swapCartWithActiveCart: "Le panier existant a été activé avec succès par {{cartName}}. Vos articles précédents ont été enregistrés dans un panier {{previousCartName}}."
};
var savedCartCartPage$b = {
  messages: {
    cartSaved: "Les articles de votre panier ont bien été enregistrés pour plus tard dans le panier \"{{cartName}}\"."
  }
};
var savedCartDialog$b = {
  saveForLater: "Enregistrer pour plus tard",
  itemsSavedForLater: "Tous les articles de votre panier seront enregistrés pour plus tard.",
  savedCartName: "Nom du panier enregistré",
  savedCartDescription: "Description du panier enregistré",
  optional: "facultatif",
  charactersLeft: "Caractères restants : {{count}}",
  cancel: "Annuler",
  save: "Enregistrer",
  restore: "Restaurer",
  followingCartDelete: "Le panier enregistré suivant sera supprimé.",
  followingCartRestore: "Le panier enregistré suivant sera restauré comme panier actif.",
  "delete": "Supprimer",
  deleteCartSuccess: "Panier supprimé avec succès",
  editCartSuccess: "Panier modifié avec succès",
  editSavedCart: "Modifier le panier enregistré",
  deleteSavedCart: "Supprimer le panier enregistré",
  restoreSavedCart: "Restaurer le panier enregistré",
  name: "Nom",
  id: "ID",
  description: "Description",
  quantity: "QTÉ",
  quantityFull: "Quantité",
  total: "Total",
  keepCopySavedCart: "Conserver une copie de ce panier dans la liste des paniers enregistrés",
  defaultCloneCartName: "Copie de {{name}}",
  nameOfCloneCart: "Nom du panier copié"
};
var addToSavedCart$b = {
  savedCarts: "Paniers enregistrés",
  saveCartForLater: "Enregistrer le panier pour plus tard"
};
var savedCart$b = {
  savedCartDetails: savedCartDetails$b,
  savedCartList: savedCartList$b,
  savedCartCartPage: savedCartCartPage$b,
  savedCartDialog: savedCartDialog$b,
  addToSavedCart: addToSavedCart$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  savedCart: savedCart$b
};
var savedCartDetails$a = {
  cartName: "नाम",
  cartDescription: "वर्णन",
  cartId: "ID",
  dateSaved: "दिनांक सहेजा गया",
  items: "आइटम",
  quantity: "मात्रा",
  total: "कुल",
  editSavedCart: "सहेजा गया कार्ट संपादित करें",
  deleteSavedCart: "सहेजा गया कार्ट हटाएं",
  addSelectedItems: "चयनित आइटम जोड़ें",
  emptyCartItems: "रिक्त कार्ट आइटम"
};
var savedCartList$a = {
  savedCarts: "सहेजे गए कार्ट ({{count}})",
  cartName: "नाम",
  cartId: "Id",
  dateSaved: "दिनांक सहेजा गया",
  cartDescription: "वर्णन",
  quantity: "मात्रा",
  quantityFull: "मात्रा",
  total: "कुल",
  actions: "क्रियाएं",
  makeCartActive: "कार्ट सक्रिय करें",
  notFound: "सहेजा गया कोई कार्ट नहीं मिला",
  swapCartNoActiveCart: "मौजूदा कार्ट को {{cartName}} द्वारा सफलतापूर्वक सक्रिय किया गया.",
  swapCartWithActiveCart: "मौजूदा कार्ट को {{cartName}} द्वारा सफलतापूर्वक सक्रिय किया गया. आपके पिछले आइटम कार्ट {{previousCartName}} में सहेजे गए."
};
var savedCartCartPage$a = {
  messages: {
    cartSaved: "आपके कार्ट आइटम बाद के लिए कार्ट  \"{{cartName}}\" में सफलतापूर्वक सहेजे गए"
  }
};
var savedCartDialog$a = {
  saveForLater: "बाद के लिए सहेजें",
  itemsSavedForLater: "आपकी कार्ट के सभी आइटम बाद के लिए सहेजे जाएंगे",
  savedCartName: "सहेजी गई कार्ट का नाम",
  savedCartDescription: "सहेजी गई कार्ट का वर्णन",
  optional: "वैकल्पिक",
  charactersLeft: "वर्ण बचे हैंः  {{count}}",
  cancel: "रद्द करें",
  save: "सहेजें",
  restore: "पुनर्स्थापित करें",
  followingCartDelete: "निम्नलिखित सहेजे गए कार्ट हटा दिए जाएंगे",
  followingCartRestore: "निम्नलिखित सहेजे गए कार्ट सक्रिय कार्ट के रूप में पुनर्स्थापित किए जाएंगे",
  "delete": "हटाएं",
  deleteCartSuccess: "कार्ट सफलतापूर्वक हटाए गए",
  editCartSuccess: "कार्ट सफलतापूर्वक संपादित किया गया",
  editSavedCart: "सहेजा गया कार्ट संपादित करें",
  deleteSavedCart: "सहेजा गया कार्ट हटाएं",
  restoreSavedCart: "सहेजे गए कार्ट को पुनर्स्थापित करें",
  name: "नाम",
  id: "ID",
  description: "वर्णन",
  quantity: "मात्रा",
  quantityFull: "मात्रा",
  total: "कुल",
  keepCopySavedCart: "इस कार्ट की एक प्रति सहेजी गई कार्ट सूची में रखें",
  defaultCloneCartName: "{{name}} की प्रति",
  nameOfCloneCart: "प्रति की गई कार्ट का नाम"
};
var addToSavedCart$a = {
  savedCarts: "सहेजे गए कार्ट",
  saveCartForLater: "कार्ट बाद के लिए सहेजें"
};
var savedCart$a = {
  savedCartDetails: savedCartDetails$a,
  savedCartList: savedCartList$a,
  savedCartCartPage: savedCartCartPage$a,
  savedCartDialog: savedCartDialog$a,
  addToSavedCart: addToSavedCart$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  savedCart: savedCart$a
};
var savedCartDetails$9 = {
  cartName: "Név",
  cartDescription: "Leírás",
  cartId: "Azonosító",
  dateSaved: "Mentés dátuma",
  items: "Tételek",
  quantity: "Mennyiség",
  total: "Összesen",
  editSavedCart: "Mentett kosár szerkesztése",
  deleteSavedCart: "Mentett kosár törlése",
  addSelectedItems: "Kiválasztott tételek hozzáadása",
  emptyCartItems: "Kosár tételeinek kiürítése"
};
var savedCartList$9 = {
  savedCarts: "Mentett kosarak ({{count}})",
  cartName: "Név",
  cartId: "Azonosító",
  dateSaved: "Mentés dátuma",
  cartDescription: "Leírás",
  quantity: "Menny.",
  quantityFull: "Mennyiség",
  total: "Összesen",
  actions: "Műveletek",
  makeCartActive: "Kosár aktiválása",
  notFound: "Nem találhatók mentett kosarak",
  swapCartNoActiveCart: "A meglévő kosár aktiválása sikeres {{cartName}} által.",
  swapCartWithActiveCart: "A meglévő kosár aktiválása sikeres {{cartName}} által. A rendszer a korábbi tételeit egy {{previousCartName}} kosárban mentette."
};
var savedCartCartPage$9 = {
  messages: {
    cartSaved: "A kosár tételei sikeresen mentve későbbi felhasználásra a(z) „{{cartName}}” kosárban"
  }
};
var savedCartDialog$9 = {
  saveForLater: "Mentés későbbre",
  itemsSavedForLater: "A kosarában található összes tétel mentve lesz későbbre",
  savedCartName: "Mentett kosár neve",
  savedCartDescription: "Mentett kosár leírása",
  optional: "opcionális",
  charactersLeft: "hátralévő karakterek: {{count}}",
  cancel: "Mégse",
  save: "Mentés",
  restore: "Visszaállítás",
  followingCartDelete: "A következő mentett kosár törölve lesz",
  followingCartRestore: "A következő mentett kosár aktív kosárként lesz visszaállítva",
  "delete": "Törlés",
  deleteCartSuccess: "Kosár sikeresen törölve",
  editCartSuccess: "Kosár sikeresen szerkesztve",
  editSavedCart: "Mentett kosár szerkesztése",
  deleteSavedCart: "Mentett kosár törlése",
  restoreSavedCart: "Mentett kosár visszaállítása",
  name: "Név",
  id: "Azonosító",
  description: "Leírás",
  quantity: "MENNY.",
  quantityFull: "Mennyiség",
  total: "Összesen",
  keepCopySavedCart: "Tartson meg egy másolatot a kosárról a mentett kosarak listáján",
  defaultCloneCartName: "{{name}} másolata",
  nameOfCloneCart: "A másolt kosár neve"
};
var addToSavedCart$9 = {
  savedCarts: "Mentett kosarak",
  saveCartForLater: "Kosár mentése későbbre"
};
var savedCart$9 = {
  savedCartDetails: savedCartDetails$9,
  savedCartList: savedCartList$9,
  savedCartCartPage: savedCartCartPage$9,
  savedCartDialog: savedCartDialog$9,
  addToSavedCart: addToSavedCart$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  savedCart: savedCart$9
};
var savedCartDetails$8 = {
  cartName: "Nama",
  cartDescription: "Deskripsi",
  cartId: "ID",
  dateSaved: "Tanggal Tersimpan",
  items: "Item",
  quantity: "Jumlah",
  total: "Total",
  editSavedCart: "Edit Keranjang Tersimpan",
  deleteSavedCart: "Hapus Keranjang Tersimpan",
  addSelectedItems: "Tambahkan item yang dipilih",
  emptyCartItems: "Kosongkan Item dalam Keranjang"
};
var savedCartList$8 = {
  savedCarts: "Keranjang Tersimpan ({{count}})",
  cartName: "Nama",
  cartId: "Id",
  dateSaved: "Tanggal Tersimpan",
  cartDescription: "Deskripsi",
  quantity: "Jml",
  quantityFull: "Jumlah",
  total: "Total",
  actions: "Tindakan",
  makeCartActive: "Aktifkan keranjang",
  notFound: "Tidak Ditemukan Keranjang Tersimpan",
  swapCartNoActiveCart: "Keranjang yang ada berhasil diaktifkan oleh {{cartName}}.",
  swapCartWithActiveCart: "Keranjang yang ada berhasil diaktifkan oleh {{cartName}}. Item Anda sebelumnya telah disimpan dalam keranjang {{previousCartName}}."
};
var savedCartCartPage$8 = {
  messages: {
    cartSaved: "Item dalam keranjang Anda berhasil disimpan untuk nanti dalam keranjang \"{{cartName}}\""
  }
};
var savedCartDialog$8 = {
  saveForLater: "Simpan Untuk Nanti",
  itemsSavedForLater: "Semua item dalam keranjang Anda akan disimpan untuk nanti",
  savedCartName: "Nama Keranjang Tersimpan",
  savedCartDescription: "Deskripsi Keranjang Tersimpan",
  optional: "opsional",
  charactersLeft: "karakter tersisa: {{count}}",
  cancel: "Batalkan",
  save: "Simpan",
  restore: "Pulihkan",
  followingCartDelete: "Keranjang tersimpan berikut akan dihapus",
  followingCartRestore: "Keranjang tersimpan berikut akan dipulihkan sebagai keranjang aktif",
  "delete": "Hapus",
  deleteCartSuccess: "Keranjang Berhasil Dihapus",
  editCartSuccess: "Keranjang Berhasil Diedit",
  editSavedCart: "Edit Keranjang Tersimpan",
  deleteSavedCart: "Hapus Keranjang Tersimpan",
  restoreSavedCart: "Pulihkan Keranjang Tersimpan",
  name: "Nama",
  id: "ID",
  description: "Deskripsi",
  quantity: "JML",
  quantityFull: "Jumlah",
  total: "Total",
  keepCopySavedCart: "Simpan salinan keranjang ini dalam daftar keranjang tersimpan",
  defaultCloneCartName: "Salinan dari {{name}}",
  nameOfCloneCart: "Nama keranjang yang disalin"
};
var addToSavedCart$8 = {
  savedCarts: "Keranjang tersimpan",
  saveCartForLater: "Simpan keranjang untuk nanti"
};
var savedCart$8 = {
  savedCartDetails: savedCartDetails$8,
  savedCartList: savedCartList$8,
  savedCartCartPage: savedCartCartPage$8,
  savedCartDialog: savedCartDialog$8,
  addToSavedCart: addToSavedCart$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  savedCart: savedCart$8
};
var savedCartDetails$7 = {
  cartName: "Nome",
  cartDescription: "Descrizione",
  cartId: "ID",
  dateSaved: "Data salvata",
  items: "Articoli",
  quantity: "Quantità",
  total: "Totale",
  editSavedCart: "Modifica carrello acquisti salvato",
  deleteSavedCart: "Elimina carrello acquisti salvato",
  addSelectedItems: "Aggiungi gli articoli selezionati",
  emptyCartItems: "Svuota articoli del carrello acquisti"
};
var savedCartList$7 = {
  savedCarts: "Carrelli acquisti salvati ({{count}})",
  cartName: "Nome",
  cartId: "ID",
  dateSaved: "Data salvata",
  cartDescription: "Descrizione",
  quantity: "Qtà",
  quantityFull: "Quantità",
  total: "Totale",
  actions: "Azioni",
  makeCartActive: "Rendi attivo il carrello acquisti",
  notFound: "Nessun carrello acquisti salvato trovato",
  swapCartNoActiveCart: "Carrello acquisti esistente attivato correttamente da {{cartName}}.",
  swapCartWithActiveCart: "Carrello acquisti esistente attivato correttamente da {{cartName}}. Gli articoli precedenti sono stati salvati nel carrello {{previousCartName}}."
};
var savedCartCartPage$7 = {
  messages: {
    cartSaved: "Gli articoli del carrello acquisti sono stati salvati correttamente per un uso successivo nel carrello acquisti \"{{cartName}}\""
  }
};
var savedCartDialog$7 = {
  saveForLater: "Salva per uso successivo",
  itemsSavedForLater: "Tutti gli articoli nel carrello acquisti verranno salvati per un uso successivo",
  savedCartName: "Nome carrello acquisti salvato",
  savedCartDescription: "Descrizione carrello acquisti salvato",
  optional: "facoltativa",
  charactersLeft: "caratteri rimanenti: {{count}}",
  cancel: "Annulla",
  save: "Salva",
  restore: "Ripristina",
  followingCartDelete: "Il seguente carrello acquisti salvato verrà eliminato",
  followingCartRestore: "Il seguente carrello acquisti salvato verrà ripristinato come carrello acquisti attivo",
  "delete": "Elimina",
  deleteCartSuccess: "Carrello acquisti eliminato correttamente",
  editCartSuccess: "Carrello acquisti modificato correttamente",
  editSavedCart: "Modifica carrello acquisti salvato",
  deleteSavedCart: "Elimina carrello acquisti salvato",
  restoreSavedCart: "Ripristina carrello acquisti salvato",
  name: "Nome",
  id: "ID",
  description: "Descrizione",
  quantity: "QTÀ",
  quantityFull: "Quantità",
  total: "Totale",
  keepCopySavedCart: "Conserva una copia di questo carrello acquisti nella lista dei carrelli acquisti salvati",
  defaultCloneCartName: "Copia di {{name}}",
  nameOfCloneCart: "Nome del carrello acquisti copiato"
};
var addToSavedCart$7 = {
  savedCarts: "Carrelli acquisti salvati",
  saveCartForLater: "Salva carrello acquisti per uso successivo"
};
var savedCart$7 = {
  savedCartDetails: savedCartDetails$7,
  savedCartList: savedCartList$7,
  savedCartCartPage: savedCartCartPage$7,
  savedCartDialog: savedCartDialog$7,
  addToSavedCart: addToSavedCart$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  savedCart: savedCart$7
};
var savedCartDetails$6 = {
  cartName: "名前",
  cartDescription: "説明",
  cartId: "ID",
  dateSaved: "保存された日付",
  items: "アイテム",
  quantity: "数量",
  total: "合計",
  editSavedCart: "保存されたカートを編集",
  deleteSavedCart: "保存されたカートを削除",
  addSelectedItems: "選択したアイテムを追加",
  emptyCartItems: "カートのアイテムを空にする"
};
var savedCartList$6 = {
  savedCarts: "保存されたカート ({{count}})",
  cartName: "名前",
  cartId: "ID",
  dateSaved: "保存された日付",
  cartDescription: "説明",
  quantity: "数量",
  quantityFull: "数量",
  total: "合計",
  actions: "アクション",
  makeCartActive: "カートの有効化",
  notFound: "保存されたカートがありません",
  swapCartNoActiveCart: "既存のカートは {{cartName}} によって正常に有効化されました。",
  swapCartWithActiveCart: "既存のカートは {{cartName}} によって正常に有効化されました。以前のアイテムはカート {{previousCartName}} に保存されたいます。"
};
var savedCartCartPage$6 = {
  messages: {
    cartSaved: "カートアイテムは後で使用するためにカート \"{{cartName}}\" に正常に保存されました。"
  }
};
var savedCartDialog$6 = {
  saveForLater: "保存して後で使用",
  itemsSavedForLater: "カート内のすべてのアイテムは後で使用するために保存されます",
  savedCartName: "保存されたカート名",
  savedCartDescription: "保存されたカートの説明",
  optional: "任意",
  charactersLeft: "残り文字数: {{count}}",
  cancel: "キャンセル",
  save: "保存",
  restore: "復元",
  followingCartDelete: "以下の保存されたカートは削除されます",
  followingCartRestore: "以下の保存されたカートは有効なカートとして復元されます",
  "delete": "削除",
  deleteCartSuccess: "カートが正常に削除されました",
  editCartSuccess: "カートが正常に編集されました",
  editSavedCart: "保存されたカートを編集",
  deleteSavedCart: "保存されたカートを削除",
  restoreSavedCart: "保存されたカートを復元",
  name: "名前",
  id: "ID",
  description: "説明",
  quantity: "数量",
  quantityFull: "数量",
  total: "合計",
  keepCopySavedCart: "このカートのコピーを保存されたカートの一覧に保持",
  defaultCloneCartName: "{{name}} のコピー",
  nameOfCloneCart: "コピーされたカートの名前"
};
var addToSavedCart$6 = {
  savedCarts: "保存されたカート",
  saveCartForLater: "後で使用するためにカートを保存"
};
var savedCart$6 = {
  savedCartDetails: savedCartDetails$6,
  savedCartList: savedCartList$6,
  savedCartCartPage: savedCartCartPage$6,
  savedCartDialog: savedCartDialog$6,
  addToSavedCart: addToSavedCart$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  savedCart: savedCart$6
};
var savedCartDetails$5 = {
  cartName: "이름",
  cartDescription: "설명",
  cartId: "ID",
  dateSaved: "저장 날짜",
  items: "품목",
  quantity: "수량",
  total: "합계",
  editSavedCart: "저장된 장바구니 편집",
  deleteSavedCart: "저장된 장바구니 삭제",
  addSelectedItems: "선택된 품목 추가",
  emptyCartItems: "장바구니 품목 비우기"
};
var savedCartList$5 = {
  savedCarts: "저장된 장바구니({{count}})",
  cartName: "이름",
  cartId: "ID",
  dateSaved: "저장 날짜",
  cartDescription: "설명",
  quantity: "수량",
  quantityFull: "수량",
  total: "합계",
  actions: "작업",
  makeCartActive: "장바구니를 활성으로 설정",
  notFound: "저장된 장바구니가 없습니다.",
  swapCartNoActiveCart: "이전 장바구니가 {{cartName}}에 의해 활성화되었습니다.",
  swapCartWithActiveCart: "이전 장바구니가 {{cartName}}에 의해 활성화되었습니다. 이전 품목이 장바구니 {{previousCartName}}에 저장되었습니다."
};
var savedCartCartPage$5 = {
  messages: {
    cartSaved: "장바구니 품목을 나중에 사용하기 위해 \"{{cartName}}\" 장바구니에 저장했습니다."
  }
};
var savedCartDialog$5 = {
  saveForLater: "저장 후 나중에 사용",
  itemsSavedForLater: "장바구니의 모든 품목을 나중에 사용하기 위해 저장합니다.",
  savedCartName: "저장된 장바구니 이름",
  savedCartDescription: "저장된 장바구니 설명",
  optional: "옵션",
  charactersLeft: "{{count}}자 남음",
  cancel: "취소",
  save: "저장",
  restore: "복원",
  followingCartDelete: "다음 저장된 장바구니가 삭제됩니다.",
  followingCartRestore: "다음 저장된 장바구니가 활성 장바구니로 복원됩니다.",
  "delete": "삭제",
  deleteCartSuccess: "장바구니가 삭제되었습니다.",
  editCartSuccess: "장바구니가 편집되었습니다.",
  editSavedCart: "저장된 장바구니 편집",
  deleteSavedCart: "저장된 장바구니 삭제",
  restoreSavedCart: "저장된 장바구니 복원",
  name: "이름",
  id: "ID",
  description: "설명",
  quantity: "수량",
  quantityFull: "수량",
  total: "합계",
  keepCopySavedCart: "이 장바구니의 복사본을 저장된 장바구니 목록에 보관",
  defaultCloneCartName: "{{name}}의 복사본",
  nameOfCloneCart: "복사된 장바구니 이름"
};
var addToSavedCart$5 = {
  savedCarts: "저장된 장바구니",
  saveCartForLater: "장바구니를 저장 후 나중에 사용"
};
var savedCart$5 = {
  savedCartDetails: savedCartDetails$5,
  savedCartList: savedCartList$5,
  savedCartCartPage: savedCartCartPage$5,
  savedCartDialog: savedCartDialog$5,
  addToSavedCart: addToSavedCart$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  savedCart: savedCart$5
};
var savedCartDetails$4 = {
  cartName: "Nazwa",
  cartDescription: "Opis",
  cartId: "ID",
  dateSaved: "Data zapisana",
  items: "Pozycje",
  quantity: "Ilość",
  total: "Suma",
  editSavedCart: "Edytuj zapisany koszyk",
  deleteSavedCart: "Usuń zapisany koszyk",
  addSelectedItems: "Dodaj wybrane pozycje",
  emptyCartItems: "Puste pozycje koszyka"
};
var savedCartList$4 = {
  savedCarts: "Zapisane koszyki ({{count}})",
  cartName: "Nazwa",
  cartId: "ID",
  dateSaved: "Data zapisana",
  cartDescription: "Opis",
  quantity: "Ilość",
  quantityFull: "Ilość",
  total: "Suma",
  actions: "Czynności",
  makeCartActive: "Aktywuj koszyk",
  notFound: "Nie znaleziono zapisanych koszyków",
  swapCartNoActiveCart: "Istniejący koszyk został aktywowany przez {{cartName}}.",
  swapCartWithActiveCart: "Istniejący koszyk został aktywowany przez {{cartName}}. Twoje poprzednie pozycje zostały zapisane w koszyku {{previousCartName}}."
};
var savedCartCartPage$4 = {
  messages: {
    cartSaved: "Twoja pozycja koszyka została zapisana na później w koszyku \"{{cartName}}\""
  }
};
var savedCartDialog$4 = {
  saveForLater: "Zapisz na później",
  itemsSavedForLater: "Wszystkie produkty w koszyku zostaną zapisane do późniejszego wykorzystania",
  savedCartName: "Zapisana nazwa koszyka",
  savedCartDescription: "Zapisany opis koszyka",
  optional: "opcjonalnie",
  charactersLeft: "pozostało znaków: {{count}}",
  cancel: "Anuluj",
  save: "Zapisz",
  restore: "Przywróć",
  followingCartDelete: "Następujący zapisany koszyk zostanie usunięty",
  followingCartRestore: "Następujący zapisany koszyk zostanie przywrócony jako aktywny koszyk",
  "delete": "Usuń",
  deleteCartSuccess: "Koszyk został usunięty",
  editCartSuccess: "Koszyk został edytowany",
  editSavedCart: "Edytuj zapisany koszyk",
  deleteSavedCart: "Usuń zapisany koszyk",
  restoreSavedCart: "Przywróć zapisany koszyk",
  name: "Nazwa",
  id: "ID",
  description: "Opis",
  quantity: "ILOŚĆ",
  quantityFull: "Ilość",
  total: "Suma",
  keepCopySavedCart: "Zachowaj kopię tego koszyka na zapisanej liście koszyków",
  defaultCloneCartName: "Kopia {{name}}",
  nameOfCloneCart: "Nazwa skopiowanego koszyka"
};
var addToSavedCart$4 = {
  savedCarts: "Zapisane koszyki",
  saveCartForLater: "Zapisz koszyk na później"
};
var savedCart$4 = {
  savedCartDetails: savedCartDetails$4,
  savedCartList: savedCartList$4,
  savedCartCartPage: savedCartCartPage$4,
  savedCartDialog: savedCartDialog$4,
  addToSavedCart: addToSavedCart$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  savedCart: savedCart$4
};
var savedCartDetails$3 = {
  cartName: "Nome",
  cartDescription: "Descrição",
  cartId: "ID",
  dateSaved: "Data em que foi gravado",
  items: "Itens",
  quantity: "Quantidade",
  total: "Total",
  editSavedCart: "Editar carrinho gravado",
  deleteSavedCart: "Eliminar carrinho gravado",
  addSelectedItems: "Adicionar itens selecionados",
  emptyCartItems: "Itens do carrinho vazio"
};
var savedCartList$3 = {
  savedCarts: "Carrinhos gravados ({{count}})",
  cartName: "Nome",
  cartId: "ID",
  dateSaved: "Data em que foi gravado",
  cartDescription: "Descrição",
  quantity: "Qtd",
  quantityFull: "Quantidade",
  total: "Total",
  actions: "Ações",
  makeCartActive: "Tornar carrinho ativo",
  notFound: "Nenhum carrinho gravado encontrado",
  swapCartNoActiveCart: "O carrinho existente é ativado por {{cartName}} com êxito.",
  swapCartWithActiveCart: "O carrinho existente é ativado por {{cartName}} com êxito. Seus itens anteriores foram gravados em um carrinho {{previousCartName}}."
};
var savedCartCartPage$3 = {
  messages: {
    cartSaved: "Os itens do seu carrinho foram gravados para mais tarde com êxito no carrinho \"{{cartName}}\""
  }
};
var savedCartDialog$3 = {
  saveForLater: "Gravar para mais tarde",
  itemsSavedForLater: "Todos os itens em seu carrinho serão gravados para mais tarde",
  savedCartName: "Nome do carrinho gravado",
  savedCartDescription: "Descrição do carrinho gravado",
  optional: "opcional",
  charactersLeft: "caracteres restantes: {{count}}",
  cancel: "Cancelar",
  save: "Gravar",
  restore: "Restaurar",
  followingCartDelete: "O seguinte carrinho gravado será eliminado",
  followingCartRestore: "O seguinte carrinho gravado será restaurado como carrinho ativo",
  "delete": "Eliminar",
  deleteCartSuccess: "Carrinho eliminado com êxito",
  editCartSuccess: "Carrinho editado com êxito",
  editSavedCart: "Editar carrinho gravado",
  deleteSavedCart: "Eliminar carrinho gravado",
  restoreSavedCart: "Restaurar carrinho gravado",
  name: "Nome",
  id: "ID",
  description: "Descrição",
  quantity: "QTD",
  quantityFull: "Quantidade",
  total: "Total",
  keepCopySavedCart: "Manter uma cópia deste carrinho na lista de carrinhos gravados",
  defaultCloneCartName: "Cópia de {{name}}",
  nameOfCloneCart: "Nome do carrinho copiado"
};
var addToSavedCart$3 = {
  savedCarts: "Carrinhos gravados",
  saveCartForLater: "Gravar carrinho para mais tarde"
};
var savedCart$3 = {
  savedCartDetails: savedCartDetails$3,
  savedCartList: savedCartList$3,
  savedCartCartPage: savedCartCartPage$3,
  savedCartDialog: savedCartDialog$3,
  addToSavedCart: addToSavedCart$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  savedCart: savedCart$3
};
var savedCartDetails$2 = {
  cartName: "Имя",
  cartDescription: "Описание",
  cartId: "Ид.",
  dateSaved: "Дата сохранения",
  items: "Позиции",
  quantity: "Количество",
  total: "Итого",
  editSavedCart: "Редактировать сохраненную корзину",
  deleteSavedCart: "Удалить сохраненную корзину",
  addSelectedItems: "Добавить выбранные позиции",
  emptyCartItems: "Удалить позиции корзины"
};
var savedCartList$2 = {
  savedCarts: "Сохраненные корзины ({{count}})",
  cartName: "Имя",
  cartId: "Ид.",
  dateSaved: "Дата сохранения",
  cartDescription: "Описание",
  quantity: "Кол.",
  quantityFull: "Количество",
  total: "Итого",
  actions: "Действия",
  makeCartActive: "Сделать корзину активной",
  notFound: "Сохраненные корзины не найдены",
  swapCartNoActiveCart: "Существующую корзину успешно активировал {{cartName}}.",
  swapCartWithActiveCart: "Существующую корзину успешно активировал {{cartName}}. Ваши прежние позиции сохранены в корзине {{previousCartName}}."
};
var savedCartCartPage$2 = {
  messages: {
    cartSaved: "Позиции вашей корзины успешно сохранены на будущее в корзине \"{{cartName}}\""
  }
};
var savedCartDialog$2 = {
  saveForLater: "Сохранить на будущее",
  itemsSavedForLater: "Все позиции в вашей корзине будут сохранены на будущее",
  savedCartName: "Имя сохраненной корзины",
  savedCartDescription: "Описание сохраненной корзины",
  optional: "необязательно",
  charactersLeft: "осталось символов: {{count}}",
  cancel: "Отменить",
  save: "Сохранить",
  restore: "Восстановить",
  followingCartDelete: "Следующая сохраненная корзина будет удалена",
  followingCartRestore: "Следующая сохраненная корзина будет восстановлена как активная",
  "delete": "Удалить",
  deleteCartSuccess: "Корзина успешно удалена",
  editCartSuccess: "Корзина успешно отредактирвана",
  editSavedCart: "Редактировать сохраненную корзину",
  deleteSavedCart: "Удалить сохраненную корзину",
  restoreSavedCart: "Восстановить сохраненную корзину",
  name: "Имя",
  id: "Ид.",
  description: "Описание",
  quantity: "Кол.",
  quantityFull: "Количество",
  total: "Итого",
  keepCopySavedCart: "Сохраните копию этой корзины в списке сохраненных корзин ",
  defaultCloneCartName: "Копия {{name}}",
  nameOfCloneCart: "Имя скопированной корзины"
};
var addToSavedCart$2 = {
  savedCarts: "Сохраненные корзины",
  saveCartForLater: "Сохранить корзину на будущее"
};
var savedCart$2 = {
  savedCartDetails: savedCartDetails$2,
  savedCartList: savedCartList$2,
  savedCartCartPage: savedCartCartPage$2,
  savedCartDialog: savedCartDialog$2,
  addToSavedCart: addToSavedCart$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  savedCart: savedCart$2
};
var savedCartDetails$1 = {
  cartName: "名称",
  cartDescription: "描述",
  cartId: "标识",
  dateSaved: "保存的日期",
  items: "项目",
  quantity: "数量",
  total: "总计",
  editSavedCart: "编辑已保存购物车",
  deleteSavedCart: "删除已保存购物车",
  addSelectedItems: "添加所选项目",
  emptyCartItems: "清空购物车项目"
};
var savedCartList$1 = {
  savedCarts: "已保存购物车 ({{count}})",
  cartName: "名称",
  cartId: "标识",
  dateSaved: "保存的日期",
  cartDescription: "描述",
  quantity: "数量",
  quantityFull: "数量",
  total: "总计",
  actions: "操作",
  makeCartActive: "激活购物车",
  notFound: "未找到保存的购物车",
  swapCartNoActiveCart: "现有购物车已由 {{cartName}} 成功激活。",
  swapCartWithActiveCart: "现有购物车已由 {{cartName}} 成功激活。您之前的项目已在购物车 {{previousCartName}} 中保存。"
};
var savedCartCartPage$1 = {
  messages: {
    cartSaved: "您的购物车项目已成功保存，稍后可放入“{{cartName}}”购物车"
  }
};
var savedCartDialog$1 = {
  saveForLater: "保存备用",
  itemsSavedForLater: "购物车中的所有项目将保存备用",
  savedCartName: "已保存购物车名称",
  savedCartDescription: "已保存购物车描述",
  optional: "可选",
  charactersLeft: "剩余字符：{{count}}",
  cancel: "取消",
  save: "保存",
  restore: "还原",
  followingCartDelete: "将删除以下保存的购物车",
  followingCartRestore: "以下已保存的购物车将恢复为活动购物车",
  "delete": "删除",
  deleteCartSuccess: "已成功删除购物车",
  editCartSuccess: "已成功编辑购物车",
  editSavedCart: "编辑已保存购物车",
  deleteSavedCart: "删除已保存购物车",
  restoreSavedCart: "恢复已保存购物车",
  name: "名称",
  id: "标识",
  description: "描述",
  quantity: "数量",
  quantityFull: "数量",
  total: "总计",
  keepCopySavedCart: "将该购物车副本保存到已保存购物车列表",
  defaultCloneCartName: "{{name}} 副本",
  nameOfCloneCart: "复制购物车的名称"
};
var addToSavedCart$1 = {
  savedCarts: "已保存购物车",
  saveCartForLater: "保存购物车备用"
};
var savedCart$1 = {
  savedCartDetails: savedCartDetails$1,
  savedCartList: savedCartList$1,
  savedCartCartPage: savedCartCartPage$1,
  savedCartDialog: savedCartDialog$1,
  addToSavedCart: addToSavedCart$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  savedCart: savedCart$1
};
var savedCartDetails = {
  cartName: "名稱",
  cartDescription: "說明",
  cartId: "ID",
  dateSaved: "儲存日期",
  items: "項目",
  quantity: "數量",
  total: "總計",
  editSavedCart: "編輯儲存的購物車",
  deleteSavedCart: "刪除儲存的購物車",
  addSelectedItems: "新增所選項目",
  emptyCartItems: "空白購物車項目"
};
var savedCartList = {
  savedCarts: "儲存的購物車 ({{count}})",
  cartName: "名稱",
  cartId: "ID",
  dateSaved: "儲存日期",
  cartDescription: "說明",
  quantity: "數量",
  quantityFull: "數量",
  total: "總計",
  actions: "動作",
  makeCartActive: "標記購物車為啟用中",
  notFound: "找不到儲存的購物車",
  swapCartNoActiveCart: "{{cartName}} 已成功啟用現有購物車。",
  swapCartWithActiveCart: "{{cartName}} 已成功啟用現有購物車。您的先前項目已儲存於購物車 {{previousCartName}} 中。"
};
var savedCartCartPage = {
  messages: {
    cartSaved: "已成功儲存您的購物車項目，稍後可用於 \"{{cartName}}\" 購物車"
  }
};
var savedCartDialog = {
  saveForLater: "儲存以供稍後使用",
  itemsSavedForLater: "購物車中的所有項目將儲存，已供稍後使用",
  savedCartName: "儲存的購物車名稱",
  savedCartDescription: "儲存的購物車說明",
  optional: "選擇性",
  charactersLeft: "剩餘字元：{{count}}",
  cancel: "取消",
  save: "儲存",
  restore: "還原",
  followingCartDelete: "將刪除下列儲存的購物車",
  followingCartRestore: "下列儲存的購物車將還原為啟用中購物車",
  "delete": "刪除",
  deleteCartSuccess: "已成功刪除購物車",
  editCartSuccess: "已成功編輯購物車",
  editSavedCart: "編輯儲存的購物車",
  deleteSavedCart: "刪除儲存的購物車",
  restoreSavedCart: "還原儲存的購物車",
  name: "名稱",
  id: "ID",
  description: "說明",
  quantity: "數量",
  quantityFull: "數量",
  total: "總計",
  keepCopySavedCart: "在儲存的購物車清單中保留此購物車副本",
  defaultCloneCartName: "{{name}} 副本",
  nameOfCloneCart: "複製的購物車名稱"
};
var addToSavedCart = {
  savedCarts: "儲存的購物車",
  saveCartForLater: "儲存購物車以供稍後使用"
};
var savedCart = {
  savedCartDetails: savedCartDetails,
  savedCartList: savedCartList,
  savedCartCartPage: savedCartCartPage,
  savedCartDialog: savedCartDialog,
  addToSavedCart: addToSavedCart
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  savedCart
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const savedCartTranslationChunksConfig = {
  savedCart: ['savedCartDetails', 'savedCartList', 'savedCartCartPage', 'savedCartDialog', 'addToSavedCart']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: savedCartTranslations
 *             +   resources: { en: savedCartTranslationsEn }
 *               }
 *             ```
 */
const savedCartTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { savedCartTranslationChunksConfig, savedCartTranslations, cs as savedCartTranslationsCs, de as savedCartTranslationsDe, en as savedCartTranslationsEn, es as savedCartTranslationsEs, es_CO as savedCartTranslationsEs_CO, fr as savedCartTranslationsFr, hi as savedCartTranslationsHi, hu as savedCartTranslationsHu, id as savedCartTranslationsId, it as savedCartTranslationsIt, ja as savedCartTranslationsJa, ko as savedCartTranslationsKo, pl as savedCartTranslationsPl, pt as savedCartTranslationsPt, ru as savedCartTranslationsRu, zh as savedCartTranslationsZh, zh_TW as savedCartTranslationsZh_TW };
