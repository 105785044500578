import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BREADCRUMB } from './quote-cart.model';
import { TranslationService } from '@spartacus/core';

@Component({
  standalone: false,
  selector: 'ds-quote-cart',
  templateUrl: './quote-cart.component.html',
  styleUrls: ['./quote-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteCartComponent implements OnInit {
  breadcrumbs: BREADCRUMB[] = [];
  items: any[] = [
    {
      product: {
        code: '#h712574',
        name: 'Everest Mentor Flex 6.1mm X 3.0m CO Case',
        images: 'PRIMARY',
        configurable: false,
      },
      totalPrice: { formattedValue: 'USD $6,565.00' },
      listPrice: { formattedValue: 'USD $9,576.00' },
      discountPercentage: '66',
      leadTime: '34',
      shipDate: '25-May-2024',
    },
    {
      product: {
        code: '#h7812574',
        name: 'Everest Mentor Flex 6.1mm X 3.0m CO Case',
        images: 'PRIMARY',
        configurable: false,
      },
      totalPrice: { formattedValue: 'USD $6,565.00' },
      listPrice: { formattedValue: 'USD $9,576.00' },
      discountPercentage: '76',
      leadTime: '34',
      shipDate: '25-May-2024',
    },
  ];
  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.translationService
      .translate('quoteCart.pageTitle')
      .subscribe((res: string) => {
        this.breadcrumbs = [
          {
            name: res,
            url: '/waygate/quote-cart',
          },
        ];
      });
  }
}
