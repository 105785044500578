var common$w = {
  cancel: "Zrušit",
  "delete": "Vymazat",
  remove: "Odebrat",
  edit: "Upravit",
  restore: "Obnovit",
  back: "Zpět",
  submit: "Odeslat",
  "continue": "Pokračovat",
  save: "Uložit",
  done: "Hotovo",
  home: "Domů",
  noData: "Žádná data",
  confirm: "Potvrdit",
  more: "více",
  close: "Zavřít",
  loading: "Načítání",
  menu: "Menu",
  reset: "Resetovat",
  search: "Hledat",
  add: "Přidat",
  breadcrumbs: "Navigační cesty",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Vyberte soubor",
  clear: "Vymazat",
  loaded: "Načteno",
  results: "Výsledky",
  of: "z",
  ngSelectDropdownOptionsList: "Možnosti",
  required: "povinné",
  zoomIn: "Zvětšit",
  zoomOut: "Zmenšit",
  selected: "Vybráno",
  expand: "Rozbalit",
  collapse: "Sbalit"
};
var pageMetaResolver$g = {
  category: {
    title: "{{count}} výsledek pro {{query}}",
    title_other: "Počet výsledků pro {{query}}: {{count}}"
  },
  checkout: {
    title: "Pokladna"
  },
  search: {
    title: "{{count}} výsledek pro „{{query}}“",
    title_other: "Počet výsledků pro „{{query}}“: {{count}}",
    findProductTitle: "{{count}} výsledek pro kupón „{{coupon}}“",
    findProductTitle_other: "Počet výsledků pro kupón „{{coupon}}“: {{count}}",
    default_title: "Všechny produkty"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$g = {
  loading: "Načítání…"
};
var navigation$g = {
  categoryNavLabel: "Nabídka Kategorie",
  footerNavLabel: "Odkazy v zápatí",
  goTo: "Přejít na {{location}}",
  navigateTo: "Navigovat k {{nav}}",
  scrollToTop: "Vraťte se na začátek stránky.",
  linkItemInList: "{{title}}. {{position}} z {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$g = {
  placeholder: "Zadejte název produktu nebo SKU",
  productSearch: "Hledat produkt",
  ariaLabelInput: "Hledat zde…",
  ariaLabelSuggestions: "návrhy pro psaní",
  ariaLabelProducts: "výsledky produktu",
  initialDescription: "Když jsou k dispozici výsledky automatického dokončování, použijte šipky nahoru a dolů k prohlížení a enter k výběru. Uživatelé dotykových zařízení mohou vyhledávat dotykem nebo gesty přejetí prstem.",
  suggestions: "Navržená klíčová slova",
  recentSearches: "Nejnovější hledání",
  trendingSearches: "Častá hledání",
  suggestionsResult: "{{ count }} návrh a ",
  suggestionsResult_other: "Počet návrhů: {{ count }} a ",
  products: "Doporučené produkty",
  productsResult: "{{ count }} produkt je k dispozici.",
  productsResult_other: "Počet produktů k dispozici: {{ count }}.",
  resetLabel: "Resetovat hledání",
  help: {
    insufficientChars: "Zadejte víc znaků",
    noMatch: "Nemůžeme najít žádné výsledky",
    exactMatch: "{{ term }}",
    empty: "Zeptejte se nás na cokoliv"
  },
  closeSearchPanel: "Zavřít"
};
var sorting$g = {
  date: "Datum",
  orderNumber: "Číslo objednávky",
  rma: "Číslo vratky",
  replenishmentNumber: "Číslo doplnění",
  nextOrderDate: "Datum další objednávky",
  pageViewUpdated: "Zobrazení stránky aktualizováno podle vybraných voleb."
};
var httpHandlers$g = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Přihlaste se znovu.",
    user_is_disabled: "{{ errorMessage }}. Obraťte se na správce."
  },
  badGateway: "Došlo k chybě serveru. Zkuste to znovu později.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Přihlaste se znovu.",
  badRequestOldPasswordIncorrect: "Staré heslo je chybné.",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} e-mail již existuje. Zadejte u pokladny jiný e-mail, čímž se zaregistrujete s účtem hosta.",
  conflict: "Již existuje.",
  forbidden: "Nemáte oprávnění provádět tuto akci. Obraťte se na správce, pokud si myslíte, že se jedná o chybu.",
  gatewayTimeout: "Server nereaguje. Zkuste to později znovu.",
  internalServerError: "Došlo k interní chybě serveru. Zkuste to znovu později.",
  sessionExpired: "Vypršel časový limit relace. Přihlaste se znovu.",
  unknownError: "Došlo k neznámé chybě",
  unauthorized: {
    common: "Došlo k neznámé chybě ověření",
    invalid_client: "Špatné identifikační údaje klienta"
  },
  validationErrors: {
    missing: {
      card_cardType: "Vybraná platební karta není podporována. Vyberte jinou kartu.",
      card_accountNumber: "Zadané číslo kreditní karty je neplatné.",
      card_cvNumber: "Zadaný bezpečnostní kód karty je neplatný.",
      card_expirationMonth: "Zadané datum konce platnosti kreditní karty je neplatné.",
      card_expirationYear: "Zadané číslo kreditní karty není platné.",
      billTo_firstName: "Zadané křestní jméno je neplatné.",
      billTo_lastName: "Zadané příjmení je neplatné.",
      billTo_street1: "Zadaná adresa je neplatná.",
      billTo_street2: "Zadaná adresa je neplatná.",
      billTo_city: "Zadané město pro tuto kreditní kartu je neplatné.",
      billTo_state: "Zadaný stát/správní celek pro tuto kreditní kartu je neplatný.",
      billTo_country: "Zadaná země/region pro tuto kreditní kartu je neplatná.",
      billTo_postalCode: "Zadané PSČ pro tuto kreditní kartu je neplatné.",
      country: {
        isocode: "Chybí země/region"
      }
    },
    invalid: {
      card_expirationMonth: "Zadané datum konce platnosti kreditní karty je neplatné.",
      firstName: "Zadané křestní jméno je neplatné.",
      lastName: "Zadané příjmení je neplatné.",
      password: "Zadané heslo není platné.",
      uid: "UID je neplatné.",
      code: "Kód je neplatný.",
      email: "E-mail je neplatný.",
      loginId: "E-mail je neplatný."
    }
  },
  cartNotFound: "Karta nebyla nalezena.",
  invalidCodeProvided: "Zadaný kód je neplatný.",
  voucherExceeded: "U tohoto kupónu byl překročen počet opakovaných použití.",
  unknownIdentifier: "Položka nebyla nalezena.",
  otherCartErrors: "Došlo k chybě košíku."
};
var miniCart$g = {
  item: "{{count}} položka je právě ve vašem košíku",
  item_other: "{{count}} položek je právě ve vašem košíku",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$g = {
  skipTo: "Přeskočit na",
  labels: {
    header: "Hlavička",
    main: "Hlavní obsah",
    footer: "Zápatí",
    productFacets: "Aspekty produktu",
    productList: "Seznam produktů"
  }
};
var carousel$g = {
  previousSlide: "Předchozí snímek",
  nextSlide: "Další snímek",
  slideNumber: "Snímek {{currentSlideNumber}} obsahuje {{numberOfSlides}} položek",
  carouselForProduct: "Karusel, obrázky pro {{product}}"
};
var formErrors$g = {
  labeled: {
    required: "Pole {{label}} je povinné",
    cxInvalidEmail: "Pole {{label}} nemá platný formát e-mailu. Musí odpovídat vzoru: example@yourdomain.com",
    cxInvalidPassword: "Pole {{label}} nemá platný formát hesla",
    cxMinOneUpperCaseCharacter: "Heslo musí obsahovat alespoň jedno velké písmeno",
    cxMinOneDigit: "Heslo musí obsahovat minimálně jednu číslici",
    cxMinOneSpecialCharacter: "Heslo musí obsahovat alespoň jeden symbol.",
    cxMinSixCharactersLength: "Heslo musí obsahovat alespoň 6 znaků",
    cxMinEightCharactersLength: "Heslo musí obsahovat alespoň 8 znaků",
    cxMaxCharactersLength: "Heslo nesmí mít víc jak 128 znaků",
    cxContainsSpecialCharacters: "Heslo nesmí obsahovat symboly.",
    cxNoConsecutiveCharacters: "Heslo nesmí obsahovat stejné znaky následující po sobě",
    date: {
      required: "Pole {{label}} je povinné",
      min: "Pole {{label}} nesmí být před {{min}}",
      max: "Pole {{label}} nesmí být po {{max}}",
      pattern: "V poli {{label}} použijte formát data rrrr-mm-dd",
      invalid: "V poli {{label}} použijte platné datum"
    }
  },
  globalMessage: "Formulář, který chcete odeslat, obsahuje chyby.",
  required: "Toto pole je povinné",
  cxInvalidEmail: "Toto není platný formát e-mailu",
  cxInvalidPassword: "Toto není platný formát hesla",
  cxPasswordsMustMatch: "Pole hesla se musejí shodovat",
  cxPasswordsCannotMatch: "Pole hesla se nemohou shodovat",
  cxEmailsMustMatch: "Pole e-mailu se musejí shodovat",
  cxStarRatingEmpty: "Pole hodnocení je povinné",
  cxNoSelectedItemToCancel: "Vyberte alespoň jednu položku",
  cxNegativeAmount: "Částka musí být větší nebo rovna nule",
  cxContainsSpecialCharacters: "Toto pole nesmí obsahovat speciální znaky.",
  date: {
    required: "Toto pole je povinné",
    min: "Datum nemůže být před {{min}}",
    max: "Datum nemůže být po {{max}}",
    pattern: "Použijte formát data rrrr-mm-dd",
    invalid: "Použijte platné datum"
  },
  file: {
    required: "Soubor je povinný",
    empty: "Soubor nesmí být prázdný",
    invalidExtension: "Přípona souboru je neplatná",
    tooLarge: "Velikost souboru přesahuje {{ maxSize }} MB",
    tooManyEntries: "Počet položek je větší než {{ maxEntries }}",
    notParsable: "Soubor nelze analyzovat",
    fileNotAllowed: "Tento typ souboru není dovolen"
  }
};
var errorHandlers$g = {
  scriptFailedToLoad: "Nepovedlo se načíst script",
  refreshThePage: "Aktualizujte tuto stránku."
};
var assistiveMessage$g = {
  actionCancelled: "Akce byla zrušena, nic nebylo změněno."
};
var passwordVisibility$g = {
  showPassword: "Zobrazit heslo",
  hidePassword: "Skrýt heslo"
};
var generalErrors$g = {
  pageFailure: "Stránku nelze načíst. Zkuste to později znovu."
};
var formLegend$g = {
  required: "Pole označená hvězdičkou (*) jsou povinná."
};
var chatMessaging$g = {
  charactersLeft: "zbývá znaků: {{count}}",
  addNewMessage: "Přidat novou zprávu",
  send: "Odeslat",
  uploadFile: "Uploadovat soubor",
  informationLabel: "{{author}}. {{text}} v {{date}}",
  messages: "Zprávy",
  addMessagePlaceHolder: "Začněte psát…",
  characterLimitAlert: "Dosažen znakový limit",
  a11y: {
    itemListBoxLabel: "Vyberte položku, které se zpráva týká."
  }
};
var common$x = {
  common: common$w,
  pageMetaResolver: pageMetaResolver$g,
  spinner: spinner$g,
  navigation: navigation$g,
  searchBox: searchBox$g,
  sorting: sorting$g,
  httpHandlers: httpHandlers$g,
  miniCart: miniCart$g,
  skipLink: skipLink$g,
  carousel: carousel$g,
  formErrors: formErrors$g,
  errorHandlers: errorHandlers$g,
  assistiveMessage: assistiveMessage$g,
  passwordVisibility: passwordVisibility$g,
  generalErrors: generalErrors$g,
  formLegend: formLegend$g,
  chatMessaging: chatMessaging$g
};
var setDeliveryMode$g = {
  unknownError: "Došlo k neznámé chybě. Obraťte se na podporu."
};
var deliveryMode$g = {
  setDeliveryMode: setDeliveryMode$g
};
var myAccountV2Consent$w = {
  header: "Správa souhlasů",
  dateDescription: "Schváleno dne ",
  clearAll: "Deaktivovat vše",
  selectAll: "Aktivovat vše",
  message: {
    success: {
      given: "Souhlas úspěšně udělen.",
      withdrawn: "Souhlas úspěšně odvolán."
    }
  }
};
var myAccountV2Consent$x = {
  myAccountV2Consent: myAccountV2Consent$w
};
var myAccountV2NotificationPreference$w = {
  header: "Kanály oznámení",
  message: "Nastavte si preferované kanály oznámení",
  note: "Poznámka: ",
  noteMessage: "Pokud deaktivujete všechny kanály, nebudete nadále dostávat žádná oznámení.",
  EMAIL: "E-mail:"
};
var myAccountV2NotificationPreference$x = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$w
};
var closeAccount$g = {
  confirmAccountClosure: "Potvrdit uzavření účtu",
  confirmAccountClosureMessage: "Chcete svůj účet opravdu uzavřít?",
  closeMyAccount: "UZAVŘÍT MŮJ ÚČET",
  accountClosedSuccessfully: "Účet úspěšně uzavřen",
  accountClosedFailure: "Nepodařilo se uzavřít účet"
};
var updatePasswordForm$g = {
  oldPassword: {
    label: "Staré heslo",
    placeholder: "Staré heslo"
  },
  oldPasswordIsRequired: "Je třeba zadat staré heslo.",
  newPassword: {
    label: "Nové heslo",
    placeholder: "Nové heslo"
  },
  passwordMinRequirements: "Zde je stručný návod na bezpečné heslo: Použijte minimálně 8 znaků a kombinujte malá a velká písmena, číslice a symboly.",
  passwordStrengthRequirements: "Zde je stručný návod na bezpečné heslo: Použijte minimálně 8 znaků a kombinujte malá a velká písmena, číslice a symboly. Nepoužívejte stejné znaky za sebou.",
  confirmPassword: {
    label: "Potvrďte nové heslo",
    placeholder: "Potvrdit heslo"
  },
  bothPasswordMustMatch: "Obě hesla se musí shodovat",
  passwordUpdateSuccess: "Heslo bylo úspěšně aktualizováno",
  accessDeniedError: "Přístup byl odepřen"
};
var updateProfileForm$g = {
  title: "Oslovení",
  none: "",
  firstName: {
    label: "Křestní jméno",
    placeholder: "Křestní jméno"
  },
  firstNameIsRequired: "Křestní jméno je povinné.",
  lastName: {
    label: "Příjmení",
    placeholder: "Příjmení"
  },
  lastNameIsRequired: "Příjmení je povinné.",
  profileUpdateSuccess: "Osobní údaje byly úspěšně aktualizovány",
  customerId: "Č. zákazníka"
};
var consentManagementForm$g = {
  clearAll: "Vymazat vše",
  selectAll: "Vybrat vše",
  message: {
    success: {
      given: "Souhlas úspěšně udělen.",
      withdrawn: "Souhlas úspěšně udělen."
    }
  }
};
var myCoupons$g = {
  noCouponsMessage: "Nemáte k dispozici žádné kupóny.",
  effectiveTitle: "Platné:",
  Effective: "PLATNÉ",
  PreSession: "BRZY PLATNÉ",
  ExpireSoon: "BRZY VYPRŠÍ",
  readMore: "Další informace",
  notification: "Oznámení",
  findProducts: "Hledat produkty",
  status: "Status:",
  dialogTitle: "Kupón",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Kód kupónu",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Úspěšně jste uplatnili tento kupón.",
  myCoupons: "Moje kupóny",
  startDateAsc: "Datum zahájení (vzestupně)",
  startDateDesc: "Datum ukončení (sestupně)",
  endDateAsc: "Datum ukončení (vzestupně)",
  endDateDesc: "Datum zahájení (sestupně)",
  sortBy: "Řadit podle",
  sortCoupons: "Řadit kupóny",
  notesPreffix: "Můžete nastavit preferované kanály pro příjem oznámení o kupónech na stránce ",
  notesLink: "Kanály oznámení",
  notesSuffix: " ."
};
var notificationPreference$g = {
  message: "Nastavte si preferované kanály oznámení",
  note: "Poznámka: ",
  noteMessage: "Pokud deaktivujete všechny kanály, nebudete nadále dostávat žádná oznámení.",
  EMAIL: "E-mail:",
  SMS: "SMS:",
  SITE_MESSAGE: "Zpráva webu"
};
var myInterests$g = {
  header: "Moje zájmy",
  item: "POLOŽKA",
  price: "CENA",
  notifications: "NOTIFIKACE",
  noInterests: "Zatím nemáte žádné registrované zájmy.",
  inStock: "Na skladě",
  lowStock: "Nedostatečné množství zásob",
  outOfStock: "Není skladem",
  BACK_IN_STOCK: "Opět na skladě",
  sortBy: "Řadit podle",
  sortInterests: "Řadit zájmy",
  expirationDate: " - Do {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Odebrat",
  sorting: {
    byNameAsc: "Název (vzestupně)",
    byNameDesc: "Název (sestupně)"
  },
  actions: "AKCE",
  caption: "Obsah mých zájmů.",
  itemRemoved: "Vybraná položka byla odebrána."
};
var myAccount$g = {
  closeAccount: closeAccount$g,
  updatePasswordForm: updatePasswordForm$g,
  updateProfileForm: updateProfileForm$g,
  consentManagementForm: consentManagementForm$g,
  myCoupons: myCoupons$g,
  notificationPreference: notificationPreference$g,
  myInterests: myInterests$g
};
var paymentForm$g = {
  payment: "Platba",
  choosePaymentMethod: "Zvolte způsob platby",
  paymentType: "Typ platby",
  accountHolderName: {
    label: "Jméno majitele účtu",
    placeholder: "Jméno majitele účtu"
  },
  cardNumber: "Číslo karty",
  expirationDate: "Datum expirace",
  securityCode: "Bezpečnostní kód (CVV)",
  securityCodeTitle: "Ověřovací hodnota karty",
  saveAsDefault: "Uložit jako výchozí",
  setAsDefault: "Nastavit jako výchozí způsob platby",
  billingAddress: "Fakturační adresa",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Stejná jako dodací adresa",
  billingAddressSameAsShipping: "Fakturační adresa je stejná jako dodací adresa",
  selectOne: "Vyberte jednu…",
  monthMask: "MM",
  yearMask: "RRRR",
  expirationYear: "Rok skončení platnosti {{ selected }}",
  expirationMonth: "Měsíc skončení platnosti {{ selected }}",
  useThisPayment: "Použít tuto platbu",
  addNewPayment: "Přidat novou platbu",
  paymentAddedSuccessfully: "Nová platba byla úspěšně přidána",
  changePayment: "Změnit platbu"
};
var paymentMethods$g = {
  paymentMethods: "Způsoby platby",
  paymentMethodSelected: "Způsob platby byl vybrán",
  newPaymentMethodsAreAddedDuringCheckout: "Během kontroly a potvrzení byl přidán nový způsob platby.",
  invalidField: "Neplatné pole: {{ field }}"
};
var paymentCard$g = {
  deleteConfirmation: "Opravdu chcete odstranit tento způsob platby?",
  setAsDefault: "Nastavit jako výchozí",
  expires: "Platnost končí: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ STANDARDNÍ",
  defaultPaymentLabel: "Standardní způsob platby",
  additionalPaymentLabel: "Další způsob platby {{ number }}",
  selected: "Vybráno",
  selectedPayment: "Vybrané platby",
  deletePaymentSuccess: "Způsob platby byl úspěšně odstraněn",
  visa: "Karta Visa",
  master: "Mastercard Card",
  masterEuro: "Karta Mastercard Eurocard",
  dinersClub: "Karta Diners Club",
  "switch": "Karta Switch",
  maestro: "Karta Maestro",
  amex: "Karta American Express",
  credit: "Platební karta"
};
var paymentTypes$g = {
  title: "Způsob platby",
  paymentType_CARD: "Platební karta",
  paymentType_ACCOUNT: "Účet"
};
var paymentMessages$g = {
  setAsDefaultSuccessfully: "Nová platba byla úspěšně nastavena jako standardní."
};
var payment$g = {
  paymentForm: paymentForm$g,
  paymentMethods: paymentMethods$g,
  paymentCard: paymentCard$g,
  paymentTypes: paymentTypes$g,
  paymentMessages: paymentMessages$g
};
var pdf$w = {
  defaultTitle: "Dokument"
};
var pdf$x = {
  pdf: pdf$w
};
var productDetails$g = {
  id: "ID",
  quantity: "Množství",
  quantityFull: "Množství",
  productDetails: "Detaily produktu",
  specification: "Specifikace",
  reviews: "Recenze",
  shipping: "Doručení",
  share: "Sdílet",
  showReviews: "Zobrazit recenze",
  noReviews: "Zatím žádné recenze",
  productPrice: "Cena produktu",
  noProductImage: "Nejsou k dispozici žádné obrázky, {{ product }}"
};
var productList$g = {
  filterBy: {
    label: "Filtrovat podle",
    action: "Filtrovat podle"
  },
  activeFilter: "{{filter}} filtr. Kliknutím na toto tlačítko odeberete filtr.",
  appliedFilter: "Použitý filtr:",
  showLess: "Zobrazit méně…",
  showMore: "Zobrazit více…",
  sortBy: "Řadit podle",
  sortResults: "Řadit výsledky",
  backToTopBtn: "ZPĚT NAHORU",
  showMoreBtn: "ZOBRAZIT VÍCE",
  productSearchPagination: "Stránkování vyhledávání produktů",
  productListResults: "Seznam výsledků produktů"
};
var productFacetNavigation$g = {
  filterBy: {
    label: "Filtrovat podle",
    action: "Filtrovat podle",
    facet: "Filtrovat produkty podle aspektů",
    name: "Filtrovat podle {{name}}"
  },
  appliedFilter: "Použitý filtr:",
  showLess: "Zobrazit méně…",
  showMore: "Zobrazit více…",
  sortBy: "Řadit podle",
  ariaLabelShowLess: "Tlačítko Zobrazit méně. Kliknutím na toto tlačítko omezíte možnosti pro aktivní skupinu.",
  ariaLabelShowMore: "Tlačítko Zobrazit více. Kliknutím na toto tlačítko rozšíříte možnosti pro aktivní skupinu.",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} položka k dispozici",
  ariaLabelItemsAvailable_other: "K dispozici položek: {{name}}, {{state}} {{count}}",
  decreaseOptionsVisibility: "Možnosti byly v aktivní skupině skryty. Můžete si je přečíst na předchozí záložce. Přechodem na další záložku zobrazíte další skupinu.",
  increaseOptionsVisibility: "Do aktivní skupiny bylo přidáno víc možností. Můžete si je přečíst na předchozí záložce. Přechodem na další záložku zobrazíte další skupinu.",
  backToResults: "Zpět na výsledky",
  productFacets: "Aspekty produktu"
};
var productSummary$g = {
  id: "ID",
  showReviews: "Zobrazit recenze",
  showReviewsDetailed: "Zobrazit počet recenzí: {{count}} s hodnocením {{rating}} z 5 hvězdiček",
  share: "Sdílet",
  newItemPrice: "Nová cena položky"
};
var productReview$g = {
  overallRating: "Celkové hodnocení",
  reviewTitle: "Název recenze",
  writeYourComments: "Napište své komentáře",
  rating: "Hodnocení",
  ratingRequired: "Hodnocení produktu, vyžadováno",
  addRate: "Přidat hodnocení: {{count}} hvězdička",
  addRate_other: "Přidat hodnocení: Počet hvězdiček: {{count}}",
  reviewerName: "Jméno recenzenta (volitelné)",
  writeReview: "Napsat recenzi",
  more: "Zobrazit více recenzí",
  less: "Zobrazit méně recenzí",
  thankYouForReview: "Děkujeme za recenzi! Upozorňujeme, že recenze mohou vyžadovat přezkoumání, než se zde objeví.",
  postReviewFail: "Při odesílání recenze se něco pokazilo. Zkuste to později.",
  ratedOutOf: "Hodnoceno: {{rating}} z 5"
};
var productCarousel$g = {
  carouselLabel: "Karusel, {{title}}"
};
var addToCart$g = {
  itemAddedToYourCart: "Položka přidána do košíku",
  itemsAddedToYourCart: "Položky přidány do košíku",
  itemsIncrementedInYourCart: "Tato položka již byla ve vašem košíku. Množství bylo upraveno.",
  items: "položky",
  updatingCart: "Aktualizace košíku…",
  addToCart: "Přidat do košíku",
  viewCart: "Zobrazit košík",
  proceedToCheckout: "Pokračovat k pokladně",
  quantity: "Množství",
  quantityFull: "Množství",
  outOfStock: "Není skladem",
  inStock: "Na skladě",
  selectStyleAndSize: "Vyberte styl a velikost ke kontrole zásob",
  removeFromCart: "Odebrat produkt z košíku",
  closeModal: "Zavřít modální okno",
  buyItAgain: "Koupit znovu",
  addToActiveCart: "Přidat do aktivního košíku"
};
var TabPanelContainer$g = {
  tabs: {
    ProductDetailsTabComponent: "Detaily produktu",
    ProductSpecsTabComponent: "Specifikace",
    ProductReviewsTabComponent: "Recenze",
    deliveryTab: "Doručení",
    SparePartsTabComponent: " Náhradní díly"
  },
  tabPanelContainerRegion: "Skupina záložek s více detaily produktu",
  tabPanelContainerRegionGroup: "Skupina s více detaily produktu"
};
var addToWishList$g = {
  add: "Přidat na seznam přání",
  remove: "Odebrat ze seznamu přání",
  anonymous: "Chcete-li přidávat do seznamu přání, přihlaste se.",
  addedToWishList: "Produkt přidán na seznam přání",
  removedFromWishList: "Produkt odebrán ze seznamu přání"
};
var stockNotification$g = {
  notifyMe: "UPOZORNĚTE MĚ",
  stopNotify: "UKONČIT UPOZORŇOVÁNÍ",
  getNotify: "Nechte si zaslat upozornění, až bude tento produkt skladem.",
  getNotifySuffix: "pro upozornění, až bude tento produkt skladem.",
  activateChannelsPrefix: "Abyste byli upozorněni, je třeba aktivovat ",
  channelsLink: "Kanály oznámení",
  activateChannelsSuffix: ".",
  notified: "Až bude tento produkt opět skladem, pošleme vám upozornění.",
  getNotified: "Nechte se upozornit, až bude tento produkt opět skladem.",
  unsubscribeSuccess: "Dostanete upozornění, až bude tento produkt opět skladem.",
  subscriptionDialog: {
    header: "Přihlášení k upozornění při nedostupnosti produktu",
    notifiedPrefix: "Budete upozorněni:",
    notifiedSuffix: "jakmile bude tento produkt opět skladem.",
    manageChannelsPrefix: "Nastavte si preferované kanály oznámení na ",
    manageChannelsLink: "stránce Preference oznámení",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "Své odběry můžete spravovat na stránce ",
    manageSubscriptionsLink: "O co mám zájem",
    manageSubscriptionsSuffix: " .",
    okBtn: "OK",
    subscribing: "Registrujeme vás do upozornění při nedostupnosti tohoto produktu."
  }
};
var itemCounter$g = {
  removeOne: "Odebrat jeden",
  addOneMore: "Přidat ještě jeden",
  quantity: "Množství"
};
var productView$g = {
  gridView: "Výběrem přepnete na zobrazení mřížky",
  listView: "Výběrem přepnete na zobrazení seznamu"
};
var product$g = {
  productDetails: productDetails$g,
  productList: productList$g,
  productFacetNavigation: productFacetNavigation$g,
  productSummary: productSummary$g,
  productReview: productReview$g,
  productCarousel: productCarousel$g,
  addToCart: addToCart$g,
  TabPanelContainer: TabPanelContainer$g,
  addToWishList: addToWishList$g,
  stockNotification: stockNotification$g,
  itemCounter: itemCounter$g,
  productView: productView$g
};
var pwa$w = {
  addToHomeScreenDescription: "Přidejte si SAP storefron na hlavní obrazovku svého zařízení, abyste se mohli rychleji vrátit.",
  noInstallationNeeded: "Není třeba nic instalovat",
  fastAccessToApplication: "Rychlý přístup do aplikace",
  addToHomeScreen: "Přidat na domovskou obrazovku",
  addedToHomeScreen: "SAP Storefront byl přidán na vaši domovskou obrazovku"
};
var pwa$x = {
  pwa: pwa$w
};
var siteThemeSwitcher$w = {
  theme: "Motiv",
  themes: {
    "default": "Standardní",
    highContrastDark: "HC-tmavý",
    highContrastLight: "HC-světlý"
  }
};
var siteThemeSwitcher$x = {
  siteThemeSwitcher: siteThemeSwitcher$w
};
var anonymousConsents$g = {
  dialog: {
    title: "Správa souhlasů",
    legalDescription: "K personalizaci obsahu a zlepšení zkušenosti uživatele používáme soubory cookie ukládané do úložiště prohlížeče. Údaje o tom, jak používáte naše stránky, můžeme také sdílet s našimi sociálními sítěmi. Více informací naleznete v našich zásadách ochrany osobních údajů.",
    selectAll: "Vybrat vše",
    clearAll: "Vymazat vše"
  },
  banner: {
    title: "Tyto webové stránky používají soubory cookie",
    description: "Používáme soubory cookie ukládané do úložiště prohlížeče k personalizaci obsahu a zlepšení zkušenosti uživatele.",
    allowAll: "Povolit vše",
    viewDetails: "Zobrazit detaily",
    consentManagement: "Správa souhlasů"
  }
};
var checkoutLogin$g = {
  emailAddress: {
    label: "E-mailová adresa",
    placeholder: "Zadejte e-mail"
  },
  confirmEmail: {
    label: "Potvrďte e-mail",
    placeholder: "Potvrďte e-mail"
  },
  "continue": "Pokračovat",
  emailIsRequired: "Neplatný formát e-mailu",
  emailsMustMatch: "E-mail se neshoduje"
};
var authMessages$g = {
  signedOutSuccessfully: "Byli jste úspěšně odhlášeni."
};
var user$g = {
  anonymousConsents: anonymousConsents$g,
  checkoutLogin: checkoutLogin$g,
  authMessages: authMessages$g
};
var player$g = {
  label: "Přehrávač videa"
};
var video$g = {
  player: player$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  common: common$x,
  deliveryMode: deliveryMode$g,
  myAccountV2Consent: myAccountV2Consent$x,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$x,
  myAccount: myAccount$g,
  payment: payment$g,
  pdf: pdf$x,
  product: product$g,
  pwa: pwa$x,
  siteThemeSwitcher: siteThemeSwitcher$x,
  user: user$g,
  video: video$g
};
var common$u = {
  cancel: "Abbrechen",
  "delete": "Löschen",
  remove: "Entfernen",
  edit: "Bearbeiten",
  restore: "Wiederherstellen",
  back: "Zurück",
  submit: "Absenden",
  "continue": "Weiter",
  save: "Speichern",
  done: "Fertig",
  home: "Startseite",
  noData: "Keine Daten.",
  confirm: "Bestätigen",
  more: "mehr",
  close: "Schließen",
  loading: "Laden",
  menu: "Menü",
  reset: "Zurücksetzen",
  search: "Suchen",
  add: "Hinzufügen",
  breadcrumbs: "Breadcrumbs",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Datei auswählen",
  clear: "Entfernen",
  loaded: "Geladen",
  results: "Ergebnisse",
  of: "von",
  ngSelectDropdownOptionsList: "Optionen",
  required: "erforderlich",
  zoomIn: "Vergrößern",
  zoomOut: "Verkleinern",
  selected: "Ausgewählt",
  expand: "Expandieren",
  collapse: "Komprimieren"
};
var pageMetaResolver$f = {
  category: {
    title: "{{count}} Ergebnis für {{query}}",
    title_other: "{{count}} Ergebnisse für {{query}}"
  },
  checkout: {
    title: "Bezahlen"
  },
  search: {
    title: "{{count}} Ergebnis für \"{{query}}\"",
    title_other: "{{count}} Ergebnisse für \"{{query}}\"",
    findProductTitle: "{{count}} Ergebnis für Coupon \"{{coupon}}\"",
    findProductTitle_other: "{{count}} Ergebnisse für Coupon \"{{coupon}}\"",
    default_title: "Alle Produkte"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$f = {
  loading: "Wird geladen..."
};
var navigation$f = {
  categoryNavLabel: "Kategoriemenü",
  footerNavLabel: "Fußzeilenlinks",
  goTo: "Gehe zu {{location}}",
  navigateTo: "Navigieren zu: {{nav}}",
  scrollToTop: "An den Anfang der Seite zurückblättern",
  linkItemInList: "{{title}}. {{position}} von {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$f = {
  placeholder: "Produktname oder SKU eingeben",
  productSearch: "Ein Produkt suchen",
  ariaLabelInput: "Hier suchen...",
  ariaLabelSuggestions: "Eingabevorschläge",
  ariaLabelProducts: "Produktergebnisse",
  initialDescription: "Wenn Ergebnisse aus der automatischen Vervollständigung verfügbar sind, können Sie sie mit den nach oben und unten zeigenden Pfeiltasten ansehen und mit der Eingabetaste auswählen. Benutzer von Touchgeräten verwenden Touch- oder Wischgesten.  ",
  suggestions: "Vorgeschlagene Schlüsselwörter",
  recentSearches: "Vor kurzem verwendete Suchen",
  trendingSearches: "Trendsuchen",
  suggestionsResult: "{{ count }} Vorschlag und ",
  suggestionsResult_other: "{{ count }} Vorschläge und ",
  products: "Empfohlene Produkte",
  productsResult: "{{ count }} Produkt ist verfügbar.",
  productsResult_other: "{{ count }} Produkte sind verfügbar.",
  resetLabel: "Suche zurücksetzen",
  help: {
    insufficientChars: "Geben Sie mehr Zeichen ein",
    noMatch: "Keine Ergebnisse gefunden",
    exactMatch: "{{ term }}",
    empty: "Stellen Sie uns eine Frage"
  },
  closeSearchPanel: "Schließen"
};
var sorting$f = {
  date: "Datum",
  orderNumber: "Bestellnummer",
  rma: "Rücksendungsnummer",
  replenishmentNumber: "Nachbestellnummer",
  nextOrderDate: "Nächstes Bestelldatum",
  pageViewUpdated: "Seitenansicht wurde mit Ihren ausgewählten Optionen aktualisiert."
};
var httpHandlers$f = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Melden Sie sich erneut an.",
    user_is_disabled: "{{ errorMessage }}. Bitte wenden Sie sich an die Administration."
  },
  badGateway: "Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Melden Sie sich erneut an.",
  badRequestOldPasswordIncorrect: "Das alte Kennwort ist falsch.",
  badRequestGuestDuplicateEmail: "E-Mail {{ errorMessage }} ist schon vorhanden. Bezahlen Sie mit einer anderen E-Mail, um sich mit einem Gastkonto zu registrieren.",
  conflict: "Schon vorhanden.",
  forbidden: "Sie haben keine Berechtigung für diese Aktion. Wenn Sie denken, dass es sich hierbei um einen Fehler handelt, wenden Sie sich an Ihren Administrator.",
  gatewayTimeout: "Der Server reagiert nicht, versuchen Sie es später erneut.",
  internalServerError: "Ein interner Serverfehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.",
  sessionExpired: "Ihre Sitzung ist abgelaufen. Melden Sie sich erneut an.",
  unknownError: "Es ist ein unbekannter Fehler aufgetreten",
  unauthorized: {
    common: "Es ist ein unbekannter Berechtigungsfehler aufgetreten",
    invalid_client: "Falsche Client-Anmeldedaten"
  },
  validationErrors: {
    missing: {
      card_cardType: "Die ausgewählte Kreditkarte wird nicht unterstützt. Wählen Sie eine andere aus.",
      card_accountNumber: "Die eingegebene Kreditkartennummer ist nicht gültig.",
      card_cvNumber: "Der eingegebene Sicherheitscode ist nicht gültig.",
      card_expirationMonth: "Das eingegebene Ablaufdatum der Kreditkarte ist nicht gültig.",
      card_expirationYear: "Das eingegebene Ablaufdatum der Kreditkarte ist nicht gültig.",
      billTo_firstName: "Der eingegebene Vorname ist nicht gültig.",
      billTo_lastName: "Der eingegebene Nachname ist nicht gültig.",
      billTo_street1: "Die eingegebene Adresse ist nicht gültig.",
      billTo_street2: "Die eingegebene Adresse ist nicht gültig.",
      billTo_city: "Der eingegebene Ort ist für diese Kreditkarte nicht gültig.",
      billTo_state: "Die Eingabe für Bundesland/Kanton ist für diese Kreditkarte nicht gültig.",
      billTo_country: "Die Eingabe für Land/Region ist für diese Kreditkarte nicht gültig.",
      billTo_postalCode: "Die eingegebene Postleitzahl ist für diese Kreditkarte nicht gültig.",
      country: {
        isocode: "Land/Region fehlt"
      }
    },
    invalid: {
      card_expirationMonth: "Das eingegebene Ablaufdatum der Kreditkarte ist nicht gültig.",
      firstName: "Eingegebener Vorname ist nicht gültig.",
      lastName: "Eingegebener Nachname ist nicht gültig.",
      password: "Eingegebenes Kennwort ist nicht gültig.",
      uid: "UID ist nicht gültig.",
      code: "Code ist nicht gültig.",
      email: "E-Mail ist nicht gültig.",
      loginId: "E-Mail ist nicht gültig."
    }
  },
  cartNotFound: "Warenkorb nicht gefunden.",
  invalidCodeProvided: "Angegebener Code ist ungültig.",
  voucherExceeded: "Die Anzahl der Verwendungen wurde für diesen Coupon überschritten",
  unknownIdentifier: "Position nicht gefunden.",
  otherCartErrors: "Warenkorbfehler aufgetreten."
};
var miniCart$f = {
  item: "Ihr Warenkorb enthält zurzeit {{count}} Position",
  item_other: "Ihr Warenkorb enthält zurzeit {{count}} Positionen",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$f = {
  skipTo: "Springen zu",
  labels: {
    header: "Kopfzeile",
    main: "Hauptinhalt",
    footer: "Fußzeile",
    productFacets: "Produktfacetten",
    productList: "Produktliste"
  }
};
var carousel$f = {
  previousSlide: "Vorherige Folie",
  nextSlide: "Nächste Folie",
  slideNumber: "Folie {{currentSlideNumber}} enthält {{numberOfSlides}} Positionen",
  carouselForProduct: "Karussel, Bilder für {{product}}"
};
var formErrors$f = {
  labeled: {
    required: "Feld {{label}} ist erforderlich",
    cxInvalidEmail: "Feld {{label}} hat kein gültiges E-Mail-Format. Es muss dem folgenden Muster entsprechen: beispiel@ihredomäne.com",
    cxInvalidPassword: "Feld {{label}} hat kein gültiges Kennwortformat",
    cxMinOneUpperCaseCharacter: "Kennwort muss mindestens einen Großbuchstaben enthalten",
    cxMinOneDigit: "Kennwort muss mindestens eine Ziffer enthalten.",
    cxMinOneSpecialCharacter: "Kennwort muss mindestens ein Sonderzeichen enthalten",
    cxMinSixCharactersLength: "Kennwort muss mindestens 6 Zeichen enthalten",
    cxMinEightCharactersLength: "Kennwort muss mindestens 8 Zeichen enthalten",
    cxMaxCharactersLength: "Kennwort darf nicht mehr als 128 Zeichen haben",
    cxContainsSpecialCharacters: "Kennwort darf keine Sonderzeichen enthalten",
    cxNoConsecutiveCharacters: "Kennwort darf keine aufeinanderfolgende identische Zeichen enthalten",
    date: {
      required: "Feld {{label}} ist erforderlich",
      min: "Feld {{label}} darf nicht vor dem {{min}} sein",
      max: "Feld {{label}} darf nicht nach dem {{max}} sein",
      pattern: "Verwenden Sie im Feld {{label}} das Datumsformat yyyy-mm-dd",
      invalid: "Verwenden Sie ein gültiges Datum im Feld {{label}}"
    }
  },
  globalMessage: "Das Formular, das Sie absenden möchten, enthält Fehler.",
  required: "Dieses Feld ist erforderlich",
  cxInvalidEmail: "Dies ist kein gültiges E-Mail-Format",
  cxInvalidPassword: "Dies ist kein gültiges Kennwortformat",
  cxPasswordsMustMatch: "Kennwortfelder müssen übereinstimmen",
  cxPasswordsCannotMatch: "Kennwortfelder dürfen nicht übereinstimmen",
  cxEmailsMustMatch: "E-Mail-Felder müssen übereinstimmen",
  cxStarRatingEmpty: "Bewertungsfeld ist erforderlich",
  cxNoSelectedItemToCancel: "Wählen Sie mindestens eine Position aus",
  cxNegativeAmount: "Betrag muss größer als oder gleich Null sein",
  cxContainsSpecialCharacters: "Feld darf keine Sonderzeichen enthalten.",
  date: {
    required: "Dieses Feld ist erforderlich",
    min: "Datum darf nicht vor dem {{min}} sein",
    max: "Datum darf nicht nach dem {{max}} sein",
    pattern: "Verwenden Sie das Datumsformat yyyy-mm-dd",
    invalid: "Verwenden Sie ein gültiges Datum"
  },
  file: {
    required: "Datei ist erforderlich",
    empty: "Datei darf nicht leer sein",
    invalidExtension: "Dateierweiterung ist nicht gültig",
    tooLarge: "Dateigröße darf {{ maxSize }} MB nicht überschreiten",
    tooManyEntries: "Die Anzahl der Positionen ist größer als {{ maxEntries }}",
    notParsable: "Datei kann nicht geparst werden",
    fileNotAllowed: "Dieser Dateityp ist nicht zulässig"
  }
};
var errorHandlers$f = {
  scriptFailedToLoad: "Laden des Skripts ist fehlgeschlagen.",
  refreshThePage: "Aktualisieren Sie die Seite."
};
var assistiveMessage$f = {
  actionCancelled: "Aktion abgebrochen, nichts geändert"
};
var passwordVisibility$f = {
  showPassword: "Kennwort anzeigen",
  hidePassword: "Kennwort ausblenden"
};
var generalErrors$f = {
  pageFailure: "Die Seite konnte nicht geladen werden. Versuchen Sie es später erneut."
};
var formLegend$f = {
  required: "Die folgenden mit einem * gekennzeichneten Felder sind erforderlich."
};
var chatMessaging$f = {
  charactersLeft: "Zeichen übrig: {{count}}",
  addNewMessage: "Neue Nachricht hinzufügen",
  send: "Senden",
  uploadFile: "Datei hochladen",
  informationLabel: "{{author}}. {{text}} am {{date}}",
  messages: "Nachrichten",
  addMessagePlaceHolder: "Mit Eingabe beginnen...",
  characterLimitAlert: "Maximale Anzahl von Zeichen erreicht.",
  a11y: {
    itemListBoxLabel: "Wählen Sie die Position aus, auf die die Nachricht angewendet werden soll."
  }
};
var common$v = {
  common: common$u,
  pageMetaResolver: pageMetaResolver$f,
  spinner: spinner$f,
  navigation: navigation$f,
  searchBox: searchBox$f,
  sorting: sorting$f,
  httpHandlers: httpHandlers$f,
  miniCart: miniCart$f,
  skipLink: skipLink$f,
  carousel: carousel$f,
  formErrors: formErrors$f,
  errorHandlers: errorHandlers$f,
  assistiveMessage: assistiveMessage$f,
  passwordVisibility: passwordVisibility$f,
  generalErrors: generalErrors$f,
  formLegend: formLegend$f,
  chatMessaging: chatMessaging$f
};
var setDeliveryMode$f = {
  unknownError: "Es ist ein unbekannter Fehler aufgetreten. Wenden Sie sich an den Support."
};
var deliveryMode$f = {
  setDeliveryMode: setDeliveryMode$f
};
var myAccountV2Consent$u = {
  header: "Einwilligungsmanagement",
  dateDescription: "Genehmigt am ",
  clearAll: "Alle deaktivieren",
  selectAll: "Alle aktivieren",
  message: {
    success: {
      given: "Einwilligung wurde erfolgreich erteilt.",
      withdrawn: "Einwilligung wurde erfolgreich widerrufen."
    }
  }
};
var myAccountV2Consent$v = {
  myAccountV2Consent: myAccountV2Consent$u
};
var myAccountV2NotificationPreference$u = {
  header: "Benachrichtigungskanäle",
  message: "Wählen Sie Ihre bevorzugten Benachrichtigungskanäle",
  note: "Hinweis: ",
  noteMessage: "Wenn Sie alle Kanäle deaktivieren, können Sie keine weiteren Benachrichtigungen mehr empfangen.",
  EMAIL: "E-Mail:"
};
var myAccountV2NotificationPreference$v = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$u
};
var closeAccount$f = {
  confirmAccountClosure: "Kontoauflösung bestätigen",
  confirmAccountClosureMessage: "Möchten Sie Ihr Konto wirklich auflösen?",
  closeMyAccount: "MEIN KONTO AUFLÖSEN",
  accountClosedSuccessfully: "Konto wurde erfolgreich aufgelöst",
  accountClosedFailure: "Auflösen des Kontos fehlgeschlagen"
};
var updatePasswordForm$f = {
  oldPassword: {
    label: "Altes Kennwort",
    placeholder: "Altes Kennwort"
  },
  oldPasswordIsRequired: "Altes Kennwort ist erforderlich.",
  newPassword: {
    label: "Neues Kennwort",
    placeholder: "Neues Kennwort"
  },
  passwordMinRequirements: "Hier eine schnelle Anleitung für ein sicheres Kennwort: Verwenden Sie mindestens 8 Zeichen mit einer Mischung aus Groß- und Kleinbuchstaben, Ziffern und Sonderzeichen.",
  passwordStrengthRequirements: "Hier eine schnelle Anleitung für ein sicheres Kennwort: Verwenden Sie mindestens 8 Zeichen mit einer Mischung aus Groß- und Kleinbuchstaben, Ziffern und Sonderzeichen. Stellen Sie sicher, dass Sie ein Zeichen nicht mehrmals hintereinander verwenden.",
  confirmPassword: {
    label: "Neues Kennwort bestätigen",
    placeholder: "Kennwort bestätigen"
  },
  bothPasswordMustMatch: "Beide Kennwörter müssen übereinstimmen",
  passwordUpdateSuccess: "Kennwort wurde erfolgreich aktualisiert",
  accessDeniedError: "Zugriff verweigert"
};
var updateProfileForm$f = {
  title: "Anrede",
  none: "",
  firstName: {
    label: "Vorname",
    placeholder: "Vorname"
  },
  firstNameIsRequired: "Vorname ist erforderlich.",
  lastName: {
    label: "Nachname",
    placeholder: "Nachname"
  },
  lastNameIsRequired: "Nachname ist erforderlich.",
  profileUpdateSuccess: "Persönliche Details wurden erfolgreich aktualisiert",
  customerId: "Kundennummer"
};
var consentManagementForm$f = {
  clearAll: "Auswahl für alle aufheben",
  selectAll: "Alle auswählen",
  message: {
    success: {
      given: "Einwilligung wurde erfolgreich erteilt.",
      withdrawn: "Einwilligung wurde erfolgreich widerrufen."
    }
  }
};
var myCoupons$f = {
  noCouponsMessage: "Sie haben keine verfügbaren Coupons.",
  effectiveTitle: "Gültig:",
  Effective: "GÜLTIG",
  PreSession: "BALD GÜLTIG",
  ExpireSoon: "LÄUFT BALD AB",
  readMore: "Mehr lesen",
  notification: "Benachrichtigung",
  findProducts: "Produkte suchen",
  status: "Status:",
  dialogTitle: "Coupon",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Couponcode",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Sie haben diesen Coupon erfolgreich eingelöst.",
  myCoupons: "Meine Coupons",
  startDateAsc: "Startdatum (aufsteigend)",
  startDateDesc: "Startdatum (absteigend)",
  endDateAsc: "Enddatum (aufsteigend)",
  endDateDesc: "Enddatum (absteigend)",
  sortBy: "Sortieren nach",
  sortCoupons: "Coupons sortieren",
  notesPreffix: "Sie können Ihre bevorzugten Kanäle für den Erhalt von Couponbenachrichtigungen auf der folgenden Seite festlegen: ",
  notesLink: "Benachrichtigungskanäle",
  notesSuffix: " ."
};
var notificationPreference$f = {
  message: "Wählen Sie Ihre bevorzugten Benachrichtigungskanäle",
  note: "Hinweis: ",
  noteMessage: "Wenn Sie alle Kanäle deaktivieren, können Sie keine weiteren Benachrichtigungen mehr empfangen.",
  EMAIL: "E-Mail:",
  SMS: "SMS:",
  SITE_MESSAGE: "Site-Nachricht"
};
var myInterests$f = {
  header: "Meine Interessen",
  item: "POSITION",
  price: "PREIS",
  notifications: "BENACHRICHTIGUNGEN",
  noInterests: "Sie haben noch keine Interessen erfasst.",
  inStock: "Vorrätig",
  lowStock: "Niederbestand",
  outOfStock: "Nicht vorrätig",
  BACK_IN_STOCK: "Wieder auf Lager",
  sortBy: "Sortieren nach",
  sortInterests: "Interessen sortieren",
  expirationDate: " - bis {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Entfernen",
  sorting: {
    byNameAsc: "Name (aufsteigend)",
    byNameDesc: "Name (absteigend)"
  },
  actions: "AKTIONEN",
  caption: "Meine Interessen - Inhalte.",
  itemRemoved: "Ausgewählte Position wurde entfernt."
};
var myAccount$f = {
  closeAccount: closeAccount$f,
  updatePasswordForm: updatePasswordForm$f,
  updateProfileForm: updateProfileForm$f,
  consentManagementForm: consentManagementForm$f,
  myCoupons: myCoupons$f,
  notificationPreference: notificationPreference$f,
  myInterests: myInterests$f
};
var paymentForm$f = {
  payment: "Zahlung",
  choosePaymentMethod: "Eine Zahlungsart auswählen",
  paymentType: "Zahlungstyp",
  accountHolderName: {
    label: "Name des Kontoinhabers",
    placeholder: "Name des Kontoinhabers"
  },
  cardNumber: "Kartennummer",
  expirationDate: "Ablaufdatum",
  securityCode: "Sicherheitscode (CVV)",
  securityCodeTitle: "Kartenprüfwert",
  saveAsDefault: "Als Standard speichern",
  setAsDefault: "Als Standardzahlungsart festlegen",
  billingAddress: "Rechnungsadresse",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Mit Lieferadresse identisch",
  billingAddressSameAsShipping: "Rechnungsadresse stimmt mit Lieferadresse überein",
  selectOne: "Treffen Sie eine Auswahl...",
  monthMask: "MM",
  yearMask: "JJJJ",
  expirationYear: "Ablaufjahr {{ selected }}",
  expirationMonth: "Ablaufmonat {{ selected }}",
  useThisPayment: "Diese Zahlung verwenden",
  addNewPayment: "Neue Zahlung hinzufügen",
  paymentAddedSuccessfully: "Neue Zahlung wurde erfolgreich hinzugefügt",
  changePayment: "Zahlung ändern"
};
var paymentMethods$f = {
  paymentMethods: "Zahlungsarten",
  paymentMethodSelected: "Ausgewählte Zahlungsart",
  newPaymentMethodsAreAddedDuringCheckout: "Neue Zahlungsarten werden beim Bezahlen hinzugefügt.",
  invalidField: "Ungültiges Feld: {{ field }}"
};
var paymentCard$f = {
  deleteConfirmation: "Möchten Sie diese Zahlungsart wirklich löschen?",
  setAsDefault: "Als Standard festlegen",
  expires: "Gültig bis: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ STANDARD",
  defaultPaymentLabel: "Standardzahlungsart",
  additionalPaymentLabel: "Zusätzliche Zahlungsart {{ number }}",
  selected: "Ausgewählt",
  selectedPayment: "Ausgewählte Zahlung",
  deletePaymentSuccess: "Zahlungsart erfolgreich gelöscht",
  visa: "Visa-Karte",
  master: "Mastercard-Karte",
  masterEuro: "Mastercard-Eurocard-Karte",
  dinersClub: "Diners-Club-Karte",
  "switch": "Switch-Karte",
  maestro: "Maestro-Karte",
  amex: "American-Express-Karte",
  credit: "Kreditkarte"
};
var paymentTypes$f = {
  title: "Zahlungsart",
  paymentType_CARD: "Kreditkarte",
  paymentType_ACCOUNT: "Konto"
};
var paymentMessages$f = {
  setAsDefaultSuccessfully: "Neue Zahlung wurde erfolgreich als Standard festgelegt"
};
var payment$f = {
  paymentForm: paymentForm$f,
  paymentMethods: paymentMethods$f,
  paymentCard: paymentCard$f,
  paymentTypes: paymentTypes$f,
  paymentMessages: paymentMessages$f
};
var pdf$u = {
  defaultTitle: "Beleg"
};
var pdf$v = {
  pdf: pdf$u
};
var productDetails$f = {
  id: "ID",
  quantity: "Menge",
  quantityFull: "Menge",
  productDetails: "Produktdetails",
  specification: "Spezifikationen",
  reviews: "Rezensionen",
  shipping: "Versand",
  share: "Teilen",
  showReviews: "Rezensionen anzeigen",
  noReviews: "Noch keine Rezensionen",
  productPrice: "Produktpreis",
  noProductImage: "Kein Bild verfügbar, {{ product }}"
};
var productList$f = {
  filterBy: {
    label: "Filtern nach",
    action: "Filtern nach"
  },
  activeFilter: "Filter {{filter}}, durch Klicken auf diese Schaltfläche wird der Filter entfernt",
  appliedFilter: "Angewendeter Filter:",
  showLess: "Weniger anzeigen...",
  showMore: "Mehr anzeigen...",
  sortBy: "Sortieren nach",
  sortResults: "Ergebnisse sortieren",
  backToTopBtn: "ZUM ANFANG",
  showMoreBtn: "MEHR ANZEIGEN",
  productSearchPagination: "Paginierung der Produktsuche",
  productListResults: "Produktergebnisliste"
};
var productFacetNavigation$f = {
  filterBy: {
    label: "Filtern nach",
    action: "Filtern nach",
    facet: "Ergebnisse nach Facetten filtern",
    name: "Filtern nach {{name}}"
  },
  appliedFilter: "Angewendeter Filter:",
  showLess: "Weniger anzeigen...",
  showMore: "Mehr anzeigen...",
  sortBy: "Sortieren nach",
  ariaLabelShowLess: "Weniger anzeigen, Schaltfläche, durch Klicken auf diese Schaltfläche werden weniger Optionen für die aktive Gruppe angezeigt",
  ariaLabelShowMore: "Mehr anzeigen, Schaltfläche, durch Klicken auf diese Schaltfläche werden alle Optionen für die aktive Gruppe angezeigt",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} Position verfügbar",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} Positionen verfügbar",
  decreaseOptionsVisibility: "Optionen wurden aus der aktiven Gruppe ausgeblendet, Tabulatortaste rückwärts, um sie zu lesen, oder Tabulatortaste vorwärts für die nächste Gruppe",
  increaseOptionsVisibility: "Der aktiven Gruppe wurden mehr Optionen hinzugefügt, Tabulatortaste rückwärts, um sie zu lesen, oder Tabulatortaste vorwärts für die nächste Gruppe",
  backToResults: "Zurück zu den Ergebnissen",
  productFacets: "Produktfacetten"
};
var productSummary$f = {
  id: "ID",
  showReviews: "Rezensionen anzeigen",
  showReviewsDetailed: "{{count}} Rezensionen anzeigen, mit {{rating}} von 5 Sternen bewertet",
  share: "Teilen",
  newItemPrice: "Neuer Positionspreis"
};
var productReview$f = {
  overallRating: "Gesamtbewertung",
  reviewTitle: "Überschrift ihrer Rezension",
  writeYourComments: "Geben Sie Ihre Kommentare ein",
  rating: "Bewertung",
  ratingRequired: "Produktbewertung, erforderlich",
  addRate: "Bewertung hinzufügen: {{count}} Stern",
  addRate_other: "Bewertung hinzufügen: {{count}} Sterne",
  reviewerName: "Name des Rezensenten (optional)",
  writeReview: "Rezension schreiben",
  more: "Mehr Rezensionen anzeigen",
  less: "Weniger Rezensionen anzeigen",
  thankYouForReview: "Vielen Dank für die Rezension! Beachten Sie, dass Rezensionen manchmal geprüft werden müssen, bevor sie hier angezeigt werden.",
  postReviewFail: "Bei der Veröffentlichung Ihrer Bewertung ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
  ratedOutOf: "Bewertet: {{rating}} von 5"
};
var productCarousel$f = {
  carouselLabel: "Karussell, {{title}}"
};
var addToCart$f = {
  itemAddedToYourCart: "Position wurde Ihrem Warenkorb hinzugefügt",
  itemsAddedToYourCart: "Position(en) wurde(n) Ihrem Warenkorb hinzugefügt",
  itemsIncrementedInYourCart: "Diese Position war schon in Ihrem Warenkorb. Die Menge wurde aktualisiert.",
  items: "Positionen",
  updatingCart: "Warenkorb wird aktualisiert...",
  addToCart: "In den Warenkorb",
  viewCart: "Warenkorb anzeigen",
  proceedToCheckout: "zur Kasse gehen",
  quantity: "Menge",
  quantityFull: "Menge",
  outOfStock: "Nicht vorrätig",
  inStock: "Vorrätig",
  selectStyleAndSize: "Stil und Größe zum Prüfen des Bestands auswählen",
  removeFromCart: "Produkt aus Warenkorb entfernen",
  closeModal: "Modalen Dialog schließen",
  buyItAgain: "Erneut kaufen",
  addToActiveCart: "In den aktiven Warenkorb"
};
var TabPanelContainer$f = {
  tabs: {
    ProductDetailsTabComponent: "Produktdetails",
    ProductSpecsTabComponent: "Spezifikationen",
    ProductReviewsTabComponent: "Rezensionen",
    deliveryTab: "Versand",
    SparePartsTabComponent: " Ersatzteile"
  },
  tabPanelContainerRegion: "Registerkartengruppe mit weiteren Produktdetails",
  tabPanelContainerRegionGroup: "Gruppe mit weiteren Produktdetails"
};
var addToWishList$f = {
  add: "Dem Wunschzettel hinzufügen",
  remove: "Aus Wunschliste entfernen",
  anonymous: "Zum Hinzufügen zur Wunschliste anmelden",
  addedToWishList: "Produkt wurde der Wunschliste hinzugefügt",
  removedFromWishList: "Produkt wurde aus Wunschliste entfernt"
};
var stockNotification$f = {
  notifyMe: "MICH BENACHRICHTIGEN",
  stopNotify: "BENACHRICHTIGUNG BEENDEN",
  getNotify: "Lassen Sie sich benachrichtigen, wenn dieses Produkt verfügbar ist.",
  getNotifySuffix: "um benachrichtigt zu werden, wenn dieses Produkt verfügbar ist.",
  activateChannelsPrefix: "Um benachrichtigt zu werden, müssen Sie Folgendes aktivieren: ",
  channelsLink: "Benachrichtigungskanäle",
  activateChannelsSuffix: ".",
  notified: "Sie werden benachrichtigt, wenn dieses Produkt wieder auf Lager ist.",
  getNotified: "Lassen Sie sich benachrichtigen, wenn dieses Produkt wieder auf Lager ist.",
  unsubscribeSuccess: "Sie erhalten für dieses Produkt keine \"Wieder auf Lager\"-Benachrichtigungen.",
  subscriptionDialog: {
    header: "\"Nicht vorrätig\"-Abonnement",
    notifiedPrefix: "Sie werden benachrichtigt:",
    notifiedSuffix: "sobald das Produkt wieder auf Lager ist.",
    manageChannelsPrefix: "Verwalten Sie Ihre bevorzugten Benachrichtigungskanäle auf der Seite ",
    manageChannelsLink: "Benachrichtigungseinstellung",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "Sie können Ihre Abonnements auf der folgenden Seite verwalten: ",
    manageSubscriptionsLink: "Meine Interessen",
    manageSubscriptionsSuffix: " .",
    okBtn: "OK",
    subscribing: "Abonnement für \"Nicht vorrätig\"-Benachrichtigungen für dieses Produkt"
  }
};
var itemCounter$f = {
  removeOne: "1 entfernen",
  addOneMore: "Noch 1 hinzufügen",
  quantity: "Menge"
};
var productView$f = {
  gridView: "Auswählen, um zur Rasteransicht zu wechseln",
  listView: "Auswählen, um zur Listenansicht zu wechseln"
};
var product$f = {
  productDetails: productDetails$f,
  productList: productList$f,
  productFacetNavigation: productFacetNavigation$f,
  productSummary: productSummary$f,
  productReview: productReview$f,
  productCarousel: productCarousel$f,
  addToCart: addToCart$f,
  TabPanelContainer: TabPanelContainer$f,
  addToWishList: addToWishList$f,
  stockNotification: stockNotification$f,
  itemCounter: itemCounter$f,
  productView: productView$f
};
var pwa$u = {
  addToHomeScreenDescription: "Fügen Sie SAP Storefront der Startseite Ihres Geräts hinzu, um einen schnelleren Aufruf zu ermöglichen",
  noInstallationNeeded: "Keine Installation erforderlich",
  fastAccessToApplication: "Schnellzugriff auf die Anwendung",
  addToHomeScreen: "Zur Startseite hinzufügen",
  addedToHomeScreen: "SAP Storefront wurde Ihrer Startseite hinzugefügt"
};
var pwa$v = {
  pwa: pwa$u
};
var siteThemeSwitcher$u = {
  theme: "Theme",
  themes: {
    "default": "Standard",
    highContrastDark: "HC-Dark",
    highContrastLight: "HC-Light"
  }
};
var siteThemeSwitcher$v = {
  siteThemeSwitcher: siteThemeSwitcher$u
};
var anonymousConsents$f = {
  dialog: {
    title: "Einwilligungsmanagement",
    legalDescription: "Wir verwenden Cookies/den Browserspeicher, um die Inhalte zu personalisieren und die Benutzerfreundlichkeit zu verbessern. Außerdem geben wir möglicherweise die Daten zu Ihrer Verwendung unserer Website an unsere Social Media weiter. Weitere Informationen finden Sie in unserer Datenschutzerklärung.",
    selectAll: "Alle auswählen",
    clearAll: "Auswahl für alle aufheben"
  },
  banner: {
    title: "Diese Website verwendet Cookies",
    description: "Wir verwenden Cookies/den Browserspeicher, um die Inhalte zu personalisieren und die Benutzerfreundlichkeit zu verbessern.",
    allowAll: "Alle erlauben",
    viewDetails: "Details anzeigen",
    consentManagement: "Einwilligungsmanagement"
  }
};
var checkoutLogin$f = {
  emailAddress: {
    label: "E-Mail-Adresse",
    placeholder: "E-Mail eingeben"
  },
  confirmEmail: {
    label: "E-Mail bestätigen",
    placeholder: "E-Mail bestätigen"
  },
  "continue": "Weiter",
  emailIsRequired: "Ungültiges E-Mail-Format",
  emailsMustMatch: "E-Mail stimmt nicht überein"
};
var authMessages$f = {
  signedOutSuccessfully: "Sie haben sich erfolgreich abgemeldet."
};
var user$f = {
  anonymousConsents: anonymousConsents$f,
  checkoutLogin: checkoutLogin$f,
  authMessages: authMessages$f
};
var player$f = {
  label: "Videoplayer"
};
var video$f = {
  player: player$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  common: common$v,
  deliveryMode: deliveryMode$f,
  myAccountV2Consent: myAccountV2Consent$v,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$v,
  myAccount: myAccount$f,
  payment: payment$f,
  pdf: pdf$v,
  product: product$f,
  pwa: pwa$v,
  siteThemeSwitcher: siteThemeSwitcher$v,
  user: user$f,
  video: video$f
};
var common$s = {
  cancel: "Cancel",
  "delete": "Delete",
  remove: "Remove",
  edit: "Edit",
  restore: "Restore",
  back: "Back",
  submit: "Submit",
  "continue": "Continue",
  save: "Save",
  done: "Done",
  home: "Home",
  noData: "No data.",
  confirm: "Confirm",
  more: "more",
  close: "Close",
  loading: "Loading",
  menu: "Menu",
  reset: "Reset",
  search: "Search",
  add: "Add",
  breadcrumbs: "breadcrumbs",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Select file",
  clear: "Clear",
  loaded: "Loaded",
  results: "Results",
  of: "of",
  ngSelectDropdownOptionsList: "Options",
  required: "required",
  zoomIn: "Zoom in",
  zoomOut: "Zoom out",
  selected: "Selected",
  expand: "Expand",
  collapse: "Collapse"
};
var pageMetaResolver$e = {
  category: {
    title: "{{count}} result for {{query}}",
    title_other: "{{count}} results for {{query}}"
  },
  checkout: {
    title: "Checkout"
  },
  search: {
    title: "{{count}} result for \"{{query}}\"",
    title_other: "{{count}} results for \"{{query}}\"",
    findProductTitle: "{{count}} result for coupon \"{{coupon}}\"",
    findProductTitle_other: "{{count}} results for coupon \"{{coupon}}\"",
    default_title: "All products"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$e = {
  loading: "Loading..."
};
var navigation$e = {
  categoryNavLabel: "Category menu",
  footerNavLabel: "Footer links",
  goTo: "Go to {{location}}",
  navigateTo: "Navigate to {{nav}}",
  scrollToTop: "Scroll back to the top of the page",
  linkItemInList: "{{title}}. {{position}} of {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$e = {
  placeholder: "Enter product name or SKU",
  productSearch: "Find a product",
  ariaLabelInput: "Search here...",
  ariaLabelSuggestions: "typing suggestions",
  ariaLabelProducts: "product results",
  initialDescription: "When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.",
  suggestions: "Suggested Keywords",
  recentSearches: "Recent Searches",
  trendingSearches: "Trending Searches",
  suggestionsResult: "{{ count }} suggestion and ",
  suggestionsResult_other: "{{ count }} suggestions and ",
  products: "Recommended Products",
  productsResult: "{{ count }} product is available.",
  productsResult_other: "{{ count }} products are available.",
  resetLabel: "Reset Search",
  help: {
    insufficientChars: "Please type more characters",
    noMatch: "We could not find any results",
    exactMatch: "{{ term }}",
    empty: "Ask us anything"
  },
  closeSearchPanel: "Close"
};
var sorting$e = {
  date: "Date",
  orderNumber: "Order Number",
  rma: "Return Number",
  replenishmentNumber: "Replenishment Number",
  nextOrderDate: "Next Order Date",
  pageViewUpdated: "Page view updated with your selected options."
};
var httpHandlers$e = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Please login again.",
    user_is_disabled: "{{ errorMessage }}. Please contact administration."
  },
  badGateway: "A server error occurred. Please try again later.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Please login again.",
  badRequestOldPasswordIncorrect: "Old password incorrect.",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} email already exist. Please checkout with a different email to register using a guest account.",
  conflict: "Already exists.",
  forbidden: "You are not authorized to perform this action. Please contact your administrator if you think this is a mistake.",
  gatewayTimeout: "The server did not respond, please try again later.",
  internalServerError: "An Internal Server Error occurred. Please try again later.",
  sessionExpired: "Your session has expired. Please login again.",
  unknownError: "An unknown error occurred",
  unauthorized: {
    common: "An unknown authorization error occured",
    invalid_client: "Bad client credentials"
  },
  validationErrors: {
    missing: {
      card_cardType: "The selected credit card is not supported. Please select another.",
      card_accountNumber: "The credit card number entered is not valid.",
      card_cvNumber: "The security code entered is not valid.",
      card_expirationMonth: "The credit card expiration date entered is not valid.",
      card_expirationYear: "The credit card expiration date entered is not valid.",
      billTo_firstName: "The first name entered is not valid.",
      billTo_lastName: "The last name entered is not valid.",
      billTo_street1: "The address entered is not valid.",
      billTo_street2: "The address entered is not valid.",
      billTo_city: "The city entered is not valid for this credit card.",
      billTo_state: "The state/province entered is not valid for this credit card.",
      billTo_country: "The country/region entered is not valid for this credit card.",
      billTo_postalCode: "The zip/postal code is not valid for this credit card.",
      country: {
        isocode: "Missing country/region"
      }
    },
    invalid: {
      card_expirationMonth: "The credit card expiration date entered is not valid.",
      firstName: "First Name entered is not valid.",
      lastName: "Last Name entered is not valid.",
      password: "Password entered is not valid.",
      uid: "UID is not valid.",
      code: "Code is not valid.",
      email: "Email is not valid.",
      loginId: "Email is not valid."
    }
  },
  cartNotFound: "Cart not found.",
  invalidCodeProvided: "Invalid code provided.",
  voucherExceeded: "This coupon has exceeded the number of times it can be used",
  unknownIdentifier: "Item not found.",
  otherCartErrors: "Cart errors occurred."
};
var miniCart$e = {
  item: "{{count}} item currently in your cart",
  item_other: "{{count}} items currently in your cart",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$e = {
  skipTo: "Skip to",
  labels: {
    header: "Header",
    main: "Main Content",
    footer: "Footer",
    productFacets: "Product Facets",
    productList: "Product List"
  }
};
var carousel$e = {
  previousSlide: "Previous slide",
  nextSlide: "Next slide",
  slideNumber: "Slide {{currentSlideNumber}} contains {{numberOfSlides}} items",
  carouselForProduct: "Carousel, Images for {{product}}"
};
var formErrors$e = {
  labeled: {
    required: "Field {{label}} is required",
    cxInvalidEmail: "Field {{label}} has not a valid email format. Match pattern: example@yourdomain.com",
    cxInvalidPassword: "Field {{label}} has not a valid password format",
    cxMinOneUpperCaseCharacter: "Password must contain at least one uppercase character",
    cxMinOneDigit: "Password must contain at least one digit",
    cxMinOneSpecialCharacter: "Password must contain at least one special character",
    cxMinSixCharactersLength: "Password must contain at least 6 characters",
    cxMinEightCharactersLength: "Password must contain at least 8 characters",
    cxMaxCharactersLength: "Password cannot have more than 128 characters",
    cxContainsSpecialCharacters: "Password cannot contain special characters",
    cxNoConsecutiveCharacters: "Password cannot contain consecutive identical characters",
    invalidTokenCodeError: "This code is not valid",
    date: {
      required: "Field {{label}} is required",
      min: "Field {{label}} cannot be before {{min}}",
      max: "Field {{label}} cannot be after {{max}}",
      pattern: "Use dateformat yyyy-mm-dd in field {{label}}",
      invalid: "Use a valid date in field {{label}}"
    }
  },
  globalMessage: "The form you are trying to submit contains errors.",
  required: "This field is required",
  cxInvalidEmail: "This is not a valid email format",
  cxInvalidPassword: "This is not a valid password format",
  cxPasswordsMustMatch: "Password fields must match",
  cxPasswordsCannotMatch: "Password fields cannot match",
  cxEmailsMustMatch: "Email fields must match",
  cxStarRatingEmpty: "Rating field is required",
  cxNoSelectedItemToCancel: "Select at least one item",
  cxNegativeAmount: "Amount must be equal or greater than zero",
  cxContainsSpecialCharacters: "Field cannot contain special characters",
  date: {
    required: "This field is required",
    min: "Date cannot be before {{min}}",
    max: "Date cannot be after {{max}}",
    pattern: "Use dateformat yyyy-mm-dd",
    invalid: "Use a valid date"
  },
  file: {
    required: "File is required",
    empty: "File should not be empty",
    invalidExtension: "File extension is not valid",
    tooLarge: "File size should not exceed {{ maxSize }} MB",
    tooManyEntries: "The number of items is greater than {{ maxEntries }}",
    notParsable: "File is not parsable",
    fileNotAllowed: "This file type is not allowed"
  }
};
var errorHandlers$e = {
  scriptFailedToLoad: "Failed to load the script.",
  refreshThePage: "Please refresh the page."
};
var assistiveMessage$e = {
  actionCancelled: "Action cancelled, nothing changed"
};
var passwordVisibility$e = {
  showPassword: "Show password",
  hidePassword: "Hide password"
};
var generalErrors$e = {
  pageFailure: "The page could not be loaded. Please try again later."
};
var formLegend$e = {
  required: "The following fields marked with \"*\" are required."
};
var chatMessaging$e = {
  charactersLeft: "characters left: {{count}}",
  addNewMessage: "Add New Message",
  send: "Send",
  uploadFile: "Upload File",
  informationLabel: "{{author}}. {{text}} at {{date}}",
  messages: "Messages",
  addMessagePlaceHolder: "Start Typing...",
  characterLimitAlert: "Characters limit reached.",
  a11y: {
    itemListBoxLabel: "Select the item to which the message should apply."
  }
};
var common$t = {
  common: common$s,
  pageMetaResolver: pageMetaResolver$e,
  spinner: spinner$e,
  navigation: navigation$e,
  searchBox: searchBox$e,
  sorting: sorting$e,
  httpHandlers: httpHandlers$e,
  miniCart: miniCart$e,
  skipLink: skipLink$e,
  carousel: carousel$e,
  formErrors: formErrors$e,
  errorHandlers: errorHandlers$e,
  assistiveMessage: assistiveMessage$e,
  passwordVisibility: passwordVisibility$e,
  generalErrors: generalErrors$e,
  formLegend: formLegend$e,
  chatMessaging: chatMessaging$e
};
var setDeliveryMode$e = {
  unknownError: "An unknown error occurred. Please contact support."
};
var deliveryMode$e = {
  setDeliveryMode: setDeliveryMode$e
};
var myAccountV2Consent$s = {
  header: "Consent Management",
  dateDescription: "Approved on ",
  clearAll: "Disable All",
  selectAll: "Enable All",
  message: {
    success: {
      given: "Consent successfully given.",
      withdrawn: "Consent successfully withdrawn."
    }
  }
};
var myAccountV2Consent$t = {
  myAccountV2Consent: myAccountV2Consent$s
};
var myAccountV2NotificationPreference$s = {
  header: "Notification Channels",
  message: "Select your preferred notification channels",
  note: "Note: ",
  noteMessage: "If you deactivate all channels you will not be able to receive any further notifications.",
  EMAIL: "Email:"
};
var myAccountV2NotificationPreference$t = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$s
};
var closeAccount$e = {
  confirmAccountClosure: "Confirm Account Closure",
  confirmAccountClosureMessage: "Are you sure you want to close your account?",
  closeMyAccount: "CLOSE MY ACCOUNT",
  accountClosedSuccessfully: "Account closed with success",
  accountClosedFailure: "Failed to close account"
};
var updatePasswordForm$e = {
  oldPassword: {
    label: "Old Password",
    placeholder: "Old Password"
  },
  oldPasswordIsRequired: "Old password is required.",
  newPassword: {
    label: "New Password",
    placeholder: "New Password"
  },
  passwordMinRequirements: "Here's a quick guide for a secure password: Use at least 8 characters with a mix of upper and lowercase letters, numbers, and symbols.",
  passwordStrengthRequirements: "Here's a quick guide for a secure password: Use at least 8 characters with a mix of upper and lowercase letters, numbers, and symbols. Make sure not to use the same character consecutively.",
  confirmPassword: {
    label: "Confirm New Password",
    placeholder: "Confirm Password"
  },
  bothPasswordMustMatch: "Both password must match",
  passwordUpdateSuccess: "Password updated with success",
  accessDeniedError: "Access is denied"
};
var updateProfileForm$e = {
  title: "Title",
  none: "",
  firstName: {
    label: "First name",
    placeholder: "First name"
  },
  firstNameIsRequired: "First name is required.",
  lastName: {
    label: "Last name",
    placeholder: "Last name"
  },
  lastNameIsRequired: "Last name is required.",
  profileUpdateSuccess: "Personal details successfully updated",
  customerId: "Customer #"
};
var consentManagementForm$e = {
  clearAll: "Clear all",
  selectAll: "Select all",
  message: {
    success: {
      given: "Consent successfully given.",
      withdrawn: "Consent successfully withdrawn."
    }
  }
};
var myCoupons$e = {
  noCouponsMessage: "You have no coupons available.",
  effectiveTitle: "Effective:",
  Effective: "EFFECTIVE",
  PreSession: "EFFECTIVE SOON",
  ExpireSoon: "EXPIRING SOON",
  readMore: "Read more",
  notification: "Notification",
  findProducts: "Find Products",
  status: "Status:",
  dialogTitle: "Coupon",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Coupon Code",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "You have successfully claimed this coupon.",
  myCoupons: "My coupons",
  startDateAsc: "Start Date (ascending)",
  startDateDesc: "Start Date (descending)",
  endDateAsc: "End Date (ascending)",
  endDateDesc: "End Date (descending)",
  sortBy: "Sort by",
  sortCoupons: "Sort coupons",
  notesPreffix: "You can set your preferred channels for receiving coupon notifications on the ",
  notesLink: "Notification Channels",
  notesSuffix: " page."
};
var notificationPreference$e = {
  message: "Select your preferred notification channels",
  note: "Note: ",
  noteMessage: "If you deactivate all channels you will not be able to receive any further notifications.",
  EMAIL: "Email:",
  SMS: "SMS:",
  SITE_MESSAGE: "SiteMessage"
};
var myInterests$e = {
  header: "My Interests",
  item: "ITEM",
  price: "PRICE",
  notifications: "NOTIFICATIONS",
  noInterests: "You have no registered interests yet.",
  inStock: "In Stock",
  lowStock: "Low Stock",
  outOfStock: "Out of Stock",
  BACK_IN_STOCK: "Back In Stock",
  sortBy: "Sort by",
  sortInterests: "Sort interests",
  expirationDate: " - Till {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Remove",
  sorting: {
    byNameAsc: "Name (ascending)",
    byNameDesc: "Name (descending)"
  },
  actions: "ACTIONS",
  caption: "My Interests contents.",
  itemRemoved: "Selected item has been removed."
};
var myAccount$e = {
  closeAccount: closeAccount$e,
  updatePasswordForm: updatePasswordForm$e,
  updateProfileForm: updateProfileForm$e,
  consentManagementForm: consentManagementForm$e,
  myCoupons: myCoupons$e,
  notificationPreference: notificationPreference$e,
  myInterests: myInterests$e
};
var paymentForm$e = {
  payment: "Payment",
  choosePaymentMethod: "Choose a payment method",
  paymentType: "Payment Type",
  accountHolderName: {
    label: "Account Holder Name",
    placeholder: "Account Holder Name"
  },
  cardNumber: "Card Number",
  expirationDate: "Expiration Date",
  securityCode: "Security code (CVV)",
  securityCodeTitle: "Card Verification Value",
  saveAsDefault: "Save as default",
  setAsDefault: "Set as default payment method",
  billingAddress: "Billing address",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Same as delivery address",
  billingAddressSameAsShipping: "Billing address is the same as delivery address",
  selectOne: "Select One...",
  monthMask: "MM",
  yearMask: "YYYY",
  expirationYear: "Expiration year {{ selected }}",
  expirationMonth: "Expiration month {{ selected }}",
  useThisPayment: "Use this payment",
  addNewPayment: "Add New Payment",
  paymentAddedSuccessfully: "New payment was added successfully",
  changePayment: "Change Payment"
};
var paymentMethods$e = {
  paymentMethods: "Payment methods",
  paymentMethodSelected: "Payment method selected",
  newPaymentMethodsAreAddedDuringCheckout: "New payment methods are added during checkout.",
  invalidField: "InvalidField: {{ field }}"
};
var paymentCard$e = {
  deleteConfirmation: "Are you sure you want to delete this payment method?",
  setAsDefault: "Set as default",
  expires: "Expires: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ DEFAULT",
  defaultPaymentLabel: "Default payment method",
  additionalPaymentLabel: "Additional payment method {{ number }}",
  selected: "Selected",
  selectedPayment: "Selected Payment",
  deletePaymentSuccess: "Payment method deleted successfully",
  visa: "Visa Card",
  master: "Mastercard Card",
  masterEuro: "Mastercard Eurocard Card",
  dinersClub: "Diners Club Card",
  "switch": "Switch Card",
  maestro: "Maestro Card",
  amex: "American Express Card",
  credit: "Credit Card"
};
var paymentTypes$e = {
  title: "Payment method",
  paymentType_CARD: "Credit Card",
  paymentType_ACCOUNT: "Account"
};
var paymentMessages$e = {
  setAsDefaultSuccessfully: "New payment was successfully set as default"
};
var payment$e = {
  paymentForm: paymentForm$e,
  paymentMethods: paymentMethods$e,
  paymentCard: paymentCard$e,
  paymentTypes: paymentTypes$e,
  paymentMessages: paymentMessages$e
};
var pdf$s = {
  defaultTitle: "Document"
};
var pdf$t = {
  pdf: pdf$s
};
var productDetails$e = {
  id: "ID",
  quantity: "Qty",
  quantityFull: "Quantity",
  productDetails: "Product Details",
  specification: "Specs",
  reviews: "Reviews",
  shipping: "Shipping",
  share: "Share",
  showReviews: "Show reviews",
  noReviews: "No reviews yet",
  productPrice: "Product price",
  noProductImage: "No image available, {{ product }}"
};
var productList$e = {
  filterBy: {
    label: "Filter by",
    action: "Filter by"
  },
  activeFilter: "{{filter}} filter, clicking on this button will remove the filter",
  appliedFilter: "Applied filter:",
  showLess: "Show less...",
  showMore: "Show more...",
  sortBy: "Sort by",
  sortResults: "Sort results",
  backToTopBtn: "BACK TO TOP",
  showMoreBtn: "SHOW MORE",
  productSearchPagination: "Product search pagination",
  productListResults: "Product Results List"
};
var productFacetNavigation$e = {
  filterBy: {
    label: "Filter by",
    action: "Filter by",
    facet: "Filter results by Facets",
    name: "Filter by {{name}}"
  },
  appliedFilter: "Applied filter:",
  showLess: "Show less...",
  showMore: "Show more...",
  sortBy: "Sort by",
  ariaLabelShowLess: "Show Less, button, clicking on this button will reduce options for the active group",
  ariaLabelShowMore: "Show more, button, clicking on this button will show all options for the active group",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} item available",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} items available",
  decreaseOptionsVisibility: "Options were hidden from the active group, tab backward to read them or forward for the next group",
  increaseOptionsVisibility: "More options were added to the active group, tab backward to read them or forward for the next group",
  backToResults: "Back To Results",
  productFacets: "Product Facets"
};
var productSummary$e = {
  id: "ID",
  showReviews: "Show reviews",
  showReviewsDetailed: "Show {{count}} reviews, Rated {{rating}} out of 5 stars",
  share: "Share",
  newItemPrice: "New item price"
};
var productReview$e = {
  overallRating: "Overall Rating",
  reviewTitle: "Review Title",
  writeYourComments: "Write your comments",
  rating: "Rating",
  ratingRequired: "Product rating, required",
  addRate: "Add rate: {{count}} star",
  addRate_other: "Add rate: {{count}} stars",
  reviewerName: "Reviewer name (optional)",
  writeReview: "Write a Review",
  more: "Show More Reviews",
  less: "Show Less Reviews",
  thankYouForReview: "Thank you for the review! Note that reviews may require review before appearing here.",
  postReviewFail: "Something went wrong while posting your review. Please try again later.",
  ratedOutOf: "Rated: {{rating}} out of 5"
};
var productCarousel$e = {
  carouselLabel: "Carousel, {{title}}"
};
var addToCart$e = {
  itemAddedToYourCart: "Item Added To Your Cart",
  itemsAddedToYourCart: "Item(s) added to your cart",
  itemsIncrementedInYourCart: "This item was already in your cart. The quantity was updated.",
  items: "items",
  updatingCart: "Updating cart...",
  addToCart: "Add to cart",
  viewCart: "view cart",
  proceedToCheckout: "proceed to checkout",
  quantity: "Qty",
  quantityFull: "Quantity",
  outOfStock: "Out of stock",
  inStock: "In stock",
  selectStyleAndSize: "Select style and size to check stock",
  removeFromCart: "Remove Product from Cart",
  closeModal: "Close Modal",
  buyItAgain: "Buy It Again",
  addToActiveCart: "Add To Active Cart"
};
var TabPanelContainer$e = {
  tabs: {
    ProductDetailsTabComponent: "Product Details",
    ProductSpecsTabComponent: "Specs",
    ProductReviewsTabComponent: "Reviews",
    deliveryTab: "Shipping",
    SparePartsTabComponent: " Spare Parts"
  },
  tabPanelContainerRegion: "Tab group with more product details",
  tabPanelContainerRegionGroup: "Group with more product details"
};
var addToWishList$e = {
  add: "Add to Wish List",
  remove: "Remove from Wish List",
  anonymous: "Sign in to add to wish list",
  addedToWishList: "Product added to wish list",
  removedFromWishList: "Product removed from wish list"
};
var stockNotification$e = {
  notifyMe: "NOTIFY ME",
  stopNotify: "STOP NOTIFICATION",
  getNotify: "Get notified when this product is available.",
  getNotifySuffix: "to get notified when this product is available.",
  activateChannelsPrefix: "To be notified you need to activate the ",
  channelsLink: "Notification Channels",
  activateChannelsSuffix: ".",
  notified: "You will be notified when this product is back in stock.",
  getNotified: "Get notified when this product is back in stock.",
  unsubscribeSuccess: "You will not receive back-in-stock notification for this product.",
  subscriptionDialog: {
    header: "Out of stock subscription",
    notifiedPrefix: "You will be notified on:",
    notifiedSuffix: "as soon as this product is back in stock.",
    manageChannelsPrefix: "Manage your preferred notification channels on the ",
    manageChannelsLink: "Notification Preference",
    manageChannelsSuffix: " page.",
    manageSubscriptionsPrefix: "You can manage your subscriptions on ",
    manageSubscriptionsLink: "My Interests",
    manageSubscriptionsSuffix: " page.",
    okBtn: "OK",
    subscribing: "Subscribing you to Out of Stock notifications for this product"
  }
};
var itemCounter$e = {
  removeOne: "Remove one",
  addOneMore: "Add one more",
  quantity: "Quantity"
};
var productView$e = {
  gridView: "Select to change to Grid View",
  listView: "Select to change to List View"
};
var product$e = {
  productDetails: productDetails$e,
  productList: productList$e,
  productFacetNavigation: productFacetNavigation$e,
  productSummary: productSummary$e,
  productReview: productReview$e,
  productCarousel: productCarousel$e,
  addToCart: addToCart$e,
  TabPanelContainer: TabPanelContainer$e,
  addToWishList: addToWishList$e,
  stockNotification: stockNotification$e,
  itemCounter: itemCounter$e,
  productView: productView$e
};
var pwa$s = {
  addToHomeScreenDescription: "Add SAP storefront to your device homescreen for a faster return visit",
  noInstallationNeeded: "No installation needed",
  fastAccessToApplication: "Fast access to application",
  addToHomeScreen: "Add to home screen",
  addedToHomeScreen: "SAP Storefront was added to your home screen"
};
var pwa$t = {
  pwa: pwa$s
};
var siteThemeSwitcher$s = {
  theme: "Theme",
  themes: {
    "default": "Default",
    highContrastDark: "HC-Dark",
    highContrastLight: "HC-Light"
  }
};
var siteThemeSwitcher$t = {
  siteThemeSwitcher: siteThemeSwitcher$s
};
var anonymousConsents$e = {
  dialog: {
    title: "Consent Management",
    legalDescription: "We use cookies/browser's storage to personalize the content and improve user experience. We also might share the data about your site usage with our social media. For more, please review our privacy policy.",
    selectAll: "Select all",
    clearAll: "Clear all"
  },
  banner: {
    title: "This website uses cookies",
    description: "We use cookies/browser's storage to personalize the content and improve user experience.",
    allowAll: "Allow All",
    viewDetails: "View Details",
    consentManagement: "Consent Management"
  }
};
var checkoutLogin$e = {
  emailAddress: {
    label: "Email address",
    placeholder: "Enter email"
  },
  confirmEmail: {
    label: "Confirm email",
    placeholder: "Confirm email"
  },
  "continue": "Continue",
  emailIsRequired: "Invalid email format",
  emailsMustMatch: "Email does not match"
};
var authMessages$e = {
  signedOutSuccessfully: "You have successfully signed out."
};
var user$e = {
  anonymousConsents: anonymousConsents$e,
  checkoutLogin: checkoutLogin$e,
  authMessages: authMessages$e
};
var player$e = {
  label: "Video Player"
};
var video$e = {
  player: player$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  common: common$t,
  deliveryMode: deliveryMode$e,
  myAccountV2Consent: myAccountV2Consent$t,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$t,
  myAccount: myAccount$e,
  payment: payment$e,
  pdf: pdf$t,
  product: product$e,
  pwa: pwa$t,
  siteThemeSwitcher: siteThemeSwitcher$t,
  user: user$e,
  video: video$e
};
var common$q = {
  cancel: "Cancelar",
  "delete": "Borrar",
  remove: "Eliminar",
  edit: "Editar",
  restore: "Restablecer",
  back: "Atrás",
  submit: "Enviar",
  "continue": "Continuar",
  save: "Grabar",
  done: "Listo",
  home: "Inicio",
  noData: "Sin datos.",
  confirm: "Confirmar",
  more: "más",
  close: "Cerrar",
  loading: "Cargando",
  menu: "Menú",
  reset: "Restaurar",
  search: "Buscar",
  add: "Añadir",
  breadcrumbs: "rutas de navegación",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Seleccionar fichero",
  clear: "Borrar",
  loaded: "Cargado",
  results: "Resultados",
  of: "de",
  ngSelectDropdownOptionsList: "Opciones",
  required: "obligatorio",
  zoomIn: "Acercar",
  zoomOut: "Alejar",
  selected: "Seleccionado",
  expand: "Ampliar",
  collapse: "Contraer"
};
var pageMetaResolver$d = {
  category: {
    title: "{{count}} resultado para {{query}}",
    title_other: "{{count}} resultados para {{query}}"
  },
  checkout: {
    title: "Finalización de la compra"
  },
  search: {
    title: "{{count}} resultado para \"{{query}}\"",
    title_other: "{{count}} resultados para \"{{query}}\"",
    findProductTitle: "{{count}} resultado para el cupón \"{{coupon}}\"",
    findProductTitle_other: "{{count}} resultados para el cupón \"{{coupon}}\"",
    default_title: "Todos los productos"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$d = {
  loading: "Cargando..."
};
var navigation$d = {
  categoryNavLabel: "Menú de categoría",
  footerNavLabel: "Enlaces del pie de página",
  goTo: "Vaya a {{location}}",
  navigateTo: "Navegue a {{nav}}",
  scrollToTop: "Desplácese hacia arriba al principio de la página",
  linkItemInList: "{{title}}. {{position}} de {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$d = {
  placeholder: "Introducir nombre de producto o SKU",
  productSearch: "Buscar un producto",
  ariaLabelInput: "Buscar aquí...",
  ariaLabelSuggestions: "escribiendo sugerencias",
  ariaLabelProducts: "resultados de productos",
  initialDescription: "Si están disponibles los resultados de autocompletar, use las flechas de arriba y abajo y la tecla Intro para seleccionar. Los usuarios de dispositivos táctiles pueden explorar tocando la pantalla o con gestos de deslizamiento.",
  suggestions: "Palabras clave sugeridas",
  recentSearches: "Búsquedas recientes",
  trendingSearches: "Búsquedas populares",
  suggestionsResult: "{{ count }} sugerencia y ",
  suggestionsResult_other: "{{ count }} sugerencias y ",
  products: "Productos recomendados",
  productsResult: "{{ count }} producto está disponible.",
  productsResult_other: "{{ count }} productos están disponibles.",
  resetLabel: "Restablecer búsqueda",
  help: {
    insufficientChars: "Escriba más caracteres",
    noMatch: "No hemos podido encontrar ningún resultado",
    exactMatch: "{{ term }}",
    empty: "Háganos preguntas"
  },
  closeSearchPanel: "Cerrar"
};
var sorting$d = {
  date: "Fecha",
  orderNumber: "Número de pedido",
  rma: "Número de devolución",
  replenishmentNumber: "Número de reaprovisionamiento",
  nextOrderDate: "Fecha del próximo pedido",
  pageViewUpdated: "Vista de página actualizada con sus opciones seleccionadas."
};
var httpHandlers$d = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Inicie sesión de nuevo.",
    user_is_disabled: "{{ errorMessage }}. Comuníquese con la administración."
  },
  badGateway: "Se produjo un error del servidor. Inténtelo de nuevo más tarde.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Inicie sesión de nuevo.",
  badRequestOldPasswordIncorrect: "Contraseña anterior incorrecta.",
  badRequestGuestDuplicateEmail: "El correo electrónico {{ errorMessage }} ya existe. Finalice la compra con un correo electrónico diferente para registrarse con una cuenta de invitado.",
  conflict: "Ya existe.",
  forbidden: "No tiene autorización para realizar esta acción. Comuníquese con el administrador si piensa que esto es un error.",
  gatewayTimeout: "El servidor no respondió. Inténtelo de nuevo más tarde.",
  internalServerError: "Se produjo un error del servidor interno. Vuelva a intentarlo más tarde.",
  sessionExpired: "Su sesión ha caducado. Vuelva a iniciar sesión.",
  unknownError: "Se produjo un error desconocido",
  unauthorized: {
    common: "Se produjo un error de autorización desconocido",
    invalid_client: "Credenciales del cliente incorrectas"
  },
  validationErrors: {
    missing: {
      card_cardType: "No se admite la tarjeta de crédito seleccionada. Seleccione otra.",
      card_accountNumber: "El número de la tarjeta de crédito introducido no es válido.",
      card_cvNumber: "El código de seguridad introducido no es válido.",
      card_expirationMonth: "La fecha de caducidad de la tarjeta de crédito introducida no es válida.",
      card_expirationYear: "La fecha de caducidad de la tarjeta de crédito introducida no es válida.",
      billTo_firstName: "El nombre introducido no es válido.",
      billTo_lastName: "El apellido introducido no es válido.",
      billTo_street1: "La dirección introducida no es válida.",
      billTo_street2: "La dirección introducida no es válida.",
      billTo_city: "La ciudad introducida no es válida para esta tarjeta de crédito.",
      billTo_state: "El estado/provincia introducido no es válido para esta tarjeta de crédito.",
      billTo_country: "El país/región introducido no es válido para esta tarjeta de crédito.",
      billTo_postalCode: "El código postal introducido no es válido para esta tarjeta de crédito.",
      country: {
        isocode: "Falta el país o la región"
      }
    },
    invalid: {
      card_expirationMonth: "La fecha de caducidad de la tarjeta de crédito introducida no es válida.",
      firstName: "El nombre introducido no es válido.",
      lastName: "El apellido introducido no es válido.",
      password: "La contraseña introducida no es válida.",
      uid: "El UID no es válido.",
      code: "El código no es válido.",
      email: "El correo electrónico no es válido.",
      loginId: "El correo electrónico no es válido."
    }
  },
  cartNotFound: "No se encontró la cesta.",
  invalidCodeProvided: "Código proporcionado no válido.",
  voucherExceeded: "Este cupón superó la cantidad de veces que se puede usar",
  unknownIdentifier: "Artículo no encontrado.",
  otherCartErrors: "Se produjeron errores con la cesta."
};
var miniCart$d = {
  item: "Hay {{count}} artículo en su cesta en este momento",
  item_other: "Hay {{count}} artículos en su cesta en este momento",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$d = {
  skipTo: "Saltar a",
  labels: {
    header: "Cabecera",
    main: "Contenido principal",
    footer: "Pie de página",
    productFacets: "Facetas de producto",
    productList: "Lista de productos"
  }
};
var carousel$d = {
  previousSlide: "Diapositiva anterior",
  nextSlide: "Diapositiva siguiente",
  slideNumber: "La diapositiva {{currentSlideNumber}} contiene {{numberOfSlides}} artículos",
  carouselForProduct: "Carrusel, imágenes para {{product}}"
};
var formErrors$d = {
  labeled: {
    required: "El campo {{label}} es obligatorio",
    cxInvalidEmail: "El campo {{label}} no tiene un formato de correo electrónico válido. Debe coincidir con el patrón: example@yourdomain.com",
    cxInvalidPassword: "El campo {{label}} no tiene un formato de contraseña válido",
    cxMinOneUpperCaseCharacter: "La contraseña debe contener al menos un carácter en mayúsculas",
    cxMinOneDigit: "La contraseña debe contener al menos un dígito",
    cxMinOneSpecialCharacter: "La contraseña debe contener al menos un carácter especial",
    cxMinSixCharactersLength: "La contraseña debe contener al menos 6 caracteres",
    cxMinEightCharactersLength: "La contraseña debe contener al menos 8 caracteres",
    cxMaxCharactersLength: "La contraseña no puede contener más de 128 caracteres",
    cxContainsSpecialCharacters: "La contraseña no puede contener caracteres especiales",
    cxNoConsecutiveCharacters: "La contraseña no puede contener caracteres idénticos consecutivos",
    date: {
      required: "El campo {{label}} es obligatorio",
      min: "El campo {{label}} no puede estar antes de {{min}}",
      max: "El campo {{label}} no puede estar después de {{max}}",
      pattern: "Use el formato de fecha aaaa-mm-dd en el campo {{label}}",
      invalid: "Use una fecha válida en el campo {{label}}"
    }
  },
  globalMessage: "El formulario que intenta enviar tiene errores.",
  required: "Este campo es obligatorio",
  cxInvalidEmail: "Este no es un formato de correo electrónico válido",
  cxInvalidPassword: "Este no es un formato de contraseña válido",
  cxPasswordsMustMatch: "Los campos de contraseña deben coincidir",
  cxPasswordsCannotMatch: "Los campos de contraseña no deben coincidir",
  cxEmailsMustMatch: "Los campos de correo electrónico deben coincidir",
  cxStarRatingEmpty: "El campo de calificación es obligatorio",
  cxNoSelectedItemToCancel: "Seleccione al menos un artículo",
  cxNegativeAmount: "El importe debe ser igual o mayor que cero",
  cxContainsSpecialCharacters: "El campo no puede tener caracteres especiales",
  date: {
    required: "Este campo es obligatorio",
    min: "La fecha no puede ser anterior a {{min}}",
    max: "La fecha no puede ser posterior a {{max}}",
    pattern: "Use el formato de fecha aaaa-mm-dd",
    invalid: "Use una fecha válida"
  },
  file: {
    required: "El fichero es obligatorio",
    empty: "El fichero no debe estar vacío",
    invalidExtension: "La extensión del fichero no es válido",
    tooLarge: "El tamaño del fichero no debe superar los {{ maxSize }} MB",
    tooManyEntries: "El número de artículos es mayor que {{ maxEntries }}",
    notParsable: "El fichero no se puede analizar sintácticamente",
    fileNotAllowed: "Este tipo de fichero no está permitido"
  }
};
var errorHandlers$d = {
  scriptFailedToLoad: "Error al cargar el script.",
  refreshThePage: "Actualice la página."
};
var assistiveMessage$d = {
  actionCancelled: "Acción cancelada. No se realizaron cambios"
};
var passwordVisibility$d = {
  showPassword: "Mostrar contraseña",
  hidePassword: "Ocultar contraseña"
};
var generalErrors$d = {
  pageFailure: "No se pudo cargar la página. Inténtelo de nuevo más tarde."
};
var formLegend$d = {
  required: "Los siguientes campos marcados con \"*\" son obligatorios."
};
var chatMessaging$d = {
  charactersLeft: "caracteres restantes: {{count}}",
  addNewMessage: "Añadir nuevo mensaje",
  send: "Enviar",
  uploadFile: "Cargar fichero",
  informationLabel: "{{author}}. {{text}} en {{date}}",
  messages: "Mensajes",
  addMessagePlaceHolder: "Empiece a escribir...",
  characterLimitAlert: "Se alcanzó el límite de caracteres.",
  a11y: {
    itemListBoxLabel: "Seleccione el artículo al cual se debe aplicar el mensaje."
  }
};
var common$r = {
  common: common$q,
  pageMetaResolver: pageMetaResolver$d,
  spinner: spinner$d,
  navigation: navigation$d,
  searchBox: searchBox$d,
  sorting: sorting$d,
  httpHandlers: httpHandlers$d,
  miniCart: miniCart$d,
  skipLink: skipLink$d,
  carousel: carousel$d,
  formErrors: formErrors$d,
  errorHandlers: errorHandlers$d,
  assistiveMessage: assistiveMessage$d,
  passwordVisibility: passwordVisibility$d,
  generalErrors: generalErrors$d,
  formLegend: formLegend$d,
  chatMessaging: chatMessaging$d
};
var setDeliveryMode$d = {
  unknownError: "Ocurrió un error desconocido. Póngase en contacto con el servicio de soporte."
};
var deliveryMode$d = {
  setDeliveryMode: setDeliveryMode$d
};
var myAccountV2Consent$q = {
  header: "Gestión de consentimientos",
  dateDescription: "Fecha de aprobación ",
  clearAll: "Desactivar todos",
  selectAll: "Activar todos",
  message: {
    success: {
      given: "Consentimiento otorgado correctamente.",
      withdrawn: "Consentimiento retirado correctamente."
    }
  }
};
var myAccountV2Consent$r = {
  myAccountV2Consent: myAccountV2Consent$q
};
var myAccountV2NotificationPreference$q = {
  header: "Canales de notificación",
  message: "Seleccione sus canales de notificación preferidos",
  note: "Nota: ",
  noteMessage: "Si desactiva todos los canales, no podrá recibir más notificaciones.",
  EMAIL: "Correo electrónico:"
};
var myAccountV2NotificationPreference$r = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$q
};
var closeAccount$d = {
  confirmAccountClosure: "Confirmar cierre de cuenta",
  confirmAccountClosureMessage: "¿Confirma que desea cerrar su cuenta?",
  closeMyAccount: "CERRAR MI CUENTA",
  accountClosedSuccessfully: "Cuenta cerrada correctamente",
  accountClosedFailure: "Error al cerrar la cuenta"
};
var updatePasswordForm$d = {
  oldPassword: {
    label: "Contraseña anterior",
    placeholder: "Contraseña anterior"
  },
  oldPasswordIsRequired: "Se requiere la contraseña anterior.",
  newPassword: {
    label: "Contraseña nueva",
    placeholder: "Contraseña nueva"
  },
  passwordMinRequirements: "Esta es una guía rápida para generar una contraseña segura: utilice al menos 8 caracteres e incluya letras en mayúscula y minúscula, números y símbolos.",
  passwordStrengthRequirements: "Esta es una guía rápida para generar una contraseña segura: utilice al menos 8 caracteres e incluya letras en mayúscula y minúscula, números y símbolos. Asegúrese de no utilizar el mismo carácter de forma consecutiva.",
  confirmPassword: {
    label: "Confirmar contraseña nueva",
    placeholder: "Confirmar contraseña"
  },
  bothPasswordMustMatch: "Ambas contraseñas deben coincidir",
  passwordUpdateSuccess: "Contraseña actualizada correctamente",
  accessDeniedError: "Se denegó el acceso"
};
var updateProfileForm$d = {
  title: "Tratamiento",
  none: "",
  firstName: {
    label: "Nombre",
    placeholder: "Nombre"
  },
  firstNameIsRequired: "Se requiere el nombre.",
  lastName: {
    label: "Apellido",
    placeholder: "Apellido"
  },
  lastNameIsRequired: "Se requiere el apellido.",
  profileUpdateSuccess: "Detalles personales actualizados correctamente",
  customerId: "Cliente n. º"
};
var consentManagementForm$d = {
  clearAll: "Borrar todo",
  selectAll: "Seleccionar todo",
  message: {
    success: {
      given: "Consentimiento otorgado correctamente.",
      withdrawn: "Consentimiento retirado correctamente."
    }
  }
};
var myCoupons$d = {
  noCouponsMessage: "No tiene cupones disponibles.",
  effectiveTitle: "Entrada en vigencia:",
  Effective: "EN VIGENCIA",
  PreSession: "EN VIGENCIA PRONTO",
  ExpireSoon: "VENCE PRONTO",
  readMore: "Más información",
  notification: "Notificación",
  findProducts: "Buscar productos",
  status: "Estado:",
  dialogTitle: "Cupón",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Código de cupón",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Ha reclamado correctamente este cupón.",
  myCoupons: "Mis cupones",
  startDateAsc: "Fecha de inicio (ascendente)",
  startDateDesc: "Fecha de inicio (descendente)",
  endDateAsc: "Fecha de fin (ascendente)",
  endDateDesc: "Fecha de fin (descendente)",
  sortBy: "Clasificar por",
  sortCoupons: "Clasificar cupones",
  notesPreffix: "Puede definir sus canales favoritos para recibir notificaciones sobre cupones en la página ",
  notesLink: "Canales de notificación",
  notesSuffix: " ."
};
var notificationPreference$d = {
  message: "Seleccione sus canales de notificación preferidos",
  note: "Nota: ",
  noteMessage: "Si desactiva todos los canales, no pdorá recibir más notificaciones.",
  EMAIL: "Correo electrónico:",
  SMS: "SMS:",
  SITE_MESSAGE: "SiteMessage"
};
var myInterests$d = {
  header: "Mis intereses",
  item: "ARTÍCULO",
  price: "PRECIO",
  notifications: "NOTIFICACIONES",
  noInterests: "Aún no tiene intereses registrados.",
  inStock: "En stock",
  lowStock: "Existencias bajas",
  outOfStock: "No disponible en stock",
  BACK_IN_STOCK: "De vuelta en stock",
  sortBy: "Clasificar por",
  sortInterests: "Clasificar intereses",
  expirationDate: " - hasta {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Eliminar",
  sorting: {
    byNameAsc: "Nombre (ascendente)",
    byNameDesc: "Nombre (descendente)"
  },
  actions: "ACCIONES",
  caption: "Contenido de Mis intereses.",
  itemRemoved: "Se eliminó el artículo seleccionado."
};
var myAccount$d = {
  closeAccount: closeAccount$d,
  updatePasswordForm: updatePasswordForm$d,
  updateProfileForm: updateProfileForm$d,
  consentManagementForm: consentManagementForm$d,
  myCoupons: myCoupons$d,
  notificationPreference: notificationPreference$d,
  myInterests: myInterests$d
};
var paymentForm$d = {
  payment: "Pago",
  choosePaymentMethod: "Seleccione un método de pago",
  paymentType: "Tipo de pago",
  accountHolderName: {
    label: "Nombre del titular de cuenta",
    placeholder: "Nombre del titular de cuenta"
  },
  cardNumber: "Número de tarjeta",
  expirationDate: "Fecha de caducidad",
  securityCode: "Código de seguridad (CVV)",
  securityCodeTitle: "Valor de verificación de tarjeta",
  saveAsDefault: "Grabar como predeterminado",
  setAsDefault: "Grabar como método de pago predeterminado",
  billingAddress: "Dirección de facturación",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "La misma que la dirección de entrega",
  billingAddressSameAsShipping: "La dirección de facturación es la misma que la dirección de entrega",
  selectOne: "Seleccionar uno...",
  monthMask: "MM",
  yearMask: "AAAA",
  expirationYear: "Año de caducidad {{ selected }}",
  expirationMonth: "Mes de caducidad {{ selected }}",
  useThisPayment: "Utilizar este pago",
  addNewPayment: "Añadir nuevo pago",
  paymentAddedSuccessfully: "El nuevo pago fue añadido correctamente",
  changePayment: "Cambiar pago"
};
var paymentMethods$d = {
  paymentMethods: "Métodos de pago",
  paymentMethodSelected: "Método de pago seleccionado",
  newPaymentMethodsAreAddedDuringCheckout: "Los nuevos métodos de pago se añaden durante la finalización de la compra.",
  invalidField: "Campo no válido: {{ field }}"
};
var paymentCard$d = {
  deleteConfirmation: "¿Está seguro de que quiere eliminar este método de pago?",
  setAsDefault: "Definir como predeterminado",
  expires: "Expira: {{ month }}/{{ year }}",
  defaultPaymentMethod: "PREDETERMINADO",
  defaultPaymentLabel: "Método de pago predeterminado",
  additionalPaymentLabel: "Método de pago adicional {{ number }}",
  selected: "Seleccionado",
  selectedPayment: "Pago seleccionado",
  deletePaymentSuccess: "Método de pago eliminado correctamente",
  visa: "Tarjeta Visa",
  master: "Tarjeta Mastercard",
  masterEuro: "Tarjeta Mastercard Eurocard",
  dinersClub: "Tarjeta Diners Club",
  "switch": "Tarjeta Switch",
  maestro: "Tarjeta Maestro",
  amex: "Tarjeta American Express",
  credit: "Tarjeta de crédito"
};
var paymentTypes$d = {
  title: "Método de pago",
  paymentType_CARD: "Tarjeta de crédito",
  paymentType_ACCOUNT: "Cuenta"
};
var paymentMessages$d = {
  setAsDefaultSuccessfully: "El nuevo pago se definió correctamente como predeterminado"
};
var payment$d = {
  paymentForm: paymentForm$d,
  paymentMethods: paymentMethods$d,
  paymentCard: paymentCard$d,
  paymentTypes: paymentTypes$d,
  paymentMessages: paymentMessages$d
};
var pdf$q = {
  defaultTitle: "Documento"
};
var pdf$r = {
  pdf: pdf$q
};
var productDetails$d = {
  id: "ID",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  productDetails: "Detalles del producto",
  specification: "Especificaciones",
  reviews: "Reseñas",
  shipping: "Envío",
  share: "Compartir",
  showReviews: "Mostrar reseñas",
  noReviews: "No tiene reseñas aún",
  productPrice: "Precio de producto",
  noProductImage: "No hay imagen disponible, {{ product }}"
};
var productList$d = {
  filterBy: {
    label: "Filtrar por",
    action: "Filtrar por"
  },
  activeFilter: "filtro {{filter}}, al hacer clic en este botón, se eliminará el filtro",
  appliedFilter: "Filtro aplicado:",
  showLess: "Mostrar menos...",
  showMore: "Mostrar más...",
  sortBy: "Ordenar por",
  sortResults: "Ordenar resultados",
  backToTopBtn: "VOLVER AL INICIO",
  showMoreBtn: "MOSTRAR MÁS",
  productSearchPagination: "Paginación de búsqueda de producto",
  productListResults: "Lista de resultados de producto"
};
var productFacetNavigation$d = {
  filterBy: {
    label: "Filtrar por",
    action: "Filtrar por",
    facet: "Filtrar resultados por facetas",
    name: "Filtrar por {{name}}"
  },
  appliedFilter: "Filtro aplicado:",
  showLess: "Mostrar menos...",
  showMore: "Mostrar más...",
  sortBy: "Ordenar por",
  ariaLabelShowLess: "Botón Mostrar menos, al hacer clic en este botón se reducirán las opciones del grupo activo",
  ariaLabelShowMore: "Botón Mostrar más, al hacer clic en este botón se mostrarán todas las opciones para el grupo activo",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} artículo disponible",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} artículos disponibles",
  decreaseOptionsVisibility: "Las opciones estaban ocultas en el grupo activo, vuelva atrás para leerlas o vaya hacia delante para el siguiente grupo.",
  increaseOptionsVisibility: "Se han añadido más opciones al grupo activo, vuelva atrás para leerlas o vaya hacia delante para el siguiente grupo.",
  backToResults: "Volver a los resultados",
  productFacets: "Facetas de producto"
};
var productSummary$d = {
  id: "ID",
  showReviews: "Mostrar reseñas",
  showReviewsDetailed: "Mostrar {{count}} reseñas con clasificación de {{rating}} de 5 estrellas",
  share: "Compartir",
  newItemPrice: "Nuevo precio del artículo"
};
var productReview$d = {
  overallRating: "Clasificación general",
  reviewTitle: "Título de la reseña",
  writeYourComments: "Escribir sus comentarios",
  rating: "Clasificación",
  ratingRequired: "Clasificación de producto requerida",
  addRate: "Añadir clasificación: {{count}} estrella",
  addRate_other: "Añadir clasificación: {{count}} estrellas",
  reviewerName: "Nombre del revisor (opcional)",
  writeReview: "Escriba una reseña",
  more: "Mostrar más reseñas",
  less: "Mostrar menos reseñas",
  thankYouForReview: "Gracias por su reseña. Tenga en cuenta que las reseñas pueden requerir revisión antes de aparecer aquí.",
  postReviewFail: "Algo salió mal al publicar su reseña. Vuelva a intentarlo más tarde.",
  ratedOutOf: "Clasificación: {{rating}} de 5"
};
var productCarousel$d = {
  carouselLabel: "Carrusel, {{title}}"
};
var addToCart$d = {
  itemAddedToYourCart: "Artículo añadido a la cesta",
  itemsAddedToYourCart: "Artículos añadidos a la cesta",
  itemsIncrementedInYourCart: "Este artículo ya estaba en su cesta. Se actualizó la cantidad.",
  items: "articulos",
  updatingCart: "Actualizando cesta...",
  addToCart: "Añadir a la cesta",
  viewCart: "ver cesta",
  proceedToCheckout: "procedes a finalizar la compra",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  outOfStock: "Fuera de stock",
  inStock: "En stock",
  selectStyleAndSize: "Seleccione estilo y tamaño para consultar en el stock",
  removeFromCart: "Eliminar producto de la cesta",
  closeModal: "Cerrar modal",
  buyItAgain: "Volver a comprar",
  addToActiveCart: "Añadir a la cesta activa"
};
var TabPanelContainer$d = {
  tabs: {
    ProductDetailsTabComponent: "Detalles del producto",
    ProductSpecsTabComponent: "Especificaciones",
    ProductReviewsTabComponent: "Reseñas",
    deliveryTab: "Envío",
    SparePartsTabComponent: " Piezas de repuesto"
  },
  tabPanelContainerRegion: "Grupo de pestañas con más detalles de producto",
  tabPanelContainerRegionGroup: "Grupo con más detalles de producto"
};
var addToWishList$d = {
  add: "Aladir a la lista de deseos",
  remove: "Eliminar de la lista de deseos",
  anonymous: "Inicie sesión para añadir a la lista de deseos",
  addedToWishList: "Producto añadido a la lista de deseos",
  removedFromWishList: "Producto eliminado de la lista de deseos"
};
var stockNotification$d = {
  notifyMe: "NOTIFICARME",
  stopNotify: "DETENER NOTIFICACIÓN",
  getNotify: "Reciba una notificación cuando este producto esté disponible.",
  getNotifySuffix: "para recibir una notificación cuando este producto esté disponible.",
  activateChannelsPrefix: "Para recibir una notificación, debe activar los ",
  channelsLink: "Canales de notificación",
  activateChannelsSuffix: ".",
  notified: "Se le notificará cuando este producto vuelva a estar disponible.",
  getNotified: "Reciba una notificación cuando este producto vuelva a estar disponible.",
  unsubscribeSuccess: "No recibirá ninguna notificación de reposición de stock de este producto.",
  subscriptionDialog: {
    header: "Suscripción a fuera de stock",
    notifiedPrefix: "Se le notificará:",
    notifiedSuffix: "apenas este producto vuelva a estar disponible.",
    manageChannelsPrefix: "Administre sus canales de notificación preferidos en la ",
    manageChannelsLink: "página Preferencia de notificación",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "Puede administrar sus suscripciones en ",
    manageSubscriptionsLink: "la página Mis intereses",
    manageSubscriptionsSuffix: " .",
    okBtn: "Aceptar",
    subscribing: "Suscribiéndole a notificaciones de fuera de stock para este producto"
  }
};
var itemCounter$d = {
  removeOne: "Eliminar una",
  addOneMore: "Añadir una más",
  quantity: "Cantidad"
};
var productView$d = {
  gridView: "Seleccione para cambiar a Vista de cuadrícula",
  listView: "Seleccione para cambiar a Vista de lista"
};
var product$d = {
  productDetails: productDetails$d,
  productList: productList$d,
  productFacetNavigation: productFacetNavigation$d,
  productSummary: productSummary$d,
  productReview: productReview$d,
  productCarousel: productCarousel$d,
  addToCart: addToCart$d,
  TabPanelContainer: TabPanelContainer$d,
  addToWishList: addToWishList$d,
  stockNotification: stockNotification$d,
  itemCounter: itemCounter$d,
  productView: productView$d
};
var pwa$q = {
  addToHomeScreenDescription: "Añada SAP Storefront a la pantalla de inicio de su dispositivo para una visita de retorno más rápida",
  noInstallationNeeded: "No se requiere instalación",
  fastAccessToApplication: "Acceso rápido a las aplicaciones",
  addToHomeScreen: "Añadir a la pantalla de inicio",
  addedToHomeScreen: "SAP Storefront se añadió a su pantalla de inicio"
};
var pwa$r = {
  pwa: pwa$q
};
var siteThemeSwitcher$q = {
  theme: "Tema",
  themes: {
    "default": "Por defecto",
    highContrastDark: "HC-Dark",
    highContrastLight: "HC-Light"
  }
};
var siteThemeSwitcher$r = {
  siteThemeSwitcher: siteThemeSwitcher$q
};
var anonymousConsents$d = {
  dialog: {
    title: "Gestión de consentimiento",
    legalDescription: "Utilizamos cookies/almacenamiento del navegador para personalizar el contenido y mejorar la experiencia del usuario. También podemos compartir los datos sobre su utilización del sitio con nuestras redes sociales. Para más información, consulte nuestra política de privacidad.",
    selectAll: "Seleccionar todo",
    clearAll: "Borrar todo"
  },
  banner: {
    title: "Este sitio web utiliza cookies",
    description: "Utilizamos cookies/almacenamiento del navegador para personalizar el contenido y mejorar la experiencia del usuario.",
    allowAll: "Permitir todos",
    viewDetails: "Ver detalles",
    consentManagement: "Gestión de consentimiento"
  }
};
var checkoutLogin$d = {
  emailAddress: {
    label: "Dirección de correo electrónico",
    placeholder: "Introducir correo electrónico"
  },
  confirmEmail: {
    label: "Confirmar correo electrónico",
    placeholder: "Confirmar correo electrónico"
  },
  "continue": "Continuar",
  emailIsRequired: "Formato de correo electrónico no válido",
  emailsMustMatch: "El correo electrónico no coincide"
};
var authMessages$d = {
  signedOutSuccessfully: "Ha cerrado la sesión correctamente."
};
var user$d = {
  anonymousConsents: anonymousConsents$d,
  checkoutLogin: checkoutLogin$d,
  authMessages: authMessages$d
};
var player$d = {
  label: "Reproductor de video"
};
var video$d = {
  player: player$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  common: common$r,
  deliveryMode: deliveryMode$d,
  myAccountV2Consent: myAccountV2Consent$r,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$r,
  myAccount: myAccount$d,
  payment: payment$d,
  pdf: pdf$r,
  product: product$d,
  pwa: pwa$r,
  siteThemeSwitcher: siteThemeSwitcher$r,
  user: user$d,
  video: video$d
};
var common$o = {
  cancel: "Cancelar",
  "delete": "Borrar",
  remove: "Eliminar",
  edit: "Editar",
  restore: "Restaurar",
  back: "Atrás",
  submit: "Enviar",
  "continue": "Continuar",
  save: "Guardar",
  done: "Listo",
  home: "Inicio",
  noData: "Sin datos.",
  confirm: "Confirmar",
  more: "más",
  close: "Cerrar",
  loading: "Cargando",
  menu: "Menú",
  reset: "Restablecer",
  search: "Buscar",
  add: "Agregar",
  breadcrumbs: "rutas de navegación",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Seleccionar archivo",
  clear: "Borrar",
  loaded: "Cargado",
  results: "Resultados",
  of: "de",
  ngSelectDropdownOptionsList: "Opciones",
  required: "obligatorio",
  zoomIn: "Acercar",
  zoomOut: "Alejar",
  selected: "Seleccionado",
  expand: "Ampliar",
  collapse: "Contraer"
};
var pageMetaResolver$c = {
  category: {
    title: "{{count}} resultado para {{query}}",
    title_other: "{{count}} resultados para {{query}}"
  },
  checkout: {
    title: "Finalización de la compra"
  },
  search: {
    title: "{{count}} resultado para \"{{query}}\"",
    title_other: "{{count}} resultados para \"{{query}}\"",
    findProductTitle: "{{count}} resultado para el cupón \"{{coupon}}\"",
    findProductTitle_other: "{{count}} resultados para el cupón \"{{coupon}}\"",
    default_title: "Todos los productos"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$c = {
  loading: "Cargando..."
};
var navigation$c = {
  categoryNavLabel: "Menú de categoría",
  footerNavLabel: "Enlaces del pie de página",
  goTo: "Vaya a {{location}}",
  navigateTo: "Navegue a {{nav}}",
  scrollToTop: "Desplácese hacia arriba al principio de la página",
  linkItemInList: "{{title}}. {{position}} de {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$c = {
  placeholder: "Ingresar nombre de producto o SKU",
  productSearch: "Buscar un producto",
  ariaLabelInput: "Buscar aquí…",
  ariaLabelSuggestions: "escribiendo sugerencias",
  ariaLabelProducts: "resultados de productos",
  initialDescription: "Si están disponibles los resultados de autocompletar, use las flechas de arriba y abajo y la tecla Intro para seleccionar. Los usuarios de dispositivos táctiles pueden explorar tocando la pantalla o con gestos de deslizamiento.",
  suggestions: "Palabras clave sugeridas",
  recentSearches: "Búsquedas recientes",
  trendingSearches: "Búsquedas más populares",
  suggestionsResult: "{{ count }} sugerencia y ",
  suggestionsResult_other: "{{ count }} sugerencias y ",
  products: "Productos recomendados",
  productsResult: "{{ count }} producto está disponible.",
  productsResult_other: "{{ count }} productos están disponibles.",
  resetLabel: "Restablecer búsqueda",
  help: {
    insufficientChars: "Escriba más caracteres",
    noMatch: "No pudimos encontrar ningún resultado",
    exactMatch: "{{ term }}",
    empty: "Háganos preguntas"
  },
  closeSearchPanel: "Cerrar"
};
var sorting$c = {
  date: "Fecha",
  orderNumber: "Número de pedido",
  rma: "Número de devolución",
  replenishmentNumber: "Número de reaprovisionamiento",
  nextOrderDate: "Próxima fecha de pedido",
  pageViewUpdated: "Vista de página actualizada con sus opciones seleccionadas."
};
var httpHandlers$c = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Inicie sesión de nuevo.",
    user_is_disabled: "{{ errorMessage }}. Comuníquese con la administración."
  },
  badGateway: "Se produjo un error del servidor. Inténtelo de nuevo más tarde.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Inicie sesión de nuevo.",
  badRequestOldPasswordIncorrect: "Contraseña anterior incorrecta.",
  badRequestGuestDuplicateEmail: "El correo electrónico {{ errorMessage }} ya existe. Finalice la compra con un correo electrónico diferente para registrarse con una cuenta de invitado.",
  conflict: "Ya existe.",
  forbidden: "No tiene autorización para realizar esta acción. Comuníquese con el administrador si piensa que esto es un error.",
  gatewayTimeout: "El servidor no respondió. Inténtelo de nuevo más tarde.",
  internalServerError: "Se produjo un error del servidor interno. Vuelva a intentarlo más tarde.",
  sessionExpired: "Su sesión ha caducado. Vuelva a iniciar sesión.",
  unknownError: "Se produjo un error desconocido",
  unauthorized: {
    common: "Se produjo un error de autorización desconocido",
    invalid_client: "Credenciales del cliente incorrectas"
  },
  validationErrors: {
    missing: {
      card_cardType: "No se admite la tarjeta de crédito seleccionada. Seleccione otra.",
      card_accountNumber: "El número de la tarjeta de crédito ingresado no es válido.",
      card_cvNumber: "El código de seguridad ingresado no es válido.",
      card_expirationMonth: "La fecha de caducidad de la tarjeta de crédito ingresada no es válida.",
      card_expirationYear: "La fecha de caducidad de la tarjeta de crédito ingresada no es válida.",
      billTo_firstName: "El nombre ingresado no es válido.",
      billTo_lastName: "El apellido ingresado no es válido.",
      billTo_street1: "La dirección ingresada no es válida.",
      billTo_street2: "La dirección ingresada no es válida.",
      billTo_city: "La ciudad ingresada no es válida para esta tarjeta de crédito.",
      billTo_state: "El estado/provincia ingresado no es válido para esta tarjeta de crédito.",
      billTo_country: "El país/región ingresado no es válido para esta tarjeta de crédito.",
      billTo_postalCode: "El código postal ingresado no es válido para esta tarjeta de crédito.",
      country: {
        isocode: "Falta el país o la región"
      }
    },
    invalid: {
      card_expirationMonth: "La fecha de caducidad de la tarjeta de crédito ingresada no es válida.",
      firstName: "El nombre ingresado no es válido.",
      lastName: "El apellido ingresado no es válido.",
      password: "La contraseña ingresada no es válida.",
      uid: "El UID no es válido.",
      code: "El código no es válido.",
      email: "El correo electrónico no es válido.",
      loginId: "El correo electrónico no es válido."
    }
  },
  cartNotFound: "No se encontró el carro.",
  invalidCodeProvided: "Código proporcionado no válido.",
  voucherExceeded: "Este cupón superó la cantidad de veces que se puede usar",
  unknownIdentifier: "Artículo no encontrado.",
  otherCartErrors: "Se produjeron errores con el carro."
};
var miniCart$c = {
  item: "Hay {{count}} artículo en su carro en este momento",
  item_other: "Hay {{count}} artículos en su carro en este momento",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$c = {
  skipTo: "Saltar a",
  labels: {
    header: "Encabezado",
    main: "Contenido principal",
    footer: "Pie de página",
    productFacets: "Facetas de producto",
    productList: "Lista de productos"
  }
};
var carousel$c = {
  previousSlide: "Diapositiva anterior",
  nextSlide: "Diapositiva siguiente",
  slideNumber: "La diapositiva {{currentSlideNumber}} contiene {{numberOfSlides}} artículos",
  carouselForProduct: "Carrusel, imágenes para {{product}}"
};
var formErrors$c = {
  labeled: {
    required: "El campo {{label}} es obligatorio",
    cxInvalidEmail: "El campo {{label}} no tiene un formato de correo electrónico válido. Debe coincidir con el patrón: example@yourdomain.com",
    cxInvalidPassword: "El campo {{label}} no tiene un formato de contraseña válido",
    cxMinOneUpperCaseCharacter: "La contraseña debe contener al menos un carácter en mayúsculas",
    cxMinOneDigit: "La contraseña debe contener al menos un dígito",
    cxMinOneSpecialCharacter: "La contraseña debe contener al menos un carácter especial",
    cxMinSixCharactersLength: "La contraseña debe contener al menos 6 caracteres",
    cxMinEightCharactersLength: "La contraseña debe contener al menos 8 caracteres",
    cxMaxCharactersLength: "La contraseña no puede contener más de 128 caracteres",
    cxContainsSpecialCharacters: "La contraseña no puede contener caracteres especiales",
    cxNoConsecutiveCharacters: "La contraseña no puede contener caracteres idénticos consecutivos",
    date: {
      required: "El campo {{label}} es obligatorio",
      min: "El campo {{label}} no puede estar antes de {{min}}",
      max: "El campo {{label}} no puede estar después de {{max}}",
      pattern: "Use el formato de fecha aaaa-mm-dd en el campo {{label}}",
      invalid: "Use una fecha válida en el campo {{label}}"
    }
  },
  globalMessage: "El formulario que intenta enviar tiene errores.",
  required: "Este campo es obligatorio",
  cxInvalidEmail: "Este no es un formato de correo electrónico válido",
  cxInvalidPassword: "Este no es un formato de contraseña válido",
  cxPasswordsMustMatch: "Los campos de contraseña deben coincidir",
  cxPasswordsCannotMatch: "Los campos de contraseña no deben coincidir",
  cxEmailsMustMatch: "Los campos de correo electrónico deben coincidir",
  cxStarRatingEmpty: "El campo de calificación es obligatorio",
  cxNoSelectedItemToCancel: "Seleccione al menos un artículo",
  cxNegativeAmount: "El importe debe ser igual o mayor que cero",
  cxContainsSpecialCharacters: "El campo no puede tener caracteres especiales",
  date: {
    required: "Este campo es obligatorio",
    min: "La fecha no puede ser anterior a {{min}}",
    max: "La fecha no puede ser posterior a {{max}}",
    pattern: "Use el formato de fecha aaaa-mm-dd",
    invalid: "Use una fecha válida"
  },
  file: {
    required: "El archivo es obligatorio",
    empty: "El archivo no debe estar vacío",
    invalidExtension: "La extensión del archivo no es válido",
    tooLarge: "El tamaño del archivo no debe superar los {{ maxSize }} MB",
    tooManyEntries: "El número de artículos es mayor que {{ maxEntries }}",
    notParsable: "El archivo no se puede analizar sintácticamente",
    fileNotAllowed: "Este tipo de archivo no está permitido"
  }
};
var errorHandlers$c = {
  scriptFailedToLoad: "Error al cargar el script.",
  refreshThePage: "Actualice la página."
};
var assistiveMessage$c = {
  actionCancelled: "Acción cancelada. No se realizaron cambios"
};
var passwordVisibility$c = {
  showPassword: "Mostrar contraseña",
  hidePassword: "Ocultar contraseña"
};
var generalErrors$c = {
  pageFailure: "No se pudo cargar la página. Inténtelo de nuevo más tarde."
};
var formLegend$c = {
  required: "Los siguientes campos marcados con \"*\" son obligatorios."
};
var chatMessaging$c = {
  charactersLeft: "caracteres restantes: {{count}}",
  addNewMessage: "Agregar nuevo mensaje",
  send: "Enviar",
  uploadFile: "Cargar archivo",
  informationLabel: "{{author}}. {{text}} en {{date}}",
  messages: "Mensajes",
  addMessagePlaceHolder: "Empiece a escribir...",
  characterLimitAlert: "Se alcanzó el límite de caracteres.",
  a11y: {
    itemListBoxLabel: "Seleccione el artículo al cual se debe aplicar el mensaje."
  }
};
var common$p = {
  common: common$o,
  pageMetaResolver: pageMetaResolver$c,
  spinner: spinner$c,
  navigation: navigation$c,
  searchBox: searchBox$c,
  sorting: sorting$c,
  httpHandlers: httpHandlers$c,
  miniCart: miniCart$c,
  skipLink: skipLink$c,
  carousel: carousel$c,
  formErrors: formErrors$c,
  errorHandlers: errorHandlers$c,
  assistiveMessage: assistiveMessage$c,
  passwordVisibility: passwordVisibility$c,
  generalErrors: generalErrors$c,
  formLegend: formLegend$c,
  chatMessaging: chatMessaging$c
};
var setDeliveryMode$c = {
  unknownError: "Ocurrió un error desconocido. Póngase en contacto con el servicio de soporte."
};
var deliveryMode$c = {
  setDeliveryMode: setDeliveryMode$c
};
var myAccountV2Consent$o = {
  header: "Administración de consentimientos",
  dateDescription: "Fecha de aprobación ",
  clearAll: "Desactivar todo",
  selectAll: "Activar todo",
  message: {
    success: {
      given: "Consentimiento otorgado correctamente.",
      withdrawn: "Consentimiento retirado correctamente."
    }
  }
};
var myAccountV2Consent$p = {
  myAccountV2Consent: myAccountV2Consent$o
};
var myAccountV2NotificationPreference$o = {
  header: "Canales de notificaciones",
  message: "Seleccione sus canales de notificación preferidos",
  note: "Nota: ",
  noteMessage: "Si desactiva todos los canales, no podrá recibir más notificaciones.",
  EMAIL: "Correo electrónico:"
};
var myAccountV2NotificationPreference$p = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$o
};
var closeAccount$c = {
  confirmAccountClosure: "Confirmar cierre de cuenta",
  confirmAccountClosureMessage: "¿Confirma que desea cerrar su cuenta?",
  closeMyAccount: "CERRAR MI CUENTA",
  accountClosedSuccessfully: "Cuenta cerrada correctamente",
  accountClosedFailure: "Error al cerrar la cuenta"
};
var updatePasswordForm$c = {
  oldPassword: {
    label: "Contraseña anterior",
    placeholder: "Contraseña anterior"
  },
  oldPasswordIsRequired: "Se requiere la contraseña anterior.",
  newPassword: {
    label: "Contraseña nueva",
    placeholder: "Contraseña nueva"
  },
  passwordMinRequirements: "Esta es una guía rápida para generar una contraseña segura: utilice al menos 8 caracteres e incluya letras en mayúscula y minúscula, números y símbolos.",
  passwordStrengthRequirements: "Esta es una guía rápida para generar una contraseña segura: utilice al menos 8 caracteres e incluya letras en mayúscula y minúscula, números y símbolos. Asegúrese de no utilizar el mismo carácter de forma consecutiva.",
  confirmPassword: {
    label: "Confirmar contraseña nueva",
    placeholder: "Confirmar contraseña"
  },
  bothPasswordMustMatch: "Ambas contraseñas deben coincidir",
  passwordUpdateSuccess: "Contraseña actualizada correctamente",
  accessDeniedError: "Se denegó el acceso"
};
var updateProfileForm$c = {
  title: "Tratamiento",
  none: "",
  firstName: {
    label: "Nombre",
    placeholder: "Nombre"
  },
  firstNameIsRequired: "Se requiere el nombre.",
  lastName: {
    label: "Apellido",
    placeholder: "Apellido"
  },
  lastNameIsRequired: "Se requiere el apellido.",
  profileUpdateSuccess: "Detalles personales actualizados correctamente",
  customerId: "Cliente n. º"
};
var consentManagementForm$c = {
  clearAll: "Borrar todo",
  selectAll: "Seleccionar todo",
  message: {
    success: {
      given: "Consentimiento otorgado correctamente.",
      withdrawn: "Consentimiento retirado correctamente."
    }
  }
};
var myCoupons$c = {
  noCouponsMessage: "No tiene cupones disponibles.",
  effectiveTitle: "Entrada en vigencia:",
  Effective: "EN VIGENCIA",
  PreSession: "EN VIGENCIA PRONTO",
  ExpireSoon: "VENCE PRONTO",
  readMore: "Más información",
  notification: "Notificación",
  findProducts: "Buscar productos",
  status: "Estado:",
  dialogTitle: "Cupón",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Código de cupón",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Ha reclamado correctamente este cupón.",
  myCoupons: "Mis cupones",
  startDateAsc: "Fecha de inicio (ascendente)",
  startDateDesc: "Fecha de inicio (descendente)",
  endDateAsc: "Fecha de fin (ascendente)",
  endDateDesc: "Fecha de fin (descendente)",
  sortBy: "Clasificar por",
  sortCoupons: "Clasificar cupones",
  notesPreffix: "Puede definir sus canales favoritos para recibir notificaciones sobre cupones en la página ",
  notesLink: "Canales de notificación",
  notesSuffix: " ."
};
var notificationPreference$c = {
  message: "Seleccione sus canales de notificación preferidos",
  note: "Nota: ",
  noteMessage: "Si desactiva todos los canales, no pdorá recibir más notificaciones.",
  EMAIL: "Correo electrónico:",
  SMS: "SMS:",
  SITE_MESSAGE: "SiteMessage"
};
var myInterests$c = {
  header: "Mis intereses",
  item: "ARTÍCULO",
  price: "PRECIO",
  notifications: "NOTIFICACIONES",
  noInterests: "Aún no tiene intereses registrados.",
  inStock: "En stock",
  lowStock: "Existencias bajas",
  outOfStock: "No disponible en stock",
  BACK_IN_STOCK: "De vuelta en stock",
  sortBy: "Clasificar por",
  sortInterests: "Clasificar intereses",
  expirationDate: " - hasta {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Eliminar",
  sorting: {
    byNameAsc: "Nombre (ascendente)",
    byNameDesc: "Nombre (descendente)"
  },
  actions: "ACCIONES",
  caption: "Contenido de Mis intereses.",
  itemRemoved: "Se eliminó el artículo seleccionado."
};
var myAccount$c = {
  closeAccount: closeAccount$c,
  updatePasswordForm: updatePasswordForm$c,
  updateProfileForm: updateProfileForm$c,
  consentManagementForm: consentManagementForm$c,
  myCoupons: myCoupons$c,
  notificationPreference: notificationPreference$c,
  myInterests: myInterests$c
};
var paymentForm$c = {
  payment: "Pago",
  choosePaymentMethod: "Seleccione un método de pago",
  paymentType: "Tipo de pago",
  accountHolderName: {
    label: "Nombre del titular de la cuenta",
    placeholder: "Nombre del titular de la cuenta"
  },
  cardNumber: "Número de tarjeta",
  expirationDate: "Fecha de caducidad",
  securityCode: "Código de seguridad (CVV)",
  securityCodeTitle: "Valor de verificación de tarjeta",
  saveAsDefault: "Guardar como predeterminado",
  setAsDefault: "Guardar como método de pago predeterminado",
  billingAddress: "Dirección de facturación",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "La misma que la dirección de entrega",
  billingAddressSameAsShipping: "La dirección de facturación es la misma que la dirección de entrega",
  selectOne: "Seleccionar uno...",
  monthMask: "MM",
  yearMask: "AAAA",
  expirationYear: "Año de vencimiento {{ selected }}",
  expirationMonth: "Mes de vencimiento {{ selected }}",
  useThisPayment: "Utilizar este pago",
  addNewPayment: "Agregar nuevo pago",
  paymentAddedSuccessfully: "El nuevo pago fue agregado correctamente",
  changePayment: "Cambiar pago"
};
var paymentMethods$c = {
  paymentMethods: "Métodos de pago",
  paymentMethodSelected: "Método de pago seleccionado",
  newPaymentMethodsAreAddedDuringCheckout: "Los nuevos métodos de pago se añaden durante la finalización de la compra.",
  invalidField: "Campo no válido: {{ field }}"
};
var paymentCard$c = {
  deleteConfirmation: "¿Está seguro de que quiere eliminar este método de pago?",
  setAsDefault: "Fijar como predeterminado",
  expires: "Vencimiento: {{ month }}/{{ year }}",
  defaultPaymentMethod: "PREDETERMINADO",
  defaultPaymentLabel: "Método de pago predeterminado",
  additionalPaymentLabel: "Método de pago adicional {{ number }}",
  selected: "Seleccionado",
  selectedPayment: "Pago seleccionado",
  deletePaymentSuccess: "Método de pago eliminado correctamente",
  visa: "Tarjeta Visa",
  master: "Tarjeta Mastercard",
  masterEuro: "Tarjeta Mastercard Eurocard",
  dinersClub: "Tarjeta Diners Club",
  "switch": "Tarjeta Switch",
  maestro: "Tarjeta Maestro",
  amex: "Tarjeta American Express",
  credit: "Tarjeta de crédito"
};
var paymentTypes$c = {
  title: "Método de pago",
  paymentType_CARD: "Tarjeta de crédito",
  paymentType_ACCOUNT: "Cuenta"
};
var paymentMessages$c = {
  setAsDefaultSuccessfully: "El nuevo pago se definió correctamente como predeterminado"
};
var payment$c = {
  paymentForm: paymentForm$c,
  paymentMethods: paymentMethods$c,
  paymentCard: paymentCard$c,
  paymentTypes: paymentTypes$c,
  paymentMessages: paymentMessages$c
};
var pdf$o = {
  defaultTitle: "Documento"
};
var pdf$p = {
  pdf: pdf$o
};
var productDetails$c = {
  id: "ID",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  productDetails: "Detalles del producto",
  specification: "Especificaciones",
  reviews: "Reseñas",
  shipping: "Envío",
  share: "Compartir",
  showReviews: "Mostrar opiniones",
  noReviews: "No tiene opiniones aún",
  productPrice: "Precio de producto",
  noProductImage: "No hay imagen disponible, {{ product }}"
};
var productList$c = {
  filterBy: {
    label: "Filtrar por",
    action: "Filtrar por"
  },
  activeFilter: "filtro {{filter}}, al hacer clic en este botón, se eliminará el filtro",
  appliedFilter: "Filtro aplicado:",
  showLess: "Mostrar menos...",
  showMore: "Mostrar más...",
  sortBy: "Clasificar por",
  sortResults: "Clasificar resultados",
  backToTopBtn: "VOLVER AL INICIO",
  showMoreBtn: "MOSTRAR MÁS",
  productSearchPagination: "Paginación de búsqueda de producto",
  productListResults: "Lista de resultados de producto"
};
var productFacetNavigation$c = {
  filterBy: {
    label: "Filtrar por",
    action: "Filtrar por",
    facet: "Filtrar resultados por facetas",
    name: "Filtrar por {{name}}"
  },
  appliedFilter: "Filtro aplicado:",
  showLess: "Mostrar menos...",
  showMore: "Mostrar más...",
  sortBy: "Clasificar por",
  ariaLabelShowLess: "Botón Mostrar menos, al hacer clic en este botón se reducirán las opciones del grupo activo",
  ariaLabelShowMore: "Botón Mostrar más, al hacer clic en este botón se mostrarán todas las opciones para el grupo activo",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} artículo disponible",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} artículos disponibles",
  decreaseOptionsVisibility: "Las opciones estaban ocultas en el grupo activo, vuelva atrás para leerlas o vaya hacia delante para el siguiente grupo.",
  increaseOptionsVisibility: "Se han añadido más opciones al grupo activo, vuelva atrás para leerlas o vaya hacia delante para el siguiente grupo.",
  backToResults: "Volver a los resultados",
  productFacets: "Facetas de producto"
};
var productSummary$c = {
  id: "ID",
  showReviews: "Mostrar opiniones",
  showReviewsDetailed: "Mostrar {{count}} opiniones con calificación de {{rating}} de 5 estrellas",
  share: "Compartir",
  newItemPrice: "Nuevo precio del artículo"
};
var productReview$c = {
  overallRating: "Calificación general",
  reviewTitle: "Título de la opinión",
  writeYourComments: "Escriba sus comentarios",
  rating: "Calificación",
  ratingRequired: "Calificación de producto requerida",
  addRate: "Agregar calificación: {{count}} estrella",
  addRate_other: "Agregar calificación: {{count}} estrellas",
  reviewerName: "Nombre del revisor (opcional)",
  writeReview: "Escriba una opinión",
  more: "Mostrar más opiniones",
  less: "Mostrar menos opiniones",
  thankYouForReview: "Gracias por su opinión. Tenga en cuenta que las opiniones pueden requerir revisión antes de aparecer aquí.",
  postReviewFail: "Algo salió mal al publicar su opinión. Vuelva a intentarlo más tarde.",
  ratedOutOf: "Calificación: {{rating}} de 5"
};
var productCarousel$c = {
  carouselLabel: "Carrusel, {{title}}"
};
var addToCart$c = {
  itemAddedToYourCart: "Artículo agregado a su carro",
  itemsAddedToYourCart: "Artículos agregados a su carro",
  itemsIncrementedInYourCart: "Este artículo ya estaba en su carro. Se actualizó la cantidad.",
  items: "artículos",
  updatingCart: "Actualizando carro...",
  addToCart: "Agregar al carro",
  viewCart: "ver carro",
  proceedToCheckout: "proceder a finalizar la compra",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  outOfStock: "Fuera de stock",
  inStock: "En stock",
  selectStyleAndSize: "Seleccione estilo y tamaño para consultar en el stock",
  removeFromCart: "Eliminar producto del carro",
  closeModal: "Cerrar modal",
  buyItAgain: "Volver a comprar",
  addToActiveCart: "Agregar para activar carro"
};
var TabPanelContainer$c = {
  tabs: {
    ProductDetailsTabComponent: "Detalles del producto",
    ProductSpecsTabComponent: "Especificaciones",
    ProductReviewsTabComponent: "Opiniones",
    deliveryTab: "Envío",
    SparePartsTabComponent: " Piezas de repuesto"
  },
  tabPanelContainerRegion: "Grupo de pestañas con más detalles de producto",
  tabPanelContainerRegionGroup: "Grupo con más detalles de producto"
};
var addToWishList$c = {
  add: "Agregar a la lista de deseos",
  remove: "Eliminar de la lista de deseos",
  anonymous: "Inicie sesión para agregar a la lista de deseos",
  addedToWishList: "Producto agregado a la lista de deseos",
  removedFromWishList: "Producto eliminado de la lista de deseos"
};
var stockNotification$c = {
  notifyMe: "NOTIFICARME",
  stopNotify: "DETENER NOTIFICACIÓN",
  getNotify: "Reciba una notificación cuando este producto esté disponible.",
  getNotifySuffix: "para recibir una notificación cuando este producto esté disponible.",
  activateChannelsPrefix: "Para recibir una notificación, debe activar los ",
  channelsLink: "Canales de notificaciones",
  activateChannelsSuffix: ".",
  notified: "Se le notificará cuando este producto vuelva a estar disponible.",
  getNotified: "Reciba una notificación cuando este producto vuelva a estar disponible.",
  unsubscribeSuccess: "No recibirá ninguna notificación de reposición de stock de este producto.",
  subscriptionDialog: {
    header: "Suscripción a fuera de stock",
    notifiedPrefix: "Se le notificará:",
    notifiedSuffix: "apenas este producto vuelva a estar disponible.",
    manageChannelsPrefix: "Administre sus canales de notificación preferidos en la ",
    manageChannelsLink: "página Preferencia de notificación",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "Puede administrar sus suscripciones en ",
    manageSubscriptionsLink: "la página Mis intereses",
    manageSubscriptionsSuffix: " .",
    okBtn: "Aceptar",
    subscribing: "Suscribiéndole a notificaciones de fuera de stock para este producto"
  }
};
var itemCounter$c = {
  removeOne: "Eliminar una",
  addOneMore: "Agregar una más",
  quantity: "Cantidad"
};
var productView$c = {
  gridView: "Seleccione para cambiar la Vista de cuadrícula",
  listView: "Seleccione para cambiar a Vista de lista"
};
var product$c = {
  productDetails: productDetails$c,
  productList: productList$c,
  productFacetNavigation: productFacetNavigation$c,
  productSummary: productSummary$c,
  productReview: productReview$c,
  productCarousel: productCarousel$c,
  addToCart: addToCart$c,
  TabPanelContainer: TabPanelContainer$c,
  addToWishList: addToWishList$c,
  stockNotification: stockNotification$c,
  itemCounter: itemCounter$c,
  productView: productView$c
};
var pwa$o = {
  addToHomeScreenDescription: "Agregue SAP Storefront a la pantalla de inicio de su dispositivo para una visita de retorno más rápida",
  noInstallationNeeded: "No se requiere instalación",
  fastAccessToApplication: "Acceso rápido a las aplicaciones",
  addToHomeScreen: "Agregar a la pantalla de inicio",
  addedToHomeScreen: "SAP Storefront se agregó a su pantalla de inicio"
};
var pwa$p = {
  pwa: pwa$o
};
var siteThemeSwitcher$o = {
  theme: "Tema",
  themes: {
    "default": "Predeterminado",
    highContrastDark: "HC-Dark",
    highContrastLight: "HC-Light"
  }
};
var siteThemeSwitcher$p = {
  siteThemeSwitcher: siteThemeSwitcher$o
};
var anonymousConsents$c = {
  dialog: {
    title: "Administración de consentimiento",
    legalDescription: "Utilizamos cookies/almacenamiento del navegador para personalizar el contenido y mejorar la experiencia del usuario. También podemos compartir los datos sobre su utilización del sitio con nuestras redes sociales. Para más información, consulte nuestra política de privacidad.",
    selectAll: "Seleccionar todo",
    clearAll: "Borrar todo"
  },
  banner: {
    title: "Este sitio web utiliza cookies",
    description: "Utilizamos cookies/almacenamiento del navegador para personalizar el contenido y mejorar la experiencia del usuario.",
    allowAll: "Permitir todo",
    viewDetails: "Ver detalles",
    consentManagement: "Administración de consentimiento"
  }
};
var checkoutLogin$c = {
  emailAddress: {
    label: "Dirección de correo electrónico",
    placeholder: "Ingresar correo electrónico"
  },
  confirmEmail: {
    label: "Confirmar correo electrónico",
    placeholder: "Confirmar correo electrónico"
  },
  "continue": "Continuar",
  emailIsRequired: "Formato de correo electrónico no válido",
  emailsMustMatch: "El correo electrónico no coincide"
};
var authMessages$c = {
  signedOutSuccessfully: "Ha cerrado la sesión correctamente."
};
var user$c = {
  anonymousConsents: anonymousConsents$c,
  checkoutLogin: checkoutLogin$c,
  authMessages: authMessages$c
};
var player$c = {
  label: "Reproductor de videos"
};
var video$c = {
  player: player$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  common: common$p,
  deliveryMode: deliveryMode$c,
  myAccountV2Consent: myAccountV2Consent$p,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$p,
  myAccount: myAccount$c,
  payment: payment$c,
  pdf: pdf$p,
  product: product$c,
  pwa: pwa$p,
  siteThemeSwitcher: siteThemeSwitcher$p,
  user: user$c,
  video: video$c
};
var common$m = {
  cancel: "Annuler",
  "delete": "Supprimer",
  remove: "Retirer",
  edit: "Modifier",
  restore: "Restaurer",
  back: "Retour",
  submit: "Envoyer",
  "continue": "Poursuivre",
  save: "Sauvegarder",
  done: "Terminé",
  home: "Accueil",
  noData: "Aucune donnée",
  confirm: "Confirmer",
  more: "plus",
  close: "Fermer",
  loading: "Chargement",
  menu: "Menu",
  reset: "Réinitialiser",
  search: "Rechercher",
  add: "Ajouter",
  breadcrumbs: "Chemin de navigation",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Sélectionner le fichier",
  clear: "Effacer",
  loaded: "Chargé",
  results: "Résultats",
  of: "sur",
  ngSelectDropdownOptionsList: "Options",
  required: "obligatoire",
  zoomIn: "Zoom avant",
  zoomOut: "Zoom arrière",
  selected: "Sélectionné",
  expand: "Développer",
  collapse: "Réduire"
};
var pageMetaResolver$b = {
  category: {
    title: "{{count}} résultat pour {{query}}",
    title_other: "{{count}} résultats pour {{query}}"
  },
  checkout: {
    title: "Règlement"
  },
  search: {
    title: "{{count}} résultat pour \"{{query}}\"",
    title_other: "{{count}} résultats pour \"{{query}}\"",
    findProductTitle: "{{count}} résultat pour le coupon \"{{coupon}}\"",
    findProductTitle_other: "{{count}} résultats pour le coupon \"{{coupon}}\"",
    default_title: "Tous les produits"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$b = {
  loading: "Chargement en cours..."
};
var navigation$b = {
  categoryNavLabel: "Menu Catégorie",
  footerNavLabel: "Liens de pied de page",
  goTo: "Passer à {{location}}",
  navigateTo: "Naviguer vers {{nav}}",
  scrollToTop: "Revenir en haut de la page",
  linkItemInList: "{{title}}. {{position}} sur {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$b = {
  placeholder: "Saisir le nom du produit ou l'UGS",
  productSearch: "Rechercher un produit",
  ariaLabelInput: "Rechercher ici...",
  ariaLabelSuggestions: "Suggestions de saisie",
  ariaLabelProducts: "Résultats de produits",
  initialDescription: "Lorsque les résultats du remplissage automatique sont disponibles, utilisez les flèches vers le haut et vers le bas pour passer en revue et la touche Entrée pour sélectionner. Pour les utilisateurs d'appareils tactiles, explorez par le toucher ou par balayage.",
  suggestions: "Mots-clés suggérés",
  recentSearches: "Recherches récentes",
  trendingSearches: "Recherches tendances",
  suggestionsResult: "{{ count }} suggestion et ",
  suggestionsResult_other: "{{ count }} suggestions et ",
  products: "Produits recommandés",
  productsResult: "{{ count }} produit est disponible.",
  productsResult_other: "{{ count }} produits sont disponibles.",
  resetLabel: "Réinitialiser la recherche",
  help: {
    insufficientChars: "Veuillez saisir davantage de caractères",
    noMatch: "Nous n'avons pas trouvé de résultats",
    exactMatch: "{{ term }}",
    empty: "Demandez-nous ce que vous voulez"
  },
  closeSearchPanel: "Fermer"
};
var sorting$b = {
  date: "Date",
  orderNumber: "Numéro de commande",
  rma: "Numéro de retour",
  replenishmentNumber: "Numéro de réapprovisionnement",
  nextOrderDate: "Prochaine date de commande",
  pageViewUpdated: "La vue de page a été mise à jour avec vos options de sélection."
};
var httpHandlers$b = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Veuillez vous reconnecter.",
    user_is_disabled: "{{ errorMessage }}. Veuillez contacter l'administration."
  },
  badGateway: "Une erreur du serveur s'est produite. Veuillez réessayer ultérieurement.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Veuillez vous reconnecter.",
  badRequestOldPasswordIncorrect: "L'ancien mot de passe est incorrect.",
  badRequestGuestDuplicateEmail: "L'e-mail {{ errorMessage }} existe déjà. Veuillez valider la commande avec un autre e-mail pour vous inscrire via un compte d'invité.",
  conflict: "Déjà existant",
  forbidden: "Vous n'êtes pas autorisé à effectuer cette action. Veuillez contacter votre administrateur si vous pensez qu'il s'agit d'une erreur.",
  gatewayTimeout: "Le serveur n'a pas répondu. Veuillez réessayer ultérieurement.",
  internalServerError: "Une erreur de serveur interne s'est produite. Veuillez réessayer ultérieurement.",
  sessionExpired: "Votre session a expiré. Veuillez vous reconnecter.",
  unknownError: "Une erreur inconnue s'est produite.",
  unauthorized: {
    common: "Une erreur d'autorisation inconnue s'est produite.",
    invalid_client: "Identifiants client incorrects"
  },
  validationErrors: {
    missing: {
      card_cardType: "La carte de crédit sélectionnée n'est pas prise en charge. Veuillez en sélectionner un autre.",
      card_accountNumber: "Le numéro de carte de crédit que vous avez saisi n'est pas valide.",
      card_cvNumber: "Le code de sécurité que vous avez saisi n'est pas valide.",
      card_expirationMonth: "La date d'expiration de la carte de crédit que vous avez saisie n'est pas valide.",
      card_expirationYear: "La date d'expiration de la carte de crédit que vous avez saisie n'est pas valide.",
      billTo_firstName: "Le prénom que vous avez saisi n'est pas valide.",
      billTo_lastName: "Le nom de famille que vous avez saisi n'est pas valide.",
      billTo_street1: "L'adresse que vous avez saisie n'est pas valide.",
      billTo_street2: "L'adresse que vous avez saisie n'est pas valide.",
      billTo_city: "La ville saisie n'est pas valide pour cette carte de crédit.",
      billTo_state: "L'État/la province saisi(e) n'est pas valide pour cette carte de crédit.",
      billTo_country: "Le pays/la région saisi(e) n'est pas valide pour cette carte de crédit.",
      billTo_postalCode: "Le code postal n'est pas valide pour cette carte de crédit.",
      country: {
        isocode: "Pays/Région manquant(e)"
      }
    },
    invalid: {
      card_expirationMonth: "La date d'expiration de la carte de crédit que vous avez saisie n'est pas valide.",
      firstName: "Le prénom que vous avez saisi n'est pas valide.",
      lastName: "Le nom de famille que vous avez saisi n'est pas valide.",
      password: "Le mot de passe que vous avez saisi n'est pas valide.",
      uid: "L'UID n'est pas valide.",
      code: "Le code n'est pas valide.",
      email: "L'e-mail n'est pas valide.",
      loginId: "L'e-mail n'est pas valide."
    }
  },
  cartNotFound: "Panier non trouvé",
  invalidCodeProvided: "Le code fourni n'est pas valide.",
  voucherExceeded: "Ce coupon a dépassé le nombre de fois où il peut être utilisé.",
  unknownIdentifier: "Article non trouvé",
  otherCartErrors: "Des erreurs avec le panier se sont produites."
};
var miniCart$b = {
  item: "{{count}} article est actuellement dans votre panier.",
  item_other: "{{count}} articles sont actuellement dans votre panier.",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$b = {
  skipTo: "Passer à",
  labels: {
    header: "En-tête",
    main: "Contenu principal",
    footer: "Pied de page",
    productFacets: "Facettes de produits",
    productList: "Liste des produits"
  }
};
var carousel$b = {
  previousSlide: "Diapositive précédente",
  nextSlide: "Diapositive suivante",
  slideNumber: "La diapositive {{currentSlideNumber}} contient {{numberOfSlides}} articles.",
  carouselForProduct: "Carrousel, images pour {{product}}"
};
var formErrors$b = {
  labeled: {
    required: "La zone {{label}} est obligatoire.",
    cxInvalidEmail: "Le format d'e-mail de la zone {{label}} n'est pas valide. Modèle de correspondance : example@yourdomain.com",
    cxInvalidPassword: "Le format du mot de passe de la zone {{label}} n'est pas valide.",
    cxMinOneUpperCaseCharacter: "Le mot de passe doit contenir au moins un lettre en majuscule.",
    cxMinOneDigit: "Le mot de passe doit contenir au moins un chiffre.",
    cxMinOneSpecialCharacter: "Le mot de passe doit contenir au moins un caractère spécial.",
    cxMinSixCharactersLength: "Le mot de passe doit contenir au moins 6 caractères.",
    cxMinEightCharactersLength: "Le mot de passe doit contenir au moins 8 caractères.",
    cxMaxCharactersLength: "Le mot de passe ne peut pas avoir plus de 128 caractères.",
    cxContainsSpecialCharacters: "Le mot de passe ne doit pas contenir de caractères spéciaux.",
    cxNoConsecutiveCharacters: "Le mot de passe ne doit pas contenir de caractères identiques consécutifs.",
    date: {
      required: "La zone {{label}} est obligatoire.",
      min: "La zone {{label}} ne peut pas être avant le {{min}}.",
      max: "La zone {{label}} ne peut pas être après le {{max}}.",
      pattern: "Utilisez le format de date aaaa-mm-jj dans la zone {{label}}.",
      invalid: "Utilisez une date valide dans la zone {{label}}."
    }
  },
  globalMessage: "Le formulaire que vous tentez d'envoyer contient des erreurs.",
  required: "Cette zone est obligatoire.",
  cxInvalidEmail: "Le format de l'e-mail n'est pas valide.",
  cxInvalidPassword: "Le format du mot de passe n'est pas valide.",
  cxPasswordsMustMatch: "Les zones du mot de passe doivent correspondre.",
  cxPasswordsCannotMatch: "Les zones du mot de passe ne correspondent pas.",
  cxEmailsMustMatch: "Les zones de l'e-mail doivent correspondre.",
  cxStarRatingEmpty: "La zone de notation est obligatoire.",
  cxNoSelectedItemToCancel: "Sélectionnez au moins un article.",
  cxNegativeAmount: "Le montant doit être supérieur ou égal à zéro.",
  cxContainsSpecialCharacters: "La zone ne doit pas contenir de caractères spéciaux.",
  date: {
    required: "Cette zone est obligatoire.",
    min: "La date ne peut pas être avant le {{min}}.",
    max: "La date ne peut pas être après le {{max}}",
    pattern: "Utilisez le format de date aaaa-mm-jj.",
    invalid: "Utilisez une date valide."
  },
  file: {
    required: "Le fichier est requis.",
    empty: "Le fichier ne doit pas être vide.",
    invalidExtension: "L'extension de fichier n'est pas valide.",
    tooLarge: "La taille du fichier ne doit pas dépasser {{ maxSize }} Mo.",
    tooManyEntries: "Le nombre d'articles est supérieur à {{ maxEntries }}.",
    notParsable: "Impossible d'analyser le fichier",
    fileNotAllowed: "Ce type de fichier n'est pas autorisé."
  }
};
var errorHandlers$b = {
  scriptFailedToLoad: "Échec de chargement du script",
  refreshThePage: "Veuillez rafraîchir la page."
};
var assistiveMessage$b = {
  actionCancelled: "Action annulée, aucune modification n'a été apportée."
};
var passwordVisibility$b = {
  showPassword: "Afficher le mot de passe",
  hidePassword: "Masquer le mot de passe"
};
var generalErrors$b = {
  pageFailure: "Impossible de charger la page. Veuillez réessayer ultérieurement."
};
var formLegend$b = {
  required: "Les zones suivantes marquées d'un \"*\" sont obligatoires."
};
var chatMessaging$b = {
  charactersLeft: "Caractères restants : {{count}}",
  addNewMessage: "Ajouter un nouveau message",
  send: "Envoyer",
  uploadFile: "Charger un fichier",
  informationLabel: "{{author}}. {{text}} à {{date}}",
  messages: "Messages",
  addMessagePlaceHolder: "Commencez à saisir des caractères...",
  characterLimitAlert: "La limite de caractères a été atteinte.",
  a11y: {
    itemListBoxLabel: "Sélectionnez l'article auquel le message doit s'appliquer."
  }
};
var common$n = {
  common: common$m,
  pageMetaResolver: pageMetaResolver$b,
  spinner: spinner$b,
  navigation: navigation$b,
  searchBox: searchBox$b,
  sorting: sorting$b,
  httpHandlers: httpHandlers$b,
  miniCart: miniCart$b,
  skipLink: skipLink$b,
  carousel: carousel$b,
  formErrors: formErrors$b,
  errorHandlers: errorHandlers$b,
  assistiveMessage: assistiveMessage$b,
  passwordVisibility: passwordVisibility$b,
  generalErrors: generalErrors$b,
  formLegend: formLegend$b,
  chatMessaging: chatMessaging$b
};
var setDeliveryMode$b = {
  unknownError: "Une erreur inconnue s'est produite. Veuillez contacter le service d'assistance."
};
var deliveryMode$b = {
  setDeliveryMode: setDeliveryMode$b
};
var myAccountV2Consent$m = {
  header: "Gestion des consentements",
  dateDescription: "Approuvé le ",
  clearAll: "Désactiver tout",
  selectAll: "Activer tout",
  message: {
    success: {
      given: "Consentement donné avec succès",
      withdrawn: "Consentement retiré avec succès"
    }
  }
};
var myAccountV2Consent$n = {
  myAccountV2Consent: myAccountV2Consent$m
};
var myAccountV2NotificationPreference$m = {
  header: "Canaux de notification",
  message: "Sélectionnez vos canaux de notification préférés",
  note: "Remarque : ",
  noteMessage: "Si vous désactivez tous les canaux, vous ne pourrez plus recevoir de notifications.",
  EMAIL: "E-mail :"
};
var myAccountV2NotificationPreference$n = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$m
};
var closeAccount$b = {
  confirmAccountClosure: "Confirmer la fermeture du compte",
  confirmAccountClosureMessage: "Voulez-vous vraiment fermer votre compte ?",
  closeMyAccount: "FERMER MON COMPTE",
  accountClosedSuccessfully: "Le compte a été fermé avec succès.",
  accountClosedFailure: "La fermeture du compte a échoué."
};
var updatePasswordForm$b = {
  oldPassword: {
    label: "Ancien mot de passe",
    placeholder: "Ancien mot de passe"
  },
  oldPasswordIsRequired: "L'ancien mot de passe est obligatoire.",
  newPassword: {
    label: "Nouveau mot de passe",
    placeholder: "Nouveau mot de passe"
  },
  passwordMinRequirements: "Voici un guide rapide pour un mot de passe sûr : Utilisez au moins 8 caractères avec un mélange de lettres majuscules et minuscules, de chiffres et de symboles.",
  passwordStrengthRequirements: "Voici un guide rapide pour un mot de passe sûr : utilisez au moins 8 caractères avec un mélange de lettres majuscules et minuscules, de chiffres et de symboles. Veillez à ne pas utiliser le même caractère consécutivement.",
  confirmPassword: {
    label: "Confirmer le nouveau mot de passe",
    placeholder: "Confirmer le mot de passe"
  },
  bothPasswordMustMatch: "Les deux mots de passe doivent correspondre",
  passwordUpdateSuccess: "Le mot de passe a été mis à jour avec succès",
  accessDeniedError: "Accès refusé"
};
var updateProfileForm$b = {
  title: "Titre",
  none: "",
  firstName: {
    label: "Prénom",
    placeholder: "Prénom"
  },
  firstNameIsRequired: "Prénom requis",
  lastName: {
    label: "Nom de famille",
    placeholder: "Nom de famille"
  },
  lastNameIsRequired: "Nom de famille requis",
  profileUpdateSuccess: "Les données personnelles ont été mises à jour avec succès",
  customerId: "Nº de client"
};
var consentManagementForm$b = {
  clearAll: "Effacer tout",
  selectAll: "Sélectionner tout",
  message: {
    success: {
      given: "Consentement donné avec succès",
      withdrawn: "Consentement retiré avec succès"
    }
  }
};
var myCoupons$b = {
  noCouponsMessage: "Vous n'avez pas de bons de réduction disponibles",
  effectiveTitle: "Valide :",
  Effective: "VALIDE",
  PreSession: "BIENTÔT VALIDE",
  ExpireSoon: "BIENTÔT À EXPIRATION",
  readMore: "En savoir plus",
  notification: "Notification",
  findProducts: "Trouver des produits",
  status: "Statut :",
  dialogTitle: "Bon de réduction",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Code du bon de réduction",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Vous avez demandé avec succès ce bon de réduction.",
  myCoupons: "Mes bons de réduction",
  startDateAsc: "Date de début (croissant)",
  startDateDesc: "Date de début (décroissant)",
  endDateAsc: "Date de fin (croissant)",
  endDateDesc: "Date de début (décroissant)",
  sortBy: "Trier par",
  sortCoupons: "Trier les bons de réduction",
  notesPreffix: "Vous pouvez définir vos canaux préférés pour la réception des notifications de bons de réduction sur la page ",
  notesLink: "Canaux de notification",
  notesSuffix: " ."
};
var notificationPreference$b = {
  message: "Sélectionnez vos canaux de notification préférés",
  note: "Remarque : ",
  noteMessage: "Si vous désactivez tous les canaux, vous ne pourrez plus recevoir de notifications.",
  EMAIL: "E-mail :",
  SMS: "SMS :",
  SITE_MESSAGE: "Message du site"
};
var myInterests$b = {
  header: "Mes intérêts",
  item: "ÉLÉMENT",
  price: "PRIX",
  notifications: "NOTIFICATIONS",
  noInterests: "Vous n'avez pas encore d'intérêts enregistrés.",
  inStock: "En stock",
  lowStock: "Stock faible",
  outOfStock: "Épuisé",
  BACK_IN_STOCK: "De nouveau disponible",
  sortBy: "Trier par",
  sortInterests: "Trier les intérêts",
  expirationDate: " - Jusqu'au {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Supprimer",
  sorting: {
    byNameAsc: "Nom (ordre croissant)",
    byNameDesc: "Nom (ordre décroissant)"
  },
  actions: "ACTIONS",
  caption: "Contenu de Mes intérêts",
  itemRemoved: "L'article sélectionné a été supprimé."
};
var myAccount$b = {
  closeAccount: closeAccount$b,
  updatePasswordForm: updatePasswordForm$b,
  updateProfileForm: updateProfileForm$b,
  consentManagementForm: consentManagementForm$b,
  myCoupons: myCoupons$b,
  notificationPreference: notificationPreference$b,
  myInterests: myInterests$b
};
var paymentForm$b = {
  payment: "Paiement",
  choosePaymentMethod: "Sélectionner un mode de paiement",
  paymentType: "Type de paiement",
  accountHolderName: {
    label: "Nom du titulaire du compte",
    placeholder: "Nom du titulaire du compte"
  },
  cardNumber: "Numéro de carte",
  expirationDate: "Date d'expiration",
  securityCode: "Code de sécurité (CVV)",
  securityCodeTitle: "Valeur de vérification de la carte",
  saveAsDefault: "Enregistrer par défaut",
  setAsDefault: "Définir comme mode de paiement par défaut",
  billingAddress: "Adresse de facturation",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Identique à l'adresse de livraison",
  billingAddressSameAsShipping: "L'adresse de facturation est la même que l'adresse de livraison.",
  selectOne: "Sélectionner un…",
  monthMask: "MM",
  yearMask: "AAAA",
  expirationYear: "Année d'expiration {{ selected }}",
  expirationMonth: "Mois d'expiration {{ selected }}",
  useThisPayment: "Utiliser ce paiement",
  addNewPayment: "Ajouter un nouveau paiement",
  paymentAddedSuccessfully: "Le nouveau paiement a été ajouté avec succès.",
  changePayment: "Modifier le paiement"
};
var paymentMethods$b = {
  paymentMethods: "Modes de paiement",
  paymentMethodSelected: "Mode de paiement sélectionné",
  newPaymentMethodsAreAddedDuringCheckout: "Les nouveaux modes de paiement sont ajoutés lors de la validation de la commande.",
  invalidField: "Zone non valide : {{ field }}"
};
var paymentCard$b = {
  deleteConfirmation: "Voulez-vous vraiment supprimer ce mode de paiement ?",
  setAsDefault: "Définir par défaut",
  expires: "Expire en : {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ PAR DÉFAUT",
  defaultPaymentLabel: "Mode de paiement par défaut",
  additionalPaymentLabel: "Mode de paiement supplémentaire {{ number }}",
  selected: "Sélectionné",
  selectedPayment: "Paiement sélectionné",
  deletePaymentSuccess: "Mode de paiement supprimé avec succès",
  visa: "Carte Visa",
  master: "Carte Mastercard",
  masterEuro: "Carte Eurocard Mastercard",
  dinersClub: "Carte Diners Club",
  "switch": "Carte Switch",
  maestro: "Carte Maestro",
  amex: "Carte American Express",
  credit: "Carte de crédit"
};
var paymentTypes$b = {
  title: "Mode de paiement",
  paymentType_CARD: "Carte de crédit",
  paymentType_ACCOUNT: "Compte"
};
var paymentMessages$b = {
  setAsDefaultSuccessfully: "Le nouveau paiement a bien été défini par défaut."
};
var payment$b = {
  paymentForm: paymentForm$b,
  paymentMethods: paymentMethods$b,
  paymentCard: paymentCard$b,
  paymentTypes: paymentTypes$b,
  paymentMessages: paymentMessages$b
};
var pdf$m = {
  defaultTitle: "Document"
};
var pdf$n = {
  pdf: pdf$m
};
var productDetails$b = {
  id: "ID",
  quantity: "Qté",
  quantityFull: "Quantité",
  productDetails: "Détails du produit",
  specification: "Spécifications",
  reviews: "Évaluations",
  shipping: "Expédition",
  share: "Partager",
  showReviews: "Afficher les évaluations",
  noReviews: "Pas d'évaluations pour le moment",
  productPrice: "Prix du produit",
  noProductImage: "Aucune image n'est disponible, {{ product }}"
};
var productList$b = {
  filterBy: {
    label: "Filtrer par",
    action: "Filtrer par"
  },
  activeFilter: "Filtre {{filter}} : cliquez sur ce bouton pour supprimer le filtre.",
  appliedFilter: "Filtre appliqué :",
  showLess: "Afficher moins...",
  showMore: "Afficher plus...",
  sortBy: "Trier par",
  sortResults: "Trier les résultats",
  backToTopBtn: "HAUT DE LA PAGE",
  showMoreBtn: "AFFICHER PLUS",
  productSearchPagination: "Pagination de la recherche de produit",
  productListResults: "Liste des résultats de produits"
};
var productFacetNavigation$b = {
  filterBy: {
    label: "Filtrer par",
    action: "Filtrer par",
    facet: "Filtrer les résultats par facettes",
    name: "Filtrer par {{name}}"
  },
  appliedFilter: "Filtre appliqué :",
  showLess: "Afficher moins...",
  showMore: "Afficher plus...",
  sortBy: "Trier par",
  ariaLabelShowLess: "Bouton Afficher moins : cliquez sur ce bouton afin de réduire les options pour le groupe actif.",
  ariaLabelShowMore: "Bouton Afficher plus : cliquez sur ce bouton afin d'afficher toutes les options pour le groupe actif.",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} article disponible",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} articles disponibles",
  decreaseOptionsVisibility: "Les options ont été masquées dans le groupe actif. Naviguez en arrière avec la touche de tabulation pour les lire ou vers l'avant pour passer au groupe suivant.",
  increaseOptionsVisibility: "D'autres options ont été ajoutées au groupe actif. Naviguez en arrière avec la touche de tabulation pour les lire ou vers l'avant pour passer au groupe suivant.",
  backToResults: "Retour aux résultats",
  productFacets: "Facettes de produits"
};
var productSummary$b = {
  id: "ID",
  showReviews: "Afficher les évaluations",
  showReviewsDetailed: "Afficher {{count}} évaluations, notées {{rating}} étoiles sur 5",
  share: "Partager",
  newItemPrice: "Nouveau prix de l'article"
};
var productReview$b = {
  overallRating: "Notation globale",
  reviewTitle: "Titre de l'évaluation",
  writeYourComments: "Rédigez vos commentaires",
  rating: "Notation",
  ratingRequired: "Notation du produit (obligatoire)",
  addRate: "Ajouter une notation : {{count}} étoile",
  addRate_other: "Ajouter une notation : {{count}} étoiles",
  reviewerName: "Nom de l'évaluateur (facultatif)",
  writeReview: "Rédiger une évaluation",
  more: "Afficher plus d'évaluations",
  less: "Afficher moins d'évaluations",
  thankYouForReview: "Merci pour votre évaluation ! Notez que les évaluations peuvent nécessiter une vérification avant d'apparaître ici.",
  postReviewFail: "Une erreur s'est produite lors de la publication de votre évaluation. Veuillez réessayer ultérieurement.",
  ratedOutOf: "Noté : {{rating}} sur 5"
};
var productCarousel$b = {
  carouselLabel: "Carrousel, {{title}}"
};
var addToCart$b = {
  itemAddedToYourCart: "Article ajouté à votre panier",
  itemsAddedToYourCart: "Article(s) ajouté(s) à votre panier",
  itemsIncrementedInYourCart: "L'article était déjà dans votre panier. La quantité a été mise à jour.",
  items: "articles",
  updatingCart: "Mise à jour du panier...",
  addToCart: "Ajouter au panier",
  viewCart: "Afficher le panier",
  proceedToCheckout: "Procéder au règlement",
  quantity: "Qté",
  quantityFull: "Quantité",
  outOfStock: "Épuisé",
  inStock: "En stock",
  selectStyleAndSize: "Sélectionner le style et la taille pour vérifier le stock",
  removeFromCart: "Retirer le produit du panier",
  closeModal: "Fermer la boîte de dialogue modale",
  buyItAgain: "Racheter l'article",
  addToActiveCart: "Ajouter au panier actif"
};
var TabPanelContainer$b = {
  tabs: {
    ProductDetailsTabComponent: "Détails sur le produit",
    ProductSpecsTabComponent: "Spécifications",
    ProductReviewsTabComponent: "Évaluations",
    deliveryTab: "Expédition",
    SparePartsTabComponent: " Pièces de rechange"
  },
  tabPanelContainerRegion: "Groupe d'onglets avec plus de détails sur le produit",
  tabPanelContainerRegionGroup: "Groupe avec plus de détails sur le produit"
};
var addToWishList$b = {
  add: "Ajouter à la liste de souhaits",
  remove: "Retirer de la liste de souhaits",
  anonymous: "Connectez-vous pour ajouter le produit à la liste de souhaits.",
  addedToWishList: "Produit ajouté à la liste de souhaits",
  removedFromWishList: "Produit retiré de la liste de souhaits"
};
var stockNotification$b = {
  notifyMe: "M'ENVOYER UNE NOTIFICATION",
  stopNotify: "NE PLUS ENVOYER DE NOTIFICATION",
  getNotify: "Recevoir une notification lorsque ce produit est disponible",
  getNotifySuffix: "Pour recevoir une notification lorsque ce produit est disponible.",
  activateChannelsPrefix: "Pour recevoir une notification, vous devez activer les ",
  channelsLink: "canaux de notification",
  activateChannelsSuffix: ".",
  notified: "Vous recevrez une notification lorsque ce produit sera de nouveau en stock.",
  getNotified: "Recevoir une notification lorsque ce produit est de nouveau en stock.",
  unsubscribeSuccess: "Vous ne recevrez pas de notification de réapprovisionnement pour ce produit.",
  subscriptionDialog: {
    header: "Abonnement pour ruptures de stock",
    notifiedPrefix: "Vous recevrez une notification le :",
    notifiedSuffix: "dès que ce produit sera réapprovisionné.",
    manageChannelsPrefix: "Gérez vos canaux de notification préférés sur la page ",
    manageChannelsLink: "Préférence de notification",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "Vous pouvez gérer vos abonnements sur la page ",
    manageSubscriptionsLink: "Mes intérêts",
    manageSubscriptionsSuffix: " .",
    okBtn: "OK",
    subscribing: "Abonnez-vous aux notifications de rupture de stock pour ce produit"
  }
};
var itemCounter$b = {
  removeOne: "Retirer un article",
  addOneMore: "Ajouter un autre article",
  quantity: "Quantité"
};
var productView$b = {
  gridView: "Sélectionner pour passer à la vue en grille",
  listView: "Sélectionner pour passer à la vue de liste"
};
var product$b = {
  productDetails: productDetails$b,
  productList: productList$b,
  productFacetNavigation: productFacetNavigation$b,
  productSummary: productSummary$b,
  productReview: productReview$b,
  productCarousel: productCarousel$b,
  addToCart: addToCart$b,
  TabPanelContainer: TabPanelContainer$b,
  addToWishList: addToWishList$b,
  stockNotification: stockNotification$b,
  itemCounter: itemCounter$b,
  productView: productView$b
};
var pwa$m = {
  addToHomeScreenDescription: "Ajoutez SAP Storefront à l'écran d'accueil de votre appareil pour une visite plus rapide.",
  noInstallationNeeded: "Aucune installation n'est nécessaire",
  fastAccessToApplication: "Accès rapide à l'application",
  addToHomeScreen: "Ajouter à l'écran d'accueil",
  addedToHomeScreen: "SAP Storefront a été ajouté à votre écran d'accueil"
};
var pwa$n = {
  pwa: pwa$m
};
var siteThemeSwitcher$m = {
  theme: "Thème",
  themes: {
    "default": "Par défaut",
    highContrastDark: "HC-Dark",
    highContrastLight: "HC-Light"
  }
};
var siteThemeSwitcher$n = {
  siteThemeSwitcher: siteThemeSwitcher$m
};
var anonymousConsents$b = {
  dialog: {
    title: "Gestion des consentements",
    legalDescription: "Nous utilisons les cookies/le stockage du navigateur pour personnaliser le contenu et améliorer l'expérience de l'utilisateur. Nous sommes également susceptibles de partager les données relatives à l'utilisation du site avec nos médias sociaux. Pour en savoir plus, consultez notre politique de confidentialité.",
    selectAll: "Sélectionner tout",
    clearAll: "Effacer tout"
  },
  banner: {
    title: "Ce site Web utilise des cookies",
    description: "Nous utilisons les cookies/le stockage du navigateur pour personnaliser le contenu et améliorer l'expérience de l'utilisateur.",
    allowAll: "Autoriser tout",
    viewDetails: "Afficher les détails",
    consentManagement: "Gestion des consentements"
  }
};
var checkoutLogin$b = {
  emailAddress: {
    label: "Adresse e-mail",
    placeholder: "Saisir l'adresse e-mail"
  },
  confirmEmail: {
    label: "Confirmer l'adresse e-mail",
    placeholder: "Confirmer l'adresse e-mail"
  },
  "continue": "Poursuivre",
  emailIsRequired: "Format d'e-mail non valide",
  emailsMustMatch: "L'e-mail ne correspond pas"
};
var authMessages$b = {
  signedOutSuccessfully: "Vous vous êtes déconnecté avec succès."
};
var user$b = {
  anonymousConsents: anonymousConsents$b,
  checkoutLogin: checkoutLogin$b,
  authMessages: authMessages$b
};
var player$b = {
  label: "Lecteur vidéo"
};
var video$b = {
  player: player$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  common: common$n,
  deliveryMode: deliveryMode$b,
  myAccountV2Consent: myAccountV2Consent$n,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$n,
  myAccount: myAccount$b,
  payment: payment$b,
  pdf: pdf$n,
  product: product$b,
  pwa: pwa$n,
  siteThemeSwitcher: siteThemeSwitcher$n,
  user: user$b,
  video: video$b
};
var common$k = {
  cancel: "रद्द करें",
  "delete": "हटाएं",
  remove: "निकालें",
  edit: "संपादित करें",
  restore: "पुनर्स्थापित करें",
  back: "वापस जाएं",
  submit: "सबमिट करें",
  "continue": "जारी रखें",
  save: "सहेजें",
  done: "पूरा हुआ",
  home: "होम",
  noData: "कोई डेटा नहीं.",
  confirm: "पुष्टि करें",
  more: "और अधिक",
  close: "बंद करें",
  loading: "लोड किया जा रहा है",
  menu: "मेनू",
  reset: "रीसेट करें",
  search: "खोजें",
  add: "जोड़ें",
  breadcrumbs: "ब्रेडक्रंब",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "फाइल चुनें",
  clear: "साफ़ करें",
  loaded: "लोड किया गया",
  results: "परिणाम",
  of: "इसमें से",
  ngSelectDropdownOptionsList: "विकल्प",
  required: "आवश्यक",
  zoomIn: "ज़ूम इन",
  zoomOut: "ज़ूम आउट",
  selected: "चयनित",
  expand: "विस्तृत करें",
  collapse: "संकुचित करें"
};
var pageMetaResolver$a = {
  category: {
    title: "{{query}} के लिए {{count}} परिणाम",
    title_other: "{{query}} के लिए {{count}} परिणाम"
  },
  checkout: {
    title: "चेकआउट करें"
  },
  search: {
    title: "\"{{query}}\" के लिए {{count}} परिणाम",
    title_other: "\"{{query}}\" के लिए {{count}} परिणाम",
    findProductTitle: "कूपन \"{{coupon}}\" के लिए {{count}} परिणाम",
    findProductTitle_other: "कूपन \"{{coupon}}\" के लिए {{count}} परिणाम",
    default_title: "सभी उत्पाद"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$a = {
  loading: "लोड हो रहा है..."
};
var navigation$a = {
  categoryNavLabel: "श्रेणी मेनू",
  footerNavLabel: "फुटर लिंक",
  goTo: "{{location}} पर जाएं",
  navigateTo: "{{nav}} पर नेविगेट करें",
  scrollToTop: "पृष्ठ के शीर्ष पर वापस स्क्रोल करें",
  linkItemInList: "{{listLength}} का {{title}}. {{position}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$a = {
  placeholder: "उत्पाद नाम या SKU दर्ज करें",
  productSearch: "उत्पाद खोजें",
  ariaLabelInput: "यहां खोजें...",
  ariaLabelSuggestions: "टाइपिंग सलाहें",
  ariaLabelProducts: "उत्पाद परिणाम",
  initialDescription: "स्वतः पूर्ण परिणामों के उपलब्ध होने पर समीक्षा करने के लिए ऊपर और नीचे तीरों का और चयन करने के लिए enter का उपयोग करें. स्पर्श उपकरण उपयोगकर्ता स्पर्श करके या स्वाइप करके खोजें.",
  suggestions: "सुझाए गए कीवर्ड",
  recentSearches: "तात्कालिक खोजें",
  trendingSearches: "ट्रेडिंग खोजें",
  suggestionsResult: "{{ count }} सुझाव और ",
  suggestionsResult_other: "{{ count }} सुझाव और ",
  products: "अनुशंसित उत्पाद",
  productsResult: "{{ count }} उत्पाद उपलब्ध है.",
  productsResult_other: "{{ count }} उत्पाद उपलब्ध हैं.",
  resetLabel: "खोज रीसेट करें",
  help: {
    insufficientChars: "कृपया और वर्ण टाइप करें",
    noMatch: "हमें कोई परिणाम नहीं मिल सका",
    exactMatch: "{{ term }}",
    empty: "हमसे कुछ भी पूछें"
  },
  closeSearchPanel: "बंद करें"
};
var sorting$a = {
  date: "दिनांक",
  orderNumber: "ऑर्डर संख्या",
  rma: "वापसी संख्या",
  replenishmentNumber: "पुनःपूर्ति संख्या",
  nextOrderDate: "अगले ऑर्डर का दिनांक",
  pageViewUpdated: "आपके चयनित विकल्पों द्वारा पृष्ठ दृश्य अपडेट किया गया."
};
var httpHandlers$a = {
  badRequest: {
    bad_credentials: "{{ errorMessage }} कृपया फिर से लॉगिन करें.",
    user_is_disabled: "{{ errorMessage }}. कृपया व्यवस्थापक से संपर्क करें."
  },
  badGateway: "सर्वर त्रुटि उत्पन्न हुई. कृपया फिर से कोशिश करें.",
  badRequestPleaseLoginAgain: "{{ errorMessage }} कृपया फिर से लॉगिन करें.",
  badRequestOldPasswordIncorrect: "पुराना पासवर्ड गलत है.",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} ईमेल पहले से मौजूद है. गेस्ट खाते का उपयोग कर पंजीकरण करने के लिए अन्य ईमेल से चेकआउट करें.",
  conflict: "पहले से मौजूद है.",
  forbidden: "आप यह क्रिया करे के लिए प्राधिकृत नहीं हैं. यदि आपको लगता है यह गलती है तो कृपया अपने व्यवस्थापक से संपर्क करें.",
  gatewayTimeout: "सर्वर प्रतिसाद नहीं दे रहा, कृपया बाद में फिर से कोशिश करें.",
  internalServerError: "आंतरिक सर्वर त्रुटि उत्पन्न हुई. कृपया बाद में फिर से कोशिश करें.",
  sessionExpired: "आपका सत्र समाप्त हुआ. कृपया फिर से लॉगिन करें.",
  unknownError: "एक अज्ञात त्रुटि उत्पन्न हुई",
  unauthorized: {
    common: "एक अज्ञात प्राधिकार त्रुटि उत्पन्न हुई",
    invalid_client: "खराब क्लाइंट क्रेडेंशियल"
  },
  validationErrors: {
    missing: {
      card_cardType: "चयनित क्रेडिट कार्ड समर्थित नहीं है. कृपया अन्य चुनें.",
      card_accountNumber: "दर्ज की गई क्रेडिट कार्ड संख्या मान्य नहीं है.",
      card_cvNumber: "दर्ज किया गया सुरक्षा कोड मान्य नहीं है.",
      card_expirationMonth: "दर्ज की गई क्रेडिट कार्ड समाप्ति दिनांक मान्य नहीं है.",
      card_expirationYear: "दर्ज की गई क्रेडिट कार्ड समाप्ति दिनांक मान्य नहीं है.",
      billTo_firstName: "दर्ज किया गया पहला नाम मान्य नहीं है.",
      billTo_lastName: "दर्ज किया गया अंतिम नाम मान्य नहीं है.",
      billTo_street1: "दर्ज किया गया पता मान्य नहीं है.",
      billTo_street2: "दर्ज किया गया पता मान्य नहीं है.",
      billTo_city: "इस क्रेडिट कार्ड के लिए दर्ज किया गया शहर मान्य नहीं है.",
      billTo_state: "इस क्रेडिट कार्ड के लिए दर्ज किया गया राज्य/प्रांत मान्य नहीं है.",
      billTo_country: "इस क्रेडिट कार्ड के लिए दर्ज किया गया देश/क्षेत्र मान्य नहीं है.",
      billTo_postalCode: "इस क्रेडिट कार्ड के लिए ज़िप/पोस्टल कोड मान्य नहीं है.",
      country: {
        isocode: "देश/क्षेत्र गुम है"
      }
    },
    invalid: {
      card_expirationMonth: "दर्ज की गई क्रेडिट कार्ड समाप्ति दिनांक मान्य नहीं है.",
      firstName: "दर्ज किया गया पहला नाम मान्य नहीं है.",
      lastName: "दर्ज किया गया अंतिम नाम मान्य नहीं है.",
      password: "दर्ज किया गया पासवर्ड मान्य नहीं है.",
      uid: "UID मान्य नहीं है.",
      code: "कोड मान्य नहीं है.",
      email: "ईमेल मान्य नहीं है.",
      loginId: "ईमेल मान्य नहीं है."
    }
  },
  cartNotFound: "कार्ट नहीं मिला",
  invalidCodeProvided: "अमान्य कोड प्रदान किया गया.",
  voucherExceeded: "इस कूपन का उपयोग करने की सीमा पार हो चुकी है.",
  unknownIdentifier: "आइटम नहीं मिला.",
  otherCartErrors: "कार्ट त्रुटियां उत्पन्न हुईं."
};
var miniCart$a = {
  item: "वर्तमान में आपकी कार्ट में {{count}} आइटम हैं",
  item_other: "वर्तमान में आपकी कार्ट में {{count}} आइटम हैं",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$a = {
  skipTo: "इसे छोड़ें",
  labels: {
    header: "शीर्षलेख",
    main: "मुख्य सामग्री",
    footer: "फुटर",
    productFacets: "उत्पाद पहलू",
    productList: "उत्पाद सूची"
  }
};
var carousel$a = {
  previousSlide: "पिछली स्लाइड",
  nextSlide: "अगली स्लाइड",
  slideNumber: "स्लाइड {{currentSlideNumber}} में {{numberOfSlides}} आइटम शामिल हैं",
  carouselForProduct: "करोसेल, {{product}} के लिए छवियां"
};
var formErrors$a = {
  labeled: {
    required: "फ़ील्ड {{label}} आवश्यक है.",
    cxInvalidEmail: "फ़ील्ड {{label}} में कोई मान्य ईमेल स्वरूप नहीं है. मिलान पैटर्नः  example@yourdomain.com",
    cxInvalidPassword: "फ़ील्ड {{label}} में मान्य पासवर्ड स्वरूप नहीं है",
    cxMinOneUpperCaseCharacter: "पासवर्ड में कम से कम एक अपरकेस वर्ण होना चाहिए",
    cxMinOneDigit: "पासवर्ड में कम से कम एक अंक होना चाहिए",
    cxMinOneSpecialCharacter: "पासवर्ड में कम से कम एक विशेष वर्ण होना चाहिए.",
    cxMinSixCharactersLength: "पासवर्ड में कम से कम 6 वर्ण होना चाहिए.",
    cxMinEightCharactersLength: "पासवर्ड में कम से कम 8 वर्ण होना चाहिए.",
    cxMaxCharactersLength: "पासवर्ड में 128 वर्णों से अधिक नहीं हो सकते",
    cxContainsSpecialCharacters: "पासवर्ड में विशेष वर्ण शामिल नहीं कर सकते",
    cxNoConsecutiveCharacters: "पासवर्ड में क्रमागत समान वर्ण शामिल नहीं कर सकते",
    date: {
      required: "फ़ील्ड {{label}} आवश्यक है.",
      min: "फील्ड {{label}} {{min}} से पहले नहीं हो सकती",
      max: "फ़ील्ड {{label}} {{max}} के बाद नहीं हो सकती",
      pattern: "फ़ील्ड {{label}} में दिनांक स्वरूप yyyy-mm-dd का उपयोग करें",
      invalid: "फ़ील्ड {{label}} में मान्य दिनांक का उपयोग करें"
    }
  },
  globalMessage: "आप जिस फ़ॉर्म को सबमिट करने की कोशिश कर रहे हैं उसमें त्रुटियां हैं.",
  required: "यह फ़ील्ड आवश्यक है",
  cxInvalidEmail: "यह मान्य ई-मेल स्वरूप नहीं है.",
  cxInvalidPassword: "यह मान्य पासवर्ड स्वरूप नहीं है",
  cxPasswordsMustMatch: "पासवर्ड फ़ील्ड मेल खानी चाहिए",
  cxPasswordsCannotMatch: "पासवर्ड फ़ील्ड मेल नहीं खा सकतीं.",
  cxEmailsMustMatch: "ईमेल फ़ील्ड मेल खानी चाहिए",
  cxStarRatingEmpty: "रेटिग फ़ील्ड आवश्यक है",
  cxNoSelectedItemToCancel: "कम से कम एक आइटम चुनें",
  cxNegativeAmount: "राशि शून्य के बराबर या बड़ी होना चाहिए",
  cxContainsSpecialCharacters: "फ़ील्ड में विशेष वर्ण शामिल नहीं हो सकते",
  date: {
    required: "यह फ़ील्ड आवश्यक है",
    min: "दिनांक {{min}} से पहले नहीं हो सकती",
    max: "दिनांक {{max}} के बाद नहीं हो सकती",
    pattern: "दिनांक स्वरूप yyyy-mm-dd का उपयोग करें",
    invalid: "कोई मान्य दिनांक का उपयोग करें"
  },
  file: {
    required: "फ़ाइल आवश्यक है",
    empty: "फ़ाइल रिक्त नहीं होना चाहिए",
    invalidExtension: "फ़ाइल विस्तार मान्य नहीं है",
    tooLarge: "फ़ाइल आकार को {{ maxSize }}MB की सीमा पार नहीं करनी चाहिए",
    tooManyEntries: "आइटम की संख्या {{ maxEntries }} से अधिक होना चाहिए",
    notParsable: "फ़ाइल पार्स करने योग्य नहीं है",
    fileNotAllowed: "यह फ़ाइल प्रकार अनुमत नहीं है"
  }
};
var errorHandlers$a = {
  scriptFailedToLoad: "स्क्रिप्ट लोड करने में विफल",
  refreshThePage: "कृपया इस पृष्ठ को रीफ़्रेश करें."
};
var assistiveMessage$a = {
  actionCancelled: "कार्रवाई रद्द की गई, कुछ नहीं बदला"
};
var passwordVisibility$a = {
  showPassword: "पासवर्ड दिखाएं",
  hidePassword: "पासवर्ड छिपाएं"
};
var generalErrors$a = {
  pageFailure: "पृष्ठ लोड नहीं किया जा सका. कृपया फिर से कोशिश करें."
};
var formLegend$a = {
  required: "\"*\"  से चिह्नित निम्न फ़ील्ड आवश्यक हैं."
};
var chatMessaging$a = {
  charactersLeft: "वर्ण बचे हैंः  {{count}}",
  addNewMessage: "नया संदेश जोड़ें",
  send: "भेजें",
  uploadFile: "फ़ाइल अपलोड करें",
  informationLabel: "{{date}} पर {{author}}. {{text}}",
  messages: "संदेश",
  addMessagePlaceHolder: "टाइपिंग प्रारंभ करें...",
  characterLimitAlert: "वर्ण सीमा तक पहुंच गया.",
  a11y: {
    itemListBoxLabel: "कोई आइटम चुनें जिस पर संदेश लागू किया जाना चाहिए."
  }
};
var common$l = {
  common: common$k,
  pageMetaResolver: pageMetaResolver$a,
  spinner: spinner$a,
  navigation: navigation$a,
  searchBox: searchBox$a,
  sorting: sorting$a,
  httpHandlers: httpHandlers$a,
  miniCart: miniCart$a,
  skipLink: skipLink$a,
  carousel: carousel$a,
  formErrors: formErrors$a,
  errorHandlers: errorHandlers$a,
  assistiveMessage: assistiveMessage$a,
  passwordVisibility: passwordVisibility$a,
  generalErrors: generalErrors$a,
  formLegend: formLegend$a,
  chatMessaging: chatMessaging$a
};
var setDeliveryMode$a = {
  unknownError: "अज्ञात त्रुटि उत्पन्न हुई. सहायता से संपर्क करें."
};
var deliveryMode$a = {
  setDeliveryMode: setDeliveryMode$a
};
var myAccountV2Consent$k = {
  header: "सहमति प्रबंधन",
  dateDescription: "अनुमोदन दिनांक ",
  clearAll: "सभी अक्षम करें",
  selectAll: "सभी सक्षम करें",
  message: {
    success: {
      given: "स्वीकृति सफलतापूर्वक दी गई.",
      withdrawn: "स्वीकृति सफलतापूर्वक हटाई गई."
    }
  }
};
var myAccountV2Consent$l = {
  myAccountV2Consent: myAccountV2Consent$k
};
var myAccountV2NotificationPreference$k = {
  header: "अधिसूचना चैनल",
  message: "अपना पसंदीदा अधिसूचना चैनल चुनें",
  note: "सूचनाः ",
  noteMessage: "यदि आपने सभी चैनल निष्क्रिय किए तो आप आगे अधिसूचनाएं प्राप्त करे में सक्षम नहीं होंगे.",
  EMAIL: "ई-मेलः"
};
var myAccountV2NotificationPreference$l = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$k
};
var closeAccount$a = {
  confirmAccountClosure: "खाता बंद होने की पुष्टि करें",
  confirmAccountClosureMessage: "क्या आप वाकई अपना खाता बंद करना चाहते हैं?",
  closeMyAccount: "मेरा खाता बंद करें",
  accountClosedSuccessfully: "खाता सफलता से बंद किया गया",
  accountClosedFailure: "खाता बंद करने में विफल"
};
var updatePasswordForm$a = {
  oldPassword: {
    label: "पुराना पासवर्ड",
    placeholder: "पुराना पासवर्ड"
  },
  oldPasswordIsRequired: "पुराना पासवर्ड आवश्यक है.",
  newPassword: {
    label: "नया पासवर्ड",
    placeholder: "नया पासवर्ड"
  },
  passwordMinRequirements: "सुरक्षित पासवर्ड के लिए त्वरित मार्गदर्शिकाः अपर और लोअर केस के वर्ण, संख्या और प्रतीक चिह्नों के कम से कम 8 वर्णों का उपयोग करें.",
  passwordStrengthRequirements: "सुरक्षित पासवर्ड के लिए त्वरित मार्गदर्शिकाः अपर और लोअर केस के वर्ण, संख्या और प्रतीक चिह्नों के कम से कम 8 वर्णों का अपयोग करे. ध्यान रखें कि एक ही वर्ण का लगातार उपयोग न करें.",
  confirmPassword: {
    label: "नए पासवर्ड की पुष्टि करें",
    placeholder: "पासवर्ड की पुष्टि करें"
  },
  bothPasswordMustMatch: "दोनों पासवर्ड मेल खाना चाहिए",
  passwordUpdateSuccess: "पासवर्ड सफलतापूर्वक अपडेट किया गया",
  accessDeniedError: "पहुंच अस्वीकृत किया गया"
};
var updateProfileForm$a = {
  title: "शीर्षक",
  none: "",
  firstName: {
    label: "प्रथम नाम",
    placeholder: "प्रथम नाम"
  },
  firstNameIsRequired: "प्रथम नाम आवश्यक है.",
  lastName: {
    label: "अंतिम नाम",
    placeholder: "अंतिम नाम"
  },
  lastNameIsRequired: "अंतिम नाम आवश्यक है.",
  profileUpdateSuccess: "निजी विवरण सफलतापूर्वक अपडेट किया गया",
  customerId: "ग्राहक #"
};
var consentManagementForm$a = {
  clearAll: "सब साफ़ करें",
  selectAll: "सभी का चयन करें",
  message: {
    success: {
      given: "स्वीकृति सफलतापूर्वक दी गई.",
      withdrawn: "स्वीकृति सफलतापूर्वक हटाई गई."
    }
  }
};
var myCoupons$a = {
  noCouponsMessage: "आपके पास कोई कूप उपलब्ध नहीं है.",
  effectiveTitle: "प्रभावी:",
  Effective: "प्रभावी:",
  PreSession: "जल्द ही प्रभावी होगा",
  ExpireSoon: "जल्दी समाप्त हो रहा है",
  readMore: "अधिक पढ़ें",
  notification: "अधिसूचना",
  findProducts: "उत्पाद खोजें",
  status: "स्थिति:",
  dialogTitle: "कूपन",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "कूपन कोड",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "आपने सफलतापूर्वक इस कूपन का दावा किया है.",
  myCoupons: "मेरे कूपन",
  startDateAsc: "प्रारंभ दिनांक (आरोही)",
  startDateDesc: "प्रारंभ दिनांक (अवरोही)",
  endDateAsc: "समाप्ति दिनांक (आरोही)",
  endDateDesc: "समाप्ति दिनांक (अवरोही)",
  sortBy: "इसके द्वारा क्रमित",
  sortCoupons: "कूपन क्रमित करें",
  notesPreffix: "आप कूपन अधिसूचा प्राप्त करे के लिए अपना पसंदीदा चैनल यहां सेट कर सकते हैं ",
  notesLink: "अधिसूचना चैनल",
  notesSuffix: " पृष्ठ."
};
var notificationPreference$a = {
  message: "अपना पसंदीदा अधिसूचना चैनल चुनें",
  note: "सूचनाः ",
  noteMessage: "यदि आपने सभी चैनल निष्क्रिय किए तो आप आगे अधिसूचनाएं प्राप्त करे में सक्षम नहीं होंगे.",
  EMAIL: "ई-मेलः",
  SMS: "SMS:",
  SITE_MESSAGE: "साइटसंदेश"
};
var myInterests$a = {
  header: "मेरी रूचि",
  item: "आइटम",
  price: "मूल्य",
  notifications: "अधिसूचनाएं",
  noInterests: "आपके पास अभी तक पंजीकृत रूचियां नहीं हैं.",
  inStock: "स्टॉक में है",
  lowStock: "कम स्टॉक",
  outOfStock: "स्टॉक में नहीं है",
  BACK_IN_STOCK: "स्टॉक में वापस जाएं",
  sortBy: "इसके द्वारा क्रमित",
  sortInterests: "रूचियां क्रमित करें",
  expirationDate: " - {{ expirationDate }} तक",
  productId: "ID {{ code }}",
  remove: "निकालें",
  sorting: {
    byNameAsc: "नाम (आरोही क्रम में)",
    byNameDesc: "नाम (अवरोही क्रम में)"
  },
  actions: "कार्रवाईयां",
  caption: "मेरी रूचि की सामग्री.",
  itemRemoved: "चयनित आइटम निकाले गए."
};
var myAccount$a = {
  closeAccount: closeAccount$a,
  updatePasswordForm: updatePasswordForm$a,
  updateProfileForm: updateProfileForm$a,
  consentManagementForm: consentManagementForm$a,
  myCoupons: myCoupons$a,
  notificationPreference: notificationPreference$a,
  myInterests: myInterests$a
};
var paymentForm$a = {
  payment: "भुगतान",
  choosePaymentMethod: "भुगतान विधि चुनें",
  paymentType: "भुगतान प्रकार",
  accountHolderName: {
    label: "खाता धारक का नाम",
    placeholder: "खाता धारक का नाम"
  },
  cardNumber: "कार्ड नंबर",
  expirationDate: "समाप्ति दिनांक",
  securityCode: "सुरक्षा कोड (CVV)",
  securityCodeTitle: "कार्ड सत्यापन मान",
  saveAsDefault: "डिफ़ॉल्ट के रूप में सहेजें",
  setAsDefault: "डिफ़ॉल्ट भुगतान विधि के रूप में सेट करें",
  billingAddress: "बिलिंग पता",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "वितरण के पते के समान",
  billingAddressSameAsShipping: "बिलिंग पता और वितरण पता समान है",
  selectOne: "एक चुनें...",
  monthMask: "MM",
  yearMask: "YYYY",
  expirationYear: "समाप्ति वर्ष {{ selected }}",
  expirationMonth: "समाप्ति माह {{ selected }}",
  useThisPayment: "इस भुगतान का उपयोग करें",
  addNewPayment: "नया भुगतान जोड़ें",
  paymentAddedSuccessfully: "नया भुगता सफलतापूर्क जोड़ा गया",
  changePayment: "भुगतान बदलें"
};
var paymentMethods$a = {
  paymentMethods: "भुगतान विधि",
  paymentMethodSelected: "भुगतान विधि चयनित",
  newPaymentMethodsAreAddedDuringCheckout: "चेकआउट के दौरा नई भुगता विधियां जोड़ी गईं.",
  invalidField: "अमान्य फ़ील्डः {{ field }}"
};
var paymentCard$a = {
  deleteConfirmation: "क्या आप वाकई में यह भुगतान विधि हटाना चाहते हैं?",
  setAsDefault: "डिफ़ॉल्ट के रूप में सेट करें",
  expires: "{{ month }} / {{ year }} को समाप्त",
  defaultPaymentMethod: "डिफ़ॉल्ट",
  defaultPaymentLabel: "डिफ़ॉल्ट भुगतान विधि",
  additionalPaymentLabel: "अतिरिक्त भुगतान विधि {{ number }}",
  selected: "चयनित",
  selectedPayment: "चयनित भुगतान",
  deletePaymentSuccess: "भुगतान विधि सफलतापूर्वक हटाई गई",
  visa: "वीसा कार्ड",
  master: "मास्टरकार्ड कार्ड",
  masterEuro: "मास्टरकार्ड कार्ड यूरोकार्ड कार्ड",
  dinersClub: "डाइनर्स क्लब कार्ड",
  "switch": "स्विच कार्ड",
  maestro: "माइस्ट्रो कार्ड",
  amex: "अमेरिकन एक्सप्रेस कार्ड",
  credit: "क्रेडिट कार्ड"
};
var paymentTypes$a = {
  title: "भुगतान विधि",
  paymentType_CARD: "क्रेडिट कार्ड",
  paymentType_ACCOUNT: "खाता"
};
var paymentMessages$a = {
  setAsDefaultSuccessfully: "नया भुगता सफलतापूर्वक डिफ़ॉल्ट के रूप में सेट किया गया"
};
var payment$a = {
  paymentForm: paymentForm$a,
  paymentMethods: paymentMethods$a,
  paymentCard: paymentCard$a,
  paymentTypes: paymentTypes$a,
  paymentMessages: paymentMessages$a
};
var pdf$k = {
  defaultTitle: "दस्तावेज़"
};
var pdf$l = {
  pdf: pdf$k
};
var productDetails$a = {
  id: "ID",
  quantity: "मात्रा",
  quantityFull: "मात्रा",
  productDetails: "उत्पाद विवरण",
  specification: "विशिष्टता",
  reviews: "समीक्षा",
  shipping: "शिपिंग",
  share: "साझा करें",
  showReviews: "समीक्षाएं दिखाएं",
  noReviews: "अभी तक कोई समीक्षाएं नहीं",
  productPrice: "उत्पाद मूल्य",
  noProductImage: "कोई छवि उपलब्ध नहीं, {{ product }}"
};
var productList$a = {
  filterBy: {
    label: "फ़िल्टरकर्ता",
    action: "फ़िल्टरकर्ता"
  },
  activeFilter: "{{filter}} फ़िल्टर, इस बटन पर क्लिक करने से फ़िल्टर निकल जाएगा",
  appliedFilter: "लागू किए गए फ़िल्टरः",
  showLess: "कम दिखाएँ...",
  showMore: "अधिक दिखाएँ...",
  sortBy: "इसके द्वारा क्रमित",
  sortResults: "क्रमण का परिणाम",
  backToTopBtn: "शीर्ष पर वापस जाएं",
  showMoreBtn: "अधिक दिखाएँ",
  productSearchPagination: "उत्पाद खोज पृष्ठांकन",
  productListResults: "उत्पाद परिणाम सूची"
};
var productFacetNavigation$a = {
  filterBy: {
    label: "फ़िल्टरकर्ता",
    action: "फ़िल्टरकर्ता",
    facet: "पहलू के अनुसार परिणाम फ़िल्टर करें",
    name: "{{name}} द्वारा फ़िल्टर"
  },
  appliedFilter: "लागू किए गए फ़िल्टरः",
  showLess: "कम दिखाएँ...",
  showMore: "अधिक दिखाएँ...",
  sortBy: "इसके द्वारा क्रमित",
  ariaLabelShowLess: "कम दिखाएं, बटन, इस बटन पर क्लिक करने से सक्रिय समूह के लिए विकल्प कम हो जाएंगे",
  ariaLabelShowMore: "अधिक दिखाएं, बटन, इस बटन पर क्लिक करने से सक्रिय समूह के लिए सभी विकल्प दिखाए जाएंगे",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} आइटम उपलब्ध",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} आइटम उपलब्ध",
  decreaseOptionsVisibility: "सक्रिय समूह से विकल्प छिपाए गए थे, इ्न्हें पढ़ने के लिए पिछला टैब दबाएं या अगले समूह के लिए फॉरवर्ड पर क्लिक करें",
  increaseOptionsVisibility: "सक्रिय समूह में अधिक विकल्प जोड़े गए, इन्हें पढ़ने के लिए पिछला टैब पर जाएं या अगले समूह के लिए फॉरवर्ड पर जाएं",
  backToResults: "परिणामों पर वापस जाएं",
  productFacets: "उत्पाद पहलू"
};
var productSummary$a = {
  id: "ID",
  showReviews: "समीक्षाएं दिखाएं",
  showReviewsDetailed: "{{count}} समीक्षाएं दिखाएं, 5 स्टार में से {{rating}} रेट किया गया",
  share: "साझा करेंसाझा करें ",
  newItemPrice: "नया आइटम मूल्य"
};
var productReview$a = {
  overallRating: "समग्र रेटिंग",
  reviewTitle: "शीर्षक की समीक्षा करें",
  writeYourComments: "अपनी टिप्पणियां लिखें",
  rating: "मुल्यांकन",
  ratingRequired: "उत्पाद रेटिंग, आवश्यक",
  addRate: "दर जोड़ेंः {{count}} स्टार",
  addRate_other: "दर जोड़ेंः {{count}} स्टार",
  reviewerName: "समीक्षक का नाम (वैकल्पिक)",
  writeReview: "एक समीक्षा लिखें",
  more: "अधिक समीक्षाओं को दिखाएं",
  less: "कम समीक्षाएं दिखाएं",
  thankYouForReview: "समीक्षा के लिए धन्यवाद! ध्यान रखें कि यहां दिखने से पहले समीक्षाओं की समीक्षा आवश्यक है.",
  postReviewFail: "आपकी समीक्षा पोस्ट करते समय कुछ गलत हुआ है. कृपया बाद में फिर से कोशिश करें.",
  ratedOutOf: "रेट किया गयाः 5 में से {{rating}}"
};
var productCarousel$a = {
  carouselLabel: "कोरोसल, {{title}}"
};
var addToCart$a = {
  itemAddedToYourCart: "आइटम आपकी कार्ट में जोड़ा गया",
  itemsAddedToYourCart: "आइटम आपकी कार्ट में जोड़े गए",
  itemsIncrementedInYourCart: "यह आइटम पहले से आपकी कार्ट में था. मात्रा अपडेट की गई.",
  items: "आइटम",
  updatingCart: "कार्ट अपडेट कर रहे हैं...",
  addToCart: "कार्ट में जोड़ें",
  viewCart: "कार्ट देखें ",
  proceedToCheckout: "चेक आउट करने के लिए आगे बढ़ें",
  quantity: "मात्रा",
  quantityFull: "मात्रा",
  outOfStock: "स्टॉक में नहीं है",
  inStock: "स्टॉक में है",
  selectStyleAndSize: "चेक आउट करने के लिए स्टाइल और आकार चुनें",
  removeFromCart: "उत्पाद कार्ट से निकालें",
  closeModal: "मॉडल बंद करें",
  buyItAgain: "फिर से खरीदें",
  addToActiveCart: "सक्रिय कार्ट में जोड़ें"
};
var TabPanelContainer$a = {
  tabs: {
    ProductDetailsTabComponent: "उत्पाद विवरण",
    ProductSpecsTabComponent: "विशिष्टता",
    ProductReviewsTabComponent: "समीक्षाएं",
    deliveryTab: "शिपिंग",
    SparePartsTabComponent: " स्पेयर पार्ट"
  },
  tabPanelContainerRegion: "अधिक उत्पाद विवरण के साथ टैब समूह",
  tabPanelContainerRegionGroup: "अधिक उत्पाद विवरण के साथ समूह"
};
var addToWishList$a = {
  add: "विश लिस्ट में जोड़ें",
  remove: "विश लिस्ट से हटाएं",
  anonymous: "विश लिस्ट में जोड़ने के लिए साइन इन करें",
  addedToWishList: "उत्पाद विश लिस्ट में जोड़ा गया",
  removedFromWishList: "उत्पाद विश लिस्ट से निकाला गया"
};
var stockNotification$a = {
  notifyMe: "मुझे सूचित करें",
  stopNotify: "अधिसूचनाएं बंद करें",
  getNotify: "यह उत्पाद उपलब्ध होने पर सूचित करें",
  getNotifySuffix: "यह उत्पाद उपलब्ध होने पर सूचना प्राप्त करें",
  activateChannelsPrefix: "आपको सूचित करने के लिए आपको इसे सक्रिय करने की आवश्यकता है ",
  channelsLink: "अधिसूचना चैनल",
  activateChannelsSuffix: ".",
  notified: "यह उत्पाद स्टॉक में वापस आने पर आपको सूचित किया जाएगा.",
  getNotified: "यह उत्पाद स्टॉक में वापस आने पर सूचना प्राप्त करें.",
  unsubscribeSuccess: "आपको इस उत्पाद के लिए स्टॉक में वापस आने की अधिसूचना प्राप्त नहीं होगी.",
  subscriptionDialog: {
    header: "स्टॉक से बाहर सब्स्क्रिप्शन",
    notifiedPrefix: "आपको इस दिनांक पर सूचित किया जाएगाः",
    notifiedSuffix: "जैसे ही यह उत्पाद स्टॉक में वापस आएगा.",
    manageChannelsPrefix: "अपना पसंदीदा अधिसूचना चैनल यहां प्रबंधित करें ",
    manageChannelsLink: "अधिसूचना वरीयता",
    manageChannelsSuffix: " पृष्ठ.",
    manageSubscriptionsPrefix: "अपने सबस्क्रिप्शन यहां प्रबंधित कर सकते हैं ",
    manageSubscriptionsLink: "मेरी रूचि",
    manageSubscriptionsSuffix: " पृष्ठ.",
    okBtn: "ठीक",
    subscribing: "इस उत्पाद के स्टॉक समाप्त होने के लिए आपको सब्स्क्राइब कर रहे हैं"
  }
};
var itemCounter$a = {
  removeOne: "एक निकालें",
  addOneMore: "एक और जोड़ें",
  quantity: "मात्रा"
};
var productView$a = {
  gridView: "ग्रिड दृश्य पर बदले के लिए चुनें",
  listView: "ग्रिड दृश्य पर बदले के लिए चुनें"
};
var product$a = {
  productDetails: productDetails$a,
  productList: productList$a,
  productFacetNavigation: productFacetNavigation$a,
  productSummary: productSummary$a,
  productReview: productReview$a,
  productCarousel: productCarousel$a,
  addToCart: addToCart$a,
  TabPanelContainer: TabPanelContainer$a,
  addToWishList: addToWishList$a,
  stockNotification: stockNotification$a,
  itemCounter: itemCounter$a,
  productView: productView$a
};
var pwa$k = {
  addToHomeScreenDescription: "तेज वापसी विज़िट के लिए अपने डिवाइस की होमस्क्री पर SAP  स्टोरफ्रंट जोड़ें",
  noInstallationNeeded: "कोई स्थापना आवश्यक नहीं",
  fastAccessToApplication: "एप्लिकेशन में तेज पहुंच",
  addToHomeScreen: "होम स्क्रीन पर जोड़ें",
  addedToHomeScreen: "SAP स्टोरफ्रंट को आपकी होम स्क्रीन पर जोड़ा गया"
};
var pwa$l = {
  pwa: pwa$k
};
var siteThemeSwitcher$k = {
  theme: "थीम",
  themes: {
    "default": "डिफ़ॉल्ट",
    highContrastDark: "HC-गहरा",
    highContrastLight: "HC-हल्का"
  }
};
var siteThemeSwitcher$l = {
  siteThemeSwitcher: siteThemeSwitcher$k
};
var anonymousConsents$a = {
  dialog: {
    title: "सहमति प्रबंधन",
    legalDescription: "हम सामग्री निजीकृत करने और उपयोगकर्ता का अनुभव सुधारने के लिए कुकीज़/ब्राउज़र के स्टोरेज का उपयोग करते हैं. हम आपके साइट उपयोग का डेटा हमारे सोशल मिडिया के साथ भी शेअर कर सकते हैं. अधिक जानकारी के लिए, कृपया हमारी गोपनीयता निती की समीक्षा करें.",
    selectAll: "सभी का चयन करें",
    clearAll: "सब साफ़ करें"
  },
  banner: {
    title: "यह वेबसाइट कुकीज़ का उपयोग करती है",
    description: "हम सामग्री निजीकृत करने और उपयोगकर्ता का अनुभव सुधारने के लिए कुकीज़/ब्राउज़र स्टोरेज का उपयोग करते हैं.",
    allowAll: "सभी अनुमत करें",
    viewDetails: "विवरण देखें",
    consentManagement: "सहमति प्रबंधन"
  }
};
var checkoutLogin$a = {
  emailAddress: {
    label: "ईमेल पता",
    placeholder: "ईमेल दर्ज करें"
  },
  confirmEmail: {
    label: "ईमेल की पुष्टि करें",
    placeholder: "ईमेल की पुष्टि करें"
  },
  "continue": "जारी रखें",
  emailIsRequired: "अमान्य ईमेल स्वरूप",
  emailsMustMatch: "ईमेल मेल हीं खाता"
};
var authMessages$a = {
  signedOutSuccessfully: "आपने सफलतापूर्वक साइन आउट किया."
};
var user$a = {
  anonymousConsents: anonymousConsents$a,
  checkoutLogin: checkoutLogin$a,
  authMessages: authMessages$a
};
var player$a = {
  label: "विडियो प्लेयर"
};
var video$a = {
  player: player$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  common: common$l,
  deliveryMode: deliveryMode$a,
  myAccountV2Consent: myAccountV2Consent$l,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$l,
  myAccount: myAccount$a,
  payment: payment$a,
  pdf: pdf$l,
  product: product$a,
  pwa: pwa$l,
  siteThemeSwitcher: siteThemeSwitcher$l,
  user: user$a,
  video: video$a
};
var common$i = {
  cancel: "Mégse",
  "delete": "Törlés",
  remove: "Eltávolítás",
  edit: "Szerkesztés",
  restore: "Visszaállítás",
  back: "Vissza",
  submit: "Elküldés",
  "continue": "Folytatás",
  save: "Mentés",
  done: "Kész",
  home: "Kezdőlap",
  noData: "Nincs adat.",
  confirm: "Megerősítés",
  more: "több",
  close: "Bezárás",
  loading: "Betöltés",
  menu: "Menü",
  reset: "Alaphelyzetbe állítás",
  search: "Keresés",
  add: "Hozzáadás",
  breadcrumbs: "útkövetések",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Fájl kiválasztása",
  clear: "Törlés",
  loaded: "Betöltve",
  results: "Eredmények",
  of: "/",
  ngSelectDropdownOptionsList: "Opciók",
  required: "kötelező",
  zoomIn: "Nagyítás",
  zoomOut: "Kicsinyítés",
  selected: "Kiválasztva",
  expand: "Kibontás",
  collapse: "Összecsukás"
};
var pageMetaResolver$9 = {
  category: {
    title: "{{count}} találat a következőre: {{query}}",
    title_other: "{{count}} találat a következőre: {{query}}"
  },
  checkout: {
    title: "Fizetés"
  },
  search: {
    title: "{{count}} találat a következőre: „{{query}}”",
    title_other: "{{count}} találat a következőre: „{{query}}”",
    findProductTitle: "{{count}} találat a következő kuponra: „{{coupon}}”",
    findProductTitle_other: "{{count}} találat a következő kuponra: „{{coupon}}”",
    default_title: "Összes termék"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$9 = {
  loading: "Betöltés..."
};
var navigation$9 = {
  categoryNavLabel: "Kategória menü",
  footerNavLabel: "Lábléchivatkozások",
  goTo: "Tovább: {{location}}",
  navigateTo: "Navigálás: {{nav}}",
  scrollToTop: "Visszagörgetés az oldal tetejére",
  linkItemInList: "{{title}}. {{position}}/{{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$9 = {
  placeholder: "Adja meg a terméknevet vagy cikkszámot",
  productSearch: "Termék keresése",
  ariaLabelInput: "Itt kereshet...",
  ariaLabelSuggestions: "javaslatok begépelése",
  ariaLabelProducts: "terméktalálatok",
  initialDescription: "Ha elérhetők az automatikus kiegészítés találatai, a fel és le nyilakkal ellenőrizheti, az Enter billentyűvel pedig kiválaszthatja őket. Az érintőképernyős eszközök felhasználói érintéssel vagy húzással navigálhatnak.",
  suggestions: "Javasolt kulcsszavak",
  recentSearches: "Legutóbbi keresések",
  trendingSearches: "Népszerű keresések",
  suggestionsResult: "{{ count }} javaslat és ",
  suggestionsResult_other: "{{ count }} javaslat és ",
  products: "Ajánlott termékek",
  productsResult: "{{ count }} termék elérhető.",
  productsResult_other: "{{ count }} termék elérhető.",
  resetLabel: "Keresés visszaállítása",
  help: {
    insufficientChars: "Gépeljen be több karaktert",
    noMatch: "Nem találtunk semmit",
    exactMatch: "{{ term }}",
    empty: "Kérdezzen bármit"
  },
  closeSearchPanel: "Bezárás"
};
var sorting$9 = {
  date: "Dátum",
  orderNumber: "Rendelésszám",
  rma: "Visszáru száma",
  replenishmentNumber: "Utánpótlás száma",
  nextOrderDate: "Következő rendelés dátuma",
  pageViewUpdated: "Az oldalnézet frissült a kiválasztott beállítások alapján."
};
var httpHandlers$9 = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Jelentkezzen be újra.",
    user_is_disabled: "{{ errorMessage }}. Forduljon egy rendszergazdához."
  },
  badGateway: "Szerverhiba történt. Próbálja újra később.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Jelentkezzen be újra.",
  badRequestOldPasswordIncorrect: "A régi jelszó helytelen.",
  badRequestGuestDuplicateEmail: "Az e-mail már létezik ({{ errorMessage }}). Fizessen egy másik e-mail-címmel, vagy regisztráljon egy vendégfiókkal.",
  conflict: "Már létezik.",
  forbidden: "Nincs jogosultsága végrehajtani ezt a műveletet. Ha úgy gondolja, ez egy hiba, forduljon a rendszergazdájához.",
  gatewayTimeout: "A szerver nem válaszolt, próbálja újra később.",
  internalServerError: "Belső szerverhiba történt. Próbálja újra később.",
  sessionExpired: "Munkamenete lejárt. Jelentkezzen be újból.",
  unknownError: "Ismeretlen hiba történt",
  unauthorized: {
    common: "Ismeretlen hitelesítési hiba történt",
    invalid_client: "A kliens hitelesítési adatai hibásak"
  },
  validationErrors: {
    missing: {
      card_cardType: "A kiválasztott hitelkártya nem támogatott. Válasszon másikat.",
      card_accountNumber: "A megadott hitelkártyaszám nem érvényes.",
      card_cvNumber: "A megadott biztonsági kód nem érvényes.",
      card_expirationMonth: "A hitelkártya megadott lejárati dátuma nem érvényes.",
      card_expirationYear: "A hitelkártya megadott lejárati dátuma nem érvényes.",
      billTo_firstName: "A megadott utónév nem érvényes.",
      billTo_lastName: "A megadott vezetéknév nem érvényes.",
      billTo_street1: "A megadott cím nem érvényes.",
      billTo_street2: "A megadott cím nem érvényes.",
      billTo_city: "A megadott város nem érvényes ehhez a hitelkártyához.",
      billTo_state: "A megadott állam/tartomány nem érvényes ehhez a hitelkártyához.",
      billTo_country: "A megadott ország/régió nem érvényes ehhez a hitelkártyához.",
      billTo_postalCode: "A megadott irányítószám nem érvényes ehhez a hitelkártyához.",
      country: {
        isocode: "Hiányzó ország/régió"
      }
    },
    invalid: {
      card_expirationMonth: "A hitelkártya megadott lejárati dátuma nem érvényes.",
      firstName: "A megadott utónév nem érvényes.",
      lastName: "A megadott vezetéknév nem érvényes.",
      password: "A megadott jelszó nem érvényes.",
      uid: "Az egyedi azonosító nem érvényes.",
      code: "A kód nem érvényes.",
      email: "Az e-mail nem érvényes.",
      loginId: "Az e-mail nem érvényes."
    }
  },
  cartNotFound: "A kosár nem található.",
  invalidCodeProvided: "A megadott kód érvénytelen.",
  voucherExceeded: "A kupon túllépte a felhasználási számára vonatkozó korlátot",
  unknownIdentifier: "A tétel nem található.",
  otherCartErrors: "Kosárhibák történtek."
};
var miniCart$9 = {
  item: "Jelenleg {{count}} tétel található a kosarában",
  item_other: "Jelenleg {{count}} tétel található a kosarában",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$9 = {
  skipTo: "Ugrás a következőre:",
  labels: {
    header: "Fejléc",
    main: "Fő tartalom",
    footer: "Lábléc",
    productFacets: "Termékkategória",
    productList: "Terméklista"
  }
};
var carousel$9 = {
  previousSlide: "Előző dia",
  nextSlide: "Következő dia",
  slideNumber: "A(z) {{currentSlideNumber}} dia {{numberOfSlides}} tételt tartalmaz",
  carouselForProduct: "Forgótár, képek a következőhöz: {{product}}"
};
var formErrors$9 = {
  labeled: {
    required: "A(z) {{label}} mező kötelező",
    cxInvalidEmail: "A(z) {{label}} mező nem egy érvényes e-mail-formátummal rendelkezik. Példa: pelda@tartomany.hu",
    cxInvalidPassword: "A(z) {{label}} mező nem egy érvényes jelszóformátummal rendelkezik",
    cxMinOneUpperCaseCharacter: "A jelszónak tartalmaznia kell legalább egy nagybetűt",
    cxMinOneDigit: "A jelszónak tartalmaznia kell legalább egy számjegyet",
    cxMinOneSpecialCharacter: "A jelszónak tartalmaznia kell legalább egy speciális karaktert",
    cxMinSixCharactersLength: "A jelszónak legalább 6 karakter hosszúságúnak kell lennie",
    cxMinEightCharactersLength: "A jelszónak legalább 8 karakter hosszúságúnak kell lennie",
    cxMaxCharactersLength: "A jelszó nem tartalmazhat 128 karakternél többet",
    cxContainsSpecialCharacters: "A jelszó nem tartalmazhat speciális karaktereket",
    cxNoConsecutiveCharacters: "A jelszó nem tartalmazhat egymást követő azonos karaktereket",
    date: {
      required: "A(z) {{label}} mező kötelező",
      min: "A(z) {{label}} mező nem eshet {{min}} elé",
      max: "A(z) {{label}} mező nem eshet {{max}} után",
      pattern: "Használjon éééé-hh-nn dátumformátumot a(z) {{label}} mezőben",
      invalid: "Használjon érvényes dátumot a(z) {{label}} mezőben"
    }
  },
  globalMessage: "Az elküldeni kívánt űrlap hibákat tartalmaz.",
  required: "Ez a mező kötelező",
  cxInvalidEmail: "Ez nem egy érvényes e-mail-formátum",
  cxInvalidPassword: "Ez nem egy érvényes jelszóformátum",
  cxPasswordsMustMatch: "A jelszómezőknek egyezniük kell",
  cxPasswordsCannotMatch: "A jelszómezők nem egyezhetnek",
  cxEmailsMustMatch: "Az e-mail-mezőknek egyezniük kell",
  cxStarRatingEmpty: "Az értékelés mező kitöltése kötelező",
  cxNoSelectedItemToCancel: "Válasszon ki legalább egy tételt",
  cxNegativeAmount: "Az összegnek legalább nullának kell lennie",
  cxContainsSpecialCharacters: "A mező nem tartalmazhat speciális karaktereket",
  date: {
    required: "Ez a mező kötelező",
    min: "A dátum nem eshet {{min}} elé",
    max: "A dátum nem eshet {{max}} után",
    pattern: "Használjon éééé-hh-nn dátumformátumot",
    invalid: "Használjon érvényes dátumot"
  },
  file: {
    required: "A fájl kitöltése kötelező",
    empty: "A fájl nem lehet üres",
    invalidExtension: "A fájlkiterjesztés nem érvényes",
    tooLarge: "A fájlméret nem haladhatja meg a(z) {{ maxSize }} MB-ot",
    tooManyEntries: "A tételek száma több mint {{ maxEntries }}",
    notParsable: "A fájl nem értelmezhető",
    fileNotAllowed: "Ez a fájltípus nem engedélyezett"
  }
};
var errorHandlers$9 = {
  scriptFailedToLoad: "Nem sikerült betölteni a szkriptet.",
  refreshThePage: "Frissítse az oldalt."
};
var assistiveMessage$9 = {
  actionCancelled: "Művelet megszakítva, semmi nem módosult"
};
var passwordVisibility$9 = {
  showPassword: "Jelszó megjelenítése",
  hidePassword: "Jelszó elrejtése"
};
var generalErrors$9 = {
  pageFailure: "Nem sikerült betölteni az oldalt. Próbálja újra később."
};
var formLegend$9 = {
  required: "A *-gal jelölt mezők kitöltése kötelező."
};
var chatMessaging$9 = {
  charactersLeft: "hátralévő karakterek: {{count}}",
  addNewMessage: "Új üzenet hozzáadása",
  send: "Küldés",
  uploadFile: "Fájl feltöltése",
  informationLabel: "{{author}}. {{text}}, {{date}}",
  messages: "Üzenetek",
  addMessagePlaceHolder: "Kezdjen el gépelni...",
  characterLimitAlert: "Elérte a karakterkorlátot.",
  a11y: {
    itemListBoxLabel: "Válassza ki a tételt, amelyre szeretné, hogy vonatkozzon az üzenet."
  }
};
var common$j = {
  common: common$i,
  pageMetaResolver: pageMetaResolver$9,
  spinner: spinner$9,
  navigation: navigation$9,
  searchBox: searchBox$9,
  sorting: sorting$9,
  httpHandlers: httpHandlers$9,
  miniCart: miniCart$9,
  skipLink: skipLink$9,
  carousel: carousel$9,
  formErrors: formErrors$9,
  errorHandlers: errorHandlers$9,
  assistiveMessage: assistiveMessage$9,
  passwordVisibility: passwordVisibility$9,
  generalErrors: generalErrors$9,
  formLegend: formLegend$9,
  chatMessaging: chatMessaging$9
};
var setDeliveryMode$9 = {
  unknownError: "Ismeretlen hiba történt. Forduljon a támogatáshoz."
};
var deliveryMode$9 = {
  setDeliveryMode: setDeliveryMode$9
};
var myAccountV2Consent$i = {
  header: "Hozzájárulás-kezelés",
  dateDescription: "Jóváhagyás dátuma ",
  clearAll: "Összes letiltása",
  selectAll: "Összes engedélyezése",
  message: {
    success: {
      given: "Hozzájárulás megadása sikeres.",
      withdrawn: "Hozzájárulás visszavonása sikeres."
    }
  }
};
var myAccountV2Consent$j = {
  myAccountV2Consent: myAccountV2Consent$i
};
var myAccountV2NotificationPreference$i = {
  header: "Értesítési csatornák",
  message: "A preferált értesítési csatornáit az",
  note: "Megjegyzés: ",
  noteMessage: "Ha inaktiválja az összes csatornát, nem fog tudni további értesítéseket fogadni.",
  EMAIL: "E-mail:"
};
var myAccountV2NotificationPreference$j = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$i
};
var closeAccount$9 = {
  confirmAccountClosure: "Fiókbezárás megerősítése",
  confirmAccountClosureMessage: "Biztosan szeretné bezárni a fiókját?",
  closeMyAccount: "FIÓK BEZÁRÁSA",
  accountClosedSuccessfully: "Fiók bezárása sikeres",
  accountClosedFailure: "Fiók bezárása sikertelen"
};
var updatePasswordForm$9 = {
  oldPassword: {
    label: "Régi jelszó",
    placeholder: "Régi jelszó"
  },
  oldPasswordIsRequired: "A régi jelszó megadása kötelező.",
  newPassword: {
    label: "Új jelszó",
    placeholder: "Új jelszó"
  },
  passwordMinRequirements: "Íme egy gyors útmutató a biztonságos jelszó létrehozásához: Használjon legalább 8 karaktert, kis- és nagybetűket, számokat és szimbólumokat.",
  passwordStrengthRequirements: "Íme egy gyors útmutató a biztonságos jelszó létrehozásához: Használjon legalább 8 karaktert, kis- és nagybetűket, számokat és szimbólumokat. Ne használja többször egymás után ugyanazt a karaktert.",
  confirmPassword: {
    label: "Új jelszó megerősítése",
    placeholder: "Jelszó megerősítése"
  },
  bothPasswordMustMatch: "A két jelszónak egyeznie kell",
  passwordUpdateSuccess: "Jelszó frissítése sikeres",
  accessDeniedError: "Hozzáférés megtagadva"
};
var updateProfileForm$9 = {
  title: "Megszólítás",
  none: "",
  firstName: {
    label: "Utónév",
    placeholder: "Utónév"
  },
  firstNameIsRequired: "Az utónév megadása kötelező.",
  lastName: {
    label: "Vezetéknév",
    placeholder: "Vezetéknév"
  },
  lastNameIsRequired: "A vezetéknév megadása kötelező.",
  profileUpdateSuccess: "A személyes adatok frissítése sikeres",
  customerId: "Ügyfél #"
};
var consentManagementForm$9 = {
  clearAll: "Összes törlése",
  selectAll: "Összes kiválasztása",
  message: {
    success: {
      given: "Hozzájárulás megadása sikeres.",
      withdrawn: "Hozzájárulás visszavonása sikeres."
    }
  }
};
var myCoupons$9 = {
  noCouponsMessage: "Nincsenek elérhető kuponjai.",
  effectiveTitle: "Érvényes:",
  Effective: "ÉRVÉNYES",
  PreSession: "HAMAROSAN ÉRVÉNYBE LÉP",
  ExpireSoon: "HAMAROSAN LEJÁR",
  readMore: "További információ",
  notification: "Értesítés",
  findProducts: "Termékek keresése",
  status: "Státus:",
  dialogTitle: "Kupon",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Kuponkód",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Sikeresen igényelte ezt a kupont.",
  myCoupons: "Saját kuponok",
  startDateAsc: "Kezdő dátum (növekvő)",
  startDateDesc: "Kezdő dátum (csökkenő)",
  endDateAsc: "Záró dátum (növekvő)",
  endDateDesc: "Záró dátum (csökkenő)",
  sortBy: "Rendezés alapja",
  sortCoupons: "Kuponok rendezése",
  notesPreffix: "A kuponértesítések fogadása esetén előnyben részesített csatornát az ",
  notesLink: "Értesítési csatornák",
  notesSuffix: " oldalon kezelheti."
};
var notificationPreference$9 = {
  message: "A preferált értesítési csatornáit az",
  note: "Megjegyzés: ",
  noteMessage: "Ha inaktiválja az összes csatornát, nem fog tudni további értesítéseket fogadni.",
  EMAIL: "E-mail:",
  SMS: "SMS:",
  SITE_MESSAGE: "Webhelyüzenet"
};
var myInterests$9 = {
  header: "Érdeklődési köreim",
  item: "TÉTEL",
  price: "ÁR",
  notifications: "ÉRTESÍTÉSEK",
  noInterests: "Még nem regisztrált érdeklődési kört.",
  inStock: "Készleten",
  lowStock: "Kevés van készleten",
  outOfStock: "Nincs készleten",
  BACK_IN_STOCK: "Újra készleten",
  sortBy: "Rendezés alapja",
  sortInterests: "Érdeklődési körök rendezése",
  expirationDate: " Eddig: {{ expirationDate }}",
  productId: "Azonosító: {{ code }}",
  remove: "Eltávolítás",
  sorting: {
    byNameAsc: "Név (növekvő sorrend)",
    byNameDesc: "Név (csökkenő sorrend)"
  },
  actions: "MŰVELETEK",
  caption: "Az „Engem érdeklők” tartalma.",
  itemRemoved: "A kiválasztott tétel el lett távolítva."
};
var myAccount$9 = {
  closeAccount: closeAccount$9,
  updatePasswordForm: updatePasswordForm$9,
  updateProfileForm: updateProfileForm$9,
  consentManagementForm: consentManagementForm$9,
  myCoupons: myCoupons$9,
  notificationPreference: notificationPreference$9,
  myInterests: myInterests$9
};
var paymentForm$9 = {
  payment: "Fizetés",
  choosePaymentMethod: "Válasszon fizetési módot",
  paymentType: "Fizetési mód",
  accountHolderName: {
    label: "Számlatulajdonos neve",
    placeholder: "Számlatulajdonos neve"
  },
  cardNumber: "Kártya száma",
  expirationDate: "Lejárat dátuma",
  securityCode: "Biztonsági kód (CVV)",
  securityCodeTitle: "Kártyahitelesítési érték",
  saveAsDefault: "Mentés alapértelmezettként",
  setAsDefault: "Beállítás alapértelmezett fizetési módként",
  billingAddress: "Számlázási cím",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Megegyezik a kézbesítési címmel",
  billingAddressSameAsShipping: "A számlázási cím megegyezik a kézbesítési címmel",
  selectOne: "Válasszon egyet…",
  monthMask: "HH",
  yearMask: "ÉÉÉÉ",
  expirationYear: "Lejárat éve: {{ selected }}",
  expirationMonth: "Lejárat hónapja: {{ selected }}",
  useThisPayment: "Fizetés használata",
  addNewPayment: "Új fizetés hozzáadása",
  paymentAddedSuccessfully: "Az új fizetés hozzáadása sikeres",
  changePayment: "Fizetés módosítása"
};
var paymentMethods$9 = {
  paymentMethods: "Fizetési módok",
  paymentMethodSelected: "Fizetési mód kiválasztva",
  newPaymentMethodsAreAddedDuringCheckout: "Új fizetési módokat a fizetéskor lehet hozzáadni.",
  invalidField: "InvalidField: {{ field }}"
};
var paymentCard$9 = {
  deleteConfirmation: "Biztosan törölni szeretné ezt a fizetési módot?",
  setAsDefault: "Beállítás alapértelmezettként",
  expires: "Lejárat: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ ALAPÉRTELMEZETT",
  defaultPaymentLabel: "Alapértelmezett fizetési mód",
  additionalPaymentLabel: "További fizetési mód: {{ number }}",
  selected: "Kiválasztva",
  selectedPayment: "Kiválasztott fizetés",
  deletePaymentSuccess: "Fizetési mód törlése sikeres",
  visa: "Visa kártya",
  master: "Mastercard kártya",
  masterEuro: "Mastercard Eurocard kártya",
  dinersClub: "Diners Club kártya",
  "switch": "Switch kártya",
  maestro: "Maestro kártya",
  amex: "American Express kártya",
  credit: "Hitelkártya"
};
var paymentTypes$9 = {
  title: "Fizetési mód",
  paymentType_CARD: "Hitelkártya",
  paymentType_ACCOUNT: "Számla"
};
var paymentMessages$9 = {
  setAsDefaultSuccessfully: "Új fizetés sikeresen beállítva alapértelmezettként"
};
var payment$9 = {
  paymentForm: paymentForm$9,
  paymentMethods: paymentMethods$9,
  paymentCard: paymentCard$9,
  paymentTypes: paymentTypes$9,
  paymentMessages: paymentMessages$9
};
var pdf$i = {
  defaultTitle: "Dokumentum"
};
var pdf$j = {
  pdf: pdf$i
};
var productDetails$9 = {
  id: "Azonosító",
  quantity: "Menny.",
  quantityFull: "Mennyiség",
  productDetails: "Termék részletei",
  specification: "Leírás",
  reviews: "Értékelések",
  shipping: "Kiszállítás",
  share: "Megosztás",
  showReviews: "Értékelések megjelenítése",
  noReviews: "Még nincsenek értékelések",
  productPrice: "Termékár",
  noProductImage: "Nincs elérhető kép, {{ product }}"
};
var productList$9 = {
  filterBy: {
    label: "Szűrés alapja",
    action: "Szűrés alapja"
  },
  activeFilter: "{{filter}} szűrő, a gombra kattintva eltávolíthatja a szűrőt",
  appliedFilter: "Alkalmazott szűrő:",
  showLess: "Kevesebb megjelenítése...",
  showMore: "Több megjelenítése...",
  sortBy: "Rendezés alapja",
  sortResults: "Eredmények rendezése",
  backToTopBtn: "VISSZA AZ OLDAL TETEJÉRE",
  showMoreBtn: "TÖBB MEGJELENÍTÉSE",
  productSearchPagination: "Termékkeresés oldalszámozása",
  productListResults: "Terméktalálatok listája"
};
var productFacetNavigation$9 = {
  filterBy: {
    label: "Szűrés alapja",
    action: "Szűrés alapja",
    facet: "Találatok szűrése kategóriák szerint",
    name: "Szűrés alapja: {{name}}"
  },
  appliedFilter: "Alkalmazott szűrő:",
  showLess: "Kevesebb megjelenítése...",
  showMore: "Több megjelenítése...",
  sortBy: "Rendezés alapja",
  ariaLabelShowLess: "Kevesebb megjelenítése gomb, erre a gombra kattintva csökkentheti az aktív csoporthoz rendelkezésre álló beállításokat",
  ariaLabelShowMore: "Több megjelenítése gomb, erre a gombra kattintva megjelenítheti az aktív csoporthoz rendelkezésre álló összes beállítást",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} tétel elérhető",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} tétel elérhető",
  decreaseOptionsVisibility: "Néhány beállítás el van rejtve az aktív csoportból; az elolvasáshoz lépjen vissza, a következő csoporthoz lépjen előre",
  increaseOptionsVisibility: "További beállításokat adtak hozzá az aktív csoporthoz; az elolvasáshoz lépjen vissza, a következő csoporthoz lépjen előre",
  backToResults: "Vissza a találatokhoz",
  productFacets: "Termékkategóriák"
};
var productSummary$9 = {
  id: "Azonosító",
  showReviews: "Értékelések megjelenítése",
  showReviewsDetailed: "{{count}} értékelés megjelenítése, minősítés: {{rating}}/5 csillag",
  share: "Megosztás",
  newItemPrice: "Új tétel ára"
};
var productReview$9 = {
  overallRating: "Átfogó minősítés",
  reviewTitle: "Értékelés címe",
  writeYourComments: "Írjon megjegyzést",
  rating: "Minősítés",
  ratingRequired: "Termékminősítés, kötelező",
  addRate: "Minősítés hozzáadása: {{count}} csillag",
  addRate_other: "Minősítés hozzáadása: {{count}} csillag",
  reviewerName: "Értékelő neve (opcionális)",
  writeReview: "Értékelés írása",
  more: "Több értékelés megjelenítése",
  less: "Kevesebb értékelés megjelenítése",
  thankYouForReview: "Köszönjük az értékelést! Megjegyzés: előfordulhat, hogy az értékelések csak a felülvizsgálatukat követően jelennek meg itt.",
  postReviewFail: "Hiba történt az értékelés közzétételekor. Próbálja újra később.",
  ratedOutOf: "Minősítés: {{rating}}/5"
};
var productCarousel$9 = {
  carouselLabel: "Forgótár, {{title}}"
};
var addToCart$9 = {
  itemAddedToYourCart: "Tétel hozzáadva a kosarához",
  itemsAddedToYourCart: "Tételek hozzáadva a kosarához",
  itemsIncrementedInYourCart: "Ez a tétel már a kosarában volt. Frissült a mennyiség.",
  items: "tételek",
  updatingCart: "Kosár frissítése...",
  addToCart: "Kosárba",
  viewCart: "kosár megtekintése",
  proceedToCheckout: "tovább a fizetéshez",
  quantity: "Menny.",
  quantityFull: "Mennyiség",
  outOfStock: "Nincs készleten",
  inStock: "Készleten",
  selectStyleAndSize: "Válasszon stílust és méretet a készlet ellenőrzéséhez",
  removeFromCart: "Termék eltávolítása a kosárból",
  closeModal: "Párbeszédpanel bezárása",
  buyItAgain: "Ismételt vásárlás",
  addToActiveCart: "Hozzáadás az aktív kosárhoz"
};
var TabPanelContainer$9 = {
  tabs: {
    ProductDetailsTabComponent: "Termék részletei",
    ProductSpecsTabComponent: "Leírás",
    ProductReviewsTabComponent: "Értékelések",
    deliveryTab: "Kiszállítás",
    SparePartsTabComponent: " Tartalékalkatrészek"
  },
  tabPanelContainerRegion: "További termékrészleteket tartalmazó lapcsoport",
  tabPanelContainerRegionGroup: "További termékrészleteket tartalmazó csoport"
};
var addToWishList$9 = {
  add: "Hozzáadás a kívánságlistához",
  remove: "Eltávolítás a kívánságlistáról",
  anonymous: "Jelentkezzen be a kívánságlistához történő hozzáadáshoz",
  addedToWishList: "Termék hozzáadva a kívánságlistához",
  removedFromWishList: "Termék eltávolítva a kívánságlistáról"
};
var stockNotification$9 = {
  notifyMe: "ÉRTESÍTÉST KÉREK",
  stopNotify: "ÉRTESÍTÉS LEÁLLÍTÁSA",
  getNotify: "Értesítést kérek, ha elérhetővé válik a termék.",
  getNotifySuffix: "az értesítés kéréséhez, ha elérhetővé válik a termék.",
  activateChannelsPrefix: "Az értesítéshez aktiválnia kell a következőt: ",
  channelsLink: "Értesítési csatornák",
  activateChannelsSuffix: ".",
  notified: "Értesítjük, ha a termék ismét készleten lesz.",
  getNotified: "Értesítést kaphat, ha a termék ismét készleten lesz.",
  unsubscribeSuccess: "Nem kap értesítést, ha a termék ismét készleten lesz.",
  subscriptionDialog: {
    header: "Feliratkozás a készletállapotra vonatkozó értesítésekre",
    notifiedPrefix: "Értesítjük, ha:",
    notifiedSuffix: "a termék ismét készleten lesz.",
    manageChannelsPrefix: "A preferált értesítési csatornáit az ",
    manageChannelsLink: "Értesítési beállítások",
    manageChannelsSuffix: " oldalon kezelheti.",
    manageSubscriptionsPrefix: "A feliratkozásait az ",
    manageSubscriptionsLink: "Érdeklődési köreim",
    manageSubscriptionsSuffix: " oldalon kezelheti.",
    okBtn: "OK",
    subscribing: "Elindítjuk a feliratkozását a termék készletállapotára vonatkozó értesítéseire"
  }
};
var itemCounter$9 = {
  removeOne: "Egy eltávolítása",
  addOneMore: "Még egy hozzáadása",
  quantity: "Mennyiség"
};
var productView$9 = {
  gridView: "Válassza a Rácsnézetre váltáshoz",
  listView: "Válassza a Listanézetre váltáshoz"
};
var product$9 = {
  productDetails: productDetails$9,
  productList: productList$9,
  productFacetNavigation: productFacetNavigation$9,
  productSummary: productSummary$9,
  productReview: productReview$9,
  productCarousel: productCarousel$9,
  addToCart: addToCart$9,
  TabPanelContainer: TabPanelContainer$9,
  addToWishList: addToWishList$9,
  stockNotification: stockNotification$9,
  itemCounter: itemCounter$9,
  productView: productView$9
};
var pwa$i = {
  addToHomeScreenDescription: "A gyorsabb visszalátogatáshoz adja hozzá az SAP storefrontot eszköze kezdőképernyőjéhez",
  noInstallationNeeded: "Nincs szükség telepítésre",
  fastAccessToApplication: "Gyors hozzáférés az alkalmazáshoz",
  addToHomeScreen: "Hozzáadás a kezdőképernyőhöz",
  addedToHomeScreen: "Hozzáadta az SAP storefrontot a kezdőképernyőjéhez"
};
var pwa$j = {
  pwa: pwa$i
};
var siteThemeSwitcher$i = {
  theme: "Téma",
  themes: {
    "default": "Alapértelmezett",
    highContrastDark: "HC-sötét",
    highContrastLight: "HC-világos"
  }
};
var siteThemeSwitcher$j = {
  siteThemeSwitcher: siteThemeSwitcher$i
};
var anonymousConsents$9 = {
  dialog: {
    title: "Hozzájárulás-kezelés",
    legalDescription: "Cookie-kat/a böngésző tárhelyét használjuk, hogy személyre szabjuk a tartalmat és javítsuk a felhasználói élményt. Ezen felül megoszthatjuk a weboldal felhasználásával kapcsolatos adatait a közösségi médiánkkal. További információkat a cookie-kra vonatkozó szabályzatunkban találhat.",
    selectAll: "Összes kiválasztása",
    clearAll: "Összes törlése"
  },
  banner: {
    title: "Ez a weboldal cookie-kat használ",
    description: "Cookie-kat/a böngésző tárhelyét használjuk, hogy személyre szabjuk a tartalmat és javítsuk a felhasználói élményt.",
    allowAll: "Összes engedélyezése",
    viewDetails: "Részletek megtekintése",
    consentManagement: "Hozzájárulás-kezelés"
  }
};
var checkoutLogin$9 = {
  emailAddress: {
    label: "E-mail-cím",
    placeholder: "E-mail-cím megadása"
  },
  confirmEmail: {
    label: "E-mail-cím megerősítése",
    placeholder: "E-mail-cím megerősítése"
  },
  "continue": "Folytatás",
  emailIsRequired: "Érvénytelen e-mail-formátum",
  emailsMustMatch: "Az e-mail-cím nem egyezik"
};
var authMessages$9 = {
  signedOutSuccessfully: "Sikeresen kijelentkezett."
};
var user$9 = {
  anonymousConsents: anonymousConsents$9,
  checkoutLogin: checkoutLogin$9,
  authMessages: authMessages$9
};
var player$9 = {
  label: "Videólejátszó"
};
var video$9 = {
  player: player$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  common: common$j,
  deliveryMode: deliveryMode$9,
  myAccountV2Consent: myAccountV2Consent$j,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$j,
  myAccount: myAccount$9,
  payment: payment$9,
  pdf: pdf$j,
  product: product$9,
  pwa: pwa$j,
  siteThemeSwitcher: siteThemeSwitcher$j,
  user: user$9,
  video: video$9
};
var common$g = {
  cancel: "Batalkan",
  "delete": "Hapus",
  remove: "Hapus",
  edit: "Edit",
  restore: "Pulihkan",
  back: "Kembali",
  submit: "Kirim",
  "continue": "Lanjutkan",
  save: "Simpan",
  done: "Selesai",
  home: "Beranda",
  noData: "Tidak ada data.",
  confirm: "Konfirmasi",
  more: "lainnya",
  close: "Tutup",
  loading: "Memuat",
  menu: "Menu",
  reset: "Atur Ulang",
  search: "Cari",
  add: "Tambahkan",
  breadcrumbs: "jejak navigasi",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Pilih file",
  clear: "Hapus",
  loaded: "Dimuat",
  results: "Hasil",
  of: "dari",
  ngSelectDropdownOptionsList: "Pilihan",
  required: "diwajibkan",
  zoomIn: "Perbesar",
  zoomOut: "Perkecil",
  selected: "Dipilih",
  expand: "Perluas",
  collapse: "Ciutkan"
};
var pageMetaResolver$8 = {
  category: {
    title: "{{count}} hasil untuk {{query}}",
    title_other: "{{count}} hasil untuk {{query}}"
  },
  checkout: {
    title: "Pembayaran"
  },
  search: {
    title: "{{count}} hasil untuk \"{{query}}\"",
    title_other: "{{count}} hasil untuk \"{{query}}\"",
    findProductTitle: "{{count}} hasil untuk kupon \"{{coupon}}\"",
    findProductTitle_other: "{{count}} hasil untuk kupon \"{{coupon}}\"",
    default_title: "Semua produk"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$8 = {
  loading: "Memuat..."
};
var navigation$8 = {
  categoryNavLabel: "Menu kategori",
  footerNavLabel: "Tautan footer",
  goTo: "Buka {{location}}",
  navigateTo: "Arahkan ke {{nav}}",
  scrollToTop: "Kembali ke bagian atas halaman",
  linkItemInList: "{{title}}. {{position}} dari {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$8 = {
  placeholder: "Masukkan nama produk atau SKU",
  productSearch: "Cari produk",
  ariaLabelInput: "Cari di sini...",
  ariaLabelSuggestions: "saran pengetikan",
  ariaLabelProducts: "hasil produk",
  initialDescription: "Jika hasil pengisian otomatis tersedia, gunakan tombol panah atas dan bawah untuk meninjau dan tombol enter untuk memilih. Untuk pengguna dengan perangkat layar sentuh, jelajahi dengan sentuhan atau gerakan geser.",
  suggestions: "Kata Kunci yang Disarankan",
  recentSearches: "Pencarian Terbaru",
  trendingSearches: "Pencarian Populer",
  suggestionsResult: "{{ count }} saran dan ",
  suggestionsResult_other: "{{ count }} saran dan ",
  products: "Produk yang Direkomendasikan",
  productsResult: "{{ count }} produk tersedia.",
  productsResult_other: "{{ count }} produk tersedia.",
  resetLabel: "Atur Ulang Pencarian",
  help: {
    insufficientChars: "Silakan ketikkan lebih banyak karakter",
    noMatch: "Kami tidak dapat menemukan hasil apa pun",
    exactMatch: "{{ term }}",
    empty: "Tanyakan apa saja kepada kami"
  },
  closeSearchPanel: "Tutup"
};
var sorting$8 = {
  date: "Tanggal",
  orderNumber: "Nomor Pemesanan",
  rma: "Nomor Pengembalian",
  replenishmentNumber: "Nomor Pengadaan Ulang",
  nextOrderDate: "Tanggal Pemesanan Berikutnya",
  pageViewUpdated: "Tampilan halaman diperbarui sesuai dengan opsi pilihan Anda."
};
var httpHandlers$8 = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Silakan masuk kembali.",
    user_is_disabled: "{{ errorMessage }}. Silakan hubungi bagian administrasi."
  },
  badGateway: "Terjadi kesalahan pada server. Silakan coba lagi nanti.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Silakan masuk kembali.",
  badRequestOldPasswordIncorrect: "Kata sandi lama salah.",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} email sudah ada. Silakan lakukan proses pembayaran dengan email lain untuk mendaftar dengan akun tamu.",
  conflict: "Sudah ada.",
  forbidden: "Anda tidak diizinkan untuk melakukan tindakan ini. Jika Anda merasa ini adalah kesalahan, hubungi administrator Anda.",
  gatewayTimeout: "Server tidak merespons, silakan coba lagi nanti.",
  internalServerError: "Terjadi Kesalahan pada Server Internal. Silakan coba lagi nanti.",
  sessionExpired: "Sesi Anda telah berakhir. Silakan masuk kembali.",
  unknownError: "Terjadi kesalahan yang tidak diketahui",
  unauthorized: {
    common: "Terjadi kesalahan otorisasi yang tidak diketahui",
    invalid_client: "Kredensial klien tidak valid"
  },
  validationErrors: {
    missing: {
      card_cardType: "Kartu kredit yang dipilih tidak didukung. Silakan pilih kartu lain.",
      card_accountNumber: "Nomor kartu kredit yang dimasukkan tidak valid.",
      card_cvNumber: "Kode keamanan yang dimasukkan tidak valid.",
      card_expirationMonth: "Tanggal kedaluwarsa kartu kredit yang dimasukkan tidak valid.",
      card_expirationYear: "Tanggal kedaluwarsa kartu kredit yang dimasukkan tidak valid.",
      billTo_firstName: "Nama depan yang dimasukkan tidak valid.",
      billTo_lastName: "Nama belakang yang dimasukkan tidak valid.",
      billTo_street1: "Alamat yang dimasukkan tidak valid.",
      billTo_street2: "Alamat yang dimasukkan tidak valid.",
      billTo_city: "Kota yang dimasukkan tidak valid untuk kartu kredit ini.",
      billTo_state: "Provinsi/negara bagian yang dimasukkan tidak valid untuk kartu kredit ini.",
      billTo_country: "Wilayah/negara yang dimasukkan tidak valid untuk kartu kredit ini.",
      billTo_postalCode: "Kode pos tidak valid untuk kartu kredit ini.",
      country: {
        isocode: "Wilayah/negara tidak ditemukan"
      }
    },
    invalid: {
      card_expirationMonth: "Tanggal kedaluwarsa kartu kredit yang dimasukkan tidak valid.",
      firstName: "Nama Depan yang dimasukkan tidak valid.",
      lastName: "Nama Belakang yang dimasukkan tidak valid.",
      password: "Kata sandi yang dimasukkan tidak valid.",
      uid: "UID tidak valid.",
      code: "Kode tidak valid.",
      email: "Email tidak valid.",
      loginId: "Email tidak valid."
    }
  },
  cartNotFound: "Keranjang tidak ditemukan.",
  invalidCodeProvided: "Kode yang diberikan tidak valid.",
  voucherExceeded: "Kupon ini telah melebihi jumlah penggunaan yang diizinkan",
  unknownIdentifier: "Item tidak ditemukan.",
  otherCartErrors: "Ada masalah dengan keranjang belanja."
};
var miniCart$8 = {
  item: "Saat ini ada {{count}} item di keranjang Anda",
  item_other: "Saat ini ada {{count}} item di keranjang Anda",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$8 = {
  skipTo: "Lompat ke",
  labels: {
    header: "Header",
    main: "Konten Utama",
    footer: "Footer",
    productFacets: "Fitur Produk",
    productList: "Daftar Produk"
  }
};
var carousel$8 = {
  previousSlide: "Slide sebelumnya",
  nextSlide: "Slide berikutnya",
  slideNumber: "Slide ke-{{currentSlideNumber}} terdapat {{numberOfSlides}} item",
  carouselForProduct: "Korsel, Gambar {{product}}"
};
var formErrors$8 = {
  labeled: {
    required: "Bidang {{label}} wajib diisi",
    cxInvalidEmail: "Bidang {{label}} tidak memiliki format email yang valid. Pola yang sesuai: contoh@domainanda.com",
    cxInvalidPassword: "Bidang {{label}} tidak memiliki format email yang valid",
    cxMinOneUpperCaseCharacter: "Kata sandi harus berisi minimal satu karakter huruf besar",
    cxMinOneDigit: "Kata sandi harus berisi minimal satu digit",
    cxMinOneSpecialCharacter: "Kata sandi harus berisi minimal satu karakter khusus",
    cxMinSixCharactersLength: "Kata sandi harus berisi minimal 6 karakter",
    cxMinEightCharactersLength: "Kata sandi harus berisi minimal 8 karakter",
    cxMaxCharactersLength: "Kata sandi tidak boleh lebih dari 128 karakter",
    cxContainsSpecialCharacters: "Kata sandi tidak boleh berisi karakter khusus",
    cxNoConsecutiveCharacters: "Kata sandi tidak boleh berisi karakter identik yang berurutan",
    date: {
      required: "Bidang {{label}} wajib diisi",
      min: "Bidang {{label}} tidak boleh sebelum {{min}}",
      max: "Bidang {{label}} tidak boleh setelah {{max}}",
      pattern: "Gunakan format tanggal tttt-bb-hh pada bidang {{label}}",
      invalid: "Gunakan tanggal yang valid pada bidang {{label}}"
    }
  },
  globalMessage: "Formulir yang Anda coba kirimkan mengandung kesalahan.",
  required: "Bidang ini wajib diisi",
  cxInvalidEmail: "Format email ini tidak valid",
  cxInvalidPassword: "Format kata sandi ini tidak valid",
  cxPasswordsMustMatch: "Bidang kata sandi harus sama",
  cxPasswordsCannotMatch: "Bidang kata sandi tidak boleh sama",
  cxEmailsMustMatch: "Bidang email harus sama",
  cxStarRatingEmpty: "Bidang penilaian wajib diisi",
  cxNoSelectedItemToCancel: "Pilih setidaknya satu item",
  cxNegativeAmount: "Jumlah harus sama dengan atau lebih besar dari nol",
  cxContainsSpecialCharacters: "Bidang tidak boleh berisi karakter khusus",
  date: {
    required: "Bidang ini wajib diisi",
    min: "Tanggal tidak boleh sebelum {{min}}",
    max: "Tanggal tidak boleh setelah {{max}}",
    pattern: "Gunakan format tanggal tttt-bb-hh",
    invalid: "Gunakan tanggal yang valid"
  },
  file: {
    required: "File diwajibkan",
    empty: "File tidak boleh kosong",
    invalidExtension: "Ekstensi file tidak valid",
    tooLarge: "Ukuran file tidak boleh melebihi {{ maxSize }} MB",
    tooManyEntries: "Jumlah item melebihi {{ maxEntries }}",
    notParsable: "File tidak dapat dibaca",
    fileNotAllowed: "Tipe file ini tidak diizinkan"
  }
};
var errorHandlers$8 = {
  scriptFailedToLoad: "Gagal memuat skrip.",
  refreshThePage: "Silakan segarkan halaman."
};
var assistiveMessage$8 = {
  actionCancelled: "Tindakan dibatalkan, tidak ada yang berubah"
};
var passwordVisibility$8 = {
  showPassword: "Tampilkan kata sandi",
  hidePassword: "Sembunyikan kata sandi"
};
var generalErrors$8 = {
  pageFailure: "Halaman tidak dapat dimuat. Silakan coba lagi nanti."
};
var formLegend$8 = {
  required: "Bidang berikut yang ditandai dengan “*” wajib diisi."
};
var chatMessaging$8 = {
  charactersLeft: "karakter tersisa: {{count}}",
  addNewMessage: "Tambahkan Pesan Baru",
  send: "Kirim",
  uploadFile: "Unggah File",
  informationLabel: "{{author}}. {{text}} pada {{date}}",
  messages: "Pesan",
  addMessagePlaceHolder: "Mulai Mengetik...",
  characterLimitAlert: "Batas karakter tercapai.",
  a11y: {
    itemListBoxLabel: "Pilih item yang akan diterapkan pesan tersebut."
  }
};
var common$h = {
  common: common$g,
  pageMetaResolver: pageMetaResolver$8,
  spinner: spinner$8,
  navigation: navigation$8,
  searchBox: searchBox$8,
  sorting: sorting$8,
  httpHandlers: httpHandlers$8,
  miniCart: miniCart$8,
  skipLink: skipLink$8,
  carousel: carousel$8,
  formErrors: formErrors$8,
  errorHandlers: errorHandlers$8,
  assistiveMessage: assistiveMessage$8,
  passwordVisibility: passwordVisibility$8,
  generalErrors: generalErrors$8,
  formLegend: formLegend$8,
  chatMessaging: chatMessaging$8
};
var setDeliveryMode$8 = {
  unknownError: "Terjadi kesalahan yang tidak diketahui. Silakan hubungi bagian dukungan."
};
var deliveryMode$8 = {
  setDeliveryMode: setDeliveryMode$8
};
var myAccountV2Consent$g = {
  header: "Manajemen Persetujuan",
  dateDescription: "Disetujui pada ",
  clearAll: "Nonaktifkan Semua",
  selectAll: "Aktifkan Semua",
  message: {
    success: {
      given: "Persetujuan berhasil diberikan.",
      withdrawn: "Persetujuan berhasil dibatalkan."
    }
  }
};
var myAccountV2Consent$h = {
  myAccountV2Consent: myAccountV2Consent$g
};
var myAccountV2NotificationPreference$g = {
  header: "Saluran Notifikasi",
  message: "Pilih saluran notifikasi favorit Anda",
  note: "Catatan: ",
  noteMessage: "Jika Anda menonaktifkan semua saluran, notifikasi selanjutnya tidak akan diterima.",
  EMAIL: "Email:"
};
var myAccountV2NotificationPreference$h = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$g
};
var closeAccount$8 = {
  confirmAccountClosure: "Konfirmasi Penutupan Akun",
  confirmAccountClosureMessage: "Apakah Anda yakin untuk menutup akun Anda?",
  closeMyAccount: "TUTUP AKUN SAYA",
  accountClosedSuccessfully: "Akun berhasil ditutup",
  accountClosedFailure: "Akun gagal ditutup"
};
var updatePasswordForm$8 = {
  oldPassword: {
    label: "Kata Sandi Lama",
    placeholder: "Kata Sandi Lama"
  },
  oldPasswordIsRequired: "Kata sandi lama wajib diisi.",
  newPassword: {
    label: "Kata Sandi Baru",
    placeholder: "Kata Sandi Baru"
  },
  passwordMinRequirements: "Berikut panduan singkat untuk membuat kata sandi yang aman: Gunakan setidaknya 8 karakter dengan kombinasi huruf besar dan kecil, angka, serta simbol.",
  passwordStrengthRequirements: "Berikut panduan singkat untuk membuat kata sandi yang aman: Gunakan setidaknya 8 karakter dengan kombinasi huruf besar dan kecil, angka, serta simbol. Pastikan untuk tidak menggunakan karakter yang sama secara berurutan.",
  confirmPassword: {
    label: "Konfirmasi Kata Sandi Baru",
    placeholder: "Konfirmasikan Kata Sandi"
  },
  bothPasswordMustMatch: "Kedua kata sandi harus sama",
  passwordUpdateSuccess: "Kata sandi berhasil diperbarui",
  accessDeniedError: "Akses ditolak"
};
var updateProfileForm$8 = {
  title: "Gelar",
  none: "",
  firstName: {
    label: "Nama depan",
    placeholder: "Nama depan"
  },
  firstNameIsRequired: "Nama depan wajib diisi.",
  lastName: {
    label: "Nama belakang",
    placeholder: "Nama belakang"
  },
  lastNameIsRequired: "Nama belakang wajib diisi.",
  profileUpdateSuccess: "Rincian pribadi berhasil diperbarui",
  customerId: "Pelanggan #"
};
var consentManagementForm$8 = {
  clearAll: "Hapus semua",
  selectAll: "Pilih semua",
  message: {
    success: {
      given: "Persetujuan berhasil diberikan.",
      withdrawn: "Persetujuan berhasil dibatalkan."
    }
  }
};
var myCoupons$8 = {
  noCouponsMessage: "Anda tidak memiliki kupon yang tersedia.",
  effectiveTitle: "Berlaku:",
  Effective: "BERLAKU",
  PreSession: "SEGERA BERLAKU",
  ExpireSoon: "SEGERA BERAKHIR",
  readMore: "Baca selengkapnya",
  notification: "Notifikasi",
  findProducts: "Cari Produk",
  status: "Status:",
  dialogTitle: "Kupon",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Kode Kupon",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Anda telah berhasil mengeklaim kupon ini.",
  myCoupons: "Kupon saya",
  startDateAsc: "Tanggal Mulai (urutkan naik)",
  startDateDesc: "Tanggal Mulai (urutkan turun)",
  endDateAsc: "Tanggal Berakhir (urutkan naik)",
  endDateDesc: "Tanggal Berakhir (urutkan turun)",
  sortBy: "Urutkan berdasarkan",
  sortCoupons: "Urutkan kupon",
  notesPreffix: "Saluran favorit Anda dapat diatur untuk menerima notifikasi kupon di halaman ",
  notesLink: "Saluran Notifikasi",
  notesSuffix: " ."
};
var notificationPreference$8 = {
  message: "Pilih saluran notifikasi favorit Anda",
  note: "Catatan: ",
  noteMessage: "Jika Anda menonaktifkan semua saluran, notifikasi selanjutnya tidak akan diterima.",
  EMAIL: "Email:",
  SMS: "SMS:",
  SITE_MESSAGE: "Pesan Situs"
};
var myInterests$8 = {
  header: "Minat Saya",
  item: "ITEM",
  price: "HARGA",
  notifications: "NOTIFIKASI",
  noInterests: "Anda belum memiliki minat yang terdaftar.",
  inStock: "Stok Tersedia",
  lowStock: "Stok Sedikit",
  outOfStock: "Stok Habis",
  BACK_IN_STOCK: "Stok Tersedia Kembali",
  sortBy: "Urutkan berdasarkan",
  sortInterests: "Urutkan minat",
  expirationDate: " - Hingga {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Hapus",
  sorting: {
    byNameAsc: "Nama (urutkan naik)",
    byNameDesc: "Nama (urutkan turun)"
  },
  actions: "TINDAKAN",
  caption: "Konten Minat Saya.",
  itemRemoved: "Item yang dipilih telah dihapus."
};
var myAccount$8 = {
  closeAccount: closeAccount$8,
  updatePasswordForm: updatePasswordForm$8,
  updateProfileForm: updateProfileForm$8,
  consentManagementForm: consentManagementForm$8,
  myCoupons: myCoupons$8,
  notificationPreference: notificationPreference$8,
  myInterests: myInterests$8
};
var paymentForm$8 = {
  payment: "Pembayaran",
  choosePaymentMethod: "Pilih metode pembayaran",
  paymentType: "Tipe Pembayaran",
  accountHolderName: {
    label: "Nama Pemegang Akun",
    placeholder: "Nama Pemegang Akun"
  },
  cardNumber: "Nomor Kartu",
  expirationDate: "Tanggal Berakhir",
  securityCode: "Kode keamanan (CVV)",
  securityCodeTitle: "Nilai Verifikasi Kartu",
  saveAsDefault: "Simpan sebagai default",
  setAsDefault: "Tetapkan sebagai metode pembayaran default",
  billingAddress: "Alamat penagihan",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Sama seperti alamat pengiriman",
  billingAddressSameAsShipping: "Alamat penagihan sama seperti alamat pengiriman",
  selectOne: "Pilih Satu...",
  monthMask: "BB",
  yearMask: "TTTT",
  expirationYear: "Tahun kedaluwarsa {{ selected }}",
  expirationMonth: "Bulan kedaluwarsa {{ selected }}",
  useThisPayment: "Gunakan pembayaran ini",
  addNewPayment: "Tambahkan Pembayaran Baru",
  paymentAddedSuccessfully: "Pembayaran baru berhasil ditambahkan",
  changePayment: "Ubah Pembayaran"
};
var paymentMethods$8 = {
  paymentMethods: "Metode pembayaran",
  paymentMethodSelected: "Metode pembayaran dipilih",
  newPaymentMethodsAreAddedDuringCheckout: "Metode pembayaran baru ditambahkan saat proses pembayaran.",
  invalidField: "Bidang Tidak Valid: {{ field }}"
};
var paymentCard$8 = {
  deleteConfirmation: "Apakah Anda yakin ingin menghapus metode pembayaran ini?",
  setAsDefault: "Tetapkan sebagai default",
  expires: "Berakhir: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ DEFAULT",
  defaultPaymentLabel: "Metode pembayaran default",
  additionalPaymentLabel: "Metode pembayaran tambahan {{ number }}",
  selected: "Dipilih",
  selectedPayment: "Pembayaran yang Dipilih",
  deletePaymentSuccess: "Metode pembayaran berhasil dihapus",
  visa: "Kartu Visa",
  master: "Kartu Mastercard",
  masterEuro: "Kartu Mastercard Eurocard",
  dinersClub: "Kartu Diners Club",
  "switch": "Kartu Switch",
  maestro: "Kartu Maestro",
  amex: "Kartu American Express",
  credit: "Kartu Kredit"
};
var paymentTypes$8 = {
  title: "Metode pembayaran",
  paymentType_CARD: "Kartu Kredit",
  paymentType_ACCOUNT: "Akun"
};
var paymentMessages$8 = {
  setAsDefaultSuccessfully: "Pembayaran baru berhasil ditetapkan sebagai pilihan utama"
};
var payment$8 = {
  paymentForm: paymentForm$8,
  paymentMethods: paymentMethods$8,
  paymentCard: paymentCard$8,
  paymentTypes: paymentTypes$8,
  paymentMessages: paymentMessages$8
};
var pdf$g = {
  defaultTitle: "Dokumen"
};
var pdf$h = {
  pdf: pdf$g
};
var productDetails$8 = {
  id: "ID",
  quantity: "Jml",
  quantityFull: "Jumlah",
  productDetails: "Rincian Produk",
  specification: "Spesifikasi",
  reviews: "Ulasan",
  shipping: "Pengiriman",
  share: "Bagikan",
  showReviews: "Tampilkan ulasan",
  noReviews: "Belum ada ulasan",
  productPrice: "Harga produk",
  noProductImage: "Gambar tidak tersedia, {{ product }}"
};
var productList$8 = {
  filterBy: {
    label: "Filter berdasarkan",
    action: "Filter berdasarkan"
  },
  activeFilter: "{{filter}} filter, mengeklik tombol ini akan menghapus filter tersebut",
  appliedFilter: "Filter yang diterapkan:",
  showLess: "Tampilkan lebih sedikit...",
  showMore: "Tampikan lebih banyak...",
  sortBy: "Urutkan berdasarkan",
  sortResults: "Urutkan hasil",
  backToTopBtn: "KEMBALI KE ATAS",
  showMoreBtn: "TAMPILKAN LEBIH BANYAK",
  productSearchPagination: "Penomoran halaman pada pencarian produk",
  productListResults: "Daftar Hasil Produk"
};
var productFacetNavigation$8 = {
  filterBy: {
    label: "Filter berdasarkan",
    action: "Filter berdasarkan",
    facet: "Filter hasil berdasarkan Fitur",
    name: "Filter berdasarkan {{name}}"
  },
  appliedFilter: "Filter yang diterapkan:",
  showLess: "Tampilkan lebih sedikit...",
  showMore: "Tampikan lebih banyak...",
  sortBy: "Urutkan berdasarkan",
  ariaLabelShowLess: "Mengeklik tombol Tampilkan Lebih Sedikit akan mengurangi opsi untuk grup yang aktif",
  ariaLabelShowMore: "Mengeklik tombol Tampilkan Lebih Banyak akan menampilkan semua opsi untuk grup yang aktif",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} item tersedia",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} item tersedia",
  decreaseOptionsVisibility: "Opsi disembunyikan dari grup yang aktif, tekan tab kembali untuk melihat ke grup sebelumnya atau tekan tab lanjut ke grup berikutnya",
  increaseOptionsVisibility: "Beberapa opsi ditambahkan ke grup yang aktif, tekan tab kembali untuk melihat ke grup sebelumnya atau tekan tab lanjut ke grup berikutnya",
  backToResults: "Kembali ke Hasil",
  productFacets: "Fitur Produk"
};
var productSummary$8 = {
  id: "ID",
  showReviews: "Tampilkan ulasan",
  showReviewsDetailed: "Tampilkan {{count}} ulasan, Diberi nilai {{rating}} dari 5 bintang",
  share: "Bagikan",
  newItemPrice: "Harga item baru"
};
var productReview$8 = {
  overallRating: "Penilaian Keseluruhan",
  reviewTitle: "Judul Ulasan",
  writeYourComments: "Tulis komentar Anda",
  rating: "Penilaian",
  ratingRequired: "Penilaian produk, diwajibkan",
  addRate: "Tambah nilai: {{count}} bintang",
  addRate_other: "Tambah nilai: {{count}} bintang",
  reviewerName: "Nama pemberi ulasan (opsional)",
  writeReview: "Tulis Ulasan",
  more: "Tampilkan Lebih Banyak Ulasan",
  less: "Tampilkan Lebih Sedikit Ulasan",
  thankYouForReview: "Terima kasih atas ulasannya! Perlu diingat bahwa ulasan mungkin memerlukan peninjauan sebelum ditampilkan di sini.",
  postReviewFail: "Ada masalah saat mengirim ulasan Anda. Silakan coba lagi nanti.",
  ratedOutOf: "Diberi nilai: {{rating}} dari 5"
};
var productCarousel$8 = {
  carouselLabel: "Korsel, {{title}}"
};
var addToCart$8 = {
  itemAddedToYourCart: "Item Telah Ditambahkan ke Keranjang Anda",
  itemsAddedToYourCart: "Item telah ditambahkan ke keranjang Anda",
  itemsIncrementedInYourCart: "Item ini sudah ada di keranjang Anda. Jumlahnya telah diperbarui.",
  items: "item",
  updatingCart: "Memperbarui keranjang...",
  addToCart: "Tambahkan ke keranjang",
  viewCart: "lihat keranjang",
  proceedToCheckout: "lanjutkan ke pembayaran",
  quantity: "Jml",
  quantityFull: "Jumlah",
  outOfStock: "Stok habis",
  inStock: "Stok tersedia",
  selectStyleAndSize: "Pilih model dan ukuran untuk memeriksa stok",
  removeFromCart: "Hapus Produk dari Keranjang",
  closeModal: "Tutup Modal",
  buyItAgain: "Beli Lagi",
  addToActiveCart: "Tambahkan ke Keranjang Aktif"
};
var TabPanelContainer$8 = {
  tabs: {
    ProductDetailsTabComponent: "Rincian Produk",
    ProductSpecsTabComponent: "Spesifikasi",
    ProductReviewsTabComponent: "Ulasan",
    deliveryTab: "Pengiriman",
    SparePartsTabComponent: " Suku Cadang"
  },
  tabPanelContainerRegion: "Grup tab dengan lebih banyak rincian produk",
  tabPanelContainerRegionGroup: "Grup dengan lebih banyak rincian produk"
};
var addToWishList$8 = {
  add: "Tambahkan ke Daftar Keinginan",
  remove: "Hapus dari Daftar Keinginan",
  anonymous: "Masuk untuk menambahkan ke daftar keinginan",
  addedToWishList: "Produk ditambahkan ke daftar keinginan",
  removedFromWishList: "Produk dihapus dari daftar keinginan"
};
var stockNotification$8 = {
  notifyMe: "BERI SAYA NOTIFIKASI",
  stopNotify: "HENTIKAN NOTIFIKASI",
  getNotify: "Dapatkan notifikasi saat produk ini tersedia.",
  getNotifySuffix: "untuk mendapatkan notifikasi saat produk ini tersedia.",
  activateChannelsPrefix: "Untuk mendapatkan notifikasi, Anda perlu mengaktifkan ",
  channelsLink: "Saluran Notifikasi",
  activateChannelsSuffix: ".",
  notified: "Anda akan diberi notifikasi saat produk ini tersedia kembali.",
  getNotified: "Dapatkan notifikasi saat produk ini tersedia kembali.",
  unsubscribeSuccess: "Anda tidak akan menerima notifikasi ketersediaan ulang untuk produk ini.",
  subscriptionDialog: {
    header: "Berlangganan pemberitahuan stok",
    notifiedPrefix: "Anda akan diberi notifikasi pada:",
    notifiedSuffix: "segera setelah produk ini tersedia kembali.",
    manageChannelsPrefix: "Kelola saluran notifikasi favorit Anda di halaman ",
    manageChannelsLink: "Preferensi Notifikasi",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "Anda dapat mengelola berlangganan Anda di halaman ",
    manageSubscriptionsLink: "Minat Saya",
    manageSubscriptionsSuffix: " .",
    okBtn: "OKE",
    subscribing: "Mendaftarkan Anda untuk berlangganan notifikasi stok untuk produk ini"
  }
};
var itemCounter$8 = {
  removeOne: "Hapus satu item",
  addOneMore: "Hapus satu item lagi",
  quantity: "Jumlah"
};
var productView$8 = {
  gridView: "Pilih untuk mengubah ke Tampilan Kisi",
  listView: "Pilih untuk mengubah ke Tampilan Daftar"
};
var product$8 = {
  productDetails: productDetails$8,
  productList: productList$8,
  productFacetNavigation: productFacetNavigation$8,
  productSummary: productSummary$8,
  productReview: productReview$8,
  productCarousel: productCarousel$8,
  addToCart: addToCart$8,
  TabPanelContainer: TabPanelContainer$8,
  addToWishList: addToWishList$8,
  stockNotification: stockNotification$8,
  itemCounter: itemCounter$8,
  productView: productView$8
};
var pwa$g = {
  addToHomeScreenDescription: "Tambahkan etalase SAP ke halaman utama perangkat untuk kemudahan pengaksesan kunjungan Anda",
  noInstallationNeeded: "Tidak diperlukan instalasi",
  fastAccessToApplication: "Akses cepat ke aplikasi",
  addToHomeScreen: "Tambahkan ke halaman utama",
  addedToHomeScreen: "Etalase SAP ditambahkan ke halaman utama Anda"
};
var pwa$h = {
  pwa: pwa$g
};
var siteThemeSwitcher$g = {
  theme: "Tema",
  themes: {
    "default": "Default",
    highContrastDark: "Kontras Tinggi - Gelap",
    highContrastLight: "Kontras Tinggi - Terang"
  }
};
var siteThemeSwitcher$h = {
  siteThemeSwitcher: siteThemeSwitcher$g
};
var anonymousConsents$8 = {
  dialog: {
    title: "Pengelolaan Persetujuan",
    legalDescription: "Kami menggunakan cookie/penyimpanan browser untuk mempersonalisasi konten dan meningkatkan pengalaman pengguna. Kami juga mungkin membagikan data tentang penggunaan situs Anda dengan media sosial kami. Untuk mengetahui selengkapnya, silakan tinjau kebijakan privasi kami.",
    selectAll: "Pilih semua",
    clearAll: "Hapus semua"
  },
  banner: {
    title: "Situs web ini menggunakan cookie",
    description: "Kami menggunakan cookie/penyimpanan browser untuk mempersonalisasi konten dan meningkatkan pengalaman pengguna.",
    allowAll: "Izinkan Semua",
    viewDetails: "Lihat Rincian",
    consentManagement: "Pengelolaan Persetujuan"
  }
};
var checkoutLogin$8 = {
  emailAddress: {
    label: "Alamat email",
    placeholder: "Masukkan email"
  },
  confirmEmail: {
    label: "Konfirmasi email",
    placeholder: "Konfirmasi email"
  },
  "continue": "Lanjutkan",
  emailIsRequired: "Format email tidak valid",
  emailsMustMatch: "Email tidak cocok"
};
var authMessages$8 = {
  signedOutSuccessfully: "Anda berhasil keluar."
};
var user$8 = {
  anonymousConsents: anonymousConsents$8,
  checkoutLogin: checkoutLogin$8,
  authMessages: authMessages$8
};
var player$8 = {
  label: "Pemutar Video"
};
var video$8 = {
  player: player$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  common: common$h,
  deliveryMode: deliveryMode$8,
  myAccountV2Consent: myAccountV2Consent$h,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$h,
  myAccount: myAccount$8,
  payment: payment$8,
  pdf: pdf$h,
  product: product$8,
  pwa: pwa$h,
  siteThemeSwitcher: siteThemeSwitcher$h,
  user: user$8,
  video: video$8
};
var common$e = {
  cancel: "Annulla",
  "delete": "Elimina",
  remove: "Rimuovi",
  edit: "Modifica",
  restore: "Ripristina",
  back: "Indietro",
  submit: "Invia",
  "continue": "Continua",
  save: "Salva",
  done: "Fine",
  home: "Pagina iniziale",
  noData: "Nessun dato.",
  confirm: "Conferma",
  more: "altro",
  close: "Chiudi",
  loading: "Caricamento in corso...",
  menu: "Menu",
  reset: "Reimposta",
  search: "Cerca",
  add: "Aggiungi",
  breadcrumbs: "percorsi di navigazione",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Seleziona file",
  clear: "Cancella",
  loaded: "Caricato",
  results: "Risultati",
  of: "di",
  ngSelectDropdownOptionsList: "Opzioni",
  required: "obbligatorio",
  zoomIn: "Ingrandisci",
  zoomOut: "Riduci",
  selected: "Selezionato",
  expand: "Espandi",
  collapse: "Comprimi"
};
var pageMetaResolver$7 = {
  category: {
    title: "{{count}} risultato per {{query}}",
    title_other: "{{count}} risultati per {{query}}"
  },
  checkout: {
    title: "Check-out"
  },
  search: {
    title: "{{count}} risultato per \"{{query}}\"",
    title_other: "{{count}} risultati per \"{{query}}\"",
    findProductTitle: "{{count}} risultato per il coupon \"{{coupon}}\"",
    findProductTitle_other: "{{count}} risultati per il coupon \"{{coupon}}\"",
    default_title: "Tutti i prodotti"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$7 = {
  loading: "Caricamento in corso..."
};
var navigation$7 = {
  categoryNavLabel: "Menu categorie",
  footerNavLabel: "Collegamenti a piè di pagina",
  goTo: "Passa a {{location}}",
  navigateTo: "Naviga a {{nav}}",
  scrollToTop: "Scorri nuovamente verso la parte superiore della pagina",
  linkItemInList: "{{title}}. {{position}} di {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$7 = {
  placeholder: "Inserisci nome prodotto o SKU",
  productSearch: "Trova un prodotto",
  ariaLabelInput: "Cerca qui...",
  ariaLabelSuggestions: "inserimento di consigli in corso",
  ariaLabelProducts: "risultati prodotti",
  initialDescription: "Quando sono disponibili i risultati del completamento automatico, utilizzare le frecce Su e Giù per la revisione e Invio per la selezione. Gli utenti dei dispositivi touch posso esplorare tramite tocco o gesti di scorrimento.",
  suggestions: "Parole chiave consigliate",
  recentSearches: "Ricerche recenti",
  trendingSearches: "Ricerche di tendenza",
  suggestionsResult: "{{ count }} consiglio e ",
  suggestionsResult_other: "{{ count }} consigli e ",
  products: "Prodotti consigliati",
  productsResult: "{{ count }} prodotto disponibile.",
  productsResult_other: "{{ count }} prodotti disponibili.",
  resetLabel: "Reimposta ricerca",
  help: {
    insufficientChars: "Digita altri caratteri",
    noMatch: "Non è stato trovato alcun risultato",
    exactMatch: "{{ term }}",
    empty: "Chiedi a noi"
  },
  closeSearchPanel: "Chiudi"
};
var sorting$7 = {
  date: "Data",
  orderNumber: "Numero dell'ordine",
  rma: "Numero del reso",
  replenishmentNumber: "Numero riapprovvigionamento",
  nextOrderDate: "Data ordine successivo",
  pageViewUpdated: "Vista della pagina aggiornata con le opzioni selezionate."
};
var httpHandlers$7 = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Accedere nuovamente.",
    user_is_disabled: "{{ errorMessage }}. Contattare l'amministratore."
  },
  badGateway: "Si è verificato un errore del server. Riprovare più tardi.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Accedere di nuovo.",
  badRequestOldPasswordIncorrect: "La password precedente non è corretta.",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} E-mail già esistente. Effettuare il check-out con un'e-mail diversa per registrarsi con un account ospite.",
  conflict: "Esiste già.",
  forbidden: "Nessuna autorizzazione a eseguire questa azione. Se si ritiene un errore, contattare l'amministratore.",
  gatewayTimeout: "Il server non ha risposto, riprovare più tardi.",
  internalServerError: "Si è verificato un errore interno del server. Riprovare più tardi.",
  sessionExpired: "La sessione è scaduta. Accedere nuovamente.",
  unknownError: "Si è verificato un errore sconosciuto",
  unauthorized: {
    common: "Si è verificato un errore di autorizzazione sconosciuto",
    invalid_client: "Credenziali client errate"
  },
  validationErrors: {
    missing: {
      card_cardType: "La carta di credito selezionata non è supportata. Selezionarne un'altra.",
      card_accountNumber: "Il numero della carta di credito inserito non è valido.",
      card_cvNumber: "Il codice di sicurezza inserito non è valido.",
      card_expirationMonth: "La data di scadenza della carta di credito inserita non è valida.",
      card_expirationYear: "La data di scadenza della carta di credito inserita non è valida.",
      billTo_firstName: "Il nome inserito non è valido.",
      billTo_lastName: "Il cognome inserito non è valido.",
      billTo_street1: "L'indirizzo inserito non è valido.",
      billTo_street2: "L'indirizzo inserito non è valido.",
      billTo_city: "La città inserita non è valida per questa carta di credito.",
      billTo_state: "Lo Stato/Provincia inserito non è valido per questa carta di credito.",
      billTo_country: "Il Paese/regione inserito non è valido per questa carta di credito.",
      billTo_postalCode: "Il CAP/codice postale inserito non è valido per questa carta di credito.",
      country: {
        isocode: "Paese/regione mancante"
      }
    },
    invalid: {
      card_expirationMonth: "La data di scadenza della carta di credito inserita non è valida.",
      firstName: "Il nome inserito non è valido.",
      lastName: "Il cognome inserito non è valido.",
      password: "La password inserita non è valida.",
      uid: "L'UID non è valido.",
      code: "Il codice non è valido.",
      email: "L'e-mail non è valida.",
      loginId: "L'e-mail non è valida."
    }
  },
  cartNotFound: "Carta non trovata.",
  invalidCodeProvided: "Codice fornito non valido.",
  voucherExceeded: "È stato superato il numero di utilizzi di questo coupon",
  unknownIdentifier: "Articolo non trovato.",
  otherCartErrors: "Si sono verificati errori del carrello acquisti."
};
var miniCart$7 = {
  item: "{{count}} articolo attualmente nel carrello acquisti",
  item_other: "{{count}} articoli attualmente nel carrello acquisti",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$7 = {
  skipTo: "Passa a",
  labels: {
    header: "Intestazione",
    main: "Contenuto principale",
    footer: "Piè di pagina",
    productFacets: "Facet prodotti",
    productList: "Lista prodotti"
  }
};
var carousel$7 = {
  previousSlide: "Diapositiva precedente",
  nextSlide: "Diapositiva successiva",
  slideNumber: "La diapositiva {{currentSlideNumber}} contiene {{numberOfSlides}} prodotti",
  carouselForProduct: "Carosello, immagini per {{product}}"
};
var formErrors$7 = {
  labeled: {
    required: "Il campo {{label}} è obbligatorio",
    cxInvalidEmail: "Il campo {{label}} non ha un formato e-mail valido. Deve corrispondere al modello: esempio@iltuodominio.com",
    cxInvalidPassword: "Il campo {{label}} non ha un formato password valido",
    cxMinOneUpperCaseCharacter: "La password deve contenere almeno un carattere maiuscolo",
    cxMinOneDigit: "La password deve contenere almeno un numero",
    cxMinOneSpecialCharacter: "La password deve contenere almeno un carattere speciale",
    cxMinSixCharactersLength: "La password deve contenere almeno 6 caratteri",
    cxMinEightCharactersLength: "La password deve contenere almeno 8 caratteri",
    cxMaxCharactersLength: "La password non può contenere più di 128 caratteri",
    cxContainsSpecialCharacters: "La password non può contenere caratteri speciali",
    cxNoConsecutiveCharacters: "La password non può contenere caratteri identici consecutivi",
    date: {
      required: "Campo {{label}} obbligatorio",
      min: "Il campo {{label}} non può precedere {{min}}",
      max: "Il campo {{label}} non può essere successivo a {{max}}",
      pattern: "Utilizza il formato data aaaa-mm-gg nel campo {{label}}",
      invalid: "Utilizza una data valida nel campo {{label}}"
    }
  },
  globalMessage: "Il modulo che si sta tentando di inviare contiene errori.",
  required: "Il campo è obbligatorio",
  cxInvalidEmail: "Formato e-mail non valido",
  cxInvalidPassword: "Formato password non valido",
  cxPasswordsMustMatch: "I campi della password devono corrispondere",
  cxPasswordsCannotMatch: "I campi della password non possono corrispondere",
  cxEmailsMustMatch: "I campi dell'e-mail devono corrispondere",
  cxStarRatingEmpty: "Il campo di valutazione è obbligatorio",
  cxNoSelectedItemToCancel: "Seleziona almeno un articolo",
  cxNegativeAmount: "L'importo deve essere pari o maggiore di zero",
  cxContainsSpecialCharacters: "Il campo non può contenere caratteri speciali",
  date: {
    required: "Questo campo è obbligatorio",
    min: "La data non può essere precedente a {{min}}",
    max: "La data non può essere successiva a {{max}}",
    pattern: "Utilizza il formato data aaaa-mm-gg",
    invalid: "Utilizza una data valida"
  },
  file: {
    required: "Il file è obbligatorio",
    empty: "Il file non può essere vuoto",
    invalidExtension: "Estensione file non valida",
    tooLarge: "La dimensione del file non può superare {{ maxSize }} MB",
    tooManyEntries: "Il numero di articoli è maggiore di {{ maxEntries }}",
    notParsable: "File non analizzabile sintatticamente",
    fileNotAllowed: "Questo tipo di file non è consentito."
  }
};
var errorHandlers$7 = {
  scriptFailedToLoad: "Impossibile caricare lo script.",
  refreshThePage: "Aggiornare la pagina."
};
var assistiveMessage$7 = {
  actionCancelled: "Azione annullata, nessuna modifica"
};
var passwordVisibility$7 = {
  showPassword: "Mostra password",
  hidePassword: "Nascondi password"
};
var generalErrors$7 = {
  pageFailure: "Impossibile caricare la pagina. Riprovare più tardi."
};
var formLegend$7 = {
  required: "I seguenti campi contrassegnati da * sono obbligatori."
};
var chatMessaging$7 = {
  charactersLeft: "caratteri rimanenti: {{count}}",
  addNewMessage: "Aggiungi nuovo messaggio",
  send: "Invia",
  uploadFile: "Carica file",
  informationLabel: "{{author}}. {{text}} alle {{date}}",
  messages: "Messaggi",
  addMessagePlaceHolder: "Inizia a digitare...",
  characterLimitAlert: "Limite di caratteri raggiunto.",
  a11y: {
    itemListBoxLabel: "Selezionare l'articolo a cui deve applicarsi il messaggio."
  }
};
var common$f = {
  common: common$e,
  pageMetaResolver: pageMetaResolver$7,
  spinner: spinner$7,
  navigation: navigation$7,
  searchBox: searchBox$7,
  sorting: sorting$7,
  httpHandlers: httpHandlers$7,
  miniCart: miniCart$7,
  skipLink: skipLink$7,
  carousel: carousel$7,
  formErrors: formErrors$7,
  errorHandlers: errorHandlers$7,
  assistiveMessage: assistiveMessage$7,
  passwordVisibility: passwordVisibility$7,
  generalErrors: generalErrors$7,
  formLegend: formLegend$7,
  chatMessaging: chatMessaging$7
};
var setDeliveryMode$7 = {
  unknownError: "Si è verificato un errore sconosciuto. Contattare il supporto."
};
var deliveryMode$7 = {
  setDeliveryMode: setDeliveryMode$7
};
var myAccountV2Consent$e = {
  header: "Gestione dei consensi",
  dateDescription: "Data di approvazione ",
  clearAll: "Disattiva tutto",
  selectAll: "Attiva tutto",
  message: {
    success: {
      given: "Consenso accordato correttamente.",
      withdrawn: "Consenso ritirato correttamente."
    }
  }
};
var myAccountV2Consent$f = {
  myAccountV2Consent: myAccountV2Consent$e
};
var myAccountV2NotificationPreference$e = {
  header: "Canali di notifica",
  message: "Seleziona i canali di notifica preferiti",
  note: "Nota: ",
  noteMessage: "Se si disattivano tutti i canali non sarà possibile ricevere ulteriori notifiche.",
  EMAIL: "E-mail:"
};
var myAccountV2NotificationPreference$f = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$e
};
var closeAccount$7 = {
  confirmAccountClosure: "Conferma chiusura account",
  confirmAccountClosureMessage: "Confermare di voler chiudere l'account?",
  closeMyAccount: "CHIUDI L'ACCOUNT",
  accountClosedSuccessfully: "Account chiuso correttamente",
  accountClosedFailure: "Impossibile chiudere l'account"
};
var updatePasswordForm$7 = {
  oldPassword: {
    label: "Password precedente",
    placeholder: "Password precedente"
  },
  oldPasswordIsRequired: "La password precedente è obbligatoria.",
  newPassword: {
    label: "Nuova password",
    placeholder: "Nuova password"
  },
  passwordMinRequirements: "Questa è una guida rapida per una password sicura: utilizzare almeno otto caratteri con una combinazione di lettere maiuscole e minuscole, numeri e simboli.",
  passwordStrengthRequirements: "Questa è una guida rapida per una password sicura: utilizzare almeno otto caratteri con una combinazione di lettere maiuscole e minuscole, numeri e simboli. Assicurarsi di non utilizzare lo stesso carattere in maniera consecutiva. ",
  confirmPassword: {
    label: "Conferma nuova password",
    placeholder: "Conferma password"
  },
  bothPasswordMustMatch: "Le password devono coincidere",
  passwordUpdateSuccess: "Password aggiornata correttamente",
  accessDeniedError: "Accesso negato"
};
var updateProfileForm$7 = {
  title: "Titolo",
  none: "",
  firstName: {
    label: "Nome",
    placeholder: "Nome"
  },
  firstNameIsRequired: "Il nome è obbligatorio.",
  lastName: {
    label: "Cognome",
    placeholder: "Cognome"
  },
  lastNameIsRequired: "Il cognome è obbligatorio.",
  profileUpdateSuccess: "Dettagli personali aggiornati correttamente",
  customerId: "N. cliente"
};
var consentManagementForm$7 = {
  clearAll: "Cancella tutto",
  selectAll: "Seleziona tutto",
  message: {
    success: {
      given: "Consenso accordato correttamente.",
      withdrawn: "Consenso ritirato correttamente."
    }
  }
};
var myCoupons$7 = {
  noCouponsMessage: "Nessun coupon disponibile.",
  effectiveTitle: "Valido a partire da:",
  Effective: "VALIDO A PARTIRE DA",
  PreSession: "VALIDO PROSSIMAMENTE",
  ExpireSoon: "SCADENZA PROSSIMA",
  readMore: "Maggiori dettagli",
  notification: "Notifica",
  findProducts: "Cerca prodotti",
  status: "Stato:",
  dialogTitle: "Coupon",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Codice coupon",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Coupon riscattato correttamente.",
  myCoupons: "Coupon personali",
  startDateAsc: "Data di inizio (in ordine crescente)",
  startDateDesc: "Data di inizio (in ordine decrescente)",
  endDateAsc: "Data di fine (in ordine crescente)",
  endDateDesc: "Data di fine (in ordine decrescente)",
  sortBy: "Ordina per",
  sortCoupons: "Ordina coupon",
  notesPreffix: "È possibile impostare i propri canali preferiti per ricevere notifiche sui coupon nella pagina ",
  notesLink: "Canali di notifica",
  notesSuffix: " ."
};
var notificationPreference$7 = {
  message: "Seleziona i canali di notifica preferiti",
  note: "Nota: ",
  noteMessage: "se si disattivano tutti i canali non sarà possibile ricevere ulteriori notifiche.",
  EMAIL: "E-mail:",
  SMS: "SMS:",
  SITE_MESSAGE: "Messaggio sito"
};
var myInterests$7 = {
  header: "Interessi personali",
  item: "ARTICOLO",
  price: "PREZZO",
  notifications: "NOTIFICHE",
  noInterests: "Ancora nessun interesse registrato.",
  inStock: "In stock",
  lowStock: "Basso livello di scorte",
  outOfStock: "Non disponibile",
  BACK_IN_STOCK: "Nuovamente in stock",
  sortBy: "Ordina per",
  sortInterests: "Ordina interessi",
  expirationDate: " - Cassa {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Rimuovi",
  sorting: {
    byNameAsc: "Nome (in ordine crescente)",
    byNameDesc: "Nome (in ordine decrescente)"
  },
  actions: "AZIONI",
  caption: "Contenuti degli interessi personali.",
  itemRemoved: "L'articolo selezionato è stato rimosso."
};
var myAccount$7 = {
  closeAccount: closeAccount$7,
  updatePasswordForm: updatePasswordForm$7,
  updateProfileForm: updateProfileForm$7,
  consentManagementForm: consentManagementForm$7,
  myCoupons: myCoupons$7,
  notificationPreference: notificationPreference$7,
  myInterests: myInterests$7
};
var paymentForm$7 = {
  payment: "Pagamento",
  choosePaymentMethod: "Scegli un metodo di pagamento",
  paymentType: "Tipo di pagamento",
  accountHolderName: {
    label: "Nome intestatario conto",
    placeholder: "Nome intestatario conto"
  },
  cardNumber: "Numero della carta",
  expirationDate: "Data di scadenza",
  securityCode: "Codice di sicurezza (CVV)",
  securityCodeTitle: "Valore di verifica della carta",
  saveAsDefault: "Salva come predefinito",
  setAsDefault: "Imposta come metodo di pagamento predefinito",
  billingAddress: "Indirizzo di fatturazione",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Uguale all'indirizzo di consegna",
  billingAddressSameAsShipping: "L'indirizzo di fatturazione è uguale all'indirizzo di consegna",
  selectOne: "Seleziona uno...",
  monthMask: "MM",
  yearMask: "AAAA",
  expirationYear: "Anno di scadenza {{ selected }}",
  expirationMonth: "Mese di scadenza {{ selected }}",
  useThisPayment: "Utilizza questo pagamento",
  addNewPayment: "Aggiungi nuovo pagamento",
  paymentAddedSuccessfully: "Nuovo pagamento aggiunto correttamente",
  changePayment: "Modifica pagamento"
};
var paymentMethods$7 = {
  paymentMethods: "Metodi di pagamento",
  paymentMethodSelected: "Metodo di pagamento selezionato",
  newPaymentMethodsAreAddedDuringCheckout: "Durante il check-out sono stati aggiunti nuovi metodi di pagamento.",
  invalidField: "Campo non valido: {{ field }}"
};
var paymentCard$7 = {
  deleteConfirmation: "Confermare di voler eliminare questo metodo di pagamento?",
  setAsDefault: "Imposta come predefinito",
  expires: "Scadenza: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ PREDEFINITO",
  defaultPaymentLabel: "Metodo di pagamento predefinito",
  additionalPaymentLabel: "Metodo di pagamento aggiuntivo {{ number }}",
  selected: "Selezionato",
  selectedPayment: "Pagamento selezionato",
  deletePaymentSuccess: "Metodo di pagamento eliminato correttamente",
  visa: "Carta Visa",
  master: "Carta MasterCard",
  masterEuro: "Carta MasterCard Eurocard",
  dinersClub: "Carta Diners Club",
  "switch": "Cambia carta",
  maestro: "Carta Maestro",
  amex: "Carta American Express",
  credit: "Carta di credito"
};
var paymentTypes$7 = {
  title: "Metodo di pagamento",
  paymentType_CARD: "Carta di credito",
  paymentType_ACCOUNT: "Account"
};
var paymentMessages$7 = {
  setAsDefaultSuccessfully: "Nuovo pagamento impostato correttamente come predefinito"
};
var payment$7 = {
  paymentForm: paymentForm$7,
  paymentMethods: paymentMethods$7,
  paymentCard: paymentCard$7,
  paymentTypes: paymentTypes$7,
  paymentMessages: paymentMessages$7
};
var pdf$e = {
  defaultTitle: "Documento"
};
var pdf$f = {
  pdf: pdf$e
};
var productDetails$7 = {
  id: "ID",
  quantity: "Qtà",
  quantityFull: "Quantità",
  productDetails: "Dettagli prodotto",
  specification: "Specifiche",
  reviews: "Recensioni",
  shipping: "Spedizione",
  share: "Condividi",
  showReviews: "Mostra recensioni",
  noReviews: "Ancora nessuna recensione",
  productPrice: "Prezzo del prodotto",
  noProductImage: "Nessuna immagine disponibile, {{ product }}"
};
var productList$7 = {
  filterBy: {
    label: "Filtra per",
    action: "Filtra per"
  },
  activeFilter: "Filtro {{filter}}, la selezione di questo pulsante rimuoverà il filtro",
  appliedFilter: "Filtro applicato:",
  showLess: "Mostra di meno...",
  showMore: "Mostra di più...",
  sortBy: "Ordina per",
  sortResults: "Ordina risultati",
  backToTopBtn: "TORNA A INIZIO PAGINA",
  showMoreBtn: "MOSTRA DI PIÙ",
  productSearchPagination: "Impaginazione della ricerca di prodotti",
  productListResults: "Lista dei risultati dei prodotti"
};
var productFacetNavigation$7 = {
  filterBy: {
    label: "Filtra per",
    action: "Filtra per",
    facet: "Filtra i risultati per facet",
    name: "Filtra per {{name}}"
  },
  appliedFilter: "Filtro applicato:",
  showLess: "Mostra di meno...",
  showMore: "Mostra di più...",
  sortBy: "Ordina per",
  ariaLabelShowLess: "Mostra di meno, pulsante; la selezione di questo pulsante ridurrà le opzioni per il gruppo attivo",
  ariaLabelShowMore: "Mostra di più, pulsante; la selezione di questo pulsante mostrerà tutte le opzioni per il gruppo attivo",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} articolo disponibile",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} articoli disponibili",
  decreaseOptionsVisibility: "Per il gruppo attivo le opzioni sono state nascoste, andare indietro per leggerle o avanti per il gruppo successivo",
  increaseOptionsVisibility: "Per il gruppo attivo sono state aggiunte più opzioni, andare indietro per leggerle o avanti per il gruppo successivo",
  backToResults: "Torna ai risultati",
  productFacets: "Facet prodotti"
};
var productSummary$7 = {
  id: "ID",
  showReviews: "Mostra recensioni",
  showReviewsDetailed: "Mostra {{count}} recensioni, valutazione {{rating}} su 5 stelle",
  share: "Condividi",
  newItemPrice: "Nuovo prezzo articolo"
};
var productReview$7 = {
  overallRating: "Valutazione complessiva",
  reviewTitle: "Titolo recensione",
  writeYourComments: "Scrivi i tuoi commenti",
  rating: "Valutazione",
  ratingRequired: "Valutazione del prodotto, obbligatoria",
  addRate: "Aggiungi valutazione: {{count}} stella",
  addRate_other: "Aggiungi valutazione: {{count}} stelle",
  reviewerName: "Nome dell'autore della recensione (facoltativo)",
  writeReview: "Scrivi una recensione",
  more: "Mostra altre recensioni",
  less: "Mostra meno recensioni",
  thankYouForReview: "Grazie della recensione. Le recensioni potrebbero richiedere una revisione prima della pubblicazione qui.",
  postReviewFail: "Si è verificato un errore durante la pubblicazione della recensione. Riprovare più tardi",
  ratedOutOf: "Valutazione: {{rating}} su 5"
};
var productCarousel$7 = {
  carouselLabel: "Carosello, {{title}}"
};
var addToCart$7 = {
  itemAddedToYourCart: "Articolo aggiunto al carrello",
  itemsAddedToYourCart: "Articoli aggiunti al carrello",
  itemsIncrementedInYourCart: "Questo articolo era già presente nel carrello acquisti. La quantità è stata aggiornata.",
  items: "articoli",
  updatingCart: "Aggiornamento del carrello acquisti in corso...",
  addToCart: "Aggiungi al carrello acquisti",
  viewCart: "visualizza carrello acquisti",
  proceedToCheckout: "passa al check-out",
  quantity: "Qtà",
  quantityFull: "Quantità",
  outOfStock: "Non disponibile",
  inStock: "In stock",
  selectStyleAndSize: "Seleziona stile e taglia per controllare lo stock",
  removeFromCart: "Rimuovi il prodotto dal carrello acquisti",
  closeModal: "Chiudi finestra modale",
  buyItAgain: "Acquista nuovamente",
  addToActiveCart: "Aggiungi al carrello acquisti attivo"
};
var TabPanelContainer$7 = {
  tabs: {
    ProductDetailsTabComponent: "Dettagli prodotto",
    ProductSpecsTabComponent: "Specifiche",
    ProductReviewsTabComponent: "Recensioni",
    deliveryTab: "Spedizione",
    SparePartsTabComponent: " Parti di ricambio"
  },
  tabPanelContainerRegion: "Gruppo di schede con ulteriori dettagli dei prodotti",
  tabPanelContainerRegionGroup: "Gruppo con ulteriori dettagli dei prodotti"
};
var addToWishList$7 = {
  add: "Aggiungi al promemoria",
  remove: "Rimuovi dal promemoria",
  anonymous: "Registrati per aggiungere al promemoria",
  addedToWishList: "Prodotto aggiunto al promemoria",
  removedFromWishList: "Prodotto rimosso dal promemoria"
};
var stockNotification$7 = {
  notifyMe: "INVIA UNA NOTIFICA",
  stopNotify: "INTERROMPI NOTIFICA",
  getNotify: "Ricevi una notifica quando questo prodotto torna disponibile.",
  getNotifySuffix: "per ricevere una notifica quando questo prodotto torna disponibile.",
  activateChannelsPrefix: "Per ricevere notifiche è necessario attivare i ",
  channelsLink: "Canali di notifica",
  activateChannelsSuffix: ".",
  notified: "Si riceverà una notifica quando questo prodotto torna disponibile.",
  getNotified: "Ricevere una notifica quando questo prodotto torna disponibile.",
  unsubscribeSuccess: "Non si riceveranno notifiche per questo prodotto quando tornerà disponibile.",
  subscriptionDialog: {
    header: "Sottoscrizione Non disponibile",
    notifiedPrefix: "Si riceverà una notifica su:",
    notifiedSuffix: "non appena questo prodotto tornerà disponibile.",
    manageChannelsPrefix: "Gestisci i canali di notifica preferiti nella pagina ",
    manageChannelsLink: "Preferenze di notifica",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "È possibile gestire le proprie sottoscrizioni nella pagina ",
    manageSubscriptionsLink: "Interessi personali",
    manageSubscriptionsSuffix: " .",
    okBtn: "OK",
    subscribing: "Sottoscrizione in corso alle notifiche di Non disponibile"
  }
};
var itemCounter$7 = {
  removeOne: "Rimuovi uno",
  addOneMore: "Aggiungi un altro",
  quantity: "Quantità"
};
var productView$7 = {
  gridView: "Seleziona per passare alla vista griglia",
  listView: "Seleziona per passare alla vista elenco"
};
var product$7 = {
  productDetails: productDetails$7,
  productList: productList$7,
  productFacetNavigation: productFacetNavigation$7,
  productSummary: productSummary$7,
  productReview: productReview$7,
  productCarousel: productCarousel$7,
  addToCart: addToCart$7,
  TabPanelContainer: TabPanelContainer$7,
  addToWishList: addToWishList$7,
  stockNotification: stockNotification$7,
  itemCounter: itemCounter$7,
  productView: productView$7
};
var pwa$e = {
  addToHomeScreenDescription: "Aggiungi lo storefront SAP alla schermata iniziale del dispositivo per tornare a visitare più rapidamente",
  noInstallationNeeded: "Nessuna installazione richiesta",
  fastAccessToApplication: "Accesso rapido all'applicazione",
  addToHomeScreen: "Aggiungi alla schermata iniziale",
  addedToHomeScreen: "Lo storefront SAP è stato aggiunto alla schermata iniziale"
};
var pwa$f = {
  pwa: pwa$e
};
var siteThemeSwitcher$e = {
  theme: "Tema",
  themes: {
    "default": "Predefinito",
    highContrastDark: "HC - Scuro",
    highContrastLight: "HC - Chiaro"
  }
};
var siteThemeSwitcher$f = {
  siteThemeSwitcher: siteThemeSwitcher$e
};
var anonymousConsents$7 = {
  dialog: {
    title: "Gestione dei consensi",
    legalDescription: "Utilizziamo i cookie/la memoria del browser per personalizzare i contenuti e migliorare l'esperienza degli utenti. Potremmo anche condividere i dati sull'utilizzo del sito con i nostri social media. Per ulteriori informazioni, consultare la nostra informativa sulla privacy. ",
    selectAll: "Seleziona tutto",
    clearAll: "Cancella tutto"
  },
  banner: {
    title: "Questo sito Web utilizza i cookie",
    description: "Utilizziamo i cookie/la memoria del browser per personalizzare i contenuti e migliorare l'esperienza degli utenti.",
    allowAll: "Consenti tutto",
    viewDetails: "Visualizza dettagli",
    consentManagement: "Gestione dei consensi"
  }
};
var checkoutLogin$7 = {
  emailAddress: {
    label: "Indirizzo e-mail",
    placeholder: "Inserisci e-mail"
  },
  confirmEmail: {
    label: "Conferma e-mail",
    placeholder: "Conferma e-mail"
  },
  "continue": "Continua",
  emailIsRequired: "Formato e-mail non valido",
  emailsMustMatch: "L'e-mail non corrisponde"
};
var authMessages$7 = {
  signedOutSuccessfully: "Disconnessione riuscita."
};
var user$7 = {
  anonymousConsents: anonymousConsents$7,
  checkoutLogin: checkoutLogin$7,
  authMessages: authMessages$7
};
var player$7 = {
  label: "Lettore video"
};
var video$7 = {
  player: player$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  common: common$f,
  deliveryMode: deliveryMode$7,
  myAccountV2Consent: myAccountV2Consent$f,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$f,
  myAccount: myAccount$7,
  payment: payment$7,
  pdf: pdf$f,
  product: product$7,
  pwa: pwa$f,
  siteThemeSwitcher: siteThemeSwitcher$f,
  user: user$7,
  video: video$7
};
var common$c = {
  cancel: "キャンセル",
  "delete": "削除",
  remove: "削除",
  edit: "編集",
  restore: "復元",
  back: "戻る",
  submit: "送信",
  "continue": "続行",
  save: "保存",
  done: "完了",
  home: "ホーム",
  noData: "データがありません。",
  confirm: "確認",
  more: "さらに表示",
  close: "閉じる",
  loading: "ロードしています",
  menu: "メニュー",
  reset: "リセット",
  search: "検索",
  add: "追加",
  breadcrumbs: "ブレッドクラム",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "ファイルを選択",
  clear: "クリア",
  loaded: "ロード済み",
  results: "結果",
  of: "/",
  ngSelectDropdownOptionsList: "オプション",
  required: "必須",
  zoomIn: "拡大",
  zoomOut: "縮小",
  selected: "選択済み",
  expand: "展開",
  collapse: "折りたたむ"
};
var pageMetaResolver$6 = {
  category: {
    title: "{{query}} に該当する結果: {{count}} 件",
    title_other: " {{query}} に該当する結果: {{count}} 件"
  },
  checkout: {
    title: "チェックアウト"
  },
  search: {
    title: "\"{{query}}\" に該当する結果: {{count}} 件",
    title_other: " \"{{query}}\" に該当する結果: {{count}} 件",
    findProductTitle: "クーポン \"{{coupon}}\" に該当する結果: {{count}} 件",
    findProductTitle_other: "クーポン \"{{coupon}}\" に該当する結果: {{count}} 件",
    default_title: "すべての商品"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$6 = {
  loading: "ロードしています..."
};
var navigation$6 = {
  categoryNavLabel: "カテゴリメニュー",
  footerNavLabel: "フッタリンク",
  goTo: "{{location}} に移動",
  navigateTo: "{{nav}} にナビゲート",
  scrollToTop: "ページの上部にスクロールして戻る",
  linkItemInList: "{{title}}. {{position}} / {{listLength}} ",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$6 = {
  placeholder: "商品名または SKU を入力",
  productSearch: "商品検索",
  ariaLabelInput: "ここで検索...",
  ariaLabelSuggestions: "提案を入力",
  ariaLabelProducts: "商品結果",
  initialDescription: "オートコンプリート結果が表示されたら、上下の矢印キーを使用して確認し、Enter を押して選択します。ダッチデバースユーザは、タッチするかスワイプジェスチャーによって参照します。",
  suggestions: "提案されたキーワード",
  recentSearches: "最近の検索",
  trendingSearches: "検索の傾向",
  suggestionsResult: "{{ count }} 提案および ",
  suggestionsResult_other: "{{ count }} 提案および ",
  products: "推奨商品",
  productsResult: "{{ count }} 商品を利用できます。",
  productsResult_other: "{{ count }} 商品を利用できます。",
  resetLabel: "検索をリセット",
  help: {
    insufficientChars: "さらに文字を入力してください",
    noMatch: "結果が見つかりませんでした",
    exactMatch: "{{ term }}",
    empty: "何か質問してください"
  },
  closeSearchPanel: "閉じる"
};
var sorting$6 = {
  date: "日付",
  orderNumber: "注文番号",
  rma: "返品番号",
  replenishmentNumber: "補充番号",
  nextOrderDate: "次回注文日",
  pageViewUpdated: "選択したオプションでページビューが更新されました。"
};
var httpHandlers$6 = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}。もう一度ログインしてください。",
    user_is_disabled: "{{ errorMessage }}。管理者に連絡してください。"
  },
  badGateway: "サーバエラーが発生しました。後でもう一度実行してください。",
  badRequestPleaseLoginAgain: "{{ errorMessage }}。もう一度ログインしてください。",
  badRequestOldPasswordIncorrect: "旧パスワードが正しくありません。",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} 電子メールがすでに存在します。別の電子メールでチェックアウトし、ゲストアカウントを使用して登録してください。",
  conflict: "すでに存在します。",
  forbidden: "このアクションを実行する権限がありません。これが誤りであると考えられる場合は、管理者に連絡してください。",
  gatewayTimeout: "サーバが応答しませんでした。後でもう一度実行してください。",
  internalServerError: "内部サーバエラーが発生しました。後でもう一度実行してください。",
  sessionExpired: "セッションの期限が切れました。もう一度ログインしてください。",
  unknownError: "不明なエラーが発生しました",
  unauthorized: {
    common: "不明な権限エラーが発生しました",
    invalid_client: "不正なクライアント認証情報"
  },
  validationErrors: {
    missing: {
      card_cardType: "選択したクレジットカードはサポートされていません。別のクレジットカードを選択してください。",
      card_accountNumber: "入力したクレジットカード番号が無効です。",
      card_cvNumber: "入力したセキュリティコードが無効です。",
      card_expirationMonth: "入力したクレジットカードの有効期限が無効です。",
      card_expirationYear: "入力したクレジットカードの有効期限が無効です。",
      billTo_firstName: "入力した名が無効です。",
      billTo_lastName: "入力した姓が無効です。",
      billTo_street1: "入力した住所が無効です。",
      billTo_street2: "入力した住所が無効です。",
      billTo_city: "このクレジットカードの入力した市区町村が無効です。",
      billTo_state: "このクレジットカードの入力した都道府県が無効です。",
      billTo_country: "このクレジットカードの入力した国/地域が無効です。",
      billTo_postalCode: "このクレジットカードの郵便番号が無効です。",
      country: {
        isocode: "未入力の国/地域"
      }
    },
    invalid: {
      card_expirationMonth: "入力したクレジットカードの有効期限が無効です。",
      firstName: "入力した名が無効です。",
      lastName: "入力した姓が無効です。",
      password: "入力したパスワードが無効です。",
      uid: "UID が無効です。",
      code: "コードが無効です。",
      email: "電子メールが無効です。",
      loginId: "電子メールが無効です。"
    }
  },
  cartNotFound: "カートが見つかりません。",
  invalidCodeProvided: "無効なコードが指定されています。",
  voucherExceeded: "このクーポンは使用可能な回数を超えています",
  unknownIdentifier: "アイテムが見つかりません。",
  otherCartErrors: "カートエラーが発生しました。"
};
var miniCart$6 = {
  item: "現在カートに {{count}} アイテムあります",
  item_other: "現在カートに {{count}} アイテムあります",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$6 = {
  skipTo: "スキップ先",
  labels: {
    header: "ヘッダ",
    main: "メインコンテンツ",
    footer: "フッタ",
    productFacets: "商品ファセット",
    productList: "商品リスト"
  }
};
var carousel$6 = {
  previousSlide: "前のスライド",
  nextSlide: "次のスライド",
  slideNumber: "スライド {{currentSlideNumber}} に {{numberOfSlides}} アイテムが含まれています",
  carouselForProduct: "カルーセル、{{product}} の画像"
};
var formErrors$6 = {
  labeled: {
    required: "フィールド {{label}} は必須です",
    cxInvalidEmail: "フィールド {{label}} に有効な電子メール形式がありません。一致パターン: example@yourdomain.com",
    cxInvalidPassword: "フィールド {{label}} に有効なパスワード形式がありません",
    cxMinOneUpperCaseCharacter: "パスワードには大文字を 1 文字以上使用する必要があります",
    cxMinOneDigit: "パスワードには数字を 1 つ以上使用する必要があります",
    cxMinOneSpecialCharacter: "パスワードには特殊文字を 1 つ以上使用する必要があります",
    cxMinSixCharactersLength: "パスワードには 6 文字以上使用する必要があります",
    cxMinEightCharactersLength: "パスワードには 8 文字以上使用する必要があります",
    cxMaxCharactersLength: "パスワードに 129 文字以上使用することはできません",
    cxContainsSpecialCharacters: "パスワードに特殊文字は使用できません",
    cxNoConsecutiveCharacters: "パスワードに同一の文字を連続して使用することはできません",
    date: {
      required: "フィールド {{label}} は必須です",
      min: "フィールド {{label}} を {{min}} の前にすることはできません",
      max: "フィールド {{label}} を {{max}} の後にすることはできません",
      pattern: "フィールド {{label}} では日付書式 yyyy-mm-dd を使用してください",
      invalid: "フィールド {{label}} で有効な日付を使用してください"
    }
  },
  globalMessage: "送信しようとしたフォームにエラーが含まれています。",
  required: "このフィールドは必須です",
  cxInvalidEmail: "これは有効な電子メール形式ではありません",
  cxInvalidPassword: "これは有効なパスワード形式ではありません",
  cxPasswordsMustMatch: "パスワードフィールドが一致する必要があります",
  cxPasswordsCannotMatch: "パスワードフィールドが一致しません",
  cxEmailsMustMatch: "電子メールフィールドが一致する必要があります",
  cxStarRatingEmpty: "評価フィールドは必須です",
  cxNoSelectedItemToCancel: "アイテムを少なくとも 1 つ選択してください",
  cxNegativeAmount: "金額はゼロ以上である必要があります",
  cxContainsSpecialCharacters: "フィールドに特殊文字は使用できません",
  date: {
    required: "このフィールドは必須です",
    min: "日付を {{min}} の前にすることはできません",
    max: "日付を {{max}} の後にすることはできません",
    pattern: "日付書式 yyyy-mm-dd を使用してください",
    invalid: "有効な日付を使用してください"
  },
  file: {
    required: "ファイルが必須です",
    empty: "ファイルを空にすることはできません",
    invalidExtension: "ファイル拡張子が無効です",
    tooLarge: "ファイルサイズが {{ maxSize }} MB を超えることはできません",
    tooManyEntries: "アイテム数が {{ maxEntries }} を超えています",
    notParsable: "ファイルを解析できません",
    fileNotAllowed: "このファイルタイプは使用できません"
  }
};
var errorHandlers$6 = {
  scriptFailedToLoad: "スクリプトをロードできませんでした。",
  refreshThePage: "ページを更新してください。"
};
var assistiveMessage$6 = {
  actionCancelled: "アクションがキャンセルされました。何も変更されていません"
};
var passwordVisibility$6 = {
  showPassword: "パスワードを表示する",
  hidePassword: "パスワードを表示しない"
};
var generalErrors$6 = {
  pageFailure: "ページをロードできませんでした。後でもう一度実行してください。"
};
var formLegend$6 = {
  required: "\"*\" の付いている次のフィールドは必須です。"
};
var chatMessaging$6 = {
  charactersLeft: "残り文字数: {{count}}",
  addNewMessage: "メッセージを新規追加",
  send: "送信",
  uploadFile: "ファイルのアップロード",
  informationLabel: "{{author}}。{{text}} ({{date}})",
  messages: "メッセージ",
  addMessagePlaceHolder: "入力を開始...",
  characterLimitAlert: "文字数制限に達しました。",
  a11y: {
    itemListBoxLabel: "メッセージを適用する必要があるアイテムを選択してください。"
  }
};
var common$d = {
  common: common$c,
  pageMetaResolver: pageMetaResolver$6,
  spinner: spinner$6,
  navigation: navigation$6,
  searchBox: searchBox$6,
  sorting: sorting$6,
  httpHandlers: httpHandlers$6,
  miniCart: miniCart$6,
  skipLink: skipLink$6,
  carousel: carousel$6,
  formErrors: formErrors$6,
  errorHandlers: errorHandlers$6,
  assistiveMessage: assistiveMessage$6,
  passwordVisibility: passwordVisibility$6,
  generalErrors: generalErrors$6,
  formLegend: formLegend$6,
  chatMessaging: chatMessaging$6
};
var setDeliveryMode$6 = {
  unknownError: "不明なエラーが発生しました。サポートに連絡してください。"
};
var deliveryMode$6 = {
  setDeliveryMode: setDeliveryMode$6
};
var myAccountV2Consent$c = {
  header: "同意管理",
  dateDescription: "承認日 ",
  clearAll: "すべての無効化",
  selectAll: "すべて有効化",
  message: {
    success: {
      given: "正常に同意されました。",
      withdrawn: "同意が正常に取り消されました。"
    }
  }
};
var myAccountV2Consent$d = {
  myAccountV2Consent: myAccountV2Consent$c
};
var myAccountV2NotificationPreference$c = {
  header: "通知チャネル",
  message: "優先通知チャネルを選択してください",
  note: "注記:  ",
  noteMessage: "すべてのチャネルを無効化すると、今後通知を受信できなくなります。",
  EMAIL: "電子メール: "
};
var myAccountV2NotificationPreference$d = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$c
};
var closeAccount$6 = {
  confirmAccountClosure: "アカウントのクローズを確認",
  confirmAccountClosureMessage: "アカウントをクローズしてもよろしいですか?",
  closeMyAccount: "マイアカウントをクローズする",
  accountClosedSuccessfully: "アカウントが正常にクローズされました",
  accountClosedFailure: "アカウントをクローズできませんでした"
};
var updatePasswordForm$6 = {
  oldPassword: {
    label: "旧パスワード",
    placeholder: "旧パスワード"
  },
  oldPasswordIsRequired: "旧パスワードが必要です。",
  newPassword: {
    label: "新しいパスワード",
    placeholder: "新しいパスワード"
  },
  passwordMinRequirements: "安全なパスワードについて簡単に説明します。大文字と小文字、数字、および記号を組み合わせた 8 文字以上を使用してださい。",
  passwordStrengthRequirements: "安全なパスワードについて簡単に説明します。大文字と小文字、数字、および記号を組み合わせた 8 文字以上を使用してださい。同じ文字を連続して使用しないようにしてください。",
  confirmPassword: {
    label: "新しいパスワードを再入力",
    placeholder: "パスワードを再入力"
  },
  bothPasswordMustMatch: "両方のパスワードが一致する必要があります",
  passwordUpdateSuccess: "パスワードが正常に更新されました",
  accessDeniedError: "アクセスが拒否されました"
};
var updateProfileForm$6 = {
  title: "タイトル",
  none: "",
  firstName: {
    label: "名",
    placeholder: "名"
  },
  firstNameIsRequired: "名は必須です。",
  lastName: {
    label: "姓",
    placeholder: "姓"
  },
  lastNameIsRequired: "姓は必須です。",
  profileUpdateSuccess: "個人詳細が正常に更新されました",
  customerId: "顧客番号"
};
var consentManagementForm$6 = {
  clearAll: "すべてクリア",
  selectAll: "すべて選択",
  message: {
    success: {
      given: "正常に同意されました。",
      withdrawn: "同意が正常に取り消されました。"
    }
  }
};
var myCoupons$6 = {
  noCouponsMessage: "利用可能なクーポンがありません。",
  effectiveTitle: "有効: ",
  Effective: "有効",
  PreSession: "間もなく有効",
  ExpireSoon: "間もなく期限切れ",
  readMore: "詳細を表示",
  notification: "通知",
  findProducts: "商品検索",
  status: "ステータス: ",
  dialogTitle: "クーポン",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "クーポンコード",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "このクーポンを請求できました。",
  myCoupons: "マイクーポン",
  startDateAsc: "開始日付 (昇順)",
  startDateDesc: "開始日付 (降順)",
  endDateAsc: "終了日付 (昇順)",
  endDateDesc: "終了日付 (降順)",
  sortBy: "ソート基準",
  sortCoupons: "クーポンをソート",
  notesPreffix: "クーポン通知を受け取る優先チャネルを設定できます ",
  notesLink: "通知チャネル",
  notesSuffix: " ページ。"
};
var notificationPreference$6 = {
  message: "優先通知チャネルを選択してください",
  note: "注記:  ",
  noteMessage: "すべてのチャネルを無効化すると、今後通知を受信できなくなります。",
  EMAIL: "電子メール: ",
  SMS: "SMS: ",
  SITE_MESSAGE: "SiteMessage"
};
var myInterests$6 = {
  header: "関心事",
  item: "アイテム",
  price: "価格",
  notifications: "通知",
  noInterests: "登録された関心事がまだありません。",
  inStock: "在庫あり",
  lowStock: "在庫僅少",
  outOfStock: "在庫なし",
  BACK_IN_STOCK: "再入荷",
  sortBy: "ソート基準",
  sortInterests: "関心事をソート",
  expirationDate: " - {{ expirationDate }} まで",
  productId: "ID {{ code }}",
  remove: "削除",
  sorting: {
    byNameAsc: "名前 (昇順)",
    byNameDesc: "名前 (降順)"
  },
  actions: "処理",
  caption: "関心事の内容。",
  itemRemoved: "選択したアイテムは削除されています。"
};
var myAccount$6 = {
  closeAccount: closeAccount$6,
  updatePasswordForm: updatePasswordForm$6,
  updateProfileForm: updateProfileForm$6,
  consentManagementForm: consentManagementForm$6,
  myCoupons: myCoupons$6,
  notificationPreference: notificationPreference$6,
  myInterests: myInterests$6
};
var paymentForm$6 = {
  payment: "支払",
  choosePaymentMethod: "支払方法を選択",
  paymentType: "支払タイプ",
  accountHolderName: {
    label: "口座名義人名",
    placeholder: "口座名義人名"
  },
  cardNumber: "カード番号",
  expirationDate: "有効期限",
  securityCode: "セキュリティコード (CVV)",
  securityCodeTitle: "カード照合番号",
  saveAsDefault: "デフォルトとして保存",
  setAsDefault: "デフォルトの支払方法として設定",
  billingAddress: "請求先住所",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "配送先住所と同じ",
  billingAddressSameAsShipping: "請求先住所は配送先住所と同じです",
  selectOne: "1 つ選択...",
  monthMask: "MM",
  yearMask: "YYYY",
  expirationYear: "有効期限の年 {{ selected }}",
  expirationMonth: "有効期限の月 {{ selected }}",
  useThisPayment: "この支払を使用",
  addNewPayment: "新しい支払を追加",
  paymentAddedSuccessfully: "新しい支払が正常に追加されました",
  changePayment: "支払を変更"
};
var paymentMethods$6 = {
  paymentMethods: "支払方法",
  paymentMethodSelected: "支払方法選択済み",
  newPaymentMethodsAreAddedDuringCheckout: "チェックアウト時に新しい支払方法が追加されます。",
  invalidField: "InvalidField: {{ field }}"
};
var paymentCard$6 = {
  deleteConfirmation: "この支払方法を削除してもよろしいですか?",
  setAsDefault: "デフォルトに設定",
  expires: "有効期限: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ デフォルト",
  defaultPaymentLabel: "デフォルトの支払方法",
  additionalPaymentLabel: "その他の支払方法 {{ number }}",
  selected: "選択済み",
  selectedPayment: "選択された支払",
  deletePaymentSuccess: "支払方法が正常に削除されました",
  visa: "Visa カード",
  master: "Mastercard カード",
  masterEuro: "Mastercard Eurocard カード",
  dinersClub: "Diners Club カード",
  "switch": "Switch カード",
  maestro: "Maestro カード",
  amex: "American Express カード",
  credit: "クレジットカード"
};
var paymentTypes$6 = {
  title: "支払方法",
  paymentType_CARD: "クレジットカード",
  paymentType_ACCOUNT: "アカウント"
};
var paymentMessages$6 = {
  setAsDefaultSuccessfully: "新しい支払がデフォルトとして正常に設定されました"
};
var payment$6 = {
  paymentForm: paymentForm$6,
  paymentMethods: paymentMethods$6,
  paymentCard: paymentCard$6,
  paymentTypes: paymentTypes$6,
  paymentMessages: paymentMessages$6
};
var pdf$c = {
  defaultTitle: "文書"
};
var pdf$d = {
  pdf: pdf$c
};
var productDetails$6 = {
  id: "ID",
  quantity: "数量",
  quantityFull: "数量",
  productDetails: "商品詳細",
  specification: "仕様",
  reviews: "レビュー",
  shipping: "配送中",
  share: "共有",
  showReviews: "レビューを表示",
  noReviews: "まだレビューなし",
  productPrice: "商品価格",
  noProductImage: "イメージがありません、{{ product }}"
};
var productList$6 = {
  filterBy: {
    label: "フィルタ基準",
    action: "フィルタ基準"
  },
  activeFilter: "{{filter}} フィルタ、このボタンをクリックするとフィルタが削除されます",
  appliedFilter: "適用済みフィルタ: ",
  showLess: "表示制限...",
  showMore: "表示追加...",
  sortBy: "ソート基準",
  sortResults: "結果をソート",
  backToTopBtn: "先頭に戻る",
  showMoreBtn: "表示追加",
  productSearchPagination: "商品検索のページネーション",
  productListResults: "商品結果一覧"
};
var productFacetNavigation$6 = {
  filterBy: {
    label: "フィルタ基準",
    action: "フィルタ基準",
    facet: "結果をファセットでフィルタ",
    name: "{{name}} でフィルタ"
  },
  appliedFilter: "適用済みフィルタ: ",
  showLess: "表示制限...",
  showMore: "表示追加...",
  sortBy: "ソート基準",
  ariaLabelShowLess: "表示制限、ボタン、このボタンをクリックすると有効なグループのオプションが減ります",
  ariaLabelShowMore: "表示追加、ボタン、このボタンをクリックすると有効なグループのすべてのオプションが表示されます",
  ariaLabelItemsAvailable: "{{name}}、{{state}} {{count}} アイテムを利用できます",
  ariaLabelItemsAvailable_other: "{{name}}、{{state}} {{count}} アイテムを利用できます",
  decreaseOptionsVisibility: "有効なタブグループでオプションが非表示になりました。戻ってオプションを読み込むか、次のグループに進んでください",
  increaseOptionsVisibility: "有効なタブグループにオプションが追加されました。戻ってオプションを読み込むか、次のグループに進んでください",
  backToResults: "結果に戻る",
  productFacets: "商品ファセット"
};
var productSummary$6 = {
  id: "ID",
  showReviews: "レビューを表示",
  showReviewsDetailed: "{{count}} レビューを表示、5 つ星のうち {{rating}} と評価",
  share: "共有",
  newItemPrice: "新しいアイテム価格"
};
var productReview$6 = {
  overallRating: "総合評価",
  reviewTitle: "レビュータイトル",
  writeYourComments: "コメントを入力してください",
  rating: "評価",
  ratingRequired: "商品評価、必須",
  addRate: "評価を追加: {{count}} つ星",
  addRate_other: "評価を追加: {{count}} つ星",
  reviewerName: "レビュー担当者名 (オプション)",
  writeReview: "レビューを書く",
  more: "レビューをさらに表示",
  less: "レビューの表示を減らす",
  thankYouForReview: "レビューをありがとうございます。レビューにはここに表示される前のレビューが必要な場合があることに注意してください。",
  postReviewFail: "レビューの投稿中に何らかの問題が発生しました。後でもう一度実行してください。",
  ratedOutOf: "評価: 5 のうち {{rating}}"
};
var productCarousel$6 = {
  carouselLabel: "カルーセル、{{title}}"
};
var addToCart$6 = {
  itemAddedToYourCart: "アイテムがカートに追加されました",
  itemsAddedToYourCart: "アイテムがカートに追加されました",
  itemsIncrementedInYourCart: "このアイテムはすでにカートに追加されています。数量が更新されました。",
  items: "アイテム",
  updatingCart: "カードを更新しています...",
  addToCart: "カートに追加",
  viewCart: "カートを表示",
  proceedToCheckout: "チェックアウトに進む",
  quantity: "数量",
  quantityFull: "数量",
  outOfStock: "在庫なし",
  inStock: "在庫あり",
  selectStyleAndSize: "在庫を確認するにはスタイルとサイズを選択してください",
  removeFromCart: "カートから商品を削除",
  closeModal: "モーダルを閉じる",
  buyItAgain: "再購入",
  addToActiveCart: "有効なカートに追加"
};
var TabPanelContainer$6 = {
  tabs: {
    ProductDetailsTabComponent: "商品詳細",
    ProductSpecsTabComponent: "仕様",
    ProductReviewsTabComponent: "レビュー",
    deliveryTab: "配送中",
    SparePartsTabComponent: " スペアパーツ"
  },
  tabPanelContainerRegion: "より多くの商品詳細が含まれるタブグループ",
  tabPanelContainerRegionGroup: "より多くの商品詳細が含まれるグループ"
};
var addToWishList$6 = {
  add: "ウィッシュリストに追加",
  remove: "ウィッシュリストから削除",
  anonymous: "サインインしてウィッシュリストに追加",
  addedToWishList: "ウィッシュリストに追加された商品",
  removedFromWishList: "ウィッシュリストから削除された商品"
};
var stockNotification$6 = {
  notifyMe: "通知を受け取る",
  stopNotify: "通知を停止",
  getNotify: "この商品が入手可能になったときに通知を受け取ります。",
  getNotifySuffix: "この商品が入手可能になったときに通知を受け取ります。",
  activateChannelsPrefix: "通知を受け取るために有効化が必要 ",
  channelsLink: "通知チャネル",
  activateChannelsSuffix: ".",
  notified: "この商品が再入荷したときに通知を受け取ります。",
  getNotified: "この商品が再入荷したときに通知を受け取ります。",
  unsubscribeSuccess: "この商品の再入荷通知を受け取りません。",
  subscriptionDialog: {
    header: "定期購入の在庫なし",
    notifiedPrefix: "通知日: ",
    notifiedSuffix: "この商品が再入荷次第。",
    manageChannelsPrefix: "優先通知チャネルを管理します ",
    manageChannelsLink: "通知設定",
    manageChannelsSuffix: " ページ。",
    manageSubscriptionsPrefix: "定期購入を管理できます ",
    manageSubscriptionsLink: "関心事",
    manageSubscriptionsSuffix: " ページ。",
    okBtn: "OK",
    subscribing: "この商品の在庫なし通知の購読"
  }
};
var itemCounter$6 = {
  removeOne: "削除",
  addOneMore: "もう 1 つ追加",
  quantity: "数量"
};
var productView$6 = {
  gridView: "選択してグリッドビューに変更",
  listView: "選択してリストビューに変更"
};
var product$6 = {
  productDetails: productDetails$6,
  productList: productList$6,
  productFacetNavigation: productFacetNavigation$6,
  productSummary: productSummary$6,
  productReview: productReview$6,
  productCarousel: productCarousel$6,
  addToCart: addToCart$6,
  TabPanelContainer: TabPanelContainer$6,
  addToWishList: addToWishList$6,
  stockNotification: stockNotification$6,
  itemCounter: itemCounter$6,
  productView: productView$6
};
var pwa$c = {
  addToHomeScreenDescription: "迅速に再訪できるように、デバイスのホーム画面に SAP Storefront を追加します",
  noInstallationNeeded: "インストールは不要です",
  fastAccessToApplication: "アプリケーションへの迅速なアクセス",
  addToHomeScreen: "ホーム画面に追加",
  addedToHomeScreen: "SAP Storefront がホーム画面に追加されました"
};
var pwa$d = {
  pwa: pwa$c
};
var siteThemeSwitcher$c = {
  theme: "テーマ",
  themes: {
    "default": "デフォルト",
    highContrastDark: "HC-Dark",
    highContrastLight: "HC-Light"
  }
};
var siteThemeSwitcher$d = {
  siteThemeSwitcher: siteThemeSwitcher$c
};
var anonymousConsents$6 = {
  dialog: {
    title: "同意管理",
    legalDescription: "クッキー/ブラウザのストレージを使用して、コンテンツをパーソナライズし、ユーザエクスペリエンスを向上させます。サイトの使用に関するデータをソーシャルメディアと共有する場合もあります。詳細については、プライバシーポリシーを確認してください。",
    selectAll: "すべて選択",
    clearAll: "すべてクリア"
  },
  banner: {
    title: "この Web サイトではクッキーが使用されます",
    description: "クッキー/ブラウザのストレージを使用して、コンテンツをパーソナライズし、ユーザエクスペリエンスを向上させます。",
    allowAll: "すべて許可",
    viewDetails: "詳細を表示",
    consentManagement: "同意管理"
  }
};
var checkoutLogin$6 = {
  emailAddress: {
    label: "メールアドレス",
    placeholder: "メールアドレスを入力"
  },
  confirmEmail: {
    label: "メールアドレスを確認",
    placeholder: "メールアドレスを確認"
  },
  "continue": "続行",
  emailIsRequired: "電子メール形式が無効です",
  emailsMustMatch: "電子メールが一致しません"
};
var authMessages$6 = {
  signedOutSuccessfully: "正常にサインアウトしました。"
};
var user$6 = {
  anonymousConsents: anonymousConsents$6,
  checkoutLogin: checkoutLogin$6,
  authMessages: authMessages$6
};
var player$6 = {
  label: "ビデオプレーヤー"
};
var video$6 = {
  player: player$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  common: common$d,
  deliveryMode: deliveryMode$6,
  myAccountV2Consent: myAccountV2Consent$d,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$d,
  myAccount: myAccount$6,
  payment: payment$6,
  pdf: pdf$d,
  product: product$6,
  pwa: pwa$d,
  siteThemeSwitcher: siteThemeSwitcher$d,
  user: user$6,
  video: video$6
};
var common$a = {
  cancel: "취소",
  "delete": "삭제",
  remove: "제거",
  edit: "편집",
  restore: "복원",
  back: "뒤로",
  submit: "제출",
  "continue": "계속",
  save: "저장",
  done: "완료",
  home: "홈",
  noData: "데이터 없음",
  confirm: "확인",
  more: "더 보기",
  close: "닫기",
  loading: "로드하는 중",
  menu: "메뉴",
  reset: "재설정",
  search: "검색",
  add: "추가",
  breadcrumbs: "탐색 경로",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "파일 선택",
  clear: "지우기",
  loaded: "로드됨",
  results: "결과",
  of: "/",
  ngSelectDropdownOptionsList: "옵션",
  required: "필수",
  zoomIn: "확대",
  zoomOut: "축소",
  selected: "선택됨",
  expand: "펼치기",
  collapse: "접기"
};
var pageMetaResolver$5 = {
  category: {
    title: "{{query}}에 대한 {{count}}개의 결과",
    title_other: "{{query}}에 대한 {{count}}개의 결과"
  },
  checkout: {
    title: "체크아웃"
  },
  search: {
    title: "\"{{query}}\"에 대한 {{count}}개의 결과",
    title_other: "\"{{query}}\"에 대한 {{count}}개의 결과",
    findProductTitle: "쿠폰 \"{{coupon}}\"에 대한 {{count}}개의 결과",
    findProductTitle_other: "쿠폰 \"{{coupon}}\"에 대한 {{count}}개의 결과",
    default_title: "모든 제품"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$5 = {
  loading: "로드하는 중..."
};
var navigation$5 = {
  categoryNavLabel: "범주 메뉴",
  footerNavLabel: "바닥글 링크",
  goTo: "{{location}}(으)로 가기",
  navigateTo: "{{nav}}(으)로 이동",
  scrollToTop: "페이지 맨 위로 이동",
  linkItemInList: "{{title}}. {{position}}/{{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$5 = {
  placeholder: "제품 이름이나 SKU 입력",
  productSearch: "제품 찾기",
  ariaLabelInput: "여기서 검색...",
  ariaLabelSuggestions: "입력 제안",
  ariaLabelProducts: "제품 결과",
  initialDescription: "자동 완성 결과가 있으면 위아래 화살표를 사용하여 확인한 후에 선택하십시오. 터치 디바이스 사용자는 터치하거나 쓸어넘기면서 찾으십시오. ",
  suggestions: "제안 키워드",
  recentSearches: "최근 검색",
  trendingSearches: "인기 검색어",
  suggestionsResult: "{{ count }}개의 제안 및 ",
  suggestionsResult_other: "{{ count }}개의 제안 및 ",
  products: "추천 제품",
  productsResult: "{{ count }}개의 제품을 사용할 수 있습니다.",
  productsResult_other: "{{ count }}개의 제품을 사용할 수 있습니다.",
  resetLabel: "검색 재설정",
  help: {
    insufficientChars: "글자를 더 입력하십시오.",
    noMatch: "결과를 찾을 수 없습니다.",
    exactMatch: "{{ term }}",
    empty: "무엇이든지 물어보세요"
  },
  closeSearchPanel: "닫기"
};
var sorting$5 = {
  date: "날짜",
  orderNumber: "주문 번호",
  rma: "반품 번호",
  replenishmentNumber: "보충 번호",
  nextOrderDate: "다음 주문일",
  pageViewUpdated: "페이지 보기에 선택한 옵션이 업데이트되었습니다."
};
var httpHandlers$5 = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. 다시 로그인하십시오.",
    user_is_disabled: "{{ errorMessage }}. 관리자에게 문의하십시오."
  },
  badGateway: "서버 오류가 발생했습니다. 나중에 다시 시도하십시오.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. 다시 로그인하십시오.",
  badRequestOldPasswordIncorrect: "이전 암호가 틀렸습니다.",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} 이메일이 이미 있습니다. 게스트 계정을 사용하여 등록하려면 다른 이메일로 체크아웃하십시오.",
  conflict: "이미 있습니다.",
  forbidden: "이 작업을 수행할 수 있는 권한이 없습니다. 잘못되었다고 생각하시면 관리자에게 문의하십시오.",
  gatewayTimeout: "서버가 응답하지 않습니다. 나중에 다시 시도하십시오.",
  internalServerError: "내부 서버 오류가 발생했습니다. 나중에 다시 시도하십시오.",
  sessionExpired: "세션이 만료되었습니다. 다시 로그인하십시오.",
  unknownError: "알 수 없는 오류가 발생했습니다.",
  unauthorized: {
    common: "알 수 없는 승인 오류가 발생했습니다.",
    invalid_client: "잘못된 클라이언트 자격 증명입니다."
  },
  validationErrors: {
    missing: {
      card_cardType: "선택한 신용 카드는 지원되지 않습니다. 다른 신용 카드를 선택하십시오.",
      card_accountNumber: "입력한 신용 카드 번호가 올바르지 않습니다.",
      card_cvNumber: "보안 코드가 올바르지 않습니다.",
      card_expirationMonth: "입력한 신용 카드 만료일이 올바르지 않습니다.",
      card_expirationYear: "입력한 신용 카드 만료일이 올바르지 않습니다.",
      billTo_firstName: "입력한 이름이 올바르지 않습니다.",
      billTo_lastName: "입력한 성이 올바르지 않습니다.",
      billTo_street1: "입력한 주소가 올바르지 않습니다.",
      billTo_street2: "입력한 주소가 올바르지 않습니다.",
      billTo_city: "이 신용 카드에 입력한 시/군/구가 올바르지 않습니다.",
      billTo_state: "이 신용 카드에 입력한 시/도가 올바르지 않습니다.",
      billTo_country: "이 신용 카드에 입력한 국가/지역이 올바르지 않습니다.",
      billTo_postalCode: "이 신용 카드에 입력한 우편번호가 올바르지 않습니다.",
      country: {
        isocode: "국가/지역이 없습니다."
      }
    },
    invalid: {
      card_expirationMonth: "입력한 신용 카드 만료일이 올바르지 않습니다.",
      firstName: "입력한 이름이 올바르지 않습니다.",
      lastName: "입력한 성이 올바르지 않습니다.",
      password: "입력한 암호가 올바르지 않습니다.",
      uid: "UID가 올바르지 않습니다.",
      code: "코드가 올바르지 않습니다.",
      email: "이메일이 올바르지 않습니다.",
      loginId: "이메일이 올바르지 않습니다."
    }
  },
  cartNotFound: "장바구니가 없습니다.",
  invalidCodeProvided: "잘못된 코드를 입력했습니다.",
  voucherExceeded: "이 쿠폰은 사용 횟수를 초과했습니다.",
  unknownIdentifier: "품목이 없습니다.",
  otherCartErrors: "장바구니 오류가 발생했습니다."
};
var miniCart$5 = {
  item: "장바구니에 현재 {{count}}개 품목이 있음",
  item_other: "장바구니에 현재 {{count}}개 품목이 있음",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$5 = {
  skipTo: "건너뛰기 -",
  labels: {
    header: "머리글",
    main: "본문",
    footer: "바닥글",
    productFacets: "제품 패싯",
    productList: "제품 목록"
  }
};
var carousel$5 = {
  previousSlide: "이전 슬라이드",
  nextSlide: "다음 슬라이드",
  slideNumber: "슬라이드 {{currentSlideNumber}}에 {{numberOfSlides}}개의 품목이 있음",
  carouselForProduct: "{{product}}의 캐러셀, 이미지"
};
var formErrors$5 = {
  labeled: {
    required: "필드 {{label}}은(는) 필수 필드입니다.",
    cxInvalidEmail: "필드 {{label}}의 형식이 올바른 이메일 형식이 아닙니다. example@yourdomain.com 형식이어야 합니다.",
    cxInvalidPassword: "필드 {{label}}의 형식이 올바른 암호 형식이 아닙니다.",
    cxMinOneUpperCaseCharacter: "암호에는 대문자가 하나 이상 포함되어야 합니다.",
    cxMinOneDigit: "암호에는 숫자가 하나 이상 포함되어야 합니다.",
    cxMinOneSpecialCharacter: "암호에는 특수 문자가 하나 이상 포함되어야 합니다.",
    cxMinSixCharactersLength: "암호는 6자 이상이어야 합니다.",
    cxMinEightCharactersLength: "암호는 8자 이상이어야 합니다.",
    cxMaxCharactersLength: "암호가 128자보다 길면 안 됩니다.",
    cxContainsSpecialCharacters: "암호에 특수 문자가 있으면 안 됩니다.",
    cxNoConsecutiveCharacters: "암호에 같은 문자가 연속되면 안 됩니다.",
    date: {
      required: "필드 {{label}}은(는) 필수 필드입니다.",
      min: "필드 {{label}}이(가) {{min}} 앞에 있으면 안 됩니다.",
      max: "필드 {{label}}이(가) {{max}} 뒤에 있으면 안 됩니다.",
      pattern: "필드 {{label}}에 yyyy-mm-dd 날짜 형식을 사용하십시오.",
      invalid: "필드 {{label}}에 올바른 날짜를 사용하십시오."
    }
  },
  globalMessage: "제출하려고 하는 서식에 오류가 있습니다.",
  required: "이 필드는 필수 필드입니다.",
  cxInvalidEmail: "올바른 이메일 형식이 아닙니다.",
  cxInvalidPassword: "올바른 암호 형식이 아닙니다.",
  cxPasswordsMustMatch: "암호 필드가 일치해야 합니다.",
  cxPasswordsCannotMatch: "암호 필드를 일치시킬 수 없습니다.",
  cxEmailsMustMatch: "이메일 필드가 일치해야 합니다.",
  cxStarRatingEmpty: "등급 필드는 필수 항목입니다.",
  cxNoSelectedItemToCancel: "품목을 하나 이상 선택하십시오.",
  cxNegativeAmount: "금액은 0보다 크거나 같아야 합니다.",
  cxContainsSpecialCharacters: "필드에 특수 문자가 있으면 안 됩니다.",
  date: {
    required: "이 필드는 필수 필드입니다.",
    min: "날짜가 {{min}}보다 먼저이면 안 됩니다.",
    max: "날짜가 {{max}}보다 나중이면 안 됩니다.",
    pattern: "yyyy-mm-dd 날짜 형식을 사용하십시오.",
    invalid: "올바른 날짜를 사용하십시오."
  },
  file: {
    required: "파일이 필요합니다.",
    empty: "파일이 비어 있으면 안 됩니다.",
    invalidExtension: "파일 확장자가 올바르지 않습니다.",
    tooLarge: "파일 크기가 {{ maxSize }}MB보다 크면 안 됩니다.",
    tooManyEntries: "항목 수가 {{ maxEntries }}개보다 많습니다.",
    notParsable: "파일 구문을 분석할 수 없습니다.",
    fileNotAllowed: "이 파일 유형은 사용할 수 없습니다."
  }
};
var errorHandlers$5 = {
  scriptFailedToLoad: "스크립트를 로드하지 못했습니다.",
  refreshThePage: "페이지를 새로 고치십시오."
};
var assistiveMessage$5 = {
  actionCancelled: "작업이 취소되었으며 아무 것도 변경되지 않았습니다."
};
var passwordVisibility$5 = {
  showPassword: "암호 표시",
  hidePassword: "암호 숨기기"
};
var generalErrors$5 = {
  pageFailure: "페이지를 로드할 수 없습니다. 나중에 다시 시도하십시오."
};
var formLegend$5 = {
  required: "\"*\"가 표시된 필드는 필수 필드입니다."
};
var chatMessaging$5 = {
  charactersLeft: "{{count}}자 남음",
  addNewMessage: "새 메시지 추가",
  send: "보내기",
  uploadFile: "파일 업로드",
  informationLabel: "{{author}}. {{text}} - {{date}}",
  messages: "메시지",
  addMessagePlaceHolder: "입력 시작...",
  characterLimitAlert: "문자 수 제한에 도달했습니다.",
  a11y: {
    itemListBoxLabel: "메시지가 적용되어야 하는 항목을 선택하십시오."
  }
};
var common$b = {
  common: common$a,
  pageMetaResolver: pageMetaResolver$5,
  spinner: spinner$5,
  navigation: navigation$5,
  searchBox: searchBox$5,
  sorting: sorting$5,
  httpHandlers: httpHandlers$5,
  miniCart: miniCart$5,
  skipLink: skipLink$5,
  carousel: carousel$5,
  formErrors: formErrors$5,
  errorHandlers: errorHandlers$5,
  assistiveMessage: assistiveMessage$5,
  passwordVisibility: passwordVisibility$5,
  generalErrors: generalErrors$5,
  formLegend: formLegend$5,
  chatMessaging: chatMessaging$5
};
var setDeliveryMode$5 = {
  unknownError: "알 수 없는 오류가 발생했습니다. 지원팀에 문의하십시오."
};
var deliveryMode$5 = {
  setDeliveryMode: setDeliveryMode$5
};
var myAccountV2Consent$a = {
  header: "동의 관리",
  dateDescription: "승인일 ",
  clearAll: "모두 비활성화",
  selectAll: "모두 활성화",
  message: {
    success: {
      given: "동의가 제공되었습니다.",
      withdrawn: "동의가 취소되었습니다."
    }
  }
};
var myAccountV2Consent$b = {
  myAccountV2Consent: myAccountV2Consent$a
};
var myAccountV2NotificationPreference$a = {
  header: "알림 채널",
  message: "원하는 알림 채널을 선택하십시오.",
  note: "참고: ",
  noteMessage: "모든 채널을 비활성화하면 나중에 통지를 받을 수 없습니다.",
  EMAIL: "이메일:"
};
var myAccountV2NotificationPreference$b = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$a
};
var closeAccount$5 = {
  confirmAccountClosure: "계정 폐쇄 확인",
  confirmAccountClosureMessage: "계정을 폐쇄하시겠습니까?",
  closeMyAccount: "내 계정 폐쇄",
  accountClosedSuccessfully: "계정이 폐쇄되었습니다.",
  accountClosedFailure: "계정을 폐쇄하지 못했습니다."
};
var updatePasswordForm$5 = {
  oldPassword: {
    label: "기존 암호",
    placeholder: "기존 암호"
  },
  oldPasswordIsRequired: "기존 암호는 필수 항목입니다.",
  newPassword: {
    label: "새 암호",
    placeholder: "새 암호"
  },
  passwordMinRequirements: "보안 암호에 대한 퀵 가이드입니다. 8자 이상이어야 하며, 대문자, 소문자, 숫자, 기호가 포함되어야 합니다.",
  passwordStrengthRequirements: "보안 암호에 대한 퀵 가이드입니다. 8자 이상이어야 하며, 대문자, 소문자, 숫자, 기호가 포함되어야 합니다. 같은 문자를 연속해서 사용하지 마십시오.",
  confirmPassword: {
    label: "새 암호 확인",
    placeholder: "암호 확인"
  },
  bothPasswordMustMatch: "두 개의 암호가 일치해야 합니다.",
  passwordUpdateSuccess: "암호가 업데이트되었습니다.",
  accessDeniedError: "액세스가 거부되었습니다."
};
var updateProfileForm$5 = {
  title: "호칭",
  none: "",
  firstName: {
    label: "이름",
    placeholder: "이름"
  },
  firstNameIsRequired: "이름은 필수 항목입니다.",
  lastName: {
    label: "성",
    placeholder: "성"
  },
  lastNameIsRequired: "성은 필수 항목입니다.",
  profileUpdateSuccess: "개인 세부사항이 업데이트되었습니다.",
  customerId: "고객 번호"
};
var consentManagementForm$5 = {
  clearAll: "모두 지우기",
  selectAll: "모두 선택",
  message: {
    success: {
      given: "동의했습니다.",
      withdrawn: "동의가 취소되었습니다."
    }
  }
};
var myCoupons$5 = {
  noCouponsMessage: "사용할 수 있는 쿠폰이 없습니다.",
  effectiveTitle: "유효:",
  Effective: "유효",
  PreSession: "곧 유효함",
  ExpireSoon: "곧 만료 예정",
  readMore: "자세히 보기",
  notification: "알림",
  findProducts: "제품 찾기",
  status: "상태:",
  dialogTitle: "쿠폰",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "쿠폰 코드",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "이 쿠폰을 청구했습니다.",
  myCoupons: "내 쿠폰",
  startDateAsc: "시작 날짜(오름차순)",
  startDateDesc: "시작 날짜(내림차순)",
  endDateAsc: "종료 날짜(오름차순)",
  endDateDesc: "종료 날짜(내림차순)",
  sortBy: "정렬 기준",
  sortCoupons: "쿠폰 정렬",
  notesPreffix: "다음에서 쿠폰 알림을 받기 위해 원하는 채널을 설정할 수 있음 - ",
  notesLink: "알림 채널",
  notesSuffix: " 페이지"
};
var notificationPreference$5 = {
  message: "원하는 알림 채널을 선택하십시오.",
  note: "참고: ",
  noteMessage: "모든 채널을 비활성화하면 나중에 알림을 받을 수 없습니다.",
  EMAIL: "이메일:",
  SMS: "SMS",
  SITE_MESSAGE: "사이트 메시지"
};
var myInterests$5 = {
  header: "내 관심",
  item: "항목",
  price: "가격",
  notifications: "알림",
  noInterests: "아직 관심을 등록하지 않았습니다.",
  inStock: "재고 있음",
  lowStock: "재고 부족",
  outOfStock: "재고 없음",
  BACK_IN_STOCK: "재입고",
  sortBy: "정렬 기준",
  sortInterests: "관심 정렬",
  expirationDate: " - {{ expirationDate }}까지",
  productId: "ID {{ code }}",
  remove: "제거",
  sorting: {
    byNameAsc: "이름(오름차순)",
    byNameDesc: "이름(내림차순)"
  },
  actions: "작업",
  caption: "내 관심 내용",
  itemRemoved: "선택한 항목이 제거되었습니다."
};
var myAccount$5 = {
  closeAccount: closeAccount$5,
  updatePasswordForm: updatePasswordForm$5,
  updateProfileForm: updateProfileForm$5,
  consentManagementForm: consentManagementForm$5,
  myCoupons: myCoupons$5,
  notificationPreference: notificationPreference$5,
  myInterests: myInterests$5
};
var paymentForm$5 = {
  payment: "지불",
  choosePaymentMethod: "지불 방법 선택",
  paymentType: "지불 유형",
  accountHolderName: {
    label: "계좌 소유주 이름",
    placeholder: "계좌 소유주 이름"
  },
  cardNumber: "카드 번호",
  expirationDate: "만료일",
  securityCode: "보안 코드(CVV)",
  securityCodeTitle: "카드 인증 값",
  saveAsDefault: "기본값으로 저장",
  setAsDefault: "기본 지불 방법으로 설정",
  billingAddress: "청구 주소",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "배송 주소와 같음",
  billingAddressSameAsShipping: "청구 주소가 배송 주소와 같습니다.",
  selectOne: "하나를 선택하십시오...",
  monthMask: "MM",
  yearMask: "YYYY",
  expirationYear: "만료 연도 {{ selected }}",
  expirationMonth: "만료 월 {{ selected }}",
  useThisPayment: "이 지불 사용",
  addNewPayment: "새 지불 추가",
  paymentAddedSuccessfully: "새 지불이 추가되었습니다.",
  changePayment: "지불 변경"
};
var paymentMethods$5 = {
  paymentMethods: "지불 방법",
  paymentMethodSelected: "지불 방법 선택됨",
  newPaymentMethodsAreAddedDuringCheckout: "체크아웃 중에 새 지불 방법이 추가되었습니다.",
  invalidField: "잘못된 필드: {{ field }}"
};
var paymentCard$5 = {
  deleteConfirmation: "이 지불 방법을 삭제하시겠습니까?",
  setAsDefault: "기본값으로 설정",
  expires: "만료: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ 기본값",
  defaultPaymentLabel: "기본 지불 방법",
  additionalPaymentLabel: "추가 지불 방법 {{ number }}",
  selected: "선택됨",
  selectedPayment: "선택한 지불",
  deletePaymentSuccess: "지불 방법이 삭제되었습니다.",
  visa: "Visa 카드",
  master: "Mastercard 카드",
  masterEuro: "Mastercard Eurocard 카드",
  dinersClub: "Diners Club 카드",
  "switch": "Switch 카드",
  maestro: "Maestro 카드",
  amex: "American Express 카드",
  credit: "신용 카드"
};
var paymentTypes$5 = {
  title: "지불 방법",
  paymentType_CARD: "신용 카드",
  paymentType_ACCOUNT: "계좌"
};
var paymentMessages$5 = {
  setAsDefaultSuccessfully: "새 지불이 기본값으로 설정되었습니다."
};
var payment$5 = {
  paymentForm: paymentForm$5,
  paymentMethods: paymentMethods$5,
  paymentCard: paymentCard$5,
  paymentTypes: paymentTypes$5,
  paymentMessages: paymentMessages$5
};
var pdf$a = {
  defaultTitle: "문서"
};
var pdf$b = {
  pdf: pdf$a
};
var productDetails$5 = {
  id: "ID",
  quantity: "수량",
  quantityFull: "수량",
  productDetails: "제품 세부사항",
  specification: "사양",
  reviews: "리뷰",
  shipping: "배송",
  share: "공유",
  showReviews: "리뷰 표시",
  noReviews: "리뷰가 아직 없음",
  productPrice: "제품 가격",
  noProductImage: "이미지 없음, {{ product }}"
};
var productList$5 = {
  filterBy: {
    label: "필터링 기준",
    action: "필터링 기준"
  },
  activeFilter: "{{filter}} 필터, 이 버튼을 클릭하면 필터가 제거됩니다.",
  appliedFilter: "적용된 필터:",
  showLess: "간단히 보기...",
  showMore: "자세히 보기...",
  sortBy: "정렬 기준",
  sortResults: "결과 정렬",
  backToTopBtn: "맨위로 이동",
  showMoreBtn: "자세히 보기",
  productSearchPagination: "제품 검색 페이지 매김",
  productListResults: "제품 결과 목록"
};
var productFacetNavigation$5 = {
  filterBy: {
    label: "필터링 기준",
    action: "필터링 기준",
    facet: "패싯별 결과 필터링",
    name: "필터링 기준: {{name}}"
  },
  appliedFilter: "적용된 필터:",
  showLess: "간단히 보기...",
  showMore: "자세히 보기...",
  sortBy: "정렬 기준",
  ariaLabelShowLess: "간단히 보기, 이 버튼을 클릭하면 활성 그룹의 옵션이 줄어듭니다.",
  ariaLabelShowMore: "자세히 보기, 이 버튼을 클릭하면 활성 그룹의 옵션이 모두 표시됩니다.",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}}개 품목을 사용할 수 있음",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}}개 품목을 사용할 수 있음",
  decreaseOptionsVisibility: "사용 중인 그룹에 옵션이 숨겨져 있습니다. 읽어보려면 뒤로 탭하고 다음 그룹으로 가려면 앞으로 탭하십시오.",
  increaseOptionsVisibility: "활성 그룹에 더 많은 옵션이 추가되었습니다. 뒤로 탭하여 읽거나 앞으로 탭하여 다음 그룹을 읽을 수 있습니다.",
  backToResults: "결과로 돌아가기",
  productFacets: "제품 패싯"
};
var productSummary$5 = {
  id: "ID",
  showReviews: "리뷰 표시",
  showReviewsDetailed: "{{count}}개 리뷰 표시, 5개 별점 중 {{rating}}",
  share: "공유",
  newItemPrice: "새 품목 가격"
};
var productReview$5 = {
  overallRating: "전체 평점",
  reviewTitle: "리뷰 제목",
  writeYourComments: "의견 쓰기",
  rating: "평가",
  ratingRequired: "제품 평가 필요",
  addRate: "평가 추가: {{count}}점",
  addRate_other: "평가 추가: {{count}}점",
  reviewerName: "리뷰어 이름(선택사항)",
  writeReview: "리뷰 쓰기",
  more: "더 많은 리뷰 표시",
  less: "더 적은 리뷰 표시",
  thankYouForReview: "리뷰를 남겨주셔서 감사합니다! 여기에 표시되기 전에 리뷰를 검토해야 할 수 있습니다.",
  postReviewFail: "리뷰를 게시하는 동안 문제가 발생했습니다. 나중에 다시 시도하십시오.",
  ratedOutOf: "평가: 5점 중 {{rating}}점"
};
var productCarousel$5 = {
  carouselLabel: "캐러셀, {{title}}"
};
var addToCart$5 = {
  itemAddedToYourCart: "품목이 장바구니에 추가됨",
  itemsAddedToYourCart: "품목이 장바구니에 추가됨",
  itemsIncrementedInYourCart: "이 품목은 이미 장바구니에 있습니다. 수량이 업데이트되었습니다.",
  items: "품목",
  updatingCart: "장바구니 업데이트 중...",
  addToCart: "장바구니에 추가",
  viewCart: "장바구니 보기",
  proceedToCheckout: "체크아웃 계속하기",
  quantity: "수량",
  quantityFull: "수량",
  outOfStock: "재고 없음",
  inStock: "재고 있음",
  selectStyleAndSize: "재고를 확인하려면 스타일과 크기를 선택하십시오.",
  removeFromCart: "장바구니에서 제품 제거",
  closeModal: "모달 닫기",
  buyItAgain: "다시 구매",
  addToActiveCart: "사용 중인 장바구니에 추가"
};
var TabPanelContainer$5 = {
  tabs: {
    ProductDetailsTabComponent: "제품 세부사항",
    ProductSpecsTabComponent: "사양",
    ProductReviewsTabComponent: "리뷰",
    deliveryTab: "배송",
    SparePartsTabComponent: " 예비품"
  },
  tabPanelContainerRegion: "추가 제품 세부사항이 있는 그룹을 탭",
  tabPanelContainerRegionGroup: "추가 제품 세부사항이 있는 그룹"
};
var addToWishList$5 = {
  add: "관심 품목 목록에 추가",
  remove: "관심 품목 목록에서 제거",
  anonymous: "관심 품목 목록에 추가하기 위해 로그인",
  addedToWishList: "제품이 관심 품목 목록에 추가됨",
  removedFromWishList: "제품이 관심 품목 목록에서 제거됨"
};
var stockNotification$5 = {
  notifyMe: "나에게 알림",
  stopNotify: "알림 중지",
  getNotify: "이 제품이 입고되면 알림을 받습니다.",
  getNotifySuffix: "이 제품이 입고되었을 때 알림을 받으려면",
  activateChannelsPrefix: "알림을 받으려면 다음을 활성화해야 함 - ",
  channelsLink: "알림 채널",
  activateChannelsSuffix: ".",
  notified: "이 제품이 재입고되면 알림을 받게 됩니다.",
  getNotified: "이 제품이 재입고되면 알림을 받게 됩니다.",
  unsubscribeSuccess: "이 제품의 재입고 알림을 받지 않습니다.",
  subscriptionDialog: {
    header: "재고 없음 구독",
    notifiedPrefix: "알림을 받음:",
    notifiedSuffix: "이 제품이 재입고되는 경우",
    manageChannelsPrefix: "원하는 알림 채널을 관리하십시오 - ",
    manageChannelsLink: "알림 기본 설정",
    manageChannelsSuffix: " 페이지",
    manageSubscriptionsPrefix: "구독을 관리할 수 있음 - ",
    manageSubscriptionsLink: "내 관심",
    manageSubscriptionsSuffix: " 페이지",
    okBtn: "확인",
    subscribing: "이 제품의 재고 없음 알림 구독"
  }
};
var itemCounter$5 = {
  removeOne: "1개 제거",
  addOneMore: "1개 더 추가",
  quantity: "수량"
};
var productView$5 = {
  gridView: "그리드 보기로 변경하려면 선택",
  listView: "목록 보기로 변경하려면 선택"
};
var product$5 = {
  productDetails: productDetails$5,
  productList: productList$5,
  productFacetNavigation: productFacetNavigation$5,
  productSummary: productSummary$5,
  productReview: productReview$5,
  productCarousel: productCarousel$5,
  addToCart: addToCart$5,
  TabPanelContainer: TabPanelContainer$5,
  addToWishList: addToWishList$5,
  stockNotification: stockNotification$5,
  itemCounter: itemCounter$5,
  productView: productView$5
};
var pwa$a = {
  addToHomeScreenDescription: "빠른 재방문을 위해 장치의 홈 화면에 SAP Storefront를 추가하십시오. ",
  noInstallationNeeded: "설치가 필요 없습니다.",
  fastAccessToApplication: "빠른 어플리케이션 액세스",
  addToHomeScreen: "홈 화면에 추가",
  addedToHomeScreen: "SAP Storefront에 홈 화면에 추가되었습니다."
};
var pwa$b = {
  pwa: pwa$a
};
var siteThemeSwitcher$a = {
  theme: "테마",
  themes: {
    "default": "기본값",
    highContrastDark: "HC-어두움",
    highContrastLight: "HC-밝음"
  }
};
var siteThemeSwitcher$b = {
  siteThemeSwitcher: siteThemeSwitcher$a
};
var anonymousConsents$5 = {
  dialog: {
    title: "동의 관리",
    legalDescription: "쿠키/브라우저 저장소를 사용하여 내용을 개인에 맞게 설정하고 사용자 경험을 향상시킵니다. 소셜 미디어로 사이트 사용에 대한 데이터를 공유할 수도 있습니다. 자세한 내용은 개인정보 보호 정책을 확인하십시오.",
    selectAll: "모두 선택",
    clearAll: "모두 지우기"
  },
  banner: {
    title: "이 웹사이트에서는 쿠키를 사용함",
    description: "쿠키/브라우저 저장소를 사용하여 내용을 개인에 맞게 설정하고 사용자 경험을 향상시킵니다.",
    allowAll: "모두 허용",
    viewDetails: "세부사항 보기",
    consentManagement: "동의 관리"
  }
};
var checkoutLogin$5 = {
  emailAddress: {
    label: "이메일 주소",
    placeholder: "이메일 입력"
  },
  confirmEmail: {
    label: "이메일 확인",
    placeholder: "이메일 확인"
  },
  "continue": "계속",
  emailIsRequired: "잘못된 이메일 형식입니다.",
  emailsMustMatch: "이메일이 일치하지 않습니다."
};
var authMessages$5 = {
  signedOutSuccessfully: "로그아웃했습니다."
};
var user$5 = {
  anonymousConsents: anonymousConsents$5,
  checkoutLogin: checkoutLogin$5,
  authMessages: authMessages$5
};
var player$5 = {
  label: "영상 플레이어"
};
var video$5 = {
  player: player$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  common: common$b,
  deliveryMode: deliveryMode$5,
  myAccountV2Consent: myAccountV2Consent$b,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$b,
  myAccount: myAccount$5,
  payment: payment$5,
  pdf: pdf$b,
  product: product$5,
  pwa: pwa$b,
  siteThemeSwitcher: siteThemeSwitcher$b,
  user: user$5,
  video: video$5
};
var common$8 = {
  cancel: "Anuluj",
  "delete": "Usuń",
  remove: "Usuń",
  edit: "Edytuj",
  restore: "Przywróć",
  back: "Wstecz",
  submit: "Prześlij",
  "continue": "Kontynuuj",
  save: "Zapisz",
  done: "Gotowe",
  home: "Strona główna",
  noData: "Brak danych",
  confirm: "Potwierdź",
  more: "więcej",
  close: "Zamknij",
  loading: "Ładowanie",
  menu: "Menu",
  reset: "Resetuj",
  search: "Szukaj",
  add: "Dodaj",
  breadcrumbs: "Ścieżka nawigacji",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Wybierz plik",
  clear: "Wyczyść",
  loaded: "Załadowane",
  results: "Wyniki",
  of: "z",
  ngSelectDropdownOptionsList: "Opcje",
  required: "wymagane",
  zoomIn: "Powiększ",
  zoomOut: "Pomniejsz",
  selected: "Wybrane",
  expand: "Rozwiń",
  collapse: "Zwiń"
};
var pageMetaResolver$4 = {
  category: {
    title: "{{count}} wynik dla {{query}}",
    title_other: "{{count}} wyniki dla {{query}}"
  },
  checkout: {
    title: "Realizacja zakupu"
  },
  search: {
    title: "{{count}} wynik dla \"{{query}}\"",
    title_other: "{{count}} wyniki dla \"{{query}}\"",
    findProductTitle: "{{count}} wynik dla kuponu \"{{coupon}}\"",
    findProductTitle_other: "{{count}} wyniki dla kuponu \"{{coupon}}\"",
    default_title: "Wszystkie produkty"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$4 = {
  loading: "Wczytywanie..."
};
var navigation$4 = {
  categoryNavLabel: "Menu kategorii",
  footerNavLabel: "Linki w stopce",
  goTo: "Przejdź do {{location}}",
  navigateTo: "Przejdź do {{nav}}",
  scrollToTop: "Przewiń na górę strony",
  linkItemInList: "{{title}}. {{position}} z {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$4 = {
  placeholder: "Wprowadź nazwę produktu lub SKU",
  productSearch: "Znajdż produkt",
  ariaLabelInput: "Szukaj tutaj...",
  ariaLabelSuggestions: "pisanie sugestii",
  ariaLabelProducts: "lista wyszukanych produktów",
  initialDescription: "Gdy dostępne są wyniki autouzupełniania, użyj strzałek w górę i w dół, aby je przejrzeć i naciśnij Enter, aby je wybrać. Użytkownicy urządzeń dotykowych mogą przeglądać za pomocą dotknięcia lub przesunięcia palcem.",
  suggestions: "Sugerowane słowa kluczowe",
  recentSearches: "Ostatnie wyszukiwania",
  trendingSearches: "Popularne wyszukiwania",
  suggestionsResult: " {{ count }} sugestia i ",
  suggestionsResult_other: " {{ count }} sugestie i ",
  products: "Polecane produkty",
  productsResult: "{{ count }} produkt jest dostępny.",
  productsResult_other: "{{ count }} produkty są dostępne.",
  resetLabel: "Resetuj wyszukiwanie",
  help: {
    insufficientChars: "Wpisz więcej znaków",
    noMatch: "Nie udało się znaleźć żadnych wyników",
    exactMatch: "{{ term }}",
    empty: "Zapytaj nas o cokolwiek"
  },
  closeSearchPanel: "Zamknij"
};
var sorting$4 = {
  date: "Data",
  orderNumber: "Numer zamówienia",
  rma: "Numer zwrotu",
  replenishmentNumber: "Numer uzupełnienia",
  nextOrderDate: "Data następnego zamówienia",
  pageViewUpdated: "Widok strony zaktualizowany o wybrane opcje."
};
var httpHandlers$4 = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Zaloguj się ponownie.",
    user_is_disabled: "{{ errorMessage }}. Skontaktuj się z administracją."
  },
  badGateway: "Wystąpił wewnętrzny błąd serwera. Spróbuj ponownie później.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Zaloguj się ponownie.",
  badRequestOldPasswordIncorrect: "Stare hasło jest niepoprawne.",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} e-mail już istnieje. Aby zarejestrować się za pomocą konta gościa, podaj inny adres e-mail.",
  conflict: "Już istnieje.",
  forbidden: "Nie masz uprawnień do wykonania tej czynności. Jeśli uważasz, że jest to błąd, skontaktuj się z administratorem.",
  gatewayTimeout: "Serwer nie odpowiedział, spróbuj ponownie później.",
  internalServerError: "Wystąpił wewnętrzny błąd serwera. Spróbuj ponownie później.",
  sessionExpired: "Sesja wygasła. Zaloguj się ponownie.",
  unknownError: "Wystąpił nieznany błąd",
  unauthorized: {
    common: "Wystąpił nieznany błąd autoryzacji",
    invalid_client: "Błędne dane uwierzytelniające klienta"
  },
  validationErrors: {
    missing: {
      card_cardType: "Wybrana karta kredytowa nie jest obsługiwana. Wybierz inną kartę.",
      card_accountNumber: "Wprowadzony numer karty kredytowej jest nieważny.",
      card_cvNumber: "Kod bezpieczeństwa jest nieprawidłowy.",
      card_expirationMonth: "Wprowadzona data ważności karty kredytowej jest nieprawidłowa.",
      card_expirationYear: "Wprowadzona data ważności karty kredytowej jest nieprawidłowa.",
      billTo_firstName: "Wprowadzone imię jest nieprawidłowe.",
      billTo_lastName: "Wprowadzone nazwisko jest nieprawidłowe.",
      billTo_street1: "Wprowadzony adres jest nieprawidłowy.",
      billTo_street2: "Wprowadzony adres jest nieprawidłowy.",
      billTo_city: "Wprowadzone miasto jest nieprawidłowe dla tej karty kredytowej.",
      billTo_state: "Wprowadzony stan/prowincja jest nieprawidłowy dla tej karty kredytowej.",
      billTo_country: "Wprowadzony kraj jest nieprawidłowy dla tej karty kredytowej.",
      billTo_postalCode: "Kod pocztowy jest nieprawidłowy dla tej karty kredytowej.",
      country: {
        isocode: "Brak informacji o kraju/regionie"
      }
    },
    invalid: {
      card_expirationMonth: "Wprowadzona data ważności karty kredytowej jest nieprawidłowa.",
      firstName: "Wprowadzone imię jest nieprawidłowe.",
      lastName: "Wprowadzone nazwisko jest nieprawidłowe.",
      password: "Wprowadzone hasło jest nieprawidłowe.",
      uid: "UID jest nieprawidłowy.",
      code: "Kod jest nieprawidłowy.",
      email: "Adres e-mail jest nieprawidłowy.",
      loginId: "Adres e-mail jest nieprawidłowy."
    }
  },
  cartNotFound: "Koszyk nie odnaleziony.",
  invalidCodeProvided: "Podano niepoprawny kod.",
  voucherExceeded: "Ten kupon przekroczył dozwoloną liczbę możliwych zastosowań",
  unknownIdentifier: "Nie znaleziono pozycji.",
  otherCartErrors: "Wystąpił błąd koszyka."
};
var miniCart$4 = {
  item: "{{count}} pozycja aktualnie w twoim koszyku",
  item_other: "{{count}} pozycje aktualnie w twoim koszyku",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$4 = {
  skipTo: "Pomiń i przejdź do",
  labels: {
    header: "Nagłówek",
    main: "Zawartość główna",
    footer: "Stopka",
    productFacets: "Właściwości produktu",
    productList: "Lista produktów"
  }
};
var carousel$4 = {
  previousSlide: "Poprzedni slajd",
  nextSlide: "Następny slajd",
  slideNumber: "Slajd {{currentSlideNumber}} zawiera {{numberOfSlides}} pozycje",
  carouselForProduct: "Karuzela, zdjęcia dla {{product}}"
};
var formErrors$4 = {
  labeled: {
    required: "Pole {{label}} jest wymagane",
    cxInvalidEmail: "Pole {{label}} ma nieprawidłowy format e-mail. Dopasuj do wzoru: przyklad@twojadomena.com",
    cxInvalidPassword: "Pole {{label}} ma nieprawidłowy format hasła",
    cxMinOneUpperCaseCharacter: "Hasło powinno zawierać co najmniej jedną wielką literę",
    cxMinOneDigit: "Hasło musi zawierać co najmniej jedną cyfrę",
    cxMinOneSpecialCharacter: "Hasło musi zawierać co najmniej jeden znak specjalny",
    cxMinSixCharactersLength: "Hasło musi zawierać co najmniej 6 znaków",
    cxMinEightCharactersLength: "Hasło musi zawierać co najmniej 8 znaków",
    cxMaxCharactersLength: "Hasło nie może mieć więcej niż 128 znaków",
    cxContainsSpecialCharacters: "Hasło nie może zawierać znaków specjalnych",
    cxNoConsecutiveCharacters: "Hasło nie może zawierać następujących po sobie identycznych znaków",
    date: {
      required: "Pole {{label}} jest wymagane",
      min: "Pole {{label}} nie może być przed {{min}}",
      max: "Pole {{label}} nie może być po {{max}}",
      pattern: "W polu {{label}} użyj formatu daty rrrr-mm-dd",
      invalid: "Użyj ważnej daty w polu {{label}}"
    }
  },
  globalMessage: "Formularz, który próbujesz przesłać, zawiera błędy.",
  required: "To pole jest obowiązkowe",
  cxInvalidEmail: "To nie jest prawidłowy format adresu e-mail",
  cxInvalidPassword: "To nie jest prawidłowy format hasła",
  cxPasswordsMustMatch: "Pola hasła muszą być zgodne",
  cxPasswordsCannotMatch: "Pola hasła nie mogą być zgodne",
  cxEmailsMustMatch: "Pola adresu e-mail muszą być zgodne",
  cxStarRatingEmpty: "Pole oceny jest wymagane",
  cxNoSelectedItemToCancel: "Wybierz przynajmniej jedną pozycję",
  cxNegativeAmount: "Liczba musi być równa lub większa od zera",
  cxContainsSpecialCharacters: "Pole nie może zawierać znaków specjalnych",
  date: {
    required: "To pole jest obowiązkowe",
    min: "Data nie może być przed {{min}}",
    max: "Data nie może być po {{max}}",
    pattern: "Użyj formatu daty rrrr-mm-dd",
    invalid: "Użyj ważnej daty"
  },
  file: {
    required: "Pole jest wymagane",
    empty: "Pole nie powinno być puste",
    invalidExtension: "Rozszerzenie pliku jest nieważne",
    tooLarge: "Wielkość pliku nie może przekroczyć {{ maxSize }} MB",
    tooManyEntries: "Liczba pozycji jest większa niż {{ maxEntries }}",
    notParsable: "Plik nie jest przetwarzalny",
    fileNotAllowed: "Ten typ pliku jest niedozwolony"
  }
};
var errorHandlers$4 = {
  scriptFailedToLoad: "Załadowanie skryptu nie powiodło się.",
  refreshThePage: "Odśwież stronę."
};
var assistiveMessage$4 = {
  actionCancelled: "Akcja anulowana, nic nie zmieniono"
};
var passwordVisibility$4 = {
  showPassword: "Pokaż hasło",
  hidePassword: "Ukryj hasło"
};
var generalErrors$4 = {
  pageFailure: "Nie można załadować strony. Spróbuj ponownie później."
};
var formLegend$4 = {
  required: "Pola oznaczone znakiem * są wymagane."
};
var chatMessaging$4 = {
  charactersLeft: "pozostało znaków: {{count}}",
  addNewMessage: "Dodaj nową wiadomość",
  send: "Wyślij",
  uploadFile: "Załaduj plik",
  informationLabel: "{{author}}. {{text}} w {{date}}",
  messages: "Komunikaty",
  addMessagePlaceHolder: "Zacznij pisać…",
  characterLimitAlert: "Osiągnięto limit znaków.",
  a11y: {
    itemListBoxLabel: "Wybierz element, którego ma dotyczyć wiadomość."
  }
};
var common$9 = {
  common: common$8,
  pageMetaResolver: pageMetaResolver$4,
  spinner: spinner$4,
  navigation: navigation$4,
  searchBox: searchBox$4,
  sorting: sorting$4,
  httpHandlers: httpHandlers$4,
  miniCart: miniCart$4,
  skipLink: skipLink$4,
  carousel: carousel$4,
  formErrors: formErrors$4,
  errorHandlers: errorHandlers$4,
  assistiveMessage: assistiveMessage$4,
  passwordVisibility: passwordVisibility$4,
  generalErrors: generalErrors$4,
  formLegend: formLegend$4,
  chatMessaging: chatMessaging$4
};
var setDeliveryMode$4 = {
  unknownError: "Wystąpił nieznany błąd. Prosimy o kontakt z działem pomocy."
};
var deliveryMode$4 = {
  setDeliveryMode: setDeliveryMode$4
};
var myAccountV2Consent$8 = {
  header: "Zarządzanie zgodami",
  dateDescription: "Zatwierdzono na ",
  clearAll: "Wyłącz wszystko",
  selectAll: "Włącz wszystko",
  message: {
    success: {
      given: "Zgoda została udzielona.",
      withdrawn: "Zgoda została cofnięta."
    }
  }
};
var myAccountV2Consent$9 = {
  myAccountV2Consent: myAccountV2Consent$8
};
var myAccountV2NotificationPreference$8 = {
  header: "Kanały powiadomień",
  message: "Wybierz swoje kanały powiadomień",
  note: "Uwaga: ",
  noteMessage: "Jeżeli wyłączysz wszystkie kanały, nie otrzymasz kolejnych powiadomień.",
  EMAIL: "E-mail:"
};
var myAccountV2NotificationPreference$9 = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$8
};
var closeAccount$4 = {
  confirmAccountClosure: "Potwierdź zamknięcie konta",
  confirmAccountClosureMessage: "Czy na pewno chcesz zamknąć swoje konto?",
  closeMyAccount: "ZAMKNIJ MOJE KONTO",
  accountClosedSuccessfully: "Konto zamknięte",
  accountClosedFailure: "Zamknięcie konta zakończone niepowodzeniem"
};
var updatePasswordForm$4 = {
  oldPassword: {
    label: "Stare hasło",
    placeholder: "Stare hasło"
  },
  oldPasswordIsRequired: "Podanie starego hasła jest wymagane.",
  newPassword: {
    label: "Nowe hasło",
    placeholder: "Nowe hasło"
  },
  passwordMinRequirements: "Oto krótki przewodnik po bezpiecznym haśle: Wpisz co najmniej 8 znaków, używając wielkich i małych liter, cyfr i symboli.",
  passwordStrengthRequirements: "Oto krótki przewodnik po bezpiecznym haśle: Wpisz co najmniej 8 znaków, używając wielkich i małych liter, cyfr i symboli. Nie używaj tych samych znaków jeden po drugim.",
  confirmPassword: {
    label: "Potwierdź nowe hasło",
    placeholder: "Potwierdź hasło"
  },
  bothPasswordMustMatch: "Oba hasła muszą być zgodne",
  passwordUpdateSuccess: "Hasło pomyślnie zaktualizowane",
  accessDeniedError: "Odmowa dostępu"
};
var updateProfileForm$4 = {
  title: "Tytuł",
  none: "",
  firstName: {
    label: "Imię",
    placeholder: "Imię"
  },
  firstNameIsRequired: "Imię jest wymagane.",
  lastName: {
    label: "Nazwisko",
    placeholder: "Nazwisko"
  },
  lastNameIsRequired: "Nazwisko jest wymagane.",
  profileUpdateSuccess: "Dane osobowe pomyślnie zaktualizowane",
  customerId: "Klient #"
};
var consentManagementForm$4 = {
  clearAll: "Usuń wszystko",
  selectAll: "Zaznacz wszystko",
  message: {
    success: {
      given: "Zgoda została udzielona.",
      withdrawn: "Zgoda została cofnięta."
    }
  }
};
var myCoupons$4 = {
  noCouponsMessage: "Nie masz dostępnych kuponów.",
  effectiveTitle: "Ważne:",
  Effective: "WAŻNE",
  PreSession: "WKRÓTCE WAŻNE",
  ExpireSoon: "WKRÓTCE WYGASA",
  readMore: "Więcej",
  notification: "Powiadomienie",
  findProducts: "Znajdź produkty",
  status: "Status:",
  dialogTitle: "Kupon",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Kod kuponu",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Pomyślnie zrealizowałeś ten kupon.",
  myCoupons: "Moje kupony",
  startDateAsc: "Data rozpoczęcia (rosnąco)",
  startDateDesc: "Data rozpoczęcia (malejąco)",
  endDateAsc: "Data zakończenia (rosnąco)",
  endDateDesc: "Data zakończenia (malejąco)",
  sortBy: "Sortuj według",
  sortCoupons: "Sortuj kupony",
  notesPreffix: "Możesz ustawić swoje kanały powiadomień o kuponach na stronie ",
  notesLink: "Kanały powiadomień",
  notesSuffix: " ."
};
var notificationPreference$4 = {
  message: "Wybierz swoje kanały powiadomień",
  note: "Uwaga: ",
  noteMessage: "Jeżeli wyłączysz wszystkie kanały, nie otrzymasz kolejnych powiadomień.",
  EMAIL: "E-mail:",
  SMS: "SMS",
  SITE_MESSAGE: "SiteMessage"
};
var myInterests$4 = {
  header: "Moje zainteresowania",
  item: "POZYCJA",
  price: "CENA",
  notifications: "POWIADOMIENIA",
  noInterests: "Nie masz zapisanych zainteresowań.",
  inStock: "W magazynie",
  lowStock: "Niski poziom zapasów",
  outOfStock: "Zapas wyczerpany",
  BACK_IN_STOCK: "Ponownie dostępny",
  sortBy: "Sortuj według",
  sortInterests: "Sortuj zainteresowania",
  expirationDate: " - Do {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Usuń",
  sorting: {
    byNameAsc: "Nazwa (rosnąco)",
    byNameDesc: "Nazwa (malejąco)"
  },
  actions: "DZIAŁANIA",
  caption: "Moje zainteresowania.",
  itemRemoved: "Wybrana pozycja została usunięta."
};
var myAccount$4 = {
  closeAccount: closeAccount$4,
  updatePasswordForm: updatePasswordForm$4,
  updateProfileForm: updateProfileForm$4,
  consentManagementForm: consentManagementForm$4,
  myCoupons: myCoupons$4,
  notificationPreference: notificationPreference$4,
  myInterests: myInterests$4
};
var paymentForm$4 = {
  payment: "Płatność",
  choosePaymentMethod: "Wybierz formę płatności",
  paymentType: "Rodzaj płatności",
  accountHolderName: {
    label: "Nazwisko/nazwa posiadacza rachunku",
    placeholder: "Nazwisko/nazwa posiadacza rachunku"
  },
  cardNumber: "Numer karty",
  expirationDate: "Data wygaśnięcia",
  securityCode: "Kod CVV",
  securityCodeTitle: "Wartość kontrolna karty",
  saveAsDefault: "Zapisz jako domyślną",
  setAsDefault: "Domyślna forma płatności",
  billingAddress: "Adres na fakturze",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Taki sam jak adres dostawy",
  billingAddressSameAsShipping: "Adres na fakturze jest taki sam jak adres dostawy.",
  selectOne: "Wybierz jeden...",
  monthMask: "MM",
  yearMask: "RRRR",
  expirationYear: "Rok wygaśnięcia {{ selected }}",
  expirationMonth: "Miesiąc wygaśnięcia {{ selected }}",
  useThisPayment: "Użyj tej płatności",
  addNewPayment: "Dodaj nową płatność",
  paymentAddedSuccessfully: "Nowa płatność dodana pomyślnie",
  changePayment: "Zmień płatność"
};
var paymentMethods$4 = {
  paymentMethods: "Formy płatności",
  paymentMethodSelected: "Wybrana forma płatności",
  newPaymentMethodsAreAddedDuringCheckout: "Nowe formy płatności są dodawane podczas realizacji zakupu.",
  invalidField: "Niepoprawne pole: {{ field }}"
};
var paymentCard$4 = {
  deleteConfirmation: "Na pewno chcesz usunąć tę formę płatności?",
  setAsDefault: "Ustaw jako domyślne",
  expires: "Wygasa: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ DOMYŚLNE",
  defaultPaymentLabel: "Domyślna forma płatności",
  additionalPaymentLabel: "Dodatkowa forma płatności {{ number }}",
  selected: "Wybrane",
  selectedPayment: "Wybrana płatność",
  deletePaymentSuccess: "Forma płatności usunięta pomyślnie",
  visa: "Karta Visa",
  master: "Karta Mastercard",
  masterEuro: "Mastercard Eurocard",
  dinersClub: "Karta Diners Club",
  "switch": "Zmień kartę",
  maestro: "Karta Maestro",
  amex: "Karta American Express",
  credit: "Karta kredytowa"
};
var paymentTypes$4 = {
  title: "Forma płatności",
  paymentType_CARD: "Karta kredytowa",
  paymentType_ACCOUNT: "Konto"
};
var paymentMessages$4 = {
  setAsDefaultSuccessfully: "Nowa płatność została ustawiona jako domyślna"
};
var payment$4 = {
  paymentForm: paymentForm$4,
  paymentMethods: paymentMethods$4,
  paymentCard: paymentCard$4,
  paymentTypes: paymentTypes$4,
  paymentMessages: paymentMessages$4
};
var pdf$8 = {
  defaultTitle: "Dokument"
};
var pdf$9 = {
  pdf: pdf$8
};
var productDetails$4 = {
  id: "ID",
  quantity: "Ilość",
  quantityFull: "Ilość",
  productDetails: "Szczegóły produktu",
  specification: "Specyfikacja",
  reviews: "Recenzje",
  shipping: "Wysyłka",
  share: "Udostępnij",
  showReviews: "Pokaż recenzje",
  noReviews: "Nie wystawiono jeszcze opinii",
  productPrice: "Cena produktu",
  noProductImage: "Brak dostępnych obrazów, {{ product }}"
};
var productList$4 = {
  filterBy: {
    label: "Filtruj wg",
    action: "Filtruj wg"
  },
  activeFilter: "Filtr {{filter}}. Kliknięcie tego przycisku spowoduje usunięcie filtra",
  appliedFilter: "Zastosowane filtry:",
  showLess: "Pokaż mniej...",
  showMore: "Pokaż więcej...",
  sortBy: "Sortuj według",
  sortResults: "Sortuj wyniki",
  backToTopBtn: "Do początku strony",
  showMoreBtn: "Pokaż więcej...",
  productSearchPagination: "Strony wyszukiwania produktu",
  productListResults: "Lista wyszukanych produktów"
};
var productFacetNavigation$4 = {
  filterBy: {
    label: "Filtruj wg",
    action: "Filtruj wg",
    facet: "Filtruj wyniki wg właściwości",
    name: "Filtruj wg {{name}}"
  },
  appliedFilter: "Zastosowane filtry:",
  showLess: "Pokaż mniej...",
  showMore: "Pokaż więcej...",
  sortBy: "Sortuj według",
  ariaLabelShowLess: "Przycisk Pokaż mniej. Kliknięcie tego przycisku zredukuje liczbę opcji dla aktywnej grupy.",
  ariaLabelShowMore: "Przycisk Pokaż więcej. Kliknięcie tego przycisku spowoduje wyświetlenie wszystkich opcji dla aktywnej grupy.",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} pozycja dostępna",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} pozycje dostępne",
  decreaseOptionsVisibility: "Opcje zostały ukryte przed aktywną grupą. Wróć, aby je przeczytać lub idź dalej, do następnej grupy.",
  increaseOptionsVisibility: "Opcje zostały dodane do aktywnej grupy. Wróć, aby je przeczytać lub idź dalej, do następnej grupy.",
  backToResults: "Powrót do wyników",
  productFacets: "Właściwości produktu"
};
var productSummary$4 = {
  id: "ID",
  showReviews: "Pokaż recenzje",
  showReviewsDetailed: "Pokaż {{count}} recenzji, oceny {{rating}} na 5 gwiazdek",
  share: "Udostępnij",
  newItemPrice: "Cena nowej pozycji"
};
var productReview$4 = {
  overallRating: "Ocena ogólna",
  reviewTitle: "Tytuł recenzji",
  writeYourComments: "Napisz swoje komentarze",
  rating: "Ocena",
  ratingRequired: "Ocena produktu, wymagana",
  addRate: "Oceń: {{count}} gwiazdka",
  addRate_other: "Oceń: {{count}} gwiazdki",
  reviewerName: "Imię recenzenta (opcjonalne)",
  writeReview: "Napisz recenzję",
  more: "Pokaż więcej recenzji",
  less: "Pokaż mniej recenzji",
  thankYouForReview: "Dziękujemy za recenzję! Recenzje mogą wymagać weryfikacji przed pojawieniem się tutaj.",
  postReviewFail: "Coś poszło nie tak podczas dodawania recenzji. Spróbuj ponownie później.",
  ratedOutOf: "Oceny: {{rating}} na 5"
};
var productCarousel$4 = {
  carouselLabel: "Karuzela, {{title}}"
};
var addToCart$4 = {
  itemAddedToYourCart: "Pozycja dodana do koszyka",
  itemsAddedToYourCart: "Pozycje dodane do koszyka",
  itemsIncrementedInYourCart: "Ten produkt był już w koszyku. Ilość została zaktualizowana.",
  items: "pozycje",
  updatingCart: "Aktualizacja koszyka...",
  addToCart: "Dodaj do koszyka",
  viewCart: "zobacz koszyk",
  proceedToCheckout: "Przejdź do realizacji zakupu",
  quantity: "Ilość",
  quantityFull: "Ilość",
  outOfStock: "Zapas wyczerpany",
  inStock: "W magazynie",
  selectStyleAndSize: "Wybierz styl i rozmiar, aby sprawdzić dostępność",
  removeFromCart: "Usuń produkt z koszyka",
  closeModal: "Zamknij okno",
  buyItAgain: "Kup ponownie",
  addToActiveCart: "Dodaj do koszyka"
};
var TabPanelContainer$4 = {
  tabs: {
    ProductDetailsTabComponent: "Szczegóły produktu",
    ProductSpecsTabComponent: "Specyfikacja",
    ProductReviewsTabComponent: "Recenzje",
    deliveryTab: "Wysyłka",
    SparePartsTabComponent: " Części zamienne"
  },
  tabPanelContainerRegion: "Grupa kart z dodatkowymi informacjami o produkcie",
  tabPanelContainerRegionGroup: "Grupa z dodatkowymi informacjami o produkcie"
};
var addToWishList$4 = {
  add: "Dodaj do listy życzeń",
  remove: "Usuń z listy życzeń",
  anonymous: "Zaloguj się, by dodać do listy życzeń",
  addedToWishList: "Produkt dodany do listy życzeń",
  removedFromWishList: "Produkt usunięty z listy życzeń"
};
var stockNotification$4 = {
  notifyMe: "Powiadom mnie",
  stopNotify: "Powiadomienie o zatrzymaniu",
  getNotify: "Otrzymaj powiadomienie, gdy ten produkt będzie dostępny.",
  getNotifySuffix: "aby otrzymać powiadomienie, że ten produkt jest dostępny.",
  activateChannelsPrefix: "Aby otrzymywać powiadomienia, musisz aktywować ",
  channelsLink: "Kanały powiadamiania",
  activateChannelsSuffix: ".",
  notified: "Otrzymasz powiadomienie, gdy ten produkt będzie ponownie dostępny.",
  getNotified: "Otrzymaj powiadomienie, gdy ten produkt będzie ponownie dostępny.",
  unsubscribeSuccess: "Nie otrzymasz powiadomienia, gdy ten produkt będzie ponownie dostępny.",
  subscriptionDialog: {
    header: "Subskrypcja stanów magazynowych",
    notifiedPrefix: "Otrzymasz powiadomienie:",
    notifiedSuffix: "tak szybko, jak ten produkt będzie ponownie dostępny.",
    manageChannelsPrefix: "Zarządzaj swoimi kanałami powiadomień na stronie ",
    manageChannelsLink: "Preferencje dot. powiadomienia",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "Możesz zarządzać swoimi subskrypcjami na stronie ",
    manageSubscriptionsLink: "Moje zainteresowania",
    manageSubscriptionsSuffix: " .",
    okBtn: "OK",
    subscribing: "Subskrybujesz powiadomienia o stanie magazynowym tego produktu"
  }
};
var itemCounter$4 = {
  removeOne: "Usuń jeden",
  addOneMore: "Dodaj jeden",
  quantity: "Ilość"
};
var productView$4 = {
  gridView: "Wybierz widok siatki",
  listView: "Wybierz widok listy"
};
var product$4 = {
  productDetails: productDetails$4,
  productList: productList$4,
  productFacetNavigation: productFacetNavigation$4,
  productSummary: productSummary$4,
  productReview: productReview$4,
  productCarousel: productCarousel$4,
  addToCart: addToCart$4,
  TabPanelContainer: TabPanelContainer$4,
  addToWishList: addToWishList$4,
  stockNotification: stockNotification$4,
  itemCounter: itemCounter$4,
  productView: productView$4
};
var pwa$8 = {
  addToHomeScreenDescription: "Dodaj witrynę sklepu SAP do ekranu głównego swojego urządzenia, aby szybciej wracać do sklepu",
  noInstallationNeeded: "Bez potrzeby instalacji",
  fastAccessToApplication: "Szybki dostęp do aplikacji",
  addToHomeScreen: "Dodaj do ekranu głównego",
  addedToHomeScreen: "Dodano SAP Storefront do ekranu głównego"
};
var pwa$9 = {
  pwa: pwa$8
};
var siteThemeSwitcher$8 = {
  theme: "Motyw",
  themes: {
    "default": "Domyślne",
    highContrastDark: "HC-Ciemny",
    highContrastLight: "HC-Jasny"
  }
};
var siteThemeSwitcher$9 = {
  siteThemeSwitcher: siteThemeSwitcher$8
};
var anonymousConsents$4 = {
  dialog: {
    title: "Zarządzanie zgodami",
    legalDescription: "Używamy plików cookie/pamięci przeglądarki do personalizacji treści i poprawy komfortu użytkowania. Możemy również udostępniać dane o korzystaniu z witryny naszym mediom społecznościowym. Więcej informacji można znaleźć w naszej polityce prywatności.",
    selectAll: "Zaznacz wszystko",
    clearAll: "Wyczyść wszystko"
  },
  banner: {
    title: "Ta strona używa cookies",
    description: "Używamy plików cookie/pamięci przeglądarki do personalizacji treści i poprawy komfortu użytkowania.",
    allowAll: "Zgoda na wszystko",
    viewDetails: "Wyświetl szczegóły",
    consentManagement: "Zarządzanie zgodami"
  }
};
var checkoutLogin$4 = {
  emailAddress: {
    label: "Adres e-mail",
    placeholder: "Wpisz adres e-mail"
  },
  confirmEmail: {
    label: "Potwierdź adres e-mail",
    placeholder: "Potwierdź adres e-mail"
  },
  "continue": "Kontynuuj",
  emailIsRequired: "Nieprawidłowy format e-mail",
  emailsMustMatch: "E-mail jest nieprawidłowy"
};
var authMessages$4 = {
  signedOutSuccessfully: "Wylogowanie powiodło się."
};
var user$4 = {
  anonymousConsents: anonymousConsents$4,
  checkoutLogin: checkoutLogin$4,
  authMessages: authMessages$4
};
var player$4 = {
  label: "Odtwarzacz wideo"
};
var video$4 = {
  player: player$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  common: common$9,
  deliveryMode: deliveryMode$4,
  myAccountV2Consent: myAccountV2Consent$9,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$9,
  myAccount: myAccount$4,
  payment: payment$4,
  pdf: pdf$9,
  product: product$4,
  pwa: pwa$9,
  siteThemeSwitcher: siteThemeSwitcher$9,
  user: user$4,
  video: video$4
};
var common$6 = {
  cancel: "Cancelar",
  "delete": "Eliminar",
  remove: "Remover",
  edit: "Editar",
  restore: "Restaurar",
  back: "Voltar",
  submit: "Enviar",
  "continue": "Continuar",
  save: "Gravar",
  done: "Concluído",
  home: "Página inicial",
  noData: "Sem dados.",
  confirm: "Confirmar",
  more: "mais",
  close: "Fechar",
  loading: "Carregando",
  menu: "Menu",
  reset: "Redefinir",
  search: "Pesquisa",
  add: "Adicionar",
  breadcrumbs: "breadcrumbs",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Selecionar arquivo",
  clear: "Limpar",
  loaded: "Carregado",
  results: "Resultados",
  of: "de",
  ngSelectDropdownOptionsList: "Opções",
  required: "obrigatório",
  zoomIn: "Ampliar",
  zoomOut: "Reduzir",
  selected: "Selecionado",
  expand: "Expandir",
  collapse: "Comprimir"
};
var pageMetaResolver$3 = {
  category: {
    title: "{{count}} resultado para {{query}}",
    title_other: "{{count}} resultados para {{query}}"
  },
  checkout: {
    title: "Fazer check-out"
  },
  search: {
    title: "{{count}} resultado para \"{{query}}\"",
    title_other: "{{count}} resultados para \"{{query}}\"",
    findProductTitle: "{{count}} resultado para cupom \"{{coupon}}\"",
    findProductTitle_other: "{{count}} resultados para cupom \"{{coupon}}\"",
    default_title: "Todos os produtos"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$3 = {
  loading: "Carregando..."
};
var navigation$3 = {
  categoryNavLabel: "Menu de categoria",
  footerNavLabel: "Links do rodapé",
  goTo: "Ir para {{location}}",
  navigateTo: "Navegar para {{nav}}",
  scrollToTop: "Navegue para trás para o topo da página",
  linkItemInList: "{{title}}. {{position}} de {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$3 = {
  placeholder: "Inserir nome do produto ou SKU",
  productSearch: "Encontrar um produto",
  ariaLabelInput: "Pesquisar aqui...",
  ariaLabelSuggestions: "digitando sugestões",
  ariaLabelProducts: "resultados do produto",
  initialDescription: "Quando os resultados de preenchimento automático estiverem disponíveis, utilize as setas para cima e para baixo para revisar e Enter para selecionar. Utilizadores de dispositivos de toque, explore por toque ou com gestos de passar o dedo.",
  suggestions: "Palavras-chave sugeridas",
  recentSearches: "Pesquisas recentes",
  trendingSearches: "Pesquisas de tendência",
  suggestionsResult: "{{ count }} sugestão e ",
  suggestionsResult_other: "{{ count }} sugestões e ",
  products: "Produtos recomendados",
  productsResult: "{{ count }} produto está disponível.",
  productsResult_other: "{{ count }} produtos estão disponíveis.",
  resetLabel: "Redefinir pesquisa",
  help: {
    insufficientChars: "Digite mais caracteres",
    noMatch: "Não conseguimos encontrar nenhum resultado",
    exactMatch: "{{ term }}",
    empty: "Pergunte-nos qualquer coisa"
  },
  closeSearchPanel: "Fechar"
};
var sorting$3 = {
  date: "Data",
  orderNumber: "Número do pedido",
  rma: "Número da devolução",
  replenishmentNumber: "Número de reabastecimento",
  nextOrderDate: "Data do próximo pedido",
  pageViewUpdated: "Visão de página atualizada com suas opções selecionadas."
};
var httpHandlers$3 = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Inicie sessão novamente.",
    user_is_disabled: "{{ errorMessage }}. Contate a administração."
  },
  badGateway: "Ocorreu um erro do servidor. Tente novamente mais tarde.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Inicie sessão novamente.",
  badRequestOldPasswordIncorrect: "Senha antiga incorreta.",
  badRequestGuestDuplicateEmail: "O e-mail {{ errorMessage }} já existe. Faça o check-out com um e-mail diferente para se registrar utilizando uma conta de convidado.",
  conflict: "Já existe.",
  forbidden: "Você não tem autorização para realizar esta ação. Contate seu administrador se acha que é um engano.",
  gatewayTimeout: "O servidor não respondeu. Tente novamente mais tarde.",
  internalServerError: "Ocorreu um erro no servidor interno. Tente novamente mais tarde.",
  sessionExpired: "Sua sessão expirou. Faça login novamente.",
  unknownError: "Ocorreu um erro desconhecido",
  unauthorized: {
    common: "Ocorreu um erro de autorização desconhecido",
    invalid_client: "Credenciais de cliente inválidas"
  },
  validationErrors: {
    missing: {
      card_cardType: "O cartão de crédito selecionado não é suportado. Selecione outro.",
      card_accountNumber: "O número do cartão de crédito inserido não é válido.",
      card_cvNumber: "O código de segurança inserido não é válido.",
      card_expirationMonth: "A data de validade do cartão de crédito inserido não é válida.",
      card_expirationYear: "A data de validade do cartão de crédito inserido não é válida.",
      billTo_firstName: "O nome inserido não é válido.",
      billTo_lastName: "O sobrenome inserido não é válido.",
      billTo_street1: "O endereço inserido não é válido.",
      billTo_street2: "O endereço inserido não é válido.",
      billTo_city: "A cidade inserida não é válida para este cartão de crédito.",
      billTo_state: "O estado/província inserido não é válido para este cartão de crédito.",
      billTo_country: "O país/região inserido não é válido para este cartão de crédito.",
      billTo_postalCode: "O código postal inserido não é válido para este cartão de crédito.",
      country: {
        isocode: "País/região em falta"
      }
    },
    invalid: {
      card_expirationMonth: "A data de validade do cartão de crédito inserido não é válido.",
      firstName: "O nome inserido não é válido.",
      lastName: "O sobrenome inserido não é válido.",
      password: "A senha inserida não é válida.",
      uid: "O UID não é válido.",
      code: "O código não é válido.",
      email: "E-mail não é válido.",
      loginId: "E-mail não é válido."
    }
  },
  cartNotFound: "Carrinho não encontrado.",
  invalidCodeProvided: "Código fornecido inválido.",
  voucherExceeded: "Este cupom excedeu o número de vezes que pode ser utilizado",
  unknownIdentifier: "Item não encontrado.",
  otherCartErrors: "Ocorreram erros no carrinho."
};
var miniCart$3 = {
  item: "{{count}} item atualmente no seu carrinho",
  item_other: "{{count}} itens atualmente no seu carrinho",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$3 = {
  skipTo: "Pular para",
  labels: {
    header: "Cabeçalho",
    main: "Conteúdo principal",
    footer: "Rodapé",
    productFacets: "Facetas de produto",
    productList: "Lista de produtos"
  }
};
var carousel$3 = {
  previousSlide: "Slide anterior",
  nextSlide: "Slide seguinte",
  slideNumber: "Slide {{currentSlideNumber}} contém {{numberOfSlides}} itens",
  carouselForProduct: "Carrossel, imagens para {{product}}"
};
var formErrors$3 = {
  labeled: {
    required: "O campo {{label}} é obrigatório",
    cxInvalidEmail: "O campo {{label}} não tem um formato de e-mail válido. Padrão de correspondência: example@yourdomain.com",
    cxInvalidPassword: "O campo {{label}} não tem um formato de senha válido",
    cxMinOneUpperCaseCharacter: "A senha deve conter pelo menos um caractere maiúsculo",
    cxMinOneDigit: "A senha deve conter pelo menos um dígito",
    cxMinOneSpecialCharacter: "A senha deve conter pelo menos um caractere especial",
    cxMinSixCharactersLength: "A senha deve conter pelo menos 6 caracteres",
    cxMinEightCharactersLength: "A senha deve conter pelo menos 8 caracteres",
    cxMaxCharactersLength: "A senha não pode ter mais de 128 caracteres",
    cxContainsSpecialCharacters: "A senha não pode conter caracteres especiais",
    cxNoConsecutiveCharacters: "A senha não pode conter caracteres idênticos consecutivos",
    date: {
      required: "O campo {{label}} é obrigatório",
      min: "O campo {{label}} não pode estar antes de {{min}}",
      max: "O campo {{label}} não pode estar depois de {{max}}",
      pattern: "Utilizar formato de data aaaa-mm-dd no campo {{label}}",
      invalid: "Utilizar uma data válida no campo {{label}}"
    }
  },
  globalMessage: "O formulário que você está tentando enviar contém erros.",
  required: "Este campo é obrigatório",
  cxInvalidEmail: "Este não é um formato de e-mail válido.",
  cxInvalidPassword: "Este não é um formato de senha válido.",
  cxPasswordsMustMatch: "Os campos da senha devem corresponder",
  cxPasswordsCannotMatch: "Os campos da senha não podem corresponder",
  cxEmailsMustMatch: "Os campos do e-mail devem corresponder",
  cxStarRatingEmpty: "O campo de avaliação é obrigatório",
  cxNoSelectedItemToCancel: "Selecione pelo menos um item",
  cxNegativeAmount: "O montante deve ser igual ou superior a zero",
  cxContainsSpecialCharacters: "O campo não pode conter caracteres especiais",
  date: {
    required: "Este campo é obrigatório",
    min: "A data não pode ser anterior a {{min}}",
    max: "A data não pode ser posterior a {{max}}",
    pattern: "Utilizar formato de data aaaa-mm-dd",
    invalid: "Utilizar uma data válida"
  },
  file: {
    required: "O arquivo é obrigatório",
    empty: "O arquivo não deve estar vazio",
    invalidExtension: "A extensão do arquivo não é válida",
    tooLarge: "O tamanho do arquivo não deve exceder {{ maxSize }} MB",
    tooManyEntries: "O número de itens é superior a {{ maxEntries }}",
    notParsable: "O arquivo não é analisável",
    fileNotAllowed: "Este tipo de arquivo não é permitido"
  }
};
var errorHandlers$3 = {
  scriptFailedToLoad: "Falha ao carregar o script.",
  refreshThePage: "Atualize a página."
};
var assistiveMessage$3 = {
  actionCancelled: "Ação cancelada, nada mudou"
};
var passwordVisibility$3 = {
  showPassword: "Mostrar senha",
  hidePassword: "Ocultar senha"
};
var generalErrors$3 = {
  pageFailure: "Não foi possível carregar a página. Tente novamente mais tarde."
};
var formLegend$3 = {
  required: "Os seguintes campos marcados com \"*\" são obrigatórios."
};
var chatMessaging$3 = {
  charactersLeft: "caracteres restantes: {{count}}",
  addNewMessage: "Adicionar nova mensagem",
  send: "Enviar",
  uploadFile: "Carregar arquivo",
  informationLabel: "{{author}}. {{text}} em {{date}}",
  messages: "Mensagens",
  addMessagePlaceHolder: "Comece a digitar...",
  characterLimitAlert: "Limite de caracteres atingido.",
  a11y: {
    itemListBoxLabel: "Selecione o item ao qual se deve aplicar a mensagem."
  }
};
var common$7 = {
  common: common$6,
  pageMetaResolver: pageMetaResolver$3,
  spinner: spinner$3,
  navigation: navigation$3,
  searchBox: searchBox$3,
  sorting: sorting$3,
  httpHandlers: httpHandlers$3,
  miniCart: miniCart$3,
  skipLink: skipLink$3,
  carousel: carousel$3,
  formErrors: formErrors$3,
  errorHandlers: errorHandlers$3,
  assistiveMessage: assistiveMessage$3,
  passwordVisibility: passwordVisibility$3,
  generalErrors: generalErrors$3,
  formLegend: formLegend$3,
  chatMessaging: chatMessaging$3
};
var setDeliveryMode$3 = {
  unknownError: "Ocorreu um erro desconhecido. Contate o suporte"
};
var deliveryMode$3 = {
  setDeliveryMode: setDeliveryMode$3
};
var myAccountV2Consent$6 = {
  header: "Gerenciamento de consentimentos",
  dateDescription: "Aprovado em ",
  clearAll: "Desativar tudo",
  selectAll: "Ativar tudo",
  message: {
    success: {
      given: "Consentimento dado com êxito.",
      withdrawn: "Consentimento retirado com êxito."
    }
  }
};
var myAccountV2Consent$7 = {
  myAccountV2Consent: myAccountV2Consent$6
};
var myAccountV2NotificationPreference$6 = {
  header: "Canais de notificação",
  message: "Selecione seus canais de notificação preferidos",
  note: "Nota: ",
  noteMessage: "Se você desativar todos os canais, não poderá receber mais notificações.",
  EMAIL: "E-mail:"
};
var myAccountV2NotificationPreference$7 = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$6
};
var closeAccount$3 = {
  confirmAccountClosure: "Confirmar fechamento de conta",
  confirmAccountClosureMessage: "Tem certeza de que quer fechar sua conta?",
  closeMyAccount: "FECHAR MINHA CONTA",
  accountClosedSuccessfully: "Conta fechada com êxito",
  accountClosedFailure: "Falha ao fechar conta"
};
var updatePasswordForm$3 = {
  oldPassword: {
    label: "Senha antiga",
    placeholder: "Senha antiga"
  },
  oldPasswordIsRequired: "A senha antiga é obrigatória.",
  newPassword: {
    label: "Nova senha",
    placeholder: "Nova senha"
  },
  passwordMinRequirements: "Aqui está um guia rápido para uma senha segura: utilize pelo menos 8 caracteres com uma mistura de letras maiúsculas e minúsculas, números e símbolos.",
  passwordStrengthRequirements: "Aqui está um guia rápido para uma senha segura: Use pelo menos 8 caracteres com uma combinação de letras maiúsculas e minúsculas, números e símbolos.",
  confirmPassword: {
    label: "Confirmar nova senha",
    placeholder: "Confirmar senha"
  },
  bothPasswordMustMatch: "As duas senhas devem corresponder",
  passwordUpdateSuccess: "Senha atualizada com êxito",
  accessDeniedError: "O acesso foi negado"
};
var updateProfileForm$3 = {
  title: "Título",
  none: "",
  firstName: {
    label: "Nome",
    placeholder: "Nome"
  },
  firstNameIsRequired: "O nome é obrigatório.",
  lastName: {
    label: "Sobrenome",
    placeholder: "Sobrenome"
  },
  lastNameIsRequired: "O sobrenome é obrigatório.",
  profileUpdateSuccess: "Detalhes pessoais atualizados com êxito",
  customerId: "Nº do cliente"
};
var consentManagementForm$3 = {
  clearAll: "Limpar tudo",
  selectAll: "Selecionar tudo",
  message: {
    success: {
      given: "Consentimento dado com êxito.",
      withdrawn: "Consentimento retirado com êxito."
    }
  }
};
var myCoupons$3 = {
  noCouponsMessage: "Você não tem cupons disponíveis.",
  effectiveTitle: "Efetivo:",
  Effective: "EFETIVO",
  PreSession: "EFETIVO EM BREVE",
  ExpireSoon: "EXPIRANDO EM BREVE",
  readMore: "Ler mais",
  notification: "Notificação",
  findProducts: "Encontrar produtos",
  status: "Status:",
  dialogTitle: "Cupom",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Código de cupom",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Você reivindicou este cupom com êxito.",
  myCoupons: "Meus cupons",
  startDateAsc: "Data inicial (em ordem crescente)",
  startDateDesc: "Data inicial (em ordem decrescente)",
  endDateAsc: "Data final (em ordem crescente)",
  endDateDesc: "Data final (em ordem decrescente)",
  sortBy: "Ordenar por",
  sortCoupons: "Ordenar cupons",
  notesPreffix: "Você pode definir seus canais preferidos para receber notificações de cupom na página ",
  notesLink: "Canais de notificação",
  notesSuffix: " ."
};
var notificationPreference$3 = {
  message: "Selecione seus canais de notificação preferidos",
  note: "Nota: ",
  noteMessage: "Se você desativar todos os canais, não poderá receber mais notificações.",
  EMAIL: "E-mail:",
  SMS: "SMS:",
  SITE_MESSAGE: "SiteMessage"
};
var myInterests$3 = {
  header: "Meus interesses",
  item: "ITEM",
  price: "PREÇO",
  notifications: "NOTIFICAÇÕES",
  noInterests: "Você ainda não tem interesses registrados.",
  inStock: "Em estoque",
  lowStock: "Estoque baixo",
  outOfStock: "Sem estoque",
  BACK_IN_STOCK: "De novo em estoque",
  sortBy: "Ordenar por",
  sortInterests: "Ordenar interesses",
  expirationDate: " - Até {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "Remover",
  sorting: {
    byNameAsc: "Nome (em ordem crescente)",
    byNameDesc: "Nome (em ordem decrescente)"
  },
  actions: "AÇÕES",
  caption: "Conteúdo de Meus interesses.",
  itemRemoved: "O item selecionado foi removido."
};
var myAccount$3 = {
  closeAccount: closeAccount$3,
  updatePasswordForm: updatePasswordForm$3,
  updateProfileForm: updateProfileForm$3,
  consentManagementForm: consentManagementForm$3,
  myCoupons: myCoupons$3,
  notificationPreference: notificationPreference$3,
  myInterests: myInterests$3
};
var paymentForm$3 = {
  payment: "Pagamento",
  choosePaymentMethod: "Selecionar uma forma de pagamento",
  paymentType: "Tipo de pagamento",
  accountHolderName: {
    label: "Nome do titular da conta",
    placeholder: "Nome do titular da conta"
  },
  cardNumber: "Número do cartão",
  expirationDate: "Data de expiração",
  securityCode: "Código de segurança (CVV)",
  securityCodeTitle: "Valor de verificação de cartão",
  saveAsDefault: "Gravar como padrão",
  setAsDefault: "Definir como forma de pagamento padrão",
  billingAddress: "Endereço de faturamento",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Igual ao endereço de entrega",
  billingAddressSameAsShipping: "O endereço de faturamento é igual ao endereço de entrega",
  selectOne: "Selecionar um...",
  monthMask: "MM",
  yearMask: "AAAA",
  expirationYear: "Ano da expiração {{ selected }}",
  expirationMonth: "Mês da expiração {{ selected }}",
  useThisPayment: "Utilizar este pagamento",
  addNewPayment: "Adicionar novo pagamento",
  paymentAddedSuccessfully: "O novo pagamento foi adicionado com êxito",
  changePayment: "Modificar pagamento"
};
var paymentMethods$3 = {
  paymentMethods: "Formas de pagamento",
  paymentMethodSelected: "Forma de pagamento selecionada",
  newPaymentMethodsAreAddedDuringCheckout: "As novas formas de pagamento são adicionadas durante o check-out.",
  invalidField: "Campo inválido: {{ field }}"
};
var paymentCard$3 = {
  deleteConfirmation: "Tem certeza de que quer eliminar esta forma de pagamento?",
  setAsDefault: "Definir como padrão",
  expires: "Expira: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ PADRÃO",
  defaultPaymentLabel: "Forma de pagamento padrão",
  additionalPaymentLabel: "Forma de pagamento adicional {{ number }}",
  selected: "Selecionado",
  selectedPayment: "Pagamento selecionado",
  deletePaymentSuccess: "Forma de pagamento eliminada com êxito",
  visa: "Cartão Visa",
  master: "Cartão MasterCard",
  masterEuro: "Cartão MasterCard Eurocard",
  dinersClub: "Cartão Diners Club",
  "switch": "Mudar cartão",
  maestro: "Cartão Maestro",
  amex: "Cartão American Express",
  credit: "Cartão de crédito"
};
var paymentTypes$3 = {
  title: "Forma de pagamento",
  paymentType_CARD: "Cartão de crédito",
  paymentType_ACCOUNT: "Conta"
};
var paymentMessages$3 = {
  setAsDefaultSuccessfully: "O novo pagamento foi definido como padrão com êxito"
};
var payment$3 = {
  paymentForm: paymentForm$3,
  paymentMethods: paymentMethods$3,
  paymentCard: paymentCard$3,
  paymentTypes: paymentTypes$3,
  paymentMessages: paymentMessages$3
};
var pdf$6 = {
  defaultTitle: "Documento"
};
var pdf$7 = {
  pdf: pdf$6
};
var productDetails$3 = {
  id: "ID",
  quantity: "Qtd",
  quantityFull: "Quantidade",
  productDetails: "Detalhes do produto",
  specification: "Especificações",
  reviews: "Revisões",
  shipping: "Entrega",
  share: "Compartilhar",
  showReviews: "Mostrar revisões",
  noReviews: "Ainda não existem revisões",
  productPrice: "Preço do produto",
  noProductImage: "Nenhuma imagem disponível, {{ product }}"
};
var productList$3 = {
  filterBy: {
    label: "Filtrar por",
    action: "Filtrar por"
  },
  activeFilter: "Filtro {{filter}}, clicar nesse botão removerá o filtro",
  appliedFilter: "Filtro aplicado:",
  showLess: "Mostrar menos...",
  showMore: "Mostrar mais...",
  sortBy: "Ordenar por",
  sortResults: "Ordenar resultados",
  backToTopBtn: "VOLTAR AO INÍCIO",
  showMoreBtn: "MOSTRAR MAIS",
  productSearchPagination: "Paginação de pesquisa de produto",
  productListResults: "Lista de resultados do produto"
};
var productFacetNavigation$3 = {
  filterBy: {
    label: "Filtrar por",
    action: "Filtrar por",
    facet: "Filtrar resultados por facetas",
    name: "Filtrar por {{name}}"
  },
  appliedFilter: "Filtro aplicado:",
  showLess: "Mostrar menos...",
  showMore: "Mostrar mais...",
  sortBy: "Ordenar por",
  ariaLabelShowLess: "Botão Mostrar menos, clicar nesse botão reduzirá as opções para o grupo ativo",
  ariaLabelShowMore: "Botão Mostrar mais, clicar nesse botão mostrará todas as opções para o grupo ativo",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} item disponível",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} itens disponíveis",
  decreaseOptionsVisibility: "As opções foram ocultadas do grupo ativo, retroceda para lê-las ou avance para o próximo grupo",
  increaseOptionsVisibility: "Mais opções foram adicionadas ao grupo ativo, retroceda para lê-las ou avance para o próximo grupo",
  backToResults: "Voltar para os resultados",
  productFacets: "Facetas de produto"
};
var productSummary$3 = {
  id: "ID",
  showReviews: "Mostrar revisões",
  showReviewsDetailed: "Mostrar {{count}} revisões, classificadas {{rating}} de 5 estrelas",
  share: "Compartilhar",
  newItemPrice: "Novo preço do item"
};
var productReview$3 = {
  overallRating: "Classificação geral",
  reviewTitle: "Título da revisão",
  writeYourComments: "Escreva seus comentários",
  rating: "Classificação",
  ratingRequired: "Classificação do produto, obrigatória",
  addRate: "Adicionar classificação: {{count}} estrela",
  addRate_other: "Adicionar classificação: {{count}} estrelas",
  reviewerName: "Nome do revisor (opcional)",
  writeReview: "Escrever uma revisão",
  more: "Mostrar mais revisões",
  less: "Mostrar menos revisões",
  thankYouForReview: "Obrigado pela revisão! Considere que as revisões podem exigir uma revisão antes de serem exibidas aqui.",
  postReviewFail: "Algo deu errado ao lançar sua revisão. Tente novamente mais tarde.",
  ratedOutOf: "Classificado: {{rating}} de 5"
};
var productCarousel$3 = {
  carouselLabel: "Carrossel, {{title}}"
};
var addToCart$3 = {
  itemAddedToYourCart: "Item adicionado ao seu carrinho",
  itemsAddedToYourCart: "Itens adicionados ao seu carrinho",
  itemsIncrementedInYourCart: "Esse item já estava em seu carrinho. A quantidade foi atualizada.",
  items: "itens",
  updatingCart: "Atualizando o carrinho...",
  addToCart: "Adicionar ao carrinho",
  viewCart: "visualizar carrinho",
  proceedToCheckout: "prosseguir para o check-out",
  quantity: "Qtd",
  quantityFull: "Quantidade",
  outOfStock: "Sem estoque",
  inStock: "Em estoque",
  selectStyleAndSize: "Selecione o estilo e o tamanho para verificar o estoque",
  removeFromCart: "Remover produto do carrinho",
  closeModal: "Fechar modal",
  buyItAgain: "Comprar novamente",
  addToActiveCart: "Adicionar ao carrinho ativo"
};
var TabPanelContainer$3 = {
  tabs: {
    ProductDetailsTabComponent: "Detalhes do produto",
    ProductSpecsTabComponent: "Especificações",
    ProductReviewsTabComponent: "Revisões",
    deliveryTab: "Entrega",
    SparePartsTabComponent: " Peças de reposição"
  },
  tabPanelContainerRegion: "Grupo de fichas com mais detalhes do produto",
  tabPanelContainerRegionGroup: "Agrupar com mais detalhes do produto"
};
var addToWishList$3 = {
  add: "Adicionar à lista de desejos",
  remove: "Remover da lista de desejos",
  anonymous: "Inicie sessão para adicionar à lista de desejos",
  addedToWishList: "Produto adicionado à lista de desejos",
  removedFromWishList: "Produto removido da lista de desejos"
};
var stockNotification$3 = {
  notifyMe: "NOTIFICAR-ME",
  stopNotify: "PARAR NOTIFICAÇÃO",
  getNotify: "Seja notificado quando este produto estiver disponível.",
  getNotifySuffix: "para ser notificado quando este produto estiver disponível.",
  activateChannelsPrefix: "Para ser notificado, você precisa ativar os ",
  channelsLink: "Canais de notificação",
  activateChannelsSuffix: ".",
  notified: "Você será notificado quando esse produto estiver de novo em estoque.",
  getNotified: "Seja notificado quando esse produto estiver de novo em estoque.",
  unsubscribeSuccess: "Você não receberá a notificação De novo em estoque para esse produto.",
  subscriptionDialog: {
    header: "Assinatura Sem estoque",
    notifiedPrefix: "Você será notificado em:",
    notifiedSuffix: "assim que esse produto estiver de novo em estoque.",
    manageChannelsPrefix: "Gerencie seus canais de notificação preferidos na página ",
    manageChannelsLink: "Preferência de notificação",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "Você pode gerenciar suas assinaturas na página ",
    manageSubscriptionsLink: "Meus interesses",
    manageSubscriptionsSuffix: " .",
    okBtn: "OK",
    subscribing: "Assinando você nas notificações Sem estoque desse produto"
  }
};
var itemCounter$3 = {
  removeOne: "Remover um",
  addOneMore: "Adicionar mais um",
  quantity: "Quantidade"
};
var productView$3 = {
  gridView: "Selecionar para modificar para Visão de grade",
  listView: "Selecionar para modificar para Visão de lista"
};
var product$3 = {
  productDetails: productDetails$3,
  productList: productList$3,
  productFacetNavigation: productFacetNavigation$3,
  productSummary: productSummary$3,
  productReview: productReview$3,
  productCarousel: productCarousel$3,
  addToCart: addToCart$3,
  TabPanelContainer: TabPanelContainer$3,
  addToWishList: addToWishList$3,
  stockNotification: stockNotification$3,
  itemCounter: itemCounter$3,
  productView: productView$3
};
var pwa$6 = {
  addToHomeScreenDescription: "Adicionar frente de loja da SAP à tela inicial do seu dispositivo para uma visita de devolução mais rápida",
  noInstallationNeeded: "Nenhuma instalação necessária",
  fastAccessToApplication: "Acesso rápido à aplicação",
  addToHomeScreen: "Adicionar à tela inicial",
  addedToHomeScreen: "A frente de loja da SAP foi adicionada à sua página inicial"
};
var pwa$7 = {
  pwa: pwa$6
};
var siteThemeSwitcher$6 = {
  theme: "Tema",
  themes: {
    "default": "Padrão",
    highContrastDark: "HC-Escuro",
    highContrastLight: "HC-Claro"
  }
};
var siteThemeSwitcher$7 = {
  siteThemeSwitcher: siteThemeSwitcher$6
};
var anonymousConsents$3 = {
  dialog: {
    title: "Gerenciamento de consentimentos",
    legalDescription: "Nós utilizamos cookies/armazenamento do navegador para personalizar o conteúdo e melhorar a experiência de utilizador. Também poderemos compartilhar os dados sobre a utilização de seu site com nossos mídia. Para saber mais, revise nossa política de privacidade.",
    selectAll: "Selecionar tudo",
    clearAll: "Limpar tudo"
  },
  banner: {
    title: "Este website utiliza cookies",
    description: "Nós utilizamos cookies/armazenamento do navegador para personalizar o conteúdo e melhorar a experiência de utilizador.",
    allowAll: "Permitir tudo",
    viewDetails: "Visualizar detalhes",
    consentManagement: "Gerenciamento de consentimentos"
  }
};
var checkoutLogin$3 = {
  emailAddress: {
    label: "Endereço de e-mail",
    placeholder: "Inserir e-mail"
  },
  confirmEmail: {
    label: "Confirmar e-mail",
    placeholder: "Confirmar e-mail"
  },
  "continue": "Continuar",
  emailIsRequired: "Formato de e-mail inválido",
  emailsMustMatch: "E-mail não corresponde"
};
var authMessages$3 = {
  signedOutSuccessfully: "Você encerrou a sessão com êxito."
};
var user$3 = {
  anonymousConsents: anonymousConsents$3,
  checkoutLogin: checkoutLogin$3,
  authMessages: authMessages$3
};
var player$3 = {
  label: "Leitor de vídeo"
};
var video$3 = {
  player: player$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  common: common$7,
  deliveryMode: deliveryMode$3,
  myAccountV2Consent: myAccountV2Consent$7,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$7,
  myAccount: myAccount$3,
  payment: payment$3,
  pdf: pdf$7,
  product: product$3,
  pwa: pwa$7,
  siteThemeSwitcher: siteThemeSwitcher$7,
  user: user$3,
  video: video$3
};
var common$4 = {
  cancel: "Отменить",
  "delete": "Удалить",
  remove: "Удалить",
  edit: "Редактировать",
  restore: "Восстановить",
  back: "Назад",
  submit: "Отправить",
  "continue": "Продолжить",
  save: "Сохранить",
  done: "Готово",
  home: "Домашняя страница",
  noData: "Нет данных.",
  confirm: "Подтвердить",
  more: "больше",
  close: "Закрыть",
  loading: "Загрузка",
  menu: "Меню",
  reset: "Сбросить",
  search: "Поиск",
  add: "Добавить",
  breadcrumbs: "история навигации",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "Выбрать файл",
  clear: "Очистить",
  loaded: "Загружено",
  results: "Результаты",
  of: "из",
  ngSelectDropdownOptionsList: "Опции",
  required: "обязательно",
  zoomIn: "Увеличить",
  zoomOut: "Уменьшить",
  selected: "Выбрано",
  expand: "Развернуть",
  collapse: "Свернуть"
};
var pageMetaResolver$2 = {
  category: {
    title: "{{count}} результат для {{query}}",
    title_other: "Число результатов для {{query}}: {{count}}"
  },
  checkout: {
    title: "Оформить заказ"
  },
  search: {
    title: "{{count}} результат для \"{{query}}\"",
    title_other: "Число результатов для \"{{query}}\": {{count}}",
    findProductTitle: "{{count}} результат для купона \"{{coupon}}\"",
    findProductTitle_other: "Число результатов для купона \"{{coupon}}\": {{count}}",
    default_title: "Все продукты"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$2 = {
  loading: "Загрузка..."
};
var navigation$2 = {
  categoryNavLabel: "Меню категории",
  footerNavLabel: "Ссылки нижнего колонтитула",
  goTo: "Перейти к {{location}}",
  navigateTo: "Перейти к {{nav}}",
  scrollToTop: "Прокрутка назад к началу страницы",
  linkItemInList: "{{title}}. {{position}} из {{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$2 = {
  placeholder: "Введите продукт или SKU",
  productSearch: "Найти продукт",
  ariaLabelInput: "Искать здесь...",
  ariaLabelSuggestions: "предложения по вводу текста",
  ariaLabelProducts: "результаты для продукта",
  initialDescription: "Если результаты автоматического заполнения доступны, используйте стрелки вверх и вниз для просмотра и Enter для выбора. Пользователи сенсорных устройств могут использовать жесты касания или смахивания.",
  suggestions: "Предлагаемые ключевые слова",
  recentSearches: "Последние поисковые запросы",
  trendingSearches: "Популярные поисковые запросы",
  suggestionsResult: "{{ count }} предложение и ",
  suggestionsResult_other: "{{ count }} предложений и ",
  products: "Рекомендуемые продукты",
  productsResult: "{{ count }} продукт доступен.",
  productsResult_other: "Доступно продуктов: {{ count }}.",
  resetLabel: "Сбросить поиск",
  help: {
    insufficientChars: "Введите больше символов",
    noMatch: "Результаты не найдены",
    exactMatch: "{{ term }}",
    empty: "Спросите нас о чем-нибудь"
  },
  closeSearchPanel: "Закрыть"
};
var sorting$2 = {
  date: "Дата",
  orderNumber: "Номер заказа",
  rma: "Номер возврата",
  replenishmentNumber: "Номер пополнения",
  nextOrderDate: "Дата следующего заказа",
  pageViewUpdated: "На страницу добавлены выбранные опции."
};
var httpHandlers$2 = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}. Выполните вход еще раз.",
    user_is_disabled: "{{ errorMessage }}. Обратитесь к администрации."
  },
  badGateway: "Произошла ошибка сервера. Повторите попытку позже.",
  badRequestPleaseLoginAgain: "{{ errorMessage }}. Выполните вход еще раз.",
  badRequestOldPasswordIncorrect: "Неверный старый пароль.",
  badRequestGuestDuplicateEmail: "{{ errorMessage }}: электронная почта уже существует. Оформите заказ, указав другой адрес электронной почты, чтобы зарегистрироваться, используя гостевую учетную запись.",
  conflict: "Уже существует.",
  forbidden: "У вас нет полномочий на выполнение этого действия. Обратитесь к администратору, если вы считаете, что это ошибка.",
  gatewayTimeout: "Нет ответа от сервера, повторите попытку позже.",
  internalServerError: "Произошла внутренняя ошибка сервера. Повторите попытку позже.",
  sessionExpired: "Срок действия сеанса истек. Повторите вход в систему.",
  unknownError: "Произошла неизвестная ошибка",
  unauthorized: {
    common: "Произошла неизвестная ошибка авторизации",
    invalid_client: "Ошибочные учетные данные клиента"
  },
  validationErrors: {
    missing: {
      card_cardType: "Выбранная кредитная карта не поддерживается. Выберите другую.",
      card_accountNumber: "Введенный номер кредитной карты недействителен.",
      card_cvNumber: "Введенный код безопасности недействителен.",
      card_expirationMonth: "Введенный срок действия кредитной карты недействителен.",
      card_expirationYear: "Введенный срок действия кредитной карты недействителен.",
      billTo_firstName: "Введенное имя недействительно. ",
      billTo_lastName: "Введенная фамилия недействительна.",
      billTo_street1: "Введенный адрес недействителен. ",
      billTo_street2: "Введенный адрес недействителен. ",
      billTo_city: "Введенный город недействителен для этой кредитной карты.",
      billTo_state: "Введенный регион/область недействителен для этой кредитной карты.",
      billTo_country: "Введенная страна/регион недействительна для этой кредитной карты.",
      billTo_postalCode: "Введенный почтовый индекс недействителен для этой кредитной карты.",
      country: {
        isocode: "Отсутствует страна/регион"
      }
    },
    invalid: {
      card_expirationMonth: "Введенный срок действия кредитной карты недействителен.",
      firstName: "Введенное имя недействительно. ",
      lastName: "Введенная фамилия недействительна.",
      password: "Введенный пароль недействителен.",
      uid: "UID недействителен.",
      code: "Код недействителен.",
      email: "Адрес электронной почты недействителен.",
      loginId: "Адрес электронной почты недействителен."
    }
  },
  cartNotFound: "Корзина не найдена.",
  invalidCodeProvided: "Указан недействительный код.",
  voucherExceeded: "Этот купон уже использован максимальное число раз",
  unknownIdentifier: "Позиция не найдена.",
  otherCartErrors: "Произошли ошибки корзины."
};
var miniCart$2 = {
  item: "{{count}} позиция сейчас в вашей корзине",
  item_other: "{{count}} поз. сейчас в вашей корзине",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$2 = {
  skipTo: "Перейти к",
  labels: {
    header: "Заголовок",
    main: "Основной контент",
    footer: "Нижний колонтитул",
    productFacets: "Фасеты продукта",
    productList: "Список продуктов"
  }
};
var carousel$2 = {
  previousSlide: "Предыдущий слайд",
  nextSlide: "Следующий слайд",
  slideNumber: "Слайд {{currentSlideNumber}} содержит {{numberOfSlides}} поз.",
  carouselForProduct: "Карусель, изображения для {{product}}"
};
var formErrors$2 = {
  labeled: {
    required: "Поле {{label}} является обязательным",
    cxInvalidEmail: "Поле {{label}} имеет недействительный формат электронной почты. Он должен соответствовать шаблону: example@yourdomain.com",
    cxInvalidPassword: "Поле {{label}} имеет недействительный формат пароля ",
    cxMinOneUpperCaseCharacter: "Пароль должен содержать хотя бы один символ в верхнем регистре",
    cxMinOneDigit: "Пароль должен содержать хотя бы одну цифру",
    cxMinOneSpecialCharacter: "Пароль должен содержать хотя бы один спецсимвол",
    cxMinSixCharactersLength: "Пароль должен содержать минимум 6 символов",
    cxMinEightCharactersLength: "Пароль должен содержать минимум 8 символов",
    cxMaxCharactersLength: "Пароль не может содержать более 128 символов",
    cxContainsSpecialCharacters: "Пароль не может содержать спецсимволы",
    cxNoConsecutiveCharacters: "Пароль не может содержать стоящие рядом одинаковые символы",
    date: {
      required: "Поле {{label}} является обязательным",
      min: "Поле {{label}} не может быть до {{min}}",
      max: "Поле {{label}} не может быть после {{max}}",
      pattern: "Используйте формат даты гггг-мм-дд в поле {{label}}",
      invalid: "Используйте действительную дату в поле {{label}}"
    }
  },
  globalMessage: "Форма, которую вы пытаетесь отправить, содержит ошибки.",
  required: "Это обязательное поле",
  cxInvalidEmail: "Это недействительный формат электронной почты",
  cxInvalidPassword: "Это недействительный формат пароля",
  cxPasswordsMustMatch: "Поля паролей должны совпадать",
  cxPasswordsCannotMatch: "Поля паролей не должны совпадать",
  cxEmailsMustMatch: "Поля электронной почты должны совпадать",
  cxStarRatingEmpty: "Рейтинг - обязательное поле",
  cxNoSelectedItemToCancel: "Выберите хотя бы одну позицию",
  cxNegativeAmount: "Сумма должна быть больше или равна нулю",
  cxContainsSpecialCharacters: "Поле не может содержать спецсимволы",
  date: {
    required: "Это обязательное поле",
    min: "Дата не может быть до {{min}}",
    max: "Дата не может быть после {{max}}",
    pattern: "Используйте формат даты гггг-мм-дд",
    invalid: "Используйте действительную дату"
  },
  file: {
    required: "Требуется файл",
    empty: "Файл не может быть пустым",
    invalidExtension: "Недействительное расширение файла",
    tooLarge: "Размер файла не должен превышать {{ maxSize }} МБ",
    tooManyEntries: "Число позиций больше {{ maxEntries }}",
    notParsable: "Синтаксический анализ файла невозможен",
    fileNotAllowed: "Неразрешенный тип файла"
  }
};
var errorHandlers$2 = {
  scriptFailedToLoad: "Не удалось загрузить скрипт.",
  refreshThePage: "Обновите страницу."
};
var assistiveMessage$2 = {
  actionCancelled: "Операция отменена, ничего не изменено"
};
var passwordVisibility$2 = {
  showPassword: "Показать пароль",
  hidePassword: "Скрыть пароль"
};
var generalErrors$2 = {
  pageFailure: "Не удалось загрузить страницу. Попробуйте еще раз позже."
};
var formLegend$2 = {
  required: "Поля, помеченные звездочкой (*), являются обязательными."
};
var chatMessaging$2 = {
  charactersLeft: "осталось символов: {{count}}",
  addNewMessage: "Добавить новое сообщение",
  send: "Отправить",
  uploadFile: "Загрузить файл",
  informationLabel: "{{author}}. {{text}} в {{date}}",
  messages: "Сообщения",
  addMessagePlaceHolder: "Начните ввод...",
  characterLimitAlert: "Достигнут лимит символов.",
  a11y: {
    itemListBoxLabel: "Выберите позицию, к которой должно быть применено сообщение."
  }
};
var common$5 = {
  common: common$4,
  pageMetaResolver: pageMetaResolver$2,
  spinner: spinner$2,
  navigation: navigation$2,
  searchBox: searchBox$2,
  sorting: sorting$2,
  httpHandlers: httpHandlers$2,
  miniCart: miniCart$2,
  skipLink: skipLink$2,
  carousel: carousel$2,
  formErrors: formErrors$2,
  errorHandlers: errorHandlers$2,
  assistiveMessage: assistiveMessage$2,
  passwordVisibility: passwordVisibility$2,
  generalErrors: generalErrors$2,
  formLegend: formLegend$2,
  chatMessaging: chatMessaging$2
};
var setDeliveryMode$2 = {
  unknownError: "Произошла неизвестная ошибка. Обратитесь в службу поддержки."
};
var deliveryMode$2 = {
  setDeliveryMode: setDeliveryMode$2
};
var myAccountV2Consent$4 = {
  header: "Управление согласиями",
  dateDescription: "Дата утверждения ",
  clearAll: "Деактивировать все",
  selectAll: "Активировать все",
  message: {
    success: {
      given: "Согласие получено.",
      withdrawn: "Согласие отозвано."
    }
  }
};
var myAccountV2Consent$5 = {
  myAccountV2Consent: myAccountV2Consent$4
};
var myAccountV2NotificationPreference$4 = {
  header: "Каналы уведомления",
  message: "Выберите предпочтительные каналы уведомления",
  note: "Примечание: ",
  noteMessage: "Если вы деактивируете все каналы, вы больше не будете получать уведомления.",
  EMAIL: "Электронная почта:"
};
var myAccountV2NotificationPreference$5 = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$4
};
var closeAccount$2 = {
  confirmAccountClosure: "Подтвердить закрытие учетной записи",
  confirmAccountClosureMessage: "Действительно закрыть учетную запись?",
  closeMyAccount: "ЗАКРЫТЬ МОЮ УЧЕТНУЮ ЗАПИСЬ ",
  accountClosedSuccessfully: "Учетная запись закрыта",
  accountClosedFailure: "Не удалось закрыть учетную запись"
};
var updatePasswordForm$2 = {
  oldPassword: {
    label: "Старый пароль",
    placeholder: "Старый пароль"
  },
  oldPasswordIsRequired: "Требуется старый пароль.",
  newPassword: {
    label: "Новый пароль",
    placeholder: "Новый пароль"
  },
  passwordMinRequirements: "Вот краткие правила создания надежного пароля: используйте не менее 8 символов, в числе которых должны быть буквы в верхнем и нижнем регистре, цифры и спецсимволы.",
  passwordStrengthRequirements: "Вот краткие правила создания надежного пароля: используйте не менее 8 символов, в числе которых должны быть буквы в верхнем и нижнем регистре, цифры и спецсимволы. Не используйте один и тот же символ несколько раз подряд.",
  confirmPassword: {
    label: "Подтвердить новый пароль",
    placeholder: "Подтвердить пароль"
  },
  bothPasswordMustMatch: "Пароли должны совпадать",
  passwordUpdateSuccess: "Пароль успешно обновлен",
  accessDeniedError: "В доступе отказано"
};
var updateProfileForm$2 = {
  title: "Обращение",
  none: "",
  firstName: {
    label: "Имя",
    placeholder: "Имя"
  },
  firstNameIsRequired: "Ввод имени обязателен.",
  lastName: {
    label: "Фамилия",
    placeholder: "Фамилия"
  },
  lastNameIsRequired: "Ввод фамилии обязателен.",
  profileUpdateSuccess: "Персональные данные обновлены",
  customerId: "№ клиента"
};
var consentManagementForm$2 = {
  clearAll: "Очистить все",
  selectAll: "Выделить все",
  message: {
    success: {
      given: "Согласие получено.",
      withdrawn: "Согласие отозвано."
    }
  }
};
var myCoupons$2 = {
  noCouponsMessage: "У вас нет купонов.",
  effectiveTitle: "Действует:",
  Effective: "ДЕЙСТВУЕТ ",
  PreSession: "СКОРО ВСТУПАЕТ В СИЛУ ",
  ExpireSoon: "СКОРО ИСТЕЧЕТ",
  readMore: "Подробнее",
  notification: "Уведомление",
  findProducts: "Найти продукты",
  status: "Статус:",
  dialogTitle: "Купон",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "Код купона",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "Вы успешно использовали этот купон.",
  myCoupons: "Мои купоны",
  startDateAsc: "Дата начала (по возрастанию)",
  startDateDesc: "Дата начала (по убыванию)",
  endDateAsc: "Дата окончания (по возрастанию)",
  endDateDesc: "Дата окончания (по убыванию)",
  sortBy: "Сортировать по",
  sortCoupons: "Сортировать купоны",
  notesPreffix: "Вы можете настроить предпочтительные каналы для получения уведомлений о купонах на странице ",
  notesLink: "Каналы уведомления",
  notesSuffix: " ."
};
var notificationPreference$2 = {
  message: "Выберите предпочтительные каналы уведомления",
  note: "Примечание: ",
  noteMessage: "Если вы деактивируете все каналы, вы больше не будете получать уведомления.",
  EMAIL: "Электронная почта:",
  SMS: "SMS:",
  SITE_MESSAGE: "Сообщение на сайте"
};
var myInterests$2 = {
  header: "Мои интересы",
  item: "ПОЗИЦИЯ",
  price: "ЦЕНА",
  notifications: "УВЕДОМЛЕНИЯ",
  noInterests: "У вас пока нет интересов.",
  inStock: "В наличии",
  lowStock: "В наличии мало",
  outOfStock: "Нет в наличии",
  BACK_IN_STOCK: "Снова в наличии",
  sortBy: "Сортировать по",
  sortInterests: "Сортировать интересы",
  expirationDate: " - До {{ expirationDate }}",
  productId: "Ид. {{ code }}",
  remove: "Удалить",
  sorting: {
    byNameAsc: "По имени (по возрастанию)",
    byNameDesc: "По имени (по убыванию)"
  },
  actions: "ДЕЙСТВИЯ",
  caption: "Содержимое моих интересов.",
  itemRemoved: "Выбранная позиция удалена."
};
var myAccount$2 = {
  closeAccount: closeAccount$2,
  updatePasswordForm: updatePasswordForm$2,
  updateProfileForm: updateProfileForm$2,
  consentManagementForm: consentManagementForm$2,
  myCoupons: myCoupons$2,
  notificationPreference: notificationPreference$2,
  myInterests: myInterests$2
};
var paymentForm$2 = {
  payment: "Оплата",
  choosePaymentMethod: "Выберите метод оплаты",
  paymentType: "Тип оплаты",
  accountHolderName: {
    label: "Имя владельца учетной записи",
    placeholder: "Имя владельца учетной записи"
  },
  cardNumber: "Номер карты",
  expirationDate: "Дата окончания действия",
  securityCode: "Код безопасности (CVV)",
  securityCodeTitle: "Значение для проверки карты",
  saveAsDefault: "Сохранить как значение по умолчанию",
  setAsDefault: "Установить как метод оплаты по умолчанию",
  billingAddress: "Адрес выставления счета",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "Совпадает с адресом доставки",
  billingAddressSameAsShipping: "Адрес выставления счета совпадает с адресом доставки",
  selectOne: "Выберите...",
  monthMask: "ММ",
  yearMask: "ГГГГ",
  expirationYear: "Год истечения срока {{ selected }}",
  expirationMonth: "Месяц истечения срока {{ selected }}",
  useThisPayment: "Использовать эту оплату",
  addNewPayment: "Добавить новую оплату",
  paymentAddedSuccessfully: "Новая оплата успешно добавлена",
  changePayment: "Изменить оплату"
};
var paymentMethods$2 = {
  paymentMethods: "Методы оплаты",
  paymentMethodSelected: "Метод оплаты выбран",
  newPaymentMethodsAreAddedDuringCheckout: "Новые методы оплаты добавляются при оформлении заказа.",
  invalidField: "Недействительное поле: {{ field }}"
};
var paymentCard$2 = {
  deleteConfirmation: "Действительно удалить этот метод оплаты?",
  setAsDefault: "Установить по умолчанию",
  expires: "Истекает: {{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ ПО УМОЛЧАНИЮ",
  defaultPaymentLabel: "Метод оплаты по умолчанию",
  additionalPaymentLabel: "Дополнительный метод оплаты {{ number }}",
  selected: "Выбрано",
  selectedPayment: "Выбранная оплата",
  deletePaymentSuccess: "Метод оплаты удален",
  visa: "Карта Visa",
  master: "Карта Mastercard",
  masterEuro: "Карта Mastercard Eurocard",
  dinersClub: "Карта Diners Club",
  "switch": "Карта Switch",
  maestro: "Карта Maestro",
  amex: "Карта American Express",
  credit: "Кредитная карта"
};
var paymentTypes$2 = {
  title: "Метод оплаты",
  paymentType_CARD: "Кредитная карта",
  paymentType_ACCOUNT: "Учетная запись"
};
var paymentMessages$2 = {
  setAsDefaultSuccessfully: "Новая оплата установлена по умолчанию"
};
var payment$2 = {
  paymentForm: paymentForm$2,
  paymentMethods: paymentMethods$2,
  paymentCard: paymentCard$2,
  paymentTypes: paymentTypes$2,
  paymentMessages: paymentMessages$2
};
var pdf$4 = {
  defaultTitle: "Документ"
};
var pdf$5 = {
  pdf: pdf$4
};
var productDetails$2 = {
  id: "Ид.",
  quantity: "Колич.",
  quantityFull: "Количество",
  productDetails: "Сведения о продукте",
  specification: "Характеристики",
  reviews: "Отзывы",
  shipping: "Доставка",
  share: "Поделиться",
  showReviews: "Показать отзывы",
  noReviews: "Нет отзывов",
  productPrice: "Цена продукта",
  noProductImage: "Нет изображения, {{ product }}"
};
var productList$2 = {
  filterBy: {
    label: "Фильтровать по",
    action: "Фильтровать по"
  },
  activeFilter: "{{filter}} фильтр; нажмите кнопку, чтобы снять фильтр",
  appliedFilter: "Примененный фильтр:",
  showLess: "Показать меньше...",
  showMore: "Показать больше...",
  sortBy: "Сортировать по",
  sortResults: "Сортировка результатов",
  backToTopBtn: "ВВЕРХ",
  showMoreBtn: "ПОКАЗАТЬ ЕЩЕ",
  productSearchPagination: "Разбивка на страницы для поиска продуктов",
  productListResults: "Список результатов для продуктов"
};
var productFacetNavigation$2 = {
  filterBy: {
    label: "Фильтровать по",
    action: "Фильтровать по",
    facet: "Результаты фильтрации по фасетам",
    name: "Фильтровать по {{name}}"
  },
  appliedFilter: "Примененный фильтр:",
  showLess: "Показать меньше...",
  showMore: "Показать больше...",
  sortBy: "Сортировать по",
  ariaLabelShowLess: "Показать меньше, кнопка; нажмите кнопку, чтобы уменьшить число опций в активной группе",
  ariaLabelShowMore: "Показать больше, кнопка; нажмите кнопку, чтобы показать все опции в активной группе",
  ariaLabelItemsAvailable: "{{name}}, {{state}} {{count}} позиция доступна",
  ariaLabelItemsAvailable_other: "{{name}}, {{state}} {{count}} поз. доступно",
  decreaseOptionsVisibility: "Опции скрыты из активной группы; вернитесь на предыдущую вкладку, чтобы просмотреть их, или на следующую, чтобы перейти к следующей группе",
  increaseOptionsVisibility: "Опции добавлены в активную группу; вернитесь на предыдущую вкладку, чтобы просмотреть их, или на следующую, чтобы перейти к следующей группе",
  backToResults: "Назад к результатам",
  productFacets: "Фасеты продукта"
};
var productSummary$2 = {
  id: "Ид.",
  showReviews: "Показать отзывы",
  showReviewsDetailed: "Показать отзывы: {{count}}, рейтинг {{rating}} из 5 звезд",
  share: "Поделиться",
  newItemPrice: "Новая цена позиции"
};
var productReview$2 = {
  overallRating: "Общий рейтинг",
  reviewTitle: "Заголовок отзыва",
  writeYourComments: "Оставьте свой комментарий",
  rating: "Рейтинг",
  ratingRequired: "Рейтинг продукта, обязательно",
  addRate: "Добавить рейтинг: {{count}} звезда",
  addRate_other: "Добавить рейтинг: {{count}} звезд(ы)",
  reviewerName: "Имя автора отзыва (необязательно)",
  writeReview: "Напишите отзыв",
  more: "Показать больше отзывов",
  less: "Показать меньше отзывов",
  thankYouForReview: "Спасибо за отзыв! Обратите внимание: мы проверяем отзывы перед тем, как они отобразятся здесь.",
  postReviewFail: "Что-то пошло не так при публикации отзыва. Попробуйте позже.",
  ratedOutOf: "Рейтинг: {{rating}} из 5"
};
var productCarousel$2 = {
  carouselLabel: "Карусель, {{title}}"
};
var addToCart$2 = {
  itemAddedToYourCart: "Позиция добавлена в корзину",
  itemsAddedToYourCart: "Позиции добавлены в корзину",
  itemsIncrementedInYourCart: "Эта позиция уже в вашей корзине. Количество обновлено.",
  items: "поз.",
  updatingCart: "Корзина обновляется...",
  addToCart: "Добавить в корзину",
  viewCart: "Просмотреть корзину",
  proceedToCheckout: "Оформить заказ",
  quantity: "Колич.",
  quantityFull: "Количество",
  outOfStock: "Нет в наличии",
  inStock: "В наличии",
  selectStyleAndSize: "Выберите стиль и размер, чтобы проверить наличие",
  removeFromCart: "Удалить продукт из корзины",
  closeModal: "Закрыть модальное окно",
  buyItAgain: "Купить повторно",
  addToActiveCart: "Добавить в активную корзину"
};
var TabPanelContainer$2 = {
  tabs: {
    ProductDetailsTabComponent: "Сведения о продукте",
    ProductSpecsTabComponent: "Характеристики",
    ProductReviewsTabComponent: "Отзывы",
    deliveryTab: "Доставка",
    SparePartsTabComponent: " Запасные части"
  },
  tabPanelContainerRegion: "Группа вкладок с подробными сведениями о продукте",
  tabPanelContainerRegionGroup: "Группа с подробными сведениями о продукте"
};
var addToWishList$2 = {
  add: "Добавить в список желаний",
  remove: "Удалить из списка желаний",
  anonymous: "Войдите, чтобы добавить в список желаний",
  addedToWishList: "Продукт добавлен в список желаний",
  removedFromWishList: "Продукт удален из списка желаний"
};
var stockNotification$2 = {
  notifyMe: "УВЕДОМИТЬ МЕНЯ",
  stopNotify: "ПРЕКРАТИТЬ УВЕДОМЛЕНИЯ",
  getNotify: "Уведомить меня, когда продукт будет доступен.",
  getNotifySuffix: ", чтобы получить уведомление, когда продукт будет доступен.",
  activateChannelsPrefix: "Чтобы получать уведомления, активируйте функцию ",
  channelsLink: "Каналы уведомления",
  activateChannelsSuffix: ".",
  notified: "Вы получите уведомление, когда продукт снова будет в наличии.",
  getNotified: ", чтобы получить уведомление, когда продукт снова будет в наличии.",
  unsubscribeSuccess: "Вы не будете получать уведомления, когда продукт снова будет в наличии.",
  subscriptionDialog: {
    header: "Подписка на продукт не в наличии",
    notifiedPrefix: "Вы получите уведомление:",
    notifiedSuffix: "как только продукт снова будет в наличии.",
    manageChannelsPrefix: "Управляйте предпочтительными каналами уведомлений на странице ",
    manageChannelsLink: "Предпочтения по уведомлениям",
    manageChannelsSuffix: " .",
    manageSubscriptionsPrefix: "Управлять своими подписками можно на странице ",
    manageSubscriptionsLink: "Мои интересы",
    manageSubscriptionsSuffix: " .",
    okBtn: "OK",
    subscribing: "Выполняется подписка на уведомления о наличии для продукта"
  }
};
var itemCounter$2 = {
  removeOne: "Удалить 1",
  addOneMore: "Добавить еще 1",
  quantity: "Количество"
};
var productView$2 = {
  gridView: "Нажмите, чтобы перейти к виду сетки",
  listView: "Нажмите, чтобы перейти к виду списка"
};
var product$2 = {
  productDetails: productDetails$2,
  productList: productList$2,
  productFacetNavigation: productFacetNavigation$2,
  productSummary: productSummary$2,
  productReview: productReview$2,
  productCarousel: productCarousel$2,
  addToCart: addToCart$2,
  TabPanelContainer: TabPanelContainer$2,
  addToWishList: addToWishList$2,
  stockNotification: stockNotification$2,
  itemCounter: itemCounter$2,
  productView: productView$2
};
var pwa$4 = {
  addToHomeScreenDescription: "Добавьте SAP Storefront на начальный экран вашего устройства для удобства дальнейшего доступа",
  noInstallationNeeded: "Установка не требуется",
  fastAccessToApplication: "Быстрый доступ к приложению",
  addToHomeScreen: "Добавить на начальный экран",
  addedToHomeScreen: "SAP Storefront добавлен на начальный экран"
};
var pwa$5 = {
  pwa: pwa$4
};
var siteThemeSwitcher$4 = {
  theme: "Тема",
  themes: {
    "default": "По умолчанию",
    highContrastDark: "Высокий контраст, темная",
    highContrastLight: "Высокий контраст, светлая"
  }
};
var siteThemeSwitcher$5 = {
  siteThemeSwitcher: siteThemeSwitcher$4
};
var anonymousConsents$2 = {
  dialog: {
    title: "Управление согласиями",
    legalDescription: "Мы используем файлы cookie / хранилище браузера для персонализации контента и улучшения пользовательского опыта. Мы также можем делиться данными об использовании вами сайта с нашими социальными сетями. Для получения дополнительной информации ознакомьтесь с нашей политикой конфиденциальности.",
    selectAll: "Выбрать все",
    clearAll: "Очистить все"
  },
  banner: {
    title: "Этот сайт использует файлы cookie",
    description: "Мы используем файлы cookie / хранилище браузера для персонализации контента и улучшения пользовательского опыта.",
    allowAll: "Разрешить все",
    viewDetails: "Посмотреть сведения",
    consentManagement: "Управление согласиями"
  }
};
var checkoutLogin$2 = {
  emailAddress: {
    label: "Адрес электронной почты",
    placeholder: "Введите электронную почту"
  },
  confirmEmail: {
    label: "Подтвердить электронную почту",
    placeholder: "Подтвердить электронную почту"
  },
  "continue": "Продолжить",
  emailIsRequired: "Неверный формат электронной почты",
  emailsMustMatch: "Адреса электронной почты не совпадают"
};
var authMessages$2 = {
  signedOutSuccessfully: "Вы вышли из системы."
};
var user$2 = {
  anonymousConsents: anonymousConsents$2,
  checkoutLogin: checkoutLogin$2,
  authMessages: authMessages$2
};
var player$2 = {
  label: "Видеоплеер"
};
var video$2 = {
  player: player$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  common: common$5,
  deliveryMode: deliveryMode$2,
  myAccountV2Consent: myAccountV2Consent$5,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$5,
  myAccount: myAccount$2,
  payment: payment$2,
  pdf: pdf$5,
  product: product$2,
  pwa: pwa$5,
  siteThemeSwitcher: siteThemeSwitcher$5,
  user: user$2,
  video: video$2
};
var common$2 = {
  cancel: "取消",
  "delete": "删除",
  remove: "移除",
  edit: "编辑",
  restore: "还原",
  back: "返回",
  submit: "提交",
  "continue": "继续",
  save: "保存",
  done: "完成",
  home: "主页",
  noData: "无数据。",
  confirm: "确认",
  more: "更多",
  close: "关闭",
  loading: "正在加载",
  menu: "菜单",
  reset: "重置",
  search: "搜索",
  add: "添加",
  breadcrumbs: "面包屑导航",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "选择文件",
  clear: "清除",
  loaded: "已加载",
  results: "结果",
  of: "/",
  ngSelectDropdownOptionsList: "选项",
  required: "必需",
  zoomIn: "放大",
  zoomOut: "缩小",
  selected: "已选择",
  expand: "展开",
  collapse: "折叠"
};
var pageMetaResolver$1 = {
  category: {
    title: "{{query}} 的结果为 {{count}} 个",
    title_other: "{{query}} 的结果为 {{count}} 个"
  },
  checkout: {
    title: "结账"
  },
  search: {
    title: "\"{{query}}\" 的结果为 {{count}} 个",
    title_other: "\"{{query}}\" 的结果为 {{count}} 个",
    findProductTitle: "优惠卷 \"{{coupon}}\" 的结果为 {{count}} 个",
    findProductTitle_other: "优惠卷 \"{{coupon}}\" 的结果为 {{count}} 个",
    default_title: "所有产品"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner$1 = {
  loading: "正在加载..."
};
var navigation$1 = {
  categoryNavLabel: "类别菜单",
  footerNavLabel: "页脚链接",
  goTo: "转到 {{location}}",
  navigateTo: "导航到 {{nav}}",
  scrollToTop: "滚动回页面顶部",
  linkItemInList: "{{title}}。{{position}}/{{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox$1 = {
  placeholder: "输入产品名称或 SKU",
  productSearch: "查找产品",
  ariaLabelInput: "在此搜索...",
  ariaLabelSuggestions: "输入建议",
  ariaLabelProducts: "产品结果",
  initialDescription: "如果自动完成结果可用，请使用向上和向下箭头进行审阅，并按回车键进行选择。触摸设备用户，通过触摸或滑动手势进行浏览。",
  suggestions: "建议关键字",
  recentSearches: "最近搜索",
  trendingSearches: "趋势搜索",
  suggestionsResult: "{{ count }} 个建议和 ",
  suggestionsResult_other: "{{ count }} 个建议和 ",
  products: "推荐产品",
  productsResult: "{{ count }} 个产品可用。",
  productsResult_other: "{{ count }} 个产品可用。",
  resetLabel: "重置搜索",
  help: {
    insufficientChars: "请键入更多字符",
    noMatch: "我们找不到任何结果",
    exactMatch: "{{ term }}",
    empty: "询问我们"
  },
  closeSearchPanel: "关闭"
};
var sorting$1 = {
  date: "日期",
  orderNumber: "订单编号",
  rma: "退货编号",
  replenishmentNumber: "补货单号",
  nextOrderDate: "下一个订单日期",
  pageViewUpdated: "页面视图已通过所选选项更新。"
};
var httpHandlers$1 = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}。请重新登录。",
    user_is_disabled: "{{ errorMessage }}。请联系管理。"
  },
  badGateway: "出现服务器错误。请稍后重试。",
  badRequestPleaseLoginAgain: "{{ errorMessage }}。请重新登录。",
  badRequestOldPasswordIncorrect: "旧密码不正确。",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} 电子邮件已存在。请使用其他电子邮件结账，以使用访客帐户进行注册。",
  conflict: "已存在。",
  forbidden: "您无权执行此操作。如果您认为这是一个错误，请联系您的管理员。",
  gatewayTimeout: "服务器未响应，请稍后重试。",
  internalServerError: "出现内部服务器错误。请稍后重试。",
  sessionExpired: "您的会话已过期。请重新登录。",
  unknownError: "出现未知错误",
  unauthorized: {
    common: "出现未知授权错误",
    invalid_client: "客户端凭据错误"
  },
  validationErrors: {
    missing: {
      card_cardType: "所选信用卡不受支持。请选择其他信用卡。",
      card_accountNumber: "输入的信用卡号无效。",
      card_cvNumber: "输入的安全码无效。",
      card_expirationMonth: "输入的信用卡失效日期无效。",
      card_expirationYear: "输入的信用卡失效日期无效。",
      billTo_firstName: "输入的名字无效。",
      billTo_lastName: "输入的姓氏无效。",
      billTo_street1: "输入的地址无效。",
      billTo_street2: "输入的地址无效。",
      billTo_city: "输入的城市对此信用卡无效。",
      billTo_state: "输入的州/省对此信用卡无效。",
      billTo_country: "输入的国家/地区对此信用卡无效。",
      billTo_postalCode: "邮政编码对此信用卡无效。",
      country: {
        isocode: "缺少国家/地区"
      }
    },
    invalid: {
      card_expirationMonth: "输入的信用卡失效日期无效。",
      firstName: "输入的名字无效。",
      lastName: "输入的姓氏无效。",
      password: "输入的密码无效。",
      uid: "UID 无效。",
      code: "代码无效。",
      email: "电子邮件无效。",
      loginId: "电子邮件无效。"
    }
  },
  cartNotFound: "找不到购物车。",
  invalidCodeProvided: "提供的代码无效。",
  voucherExceeded: "此优惠卷已超出可使用的次数",
  unknownIdentifier: "找不到商品。",
  otherCartErrors: "出现购物车错误。"
};
var miniCart$1 = {
  item: "购物车中当前已有 {{count}} 个商品",
  item_other: "购物车中当前已有 {{count}} 个商品",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink$1 = {
  skipTo: "跳转至",
  labels: {
    header: "抬头",
    main: "主要内容",
    footer: "页脚",
    productFacets: "产品构面",
    productList: "产品列表"
  }
};
var carousel$1 = {
  previousSlide: "上一张幻灯片",
  nextSlide: "下一张幻灯片",
  slideNumber: "幻灯片 {{currentSlideNumber}} 包含 {{numberOfSlides}} 个商品",
  carouselForProduct: "轮播控件，{{product}} 的图像"
};
var formErrors$1 = {
  labeled: {
    required: "字段 {{label}} 为必填字段",
    cxInvalidEmail: "字段 {{label}} 没有有效的电子邮件格式。匹配模式：example@yourdomain.com",
    cxInvalidPassword: "字段 {{label}} 没有有效的密码格式",
    cxMinOneUpperCaseCharacter: "密码必须至少包含一个大写字符",
    cxMinOneDigit: "密码必须至少包含一个数字",
    cxMinOneSpecialCharacter: "密码必须至少包含一个特殊字符",
    cxMinSixCharactersLength: "密码必须至少包含 6 个字符",
    cxMinEightCharactersLength: "密码必须至少包含 8 个字符",
    cxMaxCharactersLength: "密码长度不能超过 128 个字符",
    cxContainsSpecialCharacters: "密码不能包含特殊字符",
    cxNoConsecutiveCharacters: "密码不能包含连续的相同字符",
    date: {
      required: "字段 {{label}} 为必填字段",
      min: "字段 {{label}} 不能早于 {{min}}",
      max: "字段 {{label}} 不能晚于 {{max}}",
      pattern: "在字段 {{label}} 中使用日期格式 yyyy-mm-dd",
      invalid: "在字段 {{label}} 中使用有效日期"
    }
  },
  globalMessage: "您尝试提交的表单包含错误。",
  required: "此字段为必填字段",
  cxInvalidEmail: "电子邮件格式无效",
  cxInvalidPassword: "密码格式无效",
  cxPasswordsMustMatch: "密码字段必须匹配",
  cxPasswordsCannotMatch: "密码字段无法匹配",
  cxEmailsMustMatch: "电子邮件字段必须匹配",
  cxStarRatingEmpty: "评级字段为必填字段",
  cxNoSelectedItemToCancel: "至少选择一个商品",
  cxNegativeAmount: "金额必须等于或大于零",
  cxContainsSpecialCharacters: "字段不能包含特殊字符",
  date: {
    required: "此字段为必填字段",
    min: "日期不能早于 {{min}}",
    max: "日期不能晚于 {{max}}",
    pattern: "使用日期格式 yyyy-mm-dd",
    invalid: "使用有效日期"
  },
  file: {
    required: "必须提供文件",
    empty: "文件不应为空",
    invalidExtension: "文件扩展名无效",
    tooLarge: "文件大小不应超过 {{ maxSize }} MB",
    tooManyEntries: "商品数大于 {{ maxEntries }}",
    notParsable: "文件不可解析",
    fileNotAllowed: "不允许此文件类型"
  }
};
var errorHandlers$1 = {
  scriptFailedToLoad: "无法加载脚本。",
  refreshThePage: "请刷新页面。"
};
var assistiveMessage$1 = {
  actionCancelled: "操作已取消，未发生任何更改"
};
var passwordVisibility$1 = {
  showPassword: "显示密码",
  hidePassword: "隐藏密码"
};
var generalErrors$1 = {
  pageFailure: "页面无法加载。请稍后重试。"
};
var formLegend$1 = {
  required: "以下标有 \"*\" 的字段是必填字段。"
};
var chatMessaging$1 = {
  charactersLeft: "剩余字符：{{count}}",
  addNewMessage: "添加新消息",
  send: "发送",
  uploadFile: "上载文件",
  informationLabel: "{{author}}。{{text}}，{{date}}",
  messages: "消息",
  addMessagePlaceHolder: "开始键入...",
  characterLimitAlert: "已达到字符限制。",
  a11y: {
    itemListBoxLabel: "选择消息可以应用到的商品。"
  }
};
var common$3 = {
  common: common$2,
  pageMetaResolver: pageMetaResolver$1,
  spinner: spinner$1,
  navigation: navigation$1,
  searchBox: searchBox$1,
  sorting: sorting$1,
  httpHandlers: httpHandlers$1,
  miniCart: miniCart$1,
  skipLink: skipLink$1,
  carousel: carousel$1,
  formErrors: formErrors$1,
  errorHandlers: errorHandlers$1,
  assistiveMessage: assistiveMessage$1,
  passwordVisibility: passwordVisibility$1,
  generalErrors: generalErrors$1,
  formLegend: formLegend$1,
  chatMessaging: chatMessaging$1
};
var setDeliveryMode$1 = {
  unknownError: "出现未知错误。请与支持部门联系。"
};
var deliveryMode$1 = {
  setDeliveryMode: setDeliveryMode$1
};
var myAccountV2Consent$2 = {
  header: "Consent Management",
  dateDescription: "批准日期 ",
  clearAll: "全部禁用",
  selectAll: "全部启用",
  message: {
    success: {
      given: "已成功给予许可。",
      withdrawn: "已成功撤销许可。"
    }
  }
};
var myAccountV2Consent$3 = {
  myAccountV2Consent: myAccountV2Consent$2
};
var myAccountV2NotificationPreference$2 = {
  header: "通知渠道",
  message: "选择您的首选通知渠道",
  note: "注意： ",
  noteMessage: "如果您取消激活所有渠道，将无法接收任何进一步的通知。",
  EMAIL: "电子邮件："
};
var myAccountV2NotificationPreference$3 = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$2
};
var closeAccount$1 = {
  confirmAccountClosure: "确认帐户关闭",
  confirmAccountClosureMessage: "是否确定要关闭帐户？",
  closeMyAccount: "关闭我的帐户",
  accountClosedSuccessfully: "帐户已成功关闭",
  accountClosedFailure: "无法关闭帐户"
};
var updatePasswordForm$1 = {
  oldPassword: {
    label: "旧密码",
    placeholder: "旧密码"
  },
  oldPasswordIsRequired: "需要提供旧密码。",
  newPassword: {
    label: "新密码",
    placeholder: "新密码"
  },
  passwordMinRequirements: "以下是确保密码安全的快速指南：使用至少 8 个字符，且大写和小写字母、数字和符号混合。",
  passwordStrengthRequirements: "以下是确保密码安全的快速指南：使用至少 8 个字符，且大写和小写字母、数字和符号混合。确保不要连续使用相同字符。",
  confirmPassword: {
    label: "确认新密码",
    placeholder: "确认密码"
  },
  bothPasswordMustMatch: "两个密码必须一致",
  passwordUpdateSuccess: "密码已成功更新",
  accessDeniedError: "访问遭到拒绝"
};
var updateProfileForm$1 = {
  title: "标题",
  none: "",
  firstName: {
    label: "名字",
    placeholder: "名字"
  },
  firstNameIsRequired: "名字为必填项。",
  lastName: {
    label: "姓氏",
    placeholder: "姓氏"
  },
  lastNameIsRequired: "姓氏为必填项。",
  profileUpdateSuccess: "个人详细信息已成功更新",
  customerId: "客户 #"
};
var consentManagementForm$1 = {
  clearAll: "全部清除",
  selectAll: "全选",
  message: {
    success: {
      given: "已成功给予许可。",
      withdrawn: "已成功撤销许可。"
    }
  }
};
var myCoupons$1 = {
  noCouponsMessage: "您没有可用的优惠券。",
  effectiveTitle: "生效：",
  Effective: "生效",
  PreSession: "即将生效",
  ExpireSoon: "即将到期",
  readMore: "继续阅读",
  notification: "通知",
  findProducts: "查找产品",
  status: "状态:",
  dialogTitle: "优惠券",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "优惠券代码",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "您已成功领取此优惠券.",
  myCoupons: "我的优惠券",
  startDateAsc: "开始日期（升序）",
  startDateDesc: "开始日期（降序）",
  endDateAsc: "结束日期（升序）",
  endDateDesc: "结束日期（降序）",
  sortBy: "排序依据",
  sortCoupons: "对优惠券进行排序",
  notesPreffix: "您可以在 ",
  notesLink: "“通知渠道”",
  notesSuffix: " 页面上设置接收优惠卷通知的首选渠道。"
};
var notificationPreference$1 = {
  message: "选择您的首选通知渠道",
  note: "注意： ",
  noteMessage: "如果您取消激活所有渠道，将无法接收任何进一步的通知。",
  EMAIL: "电子邮件：",
  SMS: "SMS：",
  SITE_MESSAGE: "SiteMessage"
};
var myInterests$1 = {
  header: "我的关注对象",
  item: "项目",
  price: "价格",
  notifications: "通知",
  noInterests: "您还没有已注册的关注对象",
  inStock: "现货",
  lowStock: "库存不足",
  outOfStock: "缺货",
  BACK_IN_STOCK: "重新到货",
  sortBy: "排序依据",
  sortInterests: "对关注对象进行排序",
  expirationDate: " - 截至 {{ expirationDate }}",
  productId: "标识 {{ code }}",
  remove: "移除",
  sorting: {
    byNameAsc: "名称（升序）",
    byNameDesc: "名称（降序）"
  },
  actions: "操作",
  caption: "我的关注对象内容。",
  itemRemoved: "所选项目已移除。"
};
var myAccount$1 = {
  closeAccount: closeAccount$1,
  updatePasswordForm: updatePasswordForm$1,
  updateProfileForm: updateProfileForm$1,
  consentManagementForm: consentManagementForm$1,
  myCoupons: myCoupons$1,
  notificationPreference: notificationPreference$1,
  myInterests: myInterests$1
};
var paymentForm$1 = {
  payment: "付款",
  choosePaymentMethod: "选择付款方式",
  paymentType: "付款类型",
  accountHolderName: {
    label: "帐户持有人姓名",
    placeholder: "帐户持有人姓名"
  },
  cardNumber: "卡号",
  expirationDate: "过期日期",
  securityCode: "安全码 (CVV)",
  securityCodeTitle: "卡验证值",
  saveAsDefault: "另存为默认值",
  setAsDefault: "设置为默认付款方式",
  billingAddress: "账单地址",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "与送货地址一致",
  billingAddressSameAsShipping: "账单地址与送货地址一致",
  selectOne: "选择一个...",
  monthMask: "MM",
  yearMask: "YYYY",
  expirationYear: "到期年份 {{ selected }}",
  expirationMonth: "到期月份 {{ selected }}",
  useThisPayment: "使用此付款",
  addNewPayment: "添加新付款",
  paymentAddedSuccessfully: "新付款已成功添加",
  changePayment: "更改付款"
};
var paymentMethods$1 = {
  paymentMethods: "付款方式",
  paymentMethodSelected: "已选择付款方式",
  newPaymentMethodsAreAddedDuringCheckout: "在结账时添加了新的付款方式。",
  invalidField: "无效字段：{{ field }}"
};
var paymentCard$1 = {
  deleteConfirmation: "是否确定要删除此付款方式？",
  setAsDefault: "设置为默认值",
  expires: "过期：{{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ 默认值",
  defaultPaymentLabel: "默认付款方式",
  additionalPaymentLabel: "其他付款方式 {{ number }}",
  selected: "已选择",
  selectedPayment: "所选付款",
  deletePaymentSuccess: "付款方式已成功删除",
  visa: "Visa 卡",
  master: "Mastercard 卡",
  masterEuro: "Mastercard Eurocard 卡",
  dinersClub: "Diners Club 卡",
  "switch": "Switch 卡",
  maestro: "Maestro 卡",
  amex: "American Express 卡",
  credit: "信用卡"
};
var paymentTypes$1 = {
  title: "付款方式",
  paymentType_CARD: "信用卡",
  paymentType_ACCOUNT: "帐户"
};
var paymentMessages$1 = {
  setAsDefaultSuccessfully: "新付款已成功设置为默认付款"
};
var payment$1 = {
  paymentForm: paymentForm$1,
  paymentMethods: paymentMethods$1,
  paymentCard: paymentCard$1,
  paymentTypes: paymentTypes$1,
  paymentMessages: paymentMessages$1
};
var pdf$2 = {
  defaultTitle: "文档"
};
var pdf$3 = {
  pdf: pdf$2
};
var productDetails$1 = {
  id: "标识",
  quantity: "数量",
  quantityFull: "数量",
  productDetails: "产品详细信息",
  specification: "规格",
  reviews: "评论",
  shipping: "发货",
  share: "共享",
  showReviews: "显示评论",
  noReviews: "尚无评论",
  productPrice: "产品价格",
  noProductImage: "没有可用图像，{{ product }}"
};
var productList$1 = {
  filterBy: {
    label: "过滤条件",
    action: "过滤条件"
  },
  activeFilter: "{{filter}} 过滤器，单击此按钮将移除过滤器",
  appliedFilter: "已应用过滤器：",
  showLess: "显示更少...",
  showMore: "显示更多...",
  sortBy: "排序依据",
  sortResults: "排序结果",
  backToTopBtn: "返回顶部",
  showMoreBtn: "显示更多",
  productSearchPagination: "产品搜索分页",
  productListResults: "产品搜索列表"
};
var productFacetNavigation$1 = {
  filterBy: {
    label: "过滤条件",
    action: "过滤条件",
    facet: "按构面过滤结果",
    name: "按 {{name}} 过滤"
  },
  appliedFilter: "已应用过滤器：",
  showLess: "显示更少...",
  showMore: "显示更多...",
  sortBy: "排序依据",
  ariaLabelShowLess: "“显示更少”按钮，单击此按钮将减少已激活组的选项",
  ariaLabelShowMore: "“显示更多”按钮，单击此按钮将显示已激活组的所有选项",
  ariaLabelItemsAvailable: "{{count}} 个 {{name}}（{{state}} 状态）商品可购买",
  ariaLabelItemsAvailable_other: "{{count}} 个 {{name}}（{{state}} 状态）商品可购买",
  decreaseOptionsVisibility: "已激活组隐藏了选项，后退阅读这些选项或前进进入下一个组",
  increaseOptionsVisibility: "为已激活组添加了更多选项，后退阅读这些选项或前进进入下一个组",
  backToResults: "返回结果",
  productFacets: "产品构面"
};
var productSummary$1 = {
  id: "标识",
  showReviews: "显示评论",
  showReviewsDetailed: "显示 {{count}} 条评论（评级为 {{rating}}，共 5 颗星）",
  share: "共享",
  newItemPrice: "新商品价格"
};
var productReview$1 = {
  overallRating: "整体评级",
  reviewTitle: "评论标题",
  writeYourComments: "写下您的备注",
  rating: "评级",
  ratingRequired: "产品评级（必需）",
  addRate: "添加评级：{{count}} 颗星",
  addRate_other: "添加评级：{{count}} 颗星",
  reviewerName: "评论人姓名（可选）",
  writeReview: "发表评论",
  more: "显示更多评论",
  less: "显示更少评论",
  thankYouForReview: "感谢您的评论！请注意，评论可能先需要审核才能在此显示。",
  postReviewFail: "发布评论时出错。请稍后重试。",
  ratedOutOf: "评级为 {{rating}}，共 5 级"
};
var productCarousel$1 = {
  carouselLabel: "轮播控件，{{title}}"
};
var addToCart$1 = {
  itemAddedToYourCart: "商品已加入购物车",
  itemsAddedToYourCart: "商品已加入购物车",
  itemsIncrementedInYourCart: "此商品已在购物车中。数量已更新。",
  items: "商品",
  updatingCart: "正在更新购物车...",
  addToCart: "加入购物车",
  viewCart: "查看购物车",
  proceedToCheckout: "继续结账",
  quantity: "数量",
  quantityFull: "数量",
  outOfStock: "缺货",
  inStock: "有现货",
  selectStyleAndSize: "选择样式和尺寸以查看库存",
  removeFromCart: "从购物车中移除产品",
  closeModal: "关闭模式",
  buyItAgain: "再次购买",
  addToActiveCart: "加入已激活购物车"
};
var TabPanelContainer$1 = {
  tabs: {
    ProductDetailsTabComponent: "产品详细信息",
    ProductSpecsTabComponent: "规格",
    ProductReviewsTabComponent: "评论",
    deliveryTab: "发货",
    SparePartsTabComponent: " 备件"
  },
  tabPanelContainerRegion: "提供更多产品详细信息的选项卡组",
  tabPanelContainerRegionGroup: "提供更多产品详细信息的组"
};
var addToWishList$1 = {
  add: "加入愿望清单",
  remove: "从愿望清单中移除",
  anonymous: "登录即可加入愿望清单",
  addedToWishList: "产品已加入愿望清单",
  removedFromWishList: "产品已从愿望清单中移除"
};
var stockNotification$1 = {
  notifyMe: "通知我",
  stopNotify: "停止通知",
  getNotify: "此产品可购买时通知。",
  getNotifySuffix: "在此产品可购买时通知。",
  activateChannelsPrefix: "要获得通知，您需要激活 ",
  channelsLink: "通知渠道",
  activateChannelsSuffix: "。",
  notified: "此产品补货时，您将会收到通知。",
  getNotified: "此产品补货时通知。",
  unsubscribeSuccess: "您将不会收到此产品的补货通知。",
  subscriptionDialog: {
    header: "缺货订阅",
    notifiedPrefix: "您将在以下情况收到通知：",
    notifiedSuffix: "此产品补货后。",
    manageChannelsPrefix: "在 ",
    manageChannelsLink: "“通知首选项”",
    manageChannelsSuffix: " 页面上管理您的首选通知渠道。",
    manageSubscriptionsPrefix: "您可以在 ",
    manageSubscriptionsLink: "“我的关注对象”",
    manageSubscriptionsSuffix: " 页面上管理您的订阅。",
    okBtn: "确定",
    subscribing: "订阅此产品的缺货通知"
  }
};
var itemCounter$1 = {
  removeOne: "移除一个",
  addOneMore: "再添加一个",
  quantity: "数量"
};
var productView$1 = {
  gridView: "选择以更改为网格视图",
  listView: "选择以更改为列表视图"
};
var product$1 = {
  productDetails: productDetails$1,
  productList: productList$1,
  productFacetNavigation: productFacetNavigation$1,
  productSummary: productSummary$1,
  productReview: productReview$1,
  productCarousel: productCarousel$1,
  addToCart: addToCart$1,
  TabPanelContainer: TabPanelContainer$1,
  addToWishList: addToWishList$1,
  stockNotification: stockNotification$1,
  itemCounter: itemCounter$1,
  productView: productView$1
};
var pwa$2 = {
  addToHomeScreenDescription: "向设备主屏幕添加 SAP 店面以实现快速回访",
  noInstallationNeeded: "无需安装",
  fastAccessToApplication: "快速访问应用程序",
  addToHomeScreen: "添加到主屏幕",
  addedToHomeScreen: "SAP 店面已添加到主屏幕"
};
var pwa$3 = {
  pwa: pwa$2
};
var siteThemeSwitcher$2 = {
  theme: "主题",
  themes: {
    "default": "默认",
    highContrastDark: "HC-Dark",
    highContrastLight: "HC-Light"
  }
};
var siteThemeSwitcher$3 = {
  siteThemeSwitcher: siteThemeSwitcher$2
};
var anonymousConsents$1 = {
  dialog: {
    title: "Consent Management",
    legalDescription: "我们使用 cookie/浏览器的存储个性化内容并改善用户体验。我们还可以与我们的社交媒体共享站点使用数据。有关详细信息，请审阅我们的隐私政策。",
    selectAll: "全选",
    clearAll: "全部清除"
  },
  banner: {
    title: "此网站使用 cookie",
    description: "我们使用 cookie/浏览器的存储个性化内容并改善用户体验。",
    allowAll: "全部显示",
    viewDetails: "查看详情",
    consentManagement: "Consent Management"
  }
};
var checkoutLogin$1 = {
  emailAddress: {
    label: "电子邮件地址",
    placeholder: "输入电子邮件"
  },
  confirmEmail: {
    label: "确认电子邮件",
    placeholder: "确认电子邮件"
  },
  "continue": "继续",
  emailIsRequired: "电子邮件格式无效",
  emailsMustMatch: "电子邮件不匹配"
};
var authMessages$1 = {
  signedOutSuccessfully: "您已成功退出。"
};
var user$1 = {
  anonymousConsents: anonymousConsents$1,
  checkoutLogin: checkoutLogin$1,
  authMessages: authMessages$1
};
var player$1 = {
  label: "视频播放器"
};
var video$1 = {
  player: player$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  common: common$3,
  deliveryMode: deliveryMode$1,
  myAccountV2Consent: myAccountV2Consent$3,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$3,
  myAccount: myAccount$1,
  payment: payment$1,
  pdf: pdf$3,
  product: product$1,
  pwa: pwa$3,
  siteThemeSwitcher: siteThemeSwitcher$3,
  user: user$1,
  video: video$1
};
var common = {
  cancel: "取消",
  "delete": "刪除",
  remove: "移除",
  edit: "編輯",
  restore: "還原",
  back: "返回",
  submit: "提交",
  "continue": "繼續",
  save: "儲存",
  done: "完成",
  home: "首頁",
  noData: "無資料。",
  confirm: "確認",
  more: "更多",
  close: "關閉",
  loading: "載入中",
  menu: "功能表",
  reset: "重設",
  search: "搜尋",
  add: "新增",
  breadcrumbs: "路徑指引",
  breadcrumbsCurrentPage: "Current Page",
  selectFile: "選擇檔案",
  clear: "清除",
  loaded: "已載入",
  results: "結果",
  of: "/",
  ngSelectDropdownOptionsList: "選項",
  required: "必要",
  zoomIn: "放大",
  zoomOut: "縮小",
  selected: "已選擇",
  expand: "展開",
  collapse: "收縮"
};
var pageMetaResolver = {
  category: {
    title: "{{count}} 個 {{query}} 的結果",
    title_other: "{{count}} 個 {{query}} 的結果"
  },
  checkout: {
    title: "結帳"
  },
  search: {
    title: "{{count}} 個「{{query}}」的結果",
    title_other: "{{count}} 個「{{query}}」的結果",
    findProductTitle: "{{count}} 個「{{coupon}}」的優惠券",
    findProductTitle_other: "{{count}} 個「{{coupon}}」的優惠券",
    default_title: "所有產品"
  },
  product: {
    description: "{{description}}",
    heading: "{{heading}}",
    title: "{{title}}"
  }
};
var spinner = {
  loading: "正在載入..."
};
var navigation = {
  categoryNavLabel: "種類功能表",
  footerNavLabel: "頁尾連結",
  goTo: "移至 {{location}}",
  navigateTo: "瀏覽至 {{nav}}",
  scrollToTop: "捲動回頁面頂端",
  linkItemInList: "{{title}}。{{position}}/{{listLength}}",
  menuButonTitle: "{{title}} Menu"
};
var searchBox = {
  placeholder: "輸入產品名稱或 SKU",
  productSearch: "尋找產品",
  ariaLabelInput: "在此搜尋...",
  ariaLabelSuggestions: "輸入建議",
  ariaLabelProducts: "產品結果",
  initialDescription: "若提供自動完成結果時，請使用向上和向下箭頭以審查和輸入進行選擇。觸控裝置使用者透過觸控或滑動手勢探索。",
  suggestions: "建議的關鍵字",
  recentSearches: "最近搜尋",
  trendingSearches: "趨勢搜尋",
  suggestionsResult: "{{ count }} 個建議及 ",
  suggestionsResult_other: "{{ count }} 個建議及 ",
  products: "建議的產品",
  productsResult: "有 {{ count }} 個產品。",
  productsResult_other: "有 {{ count }} 個產品。",
  resetLabel: "重設搜尋",
  help: {
    insufficientChars: "請輸入更多字元",
    noMatch: "我們找不到結果",
    exactMatch: "{{ term }}",
    empty: "有任何問題都可向我們詢問"
  },
  closeSearchPanel: "關閉"
};
var sorting = {
  date: "日期",
  orderNumber: "訂單號碼",
  rma: "退貨號碼",
  replenishmentNumber: "補貨號碼",
  nextOrderDate: "下一個訂單日期",
  pageViewUpdated: "已使用所選選項更新頁面檢視。"
};
var httpHandlers = {
  badRequest: {
    bad_credentials: "{{ errorMessage }}。請再登入一次。",
    user_is_disabled: "{{ errorMessage }}。請聯絡管理員。"
  },
  badGateway: "發生伺服器錯誤。請稍後再試一次。",
  badRequestPleaseLoginAgain: "{{ errorMessage }}。請重新登入。",
  badRequestOldPasswordIncorrect: "舊密碼不正確。",
  badRequestGuestDuplicateEmail: "{{ errorMessage }} 電子郵件已存在。請使用不同的電子郵件結帳以使用訪客帳戶註冊。",
  conflict: "已存在。",
  forbidden: "您未授權執行此動作。如果您認證這是錯誤，請聯絡管理員。",
  gatewayTimeout: "伺服器未回應，請稍後再試一次。",
  internalServerError: "發生內部伺服器錯誤。請稍後再試一次。",
  sessionExpired: "您的工作階段已到期。請重新登入。",
  unknownError: "發生未知錯誤",
  unauthorized: {
    common: "發生未知權限錯誤",
    invalid_client: "用戶端憑證錯誤"
  },
  validationErrors: {
    missing: {
      card_cardType: "不支援所選的信用卡。請選擇其他信用卡。",
      card_accountNumber: "輸入的信用卡號無效。",
      card_cvNumber: "輸入的信用卡檢查數字無效。",
      card_expirationMonth: "輸入的信用卡到期日期無效。",
      card_expirationYear: "輸入的信用卡到期日期無效。",
      billTo_firstName: "輸入的名字無效。",
      billTo_lastName: "輸入的姓氏無效。",
      billTo_street1: "輸入的地址無效。",
      billTo_street2: "輸入的地址無效。",
      billTo_city: "針對此信用卡輸入的城市無效。",
      billTo_state: "針對此信用卡輸入的州/省無效。",
      billTo_country: "針對此信用卡輸入的國家/地區無效。",
      billTo_postalCode: "針對此信用卡輸入的郵遞區號無效。",
      country: {
        isocode: "缺少國家/地區"
      }
    },
    invalid: {
      card_expirationMonth: "輸入的信用卡到期日期無效。",
      firstName: "輸入的名字無效。",
      lastName: "輸入的姓氏無效。",
      password: "輸入的密碼無效。",
      uid: "UID 無效。",
      code: "代碼無效。",
      email: "電子郵件無效。",
      loginId: "電子郵件無效。"
    }
  },
  cartNotFound: "找不到購物車。",
  invalidCodeProvided: "提供的代碼無效。",
  voucherExceeded: "此優惠券已超過可使用的次數",
  unknownIdentifier: "找不到項目。",
  otherCartErrors: "發生購物車錯誤。"
};
var miniCart = {
  item: "您的購物車目前有 {{count}} 個項目",
  item_other: "您的購物車目前有 {{count}} 個項目",
  total: "{{total}}",
  count: "{{count}}"
};
var skipLink = {
  skipTo: "跳至",
  labels: {
    header: "表頭",
    main: "主要內容",
    footer: "頁尾",
    productFacets: "產品層面",
    productList: "產品清單"
  }
};
var carousel = {
  previousSlide: "上一張投影片",
  nextSlide: "下一張投影片",
  slideNumber: "投影片 {{currentSlideNumber}} 包含 {{numberOfSlides}} 個項目",
  carouselForProduct: "轉盤，{{product}} 的圖像"
};
var formErrors = {
  labeled: {
    required: "欄位 {{label}} 為必要",
    cxInvalidEmail: "欄位 {{label}} 沒有有效的電子郵件格式。相符模式：example@yourdomain.com",
    cxInvalidPassword: "欄位 {{label}} 沒有有效的密碼格式",
    cxMinOneUpperCaseCharacter: "密碼必須至少包含一個大寫字元",
    cxMinOneDigit: "密碼必須至少包含一位數",
    cxMinOneSpecialCharacter: "密碼必須至少包含一個特殊字元",
    cxMinSixCharactersLength: "密碼必須至少包含 6 個字元",
    cxMinEightCharactersLength: "密碼必須至少包含 8 個字元",
    cxMaxCharactersLength: "密碼不能超過 128 個字元",
    cxContainsSpecialCharacters: "密碼不能包含特殊字元",
    cxNoConsecutiveCharacters: "密碼不能包含連續相同字元",
    date: {
      required: "欄位 {{label}} 為必要",
      min: "欄位 {{label}} 不能早於 {{min}}",
      max: "欄位 {{label}} 不能晚於 {{max}}",
      pattern: "在欄位 {{label}} 中使用日期格式 yyyy-mm-dd",
      invalid: "在 {{label}} 欄位中使用有效日期"
    }
  },
  globalMessage: "您嘗試提交的表單包含錯誤。",
  required: "此為必要欄位",
  cxInvalidEmail: "這不是有效的電子郵件格式",
  cxInvalidPassword: "這不是有效的密碼格式",
  cxPasswordsMustMatch: "密碼欄位必須相符",
  cxPasswordsCannotMatch: "密碼欄位必須相符",
  cxEmailsMustMatch: "電子郵件欄位必須相符",
  cxStarRatingEmpty: "評比欄位為必要",
  cxNoSelectedItemToCancel: "至少選擇一個項目",
  cxNegativeAmount: "金額必須等於或大於零",
  cxContainsSpecialCharacters: "欄位不能包含特殊字元",
  date: {
    required: "此為必要欄位",
    min: "日期不能早於 {{min}}",
    max: "日期不能晚於 {{max}}",
    pattern: "使用日期格式 yyyy-mm-dd",
    invalid: "使用有效日期"
  },
  file: {
    required: "欄位為必要",
    empty: "欄位不應為空",
    invalidExtension: "副檔案無效。",
    tooLarge: "檔案大小不應超過 {{ maxSize }} MB",
    tooManyEntries: "項目數量大於 {{ maxEntries }}",
    notParsable: "欄位無法剖析",
    fileNotAllowed: "不允許此檔案類型"
  }
};
var errorHandlers = {
  scriptFailedToLoad: "無法載入指令碼。",
  refreshThePage: "請重新整理頁面。"
};
var assistiveMessage = {
  actionCancelled: "已取消動作，無更改"
};
var passwordVisibility = {
  showPassword: "顯示密碼",
  hidePassword: "隱藏密碼"
};
var generalErrors = {
  pageFailure: "無法載入頁面。請稍後再試一次。"
};
var formLegend = {
  required: "下列標記「*」的欄位為必填。"
};
var chatMessaging = {
  charactersLeft: "剩餘字元：{{count}}",
  addNewMessage: "新增訊息",
  send: "傳送",
  uploadFile: "上傳檔案",
  informationLabel: "{{author}}。{{text}}，{{date}}",
  messages: "訊息",
  addMessagePlaceHolder: "開始輸入...",
  characterLimitAlert: "已達到字源限制。",
  a11y: {
    itemListBoxLabel: "選擇訊息應套用的項目。"
  }
};
var common$1 = {
  common: common,
  pageMetaResolver: pageMetaResolver,
  spinner: spinner,
  navigation: navigation,
  searchBox: searchBox,
  sorting: sorting,
  httpHandlers: httpHandlers,
  miniCart: miniCart,
  skipLink: skipLink,
  carousel: carousel,
  formErrors: formErrors,
  errorHandlers: errorHandlers,
  assistiveMessage: assistiveMessage,
  passwordVisibility: passwordVisibility,
  generalErrors: generalErrors,
  formLegend: formLegend,
  chatMessaging: chatMessaging
};
var setDeliveryMode = {
  unknownError: "發生未知錯誤。請聯絡支援團隊。"
};
var deliveryMode = {
  setDeliveryMode: setDeliveryMode
};
var myAccountV2Consent = {
  header: "同意管理",
  dateDescription: "核准日期 ",
  clearAll: "全部停用",
  selectAll: "全部啟用",
  message: {
    success: {
      given: "已成功提供同意。",
      withdrawn: "已成功撤銷同意。"
    }
  }
};
var myAccountV2Consent$1 = {
  myAccountV2Consent: myAccountV2Consent
};
var myAccountV2NotificationPreference = {
  header: "通知管道",
  message: "選擇您的偏好通知管道",
  note: "請注意： ",
  noteMessage: "若您已停用所有管道，則將無法再接收通知。",
  EMAIL: "電子郵件："
};
var myAccountV2NotificationPreference$1 = {
  myAccountV2NotificationPreference: myAccountV2NotificationPreference
};
var closeAccount = {
  confirmAccountClosure: "確認帳戶關閉",
  confirmAccountClosureMessage: "您確定要關閉帳戶嗎？",
  closeMyAccount: "關閉我的帳戶",
  accountClosedSuccessfully: "帳戶關閉成功",
  accountClosedFailure: "無法關閉帳戶"
};
var updatePasswordForm = {
  oldPassword: {
    label: "舊密碼",
    placeholder: "舊密碼"
  },
  oldPasswordIsRequired: "舊密碼為必要。",
  newPassword: {
    label: "新密碼",
    placeholder: "新密碼"
  },
  passwordMinRequirements: "此為安全密碼的快速指南：請使用至少 8 個字元，其中混合大小寫字母、數字和符號。",
  passwordStrengthRequirements: "此為安全密碼的快速指南：請使用至少 8 個字元，其中混合大小寫字母、數字和符號。請確保不要連續使用相同字元。",
  confirmPassword: {
    label: "確認新密碼",
    placeholder: "確認密碼"
  },
  bothPasswordMustMatch: "兩者密碼必須相符",
  passwordUpdateSuccess: "密碼更新成功",
  accessDeniedError: "已拒絕存取"
};
var updateProfileForm = {
  title: "稱謂",
  none: "",
  firstName: {
    label: "名字",
    placeholder: "名字"
  },
  firstNameIsRequired: "名字為必要。",
  lastName: {
    label: "姓氏",
    placeholder: "姓氏"
  },
  lastNameIsRequired: "姓氏為必要。",
  profileUpdateSuccess: "已成功更新個人明細",
  customerId: "客戶 #"
};
var consentManagementForm = {
  clearAll: "全部清除",
  selectAll: "全部選擇",
  message: {
    success: {
      given: "已成功提供同意。",
      withdrawn: "已成功撤銷同意。"
    }
  }
};
var myCoupons = {
  noCouponsMessage: "您沒有優惠券",
  effectiveTitle: "有效：",
  Effective: "有效",
  PreSession: "即將有效",
  ExpireSoon: "即將到期",
  readMore: "讀取更多",
  notification: "通知",
  findProducts: "尋找產品",
  status: "狀態：",
  dialogTitle: "優惠券",
  claimCoupondialogTitle: "Add To Your Coupon List",
  claimCouponCode: {
    label: "優惠券代碼",
    placeholder: "Enter the coupon code to claim a coupon"
  },
  reset: "RESET",
  claim: "CLAIM",
  claimCustomerCoupon: "您已成功索取此優惠券。",
  myCoupons: "我的優惠券",
  startDateAsc: "開始日期 (升冪)",
  startDateDesc: "開始日期 (降冪)",
  endDateAsc: "結束日期 (升冪)",
  endDateDesc: "結束日期 (降冪)",
  sortBy: "排序依據",
  sortCoupons: "排序優惠券",
  notesPreffix: "您可設定用來接收優惠券通知的偏好管道，位置為 ",
  notesLink: "通知管道",
  notesSuffix: " 頁面。"
};
var notificationPreference = {
  message: "選擇您的偏好通知管道",
  note: "請注意： ",
  noteMessage: "若您已停用所有管道，則將無法再接收通知。",
  EMAIL: "電子郵件：",
  SMS: "SMS：",
  SITE_MESSAGE: "網站訊息"
};
var myInterests = {
  header: "我的興趣",
  item: "項目",
  price: "價格",
  notifications: "通知",
  noInterests: "您尚無註冊的興趣。",
  inStock: "庫存",
  lowStock: "少量庫存",
  outOfStock: "缺貨",
  BACK_IN_STOCK: "到貨",
  sortBy: "排序依據",
  sortInterests: "排序興趣",
  expirationDate: " - 直到 {{ expirationDate }}",
  productId: "ID {{ code }}",
  remove: "移除",
  sorting: {
    byNameAsc: "名稱 (升冪)",
    byNameDesc: "名稱 (降冪)"
  },
  actions: "動作",
  caption: "我的興趣內容。",
  itemRemoved: "所選項目已移除。"
};
var myAccount = {
  closeAccount: closeAccount,
  updatePasswordForm: updatePasswordForm,
  updateProfileForm: updateProfileForm,
  consentManagementForm: consentManagementForm,
  myCoupons: myCoupons,
  notificationPreference: notificationPreference,
  myInterests: myInterests
};
var paymentForm = {
  payment: "付款",
  choosePaymentMethod: "選擇付款方法",
  paymentType: "付款類型",
  accountHolderName: {
    label: "帳號持有人姓名",
    placeholder: "帳號持有人姓名"
  },
  cardNumber: "卡號",
  expirationDate: "到期日期",
  securityCode: "安全碼 (CVV)",
  securityCodeTitle: "卡片驗證值",
  saveAsDefault: "儲存為預設",
  setAsDefault: "設硬為預設付款方法",
  billingAddress: "帳單寄送地址",
  editBillingAddress: "Edit billing Address",
  sameAsDeliveryAddress: "與交貨地址相同",
  billingAddressSameAsShipping: "帳單寄送地址與交貨地址相同",
  selectOne: "選擇一個...",
  monthMask: "MM",
  yearMask: "YYYY",
  expirationYear: "到期年度 {{ selected }}",
  expirationMonth: "到期月份 {{ selected }}",
  useThisPayment: "使用此付款",
  addNewPayment: "新增付款",
  paymentAddedSuccessfully: "已成功新增付款",
  changePayment: "更改付款"
};
var paymentMethods = {
  paymentMethods: "付款方法",
  paymentMethodSelected: "已選擇付款方法",
  newPaymentMethodsAreAddedDuringCheckout: "結帳期間已新增付款方法。",
  invalidField: "欄位無效：{{ field }}"
};
var paymentCard = {
  deleteConfirmation: "您確定要刪除此付款方法嗎？",
  setAsDefault: "設定為預設值",
  expires: "到期：{{ month }}/{{ year }}",
  defaultPaymentMethod: "✓ 預設",
  defaultPaymentLabel: "預設付款方法",
  additionalPaymentLabel: "附加付款方法 {{ number }}",
  selected: "已選擇",
  selectedPayment: "所選付款",
  deletePaymentSuccess: "已成功刪除付款方法",
  visa: "Visa 卡",
  master: "萬事達卡",
  masterEuro: "萬事達 Eurocard 卡",
  dinersClub: "大來國際卡",
  "switch": "Switch 卡",
  maestro: "萬事順卡",
  amex: "美國運通卡",
  credit: "信用卡"
};
var paymentTypes = {
  title: "付款方法",
  paymentType_CARD: "信用卡",
  paymentType_ACCOUNT: "帳戶"
};
var paymentMessages = {
  setAsDefaultSuccessfully: "已成功將新付款設定為預設"
};
var payment = {
  paymentForm: paymentForm,
  paymentMethods: paymentMethods,
  paymentCard: paymentCard,
  paymentTypes: paymentTypes,
  paymentMessages: paymentMessages
};
var pdf = {
  defaultTitle: "文件"
};
var pdf$1 = {
  pdf: pdf
};
var productDetails = {
  id: "ID",
  quantity: "數量",
  quantityFull: "數量",
  productDetails: "產品明細",
  specification: "規格",
  reviews: "評論",
  shipping: "出貨",
  share: "分享",
  showReviews: "顯示評論",
  noReviews: "尚未有評論",
  productPrice: "產品價格",
  noProductImage: "沒有可用的圖像，{{ product }}"
};
var productList = {
  filterBy: {
    label: "篩選依據",
    action: "篩選依據"
  },
  activeFilter: "{{filter}} 篩選，按一下此按鈕將移除篩選",
  appliedFilter: "套用的篩選：",
  showLess: "顯示較少...",
  showMore: "顯示更多...",
  sortBy: "排序依據",
  sortResults: "排序結果",
  backToTopBtn: "返回最上方",
  showMoreBtn: "顯示更多",
  productSearchPagination: "產品搜尋分頁",
  productListResults: "產品結果清單"
};
var productFacetNavigation = {
  filterBy: {
    label: "篩選依據",
    action: "篩選依據",
    facet: "按層面篩選結果",
    name: "按 {{name}} 篩選"
  },
  appliedFilter: "套用的篩選：",
  showLess: "顯示更少...",
  showMore: "顯示更多...",
  sortBy: "排序依據",
  ariaLabelShowLess: "顯示更少，按鈕，按一下此按鈕將減少啟用中群組的選項",
  ariaLabelShowMore: "顯示更多，按鈕，按一下此按鈕將顯示啟用中群組的所有選項",
  ariaLabelItemsAvailable: "{{name}}，{{state}} {{count}} 個項目可供使用",
  ariaLabelItemsAvailable_other: "{{name}}，{{state}} {{count}} 個項目可供使用",
  decreaseOptionsVisibility: "啟用中群組已隱藏選項，按一下 Tab 鍵向後讀取，或按一下 Tab 鍵向前至下一個群組",
  increaseOptionsVisibility: "已將更多選項新增至啟用中群組，按一下 Tab 鍵向後讀取，或按一下 Tab 鍵向前至下一個群組",
  backToResults: "返回結果",
  productFacets: "產品層面"
};
var productSummary = {
  id: "ID",
  showReviews: "顯示評論",
  showReviewsDetailed: "顯示 {{count}} 個評論，評分 {{rating}}/5 顆星",
  share: "分享",
  newItemPrice: "新項目價格"
};
var productReview = {
  overallRating: "整體評分",
  reviewTitle: "評論標題",
  writeYourComments: "撰寫您的備註",
  rating: "評分",
  ratingRequired: "產品評比，必要",
  addRate: "新增評分：{{count}} 顆星",
  addRate_other: "新增評分：{{count}} 顆星",
  reviewerName: "評論者名稱 (選擇性)",
  writeReview: "寫入評論",
  more: "顯示更多評論",
  less: "顯示更少評論",
  thankYouForReview: "感謝您的評論！請注意，評論可能需要先審查才會在此顯示。",
  postReviewFail: "發佈您的評論時發生錯誤。請稍後再試一次。",
  ratedOutOf: "評分：{{rating}}/5"
};
var productCarousel = {
  carouselLabel: "轉盤，{{title}}"
};
var addToCart = {
  itemAddedToYourCart: "已將項目新增至您的購物車",
  itemsAddedToYourCart: "已將項目新增至您的購物車",
  itemsIncrementedInYourCart: "您的購物車已有此項目。已更新數量。",
  items: "項目",
  updatingCart: "正在更新購物車...",
  addToCart: "新增至購物車",
  viewCart: "檢視購物車",
  proceedToCheckout: "進行結帳",
  quantity: "數量",
  quantityFull: "數量",
  outOfStock: "缺貨",
  inStock: "有存貨",
  selectStyleAndSize: "選擇樣式和大小以檢查庫存",
  removeFromCart: "從購物車移除產品",
  closeModal: "關閉強制回應視窗",
  buyItAgain: "再次購買",
  addToActiveCart: "新增至啟用中購物車"
};
var TabPanelContainer = {
  tabs: {
    ProductDetailsTabComponent: "產品明細",
    ProductSpecsTabComponent: "規格",
    ProductReviewsTabComponent: "評論",
    deliveryTab: "出貨",
    SparePartsTabComponent: " 備用零件"
  },
  tabPanelContainerRegion: "包含更多產品明細的標籤群組",
  tabPanelContainerRegionGroup: "包含更多產品明細的群組"
};
var addToWishList = {
  add: "新增至願望清單",
  remove: "從願望清單移除",
  anonymous: "登入以新增至願望清單",
  addedToWishList: "已將產品新增至願望清單",
  removedFromWishList: "已從願望清單移除產品"
};
var stockNotification = {
  notifyMe: "通知我",
  stopNotify: "停止通知",
  getNotify: "供應此產品時收到通知。",
  getNotifySuffix: "供應此產品時收到通知。",
  activateChannelsPrefix: "若要收到通知，您必須啟用 ",
  channelsLink: "通知管道",
  activateChannelsSuffix: "。",
  notified: "此產品到貨時，您將收到通知。",
  getNotified: "此產品到貨時，收到通知。",
  unsubscribeSuccess: "您將不會收到此產品的到貨通知。",
  subscriptionDialog: {
    header: "缺貨訂閱",
    notifiedPrefix: "您將在下列情況下收到通知：",
    notifiedSuffix: "此產品到貨時。",
    manageChannelsPrefix: "管理您的偏好通知管道，位置為 ",
    manageChannelsLink: "通知偏好",
    manageChannelsSuffix: " 頁面。",
    manageSubscriptionsPrefix: "您可管理訂閱，位置為 ",
    manageSubscriptionsLink: "我的興趣",
    manageSubscriptionsSuffix: " 頁面。",
    okBtn: "確定",
    subscribing: "為您訂閱此產品的缺貨通知"
  }
};
var itemCounter = {
  removeOne: "移除一個",
  addOneMore: "再新增一個",
  quantity: "數量"
};
var productView = {
  gridView: "選擇以更改為格線檢視",
  listView: "選擇以更改為清單檢視"
};
var product = {
  productDetails: productDetails,
  productList: productList,
  productFacetNavigation: productFacetNavigation,
  productSummary: productSummary,
  productReview: productReview,
  productCarousel: productCarousel,
  addToCart: addToCart,
  TabPanelContainer: TabPanelContainer,
  addToWishList: addToWishList,
  stockNotification: stockNotification,
  itemCounter: itemCounter,
  productView: productView
};
var pwa = {
  addToHomeScreenDescription: "新增 SAP 店面至您的裝置首頁，以更快速回訪",
  noInstallationNeeded: "不需要安裝",
  fastAccessToApplication: "快速存取應用程式",
  addToHomeScreen: "新增至首頁畫面",
  addedToHomeScreen: "SAP 店面已新增至您的首頁畫面"
};
var pwa$1 = {
  pwa: pwa
};
var siteThemeSwitcher = {
  theme: "主題",
  themes: {
    "default": "預設",
    highContrastDark: "HC-Dark",
    highContrastLight: "HC-Light"
  }
};
var siteThemeSwitcher$1 = {
  siteThemeSwitcher: siteThemeSwitcher
};
var anonymousConsents = {
  dialog: {
    title: "同意管理",
    legalDescription: "系統使用 Cookie/瀏覽器儲存空間，個人化內容並改善使用者體驗。我們也會將關於您網站的資料與我們的社群媒體分享。如需更多資訊，請審查我們的隱私權政策。",
    selectAll: "全部選擇",
    clearAll: "全部清除"
  },
  banner: {
    title: "此網站使用 Cookie",
    description: "我們使用 Cookie/瀏覽器儲存空間，個人化內容並改善使用者體驗。",
    allowAll: "全部允許",
    viewDetails: "檢視明細",
    consentManagement: "同意管理"
  }
};
var checkoutLogin = {
  emailAddress: {
    label: "電子郵件地址",
    placeholder: "輸入電子郵件"
  },
  confirmEmail: {
    label: "確認電子郵件",
    placeholder: "確認電子郵件"
  },
  "continue": "繼續",
  emailIsRequired: "電子郵件格式無效",
  emailsMustMatch: "電子郵件不相符"
};
var authMessages = {
  signedOutSuccessfully: "您已成功登出。"
};
var user = {
  anonymousConsents: anonymousConsents,
  checkoutLogin: checkoutLogin,
  authMessages: authMessages
};
var player = {
  label: "影片播放器"
};
var video = {
  player: player
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  common: common$1,
  deliveryMode,
  myAccountV2Consent: myAccountV2Consent$1,
  myAccountV2NotificationPreference: myAccountV2NotificationPreference$1,
  myAccount,
  payment,
  pdf: pdf$1,
  product,
  pwa: pwa$1,
  siteThemeSwitcher: siteThemeSwitcher$1,
  user,
  video
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const translationChunksConfig = {
  common: ['common', 'pageMetaResolver', 'spinner', 'navigation', 'searchBox', 'sorting', 'httpHandlers', 'miniCart', 'skipLink', 'carousel', 'formErrors', 'errorHandlers', 'assistiveMessage', 'passwordVisibility', 'generalErrors', 'formLegend', 'chatMessaging'],
  deliveryMode: ['setDeliveryMode'],
  myAccountV2Consent: ['myAccountV2Consent'],
  myAccountV2NotificationPreference: ['myAccountV2NotificationPreference'],
  myAccount: ['closeAccount', 'updatePasswordForm', 'updateProfileForm', 'consentManagementForm', 'myCoupons', 'notificationPreference', 'myInterests'],
  payment: ['paymentForm', 'paymentMethods', 'paymentCard', 'paymentTypes', 'paymentMessages'],
  pdf: ['pdf'],
  product: ['productDetails', 'productList', 'productFacetNavigation', 'productSummary', 'productReview', 'productCarousel', 'addToCart', 'TabPanelContainer', 'addToWishList', 'stockNotification', 'itemCounter', 'productView'],
  pwa: ['pwa'],
  siteThemeSwitcher: ['siteThemeSwitcher'],
  user: ['anonymousConsents', 'checkoutLogin', 'authMessages'],
  video: ['player']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/*
 * Public API Surface of assets
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: translations
 *             +   resources: { en: translationsEn }
 *               }
 *             ```
 */
const translations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { translationChunksConfig, translations, cs as translationsCs, de as translationsDe, en as translationsEn, es as translationsEs, es_CO as translationsEs_CO, fr as translationsFr, hi as translationsHi, hu as translationsHu, id as translationsId, it as translationsIt, ja as translationsJa, ko as translationsKo, pl as translationsPl, pt as translationsPt, ru as translationsRu, zh as translationsZh, zh_TW as translationsZh_TW };
