<div class="summary">
  <div class="summary-title"><p class="summary-title-p">Order Summary</p></div>
  <div class="summary-fee">
    <div class="summary-fee-div">
      <p class="summary-fee-div-title">Quote fee</p>
      <p class="summary-fee-div-value">$250.00</p>
    </div>
    <hr />
  </div>
  <div class="summary-total">
    <div class="summary-total-cart">
      <p class="summary-total-cart-title">Cart total</p>
      <p class="summary-total-cart-value">$250.00</p>
    </div>
    <p class="summary-total-order">(Billable only an order)</p>
    <hr />
  </div>
  <div class="summary-agree">
    <input
      class="float-left ml-2"
      id="check-all"
      type="checkbox"
      value="true"
      name="check-all"
    />
    <p class="summary-agree-title">
      I agree to the <span class="summary-agree-title-span"
        >Terms & Conditions</span
      >
      <!-- [(ngModel)]="checkAll" -->
    </p>
  </div>
  <div class="summary-checkout">
    <bh-button
      class="return-btn"
      [type]="'primary'"
      label="Proceed to checkout"
    ></bh-button>
  </div>
</div>
