var cartDetails$g = {
  id: "ID",
  proceedToCheckout: "Pokračovat k pokladně",
  cartName: "Košík č. {{code}}"
};
var cartItems$g = {
  id: "ID",
  description: "Popis",
  item: "Položka",
  itemPrice: "Cena položky",
  quantity: "Množství",
  quantityFull: "Množství",
  quantityTitle: "Množství znamená celkové množství této položky ve vašem košíku.",
  total: "Celkem",
  actions: "Akce",
  cartTotal: "Košík celkem ({{count}} položka)",
  cartTotal_other: "Košík celkem (Počet položek: {{count}})",
  itemRemoved: "Vybraná položka byla vyjmuta. Součet košíku byla aktualizován.",
  caption: "Obsah nákupního košíku."
};
var orderCost$g = {
  orderSummary: "Souhrn objednávky",
  subtotal: "Mezisoučet po slevách:",
  shipping: "Doručení:",
  estimatedShipping: "Odhadované datum dodání:",
  discount: "Ušetřili jste:",
  salesTax: "Prodejní daň",
  grossTax: "Celková suma objednávky nezahrnuje daň",
  grossIncludeTax: "Celková suma objednávky zahrnuje daň",
  total: "Celkem:",
  toBeDetermined: "Bude určeno později"
};
var voucher$g = {
  coupon: "Máte kupón?",
  coupon_other: "Kódy kupónů",
  couponLabel: "Sem zadejte propagační kód",
  addCoupon: "Přidat kupón",
  apply: "Použít",
  placeholder: "Propagační kód",
  applyVoucherSuccess: "{{voucherCode}} byl použit.",
  removeVoucherSuccess: "{{voucherCode}} byl odebrán.",
  anchorLabel: "Zadejte nebo odeberte kód kupónu",
  vouchersApplied: "Použité kupóny",
  availableCoupons: "Dostupné kupóny",
  availableCouponsLabel: "Na tuto objednávku můžete použít tyto kupóny."
};
var saveForLaterItems$g = {
  itemTotal: "Uloženo na později (položek: {{count}})",
  itemTotal_other: "Uloženo na později (položek: {{count}})",
  cartTitle: "Košík",
  saveForLater: "Uložit na později",
  moveToCart: "Přesunout do košíku",
  stock: "Zásoba",
  forceInStock: "Na skladě"
};
var clearCart$g = {
  clearCart: "Vyprázdnit košík",
  clearingCart: "Vyprazdňování košíku…",
  cartClearedSuccessfully: "Aktivní košík byl úspěšně vyprázdněn.",
  areYouSureToClearCart: "Opravdu chcete vyprázdnit tento košík?",
  allItemsWillBeRemoved: "Všechny položky v aktuálním košíku budou odebrány."
};
var validation$g = {
  cartEntriesChangeDuringCheckout: "U pokladny se vyskytl problém s vašimi položkami. Zkontrolujte svůj košík.",
  cartEntryRemoved: "Položka {{name}} byla odebrána z vašeho košíku, protože není skladem.",
  productOutOfStock: "Z košíku byla odebrána položka {{name}} z důvodu nedostatečného množství zásob.",
  lowStock: "Množství bylo sníženo na {{quantity}} kvůli nedostatečnému stavu zásob.",
  reviewConfiguration: "Nejprve vyřešte problémy v konfiguraci pro položku v košíku.",
  configurationError: "Nejprve vyřešte problémy v konfiguraci pro položku v košíku.",
  pricingError: "Ceny konfigurátoru nejsou v současné době k dispozici. Odeslání objednávky/nabídky není možné. Zkuste to později.",
  unresolvableIssues: "Konfigurace produktu vyžaduje další záznamy v backendu. Proto nemůžete pokračovat. Obraťte se na podporu.",
  inProgress: "Zpracování"
};
var cart$g = {
  cartDetails: cartDetails$g,
  cartItems: cartItems$g,
  orderCost: orderCost$g,
  voucher: voucher$g,
  saveForLaterItems: saveForLaterItems$g,
  clearCart: clearCart$g,
  validation: validation$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  cart: cart$g
};
var cartDetails$f = {
  id: "ID",
  proceedToCheckout: "Zur Kasse gehen",
  cartName: "Warenkorbnr. {{code}}"
};
var cartItems$f = {
  id: "ID",
  description: "Beschreibung",
  item: "Position",
  itemPrice: "Positionspreis",
  quantity: "Menge",
  quantityFull: "Menge",
  quantityTitle: "Die Menge stellt die Gesamtmenge dieser Position in Ihrem Warenkorb dar.",
  total: "Gesamtsumme",
  actions: "Aktionen",
  cartTotal: "Gesamtsumme des Warenkorbs ({{count}} Position)",
  cartTotal_other: "Gesamtsumme des Warenkorbs ({{count}} Positionen)",
  itemRemoved: "Ausgewählte Position wurde entfernt. Gesamtsumme des Warenkorbs wurde aktualisiert.",
  caption: "Inhalte des Warenkorbs."
};
var orderCost$f = {
  orderSummary: "Bestellungszusammenfassung",
  subtotal: "Zwischensumme nach Rabatten:",
  shipping: "Versand:",
  estimatedShipping: "Versand voraussichtlich:",
  discount: "Sie haben gespart:",
  salesTax: "Umsatzsteuer",
  grossTax: "Die Bestellsumme enthält nicht die Steuer von",
  grossIncludeTax: "Die Bestellsumme enthält die Steuer von",
  total: "Gesamtsumme:",
  toBeDetermined: "Noch nicht festgelegt"
};
var voucher$f = {
  coupon: "Haben Sie einen Coupon?",
  coupon_other: "Couponcodes",
  couponLabel: "Hier einen Aktionscode eingeben",
  addCoupon: "Coupon hinzufügen",
  apply: "Anwenden",
  placeholder: "Aktionscode",
  applyVoucherSuccess: "{{voucherCode}} wurde angewendet.",
  removeVoucherSuccess: "{{voucherCode}} wurde entfernt.",
  anchorLabel: "Couponcode eingeben oder entfernen",
  vouchersApplied: "Angewendete Coupons",
  availableCoupons: "Verfügbare Coupons",
  availableCouponsLabel: "Sie können der Bestellung diese Coupons hinzufügen."
};
var saveForLaterItems$f = {
  itemTotal: "Für später gespeichert ({{count}} Position)",
  itemTotal_other: "Für später gespeichert ({{count}} Positionen)",
  cartTitle: "Warenkorb",
  saveForLater: "Für später speichern",
  moveToCart: "In Warenkorb verschieben",
  stock: "Bestand",
  forceInStock: "Vorrätig"
};
var clearCart$f = {
  clearCart: "Warenkorb löschen",
  clearingCart: "Warenkorb wird gelöscht...",
  cartClearedSuccessfully: "Aktiver Warenkorb wurde erfolgreich gelöscht.",
  areYouSureToClearCart: "Möchten Sie diesen Warenkorb wirklich löschen?",
  allItemsWillBeRemoved: "Alle Positionen in Ihrem aktiven Warenkorb werden entfernt."
};
var validation$f = {
  cartEntriesChangeDuringCheckout: "Beim Bezahlen wurden Probleme mit Ihren Einträgen festgestellt. Überprüfen Sie Ihren Warenkorb.",
  cartEntryRemoved: "{{name}} ist nicht vorrätig und wurde deshalb aus dem Warenkorb entfernt.",
  productOutOfStock: "Aufgrund des zu geringen Bestands wurde {{name}} aus dem Warenkorb entfernt.",
  lowStock: "Menge wurde aufgrund des zu geringen Bestands auf {{quantity}} verringert.",
  reviewConfiguration: "Beheben Sie zuerst die Probleme in der Konfiguration für den Warenkorbeintrag.",
  configurationError: "Beheben Sie zuerst die Probleme in der Konfiguration für Warenkorbeinträge.",
  pricingError: "Konfiguratorpreisfindung ist zurzeit nicht verfügbar. Kaufabwicklung/Angebotsübermittlung ist nicht möglich. Versuchen Sie es später noch einmal.",
  unresolvableIssues: "Für die Produktkonfiguration sind zusätzliche Einträge im Backend erforderlich. Deshalb können Sie nicht fortfahren. Wenden Sie sich an den Support.",
  inProgress: "Wird bearbeitet"
};
var cart$f = {
  cartDetails: cartDetails$f,
  cartItems: cartItems$f,
  orderCost: orderCost$f,
  voucher: voucher$f,
  saveForLaterItems: saveForLaterItems$f,
  clearCart: clearCart$f,
  validation: validation$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  cart: cart$f
};
var cartDetails$e = {
  id: "ID",
  proceedToCheckout: "Proceed to Checkout",
  cartName: "Cart #{{code}}"
};
var cartItems$e = {
  id: "ID",
  description: "Description",
  item: "Item",
  itemPrice: "Item price",
  quantity: "Qty",
  quantityFull: "Quantity",
  quantityTitle: "The quantity represents the total number of this item in your cart.",
  total: "Total",
  actions: "Actions",
  cartTotal: "Cart total ({{count}} item)",
  cartTotal_other: "Cart total ({{count}} items)",
  itemRemoved: "Selected item has been removed. Cart total has been updated.",
  caption: "Shopping cart contents."
};
var orderCost$e = {
  orderSummary: "Order Summary",
  subtotal: "Subtotal after discounts:",
  shipping: "Shipping:",
  estimatedShipping: "Estimated shipping:",
  discount: "You saved:",
  salesTax: "Sales Tax:",
  grossTax: "The order total does not include tax of",
  grossIncludeTax: "The order total includes tax of",
  total: "Total:",
  toBeDetermined: "TBD"
};
var voucher$e = {
  coupon: "Have a coupon?",
  coupon_other: "Coupon codes",
  couponLabel: "Enter a promo code here",
  addCoupon: "Add a coupon",
  apply: "Apply",
  placeholder: "Promo code",
  applyVoucherSuccess: "{{voucherCode}} has been applied.",
  removeVoucherSuccess: "{{voucherCode}} has been removed.",
  anchorLabel: "Enter or remove your coupon code",
  vouchersApplied: "Applied coupons",
  availableCoupons: "Available coupons",
  availableCouponsLabel: "You can add these coupons to this order."
};
var saveForLaterItems$e = {
  itemTotal: "Saved for later ({{count}} item)",
  itemTotal_other: "Saved for later ({{count}} items)",
  cartTitle: "Cart",
  saveForLater: "Save For Later",
  moveToCart: "Move To Cart",
  stock: "Stock",
  forceInStock: "In Stock"
};
var clearCart$e = {
  clearCart: "Clear Cart",
  clearingCart: "Clearing Cart...",
  cartClearedSuccessfully: "Active cart cleared successfully.",
  areYouSureToClearCart: "Are you sure you want to clear this cart?",
  allItemsWillBeRemoved: "All items in your active cart will be removed."
};
var validation$e = {
  cartEntriesChangeDuringCheckout: "During checkout we found problems with your entries. Please review your cart.",
  cartEntryRemoved: "{{name}} was removed from the cart due to being out of stock.",
  productOutOfStock: "{{name}} has been removed from the cart due to insufficient stock.",
  lowStock: "Quantity has reduced to {{quantity}} due to insufficient stock.",
  reviewConfiguration: "Resolve the issues in the configuration for cart entry first.",
  configurationError: "Resolve the issues in the configuration for cart entries first.",
  pricingError: "Configurator pricing is currently not available. Checkout/quote submission is not possible. Please try again later.",
  unresolvableIssues: "The product configuration requires additional entries in the back end. As a result, you cannot proceed. Please contact support.",
  inProgress: "Processing"
};
var cart$e = {
  cartDetails: cartDetails$e,
  cartItems: cartItems$e,
  orderCost: orderCost$e,
  voucher: voucher$e,
  saveForLaterItems: saveForLaterItems$e,
  clearCart: clearCart$e,
  validation: validation$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  cart: cart$e
};
var cartDetails$d = {
  id: "ID",
  proceedToCheckout: "Proceder a finalizar la compra",
  cartName: "Cesta #{{code}}"
};
var cartItems$d = {
  id: "ID",
  description: "Descripción",
  item: "Artículo",
  itemPrice: "Precio del artículo",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  quantityTitle: "La cantidad representa el número total de este artículo en su cesta.",
  total: "Total",
  actions: "Acciones",
  cartTotal: "Total de la cesta ({{count}} artículo)",
  cartTotal_other: "Total de la cesta ({{count}} artículos)",
  itemRemoved: "Se ha eliminado el artículo seleccionado. El total de la cesta ha sido actualizado.",
  caption: "Contenido de la cesta de compras."
};
var orderCost$d = {
  orderSummary: "Resumen del pedido",
  subtotal: "Subtotal luego de los descuentos:",
  shipping: "Envío:",
  estimatedShipping: "Envío estimado:",
  discount: "Ha ahorrado:",
  salesTax: "Impuesto sobre las ventas:",
  grossTax: "El total del pedido no incluye impuesto sobre",
  grossIncludeTax: "El total del pedido incluye impuesto sobre",
  total: "Total:",
  toBeDetermined: "A determinar"
};
var voucher$d = {
  coupon: "¿Tiene un cupón?",
  coupon_other: "Códigos de cupón",
  couponLabel: "Introducir un código promocional",
  addCoupon: "Añadir un cupón",
  apply: "Aplicar",
  placeholder: "Código promocional",
  applyVoucherSuccess: "{{voucherCode}} ha sido aplicado.",
  removeVoucherSuccess: "{{voucherCode}} se ha eliminado.",
  anchorLabel: "Introducir o eliminar código promocional",
  vouchersApplied: "Cupones aplicados",
  availableCoupons: "Cupones disponibles",
  availableCouponsLabel: "Puede añadir estos cupones al pedido."
};
var saveForLaterItems$d = {
  itemTotal: "Grabar para más tarde ({{count}} artículo)",
  itemTotal_other: "Grabar para más tarde ({{count}} artículos)",
  cartTitle: "Cesta",
  saveForLater: "Grabar para más tarde",
  moveToCart: "Mover a la cesta",
  stock: "Stock",
  forceInStock: "En stock"
};
var clearCart$d = {
  clearCart: "Vaciar cesta",
  clearingCart: "Vaciando cesta...",
  cartClearedSuccessfully: "Cesta activa vaciada correctamente.",
  areYouSureToClearCart: "¿Está seguro de que desea vaciar esta cesta?",
  allItemsWillBeRemoved: "Se eliminarán todos los artículos de su cesta activa."
};
var validation$d = {
  cartEntriesChangeDuringCheckout: "Durante la finalización de su compra detectamos problemas en sus entradas. Revise su cesta.",
  cartEntryRemoved: "{{name}} se eliminó de la cesta porque está fuera de stock.",
  productOutOfStock: "{{name}} se eliminó de la cesta por stock insuficiente.",
  lowStock: "Se redujo la cantidad a {{quantity}} por stock insuficiente.",
  reviewConfiguration: "Primero, resuelva los problemas en la configuración de la entrada de la cesta.",
  configurationError: "Primero, resuelva los problemas en la configuración de las entradas de la cesta.",
  pricingError: "La determinación de precios no está disponible en este momento. El envío de oferta/compra no es posible. Intente de nuevo más tarde.",
  unresolvableIssues: "La configuración del producto necesita entradas adicionales en el back end. Como consecuencia, no puede avanzar. Póngase en contacto con soporte.",
  inProgress: "Procesando"
};
var cart$d = {
  cartDetails: cartDetails$d,
  cartItems: cartItems$d,
  orderCost: orderCost$d,
  voucher: voucher$d,
  saveForLaterItems: saveForLaterItems$d,
  clearCart: clearCart$d,
  validation: validation$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  cart: cart$d
};
var cartDetails$c = {
  id: "ID",
  proceedToCheckout: "Proceder a finalizar la compra",
  cartName: "Carro #{{code}}"
};
var cartItems$c = {
  id: "ID",
  description: "Descripción",
  item: "Artículo",
  itemPrice: "Precio del artículo",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  quantityTitle: "La cantidad representa el número total de este artículo en su carro.",
  total: "Total",
  actions: "Acciones",
  cartTotal: "Total del carro ({{count}} artículo)",
  cartTotal_other: "Total del carro ({{count}} artículos)",
  itemRemoved: "Se ha eliminado el artículo seleccionado. El total del carro ha sido actualizado.",
  caption: "Contenido del carro de compras."
};
var orderCost$c = {
  orderSummary: "Resumen del pedido",
  subtotal: "Subtotal luego de los descuentos:",
  shipping: "Envío:",
  estimatedShipping: "Envío estimado:",
  discount: "Ahorró:",
  salesTax: "Impuesto sobre las ventas:",
  grossTax: "El total del pedido no incluye impuesto sobre",
  grossIncludeTax: "El total del pedido incluye impuesto sobre",
  total: "Total:",
  toBeDetermined: "A determinar"
};
var voucher$c = {
  coupon: "¿Tiene un cupón?",
  coupon_other: "Códigos de cupón",
  couponLabel: "Introducir un código promocional",
  addCoupon: "Agregar un cupón",
  apply: "Aplicar",
  placeholder: "Código promocional",
  applyVoucherSuccess: "{{voucherCode}} ha sido aplicado.",
  removeVoucherSuccess: "{{voucherCode}} se ha eliminado.",
  anchorLabel: "Introducir o eliminar código promocional",
  vouchersApplied: "Cupones aplicados",
  availableCoupons: "Cupones disponibles",
  availableCouponsLabel: "Puede añadir estos cupones al pedido."
};
var saveForLaterItems$c = {
  itemTotal: "Guardar para más tarde ({{count}} artículo)",
  itemTotal_other: "Guardar para más tarde ({{count}} artículos)",
  cartTitle: "Carro",
  saveForLater: "Guardar para más tarde",
  moveToCart: "Mover al carro",
  stock: "Stock",
  forceInStock: "En stock"
};
var clearCart$c = {
  clearCart: "Vaciar carro",
  clearingCart: "Vaciando carro...",
  cartClearedSuccessfully: "Carro activo vaciado correctamente.",
  areYouSureToClearCart: "¿Está seguro de que desea vaciar este carro?",
  allItemsWillBeRemoved: "Se eliminarán todos los artículos de su carro activo."
};
var validation$c = {
  cartEntriesChangeDuringCheckout: "Durante la finalización de su compra detectamos problemas en sus entradas. Revise su carro.",
  cartEntryRemoved: "{{name}} se eliminó del carro porque está fuera de stock.",
  productOutOfStock: "{{name}} se eliminó del carro por stock insuficiente.",
  lowStock: "Se redujo la cantidad a {{quantity}} por stock insuficiente.",
  reviewConfiguration: "Primero, resuelva los problemas en la configuración de la entrada del carro.",
  configurationError: "Primero, resuelva los problemas en la configuración de las entradas de la cesta.",
  pricingError: "La determinación de precios no está disponible en este momento. El envío de oferta/compra no es posible. Intente de nuevo más tarde.",
  unresolvableIssues: "La configuración del producto necesita entradas adicionales en el back end. Como consecuencia, no puede avanzar. Póngase en contacto con soporte.",
  inProgress: "Procesando"
};
var cart$c = {
  cartDetails: cartDetails$c,
  cartItems: cartItems$c,
  orderCost: orderCost$c,
  voucher: voucher$c,
  saveForLaterItems: saveForLaterItems$c,
  clearCart: clearCart$c,
  validation: validation$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  cart: cart$c
};
var cartDetails$b = {
  id: "ID",
  proceedToCheckout: "Valider la commande",
  cartName: "Panier nº{{code}}"
};
var cartItems$b = {
  id: "ID",
  description: "Description",
  item: "Article",
  itemPrice: "Prix de l'article",
  quantity: "Qté",
  quantityFull: "Quantité",
  quantityTitle: "La quantité représente le nombre total d'unités cet article dans votre panier.",
  total: "Total",
  actions: "Actions",
  cartTotal: "Total du panier ({{count}} article)",
  cartTotal_other: "Total du panier ({{count}} articles)",
  itemRemoved: "L'article sélectionné a été supprimé. Le total du panier a été mis à jour.",
  caption: "Contenu du panier"
};
var orderCost$b = {
  orderSummary: "Synthèse de la commande",
  subtotal: "Sous-total après remises :",
  shipping: "Expédition :",
  estimatedShipping: "Estimation des frais d'expédition :",
  discount: "Vous avez économisé :",
  salesTax: "Taxe sur les ventes :",
  grossTax: "Le total de la commande n'inclut pas la taxe de",
  grossIncludeTax: "Le total de la commande inclut la taxe de",
  total: "Total :",
  toBeDetermined: "À déterminer"
};
var voucher$b = {
  coupon: "Vous avez un bon de réduction ?",
  coupon_other: "Codes promo",
  couponLabel: "Saisissez un code promo ici",
  addCoupon: "Ajouter un bon de réduction",
  apply: "Appliquer",
  placeholder: "Code promo",
  applyVoucherSuccess: "{{voucherCode}} a été appliqué.",
  removeVoucherSuccess: "{{voucherCode}} a été supprimé.",
  anchorLabel: "Saisissez ou supprimez votre code promo",
  vouchersApplied: "Bons de réduction appliqués",
  availableCoupons: "Bons de réduction disponibles",
  availableCouponsLabel: "Vous pouvez ajouter ces bons de réduction à cette commande.  "
};
var saveForLaterItems$b = {
  itemTotal: "Sauvegardé pour plus tard ({{count}} article)",
  itemTotal_other: "Sauvegardés pour plus tard ({{count}} articles)",
  cartTitle: "Panier",
  saveForLater: "Sauvegarder pour plus tard",
  moveToCart: "Déplacer vers le panier",
  stock: "Stock",
  forceInStock: "En stock"
};
var clearCart$b = {
  clearCart: "Vider le panier",
  clearingCart: "Suppression des articles du panier...",
  cartClearedSuccessfully: "Le panier actif a été vidé avec succès.",
  areYouSureToClearCart: "Voulez-vous vraiment vider ce panier ?",
  allItemsWillBeRemoved: "Tous les articles de votre panier actif seront supprimés."
};
var validation$b = {
  cartEntriesChangeDuringCheckout: "Lors de la validation de la commande, nous avons trouvé des problèmes avec vos entrées. Veuillez vérifier votre panier.",
  cartEntryRemoved: "{{name}} a été retiré du panier en raison d'une rupture de stock.",
  productOutOfStock: "{{name}} a été retiré du panier en raison d'un stock insuffisant.",
  lowStock: "La quantité a été réduite à {{quantity}} en raison d'un stock insuffisant.",
  reviewConfiguration: "Commencez par résoudre les problèmes de configuration de la saisie du panier.",
  configurationError: "Commencez par résoudre les problèmes de configuration des saisies du panier.",
  pricingError: "La tarification du configurateur est actuellement indisponible. Il est impossible de valider la commande ou de soumettre un devis. Veuillez réessayer ultérieurement.",
  unresolvableIssues: "La configuration du produit nécessite des entrées supplémentaires dans le back-end. Par conséquent, vous ne pouvez pas poursuivre. Veuillez contacter le service d'assistance.",
  inProgress: "En cours de traitement"
};
var cart$b = {
  cartDetails: cartDetails$b,
  cartItems: cartItems$b,
  orderCost: orderCost$b,
  voucher: voucher$b,
  saveForLaterItems: saveForLaterItems$b,
  clearCart: clearCart$b,
  validation: validation$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  cart: cart$b
};
var cartDetails$a = {
  id: "ID",
  proceedToCheckout: "चेक आउट करने के लिए आगे बढ़ें",
  cartName: "कार्ट #{{code}}"
};
var cartItems$a = {
  id: "ID",
  description: "वर्णन",
  item: "आइटम",
  itemPrice: "आइटम मूल्य",
  quantity: "मात्रा",
  quantityFull: "मात्रा",
  quantityTitle: "मात्रा आपकी कार्ट में इस आइटम की कुल संख्या का प्रतिनिधित्व करती है.",
  total: "कुल",
  actions: "क्रियाएं",
  cartTotal: "कार्ट का कुल योग  ({{count}} आइटम)",
  cartTotal_other: "कार्ट का कुल योग  ({{count}} आइटम)",
  itemRemoved: "चयित आइटम निकाले गए हैं. कार्ट का कुल योग अपडेट किया गया.",
  caption: "शॉपिंग कार्ट की सामग्री."
};
var orderCost$a = {
  orderSummary: "ऑर्डर सारांश",
  subtotal: "छूट के बाद उप-योगः",
  shipping: "भेजा जा रहा हैः",
  estimatedShipping: "अनुमानित शिपिंगः",
  discount: "आपने सहेजाः",
  salesTax: "विक्रय करः",
  grossTax: "ऑर्डर के कुल योग में इसका कर शामिल नहीं है",
  grossIncludeTax: "ऑर्डर के कुल योग में इसका कर शामिल है",
  total: "कुल:",
  toBeDetermined: "TBD"
};
var voucher$a = {
  coupon: "कोई कूपन है?",
  coupon_other: "कूपन कोड",
  couponLabel: "यहां प्रोमो कोड दर्ज करें",
  addCoupon: "कूपन जोड़ें",
  apply: "लागू करें",
  placeholder: "प्रोमो कोड",
  applyVoucherSuccess: "{{voucherCode}} लागू किया गया.",
  removeVoucherSuccess: "{{voucherCode}} निकाला गया",
  anchorLabel: "अपना कूपन कोड दर्ज करें या निकालें",
  vouchersApplied: "लागू कूपन",
  availableCoupons: "उपलब्ध कूपन",
  availableCouponsLabel: "आप इस ऑर्डर पर ये कूप जोड़ सकते हैं."
};
var saveForLaterItems$a = {
  itemTotal: "बाद के लिए सहेजा गया ({{count}} आइटम)",
  itemTotal_other: "बाद के लिए सहेजा गया ({{count}} आइटम)",
  cartTitle: "कार्ट",
  saveForLater: "बाद के लिए सहेजें",
  moveToCart: "कार्ट में ले जाएं",
  stock: "स्टॉक",
  forceInStock: "स्टॉक में है"
};
var clearCart$a = {
  clearCart: "कार्ट साफ करें",
  clearingCart: "कार्ट साफ कर रहे हैं...",
  cartClearedSuccessfully: "सक्रिय कार्ट सफलतापूर्वक साफ किया गया.",
  areYouSureToClearCart: "क्या आप वाकई में यह कार्ट साफ करना चाहते हैं?",
  allItemsWillBeRemoved: "आपकी सक्रिय कार्ट के सभी आइटम निकाले जाएंगे."
};
var validation$a = {
  cartEntriesChangeDuringCheckout: "चेकआउट के दौरान आपकी प्रविष्टियों में हमें समस्याएं मिलीं. कृपया अपनी कार्ट की समीक्षा करें.",
  cartEntryRemoved: "स्टॉक में न होने के कारण आपकी कार्ट से {{name}} निकाला गया.",
  productOutOfStock: "अपर्याप्त स्टॉक के कारण {{name}} को कार्ट से निकाला गया.",
  lowStock: "अपर्याप्त स्टॉक के कारण मात्रा {{quantity}} तक कम हुई है.",
  reviewConfiguration: "पहले कार्ट प्रविष्टि के लिए कॉन्फ़िगरेशन में समस्याओं का समाधान करें.",
  configurationError: "पहले कार्ट प्रविष्टियों के लिए कॉन्फ़िगरेशन में समस्याओं का समाधान करें.",
  pricingError: "कॉन्फ़िगरेटर मूल्य-निर्धारण वर्तमान में उपलब्ध नहीं है. चेकआउट/उद्धरण सबमिशन संभव नहीं है. कृपया फिर से प्रयास करें.",
  unresolvableIssues: "उत्पाद कॉन्फ़िगरेशन को बैक एंड में अतिरिक्त प्रविष्टियों की आवश्यकता है. परिणाम स्वरूप, आप आगे नहीं बढ़ सकते. कृपया सहायता के लिए संपर्क करें.",
  inProgress: "प्रक्रिया हो रही है"
};
var cart$a = {
  cartDetails: cartDetails$a,
  cartItems: cartItems$a,
  orderCost: orderCost$a,
  voucher: voucher$a,
  saveForLaterItems: saveForLaterItems$a,
  clearCart: clearCart$a,
  validation: validation$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  cart: cart$a
};
var cartDetails$9 = {
  id: "Azonosító",
  proceedToCheckout: "Tovább a fizetéshez",
  cartName: "Kosár #{{code}}"
};
var cartItems$9 = {
  id: "Azonosító",
  description: "Leírás",
  item: "Tétel",
  itemPrice: "Tétel ára",
  quantity: "Menny.",
  quantityFull: "Mennyiség",
  quantityTitle: "A mennyiség a tétel teljes darabszámát jelzi a kosárban.",
  total: "Összesen",
  actions: "Műveletek",
  cartTotal: "Kosár összege ({{count}} tétel)",
  cartTotal_other: "Kosár összege ({{count}} tétel)",
  itemRemoved: "A kiválasztott tétel el lett távolítva. A kosár összege frissült.",
  caption: "A bevásárlókosár tartalma."
};
var orderCost$9 = {
  orderSummary: "Rendelés összefoglalása",
  subtotal: "Részösszeg az engedmények után:",
  shipping: "Kiszállítás:",
  estimatedShipping: "Becsült kiszállítás:",
  discount: "Megtakarítás:",
  salesTax: "Forgalmi adó:",
  grossTax: "A rendelés összege nem tartalmazza az adókat:",
  grossIncludeTax: "A rendelés összege tartalmazza az adókat:",
  total: "Összesen:",
  toBeDetermined: "Meghatározandó"
};
var voucher$9 = {
  coupon: "Szeretne felhasználni egy kupont?",
  coupon_other: "Kuponkódok",
  couponLabel: "Itt megadhatja a promóciós kódot",
  addCoupon: "Kupon hozzáadása",
  apply: "Alkalmaz",
  placeholder: "Promóciós kód",
  applyVoucherSuccess: "{{voucherCode}} alkalmazva.",
  removeVoucherSuccess: "{{voucherCode}} eltávolítva.",
  anchorLabel: "Kuponkód megadása vagy eltávolítása",
  vouchersApplied: "Alkalmazott kuponok",
  availableCoupons: "Elérhető kuponok",
  availableCouponsLabel: "Ezeket a kuponokat hozzáadhatja a rendeléshez."
};
var saveForLaterItems$9 = {
  itemTotal: "Mentve későbbre ({{count}} tétel)",
  itemTotal_other: "Mentve későbbre ({{count}} tétel)",
  cartTitle: "Kosár",
  saveForLater: "Mentés későbbre",
  moveToCart: "Kosárba",
  stock: "Készlet",
  forceInStock: "Készleten"
};
var clearCart$9 = {
  clearCart: "Kosár kiürítése",
  clearingCart: "Kosár kiürítése...",
  cartClearedSuccessfully: "Az aktív kosár kiürítése sikeres.",
  areYouSureToClearCart: "Biztosan szeretné kiüríteni ezt a kosarat?",
  allItemsWillBeRemoved: "Az aktív kosárban található minden tétel el lesz távolítva."
};
var validation$9 = {
  cartEntriesChangeDuringCheckout: "A fizetés során hibát észleltünk a bejegyzéseiben. Ellenőrizze a kosarát.",
  cartEntryRemoved: "A(z) {{name}} nincs készleten, így el lett távolítva a kosárból.",
  productOutOfStock: "Nincs elegendő készlet, így a(z) {{name}} el lett távolítva a kosárból.",
  lowStock: "Mennyiség a következőre csökkentve alacsony készlet miatt: {{quantity}}.",
  reviewConfiguration: "Előbb oldja meg a kosárbejegyzés konfigurációs problémáit.",
  configurationError: "Előbb oldja meg a kosárbejegyzések konfigurációs problémáit.",
  pricingError: "A konfiguráló árképzése jelenleg nem áll rendelkezésre. Fizetés/ajánlat beküldése nem lehetséges. Próbálkozzon újra később.",
  unresolvableIssues: "A termék konfigurációja kiegészítő bejegyzéseket igényel a háttérrendszerben. Emiatt nem tud továbblépni. Forduljon a támogatáshoz.",
  inProgress: "Feldolgozás"
};
var cart$9 = {
  cartDetails: cartDetails$9,
  cartItems: cartItems$9,
  orderCost: orderCost$9,
  voucher: voucher$9,
  saveForLaterItems: saveForLaterItems$9,
  clearCart: clearCart$9,
  validation: validation$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  cart: cart$9
};
var cartDetails$8 = {
  id: "ID",
  proceedToCheckout: "Lanjutkan ke Pembayaran",
  cartName: "Keranjang #{{code}}"
};
var cartItems$8 = {
  id: "ID",
  description: "Deskripsi",
  item: "Item",
  itemPrice: "Harga item",
  quantity: "Jml",
  quantityFull: "Jumlah",
  quantityTitle: "Jumlah menunjukkan jumlah total item ini dalam keranjang Anda.",
  total: "Total",
  actions: "Tindakan",
  cartTotal: "Total keranjang ({{count}} item)",
  cartTotal_other: "Total keranjang ({{count}} item)",
  itemRemoved: "Item yang dipilih telah dihapus. Total keranjang telah diperbarui.",
  caption: "Isi keranjang belanja."
};
var orderCost$8 = {
  orderSummary: "Ringkasan Pesanan",
  subtotal: "Subtotal setelah diskon:",
  shipping: "Pengiriman:",
  estimatedShipping: "Estimasi pengiriman:",
  discount: "Anda hemat:",
  salesTax: "Pajak Penjualan:",
  grossTax: "Total pesanan tidak termasuk pajak sebesar",
  grossIncludeTax: "Total pesanan termasuk pajak sebesar",
  total: "Total:",
  toBeDetermined: "Menyusul"
};
var voucher$8 = {
  coupon: "Punya kupon?",
  coupon_other: "Kode kupon",
  couponLabel: "Masukkan kode promo di sini",
  addCoupon: "Tambahkan kupon",
  apply: "Terapkan",
  placeholder: "Kode promo",
  applyVoucherSuccess: "{{voucherCode}} telah diterapkan.",
  removeVoucherSuccess: "{{voucherCode}} telah dihapus.",
  anchorLabel: "Masukkan atau hapus kode kupon Anda",
  vouchersApplied: "Kupon yang diterapkan",
  availableCoupons: "Kupon yang tersedia",
  availableCouponsLabel: "Anda dapat menambahkan kupon ke pesanan ini."
};
var saveForLaterItems$8 = {
  itemTotal: "Disimpan untuk nanti ({{count}} item)",
  itemTotal_other: "Disimpan untuk nanti ({{count}} item)",
  cartTitle: "Keranjang",
  saveForLater: "Simpan Untuk Nanti",
  moveToCart: "Pindahkan Ke Keranjang",
  stock: "Stok",
  forceInStock: "Stok Tersedia"
};
var clearCart$8 = {
  clearCart: "Hapus Keranjang",
  clearingCart: "Menghapus Keranjang...",
  cartClearedSuccessfully: "Keranjang aktif berhasil dihapus.",
  areYouSureToClearCart: "Apakah Anda yakin ingin menghapus keranjang ini?",
  allItemsWillBeRemoved: "Semua item dalam keranjang aktif Anda akan dihapus."
};
var validation$8 = {
  cartEntriesChangeDuringCheckout: "Kami menemukan masalah dalam entri Anda saat proses pembayaran. Silakan tinjau keranjang Anda.",
  cartEntryRemoved: "{{name}} dihapus dari keranjang karena stok habis.",
  productOutOfStock: "{{name}} telah dihapus dari keranjang karena stok kurang.",
  lowStock: "Jumlah telah dikurangi menjadi {{quantity}} karena stok kurang.",
  reviewConfiguration: "Selesaikan masalah dalam konfigurasi entri pada keranjang terlebih dahulu.",
  configurationError: "Selesaikan masalah dalam konfigurasi entri pada keranjang terlebih dahulu.",
  pricingError: "Penentuan harga dengan konfigurator saat ini tidak tersedia. Anda tidak dapat melakukan proses pembayaran/pengajuan penawaran. Silakan coba lagi nanti.",
  unresolvableIssues: "Konfigurasi produk memerlukan entri tambahan pada back end. Akibatnya, Anda tidak dapat memprosesnya. Silakan hubungi tim dukungan.",
  inProgress: "Memproses"
};
var cart$8 = {
  cartDetails: cartDetails$8,
  cartItems: cartItems$8,
  orderCost: orderCost$8,
  voucher: voucher$8,
  saveForLaterItems: saveForLaterItems$8,
  clearCart: clearCart$8,
  validation: validation$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  cart: cart$8
};
var cartDetails$7 = {
  id: "ID",
  proceedToCheckout: "Passa al check-out",
  cartName: "Carrello n. {{code}}"
};
var cartItems$7 = {
  id: "ID",
  description: "Descrizione",
  item: "Articolo",
  itemPrice: "Prezzo articolo",
  quantity: "Qtà",
  quantityFull: "Quantità",
  quantityTitle: "La quantità rappresenta il numero totale di questo articolo nel carrello acquisti.",
  total: "Totale",
  actions: "Azioni",
  cartTotal: "Totale carrello acquisti ({{count}} articolo)",
  cartTotal_other: "Totale carrello acquisti ({{count}} articoli)",
  itemRemoved: "L'articolo selezionato è stato rimosso. Il totale del carrello acquisti è stato aggiornato.",
  caption: "Contenuto del carrello acquisti."
};
var orderCost$7 = {
  orderSummary: "Riepilogo dell'ordine",
  subtotal: "Totale parziale dopo gli sconti:",
  shipping: "Spedizione:",
  estimatedShipping: "Spedizione stimata:",
  discount: "Risparmio:",
  salesTax: "IVA:",
  grossTax: "Il totale dell'ordine non include l'imposta pari a",
  grossIncludeTax: "Il totale dell'ordine include l'imposta pari a",
  total: "Totale:",
  toBeDetermined: "Da definire"
};
var voucher$7 = {
  coupon: "Hai un coupon?",
  coupon_other: "Codici coupon",
  couponLabel: "Inserisci qui un codice promozionale",
  addCoupon: "Aggiungi un coupon",
  apply: "Applica",
  placeholder: "Codice promozionale",
  applyVoucherSuccess: "{{voucherCode}} è stato applicato.",
  removeVoucherSuccess: "{{voucherCode}} è stato rimosso.",
  anchorLabel: "Inserisci o rimuovi il codice coupon",
  vouchersApplied: "Coupon applicati",
  availableCoupons: "Coupon disponibili",
  availableCouponsLabel: "È possibile aggiungere questi coupon a questo ordine."
};
var saveForLaterItems$7 = {
  itemTotal: "Salvato per uso successivo ({{count}} articolo)",
  itemTotal_other: "Salvato per uso successivo ({{count}} articoli)",
  cartTitle: "Carrello acquisti",
  saveForLater: "Salva per uso successivo",
  moveToCart: "Sposta nel carrello acquisti",
  stock: "Stock",
  forceInStock: "In stock"
};
var clearCart$7 = {
  clearCart: "Svuota carrello acquisti",
  clearingCart: "Svuotamento del carrello acquisti in corso...",
  cartClearedSuccessfully: "Carrello acquisti attivo svuotato correttamente.",
  areYouSureToClearCart: "Confermare lo svuotamento di questo carrello acquisti?",
  allItemsWillBeRemoved: "Tutti gli articoli nel carrello acquisti verranno rimossi."
};
var validation$7 = {
  cartEntriesChangeDuringCheckout: "Durante il check-out sono stati rilevati problemi con le voci. Rivedere il carrello acquisti.",
  cartEntryRemoved: "{{name}} è stato rimosso dal carrello acquisti perché non disponibile.",
  productOutOfStock: "{{name}} è stato rimosso dal carrello acquisti a causa di scorte insufficienti.",
  lowStock: "Quantità ridotta di {{quantity}} a causa di scorte insufficienti.",
  reviewConfiguration: "Risolvere innanzitutto i problemi nella configurazione della voce nel carrello acquisti.",
  configurationError: "Risolvere innanzitutto i problemi nella configurazione delle voci nel carrello acquisti.",
  pricingError: "Determinazione del prezzo con configuratore al momento non disponibile; impossibile procedere al check-out o inviare offerte. Riprovare più tardi.",
  unresolvableIssues: "La configurazione del prodotto richiede voci supplementari nel back-end. Di conseguenza, non è possibile procedere. Contattare il supporto.",
  inProgress: "In elaborazione"
};
var cart$7 = {
  cartDetails: cartDetails$7,
  cartItems: cartItems$7,
  orderCost: orderCost$7,
  voucher: voucher$7,
  saveForLaterItems: saveForLaterItems$7,
  clearCart: clearCart$7,
  validation: validation$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  cart: cart$7
};
var cartDetails$6 = {
  id: "ID",
  proceedToCheckout: "チェックアウトに進む",
  cartName: "カート番号 {{code}}"
};
var cartItems$6 = {
  id: "ID",
  description: "説明",
  item: "アイテム",
  itemPrice: "商品価格",
  quantity: "数量",
  quantityFull: "数量",
  quantityTitle: "数量はカート内にあるこのアイテムの合計数を表しています。",
  total: "合計",
  actions: "アクション",
  cartTotal: "カート合計 ({{count}} アイテム)",
  cartTotal_other: "カート合計 ({{count}} アイテム)",
  itemRemoved: "選択したアイテムが削除されました。カート合計が更新されました。",
  caption: "ショッピングカートの内容。"
};
var orderCost$6 = {
  orderSummary: "注文内容",
  subtotal: "割引後の小計:",
  shipping: "配送中:",
  estimatedShipping: "配送予定日:",
  discount: "以下を保存しました:",
  salesTax: "消費税:",
  grossTax: "注文合計には次の税は含まれません",
  grossIncludeTax: "注文合計には次の税が含まれます",
  total: "合計: ",
  toBeDetermined: "未定"
};
var voucher$6 = {
  coupon: "クーポンをお持ちですか?",
  coupon_other: "クーポンコード",
  couponLabel: "プロモーションコードはここに入力します",
  addCoupon: "クーポンを追加",
  apply: "適用",
  placeholder: "プロモーションコード",
  applyVoucherSuccess: "{{voucherCode}} が適用されました。",
  removeVoucherSuccess: "{{voucherCode}} が削除されました。",
  anchorLabel: "クーポンコードを入力または削除してください",
  vouchersApplied: "適用されたクーポン",
  availableCoupons: "利用可能なクーポン",
  availableCouponsLabel: "今回の注文ではこれらのクーポンを追加できます"
};
var saveForLaterItems$6 = {
  itemTotal: "保存して後で使用 ({{count}} アイテム)",
  itemTotal_other: "保存して後で使用 ({{count}} アイテム)",
  cartTitle: "カート",
  saveForLater: "保存して後で使用",
  moveToCart: "カートに移動",
  stock: "在庫",
  forceInStock: "在庫有り"
};
var clearCart$6 = {
  clearCart: "カートをクリア",
  clearingCart: "カートのクリア中...",
  cartClearedSuccessfully: "有効なカートが正常にクリアされました。",
  areYouSureToClearCart: "このカートをクリアしてもよろしいですか?",
  allItemsWillBeRemoved: "有効なカート内にあるすべてのアイテムが削除されます。"
};
var validation$6 = {
  cartEntriesChangeDuringCheckout: "チェックアウト中にエントリで問題が見つかりました。カートを確認してください。",
  cartEntryRemoved: "在庫がないため、{{name}} はカートから削除されました。",
  productOutOfStock: "在庫がないため、{{name}} はカートから削除されました。",
  lowStock: "在庫不足のため、数量が {{quantity}} に削減されました。",
  reviewConfiguration: "最初にカートエントリの設定の問題を解決してください。",
  configurationError: "最初にカートエントリの設定の問題を解決してください。",
  pricingError: "価格設定は現在利用できません。チェックアウト/見積を送信できません。後でもう一度実行してください。",
  unresolvableIssues: "商品設定にはバックエンドで追加のエントリが必要です。その結果、続行することができません。サポートに連絡してください。",
  inProgress: "処理中です"
};
var cart$6 = {
  cartDetails: cartDetails$6,
  cartItems: cartItems$6,
  orderCost: orderCost$6,
  voucher: voucher$6,
  saveForLaterItems: saveForLaterItems$6,
  clearCart: clearCart$6,
  validation: validation$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  cart: cart$6
};
var cartDetails$5 = {
  id: "ID",
  proceedToCheckout: "체크아웃 계속하기",
  cartName: "장바구니 #{{code}}"
};
var cartItems$5 = {
  id: "ID",
  description: "설명",
  item: "품목",
  itemPrice: "품목 가격",
  quantity: "수량",
  quantityFull: "수량",
  quantityTitle: "수량은 장바구니에 있는 이 품목의 합계를 나타냅니다.",
  total: "합계",
  actions: "작업",
  cartTotal: "장바구니 합계({{count}}개 품목)",
  cartTotal_other: "장바구니 합계({{count}}개 품목)",
  itemRemoved: "선택한 품목이 제거되었습니다. 장바구니 합계가 업데이트되었습니다.",
  caption: "장바구니 내용"
};
var orderCost$5 = {
  orderSummary: "주문 요약",
  subtotal: "할인 후 소계:",
  shipping: "배송:",
  estimatedShipping: "예상 배송일:",
  discount: "절약한 금액:",
  salesTax: "매출 부가가치세:",
  grossTax: "주문 합계가 다음 세금을 포함하지 않음 -",
  grossIncludeTax: "주문 합계가 다음 세금을 포함 -",
  total: "합계:",
  toBeDetermined: "TBD"
};
var voucher$5 = {
  coupon: "쿠폰이 있습니까?",
  coupon_other: "쿠폰 코드",
  couponLabel: "여기에 프로모션 코드 입력",
  addCoupon: "쿠폰 추가",
  apply: "적용",
  placeholder: "프로모션 코드",
  applyVoucherSuccess: "{{voucherCode}}이(가) 적용되었습니다.",
  removeVoucherSuccess: "{{voucherCode}}이(가) 제거되었습니다.",
  anchorLabel: "쿠폰 코드를 입력하거나 제거하십시오.",
  vouchersApplied: "적용된 쿠폰",
  availableCoupons: "사용할 수 있는 쿠폰",
  availableCouponsLabel: "이 주문에 이 쿠폰을 추가할 수 있습니다."
};
var saveForLaterItems$5 = {
  itemTotal: "저장 후 나중에 사용({{count}}개 품목)",
  itemTotal_other: "저장 후 나중에 사용({{count}}개 품목)",
  cartTitle: "장바구니",
  saveForLater: "저장 후 나중에 사용",
  moveToCart: "장바구니로 이동",
  stock: "재고",
  forceInStock: "재고 있음"
};
var clearCart$5 = {
  clearCart: "장바구니 비우기",
  clearingCart: "장바구니 비우는 중...",
  cartClearedSuccessfully: "사용 중인 장바구니를 비웠습니다.",
  areYouSureToClearCart: "이 장바구니를 비우시겠습니까?",
  allItemsWillBeRemoved: "사용 중인 장바구니의 모든 품목이 제거됩니다."
};
var validation$5 = {
  cartEntriesChangeDuringCheckout: "체크아웃하는 동안 항목에 문제가 있었습니다. 장바구니를 확인하십시오.",
  cartEntryRemoved: "재고가 없어서 {{name}}이(가) 장바구니에서 제거되었습니다.",
  productOutOfStock: "재고 부족으로 인해 {{name}}이(가) 장바구니에서 제거되었습니다.",
  lowStock: "재고 부족으로 인해 수량이  {{quantity}}개로 줄었습니다.",
  reviewConfiguration: "먼저 구성에서 장바구니 항목에 대한 문제를 해결하십시오.",
  configurationError: "먼저 구성에서 장바구니 항목에 대한 문제를 해결하십시오.",
  pricingError: "현재 가격결정 구성을 사용할 수 없습니다. 체크아웃/견적 제출이 불가능합니다. 나중에 다시 시도하십시오.",
  unresolvableIssues: "제품 구성을 위해 백엔드에서 추가로 입력해야 합니다. 따라서 계속할 수 없습니다. 지원 팀에 문의하십시오.",
  inProgress: "처리 중"
};
var cart$5 = {
  cartDetails: cartDetails$5,
  cartItems: cartItems$5,
  orderCost: orderCost$5,
  voucher: voucher$5,
  saveForLaterItems: saveForLaterItems$5,
  clearCart: clearCart$5,
  validation: validation$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  cart: cart$5
};
var cartDetails$4 = {
  id: "ID",
  proceedToCheckout: "Przejdź do realizacji zakupu",
  cartName: "Koszyk nr {{code}}"
};
var cartItems$4 = {
  id: "ID",
  description: "Opis",
  item: "Pozycja",
  itemPrice: "Cena pozycji",
  quantity: "Ilość",
  quantityFull: "Ilość",
  quantityTitle: "Ilość reprezentuje całkowitą liczbę tego produktu w koszyku.",
  total: "Suma",
  actions: "Czynności",
  cartTotal: "Suma koszyka: ({{count}} pozycja)",
  cartTotal_other: "Suma koszyka: ({{count}} pozycje(-i))",
  itemRemoved: "Wybrana pozycja została usunięta. Suma koszyka została zaktualizowana.",
  caption: "Zawartość koszyka zakupów."
};
var orderCost$4 = {
  orderSummary: "Podsumowanie zamówienia",
  subtotal: "Suma pośrednia po rabatach:",
  shipping: "Wysyłka:",
  estimatedShipping: "Szacowana wysyłka:",
  discount: "Oszczędzasz:",
  salesTax: "VAT:",
  grossTax: "Suma zamówienia nie obejmuje podatku",
  grossIncludeTax: "Suma zamówienia zawiera podatek",
  total: "Suma:",
  toBeDetermined: "Do ustalenia"
};
var voucher$4 = {
  coupon: "Masz kupon?",
  coupon_other: "Kody kuponów",
  couponLabel: "Wprowadź kod promocyjny tutaj",
  addCoupon: "Dodaj kupon",
  apply: "Zastosuj",
  placeholder: "Kod promocyjny",
  applyVoucherSuccess: "Zastosowano {{voucherCode}}.",
  removeVoucherSuccess: "Usunięto {{voucherCode}}.",
  anchorLabel: "Wprowadź lub usuń kod kuponu",
  vouchersApplied: "Zastosowane kupony",
  availableCoupons: "Dostępne kupony",
  availableCouponsLabel: "Możesz dodać te kupony do tego zamówienia."
};
var saveForLaterItems$4 = {
  itemTotal: "Zapisane w celu późniejszego użycia (pozycje: {{count}})",
  itemTotal_other: "Zapisane w celu późniejszego użycia (pozycje: {{count}})",
  cartTitle: "Koszyk",
  saveForLater: "Zapisz na później",
  moveToCart: "Przenieś do koszyka",
  stock: "Zapas",
  forceInStock: "W magazynie"
};
var clearCart$4 = {
  clearCart: "Wyczyść koszyk",
  clearingCart: "Czyszczenie koszyka...",
  cartClearedSuccessfully: "Aktywny koszyk został wyczyszczony.",
  areYouSureToClearCart: "Czy na pewno chcesz wyczyścić ten koszyk?",
  allItemsWillBeRemoved: "Wszystkie produkty w aktywnym koszyku zostaną usunięte."
};
var validation$4 = {
  cartEntriesChangeDuringCheckout: "Wystąpiły problemy z twoimi wpisami. Sprawdź swój koszyk.",
  cartEntryRemoved: "Usunięto {{name}} z koszyka z powodu niedostępności w magazynie.",
  productOutOfStock: "Usunięto {{name}} z koszyka z powodu niewystarczającego stanu magazynowego.",
  lowStock: "Ilość została zmniejszona {{quantity}} z powodu niewystarczającego stanu magazynowego.",
  reviewConfiguration: "Najpierw rozwiąż problemy w konfiguracji wpisu koszyka.",
  configurationError: "Najpierw rozwiąż problemy w konfiguracji wpisów koszyka.",
  pricingError: "Ustalanie cen konfiguratora nie jest obecnie dostępne. Realizacja płatności/przesłanie oferty nie jest możliwe. Spróbuj ponownie później.",
  unresolvableIssues: "Konfiguracja produktu wymaga dodatkowych wpisów w systemie zaplecza. W rezultacie nie możesz kontynuować. Prosimy o kontakt z pomocą techniczną.",
  inProgress: "Przetwarzanie"
};
var cart$4 = {
  cartDetails: cartDetails$4,
  cartItems: cartItems$4,
  orderCost: orderCost$4,
  voucher: voucher$4,
  saveForLaterItems: saveForLaterItems$4,
  clearCart: clearCart$4,
  validation: validation$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  cart: cart$4
};
var cartDetails$3 = {
  id: "ID",
  proceedToCheckout: "Prosseguir para o check-out",
  cartName: "Carrinho #{{code}}"
};
var cartItems$3 = {
  id: "ID",
  description: "Descrição",
  item: "Item",
  itemPrice: "Preço do item",
  quantity: "Qtd",
  quantityFull: "Quantidade",
  quantityTitle: "A quantidade representa o número total desse item em seu carrinho.",
  total: "Total",
  actions: "Ações",
  cartTotal: "Total do carrinho ({{count}} item)",
  cartTotal_other: "Total do carrinho ({{count}} itens)",
  itemRemoved: "O item selecionado foi removido. O total do carrinho foi atualizado.",
  caption: "Conteúdo do carrinho de compras."
};
var orderCost$3 = {
  orderSummary: "Resumo do pedido",
  subtotal: "Subtotal após descontos:",
  shipping: "Entrega:",
  estimatedShipping: "Entrega estimada:",
  discount: "Você economizou:",
  salesTax: "IVA:",
  grossTax: "O total do pedido não inclui o imposto de",
  grossIncludeTax: "O total do pedido inclui o imposto de",
  total: "Total:",
  toBeDetermined: "A ser determinado"
};
var voucher$3 = {
  coupon: "Tem um cupom?",
  coupon_other: "Códigos de cupom",
  couponLabel: "Insira um código promocional aqui",
  addCoupon: "Adicione um cupom",
  apply: "Aplicar",
  placeholder: "Código promocional",
  applyVoucherSuccess: "{{voucherCode}} foi aplicado.",
  removeVoucherSuccess: "{{voucherCode}} foi removido.",
  anchorLabel: "Insira ou remova seu código de cupom",
  vouchersApplied: "Cupons aplicados",
  availableCoupons: "Cupons disponíveis",
  availableCouponsLabel: "Você pode adicionar esses cupons a esse pedido."
};
var saveForLaterItems$3 = {
  itemTotal: "Gravado para mais tarde ({{count}} item)",
  itemTotal_other: "Gravado para mais tarde ({{count}} itens)",
  cartTitle: "Carrinho",
  saveForLater: "Gravar para mais tarde",
  moveToCart: "Mover para o carrinho",
  stock: "Estoque",
  forceInStock: "Em estoque"
};
var clearCart$3 = {
  clearCart: "Limpar carrinho",
  clearingCart: "Limpando o carrinho...",
  cartClearedSuccessfully: "O carrinho ativo foi limpo com êxito.",
  areYouSureToClearCart: "Tem certeza de que deseja limpar esse carrinho?",
  allItemsWillBeRemoved: "Todos os itens em seu carrinho ativo serão removidos."
};
var validation$3 = {
  cartEntriesChangeDuringCheckout: "Durante o check-out, encontramos problemas com suas entradas. Revise seu carrinho.",
  cartEntryRemoved: "{{name}} foi removido do carrinho porque está sem estoque.",
  productOutOfStock: "{{name}} foi removido do carrinho devido a estoque insuficiente.",
  lowStock: "A quantidade foi reduzida para {{quantity}} devido a estoque insuficiente.",
  reviewConfiguration: "Resolva primeiro os problemas na configuração para a entrada de carrinho.",
  configurationError: "Resolva primeiro os problemas na configuração para as entradas de carrinho.",
  pricingError: "A determinação do preço do configurador não está disponível no momento. Não é possível fazer check-out/enviar cotações. Tente novamente mais tarde.",
  unresolvableIssues: "A configuração do produto requer entradas adicionais no back-end. Como resultado, você não pode continuar. Contate o suporte.",
  inProgress: "Em processamento"
};
var cart$3 = {
  cartDetails: cartDetails$3,
  cartItems: cartItems$3,
  orderCost: orderCost$3,
  voucher: voucher$3,
  saveForLaterItems: saveForLaterItems$3,
  clearCart: clearCart$3,
  validation: validation$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  cart: cart$3
};
var cartDetails$2 = {
  id: "Ид.",
  proceedToCheckout: "Перейти к оформлению",
  cartName: "Корзина № {{code}}"
};
var cartItems$2 = {
  id: "Ид.",
  description: "Описание",
  item: "Позиция",
  itemPrice: "Цена позиции",
  quantity: "Кол.",
  quantityFull: "Количество",
  quantityTitle: "Количество - это общее число единиц данной позиции в вашей корзине.",
  total: "Итого",
  actions: "Действия",
  cartTotal: "Общая сумма корзины ({{count}} позиция)",
  cartTotal_other: "Общая сумма корзины ({{count}} поз.)",
  itemRemoved: "Выбранная позиция удалена. Общая сумма корзины обновлена.",
  caption: "Содержимое корзины покупок."
};
var orderCost$2 = {
  orderSummary: "Обзор заказа",
  subtotal: "Промежуточная сумма с учетом скидок:",
  shipping: "Доставка:",
  estimatedShipping: "Ожидаемая доставка:",
  discount: "Вы сохранили:",
  salesTax: "Налог с продаж:",
  grossTax: "Общая сумма заказа не включает налог",
  grossIncludeTax: "Общая сумма заказа включает налог",
  total: "Итого:",
  toBeDetermined: "Не определено"
};
var voucher$2 = {
  coupon: "У вас есть купон?",
  coupon_other: "Коды купонов",
  couponLabel: "Введите промокод здесь",
  addCoupon: "Добавить купон",
  apply: "Применить",
  placeholder: "Промокод",
  applyVoucherSuccess: "{{voucherCode}} применен.",
  removeVoucherSuccess: "{{voucherCode}} удален.",
  anchorLabel: "Введите или удалите код купона",
  vouchersApplied: "Примененные купоны",
  availableCoupons: "Доступные купоны",
  availableCouponsLabel: "Вы можете добавить эти купоны в этот заказ."
};
var saveForLaterItems$2 = {
  itemTotal: "Сохранено на будущее ({{count}} позиция)",
  itemTotal_other: "Сохранено на будущее ({{count}} поз.)",
  cartTitle: "Корзина",
  saveForLater: "Сохранить на будущее",
  moveToCart: "Переместить в корзину",
  stock: "Запас",
  forceInStock: "В наличии"
};
var clearCart$2 = {
  clearCart: "Очистить корзину",
  clearingCart: "Очистка корзины...",
  cartClearedSuccessfully: "Активная корзина очищена.",
  areYouSureToClearCart: "Действительно очистить эту корзину?",
  allItemsWillBeRemoved: "Все позиции в вашей активной корзине будут удалены."
};
var validation$2 = {
  cartEntriesChangeDuringCheckout: "При оформлении найдены проблемы с вашими позициями. Проверьте корзину.",
  cartEntryRemoved: "{{name}} - удалено из корзины, поскольку нет в наличии.",
  productOutOfStock: "{{name}} - удалено из корзины, поскольку нет достаточного количества.",
  lowStock: "Количество сокращено до {{quantity}}, поскольку нет достаточного количества.",
  reviewConfiguration: "Сначала устраните проблемы в конфигурации для записи корзины.",
  configurationError: "Сначала устраните проблемы в конфигурации для записей корзины.",
  pricingError: "Конфигуратор расчета цен сейчас недоступен. Оформление/подача предложения невозможны. Повторите попытку позже.",
  unresolvableIssues: "Для конфигурации продукта требуются дополнительные записи в бэкэнде. Дальнейшая обработка невозможна. Обратитесь в службу поддержки.",
  inProgress: "Обработка"
};
var cart$2 = {
  cartDetails: cartDetails$2,
  cartItems: cartItems$2,
  orderCost: orderCost$2,
  voucher: voucher$2,
  saveForLaterItems: saveForLaterItems$2,
  clearCart: clearCart$2,
  validation: validation$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  cart: cart$2
};
var cartDetails$1 = {
  id: "标识",
  proceedToCheckout: "继续结账",
  cartName: "购物车编号 {{code}}"
};
var cartItems$1 = {
  id: "标识",
  description: "描述",
  item: "商品",
  itemPrice: "商品价格",
  quantity: "数量",
  quantityFull: "数量",
  quantityTitle: "数量代表您的购物车中此项目的总数。",
  total: "总计",
  actions: "操作",
  cartTotal: "购物车总计（{{count}} 个项目）",
  cartTotal_other: "购物车总计（{{count}} 个项目）",
  itemRemoved: "所选项目已移除。购物车总计已更新。",
  caption: "购物车内容。"
};
var orderCost$1 = {
  orderSummary: "订单摘要",
  subtotal: "折扣后的小计：",
  shipping: "发货:",
  estimatedShipping: "预计发货：",
  discount: "您已保存：",
  salesTax: "销售税：",
  grossTax: "订单总计不包含税",
  grossIncludeTax: "订单总计包含税",
  total: "总计：",
  toBeDetermined: "待定"
};
var voucher$1 = {
  coupon: "具有优惠卷？",
  coupon_other: "优惠券代码",
  couponLabel: "在此输入促销代码",
  addCoupon: "添加优惠券",
  apply: "应用",
  placeholder: "促销代码",
  applyVoucherSuccess: "已应用 {{voucherCode}}。",
  removeVoucherSuccess: "已移除 {{voucherCode}}。",
  anchorLabel: "输入或移除您的优惠券代码",
  vouchersApplied: "应用的优惠券",
  availableCoupons: "可用优惠券",
  availableCouponsLabel: "您可以将这些优惠券添加到此订单。"
};
var saveForLaterItems$1 = {
  itemTotal: "以后购买（{{count}} 个项目）",
  itemTotal_other: "以后购买（{{count}} 个项目）",
  cartTitle: "购物车",
  saveForLater: "保存备用",
  moveToCart: "移动到购物车",
  stock: "库存",
  forceInStock: "现货"
};
var clearCart$1 = {
  clearCart: "清除购物车",
  clearingCart: "正在清除购物车...",
  cartClearedSuccessfully: "已成功清除活动购物车。",
  areYouSureToClearCart: "是否确定要清除此购物车？",
  allItemsWillBeRemoved: "将移除活动购物车中的所有项目。"
};
var validation$1 = {
  cartEntriesChangeDuringCheckout: "结账时我们发现您的输入存在问题。请检查您的购物车。",
  cartEntryRemoved: "{{name}} 因缺货而从购物车中移除。",
  productOutOfStock: "库存不足，已从购物车中移除 {{name}}.",
  lowStock: "库存不足，数量已减少至 {{quantity}}。",
  reviewConfiguration: "首先解决购物车条目的配置问题。",
  configurationError: "首先解决购物车条目的配置问题。",
  pricingError: "配置器定价当前不可用；无法提交结账/报价。请稍后重试。",
  unresolvableIssues: "产品配置需要后端中的其他条目。因此，您无法继续。请联系支持部门。",
  inProgress: "正在处理"
};
var cart$1 = {
  cartDetails: cartDetails$1,
  cartItems: cartItems$1,
  orderCost: orderCost$1,
  voucher: voucher$1,
  saveForLaterItems: saveForLaterItems$1,
  clearCart: clearCart$1,
  validation: validation$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  cart: cart$1
};
var cartDetails = {
  id: "ID",
  proceedToCheckout: "進行結帳",
  cartName: "購物車號碼 {{code}}"
};
var cartItems = {
  id: "ID",
  description: "說明",
  item: "項目",
  itemPrice: "項目價格",
  quantity: "數量",
  quantityFull: "數量",
  quantityTitle: "數量表示購物車中此項目的總數量。",
  total: "總計",
  actions: "動作",
  cartTotal: "購物車總計 ({{count}} 個項目)",
  cartTotal_other: "購物車總計 ({{count}} 個項目)",
  itemRemoved: "已移除選擇的項目。已更新購物車總計。",
  caption: "購物車內容。"
};
var orderCost = {
  orderSummary: "訂單摘要",
  subtotal: "折扣後小計：",
  shipping: "出貨：",
  estimatedShipping: "預計出貨：",
  discount: "您已節省：",
  salesTax: "銷售稅：",
  grossTax: "訂單總計未包含下列項目的稅",
  grossIncludeTax: "訂單總計包含下列項目的稅",
  total: "總計：",
  toBeDetermined: "待定"
};
var voucher = {
  coupon: "有優惠券嗎？",
  coupon_other: "優惠券代碼",
  couponLabel: "在此輸入促銷代碼",
  addCoupon: "新增優惠券",
  apply: "套用",
  placeholder: "促銷代碼",
  applyVoucherSuccess: "已套用 {{voucherCode}}。",
  removeVoucherSuccess: "已移除 {{voucherCode}}。",
  anchorLabel: "輸入或移除優惠券代碼",
  vouchersApplied: "套用的優惠券",
  availableCoupons: "可用的優惠券",
  availableCouponsLabel: "您可將這些優惠券新增至此訂單。"
};
var saveForLaterItems = {
  itemTotal: "儲存以供稍後使用 ({{count}} 個項目)",
  itemTotal_other: "儲存以供稍後使用 ({{count}} 個項目)",
  cartTitle: "購物車",
  saveForLater: "儲存以供稍後使用",
  moveToCart: "移至購物車",
  stock: "庫存",
  forceInStock: "有存貨"
};
var clearCart = {
  clearCart: "清除購物車",
  clearingCart: "正在清除購物車...",
  cartClearedSuccessfully: "已成功清除啟用中購物車",
  areYouSureToClearCart: "您確定要清除此購物車嗎？",
  allItemsWillBeRemoved: "將移除啟用中購物中的所有項目。"
};
var validation = {
  cartEntriesChangeDuringCheckout: "在結帳期間，我們發現您的項目發生問題。請審查您的購物車。",
  cartEntryRemoved: "已從購物車移除 {{name}}，因為缺貨。",
  productOutOfStock: "已從購物車移除 {{name}}，因為庫存不足。",
  lowStock: "數量已減少為 {{quantity}}，因為庫存不足。",
  reviewConfiguration: "請先針對購物車項目解決組態中的問題。",
  configurationError: "請先針對購物車項目解決組態中的問題。",
  pricingError: "目前無法使用組態器定價。無法提交結帳/報價。請稍後再試。",
  unresolvableIssues: "產品組態需要後端中的其他輸入項，因此無法繼續。請聯絡支援團隊。",
  inProgress: "處理中"
};
var cart = {
  cartDetails: cartDetails,
  cartItems: cartItems,
  orderCost: orderCost,
  voucher: voucher,
  saveForLaterItems: saveForLaterItems,
  clearCart: clearCart,
  validation: validation
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  cart
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cartBaseTranslationChunksConfig = {
  cart: ['cartDetails', 'cartItems', 'orderCost', 'voucher', 'saveForLaterItems', 'clearCart', 'validation']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: cartBaseTranslations
 *             +   resources: { en: cartBaseTranslationsEn }
 *               }
 *             ```
 */
const cartBaseTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { cartBaseTranslationChunksConfig, cartBaseTranslations, cs as cartBaseTranslationsCs, de as cartBaseTranslationsDe, en as cartBaseTranslationsEn, es as cartBaseTranslationsEs, es_CO as cartBaseTranslationsEs_CO, fr as cartBaseTranslationsFr, hi as cartBaseTranslationsHi, hu as cartBaseTranslationsHu, id as cartBaseTranslationsId, it as cartBaseTranslationsIt, ja as cartBaseTranslationsJa, ko as cartBaseTranslationsKo, pl as cartBaseTranslationsPl, pt as cartBaseTranslationsPt, ru as cartBaseTranslationsRu, zh as cartBaseTranslationsZh, zh_TW as cartBaseTranslationsZh_TW };
