<div class="waygate-cart-list-container">
  <div class="waygate-cart-item-list">
    <ds-quote-cart-actions></ds-quote-cart-actions>
    <div class="waygate-cart-selectall-checkbox">
      <hr class="horizontal-line" />
      <div class="waygate-cart-actions">
        <div class="input">
          <input
            class="float-left ml-2"
            id="check-all"
            type="checkbox"
            value="true"
            name="check-all"
            [(ngModel)]="checkAll"
            (click)="checkAllEntries($event)"
          />
        </div>
        <div class="total-line-items">
          <ng-container *ngIf="selectedItems?.length; else allItem"
            >{{ selectedItems?.length }} of {{ items.length }} Items
            Selected</ng-container
          >
          <ng-template #allItem>{{ items.length }} Items</ng-template>
        </div>
      </div>
      <hr class="horizontal-line" />
    </div>

    <div class="waygate-cart-body">
      <div class="cx-item-list-row">
        <div
          class="cx-item-list-row mt-4"
          *ngFor="let item of items; let i = index"
        >
          <div
            class="cx-item-list-items"
            *ngIf="getControl(item) | async as control"
            [class.is-changed]="control.get('quantity').dirty"
          >
            <!-- 
                [quantityControl]="control.get('quantity')"
                [readonly]="readonly"
                [promotionLocation]="promotionLocation"
                [options]="options"
                
                [productOpen]="showToggle"
                [userType]="userType"
                [cart]="cart"
                
                (selectedEntry)="onEntryCheck($event)"
                [isPartialShipment]="isPartialShipment"
                #cartEntry -->
            <ds-quote-cart-item
              [item]="item"
              [entryNum]="i"
              [totalEntries]="items.length"
              [checkAll]="checkAll"
              #cartEntry
            ></ds-quote-cart-item>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="waygate-cart-summary">
    <!-- [cart$]="cart$"
    [userType]="userType"
    (scrollToEntry)="scrollCartEntryIntoView($event)"
    [cart]="cart"
    [items]="items"
    [profileType]="profileType" -->
    <ds-quote-cart-summary></ds-quote-cart-summary>
  </div>
</div>
