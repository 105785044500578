var quickOrderCartForm$g = {
  entriesWereAdded: "Do košíku přidáno: ({{ quantity }}) {{ product }}",
  entryWasAdded: "Do košíku přidáno: {{ product }}",
  noResults: "Nemůžeme najít žádné produkty",
  stockLevelReached: "Byla dosažena maximální úroveň zásob",
  title: "Rychlá objednávka",
  productCodePlaceholder: "Zadejte ID",
  entryProductCode: "Zadejte ID produkty pro rychlou objednávku",
  quantity: "Množství pro rychlou objednávku",
  addToCart: "Přidejte produkt pro rychlou objednávku",
  add: "Přidat",
  product: "Produkt",
  products: "Produkty",
  productCodeLabel: "ID produktu",
  quantityLabel: "Množství",
  quantityFullLabel: "Množství"
};
var quickOrderForm$g = {
  placeholder: "Zadejte název produktu nebo SKU",
  searchBoxLabel: "Zadejte název produktu nebo SKU pro rychlou objednávku. Na jednu objednávku můžete přidat až {{ limit }} produktů.",
  listLimitReached: "Byl dosažen limit produktů.",
  id: "ID {{ id }}",
  noResults: "Nemůžeme najít žádné výsledky",
  addProduct: "Přidat produkt {{ product }}",
  initialDescription: "Až budou výsledky automatického dokončení k dispozici, použijte šipky nahoru a dolů ke kontrole a klávesu Enter k výběru.",
  productsResults: "Počet produktů k dispozici: {{ count }}.",
  quickOrderSearch: "Vyhledejte produkt pro rychlou objednávku"
};
var quickOrderList$g = {
  addToCart: "Přidat do košíku",
  emptyList: "Prázdný seznam",
  header: "Přidat produkty/SKU",
  subHeader: "Najednou můžete přidat max. {{ limit }} platných SKU.",
  errorProceedingToCart: "Při přechodu do košíku došlo k chybě.",
  warningProceedingToCart: "Varování při přechodu do košíku.",
  successfullyAddedToCart: "Úspěšně přidáno do košíku.",
  errors: {
    productIsOutOfStock: "{{ name }} (#{{code}}) není skladem.",
    reviewErrors: "Zkontrolujte tyto chyby.",
    listIsFull: "Seznam je plný. Chcete-li pokračovat, přidejte tyto {{ count }} produkty do košíku.",
    nonPurchasableError: "Produkt {{ name }} nelze zakoupit.",
    outOfStockErrorFound: "{{count}} vyprodaný produkt nebyl přidán.",
    outOfStockErrorFound_other: "{{count}} vyprodané produkty nebyly přidány."
  },
  warnings: {
    productWasReduced: "Množství {{ name }} (#{{code}}) bylo sníženo na {{ quantityAdded}}.",
    reviewWarnings: "Zkontrolujte tato varování.",
    reduceWarningFound: "{{count}} množství produktu bylo sníženo.",
    reduceWarningFound_other: "{{count}} množství produktů bylo sníženo."
  },
  successes: {
    productAddedToCart: "{{ name }} (#{{code}}) přidáno do košíku.",
    addedToCartFound: "{{ count }} produkt přidán do košíku.",
    addedToCartFound_other: "{{ count }} produktů přidáno do košíku."
  },
  informations: {
    addProductBeforeAddingToCart: "Než vložíte produkty do košíku, přidejte je do seznamu."
  },
  undo: "ZPĚT",
  revokeUndo: "Odstranění zrušeného produktu „{{name}}“.",
  productWasDeleted: " Produkt „{{ name }}“ přesunut do koše."
};
var quickOrderTable$g = {
  product: "Produkt",
  id: "ID",
  price: "Cena",
  quantity: "Množství",
  quantityFull: "Množství",
  itemPrice: "Cena položky",
  qty: "Množství",
  actions: "Akce",
  inStock: "Na skladě",
  lowStock: "Nedostatečné množství zásob",
  outOfStock: "Není skladem",
  listCleared: "Seznam pro rychlou objednávku byl vymazán",
  addedtoCart: "Seznam pro rychlou objednávku byl přidán do košíku",
  caption: "Obsah rychlé objednávky."
};
var quickOrder$g = {
  quickOrderCartForm: quickOrderCartForm$g,
  quickOrderForm: quickOrderForm$g,
  quickOrderList: quickOrderList$g,
  quickOrderTable: quickOrderTable$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  quickOrder: quickOrder$g
};
var quickOrderCartForm$f = {
  entriesWereAdded: "({{ quantity }}) {{ product }} wurde dem Warenkorb hinzugefügt",
  entryWasAdded: "{{ product }} wurde dem Warenkorb hinzugefügt",
  noResults: "Es wurden keine Produkte gefunden",
  stockLevelReached: "Der Höchstbestand wurde erreicht",
  title: "Schnellbestellung",
  productCodePlaceholder: "ID eingeben",
  entryProductCode: "Produkt-ID für Schnellbestellung eingeben",
  quantity: "Menge für Schnellbestellung",
  addToCart: "Produkt zur Schnellbestellung hinzufügen",
  add: "Hinzufügen",
  product: "Produkt",
  products: "Produkte",
  productCodeLabel: "Produkt-ID",
  quantityLabel: "Menge",
  quantityFullLabel: "Menge"
};
var quickOrderForm$f = {
  placeholder: "Produktname oder SKU eingeben",
  searchBoxLabel: "Geben Sie den Produktnamen oder die SKU für die Schnellbestellung ein. Sie können bis zu {{ limit }} Produkte pro Bestellung hinzufügen.",
  listLimitReached: "Das Produktlimit wurde erreicht.",
  id: "ID {{ id }}",
  noResults: "Keine Ergebnisse gefunden",
  addProduct: "Produkt {{ product }} hinzufügen",
  initialDescription: "Wenn Ergebnisse aus der automatischen Vervollständigung verfügbar sind, können Sie sie mit den nach oben und unten zeigenden Pfeiltasten ansehen und mit der Eingabetaste auswählen.",
  productsResults: "{{ count }} Produkte sind verfügbar.",
  quickOrderSearch: "Produkte für Schnellbestellung suchen"
};
var quickOrderList$f = {
  addToCart: "In den Warenkorb",
  emptyList: "Liste leeren",
  header: "Produkte/SKUs hinzufügen",
  subHeader: "Sie können bis zu {{ limit }} gültige SKU gleichzeitig hinzufügen.",
  errorProceedingToCart: "Fehler beim Wechsel zum Warenkorb.",
  warningProceedingToCart: "Warnung beim Wechsel zum Warenkorb.",
  successfullyAddedToCart: "Erfolgreich dem Warenkorb hinzugefügt.",
  errors: {
    productIsOutOfStock: "{{ name }} (Nr. {{code}}) ist nicht vorrätig.",
    reviewErrors: "Überprüfen Sie diese Fehler.",
    listIsFull: "Die Liste ist voll, fügen Sie diese {{ count }} Produkte dem Warenkorb hinzu, um fortzufahren.",
    nonPurchasableError: "Produkt {{ name }} kann nicht gekauft werden",
    outOfStockErrorFound: "{{count}} Produkt, das nicht vorrätig ist, wurde nicht hinzugefügt.",
    outOfStockErrorFound_other: "{{count}} Produkte, die nicht vorrätig sind, wurden nicht hinzugefügt."
  },
  warnings: {
    productWasReduced: "Menge für {{ name }} (Nr. {{code}}) wurde auf {{ quantityAdded}} verringert.",
    reviewWarnings: "Überprüfen Sie diese Warnungen.",
    reduceWarningFound: "Menge von {{count}} Produkt wurde verringert.",
    reduceWarningFound_other: "Menge von {{count}} Produkten wurde verringert."
  },
  successes: {
    productAddedToCart: "{{ name }} (Nr. {{code}}) wurde dem Warenkorb hinzugefügt.",
    addedToCartFound: "{{ count }} Produkt wurde dem Warenkorb hinzugefügt.",
    addedToCartFound_other: "{{ count }} Produkte wurden dem Warenkorb hinzugefügt."
  },
  informations: {
    addProductBeforeAddingToCart: "Bevor Sie die Produkte dem Warenkorb hinzufügen, müssen Sie sie der Liste hinzufügen."
  },
  undo: "RÜCKGÄNGIG",
  revokeUndo: "Löschung des Produkts \"{{name}}\" wurde widerrufen.",
  productWasDeleted: "Produkt \"{{ name }}\" wurde in den Papierkorb verschoben."
};
var quickOrderTable$f = {
  product: "Produkt",
  id: "ID",
  price: "Preis",
  quantity: "MENGE",
  quantityFull: "Menge",
  itemPrice: "Positionspreis",
  qty: "Menge",
  actions: "Aktionen",
  inStock: "Vorrätig",
  lowStock: "Niederbestand",
  outOfStock: "Nicht vorrätig",
  listCleared: "Schnellbestellungsliste wurde geleert",
  addedtoCart: "Schnellbestellungsliste wurde dem Warenkorb hinzugefügt",
  caption: "Inhalte der Schnellbestellung."
};
var quickOrder$f = {
  quickOrderCartForm: quickOrderCartForm$f,
  quickOrderForm: quickOrderForm$f,
  quickOrderList: quickOrderList$f,
  quickOrderTable: quickOrderTable$f
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  quickOrder: quickOrder$f
};
var quickOrderCartForm$e = {
  entriesWereAdded: "({{ quantity }}) {{ product }} has been added to the cart",
  entryWasAdded: "{{ product }} has been added to the cart",
  noResults: "We could not find any products",
  stockLevelReached: "The maximum stock level has been reached",
  title: "Quick Order",
  productCodePlaceholder: "Enter ID",
  entryProductCode: "Enter Product ID for Quick Order",
  quantity: "Quantity for Quick Order",
  addToCart: "Add Product to Quick Order",
  add: "Add",
  product: "Product",
  products: "Products",
  productCodeLabel: "Product ID",
  quantityLabel: "Qty",
  quantityFullLabel: "Quantity"
};
var quickOrderForm$e = {
  placeholder: "Enter Product name or SKU",
  searchBoxLabel: "Enter Product name or SKU for quick order. You can add up to {{ limit }} products per order.",
  listLimitReached: "The product limit has been reached.",
  id: "ID {{ id }}",
  noResults: "We could not find any results",
  addProduct: "Add product {{ product }}",
  initialDescription: "When autocomplete results are available use up and down arrows to review and enter to select.",
  productsResults: "{{ count }} products are available.",
  quickOrderSearch: "Find product for quick order"
};
var quickOrderList$e = {
  addToCart: "Add to cart",
  emptyList: "Empty list",
  header: "Add Products/SKUs",
  subHeader: "You can add up to {{ limit }} valid SKU at a time.",
  errorProceedingToCart: "Error proceeding to Cart.",
  warningProceedingToCart: "Warning proceeding to Cart.",
  successfullyAddedToCart: "Successfully added to Cart.",
  errors: {
    productIsOutOfStock: "{{ name }} (#{{code}}) is out of stock.",
    reviewErrors: "Please review these errors.",
    listIsFull: "The list is full, add these {{ count }} products to Cart in order to continue.",
    nonPurchasableError: "Product {{ name }} cannot be purchased",
    outOfStockErrorFound: "{{count}} out of stock product was not added.",
    outOfStockErrorFound_other: "{{count}} out of stock products were not added."
  },
  warnings: {
    productWasReduced: "Quantity for {{ name }} (#{{code}}) was reduced to {{ quantityAdded}}.",
    reviewWarnings: "Please review these warnings.",
    reduceWarningFound: "{{count}} product quantity was reduced.",
    reduceWarningFound_other: "{{count}} products quantity were reduced."
  },
  successes: {
    productAddedToCart: "{{ name }} (#{{code}}) was added to cart.",
    addedToCartFound: "{{ count }} product was added to cart.",
    addedToCartFound_other: "{{ count }} products were added to cart."
  },
  informations: {
    addProductBeforeAddingToCart: "Add products to the list before adding to the cart."
  },
  undo: "UNDO",
  revokeUndo: "Revoked product \"{{name}}\" deletion.",
  productWasDeleted: "Product \"{{ name }}\" moved to trash."
};
var quickOrderTable$e = {
  product: "Product",
  id: "ID",
  price: "Price",
  quantity: "QTY",
  quantityFull: "Quantity",
  itemPrice: "Item price",
  qty: "Qty",
  actions: "Actions",
  inStock: "In Stock",
  lowStock: "Low Stock",
  outOfStock: "Out of Stock",
  listCleared: "Quick order list has been cleared",
  addedtoCart: "Quick order list has been added to the cart",
  caption: "Quick order contents."
};
var quickOrder$e = {
  quickOrderCartForm: quickOrderCartForm$e,
  quickOrderForm: quickOrderForm$e,
  quickOrderList: quickOrderList$e,
  quickOrderTable: quickOrderTable$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  quickOrder: quickOrder$e
};
var quickOrderCartForm$d = {
  entriesWereAdded: "({{ quantity }}) {{ product }} ha sido añadido a la cesta",
  entryWasAdded: "{{ product }} ha sido añadido a la cesta",
  noResults: "No hemos podido encontrar ningún producto",
  stockLevelReached: "Se ha alcanzado el nivel máximo de stock",
  title: "Pedido rápido",
  productCodePlaceholder: "Introducir ID",
  entryProductCode: "Introducir ID de producto para Pedido rápido",
  quantity: "Cantidad para Pedido rápido",
  addToCart: "Añadir producto a Pedido rápido",
  add: "Añadir",
  product: "Producto",
  products: "Productos",
  productCodeLabel: "ID de producto",
  quantityLabel: "Cantidad",
  quantityFullLabel: "Cantidad"
};
var quickOrderForm$d = {
  placeholder: "Introducir Nombre de producto o SKU",
  searchBoxLabel: "Introducir Nombre de producto o SKU para pedido rápido. Puede agregar hasta {{ limit }} productos por pedido.",
  listLimitReached: "Se ha alcanzado el límite del producto.",
  id: "ID {{ id }}",
  noResults: "No hemos podido encontrar ningún resultado",
  addProduct: "Añadir producto {{ product }}",
  initialDescription: "Si están disponibles los resultados de autocompletar, use las flechas de arriba y abajo y la tecla Intro para seleccionar.",
  productsResults: "{{ count }} productos están disponibles.",
  quickOrderSearch: "Buscar producto para pedido rápido"
};
var quickOrderList$d = {
  addToCart: "Añadir a la cesta",
  emptyList: "Vaciar lista",
  header: "Añadir productos/SKU",
  subHeader: "Puede añadir hasta {{ limit }} SKU válidos a la vez.",
  errorProceedingToCart: "Error al pasar a la Cesta.",
  warningProceedingToCart: "Advertencia al pasar a la Cesta.",
  successfullyAddedToCart: "Añadido correctamente a la Cesta.",
  errors: {
    productIsOutOfStock: "{{ name }} (#{{code}}) no está disponible en stock.",
    reviewErrors: "Revise estos errores.",
    listIsFull: "La lista está completa. Añada estos {{ count }} productos a la Cesta para continuar.",
    nonPurchasableError: "No se puede comprar el producto {{ name }}",
    outOfStockErrorFound: "No se añadió el producto {{count}} fuera de stock.",
    outOfStockErrorFound_other: "No se añadieron los productos {{count}} fuera de stock."
  },
  warnings: {
    productWasReduced: "La cantidad de {{ name }} (#{{code}}) se ha reducido a {{ quantityAdded}}.",
    reviewWarnings: "Revise estas advertencias.",
    reduceWarningFound: "Se ha reducido la cantidad del producto {{count}}.",
    reduceWarningFound_other: "Se ha reducido la cantidad de los productos {{count}}."
  },
  successes: {
    productAddedToCart: "{{ name }} (#{{code}}) se ha añadido a la cesta.",
    addedToCartFound: "El producto {{ count }} se ha añadido a la cesta.",
    addedToCartFound_other: "Los productos {{ count }} se han añadido a la cesta."
  },
  informations: {
    addProductBeforeAddingToCart: "Añada productos a la lista antes de añadirlos a la cesta."
  },
  undo: "DESHACER",
  revokeUndo: "Anular la eliminación de producto \"{{name}}\".",
  productWasDeleted: "El producto \"{{ name }}\" ha sido enviado a la papelera."
};
var quickOrderTable$d = {
  product: "Producto",
  id: "ID",
  price: "Precio",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  itemPrice: "Precio del artículo",
  qty: "Cantidad",
  actions: "Acciones",
  inStock: "En stock",
  lowStock: "Stock bajo",
  outOfStock: "Fuera de stock",
  listCleared: "Se ha vaciado la lista de pedido rápido",
  addedtoCart: "Se ha añadido la lista de pedido rápido a la cesta",
  caption: "Contenido de pedido rápido."
};
var quickOrder$d = {
  quickOrderCartForm: quickOrderCartForm$d,
  quickOrderForm: quickOrderForm$d,
  quickOrderList: quickOrderList$d,
  quickOrderTable: quickOrderTable$d
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  quickOrder: quickOrder$d
};
var quickOrderCartForm$c = {
  entriesWereAdded: "({{ quantity }}) {{ product }} ha sido añadido al carro",
  entryWasAdded: "{{ product }} ha sido añadido al carro",
  noResults: "No pudimos encontrar ningún producto",
  stockLevelReached: "Se ha alcanzado el nivel máximo de stock",
  title: "Pedido rápido",
  productCodePlaceholder: "Introducir ID",
  entryProductCode: "Introducir ID de producto para Pedido rápido",
  quantity: "Cantidad para Pedido rápido",
  addToCart: "Añadir producto a Pedido rápido",
  add: "Agregar",
  product: "Producto",
  products: "Productos",
  productCodeLabel: "ID de producto",
  quantityLabel: "Cantidad",
  quantityFullLabel: "Cantidad"
};
var quickOrderForm$c = {
  placeholder: "Introducir Nombre de producto o SKU",
  searchBoxLabel: "Introducir Nombre de producto o SKU para pedido rápido. Puede agregar hasta {{ limit }} productos por pedido.",
  listLimitReached: "Se ha alcanzado el límite del producto.",
  id: "ID {{ id }}",
  noResults: "No pudimos encontrar ningún resultado",
  addProduct: "Agregar producto {{ product }}",
  initialDescription: "Si están disponibles los resultados de autocompletar, use las flechas de arriba y abajo y la tecla Intro para seleccionar.",
  productsResults: "{{ count }} productos están disponibles.",
  quickOrderSearch: "Buscar producto para pedido rápido"
};
var quickOrderList$c = {
  addToCart: "Agregar al carro",
  emptyList: "Vaciar lista",
  header: "Añadir productos/SKU",
  subHeader: "Puede añadir hasta {{ limit }} SKU válidos a la vez.",
  errorProceedingToCart: "Error al pasar al Carro.",
  warningProceedingToCart: "Advertencia al pasar al Carro.",
  successfullyAddedToCart: "Agregado correctamente al Carro.",
  errors: {
    productIsOutOfStock: "{{ name }} (#{{code}}) no está disponible en stock.",
    reviewErrors: "Revise estos errores.",
    listIsFull: "La lista está completa. Añada estos {{ count }} productos al Carro para continuar.",
    nonPurchasableError: "No se puede comprar el producto {{ name }}",
    outOfStockErrorFound: "No se agregó el producto {{count}} fuera de stock.",
    outOfStockErrorFound_other: "No se agregaron los productos {{count}} fuera de stock."
  },
  warnings: {
    productWasReduced: "La cantidad de {{ name }}: {{code}} se ha reducido a {{ quantityAdded}}.",
    reviewWarnings: "Revise estas advertencias.",
    reduceWarningFound: "Se ha reducido la cantidad del producto {{count}}.",
    reduceWarningFound_other: "Se ha reducido la cantidad de los productos {{count}}."
  },
  successes: {
    productAddedToCart: "{{ name }} (#{{code}}) se ha añadido al carro.",
    addedToCartFound: "El producto {{ count }} se ha añadido al carro.",
    addedToCartFound_other: "Los productos {{ count }} se han añadido al carro."
  },
  informations: {
    addProductBeforeAddingToCart: "Añada productos a la lista antes de añadirlos al carro."
  },
  undo: "DESHACER",
  revokeUndo: "Anular la eliminación de producto \"{{name}}\".",
  productWasDeleted: "El producto \"{{ name }}\" ha sido enviado a la papelera."
};
var quickOrderTable$c = {
  product: "Producto",
  id: "ID",
  price: "Precio",
  quantity: "Cantidad",
  quantityFull: "Cantidad",
  itemPrice: "Precio del artículo",
  qty: "Cantidad",
  actions: "Acciones",
  inStock: "En stock",
  lowStock: "Stock bajo",
  outOfStock: "Fuera de stock",
  listCleared: "Se ha vaciado la lista de pedido rápido",
  addedtoCart: "Se ha añadido la lista de pedido rápido al carro",
  caption: "Contenido de pedido rápido."
};
var quickOrder$c = {
  quickOrderCartForm: quickOrderCartForm$c,
  quickOrderForm: quickOrderForm$c,
  quickOrderList: quickOrderList$c,
  quickOrderTable: quickOrderTable$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  quickOrder: quickOrder$c
};
var quickOrderCartForm$b = {
  entriesWereAdded: "({{ quantity }}) {{ product }} a été ajouté au panier.",
  entryWasAdded: "{{ product }} a été ajouté au panier.",
  noResults: "Nous n'avons pas trouvé de produits.",
  stockLevelReached: "Le niveau de stock maximum a été atteint.",
  title: "Commande rapide",
  productCodePlaceholder: "Saisir l'ID",
  entryProductCode: "Saisir l'ID du produit pour la commande rapide",
  quantity: "Quantité pour la commande rapide",
  addToCart: "Ajouter un produit à la commande rapide",
  add: "Ajouter",
  product: "Produit",
  products: "Produits",
  productCodeLabel: "ID produit",
  quantityLabel: "Qté",
  quantityFullLabel: "Quantité"
};
var quickOrderForm$b = {
  placeholder: "Saisissez le nom du produit ou l'UGS.",
  searchBoxLabel: "Saisissez le nom du produit ou l'UGS pour la commande rapide. Vous pouvez ajouter jusqu'à {{ limit }} produits par commande. ",
  listLimitReached: "La limite de produits a été atteinte.",
  id: "ID {{ id }}",
  noResults: "Nous n'avons pas trouvé de résultats.",
  addProduct: "Ajouter le produit {{ product }}",
  initialDescription: "Si des résultats de saisie automatique sont disponibles, utilisez les flèches haut et bas pour les parcourir et appuyez sur Entrée pour sélectionner.",
  productsResults: "{{ count }} produits sont disponibles.",
  quickOrderSearch: "Trouver un produit pour une commande rapide"
};
var quickOrderList$b = {
  addToCart: "Ajouter au panier",
  emptyList: "Liste vide",
  header: "Ajouter des produits/UGS",
  subHeader: "Vous pouvez ajouter jusqu'à {{ limit }} UGS valides à la fois.",
  errorProceedingToCart: "Erreur lors de l'ajout au panier",
  warningProceedingToCart: "Avertissement lors de l'ajout au panier",
  successfullyAddedToCart: "Ajouté au panier avec succès  ",
  errors: {
    productIsOutOfStock: "{{ name }} (nº{{code}}) est en rupture de stock.",
    reviewErrors: "Veuillez consulter ces erreurs.",
    listIsFull: "La liste est pleine, ajoutez ces {{ count }} produits au panier pour continuer.",
    nonPurchasableError: "Le produit {{ name }} ne peut pas être acheté.",
    outOfStockErrorFound: "{{count}} produit en rupture de stock n'a pas été ajouté.",
    outOfStockErrorFound_other: "{{count}} produits en rupture de stock n'ont pas été ajoutés."
  },
  warnings: {
    productWasReduced: "La quantité pour {{ name }} (nº{{code}}) a été réduite à {{ quantityAdded}}.",
    reviewWarnings: "Veuillez consulter ces avertissements.",
    reduceWarningFound: "La quantité de {{count}} produit a été réduite.",
    reduceWarningFound_other: "La quantité de {{count}} produits a été réduite."
  },
  successes: {
    productAddedToCart: "{{ name }} (nº{{code}}) a été ajouté au panier.",
    addedToCartFound: "{{ count }} produit a été ajouté au panier.",
    addedToCartFound_other: "{{ count }} produits ont été ajoutés au panier."
  },
  informations: {
    addProductBeforeAddingToCart: "Ajoutez les produits à la liste avant de les ajouter au panier.  "
  },
  undo: "ANNULER",
  revokeUndo: "Suppression du produit \"{{name}}\" annulée",
  productWasDeleted: "Produit \"{{ name }}\" déplacé dans la corbeille"
};
var quickOrderTable$b = {
  product: "Produit",
  id: "ID",
  price: "Prix",
  quantity: "QTÉ",
  quantityFull: "Quantité",
  itemPrice: "Prix de l'article",
  qty: "Qté",
  actions: "Actions",
  inStock: "En stock",
  lowStock: "Stock faible",
  outOfStock: "Épuisé",
  listCleared: "La liste de la commande rapide a été effacée.",
  addedtoCart: "La liste de la commande rapide a été ajoutée au panier.",
  caption: "Contenu de la commande rapide"
};
var quickOrder$b = {
  quickOrderCartForm: quickOrderCartForm$b,
  quickOrderForm: quickOrderForm$b,
  quickOrderList: quickOrderList$b,
  quickOrderTable: quickOrderTable$b
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  quickOrder: quickOrder$b
};
var quickOrderCartForm$a = {
  entriesWereAdded: "({{ quantity }}) {{ product }} कार्ट में जोड़े गए",
  entryWasAdded: "{{ product }} कार्ट में जोड़ा गया",
  noResults: "हमें कोई उत्पाद हीं मिल सका",
  stockLevelReached: "अधिकतम स्टॉक स्तर सीमा पार कर गया",
  title: "त्वरित ऑर्डर",
  productCodePlaceholder: "ID दर्ज करें",
  entryProductCode: "त्वरित ऑर्डर के लिए उत्पाद ID दर्ज करें",
  quantity: "त्वरित ऑर्डर के लिए मात्रा",
  addToCart: "त्वरित ऑर्डर के लिए उत्पाद जोड़ें",
  add: "जोड़ें",
  product: "उत्पाद",
  products: "उत्पाद",
  productCodeLabel: "उत्पाद ID",
  quantityLabel: "मात्रा",
  quantityFullLabel: "मात्रा"
};
var quickOrderForm$a = {
  placeholder: "उत्पाद नाम या SKU दर्ज करें",
  searchBoxLabel: "त्वरित ऑर्डर के लिए उत्पाद का नाम या SKU दर्ज करें. आप प्रति ऑर्डर  {{ limit }} तक उत्पाद जोड़ सकते हैं.",
  listLimitReached: "उत्पाद सीमा पार कर गया.",
  id: "ID {{ id }}",
  noResults: "हमें कोई परिणाम नहीं मिल सका",
  addProduct: "उत्पाद जोड़ें {{ product }}",
  initialDescription: "स्वतः पूर्ण परिणामों के उपलब्ध होने पर समीक्षा करने के लिए ऊपर और नीचे तीरों का और चयन करने के लिए enter का उपयोग करें.",
  productsResults: "{{ count }} उत्पाद उपलब्ध हैं.",
  quickOrderSearch: "त्वरित ऑर्डर के लिए उत्पाद खोजें"
};
var quickOrderList$a = {
  addToCart: "कार्ट में जोड़ें",
  emptyList: "रिक्त सूची",
  header: "उत्पाद/SKU जोड़ें",
  subHeader: "आप एक समय में {{ limit }} मान्य SKU जोड़ सकते हैं.",
  errorProceedingToCart: "कार्ट पर जाने में त्रुटि हुई.",
  warningProceedingToCart: "कार्ट पर जाने के लिए चेतावनी",
  successfullyAddedToCart: "कार्ट में सफलतापूर्वक जोड़ा गया.",
  errors: {
    productIsOutOfStock: "{{ name }} (#{{code}}) स्टॉक में नहीं है.",
    reviewErrors: "कृपया इन त्रुटियों की समीक्षा करें.",
    listIsFull: "सूची भर गई गई है, जारी रखने के लिए ये {{ count }} उत्पाद कार्ट में जोड़ें.",
    nonPurchasableError: "उत्पाद {{ name }} खरीदा नहीं जा सकता",
    outOfStockErrorFound: "{{count}} समाप्त स्टॉक का उत्पाद जोड़ा नहीं गया.",
    outOfStockErrorFound_other: "{{count}} समाप्त स्टॉक के उत्पाद जोड़े नहीं गए."
  },
  warnings: {
    productWasReduced: "{{ name }} (#{{code}}) के लिए मात्रा {{ quantityAdded}} तक कम की गई.",
    reviewWarnings: "कृपया इन चेतावनियों की समीक्षा करें.",
    reduceWarningFound: "{{count}} उत्पाद मात्रा कम की गई.",
    reduceWarningFound_other: "{{count}} उत्पाद मात्राएं कम की गईं."
  },
  successes: {
    productAddedToCart: "{{ name }} (#{{code}}) कार्ट में जोड़े गए.",
    addedToCartFound: "{{ count }} उत्पाद कार्ट में जोड़ा गया.",
    addedToCartFound_other: "{{ count }} उत्पाद कार्ट में जोड़े गए."
  },
  informations: {
    addProductBeforeAddingToCart: "कार्ट में जोड़ने से पहले उत्पाद सूची में जोड़ें."
  },
  undo: "पूर्ववत करें",
  revokeUndo: "निरस्त उत्पाद \"{{name}}\" को हटाना.",
  productWasDeleted: "उत्पाद \"{{ name }}\" ट्रैश में ले जाया गया."
};
var quickOrderTable$a = {
  product: "उत्पाद",
  id: "ID",
  price: "मूल्य",
  quantity: "मात्रा",
  quantityFull: "मात्रा",
  itemPrice: "आइटम मूल्य",
  qty: "मात्रा",
  actions: "क्रियाएं",
  inStock: "स्टॉक में है",
  lowStock: "कम स्टॉक",
  outOfStock: "स्टॉक में नहीं है",
  listCleared: "त्वरित ऑर्डर सूची साफ की गई",
  addedtoCart: "त्वरित ऑर्डर सूची को कार्ट में जोड़ा गया",
  caption: "त्वरित ऑर्डर सामग्री."
};
var quickOrder$a = {
  quickOrderCartForm: quickOrderCartForm$a,
  quickOrderForm: quickOrderForm$a,
  quickOrderList: quickOrderList$a,
  quickOrderTable: quickOrderTable$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  quickOrder: quickOrder$a
};
var quickOrderCartForm$9 = {
  entriesWereAdded: "({{ quantity }}) {{ product }} hozzáadva a kosárhoz",
  entryWasAdded: "{{ product }} hozzáadva a kosárhoz",
  noResults: "Nem találtunk termékeket",
  stockLevelReached: "Elérte a maximális készletszintet",
  title: "Gyorsrendelés",
  productCodePlaceholder: "Azonosító megadása",
  entryProductCode: "Termékazonosító megadása a gyorsrendeléshez",
  quantity: "Gyorsrendelés mennyisége",
  addToCart: "Termék hozzáadása a gyorsrendeléshez",
  add: "Hozzáadás",
  product: "Termék",
  products: "Termékek",
  productCodeLabel: "Termékazonosító",
  quantityLabel: "Menny.",
  quantityFullLabel: "Mennyiség"
};
var quickOrderForm$9 = {
  placeholder: "Termék nevének vagy cikkszámának megadása",
  searchBoxLabel: "Termék nevének vagy cikkszámának megadása a gyorsrendeléshez. Rendelésenként legfeljebb {{ limit }} terméket adhat meg.",
  listLimitReached: "Elérte a termékkorlátot.",
  id: "Azonosító: {{ id }}",
  noResults: "Nem találtunk eredményt a keresésre",
  addProduct: "Termék hozzáadása: {{ product }}",
  initialDescription: "Ha rendelkezésre állnak automatikus kitöltési eredmények, akkor a fel és le nyilakkal áttekintheti őket, az Enter billentyűvel pedig kiválaszthatja a megfelelőt.",
  productsResults: "{{ count }} termék elérhető.",
  quickOrderSearch: "Termék keresése a gyorsrendeléshez"
};
var quickOrderList$9 = {
  addToCart: "Kosárba",
  emptyList: "Üres lista",
  header: "Termékek/cikkszámok hozzáadása",
  subHeader: "Egyszerre legfeljebb {{ limit }} érvényes cikkszámot adhat hozzá.",
  errorProceedingToCart: "Hiba a kosárba lépés során.",
  warningProceedingToCart: "Figyelmeztetés a kosárba lépés során.",
  successfullyAddedToCart: "Sikeresen hozzáadva a kosárhoz.",
  errors: {
    productIsOutOfStock: "A(z) {{ name }} (#{{code}}) nincs készleten.",
    reviewErrors: "Tekintse át ezeket a hibákat.",
    listIsFull: "A lista megtelt, a folytatáshoz adja hozzá ezt a(z) {{ count }} terméket a kosárhoz.",
    nonPurchasableError: "A(z) {{ name }} termék nem vásárolható meg",
    outOfStockErrorFound: "{{count}}, készleten nem megtalálható termék nem lett hozzáadva.",
    outOfStockErrorFound_other: "{{count}}, készleten nem megtalálható termék nem lett hozzáadva."
  },
  warnings: {
    productWasReduced: "A(z) {{ name }} (#{{code}}) mennyisége a következőre csökkent: {{ quantityAdded}}.",
    reviewWarnings: "Ellenőrizze ezeket a figyelmeztetéseket.",
    reduceWarningFound: "{{count}} termék mennyisége csökkent.",
    reduceWarningFound_other: "{{count}} termék mennyisége csökkent."
  },
  successes: {
    productAddedToCart: "A(z) {{ name }} (#{{code}}) hozzáadva a kosárhoz.",
    addedToCartFound: "{{ count }} termék hozzáadva a kosárhoz.",
    addedToCartFound_other: "{{ count }} termék hozzáadva a kosárhoz."
  },
  informations: {
    addProductBeforeAddingToCart: "Adja hozzá a termékeket a listához, mielőtt hozzáadná őket a kosárhoz."
  },
  undo: "MÉGSE",
  revokeUndo: "A(z) „{{name}}” termék törlése visszavonva.",
  productWasDeleted: "A(z) „{{ name }}” termék lomtárba helyezve."
};
var quickOrderTable$9 = {
  product: "Termék",
  id: "Azonosító",
  price: "Ár",
  quantity: "MENNY.",
  quantityFull: "Mennyiség",
  itemPrice: "Tétel ára",
  qty: "Menny.",
  actions: "Műveletek",
  inStock: "Készleten",
  lowStock: "Kevés van készleten",
  outOfStock: "Nincs készleten",
  listCleared: "Gyorsrendelési lista törölve",
  addedtoCart: "Gyorsrendelési lista hozzáadva a kosárhoz",
  caption: "Gyorsrendelési tartalmak."
};
var quickOrder$9 = {
  quickOrderCartForm: quickOrderCartForm$9,
  quickOrderForm: quickOrderForm$9,
  quickOrderList: quickOrderList$9,
  quickOrderTable: quickOrderTable$9
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  quickOrder: quickOrder$9
};
var quickOrderCartForm$8 = {
  entriesWereAdded: "({{ quantity }}) {{ product }} telah ditambahkan ke keranjang",
  entryWasAdded: "{{ product }} telah ditambahkan ke keranjang",
  noResults: "Kami tidak dapat menemukan produk apa pun",
  stockLevelReached: "Tingkat stok maksimum telah tercapai",
  title: "Pemesanan Cepat",
  productCodePlaceholder: "Masukkan ID",
  entryProductCode: "Masukkan ID Produk untuk Pemesanan Cepat",
  quantity: "Jumlah Pemesanan Cepat",
  addToCart: "Tambahkan Produk ke Pemesanan Cepat",
  add: "Tambahkan",
  product: "Produk",
  products: "Produk",
  productCodeLabel: "ID Produk",
  quantityLabel: "Jml",
  quantityFullLabel: "Jumlah"
};
var quickOrderForm$8 = {
  placeholder: "Masukkan nama Produk atau SKU",
  searchBoxLabel: "masukkan nama Produk atau SKU untuk pemesanan cepat. Anda dapat menambahkan hingga {{ limit }} produk per pemesanan.",
  listLimitReached: "Batas maksimum produk telah tercapai.",
  id: "ID {{ id }}",
  noResults: "Kami tidak dapat menemukan hasil apa pun",
  addProduct: "Tambahkan produk {{ product }}",
  initialDescription: "Jika hasil pengisian otomatis tersedia, gunakan tombol panah atas dan bawah untuk meninjau dan tombol enter untuk memilih.",
  productsResults: "{{ count }} produk tersedia.",
  quickOrderSearch: "Temukan produk untuk pemesanan cepat"
};
var quickOrderList$8 = {
  addToCart: "Tambahkan ke keranjang",
  emptyList: "Kosongkan daftar",
  header: "Tambahkan Produk/SKU",
  subHeader: "Anda dapat menambahkan hingga {{ limit }} SKU yang valid dalam satu waktu.",
  errorProceedingToCart: "Terjadi kesalahan saat memproses ke Keranjang.",
  warningProceedingToCart: "Terdapat peringatan saat memproses ke Keranjang.",
  successfullyAddedToCart: "Berhasil menambahkan ke Keranjang.",
  errors: {
    productIsOutOfStock: "Stok {{ name }} (#{{code}}) habis.",
    reviewErrors: "Silakan tinjau kesalahan ini.",
    listIsFull: "Daftar penuh, tambahkan produk {{ count }} ini ke Keranjang untuk melanjutkan.",
    nonPurchasableError: "Produk {{ name }} tidak dapat dibeli",
    outOfStockErrorFound: "{{count}} produk habis tidak ditambahkan.",
    outOfStockErrorFound_other: "{{count}} produk habis tidak ditambahkan."
  },
  warnings: {
    productWasReduced: "Jumlah untuk {{ name }} (#{{code}}) telah dikurangi menjadi {{ quantityAdded}}.",
    reviewWarnings: "Silakan tinjau peringatan ini.",
    reduceWarningFound: "{{count}} jumlah produk telah dikurangi.",
    reduceWarningFound_other: "{{count}} jumlah produk telah dikurangi."
  },
  successes: {
    productAddedToCart: "{{ name }} (#{{code}}) telah ditambahkan ke keranjang.",
    addedToCartFound: "{{ count }} produk telah ditambahkan ke keranjang.",
    addedToCartFound_other: "{{ count }} produk telah ditambahkan ke keranjang."
  },
  informations: {
    addProductBeforeAddingToCart: "Tambahkan produk ke daftar sebelum menambahkannya ke keranjang."
  },
  undo: "BATALKAN",
  revokeUndo: "Penghapusan produk \"{{name}}\" dibatalkan.",
  productWasDeleted: "Produk \"{{ name }}\" dipindahkan ke tempat sampah."
};
var quickOrderTable$8 = {
  product: "Produk",
  id: "ID",
  price: "Harga",
  quantity: "JML",
  quantityFull: "Jumlah",
  itemPrice: "Harga item",
  qty: "Jml",
  actions: "Tindakan",
  inStock: "Stok Tersedia",
  lowStock: "Stok Sedikit",
  outOfStock: "Stok Habis",
  listCleared: "Daftar pemesanan cepat telah dihapus",
  addedtoCart: "Daftar pemesanan cepat telah ditambahkan ke keranjang",
  caption: "Isi pemesanan cepat."
};
var quickOrder$8 = {
  quickOrderCartForm: quickOrderCartForm$8,
  quickOrderForm: quickOrderForm$8,
  quickOrderList: quickOrderList$8,
  quickOrderTable: quickOrderTable$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  quickOrder: quickOrder$8
};
var quickOrderCartForm$7 = {
  entriesWereAdded: "({{ quantity }}) {{ product }} aggiunto al carrello acquisti",
  entryWasAdded: "{{ product }} aggiunto al carrello acquisti",
  noResults: "Non è stato possibile trovare alcun prodotto",
  stockLevelReached: "È stato raggiunto il livello massimo di stock",
  title: "Ordine rapido",
  productCodePlaceholder: "Inserisci ID",
  entryProductCode: "Inserisci ID prodotto per l'ordine rapido",
  quantity: "Quantità per ordine rapido",
  addToCart: "Aggiungi prodotto all'ordine rapido",
  add: "Aggiungi",
  product: "Prodotto",
  products: "Prodotti",
  productCodeLabel: "ID prodotto",
  quantityLabel: "Qtà",
  quantityFullLabel: "Quantità"
};
var quickOrderForm$7 = {
  placeholder: "Inserisci nome prodotto o SKU",
  searchBoxLabel: "Inserire nome prodotto o SKU per l'ordine rapido. È possibile aggiungere fino a {{ limit }} prodotti per ciascun ordine.",
  listLimitReached: "È stato raggiunto il limite di prodotti.",
  id: "ID {{ id }}",
  noResults: "Non è stato trovato alcun risultato",
  addProduct: "Aggiungi prodotto {{ product }}",
  initialDescription: "Quando sono disponibili i risultati del completamento automatico, utilizzare le frecce Su e Giù per la revisione e Invio per la selezione.",
  productsResults: "{{ count }} prodotti disponibili.",
  quickOrderSearch: "Trova il prodotto per l'ordine rapido"
};
var quickOrderList$7 = {
  addToCart: "Aggiungi al carrello acquisti",
  emptyList: "Lista vuota",
  header: "Aggiungi prodotti/SKU",
  subHeader: "È possibile aggiungere un massimo di {{ limit }} SKU validi alla volta.",
  errorProceedingToCart: "Errore nel passaggio al carrello acquisti.",
  warningProceedingToCart: "Messaggio di avvertimento nel passaggio al carrello acquisti.",
  successfullyAddedToCart: "Aggiunto correttamente al carrello acquisti.",
  errors: {
    productIsOutOfStock: "{{ name }} (n. {{code}}) non disponibile.",
    reviewErrors: "Rivedere questi errori.",
    listIsFull: "La lista è piena, per continuare aggiungere questi {{ count }} prodotti al carrello acquisti.",
    nonPurchasableError: "Il prodotto {{ name }} non può essere acquistato",
    outOfStockErrorFound: "{{count}} prodotto non disponibile non aggiunto.",
    outOfStockErrorFound_other: "{{count}} prodotti non disponibili non aggiunti."
  },
  warnings: {
    productWasReduced: "Quantità per {{ name }} (n. {{code}}) ridotta a {{ quantityAdded}}.",
    reviewWarnings: "Rivedere questi messaggi di avvertimento.",
    reduceWarningFound: "Quantità di {{count}} prodotto ridotta.",
    reduceWarningFound_other: "Quantità di {{count}} prodotti ridotta."
  },
  successes: {
    productAddedToCart: "{{ name }} (n. {{code}}) aggiunto al carrello acquisti.",
    addedToCartFound: "{{ count }} prodotto aggiunto al carrello acquisti.",
    addedToCartFound_other: "{{ count }} prodotti aggiunti al carrello acquisti."
  },
  informations: {
    addProductBeforeAddingToCart: "Aggiungere i prodotti alla lista prima di aggiungerli al carrello acquisti."
  },
  undo: "ANNULLA",
  revokeUndo: "Eliminazione del prodotto \"{{name}}\" revocata.",
  productWasDeleted: "Prodotto \"{{ name }}\" spostato nel cestino."
};
var quickOrderTable$7 = {
  product: "Prodotto",
  id: "ID",
  price: "Prezzo",
  quantity: "QTÀ",
  quantityFull: "Quantità",
  itemPrice: "Prezzo articolo",
  qty: "Qtà",
  actions: "Azioni",
  inStock: "In stock",
  lowStock: "Basso livello di scorte",
  outOfStock: "Non disponibile",
  listCleared: "Lista dell'ordine rapido svuotata",
  addedtoCart: "Lista dell'ordine rapido aggiunta al carrello",
  caption: "Contenuto dell'ordine rapido."
};
var quickOrder$7 = {
  quickOrderCartForm: quickOrderCartForm$7,
  quickOrderForm: quickOrderForm$7,
  quickOrderList: quickOrderList$7,
  quickOrderTable: quickOrderTable$7
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  quickOrder: quickOrder$7
};
var quickOrderCartForm$6 = {
  entriesWereAdded: "({{ quantity }}) {{ product }} がカートに追加されました",
  entryWasAdded: "{{ product }} がカートに追加されました",
  noResults: "商品が見つかりません",
  stockLevelReached: "在庫の最大レベルに達しました",
  title: "簡易注文",
  productCodePlaceholder: "ID を入力",
  entryProductCode: "簡易注文の商品 ID を入力",
  quantity: "簡易注文の数量",
  addToCart: "簡易注文に商品を追加",
  add: "追加",
  product: "商品",
  products: "商品",
  productCodeLabel: "商品 ID",
  quantityLabel: "数量",
  quantityFullLabel: "数量"
};
var quickOrderForm$6 = {
  placeholder: "商品名または SKU を入力",
  searchBoxLabel: "簡易注文の商品名または SKU を入力します。注文ごとに最大で {{ limit }} 商品を追加することができます。",
  listLimitReached: "商品の制限に達しました。",
  id: "ID {{ id }}",
  noResults: "結果が見つかりませんでした",
  addProduct: "商品 {{ product }} を追加",
  initialDescription: "オートコンプリート結果が表示されたら、上下の矢印キーを使用して確認し、Enter キーで選択します。",
  productsResults: "{{ count }} 商品を利用できます。",
  quickOrderSearch: "簡易注文の商品を検索"
};
var quickOrderList$6 = {
  addToCart: "カートに追加",
  emptyList: "リストを空にする",
  header: "製品/SKU の追加",
  subHeader: "同時に最大で {{ limit }} の有効な SKU を追加できます。",
  errorProceedingToCart: "カートに進むときにエラーが発生しました。",
  warningProceedingToCart: "カートに進むときに警告が発生しました。",
  successfullyAddedToCart: "カートへ正常に追加されました。",
  errors: {
    productIsOutOfStock: "{{ name }} (#{{code}}) は在庫がありません。",
    reviewErrors: "これらのエラーを確認してください。",
    listIsFull: "リストがいっぱいです。続行するにはこれら {{ count }} の商品をカートに追加します。",
    nonPurchasableError: "商品 {{ name }} を購入できません",
    outOfStockErrorFound: "{{count}} の在庫切れ商品は追加されませんでした。",
    outOfStockErrorFound_other: "{{count}} の在庫切れ商品は追加されませんでした。"
  },
  warnings: {
    productWasReduced: "{{ name }} の数量 (#{{code}}) は {{ quantityAdded}} に削減されました。",
    reviewWarnings: "これらの警告を確認してください。",
    reduceWarningFound: "{{count}} 商品の数量が削減されました。",
    reduceWarningFound_other: "{{count}} 商品の数量が削減されました。"
  },
  successes: {
    productAddedToCart: "{{ name }} (#{{code}}) がカートに追加されました。",
    addedToCartFound: "{{ count }} 商品がカートに追加されました。",
    addedToCartFound_other: "{{ count }} 商品がカートに追加されました。"
  },
  informations: {
    addProductBeforeAddingToCart: "カートに追加する前に、商品をリストに追加します。"
  },
  undo: "元に戻す",
  revokeUndo: "取り消された商品 \"{{name}}\" の削除。",
  productWasDeleted: "商品 \"{{ name }}\" をごみ箱に移動します。"
};
var quickOrderTable$6 = {
  product: "商品",
  id: "ID",
  price: "価格",
  quantity: "数量",
  quantityFull: "数量",
  itemPrice: "商品価格",
  qty: "数量",
  actions: "アクション",
  inStock: "在庫有り",
  lowStock: "在庫僅少",
  outOfStock: "在庫なし",
  listCleared: "簡易注文リストがクリアされました",
  addedtoCart: "簡易注文リストがカートに追加されました",
  caption: "簡易注文の内容です。"
};
var quickOrder$6 = {
  quickOrderCartForm: quickOrderCartForm$6,
  quickOrderForm: quickOrderForm$6,
  quickOrderList: quickOrderList$6,
  quickOrderTable: quickOrderTable$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  quickOrder: quickOrder$6
};
var quickOrderCartForm$5 = {
  entriesWereAdded: "({{ quantity }}){{ product }}이(가) 장바구니에 추가되었습니다.",
  entryWasAdded: "{{ product }}이(가) 장바구니에 추가되었습니다.",
  noResults: "제품을 찾을 수 없습니다.",
  stockLevelReached: "최대 재고 레벨에 도달했습니다.",
  title: "빠른 주문",
  productCodePlaceholder: "ID 입력",
  entryProductCode: "빠른 주문을 위한 제품 ID 입력",
  quantity: "빠른 주문 수량",
  addToCart: "빠른 주문에 제품 추가",
  add: "추가",
  product: "제품",
  products: "제품",
  productCodeLabel: "제품 ID",
  quantityLabel: "수량",
  quantityFullLabel: "수량"
};
var quickOrderForm$5 = {
  placeholder: "제품 이름 또는 SKU 입력 ",
  searchBoxLabel: "빠른 주문을 위해 제품 이름이나 SKU를 입력하십시오. 주문마다 {{ limit }}개까지 제품을 추가할 수 있습니다.",
  listLimitReached: "제품 한도에 도달했습니다.",
  id: "ID {{ id }}",
  noResults: "결과를 찾을 수 없습니다.",
  addProduct: "제품 {{ product }} 추가",
  initialDescription: "자동 완성 결과가 표시되면 위아래 화살표를 사용하여 검토하고 Enter를 눌러 선택하십시오.",
  productsResults: "{{ count }}개 제품을 사용할 수 있습니다.",
  quickOrderSearch: "빠른 주문 제품 찾기"
};
var quickOrderList$5 = {
  addToCart: "장바구니에 추가",
  emptyList: "빈 목록",
  header: "제품/SKU 추가",
  subHeader: "유효한 SKU를 한 번에 {{ limit }}개까지 추가할 수 있습니다.",
  errorProceedingToCart: "장바구니로 이동하는 동안 오류가 발생했습니다.",
  warningProceedingToCart: "장바구니로 이동하는 동안 경고가 발생했습니다.",
  successfullyAddedToCart: "장바구니에 추가되었습니다.",
  errors: {
    productIsOutOfStock: "{{ name }}(#{{code}})의 재고가 없습니다.",
    reviewErrors: "이 오류를 검토하십시오.",
    listIsFull: "목록이 가득 찼습니다. 계속하려면 이 {{ count }}개 제품을 장바구니로 추가하십시오.",
    nonPurchasableError: "제품 {{ name }}을(를) 구매할 수 없습니다.",
    outOfStockErrorFound: "재고가 없는 {{count}}개 제품이 추가되지 않았습니다.",
    outOfStockErrorFound_other: "재고가 없는 {{count}}개 제품이 추가되지 않았습니다."
  },
  warnings: {
    productWasReduced: "{{ name }}(#{{code}})의 수량이 {{ quantityAdded}}개로 줄었습니다.",
    reviewWarnings: "이 경고를 검토하십시오.",
    reduceWarningFound: "{{count}}개 제품 수량이 줄었습니다.",
    reduceWarningFound_other: "{{count}}개 제품 수량이 줄었습니다."
  },
  successes: {
    productAddedToCart: "{{ name }}(#{{code}})이(가) 장바구니에 추가되었습니다.",
    addedToCartFound: "{{ count }}개 제품이 장바구니에 추가되었습니다.",
    addedToCartFound_other: "{{ count }}개 제품이 장바구니에 추가되었습니다."
  },
  informations: {
    addProductBeforeAddingToCart: "장바구니에 추가하기 전에 목록에 제품을 추가하십시오."
  },
  undo: "실행 취소",
  revokeUndo: "제품 \"{{name}}\"의 삭제가 취소되었습니다.",
  productWasDeleted: "제품 \"{{ name }}\"이(가) 휴지통으로 이동되었습니다."
};
var quickOrderTable$5 = {
  product: "제품",
  id: "ID",
  price: "가격",
  quantity: "수량",
  quantityFull: "수량",
  itemPrice: "품목 가격",
  qty: "수량",
  actions: "작업",
  inStock: "재고 있음",
  lowStock: "재고 부족",
  outOfStock: "재고 없음",
  listCleared: "빠른 주문 목록이 지워졌습니다.",
  addedtoCart: "빠른 주문 목록이 장바구니에 추가되었습니다.",
  caption: "빠른 주문 내용"
};
var quickOrder$5 = {
  quickOrderCartForm: quickOrderCartForm$5,
  quickOrderForm: quickOrderForm$5,
  quickOrderList: quickOrderList$5,
  quickOrderTable: quickOrderTable$5
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  quickOrder: quickOrder$5
};
var quickOrderCartForm$4 = {
  entriesWereAdded: "Dodano ({{ quantity }}) {{ product }} do koszyka",
  entryWasAdded: "Dodano {{ product }} do koszyka",
  noResults: "Nie mogliśmy znaleźć żadnych produktów",
  stockLevelReached: "Osiągnięto maksymalny poziom zapasów",
  title: "Szybkie zamówienie",
  productCodePlaceholder: "Wprowadź ID",
  entryProductCode: "Wprowadź ID produktu dla szybkiego zamówienia",
  quantity: "Ilość dla szybkiego zamówienia",
  addToCart: "Dodaj produkt do szybkiego zamówienia",
  add: "Dodaj",
  product: "Produkt",
  products: "Produkty",
  productCodeLabel: "ID produktu",
  quantityLabel: "Ilość",
  quantityFullLabel: "Ilość"
};
var quickOrderForm$4 = {
  placeholder: "Wprowadź nazwę produktu lub SKU",
  searchBoxLabel: "Wprowadź nazwę produktu lub SKU dla szybkiego zamówienia. Możesz dodać maksymalnie {{ limit }} produktów dla zamówienia.",
  listLimitReached: "Osiągnięto limit produktów.",
  id: "ID {{ id }}",
  noResults: "Nie udało się znaleźć żadnych wyników",
  addProduct: "Dodaj produkt {{ product }}",
  initialDescription: "Po udostępnieniu wyników autouzupełniania użyj strzałek w górę i w dół w celu przeglądania i klawisza Enter w celu wyboru.",
  productsResults: "{{ count }} produkty są dostępne.",
  quickOrderSearch: "Znajdź produkt dla szybkiego zamówienia"
};
var quickOrderList$4 = {
  addToCart: "Dodaj do koszyka",
  emptyList: "Pusta lista",
  header: "Dodaj produktu/SKU",
  subHeader: "Możesz dodać jednocześnie maksymalnie {{ limit }} prawidłowych SKU.",
  errorProceedingToCart: "Błąd przejścia do koszyka.",
  warningProceedingToCart: "Ostrzeżenie dotyczące przejścia do koszyka.",
  successfullyAddedToCart: "Dodano do koszyka.",
  errors: {
    productIsOutOfStock: "{{ name }} (#{{code}}) jest niedostępny na zapasach.",
    reviewErrors: "Przejrzyj te błędy.",
    listIsFull: "Ta lista jest pełna. Dodaj te {{ count }} produkty(-ów) do koszyka, aby kontynuować.",
    nonPurchasableError: "Nie można kupić produktu {{ name }}",
    outOfStockErrorFound: "Nie dodano {{count}} produktu, który jest niedostępny w magazynie.",
    outOfStockErrorFound_other: "Nie dodano {{count}} produktów, które są niedostępne w magazynie."
  },
  warnings: {
    productWasReduced: "Ilość dla {{ name }} (nr {{code}}) została zmniejszona do {{ quantityAdded}}.",
    reviewWarnings: "Przejrzyj te ostrzeżenia.",
    reduceWarningFound: "Ilość {{count}} produktu została zmieniona.",
    reduceWarningFound_other: "Ilość {{count}} produktów została zmieniona."
  },
  successes: {
    productAddedToCart: "Dodano {{ name }} (nr {{code}}) do koszyka.",
    addedToCartFound: "Dodano {{ count }} produkt do koszyka.",
    addedToCartFound_other: "Dodano {{ count }} produktów do koszyka."
  },
  informations: {
    addProductBeforeAddingToCart: "Dodaj produkty do listy przed dodaniem do koszyka."
  },
  undo: "COFNIJ",
  revokeUndo: "Usuwanie przywróconego produktu \"{{name}}\".",
  productWasDeleted: "Produkt \"{{ name }}\" przeniesiony do kosza."
};
var quickOrderTable$4 = {
  product: "Produkt",
  id: "ID",
  price: "Cena",
  quantity: "ILOŚĆ",
  quantityFull: "Ilość",
  itemPrice: "Cena pozycji",
  qty: "Ilość",
  actions: "Czynności",
  inStock: "W magazynie",
  lowStock: "Niski poziom zapasu",
  outOfStock: "Zapas wyczerpany",
  listCleared: "Lista szybkich zamówień została wyczyszczona",
  addedtoCart: "Lista szybkich zamówień została dodana do koszyka",
  caption: "Treść szybkiego zamówienia"
};
var quickOrder$4 = {
  quickOrderCartForm: quickOrderCartForm$4,
  quickOrderForm: quickOrderForm$4,
  quickOrderList: quickOrderList$4,
  quickOrderTable: quickOrderTable$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  quickOrder: quickOrder$4
};
var quickOrderCartForm$3 = {
  entriesWereAdded: "({{ quantity }}) {{ product }} foi adicionado ao carrinho",
  entryWasAdded: "{{ product }} foi adicionado ao carrinho",
  noResults: "Não conseguimos encontrar nenhum produto",
  stockLevelReached: "O nível de estoque máximo foi atingido",
  title: "Pedido rápido",
  productCodePlaceholder: "Inserir ID",
  entryProductCode: "Inserir ID do produto para pedido rápido",
  quantity: "Quantidade para pedido rápido",
  addToCart: "Adicionar produto ao pedido rápido",
  add: "Adicionar",
  product: "Produto",
  products: "Produtos",
  productCodeLabel: "ID do produto",
  quantityLabel: "Qtd",
  quantityFullLabel: "Quantidade"
};
var quickOrderForm$3 = {
  placeholder: "Inserir nome do produto ou SKU",
  searchBoxLabel: "Inserir nome do produto ou SKU para o pedido rápido. Você pode adicionar até {{ limit }} produtos por pedido.",
  listLimitReached: "O limite de produtos foi atingido.",
  id: "ID {{ id }}",
  noResults: "Não conseguimos encontrar nenhum resultado",
  addProduct: "Adicionar produto {{ product }}",
  initialDescription: "Quando os resultados de preenchimento automático estiverem disponíveis, utilize as setas para cima e para baixo para revisar e Enter para selecionar.",
  productsResults: "{{ count }} produtos estão disponíveis.",
  quickOrderSearch: "Procurar produto para pedido rápido"
};
var quickOrderList$3 = {
  addToCart: "Adicionar ao carrinho",
  emptyList: "Lista vazia",
  header: "Adicionar produtos/SKUs",
  subHeader: "Você pode adicionar até {{ limit }} SKU válido de cada vez.",
  errorProceedingToCart: "Erro ao prosseguir para o carrinho.",
  warningProceedingToCart: "Advertência ao prosseguir para o carrinho.",
  successfullyAddedToCart: "Adicionado com êxito ao carrinho.",
  errors: {
    productIsOutOfStock: "{{ name }} (nº{{code}}) sem estoque.",
    reviewErrors: "Revise esses erros.",
    listIsFull: "A lista está cheia, adicione esses {{ count }} produtos ao carrinho para continuar.",
    nonPurchasableError: "O produto {{ name }} não pode ser comprado",
    outOfStockErrorFound: "{{count}} produto sem estoque não foi adicionado.",
    outOfStockErrorFound_other: "{{count}} produtos sem estoque não foram adicionados."
  },
  warnings: {
    productWasReduced: "A quantidade para {{ name }} (nº{{code}}) foi reduzida para {{ quantityAdded}}.",
    reviewWarnings: "Revisar essas advertências.",
    reduceWarningFound: "{{count}} quantidade de produto foi reduzida.",
    reduceWarningFound_other: "{{count}} quantidades de produto foram reduzidas."
  },
  successes: {
    productAddedToCart: "{{ name }} (nº{{code}}) foi adicionado ao carrinho.",
    addedToCartFound: "{{ count }} produto foi adicionado ao carrinho.",
    addedToCartFound_other: "{{ count }} produtos foram adicionados ao carrinho."
  },
  informations: {
    addProductBeforeAddingToCart: "Adicione produtos à lista antes de adicionar ao carrinho."
  },
  undo: "DESFAZER",
  revokeUndo: "Exclusão do produto \"{{name}}\" revogada.",
  productWasDeleted: "Produto \"{{ name }}\" movido para a lixeira."
};
var quickOrderTable$3 = {
  product: "Produto",
  id: "ID",
  price: "Preço",
  quantity: "QTD",
  quantityFull: "Quantidade",
  itemPrice: "Preço do item",
  qty: "Qtd",
  actions: "Ações",
  inStock: "Em estoque",
  lowStock: "Estoque baixo",
  outOfStock: "Sem estoque",
  listCleared: "A lista de pedidos rápidos foi limpa",
  addedtoCart: "A lista de pedidos rápidos foi adicionada ao carrinho",
  caption: "Conteúdo do pedido rápido."
};
var quickOrder$3 = {
  quickOrderCartForm: quickOrderCartForm$3,
  quickOrderForm: quickOrderForm$3,
  quickOrderList: quickOrderList$3,
  quickOrderTable: quickOrderTable$3
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  quickOrder: quickOrder$3
};
var quickOrderCartForm$2 = {
  entriesWereAdded: "({{ quantity }}) {{ product }}: добавлено в корзину",
  entryWasAdded: "{{ product }}: добавлено в корзину",
  noResults: "Продукты не найдены",
  stockLevelReached: "Достигнут максимальный уровень запасов",
  title: "Быстрый заказ",
  productCodePlaceholder: "Введите ид.",
  entryProductCode: "Введите ид. продукта для быстрого заказа",
  quantity: "Количество для быстрого заказа",
  addToCart: "Добавить продукт в быстрый заказ",
  add: "Добавить",
  product: "Продукт",
  products: "Продукты",
  productCodeLabel: "Ид. продукта",
  quantityLabel: "Кол.",
  quantityFullLabel: "Количество"
};
var quickOrderForm$2 = {
  placeholder: "Введите имя продукта или SKU",
  searchBoxLabel: "Введите имя продукта или SKU для быстрого заказа. Можно добавить до {{ limit }} продуктов на заказ.",
  listLimitReached: "Достигнут лимит продуктов.",
  id: "Ид. {{ id }}",
  noResults: "Результаты не найдены",
  addProduct: "Добавить продукт {{ product }}",
  initialDescription: "Если результаты автоматического заполнения доступны, используйте стрелки вверх и вниз для просмотра и Enter для выбора.",
  productsResults: "Доступно продуктов: {{ count }}.",
  quickOrderSearch: "Найти продукт для быстрого заказа"
};
var quickOrderList$2 = {
  addToCart: "Добавить в корзину",
  emptyList: "Пустой список",
  header: "Добавить продукты/SKU",
  subHeader: "За один раз можно добавить до {{ limit }} действительных SKU.",
  errorProceedingToCart: "Ошибка при переходе к корзине.",
  warningProceedingToCart: "Предупреждение при переходе к корзине.",
  successfullyAddedToCart: "Успешно добавлено в корзину.",
  errors: {
    productIsOutOfStock: "{{ name }} (№ {{code}}) нет в наличии",
    reviewErrors: "Проверьте эти ошибки.",
    listIsFull: "Список заполнен, добавьте эти продукты ({{ count }}) в корзину, чтобы продолжить.",
    nonPurchasableError: "Покупка продукта {{ name }} невозможна",
    outOfStockErrorFound: "Добавлен продукт ({{count}}), которого нет в наличии.",
    outOfStockErrorFound_other: "Добавлены продукты ({{count}}), которых нет в наличии."
  },
  warnings: {
    productWasReduced: "Количество {{ name }} (№ {{code}}) сокращено до {{ quantityAdded}}.",
    reviewWarnings: "Проверьте эти предупреждения.",
    reduceWarningFound: "Количество продукта ({{count}}) сокращено.",
    reduceWarningFound_other: "Количество продуктов ({{count}}) сокращено."
  },
  successes: {
    productAddedToCart: "{{ name }} (#{{code}}) добавлен в корзину.",
    addedToCartFound: "Продукт ({{ count }}) добавлен в корзину.",
    addedToCartFound_other: "Продукты ({{ count }}) добавлены в корзину."
  },
  informations: {
    addProductBeforeAddingToCart: "Добавьте продукты в список перед добавлением в корзину."
  },
  undo: "ОТМЕНИТЬ",
  revokeUndo: "Удаление продукта \"{{name}}\" отозвано.",
  productWasDeleted: "Продукт \"{{ name }}\" перемещен в корзину для удаления."
};
var quickOrderTable$2 = {
  product: "Продукт",
  id: "Ид.",
  price: "Цена",
  quantity: "Кол.",
  quantityFull: "Количество",
  itemPrice: "Цена позиции",
  qty: "Кол.",
  actions: "Действия",
  inStock: "В наличии",
  lowStock: "В наличии мало",
  outOfStock: "Нет в наличии",
  listCleared: "Список быстрого заказа очищен",
  addedtoCart: "Список быстрого заказа добавлен в корзину",
  caption: "Содержимое быстрого заказа."
};
var quickOrder$2 = {
  quickOrderCartForm: quickOrderCartForm$2,
  quickOrderForm: quickOrderForm$2,
  quickOrderList: quickOrderList$2,
  quickOrderTable: quickOrderTable$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  quickOrder: quickOrder$2
};
var quickOrderCartForm$1 = {
  entriesWereAdded: "({{ quantity }}) {{ product }} 已添加到购物车",
  entryWasAdded: "{{ product }} 已添加到购物车",
  noResults: "我们无法找到任何产品",
  stockLevelReached: "已达到最大库存水平",
  title: "快速订单",
  productCodePlaceholder: "输入标识",
  entryProductCode: "输入快速订单的产品标识",
  quantity: "快速订单的数量",
  addToCart: "将产品添加到快速订单",
  add: "添加",
  product: "产品",
  products: "产品",
  productCodeLabel: "产品标识",
  quantityLabel: "数量",
  quantityFullLabel: "数量"
};
var quickOrderForm$1 = {
  placeholder: "输入产品名称或 SKU",
  searchBoxLabel: "输入快速订单的产品名称或 SKU。每个订单最多可以添加 {{ limit }} 个产品。",
  listLimitReached: "已达到产品限制。",
  id: "标识 {{ id }}",
  noResults: "我们找不到任何结果",
  addProduct: "添加产品 {{ product }}",
  initialDescription: "自动完成时结果将可用，使用上下箭头进行审阅，按回车键以选择。",
  productsResults: "{{ count }} 个产品可用。",
  quickOrderSearch: "查找快速订单的产品"
};
var quickOrderList$1 = {
  addToCart: "添加到购物车",
  emptyList: "空列表",
  header: "添加产品/SKU",
  subHeader: "您一次最多可以添加 {{ limit }} 个有效 SKU。",
  errorProceedingToCart: "进入购物车时出错。",
  warningProceedingToCart: "进入购物车时出现警告。",
  successfullyAddedToCart: "已成功添加到购物车。",
  errors: {
    productIsOutOfStock: "{{ name }} (#{{code}}) 缺货。",
    reviewErrors: "请查看这些错误。",
    listIsFull: "列表已满，请将这 {{ count }} 种产品添加到购物车以继续。",
    nonPurchasableError: "无法购买产品 {{ name }}",
    outOfStockErrorFound: "{{count}} 个缺货产品尚未添加。",
    outOfStockErrorFound_other: "{{count}} 个缺货产品尚未添加。"
  },
  warnings: {
    productWasReduced: "{{ name }} (#{{code}}) 的数量已减少至 {{ quantityAdded}}。",
    reviewWarnings: "请查看这些警告。",
    reduceWarningFound: "{{count}} 个产品数量已减少。",
    reduceWarningFound_other: "{{count}} 个产品数量已减少。"
  },
  successes: {
    productAddedToCart: "{{ name }} (#{{code}}) 已添加到购物车。",
    addedToCartFound: "{{ count }} 个产品已添加到购物车。",
    addedToCartFound_other: "{{ count }} 个产品已添加到购物车。"
  },
  informations: {
    addProductBeforeAddingToCart: "将产品添加到购物车之前先添加到列表中。"
  },
  undo: "撤销",
  revokeUndo: "已撤销产品“{{name}}”删除。",
  productWasDeleted: "产品“{{ name }}”已移至垃圾箱。"
};
var quickOrderTable$1 = {
  product: "产品",
  id: "标识",
  price: "价格",
  quantity: "数量",
  quantityFull: "数量",
  itemPrice: "项目价格",
  qty: "数量",
  actions: "操作",
  inStock: "现货",
  lowStock: "低库存",
  outOfStock: "缺货",
  listCleared: "已清除快速订单列表",
  addedtoCart: "快速订单列表已添加到购物车",
  caption: "快速订单内容。"
};
var quickOrder$1 = {
  quickOrderCartForm: quickOrderCartForm$1,
  quickOrderForm: quickOrderForm$1,
  quickOrderList: quickOrderList$1,
  quickOrderTable: quickOrderTable$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  quickOrder: quickOrder$1
};
var quickOrderCartForm = {
  entriesWereAdded: "已將 ({{ quantity }}) {{ product }} 新增至購物車",
  entryWasAdded: "已將 {{ product }} 新增至購物車",
  noResults: "系統找不到產品",
  stockLevelReached: "已達到最大庫存量",
  title: "快速訂購",
  productCodePlaceholder: "輸入 ID",
  entryProductCode: "輸入快速訂單的產品 ID",
  quantity: "快速訂單的數量",
  addToCart: "將產品新增至快速訂單",
  add: "新增",
  product: "產品",
  products: "產品",
  productCodeLabel: "產品 ID",
  quantityLabel: "數量",
  quantityFullLabel: "數量"
};
var quickOrderForm = {
  placeholder: "輸入產品名稱或 SKU",
  searchBoxLabel: "輸入快速訂單的產品名稱或 SKU。每個訂單最多可新增 {{ limit }} 個產品。",
  listLimitReached: "已達到產品限制。",
  id: "ID {{ id }}",
  noResults: "系統找不到結果",
  addProduct: "新增產品 {{ product }}",
  initialDescription: "若提供自動完成結果時，請使用向上和向下箭頭以審查和輸入進行選擇。",
  productsResults: "{{ count }} 個產品可供使用。",
  quickOrderSearch: "尋找快速訂單的產品"
};
var quickOrderList = {
  addToCart: "新增至購物車",
  emptyList: "空白清單",
  header: "新增產品/SKU",
  subHeader: "您一次最多可新增 {{ limit }} 個有效的 SKU。",
  errorProceedingToCart: "繼續移至購物車時發生錯誤。",
  warningProceedingToCart: "繼續移至購物車時發生警告。",
  successfullyAddedToCart: "已成功新增至購物車。",
  errors: {
    productIsOutOfStock: "{{ name }} (#{{code}}) 缺貨。",
    reviewErrors: "請審查這些錯誤。",
    listIsFull: "清單已滿，請將 {{ count }} 個產品新增至購物車以繼續。",
    nonPurchasableError: "無法購買產品 {{ name }}",
    outOfStockErrorFound: "未新增 {{count}} 個缺貨產品。",
    outOfStockErrorFound_other: "未新增 {{count}} 個缺貨產品。"
  },
  warnings: {
    productWasReduced: "已將 {{ name }} (#{{code}}) 的數量減少為 {{ quantityAdded}}。",
    reviewWarnings: "請審查這些警告。",
    reduceWarningFound: "已減少 {{count}} 個產品數量。",
    reduceWarningFound_other: "已減少 {{count}} 個產品數量。"
  },
  successes: {
    productAddedToCart: "已將 {{ name }} (#{{code}}) 新增至購物車。",
    addedToCartFound: "已將 {{ count }} 產品新增至購物車。",
    addedToCartFound_other: "已將 {{ count }} 產品新增至購物車。"
  },
  informations: {
    addProductBeforeAddingToCart: "新增至購物車前，先將產品新增至清單。"
  },
  undo: "復原",
  revokeUndo: "已撤銷產品「{{name}}」刪除。",
  productWasDeleted: "已將產品「{{ name }}」移至垃圾桶。"
};
var quickOrderTable = {
  product: "產品",
  id: "ID",
  price: "價格",
  quantity: "數量",
  quantityFull: "數量",
  itemPrice: "項目價格",
  qty: "數量",
  actions: "動作",
  inStock: "有存貨",
  lowStock: "少量庫存",
  outOfStock: "缺貨",
  listCleared: "已清除快速訂單清單",
  addedtoCart: "已將快速訂單清單新增至購物車",
  caption: "快速訂單內容。"
};
var quickOrder = {
  quickOrderCartForm: quickOrderCartForm,
  quickOrderForm: quickOrderForm,
  quickOrderList: quickOrderList,
  quickOrderTable: quickOrderTable
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  quickOrder
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const quickOrderTranslationChunksConfig = {
  quickOrder: ['quickOrderCartForm', 'quickOrderForm', 'quickOrderList', 'quickOrderTable']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: quickOrderTranslations
 *             +   resources: { en: quickOrderTranslationsEn }
 *               }
 *             ```
 */
const quickOrderTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { quickOrderTranslationChunksConfig, quickOrderTranslations, cs as quickOrderTranslationsCs, de as quickOrderTranslationsDe, en as quickOrderTranslationsEn, es as quickOrderTranslationsEs, es_CO as quickOrderTranslationsEs_CO, fr as quickOrderTranslationsFr, hi as quickOrderTranslationsHi, hu as quickOrderTranslationsHu, id as quickOrderTranslationsId, it as quickOrderTranslationsIt, ja as quickOrderTranslationsJa, ko as quickOrderTranslationsKo, pl as quickOrderTranslationsPl, pt as quickOrderTranslationsPt, ru as quickOrderTranslationsRu, zh as quickOrderTranslationsZh, zh_TW as quickOrderTranslationsZh_TW };
