var configurator$w = {
  header: {
    consistent: "Konzistentní",
    complete: "Dokončeno",
    configId: "ID konfigurace",
    toconfig: "Konfigurovat",
    toConfigReadOnly: "Zobrazit detaily",
    editConfiguration: "Upravit konfiguraci",
    displayConfiguration: "Zobrazit konfiguraci",
    resolveIssues: "Vyřešit problémy",
    resolveIssue: "Vyřešit problém",
    resolveConflicts: "Vyřešit konflikty",
    conflictWarning: "Aby bylo možné pokračovat, je třeba vyřešit konflikty.",
    updateMessage: "Konfigurace se aktualizuje na pozadí",
    conflictsResolved: "Konflikty byly vyřešeny",
    showMore: "zobrazit více",
    showLess: "zobrazit méně",
    items: "{{count}} položka",
    items_other: "Počet položek: {{count}}",
    show: "zobrazit",
    hide: "skrýt",
    multipleWarnings: "Je zde více varování.",
    reviewWarnings: "Zkontrolujte tato varování.",
    multipleErrors: "Je zde více chyb.",
    reviewErrors: "Zkontrolujte tyto chyby.",
    kbKeyName: "Název vědomostní databáze",
    kbKeyLogsys: "Logický systém vědomostní databáze",
    kbKeyVersion: "Verze vědomostní databáze",
    kbKeyBuildNr: "Číslo buildu vědomostní databáze"
  },
  tabBar: {
    configuration: "Konfigurace",
    overview: "Přehled"
  },
  notificationBanner: {
    numberOfIssues: "Počet problémů, které je potřeba vyřešit před přechodem k pokladně: {{count}}",
    numberOfIssues_other: "Počet problémů, které je potřeba vyřešit před přechodem k pokladně: {{count}}",
    numberOfConflicts: "Počet konfliktů, které je potřeba vyřešit před přechodem k pokladně: {{count}}",
    numberOfConflicts_other: "Počet konfliktů, které je potřeba vyřešit před přechodem k pokladně: {{count}} "
  },
  attribute: {
    id: "ID",
    quantity: "Množství",
    caption: "Atributy",
    notSupported: "Typ atributu není podporovaný.",
    requiredAttribute: "{{param}} povinné",
    defaultRequiredMessage: "Zadejte hodnotu povinného pole",
    singleSelectRequiredMessage: "Vyberte hodnotu",
    singleSelectAdditionalRequiredMessage: "Vyberte nebo zadejte hodnotu",
    multiSelectRequiredMessage: "Vyberte jednu nebo více hodnot",
    wrongNumericFormat: "Špatný formát. Tento numerický atribut musí být zadán podle vzoru {{pattern}}",
    wrongNumericFormatMessage: "Zadejte číslo v následujícím formátu: {{pattern}}",
    wrongIntervalFormat: "Zadejte hodnotu v rámci naznačených mezí",
    deselectionNotPossible: "Než odeberete tento produkt, přidejte jiný.",
    dropDownSelectMsg: "Vyberte",
    noOptionSelectedMsg: "Není vybraná žádná možnost",
    notVisibleAttributeMsg: "Tento atribut není pro uživatele viditelný"
  },
  button: {
    previous: "Předchozí",
    next: "Další",
    back: "Zpět",
    more: "více",
    less: "méně",
    deselect: "Zrušit výběr",
    select: "Vybrat",
    add: "Přidat",
    remove: "Odebrat",
    exit: "Ukončit konfiguraci",
    exitMobile: "Konec",
    cancelConfiguration: "Zrušit konfiguraci",
    cancelConfigurationMobile: "Zrušit",
    filterOverview: "Filtrovat",
    filterOverviewWithCount: "Filtr ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Dokončeno",
    groupIncomplete: "Neúplné",
    groupConflict: "Konflikt",
    subgroup: "má podskupiny"
  },
  priceSummary: {
    basePrice: "Základní cena",
    selectedOptions: "Vybrané možnosti",
    totalPrice: "Celkem",
    totalPricePerItem: "Celkem na položku"
  },
  addToCart: {
    button: "Přidat do košíku",
    buttonAfterAddToCart: "Pokračovat do košíku",
    buttonForQuote: "Pokračovat na nabídku",
    buttonUpdateCart: "Hotovo",
    buttonDisplayOnly: "Hotovo",
    buttonClose: "Zavřít",
    confirmation: "Konfigurace byla přidána do košíku",
    confirmationUpdate: "Košík byl aktualizován podle konfigurace",
    confirmationQuoteUpdate: "Nabídka byla aktualizována podle konfigurace",
    quantity: "Množství"
  },
  overviewForm: {
    noAttributeHeader: "Žádné výsledky",
    noAttributeText: "Odebrat filtr(y) pro zobrazení obsahu přehledu",
    itemPrice: "Cena položky"
  },
  overviewSidebar: {
    menu: "Menu",
    filter: "Filtrovat"
  },
  overviewFilter: {
    title: "Vybrat filtry",
    byOption: "Filtrovat podle možnosti",
    byGroup: "Filtrovat podle skupiny",
    byPrice: "Možnosti relevantní pro cenu",
    mySelections: "Moje výběry",
    removeAll: "Odebrat vše",
    removeAllFilters: "Odebrat všechny filtry",
    removeByPrice: "Odebrat filtr relevantní pro cenu",
    removeMySelections: "Odebrat filtr Moje výběry",
    removeByGroup: "Odebrat filtr skupiny pro skupinu {{group}}",
    noFiltersAvailable: "Žádné filtry nejsou k dispozici"
  },
  group: {
    general: "Všeobecné",
    conflictHeader: "Vyřešit konflikty",
    conflictGroup: "Konflikt pro {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Návrh {{number}}:",
    suggestionText: "Změnit hodnotu pro „{{ attribute }}“",
    viewConflictDetails: "Zjištěn konflikt – zobrazit detaily",
    conflictDetected: "Byl zjištěn konflikt.",
    viewConfigurationDetails: "Zobrazit v konfiguraci"
  },
  restartDialog: {
    title: "Nedokončená konfigurace",
    description: "Existuje nedokončená konfigurace z předchozí relace. Chcete pokračovat nebo zahájit novou konfiguraci?",
    restartButton: "Zahájit novou",
    resumeButton: "Pokračovat"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Tento produkt je základním produktem a nelze jej přidat do košíku. Místo toho vyberte variantu produktu."
  },
  a11y: {
    configureProduct: "Nakonfigurovat produkt",
    showDetailsProduct: "Zobrazit detaily produktu",
    cartEntryBundleInfo: "Je zde položka ",
    cartEntryBundleInfo_other: "Je zde položek: {{items}} ",
    cartEntryBundleName: "položka {{ name }}",
    cartEntryBundleNameWithQuantity: "Počet položek {{ name }}: {{quantity}}",
    cartEntryBundleNameWithPrice: "Cena položky {{ name }}: {{price}}",
    cartEntryBundle: "Položka {{ name }}, cena položky {{price}}, množství položky {{quantity}}",
    cartEntryInfoIntro: "Produkt má následující atributy a vybrané hodnoty",
    cartEntryInfo: "Atribut {{ attribute }} má vybranou hodnotu {{value}}",
    nameOfAttribute: "Název atributu",
    valueOfAttribute: "Hodnota atributu {{ attribute }}",
    forAttribute: "{{ value }} pro atribut {{ attribute }}",
    valueOfAttributeFull: "Hodnota {{ value }} atributu {{ attribute }}.",
    valueOfDateAttributeFull: "Hodnota {{ value }} data atributu {{ attribute }}. Stiskem mezerníku zobrazíte výběr data.",
    valueOfAttributeFullWithPrice: "Hodnota {{ value }} atributu {{ attribute }}, příplatek {{ price }}",
    selectedValueOfAttributeFull: "Vybraná hodnota {{ value }} atributu {{ attribute }}.",
    selectedValueOfAttributeFullWithPrice: "Vybraná hodnota {{ value }} atributu {{ attribute }}, příplatek {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Hodnota pouze pro čtení {{ value }} atributu {{ attribute }}, příplatek {{ price }}",
    readOnlyValueOfAttributeFull: "Hodnota pouze pro čtení {{ value }} atributu {{ attribute }}",
    valueOfAttributeBlank: "Hodnota atributu {{ attribute }} je prázdná.",
    valueOfDateAttributeBlank: "Hodnota data atributu {{ attribute }} je prázdná. Stiskem mezerníku zobrazíte výběr data.",
    value: "Hodnota {{ value }}",
    attribute: "Atribut {{ attribute }}",
    requiredAttribute: "Atribut {{param}} je povinný",
    listOfAttributesAndValues: "Seznam atributů a jejich hodnot:",
    editAttributesAndValues: "Upravit hodnoty atributů:",
    group: "{{ group }} skupina atributů",
    itemOfAttributeSelected: "Položka {{ item }} atributu {{ attribute }} je vybraná. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "Položka {{ item }} atributu {{ attribute }} je vybraná, příplatek {{ price }}. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "Položka {{ item }} atributu {{ attribute }} je vybraná. Výběr zrušíte tlačítkem. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Položka {{ item }} atributu {{ attribute }} je vybraná, příplatek {{ price }} je vybraná. Výběr zrušíte tlačítkem. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeUnselected: "Výběr položky {{ item }} atributu {{ attribute }} je zrušen. Vyberete tlačítkem. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "Výběr položky {{ item }} atributu {{ attribute }} je zrušen, příplatek {{ price }}. Vyberete tlačítkem. {{ itemIndex }} z {{ itemCount }}",
    selectNoItemOfAttribute: "Nechcete-li pro atribut {{ attribute }} vybrat žádnou položku, stiskněte tlačítko. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttribute: "Položka atributu {{ attribute }}",
    itemOfAttributeFull: "Položka {{ item }} atributu {{ attribute }}",
    itemOfAttributeFullWithPrice: "Položka {{ item }} atributu {{ attribute }}, příplatek {{ price }}",
    itemOfAttributeFullWithQuantity: "Položka {{ item }} atributu {{ attribute }}, množství {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Položka {{ item }} atributu {{ attribute }}, množství {{ quantity }}, příplatek {{ price }}",
    itemDescription: "Popis položky {{ item }}",
    listbox: "Pole se seznamem s {{ count }} hodnotami.",
    valueSurcharge: "Příplatek hodnoty",
    conflictDetected: "Zjištěn konflikt! Zkontrolujte konflikty v horní části seznamu skupiny.",
    conflictsInConfiguration: "Ve vaší konfiguraci existují konflikty. Počet konfliktů: {{ numberOfConflicts }}. Výběrem zobrazíte další informace.",
    listOfGroups: "Seznam skupin",
    inListOfGroups: "Jste v seznamu skupin",
    groupName: "Skupina {{ group }}",
    groupBack: "Jste v podskupině. Výběrem se vrátíte.",
    conflictBack: "Jste v řešení konfliktů. Výběrem se vrátíte zpět nebo vyberte další záložky pro řešení konfliktů.",
    iconConflict: "Skupina má konflikty.",
    iconIncomplete: "Skupina má požadované atributy bez vybraných hodnot.",
    iconComplete: "Skupina je kompletní.",
    iconSubGroup: "Skupina má podskupiny.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Zobrazit další informace pro produkt  {{ product }} nebo pokračovat v konfiguraci.",
    showLessProductInfo: "Zobrazte méně informací pro produkt  {{ product }} nebo pokračovat v konfiguraci.",
    productName: "Název produktu",
    productCode: "Kód produktu",
    productDescription: "Popis produktu",
    kbKeyName: "Název vědomostní databáze {{name}}",
    kbKeyLogsys: "Logický systém vědomostní databáze {{logsys}}",
    kbKeyVersion: "Verze vědomostní databáze {{version}}",
    kbKeyBuildNr: "Číslo buildu vědomostní databáze {{number}}",
    configurationPage: "Jste na stránce konfigurace.",
    configurationPageLink: "Přejít na stránku konfigurace.",
    overviewPage: "Jste na stránce přehledu. Zkontrolujte atributy a jejich vybrané hodnoty v režimu čtení programu pro čtení obrazovky.",
    overviewPageLink: "Přejít na stránku přehledu konfigurace.",
    navigateToConflict: "Použijte odkaz pro zobrazení více podrobností o konfliktu atributu {{ attribute }}.",
    numericIntervalStandard: "Zadejte hodnotu od {{ minValue }} do {{ maxValue }}.",
    numericIntervalStandardOpen: "(meze nejsou zahrnuty)",
    numericIntervalStandardLowerEndpointNotIncluded: "(dolní mez není zahrnuta)",
    numericIntervalStandardUpperEndpointNotIncluded: "(horní mez není zahrnuta)",
    numericInfiniteIntervalMinValueIncluded: "Zadejte hodnotu větší nebo rovnou {{ value }}",
    numericInfiniteIntervalMinValue: "zadejte hodnotu větší než {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Zadejte hodnotu menší nebo rovnou {{ value }}",
    numericInfiniteIntervalMaxValue: "Zadejte hodnotu menší než {{ value }}",
    numericIntervalSingleValue: "Zadejte {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} nebo {{ newInterval }}",
    additionalValue: "Také můžete zadat další hodnotu.",
    addToCartPrices: "Základní cena {{basePrice}}, vybrané možnosti {{selectedOptions}}, celková cena {{totalPrice}}",
    filterOverview: "Otevřít nabídku filtrů přehledu konfigurace",
    filterOverviewWithCount: "Otevřít nabídku filtrů přehledu konfigurace – aktivní filtry: {{numAppliedFilters}}",
    closeFilterMenu: "Zavřít nabídku filtrů přehledu konfigurace a použít vybrané filtry",
    filterOverviewByPrice: "Filtrovat přehled konfigurace podle možností relevantních pro cenu",
    filterOverviewByMySelections: "Filtrovat přehled konfigurace podle vašeho výběru",
    filterOverviewByGroup: "Filtrovat přehled konfigurace podle skupiny {{groupName}}",
    closeConflictSolverModal: "Zavřít modální okno pro řešení konfliktů",
    closeRestartDialog: "Zavřít dialogové okno „Nedokončená konfigurace“ a přejít zpět na stránku s detaily produktu.",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Předkonfigurované verze"
  }
};
var configurator$x = {
  configurator: configurator$w
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cs = {
  configurator: configurator$x
};
var configurator$u = {
  header: {
    consistent: "Konsistent",
    complete: "Vollständig",
    configId: "Konfigurations-ID",
    toconfig: "Konfigurieren",
    toConfigReadOnly: "Details anzeigen",
    editConfiguration: "Konfiguration bearbeiten",
    displayConfiguration: "Konfiguration anzeigen",
    resolveIssues: "Probleme beheben",
    resolveIssue: "Problem beheben",
    resolveConflicts: "Konflikte auflösen",
    conflictWarning: "Konflikt muss aufgelöst werden, um fortzufahren",
    updateMessage: "Die Konfiguration wird im Hintergrund aktualisiert",
    conflictsResolved: "Konflikte wurden aufgelöst",
    showMore: "mehr anzeigen",
    showLess: "weniger anzeigen",
    items: "{{count}} Position",
    items_other: "{{count}} Positionen",
    show: "anzeigen",
    hide: "ausblenden",
    multipleWarnings: "Es gibt mehrere Warnungen.",
    reviewWarnings: "Diese Warnungen überprüfen",
    multipleErrors: "Es gibt mehrere Fehler.",
    reviewErrors: "Diese Fehler überprüfen",
    kbKeyName: "WB-Name",
    kbKeyLogsys: "Logisches System der WB",
    kbKeyVersion: "WB-Version",
    kbKeyBuildNr: "WB-Build-Nummer"
  },
  tabBar: {
    configuration: "Konfiguration",
    overview: "Übersicht"
  },
  notificationBanner: {
    numberOfIssues: "Sie müssen {{count}} Problem beheben, bevor Sie zur Kasse gehen.",
    numberOfIssues_other: "Sie müssen {{count}} Probleme beheben, bevor Sie zur Kasse gehen.",
    numberOfConflicts: "Sie müssen {{count}} Konflikt auflösen, bevor Sie zur Kasse gehen.",
    numberOfConflicts_other: "Sie müssen {{count}} Konflikte auflösen, bevor Sie zur Kasse gehen."
  },
  attribute: {
    id: "ID",
    quantity: "Menge",
    caption: "Attribute",
    notSupported: "Attributtyp wird nicht unterstützt.",
    requiredAttribute: "{{param}} erforderlich",
    defaultRequiredMessage: "Geben Sie einen Wert für das Mussfeld ein",
    singleSelectRequiredMessage: "Wählen Sie einen Wert aus",
    singleSelectAdditionalRequiredMessage: "Wählen Sie einen Wert aus, oder geben Sie einen Wert ein",
    multiSelectRequiredMessage: "Wählen Sie mindestens einen Wert aus",
    wrongNumericFormat: "Falsches Format, dieses numerische Attribut sollte entsprechend dem Muster {{pattern}} eingegeben werden",
    wrongNumericFormatMessage: "Geben Sie die Zahl in folgendem Format ein: {{pattern}}",
    wrongIntervalFormat: "Geben Sie einen Wert innerhalb der angegebenen Grenzen ein",
    deselectionNotPossible: "Fügen Sie ein anderes Produkt hinzu, bevor Sie dieses entfernen",
    dropDownSelectMsg: "Treffen Sie eine Auswahl",
    noOptionSelectedMsg: "Keine Option ausgewählt",
    notVisibleAttributeMsg: "Dieses Attribut ist für den Benutzer nicht sichtbar"
  },
  button: {
    previous: "Zurück",
    next: "Weiter",
    back: "Zurück",
    more: "mehr",
    less: "weniger",
    deselect: "Auswahl aufheben",
    select: "Auswählen",
    add: "Hinzufügen",
    remove: "Entfernen",
    exit: "Konfiguration beenden",
    exitMobile: "Beenden",
    cancelConfiguration: "Konfiguration abbrechen",
    cancelConfigurationMobile: "Abbrechen",
    filterOverview: "Filter",
    filterOverviewWithCount: "Filter ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Vollständig",
    groupIncomplete: "Unvollständig",
    groupConflict: "Konflikt",
    subgroup: "hat Untergruppen"
  },
  priceSummary: {
    basePrice: "Basispreis",
    selectedOptions: "Ausgewählte Optionen",
    totalPrice: "Gesamtsumme",
    totalPricePerItem: "Summe pro Position"
  },
  addToCart: {
    button: "In den Warenkorb",
    buttonAfterAddToCart: "Mit Warenkorb fortfahren",
    buttonForQuote: "Mit Angebot fortfahren",
    buttonUpdateCart: "Fertig",
    buttonDisplayOnly: "Fertig",
    buttonClose: "Schließen",
    confirmation: "Konfiguration wurde dem Warenkorb hinzugefügt",
    confirmationUpdate: "Warenkorb wurde mit Konfiguration aktualisiert",
    confirmationQuoteUpdate: "Angebot wurde mit Konfiguration aktualisiert",
    quantity: "Menge"
  },
  overviewForm: {
    noAttributeHeader: "Keine Ergebnisse",
    noAttributeText: "Filter entfernen, um Übersichtsinhalt anzuzeigen",
    itemPrice: "Positionspreis"
  },
  overviewSidebar: {
    menu: "Menü",
    filter: "Filter"
  },
  overviewFilter: {
    title: "Filter auswählen",
    byOption: "Nach Option filtern",
    byGroup: "Nach Gruppe filtern",
    byPrice: "Preisrelevante Optionen",
    mySelections: "Meine Selektionen",
    removeAll: "Alle entfernen",
    removeAllFilters: "Alle Filter entfernen",
    removeByPrice: "Preisrelevanten Filter entfernen",
    removeMySelections: "Filter \"Meine Selektionen\" entfernen",
    removeByGroup: "Gruppenfilter für Gruppe {{group}} entfernen",
    noFiltersAvailable: "Keine Filter verfügbar"
  },
  group: {
    general: "Allgemein",
    conflictHeader: "Konflikte auflösen",
    conflictGroup: "Konflikt für {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Vorschlag {{number}}:",
    suggestionText: "Wert für \"{{ attribute }}\" ändern",
    viewConflictDetails: "Konflikt festgestellt - Details anzeigen",
    conflictDetected: "Konflikt festgestellt",
    viewConfigurationDetails: "In Konfiguration anzeigen"
  },
  restartDialog: {
    title: "Unbeendete Konfiguration",
    description: "Es gibt eine unbeendete Konfiguration aus einer früheren Sitzung. Möchten Sie sie wiederaufnehmen, oder möchten Sie eine neue Konfiguration beginnen?",
    restartButton: "Neu starten",
    resumeButton: "Wiederaufnehmen"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Dieses Produkt ist ein Basisprodukt und kann dem Warenkorb nicht hinzugefügt werden; wählen Sie stattdessen eine Produktvariante aus"
  },
  a11y: {
    configureProduct: "Produkt konfigurieren",
    showDetailsProduct: "Details des Produkts anzeigen",
    cartEntryBundleInfo: "Eine Position vorhanden ",
    cartEntryBundleInfo_other: "{{items}} Positionen vorhanden ",
    cartEntryBundleName: "Position {{ name }}",
    cartEntryBundleNameWithQuantity: "Position {{ name }} Positionsmenge {{quantity}}",
    cartEntryBundleNameWithPrice: "Position {{ name }} Positionspreis {{price}}",
    cartEntryBundle: "Position {{ name }} Positionspreis {{price}} Positionsmenge {{quantity}}",
    cartEntryInfoIntro: "Produkt hat die folgenden Attribute und ausgewählten Werte",
    cartEntryInfo: "Attribut {{ attribute }} hat ausgewählten Wert {{value}}",
    nameOfAttribute: "Name des Attributs",
    valueOfAttribute: "Wert von Attribut {{ attribute }}",
    forAttribute: "{{ value }} für Attribut {{ attribute }}",
    valueOfAttributeFull: "Wert {{ value }} von Attribut {{ attribute }}.",
    valueOfDateAttributeFull: "Wert {{ value }} von Datumsattribut {{ attribute }}. Drücken Sie die Leertaste, um die Datumsauswahl aufzurufen.",
    valueOfAttributeFullWithPrice: "Wert {{ value }} von Attribut {{ attribute }}, Zuschlag {{ price }}",
    selectedValueOfAttributeFull: "Ausgewählter Wert {{ value }} von Attribut {{ attribute }}.",
    selectedValueOfAttributeFullWithPrice: "Ausgewählter Wert {{ value }} von Attribut {{ attribute }}, Zuschlag {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Schreibgeschützter Wert {{ value }} von Attribut {{ attribute }}, Zuschlag {{ price }}",
    readOnlyValueOfAttributeFull: "Schreibgeschützter Wert {{ value }} von Attribut {{ attribute }}.",
    valueOfAttributeBlank: "Wert von Attribut {{ attribute }} ist leer.",
    valueOfDateAttributeBlank: "Wert von Datumsattribut {{ attribute }} ist leer. Leertaste drücken, um die Datumsauswahl zu öffnen",
    value: "Wert {{ value }}",
    attribute: "Attribut {{ attribute }}",
    requiredAttribute: "Attribut {{param}} ist erforderlich",
    listOfAttributesAndValues: "Liste der Attribute und die zugehörigen Werte:",
    editAttributesAndValues: "Werte der Attribute bearbeiten:",
    group: "Gruppe {{ group }} von Attributen",
    itemOfAttributeSelected: "Position {{ item }} von Attribut {{ attribute }} ist ausgewählt. {{ itemIndex }} von {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "Position {{ item }} von Attribut {{ attribute }} ist ausgewählt, Zuschlag {{ price }}. {{ itemIndex }} von {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "Position {{ item }} von Attribut {{ attribute }} ist ausgewählt. Zum Aufheben der Auswahl Schaltfläche drücken. {{ itemIndex }} von {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Position {{ item }} von Attribut {{ attribute }} ist ausgewählt, Zuschlag {{ price }}. Zum Aufheben der Auswahl Schaltfläche drücken. {{ itemIndex }} von {{ itemCount }}",
    itemOfAttributeUnselected: "Position {{ item }} von Attribut {{ attribute }} ist nicht ausgewählt. Zum Auswählen Schaltfläche drücken. {{ itemIndex }} von {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "Position {{ item }} von Attribut {{ attribute }} ist nicht ausgewählt, Zuschlag {{ price }}. Zum Auswählen Schaltfläche drücken. {{ itemIndex }} von {{ itemCount }}",
    selectNoItemOfAttribute: "Um keine Position für Attribut {{ attribute }} auszuwählen, drücken Sie die Schaltfläche. {{ itemIndex }} von {{ itemCount }}",
    itemOfAttribute: "Position von Attribut {{ attribute }}",
    itemOfAttributeFull: "Position {{ item }} von Attribut {{ attribute }}",
    itemOfAttributeFullWithPrice: "Position {{ item }} von Attribut {{ attribute }}, Zuschlag {{ price }}",
    itemOfAttributeFullWithQuantity: "Position {{ item }} von Attribut {{ attribute }}, Menge {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Position {{ item }} von Attribut {{ attribute }}, Menge {{ quantity }}, Zuschlag {{ price }}",
    itemDescription: "Beschreibung für Position {{ item }}",
    listbox: "Listenfeld mit {{ count }} Werten.",
    valueSurcharge: "Wertzuschlag",
    conflictDetected: "Konflikt festgestellt. Prüfen Sie die Konflikte oben in der Gruppenliste.",
    conflictsInConfiguration: "In Ihrer Konfiguration gibt es Konflikte. Anzahl der Konflikte: {{ numberOfConflicts }}, für weitere Details auswählen.",
    listOfGroups: "Liste der Gruppen",
    inListOfGroups: "Sie sind in der Gruppenliste",
    groupName: "Gruppe {{ group }}",
    groupBack: "Sie sind in einer Untergruppe. Auswählen, um zurückzukehren.",
    conflictBack: "Sie sind in der Konfliktauflösung. Wählen Sie, um zurückzukehren, oder wählen Sie die nächsten Registerkarten, um Konflikte zu beheben.",
    iconConflict: "Gruppe hat Konflikte.",
    iconIncomplete: "Gruppe hat erforderliche Attribute ohne ausgewählte Werte.",
    iconComplete: "Gruppe ist vollständig.",
    iconSubGroup: "Gruppe hat eine Untergruppe.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Zeigen Sie mehr Informationen für das Produkt {{ product }} an, oder fahren Sie mit der Konfiguration fort.",
    showLessProductInfo: "Zeigen Sie weniger Informationen für das Produkt {{ product }} an, oder fahren Sie mit der Konfiguration fort.",
    productName: "Produktname",
    productCode: "Produktcode",
    productDescription: "Produktbeschreibung",
    kbKeyName: "WB-Name {{name}}",
    kbKeyLogsys: "Logisches System der WB {{logsys}}",
    kbKeyVersion: "WB-Version {{version}}",
    kbKeyBuildNr: "WB-Build-Nummer {{number}}",
    configurationPage: "Sie sind auf der Konfigurationsseite.",
    configurationPageLink: "Navigieren Sie zur Konfigurationsseite.",
    overviewPage: "Sie sind auf der Übersichtsseite. Prüfen Sie die Attribute und ihre ausgewählten Werte mit dem Lesemodus Ihres Screenreaders.",
    overviewPageLink: "Navigieren Sie zur Übersichtsseite der Konfiguration.",
    navigateToConflict: "Wählen Sie den Link, um weitere Details zum Konflikt für Attribut {{ attribute }} aufzurufen.",
    numericIntervalStandard: "Geben Sie einen Wert zwischen {{ minValue }} und {{ maxValue }} ein",
    numericIntervalStandardOpen: "(Endpunkte sind nicht enthalten)",
    numericIntervalStandardLowerEndpointNotIncluded: "(Der untere Endpunkt ist nicht enthalten)",
    numericIntervalStandardUpperEndpointNotIncluded: "(Der obere Endpunkt ist nicht enthalten)",
    numericInfiniteIntervalMinValueIncluded: "Geben Sie einen Wert ein, der größer als oder gleich {{ value }} ist",
    numericInfiniteIntervalMinValue: "Geben Sie einen Wert größer als {{ value }} ein",
    numericInfiniteIntervalMaxValueIncluded: "Geben Sie einen Wert ein, der kleiner als oder gleich {{ value }} ist",
    numericInfiniteIntervalMaxValue: "Geben Sie einen Wert kleiner als {{ value }} ein",
    numericIntervalSingleValue: "{{ value }} eingeben",
    combinedIntervalsText: "{{ combinedInterval }} oder {{ newInterval }}",
    additionalValue: "Sie können auch einen zusätzlichen Wert eingeben.",
    addToCartPrices: "Basispreis {{basePrice}}, ausgewählte Optionen {{selectedOptions}}, Gesamtpreis {{totalPrice}}",
    filterOverview: "Filtermenü der Konfigurationsübersicht öffnen",
    filterOverviewWithCount: "Filtermenü der Konfigurationsübersicht öffnen - {{numAppliedFilters}} aktive Filter",
    closeFilterMenu: "Filtermenü der Konfigurationsübersicht schließen und die ausgewählten Filter anwenden",
    filterOverviewByPrice: "Konfigurationsübersicht nach preisrelevanten Optionen filtern",
    filterOverviewByMySelections: "Konfigurationsübersicht nach Ihren Selektionen filtern",
    filterOverviewByGroup: "Konfigurationsübersicht nach Gruppe {{groupName}} filtern",
    closeConflictSolverModal: "Modalen Dialog zum Auflösen von Konflikten schließen",
    closeRestartDialog: "Dialog \"Unbeendete Konfiguration\" schließen und zur Seite der Produktdetails zurückkehren",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Vorkonfigurierte Versionen"
  }
};
var configurator$v = {
  configurator: configurator$u
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const de = {
  configurator: configurator$v
};
var configurator$s = {
  header: {
    consistent: "Consistent",
    complete: "Complete",
    configId: "Configuration ID",
    toconfig: "Configure",
    toConfigReadOnly: "Show details",
    editConfiguration: "Edit Configuration",
    displayConfiguration: "Display Configuration",
    resolveIssues: "Resolve Issues",
    resolveIssue: "Resolve Issue",
    resolveConflicts: "Resolve Conflicts",
    conflictWarning: "Conflict must be resolved to continue",
    updateMessage: "The configuration is being updated in the background",
    conflictsResolved: "Conflicts have been resolved",
    showMore: "show more",
    showLess: "show less",
    items: "{{count}} item",
    items_other: "{{count}} items",
    show: "show",
    hide: "hide",
    multipleWarnings: "There are multiple warnings.",
    reviewWarnings: "Review these warnings",
    multipleErrors: "There are multiple errors.",
    reviewErrors: "Review these errors",
    kbKeyName: "Kb name",
    kbKeyLogsys: "Kb logical system",
    kbKeyVersion: "Kb version",
    kbKeyBuildNr: "Kb build number"
  },
  tabBar: {
    configuration: "Configuration",
    overview: "Overview"
  },
  notificationBanner: {
    numberOfIssues: "{{count}} issue must be resolved before checkout.",
    numberOfIssues_other: "{{count}} issues must be resolved before checkout.",
    numberOfConflicts: "{{count}} conflict must be resolved before checkout.",
    numberOfConflicts_other: "{{count}} conflicts must be resolved before checkout."
  },
  attribute: {
    id: "ID",
    quantity: "Qty",
    caption: "Attributes",
    notSupported: "Attribute Type is not supported.",
    requiredAttribute: "{{param}} required",
    defaultRequiredMessage: "Enter a value for the required field",
    singleSelectRequiredMessage: "Select a value",
    singleSelectAdditionalRequiredMessage: "Select or enter a value",
    multiSelectRequiredMessage: "Select one or more values",
    wrongNumericFormat: "Wrong format, this numerical attribute should be entered according to pattern {{pattern}}",
    wrongNumericFormatMessage: "Enter the number in the following format: {{pattern}}",
    wrongIntervalFormat: "Enter a value within the indicated boundaries",
    deselectionNotPossible: "Add a different product before removing this one",
    dropDownSelectMsg: "Make a selection",
    noOptionSelectedMsg: "No option selected",
    notVisibleAttributeMsg: "This attribute is not visible to the user"
  },
  button: {
    previous: "Previous",
    next: "Next",
    back: "Back",
    more: "more",
    less: "less",
    deselect: "Deselect",
    select: "Select",
    add: "Add",
    remove: "Remove",
    exit: "Exit Configuration",
    exitMobile: "Exit",
    cancelConfiguration: "Cancel Configuration",
    cancelConfigurationMobile: "Cancel",
    filterOverview: "Filter",
    filterOverviewWithCount: "Filter ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Complete",
    groupIncomplete: "Incomplete",
    groupConflict: "Conflict",
    subgroup: "has sub-groups"
  },
  priceSummary: {
    basePrice: "Base Price",
    selectedOptions: "Selected Options",
    totalPrice: "Total",
    totalPricePerItem: "Total per Item"
  },
  addToCart: {
    button: "Add to Cart",
    buttonAfterAddToCart: "Continue to Cart",
    buttonForQuote: "Continue to Quote",
    buttonUpdateCart: "Done",
    buttonDisplayOnly: "Done",
    buttonClose: "Close",
    confirmation: "Configuration has been added to the cart",
    confirmationUpdate: "Cart has been updated with configuration",
    confirmationQuoteUpdate: "Quote has been updated with configuration",
    quantity: "Qty"
  },
  overviewForm: {
    noAttributeHeader: "No Results",
    noAttributeText: "Remove filter(s) to see Overview content",
    itemPrice: "Item Price"
  },
  overviewSidebar: {
    menu: "Menu",
    filter: "Filter"
  },
  overviewFilter: {
    title: "Select Filters",
    byOption: "Filter by Option",
    byGroup: "Filter by Group",
    byPrice: "Price-Relevant Options",
    mySelections: "My Selections",
    removeAll: "Remove All",
    removeAllFilters: "Remove All Filters",
    removeByPrice: "Remove Price-Relevant Filter",
    removeMySelections: "Remove My Selections Filter",
    removeByGroup: "Remove Group Filter for group {{group}}",
    noFiltersAvailable: "No filters available"
  },
  group: {
    general: "General",
    conflictHeader: "Resolve conflicts",
    conflictGroup: "Conflict for {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Suggestion {{number}}:",
    suggestionText: "Change value for \"{{ attribute }}\"",
    viewConflictDetails: "Conflict Detected - View Details",
    conflictDetected: "Conflict Detected",
    viewConfigurationDetails: "View In Configuration"
  },
  restartDialog: {
    title: "Unfinished Configuration",
    description: "There is an unfinished configuration from a previous session. Would you like to resume or start a new configuration?",
    restartButton: "Start New",
    resumeButton: "Resume"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "This product is a base product and cannot be added to the cart; choose a product variant instead"
  },
  a11y: {
    configureProduct: "Configure product",
    showDetailsProduct: "Show details of the product",
    cartEntryBundleInfo: "There is an item ",
    cartEntryBundleInfo_other: "There are {{items}} items ",
    cartEntryBundleName: "Item {{ name }}",
    cartEntryBundleNameWithQuantity: "Item {{ name }} item quantity {{quantity}}",
    cartEntryBundleNameWithPrice: "Item {{ name }} item price {{price}}",
    cartEntryBundle: "Item {{ name }} item price {{price}} item quantity {{quantity}}",
    cartEntryInfoIntro: "Product has the following attributes and selected values",
    cartEntryInfo: "Attribute {{ attribute }} has selected value {{value}}",
    nameOfAttribute: "Name of Attribute",
    valueOfAttribute: "Value of attribute {{ attribute }}",
    forAttribute: "{{ value }} for attribute {{ attribute }}",
    valueOfAttributeFull: "Value {{ value }} of attribute {{ attribute }}.",
    valueOfDateAttributeFull: "Value {{ value }} of date attribute {{ attribute }}. Press space key to enter date picker.",
    valueOfAttributeFullWithPrice: "Value {{ value }} of attribute {{ attribute }}, Surcharge {{ price }}",
    selectedValueOfAttributeFull: "Selected value {{ value }} of attribute {{ attribute }}",
    selectedValueOfAttributeFullWithPrice: "Selected value {{ value }} of attribute {{ attribute }}, Surcharge {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Read-only value {{ value }} of attribute {{ attribute }}, Surcharge {{ price }}",
    readOnlyValueOfAttributeFull: "Read-only value {{ value }} of attribute {{ attribute }}",
    valueOfAttributeBlank: "Value of attribute {{ attribute }} is blank.",
    valueOfDateAttributeBlank: "Value of date attribute {{ attribute }} is blank. Press space key to enter date picker",
    value: "Value {{ value }}",
    attribute: "Attribute {{ attribute }}",
    requiredAttribute: "Attribute {{param}} is required",
    listOfAttributesAndValues: "List of attributes and their values:",
    editAttributesAndValues: "Edit values of attributes:",
    group: "{{ group }} group of attributes",
    itemOfAttributeSelected: "Item {{ item }} of attribute {{ attribute }} is selected. {{ itemIndex }} of {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "Item {{ item }} of attribute {{ attribute }} is selected, Surcharge {{ price }}. {{ itemIndex }} of {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "Item {{ item }} of attribute {{ attribute }} is selected. Press button to unselect. {{ itemIndex }} of {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Item {{ item }} of attribute {{ attribute }} is selected, Surcharge {{ price }} Press button to unselect. {{ itemIndex }} of {{ itemCount }}",
    itemOfAttributeUnselected: "Item {{ item }} of attribute {{ attribute }} is unselected. Press button to select. {{ itemIndex }} of {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "Item {{ item }} of attribute {{ attribute }} is unselected, Surcharge {{ price }}. Press button to select. {{ itemIndex }} of {{ itemCount }}",
    selectNoItemOfAttribute: "To select no item for attribute {{ attribute }} press button. {{ itemIndex }} of {{ itemCount }}",
    itemOfAttribute: "Item of attribute {{ attribute }}",
    itemOfAttributeFull: "Item {{ item }} of attribute {{ attribute }}",
    itemOfAttributeFullWithPrice: "Item {{ item }} of attribute {{ attribute }}, Surcharge {{ price }}",
    itemOfAttributeFullWithQuantity: "Item {{ item }} of attribute {{ attribute }}, Quantity {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Item {{ item }} of attribute {{ attribute }}, Quantity {{ quantity }}, Surcharge {{ price }}",
    itemDescription: "Description for item {{ item }}",
    listbox: "Listbox with {{ count }} values.",
    valueSurcharge: "Value surcharge",
    conflictDetected: "Conflict detected! Check the conflicts at top of group list.",
    conflictsInConfiguration: "There are conflicts in your configuration. Number of conflicts: {{ numberOfConflicts }}, Select for more details.",
    listOfGroups: "List of groups",
    inListOfGroups: "You are in the group list",
    groupName: "Group {{ group }}",
    groupBack: "You are in a sub-group. Select to go back.",
    conflictBack: "You are in the conflict solver. Select to go back or select next tabs to solve conflicts.",
    iconConflict: "Group has conflicts.",
    iconIncomplete: "Group has required attributes without selected values.",
    iconComplete: "Group is complete.",
    iconSubGroup: "Group has a sub-group.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Show more information for product {{ product }} or continue to configuration.",
    showLessProductInfo: "Show less information for product {{ product }} or continue to configuration.",
    productName: "Product Name",
    productCode: "Product Code",
    productDescription: "Product description",
    kbKeyName: "Kb name {{name}}",
    kbKeyLogsys: "Kb logical system {{logsys}}",
    kbKeyVersion: "Kb version {{version}}",
    kbKeyBuildNr: "Kb build number {{number}}",
    configurationPage: "You are on the configuration page.",
    configurationPageLink: "Navigate to configuration  page.",
    overviewPage: "You are on the overview page. Check attributes and their selected values with reading mode of your screen reader.",
    overviewPageLink: "Navigate to configuration overview page.",
    navigateToConflict: "Follow link to get more details on conflict for attribute {{ attribute }}.",
    numericIntervalStandard: "Enter a value between {{ minValue }} and {{ maxValue }}",
    numericIntervalStandardOpen: "(Endpoints are not included)",
    numericIntervalStandardLowerEndpointNotIncluded: "(The lower endpoint is not included)",
    numericIntervalStandardUpperEndpointNotIncluded: "(The upper endpoint is not included)",
    numericInfiniteIntervalMinValueIncluded: "Enter a value greater than or equal to {{ value }}",
    numericInfiniteIntervalMinValue: "Enter a value greater than {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Enter a value less than or equal to {{ value }}",
    numericInfiniteIntervalMaxValue: "Enter a value less than {{ value }}",
    numericIntervalSingleValue: "Enter {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} or {{ newInterval }}",
    additionalValue: "You can also enter an additional value.",
    addToCartPrices: "Base Price {{basePrice}}, Selected Options {{selectedOptions}}, Total Price {{totalPrice}}",
    filterOverview: "Open configuration overview filter menu",
    filterOverviewWithCount: "Open the configuration overview filter menu - {{numAppliedFilters}} active filters",
    closeFilterMenu: "Close the configuration overview filter menu and apply the selected filters",
    filterOverviewByPrice: "Filter configuration overview by price-relevant options",
    filterOverviewByMySelections: "Filter configuration overview by your selections",
    filterOverviewByGroup: "Filter configuration overview by group {{groupName}}",
    closeConflictSolverModal: "Close conflict solver modal",
    closeRestartDialog: "Close the \"Unfinished Configuration\" dialog and navigate back to the product details page",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Pre-configured Versions"
  }
};
var configurator$t = {
  configurator: configurator$s
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
  configurator: configurator$t
};
var configurator$q = {
  header: {
    consistent: "Consistente",
    complete: "Completar",
    configId: "ID de configuración",
    toconfig: "Configurar",
    toConfigReadOnly: "Mostrar detalles",
    editConfiguration: "Editar configuración",
    displayConfiguration: "Visualizar configuración",
    resolveIssues: "Solucionar problemas",
    resolveIssue: "Solucionar problema",
    resolveConflicts: "Solucionar conflictos",
    conflictWarning: "Se debe solucionar el conflicto para continuar",
    updateMessage: "La configuración se está actualizando en proceso de fondo",
    conflictsResolved: "Se resolvieron los conflictos",
    showMore: "mostrar más",
    showLess: "mostrar menos",
    items: "{{count}} artículo",
    items_other: "{{count}} artículos",
    show: "mostrar",
    hide: "ocultar",
    multipleWarnings: "Hay varias advertencias.",
    reviewWarnings: "Revisar estas advertencias",
    multipleErrors: "Hay varios errores.",
    reviewErrors: "Revisar estos errores.",
    kbKeyName: "Nombre de BC",
    kbKeyLogsys: "Sistema lógico de BC",
    kbKeyVersion: "Versión de BC",
    kbKeyBuildNr: "Número de build de BC"
  },
  tabBar: {
    configuration: "Configuración",
    overview: "Resumen"
  },
  notificationBanner: {
    numberOfIssues: "Se debe resolver {{count}} problema antes de finalizar la compra.",
    numberOfIssues_other: "Se deben resolver {{count}} problemas antes de finalizar la compra.",
    numberOfConflicts: "Se debe resolver {{count}} conflicto antes de finalizar la compra.",
    numberOfConflicts_other: "Se deben resolver {{count}} conflictos antes de finalizar la compra."
  },
  attribute: {
    id: "ID",
    quantity: "Cantidad",
    caption: "Atributos",
    notSupported: "No se admite el tipo de atributo.",
    requiredAttribute: "Se requiere {{param}}",
    defaultRequiredMessage: "Introduzca un valor para el campo requerido",
    singleSelectRequiredMessage: "Seleccionar un valor",
    singleSelectAdditionalRequiredMessage: "Seleccionar o introducir un valor",
    multiSelectRequiredMessage: "Seleccionar uno o más valores",
    wrongNumericFormat: "Formato incorrecto, este atributo numérico se debe introducir conforme al patrón {{pattern}}",
    wrongNumericFormatMessage: "Introducir el número con el siguiente formato: {{pattern}}",
    wrongIntervalFormat: "Introducir un valor dentro de los límites indicados",
    deselectionNotPossible: "Añadir un producto diferente antes de eliminar este",
    dropDownSelectMsg: "Realizar una selección",
    noOptionSelectedMsg: "Ninguna opción seleccionada",
    notVisibleAttributeMsg: "Este atributo no es visible para el usuario"
  },
  button: {
    previous: "Anterior",
    next: "Siguiente",
    back: "Atrás",
    more: "más",
    less: "menos",
    deselect: "Anular selección",
    select: "Seleccionar",
    add: "Añadir",
    remove: "Eliminar",
    exit: "Salir de la configuración",
    exitMobile: "Salir",
    cancelConfiguration: "Cancelar configuración",
    cancelConfigurationMobile: "Cancelar",
    filterOverview: "Filtrar",
    filterOverviewWithCount: "Filtrar ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Completo",
    groupIncomplete: "Incompleto",
    groupConflict: "Conflicto",
    subgroup: "tiene subgrupos"
  },
  priceSummary: {
    basePrice: "Precio base",
    selectedOptions: "Opciones seleccionadas",
    totalPrice: "Total",
    totalPricePerItem: "Total por artículo"
  },
  addToCart: {
    button: "Añadir a la cesta",
    buttonAfterAddToCart: "Continuar a la cesta",
    buttonForQuote: "Continuar a la cotización",
    buttonUpdateCart: "Listo",
    buttonDisplayOnly: "Listo",
    buttonClose: "Cerrar",
    confirmation: "Se añadió la configuración a la cesta",
    confirmationUpdate: "La cesta se actualizó con la configuración",
    confirmationQuoteUpdate: "La cotización se actualizó con la configuración",
    quantity: "Cantidad"
  },
  overviewForm: {
    noAttributeHeader: "Sin resultados",
    noAttributeText: "Eliminar filtro(s) para ver el contenido del resumen",
    itemPrice: "Precio del artículo"
  },
  overviewSidebar: {
    menu: "Menú",
    filter: "Filtrar"
  },
  overviewFilter: {
    title: "Seleccionar filtros",
    byOption: "Filtrar por opción",
    byGroup: "Filtrar por grupo",
    byPrice: "Opciones relevantes para el precio",
    mySelections: "Mis selecciones",
    removeAll: "Eliminar todos",
    removeAllFilters: "Eliminar todos los filtros",
    removeByPrice: "Eliminar los filtros relevantes para el precio",
    removeMySelections: "Eliminar el filtro Mis selecciones",
    removeByGroup: "Eliminar el filtro de grupo para el grupo {{group}}",
    noFiltersAvailable: "No hay filtros disponibles"
  },
  group: {
    general: "General",
    conflictHeader: "Solucionar conflictos",
    conflictGroup: "Conflicto para {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Sugerencia {{number}}:",
    suggestionText: "Modificar valor para \"{{ attribute }}\"",
    viewConflictDetails: "Conflicto detectado - ver detalles",
    conflictDetected: "Conflicto detectado",
    viewConfigurationDetails: "Ver en configuración"
  },
  restartDialog: {
    title: "Configuración sin terminar",
    description: "Hay una configuración sin terminar de una sesión anterior. ¿Desea reanudarla o comenzar una configuración nueva?",
    restartButton: "Comenzar una nueva",
    resumeButton: "Reanudar"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Este producto es un producto base y no se puede añadir a la cesta; seleccione una variante de producto en su lugar"
  },
  a11y: {
    configureProduct: "Configurar producto",
    showDetailsProduct: "Mostrar detalles del producto",
    cartEntryBundleInfo: "Hay un artículo ",
    cartEntryBundleInfo_other: "Hay {{items}} artículos ",
    cartEntryBundleName: "Artículo {{ name }}",
    cartEntryBundleNameWithQuantity: "Artículo {{ name }} cantidad de artículo {{quantity}}",
    cartEntryBundleNameWithPrice: "Artículo {{ name }} precio del artículo {{price}}",
    cartEntryBundle: "Artículo {{ name }} precio del artículo {{price}} cantidad del artículo {{quantity}}",
    cartEntryInfoIntro: "El producto tiene los siguientes atributos y valores seleccionados",
    cartEntryInfo: "El atributo {{ attribute }} tiene el valor seleccionado {{value}}",
    nameOfAttribute: "Nombre del atributo",
    valueOfAttribute: "Valor del atributo {{ attribute }}",
    forAttribute: "{{ value }} para el atributo {{ attribute }}",
    valueOfAttributeFull: "Valor {{ value }} del atributo {{ attribute }}.",
    valueOfDateAttributeFull: "Valor {{ value }} del atributo de fecha {{ attribute }}. Pulse la tecla Espacio para introducir un selector de fechas.",
    valueOfAttributeFullWithPrice: "Valor {{ value }} del atributo {{ attribute }}, recargo {{ price }}",
    selectedValueOfAttributeFull: "Valor seleccionado {{ value }} del atributo {{ attribute }}",
    selectedValueOfAttributeFullWithPrice: "Valor seleccionado {{ value }} del atributo {{ attribute }}, recargo {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Valor de solo lectura {{ value }} del atributo {{ attribute }}, recargo {{ price }}",
    readOnlyValueOfAttributeFull: "Valor de solo lectura {{ value }} del atributo {{ attribute }}",
    valueOfAttributeBlank: "El valor del atributo {{ attribute }} está vacío.",
    valueOfDateAttributeBlank: "El valor del atributo {{ attribute }} está vacío; pulse la tecla Espacio para introducir un selector de fechas",
    value: "Valor {{ value }}",
    attribute: "Atributo {{ attribute }}",
    requiredAttribute: "Se requiere el atributo {{param}}",
    listOfAttributesAndValues: "Lista de atributos y sus valores:",
    editAttributesAndValues: "Editar valores de atributos:",
    group: "{{ group }} grupo de atributos",
    itemOfAttributeSelected: "Está seleccionado el artículo {{ item }} del atributo {{ attribute }}. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "Está seleccionado el artículo {{ item }} del atributo {{ attribute }}, recargo {{ price }}. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "Está seleccionado el artículo {{ item }} del atributo {{ attribute }}. Pulse el botón para anular la selección. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Está seleccionado el artículo {{ item }} del atributo {{ attribute }}, recargo {{ price }}. Pulse el botón para anular la selección. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeUnselected: "No está seleccionado el artículo {{ item }} del atributo {{ attribute }}. Pulse el botón para seleccionar. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "No está seleccionado el artículo {{ item }} del atributo {{ attribute }}, recargo {{ price }}. Pulse el botón para seleccionar. {{ itemIndex }} de {{ itemCount }}",
    selectNoItemOfAttribute: "Para no seleccionar ningún artículo para el atributo {{ attribute }} pulse el botón. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttribute: "Artículo del atributo {{ attribute }}",
    itemOfAttributeFull: "Artículo {{ item }} del atributo {{ attribute }}",
    itemOfAttributeFullWithPrice: "Artículo {{ item }} del atributo {{ attribute }}, recargo {{ price }}",
    itemOfAttributeFullWithQuantity: "Artículo {{ item }} del atributo {{ attribute }}, cantidad {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Artículo {{ item }} del atributo {{ attribute }}, cantidad {{ quantity }}, recargo {{ price }}",
    itemDescription: "Descripción para el artículo {{ item }}",
    listbox: "Lista desplegable con {{ count }} valores.",
    valueSurcharge: "Recargo de valor",
    conflictDetected: "Se detectó un conflicto. Verifique los conflictos en la parte superior de la lista de grupos.",
    conflictsInConfiguration: "Hay conflictos en su configuración. Número de conflictos: {{ numberOfConflicts }}. Seleccione para obtener más información.",
    listOfGroups: "Lista de grupos",
    inListOfGroups: "Usted se encuentra en la lista de grupos",
    groupName: "Grupo {{ group }}",
    groupBack: "Se encuentra en un subgrupo. Seleccione para volver.",
    conflictBack: "Se encuentra en el solucionador de conflictos. Seleccione para volver o seleccione las siguientes fichas para solucionar los conflictos.",
    iconConflict: "El grupo tiene conflictos.",
    iconIncomplete: "El grupo tiene atributos requeridos sin valores seleccionados.",
    iconComplete: "El grupo está completo.",
    iconSubGroup: "El grupo tiene un subgrupo.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Visualice más información para el producto {{ product }} o continúe a la configuración.",
    showLessProductInfo: "Visualice menos información para el producto {{ product }} o continúe a la configuración.",
    productName: "Nombre del producto",
    productCode: "Código de producto",
    productDescription: "Descripción del producto",
    kbKeyName: "Nombre de BC {{name}}",
    kbKeyLogsys: "Sistema lógico de BC {{logsys}}",
    kbKeyVersion: "Versión de BC {{version}}",
    kbKeyBuildNr: "Número de build de BC {{number}}",
    configurationPage: "Se encuentra en la página de configuración.",
    configurationPageLink: "Navegue a la página de configuración.",
    overviewPage: "Se encuentra en la página de información general. Compruebe los atributos y sus valores seleccionados con el modo de lectura de su lector de pantalla.",
    overviewPageLink: "Navegue a la página de información general de configuración.",
    navigateToConflict: "Siga el enlace para obtener más detalles sobre el conflicto para el atributo {{ attribute }}.",
    numericIntervalStandard: "Introduzca un valor entre {{ minValue }} y {{ maxValue }}",
    numericIntervalStandardOpen: "(No se incluyen los puntos de acceso)",
    numericIntervalStandardLowerEndpointNotIncluded: "(No se incluye el punto de acceso menor)",
    numericIntervalStandardUpperEndpointNotIncluded: "(No se incluye el punto de acceso mayor)",
    numericInfiniteIntervalMinValueIncluded: "Introduzca un valor igual o mayor que {{ value }}",
    numericInfiniteIntervalMinValue: "Introduzca un valor mayor que {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Introduzca un valor igual o menor que {{ value }}",
    numericInfiniteIntervalMaxValue: "Introduzca un valor menor que {{ value }}",
    numericIntervalSingleValue: "Introduzca {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} o {{ newInterval }}",
    additionalValue: "También puede introducir un valor adicional.",
    addToCartPrices: "Precio base {{basePrice}}, Opciones seleccionadas {{selectedOptions}}, Precio total {{totalPrice}}",
    filterOverview: "Abra el menú de filtros de información general de configuración",
    filterOverviewWithCount: "Abra el menú de filtros de información general de configuración: {{numAppliedFilters}} filtros activos",
    closeFilterMenu: "Cierre el menú de filtros de información general de configuración y aplique los filtros seleccionados",
    filterOverviewByPrice: "Filtre la información general de configuración por opciones relevantes para el precio",
    filterOverviewByMySelections: "Filtre la información general de configuración por sus selecciones",
    filterOverviewByGroup: "Filtre la información general de configuración por el grupo {{groupName}}",
    closeConflictSolverModal: "Cierre el modal de solucionador de conflictos",
    closeRestartDialog: "Cierre el diálogo \"Configuración sin terminar\" y vuelva a la página de detalles del producto",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Versiones preconfiguradas"
  }
};
var configurator$r = {
  configurator: configurator$q
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es = {
  configurator: configurator$r
};
var configurator$o = {
  header: {
    consistent: "Consistente",
    complete: "Completar",
    configId: "ID de configuración",
    toconfig: "Configurar",
    toConfigReadOnly: "Mostrar detalles",
    editConfiguration: "Editar configuración",
    displayConfiguration: "Ver configuración",
    resolveIssues: "Solucionar problemas",
    resolveIssue: "Solucionar problema",
    resolveConflicts: "Solucionar conflictos",
    conflictWarning: "Se debe solucionar el conflicto para continuar",
    updateMessage: "La configuración se está actualizando en proceso de fondo",
    conflictsResolved: "Se resolvieron los conflictos",
    showMore: "mostrar más",
    showLess: "mostrar menos",
    items: "{{count}} artículo",
    items_other: "{{count}} artículos",
    show: "mostrar",
    hide: "ocultar",
    multipleWarnings: "Hay varias advertencias.",
    reviewWarnings: "Revisar estas advertencias",
    multipleErrors: "Hay varios errores.",
    reviewErrors: "Revisar estos errores.",
    kbKeyName: "Nombre de BC",
    kbKeyLogsys: "Nombre de BC",
    kbKeyVersion: "Versión de BC",
    kbKeyBuildNr: "Número de build de BC"
  },
  tabBar: {
    configuration: "Configuración",
    overview: "Resumen"
  },
  notificationBanner: {
    numberOfIssues: "Se debe resolver {{count}} problema antes de finalizar la compra.",
    numberOfIssues_other: "Se deben resolver {{count}} problemas antes de finalizar la compra.",
    numberOfConflicts: "Se debe resolver {{count}} conflicto antes de finalizar la compra.",
    numberOfConflicts_other: "Se deben resolver {{count}} conflictos antes de finalizar la compra."
  },
  attribute: {
    id: "ID",
    quantity: "Cantidad",
    caption: "Atributos",
    notSupported: "No se admite el tipo de atributo.",
    requiredAttribute: "Se requiere {{param}}",
    defaultRequiredMessage: "Ingrese un valor para el campo requerido",
    singleSelectRequiredMessage: "Seleccionar un valor",
    singleSelectAdditionalRequiredMessage: "Seleccionar o ingresar un valor",
    multiSelectRequiredMessage: "Seleccionar uno o más valores",
    wrongNumericFormat: "Formato incorrecto, este atributo numérico se debe ingresar conforme al patrón {{pattern}}",
    wrongNumericFormatMessage: "Ingresar el número con el siguiente formato: {{pattern}}",
    wrongIntervalFormat: "Ingresar un valor dentro de los límites indicados",
    deselectionNotPossible: "Agregar un producto diferente antes de eliminar este",
    dropDownSelectMsg: "Realizar una selección",
    noOptionSelectedMsg: "Ninguna opción seleccionada",
    notVisibleAttributeMsg: "Este atributo no es visible para el usuario"
  },
  button: {
    previous: "Anterior",
    next: "Siguiente",
    back: "Atrás",
    more: "más",
    less: "menos",
    deselect: "Anular selección",
    select: "Seleccionar",
    add: "Agregar",
    remove: "Eliminar",
    exit: "Salir de la configuración",
    exitMobile: "Salir",
    cancelConfiguration: "Cancelar configuración",
    cancelConfigurationMobile: "Cancelar",
    filterOverview: "Filtrar",
    filterOverviewWithCount: "Filtrar ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Completo",
    groupIncomplete: "Incompleto",
    groupConflict: "Conflicto",
    subgroup: "tiene subgrupos"
  },
  priceSummary: {
    basePrice: "Precio base",
    selectedOptions: "Opciones seleccionadas",
    totalPrice: "Total",
    totalPricePerItem: "Total por artículo"
  },
  addToCart: {
    button: "Agregar al carro",
    buttonAfterAddToCart: "Continuar al carro",
    buttonForQuote: "Continuar a la oferta",
    buttonUpdateCart: "Listo",
    buttonDisplayOnly: "Listo",
    buttonClose: "Cerrar",
    confirmation: "Se agregó la configuración al carro",
    confirmationUpdate: "El carro se actualizó con la configuración",
    confirmationQuoteUpdate: "La oferta se actualizó con la configuración",
    quantity: "Cantidad"
  },
  overviewForm: {
    noAttributeHeader: "Sin resultados",
    noAttributeText: "Eliminar filtro(s) para ver el contenido del resumen",
    itemPrice: "Precio del artículo"
  },
  overviewSidebar: {
    menu: "Menú",
    filter: "Filtrar"
  },
  overviewFilter: {
    title: "Seleccionar filtros",
    byOption: "Filtrar por opción",
    byGroup: "Filtrar por grupo",
    byPrice: "Opciones relevantes para el precio",
    mySelections: "Mis selecciones",
    removeAll: "Eliminar todos",
    removeAllFilters: "Eliminar todos los filtros",
    removeByPrice: "Eliminar los filtros relevantes para el precio",
    removeMySelections: "Eliminar el filtro Mis selecciones",
    removeByGroup: "Eliminar el filtro de grupo para el grupo {{group}}",
    noFiltersAvailable: "No hay filtros disponibles"
  },
  group: {
    general: "General",
    conflictHeader: "Solucionar conflictos",
    conflictGroup: "Conflicto para {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Sugerencia {{number}}:",
    suggestionText: "Cambiar valor para \"{{ attribute }}\"",
    viewConflictDetails: "Conflicto detectado - ver detalles",
    conflictDetected: "Conflicto detectado",
    viewConfigurationDetails: "Ver en configuración"
  },
  restartDialog: {
    title: "Configuración sin terminar",
    description: "Hay una configuración sin terminar de una sesión anterior. ¿Desea reanudarla o comenzar una configuración nueva?",
    restartButton: "Comenzar una nueva",
    resumeButton: "Reanudar"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Este producto es un producto base y no se puede agregar al carro; seleccione una variante de producto en su lugar"
  },
  a11y: {
    configureProduct: "Configurar producto",
    showDetailsProduct: "Mostrar detalles del producto",
    cartEntryBundleInfo: "Hay un artículo ",
    cartEntryBundleInfo_other: "Hay {{items}} artículos ",
    cartEntryBundleName: "Artículo {{ name }}",
    cartEntryBundleNameWithQuantity: "Artículo {{ name }} cantidad de artículo {{quantity}}",
    cartEntryBundleNameWithPrice: "Artículo {{ name }} precio del artículo {{price}}",
    cartEntryBundle: "Artículo {{ name }} precio del artículo {{price}} cantidad del artículo {{quantity}}",
    cartEntryInfoIntro: "El producto tiene los siguientes atributos y valores seleccionados",
    cartEntryInfo: "El atributo {{ attribute }} tiene el valor seleccionado {{value}}",
    nameOfAttribute: "Nombre del atributo",
    valueOfAttribute: "Valor del atributo {{ attribute }}",
    forAttribute: "{{ value }} para el atributo {{ attribute }}",
    valueOfAttributeFull: "Valor {{ value }} del atributo {{ attribute }}.",
    valueOfDateAttributeFull: "Valor {{ value }} del atributo de fecha {{ attribute }}. Presione la tecla Espacio para ingresar un selector de fechas.",
    valueOfAttributeFullWithPrice: "Valor {{ value }} del atributo {{ attribute }}, recargo {{ price }}",
    selectedValueOfAttributeFull: "Valor seleccionado {{ value }} del atributo {{ attribute }}",
    selectedValueOfAttributeFullWithPrice: "Valor seleccionado {{ value }} del atributo {{ attribute }}, recargo {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Valor de solo lectura {{ value }} del atributo {{ attribute }}, recargo {{ price }}",
    readOnlyValueOfAttributeFull: "Valor de solo lectura {{ value }} del atributo {{ attribute }}",
    valueOfAttributeBlank: "El valor del atributo {{ attribute }} está vacío.",
    valueOfDateAttributeBlank: "El valor del atributo {{ attribute }} está vacío; presione la tecla Espacio para ingresar un selector de fechas",
    value: "Valor {{ value }}",
    attribute: "Atributo {{ attribute }}",
    requiredAttribute: "Se requiere el atributo {{param}}",
    listOfAttributesAndValues: "Lista de atributos y sus valores:",
    editAttributesAndValues: "Editar valores de atributos:",
    group: "{{ group }} grupo de atributos",
    itemOfAttributeSelected: "Está seleccionado el artículo {{ item }} del atributo {{ attribute }}. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "Está seleccionado el artículo {{ item }} del atributo {{ attribute }}, recargo {{ price }}. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "Está seleccionado el artículo {{ item }} del atributo {{ attribute }}. Presione el botón para anular la selección. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Está seleccionado el artículo {{ item }} del atributo {{ attribute }}, recargo {{ price }}. Presione el botón para anular la selección. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeUnselected: "No está seleccionado el artículo {{ item }} del atributo {{ attribute }}. Presione el botón para seleccionar. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "No está seleccionado el artículo {{ item }} del atributo {{ attribute }}, recargo {{ price }}. Presione el botón para seleccionar. {{ itemIndex }} de {{ itemCount }}",
    selectNoItemOfAttribute: "Para no seleccionar ningún artículo para el atributo {{ attribute }} presione el botón. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttribute: "Artículo del atributo {{ attribute }}",
    itemOfAttributeFull: "Artículo {{ item }} del atributo {{ attribute }}",
    itemOfAttributeFullWithPrice: "Artículo {{ item }} del atributo {{ attribute }}, recargo {{ price }}",
    itemOfAttributeFullWithQuantity: "Artículo {{ item }} del atributo {{ attribute }}, cantidad {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Artículo {{ item }} del atributo {{ attribute }}, cantidad {{ quantity }}, recargo {{ price }}",
    itemDescription: "Descripción para el artículo {{ item }}",
    listbox: "Lista desplegable con {{ count }} valores.",
    valueSurcharge: "Recargo de valor",
    conflictDetected: "Se detectó un conflicto. Verifique los conflictos en la parte superior de la lista de grupos.",
    conflictsInConfiguration: "Hay conflictos en su configuración. Número de conflictos: {{ numberOfConflicts }}. Seleccione para obtener más información.",
    listOfGroups: "Lista de grupos",
    inListOfGroups: "Usted se encuentra en la lista de grupos",
    groupName: "Grupo {{ group }}",
    groupBack: "Se encuentra en un subgrupo. Seleccione para volver.",
    conflictBack: "Se encuentra en el solucionador de conflictos. Seleccione para volver o seleccione las siguientes pestañas para solucionar los conflictos.",
    iconConflict: "El grupo tiene conflictos.",
    iconIncomplete: "El grupo tiene atributos requeridos sin valores seleccionados.",
    iconComplete: "El grupo está completo.",
    iconSubGroup: "El grupo tiene un subgrupo.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Vea más información para el producto {{ product }} o continúe a la configuración.",
    showLessProductInfo: "Vea menos información para el producto {{ product }} o continúe a la configuración.",
    productName: "Nombre del producto",
    productCode: "Código del producto",
    productDescription: "Descripción del producto",
    kbKeyName: "Nombre de BC {{name}}",
    kbKeyLogsys: "Sistema lógico de BC {{logsys}}",
    kbKeyVersion: "Versión de BC {{version}}",
    kbKeyBuildNr: "Número de build de BC {{number}}",
    configurationPage: "Se encuentra en la página de configuración.",
    configurationPageLink: "Navegue a la página de configuración.",
    overviewPage: "Se encuentra en la página de resumen. Compruebe los atributos y sus valores seleccionados con el modo de lectura de su lector de pantalla.",
    overviewPageLink: "Navegue a la página de resumen de configuración.",
    navigateToConflict: "Siga el enlace para obtener más detalles sobre el conflicto para el atributo {{ attribute }}.",
    numericIntervalStandard: "Ingrese un valor entre {{ minValue }} y {{ maxValue }}",
    numericIntervalStandardOpen: "(No se incluyen los puntos de acceso)",
    numericIntervalStandardLowerEndpointNotIncluded: "(No se incluye el punto de acceso menor)",
    numericIntervalStandardUpperEndpointNotIncluded: "(No se incluye el punto de acceso mayor)",
    numericInfiniteIntervalMinValueIncluded: "Ingrese un valor igual o mayor que {{ value }}",
    numericInfiniteIntervalMinValue: "Ingrese un valor mayor que {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Ingrese un valor igual o menor que {{ value }}",
    numericInfiniteIntervalMaxValue: "Ingrese un valor menor que {{ value }}",
    numericIntervalSingleValue: "Ingrese {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} o {{ newInterval }}",
    additionalValue: "También puede ingresar un valor adicional.",
    addToCartPrices: "Precio base {{basePrice}}, Opciones seleccionadas {{selectedOptions}}, Precio total {{totalPrice}}",
    filterOverview: "Abra el menú de filtros de resumen de configuración",
    filterOverviewWithCount: "Abra el menú de filtros de resumen de configuración: {{numAppliedFilters}} filtros activos",
    closeFilterMenu: "Cierre el menú de filtros de resumen de configuración y aplique los filtros seleccionados",
    filterOverviewByPrice: "Filtre el resumen de configuración por opciones relevantes para el precio",
    filterOverviewByMySelections: "Filtre el resumen de configuración por sus selecciones",
    filterOverviewByGroup: "Filtre el resumen de configuración por el grupo {{groupName}}",
    closeConflictSolverModal: "Cierre el modal de solucionador de conflictos",
    closeRestartDialog: "Cierre el diálogo \"Configuración sin terminar\" y vuelva a la página de detalles del producto",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Versiones preconfiguradas"
  }
};
var configurator$p = {
  configurator: configurator$o
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const es_CO = {
  configurator: configurator$p
};
var configurator$m = {
  header: {
    consistent: "Cohérent",
    complete: "Terminé",
    configId: "ID de configuration",
    toconfig: "Configurer",
    toConfigReadOnly: "Afficher les détails",
    editConfiguration: "Modifier la configuration",
    displayConfiguration: "Afficher la configuration",
    resolveIssues: "Résoudre les problèmes",
    resolveIssue: "Résoudre le problème",
    resolveConflicts: "Résoudre les conflits",
    conflictWarning: "Vous devez résoudre le conflit pour poursuivre.",
    updateMessage: "La configuration est en train d'être mise à jour en arrière-plan.",
    conflictsResolved: "Les conflits ont été résolus.",
    showMore: "Afficher plus",
    showLess: "Afficher moins",
    items: "{{count}} élément",
    items_other: "{{count}} éléments",
    show: "afficher",
    hide: "masquer",
    multipleWarnings: "Il y a plusieurs avertissements.",
    reviewWarnings: "Passez en revue ces avertissements.",
    multipleErrors: "Il y a plusieurs erreurs.",
    reviewErrors: "Passez en revue ces erreurs.",
    kbKeyName: "Nom de la base de connaissances",
    kbKeyLogsys: "Système logique de la base de connaissances",
    kbKeyVersion: "Version de la base de connaissances",
    kbKeyBuildNr: "Numéro de build de la base de connaissances"
  },
  tabBar: {
    configuration: "Configuration",
    overview: "Synthèse"
  },
  notificationBanner: {
    numberOfIssues: "{{count}} problème doit être résolu avant validation de la commande.",
    numberOfIssues_other: "{{count}} problèmes doivent être résolus avant validation de la commande.",
    numberOfConflicts: "{{count}} conflit doit être résolu avant validation de la commande.",
    numberOfConflicts_other: "{{count}} conflits doivent être résolus avant validation de la commande."
  },
  attribute: {
    id: "ID",
    quantity: "Qté",
    caption: "Attributs",
    notSupported: "Le type d'attribut n'est pas pris en charge.",
    requiredAttribute: "{{param}} obligatoire",
    defaultRequiredMessage: "Saisissez une valeur pour la zone obligatoire.",
    singleSelectRequiredMessage: "Sélectionnez une valeur.",
    singleSelectAdditionalRequiredMessage: "Sélectionnez ou saisissez une valeur.",
    multiSelectRequiredMessage: "Sélectionnez une ou plusieurs valeurs.",
    wrongNumericFormat: "Format incorrect, cet attribut numérique doit être saisi conformément au modèle {{pattern}}.",
    wrongNumericFormatMessage: "Saisissez le nombre au format suivant : {{pattern}}",
    wrongIntervalFormat: "Saisissez une valeur comprise dans les limites indiquées.",
    deselectionNotPossible: "Ajoutez un autre produit avant de retirer celui-ci.",
    dropDownSelectMsg: "Effectuez une sélection.",
    noOptionSelectedMsg: "Aucune option sélectionnée",
    notVisibleAttributeMsg: "Cet attribut n'est pas visible par l'utilisateur."
  },
  button: {
    previous: "Précédent",
    next: "Suivant",
    back: "Retour",
    more: "plus",
    less: "moins",
    deselect: "Désélectionner",
    select: "Sélectionner",
    add: "Ajouter",
    remove: "Retirer",
    exit: "Quitter la configuration",
    exitMobile: "Quitter",
    cancelConfiguration: "Annuler la configuration",
    cancelConfigurationMobile: "Annuler",
    filterOverview: "Filtrer",
    filterOverviewWithCount: "Filtrer ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Terminé",
    groupIncomplete: "Non terminé",
    groupConflict: "Conflit",
    subgroup: "a des sous-groupes"
  },
  priceSummary: {
    basePrice: "Prix de base",
    selectedOptions: "Options sélectionnées",
    totalPrice: "Total",
    totalPricePerItem: "Total par élément"
  },
  addToCart: {
    button: "Ajouter au panier",
    buttonAfterAddToCart: "Accéder au panier",
    buttonForQuote: "Accéder au devis",
    buttonUpdateCart: "Terminé",
    buttonDisplayOnly: "Terminé",
    buttonClose: "Fermer",
    confirmation: "La configuration a été ajoutée au panier.",
    confirmationUpdate: "Le panier a été mis à jour avec la configuration.",
    confirmationQuoteUpdate: "Le devis a été mis à jour avec la configuration.",
    quantity: "Qté"
  },
  overviewForm: {
    noAttributeHeader: "Aucun résultat",
    noAttributeText: "Supprimez les filtres pour afficher le contenu de la synthèse.",
    itemPrice: "Prix de l'article"
  },
  overviewSidebar: {
    menu: "Menu",
    filter: "Filtre"
  },
  overviewFilter: {
    title: "Sélectionner les filtres",
    byOption: "Filtrer par option",
    byGroup: "Filtrer par groupe",
    byPrice: "Options de prix",
    mySelections: "Mes sélections",
    removeAll: "Supprimer tout",
    removeAllFilters: "Supprimer tous les filtres",
    removeByPrice: "Supprimer le filtre de prix",
    removeMySelections: "Supprimer le filtre Mes sélections",
    removeByGroup: "Supprimer le filtre de groupe pour le groupe {{group}}",
    noFiltersAvailable: "Aucun filtre disponible"
  },
  group: {
    general: "Général",
    conflictHeader: "Résoudre les conflits",
    conflictGroup: "Conflit pour {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Suggestion {{number}} :",
    suggestionText: "Modifier la valeur pour \"{{ attribute }}\"",
    viewConflictDetails: "Conflit détecté - Afficher les détails",
    conflictDetected: "Conflit détecté",
    viewConfigurationDetails: "Afficher dans la configuration"
  },
  restartDialog: {
    title: "Configuration inachevée",
    description: "Il existe une configuration inachevée d'une session précédente. Voulez-vous la reprendre ou commencer une nouvelle configuration ?",
    restartButton: "Commencer une nouvelle configuration",
    resumeButton: "Reprendre"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Ce produit est un produit de base et ne peut pas être ajouté au panier. Sélectionnez à la place une variante de produit."
  },
  a11y: {
    configureProduct: "Configurer le produit",
    showDetailsProduct: "Afficher les détails du produit",
    cartEntryBundleInfo: "Il y a un article. ",
    cartEntryBundleInfo_other: "Il y a {{items}} articles. ",
    cartEntryBundleName: "Article {{ name }}",
    cartEntryBundleNameWithQuantity: "Article {{ name }} quantité de l'article {{quantity}}",
    cartEntryBundleNameWithPrice: "Article {{ name }} prix de l'article {{price}}",
    cartEntryBundle: "Article {{ name }} prix de l'article {{price}} quantité de l'article {{quantity}}",
    cartEntryInfoIntro: "Le produit a les attributs et les valeurs sélectionnées suivants.",
    cartEntryInfo: "L'attribut {{ attribute }} a la valeur sélectionnée {{value}}.",
    nameOfAttribute: "Nom de l'attribut",
    valueOfAttribute: "Valeur de l'attribut {{ attribute }}",
    forAttribute: "{{ value }} pour l'attribut {{ attribute }}",
    valueOfAttributeFull: "Valeur {{ value }} de l'attribut {{ attribute }}.",
    valueOfDateAttributeFull: "Valeur {{ value }} de l'attribut de date {{ attribute }}. Appuyez sur la barre d'espacement pour accéder au sélecteur de dates.",
    valueOfAttributeFullWithPrice: "Valeur {{ value }} de l'attribut {{ attribute }}, majoration {{ price }}",
    selectedValueOfAttributeFull: "Valeur sélectionnée {{ value }} de l'attribut {{ attribute }}",
    selectedValueOfAttributeFullWithPrice: "Valeur sélectionnée {{ value }} de l'attribut {{ attribute }}, majoration {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Valeur en lecture seule {{ value }} de l'attribut {{ attribute }}, majoration {{ price }}",
    readOnlyValueOfAttributeFull: "Valeur en lecture seule {{ value }} de l'attribut {{ attribute }}",
    valueOfAttributeBlank: "La valeur de l'attribut {{ attribute }} est vide.",
    valueOfDateAttributeBlank: "La valeur de l'attribut de date {{ attribute }} est vide. Appuyez sur la barre d'espacement pour accéder au sélecteur de dates.",
    value: "Valeur {{ value }}",
    attribute: "Attribut {{ attribute }}",
    requiredAttribute: "L'attribut {{param}} est obligatoire.",
    listOfAttributesAndValues: "Liste des attributs et de leurs valeurs :",
    editAttributesAndValues: "Modifier les valeurs des attributs :",
    group: "{{ group }} groupe d'attributs",
    itemOfAttributeSelected: "L'élément {{ item }} de l'attribut {{ attribute }} est sélectionné. {{ itemIndex }} sur {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "L'élément {{ item }} de l'attribut {{ attribute }} est sélectionné, majoration {{ price }}. {{ itemIndex }} sur {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "L'élément {{ item }} de l'attribut {{ attribute }} est sélectionné. Appuyez sur le bouton pour le désélectionner. {{ itemIndex }} sur {{ itemCount }} ",
    itemOfAttributeSelectedPressToUnselectWithPrice: "L'élément {{ item }} de l'attribut {{ attribute }} est sélectionné, majoration {{ price }} Appuyez sur le bouton pour le désélectionner. {{ itemIndex }} sur {{ itemCount }}",
    itemOfAttributeUnselected: "L'élément {{ item }} de l'attribut {{ attribute }} est désélectionné. Appuyez sur le bouton pour le sélectionner. {{ itemIndex }} sur {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "L'élément {{ item }} de l'attribut {{ attribute }} est désélectionné, majoration {{ price }}. Appuyez sur le bouton pour le sélectionner. {{ itemIndex }} sur {{ itemCount }}",
    selectNoItemOfAttribute: "Pour ne sélectionner aucun élément pour l'attribut {{ attribute }} appuyez sur le bouton. {{ itemIndex }} sur {{ itemCount }}",
    itemOfAttribute: "Élément de l'attribut {{ attribute }}",
    itemOfAttributeFull: "Élément {{ item }} de l'attribut {{ attribute }}",
    itemOfAttributeFullWithPrice: "Élément {{ item }} de l'attribut {{ attribute }}, majoration {{ price }}",
    itemOfAttributeFullWithQuantity: "Élément {{ item }} de l'attribut {{ attribute }}, quantité {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Élément {{ item }} de l'attribut {{ attribute }}, quantité {{ quantity }}, majoration {{ price }}",
    itemDescription: "Description pour l'élément {{ item }}",
    listbox: "Zone de liste avec {{ count }} valeurs",
    valueSurcharge: "Majoration de valeur",
    conflictDetected: "Conflit détecté ! Examinez les conflits en haut de la liste des groupes.",
    conflictsInConfiguration: "Il y a des conflits dans votre configuration. Nombre de conflits : {{ numberOfConflicts }}, sélectionnez pour plus de détails.",
    listOfGroups: "Liste des groupes",
    inListOfGroups: "Vous êtes dans liste des groupes",
    groupName: "Groupe {{ group }}",
    groupBack: "Vous êtes dans un sous-groupe. Sélectionnez pour revenir en arrière.",
    conflictBack: "Vous vous trouvez dans le solutionneur de conflits. Sélectionnez pour revenir en arrière ou sélectionnez les onglets suivants pour résoudre les conflits.",
    iconConflict: "Le groupe a des conflits.",
    iconIncomplete: "Le groupe a des attributs obligatoires sans valeurs sélectionnées.",
    iconComplete: "Le groupe est terminé.",
    iconSubGroup: "Le groupe a des sous-groupes.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Affichez plus d'informations sur le produit {{ product }} ou poursuivez vers la configuration.",
    showLessProductInfo: "Affichez moins d'informations sur le produit {{ product }} ou poursuivez vers la configuration.",
    productName: "Nom du produit",
    productCode: "Code de produit",
    productDescription: "Description du produit",
    kbKeyName: "Nom de la base de connaissances {{name}}",
    kbKeyLogsys: "Système logique de la base de connaissances {{logsys}}",
    kbKeyVersion: "Version de la base de connaissances {{version}}",
    kbKeyBuildNr: "Numéro de build de la base de connaissances {{number}}",
    configurationPage: "Vous êtes sur la page de configuration.",
    configurationPageLink: "Naviguez vers la page de configuration.",
    overviewPage: "Vous êtes sur la page de synthèse. Vérifiez les attributs et leurs valeurs sélectionnées avec le mode de lecture de votre lecteur d'écran.",
    overviewPageLink: "Naviguez vers la page de synthèse de la configuration.",
    navigateToConflict: "Suivez le lien pour obtenir plus de détails concernant le conflit pour l'attribut {{ attribute }}.",
    numericIntervalStandard: "Saisissez une valeur comprise entre {{ minValue }} et {{ maxValue }}.",
    numericIntervalStandardOpen: "(Les points de terminaison ne sont pas inclus)",
    numericIntervalStandardLowerEndpointNotIncluded: "(Le point de terminaison inférieur n'est pas inclus)",
    numericIntervalStandardUpperEndpointNotIncluded: "(Le point de terminaison supérieur n'est pas inclus)",
    numericInfiniteIntervalMinValueIncluded: "Saisissez une valeur supérieure ou égale à {{ value }}.",
    numericInfiniteIntervalMinValue: "Saisissez une valeur supérieure à {{ value }}.",
    numericInfiniteIntervalMaxValueIncluded: "Saisissez une valeur inférieure ou égale à {{ value }}.",
    numericInfiniteIntervalMaxValue: "Saisissez une valeur inférieure à {{ value }}.",
    numericIntervalSingleValue: "Saisissez {{ value }}.",
    combinedIntervalsText: "{{ combinedInterval }} ou {{ newInterval }}",
    additionalValue: "Vous pouvez aussi saisir une autre valeur.",
    addToCartPrices: "Prix de base {{basePrice}}, options sélectionnées {{selectedOptions}}, prix total {{totalPrice}}",
    filterOverview: "Ouvrir le menu des filtres de la synthèse de configuration",
    filterOverviewWithCount: "Ouvrir le menu des filtres de la synthèse de configuration - {{numAppliedFilters}} filtres actifs",
    closeFilterMenu: "Fermer le menu des filtres de la synthèse de configuration et appliquer les filtres sélectionnés",
    filterOverviewByPrice: "Filtrer la synthèse de la configuration par options de prix",
    filterOverviewByMySelections: "Filtrer la synthèse de la configuration par vos sélections",
    filterOverviewByGroup: "Filtrer la synthèse de la configuration par groupe {{groupName}}",
    closeConflictSolverModal: "Fermer la boîte de dialogue modale du solutionneur de conflits",
    closeRestartDialog: "Fermer la boîte de dialogue modale \"Configuration inachevée\" et revenir à la page des détails du produit",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Versions préconfigurées"
  }
};
var configurator$n = {
  configurator: configurator$m
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const fr = {
  configurator: configurator$n
};
var configurator$k = {
  header: {
    consistent: "सुसंगत",
    complete: "पूर्ण करें",
    configId: "कॉन्फ़िगरेशन ID",
    toconfig: "कॉन्फ़िगर करें",
    toConfigReadOnly: "विवरण दिखाएँ",
    editConfiguration: "कॉन्फ़िगरेशन संपादित करें",
    displayConfiguration: "कॉन्फ़िगरेशन प्रदर्शित करें",
    resolveIssues: "समस्या का समाधान करें",
    resolveIssue: "समस्या का समाधान करें",
    resolveConflicts: "टकरावों का समाधान करें",
    conflictWarning: "जारी रखने के लिए टकरावों का समाधान किया जाना चाहिए",
    updateMessage: "पृष्ठभूमि में कॉन्फिगरेशन अपडेट किया जा रहा है",
    conflictsResolved: "टकरावों का समाधान किया गया",
    showMore: "अधिक दिखाएं",
    showLess: "कम दिखाएं",
    items: "{{count}} आइटम",
    items_other: "{{count}} आइटम",
    show: "दिखाएं",
    hide: "छिपाएं",
    multipleWarnings: "एक से अधिक चेतावनियां हैं.",
    reviewWarnings: "इन चेतावनियों की समीक्षा करें.",
    multipleErrors: "एक से अधिक त्रुटियां हैं",
    reviewErrors: "इन त्रुटियों की समीक्षा करें.",
    kbKeyName: "Kb नाम",
    kbKeyLogsys: "KB तार्किक सिस्टम",
    kbKeyVersion: "KB संस्करण",
    kbKeyBuildNr: "KB बिल्ड संख्या"
  },
  tabBar: {
    configuration: "कॉन्फ़िगरेशन",
    overview: "ओवरव्यू"
  },
  notificationBanner: {
    numberOfIssues: "चेक आउट करने से पहले {{count}} समस्याओं का समाधान किया जाना आवश्यक है.",
    numberOfIssues_other: "चेक आउट करने से पहले {{count}} समस्याओं का समाधान किया जाना आवश्यक है.",
    numberOfConflicts: "चेक आउट करने से पहले {{count}} टकराव का समाधान किया जाना आवश्यक है.",
    numberOfConflicts_other: "चेक आउट करने से पहले {{count}} टकरावों का समाधान किया जाना आवश्यक है."
  },
  attribute: {
    id: "ID",
    quantity: "मात्रा",
    caption: "विशेषताएं",
    notSupported: "विशेषता प्रकार समर्थित नहीं है.",
    requiredAttribute: "{{param}} आवश्यक",
    defaultRequiredMessage: "आवश्यक फ़ील्ड के लिए मान दर्ज करें",
    singleSelectRequiredMessage: "मान चुनें",
    singleSelectAdditionalRequiredMessage: "मान दर्ज करें या चुनें",
    multiSelectRequiredMessage: "एक या अधिक मानों का चयन करें",
    wrongNumericFormat: "गलत स्वरूप, यह अंकीय विशेषता {{pattern}} पैटर्न के अनुसार दर्ज की जानी चाहिए",
    wrongNumericFormatMessage: "निम्न स्वरूप में संख्या दर्ज करेंः {{pattern}}",
    wrongIntervalFormat: "चिह्नित सीमाओं के अंतर्गत मान दर्ज करें",
    deselectionNotPossible: "इस एक को निकालने से पहले कोई भिन्न उत्पाद जोड़ें",
    dropDownSelectMsg: "कोई चयन करें",
    noOptionSelectedMsg: "कोई विकल्प चयनित नहीं",
    notVisibleAttributeMsg: "यह विशेषता उपयोगकर्ता को दृश्यमा नहीं है."
  },
  button: {
    previous: "पिछला",
    next: "अगला",
    back: "वापस जाएं",
    more: "और अधिक",
    less: "कम",
    deselect: "चयन रद्द करें",
    select: "चयन करें",
    add: "जोड़ें",
    remove: "निकालें",
    exit: "कॉन्फ़िगरेशन से बाहर निकलें",
    exitMobile: "निकास",
    cancelConfiguration: "कॉन्फ़िगरेश रद्द करें",
    cancelConfigurationMobile: "रद्द करें",
    filterOverview: "फ़िल्टर",
    filterOverviewWithCount: "फ़िल्टर ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "पूर्ण करें",
    groupIncomplete: "अपूर्ण",
    groupConflict: "टकराव",
    subgroup: "उप-समूह हैं"
  },
  priceSummary: {
    basePrice: "आधार मूल्य",
    selectedOptions: "चयनित विकल्प",
    totalPrice: "कुल",
    totalPricePerItem: "प्रति आइटम कुल योग"
  },
  addToCart: {
    button: "कार्ट में जोड़ें",
    buttonAfterAddToCart: "कार्ट में जारी रखें",
    buttonForQuote: "कोट के लिए जारी रखें",
    buttonUpdateCart: "पूरा हुआ",
    buttonDisplayOnly: "पूरा हुआ",
    buttonClose: "बंद करें",
    confirmation: "कॉन्फ़िगरेशन कार्ट में जोड़ा गया",
    confirmationUpdate: "कार्ट कॉन्फ़िगरेशन के साथ अपडेट किया गया",
    confirmationQuoteUpdate: "कोट कॉन्फ़िगरेशन के साथ अपडेट किया गया",
    quantity: "मात्रा"
  },
  overviewForm: {
    noAttributeHeader: "कोई परिणाम नहीं",
    noAttributeText: "ओवरव्यू सामग्री देखने हेतु फ़िल्टर निकालें",
    itemPrice: "आइटम मूल्य"
  },
  overviewSidebar: {
    menu: "मेनू",
    filter: "फ़िल्टर"
  },
  overviewFilter: {
    title: "फ़िल्टर्स का चयन करें",
    byOption: "विकल्प के अनुसार फ़िल्टर करें",
    byGroup: "समूह के अनुसार फ़िल्टर करें",
    byPrice: "मूल्य-प्रासंगिक विकल्प",
    mySelections: "मेरे चयन",
    removeAll: "सभी निकालें",
    removeAllFilters: "सभी फ़िल्टर निकालें",
    removeByPrice: "मूल्य-प्रासंगिक फ़िल्टर निकालें",
    removeMySelections: "मेरे चयनित फ़िल्टर निकालें",
    removeByGroup: "समूह {{group}} के लिए समूह फ़िल्टर निकालें",
    noFiltersAvailable: "कोई फ़िल्टर उपलब्ध नहीं है"
  },
  group: {
    general: "सामान्य",
    conflictHeader: "टकरावों का समाधान करें",
    conflictGroup: "{{attribute}} हेतु टकराव"
  },
  conflict: {
    suggestionTitle: "सुझाव {{number}}:",
    suggestionText: "\"{{ attribute }}\" के लिए मान बदलें",
    viewConflictDetails: "टकराव पहचाना गया - विवरण देखें",
    conflictDetected: "टकराव पहचाना गया",
    viewConfigurationDetails: "कॉन्फ़िगरेशन में देखें"
  },
  restartDialog: {
    title: "अपूर्ण कॉन्फ़िगरेशन",
    description: "पूर्व के सत्र से कोई अपूर्ण कॉन्फ़िगरेशन है. क्या आप पुनः प्रारंभ करना या नया कॉन्फ़िगरेशन प्रारंभ करना चाहते हैं?",
    restartButton: "नया प्रारंभ करें",
    resumeButton: "पुनः प्रारंभ करें"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "यह उत्पाद आधार उत्पाद है और कार्ट में नहीं जोड़ा जा सकता; इसके बजाय उत्पाद भिन्न रूप चुनें"
  },
  a11y: {
    configureProduct: "उत्पाद कॉन्फ़िगर करें",
    showDetailsProduct: "उत्पाद का विवरण दिखाएं",
    cartEntryBundleInfo: "कोई आइटम है ",
    cartEntryBundleInfo_other: "{{items}} आइटम है ",
    cartEntryBundleName: "आइटम {{ name }}",
    cartEntryBundleNameWithQuantity: "आइटम {{ name }} आइटम मात्रा {{quantity}}",
    cartEntryBundleNameWithPrice: "आइटम {{ name }} आइटम मूल्य {{price}}",
    cartEntryBundle: "आइटम {{ name }} आइटम मूल्य {{price}} आइटम मात्रा {{quantity}}",
    cartEntryInfoIntro: "उत्पाद में निम्न विशेषताएं और चयनित मान हैं",
    cartEntryInfo: "विशेषता {{ attribute }} में चयनित मान {{value}} है",
    nameOfAttribute: "विशेषता का नाम",
    valueOfAttribute: "विशेषता {{ attribute }} का मान",
    forAttribute: "विशेषता {{ attribute }} के लिए {{ value }}",
    valueOfAttributeFull: "विशेषता {{ attribute }}का मान {{ value }}",
    valueOfDateAttributeFull: "दिनांक विशेषता {{ attribute }} का मान {{ value }}. दिनांक पिकर दर्ज करने के लिए स्पेस कुंजी दबाएं.",
    valueOfAttributeFullWithPrice: "विशेषता {{ attribute }}का मान {{ value }}, अधिभार {{ price }}",
    selectedValueOfAttributeFull: "विशेषता {{ attribute }}का चयनित मान {{ value }}",
    selectedValueOfAttributeFullWithPrice: "विशेषता {{ attribute }}का चयनित मान {{ value }}, अधिभार {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "विशेषता {{ attribute }}का केवल-पठन मान {{ value }}, अधिभार {{ price }}",
    readOnlyValueOfAttributeFull: "विशेषता {{ attribute }} का केवल-पठन मान {{ value }}",
    valueOfAttributeBlank: "विशेषता {{ attribute }} का मान रिक्त है.",
    valueOfDateAttributeBlank: "दिनांक विशेषता {{ attribute }} का मान रिक्त है. दिनांक पिकर दर्ज करने के लिए स्पेस कुंजी दबाएं",
    value: "मान {{ value }}",
    attribute: "विशेषता {{ attribute }}",
    requiredAttribute: "विशेषता {{param}}आवश्यक है",
    listOfAttributesAndValues: "विशेषताएं और उनके मानों की सूचीः",
    editAttributesAndValues: "विशेषताओं के मान संपादित करेंः",
    group: "विशेषताओं का {{ group }} समूह",
    itemOfAttributeSelected: "विशेषता {{ attribute }} का आइटम {{ item }} चुना गया है. {{ itemCount }} का {{ itemIndex }}",
    itemOfAttributeSelectedWithPrice: "विशेषता {{ attribute }} का आइटम {{ item }} चुना गया है, अधिभार {{ price }}. {{ itemCount }} का {{ itemIndex }}",
    itemOfAttributeSelectedPressToUnselect: "विशेषता {{ attribute }} का आइटम {{ item }} चुना गया है. न चुनने के लिए बटन दबाएं. {{ itemCount }} का {{ itemIndex }} ",
    itemOfAttributeSelectedPressToUnselectWithPrice: "विशेषता {{ attribute }} का आइटम {{ item }} चुना गया है. अधिभार {{ price }} न चुनने के लिए बटन दबाएं. {{ itemCount }} का {{ itemIndex }}",
    itemOfAttributeUnselected: "विशेषता {{ attribute }} का आइटम {{ item }} नहीं चुना है. चुनने के लिए बटन दबाएं. {{ itemCount }} का {{ itemIndex }} ",
    itemOfAttributeUnselectedWithPrice: "विशेषता {{ attribute }} का आइटम {{ item }} नहीं चुना है. अधिभार {{ price }} चुनने के लिए बटन दबाएं. {{ itemCount }} का {{ itemIndex }}",
    selectNoItemOfAttribute: "विशेषता {{ attribute }} के लिए आइटम नहीं चुनने के लिए बटन दबाएं. {{ itemCount }} का {{ itemIndex }}",
    itemOfAttribute: "विशेषता {{ attribute }} का आइटम",
    itemOfAttributeFull: "विशेषता {{ attribute }} का आइटम {{ item }}",
    itemOfAttributeFullWithPrice: "विशेषता {{ attribute }}का मान {{ item }}, अधिभार {{ price }}",
    itemOfAttributeFullWithQuantity: "विशेषता {{ attribute }} का आइटम {{ item }}, मात्रा {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "विशेषता {{ attribute }}का मान {{ item }}, मात्रा {{ quantity }}, अधिभार {{ price }}",
    itemDescription: "आइटम {{ item }} के लिए विवरण",
    listbox: "{{ count }} मानों सहित लिस्टबॉक्स",
    valueSurcharge: "मान अधिभार",
    conflictDetected: "टकराव पहचाना गया! समूह सूची के ऊपर टकराव जांचें.",
    conflictsInConfiguration: "आपके कॉन्फ़िगरेशन में टकराव हैं. टकरावों की संख्याः {{ numberOfConflicts }}, अधिक विवरण के लिए चुनें.",
    listOfGroups: "समूहों की सूची",
    inListOfGroups: "आप समूह सूची में हैं",
    groupName: "समूह {{ group }}",
    groupBack: "आप उप-समूह में हैं. वापस जाने के लिए चुनें.",
    conflictBack: "आप टकराव समाधानकर्ता में हैं. वापस जाने के लिे चुनें या टकरावों के समाधान के लिए अगला टैब चुनें.",
    iconConflict: "समूह में टकराव हैं.",
    iconIncomplete: "समूह में चयनित मानों के बिना आवश्यक विशेषताएं हैं.",
    iconComplete: "समूह पूर्ण है.",
    iconSubGroup: "समूह के उप-समूह हैं.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "उत्पाद {{ product }} के लिए अधिक जानकारी दिखाएं या कॉन्फ़िगरेशन हेतु जारी रखें.",
    showLessProductInfo: "उत्पाद {{ product }} के लिए कम जानकारी दिखाएं या कॉन्फ़िगरेशन हेतु जारी रखें.",
    productName: "उत्पाद का नाम",
    productCode: "उत्पाद का कोड",
    productDescription: "उत्पादन वर्णन",
    kbKeyName: "Kb नाम {{name}}",
    kbKeyLogsys: "KB तार्किक सिस्टम {{logsys}}",
    kbKeyVersion: "KB संस्करण {{version}}",
    kbKeyBuildNr: "Kb बिल्ड संख्या {{number}}",
    configurationPage: "आप कॉन्फ़िगरेशन पृष्ठ पर हैं.",
    configurationPageLink: "कॉन्फ़िगरेशन पृष्ठ पर नेविगेट करें.",
    overviewPage: "आप ओवरव्यू पृष्ठ पर हैं. आपके स्क्रीन पाठक के साथ पठन मोड सहित विशेषताएं और उनके चयनित मान जांचें.",
    overviewPageLink: "कॉन्फ़िगरेशन ओवरव्यू पृष्ठ पर नेविगेट करें.",
    navigateToConflict: "विशेषता {{ attribute }} के लिए टकराव पर अधिक जानकारी के लिए लिंक फॉलो करें.",
    numericIntervalStandard: "{{ minValue }} और {{ maxValue }} के बीच मान दर्ज करें",
    numericIntervalStandardOpen: "(समाप्ति बिंदु शामिल नहीं किए हैं)",
    numericIntervalStandardLowerEndpointNotIncluded: "(निचले समाप्ति बिंदु शामिल नहीं किए हैं)",
    numericIntervalStandardUpperEndpointNotIncluded: "(ऊपरी समाप्ति बिंदु शामिल नहीं किए हैं)",
    numericInfiniteIntervalMinValueIncluded: "{{ value }} से बड़ा या समान मान दर्ज करें",
    numericInfiniteIntervalMinValue: "{{ value }} से बड़ा मान दर्ज करें",
    numericInfiniteIntervalMaxValueIncluded: "{{ value }} से छोटा या समान मान दर्ज करें",
    numericInfiniteIntervalMaxValue: "{{ value }} से छोटा मान दर्ज करें",
    numericIntervalSingleValue: "{{ value }} दर्ज करें",
    combinedIntervalsText: "{{ combinedInterval }} या {{ newInterval }}",
    additionalValue: "आप अतिरिक्त मान भी दर्ज कर सकते हैं.",
    addToCartPrices: "आधार मूल्य {{basePrice}}, चयनित विकल्प {{selectedOptions}}, कुल मूल्य {{totalPrice}}",
    filterOverview: "कॉन्फ़िगरेशन ओवरव्यू फ़िल्टर मेनू खोलें",
    filterOverviewWithCount: "कॉन्फ़िगरेशन ओवरव्यू फ़िल्टर मेनू खोलें - {{numAppliedFilters}} सक्रिय फ़िल्टर",
    closeFilterMenu: "कॉन्फ़िगरेशन ओवरव्यू फ़िल्टर मेनू बंद करें और चयनित फ़िल्टर लागू करें",
    filterOverviewByPrice: "मूल्य-प्रासंगिक विकल्पों के अनुसार कॉन्फ़िगरेशन ओवरव्यू फ़िल्टर करें",
    filterOverviewByMySelections: "अपने चयन के अनुसार कॉन्फ़िगरेशन ओवरव्यू फ़िल्टर करें",
    filterOverviewByGroup: "समूह {{groupName}} के अनुसार कॉन्फ़िगरेशन ओवरव्यू फ़िल्टर करें",
    closeConflictSolverModal: "टकराव समाधानकर्ता मॉडल बंद करें",
    closeRestartDialog: "\"अपूर्ण कॉन्फ़िगरेशन\" डायलॉग बंद करें और उत्पाद विवरण पृष्ठ पर वापस नेविगेट करें",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "पूर्व-कॉन्फ़िगर किए गए संस्करण"
  }
};
var configurator$l = {
  configurator: configurator$k
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hi = {
  configurator: configurator$l
};
var configurator$i = {
  header: {
    consistent: "Konzisztens",
    complete: "Befejezve",
    configId: "Konfigurációazonosító",
    toconfig: "Konfigurálás",
    toConfigReadOnly: "Részletek",
    editConfiguration: "Konfiguráció szerkesztése",
    displayConfiguration: "Konfiguráció megjelenítése",
    resolveIssues: "Problémák megoldása",
    resolveIssue: "Probléma megoldása",
    resolveConflicts: "Ütközések feloldása",
    conflictWarning: "A folytatáshoz fel kell oldani az ütközést",
    updateMessage: "A háttérben folyamatban van a konfiguráció aktualizálása",
    conflictsResolved: "A konfliktusok fel lettek oldva",
    showMore: "több megjelenítése",
    showLess: "kevesebb megjelenítése",
    items: "{{count}} tétel",
    items_other: "{{count}} tétel",
    show: "megjelenítés",
    hide: "elrejtés",
    multipleWarnings: "Több figyelmeztetés található.",
    reviewWarnings: "Figyelmeztetések áttekintése",
    multipleErrors: "Több hiba található.",
    reviewErrors: "Hibák áttekintése",
    kbKeyName: "Kb-név",
    kbKeyLogsys: "Kb logikai rendszere",
    kbKeyVersion: "Kb-verzió",
    kbKeyBuildNr: "Kb-build száma"
  },
  tabBar: {
    configuration: "Konfiguráció",
    overview: "Áttekintés"
  },
  notificationBanner: {
    numberOfIssues: "A rendelés leadása előtt meg kell oldani {{count}} problémát.",
    numberOfIssues_other: "A rendelés leadása előtt meg kell oldani {{count}} problémát.",
    numberOfConflicts: "A rendelés leadása előtt fel kell oldani {{count}} ütközést.",
    numberOfConflicts_other: "A rendelés leadása előtt fel kell oldani {{count}} ütközést."
  },
  attribute: {
    id: "Azonosító",
    quantity: "Menny.",
    caption: "Attribútumok",
    notSupported: "Az attribútumtípus nem támogatott.",
    requiredAttribute: "A(z) {{param}} kötelező",
    defaultRequiredMessage: "Adja meg a kötelező mező értékét",
    singleSelectRequiredMessage: "Érték kiválasztása",
    singleSelectAdditionalRequiredMessage: "Válassza ki vagy adja meg az értéket",
    multiSelectRequiredMessage: "Válasszon ki néhány értéket",
    wrongNumericFormat: "Hibás formátum; ezt a numerikus attribútumot a következő minta szerint kell megadni: {{pattern}}",
    wrongNumericFormatMessage: "Adja meg a számot a következő formátumban: {{pattern}}",
    wrongIntervalFormat: "Adjon meg egy, a jelölt határértékeken belül található értéket",
    deselectionNotPossible: "Adjon hozzá egy másik terméket, mielőtt ezt eltávolítaná",
    dropDownSelectMsg: "Válasszon",
    noOptionSelectedMsg: "Nincs kiválasztva beállítás",
    notVisibleAttributeMsg: "Ez az attribútum nem látható a felhasználó számára"
  },
  button: {
    previous: "Előző",
    next: "Következő",
    back: "Vissza",
    more: "több",
    less: "kevesebb",
    deselect: "Kiválasztás megszüntetése",
    select: "Kiválasztás",
    add: "Hozzáadás",
    remove: "Eltávolítás",
    exit: "Kilépés a konfigurációból",
    exitMobile: "Kilépés",
    cancelConfiguration: "Konfiguráció törlése",
    cancelConfigurationMobile: "Mégse",
    filterOverview: "Szűrő",
    filterOverviewWithCount: "Szűrő ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Befejezve",
    groupIncomplete: "Befejezetlen",
    groupConflict: "Ütközés",
    subgroup: "alcsoportokkal rendelkezik"
  },
  priceSummary: {
    basePrice: "Alapár",
    selectedOptions: "Kiválasztott beállítások",
    totalPrice: "Összesen",
    totalPricePerItem: "Tételenkénti összesítés"
  },
  addToCart: {
    button: "Kosárba",
    buttonAfterAddToCart: "Folytatás a kosárhoz",
    buttonForQuote: "Folytatás az ajánlathoz",
    buttonUpdateCart: "Kész",
    buttonDisplayOnly: "Kész",
    buttonClose: "Bezárás",
    confirmation: "Konfiguráció hozzáadva a kosárhoz",
    confirmationUpdate: "Kosár frissítve a konfigurációval",
    confirmationQuoteUpdate: "Ajánlat frissítve a konfigurációval",
    quantity: "Menny."
  },
  overviewForm: {
    noAttributeHeader: "Nincsenek eredmények",
    noAttributeText: "Távolítsa el a szűrőket az áttekintés tartalmának megtekintéséhez",
    itemPrice: "Tétel ára"
  },
  overviewSidebar: {
    menu: "Menü",
    filter: "Szűrő"
  },
  overviewFilter: {
    title: "Szűrők kiválasztása",
    byOption: "Szűrés beállítás szerint",
    byGroup: "Szűrés csoport szerint",
    byPrice: "Árra vonatkozó beállítások",
    mySelections: "Saját kiválasztás",
    removeAll: "Összes eltávolítása",
    removeAllFilters: "Összes szűrő eltávolítása",
    removeByPrice: "Árra vonatkozó szűrő eltávolítása",
    removeMySelections: "Saját kiválasztás szűrő eltávolítása",
    removeByGroup: "Csoportszűrő eltávolítása a következő csoporthoz: {{group}}",
    noFiltersAvailable: "Nincsenek elérhető szűrők"
  },
  group: {
    general: "Általános",
    conflictHeader: "Ütközések feloldása",
    conflictGroup: "Ütközés a következőnél: {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Javaslat ({{number}}):",
    suggestionText: "„{{ attribute }}” értékének módosítása",
    viewConflictDetails: "Ütközés észlelve – Részletek megtekintése",
    conflictDetected: "Ütközés észlelve",
    viewConfigurationDetails: "Megtekintés a konfigurációban"
  },
  restartDialog: {
    title: "Be nem fejezett konfiguráció",
    description: "Be nem fejezett konfiguráció található egy korábbi munkamenetből. Szeretné folytatni, vagy inkább új konfigurációt indítana?",
    restartButton: "Új indítása",
    resumeButton: "Folytatás"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Ez a termék egy alaptermék, ezért nem adható hozzá a kosárhoz; válasszon helyette egy termékváltozatot"
  },
  a11y: {
    configureProduct: "Termék konfigurálása",
    showDetailsProduct: "A termék részleteinek megjelenítése",
    cartEntryBundleInfo: "Egy tétel található ",
    cartEntryBundleInfo_other: "{{items}} tétel található ",
    cartEntryBundleName: "Tétel ({{ name }})",
    cartEntryBundleNameWithQuantity: "Tétel ({{ name }}), tételmennyiség ({{quantity}})",
    cartEntryBundleNameWithPrice: "Tétel ({{ name }}), tételár ({{price}})",
    cartEntryBundle: "Tétel ({{ name }}), tételár ({{price}}), tételmennyiség ({{quantity}})",
    cartEntryInfoIntro: "A termék a következő attribútumokkal és kiválasztott értékekkel rendelkezik",
    cartEntryInfo: "A(z) {{ attribute }} attribútum a(z) {{value}} kiválasztott értékkel rendelkezik",
    nameOfAttribute: "Attribútum neve",
    valueOfAttribute: "A(z) {{ attribute }} attribútum értéke",
    forAttribute: "A(z) {{ attribute }} attribútum {{ value }} értéke",
    valueOfAttributeFull: "A(z) {{ attribute }} attribútum {{ value }} értéke.",
    valueOfDateAttributeFull: "A(z) {{ attribute }} dátumattribútum {{ value }} értéke. A dátumválasztó megnyitásához nyomja meg a Szóköz billentyűt.",
    valueOfAttributeFullWithPrice: "A(z) {{ attribute }} attribútum {{ value }} értéke, pótdíj: {{ price }}",
    selectedValueOfAttributeFull: "A(z) {{ attribute }} attribútum kiválasztott {{ value }} értéke",
    selectedValueOfAttributeFullWithPrice: "A(z) {{ attribute }} attribútum kiválasztott {{ value }} értéke, pótdíj: {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "A(z) {{ attribute }} attribútum csak olvasható {{ value }} értéke, pótdíj: {{ price }}",
    readOnlyValueOfAttributeFull: "A(z) {{ attribute }} attribútum csak olvasható {{ value }} értéke",
    valueOfAttributeBlank: "A(z) {{ attribute }} attribútum értéke üres.",
    valueOfDateAttributeBlank: "A(z) {{ attribute }} dátumattribútum értéke üres. A dátumválasztó megnyitásához nyomja meg a Szóköz billentyűt.",
    value: "Érték: {{ value }}",
    attribute: "Attribútum: {{ attribute }}",
    requiredAttribute: "A(z) {{param}} attribútum kötelező",
    listOfAttributesAndValues: "Az attribútumok és azok értékeinek listája:",
    editAttributesAndValues: "Az attribútumok értékeinek szerkesztése:",
    group: "A(z) {{ group }} attribútumcsoport",
    itemOfAttributeSelected: "A(z) {{ attribute }} attribútum {{ item }} tétele ki van választva. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "A(z) {{ attribute }} attribútum {{ item }} tétele ki van választva, pótdíj: {{ price }}. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "A(z) {{ attribute }} attribútum {{ item }} tétele ki van választva. Nyomjon egy gombot a kiválasztás törléséhez. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "A(z) {{ attribute }} attribútum {{ item }} tétele ki van választva, pótdíj: {{ price }}. Nyomjon egy gombot a kiválasztás törléséhez. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselected: "A(z) {{ attribute }} attribútum {{ item }} tételének kiválasztása törölve. Nyomjon egy gombot a kiválasztáshoz. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "A(z) {{ attribute }} attribútum {{ item }} tételének kiválasztása törölve, pótdíj: {{ price }}. Nyomjon egy gombot a kiválasztáshoz. {{ itemIndex }}/{{ itemCount }}",
    selectNoItemOfAttribute: "Ha nem szeretne tételt kiválasztani a(z) {{ attribute }} attribútumhoz, nyomjon meg egy gombot. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttribute: "A(z) {{ attribute }} attribútum tétele",
    itemOfAttributeFull: "A(z) {{ attribute }} attribútum {{ item }} tétele",
    itemOfAttributeFullWithPrice: "A(z) {{ attribute }} attribútum {{ item }} tétele, pótdíj: {{ price }}",
    itemOfAttributeFullWithQuantity: "A(z) {{ attribute }} attribútum {{ item }} tétele, mennyiség: {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "A(z) {{ attribute }} attribútum {{ item }} tétele, mennyiség:{{ quantity }}, pótdíj: {{ price }}",
    itemDescription: "A(z) {{ item }} tétel leírása",
    listbox: "A(z) {{ count }} értékeket tartalmazó listamező.",
    valueSurcharge: "Értékpótdíj",
    conflictDetected: "Ütközés észlelve! Ellenőrizze az ütközéseket a csoportlista tetején.",
    conflictsInConfiguration: "Ütközések találhatók a konfigurációjában. Ütközések száma: {{ numberOfConflicts }}, a további részletekért válassza ki őket.",
    listOfGroups: "Csoportok listája",
    inListOfGroups: "Ön jelenleg a csoportlistában van",
    groupName: "Csoport ({{ group }})",
    groupBack: "Ön jelenleg egy alcsoportban van. Kiválasztással visszaléphet.",
    conflictBack: "Ön jelenleg az ütközésfeloldóban van. Kiválasztással visszaléphet, vagy a következő lapokkal feloldhatja az ütközéseket.",
    iconConflict: "A csoport ütközéseket tartalmaz.",
    iconIncomplete: "A csoport kiválasztott értékek nélküli kötelező attribútumokkal rendelkezik.",
    iconComplete: "A csoport elkészült.",
    iconSubGroup: "A csoport alcsoporttal rendelkezik.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Több információt jeleníthet meg a(z) {{ product }} termékhez, vagy folytathatja a konfigurációt.",
    showLessProductInfo: "Kevesebb információt jeleníthet meg a(z) {{ product }} termékhez, vagy folytathatja a konfigurációt.",
    productName: "Termék neve",
    productCode: "Termékkód",
    productDescription: "Termék leírása",
    kbKeyName: "Kb-név ({{name}})",
    kbKeyLogsys: "Kb logikai rendszere ({{logsys}})",
    kbKeyVersion: "Kb-verzió ({{version}})",
    kbKeyBuildNr: "Kb-build száma ({{number}})",
    configurationPage: "Ön jelenleg a konfigurációs oldalon van.",
    configurationPageLink: "Navigáljon a konfigurációs oldalra.",
    overviewPage: "Ön jelenleg az áttekintés oldalon van. A képernyőolvasó olvasási módjával ellenőrizheti az attribútumokat és azok kiválasztott értékeit.",
    overviewPageLink: "Navigáljon a konfiguráció áttekintése oldalra.",
    navigateToConflict: "A hivatkozást követve további részleteket kaphat a(z) {{ attribute }} attribútum ütközéséről.",
    numericIntervalStandard: "{{ minValue }} és {{ maxValue }} közötti értéket adjon meg",
    numericIntervalStandardOpen: "(a végpontok nélkül)",
    numericIntervalStandardLowerEndpointNotIncluded: "(az alsó végpont nélkül)",
    numericIntervalStandardUpperEndpointNotIncluded: "(a felső végpont nélkül)",
    numericInfiniteIntervalMinValueIncluded: "Adjon meg egy értéket, amely legalább {{ value }}",
    numericInfiniteIntervalMinValue: "Adjon meg egy értéket, amely nagyobb, mint {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Adjon meg egy értéket, amely legfeljebb {{ value }}",
    numericInfiniteIntervalMaxValue: "Adjon meg egy értéket, amely kisebb, mint {{ value }}",
    numericIntervalSingleValue: "Adja meg a következőt: {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} vagy {{ newInterval }}",
    additionalValue: "Megadhat még egy értéket.",
    addToCartPrices: "Alapár: {{basePrice}}, Kiválasztott beállítások: {{selectedOptions}}, Teljes ár: {{totalPrice}}",
    filterOverview: "A konfiguráció áttekintése szűrőmenü megnyitása",
    filterOverviewWithCount: "A konfiguráció áttekintése szűrőmenü megnyitása – {{numAppliedFilters}} aktív szűrő",
    closeFilterMenu: "A konfiguráció áttekintése szűrőmenü bezárása és a kiválasztott szűrők alkalmazása",
    filterOverviewByPrice: "A konfiguráció áttekintésének szűrése árra vonatkozó beállítások szerint",
    filterOverviewByMySelections: "A konfiguráció áttekintésének szűrése a kiválasztásai szerint",
    filterOverviewByGroup: "A konfiguráció áttekintésének szűrése a(z) {{groupName}} csoport szerint",
    closeConflictSolverModal: "Ütközésfeloldó előugró ablak bezárása",
    closeRestartDialog: "A „Be nem fejezett konfiguráció” párbeszédablak bezárása, majd visszalépés a termék részletei oldalra",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Előre konfigurált verziók"
  }
};
var configurator$j = {
  configurator: configurator$i
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const hu = {
  configurator: configurator$j
};
var configurator$g = {
  header: {
    consistent: "Konsisten",
    complete: "Lengkap",
    configId: "ID Konfigurasi",
    toconfig: "Konfigurasikan",
    toConfigReadOnly: "Tampilkan rincian",
    editConfiguration: "Edit Konfigurasi",
    displayConfiguration: "Tampilkan Konfigurasi",
    resolveIssues: "Selesaikan Masalah",
    resolveIssue: "Selesaikan Masalah",
    resolveConflicts: "Selesaikan Ketidaksesuaian",
    conflictWarning: "Ketidaksesuaian harus diselesaikan untuk melanjutkan",
    updateMessage: "Konfigurasi sedang diperbarui di latar belakang",
    conflictsResolved: "Ketidaksesuaian telah diselesaikan",
    showMore: "tampilkan lebih banyak",
    showLess: "tampilkan lebih sedikit",
    items: "{{count}} item",
    items_other: "{{count}} item",
    show: "tampilkan",
    hide: "sembunyikan",
    multipleWarnings: "Terdapat beberapa peringatan.",
    reviewWarnings: "Tinjau peringatan ini",
    multipleErrors: "Terdapat beberapa kesalahan.",
    reviewErrors: "Tinjau kesalahan ini",
    kbKeyName: "Nama kb",
    kbKeyLogsys: "Sistem logis kb",
    kbKeyVersion: "Versi kb",
    kbKeyBuildNr: "Nomor build kb"
  },
  tabBar: {
    configuration: "Konfigurasi",
    overview: "Gambaran Umum"
  },
  notificationBanner: {
    numberOfIssues: "{{count}} masalah harus diselesaikan sebelum menyelesaikan pembayaran.",
    numberOfIssues_other: "{{count}} masalah harus diselesaikan sebelum menyelesaikan pembayaran.",
    numberOfConflicts: "{{count}} ketidaksesuaian harus diselesaikan sebelum menyelesaikan pembayaran.",
    numberOfConflicts_other: "{{count}} ketidaksesuaian harus diselesaikan sebelum menyelesaikan pembayaran."
  },
  attribute: {
    id: "ID",
    quantity: "Jml",
    caption: "Atribut",
    notSupported: "Tipe Atribut tidak didukung.",
    requiredAttribute: "{{param}} wajib diisi",
    defaultRequiredMessage: "Masukkan nilai untuk bidang yang wajib diisi",
    singleSelectRequiredMessage: "Pilih nilai",
    singleSelectAdditionalRequiredMessage: "Pilih atau masukkan nilai",
    multiSelectRequiredMessage: "Pilih satu atau beberapa nilai",
    wrongNumericFormat: "Format tidak sesuai, atribut numerik ini harus dimasukkan sesuai pola {{pattern}}",
    wrongNumericFormatMessage: "Masukkan angka dalam format berikut: {{pattern}}",
    wrongIntervalFormat: "Masukkan nilai dalam batas yang ditentukan",
    deselectionNotPossible: "Tambahkan produk lain sebelum menghapus produk ini",
    dropDownSelectMsg: "Buat pilihan",
    noOptionSelectedMsg: "Tidak ada opsi yang dipilih",
    notVisibleAttributeMsg: "Atribut ini tidak terlihat oleh pengguna"
  },
  button: {
    previous: "Sebelumnya",
    next: "Berikutnya",
    back: "Kembali",
    more: "lebih banyak",
    less: "lebih sedikit",
    deselect: "Hapus Pilihan",
    select: "Pilih",
    add: "Tambahkan",
    remove: "Hapus",
    exit: "Keluar dari Konfigurasi",
    exitMobile: "Keluar",
    cancelConfiguration: "Batalkan Konfigurasi",
    cancelConfigurationMobile: "Batalkan",
    filterOverview: "Filter",
    filterOverviewWithCount: "Filter ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Lengkap",
    groupIncomplete: "Tidak Lengkap",
    groupConflict: "Ketidaksesuaian",
    subgroup: "terdapat subgrup"
  },
  priceSummary: {
    basePrice: "Harga Dasar",
    selectedOptions: "Opsi yang Dipilih",
    totalPrice: "Total",
    totalPricePerItem: "Total per Item"
  },
  addToCart: {
    button: "Tambahkan ke Keranjang",
    buttonAfterAddToCart: "Lanjutkan ke Keranjang",
    buttonForQuote: "Lanjutkan ke Penawaran",
    buttonUpdateCart: "Selesai",
    buttonDisplayOnly: "Selesai",
    buttonClose: "Tutup",
    confirmation: "Konfigurasi telah ditambahkan ke keranjang",
    confirmationUpdate: "Keranjang telah diperbarui dengan konfigurasi",
    confirmationQuoteUpdate: "Penawaran telah diperbarui dengan konfigurasi",
    quantity: "Jml"
  },
  overviewForm: {
    noAttributeHeader: "Tidak Ada Hasil",
    noAttributeText: "Hapus filter untuk melihat konten Gambaran Umum",
    itemPrice: "Harga Item"
  },
  overviewSidebar: {
    menu: "Menu",
    filter: "Filter"
  },
  overviewFilter: {
    title: "Pilih Filter",
    byOption: "Filter Berdasarkan Opsi",
    byGroup: "Filter Berdasarkan Grup",
    byPrice: "Opsi yang Relevan dengan Harga",
    mySelections: "Pilihan Saya",
    removeAll: "Hapus Semua",
    removeAllFilters: "Hapus Semua Filter",
    removeByPrice: "Hapus Filter yang Relevan dengan Harga",
    removeMySelections: "Hapus Filter Pilihan Saya",
    removeByGroup: "Hapus Filter Grup untuk grup {{group}}",
    noFiltersAvailable: "Filter tidak tersedia"
  },
  group: {
    general: "Umum",
    conflictHeader: "Selesaikan ketidaksesuaian",
    conflictGroup: "Ketidaksesuaian pada {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Saran {{number}}:",
    suggestionText: "Ubah nilai untuk \"{{ attribute }}\"",
    viewConflictDetails: "Ketidaksesuaian Terdeteksi - Lihat Rincian",
    conflictDetected: "Ketidaksesuaian Terdeteksi",
    viewConfigurationDetails: "Tampilkan Dalam Konfigurasi"
  },
  restartDialog: {
    title: "Konfigurasi Yang Belum Selesai",
    description: "Terdapat konfigurasi yang belum selesai dari sesi sebelumnya. Apakah Anda ingin melanjutkannya atau memulai konfigurasi yang baru?",
    restartButton: "Mulai Baru",
    resumeButton: "Lanjutkan"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Produk ini adalah produk dasar dan tidak dapat ditambahkan ke keranjang. Silakan pilih varian produk sebagai gantinya"
  },
  a11y: {
    configureProduct: "Konfigurasikan produk",
    showDetailsProduct: "Tampilkan rincian produk",
    cartEntryBundleInfo: "Terdapat satu item ",
    cartEntryBundleInfo_other: "Terdapat {{items}} item ",
    cartEntryBundleName: "Item {{ name }}",
    cartEntryBundleNameWithQuantity: "Item {{ name }} dengan jumlah {{quantity}}",
    cartEntryBundleNameWithPrice: "Item {{ name }} dengan harga {{price}}",
    cartEntryBundle: "Item {{ name }} dengan harga {{price}} dan jumlah {{quantity}}",
    cartEntryInfoIntro: "Produk memiliki atribut berikut beserta nilai yang dipilih",
    cartEntryInfo: "Atribut {{ attribute }} memiliki nilai yang dipilih {{value}}",
    nameOfAttribute: "Nama Atribut",
    valueOfAttribute: "Nilai atribut {{ attribute }}",
    forAttribute: "{{ value }} untuk atribut {{ attribute }}",
    valueOfAttributeFull: "Nilai {{ value }} dari atribut {{ attribute }}.",
    valueOfDateAttributeFull: "Nilai {{ value }} dari atribut tanggal {{ attribute }}. Tekan tombol spasi untuk membuka pemilih tanggal.",
    valueOfAttributeFullWithPrice: "Nilai {{ value }} dari atribut {{ attribute }}, Biaya Tambahan {{ price }}",
    selectedValueOfAttributeFull: "Nilai {{ value }} yang dipilih dari atribut {{ attribute }}",
    selectedValueOfAttributeFullWithPrice: "Nilai {{ value }} yang dipilih dari atribut {{ attribute }}, Biaya Tambahan {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Nilai {{ value }} yang hanya untuk dibaca dari atribut {{ attribute }}, Biaya Tambahan {{ price }}",
    readOnlyValueOfAttributeFull: "Nilai {{ value }} yang hanya untuk dibaca dari atribut {{ attribute }}",
    valueOfAttributeBlank: "Nilai dari atribut {{ attribute }} kosong.",
    valueOfDateAttributeBlank: "Nilai dari atribut tanggal {{ attribute }} kosong. Tekan tombol spasi untuk membuka pemilih tanggal",
    value: "Nilai {{ value }}",
    attribute: "Atribut {{ attribute }}",
    requiredAttribute: "Atribut {{param}} wajib diisi",
    listOfAttributesAndValues: "Daftar atribut dan nilainya:",
    editAttributesAndValues: "Edit nilai atribut:",
    group: "Grup {{ group }} dari atribut",
    itemOfAttributeSelected: "Item {{ item }} dari atribut {{ attribute }} telah dipilih. {{ itemIndex }} dari {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "Item {{ item }} dari atribut {{ attribute }} telah dipilih, Biaya Tambahan {{ price }}. {{ itemIndex }} dari {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "Item {{ item }} dari atribut {{ attribute }} telah dipilih. Tekan tombol untuk membatalkan pilihan. {{ itemIndex }} dari {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Item {{ item }} dari atribut {{ attribute }} telah dipilih, Biaya Tambahan {{ price }} Tekan tombol untuk membatalkan pilihan. {{ itemIndex }} dari {{ itemCount }}",
    itemOfAttributeUnselected: "Item {{ item }} dari atribut {{ attribute }} tidak dipilih. Tekan tombol untuk memilih. {{ itemIndex }} dari {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "Item {{ item }} dari atribut {{ attribute }} tidak dipilih, Biaya Tambahan {{ price }}. Tekan tombol untuk memilih. {{ itemIndex }} dari {{ itemCount }}",
    selectNoItemOfAttribute: "Tekan tombol untuk tidak memilih item apa pun pada atribut {{ attribute }}. {{ itemIndex }} dari {{ itemCount }}",
    itemOfAttribute: "Item dari atribut {{ attribute }}",
    itemOfAttributeFull: "Item {{ item }} dari atribut {{ attribute }}",
    itemOfAttributeFullWithPrice: "Item {{ item }} dari atribut {{ attribute }}, Biaya Tambahan {{ price }}",
    itemOfAttributeFullWithQuantity: "Item {{ item }} dari atribut {{ attribute }}, Jumlah {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Item {{ item }} dari atribut {{ attribute }}, Jumlah {{ quantity }}, Biaya Tambahan {{ price }}",
    itemDescription: "Deskripsi untuk item {{ item }}",
    listbox: "Kotak daftar dengan {{ count }} nilai.",
    valueSurcharge: "Biaya tambahan nilai",
    conflictDetected: "Ketidaksesuaian terdeteksi! Periksa ketidaksesuaian di bagian atas daftar grup.",
    conflictsInConfiguration: "Terdapat ketidaksesuaian dalam konfigurasi Anda. Jumlah ketidaksesuaian: {{ numberOfConflicts }}, Pilih untuk melihat rincian selengkapnya.",
    listOfGroups: "Daftar grup",
    inListOfGroups: "Anda masuk dalam daftar grup",
    groupName: "Grup {{ group }}",
    groupBack: "Anda masuk dalam subgrup. Pilih untuk kembali.",
    conflictBack: "Anda sedang menggunakan fitur penyelesaian ketidaksesuaian. Pilih untuk kembali atau pilih tab berikutnya untuk menyelesaikan ketidaksesuaian.",
    iconConflict: "Terdapat ketidaksesuaian dalam grup.",
    iconIncomplete: "Terdapat atribut wajib dalam grup meskipun tanpa nilai yang dipilih.",
    iconComplete: "Grup sudah lengkap.",
    iconSubGroup: "Terdapat subgrup dalam grup.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Tampilkan lebih banyak informasi untuk produk {{ product }} atau lanjutkan ke konfigurasi.",
    showLessProductInfo: "Tampilkan lebih sedikit informasi untuk produk {{ product }} atau lanjutkan ke konfigurasi.",
    productName: "Nama Produk",
    productCode: "Kode Produk",
    productDescription: "Deskripsi produk",
    kbKeyName: "Nama kb {{name}}",
    kbKeyLogsys: "Sistem logis kb {{logsys}}",
    kbKeyVersion: "Versi kb {{version}}",
    kbKeyBuildNr: "Nomor build kb {{number}}",
    configurationPage: "Anda berada di halaman konfigurasi.",
    configurationPageLink: "Arahkan ke halaman konfigurasi.",
    overviewPage: "Anda berada di halaman gambaran umum. Periksa atribut dan nilai yang dipilih menggunakan mode baca dari pembaca layar Anda.",
    overviewPageLink: "Arahkan ke halaman gambaran umum konfigurasi.",
    navigateToConflict: "Ikuti tautan untuk mendapatkan rincian selengkapnya tentang ketidaksesuaian atribut {{ attribute }}. ",
    numericIntervalStandard: "Masukkan nilai antara {{ minValue }} dan {{ maxValue }}",
    numericIntervalStandardOpen: "(Titik akhir tidak termasuk)",
    numericIntervalStandardLowerEndpointNotIncluded: "(Titik akhir bawah tidak termasuk)",
    numericIntervalStandardUpperEndpointNotIncluded: "(Titik akhir atas tidak termasuk)",
    numericInfiniteIntervalMinValueIncluded: "Masukkan nilai yang lebih besar dari atau sama dengan {{ value }}",
    numericInfiniteIntervalMinValue: "Masukkan nilai yang lebih besar dari {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Masukkan nilai yang kurang dari atau sama dengan {{ value }}",
    numericInfiniteIntervalMaxValue: "Masukkan nilai yang kurang dari {{ value }}",
    numericIntervalSingleValue: "Masukkan {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} atau {{ newInterval }}",
    additionalValue: "Anda juga dapat memasukkan nilai tambahan.",
    addToCartPrices: "Harga Dasar {{basePrice}}, Opsi yang Dipilih {{selectedOptions}}, Harga Total {{totalPrice}}",
    filterOverview: "Buka menu filter gambaran umum konfigurasi",
    filterOverviewWithCount: "Buka menu filter gambaran umum konfigurasi - {{numAppliedFilters}} filter aktif",
    closeFilterMenu: "Tutup menu filter gambaran umum konfigurasi dan terapkan filter yang dipilih",
    filterOverviewByPrice: "Filter gambaran umum konfigurasi berdasarkan opsi yang relevan dengan harga",
    filterOverviewByMySelections: "Filter gambaran umum konfigurasi berdasarkan pilihan Anda",
    filterOverviewByGroup: "Filter gambaran umum konfigurasi berdasarkan grup {{groupName}}",
    closeConflictSolverModal: "Tutup modal fitur penyelesaian ketidaksesuaian",
    closeRestartDialog: "Tutup dialog \"Konfigurasi yang Belum Selesai\" dan arahkan kembali ke halaman rincian produk",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Versi yang Telah Dikonfigurasi Sebelumnya"
  }
};
var configurator$h = {
  configurator: configurator$g
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const id = {
  configurator: configurator$h
};
var configurator$e = {
  header: {
    consistent: "Coerente",
    complete: "Completa",
    configId: "ID configurazione",
    toconfig: "Configura",
    toConfigReadOnly: "Mostra dettagli",
    editConfiguration: "Modifica configurazione",
    displayConfiguration: "Visualizza configurazione",
    resolveIssues: "Risolvi problemi",
    resolveIssue: "Risolvi problemi",
    resolveConflicts: "Risolvi conflitti",
    conflictWarning: "Per continuare è necessario risolvere il conflitto",
    updateMessage: "L'aggiornamento della configurazione è in corso in background",
    conflictsResolved: "I conflitti sono stati risolti",
    showMore: "mostra di più",
    showLess: "mostra di meno",
    items: "{{count}} articolo",
    items_other: "{{count}} articoli",
    show: "mostra",
    hide: "nascondi",
    multipleWarnings: "Sono presenti più messaggi di avvertimento.",
    reviewWarnings: "Rivedi questi messaggi di avvertimento",
    multipleErrors: "Sono presenti più errori.",
    reviewErrors: "Rivedi questi errori",
    kbKeyName: "Nome BC",
    kbKeyLogsys: "Sistema logico BC",
    kbKeyVersion: "Versione BC",
    kbKeyBuildNr: "Numero build BC"
  },
  tabBar: {
    configuration: "Configurazione",
    overview: "Riepilogo"
  },
  notificationBanner: {
    numberOfIssues: "Occorre risolvere il problema {{count}} prima del check-out.",
    numberOfIssues_other: "Occorre risolvere i problemi {{count}} prima del check-out.",
    numberOfConflicts: "Occorre risolvere il conflitto {{count}} prima del check-out.",
    numberOfConflicts_other: "Occorre risolvere i conflitti {{count}} prima del check-out."
  },
  attribute: {
    id: "ID",
    quantity: "Qtà",
    caption: "Attributi",
    notSupported: "Tipo attributo non supportato.",
    requiredAttribute: "{{param}} obbligatorio",
    defaultRequiredMessage: "Inserisci un valore per il campo obbligatorio",
    singleSelectRequiredMessage: "Seleziona un valore",
    singleSelectAdditionalRequiredMessage: "Seleziona o inserisci un valore",
    multiSelectRequiredMessage: "Seleziona uno o più valori",
    wrongNumericFormat: "Formato errato; questo attributo numerico deve essere inserito secondo il modello {{pattern}}",
    wrongNumericFormatMessage: "Inserisci il numero nel seguente formato: {{pattern}}",
    wrongIntervalFormat: "Inserisci un valore all'interno dei limiti indicati",
    deselectionNotPossible: "Aggiungi un prodotto diverso prima di rimuovere questo",
    dropDownSelectMsg: "Effettua una selezione",
    noOptionSelectedMsg: "Nessuna opzione selezionata",
    notVisibleAttributeMsg: "Questo attributo non è visibile all'utente"
  },
  button: {
    previous: "Indietro",
    next: "Avanti",
    back: "Indietro",
    more: "più",
    less: "meno",
    deselect: "Deseleziona",
    select: "Seleziona",
    add: "Aggiungi",
    remove: "Rimuovi",
    exit: "Esci dalla configurazione",
    exitMobile: "Esci",
    cancelConfiguration: "Annulla configurazione",
    cancelConfigurationMobile: "Annulla",
    filterOverview: "Filtro",
    filterOverviewWithCount: "Filtro ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Completo",
    groupIncomplete: "Incompleto",
    groupConflict: "Conflitto",
    subgroup: "presenta sottogruppi"
  },
  priceSummary: {
    basePrice: "Prezzo base",
    selectedOptions: "Opzioni selezionate",
    totalPrice: "Totale",
    totalPricePerItem: "Totale per ogni articolo"
  },
  addToCart: {
    button: "Aggiungi al carrello acquisti",
    buttonAfterAddToCart: "Procedi al carrello acquisti",
    buttonForQuote: "Procedi all'offerta",
    buttonUpdateCart: "Fine",
    buttonDisplayOnly: "Fine",
    buttonClose: "Chiudi",
    confirmation: "Configurazione aggiunta al carrello acquisti",
    confirmationUpdate: "Carrello acquisti aggiornato con la configurazione",
    confirmationQuoteUpdate: "Offerta aggiornata con la configurazione",
    quantity: "Qtà"
  },
  overviewForm: {
    noAttributeHeader: "Nessun risultato",
    noAttributeText: "Rimuovi i filtri per visualizzare il contenuto del riepilogo",
    itemPrice: "Prezzo articolo"
  },
  overviewSidebar: {
    menu: "Menu",
    filter: "Filtro"
  },
  overviewFilter: {
    title: "Seleziona filtri",
    byOption: "Filtra per opzione",
    byGroup: "Filtra per gruppo",
    byPrice: "Opzioni rilevanti per il prezzo",
    mySelections: "Selezioni personali",
    removeAll: "Rimuovi tutto",
    removeAllFilters: "Rimuovi tutti i filtri",
    removeByPrice: "Rimuovi il Filtro rilevante per il prezzo",
    removeMySelections: "Rimuovi il filtro Selezioni personali",
    removeByGroup: "Rimuovi il Filtro di gruppo per il gruppo {{group}}",
    noFiltersAvailable: "Nessun filtro disponibile"
  },
  group: {
    general: "Generale",
    conflictHeader: "Risolvi conflitti",
    conflictGroup: "Conflitto per {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Consiglio {{number}}:",
    suggestionText: "Modifica valore per \"{{ attribute }}\"",
    viewConflictDetails: "Conflitto rilevato - Visualizza i dettagli",
    conflictDetected: "Conflitto rilevato",
    viewConfigurationDetails: "Visualizza nella configurazione"
  },
  restartDialog: {
    title: "Configurazione incompleta",
    description: "È presente una configurazione incompleta di una sessione precedente. Riprendere o avviare una nuova configurazione?",
    restartButton: "Avvia nuova",
    resumeButton: "Riprendi"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Questo è un prodotto di base e non può essere aggiunto al carrello acquisti; scegliere piuttosto una variante del prodotto"
  },
  a11y: {
    configureProduct: "Configura prodotto",
    showDetailsProduct: "Mostra dettagli del prodotto",
    cartEntryBundleInfo: "È presente un articolo ",
    cartEntryBundleInfo_other: "Sono presenti {{items}} articoli ",
    cartEntryBundleName: "Articolo {{ name }}",
    cartEntryBundleNameWithQuantity: "Articolo {{ name }} quantità articolo {{quantity}}",
    cartEntryBundleNameWithPrice: "Articolo {{ name }} prezzo articolo {{price}}",
    cartEntryBundle: "Articolo {{ name }} prezzo articolo {{price}} quantità articolo {{quantity}}",
    cartEntryInfoIntro: "Il prodotto ha i seguenti attributi e valori selezionati",
    cartEntryInfo: "L'attributo {{ attribute }} ha il valore selezionato {{value}}",
    nameOfAttribute: "Nome dell'attributo",
    valueOfAttribute: "Valore dell'attributo {{ attribute }}",
    forAttribute: "{{ value }} per l'attributo {{ attribute }}",
    valueOfAttributeFull: "Valore {{ value }} dell'attributo {{ attribute }}.",
    valueOfDateAttributeFull: "Valore {{ value }} dell'attributo data {{ attribute }}. Premere la barra spaziatrice per accedere alla selezione data.",
    valueOfAttributeFullWithPrice: "Valore {{ value }} dell'attributo {{ attribute }}, costo aggiuntivo {{ price }}",
    selectedValueOfAttributeFull: "Valore selezionato {{ value }} dell'attributo {{ attribute }}",
    selectedValueOfAttributeFullWithPrice: "Valore selezionato {{ value }} dell'attributo {{ attribute }}, costo aggiuntivo {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Valore di sola lettura {{ value }} dell'attributo {{ attribute }}, costo aggiuntivo {{ price }}",
    readOnlyValueOfAttributeFull: "Valore di sola lettura {{ value }} dell'attributo {{ attribute }}",
    valueOfAttributeBlank: "Il valore dell'attributo {{ attribute }} è vuoto.",
    valueOfDateAttributeBlank: "Il valore dell'attributo data {{ attribute }} è vuoto.  Premere la barra spaziatrice per accedere alla selezione data.",
    value: "Valore {{ value }}",
    attribute: "Attributo {{ attribute }}",
    requiredAttribute: "Attributo {{param}} obbligatorio",
    listOfAttributesAndValues: "Lista degli attributi e dei relativi valori:",
    editAttributesAndValues: "Modifica valori degli attributi:",
    group: "Gruppo {{ group }} degli attributi",
    itemOfAttributeSelected: "Elemento {{ item }} dell'attributo {{ attribute }} selezionato. {{ itemIndex }} di {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "Elemento {{ item }} dell'attributo {{ attribute }} selezionato, costo aggiuntivo {{ price }}. {{ itemIndex }} di {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "Elemento {{ item }} dell'attributo {{ attribute }} selezionato. Premere il pulsante per deselezionarlo. {{ itemIndex }} di {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Elemento {{ item }} dell'attributo {{ attribute }} selezionato, costo aggiuntivo {{ price }}. Premere il pulsante per deselezionarlo. {{ itemIndex }} di {{ itemCount }}",
    itemOfAttributeUnselected: "Elemento {{ item }} dell'attributo {{ attribute }} deselezionato. Premere il pulsante per selezionarlo. {{ itemIndex }} di {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "Elemento {{ item }} dell'attributo {{ attribute }} deselezionato, costo aggiuntivo {{ price }}. Premere il pulsante per selezionarlo. {{ itemIndex }} di {{ itemCount }}",
    selectNoItemOfAttribute: "Per non selezionare alcun elemento dell'attributo {{ attribute }} premere il pulsante. {{ itemIndex }} di {{ itemCount }}",
    itemOfAttribute: "Elemento dell'attributo {{ attribute }}",
    itemOfAttributeFull: "Elemento {{ item }} dell'attributo {{ attribute }}",
    itemOfAttributeFullWithPrice: "Elemento {{ item }} dell'attributo {{ attribute }}, costo aggiuntivo {{ price }}",
    itemOfAttributeFullWithQuantity: "Elemento {{ item }} dell'attributo {{ attribute }}, quantità {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Elemento {{ item }} dell'attributo {{ attribute }}, quantità {{ quantity }}, costo aggiuntivo {{ price }}",
    itemDescription: "Descrizione dell'elemento {{ item }}",
    listbox: "Casella di riepilogo con {{ count }} valori.",
    valueSurcharge: "Costo aggiuntivo del valore",
    conflictDetected: "Conflitto rilevato. Verificare i conflitti in cima alla lista del gruppo.",
    conflictsInConfiguration: "Sono presenti conflitti nella configurazione. Numero di conflitti: {{ numberOfConflicts }}, selezionare per ulteriori dettagli.",
    listOfGroups: "Lista di gruppi",
    inListOfGroups: "L'utente è nella lista di gruppi",
    groupName: "Gruppo {{ group }}",
    groupBack: "L'utente è in un sottogruppo. Selezionare per tornare indietro.",
    conflictBack: "L'utente è nel risolutore di conflitti. Selezionare per tornare indietro o selezionare le schede successive per risolvere i conflitti.",
    iconConflict: "Il gruppo presenta conflitti.",
    iconIncomplete: "Il gruppo ha attributi obbligatori senza valori selezionati.",
    iconComplete: "Il gruppo è completo.",
    iconSubGroup: "Il gruppo presenta un sottogruppo.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Mostrare più informazioni per il prodotto {{ product }} o procedere alla configurazione.",
    showLessProductInfo: "Mostrare meno informazioni per il prodotto {{ product }} o procedere alla configurazione.",
    productName: "Nome prodotto",
    productCode: "Codice prodotto",
    productDescription: "Descrizione del prodotto",
    kbKeyName: "Nome BC {{name}}",
    kbKeyLogsys: "Sistema logico BC {{logsys}}",
    kbKeyVersion: "Versione BC {{version}}",
    kbKeyBuildNr: "Numero build BC {{number}}",
    configurationPage: "L'utente si trova nella pagina di configurazione.",
    configurationPageLink: "Passare alla pagina di configurazione.",
    overviewPage: "L'utente si trova nella pagina di riepilogo. Controllare gli attributi e i relativi valori selezionati in modalità di lettura sul lettore dello schermo.",
    overviewPageLink: "Passare alla pagina di riepilogo della configurazione.",
    navigateToConflict: "Seguire il collegamento per ottenere più dettagli sul conflitto dell'attributo {{ attribute }}.",
    numericIntervalStandard: "Inserisci un valore tra {{ minValue }} e {{ maxValue }}",
    numericIntervalStandardOpen: "(Punti finali non inclusi)",
    numericIntervalStandardLowerEndpointNotIncluded: "(Punto finale più basso non incluso)",
    numericIntervalStandardUpperEndpointNotIncluded: "(Punto finale più alto non incluso)",
    numericInfiniteIntervalMinValueIncluded: "Inserisci un valore maggiore o uguale a {{ value }}",
    numericInfiniteIntervalMinValue: "Inserisci un valore maggiore di {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Inserisci un valore minore o uguale a {{ value }}",
    numericInfiniteIntervalMaxValue: "Inserisci un valore minore di {{ value }}",
    numericIntervalSingleValue: "Inserisci {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} o {{ newInterval }}",
    additionalValue: "È anche possibile inserire un valore aggiuntivo.",
    addToCartPrices: "Prezzo di base {{basePrice}}, opzioni selezionate {{selectedOptions}}, prezzo totale {{totalPrice}}",
    filterOverview: "Apri il menu dei filtri del riepilogo della configurazione",
    filterOverviewWithCount: "Apri il menu dei filtri del riepilogo della configurazione - {{numAppliedFilters}} filtri attivi",
    closeFilterMenu: "Chiudi il menu dei filtri del riepilogo della configurazione e applica i filtri selezionati",
    filterOverviewByPrice: "Riepilogo della configurazione dei filtri in base alle opzioni rilevanti per il prezzo",
    filterOverviewByMySelections: "Riepilogo della configurazione dei filtri in base alle selezioni personali",
    filterOverviewByGroup: "Riepilogo della configurazione dei filtri in base al gruppo {{groupName}}",
    closeConflictSolverModal: "Chiudi il modale del risolutore dei conflitti",
    closeRestartDialog: "Chiudi la finestra di dialogo \"Configurazione incompleta\" e torna alla pagina dei dettagli del prodotto",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Versioni preconfigurate"
  }
};
var configurator$f = {
  configurator: configurator$e
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const it = {
  configurator: configurator$f
};
var configurator$c = {
  header: {
    consistent: "整合",
    complete: "完了",
    configId: "設定 ID",
    toconfig: "設定",
    toConfigReadOnly: "詳細表示",
    editConfiguration: "設定編集",
    displayConfiguration: "表示設定",
    resolveIssues: "問題を解決",
    resolveIssue: "問題を解決",
    resolveConflicts: "不整合を解決",
    conflictWarning: "続行するには不整合を解決する必要があります",
    updateMessage: "設定がバックグラウンドで更新中です",
    conflictsResolved: "不整合が解決しました",
    showMore: "表示を増やす",
    showLess: "表示数を減らす",
    items: "{{count}} 個のアイテム",
    items_other: "{{count}} 個のアイテム",
    show: "表示",
    hide: "非表示",
    multipleWarnings: "複数の警告があります。",
    reviewWarnings: "これらの警告を確認します",
    multipleErrors: "複数のエラーがあります。",
    reviewErrors: "これらのエラーを確認します",
    kbKeyName: "KB 名",
    kbKeyLogsys: "KB 論理システム",
    kbKeyVersion: "KB バージョン",
    kbKeyBuildNr: "KB ビルド番号"
  },
  tabBar: {
    configuration: "設定",
    overview: "概要"
  },
  notificationBanner: {
    numberOfIssues: "チェックアウトの前に {{count}} 個の問題を解決する必要があります。",
    numberOfIssues_other: "チェックアウトの前に {{count}} 個の問題を解決する必要があります。",
    numberOfConflicts: "チェックアウトの前に {{count}} 個の不整合を解決する必要があります。",
    numberOfConflicts_other: "チェックアウトの前に {{count}} 個の不整合を解決する必要があります。"
  },
  attribute: {
    id: "ID",
    quantity: "数量",
    caption: "属性",
    notSupported: "サポートされていない属性タイプです。",
    requiredAttribute: "{{param}} は必須です",
    defaultRequiredMessage: "必須フィールドに値を入力してください",
    singleSelectRequiredMessage: "値を選択",
    singleSelectAdditionalRequiredMessage: "値を選択または入力",
    multiSelectRequiredMessage: "1 つまたは複数の値を選択してください",
    wrongNumericFormat: "書式が間違っています。この数値属性はパターン {{pattern}} に従って入力する必要があります",
    wrongNumericFormatMessage: "数値を次の書式で入力してください: {{pattern}}",
    wrongIntervalFormat: "指定された境界内で値を入力します",
    deselectionNotPossible: "この商品を削除する前に、別の商品を追加します",
    dropDownSelectMsg: "選択してください",
    noOptionSelectedMsg: "オプションが選択されていません",
    notVisibleAttributeMsg: "この属性はユーザには表示されません"
  },
  button: {
    previous: "前",
    next: "次",
    back: "戻る",
    more: "さらに表示",
    less: "少なく表示",
    deselect: "選択解除",
    select: "選択",
    add: "追加",
    remove: "削除",
    exit: "設定終了",
    exitMobile: "終了",
    cancelConfiguration: "設定のキャンセル",
    cancelConfigurationMobile: "キャンセル",
    filterOverview: "フィルタ",
    filterOverviewWithCount: "フィルタ ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "完了",
    groupIncomplete: "未完了",
    groupConflict: "不整合",
    subgroup: "サブグループがあります"
  },
  priceSummary: {
    basePrice: "基準価格",
    selectedOptions: "選択したオプション",
    totalPrice: "合計",
    totalPricePerItem: "アイテムごとの合計"
  },
  addToCart: {
    button: "カートに追加",
    buttonAfterAddToCart: "カートを続行",
    buttonForQuote: "見積を続行",
    buttonUpdateCart: "完了",
    buttonDisplayOnly: "完了",
    buttonClose: "閉じる",
    confirmation: "カートに設定が追加されました",
    confirmationUpdate: "カートの設定が更新されました",
    confirmationQuoteUpdate: "見積の設定が更新されました",
    quantity: "数量"
  },
  overviewForm: {
    noAttributeHeader: "結果なし",
    noAttributeText: "フィルタを削除して概要を確認",
    itemPrice: "商品価格"
  },
  overviewSidebar: {
    menu: "メニュー",
    filter: "フィルタ"
  },
  overviewFilter: {
    title: "フィルタの選択",
    byOption: "オプションでフィルタ",
    byGroup: "グループでフィルタ",
    byPrice: "価格関連オプション",
    mySelections: "my 選択",
    removeAll: "すべて削除",
    removeAllFilters: "すべてのフィルタを削除",
    removeByPrice: "価格関連フィルタを削除",
    removeMySelections: "my 選択フィルタを削除",
    removeByGroup: "グループ {{group}} のグループフィルタを削除",
    noFiltersAvailable: "利用可能なフィルタがありません"
  },
  group: {
    general: "一般",
    conflictHeader: "不整合を解決",
    conflictGroup: "{{attribute}} の不整合"
  },
  conflict: {
    suggestionTitle: "提案 {{number}}:",
    suggestionText: "\"{{ attribute }}\" の値を変更",
    viewConflictDetails: "不整合が検出されました - 詳細を表示",
    conflictDetected: "不整合が検出されました。",
    viewConfigurationDetails: "設定で表示"
  },
  restartDialog: {
    title: "完了していない設定",
    description: "前のセッションで完了していない設定があります。設定を再開しますか、または新しい設定を開始しますか?",
    restartButton: "新しく開始",
    resumeButton: "再開"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "この商品はベース商品であり、カートには追加できません。代わりに商品バリアントを選択してください"
  },
  a11y: {
    configureProduct: "商品を設定",
    showDetailsProduct: "商品の詳細を表示",
    cartEntryBundleInfo: "アイテムが 1 個あります ",
    cartEntryBundleInfo_other: "アイテムが {{items}} 個あります ",
    cartEntryBundleName: "アイテム {{ name }}",
    cartEntryBundleNameWithQuantity: "アイテム {{ name }} アイテム数量 {{quantity}}",
    cartEntryBundleNameWithPrice: "アイテム {{ name }} アイテム価格 {{price}}",
    cartEntryBundle: "アイテム {{ name }} アイテム価格 {{price}} アイテム数量 {{quantity}}",
    cartEntryInfoIntro: "商品には以下の属性と選択値があります",
    cartEntryInfo: "属性 {{ attribute }} には選択値 {{value}} があります",
    nameOfAttribute: "属性の名前",
    valueOfAttribute: "属性の値 {{ attribute }}",
    forAttribute: "属性 {{ attribute }} の {{ value }}",
    valueOfAttributeFull: "属性 {{ attribute }} の値 {{ value }}",
    valueOfDateAttributeFull: "日付属性 {{ attribute }} の値 {{ value }} です。日付セレクターで入力するにはスペースキーを押します。",
    valueOfAttributeFullWithPrice: "属性 {{ attribute }} の値は {{ value }}、追加料金は {{ price }} です",
    selectedValueOfAttributeFull: "属性 {{ attribute }} の選択値は {{ value }} です",
    selectedValueOfAttributeFullWithPrice: "属性 {{ attribute }} の選択値は {{ value }}、追加料金は {{ price }} です",
    readOnlyValueOfAttributeFullWithPrice: "属性 {{ attribute }} の読み込み専用値は {{ value }}、追加料金は {{ price }} です",
    readOnlyValueOfAttributeFull: "属性 {{ attribute }} の読み込み専用値は {{ value }} です",
    valueOfAttributeBlank: "属性 {{ attribute }} の値は空白です。",
    valueOfDateAttributeBlank: "日付属性 {{ attribute }} の値は空白です。日付セレクターで入力するにはスペースキーを押します。",
    value: "値 {{ value }}",
    attribute: "属性 {{ attribute }}",
    requiredAttribute: "属性 {{param}} は必須です",
    listOfAttributesAndValues: "属性とその値の一覧",
    editAttributesAndValues: "属性の値を編集:",
    group: "{{ group }} グループの属性",
    itemOfAttributeSelected: "属性 {{ attribute }} のアイテム {{ item }} が選択されました。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "属性 {{ attribute }} のアイテム {{ item }} が選択されました。追加料金は {{ price }} です。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "属性 {{ attribute }} のアイテム {{ item }} が選択されました。選択を解除するにはボタンを押します。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "属性 {{ attribute }} のアイテム {{ item }} が選択されました。追加料金は {{ price }} です。選択を解除するにはボタンを押します。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselected: "属性 {{ attribute }} のアイテム {{ item }} が選択されました。選択するにはボタンを押します。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "属性 {{ attribute }} のアイテム {{ item }} の選択が解除されました。追加料金は {{ price }} です。選択するにはボタンを押します。{{ itemIndex }}/{{ itemCount }}",
    selectNoItemOfAttribute: "属性 {{ attribute }} のアイテムを選択しない場合はボタンを押します。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttribute: "属性 {{ attribute }} のアイテム",
    itemOfAttributeFull: "属性 {{ attribute }} のアイテム {{ item }}",
    itemOfAttributeFullWithPrice: "属性 {{ attribute }} のアイテム {{ item }}、追加料金 {{ price }}",
    itemOfAttributeFullWithQuantity: "属性 {{ attribute }} のアイテム {{ item }}、数量 {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "属性 {{ attribute }} のアイテム {{ item }}、数量 {{ quantity }}、追加料金 {{ price }}",
    itemDescription: "アイテム {{ item }} の説明",
    listbox: "{{ count }} の値があるリストボックス。",
    valueSurcharge: "追加料金値",
    conflictDetected: "不整合が検出されました。グループ一覧の上部で不整合を確認してください。",
    conflictsInConfiguration: "設定に不整合があります。設定の数: {{ numberOfConflicts }}、詳細については選択してください。",
    listOfGroups: "グループ一覧",
    inListOfGroups: "グループ一覧にいます",
    groupName: "グループ {{ group }}",
    groupBack: "サブグループにいます。戻るには選択してください。",
    conflictBack: "不整合ソルバにいます。選択して戻るか、または次のタブを選択して不整合を解決してください。",
    iconConflict: "グループに不整合があります。",
    iconIncomplete: "グループに値が選択されていない必須属性があります。",
    iconComplete: "グループは完了しています。",
    iconSubGroup: "グループにはサブグループがあります。",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "商品 {{ product }} の情報をさらに表示するか、または設定を続行します。",
    showLessProductInfo: "商品 {{ product }} の情報をより少なく表示するか、または設定を続行します。",
    productName: "商品名",
    productCode: "商品コード",
    productDescription: "商品説明",
    kbKeyName: "KB 名 {{name}}",
    kbKeyLogsys: "KB 論理システム {{logsys}}",
    kbKeyVersion: "KB バージョン {{version}}",
    kbKeyBuildNr: "KB ビルド番号 {{number}}",
    configurationPage: "ここは設定ページです。",
    configurationPageLink: "設定ページにナビゲートします。",
    overviewPage: "ここは概要ページです。スクリーンリーダーの読み上げモードを使用して、属性とその選択値を確認してください。",
    overviewPageLink: "設定概要ページにナビゲートします。",
    navigateToConflict: "リンクに従って属性 {{ attribute }} についての不整合の詳細を取得します。",
    numericIntervalStandard: "{{ minValue }} から {{ maxValue }} の範囲の値を入力してください",
    numericIntervalStandardOpen: "(両端のエンドポイントは含まれていません)",
    numericIntervalStandardLowerEndpointNotIncluded: "(小さい方のエンドポイントは含まれていません)",
    numericIntervalStandardUpperEndpointNotIncluded: "(大きい方のエンドポイントは含まれていません)",
    numericInfiniteIntervalMinValueIncluded: "{{ value }} 以上の値を入力してください。",
    numericInfiniteIntervalMinValue: "{{ value }} より大きい値を入力してください",
    numericInfiniteIntervalMaxValueIncluded: "{{ value }} 以下の値を入力してください。",
    numericInfiniteIntervalMaxValue: "{{ value }} より小さい値を入力してください。",
    numericIntervalSingleValue: "{{ value }} を入力",
    combinedIntervalsText: "{{ combinedInterval }} または {{ newInterval }}",
    additionalValue: "追加の値を入力することもできます。",
    addToCartPrices: "基準価格 {{basePrice}}、選択したオプション {{selectedOptions}}、合計価格 {{totalPrice}}",
    filterOverview: "設定概要フィルタメニューを開きます",
    filterOverviewWithCount: "設定概要フィルタメニューを開きます - {{numAppliedFilters}} の有効なフィルタ",
    closeFilterMenu: "設定概要フィルタメニューを閉じて選択したフィルタを適用します",
    filterOverviewByPrice: "価格関連オプション別のフィルタ設定概要",
    filterOverviewByMySelections: "ユーザ選択によるフィルタ設定概要",
    filterOverviewByGroup: "グループ {{groupName}} 別のフィルタ設定概要",
    closeConflictSolverModal: "不整合ソルバのモーダルを閉じます",
    closeRestartDialog: "\"完了していない設定\" ダイアログを閉じて、商品の詳細ページに戻ります",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "事前設定済みバージョン"
  }
};
var configurator$d = {
  configurator: configurator$c
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ja = {
  configurator: configurator$d
};
var configurator$a = {
  header: {
    consistent: "일치",
    complete: "완료",
    configId: "구성 ID",
    toconfig: "구성",
    toConfigReadOnly: "세부사항 표시",
    editConfiguration: "구성 편집",
    displayConfiguration: "구성 표시",
    resolveIssues: "이슈 해결",
    resolveIssue: "이슈 해결",
    resolveConflicts: "충돌 해결",
    conflictWarning: "계속하려면 충돌을 해결해야 합니다.",
    updateMessage: "구성이 백그라운드에서 업데이트되고 있습니다.",
    conflictsResolved: "충돌이 해결되었습니다.",
    showMore: "자세히 보기",
    showLess: "간단히 보기",
    items: "{{count}}개 품목",
    items_other: "{{count}}개 품목",
    show: "표시",
    hide: "숨기기",
    multipleWarnings: "경고가 여러 개 있습니다.",
    reviewWarnings: "이 경고를 검토하십시오.",
    multipleErrors: "오류가 ",
    reviewErrors: "이 오류를 검토하십시오.",
    kbKeyName: "KB 이름",
    kbKeyLogsys: "KB 논리 시스템",
    kbKeyVersion: "KB 버전",
    kbKeyBuildNr: "KB 빌드 번호"
  },
  tabBar: {
    configuration: "구성",
    overview: "개요"
  },
  notificationBanner: {
    numberOfIssues: "체크아웃 전에 {{count}}개의 이슈를 해결해야 합니다.",
    numberOfIssues_other: "체크아웃 전에 {{count}}개의 이슈를 해결해야 합니다.",
    numberOfConflicts: "체크아웃 전에 {{count}}개의 충돌을 해결해야 합니다.",
    numberOfConflicts_other: "체크아웃 전에 {{count}}개의 충돌을 해결해야 합니다."
  },
  attribute: {
    id: "ID",
    quantity: "수량",
    caption: "특성",
    notSupported: "특성 유형은 지원되지 않습니다.",
    requiredAttribute: "{{param}} 필수",
    defaultRequiredMessage: "필수 필드에 값을 입력하십시오.",
    singleSelectRequiredMessage: "값을 선택하십시오.",
    singleSelectAdditionalRequiredMessage: "값 선택하거나 입력하십시오.",
    multiSelectRequiredMessage: "값을 하나 이상 선택하십시오.",
    wrongNumericFormat: "형식이 잘못되었습니다. 이 숫자 특성은 {{pattern}} 패턴에 따라 입력해야 합니다.",
    wrongNumericFormatMessage: "숫자를 {{pattern}} 형식으로 입력하십시오.",
    wrongIntervalFormat: "지정된 범위 내의 값을 입력하십시오.",
    deselectionNotPossible: "이 제품을 제거하기 전에 다른 제품을 추가하십시오.",
    dropDownSelectMsg: "선택하십시오.",
    noOptionSelectedMsg: "선택한 옵션이 없습니다.",
    notVisibleAttributeMsg: "이 특성은 사용자에게 표시되지 않습니다."
  },
  button: {
    previous: "이전",
    next: "다음",
    back: "뒤로",
    more: "자세히",
    less: "간단히",
    deselect: "선택 취소",
    select: "선택",
    add: "추가",
    remove: "제거",
    exit: "구성 나가기",
    exitMobile: "나가기",
    cancelConfiguration: "구성 취소",
    cancelConfigurationMobile: "취소",
    filterOverview: "필터",
    filterOverviewWithCount: "필터({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "완료",
    groupIncomplete: "완료되지 않음",
    groupConflict: "충돌",
    subgroup: "하위 그룹이 있음"
  },
  priceSummary: {
    basePrice: "기준 가격",
    selectedOptions: "선택된 옵션",
    totalPrice: "합계",
    totalPricePerItem: "품목별 합계"
  },
  addToCart: {
    button: "장바구니에 추가",
    buttonAfterAddToCart: "장바구니로 계속",
    buttonForQuote: "견적으로 계속",
    buttonUpdateCart: "완료",
    buttonDisplayOnly: "완료",
    buttonClose: "닫기",
    confirmation: "구성이 장바구니에 추가되었습니다.",
    confirmationUpdate: "장바구니에 구성이 업데이트되었습니다.",
    confirmationQuoteUpdate: "견적에 구성이 업데이트되었습니다.",
    quantity: "수량"
  },
  overviewForm: {
    noAttributeHeader: "결과 없음",
    noAttributeText: "개요 내용을 보려면 필터를 제거하십시오.",
    itemPrice: "품목 가격"
  },
  overviewSidebar: {
    menu: "메뉴",
    filter: "필터"
  },
  overviewFilter: {
    title: "필터 선택",
    byOption: "옵션별 필터",
    byGroup: "그룹별 필터",
    byPrice: "가격 관련 옵션",
    mySelections: "내 선택사항",
    removeAll: "모두 제거",
    removeAllFilters: "모든 필터 제거",
    removeByPrice: "가격 관련 필터 제거",
    removeMySelections: "내 선택사항 필터 제거",
    removeByGroup: "그룹 {{group}}의 그룹 필터 제거",
    noFiltersAvailable: "사용 가능한 필터 없음"
  },
  group: {
    general: "일반",
    conflictHeader: "충돌 해결",
    conflictGroup: "{{attribute}}에 대한 충돌"
  },
  conflict: {
    suggestionTitle: "제안 {{number}}:",
    suggestionText: "\"{{ attribute }}\"의 값 변경",
    viewConflictDetails: "충돌 감지됨 - 세부사항 보기",
    conflictDetected: "충돌 감지됨",
    viewConfigurationDetails: "구성에서 보기"
  },
  restartDialog: {
    title: "종료되지 않은 구성",
    description: "이전 세션에서 완료되지 않은 구성이 있습니다. 새 구성을 재개하거나 시작하시겠습니까?",
    restartButton: "새로 시작",
    resumeButton: "다시 시작"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "이 제품은 기본 제품이어서 장바구니에 추가할 수 없습니다. 제품 변형을 대신 선택하십시오."
  },
  a11y: {
    configureProduct: "제품 구성",
    showDetailsProduct: "제품 세부사항 표시",
    cartEntryBundleInfo: "품목이 있습니다. ",
    cartEntryBundleInfo_other: "{{items}}개의 품목이 있습니다. ",
    cartEntryBundleName: "품목 {{ name }}",
    cartEntryBundleNameWithQuantity: "품목 {{ name }} 품목 수량 {{quantity}}",
    cartEntryBundleNameWithPrice: "품목 {{ name }} 품목 가격 {{price}}",
    cartEntryBundle: "품목 {{ name }} 품목 가격 {{price}} 품목 수량 {{quantity}}",
    cartEntryInfoIntro: "제품에 다음과 같은 특성과 선택된 값이 있습니다.",
    cartEntryInfo: "특성 {{ attribute }}에 선택된 값 {{value}}이(가) 있습니다.",
    nameOfAttribute: "특성 이름",
    valueOfAttribute: "특성 {{ attribute }}의 값",
    forAttribute: "특성 {{ attribute }}의 {{ value }}",
    valueOfAttributeFull: "특성 {{ attribute }}의 값 {{ value }}",
    valueOfDateAttributeFull: "날짜 특성 {{ attribute }}의 값 {{ value }}. 날짜 선택을 열려면 Space 키를 누르십시오.",
    valueOfAttributeFullWithPrice: "특성 {{ attribute }}의 값 {{ value }}, 부가금 {{ price }}",
    selectedValueOfAttributeFull: "특성 {{ attribute }}의 선택된 값 {{ value }}",
    selectedValueOfAttributeFullWithPrice: "특성 {{ attribute }}의 선택된 값 {{ value }}, 부가금 {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "특성 {{ attribute }}의 읽기 전용 값 {{ value }}, 부가금 {{ price }}",
    readOnlyValueOfAttributeFull: "특성 {{ attribute }}의 읽기 전용 값 {{ value }}",
    valueOfAttributeBlank: "특성 {{ attribute }}의 값이 비어 있습니다.",
    valueOfDateAttributeBlank: "날짜 특성 {{ attribute }}의 값이 비어 있습니다. 날짜 선택을 열려면 Space 키를 누르십시오.",
    value: "값 {{ value }}",
    attribute: "특성 {{ attribute }}",
    requiredAttribute: "특성 {{param}}은(는) 필수 항목입니다.",
    listOfAttributesAndValues: "특성 및 값 목록:",
    editAttributesAndValues: "특성 값 편집:",
    group: "특성의 {{ group }} 그룹",
    itemOfAttributeSelected: "특성 {{ attribute }}의 항목 {{ item }}이(가) 선택되었습니다. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "특성 {{ attribute }}의 항목 {{ item }}이(가) 선택되었습니다. 부가금 {{ price }}. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "특성 {{ attribute }}의 항목 {{ item }}이(가) 선택되었습니다. 선택을 취소하려면 버튼을 누르십시오. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "특성 {{ attribute }}의 항목 {{ item }}이(가) 선택되었습니다. 부가금 {{ price }}. 선택을 취소하려면 버튼을 누르십시오. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselected: "특성 {{ attribute }}의 항목 {{ item }}에 대한 선택이 취소되었습니다. 선택하려면 버튼을 누르십시오. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "특성 {{ attribute }}의 항목 {{ item }}에 대한 선택이 취소되었습니다. 부가금 {{ price }}. 선택하려면 버튼을 누르십시오. {{ itemIndex }}/{{ itemCount }}",
    selectNoItemOfAttribute: "특성 {{ attribute }}에서 항목을 선택하지 않으려면 버튼을 누르십시오. {{ itemIndex }}/{{ itemCount }}",
    itemOfAttribute: "특성 {{ attribute }}의 항목",
    itemOfAttributeFull: "특성 {{ attribute }}의 항목 {{ item }}",
    itemOfAttributeFullWithPrice: "특성 {{ attribute }}의 항목 {{ item }}, 부가금 {{ price }}",
    itemOfAttributeFullWithQuantity: "특성 {{ attribute }}의 항목 {{ item }}, 수량 {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "특성 {{ attribute }}의 항목 {{ item }}, 수량 {{ quantity }}, 부가금 {{ price }}",
    itemDescription: "항목 {{ item }}에 대한 설명",
    listbox: " {{ count }}개의 값이 있는 Listbox",
    valueSurcharge: "값 부가금",
    conflictDetected: "충돌이 감지되었습니다! 그룹 목록의 맨 위에서 충돌을 확인하십시오.",
    conflictsInConfiguration: "구성에 충돌이 있습니다. 충돌 수: {{ numberOfConflicts }}. 자세한 내용을 보려면 선택하십시오.",
    listOfGroups: "그룹 목록",
    inListOfGroups: "그룹 목록에 있습니다.",
    groupName: "그룹 {{ group }}",
    groupBack: "하위 그룹에 있습니다. 돌아가려면 선택하십시오.",
    conflictBack: "충돌 솔버에 있습니다. 뒤로 가려면 선택하고 충돌을 해결하려면 다음 탭을 선택하십시오.",
    iconConflict: "그룹에 충돌이 있습니다.",
    iconIncomplete: "그룹에 값이 선택되지 않은 필수 특성이 있습니다.",
    iconComplete: "그룹이 완료되었습니다.",
    iconSubGroup: "그룹에 하위 그룹이 있습니다.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "제품 {{ product }}에 대한 자세한 정보를 표시하거나 구성을 계속합니다.",
    showLessProductInfo: "제품 {{ product }}에 대한 간단한 정보를 표시하거나 구성을 계속합니다.",
    productName: "제품 이름",
    productCode: "제품 코드",
    productDescription: "제품 설명",
    kbKeyName: "KB 이름 {{name}}",
    kbKeyLogsys: "KB 논리 시스템 {{logsys}}",
    kbKeyVersion: "KB 버전 {{version}}",
    kbKeyBuildNr: "KB 빌드 번호 {{number}}",
    configurationPage: "구성 페이지에 있습니다.",
    configurationPageLink: "구성 페이지로 이동합니다.",
    overviewPage: "개요 페이지에 있습니다. 화면 리더의 읽기 모드에서 화면 특성과 선택된 값을 확인하십시오.",
    overviewPageLink: "구성 개요 페이지로 이동합니다.",
    navigateToConflict: "특성 {{ attribute }}의 충돌에 대한 자세한 내용을 보려면 링크를 클릭하십시오.",
    numericIntervalStandard: "{{ minValue }} ~ {{ maxValue }} 사이의 값을 입력하십시오.",
    numericIntervalStandardOpen: "(엔드포인트는 포함되지 않음)",
    numericIntervalStandardLowerEndpointNotIncluded: "(낮은 엔드포인트는 포함되지 않음)",
    numericIntervalStandardUpperEndpointNotIncluded: "(높은 엔드포인트는 포함되지 않음)",
    numericInfiniteIntervalMinValueIncluded: "{{ value }}보다 크거나 같은 값을 입력하십시오.",
    numericInfiniteIntervalMinValue: "{{ value }}보다 큰 값을 입력하십시오.",
    numericInfiniteIntervalMaxValueIncluded: "{{ value }}보다 작거나 같은 값을 입력하십시오.",
    numericInfiniteIntervalMaxValue: "{{ value }}보다 작은 값을 입력하십시오.",
    numericIntervalSingleValue: "{{ value }}을(를) 입력하십시오.",
    combinedIntervalsText: "{{ combinedInterval }} 또는 {{ newInterval }}",
    additionalValue: "값을 더 입력할 수도 있습니다.",
    addToCartPrices: "기준 가격 {{basePrice}}, 선택된 옵션 {{selectedOptions}}, 가격 합계 {{totalPrice}}",
    filterOverview: "구성 개요 필터 메뉴를 엽니다.",
    filterOverviewWithCount: "구성 개요 필터 메뉴를 엽니다 - {{numAppliedFilters}}개의 활성 필터",
    closeFilterMenu: "구성 개요 필터 메뉴를 닫고 선택된 필터를 적용합니다.",
    filterOverviewByPrice: "가격 관련 옵션으로 구성 개요를 필터링합니다.",
    filterOverviewByMySelections: "선택사항으로 구성 개요를 필터링합니다.",
    filterOverviewByGroup: "그룹 {{groupName}}별로 구성 개요를 필터링합니다.",
    closeConflictSolverModal: "충돌 솔버 닫기 모달",
    closeRestartDialog: "\"완료되지 않은 구성\" 대화 상자를 닫고 제품 세부사항 페이지로 돌아갑니다.",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "사전 구성된 버전"
  }
};
var configurator$b = {
  configurator: configurator$a
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ko = {
  configurator: configurator$b
};
var configurator$8 = {
  header: {
    consistent: "Spójny",
    complete: "Zakończ",
    configId: "Identyfikator konfiguracji",
    toconfig: "Konfiguruj",
    toConfigReadOnly: "Pokaż detale",
    editConfiguration: "Edytuj konfigurację",
    displayConfiguration: "Wyświetl konfigurację",
    resolveIssues: "Rozwiąż problemy",
    resolveIssue: "Rozwiąż problem",
    resolveConflicts: "Rozwiąż konflikty",
    conflictWarning: "Konflikt musi zostać rozwiązany, aby przejść dalej",
    updateMessage: "Konfiguracja jest aktualizowana w tle",
    conflictsResolved: "Wszystkie konflikty zostały rozwiązane",
    showMore: "pokaż więcej",
    showLess: "pokaż mniej",
    items: "{{count}} pozycja",
    items_other: "{{count}} pozycje",
    show: "pokaż",
    hide: "ukryj",
    multipleWarnings: "Jest wiele ostrzeżeń.",
    reviewWarnings: "Przejrzyj te ostrzeżenia.",
    multipleErrors: "Jest wiele błędów.",
    reviewErrors: "Przejrzyj te błędy.",
    kbKeyName: "Nazwa Kb",
    kbKeyLogsys: "System logiczny Kb",
    kbKeyVersion: "Wersja Kb",
    kbKeyBuildNr: "Numer kompilacji Kb"
  },
  tabBar: {
    configuration: "Konfiguracja",
    overview: "Przegląd"
  },
  notificationBanner: {
    numberOfIssues: "Problem do rozwiązania przed realizacją zakupu: {{count}}",
    numberOfIssues_other: "Problemy do rozwiązania przed realizacją zakupu: {{count}}",
    numberOfConflicts: "Konflikt do rozwiązania przed realizacją zakupu: {{count}}",
    numberOfConflicts_other: "Konflikty do rozwiązania przed realizacją zakupu: {{count}}"
  },
  attribute: {
    id: "ID",
    quantity: "Ilość",
    caption: "Atrybuty",
    notSupported: "Typ atrybutu nie jest wspierany.",
    requiredAttribute: "Wymagane {{param}}",
    defaultRequiredMessage: "Wprowadź wartość dla pola obowiązkowego",
    singleSelectRequiredMessage: "Wybierz wartość",
    singleSelectAdditionalRequiredMessage: "Wybierz lub wprowadź wartość",
    multiSelectRequiredMessage: "Wybierz jedną lub więcej wartości",
    wrongNumericFormat: "Nieprawidłowy format. Ten atrybut liczbowy powinien być wprowadzony zgodnie ze wzorem {{pattern}}",
    wrongNumericFormatMessage: "Wprowadź liczbę w następującym formacie: {{pattern}}",
    wrongIntervalFormat: "Wprowadź wartość mieszczącą się we wskazanych granicach",
    deselectionNotPossible: "Dodaj inny produkt przed usunięciem tego",
    dropDownSelectMsg: "Dokonaj wyboru",
    noOptionSelectedMsg: "Nie wybrano żadnej opcji",
    notVisibleAttributeMsg: "Ten atrybut nie jest widoczny dla użytkownika"
  },
  button: {
    previous: "Poprzednie",
    next: "Następne",
    back: "Wstecz",
    more: "więcej",
    less: "mniej",
    deselect: "Odznacz",
    select: "Wybierz",
    add: "Dodaj",
    remove: "Usuń",
    exit: "Opuść konfigurację",
    exitMobile: "Wyjdź",
    cancelConfiguration: "Anuluj konfigurację",
    cancelConfigurationMobile: "Anuluj",
    filterOverview: "Filtr",
    filterOverviewWithCount: "Filtr ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Zakończ",
    groupIncomplete: "Niekompletna",
    groupConflict: "Konflikt",
    subgroup: "ma podgrupy"
  },
  priceSummary: {
    basePrice: "Cena bazowa",
    selectedOptions: "Wybrane opcje",
    totalPrice: "Suma",
    totalPricePerItem: "Suma za sztukę"
  },
  addToCart: {
    button: "Dodaj do koszyka",
    buttonAfterAddToCart: "Przejdź do koszyka",
    buttonForQuote: "Przejdź do wyceny",
    buttonUpdateCart: "Gotowe",
    buttonDisplayOnly: "Gotowe",
    buttonClose: "Zamknij",
    confirmation: "Konfiguracja została dodana do koszyka",
    confirmationUpdate: "Koszyk został zaktualizowany o konfigurację",
    confirmationQuoteUpdate: "Wycena została zaktualizowana o konfigurację",
    quantity: "Ilość"
  },
  overviewForm: {
    noAttributeHeader: "Brak wyników",
    noAttributeText: "Usuń filtr(y), aby wyświetlić zawartość przeglądu",
    itemPrice: "Cena pozycji"
  },
  overviewSidebar: {
    menu: "Menu",
    filter: "Filtr"
  },
  overviewFilter: {
    title: "Wybierz filtry",
    byOption: "Filtruj według opcji",
    byGroup: "Filtruj według grup",
    byPrice: "Opcje istotne dla ceny",
    mySelections: "Moje wybory",
    removeAll: "Usuń wszystkie",
    removeAllFilters: "Usuń wszystkie filtry",
    removeByPrice: "Usuń filtry istotne dla ceny",
    removeMySelections: "Usuń filtr Moje wybory",
    removeByGroup: "Usuń grupę filtrów dla grupy {{group}}",
    noFiltersAvailable: "Brak dostępnych filtrów"
  },
  group: {
    general: "Ogólne",
    conflictHeader: "Rozwiąż konflikty",
    conflictGroup: "Konflikt dla {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Sugestia {{number}}:",
    suggestionText: "Zmień wartość dla \"{{ attribute }}\"",
    viewConflictDetails: "Wykryto konflikt - wyświetl detale",
    conflictDetected: "Wykryto konflikt",
    viewConfigurationDetails: "Wyświetl w konfiguracji"
  },
  restartDialog: {
    title: "Nieukończona konfiguracja",
    description: "Istnieje niedokończona konfiguracja z poprzedniej sesji. Czy chcesz ją wznowić lub rozpocząć nową konfigurację?",
    restartButton: "Rozpocznij nową",
    resumeButton: "Wznów"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Ten produkt jest produktem bazowym i nie można go dodać do koszyka; zamiast tego wybierz wariant produktu."
  },
  a11y: {
    configureProduct: "Skonfiguruj produkt",
    showDetailsProduct: "Pokaz szczegóły produktu",
    cartEntryBundleInfo: "Istnieje pozycja ",
    cartEntryBundleInfo_other: "Istnieją {{items}} pozycje ",
    cartEntryBundleName: "Pozycja {{ name }}",
    cartEntryBundleNameWithQuantity: "Pozycja {{ name }} ilość pozycji {{quantity}}",
    cartEntryBundleNameWithPrice: "Pozycja {{ name }} cena pozycji {{price}}",
    cartEntryBundle: "Pozycja {{ name }} cena pozycji {{price}} ilość pozycji {{quantity}}",
    cartEntryInfoIntro: "Produkt ma następujące atrybuty i wybrane wartości",
    cartEntryInfo: "Atrybut {{ attribute }} ma wybraną wartość {{value}}",
    nameOfAttribute: "Nazwa atrybutu",
    valueOfAttribute: "Wartość atrybutu {{ attribute }}",
    forAttribute: "{{ value }} dla atrybutu {{ attribute }}",
    valueOfAttributeFull: "Wartość {{ value }} atrybutu {{ attribute }}.",
    valueOfDateAttributeFull: "Wartość {{ value }} atrybutu daty {{ attribute }}. Naciśnij klawisz spacji, aby przejść do wyboru daty.",
    valueOfAttributeFullWithPrice: "Wartość {{ value }} atrybutu {{ attribute }}. Dopłata {{ price }}",
    selectedValueOfAttributeFull: "Wybrana wartość {{ value }} atrybutu {{ attribute }}.",
    selectedValueOfAttributeFullWithPrice: "Wybrana wartość {{ value }} atrybutu {{ attribute }}. Dopłata {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Wartość tylko do odczytu {{ value }} atrybutu {{ attribute }}. Dopłata {{ price }}",
    readOnlyValueOfAttributeFull: "Wartość tylko do odczytu {{ value }} atrybutu {{ attribute }}",
    valueOfAttributeBlank: "Wartość atrybutu {{ attribute }} jest pusta.",
    valueOfDateAttributeBlank: "Wartość atrybutu daty {{ attribute }} jest pusta. Naciśnij klawisz spacji, by przejść do wyboru daty.",
    value: "Wartość {{ value }}",
    attribute: "Atrybut {{ attribute }}",
    requiredAttribute: "Atrybut {{param}} jest wymagany",
    listOfAttributesAndValues: "Lista atrybutów i ich wartości:",
    editAttributesAndValues: "Edytuj wartości atrybutów:",
    group: "{{ group }} grupa atrybutów",
    itemOfAttributeSelected: "Pozycja {{ item }} atrybutu {{ attribute }} jest zaznaczona. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "Pozycja {{ item }} atrybutu {{ attribute }} jest zaznaczona. Dopłata {{ price }}. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "Pozycja {{ item }} atrybutu {{ attribute }} jest zaznaczona. Naciśnij przycisk, aby odznaczyć. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Pozycja {{ item }} atrybutu {{ attribute }} jest zaznaczona. Dopłata {{ price }}. Naciśnij przycisk, aby odznaczyć. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeUnselected: "Pozycja {{ item }} atrybutu {{ attribute }} jest odznaczona. Naciśnij przycisk, aby zaznaczyć. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "Pozycja {{ item }} atrybutu {{ attribute }} jest odznaczona. Dopłata {{ price }}. Naciśnij przycisk, aby zaznaczyć. {{ itemIndex }} z {{ itemCount }}",
    selectNoItemOfAttribute: "Aby nie wybrać żadnej pozycji dla atrybutu {{ attribute }} naciśnij przycisk. {{ itemIndex }} z {{ itemCount }}",
    itemOfAttribute: "Pozycja atrybutu {{ attribute }}",
    itemOfAttributeFull: "Pozycja {{ item }} atrybutu {{ attribute }}",
    itemOfAttributeFullWithPrice: "Pozycja {{ item }} atrybutu {{ attribute }}. Dopłata {{ price }}",
    itemOfAttributeFullWithQuantity: "Pozycja {{ item }} atrybutu {{ attribute }}. Ilość {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Pozycja {{ item }} atrybutu {{ attribute }}. Ilość {{ quantity }}. Dopłata {{ price }}",
    itemDescription: "Opis dla pozycji {{ item }}",
    listbox: "Pole listy z {{ count }} wartościami.",
    valueSurcharge: "Dopłata do wartości",
    conflictDetected: "Wykryto konflikt! Sprawdź konflikty na górze listy grup.",
    conflictsInConfiguration: "W konfiguracji występują konflikty. Liczba konfliktów: {{ numberOfConflicts }}. Wybierz, aby uzyskać więcej informacji.",
    listOfGroups: "Lista grup",
    inListOfGroups: "Znajdujesz się w liście grup",
    groupName: "Grupa {{ group }}",
    groupBack: "Znajdujesz się w podgrupie. Wybierz, aby wrócić.",
    conflictBack: "Znajdujesz się w oknie rozwiązywania konfliktów. Wybierz, aby wrócić lub wybierz kolejne zakładki, aby rozwiązać konflikty.",
    iconConflict: "Grupa zawiera konflikty.",
    iconIncomplete: "Grupa ma wymagane atrybuty bez wybranych wartości.",
    iconComplete: "Grupa jest kompletna.",
    iconSubGroup: "Grupa ma podgrupę.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Wyświetl więcej informacji o produkcie {{ product }} lub kontynuuj konfigurację.",
    showLessProductInfo: "Wyświetl mniej informacji o produkcie {{ product }} lub kontynuuj konfigurację.",
    productName: "Nazwa produktu",
    productCode: "Kod produktu",
    productDescription: "Opis produktu",
    kbKeyName: "Nazwa Kb {{name}}",
    kbKeyLogsys: "System logiczny Kb {{logsys}}",
    kbKeyVersion: "Wersja Kb {{version}}",
    kbKeyBuildNr: "Numer kompilacji Kb {{number}}",
    configurationPage: "Znajdujesz się na stronie konfiguracji.",
    configurationPageLink: "Przejdź na stronę konfiguracji.",
    overviewPage: "Znajdujesz się na stronie przeglądu. Sprawdź atrybuty i ich wybrane wartości w trybie czytania twojego czytnika ekranu.",
    overviewPageLink: "Przejdź na stronę przeglądu konfiguracji.",
    navigateToConflict: "Kliknij link, aby uzyskać więcej informacji na temat konfliktu o atrybut {{ attribute }}.",
    numericIntervalStandard: "Wprowadź wartość z przedziału od {{ minValue }} do {{ maxValue }}",
    numericIntervalStandardOpen: "(Punkty końcowe nie są uwzględnione)",
    numericIntervalStandardLowerEndpointNotIncluded: "(Dolny punkt końcowy nie jest uwzględniony)",
    numericIntervalStandardUpperEndpointNotIncluded: "(Górny punkt końcowy nie jest uwzględniony)",
    numericInfiniteIntervalMinValueIncluded: "Wprowadź wartość większą niż lub równą {{ value }}",
    numericInfiniteIntervalMinValue: "Wprowadź wartość większą niż {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Wprowadź wartość mniejszą niż lub równą {{ value }}",
    numericInfiniteIntervalMaxValue: "Wprowadź wartość mniejszą niż {{ value }}",
    numericIntervalSingleValue: "Wprowadź {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} lub {{ newInterval }}",
    additionalValue: "Można również wprowadzić dodatkową wartość.",
    addToCartPrices: "Bazowa cena {{basePrice}}, wybrane opcje {{selectedOptions}}, cena ogółem {{totalPrice}}",
    filterOverview: "Otwórz menu filtra przeglądu konfiguracji",
    filterOverviewWithCount: "Otwórz menu filtra przeglądu konfiguracji - {{numAppliedFilters}} aktywne filtry",
    closeFilterMenu: "Zamknij menu filtrów przeglądu konfiguracji i zastosuj wybrane filtry",
    filterOverviewByPrice: "Filtruj przegląd konfiguracji według opcji istotnych dla ceny",
    filterOverviewByMySelections: "Filtruj przegląd konfiguracji według swoich wyborów",
    filterOverviewByGroup: "Filtruj przegląd konfiguracji według grup {{groupName}}",
    closeConflictSolverModal: "Zamknij moduł rozwiązywania konfliktów",
    closeRestartDialog: "Zamknij okno dialogowe „Niedokończona konfiguracja” i wróć do strony szczegółów produktu",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Wstępnie skonfigurowane wersje"
  }
};
var configurator$9 = {
  configurator: configurator$8
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pl = {
  configurator: configurator$9
};
var configurator$6 = {
  header: {
    consistent: "Consistente",
    complete: "Concluir",
    configId: "ID da configuração",
    toconfig: "Configurar",
    toConfigReadOnly: "Mostrar detalhes",
    editConfiguration: "Editar configuração",
    displayConfiguration: "Exibir configuração",
    resolveIssues: "Resolver problemas",
    resolveIssue: "Resolver problema",
    resolveConflicts: "Resolver conflitos",
    conflictWarning: "O conflito deve ser resolvido para continuar",
    updateMessage: "A configuração está sendo atualizada em background",
    conflictsResolved: "Os conflitos foram resolvidos",
    showMore: "mostrar mais",
    showLess: "mostrar menos",
    items: "{{count}} item",
    items_other: "{{count}} itens",
    show: "mostrar",
    hide: "ocultar",
    multipleWarnings: "Existem várias advertências.",
    reviewWarnings: "Revisar essas advertências",
    multipleErrors: "Existem vários erros.",
    reviewErrors: "Revisar esses erros",
    kbKeyName: "Nome de BC",
    kbKeyLogsys: "Sistema lógico de BC",
    kbKeyVersion: "Versão de BC",
    kbKeyBuildNr: "Número de build de BC"
  },
  tabBar: {
    configuration: "Configuração",
    overview: "Síntese"
  },
  notificationBanner: {
    numberOfIssues: "{{count}} problema deve ser resolvido antes do check-out",
    numberOfIssues_other: "{{count}} problemas devem ser resolvidos antes do check-out",
    numberOfConflicts: "{{count}} conflito deve ser resolvido antes do check-out",
    numberOfConflicts_other: "{{count}} conflitos devem ser resolvidos antes do check-out"
  },
  attribute: {
    id: "ID",
    quantity: "Quantidade",
    caption: "Atributos",
    notSupported: "O tipo de atributo não é suportado.",
    requiredAttribute: "{{param}} obrigatório",
    defaultRequiredMessage: "Insira um valor para o campo obrigatório",
    singleSelectRequiredMessage: "Selecionar um valor",
    singleSelectAdditionalRequiredMessage: "Selecionar ou inserir um valor",
    multiSelectRequiredMessage: "Selecionar um ou mais valores",
    wrongNumericFormat: "Formato incorreto, este atributo numérico deve ser inserido de acordo com o padrão {{pattern}}",
    wrongNumericFormatMessage: "Insira o número no seguinte formato: {{pattern}}",
    wrongIntervalFormat: "Insira um valor dentro dos limites indicados",
    deselectionNotPossible: "Adicione um produto diferente antes de remover este",
    dropDownSelectMsg: "Faça uma seleção",
    noOptionSelectedMsg: "Nenhuma opção selecionada",
    notVisibleAttributeMsg: "Este atributo não é visível para o usuário"
  },
  button: {
    previous: "Anterior",
    next: "Seguinte",
    back: "Voltar",
    more: "mais",
    less: "menos",
    deselect: "Desmarcar",
    select: "Selecionar",
    add: "Adicionar",
    remove: "Remover",
    exit: "Sair da configuração",
    exitMobile: "Sair",
    cancelConfiguration: "Cancelar configuração",
    cancelConfigurationMobile: "Cancelar",
    filterOverview: "Filtro",
    filterOverviewWithCount: "Filtro ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Completo",
    groupIncomplete: "Incompleto",
    groupConflict: "Conflito",
    subgroup: "tem subgrupos"
  },
  priceSummary: {
    basePrice: "Preço base",
    selectedOptions: "Opções selecionadas",
    totalPrice: "Total",
    totalPricePerItem: "Total por item"
  },
  addToCart: {
    button: "Adicionar ao carrinho",
    buttonAfterAddToCart: "Continuar para o carrinho",
    buttonForQuote: "Continuar para cotação",
    buttonUpdateCart: "Concluído",
    buttonDisplayOnly: "Concluído",
    buttonClose: "Fechar",
    confirmation: "A configuração foi adicionada ao carrinho",
    confirmationUpdate: "O carrinho foi atualizado com a configuração",
    confirmationQuoteUpdate: "A cotação foi atualizada com a configuração",
    quantity: "Quantidade"
  },
  overviewForm: {
    noAttributeHeader: "Nenhum resultado",
    noAttributeText: "Remover filtro(s) para ver conteúdo de síntese",
    itemPrice: "Preço do item"
  },
  overviewSidebar: {
    menu: "Menu",
    filter: "Filtro"
  },
  overviewFilter: {
    title: "Selecionar filtros",
    byOption: "Filtrar por opção",
    byGroup: "Filtrar por grupo",
    byPrice: "Opções relevantes para o preço",
    mySelections: "Minhas seleções",
    removeAll: "Remover tudo",
    removeAllFilters: "Remover todos os filtros",
    removeByPrice: "Remover filtro relevante para preço",
    removeMySelections: "Remover filtro Minhas seleções",
    removeByGroup: "Remover filtro de grupo para o grupo {{group}}",
    noFiltersAvailable: "Nenhum filtro disponível"
  },
  group: {
    general: "Geral",
    conflictHeader: "Resolver conflitos",
    conflictGroup: "Conflito para {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Sugestão {{number}}:",
    suggestionText: "Modificar valor para \"{{ attribute }}\"",
    viewConflictDetails: "Conflito detectado - Visualizar detalhes",
    conflictDetected: "Conflito detectado",
    viewConfigurationDetails: "Visualizar em configuração"
  },
  restartDialog: {
    title: "Configuração não concluída",
    description: "Existe uma configuração não concluída de uma sessão anterior. Você gostaria de retomar ou iniciar uma nova configuração?",
    restartButton: "Iniciar novo",
    resumeButton: "Retomar"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Este produto é um produto base e não pode ser adicionado ao carrinho; em vez desse, selecione uma variante do produto"
  },
  a11y: {
    configureProduct: "Configurar produto",
    showDetailsProduct: "Mostrar detalhes do produto",
    cartEntryBundleInfo: "Existe um item ",
    cartEntryBundleInfo_other: "Existem {{items}} itens ",
    cartEntryBundleName: "Item {{ name }}",
    cartEntryBundleNameWithQuantity: "Item {{ name }} quantidade do item {{quantity}}",
    cartEntryBundleNameWithPrice: "Item {{ name }} preço do item {{price}}",
    cartEntryBundle: "Item {{ name }} preço do item {{price}} quantidade do item {{quantity}}",
    cartEntryInfoIntro: "O produto tem os seguintes atributos e valores selecionados",
    cartEntryInfo: "O atributo {{ attribute }} tem o valor selecionado {{value}}",
    nameOfAttribute: "Nome do atributo",
    valueOfAttribute: "Nome do atributo {{ attribute }}",
    forAttribute: "{{ value }} para atributo {{ attribute }}",
    valueOfAttributeFull: "Valor {{ value }} de atributo {{ attribute }}.",
    valueOfDateAttributeFull: "Valor {{ value }} de atributo de data {{ attribute }}. Pressione a tecla de espaço para inserir o selecionador de data.",
    valueOfAttributeFullWithPrice: "Valor {{ value }} de atributo {{ attribute }}, sobretaxa {{ price }}",
    selectedValueOfAttributeFull: "Valor selecionado {{ value }} de atributo {{ attribute }}",
    selectedValueOfAttributeFullWithPrice: "Valor selecionado {{ value }} de atributo {{ attribute }}, sobretaxa {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Valor somente leitura {{ value }} de atributo {{ attribute }}, sobretaxa {{ price }}",
    readOnlyValueOfAttributeFull: "Valor somente leitura {{ value }} de atributo {{ attribute }}",
    valueOfAttributeBlank: "O valor do atributo {{ attribute }} está em branco.",
    valueOfDateAttributeBlank: "O valor de atributo de data {{ attribute }} está em branco. Pressione a tecla de espaço para inserir o selecionador de data",
    value: "Valor {{ value }}",
    attribute: "Atributo {{ attribute }}",
    requiredAttribute: "O atributo {{param}} é obrigatório",
    listOfAttributesAndValues: "Lista de atributos e seus valores:",
    editAttributesAndValues: "Editar valores de atributos:",
    group: "Grupo de atributos {{ group }}",
    itemOfAttributeSelected: "O item {{ item }} do atributo {{ attribute }} está selecionado. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "O item {{ item }} do atributo {{ attribute }} está selecionado, sobretaxa {{ price }}. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "O item {{ item }} do atributo {{ attribute }} está selecionado. Pressione o botão para desmarcar. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "O item {{ item }} do atributo {{ attribute }} está selecionado, sobretaxa {{ price }} Pressione o botão para desmarcar. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeUnselected: "O item {{ item }} do atributo {{ attribute }} está desmarcado. Pressione o botão para selecionar. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "O item {{ item }} do atributo {{ attribute }} está desmarcado, sobretaxa {{ price }}. Pressione o botão para selecionar. {{ itemIndex }} de {{ itemCount }}",
    selectNoItemOfAttribute: "Para não selecionar nenhum item para o atributo {{ attribute }}, pressione o botão. {{ itemIndex }} de {{ itemCount }}",
    itemOfAttribute: "Item de atributo {{ attribute }}",
    itemOfAttributeFull: "Item {{ item }} de atributo {{ attribute }}",
    itemOfAttributeFullWithPrice: "Item {{ item }} de atributo {{ attribute }}, sobretaxa {{ price }}",
    itemOfAttributeFullWithQuantity: "Item {{ item }} de atributo {{ attribute }}, quantidade {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Item {{ item }} de atributo {{ attribute }}, quantidade {{ quantity }}, sobretaxa {{ price }}",
    itemDescription: "Descrição para item {{ item }}",
    listbox: "Caixa de listagem com {{ count }} valores.",
    valueSurcharge: "Sobretaxa de valor",
    conflictDetected: "Conflito detectado! Verifique os conflitos no topo da lista de grupos.",
    conflictsInConfiguration: "Existem conflitos na sua configuração. Número de conflitos: {{ numberOfConflicts }}, selecione para mais detalhes.",
    listOfGroups: "Lista de grupos",
    inListOfGroups: "Você está na lista de grupos",
    groupName: "Grupo {{ group }}",
    groupBack: "Você está em um subgrupo. Selecione para voltar.",
    conflictBack: "Você está no resolvedor de conflitos. Selecione para voltar ou selecione as fichas de registro seguintes para resolver conflitos.",
    iconConflict: "O grupo tem conflitos.",
    iconIncomplete: "O grupo tem atributos necessários sem os valores selecionados.",
    iconComplete: "O grupo está completo.",
    iconSubGroup: "O grupo tem um subgrupo.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Mostrar mais informações para o produto {{ product }} ou continuar para a configuração.",
    showLessProductInfo: "Mostrar menos informações para o produto {{ product }} ou continuar para a configuração.",
    productName: "Nome do produto",
    productCode: "Código do produto",
    productDescription: "Descrição do produto",
    kbKeyName: "Nome de BC {{name}}",
    kbKeyLogsys: "Sistema lógico de BC {{logsys}}",
    kbKeyVersion: "Versão de BC {{version}}",
    kbKeyBuildNr: "Número de build de BC {{number}}",
    configurationPage: "Você está na página de configuração.",
    configurationPageLink: "Navegar para página de configuração.",
    overviewPage: "Você está na página de síntese. Verifique os atributos e seus valores selecionados com o modo de leitura de seu leitor de tela.",
    overviewPageLink: "Navegar para página de síntese de configuração.",
    navigateToConflict: "Siga o link para obter mais detalhes sobre o conflito para o atributo {{ attribute }}.",
    numericIntervalStandard: "Insira um valor entre {{ minValue }} e {{ maxValue }}",
    numericIntervalStandardOpen: "(Os pontos de acesso não estão incluídos)",
    numericIntervalStandardLowerEndpointNotIncluded: "(O ponto de acesso inferior não está incluído)",
    numericIntervalStandardUpperEndpointNotIncluded: "(O ponto de acesso superior não está incluído)",
    numericInfiniteIntervalMinValueIncluded: "Insira um valor maior que ou igual a {{ value }}",
    numericInfiniteIntervalMinValue: "Insira um valor maior que {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Insira um valor menor que ou igual a {{ value }}",
    numericInfiniteIntervalMaxValue: "Insira um valor menor que {{ value }}",
    numericIntervalSingleValue: "Insira {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} ou {{ newInterval }}",
    additionalValue: "Você também pode inserir um valor adicional.",
    addToCartPrices: "Preço base {{basePrice}}, opções selecionadas {{selectedOptions}}, preço total {{totalPrice}}",
    filterOverview: "Abrir menu de filtro de síntese de configuração",
    filterOverviewWithCount: "Abrir o menu de filtro de síntese de configuração - {{numAppliedFilters}} filtros ativos",
    closeFilterMenu: "Fechar o menu de filtro de síntese de configuração e aplicar os filtros selecionados",
    filterOverviewByPrice: "Filtrar síntese de configuração por opções relevantes para o preço",
    filterOverviewByMySelections: "Filtrar síntese de configuração pelas suas seleções",
    filterOverviewByGroup: "Filtrar síntese de configuração pelo grupo {{groupName}}",
    closeConflictSolverModal: "Fechar modal de resolvedor de conflitos",
    closeRestartDialog: "Fechar o diálogo \"Configuração não concluída\" e navegar de volta para a página de detalhes do produto",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Versões pré-configuradas"
  }
};
var configurator$7 = {
  configurator: configurator$6
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const pt = {
  configurator: configurator$7
};
var configurator$4 = {
  header: {
    consistent: "Непротиворечиво",
    complete: "Завершено",
    configId: "Ид. конфигурации",
    toconfig: "Сконфигурировать",
    toConfigReadOnly: "Показать сведения",
    editConfiguration: "Редактировать конфигурацию",
    displayConfiguration: "Просмотреть конфигурацию",
    resolveIssues: "Устранить проблемы",
    resolveIssue: "Устранить проблему",
    resolveConflicts: "Устранить конфликты",
    conflictWarning: "Для продолжения работы устраните конфликт",
    updateMessage: "Конфигурация обновляется в фоновом режиме",
    conflictsResolved: "Конфликты устранены",
    showMore: "показать больше",
    showLess: "показать меньше",
    items: "{{count}} позиция",
    items_other: "Позиций: {{count}}",
    show: "показать",
    hide: "скрыть",
    multipleWarnings: "Существует несколько предупреждений.",
    reviewWarnings: "Проверьте эти предупреждения.",
    multipleErrors: "Существует несколько ошибок.",
    reviewErrors: "Проверьте эти ошибки.",
    kbKeyName: "Имя базы знаний",
    kbKeyLogsys: "Логическая система базы знаний",
    kbKeyVersion: "Версия базы знаний",
    kbKeyBuildNr: "Номер сборки базы знаний"
  },
  tabBar: {
    configuration: "Конфигурация",
    overview: "Обзор"
  },
  notificationBanner: {
    numberOfIssues: "Перед оплатой необходимо решить проблемы: {{count}}.",
    numberOfIssues_other: "Перед оплатой необходимо решить проблемы: {{count}}.",
    numberOfConflicts: "Перед оплатой необходимо решить конфликты: {{count}}.",
    numberOfConflicts_other: "Перед оплатой необходимо решить конфликты: {{count}}."
  },
  attribute: {
    id: "Ид.",
    quantity: "Колич.",
    caption: "Атрибуты",
    notSupported: "Неподдерживаемый тип атрибута.",
    requiredAttribute: "Требуется: {{param}}",
    defaultRequiredMessage: "Введите значение для требуемого поля",
    singleSelectRequiredMessage: "Выберите значение",
    singleSelectAdditionalRequiredMessage: "Выберите или введите значение",
    multiSelectRequiredMessage: "Выберите одно или несколько значений",
    wrongNumericFormat: "Неверный формат: введите числовой атрибут по шаблону {{pattern}}",
    wrongNumericFormatMessage: "Введите число в следующем формате: {{pattern}}",
    wrongIntervalFormat: "Введите число в указанном диапазоне",
    deselectionNotPossible: "Добавьте другой продукт, прежде чем удалить выбранный",
    dropDownSelectMsg: "Сделайте выбор",
    noOptionSelectedMsg: "Вариант не выбран",
    notVisibleAttributeMsg: "Этот атрибут не отображается для пользователя"
  },
  button: {
    previous: "Назад",
    next: "Дальше",
    back: "Назад",
    more: "больше",
    less: "меньше",
    deselect: "Отменить выбор",
    select: "Выбрать",
    add: "Добавить",
    remove: "Удалить",
    exit: "Закрыть конфигурацию",
    exitMobile: "Выход",
    cancelConfiguration: "Отменить конфигурацию",
    cancelConfigurationMobile: "Отменить",
    filterOverview: "Фильтр",
    filterOverviewWithCount: "Фильтр ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "Завершено",
    groupIncomplete: "Не завершено",
    groupConflict: "Конфликт",
    subgroup: "имеет подгруппы"
  },
  priceSummary: {
    basePrice: "Базовая цена",
    selectedOptions: "Выбранные опции",
    totalPrice: "Итого",
    totalPricePerItem: "Итого по позиции"
  },
  addToCart: {
    button: "Добавить в корзину",
    buttonAfterAddToCart: "Перейти к корзине",
    buttonForQuote: "Перейти к предложению",
    buttonUpdateCart: "Выполнено",
    buttonDisplayOnly: "Выполнено",
    buttonClose: "Закрыть",
    confirmation: "Конфигурация добавлена в корзину",
    confirmationUpdate: "Корзина обновлена с добавлением конфигурации",
    confirmationQuoteUpdate: "Предложение обновлено с добавлением конфигурации",
    quantity: "Колич."
  },
  overviewForm: {
    noAttributeHeader: "Нет результатов",
    noAttributeText: "Удалите фильтры для отображения содержимого обзора",
    itemPrice: "Цена позиции"
  },
  overviewSidebar: {
    menu: "Меню",
    filter: "Фильтр"
  },
  overviewFilter: {
    title: "Выбор фильтров",
    byOption: "Фильтр по опции",
    byGroup: "Фильтр по группе",
    byPrice: "Релевантные для цены опции",
    mySelections: "Мои выборки",
    removeAll: "Удалить все",
    removeAllFilters: "Удалить все фильтры",
    removeByPrice: "Удалить фильтр релевантности для цены",
    removeMySelections: "Удалить фильтр \"Мои выборки\"",
    removeByGroup: "Удалить фильтр групп для группы {{group}}",
    noFiltersAvailable: "Нет доступных фильтров"
  },
  group: {
    general: "Общее",
    conflictHeader: "Устранить конфликты",
    conflictGroup: "Конфликт для {{attribute}}"
  },
  conflict: {
    suggestionTitle: "Предложение {{number}}:",
    suggestionText: "Изменить значение для \"{{ attribute }}\"",
    viewConflictDetails: "Обнаружен конфликт - просмотрите сведения",
    conflictDetected: "Обнаружен конфликт",
    viewConfigurationDetails: "Просмотреть в конфигурации"
  },
  restartDialog: {
    title: "Незавершенная конфигурация",
    description: "Существует незавершенная конфигурация из предыдущего сеанса. Восстановить ее или начать новую?",
    restartButton: "Начать новую",
    resumeButton: "Возобновить"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "Этот продукт является базовым и не может быть добавлен в корзину; выберите вариант продукта"
  },
  a11y: {
    configureProduct: "Сконфигурировать продукт",
    showDetailsProduct: "Показать сведения продукта",
    cartEntryBundleInfo: "1 позиция ",
    cartEntryBundleInfo_other: "Позиций: {{items}} ",
    cartEntryBundleName: "Позиция {{ name }}",
    cartEntryBundleNameWithQuantity: "Позиция {{ name }}, количество по позиции {{quantity}}",
    cartEntryBundleNameWithPrice: "Позиция {{ name }}, цена позиции {{price}}",
    cartEntryBundle: "Позиция {{ name }}, цена позиции {{price}}, количество по позиции {{quantity}}",
    cartEntryInfoIntro: "Продукт имеет следующие атрибуты и выбранные значения",
    cartEntryInfo: "Для атрибута {{ attribute }} выбрано значение {{value}}",
    nameOfAttribute: "Имя атрибута",
    valueOfAttribute: "Значение атрибута {{ attribute }}",
    forAttribute: "{{ value }} для атрибута {{ attribute }}",
    valueOfAttributeFull: "Значение {{ value }} для атрибута {{ attribute }}",
    valueOfDateAttributeFull: "Значение {{ value }} для атрибута даты {{ attribute }}. Нажмите пробел, чтобы открыть средство выбора дат.",
    valueOfAttributeFullWithPrice: "Значение {{ value }} для атрибута {{ attribute }}, надбавка {{ price }}",
    selectedValueOfAttributeFull: "Выбранное значение {{ value }} для атрибута {{ attribute }}",
    selectedValueOfAttributeFullWithPrice: "Выбранное значение {{ value }} для атрибута {{ attribute }}, надбавка {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "Значение только для чтения {{ value }} для атрибута {{ attribute }}, надбавка {{ price }}",
    readOnlyValueOfAttributeFull: "Значение только для чтения {{ value }} для атрибута {{ attribute }}",
    valueOfAttributeBlank: "Пустое значение атрибута {{ attribute }}.",
    valueOfDateAttributeBlank: "Пустое значение атрибута даты {{ attribute }}. Нажмите пробел, чтобы открыть средство выбора дат.",
    value: "Значение {{ value }}",
    attribute: "Атрибут {{ attribute }}",
    requiredAttribute: "Атрибут {{param}} является обязательным",
    listOfAttributesAndValues: "Список атрибутов и значений:",
    editAttributesAndValues: "Редактирование значений атрибутов:",
    group: "{{ group }} группа атрибутов",
    itemOfAttributeSelected: "Выбрана позиция {{ item }} для атрибута {{ attribute }}. {{ itemIndex }} из {{ itemCount }}.",
    itemOfAttributeSelectedWithPrice: "Выбрана позиция {{ item }} для атрибута {{ attribute }}, надбавка {{ price }}, {{ itemIndex }} из {{ itemCount }}.",
    itemOfAttributeSelectedPressToUnselect: "Выбрана позиция {{ item }} для атрибута {{ attribute }}. Чтобы отменить выбор, нажмите кнопку. {{ itemIndex }} из {{ itemCount }}.",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Выбрана позиция {{ item }} для атрибута {{ attribute }}, надбавка {{ price }}. Чтобы отменить выбор, нажмите кнопку. {{ itemIndex }} из {{ itemCount }}.",
    itemOfAttributeUnselected: "Отменен выбор позиции {{ item }} для атрибута {{ attribute }}. Чтобы выбрать, нажмите кнопку. {{ itemIndex }} из {{ itemCount }}.",
    itemOfAttributeUnselectedWithPrice: "Отменен выбор позиции {{ item }} для атрибута {{ attribute }} , надбавка {{ price }}. Чтобы выбрать, нажмите кнопку. {{ itemIndex }} из {{ itemCount }}.",
    selectNoItemOfAttribute: "Чтобы не выбирать позиции для атрибута {{ attribute }}, нажмите кнопку. {{ itemIndex }} из {{ itemCount }}.",
    itemOfAttribute: "Позиция для атрибута {{ attribute }}",
    itemOfAttributeFull: "Позиция {{ item }} для атрибута {{ attribute }}",
    itemOfAttributeFullWithPrice: "Позиция {{ item }} для атрибута {{ attribute }}, надбавка {{ price }}",
    itemOfAttributeFullWithQuantity: "Позиция {{ item }} для атрибута {{ attribute }}, количество {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "Позиция {{ item }} для атрибута {{ attribute }}, количество {{ quantity }}, надбавка {{ price }}",
    itemDescription: "Описание для позиции {{ item }}",
    listbox: "Окно списка со значениями ({{ count }})",
    valueSurcharge: "Надбавка для значения",
    conflictDetected: "Обнаружен конфликт! Просмотрите конфликты наверху списка групп.",
    conflictsInConfiguration: "Обнаружены конфликты в конфигурации. Число конфликтов: {{ numberOfConflicts }}. Щелкните, чтобы просмотреть сведения.",
    listOfGroups: "Список групп",
    inListOfGroups: "Вы просматриваете список групп",
    groupName: "Группа {{ group }}",
    groupBack: "Вы просматриваете подгруппу. Щелкните, чтобы вернуться.",
    conflictBack: "Вы просматриваете средство устранения конфликтов. Щелкните, чтобы вернуться, или выберите вкладку, чтобы устранить конфликты.",
    iconConflict: "Обнаружены конфликты в группе.",
    iconIncomplete: "Группа содержит обязательные атрибуты без выбранных значений.",
    iconComplete: "Группа завершена.",
    iconSubGroup: "Группа имеет подгруппу.",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "Просмотрите больше информации о продукте {{ product }} или перейдите к конфигурации.",
    showLessProductInfo: "Просмотрите меньше информации о продукте {{ product }} или перейдите к конфигурации.",
    productName: "Имя продукта",
    productCode: "Код продукта",
    productDescription: "Описание продукта",
    kbKeyName: "Имя базы знаний {{name}}",
    kbKeyLogsys: "Логическая система базы знаний {{logsys}}",
    kbKeyVersion: "Версия базы знаний {{version}}",
    kbKeyBuildNr: "Номер сборки базы знаний {{number}}",
    configurationPage: "Вы на странице конфигурации.",
    configurationPageLink: "Переход к странице конфигурации.",
    overviewPage: "Вы на странице обзора. Проверьте атрибуты и выбранные значения в режиме чтения считывающего устройства экрана.",
    overviewPageLink: "Переход к странице обзора конфигурации.",
    navigateToConflict: "Щелкните ссылку, чтобы получить дополнительные сведения о конфликте для атрибута {{ attribute }}.",
    numericIntervalStandard: "Введите значение от {{ minValue }} до {{ maxValue }}",
    numericIntervalStandardOpen: "(конечные точки не включены)",
    numericIntervalStandardLowerEndpointNotIncluded: "(нижняя конечная точка не включена)",
    numericIntervalStandardUpperEndpointNotIncluded: "(верхняя конечная точка не включена)",
    numericInfiniteIntervalMinValueIncluded: "Введите значение, которое больше или равно {{ value }}",
    numericInfiniteIntervalMinValue: "Введите значение больше {{ value }}",
    numericInfiniteIntervalMaxValueIncluded: "Введите значение, которое меньше или равно {{ value }}",
    numericInfiniteIntervalMaxValue: "Введите значение больше {{ value }}",
    numericIntervalSingleValue: "Введите {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} или {{ newInterval }}",
    additionalValue: "Также можно ввести дополнительное значение.",
    addToCartPrices: "Базовая цена {{basePrice}}, выбранные опции {{selectedOptions}}, общая цена {{totalPrice}}",
    filterOverview: "Открыть меню фильтров обзора конфигурации",
    filterOverviewWithCount: "Открыть меню фильтров обзора конфигурации - активных фильтров: {{numAppliedFilters}}",
    closeFilterMenu: "Закрыть меню фильтров обзора конфигурации и применить выбранные фильтры",
    filterOverviewByPrice: "Обзор конфигурации фильтров по опциям, релевантным для цены",
    filterOverviewByMySelections: "Обзор конфигурации фильтров по выборке",
    filterOverviewByGroup: "Обзор конфигурации фильтров по группе {{groupName}}",
    closeConflictSolverModal: "Закрыть модуль средства устранения конфликтов",
    closeRestartDialog: "Закрыть диалоговое окно \"Незавершенная конфигурация\" и вернуться на страницу сведений продукта",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "Предварительно настроенные версии"
  }
};
var configurator$5 = {
  configurator: configurator$4
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ru = {
  configurator: configurator$5
};
var configurator$2 = {
  header: {
    consistent: "一致",
    complete: "完成",
    configId: "配置标识",
    toconfig: "配置",
    toConfigReadOnly: "显示详细信息",
    editConfiguration: "编辑配置",
    displayConfiguration: "显示配置",
    resolveIssues: "解决问题",
    resolveIssue: "解决问题",
    resolveConflicts: "解决冲突",
    conflictWarning: "必须解决冲突才能继续",
    updateMessage: "正在后台更新配置",
    conflictsResolved: "冲突已解决",
    showMore: "显示更多",
    showLess: "显示更少",
    items: "{{count}} 个项目",
    items_other: "{{count}} 个项目",
    show: "显示",
    hide: "隐藏",
    multipleWarnings: "存在多个警告。",
    reviewWarnings: "查看这些警告",
    multipleErrors: "存在多个错误。",
    reviewErrors: "查看这些错误",
    kbKeyName: "知识库名称",
    kbKeyLogsys: "知识库逻辑系统",
    kbKeyVersion: "知识库版本",
    kbKeyBuildNr: "知识库版本号"
  },
  tabBar: {
    configuration: "配置",
    overview: "概览"
  },
  notificationBanner: {
    numberOfIssues: "结账前必须解决 {{count}} 个问题。",
    numberOfIssues_other: "结账前必须解决 {{count}} 个问题。",
    numberOfConflicts: "结账前必须解决 {{count}} 个冲突。",
    numberOfConflicts_other: "结账前必须解决 {{count}} 个冲突。"
  },
  attribute: {
    id: "标识",
    quantity: "数量",
    caption: "属性",
    notSupported: "属性类型不受支持。",
    requiredAttribute: "需要 {{param}}",
    defaultRequiredMessage: "为必填字段输入值",
    singleSelectRequiredMessage: "选择值",
    singleSelectAdditionalRequiredMessage: "选择或输入值",
    multiSelectRequiredMessage: "选择一个或多个值",
    wrongNumericFormat: "格式错误，此数字属性应根据模式 {{pattern}} 进行输入",
    wrongNumericFormatMessage: "输入以下格式的数字：{{pattern}}",
    wrongIntervalFormat: "输入所标明边界内的值",
    deselectionNotPossible: "移除此产品前添加其他产品",
    dropDownSelectMsg: "进行选择",
    noOptionSelectedMsg: "未选择任何选项",
    notVisibleAttributeMsg: "此属性对用户不可见"
  },
  button: {
    previous: "上一步",
    next: "下一步",
    back: "返回",
    more: "更多",
    less: "更少",
    deselect: "取消选择",
    select: "选择",
    add: "添加",
    remove: "移除",
    exit: "退出配置",
    exitMobile: "退出",
    cancelConfiguration: "取消配置",
    cancelConfigurationMobile: "取消",
    filterOverview: "过滤器",
    filterOverviewWithCount: "过滤器 ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "完成",
    groupIncomplete: "未完成",
    groupConflict: "冲突",
    subgroup: "具有子组"
  },
  priceSummary: {
    basePrice: "底价",
    selectedOptions: "所选的选项",
    totalPrice: "总计",
    totalPricePerItem: "每个商品的总计"
  },
  addToCart: {
    button: "加入购物车",
    buttonAfterAddToCart: "继续加入购物车",
    buttonForQuote: "继续报价",
    buttonUpdateCart: "完成",
    buttonDisplayOnly: "完成",
    buttonClose: "关闭",
    confirmation: "配置已添加到购物车",
    confirmationUpdate: "购物车已通过配置进行更新",
    confirmationQuoteUpdate: "报价已通过配置进行更新",
    quantity: "数量"
  },
  overviewForm: {
    noAttributeHeader: "无结果",
    noAttributeText: "移除过滤器以查看概览内容",
    itemPrice: "商品价格"
  },
  overviewSidebar: {
    menu: "菜单",
    filter: "过滤器"
  },
  overviewFilter: {
    title: "选择过滤器",
    byOption: "按选项过滤",
    byGroup: "按组过滤",
    byPrice: "价格相关选项",
    mySelections: "我的选择",
    removeAll: "全部移除",
    removeAllFilters: "移除所有过滤器",
    removeByPrice: "移除价格相关过滤器",
    removeMySelections: "移除“我的选择”过滤器",
    removeByGroup: "移除组 {{group}} 的“组”过滤器",
    noFiltersAvailable: "无可用过滤器"
  },
  group: {
    general: "常规",
    conflictHeader: "解决冲突",
    conflictGroup: "{{attribute}} 的冲突"
  },
  conflict: {
    suggestionTitle: "建议 {{number}}：",
    suggestionText: "更改 \"{{ attribute }}\" 的值",
    viewConflictDetails: "检测到冲突 - 查看详情",
    conflictDetected: "检测到冲突",
    viewConfigurationDetails: "在配置中查看"
  },
  restartDialog: {
    title: "未完成配置",
    description: "上一会话中有未完成的配置。是要继续该配置还是开始新的配置？",
    restartButton: "开始新配置",
    resumeButton: "继续"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "此产品为基础产品，无法加入购物车；改为选择产品变式"
  },
  a11y: {
    configureProduct: "配置产品",
    showDetailsProduct: "显示产品的详细信息",
    cartEntryBundleInfo: "存在一个商品 ",
    cartEntryBundleInfo_other: "存在 {{items}} 个商品 ",
    cartEntryBundleName: "商品 {{ name }}",
    cartEntryBundleNameWithQuantity: "商品 {{ name }} 商品数量 {{quantity}}",
    cartEntryBundleNameWithPrice: "商品 {{ name }} 商品价格 {{price}}",
    cartEntryBundle: "商品 {{ name }} 商品价格 {{price}} 商品数量 {{quantity}}",
    cartEntryInfoIntro: "产品具有以下属性和所选值",
    cartEntryInfo: "属性 {{ attribute }} 具有所选值 {{value}}",
    nameOfAttribute: "属性名称",
    valueOfAttribute: "属性 {{ attribute }} 的值",
    forAttribute: "属性 {{ attribute }} 的 {{ value }}",
    valueOfAttributeFull: "属性 {{ attribute }} 的值 {{ value }}。",
    valueOfDateAttributeFull: "日期属性 {{ attribute }} 的值 {{ value }}。按空格键进入日期选取器。",
    valueOfAttributeFullWithPrice: "属性 {{ attribute }} 的值 {{ value }}，附加费为 {{ price }}",
    selectedValueOfAttributeFull: "属性 {{ attribute }} 的所选值 {{ value }}",
    selectedValueOfAttributeFullWithPrice: "属性 {{ attribute }} 的所选值 {{ value }}，附加费为 {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "属性 {{ attribute }} 的只读值 {{ value }}，附加费为 {{ price }}",
    readOnlyValueOfAttributeFull: "属性 {{ attribute }} 的只读值 {{ value }}",
    valueOfAttributeBlank: "属性 {{ attribute }} 的值为空。",
    valueOfDateAttributeBlank: "日期属性 {{ attribute }} 的值为空。按空格键进入日期选取器",
    value: "值 {{ value }}",
    attribute: "属性 {{ attribute }}",
    requiredAttribute: "属性 {{param}} 是必需属性",
    listOfAttributesAndValues: "属性及其值的列表：",
    editAttributesAndValues: "编辑属性值：",
    group: "属性的 {{ group }} 组",
    itemOfAttributeSelected: "已选择属性 {{ attribute }} 的商品 {{ item }}。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "已选择属性 {{ attribute }} 的商品 {{ item }}，附加费为 {{ price }}。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "已选择属性 {{ attribute }} 的商品 {{ item }}。按下按钮取消选择。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "已选择属性 {{ attribute }} 的商品 {{ item }}，附加费为 {{ price }}。按下按钮取消选择。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselected: "已取消选择属性 {{ attribute }} 的商品 {{ item }}。按下按钮进行选择。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "已取消选择属性 {{ attribute }} 的商品 {{ item }}，附加费为 {{ price }}。按下按钮进行选择。{{ itemIndex }}/{{ itemCount }}",
    selectNoItemOfAttribute: "若想不选择属性 {{ attribute }} 的任何商品，请按下按钮。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttribute: "属性 {{ attribute }} 的商品",
    itemOfAttributeFull: "属性 {{ attribute }} 的商品 {{ item }}",
    itemOfAttributeFullWithPrice: "属性 {{ attribute }} 的商品 {{ item }}，附加费为 {{ price }}",
    itemOfAttributeFullWithQuantity: "属性 {{ attribute }} 的商品 {{ item }}，数量为 {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "属性 {{ attribute }} 的商品 {{ item }}，数量为 {{ quantity }}，附加费为 {{ price }}",
    itemDescription: "商品 {{ item }} 的描述",
    listbox: "具有 {{ count }} 个值的列表框。",
    valueSurcharge: "值附加费",
    conflictDetected: "检测到冲突！在组列表顶部查看冲突。",
    conflictsInConfiguration: "配置中存在冲突。冲突数量：{{ numberOfConflicts }}，选择以获取更多详情。",
    listOfGroups: "组列表",
    inListOfGroups: "您在组列表中",
    groupName: "组 {{ group }}",
    groupBack: "您在子组中。选择返回。",
    conflictBack: "您在冲突处理器中。选择返回或选择下一个选项卡解决冲突。",
    iconConflict: "组具有冲突。",
    iconIncomplete: "组具有必需属性，但未选择值。",
    iconComplete: "组未完成。",
    iconSubGroup: "组具有子组。",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "显示产品 {{ product }} 的更多信息或继续配置。",
    showLessProductInfo: "显示产品 {{ product }} 的更少信息或继续配置。",
    productName: "产品名称",
    productCode: "产品代码",
    productDescription: "产品描述",
    kbKeyName: "知识库名称 {{name}}",
    kbKeyLogsys: "知识库逻辑系统 {{logsys}}",
    kbKeyVersion: "知识库版本 {{version}}",
    kbKeyBuildNr: "知识库版本号 {{number}}",
    configurationPage: "您在配置页面上。",
    configurationPageLink: "导航到配置页面。",
    overviewPage: "您在概览页面上。使用屏幕阅读器的阅读模式检查属性及其所选值。",
    overviewPageLink: "导航到配置概览页面。",
    navigateToConflict: "打开链接获取有关属性 {{ attribute }} 的冲突的更多详情。",
    numericIntervalStandard: "输入介于 {{ minValue }} 和 {{ maxValue }} 之间的值",
    numericIntervalStandardOpen: "（不包括端点）",
    numericIntervalStandardLowerEndpointNotIncluded: "（不包括下端点）",
    numericIntervalStandardUpperEndpointNotIncluded: "（不包括上端点）",
    numericInfiniteIntervalMinValueIncluded: "输入大于或等于 {{ value }} 的值",
    numericInfiniteIntervalMinValue: "输入大于 {{ value }} 的值",
    numericInfiniteIntervalMaxValueIncluded: "输入小于或等于 {{ value }} 的值",
    numericInfiniteIntervalMaxValue: "输入小于 {{ value }} 的值",
    numericIntervalSingleValue: "输入 {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} 或 {{ newInterval }}",
    additionalValue: "还可以输入附加值。",
    addToCartPrices: "基础价格 {{basePrice}}、所选选项 {{selectedOptions}}、总价格 {{totalPrice}}",
    filterOverview: "打开配置概览过滤器菜单",
    filterOverviewWithCount: "打开配置概览过滤器菜单 - {{numAppliedFilters}} 个已激活过滤器",
    closeFilterMenu: "关闭配置概览过滤器菜单并应用所选过滤器",
    filterOverviewByPrice: "按价格相关选项过滤配置概览",
    filterOverviewByMySelections: "按您的选择过滤配置概览",
    filterOverviewByGroup: "按组 {{groupName}} 过滤配置概览",
    closeConflictSolverModal: "关闭冲突处理器模式",
    closeRestartDialog: "关闭“未完成配置”对话框并导航回产品详细信息页面",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "预配置版本"
  }
};
var configurator$3 = {
  configurator: configurator$2
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh = {
  configurator: configurator$3
};
var configurator = {
  header: {
    consistent: "一致",
    complete: "完成",
    configId: "組態 ID",
    toconfig: "組態",
    toConfigReadOnly: "顯示明細",
    editConfiguration: "編輯組態",
    displayConfiguration: "顯示組態",
    resolveIssues: "解決問題",
    resolveIssue: "解決問題",
    resolveConflicts: "解決衝突",
    conflictWarning: "必須解決衝突才能繼續",
    updateMessage: "正在背景中更新組態",
    conflictsResolved: "已解決衝突",
    showMore: "顯示更多",
    showLess: "顯示更少",
    items: "{{count}} 個項目",
    items_other: "{{count}} 個項目",
    show: "顯示",
    hide: "隱藏",
    multipleWarnings: "有多個警告。",
    reviewWarnings: "審查這些警告",
    multipleErrors: "有多個錯誤。",
    reviewErrors: "審查這些錯誤",
    kbKeyName: "KB 名稱",
    kbKeyLogsys: "KB 邏輯系統",
    kbKeyVersion: "KB 版本",
    kbKeyBuildNr: "KB 建立號碼"
  },
  tabBar: {
    configuration: "組態",
    overview: "概觀"
  },
  notificationBanner: {
    numberOfIssues: "結帳前，必須解決 {{count}} 個問題。",
    numberOfIssues_other: "結帳節，必須解決 {{count}} 個問題。",
    numberOfConflicts: "結帳前，必須解決 {{count}} 個衝突。",
    numberOfConflicts_other: "解帳前，必須解決 {{count}} 個衝突。"
  },
  attribute: {
    id: "ID",
    quantity: "數量",
    caption: "屬性",
    notSupported: "不支援屬性類型。",
    requiredAttribute: "{{param}} 為必要",
    defaultRequiredMessage: "輸入必要欄位的值",
    singleSelectRequiredMessage: "選擇值",
    singleSelectAdditionalRequiredMessage: "選擇或輸入值",
    multiSelectRequiredMessage: "選擇一或多個值",
    wrongNumericFormat: "格式錯誤，應根據模式 {{pattern}} 輸入此數字屬性",
    wrongNumericFormatMessage: "以下列格式輸入數字： {{pattern}}",
    wrongIntervalFormat: "輸入指示邊界內的值",
    deselectionNotPossible: "移除此產品前，先新增不同產品",
    dropDownSelectMsg: "進行選擇",
    noOptionSelectedMsg: "未選擇選項",
    notVisibleAttributeMsg: "使用者無法此查看屬性"
  },
  button: {
    previous: "上一頁",
    next: "下一頁",
    back: "返回",
    more: "更多",
    less: "更少",
    deselect: "取消選擇",
    select: "選擇",
    add: "新增",
    remove: "移除",
    exit: "跳出組態",
    exitMobile: "跳出",
    cancelConfiguration: "取消組態",
    cancelConfigurationMobile: "取消",
    filterOverview: "篩選器",
    filterOverviewWithCount: "篩選器 ({{numAppliedFilters}})"
  },
  icon: {
    groupComplete: "完成",
    groupIncomplete: "未完成",
    groupConflict: "衝突",
    subgroup: "有子群組"
  },
  priceSummary: {
    basePrice: "基本價格",
    selectedOptions: "所選選項",
    totalPrice: "總計",
    totalPricePerItem: "各項目總計"
  },
  addToCart: {
    button: "新增至購物車",
    buttonAfterAddToCart: "繼續移至購物車",
    buttonForQuote: "繼續移至報價",
    buttonUpdateCart: "完成",
    buttonDisplayOnly: "完成",
    buttonClose: "關閉",
    confirmation: "已將組態新增至購物車",
    confirmationUpdate: "已使用組態更新購物車",
    confirmationQuoteUpdate: "已使用組態更新報價",
    quantity: "數量"
  },
  overviewForm: {
    noAttributeHeader: "沒有結果",
    noAttributeText: "移除篩選器以查看概觀內容",
    itemPrice: "項目價格"
  },
  overviewSidebar: {
    menu: "功能表",
    filter: "篩選器"
  },
  overviewFilter: {
    title: "選擇篩選器",
    byOption: "篩選依據選項",
    byGroup: "篩選依據群組",
    byPrice: "價格相關選項",
    mySelections: "我的選擇",
    removeAll: "移除全部",
    removeAllFilters: "移除所有篩選器",
    removeByPrice: "移除價格相關篩選器",
    removeMySelections: "移除我的選擇篩選器",
    removeByGroup: "移除群組 {{group}} 的群組篩選器",
    noFiltersAvailable: "沒有可用的篩選器"
  },
  group: {
    general: "一般",
    conflictHeader: "解決衝突",
    conflictGroup: "{{attribute}} 的衝突"
  },
  conflict: {
    suggestionTitle: "建議 {{number}}：",
    suggestionText: "更改「{{ attribute }}」的值",
    viewConflictDetails: "已偵測到衝突 - 檢視明細",
    conflictDetected: "已偵測到衝突",
    viewConfigurationDetails: "組態中檢視"
  },
  restartDialog: {
    title: "未完成的組態",
    description: "先前工作階段有未完成的組態。您要繼續或開始新組態嗎？",
    restartButton: "重新開始",
    resumeButton: "繼續"
  },
  httpHandlers: {
    makeToStockBaseProductIssue: "此產品是基本產品，因此無法新增至購物車；請改為選擇產品變式"
  },
  a11y: {
    configureProduct: "組態產品",
    showDetailsProduct: "顯示產品明細",
    cartEntryBundleInfo: "有項目 ",
    cartEntryBundleInfo_other: "有 {{items}} 個項目 ",
    cartEntryBundleName: "項目 {{ name }}",
    cartEntryBundleNameWithQuantity: "項目 {{ name }} 項目數量 {{quantity}}",
    cartEntryBundleNameWithPrice: "項目 {{ name }} 項目價格 {{price}}",
    cartEntryBundle: "項目 {{ name }} 項目價格 {{price}} 項目數量 {{quantity}}",
    cartEntryInfoIntro: "產品有下列屬性和所選值",
    cartEntryInfo: "屬性 {{ attribute }} 有所選值 {{value}}",
    nameOfAttribute: "屬性名稱",
    valueOfAttribute: "屬性 {{ attribute }} 的值",
    forAttribute: "屬性 {{ attribute }} 的 {{ value }}",
    valueOfAttributeFull: "屬性 {{ attribute }} 的值 {{ value }}。",
    valueOfDateAttributeFull: "日期屬性 {{ attribute }} 的值 {{ value }}。請按下空格鍵以輸入日期選擇器。",
    valueOfAttributeFullWithPrice: "屬性 {{ attribute }} 的值 {{ value }}、附加費用 {{ price }}",
    selectedValueOfAttributeFull: "屬性 {{ attribute }} 的所選值 {{ value }}",
    selectedValueOfAttributeFullWithPrice: "屬性 {{ attribute }} 的所選值 {{ value }}、附加費用 {{ price }}",
    readOnlyValueOfAttributeFullWithPrice: "屬性 {{ attribute }} 的唯讀值 {{ value }}、附加費用 {{ price }}",
    readOnlyValueOfAttributeFull: "屬性 {{ attribute }} 的唯讀值 {{ value }}",
    valueOfAttributeBlank: "屬性 {{ attribute }} 的值為空白。",
    valueOfDateAttributeBlank: "日期屬性 {{ attribute }} 的值為空白 。請按下空格鍵以輸入日期選擇器",
    value: "值 {{ value }}",
    attribute: "屬性 {{ attribute }}",
    requiredAttribute: "屬性 {{param}} 為必要",
    listOfAttributesAndValues: "屬性清單和其值：",
    editAttributesAndValues: "編輯屬性值：",
    group: "屬性的 {{ group }} 群組",
    itemOfAttributeSelected: "已選擇屬性 {{ attribute }} 的項目 {{ item }}。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedWithPrice: "已選擇屬性 {{ attribute }} 的項目 {{ item }}，附加費用 {{ price }}。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselect: "已選擇屬性 {{ attribute }} 的項目 {{ item }}。請按下按鈕以取消選擇。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "已選擇屬性 {{ attribute }} 的項目 {{ item }}，附加費用 {{ price }}。請按下按鈕以取消選擇。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselected: "已取消選擇屬性 {{ attribute }} 的項目 {{ item }}。請按下按鈕以選擇。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttributeUnselectedWithPrice: "已取消選擇屬性 {{ attribute }} 的項目 {{ item }}，附加費用 {{ price }}。請按下按鈕以選擇。{{ itemIndex }}/{{ itemCount }}",
    selectNoItemOfAttribute: "若要不選擇屬性 {{ attribute }} 的項目，請按下按鈕。{{ itemIndex }}/{{ itemCount }}",
    itemOfAttribute: "屬性 {{ attribute }} 的項目",
    itemOfAttributeFull: "屬性 {{ attribute }} 的項目 {{ item }}",
    itemOfAttributeFullWithPrice: "屬性 {{ attribute }} 的項目 {{ item }}、附加費用 {{ price }}",
    itemOfAttributeFullWithQuantity: "屬性 {{ attribute }} 的項目 {{ item }}、數量 {{ quantity }}",
    itemOfAttributeFullWithPriceAndQuantity: "屬性 {{ attribute }} 的項目 {{ item }}、數量 {{ quantity }}、附加費用 {{ price }}",
    itemDescription: "項目 {{ item }} 的說明",
    listbox: "包含 {{ count }} 值的清單方塊。",
    valueSurcharge: "值附加費用",
    conflictDetected: "已偵測到衝突！請在群組清單頂端檢查衝突。",
    conflictsInConfiguration: "您的組態中有衝突。衝突數量：{{ numberOfConflicts }}，請選擇以取得更多明細。",
    listOfGroups: "群組清單",
    inListOfGroups: "您在群組清單中",
    groupName: "群組 {{ group }}",
    groupBack: "您在子群組中。請選擇以返回。",
    conflictBack: "您在衝突解決程式中。請選擇以返回，或選擇下一個標籤以解決衝突。",
    iconConflict: "群組有衝突。",
    iconIncomplete: "群組有必要屬性，但沒有所選值。",
    iconComplete: "群組已完成。",
    iconSubGroup: "群組有子群組。",
    next: "Navigate to next group: {{ group }}",
    previous: "Navigate to previous group: {{ group }}",
    showMoreProductInfo: "顯示產品 {{ product }} 的更多資訊，或繼續組態。",
    showLessProductInfo: "顯示產品 {{ product }} 的更少資訊，或繼續組態。",
    productName: "產品名稱",
    productCode: "產品代碼",
    productDescription: "產品說明",
    kbKeyName: "KB 名稱 {{name}}",
    kbKeyLogsys: "KB 邏輯系統 {{logsys}}",
    kbKeyVersion: "KB 版本 {{version}}",
    kbKeyBuildNr: "KB 建立號碼 {{number}}",
    configurationPage: "您在組態頁面上。",
    configurationPageLink: "瀏覽至組態頁面。",
    overviewPage: "您在概觀頁面上。請使用螢幕助讀程式的讀取模式以檢查屬性和其所選值。",
    overviewPageLink: "瀏覽至組態概觀頁面。",
    navigateToConflict: "遵循連結以取得關於屬性 {{ attribute }} 衝突的更多明細。",
    numericIntervalStandard: "輸入介於 {{ minValue }} 到 {{ maxValue }} 之間的值",
    numericIntervalStandardOpen: "(未包含端點)",
    numericIntervalStandardLowerEndpointNotIncluded: "(未包含下限端點)",
    numericIntervalStandardUpperEndpointNotIncluded: "(未包含上限端點)",
    numericInfiniteIntervalMinValueIncluded: "輸入大於或等於 {{ value }} 的值",
    numericInfiniteIntervalMinValue: "輸入大於 {{ value }} 的值",
    numericInfiniteIntervalMaxValueIncluded: "輸入小於或等於 {{ value }} 的值",
    numericInfiniteIntervalMaxValue: "輸入小於 {{ value }} 的值",
    numericIntervalSingleValue: "輸入 {{ value }}",
    combinedIntervalsText: "{{ combinedInterval }} 或 {{ newInterval }}",
    additionalValue: "您也可輸入其他值。",
    addToCartPrices: "基本價格 {{basePrice}}、所選選項 {{selectedOptions}}、總價 {{totalPrice}}",
    filterOverview: "開啟組態概觀篩選器功能表",
    filterOverviewWithCount: "開啟組態概觀篩選器功能表 - {{numAppliedFilters}} 啟用中篩選器",
    closeFilterMenu: "關閉組態概觀篩選器功能表並套用所選篩選器",
    filterOverviewByPrice: "按價格相關選項篩選組態概觀",
    filterOverviewByMySelections: "按您的選擇篩選組態概觀",
    filterOverviewByGroup: "按群組 {{groupName}} 篩選組態概觀",
    closeConflictSolverModal: "關閉衝突解決程式強制回應視窗",
    closeRestartDialog: "關閉「未完成的組態」對話並瀏覽回產品明細頁面",
    description: "Click to see a description for value {{ value }}"
  },
  variantCarousel: {
    title: "預先組態的版本"
  }
};
var configurator$1 = {
  configurator: configurator
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const zh_TW = {
  configurator: configurator$1
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const configuratorTranslationChunksConfig = {
  configurator: ['configurator']
};

/*
 * SPDX-FileCopyrightText: 2025 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated Please use **specific language** translations (suffixed with language code) instead,
 * like in the following example:
 *             ```diff
 *               i18n: {
 *             -   resources: configuratorTranslations
 *             +   resources: { en: configuratorTranslationsEn }
 *               }
 *             ```
 */
const configuratorTranslations = {
  en: en
};

/**
 * Generated bundle index. Do not edit.
 */

export { configuratorTranslationChunksConfig, configuratorTranslations, cs as configuratorTranslationsCs, de as configuratorTranslationsDe, en as configuratorTranslationsEn, es as configuratorTranslationsEs, es_CO as configuratorTranslationsEs_CO, fr as configuratorTranslationsFr, hi as configuratorTranslationsHi, hu as configuratorTranslationsHu, id as configuratorTranslationsId, it as configuratorTranslationsIt, ja as configuratorTranslationsJa, ko as configuratorTranslationsKo, pl as configuratorTranslationsPl, pt as configuratorTranslationsPt, ru as configuratorTranslationsRu, zh as configuratorTranslationsZh, zh_TW as configuratorTranslationsZh_TW };
